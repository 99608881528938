import { F7ComponentContext } from '../types/framework7-types';
import { Messages } from 'framework7/build/core/components/messages/messages';
import { F7Messagebar } from '../types/framework7-types';
import { Router } from 'framework7/build/core/modules/router/router';
import { BaseComponent, ComponentState } from '../lib/base-component';
import { RootServices } from '../services/root.services';
import { NotificationModel } from '../model/notification.model';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { DownloadProgress, SyncStatus } from '@ionic-native/code-push/ngx';
import { filter, takeUntil } from 'rxjs/operators';
import { Gauge } from 'framework7/build/core/components/gauge/gauge';
import { CommonService } from '../services/common.service';

export interface ComponentStateExtend extends ComponentState {
  [key: string]: any;
}

export class F7ComponentContextExtend extends F7ComponentContext {
  messagebar?: F7Messagebar;
  messages?: Messages.Messages;
  images?: string[];
  responseInProgress?: boolean;
  answers?: string[];
  people?: { name?: string, avatar?: string }[];
  hideToolbar?: () => void;
  sheetToggle?: () => void;
  deleteAttachment?: (e: MouseEvent, index: number) => void;
  handleAttachment?: (e: MouseEvent) => void;
  checkAttachments?: () => void;
  sendMessage?: () => void;
  $root: any;
  notifications?: NotificationModel[];
  $route: Router.Route & {
    context?: {
      backTitle?: string,
      title?: string,
      largeTitle?: string,
      progress?: BehaviorSubject<DownloadProgress>,
      status?: BehaviorSubject<SyncStatus>,
    }
  };
  // [key: string]: any;
}

/** Component group manager */
export class UpdateComponent extends BaseComponent<ComponentStateExtend> {
  // states: { [key: string]: State } = {};

  title: 'Update';
  newNotification$ = new BehaviorSubject<NotificationModel>(null);

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
  ) {
    super(rootServices);
    // // console.log('Click here to open ts file');
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: 'update',
      path: '/update',
      component: {
        template: /*html*/`
            <div class="page no-toolbar" data-name="update">
              <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                      <a class="link back">
                          <i class="icon icon-back"></i>
                          <span class="if-not-md">{{backTitle}}</span>
                      </a>
                  </div>
                  <div class="title">Update</div>
                  <div class="right">
                      <a class="link icon-only">
                          <i class="icon f7-icons">ellipsis</i>
                      </a>
                  </div>
                </div>
              </div>
              <div class="page-content"> 

                <div class="profile-info" @click="testProgress"> 
                  <div class="profile-title" style="margin-top: 10px;"><span class="text-color-white bg-color-default" style="border-radius: 5px; padding: 3px;">Cập nhật robot {{appVersion}} {{mode}}</span></div>
                </div>

                <br>

                <div class="col text-align-center">
                  <div
                    class="gauge gauge-init"
                    data-type="circle"
                    data-value="0"
                    data-value-text="0%"
                    data-value-text-color="#4caf50"
                    data-border-color="#4caf50"
                    data-label-text="Update"
                    data-label-text-color="#f44336"
                    data-label-font-weight="700"
                  ></div>
                </div>

                <div class="block">
                  <div class="row">
                    <button class="col button button-large button-fill color-green" @click="goback">Trở về</button>
                    <button class="col button button-large button-fill {{#if notRequireReboot}}color-gray{{else}}color-red{{/if}}" {{#if notRequireReboot}}disabled{{/if}} @click="reloadApp">Reboot</button>
                  </div>
                </div>
                <br>
                <div class="block"></div>
                <div class="block-footer">
                  <div>
                    {{appName}}<br>
                    <div style="width: 50%; margin-bottom: 5px; border-bottom: 1px solid var(--f7-chip-outline-border-color)"></div>
                    {{appCopyright}}<br>
                    Version {{appVersion}} core {{coreVersion}}
                  </div>
                </div>
              </div>
            </div>
        `,
        style: `
        `,
        data() {
          const self: F7ComponentContextExtend = this;
          return {
            mode: $this.rootServices.env.mode,
            appVersion: 'v1.1',
            coreVersion: '3.9',
            title: $this.title,
            percent: 0,
            status: 'Đang cập nhật',
            notRequireReboot: true,
            appName: $this.rootServices.env.name,
            appTitle: $this.rootServices.env.title,
            appCopyright: $this.rootServices.env.copyright,
          };
        },
        methods: {
          async refresh() {
            console.debug('refresh home');
            const self: F7ComponentContextExtend = this;
            return true;
          },
          testProgress() {
            const self: F7ComponentContextExtend = this;
            const gauge = self.$app.gauge.get(self.$$('.gauge')[0] as HTMLElement);
            gauge.update({
              value: self.percent + 0.1,
            } as any);
            self.$setState({
              percent: self.percent + 0.1,
            });
          },
          goback() {
            const self: F7ComponentContextExtend = this;
            self.$router.back();
          },
          reloadApp() {
            const self: F7ComponentContextExtend = this;
            $this.rootServices.codePush.restartApplication();
          }
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          pageInit(e, page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;
            if (!self.backTitle) {
              self.$setState({
                backTitle: 'Back',
              });
            }

            $this.onComponentInit({ instance: self, destroy$: new Subject() }, 'main');
            (async function () {
              let gauge: Gauge.Gauge = null;
              await $this.rootServices.waitFor(300, 50, () => !!(gauge = self.$app.gauge.get(self.$$('.gauge')[0] as HTMLElement)));

              const version = $this.rootServices.getVersion();
              self.$setState({
                appVersion: 'v' + (await version).appVersion,
                coreVersion: 'v' + (await version).coreVersion,
              });

              const progress = $this.rootServices.updateProgress$;
              const updateStatus = $this.rootServices.updateStatus$;
              // if (progress) {
              //   progress.pipe(takeUntil($this.currentState.destroy$)).subscribe(state => {
              //     console.log('update progress', state);
              //     if (state !== null) {
              //       gauge.update({
              //         value: (state.receivedBytes / state.totalBytes) * 70 / 100,
              //       } as any);
              //     }
              //   });
              // }
              if (updateStatus) {
                updateStatus.pipe(takeUntil($this.currentState.destroy$)).subscribe(async status => {
                  console.log('udpate status', status);
                  let progressSubs: Subscription;
                  if (status !== null) {
                    if (status == SyncStatus.DOWNLOADING_PACKAGE) {
                      progressSubs = progress.subscribe(progress => {
                        const progressPercent = progress.receivedBytes / progress.totalBytes;
                        gauge.update({ 
                          value: [1, 1, 0, 0, 0, 0.2, 0.3, progressPercent, 0.7][status],
                          valueText: ['100%', '100%', '0%', '0%', '0%', '20%', '30%', (progressPercent * 100).toFixed(2) + '%', '70%'][status],
                          borderColor: ['#4caf50', '#4caf50', '#ff9800', '#ff9800', '#ff9800', '#ff9800', '#ff9800', '#3bb8e8', '#ff9800'][status],
                          labelText: ['Đã cập nhật', 'Đã cập nhật xong', 'Bỏ qua', 'Lỗi', 'Đang cập nhật', 'Đang kiểm tra cập nhật', 'Chờ xác nhận', 'Đang tải về', 'Đang cài đặt'][status],
                          labelTextColor: ['#4caf50', '#4caf50', '#ff9800', '#ff9800', '#ff9800', '#ff9800', '#ff9800', '#3bb8e8', '#ff9800'][status],
                        } as any);
                      });
                    } else {
                      gauge.update({
                        value: [1, 1, 0, 0, 0, 0.2, 0.3, 0.3, 0.7][status],
                        valueText: ['100%', '100%', '0%', '0%', '0%', '20%', '30%', '40%', '70%'][status],
                        borderColor: ['#4caf50', '#4caf50', '#ff9800', '#ff9800', '#ff9800', '#ff9800', '#ff9800', '#3bb8e8', '#ff9800'][status],
                        labelText: ['Đã cập nhật', 'Đã cập nhật xong', 'Bỏ qua', 'Lỗi', 'Đang cập nhật', 'Đang kiểm tra cập nhật', 'Chờ xác nhận', 'Đang tải về', 'Đang cài đặt'][status],
                        labelTextColor: ['#4caf50', '#4caf50', '#ff9800', '#ff9800', '#ff9800', '#ff9800', '#ff9800', '#3bb8e8', '#ff9800'][status],
                      } as any);
                    }
                    if ([SyncStatus.UPDATE_INSTALLED, SyncStatus.UP_TO_DATE].indexOf(status) > -1) {
                      if (progressSubs) {
                        progressSubs.unsubscribe();
                      }
                      let pendingPackage = null;
                      try {
                        pendingPackage = await $this.rootServices.codePush.getPendingPackage();
                        console.debug(pendingPackage);
                      } catch (err) {
                        console.error(err);
                        $this.commonService.showError(err);
                      }

                      self.$setState({
                        notRequireReboot: !pendingPackage,
                      });
                    }
                  }
                });
              }

            })();

          },
          pageBeforeIn(e, page) {
            console.log('page before in');
          },
          pageAfterIn(e, page) {
            console.log('page after in');
          },
          pageBeforeOut(e, page) {
            console.log('page before out');
          },
          pageAfterOut(e, page) {
            console.log('page after out');
          },
          pageBeforeUnmount(e, page) {
            console.log('page before unmount');
          },
          pageBeforeRemove(e, page) {
            console.log('page before remove');
            $this.currentState.destroy$.next();
            $this.currentState.destroy$.complete();
          },
        },
      },
    };
  }
}
