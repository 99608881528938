import { F7ComponentContext } from '../types/framework7-types';
import { Messages } from 'framework7/build/core/components/messages/messages';
import { F7Messagebar } from '../types/framework7-types';
import { Router } from 'framework7/build/core/modules/router/router';
import { BaseComponent, ComponentState } from '../lib/base-component';
import { RootServices } from '../services/root.services';
import { filter, take } from 'rxjs/operators';
import { NotificationModel } from '../model/notification.model';
import { BehaviorSubject } from 'rxjs';
import { RecentLoginModel } from '../model/recent-login.model';
import { CommonService } from '../services/common.service';

export interface ComponentStateExtend extends ComponentState {
  [key: string]: any;
}

export class F7ComponentContextExtend extends F7ComponentContext {
  messagebar?: F7Messagebar;
  messages?: Messages.Messages;
  images?: string[];
  responseInProgress?: boolean;
  answers?: string[];
  people?: { name?: string, avatar?: string }[];
  hideToolbar?: () => void;
  sheetToggle?: () => void;
  deleteAttachment?: (e: MouseEvent, index: number) => void;
  handleAttachment?: (e: MouseEvent) => void;
  checkAttachments?: () => void;
  sendMessage?: () => void;
  $root: any;
  notifications?: NotificationModel[];
  // [key: string]: any;
}

/** Component group manager */
export class RecentLoginsComponent extends BaseComponent<ComponentStateExtend> {
  // states: { [key: string]: State } = {};

  title: 'Recent Logins';
  newNotification$ = new BehaviorSubject<NotificationModel>(null);

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
  ) {
    super(rootServices);
    // // console.log('Click here to open ts file');
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: 'recent-logins',
      path: '/recent-logins',
      component: {
        template: /*html*/`
            <div class="page no-toolbar no-navbar no-swipeback" data-name="recent-logins">
              <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a class="panel-open link icon-only">
                        <i class="icon f7-icons">bars</i>
                    </a>
                  </div>                
                  <div class="title">{{appName}}</div>
                  <div class="right">
                      <a class="link icon-only">
                          <i class="icon f7-icons">ellipsis</i>
                      </a>
                  </div>
                </div>
              </div>
              <div class="page-content">

                
                <div class="block inset" style="padding: 0.5rem">
                  <div class="profile-info" style="display: flex">
                      <div class="avatar" style="">
                          <div class="profile-logo bg-color-gray" style="border-radius: 20%; background-image: url(assets/icon/icon-1024.png)"></div>
                      </div>
                      <div class="info" style="flex: 1; padding-left: 0.5rem; font-size: 0.7rem">
                          <div style="font-size: 2rem; font-weight: bold; line-height: 2rem;">Smart-BOT</div>
                          <div class="text-color-gray" style="text-align: left">{{appTitle}}<br>{{appCopyright}}<br>Version {{appVersion}} core {{coreVersion}}</div>
                      </div>
                  </div>
                </div>

                <!--<div class="card demo-facebook-card" style="opacity: 0.6;">
                  <div class="card-header">
                    <div class="demo-facebook-avatar">
                      <div class="demo-facebook-avatar-container" style=""></div>
                    </div>
                    <div class="demo-facebook-name">Tài khoản mới</div>
                    <div class="demo-facebook-date">Đăng nhập tài khoản mới</div>
                  </div>
                  <div class="card-content card-content-padding">Đăng nhập bằng mật khẩu hoặc quét mã</div>
                  <div class="card-footer">
                    <a href="#" class="link text-color-default" @click="gotoLogin">Đăng nhập</a>
                    <a href="#" class="link text-color-default">Quét mã</a>
                  </div>
                </div>-->

                {{#each recentLogins}}
                <div class="card demo-facebook-card" data-id="{{id}}">
                  <div class="card-header">
                    <div class="demo-facebook-avatar">
                      <div class="demo-facebook-avatar-container" style="background-image: url({{avatar}})"></div>
                    </div>
                    <div class="demo-facebook-name">{{name}}</div>
                    <div class="demo-facebook-date">{{coreName}}</div>
                  </div>
                  <div class="card-content" style="padding: 0.5rem; {{js "this.backgroundBannerColor ? ('background-color: ' + this.backgroundBannerColor) : ''"}}">
                    <div class="site-logo" style="background-image: url({{js "this.banner"}})"></div>
                  </div>
                  <div class="card-footer">
                    <a href="#" class="link text-color-default" @click="restoreLogin">Đăng nhập vào tài khoản</a>
                    <a href="#" @click="logout" class="link text-color-red">Gở</a>
                  </div>
                </div>
                {{/each}}

                <div class="block" style="padding-left: 0.5rem; padding-right: 0.5rem;">
                  <button class="button button-large button-fill color-green" @click="gotoLogin">Đăng nhập bằng máy chủ mới</button>
                </div>
              </div>
            </div>
        `,
        style: /*css*/`
          .site-info {
            margin-top: 20px;
          }
          .site-logo {
            height: 100px;
            border-radius: 3px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            margin: 0 auto;
            background-image: url(assets/images/logo_probox_one.png)
          }
          .site-title {
            text-align: center;
            font-weight: bold;
          }
          .site-subtitle {
            text-align: center;
          }
          .notification-list {
            margin-left: calc(var(--f7-list-inset-side-margin) + var(--f7-safe-area-outer-left));
            margin-right: calc(var(--f7-list-inset-side-margin) + var(--f7-safe-area-outer-right));
          }
          .notification-list ul:before,
          .notification-list ul:after {
            height: 0;
          }
          .notification.home-notification {
            position: inherit;
            margin-bottom: 10px;
            box-shadow: inherit;
            display: block;
            left: inherit;
            top: inherit;
            width: inherit;
            overflow: hidden;
            max-width: inherit;
          }
          .notification.home-notification.swipeout-deleting {
              padding: inherit;
          }
          .notification.home-notification-list.list ul:before {
            height: 0px;
          }
          .notification.home-notification-list.list ul:after {
            height: 0px;
          }
          @media (min-width: 568px) {
            .notification.home-notification {
              left: inherit;
              width: inherit;
              margin-left: inherit;
            }
          }
        `,
        data() {
          const self: F7ComponentContextExtend = this;
          return {
            appVersion: $this.rootServices.env.version,
            coreVersion: $this.rootServices.env.coreVersion,
            title: $this.title,
            recentLogins: [],
            appName: $this.rootServices.env.name,
            appTitle: $this.rootServices.env.title,
            appCopyright: $this.rootServices.env.copyright,
            // notifications: self.$root.notifications,
          };
        },
        methods: {
          async refresh() {
            console.debug('refresh home');
            const self: F7ComponentContextExtend = this;
            return true;
          },
          gotoLogin() {
            const self: F7ComponentContextExtend = this;
            self.$router.view.router.navigate('/login', { force: true });
          },
          restoreLogin(e: MouseEvent) {
            const self: F7ComponentContextExtend = this;
            const id = self.$(e.target).closest('.card').data('id');
            const recentLogin: RecentLoginModel = (self.recentLogins || []).find(f => f.id == id);
            // if(recentLogin) {
            //   $this.rootServices.authService.token$.next(new AuthToken(recentLogin.token));
            //   $this.rootServices.authService.loadUserInfoByToken(recentLogin.token);
            // }

            try {
              $this.commonService.showPreloader();
              $this.rootServices.authService.restoreLogin(id).then(async authToken => {
                $this.commonService.hidePreloader();
                if (authToken) {
                  const token = (await $this.rootServices.authService.token$.pipe(filter(f => !!f), take(1)).toPromise()).getPayload();
                  $this.rootServices.storage.get('recent_logins')
                    .then(async (recentLogins: RecentLoginModel[]) => {
                      recentLogins = recentLogins || [];
                      let currentLogin = recentLogins.find(f => f.core === token.core && f.user === token.user);
                      recentLogins = recentLogins.filter(f => f.core !== currentLogin.core || f.user !== currentLogin.user);

                      const loginUser = await $this.rootServices.authService.user$.pipe(filter(f => !!f), take(1)).toPromise();
                      currentLogin.name = loginUser.name;
                      currentLogin.avatar = loginUser.avatar && loginUser.avatar.payload && loginUser.avatar.payload.thumbnail;
                      currentLogin.coreName = loginUser.core && loginUser.core.name;
                      currentLogin.coreBanner = loginUser.core && loginUser.core.banner || '/assets/images/no-image-available.png';
                      currentLogin.banner = loginUser.core && loginUser.core.banner;
                      currentLogin.backgroundBannerColor = loginUser.core && loginUser.core.backgroundBannerColor;
                      currentLogin.token = token;

                      if (/\/v\d+/.test(currentLogin.url)) {
                        currentLogin.url = currentLogin.url.replace(/\/v\d+/, '');
                      }

                      recentLogins.unshift(currentLogin);
                      $this.rootServices.storage.set('recent_logins', recentLogins);

                      const router: Router.Router = this.$f7router;
                      self.$router.back('/home', { force: true });
                    }).catch(err => {
                      console.error(err);
                      $this.commonService.showError(err);
                    });


                  self.$router.back('/');
                } else {
                  self.$router.navigate('/login', {
                    context: {
                      recentLogin: recentLogin,
                    }
                  });
                }
              }).catch(err => {
                $this.commonService.hidePreloader();
                self.$router.navigate('/login', {
                  context: {
                    recentLogin: recentLogin,
                  }
                });
                console.error(err);
                $this.commonService.showError(err);
              });
            } catch (err) {
              console.error(err);
              $this.commonService.showError(err);
            }
          },
          logout(e: MouseEvent) {
            const self: F7ComponentContextExtend = this;
            const id = self.$(e.target).closest('.card').data('id');
            const recentLogin: RecentLoginModel = (self.recentLogins || []).find(f => f.id == id);
            if (recentLogin) {
              if (recentLogin.token) {
                self.$app.dialog.confirm('Chú ý: Tài khoản này sẽ không thể đăng nhập lại sau khi đăng xuất, để đăng nhập lại bạn cần liên hệ với admin !', 'Đăng xuất', () => {
                  $this.rootServices.authService.logout(recentLogin.token);
                  self.recentLogins = self.recentLogins.filter(f => f.id != id);
                  $this.rootServices.storage.set('recent_logins', self.recentLogins);
                  self.$setState({
                    recentLogins: self.recentLogins,
                  });
                });
              } else {
                console.log('recent login was not contain token');
              }
            }
          }
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          pageInit(e, page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;
            $this.onChangedState({ instance: self }, 'main');

            $this.rootServices.storage.get('recent_logins').then((recentLogins: RecentLoginModel[]) => {
              if (!recentLogins || recentLogins.length === 0) {
                self.$router.view.router.navigate('/login', { animate: false });
              } else {
                self.$setState({
                  recentLogins: recentLogins.map(item => {
                    item.coreName = new URL(item.url).host;
                    item.avatar = item.url + '/v3/user/users/' + item.user + '/avatar';
                    item.banner = item.url + '/v3/system/settings/banner';
                    return item;
                  }),
                });
              }
            }).catch(err => {
              console.error(err);
              $this.commonService.showError(err);
            });
          },
          pageBeforeIn(e, page) {
            console.log('page before in');
          },
          pageAfterIn(e, page) {
            console.log('page after in');
          },
          pageBeforeOut(e, page) {
            console.log('page before out');
          },
          pageAfterOut(e, page) {
            console.log('page after out');
          },
          pageBeforeUnmount(e, page) {
            console.log('page before unmount');
          },
          pageBeforeRemove(e, page) {
            console.log('page before remove');
          },
        },
      },
    };
  }
}
