import { F7ComponentContext, F7Page } from './../types/framework7-types';
import { Messages } from 'framework7/build/core/components/messages/messages';
import { F7Messagebar } from '../types/framework7-types';
import { Router } from 'framework7/build/core/modules/router/router';
import { BaseComponent, ComponentState } from '../lib/base-component';
import { RootServices } from '../services/root.services';
import { filter, take, takeUntil } from 'rxjs/operators';
import { environment } from '../../../src/environments/environment';
import { CommonService } from '../services/common.service';
import { isPlatform } from '@ionic/angular';
import { RecentLoginModel } from '../model/recent-login.model';
import { NotificationSettings } from '../model/user.model';
import { AppComponentProcessor } from '../app.component.processor';
import { Subject } from 'rxjs';
import { ILocalPackage } from '@ionic-native/code-push/ngx';
// import * as $ from 'jquery';
declare const $: any;

// declare const $: any;
export interface ComponentStateExtend extends ComponentState {
  [key: string]: any;
}

export class F7ComponentContextExtend extends F7ComponentContext {
  messagebar?: F7Messagebar;
  messages?: Messages.Messages;
  images?: string[];
  responseInProgress?: boolean;
  answers?: string[];
  people?: { name?: string, avatar?: string }[];
  hideToolbar?: () => void;
  sheetToggle?: () => void;
  deleteAttachment?: (e: MouseEvent, index: number) => void;
  handleAttachment?: (e: MouseEvent) => void;
  checkAttachments?: () => void;
  sendMessage?: () => void;
  $root: any;
  // [key: string]: any;
}

/** Component group manager */
export class SettingsComponent extends BaseComponent<ComponentStateExtend> {
  // states: { [key: string]: State } = {};

  title = 'Cài đặt'
  env = environment;

  constructor(
    public appComponent: AppComponentProcessor,
    public rootServices: RootServices,
    public commonService: CommonService,
  ) {
    super(rootServices);
    // // console.log('Click here to open ts file');
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  resetUserNotificationSetting() {
    this.rootServices.settings$.pipe(takeUntil(this.currentState.destroy$), filter(f => f !== null), take(1)).toPromise().then(settings => {
      let checkpoint = settings.NOTIFICATION_SETTINGS.ENABLEDONTOMORROW;
      if (checkpoint) {
        checkpoint = parseInt(checkpoint);
      }

      if (settings.NOTIFICATION_SETTINGS.ENABLEDONRETURN || (checkpoint && (checkpoint + 24 * 60 * 60) * 1000 < Date.now())) {
        const setting = {
          NOTIFICATION_SETTINGS: {
            DISABLEDNEWMESSAGE: false,
            DISABLEDMENTION: false,
            DISABLEDREPLY: false,
            ENABLEDONRETURN: false,
            ENABLEDONTOMORROW: false,
          },
        };
        // this.rootServices.socketService.mainSocket.emit<{ NOTIFICATION_SETTINGS: NotificationSettings }>('update-user-settings', setting).then(rs => {
        this.rootServices.apiService.putPromise<{ NOTIFICATION_SETTINGS: NotificationSettings }>('/user/users/settings', {}, setting).then(async rs => {
          const settings = await this.rootServices.settings$.pipe(takeUntil(this.currentState.destroy$), filter(f => f !== null), take(1)).toPromise();
          settings.NOTIFICATION_SETTINGS = setting.NOTIFICATION_SETTINGS;
          this.commonService.showInfo('Đã khôi phục cài đặt thông báo');
          this.rootServices.settings$.next(settings);
        }).catch(err => {
          this.commonService.showError(err);
        });
      }
    });
  }

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: 'settings',
      path: '/settings',
      component: {
        template: /*html*/`
            <div class="page" data-name="settings">
              <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a class="back link icon-only">
                    <i class="icon icon-back"></i>
                    <span class="if-not-md {{textColorClass}}">{{backTitle}}</span>
                    </a>
                  </div>  
                  <div class="title">Cài đặt</div>
                  <div class="right">
                      <a class="link icon-only">
                          <i class="icon f7-icons">ellipsis</i>
                      </a>
                  </div>
                </div>
              </div>
              <div class="page-content">


              <div class="block block-strong inset site-info" style="{{js "this.backgroundLogoColor ? ('background-color: ' + this.backgroundLogoColor) : ''"}}">
                <div class="site-logo" style="background-image: url({{js "this.logo || 'assets/images/logo_probox_one.png'"}})"></div>
                <!--<div class="site-subtitle">Công Ty Cổ Phần Công Nghệ ProBox</div>-->
              </div>

          
                <div class="list media-list inset">
                  <ul>
                    <li class="item-link" @click="gotoAccount">
                      <div class="item-content">
                        <div class="item-media"><div style="width: 44px; height: 44px; border-radius: 50%; background-image: url({{js "this.profile && this.profile.Avatar && this.profile.Avatar.payload && this.profile.Avatar.payload.thumbnail"}}); background-repeat: no-repeat; background-position: center; background-size: cover;"></div></div>
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title">{{js "this.profile && this.profile.Name"}}</div>
                          </div>
                          <div class="item-subtitle">{{js "this.profile && this.profile.Core"}}</div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                <!--
                <div class="block-title">Cài đặt ngôn ngữ</div>
                <div class="list simple-list inset">
                  <ul>
                    <li>
                      <span>English/Tiếng việt</span>
                      <label class="toggle toggle-init color-blue">
                        <input type="checkbox" checked disabled>
                        <span class="toggle-icon"></span>
                      </label>
                    </li>
                  </ul>
                </div>
                -->

                <div class="block-title">Cài đặt thông báo</div>
                <div class="list simple-list inset notificationSettings">
                  <ul>
                    <li>
                        <span>Tin nhắn mới</span>
                        <label class="toggle toggle-init color-green">
                            <input class="field" name="DISABLEDNEWMESSAGE" value="-1" type="checkbox" checked>
                            <span class="toggle-icon"></span>
                        </label>
                    </li>
                    <li>
                        <span>Có người nhắc tôi</span>
                        <label class="toggle toggle-init color-red">
                            <input class="field" name="DISABLEDMENTION" value="-1" type="checkbox" checked>
                            <span class="toggle-icon"></span>
                        </label>
                    </li>
                    <li>
                        <span>Trả lời tin nhắn</span>
                        <label class="toggle toggle-init color-orange">
                            <input class="field" name="DISABLEDREPLY" value="-1" type="checkbox" checked>
                            <span class="toggle-icon"></span>
                        </label>
                    </li>
                    <li>
                        <span>Tắt tới khi quay lại</span>
                        <label class="toggle toggle-init color-gray">
                            <input class="field" name="ENABLEDONRETURN" value="1" type="checkbox">
                            <span class="toggle-icon"></span>
                        </label>
                    </li>
                    <li>
                        <span>Tắt tới hôm sau</span>
                        <label class="toggle toggle-init color-black">
                            <input class="field" name="ENABLEDONTOMORROW" value="1" type="checkbox">
                            <span class="toggle-icon"></span>
                        </label>
                    </li>
                    <li class="item-content item-input" style="padding-left: 0; padding-right: 0;">
                      <div class="row" style="width: 100%">
                        <a @click="resetNotificationSettings" class="col button button-large link color-red" @click="applyNotificationSettings" style="border-top-right-radius: 0; border-bottom-right-radius: 0; border-top-left-radius: 0;">Đặt lại</a>
                        <a @click="applyNotificationSettings" class="col button button-large link" @click="applyNotificationSettings" style="border-top-left-radius: 0; border-bottom-left-radius: 0; border-top-right-radius: 0;">Áp dụng</a>
                      </div>
                    </li>
                  </ul>
                </div>

                <div class="list media-list inset">
                  <ul>
                    <li class="item-link" @click="switchAccount">
                      <div class="item-content text-color-default">
                        <div class="item-media"><i class="icon f7-icons">arrow_left_right_circle_fill</i></div>
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title">Chuyển máy chủ</div>
                          </div>
                          <div class="item-subtitle">Chuyển sang máy chủ khác</div>
                        </div>
                      </div>
                    </li>
                    <li class="item-link text-color-red" @click="logout">
                      <div class="item-content">
                        <div class="item-media"><i class="icon f7-icons">lock_circle_fill</i></div>
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title">Đăng xuất</div>
                          </div>
                          <div class="item-subtitle">Thoát khỏi tài khoản hiện tại</div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                <div class="list media-list inset">
                  <ul>
                    <li class="item-link" @click="gotoAbout">
                      <div class="item-content text-color-gray">
                        <div class="item-media"><i class="icon f7-icons">info_circle_fill</i></div>
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title">Giới thiệu</div>
                          </div>
                          <div class="item-subtitle">Thông tin ứng dụng Smart-BOT</div>
                        </div>
                      </div>
                    </li>
                    <li class="item-link" @click="checkUpdate">
                      <div class="item-content text-color-gray"> 
                        <div class="item-media"><i class="icon f7-icons">tag_fill</i></div>
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title">Phiên bản {{appVersion}} {{mode}}</div>
                          </div>
                          <div class="item-subtitle text-color-{{updateStatus.color}}">{{text updateStatus}}</div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                
                <div class="block-title">Giới thiệu</div>
                <div class="block block-strong inset site-info">
                  <div>{{appName}}<br>
                  <div style="width: 50%; margin-bottom: 5px; margin-top: 3px; border-bottom: 1px solid var(--f7-chip-outline-border-color)"></div>
                  {{appCopyright}}<br>
                  Version {{appVersion}} core {{coreVersion}}</div>
                </div>
              </div>
            </div>
        `,
        style: /*css*/`
          .site-info {
            margin-top: 20px;
          }
          .site-logo {
            height: 100px;
            border-radius: 3px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            margin: 0 auto;
          }
          .site-title {
            text-align: center;
            font-weight: bold;
          }
          .site-subtitle {
            text-align: center;
          }
        `,
        data() {
          const version = $this.rootServices.version$.getValue();
          return {
            mode: $this.rootServices.env.mode,
            appName: $this.rootServices.env.name,
            appTitle: $this.rootServices.env.title,
            appCopyright: $this.rootServices.env.copyright,
            appVersion: version.appVersion,
            coreVersion: version.coreVersion,
            title: $this.title,
            profile: {},
            disabledLeftPannel: false && isPlatform('desktop'),
            backTitle: 'Back',
            updateStatus: {},
          };
        },
        methods: {
          gotoAbout(e) {
            const self: F7ComponentContextExtend = this;
            if($this.rootServices.device.platform == 'browser') {
              $this.rootServices.openExtenalLink('https://probox.one');
            } else {
              $this.rootServices.navigate('/about');
            }
          },
          openExternalLink(e) {
            const self: F7ComponentContextExtend = this;
            $this.rootServices.openExtenalLink($(e.currentTarget).data('link'));
          },
          applyNotificationSettings() {
            const self: F7ComponentContextExtend = this;
            self.$app.actions.create({
              buttons: [
                [
                  {
                    text: 'Cài đặt thông báo sẽ áp dụng cho tất cả nhiệm vụ !',
                    label: true,
                  },
                  {
                    text: 'Đồng ý',
                    color: 'red',
                    icon: '<i class="icon f7-icons">checkmark_alt_circle_fill</i>',
                    bold: true,
                    onClick() {
                      // self.notificationSettinsDialog && self.notificationSettinsDialog.close();
                      const fieldEles = self.$('.notificationSettings .field');
                      console.log(fieldEles);
                      const notificationSettings: { [key: string]: any } = {};
                      fieldEles.each((index, el) => {
                        console.log(index, el.checked);
                        notificationSettings[el.name] = (el.checked ? 1 : -1) * parseInt(el.value) > 0 ? true : false;
                      });
                      console.log(notificationSettings);
                      // if (self.$route.context && self.$route.context.chatRoom) {
                      // self.$route.context.chatRoom.updateMemberNotificationSettings(notificationSettings);
                      // }
                      // self.$setState({ notificationSettings: Object.keys(notificationSettings).length > 0 ? notificationSettings : null });
                      $this.rootServices.apiService.putPromise<{ NOTIFICATION_SETTINGS: NotificationSettings }>('/user/users/settings', {}, {
                        NOTIFICATION_SETTINGS: notificationSettings,
                      }).then(async settingsUpdate => {
                        const settings = await $this.rootServices.settings$.pipe(takeUntil($this.currentState.destroy$), filter(f => f !== null), take(1)).toPromise();
                        settings.NOTIFICATION_SETTINGS = settingsUpdate.NOTIFICATION_SETTINGS;
                        $this.rootServices.settings$.next(settings);
                        $this.commonService.showInfo('Đã cập nhật cài đặt thông báo');
                      }).catch(err => {
                        $this.commonService.showError(err);
                      });
                    }
                  }
                ],
                [{
                  text: 'Trở về',
                  icon: '<i class="icon f7-icons">arrow_down_circle_fill</i>',
                  // color: 'red',
                  bold: true,
                  onClick() {
                  }
                }],
              ] as any
            }).open();
          },
          resetNotificationSettings() {
            const self: F7ComponentContextExtend = this;
            // if (self.notificationSettinsDialog) {


            self.$app.actions.create({
              buttons: [
                [
                  {
                    text: 'Bạn có muốn khôi phục cài đặt thông báo ?',
                    label: true,
                  },
                  {
                    text: 'Đồng ý',
                    color: 'red',
                    icon: '<i class="icon f7-icons">checkmark_alt_circle_fill</i>',
                    bold: true,
                    onClick() {
                      const fieldEles = self.$('.notificationSettings .field');

                      // Reset to default
                      fieldEles.each((index, el) => {
                        self.$(el).prop('checked', parseInt(el.value) < 0 ? true : false);
                      });

                      // Get currnet settings
                      const notificationSettings: { [key: string]: any } = {};
                      fieldEles.each((index, el) => {
                        console.log(index, el.checked);
                        notificationSettings[el.name] = (el.checked ? 1 : -1) * parseInt(el.value) > 0 ? true : false;
                      });
                      // if (self.$route.context && self.$route.context.chatRoom) {
                      //   self.$route.context.chatRoom.updateMemberNotificationSettings(notificationSettings);
                      // }
                      // self.$setState({ notificationSettings: null });
                      // self.notificationSettinsDialog.close();
                      // }

                      // Put new setttings to service
                      $this.rootServices.apiService.putPromise<{ NOTIFICATION_SETTINGS: NotificationSettings }>('/user/users/settings', {}, {
                        NOTIFICATION_SETTINGS: notificationSettings,
                      }).then(async settingsUpdate => {
                        const settings = await $this.rootServices.settings$.pipe(takeUntil($this.currentState.destroy$), filter(f => f !== null), take(1)).toPromise();
                        settings.NOTIFICATION_SETTINGS = settingsUpdate.NOTIFICATION_SETTINGS;
                        $this.rootServices.settings$.next(settings);
                        $this.commonService.showInfo('Đã khôi phục lại cài đặt thông báo');
                      }).catch(err => {
                        $this.commonService.showError(err);
                      });
                    }
                  }
                ],
                [{
                  text: 'Trở về',
                  icon: '<i class="icon f7-icons">arrow_down_circle_fill</i>',
                  // color: 'red',
                  bold: true,
                  onClick() {
                  }
                }],
              ] as any
            }).open();
          },
          async refresh() {
            const self: F7ComponentContextExtend = this;
            return true;
          },
          logout(e: MouseEvent) {
            const self: F7ComponentContextExtend = this;
            if ($this.commonService.isFrameMode) {
              return true;
            }
            self.$app.dialog.confirm('Chú ý: Tài khoản này sẽ không thể đăng nhập lại sau khi đăng xuất, để đăng nhập lại bạn cần liên hệ với admin !', 'Đăng xuất', () => {
              $this.rootServices.authService.logout().then(status => {
                location.reload();
              });
            });
          },
          async switchAccount(e: MouseEvent) {
            if ($this.commonService.isFrameMode) {
              return true;
            }
            const self: F7ComponentContextExtend = this;

            // Show recent logins



            // Update current token to recent login
            await $this.rootServices.storage.get('recent_logins')
              .then((recentLogins: RecentLoginModel[]) => {
                recentLogins = recentLogins || [];
                const token = $this.rootServices.authService.token$.getValue().getPayload();
                let currentLogin = recentLogins.find(f => f.core === token.core && f.user == token.user);
                recentLogins = recentLogins.filter(f => f.core !== currentLogin.core || f.user !== currentLogin.user);
                const loginUser = $this.rootServices.authService.getUser();
                currentLogin.name = loginUser.name;
                currentLogin.avatar = loginUser.avatar && loginUser.avatar.payload && loginUser.avatar.payload.thumbnail;
                currentLogin.coreName = loginUser.core && loginUser.core.name;
                currentLogin.coreBanner = loginUser.core && loginUser.core.banner;
                currentLogin.token = token;
                recentLogins.unshift(currentLogin);
                $this.rootServices.storage.set('recent_logins', recentLogins);

                const router: Router.Router = this.$f7router;
                // self.$router.back('/home', { force: true });
              }).catch(err => {
                console.error(err);
                $this.commonService.showError(err);
              });


            await $this.rootServices.storage.remove($this.env.tokenStorageKey).then(() => {
            }).catch(err => {
              console.error(err);
              $this.commonService.showError(err);
            });

            await $this.rootServices.authService.suspendLogin().then(status => {
            }).catch(err => {
              console.error(err);
              $this.commonService.showError(err);
            });
            $this.rootServices.navigate('/recent-logins', { force: true });
          },
          gotoAccount() {
            const self: F7ComponentContextExtend = this;
            $this.rootServices.navigate('/account', { context: { backTitle: 'Cài đặt' } });
          },
          switchDarkTheme(e) {
            console.log(e);
            $this.appComponent.isDarkMode$.next(e.target.checked);
          },
          checkUpdate() {
            if ($this.commonService.isFrameMode) {
              return true;
            }
            const self: F7ComponentContextExtend = this;
            $this.rootServices.codePush.sync({ deploymentKey: $this.rootServices.getDeploymentKey() });
            self.$router.navigate('/update');
          },
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          pageInit(e, page: F7Page) {
            console.log('page init');
            const self: F7ComponentContext = this;
            $this.onComponentInit({ instance: self, destroy$: new Subject() }, 'main');

            $this.rootServices.systemSettings$.pipe(filter(f => !!f)).subscribe(systemConfigs => {
              self.$setState({
                logo: systemConfigs.LICENSE_INFO.register.mainLogo,
                backgroundLogoColor: systemConfigs.LICENSE_INFO.register.backgroundLogoColor,
              });
            });

            $this.rootServices.authService.user$.pipe(filter(profile => !!profile)).subscribe(async profile => {
              // profile.avatar.payload.thumbnail += ('?token=' + $this.rootServices.apiService.token.access_token); 
              // profile.Role = 'Member';
              let coreDomain = await $this.rootServices.authService.token$.pipe(filter(token => !!token), take(1)).toPromise().then(token => token.getPayload().api_url);
              if (coreDomain) {
                const url = new URL(coreDomain);
                coreDomain = url.host;
              }

              self.$setState({
                profile: {
                  Code: profile.id,
                  Name: profile.name,
                  ShortName: profile.shortName,
                  Avatar: profile.avatar && profile.avatar.payload && {
                    id: profile.avatar.id,
                    payload: {
                      thumbnail: profile.avatar.payload.thumbnail,
                    }
                  },
                  Role: profile.role || 'Undefined',
                  Core: coreDomain || 'Undefined',
                },
              });
            });

            const fieldEles = page.$el.find('.notificationSettings .field');
            $this.rootServices.settings$.subscribe(settings => {
              if (settings) {
                const userSettings = settings;
                const currentNotificationSettings = userSettings && userSettings.NOTIFICATION_SETTINGS || {};
                fieldEles.each((index, el) => {
                  console.log(index, el.checked);
                  self.$(el).prop('checked', parseInt(el.value) * (currentNotificationSettings[el.name] ? 1 : -1) > 0 ? true : false);
                });
              }
            });

            $this.resetUserNotificationSetting();
            $this.rootServices.platform.resume.subscribe(async status => {
              $this.rootServices.syncInfo().then(settings => {
                $this.resetUserNotificationSetting();
              });
            });

            $this.rootServices.version$.subscribe(version => {
              self.$setState({
                appVersion: version.appVersion,
                coreVersion: version.coreVersion,
              });
            });

            $this.rootServices.updateStatus$.subscribe(async status => {
              const pendingPkg = await $this.rootServices.codePush.getPendingPackage();
              console.log('pendding package: ', pendingPkg);
              const pendingVersion = await $this.rootServices.getVersion(pendingPkg);
              console.log('pendding version: ', pendingVersion);
              self.$setState({
                updateStatus: {
                  0: { id: 0, text: 'Đã cập nhật', color: 'green' },
                  1: { id: 1, text: 'Vừa mới cập nhật v' + pendingVersion.appVersion + ' (yêu cầu mở lại app)', color: 'orange' },
                  3: { id: 3, text: 'Lỗi cập nhật !', color: 'red' },
                  7: { id: 7, text: 'Đang tải cập nhật...', color: 'blue' },
                  8: { id: 8, text: 'Đang cập nhật...', color: 'pink' },
                }[status] || { id: 1, text: 'Đã cập nhật', color: 'green' },
              });
            });


            // $this.rootServices.apiService.getPromise<ProfileModel>('/user/users/getProfile').then(profile => {
            //   profile.Avatar.payload.thumbnail += ('?token=' + $this.rootServices.apiService.token.access_token); 
            //   profile.Role = 'Member';
            //   self.$setState({
            //     profile: profile,
            //   });
            // });

          },
          // pageBeforeIn(e, page) {
          //     console.log('page before in');
          // },
          // pageAfterIn(e, page) {
          //     console.log('page after in');
          // },
          // pageBeforeOut(e, page) {
          //     console.log('page before out');
          // },
          // pageAfterOut(e, page) {
          //     console.log('page after out');
          // },
          // pageBeforeUnmount(e, page) {
          //     console.log('page before unmount');
          // },
          // pageBeforeRemove(e, page) {
          //     console.log('page before remove');
          // },
        },
      },
    };
  }
}
