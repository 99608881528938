import { ProductModel } from '../../../model/product.model';
import { F7ComponentContext, F7Page } from '../../../types/framework7-types';
import { Router } from 'framework7/build/core/modules/router/router';
import { BaseComponent, ComponentState } from '../../../lib/base-component';
import { RootServices } from '../../../services/root.services';
import { ChatRoom } from '../../../lib/nam-chat/chat-room';
import { CommonService } from '../../../services/common.service';
import { TaskReminderModel } from '../../../model/task.model';
import { BehaviorSubject } from 'rxjs';
import { Dialog } from 'framework7/build/core/components/dialog/dialog';
import { Chart, ChartOptions, registerables } from 'chart.js';
import { AccMasterBookModel } from '../../../model/accounting.model';
// import * as $ from 'jquery';
declare const $: any;

// declare const $: any;
export interface ComponentStateExtend extends ComponentState {
  [key: string]: any;
  instance: F7ComponentContextExtend,
  // voucher?: CommercePosOrderModel;
  // vouchers?: CommercePosOrderModel[];
  reportQuery?: { type: string, range: Date[] },
  reportForm?: { type?: any, range?: any },
  reportTypeList?: { id: string, text: string, reportType?: string, range: Date[] }[],
  reportType?: string,
}

export class F7ComponentContextExtend extends F7ComponentContext {
  // messagebar?: F7Messagebar;
  // messages?: Messages.Messages;
  // images?: string[];
  responseInProgress?: boolean;
  // answers?: string[];
  // people?: { name?: string, avatar?: string }[];
  // hideToolbar?: () => void;
  // sheetToggle?: () => void;
  // deleteAttachment?: (e: MouseEvent, index: number) => void;
  // handleAttachment?: (e: MouseEvent) => void;
  // checkAttachments?: () => void;
  // sendMessage?: () => void;

  detailDialog?: Dialog.Dialog;

  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, reminder?: TaskReminderModel, chatRoom?: ChatRoom, reminderList$?: BehaviorSubject<TaskReminderModel[]> } };
}

/** Component group manager */
export class CollaboratorDashboardComponent extends BaseComponent<ComponentStateExtend> {
  // states: { [key: string]: State } = {};
  namespace = 'collaborator-dashboard';
  title: 'Collaborator Dashboard'
  stateMap = {
    NOTJUSTAPPROVED: {
      label: 'Chưa duyệt',
      status: 'warning',
    },
    APPROVED: {
      label: 'Đã duyệt',
      status: 'success',
    },
  };

  masterBook: AccMasterBookModel = null;
  costAndRevenueStatisticsData: {};
  reportDate = new Date();

  dateReportList = [
    { id: 'DAY', text: 'Phân tích theo ngày', range: [new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0, 0), new Date(new Date().getFullYear(), new Date().getMonth(), 31, 23, 59, 59)] },
    { id: 'MONTH', text: 'Phân tích theo tháng', range: [new Date(new Date().getFullYear(), 0, 1), new Date(new Date().getFullYear(), 11, 31)] },
    { id: 'DAYOFWEEK', text: 'Phân tích theo tuần', range: [this.getUpcomingMonday(), this.getUpcomingSunday()] },
    { id: 'HOUR', text: 'Phân tích theo giờ', range: [new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0), new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59)] },
  ];
  chartOptions: any;
  orderOptions: ChartOptions;
  revenueOptions: any;
  chartColor: any;
  chartVariables: any;

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
  ) {
    super(rootServices);
    // // console.log('Click here to open ts file');
    Chart.register(...registerables);

    this.chartColor = {};
    this.chartVariables = {
      textColor: '#000',
      axisLineColor: '#000'
    };
    const $this = this;
    this.chartOptions = {
      responsive: true,
      // bezierCurve: false,
      maintainAspectRatio: false,
      // legend: {
      //   position: 'bottom',
      //   labels: {
      //     fontColor: this.chartVariables.textColor,
      //   },
      // },
      // elements: {
      //   line: {
      //     tension: 0.3 // disables bezier curves
      //   }
      // },
      hover: {
        mode: 'index',
      },
      scales: {
        // x: {
        //   stacked: true,
        // },
        // y: {
        //   stacked: true
        // },
        x:
        {
          display: true,
          // scaleLabel: {
          //   display: true,
          //   labelString: 'Thời gian',
          // },
          // gridLines: {
          //   display: true,
          //   color: this.chartVariables.axisLineColor,
          // },
          ticks: {
            // fontColor: this.chartVariables.textColor
          },
          stacked: true,
        },
        y: {
          display: true,
          // scaleLabel: {
          //   display: true,
          //   labelString: 'Giá trị',
          // },
          // gridLines: {
          //   display: true,
          //   color: this.chartVariables.axisLineColor,
          // },
          ticks: {
            // fontColor: this.chartVariables.textColor,
            callback: (value, index, values) => {
              return $this.rootServices.currencyPipe.transform(value || 0, 'VND');
            }
          },
          stacked: true,
        },

        // y: {
        //   beginAtZero: true
        // }
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            return this.rootServices.currencyPipe.transform(tooltipItem.yLabel, 'VND');
          }
        }
      }
    };

    this.orderOptions = {
      responsive: true,
      // bezierCurve: false,
      maintainAspectRatio: false,
      // legend: {
      //   position: 'bottom',
      //   labels: {
      //     fontColor: this.chartVariables.textColor,
      //   },
      // },
      // elements: {
      //   line: {
      //     tension: 0.3 // disables bezier curves
      //   }
      // },
      hover: {
        mode: 'index',
      },
      scales: {
        // x: {
        //   stacked: true,
        // },
        // y: {
        //   stacked: true
        // },
        x: {
          display: true,
          // scaleLabel: {
          //   display: true,
          //   labelString: 'Thời gian',
          // },
          // gridLines: {
          //   display: true,
          //   color: this.chartVariables.axisLineColor,
          // },
          ticks: {
            // fontColor: this.chartVariables.textColor
          },
          stacked: true,
        },
        y:
        {
          display: true,
          // scaleLabel: {
          //   display: true,
          //   labelString: 'Đơn hàng',
          // },
          // gridLines: {
          //   display: true,
          //   color: this.chartVariables.axisLineColor,
          // },
          ticks: {
            // fontColor: this.chartVariables.textColor,
            callback: (value, index, values) => {
              return this.rootServices.decimalPipe.transform(value || 0, '1.0-0')
            }
          },
          stacked: true,
        },
        // y: {
        //   beginAtZero: true
        // }
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: (tooltipItem) => {
              return tooltipItem.dataset.label + ': ' + $this.rootServices.decimalPipe.transform(tooltipItem.parsed.y, '1.0-0') + ' đơn';
            }
          }
        }
      }
    };

    this.revenueOptions = {
      responsive: true,
      // bezierCurve: false,
      maintainAspectRatio: false,
      // legend: {
      //   position: 'bottom',
      //   labels: {
      //     fontColor: this.chartVariables.textColor,
      //   },
      // },
      // elements: {
      //   line: {
      //     tension: 0.3 // disables bezier curves
      //   }
      // },
      hover: {
        mode: 'index',
      },
      scales: {
        // x: {
        //   stacked: true,
        // },
        // y: {
        //   stacked: true
        // },
        x: {
          display: true,
          // scaleLabel: {
          //   display: true,
          //   labelString: 'Thời gian',
          // },
          // gridLines: {
          //   display: true,
          //   color: this.chartVariables.axisLineColor,
          // },
          ticks: {
            // fontColor: this.chartVariables.textColor
          },
          stacked: true,
        },

        y:
        {
          display: true,
          // scaleLabel: {
          //   display: true,
          //   labelString: 'Giá trị',
          // },
          // gridLines: {
          //   display: true,
          //   color: this.chartVariables.axisLineColor,
          // },
          ticks: {
            // fontColor: this.chartVariables.textColor,
            callback: (value, index, values) => {
              return this.rootServices.currencyPipe.transform(value || 0, 'VND')
            }
          },
          stacked: true,
        },

        // y: {
        //   beginAtZero: true
        // }
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: (tooltipItem) => {
              return tooltipItem.dataset.label + ': ' + $this.rootServices.decimalPipe.transform(tooltipItem.parsed.y, '1.0-0');
            }
          }
        }
      }
    };
  }

  getUpcomingMonday() {
    const date = new Date();
    const today = date.getDate();
    const dayOfTheWeek = date.getDay();
    const newDate = date.setDate(today - dayOfTheWeek + 1);
    const result = new Date(newDate);
    result.setHours(0);
    result.setMinutes(0);
    result.setSeconds(0);
    return result;
  }
  getUpcomingSunday() {
    const date = new Date();
    const today = date.getDate();
    const dayOfTheWeek = date.getDay();
    const newDate = date.setDate(today - dayOfTheWeek + 7);
    const result = new Date(newDate);
    result.setHours(23);
    result.setMinutes(59);
    result.setSeconds(59);
    return result;
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  prepareItem(p: ProductModel) {
    p.DateOfOrderText = this.rootServices.datePipe.transform(p.DateOfOrder, 'short');
    p.StateLabel = this.stateMap[p.State]?.label || 'Unknow';
    p.StateStatus = this.stateMap[p.State]?.status || 'primary';
    return p;
  }

  async getList(params: any) {
    // console.log(self.searchBar);
    // return this.rootServices.apiService.getPromise<any[]>('/purchase/order-vouchers', {
    //   includeCreator: true,
    //   includeRelativeVouchers: true,
    //   sort_Id: 'desc',
    //   limit: 10,
    //   offset: 0,
    //   ...params,
    // });
    return [];
  }

  weekDayMap = {
    1: 'CN',
    2: 'T2',
    3: 'T3',
    4: 'T4',
    5: 'T5',
    6: 'T6',
    7: 'T7',
  };

  makeStaticLabel(item: any, reportType: string) {
    if (reportType === 'MONTH') {
      return (item['Month']).toString().padStart(2, "0") + '/' + (item['Year']).toString().padStart(2, "0");
    }
    if (reportType === 'DAY') {
      return (item['Day']).toString().padStart(2, "0") + '/' + (item['Month']).toString().padStart(2, "0");
    }
    if (reportType === 'HOUR') {
      return (item['Hour']).toString().padStart(2, "0");
    }
    // return (item['DayOfWeek']).toString().padStart(2, "0");
    return this.weekDayMap[item['DayOfWeek']] + ':' + (item['Day']).toString().padStart(2, "0") + '/' + (item['Month']).toString().padStart(2, "0");
  }

  makeTimeline(item: any, reportType: string) {
    if (reportType === 'MONTH') {
      return (item['Year']).toString().padStart(2, "0") + '/' + (item['Month']).toString().padStart(2, "0");
    }
    if (reportType === 'DAY') {
      return item['Year'] + '/' + (item['Month']).toString().padStart(2, "0") + '/' + (item['Day']).toString().padStart(2, "0");
    }
    if (reportType === 'HOUR') {
      return (item['Hour']).toString().padStart(2, "0");
    }
    return item['Year'] + '/' + (item['Month']).toString().padStart(2, "0") + '/' + (item['Day']).toString().padStart(2, "0");
  }

  // On component init
  async onComponentInit(state: ComponentStateExtend, index: string, asCase?: string, f7Page?: F7Page) {
    const currentState = await super.onComponentInit(state, index, asCase, f7Page);
    currentState.reportType = 'HOUR';
    currentState.reportForm = {};
    // const ctx = $('#myChart');

    // Load statistic data for accounting activity
    await this.rootServices.apiService.getPromise<AccMasterBookModel[]>('/accounting/master-books/current', {}).then(rs => {
      this.masterBook = rs[0];
      const current = new Date();
      const previousMonth = new Date(current.getTime() - 31 * 24 * 60 * 60 * 1000);
      let previousYear = new Date();
      previousYear.setFullYear(previousYear.getFullYear() - 1);
      let fromDate = new Date(this.masterBook.DateOfBeginning);
      currentState.reportTypeList = [
        { id: 'DAY', reportType: 'DAY', text: '30 ngày gần nhất', range: [new Date(previousMonth.getFullYear(), previousMonth.getMonth(), previousMonth.getDate(), 0, 0, 0), current] },
        { id: 'MONTH', reportType: 'MONTH', text: '12 tháng gần nhất', range: [new Date(previousYear.getFullYear(), previousYear.getMonth(), previousYear.getDate()), current] },
        { id: 'FROMOPENED', reportType: 'MONTH', text: 'Đầu kỳ đến hiện tại', range: [new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate()), current] },
        { id: 'DAYOFWEEK', reportType: 'DAYOFWEEK', text: 'Các ngày trong tuần', range: [this.getUpcomingMonday(), this.getUpcomingSunday()] },
        { id: 'HOUR', reportType: 'HOUR', text: '24 giờ trong ngày', range: [new Date(current.getFullYear(), current.getMonth(), current.getDate(), 0, 0, 0), new Date(current.getFullYear(), current.getMonth(), current.getDate(), 23, 59, 59)] },
      ];
      currentState.instance.$setState({
        reportTypeList: currentState.reportTypeList
      });
    });


    currentState.reportQuery = { type: currentState.reportType, range: currentState.reportTypeList.find(f => f.id == currentState.reportType)?.range };

    const reportRanageControl = currentState.reportForm.range = currentState.instance.$f7.calendar.create({
      inputEl: '[name="ReportRange"]',
      dateFormat: 'd/m/yyyy',
      rangePicker: true,
      closeOnSelect: true,
      on: {
        change(calendar, value: Date[]) {
          console.log(calendar, value);
          if (value && value.length == 2) {
            currentState.reportQuery.range = value;
            currentState.instance.refresh();
          }
        },
      }
    });
    const reportTypeControl = currentState.reportForm.type = currentState.instance.$f7.smartSelect.get($(f7Page.el).find('[name="ReportType"]').closest('.smart-select') as any);
    reportTypeControl.on('change' as any, (smartSelect, value) => {
      console.log(smartSelect, value);
      currentState.reportQuery.type = value;
      currentState.reportQuery.range = currentState.reportTypeList.find(f => f.id == currentState.reportQuery.type)?.range;
      currentState.reportForm.range.setValue(currentState.reportQuery.range);
    });

    setTimeout(() => {
      currentState.reportForm.range.setValue(currentState.reportQuery.range);
      currentState.reportForm.type.setValue(currentState.reportType);
    }, 300);

    return currentState;
  }

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: 'collaborator-dashboard',
      path: '/collaborator/dashboard',
      component: {
        template: /*html*/`
            <div class="page ${this.namespace}" data-name="${this.namespace}">
              <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a class="link back {{textColorClass}}">
                      <i class="icon icon-back"></i>
                      <span class="if-not-md {{textColorClass}}">{{js "this.backTitle || 'Back'"}}</span>
                    </a>
                  </div>
                  <div class="title">CTV Dashboard</div>
                  <div class="right">
                    <!-- Link to enable searchbar -->
                    <a class="link icon-only searchbar-enable {{textColorClass}}" data-searchbar=".${this.namespace}-searchbar">
                      <i class="icon f7-icons if-not-md">search</i>
                      <i class="icon material-icons md-only">search</i>
                    </a>
                  </div>
                  <!-- Searchbar is a direct child of "navbar-inner" -->
                  <form class="searchbar ${this.namespace}-searchbar searchbar-expandable">
                    <div class="searchbar-inner">
                      <div class="searchbar-input-wrap">
                        <input type="search" placeholder="Search" />
                        <i class="searchbar-icon"></i><span class="input-clear-button"></span>
                      </div>
                      <span class="searchbar-disable-button {{textColorClass}}">Hủy</span>
                    </div>
                  </form>
                </div>
              </div>
              <div class="page-content ptr-content" @ptr:refresh="refresh" data-ptr-distance="80">
                <div class="ptr-preloader">
                  <div class="preloader"></div>
                  <div class="ptr-arrow"></div>
                </div>
                <div class="searchbar-backdrop product-list-backdrop"></div>

                <!--
                <div class="block" style="margin-top: 1rem; margin-buttom: 0.3rem">
                  <div class="row">
                    <div class="col-50">
                      <div class="card">
                        <div class="card-content card-content-padding" style="color: var(--ion-color-warning);">
                          <div class="card-content-wrap">
                            <div class="icon-wrap">
                              <button class="col button button-large button-fill color-orange">
                                <i class="icon f7-icons">money_dollar_circle</i>
                              </button>
                            </div>
                            <div class="label-wrap">
                              <div class="label-text">Chiết khấu</div>
                              <div class="label-value" style="margin-top: -3px;">Tạm tính: {{summaryReportData.TmpCommission}}</div>
                              <div class="label-value" style="margin-top: -3px;">Đã chốt: {{summaryReportData.Commission}}</div>
                              <div class="label-value" style="margin-top: -3px;">Thưởng: {{summaryReportData.Award}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-50">
                      <div class="card">
                        <div class="card-content card-content-padding" style="color: var(--ion-color-primary);">
                          <div class="card-content-wrap">
                            <div class="icon-wrap">
                              <button class="col button button-large button-fill color-blue">
                                <i class="icon f7-icons">chart_bar_circle</i>
                              </button>
                            </div>
                            <div class="label-wrap">
                              <div class="label-text">Doanh thu</div>
                              <div class="label-value" style="margin-top: -3px;">DT: {{summaryReportData.Revenues}}</div>
                              <div class="label-value" style="margin-top: -3px;">CP: {{summaryReportData.Cost}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-50">
                      <div class="card">
                        <div class="card-content card-content-padding" style="color: var(--ion-color-danger);">
                          <div class="card-content-wrap">
                            <div class="icon-wrap">
                              <button class="col button button-large button-fill color-red">
                                <i class="icon f7-icons">creditcard</i>
                              </button>
                            </div>
                            <div class="label-wrap">
                              <div class="label-text">Công nợ</div>
                              <div class="label-value" style="margin-top: -3px;">PTH: {{summaryReportData.CustomerReceivableDebt}}</div>
                              <div class="label-value" style="margin-top: -3px;">PTR: {{summaryReportData.LiabilitiesDebt}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-50">
                      <div class="card">
                        <div class="card-content card-content-padding" style="color: var(--ion-color-success);">
                          <div class="card-content-wrap">
                            <div class="icon-wrap">
                              <button class="col button button-large button-fill color-green">
                                <i class="icon f7-icons">graph_circle</i>
                              </button>
                            </div>
                            <div class="label-wrap">
                              <div class="label-text">Nhân lực</div>
                              <div class="label-value">QL CTV: {{summaryReportData.NumOfSales}}</div>
                              <div class="label-value">CTV: {{summaryReportData.NumOfPublishers}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                -->

                <div class="block block-strong inset" style="padding: 0; overflow: hidden">
                  <div class="list inline-labels no-hairlines" style="margin: 0;">
                    <ul>
                      <li>
                        <a class="item-link smart-select smart-select-init" data-open-in="sheet" data-close-on-select="true">
                            <select name="ReportType">
                              {{#each reportTypeList}}
                              <option value="{{id}}">{{text}}</option>
                              {{/each}}
                            </select>
                            <div class="item-content">
                              <div class="item-inner">
                                <div class="item-title">Báo cáo theo</div>
                              </div>
                            </div>
                          </a>
                      </li>
                      <li>
                        <div class="item-content item-input">
                          <div class="item-inner">
                            <div class="item-title item-label">Phạm vi</div>
                            <div class="item-input-wrap">
                              <input name="ReportRange" type="text" placeholder="Ngày báo cáo" readonly="readonly" id="reportDate" @change="onReportDateChange" style="text-align: right;"/>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="tabs-swipeable-wrap" style="height: initial;">
                  <div class="tabs">
                    <div id="acc-chart-tab-1" class="page-content tab tab-active" style="padding-top: 0px; padding-bottom: 0">
                      <div class="block-title" style="margin-top: 0.5rem">Đơn hàng<a class="button button-small button-fill tab-link" href="#acc-chart-tab-2" style="float: right; font-size: 0.7rem; font-weight: bold">Next»</a></div>
                      <div class="block block-strong inset chart-wrap">
                      <canvas id="orderActivityChart"></canvas>
                      </div>
                      <div class="block-title" style="margin-top: 0.5rem">Đơn hàng {{previousLabel}}</div>
                      <div class="block block-strong inset chart-wrap">
                        <canvas id="orderActivityChartPrevious"></canvas>
                      </div>
                    </div>
                    <div id="acc-chart-tab-1" class="page-content tab" style="padding-top: 0px; padding-bottom: 0">
                      <div class="block-title" style="margin-top: 0.5rem">Hoạt động kinh doanh<a class="button button-small button-fill tab-link" href="#acc-chart-tab-3" style="float: right; font-size: 0.7rem; font-weight: bold">Next»</a></div>
                      <div class="block block-strong inset chart-wrap">
                        <canvas id="accountingActivityChart"></canvas>
                      </div>
                      <div class="block-title" style="margin-top: 0.5rem">Hoạt động kinh doanh {{previousLabel}}</div>
                      <div class="block block-strong inset chart-wrap">
                        <canvas id="accountingActivityChartPrevious"></canvas>
                      </div>
                    </div>
                    <div id="acc-chart-tab-2" class="page-content tab" style="padding-top: 0px; padding-bottom: 0">
                      <div class="block-title" style="margin-top: 0.5rem">CTV Bán hàng<a class="button button-small button-fill tab-link" href="#acc-chart-tab-1" style="float: right; font-size: 0.7rem; font-weight: bold">Quay lại</a></div>
                      <div class="block block-strong inset chart-wrap">
                        <canvas id="publisherChart"></canvas>
                      </div>
                    </div>
                  </div>
                </div>

                

                <div class="block block inset" style="padding: 0rem; overflow: hidden; margin-bottom: 0">
                </div>
                <div class="tabs-swipeable-wrap" style="height: initial;">
                  <div class="tabs">
                    <div id="top-tab-1" class="page-content tab tab-active" style="padding-top: 0px">
                      <div class="block-title">Top CTV online<a class="button button-small button-fill tab-link" href="#top-tab-2" style="float: right; font-size: 0.7rem; font-weight: bold">next»</a></div>
                      <div class="list media-list inset">
                        <ul>
                          {{#each topOnlinePublishers}}
                          <li data-name="remove-chat-room-member" data-id="{{Contact}}" @click="gotoProfile">
                            <a class="item-content item-link">
                              <div class="item-media">
                                {{#js_if "this.EmployeeAvatar"}}
                                <div class="list-item-img" style="width: 44px; height: 44px; background-image: url({{js "this.EmployeeAvatar &&  this.EmployeeAvatar.SmallImage"}});"></div>
                                {{else}}
                                <div class="list-item-img" style="width: 44px; height: 44px">
                                  <i style="font-size: 16px" class="f7-icons">person_fill</i>
                                </div>
                                {{/js_if}}
                              </div>
                              <div class="item-inner">
                                <div class="item-title-row">
                                  <div class="item-title">{{Name}}</div>
                                  <div class="item-after">{{#if RefIcon}}<img style="height: 12px;" src="{{RefIcon}}">{{/if}}</div>
                                </div>
                                <div class="item-subtitle" style="color: var(--ion-color-primary)">Lần cuối: {{date LastOnline format="short"}}</div>
                              </div>
                            </a>
                          </li>
                          {{/each}}
                        </ul>
                      </div>
                    </div>  
                    <div id="top-tab-2" class="page-content tab" style="padding-top: 0px">
                      <div class="block-title">Top CTV vừa có đơn<a class="button button-small button-fill tab-link" href="#top-tab-3" style="float: right; font-size: 0.7rem; font-weight: bold">next»</a></div>
                      <div class="list media-list inset">
                        <ul>
                          {{#each topOrderedPublishers}}
                          <li data-name="remove-chat-room-member" data-id="{{Contact}}" @click="gotoProfile">
                            <a class="item-content item-link">
                              <div class="item-media">
                                {{#js_if "this.EmployeeAvatar"}}
                                <div class="list-item-img" style="width: 44px; height: 44px; background-image: url({{js "this.EmployeeAvatar &&  this.EmployeeAvatar.SmallImage"}});"></div>
                                {{else}}
                                <div class="list-item-img" style="width: 44px; height: 44px">
                                  <i style="font-size: 16px" class="f7-icons">person_fill</i>
                                </div>
                                {{/js_if}}
                              </div>
                              <div class="item-inner">
                                <div class="item-title-row">
                                  <div class="item-title">{{Name}}</div>
                                  <div class="item-after">{{#if RefIcon}}<img style="height: 12px;" src="{{RefIcon}}">{{/if}}</div>
                                </div>
                                <div class="item-subtitle" style="color: var(--ion-color-primary)">Đơn cuối: {{date LastOrderDate format="short"}}</div>
                              </div>
                            </a>
                          </li>
                          {{/each}}
                        </ul>
                      </div>
                    </div>  
                    <div id="top-tab-3" class="page-content tab" style="padding-top: 0px">
                      <div class="block-title">Top CTV vừa gia nhập<a class="button button-small button-fill tab-link" href="#top-tab-4" style="float: right; font-size: 0.7rem; font-weight: bold">next»</a></div>
                      <div class="list media-list inset">
                        <ul>
                          {{#each topAssignedPublishers}}
                          <li data-name="remove-chat-room-member" data-id="{{Contact}}" @click="gotoProfile">
                            <a class="item-content item-link">
                              <div class="item-media">
                                {{#js_if "this.EmployeeAvatar"}}
                                <div class="list-item-img" style="width: 44px; height: 44px; background-image: url({{js "this.EmployeeAvatar &&  this.EmployeeAvatar.SmallImage"}});"></div>
                                {{else}}
                                <div class="list-item-img" style="width: 44px; height: 44px">
                                  <i style="font-size: 16px" class="f7-icons">person_fill</i>
                                </div>
                                {{/js_if}}
                              </div>
                              <div class="item-inner">
                                <div class="item-title-row">
                                  <div class="item-title">{{Name}}</div>
                                  <div class="item-after">{{#if RefIcon}}<img style="height: 12px;" src="{{RefIcon}}">{{/if}}</div>
                                </div>
                                <div class="item-subtitle" style="color: var(--ion-color-primary)">Gia nhập: {{date Assigned format="short"}}</div>
                              </div>
                            </a>
                          </li>
                          {{/each}}
                        </ul>
                      </div>
                    </div>  
                    <div id="top-tab-4" class="page-content tab" style="padding-top: 0px">
                      <div class="block-title">Top CTV không có đơn<a class="button button-small button-fill tab-link" href="#top-tab-5" style="float: right; font-size: 0.7rem; font-weight: bold">next»</a></div>
                      <div class="list media-list inset">
                        <ul>
                          {{#each topNotOtderedPublishers}}
                          <li data-name="remove-chat-room-member" data-id="{{Contact}}" @click="gotoProfile">
                            <a class="item-content item-link">
                              <div class="item-media">
                                {{#js_if "this.EmployeeAvatar"}}
                                <div class="list-item-img" style="width: 44px; height: 44px; background-image: url({{js "this.EmployeeAvatar &&  this.EmployeeAvatar.SmallImage"}});"></div>
                                {{else}}
                                <div class="list-item-img" style="width: 44px; height: 44px">
                                  <i style="font-size: 16px" class="f7-icons">person_fill</i>
                                </div>
                                {{/js_if}}
                              </div>
                              <div class="item-inner">
                                <div class="item-title-row">
                                  <div class="item-title">{{Name}}</div>
                                  <div class="item-after">{{#if RefIcon}}<img style="height: 12px;" src="{{RefIcon}}">{{/if}}</div>
                                </div>
                                <div class="item-subtitle" style="color: var(--ion-color-primary)">Đơn cuối: {{date LastOrderDate format="short"}}</div>
                              </div>
                            </a>
                          </li>
                          {{/each}}
                        </ul>
                      </div>
                    </div>  
                    <div id="top-tab-5" class="page-content tab" style="padding-top: 0px">
                      <div class="block-title">Top nhân viên theo doanh số<a class="button button-small button-fill tab-link" href="#top-tab-6" style="float: right; font-size: 0.7rem; font-weight: bold">next»</a></div>
                      <div class="list media-list inset">
                        <ul>
                          {{#each topEmployeeList}}
                          <li data-name="remove-chat-room-member" data-id="{{Employee}}" @click="gotoProfile">
                            <a class="item-content item-link">
                              <div class="item-media">
                                {{#js_if "this.EmployeeAvatar"}}
                                <div class="list-item-img" style="width: 44px; height: 44px; background-image: url({{js "this.EmployeeAvatar &&  this.EmployeeAvatar.SmallImage"}});"></div>
                                {{else}}
                                <div class="list-item-img" style="width: 44px; height: 44px">
                                  <i style="font-size: 16px" class="f7-icons">person_fill</i>
                                </div>
                                {{/js_if}}
                              </div>
                              <div class="item-inner">
                                <div class="item-title-row">
                                  <div class="item-title">{{EmployeeName}}</div>
                                  <div class="item-after">{{#if RefIcon}}<img style="height: 12px;" src="{{RefIcon}}">{{/if}}</div>
                                </div>
                                <div class="item-subtitle" style="color: var(--ion-color-primary)">{{Revenus}}/{{GenerateNumOfVouchers}} đơn</div>
                              </div>
                            </a>
                          </li>
                          {{/each}}
                        </ul>
                      </div>
                    </div>  
                    <div id="top-tab-6" class="page-content tab" style="padding-top: 0px">
                      <div class="block-title">Top hàng hóa theo doanh số<a class="button button-small button-fill tab-link" href="#top-tab-1" style="float: right; font-size: 0.7rem; font-weight: bold">Quay lại</a></div>
                      <div class="list media-list inset">
                        <ul>
                          {{#each topGoodsList}}
                          <li data-name="remove-chat-room-member" data-id="{{Employee}}" @click="gotoProfile">
                            <a class="item-content item-link">
                              <div class="item-media">
                                {{#js_if "this.Thumbnail"}}
                                <div class="list-item-img" style="width: 44px; height: 44px; background-image: url({{js "this.Thumbnail &&  this.Thumbnail.SmallImage"}});"></div>
                                {{else}}
                                <div class="list-item-img" style="width: 44px; height: 44px">
                                  <i style="font-size: 16px" class="f7-icons">person_fill</i>
                                </div>
                                {{/js_if}}
                              </div>
                              <div class="item-inner">
                                <div class="item-title-row">
                                  <div class="item-title">{{Description}}</div>
                                  <div class="item-after">{{#if RefIcon}}<img style="height: 12px;" src="{{RefIcon}}">{{/if}}</div>
                                </div>
                                <div class="item-subtitle" style="color: var(--ion-color-primary)">{{Revenus}}/{{GenerateNumOfVouchers}} đơn</div>
                              </div>
                            </a>
                          </li>
                          {{/each}}
                        </ul>
                      </div>
                    </div>  
                  </div>
                </div>
              
              </div>
            </div>
        `,
        style: /*css*/`
          .${this.namespace}-list .find-order {
            font-size: 16px;
            font-weight: bold;
          }
          .${this.namespace}-list .action-bar {
            color: var(--f7-block-strong-text-color);
            padding-top: var(--f7-block-padding-vertical);
            padding-bottom: var(--f7-block-padding-vertical);
            background-color: var(--f7-block-strong-bg-color);
          }

          .page.${this.namespace} .card {
            margin-left: 0; 
            margin-right: 0
          }
          .page.${this.namespace} .card .card-content {
            padding: 0.5rem;
          }
          .page.${this.namespace} .card .card-content-wrap {
            display: flex; flex-direction: row
          }
          .page.${this.namespace} .card .icon-wrap {
            padding-right: 0.5rem;
          }
          .page.${this.namespace} .card .icon-wrap button {
            width: 3rem; height: 3rem
          }
          .page.${this.namespace} .card .label-wrap {

          }
          .page.${this.namespace} .card .label-wrap {
            display: flex; flex-direction: column
          }
          .page.${this.namespace} .card .label-wrap .label-text {
            font-weight: bold; font-size: 1rem
          }
          .page.${this.namespace} .card .label-wrap .label-value {
            font-weight: bold;
            font-size: 0.6rem;
            white-space: nowrap;
          }
          .page.${this.namespace} .chart-wrap {
            margin-bottom: 0;
            height: calc(100vh - 310px);
            min-height: 300px;
          }

        `,
        data() {
          return {
            title: $this.title,
            // reminder: {
            //   No: '234234',
            // },
            priceReport: { Details: [] },
            summaryReportData: {

            },
            reportDate: $this.reportDate.getDate() + '/' + ($this.reportDate.getMonth() + 1) + '/' + $this.reportDate.getFullYear(),
            dateReportList: [],
            reportType: 'HOUR',
          };
        },
        methods: {
          onReportDateChange(e) {
            // const self: F7ComponentContextExtend = this;
            // console.log(e.currentTarget.value);
            // const inputDate = e.currentTarget.value.split('/');
            // $this.reportDate = new Date(inputDate[2], inputDate[1] - 1, inputDate[0]);
            // $this.commonService.showPreloader();
            // self.refresh().then(rs => {
            //   $this.commonService.hidePreloader();
            // }).catch(err => {
            //   $this.commonService.hidePreloader();
            // });
          },
          gotoProfile(e) {
          },
          // Price report
          openVoucher(e, id?: string) {
            const self: F7ComponentContextExtend = this;
            id = id || self.$(e.target).closest('li').data('id') || 'new';

            // if ($this.commonService.frameSocket.isFrameMode && id && id !== 'new') {
            //   $this.commonService.frameSocket.emit('request-open-voucher', { id: id, type: 'PRICEREPORT' });
            // } else {
            $this.rootServices.navigate('/purchase/order/' + id, {
              context: {
                backTitle: $this.title,
                textColorClass: self.textColorClass,
                // memberInfo: $this.currentState.chatRoom.memberList$.getValue().find(f => f.id === id),
                // chatRoom: self.$route.context.chatRoom,
                // reminderList$: self.reminderList$,
              }
            });
            // }
          },
          goto(e) {
            const self: F7ComponentContextExtend = this;
            const url = self.$(e.target).closest('.goto').data('url');
            $this.rootServices.navigate(url);
          },
          chooseOne(e) {
            const self: F7ComponentContextExtend = this;
            const id = self.$(e.target).closest('li').data('id');
            self.$route.context['onChoose'] && self.$route.context['onChoose'](self.productList.find(f => f.Code === id));
          },
          chooseProduct(e) {

          },
          async refresh(e, done) {
            const self: F7ComponentContextExtend = this;
            const currentState = $this.state['main'];
            let doneTimeout = null;
            if (done) {
              doneTimeout = setTimeout(() => {
                done();
              }, 10000);
            };

            let reportType = currentState.reportTypeList.find(f => f.id == currentState.reportQuery.type)?.reportType;
            let pages = [];
            const range = currentState.reportQuery.range;
            if (range && range.length < 2 || !reportType) {
              done && done();
              return;
            }
            const dateRange: Date[] = [range[0], range[1]];

            let fromDate: Date = dateRange && dateRange[0] && (new Date(dateRange[0].getFullYear(), dateRange[0].getMonth(), dateRange[0].getDate(), 0, 0, 0, 0)) || null;
            let toDate: Date = dateRange && dateRange[1] && new Date(dateRange[1].getFullYear(), dateRange[1].getMonth(), dateRange[1].getDate(), 23, 59, 59, 999) || null;

            let fromDateStr = fromDate.toISOString();
            let toDateStr = toDate.toISOString();

            let fromDateStrPrevious: string;
            let toDateStrPrevious: string;

            let previousLabel = '';

            if (reportType == 'HOUR') {
              const previousFromDate = new Date(fromDate);
              previousFromDate.setDate(fromDate.getDate() - 1);
              fromDateStrPrevious = previousFromDate.toISOString();

              const previousToDate = new Date(toDate);
              previousToDate.setDate(toDate.getDate() - 1);
              toDateStrPrevious = previousToDate.toISOString();

              previousLabel = 'hôm qua';
            }
            if (reportType == 'DAYOFWEEK') {
              const previousFromDate = new Date(fromDate);
              previousFromDate.setDate(fromDate.getDate() - 7);
              fromDateStrPrevious = previousFromDate.toISOString();

              const previousToDate = new Date(toDate);
              previousToDate.setDate(toDate.getDate() - 7);
              toDateStrPrevious = previousToDate.toISOString();
              previousLabel = 'tuần trước';
            }
            if (reportType == 'DAY') {
              const previousFromDate = new Date(fromDate);
              previousFromDate.setMonth(fromDate.getMonth() - 1);
              fromDateStrPrevious = previousFromDate.toISOString();

              const previousToDate = new Date(toDate);
              previousToDate.setMonth(toDate.getMonth() - 1);
              toDateStrPrevious = previousToDate.toISOString();

              previousLabel = 'tháng trước';
            }
            if (reportType == 'MONTH') {
              const previousFromDate = new Date(fromDate);
              previousFromDate.setFullYear(fromDate.getFullYear() - 1);
              fromDateStrPrevious = previousFromDate.toISOString();

              const previousToDate = new Date(toDate);
              previousToDate.setFullYear(toDate.getFullYear() - 1);
              toDateStrPrevious = previousToDate.toISOString();

              previousLabel = 'năm trước';
            }

            // Report summary
            // let summaryReport = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/reports', {
            //   reportSummary: true,
            //   eq_Accounts: "511,512,515,521,632,635,642,641,623,131,331",
            //   skipHeader: true,
            //   branch: pages,
            //   toDate: toDateStr,
            //   fromDate: fromDateStr,
            //   limit: 'nolimit'
            // });
            let summaryReportData: any = {
              // Cash: 0,
              // Revenues: 0,
              // DecreaseRevenues: 0,
              // DecreaseRevenuesByReturns: 0,
              // DecreaseRevenuesByDiscount: 0,
              // CustomerReceivableDebt: 0,
              // CostOfGoodsSold: 0,
              // GrossProfit: 0
            };

            await $this.rootServices.apiService.getPromise<any[]>('/collaborator/reports', { reportSummaryx: true, eq_Accounts: "511,521,515,521,632,635,642,641,623,2288,711,811,131,331,3349,", eq_VoucherType: '[CLBRTORDER,CLBRTCOMMISSION,CLBRTAWARD]', groupBy: 'Account', skipHeaderx: true, branch: pages, toDate: toDateStr, fromDate: fromDateStr, limit: 'nolimit' }).then(async summaryReport => {
              console.log(summaryReport);

              // const humanResourceReport = await $this.rootServices.apiService.getPromise('/collaborator/reports', { humanResource: true });

              summaryReportData = {
                // TmpCommission: summaryReport.filter(f => /^3349/.test(f.Account)).reduce((sum, current) => sum + parseFloat(current.TailCredit), 0),
                // Commission: summaryReport.filter(f => /^3348/.test(f.Account) && f.VoucherType == 'CLBRTCOMMISSION').reduce((sum, current) => sum + parseFloat(current.TailCredit), 0),
                // Award: summaryReport.filter(f => /^3348/.test(f.Account) && f.VoucherType == 'CLBRTAWARD').reduce((sum, current) => sum + parseFloat(current.TailCredit), 0),
                // Revenues: summaryReport.filter(f => /^511|515|711/.test(f.Account)).reduce((sum, current) => sum + parseFloat(current.TailCredit), 0),
                // DecreaseRevenues: summaryReport.filter(f => /^521/.test(f.Account)).reduce((sum, current) => sum + parseFloat(current.TailDebit), 0),
                // Cost: summaryReport.filter(f => /^632|642|635|623|641|811|521/.test(f.Account)).reduce((sum, current) => sum + parseFloat(current.TailDebit), 0),
                // CustomerReceivableDebt: summaryReport.filter(f => /^131/.test(f.Account)).reduce((sum, current) => sum + parseFloat(current.TailDebit), 0),
                // LiabilitiesDebt: summaryReport.filter(f => /^331/.test(f.Account)).reduce((sum, current) => sum + parseFloat(current.TailCredit), 0),
                // NumOfPublishers: humanResourceReport['NumOfPublishers'],
                // NumOfSales: humanResourceReport['NumOfSales'],
                // NumOfManagers: 0,
              };
              // summaryReportData.Profit = summaryReportData.Revenues - summaryReportData.Cost;
            });

            self.$setState({
              // Summary data
              summaryReportData: {
                // Revenues: $this.rootServices.currencyPipe.transform(summaryReportData.Revenues, 'VND'),
                // Cost: $this.rootServices.currencyPipe.transform(summaryReportData.Cost, 'VND'),
                // CustomerReceivableDebt: $this.rootServices.currencyPipe.transform(summaryReportData.CustomerReceivableDebt, 'VND'),
                // LiabilitiesDebt: $this.rootServices.currencyPipe.transform(summaryReportData.LiabilitiesDebt, 'VND'),
                // Profit: $this.rootServices.currencyPipe.transform(summaryReportData.Profit, 'VND'),
                // TmpCommission: $this.rootServices.currencyPipe.transform(summaryReportData.TmpCommission, 'VND'),
                // Commission: $this.rootServices.currencyPipe.transform(summaryReportData.Commission, 'VND'),
                // Award: $this.rootServices.currencyPipe.transform(summaryReportData.Award, 'VND'),
                // NumOfPublishers: $this.rootServices.decimalPipe.transform(summaryReportData.NumOfPublishers, '1.0-0'),
                // NumOfSales: $this.rootServices.decimalPipe.transform(summaryReportData.NumOfSales, '1.0-0'),
              },
            });



            /** Load data for business activity */
            let line1Statistics = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/orders/statistics', { state: 'NOTJUSTAPPROVED', statisticsRevenue: true, branch: pages, reportBy: reportType, ge_DateOfOrder: fromDateStr, le_DateOfOrder: toDateStr, limit: 'nolimit' });
            let line2Statistics = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/orders/statistics', { state: 'PROCESSING', statisticsCost: true, branch: pages, reportBy: reportType, ge_DateOfOrder: fromDateStr, le_DateOfOrder: toDateStr, limit: 'nolimit' });
            let line3Statistics = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/orders/statistics', { state: 'APPROVED', statisticsCost: true, branch: pages, reportBy: reportType, ge_DateOfOrder: fromDateStr, le_DateOfOrder: toDateStr, limit: 'nolimit' });
            let line4Statistics = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/orders/statistics', { state: 'COMPLETED', statisticsCost: true, branch: pages, reportBy: reportType, ge_DateOfOrder: fromDateStr, le_DateOfOrder: toDateStr, limit: 'nolimit' });
            let line5Statistics = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/orders/statistics', { state: 'UNRECORDED', statisticsCost: true, branch: pages, reportBy: reportType, ge_DateOfOrder: fromDateStr, le_DateOfOrder: toDateStr, limit: 'nolimit' });
            let line6Statistics = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/orders/statistics', { state: 'DEPLOYED', statisticsCost: true, branch: pages, reportBy: reportType, ge_DateOfOrder: fromDateStr, le_DateOfOrder: toDateStr, limit: 'nolimit' });

            /** Prepare data */
            let line1Data = line1Statistics.map(statistic => { statistic.Label = $this.makeStaticLabel(statistic, reportType); statistic.Timeline = $this.makeTimeline(statistic, reportType); return statistic; });
            let line2Data = line2Statistics.map(statistic => { statistic.Label = $this.makeStaticLabel(statistic, reportType); statistic.Timeline = $this.makeTimeline(statistic, reportType); return statistic; });
            let line3Data = line3Statistics.map(statistic => { statistic.Label = $this.makeStaticLabel(statistic, reportType); statistic.Timeline = $this.makeTimeline(statistic, reportType); return statistic; });
            let line4Data = line4Statistics.map(statistic => { statistic.Label = $this.makeStaticLabel(statistic, reportType); statistic.Timeline = $this.makeTimeline(statistic, reportType); return statistic; });
            let line5Data = line5Statistics.map(statistic => { statistic.Label = $this.makeStaticLabel(statistic, reportType); statistic.Timeline = $this.makeTimeline(statistic, reportType); return statistic; });
            let line6Data = line6Statistics.map(statistic => { statistic.Label = $this.makeStaticLabel(statistic, reportType); statistic.Timeline = $this.makeTimeline(statistic, reportType); return statistic; });
            let timeline = [...new Set([
              ...line1Data.map(item => item['Timeline']),
              ...line2Data.map(item => item['Timeline']),
              ...line3Data.map(item => item['Timeline']),
              ...line4Data.map(item => item['Timeline']),
              ...line5Data.map(item => item['Timeline']),
              ...line6Data.map(item => item['Timeline']),
            ].sort())];

            let labels = [];
            let mergeData: any;

            mergeData = timeline.map(t => {
              const point1 = line1Data.find(f => f.Timeline == t);
              const point2 = line2Data.find(f => f.Timeline == t);
              const point3 = line3Data.find(f => f.Timeline == t);
              const point4 = line4Data.find(f => f.Timeline == t);
              const point5 = line5Data.find(f => f.Timeline == t);
              const point6 = line6Data.find(f => f.Timeline == t);
              labels.push(point1?.Label || point2?.Label || point3?.Label || point4?.label || point5?.label);
              // labels.push(point1?.Label);
              return {
                Label: t,
                Line1: point1 || { NumOfOrders: 0, Revenue: 0 },
                Line2: point2 || { NumOfOrders: 0, Revenue: 0 },
                Line3: point3 || { NumOfOrders: 0, Revenue: 0 },
                Line4: point4 || { NumOfOrders: 0, Revenue: 0 },
                Line5: point5 || { NumOfOrders: 0, Revenue: 0 },
                Line6: point6 || { NumOfOrders: 0, Revenue: 0 },
              };
            });

            self.orderStatisticsData = {
              labels: labels,
              datasets: [
                {
                  label: 'Hoàn tất',
                  data: mergeData.map(point => point.Line4['NumOfOrders']),
                  borderColor: '#4cd964',
                  backgroundColor: '#4cd964',
                  pointRadius: 1,
                  pointHoverRadius: 1,
                },
                {
                  label: 'Đã triển khai',
                  data: mergeData.map(point => point.Line6['NumOfOrders']),
                  borderColor: '#7e208f',
                  backgroundColor: '#7e208f',
                  pointRadius: 1,
                  pointHoverRadius: 1,
                },
                {
                  label: 'Đã chốt',
                  data: mergeData.map(point => point.Line3['NumOfOrders']),
                  borderColor: '#2196f3',
                  backgroundColor: '#2196f3',
                  pointRadius: 1,
                  pointHoverRadius: 1,
                },
                {
                  label: 'Đang xử lý',
                  data: mergeData.map(point => point.Line2['NumOfOrders']),
                  borderColor: '#5ac8fa',
                  backgroundColor: '#5ac8fa',
                  pointRadius: 1,
                  pointHoverRadius: 1,
                },
                {
                  label: 'Đơn mới',
                  data: mergeData.map(point => point.Line1['NumOfOrders']),
                  borderColor: '#ffcc00',
                  backgroundColor: '#ffcc00',
                  pointRadius: 1,
                  pointHoverRadius: 1,
                },
                {
                  label: 'Đơn bị hủy',
                  data: mergeData.map(point => point.Line5['NumOfOrders']),
                  borderColor: '#ff3b30',
                  backgroundColor: '#ff3b30',
                  pointRadius: 1,
                  pointHoverRadius: 1,
                },
              ],
            };

            self.costAndRevenueStatisticsData = {
              labels: labels,
              datasets: [
                {
                  label: 'Hoàn tất',
                  data: mergeData.map(point => point.Line4['Revenue']),
                  borderColor: '#4cd964',
                  backgroundColor: '#4cd964',
                  pointRadius: 1,
                  pointHoverRadius: 1,
                },
                {
                  label: 'Đã chốt',
                  data: mergeData.map(point => point.Line3['Revenue']),
                  borderColor: '#2196f3',
                  backgroundColor: '#2196f3',
                  pointRadius: 1,
                  pointHoverRadius: 1,
                },
                {
                  label: 'Đang xử lý',
                  data: mergeData.map(point => point.Line2['Revenue']),
                  borderColor: '#5ac8fa',
                  backgroundColor: '#5ac8fa',
                  pointRadius: 1,
                  pointHoverRadius: 1,
                },
                {
                  label: 'Đơn mới',
                  data: mergeData.map(point => point.Line1['Revenue']),
                  borderColor: '#ffcc00',
                  backgroundColor: '#ffcc00',
                  pointRadius: 1,
                  pointHoverRadius: 1,
                },
                {
                  label: 'Đơn bị hủy',
                  data: mergeData.map(point => point.Line5['Revenue']),
                  borderColor: '#ff3b30',
                  backgroundColor: '#ff3b30',
                  pointRadius: 1,
                  pointHoverRadius: 1,
                },
              ],
            };

            if (!self.orderActivityChart) {
              self.orderActivityChart = new Chart('orderActivityChart', {
                type: 'bar',
                data: self.orderStatisticsData as any,
                options: $this.orderOptions
              });
            } else {
              self.orderActivityChart.data = self.orderStatisticsData;
              self.orderActivityChart.update('active');
            }

            if (!self.accountingActivityChart) {
              self.accountingActivityChart = new Chart('accountingActivityChart', {
                type: 'bar',
                data: self.costAndRevenueStatisticsData as any,
                options: $this.revenueOptions
              });
            } else {
              self.accountingActivityChart.data = self.costAndRevenueStatisticsData;
              self.accountingActivityChart.update('active');
            }
            //End business activity




            /** Load data for business activity */
            line1Statistics = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/orders/statistics', { state: 'NOTJUSTAPPROVED', statisticsRevenue: true, branch: pages, reportBy: reportType, ge_DateOfOrder: fromDateStrPrevious, le_DateOfOrder: toDateStrPrevious, limit: 'nolimit' });
            line2Statistics = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/orders/statistics', { state: 'PROCESSING', statisticsCost: true, branch: pages, reportBy: reportType, ge_DateOfOrder: fromDateStrPrevious, le_DateOfOrder: toDateStrPrevious, limit: 'nolimit' });
            line3Statistics = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/orders/statistics', { state: 'APPROVED', statisticsCost: true, branch: pages, reportBy: reportType, ge_DateOfOrder: fromDateStrPrevious, le_DateOfOrder: toDateStrPrevious, limit: 'nolimit' });
            line4Statistics = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/orders/statistics', { state: 'COMPLETED', statisticsCost: true, branch: pages, reportBy: reportType, ge_DateOfOrder: fromDateStrPrevious, le_DateOfOrder: toDateStrPrevious, limit: 'nolimit' });
            line5Statistics = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/orders/statistics', { state: 'UNRECORDED', statisticsCost: true, branch: pages, reportBy: reportType, ge_DateOfOrder: fromDateStrPrevious, le_DateOfOrder: toDateStrPrevious, limit: 'nolimit' });

            /** Prepare data */
            line1Data = line1Statistics.map(statistic => { statistic.Label = $this.makeStaticLabel(statistic, reportType); statistic.Timeline = $this.makeTimeline(statistic, reportType); return statistic; });
            line2Data = line2Statistics.map(statistic => { statistic.Label = $this.makeStaticLabel(statistic, reportType); statistic.Timeline = $this.makeTimeline(statistic, reportType); return statistic; });
            line3Data = line3Statistics.map(statistic => { statistic.Label = $this.makeStaticLabel(statistic, reportType); statistic.Timeline = $this.makeTimeline(statistic, reportType); return statistic; });
            line4Data = line4Statistics.map(statistic => { statistic.Label = $this.makeStaticLabel(statistic, reportType); statistic.Timeline = $this.makeTimeline(statistic, reportType); return statistic; });
            line5Data = line5Statistics.map(statistic => { statistic.Label = $this.makeStaticLabel(statistic, reportType); statistic.Timeline = $this.makeTimeline(statistic, reportType); return statistic; });
            timeline = [...new Set([
              ...line1Data.map(item => item['Timeline']),
              ...line2Data.map(item => item['Timeline']),
              ...line3Data.map(item => item['Timeline']),
              ...line4Data.map(item => item['Timeline']),
              ...line5Data.map(item => item['Timeline']),
            ].sort())];

            labels = [];

            mergeData = timeline.map(t => {
              const point1 = line1Data.find(f => f.Timeline == t);
              const point2 = line2Data.find(f => f.Timeline == t);
              const point3 = line3Data.find(f => f.Timeline == t);
              const point4 = line4Data.find(f => f.Timeline == t);
              const point5 = line5Data.find(f => f.Timeline == t);
              labels.push(point1?.Label || point2?.Label || point3?.Label || point4?.label || point5?.label);
              // labels.push(point1?.Label);
              return {
                Label: t,
                Line1: point1 || { NumOfOrders: 0, Revenue: 0 },
                Line2: point2 || { NumOfOrders: 0, Revenue: 0 },
                Line3: point3 || { NumOfOrders: 0, Revenue: 0 },
                Line4: point4 || { NumOfOrders: 0, Revenue: 0 },
                Line5: point5 || { NumOfOrders: 0, Revenue: 0 },
              };
            });

            self.orderStatisticsDataPrevious = {
              labels: labels,
              datasets: [
                {
                  label: 'Hoàn tất',
                  data: mergeData.map(point => point.Line4['NumOfOrders']),
                  borderColor: '#4cd964',
                  backgroundColor: '#4cd964',
                  pointRadius: 1,
                  pointHoverRadius: 1,
                },
                {
                  label: 'Đã chốt',
                  data: mergeData.map(point => point.Line3['NumOfOrders']),
                  borderColor: '#2196f3',
                  backgroundColor: '#2196f3',
                  pointRadius: 1,
                  pointHoverRadius: 1,
                },
                {
                  label: 'Đang xử lý',
                  data: mergeData.map(point => point.Line2['NumOfOrders']),
                  borderColor: '#5ac8fa',
                  backgroundColor: '#5ac8fa',
                  pointRadius: 1,
                  pointHoverRadius: 1,
                },
                {
                  label: 'Đơn mới',
                  data: mergeData.map(point => point.Line1['NumOfOrders']),
                  borderColor: '#ffcc00',
                  backgroundColor: '#ffcc00',
                  pointRadius: 1,
                  pointHoverRadius: 1,
                },
                {
                  label: 'Đơn bị hủy',
                  data: mergeData.map(point => point.Line5['NumOfOrders']),
                  borderColor: '#ff3b30',
                  backgroundColor: '#ff3b30',
                  pointRadius: 1,
                  pointHoverRadius: 1,
                },
              ],
            };

            self.costAndRevenueStatisticsDataPrevious = {
              labels: labels,
              datasets: [
                {
                  label: 'Hoàn tất',
                  data: mergeData.map(point => point.Line4['Revenue']),
                  borderColor: '#4cd964',
                  backgroundColor: '#4cd964',
                  pointRadius: 1,
                  pointHoverRadius: 1,
                },
                {
                  label: 'Đã chốt',
                  data: mergeData.map(point => point.Line3['Revenue']),
                  borderColor: '#2196f3',
                  backgroundColor: '#2196f3',
                  pointRadius: 1,
                  pointHoverRadius: 1,
                },
                {
                  label: 'Đang xử lý',
                  data: mergeData.map(point => point.Line2['Revenue']),
                  borderColor: '#5ac8fa',
                  backgroundColor: '#5ac8fa',
                  pointRadius: 1,
                  pointHoverRadius: 1,
                },
                {
                  label: 'Đơn mới',
                  data: mergeData.map(point => point.Line1['Revenue']),
                  borderColor: '#ffcc00',
                  backgroundColor: '#ffcc00',
                  pointRadius: 1,
                  pointHoverRadius: 1,
                },
                {
                  label: 'Đơn bị hủy',
                  data: mergeData.map(point => point.Line5['Revenue']),
                  borderColor: '#ff3b30',
                  backgroundColor: '#ff3b30',
                  pointRadius: 1,
                  pointHoverRadius: 1,
                },
              ],
            };

            if (!self.orderActivityChartPrevious) {
              self.orderActivityChartPrevious = new Chart('orderActivityChartPrevious', {
                type: 'bar',
                data: self.orderStatisticsDataPrevious as any,
                options: $this.orderOptions
              });
            } else {
              self.orderActivityChartPrevious.data = self.orderStatisticsDataPrevious;
              self.orderActivityChartPrevious.update('active');
            }

            if (!self.accountingActivityChartPrevious) {
              self.accountingActivityChartPrevious = new Chart('accountingActivityChartPrevious', {
                type: 'bar',
                data: self.costAndRevenueStatisticsDataPrevious as any,
                options: $this.revenueOptions
              });
            } else {
              self.accountingActivityChartPrevious.data = self.costAndRevenueStatisticsDataPrevious;
              self.accountingActivityChartPrevious.update('active');
            }






            /** Load data for business activity */
            line1Statistics = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/publishers/statistics/online-publishers', { reportBy: reportType, le_DateOfLog: toDateStr, ge_DateOfLog: fromDateStr, limit: 'nolimit' });
            line2Statistics = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/publishers/statistics/ordered-publishers', { reportBy: reportType, le_DateOfLog: toDateStr, ge_DateOfLog: fromDateStr, limit: 'nolimit' });
            line3Statistics = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/publishers/statistics/created-publishers', { reportBy: reportType, le_DateOfLog: toDateStr, ge_DateOfLog: fromDateStr, limit: 'nolimit' });

            /** Prepare data */
            line1Data = line1Statistics.map(statistic => { statistic.Label = $this.makeStaticLabel(statistic, reportType); statistic.Timeline = $this.makeTimeline(statistic, reportType); return statistic; });
            line2Data = line2Statistics.map(statistic => { statistic.Label = $this.makeStaticLabel(statistic, reportType); statistic.Timeline = $this.makeTimeline(statistic, reportType); return statistic; });
            line3Data = line3Statistics.map(statistic => { statistic.Label = $this.makeStaticLabel(statistic, reportType); statistic.Timeline = $this.makeTimeline(statistic, reportType); return statistic; });
            timeline = [...new Set([
              ...line1Data.map(item => item['Timeline']),
              ...line2Data.map(item => item['Timeline']),
              ...line3Data.map(item => item['Timeline']),
            ].sort())];

            labels = [];

            mergeData = timeline.map(t => {
              const point1 = line1Data.find(f => f.Timeline == t);
              const point2 = line2Data.find(f => f.Timeline == t);
              const point3 = line3Data.find(f => f.Timeline == t);
              labels.push(
                point1?.Label
                || point2?.Label
                || point3?.Label
              );
              // labels.push(point1?.Label);
              return {
                Label: t,
                Line1: point1 || { Value: 0 },
                Line2: point2 || { Value: 0 },
                Line3: point3 || { Value: 0 },
              };
            });

            const chartOptions = {
              responsive: true,
              maintainAspectRatio: false,
              hover: {
                mode: 'index',
              },
              scales: {
                x:
                {
                  display: true,
                  ticks: {
                  },
                  // stacked: true,
                },
                y: {
                  display: true,
                  ticks: {
                    callback: (value, index, values) => {
                      return $this.rootServices.decimalPipe.transform(value || 0, '1.0-0');
                    }
                  },
                  // stacked: true,
                },
              },
              tooltips: {
                callbacks: {
                  label: (tooltipItem, data) => {
                    return $this.rootServices.decimalPipe.transform(tooltipItem.yLabel, '1.0-0');
                  }
                }
              }
            };
            self.publishersStatisticsData = {
              labels: labels,
              datasets: [
                {
                  label: 'Online`',
                  data: mergeData.map(point => point.Line1['Value']),
                  borderColor: '#4cd964',
                  backgroundColor: '#4cd964',
                  pointRadius: 1,
                  pointHoverRadius: 1,
                },
                {
                  label: 'Có đơn',
                  data: mergeData.map(point => point.Line2['Value']),
                  borderColor: '#2196f3',
                  backgroundColor: '#2196f3',
                  pointRadius: 1,
                  pointHoverRadius: 1,
                },
                {
                  label: 'Mới',
                  data: mergeData.map(point => point.Line3['Value']),
                  borderColor: '#ffcc00',
                  backgroundColor: '#ffcc00',
                  pointRadius: 1,
                  pointHoverRadius: 1,
                },
              ],
            };


            if (!self.publisherChart) {
              self.publisherChart = new Chart('publisherChart', {
                type: 'line',
                data: self.publishersStatisticsData as any,
                options: chartOptions as any
              });
            } else {
              self.publisherChart.data = self.publishersStatisticsData;
              self.publisherChart.update('active');
            }
            //End business activity


            // Report top
            await $this.rootServices.apiService.getPromise<any>('/collaborator/reports', { eq_Accounts: '511,512,515', eq_VoucherType: 'CLBRTORDER', isn_Product: null, ne_ContraAccount: '911', groupBy: 'Employee', includeEmployeeInfo: true, fromDate: fromDateStr, toDate: toDateStr, sort_CreditGenerate: 'desc', limit: 10 }).then(rs => {
              self.topEmployeeList = rs.map(item => {
                item.Revenus = $this.rootServices.currencyPipe.transform((item.GenerateCredit - item.GenerateDebit) || 0, 'VND');
                return item;
              });
              console.log(rs);
            });
            await $this.rootServices.apiService.getPromise<any>('/collaborator/reports', { eq_Accounts: '511,512,515', eq_VoucherType: 'CLBRTORDER', isn_Object: null, ne_ContraAccount: '911', groupBy: 'Product,ProductUnit', includeProductInfo: true, fromDate: fromDateStr, toDate: toDateStr, sort_CreditGenerate: 'desc', limit: 10 }).then(rs => {
              self.topGoodsList = rs.map(item => {
                item.Revenus = $this.rootServices.currencyPipe.transform((item.GenerateCredit - item.GenerateDebit) || 0, 'VND');
                return item;
              });
              console.log(rs);
            });
            await $this.rootServices.apiService.getPromise<any>('/collaborator/publishers/top/online-publishers', { limit: 10 }).then(rs => {
              self.topOnlinePublishers = rs;
              console.log(rs);
            });
            await $this.rootServices.apiService.getPromise<any>('/collaborator/publishers/top/ordered-publishers', { limit: 10 }).then(rs => {
              self.topOrderedPublishers = rs;
              console.log(rs);
            });
            await $this.rootServices.apiService.getPromise<any>('/collaborator/publishers/top/assigned-publishers', { limit: 10 }).then(rs => {
              self.topAssignedPublishers = rs;
              console.log(rs);
            });
            await $this.rootServices.apiService.getPromise<any>('/collaborator/publishers/top/not-ordered-publishers', { limit: 10 }).then(rs => {
              self.topNotOtderedPublishers = rs;
              console.log(rs);
            });

            self.$setState({
              // Top data
              reportType: reportType,
              previousLabel: previousLabel,
              topEmployeeList: self.topEmployeeList,
              topGoodsList: self.topGoodsList,
              topOnlinePublishers: self.topOnlinePublishers,
              topOrderedPublishers: self.topOrderedPublishers,
              topAssignedPublishers: self.topAssignedPublishers,
              topNotOtderedPublishers: self.topNotOtderedPublishers,
            });

            if (false) {
              // Load data fotr cash flow
              const cashFlowStatistics = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/statistics', { eq_Account: "[1111]", increment: true, statisticsCost: true, branch: pages, reportBy: reportType, ge_VoucherDate: fromDateStr, le_VoucherDate: toDateStr, limit: 'nolimit' });
              const cashInBankFlowStatistics = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/statistics', { eq_Account: "[1121]", increment: true, statisticsCost: true, branch: pages, reportBy: reportType, ge_VoucherDate: fromDateStr, le_VoucherDate: toDateStr, limit: 'nolimit' });
              const goldFlowStatistics = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/statistics', { eq_Account: "[1113]", increment: true, statisticsCost: true, branch: pages, reportBy: reportType, ge_VoucherDate: fromDateStr, le_VoucherDate: toDateStr, limit: 'nolimit' });
              const voucherFlowStatistics = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/statistics', { eq_Account: "[1114]", increment: true, statisticsCost: true, branch: pages, reportBy: reportType, ge_VoucherDate: fromDateStr, le_VoucherDate: toDateStr, limit: 'nolimit' });

              /** Prepare data */
              line1Data = voucherFlowStatistics.map(statistic => { statistic.Label = $this.makeStaticLabel(statistic, reportType); statistic.Timeline = $this.makeTimeline(statistic, reportType); statistic.Value = statistic.SumOfDebit - statistic.SumOfCredit; return statistic; });
              line2Data = goldFlowStatistics.map(statistic => { statistic.Label = $this.makeStaticLabel(statistic, reportType); statistic.Timeline = $this.makeTimeline(statistic, reportType); statistic.Value = statistic.SumOfDebit - statistic.SumOfCredit; return statistic; });
              line3Data = cashInBankFlowStatistics.map(statistic => { statistic.Label = $this.makeStaticLabel(statistic, reportType); statistic.Timeline = $this.makeTimeline(statistic, reportType); statistic.Value = statistic.SumOfDebit - statistic.SumOfCredit; return statistic; });
              line4Data = cashFlowStatistics.map(statistic => { statistic.Label = $this.makeStaticLabel(statistic, reportType); statistic.Timeline = $this.makeTimeline(statistic, reportType); statistic.Value = statistic.SumOfDebit - statistic.SumOfCredit; return statistic; });
              timeline = [...new Set([
                ...line1Data.map(item => item['Timeline']),
                ...line2Data.map(item => item['Timeline']),
                ...line3Data.map(item => item['Timeline']),
                ...line4Data.map(item => item['Timeline']),
              ].sort())];
              labels = [];
              mergeData = timeline.map(t => {
                const point1 = line1Data.find(f => f.Timeline == t);
                const point2 = line2Data.find(f => f.Timeline == t);
                const point3 = line3Data.find(f => f.Timeline == t);
                const point4 = line4Data.find(f => f.Timeline == t);
                labels.push(point1?.Label || point2?.Label || point3?.Label || point4?.Label);
                return {
                  Label: t,
                  Line1: point1 || { Value: 0 },
                  Line2: point2 || { Value: 0 },
                  Line3: point3 || { Value: 0 },
                  Line4: point4 || { Value: 0 },
                };
              });


              self.cashFlowStatisticsData = {
                labels: labels,
                datasets: [
                  {
                    label: 'Voucher',
                    data: mergeData.map(point => point.Line1['Value']),
                    borderColor: $this.rootServices.themeColors.default.primary,
                    backgroundColor: $this.rootServices.themeColors.dim10.primary,
                    pointRadius: 1,
                    pointHoverRadius: 1,
                    // fill: true,
                  },
                  {
                    label: 'Vàng',
                    data: mergeData.map(point => point.Line2['Value']),
                    borderColor: $this.rootServices.themeColors.default.warning,
                    backgroundColor: $this.rootServices.themeColors.dim10.warning,
                    pointRadius: 1,
                    pointHoverRadius: 1,
                    // fill: true,
                  },
                  {
                    label: 'Tiền trong ngân hàng',
                    data: mergeData.map(point => point.Line3['Value']),
                    borderColor: $this.rootServices.themeColors.default.info,
                    backgroundColor: $this.rootServices.themeColors.dim10.info,
                    pointRadius: 1,
                    pointHoverRadius: 1,
                    // fill: true,
                  },
                  {
                    label: 'Tiền mặt',
                    data: mergeData.map(point => point.Line4['Value']),
                    borderColor: $this.rootServices.themeColors.default.succuess,
                    backgroundColor: $this.rootServices.themeColors.dim10.succuess,
                    pointRadius: 1,
                    pointHoverRadius: 1,
                    // fill: true,
                  },
                ],
              };
              if (!self.cashFlowChart) {
                self.cashFlowChart = new Chart('cashFlowChart', {
                  type: 'line',
                  data: self.cashFlowStatisticsData as any,
                  // options: {
                  //   scales: {
                  //     y: {
                  //       beginAtZero: true
                  //     }
                  //   }
                  // }
                  options: $this.chartOptions
                });
              } else {
                self.cashFlowChart.data = self.cashFlowStatisticsData;
                self.cashFlowChart.update('active');
              }
              // End cash flow



              // Load statistic data for debt
              /** Load data */
              const customerReceivableStatistics = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/statistics', { eq_Account: "[131]", increment: true, branch: pages, reportBy: reportType, ge_VoucherDate: fromDateStr, le_VoucherDate: toDateStr, limit: 'nolimit' });
              const liabilitiesStatistics = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/statistics', { eq_Account: "[331]", increment: true, branch: pages, reportBy: reportType, ge_VoucherDate: fromDateStr, le_VoucherDate: toDateStr, limit: 'nolimit' });
              const loadStatistics = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/statistics', { eq_Account: "[3411]", increment: true, branch: pages, reportBy: reportType, ge_VoucherDate: fromDateStr, le_VoucherDate: toDateStr, limit: 'nolimit' });
              const financialLeasingDebtStatistics = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/statistics', { eq_Account: "[3412]", increment: true, branch: pages, reportBy: reportType, ge_VoucherDate: fromDateStr, le_VoucherDate: toDateStr, limit: 'nolimit' });
              const a1288Statistics = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/statistics', { eq_Account: "[1288]", increment: true, branch: pages, reportBy: reportType, ge_VoucherDate: fromDateStr, le_VoucherDate: toDateStr, limit: 'nolimit' });

              /** Prepare data */
              line1Data = customerReceivableStatistics.map(statistic => { statistic.Label = $this.makeStaticLabel(statistic, reportType); statistic.Timeline = $this.makeTimeline(statistic, reportType); statistic.Value = statistic.SumOfDebit - statistic.SumOfCredit; return statistic; });
              line2Data = liabilitiesStatistics.map(statistic => { statistic.Label = $this.makeStaticLabel(statistic, reportType); statistic.Timeline = $this.makeTimeline(statistic, reportType); statistic.Value = statistic.SumOfCredit - statistic.SumOfDebit; return statistic; });
              line3Data = loadStatistics.map(statistic => { statistic.Label = $this.makeStaticLabel(statistic, reportType); statistic.Timeline = $this.makeTimeline(statistic, reportType); statistic.Value = statistic.SumOfCredit - statistic.SumOfDebit; return statistic; });
              line4Data = financialLeasingDebtStatistics.map(statistic => { statistic.Label = $this.makeStaticLabel(statistic, reportType); statistic.Timeline = $this.makeTimeline(statistic, reportType); statistic.Value = statistic.SumOfCredit - statistic.SumOfDebit; return statistic; });
              line5Data = a1288Statistics.map(statistic => { statistic.Label = $this.makeStaticLabel(statistic, reportType); statistic.Timeline = $this.makeTimeline(statistic, reportType); statistic.Value = statistic.SumOfDebit - statistic.SumOfCredit; return statistic; });
              timeline = [...new Set([
                ...line1Data.map(item => item['Timeline']),
                ...line2Data.map(item => item['Timeline']),
                ...line3Data.map(item => item['Timeline']),
                ...line4Data.map(item => item['Timeline']),
                ...line5Data.map(item => item['Timeline']),
              ].sort())];

              labels = [];

              mergeData = timeline.map(t => {
                const point1 = line1Data.find(f => f.Timeline == t);
                const point2 = line2Data.find(f => f.Timeline == t);
                const point3 = line3Data.find(f => f.Timeline == t);
                const point4 = line4Data.find(f => f.Timeline == t);
                const point5 = line5Data.find(f => f.Timeline == t);
                labels.push(point1?.Label || point2?.Label || point3?.Label || point4?.Label);
                return {
                  Label: t,
                  Line1: point1 || { Value: 0 },
                  Line2: point2 || { Value: 0 },
                  Line3: point3 || { Value: 0 },
                  Line4: point4 || { Value: 0 },
                  Line5: point5 || { Value: 0 },
                };
              });

              self.debtStatisticsData = {
                labels: labels,
                datasets: [
                  {
                    label: 'Công nợ phải thu',
                    data: mergeData.map(point => point.Line1['Value']),
                    borderColor: $this.rootServices.themeColors.default.succuess,
                    backgroundColor: $this.rootServices.themeColors.dim10.succuess,
                    pointRadius: 1,
                    pointHoverRadius: 1,
                    // fill: true,
                  },
                  {
                    label: 'Công nợ phải trả',
                    data: mergeData.map(point => point.Line2['Value']),
                    borderColor: $this.rootServices.themeColors.default.primary,
                    backgroundColor: $this.rootServices.themeColors.dim10.primary,
                    pointRadius: 1,
                    pointHoverRadius: 1,
                    // fill: true,
                  },
                  {
                    label: 'Các khoản vay',
                    data: mergeData.map(point => point.Line3['Value']),
                    borderColor: $this.rootServices.themeColors.default.warning,
                    backgroundColor: $this.rootServices.themeColors.dim10.warning,
                    pointRadius: 1,
                    pointHoverRadius: 1,
                    // fill: true,
                  },
                  {
                    label: 'Nợ thuê tài chính',
                    data: mergeData.map(point => point.Line4['Value']),
                    borderColor: $this.rootServices.themeColors.default.danger,
                    backgroundColor: $this.rootServices.themeColors.dim10.danger,
                    pointRadius: 1,
                    pointHoverRadius: 1,
                    // fill: true,
                  },
                  {
                    label: 'Đầu tư khác',
                    data: mergeData.map(point => point.Line5['Value']),
                    borderColor: $this.rootServices.themeColors.default.info,
                    backgroundColor: $this.rootServices.themeColors.dim10.info,
                    pointRadius: 1,
                    pointHoverRadius: 1,
                    // fill: true,
                  },
                ],
              };

              if (!self.accDebtChart) {
                self.accDebtChart = new Chart('accDebtChart', {
                  type: 'line',
                  data: self.debtStatisticsData as any,
                  options: $this.chartOptions,
                });
              } else {
                self.accDebtChart.data = self.debtStatisticsData;
                self.accDebtChart.update('active');
              }
              // End 


              // Load statistic data for profit
              /** Load data */
              const profitStatistics = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/statistics', { eq_Account: "[632,641,642,811,511,512,515,711]", statisticsProfit: true, increment: true, branch: pages, reportBy: reportType, ge_VoucherDate: fromDateStr, le_VoucherDate: toDateStr, limit: 'nolimit' });

              /** Prepare data */
              line1Data = profitStatistics.map(statistic => { statistic.Label = $this.makeStaticLabel(statistic, reportType); statistic.Timeline = $this.makeTimeline(statistic, reportType); statistic.Value = statistic.SumOfCredit - statistic.SumOfDebit; return statistic; });
              timeline = [...new Set([
                ...line1Data.map(item => item['Timeline']),
              ].sort())];

              labels = [];

              mergeData = timeline.map(t => {
                const point1 = line1Data.find(f => f.Timeline == t);
                labels.push(point1?.Label);
                return {
                  Label: t,
                  Line1: point1 || { Value: 0 },
                };
              });

              self.profitStatisticsData = {
                labels: labels,
                datasets: [
                  {
                    label: 'Lợi nhuận',
                    data: mergeData.map(point => point.Line1['Value']),
                    borderColor: $this.rootServices.themeColors.default.primary,
                    backgroundColor: $this.rootServices.themeColors.dim10.primary,
                    pointRadius: 1,
                    pointHoverRadius: 1,
                    // fill: true,
                  },
                ],
              };

              if (!self.profitChart) {
                self.profitChart = new Chart('profitChart', {
                  type: 'line',
                  data: self.profitStatisticsData as any,
                  options: $this.chartOptions,
                });
              } else {
                self.profitChart.data = self.profitStatisticsData;
                self.profitChart.update('active');
              }
              // End 

              // Report top
              await $this.rootServices.apiService.getPromise<any>('/collaborator/reports', { reportReceivablesFromCustomer: true, branch: pages, toDate: toDateStr, sort_TailDebit: 'desc' }).then(rs => {
                self.customerReceivableDebt = rs.map(item => {
                  item.TailDebit = $this.rootServices.currencyPipe.transform((item.TailDebit) || 0, 'VND');
                  return item;
                });
                console.log(rs);
              });
              await $this.rootServices.apiService.getPromise<any>('/collaborator/reports', { reportLiabilities: true, branch: pages, toDate: toDateStr, sort_TailCredit: 'desc' }).then(rs => {
                self.liabilitityDebt = rs.map(item => {
                  item.TailCredit = $this.rootServices.currencyPipe.transform((item.TailCredit) || 0, 'VND');
                  return item;
                });
                console.log(rs);
              });

              self.$setState({
                // Summary data
                // summaryReportData: {
                //   Cash: $this.rootServices.currencyPipe.transform(summaryReportData.Cash, 'VND'),
                //   Revenues: $this.rootServices.currencyPipe.transform(summaryReportData.Revenues, 'VND'),
                //   DecreaseRevenuesByReturns: $this.rootServices.currencyPipe.transform(summaryReportData.DecreaseRevenuesByReturns, 'VND'),
                //   DecreaseRevenuesByDiscount: $this.rootServices.currencyPipe.transform(summaryReportData.DecreaseRevenuesByDiscount, 'VND'),
                //   CustomerReceivableDebt: $this.rootServices.currencyPipe.transform(summaryReportData.CustomerReceivableDebt, 'VND'),
                // },
                // Top data
                customerReceivableDebt: self.customerReceivableDebt,
                liabilitityDebt: self.liabilitityDebt,
              });
            }
            done && done();
            return true;
          },
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          pageInit(e, page: F7Page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;
            // Loading flag
            let allowInfinite = true;
            $this.onComponentInit({ instance: self }, 'main', null, page).then(currentState => {
              // self.refresh();
            });

          },
          // pageBeforeIn(e, page) {
          //     console.log('page before in');
          //     const self: F7ComponentContextExtend = this;
          //     self.refresh();
          // },
          pageAfterIn(e, page) {
            console.log('page after in');
            const self: F7ComponentContextExtend = this;
            const currentState = $this.state[self.$route.params.id];
            if (e['detail']?.from == 'previous') {
              self.refresh();
            }
          },
          // pageBeforeOut(e, page) {
          //     console.log('page before out');
          // },
          // pageAfterOut(e, page) {
          //     console.log('page after out');
          // },
          // pageBeforeUnmount(e, page) {
          //     console.log('page before unmount');
          // },
          // pageBeforeRemove(e, page) {
          //     console.log('page before remove');
          // },
          pageBeforeRemove(e, page) {
            console.log('[page event] before remove', page.route.url);
            const self: F7ComponentContextExtend = this;
            // const starmtSelectRoles = self.$app.smartSelect.get('.smart-select');
            // starmtSelectRoles.destroy();
          },
        },
      },
    };
  }
}
