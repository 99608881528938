import { isPlatform } from '@ionic/angular';
// import { SystemSettingsModel } from './../../../model/model';
import { CommercePosOrderDetailModel, CommercePosOrderModel } from '../../../model/commerce-pos.model';
import { take, filter } from 'rxjs/operators';
import { Dialog } from "framework7/build/core/components/dialog/dialog";
import { Router } from "framework7/build/core/modules/router/router";
import { CommonService } from "../../../../../src/app/services/common.service";
import { RootServices } from "../../../../../src/app/services/root.services";
import { F7ComponentContext, F7Page } from "../../../../../src/app/types/framework7-types";
import { FormComponentStateExtend, FormSchema } from '../../../../../src/app/lib/base-form-component';
import { BaseComponent } from '../../../../../src/app/lib/base-component';
import { PrintOptions } from '@awesome-cordova-plugins/printer/ngx';
// import { Printer, PrintOptions } from '@awesome-cordova-plugins/printer/ngx';
// import * as $ from 'jquery';
declare const $: any;
import { SystemSettingsModel } from '../../../../../src/app/model/system.model';
// import { SystemSettingsModel } from 'src/app/model/system.model';
// import { SystemSettingsModel } from 'src/app/model/system.model';

// declare const $: any;
export interface ComponentStateExtend extends FormComponentStateExtend<CommercePosOrderModel> {
  [key: string]: any;
  instance: F7ComponentContextExtend,
  data?: CommercePosOrderModel;
  form?: any;
}

export class F7ComponentContextExtend extends F7ComponentContext {
  responseInProgress?: boolean;

  detailDialog?: Dialog.Dialog;

  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, [key: string]: any } };

  systemConfigs?: SystemSettingsModel;
}

/** Component group manager */
export class CommerceOrderPrintComponent extends BaseComponent<ComponentStateExtend> {
  // states: { [key: string]: State } = {};
  currentState: ComponentStateExtend;
  title: 'Print bill';

  idKey = 'Code';
  apiPath = '/commerce-pos/orders';

  schema: FormSchema = {
    Object: {
      type: 'autocomplete-contact',
      label: 'Khách hàng',
      validators: [
        // 'required'
      ]
    },
    ObjectName: {
      type: 'input',
      label: 'Tên khách hàng',
      validators: [
        // 'required'
      ]

    },
    Note: {
      type: 'input',
    },
    Details: {
      type: 'list',
      makeModel: (properties: CommercePosOrderDetailModel) => {
        return new CommercePosOrderDetailModel(properties);
      },
      Unit: {
        type: 'smart-select',
        label: 'Đơn vị tính',
        validators: [
          'required'
        ]
      },
      AccessNumbers: {
        type: 'smart-select',
        label: 'Số truy xuất',
        validators: [

        ]
      },
      Quantity: {
        type: 'stepper',
        label: 'Số lượng',
        validators: [
          'required'
        ]
      },
      Price: { type: 'inputmask', format: { mask: 'decimal', option: { radixPoint: ',', groupSeparator: '.' } } },
      ToMoney: { type: 'inputmask', format: { mask: 'decimal', option: { radixPoint: ',', groupSeparator: '.' } } },
    }
  };

  orderList: ComponentStateExtend[] = [];
  printPptions: PrintOptions = {
    name: 'POS Order',
    duplex: true,
    orientation: 'portrait',
    monochrome: true,
    margin: {
      top: '0px',
      left: '0px',
      right: '0px',
      bottom: '0px',
    },
    autoFit: true,
    paper: {
      width: '80mm',
      height: '1024px',
      name: 'bill',
      length: '1'
    },
    ui: {
      height: 1024,
    }
  }

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
    // public printer: Printer,
  ) {
    super(rootServices);
    // // console.log('Click here to open ts file');

    // this.printer.isAvailable().then(() => {
    //   console.log('printer available');
    //   this.commonService.showInfo('printer available');
    // }, () => {
    //   console.log('printer not available');
    //   this.commonService.showError('printer not available');
    // });
  }

  prepareData(data: CommercePosOrderModel): CommercePosOrderModel {
    for (const detail of data.Details) {

    };
    return data;
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  async onComponentInit(state: ComponentStateExtend, index: string, asCase?: string, f7Page?: F7Page) {
    const $this = this;

    // Start loading status
    state.instance.$setState({
      loading: true,
      schema: this.schema,
    });
    this.commonService.showPreloader();

    // End

    // set static data

    const currentState: ComponentStateExtend = await super.onComponentInit(state, index, asCase) as any;

    currentState.data = await this.rootServices.apiService.getPromise<CommercePosOrderModel[]>(this.apiPath + '/' + currentState.instance.$route?.params?.id, {
      includeDetails: true,
      includeTax: true,
      includeUnit: true,
      includeProductPrice: true,
      includeProductUnitList: true,
      includeProductCategories: true,
      includeRelativeVouchers: true,
      renderBarCode: true,
    }).then(rs => {
      const data = rs[0];
      if (data) {
        data.Total = 0;
        for (const detail of data.Details) {
          detail.ToMoney = detail.Price * detail.Quantity;
          data.Total += detail.ToMoney;

          detail.AccessNumbers = detail.AccessNumbers || [];
        }
      }
      return data;
    });

    const systemConfigs: SystemSettingsModel = await this.rootServices.systemSettings$.pipe(filter(p => !!p), take(1)).toPromise();
    console.log(systemConfigs);

    // Stop loading status
    currentState.instance.$setState({
      loading: false,
      data: currentState.data,
      // systemConfigs: systemConfigs,
      registerInfo: systemConfigs?.LICENSE_INFO.register,
    });
    this.commonService.hidePreloader();

    if (isPlatform('android')) {
      setTimeout(() => {
        this.print(currentState.instance);
      }, 300);
    }

    return currentState;
  }

  print(self: F7ComponentContextExtend) {
    let style = this.style.trim().replace(/\n+/g, '');
    let html = /*html*/`
        <html>
          <head>
            <meta http-equiv="Content-Type" content="text/html;charset=utf-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
            <style>${style}</style>
            <title>PHIẾU BÁO GIÁ</title>
          </head>
          <body>
          ${self.$el.find('.print-area').html()}
          </body>
        </html>
      `.trim().replace(/\n+/g, '').replace(/> +</g, '><');
    // console.log(html);
    this.rootServices.printer.print(html, this.printPptions).then(() => {
      // this.commonService.showInfo('Print success');
      self.$router.back();
    }, () => {
      this.commonService.showError('Phiên bản hiện tại chưa hỗ trợ chức năng In');
    });
  }

  style = /*css*/`
    .page.pos-order-print .page-content {
      background: #fff;
    }
    @page {
      margin-left: 0px;
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 0px;
    },
    body {
      padding: 0;
      margin: 0;
    }
    #print-area {
      background: #fff;
      color: #000;
    }
    #print-area .print-title {
      text-align: center;
      font-size: 1.5rem;
      font-weight: bold;
      line-height: 2rem;
      padding: 0.5px;
    }
    
    #print-area .under-title {
      text-align: center;
      font-size: 0.8rem;
      font-style: italic;
      line-height: 0.8rem;
      padding: 0.5px;
    }
    
    #print-area * {
      font-size: 12px;
      text-shadow: initial !important;
      font-family: Open Sans, sans-serif;
    }
    
    .voucher-logo-image {
      height: 60px;
    }
    
    .print-voucher-detail-table {
      page-break-inside: auto;
    }
    
    .print-voucher-detail-table tr {
      page-break-inside: avoid;
      page-break-after: auto;
    }
    
    .print-voucher-detail-table tr td,
    .print-voucher-detail-table tr th {
      border-bottom: 1px solid #ccc;
      vertical-align: top;
    }
    
    #print-area .under-line {
      border-bottom: 1px solid #ccc;
      padding-right: 5px;
    }
    
    #print-area .text-align-right {
      text-align: right;
    }
    #print-area .text-align-left {
      text-align: left;
    }
    
    #print-area .print-voucher-detail-line {
      line-height: 1rem;
    }
    
    #print-area .print-voucher-detail-line td,
    #print-area .print-voucher-detail-header td {
      padding-left: 3px;
      padding-right: 3px;
    }
    #print-area .nowrap {
      white-space: nowrap;
    }
    
    #print-area .print-voucher-detail-thumnail {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 3px;
      width: 100%;
      height: 1.5cm;
    }
    
    #print-area .print-voucher-detail-line td {
      padding-top: 2px;
      padding-bottom: 2px;
    }
    
    .print-body {
      background-color: #fff;
      color: #000;
      padding: 1rem 1rem;
    }
    
    @media print {
      body {
        background: #fff !important;
      }
      #print-area {
        min-height: 29.7cm;
        margin: 0;
        padding: 0;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
      }
    
      #print-area .print-footer {
        display: block !important;
        position: fixed;
        bottom: 10px;
        right: 15px;
        font-style: italic;
      }
    }
    
    .row {
      width: 100%;
    }
    
    .col-sm-1 {
      -webkit-box-flex: 0;
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }
    
    .col-sm-2 {
      -webkit-box-flex: 0;
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    
    .col-sm-3 {
      float: left;
      width: 25%;
    }
    
    .col-sm-4 {
      -webkit-box-flex: 0;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    
    .col-sm-5 {
      -webkit-box-flex: 0;
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
    
    .col-sm-6 {
      -webkit-box-flex: 0;
      flex: 0 0 50%;
      max-width: 50%;
    }
    
    .col-sm-7 {
      -webkit-box-flex: 0;
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    
    .col-sm-8 {
      -webkit-box-flex: 0;
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    
    .col-sm-9 {
      -webkit-box-flex: 0;
      flex: 0 0 75%;
      max-width: 75%;
    }
    
    .col-sm-10 {
      -webkit-box-flex: 0;
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }
    
    .col-sm-11 {
      -webkit-box-flex: 0;
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }
    
    .col-sm-12 {
      -webkit-box-flex: 0;
      flex: 0 0 100%;
      max-width: 100%;
    }
    
    .details {
      page-break-inside: auto;
    }
    
    .grid-item {
      margin: 5px;
    }
    
    .grid-item .image {
      height: 125px;
      border: 1px solid #ccc;
      border-radius: 5px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
    
    .grid-item .name {
      overflow: hidden;
      line-height: 16px;
      font-weight: bold;
      font-size: 12px !important;
    }
    
    .grid-item .price {
      height: 16px;
      font-weight: bold;
      color: #a91a49;
      font-size: 14px !important;
      line-height: 18px;
    }
    
    .grid-item .unit {
      float: right;
      color: #000;
      text-transform: lowercase;
    }
    
    .page-break-after {
      page-break-inside: avoid;
      page-break-after: auto;
      display: table;
    }
    
    .page-break-before {
      page-break-inside: avoid;
      page-break-before: auto;
      display: table;
    }
    
    .print-voucher-detail-table.with-border {
      border-left: 1px solid #ccc;
      border-top: 1px solid #ccc;
    }
    .print-voucher-detail-table.with-border tr th,
    .print-voucher-detail-table.with-border tr td {
      border-right: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
    }
    .text-label {
      font-weight: bold;
    }
    
    .print-voucher-detail-table thead {
      font-weight: bold;
    }
    .print-voucher-detail-table thead td {
      border-bottom: 1px solid #ccc;
    }

    #print-area {
      width: initial;
      
    }
    .bill {
    }
    .bill .bill-title {
      font-weight: bold;
      font-size: 1.2rem !important;
      text-align: center;
    }
    .bill table thead td {
      font-weight: bold;
      border-bottom: 1px dashed #000;
    }
    .bill table tr td {
      border-bottom: 1px dashed #000;
      vertical-align: top;
    }
    .bill .bill-register-info {
      text-align: center;
      line-height: 1rem;
    }
    .bill .bill-info {
      text-align: center;
    }
    .bill .bill-register-logo img {
      width: 50mm;
    }
    .bill .bill-register-name {
    }
    .bill .bill-register-tax-code {
    }
    .bill .bill-register-tel {
    }
    .bill .bill-register-email {
    }
    .bill .bill-register-website {
    }
    .bill .bill-register-address {
    }
    
    .bill-head-info div {
      border-bottom: dashed #000 1px;
    }
    
  `;

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: 'commerce-pos-order-print',
      path: '/commerce-pos/order-print/:id',
      component: {
        template: /*html*/`
            <div class="page pos-order-print page-form">
              <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a class="link back {{textColorClass}}">
                      <i class="icon icon-back"></i>
                      <span class="if-not-md {{textColorClass}}">{{backTitle}}</span>
                    </a>
                  </div>
                  <div class="title">Print bill</div>
                  <div class="right">
                    <a class="link only-icon {{textColorClass}}" @click="print">
                      <i class="icon f7-icons">printer</i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="page-content ptr-content infinite-scroll-content" @ptr:refresh="refresh" data-ptr-distance="100">
                <div class="ptr-preloader">
                  <div class="preloader"></div>
                  <div class="ptr-arrow"></div>
                </div>
                <div class="block block-strong inset" style="background: #fff">
                  <div class="print-area">
                    <div id="print-area">
                      <div class="bill">
                          <div class="bill-header">
                              <div class="bill-register-info">
                                  <div class="bill-register-logo"><img src="{{js "this.registerInfo && this.registerInfo.posBillLogo || this.registerInfo.voucherLogo"}}"></div>
                                  <div class="bill-register-name">{{registerInfo.companyName}}</div>
                                  <div class="bill-register-tax-code">MST: {{registerInfo.companyTaxCode}}</div>
                                  <div class="bill-register-email">Email: {{registerInfo.email}}</div>
                                  <div class="bill-register-website">Website: {{registerInfo.website}}</div>
                                  <div class="bill-register-tel">ĐT: {{registerInfo.tel}}</div>
                                  <div class="bill-register-address">ĐC: {{registerInfo.address}}</div>
                              </div>
                              <div class="bill-title">PHIẾU BÁO GIÁ</div>
                              <div class="bill-info">{{data.Code}} - {{date data.DateOfSale format="short"}}</div>
                              <div class="barcode" style="text-align: center;">
                                  <img src="{{data.BarCode}}">
                              </div>
                              <div class="bill-head-info">
                                  <div class="bill-object-name">Khách hàng: {{data.ObjectName}}</div>
                                  <div class="bill-object-phone">Số điện thoại: {{data.ObjectPhone}}</div>
                                  <div class="bill-object-address">Địa chỉ: {{data.ObjectAddress}}</div>
                                  <div class="bill-object-email">Email: {{data.ObjectEmail}}</div>
                              </div>
                          </div>
                          <div class="bill-body">
                              <table style="width: 100%;" border="0" cellPadding="2">
                                  <thead>
                                      <td>Hàng hóa/Dịch vụ</td>
                                      <td style="text-align: right;" class="nowrap">Đ.Giá x SL</td>
                                      <td style="text-align: right;" class="nowrap">Thành tiền</td>
                                  </thead>
                                  {{#each data.Details}}
                                  <tr>
                                      <td>
                                          <div>{{Description}} - SKU:{{Sku}}</div>
                                          <div>Số truy xuất:{{id AccessNumbers}}</div>
                                      </td>
                                      <td style="text-align: right">
                                          <div class="bill-price">{{currency Price}}</div>
                                          <div class="bill-quantity" style="white-space: nowrap;">x {{decimal Quantity}} {{text Unit}}</div>
                                      </td>
                                      <td style="text-align: right; white-space: nowrap;">
                                          <div class="bill-tomoney">{{currency ToMoney}}</div>
                                      </td>
                                  </tr>
                                  {{/each}}
                                  <tr>
                                      <td style="text-align: left; font-weight: bold;">{{js "(this.data && this.data.IsDebt) ? 'Công nợ' : ''"}}</td>
                                      <td colspan="2" style="text-align: right; font-weight: bold;">Tổng cộng: {{currency data.Total}}</td>
                                  </tr>
                                  <tr>
                                      <td colspan="3" style="text-align: right; font-weight: bold;">&nbsp;</td>
                                  </tr>
                                  <tr>
                                      <td colspan="3" style="text-align: right; font-weight: bold;">&nbsp;</td>
                                  </tr>
                                  <tr>
                                      <td colspan="3" style="text-align: right; font-weight: bold;">&nbsp;</td>
                                  </tr>
                              </table>
                          </div>
                          <br>
                          <div class="bill-footer" style="text-align: left;">
                              <div>Quý khách giữ lại tem mã vạch trên hàng hóa trong trường hợp đổi trả!</div>
                              <div style="font-style: italic; padding-top: 3px; padding-bottom: 3px; font-weight: bold; font-size: 14px;">Cảm ơn Quý Khách và hẹn gặp lại</div>
                              <div>Phiếu được in từ phần mềm<br>ProBox One - Phần mềm quản trị cộng tác viên chuyên sâu.</div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="block"><br></div>
              </div>
            </div>
        `,
        style: this.style,
        data() {
          return {
            style: $this.style,
            title: $this.title,
            data: {},
            registerInfo: {},
          };
        },
        methods: {
          print() {
            const self: F7ComponentContextExtend = this;
            $this.print(self);
          },
          refresh(e, done) {
            const self: F7ComponentContextExtend = this;
          }
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          pageInit(e, page: F7Page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;

            $this.onComponentInit({ instance: self, page: page }, self.$route.params['id'], null, page).then(curentState => {
              // self.detailDialog = self.$app.dialog.create({
              //   el: page.$el.find('.dialog-detail'),
              //   closeByBackdropClick: true,
              // });
            });

            $this.rootServices.authService.isAuthenticatedOrRefresh().pipe(take(1)).toPromise().then(() => {
              self.$setState({
                backTitle: self.$route.context && self.$route.context.backTitle || 'Back', title: self.$route.context && self.$route.context.title || 'Chat GUI',
              });
            }).catch(err => {
              console.error(err);
              $this.commonService.showError(err);
            });
          },
          // pageBeforeIn(e, page) {
          //     console.log('page before in');
          //     const self: F7ComponentContextExtend = this;
          //     self.refresh();
          // },
          pageAfterIn(e, page) {
            console.log('page after in');
            const self: F7ComponentContextExtend = this;

            $this.onChangedState({ instance: self, page }, self.$route?.params?.id, 'before-in', { doNotRefresh: true }).then(currentState => {
              // self.$setState({
              //     lastUpdateMoment: moment(self.lastUpdate).fromNow(),
              // });
            });
          },
          pageBeforeOut(e, page) {
            // console.log('page before out');
            // // Call caback function
            const self: F7ComponentContextExtend = this;
            // if (this.$route?.context && this.$route?.context['callback']) {
            //   this.$route?.context['callback']($this.currentState.data, { lastAction: $this.currentState.lastAction });
            // }
            // (self.keydownSubscription as Subscription).unsubscribe();
            // const index = $this.orderList.findIndex(f => f.id == self.$route?.params?.id);
            // $this.orderList.splice(index, 1);
            // console.log($this.orderList);
            console.log('Before out');
          },
          // pageAfterOut(e, page) {
          //     console.log('page after out');
          // },
          // pageBeforeUnmount(e, page) {
          //     console.log('page before unmount');
          // },
          // pageBeforeRemove(e, page) {
          //     console.log('page before remove');
          // },
          pageBeforeRemove(e, page) {
            console.log('[page event] before remove', page.route.url);
            const self: F7ComponentContextExtend = this;
            // const starmtSelectRoles = self.$app.smartSelect.get('.smart-select');
            // starmtSelectRoles.destroy();
            const currentState = $this.state[self.$route.params.id];

            console.log('page before out');
            // Call caback function
            if (this.$route?.context && this.$route?.context['callback']) {
              this.$route?.context['callback']($this.currentState.data, { lastAction: $this.currentState.lastAction });
            }
            // const index = $this.orderList.findIndex(f => f.id == self.$route?.params?.id);
            // $this.orderList.splice(index, 1);
            // console.log($this.orderList);
          },
        },
      },
    };
  }
}
