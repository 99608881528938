import { Router } from 'framework7/build/core/modules/router/router';
import { Messages } from 'framework7/build/core/components/messages/messages';
import * as moment from 'moment';
import { Searchbar } from 'framework7/build/core/components/searchbar/searchbar';
import { isPlatform } from '@ionic/angular';
import { Dialog } from 'framework7/build/core/components/dialog/dialog';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { BaseComponent, ComponentState } from 'vendors/smart-bot-app/src/app/lib/base-component';
import { ChatRoomModel } from 'vendors/smart-bot-app/src/app/model/chat-room.model';
import { RootServices } from 'vendors/smart-bot-app/src/app/services/root.services';
import { CommonService } from 'vendors/smart-bot-app/src/app/services/common.service';
import { F7ComponentContext, F7Messagebar, F7Page } from 'src/app/types/framework7-types';

export interface ComponentStateExtend extends ComponentState {
    [key: string]: any;
}

export class F7ComponentContextExtend extends F7ComponentContext {
    messagebar?: F7Messagebar;
    messages?: Messages.Messages;
    images?: string[];
    responseInProgress?: boolean;
    answers?: string[];
    people?: { name?: string, avatar?: string }[];
    hideToolbar?: () => void;
    sheetToggle?: () => void;
    deleteAttachment?: (e: MouseEvent, index: number) => void;
    handleAttachment?: (e: MouseEvent) => void;
    checkAttachments?: () => void;
    sendMessage?: () => void;
    $root: any;
    searchBar?: Searchbar.Searchbar;
    commissions?: ChatRoomModel[];

    createChatRoomDialog?: Dialog.Dialog;
    filterDialog?: Dialog.Dialog;
    createWorkplaceDialog?: Dialog.Dialog;
    mode?: string;
    callback?: (task: ChatRoomModel) => void;

    refresh?: (e?: Event, done?: () => void) => Promise<boolean>;
}

/** Component group manager */
export class PageCollaboratorCommissionsComponent extends BaseComponent<ComponentStateExtend> {
    // states: { [key: string]: State } = {};

    title = 'Danh sách chốt chiết khấu';

    stateIndex = {
        APPROVED: 'đã duyệt',
        NOTJUSTAPPROVED: 'chưa duyệt',
        COMPLETE: 'hoàn tất',
        CONFIRMATIONREQUESTED: 'yêu cầu xác nhận',
        CONFIRMED: 'đã xác nhận',
    };

    stateColorIndex = {
        APPROVED: 'green',
        NOTJUSTAPPROVED: 'red',
        COMPLETE: 'green',
        CONFIRMATIONREQUESTED: 'orange',
        CONFIRMED: 'blue',
    };

    constructor(
        public rootServices: RootServices,
        public commonService: CommonService,
        public datePipe: DatePipe,
        public currencyPipe: CurrencyPipe,
    ) {
        super(rootServices);
        // // console.log('Click here to open ts file');
    }

    onF7pageRemove(chatRoomId: string) {
        // if (this.chatRoomCacheList[chatRoomId]) {
        //   this.chatRoomCacheList[chatRoomId].disconnect();
        // }
    }

    preapreTaskState(t: ChatRoomModel) {
        // const unread = t.NumOfMessage - t.LoggedNumOfMessage - t.LoggedNumOfReadMessage;
        t.LastUpdate_Moment = moment(t.DateOfOrderText).fromNow();
        t.CommissionFromText = this.datePipe.transform(t.CommissionFrom, 'shortDate');
        t.CommissionToText = this.datePipe.transform(t.CommissionTo, 'shortDate');
        t.StateColor = this.stateColorIndex[t.State];
        t.AmountText = this.currencyPipe.transform(t.Amount, 'VND');
        // t.StateLabel = { 'OPEN': 'Mở', 'NEWSESSION': 'Mới', 'ACCEPT': 'Tiếp nhận', 'CLOSE': 'Đóng', 'COMPLETE': 'Hoàn tất', 'CANCEL': 'Hủy' }[t.State];
        // if (t.State in ['OPEN', 'NEWSESSION']) {
        //     t.ActionMemberName = t.OpenByMemberName;
        // }
        // if (t.State === 'ACCEPT') {
        //     t.ActionMemberName = t.AcceptByMemberName;
        // }
        // if (t.State === 'CLOSE') {
        //     t.ActionMemberName = t.CloseByMemberName;
        // }
        // if (t.State === 'COMPLETE') {
        //     t.ActionMemberName = t.CompleteByMemberName;
        // }
        // if (t.State === 'CANCEL') {
        //     t.ActionMemberName = t.CancelByMemberName;
        // }
        // t.NumOfUnreadMessage = unread > 0 ? unread : '';
        t.StateText = this.stateIndex[t.State];
        return t;
    }

    get f7Component(): Router.RouteParameters {
        const $this = this;
        return {
            name: 'page-collaborator-commissions',
            path: '/page-collaborator-commissions/:pageId',
            component: {
                template: /*html*/`
                <div class="page">
                    <div class="navbar">
                        <div class="navbar-bg"></div>
                        <div class="navbar-inner sliding">
                            <div class="left">
                                <a class="link back {{textColorClass}}">
                                    <i class="icon icon-back"></i>
                                    <span class="if-not-md {{textColorClass}}">{{backTitle}}</span>
                                </a>
                            </div>
                            <div class="title text-color-default">{{title}}</div>
                            <div class="right">
                                <!-- Link to enable searchbar -->
                                <a class="link icon-only searchbar-enable text-color-default" data-searchbar=".workplace-searchbar">
                                    <i class="icon f7-icons if-not-md">search</i>
                                    <i class="icon material-icons md-only">search</i>
                                </a>
                            </div>
                            <!-- Searchbar is a direct child of "navbar-inner" -->
                            <form class="searchbar workplace-searchbar searchbar-expandable">
                                <div class="searchbar-inner">
                                    <div class="searchbar-input-wrap">
                                        <input type="search" placeholder="#thẻ/@thành viên/+tin nhắn/tiêu đề" />
                                        <i class="searchbar-icon"></i>
                                        <span class="input-clear-button"></span>
                                    </div>
                                    <span class="searchbar-disable-button text-color-default">Cancel</span>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="page-content ptr-content infinite-scroll-content" data-ptr-mousewheel="false" @ptr:refresh="refresh">
                        <div class="ptr-preloader">
                            <div class="preloader"></div>
                            <div class="ptr-arrow"></div>
                        </div>
                        <div class="searchbar-backdrop workplace-searchbar-backdrop"></div>
                
                        
                        <!--<div class="block inset block-strong page-banner bg-color-gray{{js "!this.pageInfo ? ' skeleton-text skeleton-effect-blink' : ''"}}" style="background-image: url({{js "this.pageInfo && this.pageInfo.Banner && this.pageInfo.Banner.OriginImage"}});"></div>-->
                        <div class="block inset block-strong page-bannerx {{js "!this.pageInfo ? ' skeleton-text skeleton-effect-blink' : ''"}}" style="padding: 0; overflow: hidden">
                            <img src="{{js "this.pageInfo && this.pageInfo.Banner && this.pageInfo.Banner.OriginImage"}}" style="width: 100%; margin-bottom: -6px">
                        </div>
                        <div class="block block-strong inset{{js "!this.pageInfo ? ' skeleton-text skeleton-effect-blink' : ''"}}">
                            <div class="page-header">
                                <div class="logo bg-color-gray" style="background-image: url({{js "this.pageInfo && this.pageInfo.Logo && this.pageInfo.Logo.OriginImage"}});"></div>
                                <div class="info">
                                    <div class="name">{{js "this.pageInfo && this.pageInfo.Name"}}</div>
                                    <div class="summary">{{js "this.pageInfo && this.pageInfo.Summary"}}</div>
                                    <div class="tag">@{{js "this.pageInfo && this.pageInfo.TagName"}}</div>
                                </div>
                            </div>
                        </div>
                
                        <div class="block-title">Danh sách chốt chiết khấu</div>
                        {{#js_if "this.commissions"}}
                        {{#js_if "this.commissions.length > 0"}}
                        <div class="list media-list workplace-list inset">
                            <ul>
                                {{#each commissions}}
                                <li data-id="{{Code}}" data-chat-room="{{Code}}" @click="openChatRoom">
                                    <a class="item-link item-content">
                                        <div class="item-inner">
                                            <div class="item-title-row">
                                                <div class="item-title text-color-default description" style="font-size: 0.9rem">
                                                    {{Description}}</div>
                                                <div class="item-after text-color-blue" style="font-size: 0.8rem"><span
                                                        class="badge color-{{StateColor}} unread">{{StateText}}</span></div>
                                            </div>
                                            <div class="item-subtitle text-color-gray">
                                                <div class="chat-list-item-label"><i
                                                        class="icon f7-icons">timer_fill</i>{{CommissionFromText}} -
                                                    {{CommissionToText}}</div>
                                            </div>
                                            <div class="item-subtitle text-color-gray">
                                                <div class="chat-list-item-label">
                                                    <i class="icon f7-icons">person_crop_square_fill</i>Số tiền
                                                </div>
                                                <div class="text-color-blue lastUpdateMoment" style="float: right">{{AmountText}}
                                                </div>
                                            </div>
                                            {{#if Title}}<div class="item-text"><i class="icon f7-icons">tickets_fill</i><span
                                                    class="description">{{Title}}</span></div>{{/if}}
                                            {{#if LastMessageText}}<div class="item-text chat-list-item-label"><i
                                                    class="icon f7-icons">captions_bubble_fill</i><span
                                                    class="lastMessageUserName">{{ObjectName}}</span></div>{{/if}}
                                            <div class="item-text" style="clear: both; max-height: initial;"><span
                                                    class="lastMessageText">
                                                    <div class="chip">
                                                        <div class="chip-label text-color-gray" style="font-weight: bold">#{{Code}}
                                                        </div>
                                                    </div>
                                                    {{#each Tags}}
                                                    <div class="chip">
                                                        <div class="chip-label text-color-gray" style="font-weight: bold">#{{this}}
                                                        </div>
                                                    </div>
                                                    {{/each}}
                                                </span></div>
                                        </div>
                                    </a>
                                </li>
                                {{/each}}
                            </ul>
                            {{#if infiniteLoadLast}}
                            <div style="text-align: center" class="text-color-gray">end</div>
                            {{else}}
                            <div class="block text-align-center"><div class="preloader"></div></div>
                            {{/if}}
                        </div>
                        {{else}}
                        <div class="block block-strong inset bg-color-orange text-color-white">
                            Chưa có phiếu chốt chiết khấu nào !<br>
                            Nếu đã đến kỳ chốt sổ mà bạn vẫn chưa thấy phiếu chốt chiết khấu thì có thể bạn chưa bán được hàng hoặc đơn hàng của bạn chưa hoàn tất, hãy liên hệ với trang <a @click="gotoPage"><b>{{js "this.pageInfo && this.pageInfo.Name"}}</b></a> để được kiểm tra lại.<br>
                            Xin cám ơn !
                        </div>
                        {{/js_if}}
                        {{else}}
                        <div class="list media-list workplace-list inset">
                            <ul>
                                {{#each ghostItems}}
                                <li class="skeleton-text skeleton-effect-blink">
                                    <a class="item-link item-content">
                                        <div class="item-inner">
                                            <div class="item-title-row">
                                                <div class="item-title text-color-default description" style="font-size: 0.9rem"> lobortis lobortis lobortis lobortis lobortis lobortis lobortis</div>
                                                <div class="item-after text-color-blue" style="font-size: 0.8rem">
                                                    <span class="badge color-green unread">9</span>
                                                </div>
                                            </div>
                                            <div class="item-subtitle text-color-gray">
                                                <div class="chat-list-item-label"><i class="icon f7-icons">timer_fill</i>lobortis lobortis lobortis</div>
                                            </div>
                                            <div class="item-subtitle text-color-gray">
                                                <div class="chat-list-item-label">
                                                    <i class="icon f7-icons">person_crop_square_fill</i>lobortis lobortis
                                                </div>
                                                <div class="text-color-blue lastUpdateMoment" style="float: right">lobortis&nbsp;₫
                                                </div>
                                            </div>
                                            <div class="item-text" style="clear: both; max-height: initial;">
                                                <span class="lastMessageText"><div class="chip"><div class="chip-label text-color-gray" style="font-weight: bold">lobortis lobortis</div></div></span>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                {{/each}}
                            </ul>
                        </div>
                        {{/js_if}}
                    </div>
                </div>
              `,
                style: ``,
                data() {
                    const self: F7ComponentContextExtend = this;
                    return {
                        title: self.title || $this.title,
                        backTitle: 'Back',
                        commissions: null,
                        infiniteLoadLast: true,
                        disabledLeftPannel: false && isPlatform('desktop'),
                        lastUpdateMoment: moment(Date.now()).fromNow(),
                        filter: null,
                        mode: '',
                        unreadFilterStatus: false,
                        // pageInfo: {},
                        ghostItems: [1, 2],
                    };
                },
                methods: {
                    gotoPage(e) {
                        const self: F7ComponentContextExtend = this;
                        $this.rootServices.navigate("/page/" + self.$route.params.pageId + "/");
                    },
                    toggleUnreadFilter(e) {
                        const self: F7ComponentContextExtend = this;
                        const unreadCheckbox = self.filterDialog.$el.find('.field[name="filter_UnreadState"]');
                        unreadCheckbox.click();
                        self.applyFilter(e);
                        self.$setState({ unreadFilterStatus: unreadCheckbox[0] && unreadCheckbox[0]['checked'] });
                    },
                    openFilterDialog(e) {
                        const self: F7ComponentContextExtend = this;
                        self.filterDialog && self.filterDialog.open();
                        console.log('Current filter: ', self.filter);
                    },
                    closeFilterDialog(e) {
                        const self: F7ComponentContextExtend = this;
                        if (self.filterDialog) {
                            const fieldEles = self.filterDialog.$el.find('.field');
                            fieldEles.each((index, el) => {
                                self.$(el).prop('checked', false);
                            });
                            self.$setState({ filter: null });
                            self.filterDialog.close();
                            self.refresh(null, () => { });
                        }
                    },
                    applyFilter(e) {
                        const self: F7ComponentContextExtend = this;
                        self.filterDialog && self.filterDialog.close();
                        const fieldEles = self.filterDialog.$el.find('.field');
                        console.log(fieldEles);
                        const filter: { [key: string]: any } = {};
                        fieldEles.each((index, el) => {
                            console.log(index, el.checked);
                            if (el.checked) {
                                filter[el.name] = el.value;
                            }
                        });
                        console.log(filter);
                        self.$setState({ filter: Object.keys(filter).length > 0 ? filter : null });
                        self.refresh(null, () => { });
                    },
                    closeCreateChatRoomDialog() {
                        const self: F7ComponentContextExtend = this;
                        self.createChatRoomDialog && self.createChatRoomDialog.close();
                    },
                    createChatRoom(e: MouseEvent) {
                        const self: F7ComponentContextExtend = this;
                        const chatRoomData: ChatRoomModel = {};
                        const fieldEles = self.createChatRoomDialog.$el.find('.field');
                        fieldEles.each((index, fieldEle) => {
                            console.log(fieldEle);
                            console.log(fieldEle.value);
                            chatRoomData[fieldEle.name] = fieldEle.value;
                        });
                        if (!chatRoomData.Description) {
                            console.error('Bạn chưa điền mô tả');
                            $this.commonService.showError('Bạn chưa điền mô tả');
                            return;
                        }
                        console.log(chatRoomData);
                        self.$app.preloader.show();
                        try {
                            $this.rootServices.apiService.postPromise<ChatRoomModel[]>('/chat/rooms', {}, [chatRoomData]).then(rs => {
                                console.log(rs);
                                if (rs && rs[0] && rs[0].Code) {
                                    $this.commonService.navigate('/chat-room/' + rs[0].Code, {
                                        context: {
                                            backTitle: 'Workplace',
                                            title: rs[0].Description,
                                        }
                                    });
                                    self.createChatRoomDialog.close();
                                    fieldEles.each((index, fieldEle) => {
                                        fieldEle.value = null;
                                        self.$(fieldEle).change();
                                    });
                                    self.$app.preloader.hide();
                                }
                            }).catch(err => {
                                console.error(err);
                                self.$app.preloader.hide();
                                $this.commonService.showError(err);
                            });
                        } catch (err) {
                            console.error(err);
                            self.$app.preloader.hide();
                            $this.commonService.showError(err);
                        }
                    },
                    openCreateTicketDialog(e: MouseEvent) {
                        const self: F7ComponentContextExtend = this;
                        if (self.createChatRoomDialog) {
                            self.createChatRoomDialog.open();
                            self.createChatRoomDialog.$el.find('.field[name="Description"]').focus();
                        }
                    },
                    openCreateWorkplaceDialog(e: MouseEvent) {
                        const self: F7ComponentContextExtend = this;
                        if (self.createWorkplaceDialog) {
                            self.createWorkplaceDialog.open();
                            self.createWorkplaceDialog.$el.find('.field[name="Description"]').focus();
                        }
                    },
                    closeCreateWorkplaceDialog() {
                        const self: F7ComponentContextExtend = this;
                        self.createWorkplaceDialog && self.createWorkplaceDialog.close();
                    },
                    createWorkplace(e: MouseEvent) {
                        const self: F7ComponentContextExtend = this;
                        const chatRoomData: ChatRoomModel = {};
                        const fieldEles = self.createWorkplaceDialog.$el.find('.field');
                        fieldEles.each((index, fieldEle) => {
                            console.log(fieldEle);
                            console.log(fieldEle.value);
                            chatRoomData[fieldEle.name] = fieldEle.value;
                        });
                        if (!chatRoomData.Description) {
                            console.error('Bạn chưa điền mô tả');
                            $this.commonService.showError('Bạn chưa điền mô tả');
                            return;
                        }
                        chatRoomData.Type = 'WORKPLACE';
                        chatRoomData.State = 'OPEN';
                        console.log(chatRoomData);
                        self.$app.preloader.show();
                        try {
                            $this.rootServices.apiService.postPromise<ChatRoomModel[]>('/chat/rooms', {}, [chatRoomData]).then(rs => {
                                console.log(rs);
                                if (rs && rs[0] && rs[0].Code) {
                                    $this.commonService.navigate('/chat-room/' + rs[0].Code, {
                                        context: {
                                            backTitle: 'Workplace',
                                            title: rs[0].Description,
                                        }
                                    });
                                    self.createWorkplaceDialog.close();
                                    fieldEles.each((index, fieldEle) => {
                                        fieldEle.value = null;
                                        self.$(fieldEle).change();
                                    });
                                    self.$app.preloader.hide();
                                }
                            }).catch(err => {
                                console.error(err);
                                self.$app.preloader.hide();
                                $this.commonService.showError(err);
                            });
                        } catch (err) {
                            console.error(err);
                            self.$app.preloader.hide();
                            $this.commonService.showError(err);
                        }
                    },
                    async refresh(e, done) {
                        const self: F7ComponentContextExtend = this;
                        // Refresh whene overtime 5 minutes
                        let doneTimeout = null;
                        if (done) {
                            doneTimeout = setTimeout(() => {
                                done();
                            }, 10000);
                        };
                        if (done || ($this.rootServices.authService.loginState$.getValue() && (!self.commissions || self.commissions.length === 0 || !self.lastUpdate || self.lastUpdate + 300000 < Date.now()))) {
                            console.log('refresh chat room list');
                            self.commissions = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/commission-vouchers', {
                                search: encodeURIComponent(self.searchBar.query),
                                sort_CommissionTo: 'desc',
                                page: self.$route.params?.pageId,
                                limit: 10,
                                offset: 0,
                                ...self.filter,
                            }).catch(err => {
                                if (done) done();
                                if (doneTimeout) clearTimeout(doneTimeout);
                                return Promise.reject(err);
                            });
                            if (doneTimeout) clearTimeout(doneTimeout);

                            const pageInfo = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/pages/' + self.$route.params?.pageId, {}).then(rs => rs[0]);
                            self.$setState({
                                pageInfo: pageInfo,
                                commissions: self.commissions.map(t => {
                                    // Moment.now()
                                    // const unread = t.NumOfMessage - t.LoggedNumOfMessage - t.LoggedNumOfReadMessage;
                                    // t.LastUpdate_Moment = moment(t.LastUpdate).fromNow();
                                    // t.DateOfCreate = new Date(t.DateOfCreate).toLocaleString();
                                    // t.Infos.Request_Date_Time = new Date(t.Infos.Request_Date_Time).toLocaleTimeString();
                                    $this.preapreTaskState(t);
                                    return t;
                                }), lastUpdate: Date.now()
                            });
                            self.infiniteOffset = 10;

                            self.$setState({ infiniteLoadLast: self.commissions.length === 0 });
                            if (done) done();

                            self.updateBadge();
                        } else {
                            self.$setState({
                                lastUpdateMoment: moment(self.lastUpdate).fromNow(),
                            });
                        }
                        return true;
                    },
                    async openChatRoom(e) {
                        const self: F7ComponentContextExtend = this;
                        const id = self.$(e.target).closest('li').data('id');
                        $this.commonService.navigate('/page-collaborator-commission/' + id, { context: { commission: self.commissions, pageInfo: self.pageInfo } });

                    },
                    updateBadge(e) {
                        const self: F7ComponentContextExtend = this;
                        let unreadCount = 0;
                        if (self.commissions) {
                            for (const cahtRoom of self.commissions) {
                                if (cahtRoom.NumOfUnreadMessage) {
                                    unreadCount += parseInt(cahtRoom.NumOfUnreadMessage);
                                }
                            }
                            $this.rootServices.workplaceBadge = unreadCount;
                        }
                    },
                },
                on: {
                    pageInit(e, page: F7Page) {
                        console.log('page init');
                        const self: F7ComponentContextExtend = this;
                        if (self.$route.params['id']) {
                            self.mode = self.$route.params['id'];
                        }
                        const pageId = self.$route.params?.pageId;
                        $this.onComponentInit({ instance: self }, 'main').then(async currentState => {
                            // Init create chat room dialog
                            // self.createChatRoomDialog = self.$app.dialog.create({
                            //     el: page.$el.find('.dialog-create-chat-room'),
                            //     closeByBackdropClick: true,
                            // });
                            // self.createWorkplaceDialog = self.$app.dialog.create({
                            //     el: page.$el.find('.dialog-create-workplace-dialog'),
                            //     closeByBackdropClick: true,
                            // });
                            // self.filterDialog = self.$app.dialog.create({
                            //     el: page.$el.find('.dialog-filter'),
                            //     closeByBackdropClick: true,
                            // });
                            // self.filterDialog.$el.find('.field').each((index, field) => {
                            //     self.$(field).change((e: Event) => {
                            //         console.log(e);
                            //         if (e.target['name'] === 'filter_State' && e.target['checked']) {
                            //             self.filterDialog.$el.find('.field[name="filter_State"]').filter((index, el) => el.value !== e.target['value']).prop('checked', false);
                            //         }
                            //     });
                            // });
                            const pageInfo = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/pages/' + pageId, {}).then(rs => rs[0]);
                            self.$setState({ pageInfo: pageInfo })
                        });

                        // Loading flag
                        let allowInfinite = true;
                        // Last loaded index
                        var lastItemIndex = self.$$('.list li').length;

                        // Max items to load
                        var maxItems = 200;

                        // Append items per load
                        var itemsPerLoad = 20;

                        self.infiniteOffset = 0;

                        self.searchBar = self.$app.searchbar.create({
                            el: '.workplace-searchbar',
                            backdrop: true,
                            backdropEl: '.workplace-searchbar-backdrop',
                            searchContainer: '.list',
                            searchIn: '.item-text',
                            customSearch: true,
                            on: {
                                search(sb, query, previousQuery) {
                                    $this.commonService.takeUntil('heldpesk-ticket-search', 600).then(() => {
                                        console.log(query, previousQuery);
                                        $this.rootServices.apiService.getPromise<any[]>('/collaborator/commission-vouchers', {
                                            search: encodeURIComponent(query),
                                            sort_CommissionTo: 'desc',
                                            eq_Page: self.$route.params?.pageId,
                                            limit: 10,
                                            offset: 0,
                                            ...self.filter,
                                        }).then(commissions => {
                                            self.$$(page.el).find('.page-content').scrollTo(0, 0);
                                            self.$setState({ commissions: commissions.map(t => $this.preapreTaskState(t)), infiniteLoadLast: commissions.length === 0 });
                                            self.infiniteOffset = 0;
                                        }).catch(err => {
                                            console.error(err);
                                            $this.commonService.showError(err);
                                        });
                                    });
                                }
                            }
                        });

                        // Attach 'infinite' event handler
                        self.$$(page.el).find('.infinite-scroll-content').on('infinite', function () {
                            // Exit, if loading in progress
                            if (!allowInfinite) return;

                            // Set loading flag
                            allowInfinite = false;

                            // Emulate 1s loading
                            $this.rootServices.apiService.getPromise<any[]>('/collaborator/commission-vouchers', {
                                search: self.searchBar.query,
                                sort_CommissionTo: 'desc',
                                eq_Page: self.$route.params?.pageId,
                                limit: 10,
                                offset: self.infiniteOffset,
                                ...self.filter,
                            }).then(commissions => {
                                commissions.map(t => {
                                    // Moment.now()
                                    // const unread = t.NumOfMessage - t.LoggedNumOfMessage - t.LoggedNumOfReadMessage;
                                    // t.LastUpdate_Moment = moment(t.LastUpdate).fromNow();
                                    // t.DateOfCreate = new Date(t.DateOfCreate).toLocaleString();
                                    // // t.Infos.Request_Date_Time = new Date(t.Infos.Request_Date_Time).toLocaleTimeString();
                                    // t.NumOfUnreadMessage = unread > 0 ? unread : '';
                                    $this.preapreTaskState(t);
                                    return t;
                                });
                                self.$setState({ commissions: self.commissions.concat(commissions), infiniteLoadLast: commissions.length === 0 });
                                self.infiniteOffset += 10;
                                lastItemIndex = self.$$('.list li').length;
                                allowInfinite = true;

                                self.updateBadge();
                            }).catch(err => {
                                console.error(err);
                                $this.commonService.showError(err);
                            });
                        });

                        // if (page.router.history && page.router.history[page.router.history.length - 1] === page.route.url) {
                        //     self.refresh();
                        // }
                    },
                    pageAfterIn(e, page) {
                        console.log('p[page event]age after in', page.route.url);
                        const self: F7ComponentContextExtend = this;
                        $this.onChangedState({ instance: self, page }, 'main', 'before-in').then(currentState => {
                            self.$setState({
                                lastUpdateMoment: moment(self.lastUpdate).fromNow(),
                            });
                        });
                    },
                    pageBeforeIn(e, page: F7Page) {
                        console.log('[page event] before in', page.route.url);
                        const self: F7ComponentContextExtend = this;

                        if ($this.rootServices.changedChatRooms.workplace.length > 0) {
                            const updatedRooms = [... new Set($this.rootServices.changedChatRooms.workplace)];
                            $this.rootServices.apiService.getPromise<ChatRoomModel[]>('/chat/rooms', { id: updatedRooms }).then(chatRoomInfos => {
                                const newChatRooms = self.commissions.map(oldChatRoom => {
                                    const newChatRoom = chatRoomInfos.find(f => f.Code === oldChatRoom.Code);
                                    if (newChatRoom) {
                                        // newChatRoom.LastUpdate_Moment = moment(newChatRoom.LastUpdate).fromNow();
                                        // newChatRoom.DateOfCreate = new Date(newChatRoom.DateOfCreate).toLocaleString();
                                        // const unread = newChatRoom.NumOfMessage - newChatRoom.LoggedNumOfMessage - newChatRoom.LoggedNumOfReadMessage;
                                        // newChatRoom.NumOfUnreadMessage = unread > 0 ? unread : '';

                                        $this.preapreTaskState(newChatRoom);
                                        return newChatRoom;
                                    }
                                    return oldChatRoom;
                                });
                                self.$setState({
                                    commissions: newChatRooms,
                                });
                                $this.rootServices.changedChatRooms.workplace = [];

                                self.updateBadge();
                            }).catch(err => {
                                console.error(err);
                                $this.commonService.showError(err);
                            });
                        }
                    },
                }
            },
        };
    }
}
