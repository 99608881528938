export const environment = {
  mode: 'production',
  version: '1.5.1',
  coreVersion: '4.17',
  production: true,
  bundleId: 'com.namsoftware.smartbot',
  name: 'Smart-BOT',
  title: 'App vận hành nội bộ thuộc ProBox One',
  copyright: 'Copyright ProBox năm 2017',
  api: {
    defaultUrl: 'https://user.probox.vn/v3/users',// check
    version: 'v3'
  },
  authentication: {
    notLoggedInUrl: '/recent-logins',
  },
  notification: {
    senderId: 316262946834,
  },
  tokenStorageKey: 'jwt-token',
  deployment: {
    ios: {
      baseBuild: 392,
      key: 'o42s6NQINffaNrRK8VhWcpygDwkgpPsZlz1cc',
    },
    android: {
      baseBuild: 375,
      key: 'xrTYGssFkYhFqLw842GR5dVbiVL8NOXPXQ_mJ',
    },
  },
  google: {
    maps: {
      apiKey: 'AIzaSyDjQl1YsJdrESCyYpxIXTFs7oTiL1nxKY8',
    },
  },
  chat: {
    requireLocation: true
  }
};
