import { Model } from "./model";

export class DeplpoymentVoucherModel extends Model {

    static getInstance(properties?: Partial<DeplpoymentVoucherModel>) {
        return new DeplpoymentVoucherModel(properties);
    }

}

export class DeplpoymentVoucherDetailModel extends Model {

    static getInstance(properties?: Partial<DeplpoymentVoucherDetailModel>) {
        return new DeplpoymentVoucherDetailModel(properties);
    }

}