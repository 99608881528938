import { F7ComponentContext, F7Page } from '../../types/framework7-types';
import { Router } from 'framework7/build/core/modules/router/router';
import { BaseComponent, ComponentState } from '../../lib/base-component';
import { RootServices } from '../../services/root.services';
import { take } from 'rxjs/operators';
import { Member } from '../../lib/nam-chat/model/member';
import { ChatRoom } from '../../lib/nam-chat/chat-room';
import { CommonService } from '../../services/common.service';
import { TaskReminderModel } from '../../model/task.model';
import { BehaviorSubject } from 'rxjs';
import { ProductModel } from '../../model/product.model';

export interface ComponentStateExtend extends ComponentState {
  [key: string]: any;
}

export class F7ComponentContextExtend extends F7ComponentContext {
  // messagebar?: F7Messagebar;
  // messages?: Messages.Messages;
  // images?: string[];
  responseInProgress?: boolean;
  // answers?: string[];
  // people?: { name?: string, avatar?: string }[];
  // hideToolbar?: () => void;
  // sheetToggle?: () => void;
  // deleteAttachment?: (e: MouseEvent, index: number) => void;
  // handleAttachment?: (e: MouseEvent) => void;
  // checkAttachments?: () => void;
  // sendMessage?: () => void;
  productList?: ProductModel[];
  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, chatRoom?: ChatRoom } };
}

/** Component group manager */
export class WarehouseContainerListComponent extends BaseComponent<ComponentStateExtend> {
  // states: { [key: string]: State } = {};

  title: 'Vị trí'

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
  ) {
    super(rootServices);
    // // console.log('Click here to open ts file');
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  prepareItem(p: ProductModel) {
    // if (p.FindOrder) {
    //   p.Path = `${p.FindOrder} - ${p.Path}`;
    // }
    if (p.Goods && p.Goods.length > 0) {
      p.GoodsTexts = [];
      for (const goods of p.Goods) {
        p.GoodsTexts.push(this.commonService.getObjectText(goods) + ' (' + goods.UnitLabel + ')');
        if (goods.GoodsThumbnail) {
          p.GoodsThumbnail = goods.GoodsThumbnail?.SmallImage;
        }
      }
      p.GoodsText = p.GoodsTexts.join('<br>');
    }
    return p;
  }

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: 'warehouse-container-list',
      path: '/warehouse/container-list',
      component: {
        template: /*html*/`
            <div class="page no-toolbar-x warehouse-container-list" data-name="warehouse-container-list">
              <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a class="link back {{textColorClass}}">
                      <i class="icon icon-back"></i>
                      <span class="if-not-md {{textColorClass}}">{{js "this.backTitle || 'Back'"}}</span>
                    </a>
                  </div>
                  <div class="title">Vị trí hàng hóa</div>
                  <div class="right">
                    <!-- Link to enable searchbar -->
                    <a class="link icon-only searchbar-enable {{textColorClass}}" data-searchbar=".container-list-searchbar">
                      <i class="icon f7-icons if-not-md">search</i>
                      <i class="icon material-icons md-only">search</i>
                    </a>
                  </div>
                  <!-- Searchbar is a direct child of "navbar-inner" -->
                  <form class="searchbar container-list-searchbar searchbar-expandable">
                    <div class="searchbar-inner">
                      <div class="searchbar-input-wrap">
                        <input type="search" placeholder="Search" />
                        <i class="searchbar-icon"></i><span class="input-clear-button"></span>
                        
                      </div>
                      <span class="searchbar-disable-button {{textColorClass}}">Hủy</span>
                    </div>
                  </form>
                </div>
              </div>
              <div class="page-content ptr-content infinite-scroll-content" @ptr:refresh="refresh">
                <div class="ptr-preloader">
                  <div class="preloader"></div>
                  <div class="ptr-arrow"></div>
                </div>
                <div class="searchbar-backdrop container-list-backdrop"></div>
            
                <div class="action-bar block block-strong inset">
                  <div class="row">
                    <div class="col-25 tablet-15 action-bar-item text-color-gray link goto" @click="goto" data-url="/warehouse/goods-list">
                      <div class="demo-icon-icon"><i class="icon f7-icons" >cube_box</i></div>
                      <div class="action-bar-item-label">hàng hóa</div>
                    </div>
                    <div class="col-25 tablet-15 action-bar-item text-color-gray link goto" @click="goto" data-url="/warehouse/container-list">
                      <div class="demo-icon-icon"><i class="icon f7-icons">tray_2_fill</i></div>
                      <div class="action-bar-item-label">vị trí</div>
                    </div>
                    <div class="col-25 tablet-15 action-bar-item text-color-gray link goto" @click="goto" data-url="/warehouse/inventory-adjust-note-list">
                      <div class="demo-icon-icon"><i class="icon f7-icons">arrow_up_arrow_down_square</i></div>
                      <div class="action-bar-item-label">kiểm kho</div>
                    </div>
                    <div class="col-25 tablet-15 action-bar-item text-color-gray link goto" @click="goto" data-url="/warehouse/book-form/current">
                      <div class="demo-icon-icon"><i class="icon f7-icons">book</i></div>
                      <div class="action-bar-item-label">sổ kho</div>
                    </div>
                  </div>
                </div>

                <div class="block-title">Vị trí hàng hóa</div>
                <div class="list no-hairlines-md cart inset">
                  <ul>
                    {{#each productList}}
                    <li class="item-content product-item" data-id="{{Code}}">
                      <div class="categories">{{this.Path}}</div>
                      <div class="image" style="width: 30%">
                        <div class="image-contain" style="background-image: url('{{this.GoodsThumbnail}}')"></div>
                      </div>
                      <div class="detail" style="width: 70%">
                        <div class="find-order">{{FindOrder}}</div>
                        <div class="name">{{Description}}</div>
                        <div class="goods">
                          {{#if GoodsText}}Hàng hóa: <span class="sales">{{GoodsText}}</span>{{/if}}
                        </div>
                        <div>
                          <i @click="chooseOne" class="f7-icons text-color-default link"
                            style="font-size: 20px; float: left; line-height: 26px; margin-right: 6px;">cart_fill_badge_plus</i>
                          {{#if Tax}}<a class="vat" href="#">{{js "this.Tax && this.Tax.text"}}</a>{{/if}}
                          <div class="to-money" style="line-height: 26px">{{this.ToMoneyText}}</div>
                        </div>
                        <div style="clear: both"></div>
                      </div>
                      {{#js_if "!!this.Promotion"}}
                      <div class="promotion">
                        <span>Chương trình khuyến mãi</span>
                        <div class="chip color-blue">
                          <div class="chip-label">{{this.PromotionText}}</div>
                        </div>
                      </div>
                      {{/js_if}}
                      <div class="clear-both"></div>
                    </li>
                    {{/each}}
                  </ul>
                  {{#if infiniteLoadLast}}
                      <div style="text-align: center" class="text-color-gray">end</div>
                  {{else}}
                      <div class="preloader color-blue infinite-scroll-preloader">
                          <span class="preloader-inner">
                              <span class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                  class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                  class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                  class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                  class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                  class="preloader-inner-line"></span><span class="preloader-inner-line"></span>
                          </span>
                      </div>
                  {{/if}}
                </div>
            
                <div class="block"><br></div>
            
              </div>
            </div>
        `,
        style: /*css*/`
          .warehouse-container-list .find-order {
            font-size: 16px;
            font-weight: bold;
          }
          .warehouse-container-list .action-bar {
            color: var(--f7-block-strong-text-color);
            padding-top: var(--f7-block-padding-vertical);
            padding-bottom: var(--f7-block-padding-vertical);
            background-color: var(--f7-block-strong-bg-color);
          }
        `,
        data() {
          return {
            title: $this.title,
            // reminder: {
            //   No: '234234',
            // },
            priceReport: { Details: [] },
          };
        },
        methods: {
          goto(e) {
            const self: F7ComponentContextExtend = this;
            const url = self.$(e.target).closest('.goto').data('url');
            $this.rootServices.navigate(url);
          },
          chooseOne(e) {
            const self: F7ComponentContextExtend = this;
            const id = self.$(e.target).closest('li').data('id');
            self.$route.context['onChoose'] && self.$route.context['onChoose'](self.productList.find(f => f.Code === id));
          },
          chooseProduct(e) {

          },
          async refresh(e, done) {
            const self: F7ComponentContextExtend = this;

            let doneTimeout = null;
            if (done) {
              doneTimeout = setTimeout(() => {
                done();
              }, 10000);
            };

            // console.log(self.searchBar);
            $this.rootServices.apiService.getPromise<any[]>('/warehouse/goods-containers', {
              search: self?.searchBar?.query && self.searchBar.query || '',
              includeParent: true,
              includePath: true,
              includeWarehouse: true,
              includeGoods: true,
              includeIdText: true,
              includeInventory: self.$route.params['action'] == 'choose',
              limit: 10,
              offset: 0,
              ...self.filter,
            }).then(products => {
              // self.$$(page.el).find('.page-content').scrollTo(0, 0);
              self.$setState({ productList: products.map(t => $this.prepareItem(t)), infiniteLoadLast: products.length === 0 });
              self.infiniteOffset = 10;
              if (done) done();
            }).catch(err => {
              console.error(err);
              $this.commonService.showError(err);
              if (done) done();
            });

            return true;
          },
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          pageInit(e, page: F7Page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;
            // Loading flag
            let allowInfinite = true;

            self.refresh();
            self.searchBar = self.$app.searchbar.create({
              el: '.container-list-searchbar',
              backdrop: true,
              backdropEl: '.container-list-backdrop',
              searchContainer: '.list',
              searchIn: '.item-text',
              customSearch: true,
              on: {
                search(sb, query, previousQuery) {
                  $this.commonService.takeUntil('product-search', 600).then(() => {
                    console.log(query, previousQuery);
                    $this.rootServices.apiService.getPromise<any[]>('/warehouse/goods-containers', {
                      search: query,
                      includeParent: true,
                      includePath: true,
                      includeWarehouse: true,
                      includeGoods: true,
                      includeIdText: true,
                      includeInventory: self.$route.params['action'] == 'choose',
                      limit: 10,
                      offset: 0,
                      ...self.filter,
                    }).then(products => {
                      self.$$(page.el).find('.page-content').scrollTo(0, 0);
                      self.$setState({ productList: products.map(t => $this.prepareItem(t)), infiniteLoadLast: products.length === 0 });
                      self.infiniteOffset = 10;
                    }).catch(err => {
                      console.error(err);
                      $this.commonService.showError(err);
                    });
                  });
                }
              }
            });

            self.$$(page.el).find('.infinite-scroll-content').on('infinite', function () {
              // Exit, if loading in progress
              if (!allowInfinite) return;

              // Set loading flag
              allowInfinite = false;
              // Last loaded index
              var lastItemIndex = self.$$('.list li').length;

              // Emulate 1s loading
              $this.rootServices.apiService.getPromise<any[]>('/warehouse/goods-containers', {
                search: self.searchBar.query,
                includeParent: true,
                includePath: true,
                includeWarehouse: true,
                includeGoods: true,
                includeIdText: true,
                includeInventory: self.$route.params['action'] == 'choose',
                limit: 10,
                offset: self.infiniteOffset,
                ...self.filter,
              }).then(products => {
                products.map(t => {
                  $this.prepareItem(t);
                  return t;
                });
                self.$setState({ productList: self.productList.concat(products), infiniteLoadLast: products.length === 0 });
                self.infiniteOffset += 10;
                lastItemIndex = self.$$('.list li').length;
                allowInfinite = true;

                // self.updateBadge();
              }).catch(err => {
                console.error(err);
                $this.commonService.showError(err);
              });
            });

            // const reminder = self.$route.context.reminder || {};
            const chatRoom = self.$route.context.chatRoom;


            self.calendarDateTime = self.$f7.calendar.create({
              // openIn: 'customModal',
              inputEl: page.$el.find('input[name="RemindAtDateTime"]'),
              timePicker: true,
              dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' },
              // header: true,
              footer: true,
            });

            // Set back title
            // self.$setState({ backTitle: self.$route.context && self.$route.context.backTitle || 'Back', title: self.$route.context && self.$route.context.title || 'Tài khoản' });

            $this.onComponentInit({ instance: self }, 'main');

            // console.log(reminder);
            // $this.rootServices.authService.isAuthenticatedOrRefresh().pipe(take(1)).toPromise().then(() => {
            //   self.$setState({
            //     backTitle: self.$route.context && self.$route.context.backTitle || 'Back', title: self.$route.context && self.$route.context.title || 'Profile',
            //     memberList: chatRoom.memberList$.getValue().filter(member => member.Type === 'USER'),
            //     // reminder: self.reminder,
            //   });

            //   // setTimeout(() => {
            //   //   self.$setState({
            //   //     reminder: self.reminder,
            //   //   });
            //   // }, 5000);
            // }).catch(err => {
            //   console.error(err);
            //   $this.commonService.showError(err);
            // });

          },
          // pageBeforeIn(e, page) {
          //     console.log('page before in');
          //     const self: F7ComponentContextExtend = this;
          //     self.refresh();
          // },
          // pageAfterIn(e, page) {
          //     console.log('page after in');
          // },
          // pageBeforeOut(e, page) {
          //     console.log('page before out');
          // },
          // pageAfterOut(e, page) {
          //     console.log('page after out');
          // },
          // pageBeforeUnmount(e, page) {
          //     console.log('page before unmount');
          // },
          // pageBeforeRemove(e, page) {
          //     console.log('page before remove');
          // },
          pageBeforeRemove(e, page) {
            console.log('[page event] before remove', page.route.url);
            const self: F7ComponentContextExtend = this;
            // const starmtSelectRoles = self.$app.smartSelect.get('.smart-select');
            // starmtSelectRoles.destroy();
          },
        },
      },
    };
  }
}
