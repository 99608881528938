// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { environment as prodenv } from './environment.prod';

export const environment = {
  ...prodenv,
  mode: 'development',
  production: false,
  version: prodenv.version + '.dev',
  api: {
    defaultUrl: 'http://local.probox.vn/v3',
    version: 'v3'
  },
  universal: {
    link: 'http://localhost:8100',
  },
  deployment: {
    ios: {
      baseBuild: 0,
      key: 'msrvvbOvvlBg371igIW6YOEB_1iXzH50VNg8n',
    },
    android: {
      baseBuild: 0,
      key: 'Faexuq69t_kPJMRm1J52oFs06vR3iR0_Fo4-W',
    },
  },
  // mode: 'development',
  // version: '1.1.0',
  // coreVersion: '3.9',
  // production: false,
  // bundleId: 'com.namsoftware.smartbot',
  // name: 'Smart-BOT',
  // title: 'Chú robot thông minh',
  // copyright: 'Ứng dụng được phát triển bởi ProBox One.<br>\
  // Tên ứng dụng: Smart-BOT, ý nghĩa là chú robot thông minh, trợ lý thông minh cho chủ doanh nghiệp.<br>\
  // Smart-BOT có thể kết nối với các sản phẩm phần mềm do công ty NaM software phát triển.<br>\
  // Smart-BOT có thể cùng lúc đăng nhập nhiều tài khoản.<br>\
  // ProBox One since 2020',
  // api: {
  //   defaultUrl: 'http://local.probox.vn/v1',
  // },
  // authentication: {
  //   notLoggedInUrl: '/recent-logins',
  // },
  // notification: {
  //   senderId: 316262946834,
  // },
  // tokenStorageKey: 'jwt-token',
  // deployment: {
  //   ios: {
  //     baseBuild: 0,
  //     key: 'msrvvbOvvlBg371igIW6YOEB_1iXzH50VNg8n',
  //   },
  //   android: {
  //     baseBuild: 0,
  //     key: 'Faexuq69t_kPJMRm1J52oFs06vR3iR0_Fo4-W',
  //   },
  // },
  // google: {
  //   maps: {
  //     apiKey: 'AIzaSyDjQl1YsJdrESCyYpxIXTFs7oTiL1nxKY8',
  //   },
  // },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
