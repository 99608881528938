import { Calendar } from 'framework7/build/core/components/calendar/calendar';
import { Autocomplete } from 'framework7/build/core/components/autocomplete/autocomplete';
import { take } from 'rxjs/operators';
import { F7ComponentContext, F7Messagebar, F7Page, F7View } from '../types/framework7-types';
import { RootServices } from '../services/root.services';
import { Attachment } from './nam-chat/model/message';
import { Subject, Subscription, BehaviorSubject } from 'rxjs';
import { Router } from 'framework7/build/core/modules/router/router';
import { View } from 'framework7/build/core/components/view/view';
import { isPlatform } from '@ionic/angular';
import { StreamingVideoOptions } from '@ionic-native/streaming-media/ngx';
import { ChatRoom } from './nam-chat/chat-room';
import { Dom7Instance } from 'dom7';
import { Actions } from 'framework7/build/core/components/actions/actions';
import { CSSSelector } from 'framework7/build/core/components/app/app-class';
import { LoginScreen } from 'framework7/build/core/components/login-screen/login-screen';
import { Panel } from 'framework7/build/core/components/panel/panel';
import { Popover } from 'framework7/build/core/components/popover/popover';
import { Popup } from 'framework7/build/core/components/popup/popup';
import { Sheet } from 'framework7/build/core/components/sheet/sheet';
import { Component } from 'framework7/build/core/framework7.esm.bundle';
import { ComponentOptions } from 'framework7/build/core/modules/component/component';
import { CommonService } from '../services/common.service';
import { FileModel } from '../model/file.model';
import { HostListener } from '@angular/core';
// import * as $ from 'jquery';
declare const $: any;

// declare const $: any;
export interface ComponentState {
    [key: string]: any;
    chatRoom?: ChatRoom;
    asCase?: string;
    // data?: any;
    id?: string;
    instance?: F7ComponentContext;
    title?: string;
    backTitle?: string;
    scrollPosition?: number;
    // page?: { $el: any, el: any, view: View.View & { id?: string }, route: any };
    page?: F7Page;
    destroy$?: Subject<void>;
    lastUpdate?: number;
    loaded?: boolean;
    loaded$?: BehaviorSubject<boolean>;
    filter?: any;
    searchQuery?: string;
}

export abstract class BaseComponent<S extends ComponentState> implements Router.RouteParameters {
    currentState: S;
    state: { [key: string]: S } = {};

    /** Component title */
    title: string;
    callback$ = new Subject<{ action?: string, id: number, data?: any }>();

    /** Vouchers stap map */
    voucherStateMap: any = {
        'NOTJUSTAPPROVED': {
            text: 'chưa duyệt',
            color: 'color-gray',
            icon: 'circle_fill'
        },
        'UNRECORDED': {
            text: 'bỏ ghi',
            color: 'color-orange',
            icon: 'multiply_circle_fill'
        },
        'APPROVED': {
            text: 'đã duyệt',
            color: 'color-green',
            icon: 'checkmark_alt_circle_fill'
        },
    };

    constructor(
        public rootServices: RootServices,
    ) {
        this.rootServices.authService.switchAccount$.subscribe(recentLogin => {
            // console.log(this.rootServices.authService.switchAccount$);
            // console.log('Base component:Switch account: ', recentLogin);
            // setTimeout(() => {
            if (this.currentState?.instance) {
                this.refresh(this.currentState?.instance);
            }
            // }, 5000);
        });
    }

    get f7Component(): Router.RouteParameters {
        return null;
    }

    get isPublicComponent() {
        return false;
    }

    /** Implement Router.RouteParameters */
    name?: string;
    path: string;
    viewName?: string;
    options?: Router.RouteOptions;
    routes?: Router.RouteParameters[];
    modules?: any[];
    el?: HTMLElement | CSSSelector;
    pageName?: string;
    content?: string | HTMLElement | Dom7Instance | HTMLElement[];
    url?: string;
    template?: string | Function;
    templateUrl?: string;
    component?: Function | Component | ComponentOptions;
    componentUrl?: string;
    async(routeTo: Router.Route, routeFrom: Router.Route, resolve: Function, reject: Function): void {
        // throw new Error('Method not implemented.');
    }
    async asyncComponent(): Promise<any> {
        // throw new Error('Method not implemented.');
        return true;
    }
    id?: string;
    tabs?: Router.RouteParameters[];
    actions?: Actions.Parameters & Router.ModalRouteParameters;
    popup?: Popup.Parameters & Router.ModalRouteParameters;
    loginScreen?: LoginScreen.Parameters & Router.ModalRouteParameters;
    popover?: Popover.Parameters & Router.ModalRouteParameters;
    sheet?: Sheet.Parameters & Router.ModalRouteParameters;
    panel?: Panel.Parameters & Router.ModalRouteParameters;
    alias?: string | any[];
    redirect?: string | ((routeTo: Router.Route, resolve: Function, reject: Function) => void);
    beforeEnter?: Function[] | ((routeTo: Router.Route, routeFrom: Router.Route, resolve: Function, reject: Function) => void);
    beforeLeave?: Function[] | ((routeTo: Router.Route, routeFrom: Router.Route, resolve: Function, reject: Function) => void);
    keepAlive?: boolean;
    master?: boolean;
    detailRoutes?: Router.RouteParameters[];
    on?: { [event: string]: (e: Event, page: any) => void; };
    /** End Implement Router.RouteParameters */



    async refresh(instance?: ComponentState): Promise<any> {
        // const $this = this;
        // if (instance) {
        //     return instance.refresh();
        // } else {
        //     // Wait for instance ready
        //     // return new Promise<boolean>((resolve, reject) => {
        //     //     let counter = 0;
        //     //     (function loop() {
        //     //         counter++;
        //     //         if (counter > 20) {
        //     //             reject('wait for f7 component instance timeout');
        //     //             return;
        //     //         }
        //     //         if ($this.currentState && $this.currentState.instance) {
        //     //             $this.currentState.instance.refresh().then(result => resolve(result));
        //     //         } else {
        //     //             setTimeout(() => loop(), 300);
        //     //         }
        //     //     })();
        //     // });
        // }
        if (this.currentState) {
            instance = instance || this.currentState.instance;
            if (instance && instance.refresh) {
                return instance.refresh();
            }
        } else {
            return false;
        }
    }

    async onComponentInit(state: S, index: string, asCase?: string, page?: F7Page): Promise<S> {
        this.currentState = this.state[index] || (this.state[index] = { ...this.state[index], instance: state.instance, page: state.page, id: index, asCase, loaded$: new BehaviorSubject<boolean>(false) });
        this.currentState.instance = state.instance;
        this.currentState.page = state.page;
        this.currentState.id = index;
        this.currentState.asCase = asCase;
        // Merge filter
        this.currentState.filter = {
            ...this.currentState.filter,
            ...state.filter,
        };
        this.currentState.destroy$ = state.destroy$ || new Subject();
        // this.currentState = { ...this.state[index], instance: state.instance, page: state.page, id: index, asCase };

        if (!this.isPublicComponent) {
            await this.rootServices.waitingForAllReady();
            this.rootServices.authService.isAuthenticatedOrRefresh().pipe(take(1)).toPromise().then(isAuth => {
                if (!isAuth) {
                    this.rootServices.navigate(this.rootServices.env.authentication.notLoggedInUrl, { animate: false });
                }
            });
        }

        this.currentState.instance.$setState({ componentIndex: index });

        return this.state[index];
    }

    async onComponentRemove(state: S, index: string, asCase?: string): Promise<boolean> {

        if (this.state[index].destroy$) {
            this.state[index].destroy$.next();
            this.state[index].destroy$.complete();
        }

        delete this.currentState;
        delete this.state[index];

        return true;
    }

    async onChangedState(state: S, index: string, asCase?: string, option?: { doNotRefresh?: boolean }): Promise<S> {
        this.currentState = this.state[index] || (this.state[index] = { ...this.state[index], instance: state.instance, page: state.page, id: index, asCase });
        this.currentState.instance = state.instance;
        this.currentState.page = state.page;
        this.currentState.id = index;
        this.currentState.asCase = asCase;
        // this.currentState = this.state[index] = { ...this.state[index], instance: state.instance, page: state.page, id: index, asCase };

        if (this.rootServices.f7app) {
            this.rootServices.f7app.data.activeComponent = this;
        }

        if (!option?.doNotRefresh) {
            this.currentState.instance.refresh();
        }
        return this.state[index];
    }

    async onBeforeChangedState(state: S, index: string): Promise<S> {
        this.currentState = this.state[index] || (this.state[index] = { ...this.state[index], instance: state.instance, page: state.page, scrollPosition: state.scrollPosition });
        this.currentState.instance = state.instance;
        this.currentState.page = state.page;
        this.currentState.scrollPosition = state.scrollPosition;
        // this.currentState = this.state[index] = { ...this.state[index], instance: state.instance, page: state.page, scrollPosition: state.scrollPosition };
        return this.state[index];
    }

    async browseAttachment(attachments: Attachment[], attachmentId: string, download?: (attachment: Attachment) => void) {
        // const attachments = this.currentState.chatRoom.attachments;

        // Check attachments
        if (!attachments || attachments.length === 0) {
            return false;
        }

        // find index
        const index = attachments.findIndex(f => f.Id === attachmentId);
        const idCallback = Date.now();
        const myPhotoBrowserPopupDark = this.rootServices.f7app.photoBrowser.create({
            photos: attachments.map(att => {
                const preview: any = {
                    caption: att.Description,
                };
                if (/^image/.test(att.Type)) {
                    preview.url = att.OriginUrl;
                } else if (/^video/.test(att.Type)) {
                    preview.html = `<video controls width="100%" src="${att.OriginUrl}" type="${att.DataType}"></video>`;
                } else {
                    preview.url = att.Thumbnail;
                }
                return preview;
            }),
            theme: 'dark',
            type: 'standalone' as any,
            renderNavbar() {
                return /*html*/`
                    <div class="navbar">
                        <div class="navbar-bg"></div>
                        <div class="navbar-inner sliding">
                            <div class="left">
                            <a class="link back">
                                <i class="icon icon-back"></i>
                                <span class="if-not-md">Đóng</span>
                            </a>
                            </div>
                            <div class="title">Xem hình</div>
                            <div class="right">
                            <a href="javascript:f7app.data.activeComponent.addToStack(${idCallback})" class="icon-only link">
                                <i class="icon f7-icons">rectangle_fill_on_rectangle_angled_fill</i>
                            </a>
                            <a href="javascript:f7app.data.activeComponent.onPhotoBrowserDownloadAttachment(${idCallback})" class="icon-only link">
                                <i class="icon f7-icons if-not-md">cloud_download_fill</i>
                                <i class="icon material-icons if-md">cloud_download</i>
                            </a>
                            </div>
                        </div>
                    </div>
                `;
            },
            // renderCaption(caption, pIndex) {
            //     return `
            //     <div class="photo-browser-captions photo-browser-captions-dark">
            //         <div class="photo-browser-caption" data-caption-index="${pIndex}">
            //             <a href="/download/${attachments[pIndex].id}" data-id="${pIndex}">[Download]</a> ${caption}
            //         </div>
            //     </div>
            //     `;
            // },
        });
        myPhotoBrowserPopupDark.open(index);
        let obs: Subscription = null;
        if (download) {
            obs = this.callback$.subscribe(params => {
                if (params.action == 'onPhotoBrowserDownloadAttachment') {
                    if (params.id === idCallback) {
                        download(attachments[params.data]);
                    }
                }
                if (params.action == 'addToStack') {
                    const attachment = attachments[params.data];
                    if (attachment) {
                        if (this.rootServices.appComponent.processor.previewStack.findIndex(f => f.Id == attachment.Id) < 0) {
                            this.rootServices.appComponent.processor.previewStack.unshift({
                                Id: attachment.Id,
                                SmallImage: attachment.SmallUrl,
                                LargeImage: attachment.LargeUrl,
                                OriginImage: attachment.OriginUrl,
                                DownloadLink: attachment.Url,
                                Thumbnail: attachment.Thumbnail,
                                MimeType: attachment.Type,
                            } as any);
                            this.rootServices.appComponent.processor.savePreviewStack();
                            this.rootServices.commonService.showInfo('Đã thêm hình ảnh vào ngăn xếp', { position: 'bottom' });
                            this.rootServices.appComponent.ref.detectChanges();
                        } else {
                            this.rootServices.commonService.showError('Hình ảnh này đã có trong ngăn xếp', { position: 'bottom' });
                        }
                    }
                }
            });
        }

        return new Promise<void>(resolve => {
            myPhotoBrowserPopupDark.on('closed', () => {
                myPhotoBrowserPopupDark.destroy();
                if (obs) { obs.unsubscribe(); }
                resolve();
            });
        });
    }

    previewPictures(pictures: FileModel[], index: number | string) {

        // const self: F7ComponentContextExtend = this;
        const currenntState = this.currentState;
        // let index = $(e.target).data('index');

        this.browseAttachment(pictures.map(m => ({
            Id: m.Id,
            Type: m.Type,
            DataType: m.MimeType,
            Ext: m.Extension,
            Name: m.Description,
            Description: m.Description,
            // DateOfAttach: currenntState.data.Created,
            // File: string;
            Thumbnail: m.Thumbnail,
            Url: m.OriginImage,
            SmallUrl: m.SmallImage,
            LargeUrl: m.LargeImage,
            OriginUrl: m.OriginImage,
        })) as any, pictures[index]?.Id, (attachment => {
            console.debug('download index', attachment);
            if (/image/.test(attachment.Type)) {
                this.currentState.instance.$app.dialog.create({
                    title: 'Xác nhận tải về',
                    content: 'Đính kèm',
                    buttons: [
                        {
                            text: 'Đóng',
                            color: 'red',
                            close: true,
                        },
                        {
                            text: 'Mở link',
                            onClick: () => {
                                this.rootServices.saveImageToPhone(attachment.Url as string);
                            }
                        }
                    ],
                }).open();
            } else if (/video/.test(attachment.Type)) {
                this.playVideo((attachment.OriginUrl || attachment.Url) as string);
            } else {
                this.rootServices.iab.create((attachment.OriginUrl || attachment.Url) as string, '_system');
            }
        })).then(() => {
            console.log('Photo browser was closed');
        });

    }

    playVideo(url: string) {
        if (isPlatform('desktop')) {
            this.rootServices.iab.create(url, '_system');
        } else {
            let options: StreamingVideoOptions = {
                successCallback: () => { console.log('Video played') },
                errorCallback: (e) => { console.log('Error streaming') },
                // orientation: 'landscape',
                shouldAutoClose: false,
                controls: true
            };
            this.rootServices.streamingMedia.playVideo(url, options);
        }
    }

    onPhotoBrowserDownloadAttachment(idCallback: number, context?: string) {
        const currentPhotoBrowser = this.rootServices.f7app.photoBrowser.get(undefined);
        if (context && context == 'rootServices') {
            this.rootServices.callback$.next({ action: 'onPhotoBrowserDownloadAttachment', id: idCallback, data: currentPhotoBrowser.activeIndex });
        } else {
            this.callback$.next({ action: 'onPhotoBrowserDownloadAttachment', id: idCallback, data: currentPhotoBrowser.activeIndex });
        }
    }
    addToStack(idCallback: number, context?: string) {
        console.log(idCallback);
        const currentPhotoBrowser = this.rootServices.f7app.photoBrowser.get($('.photo-browser-popup:last')[0]);
        if (context && context == 'rootServices') {
            this.rootServices.callback$.next({ action: 'addToStack', id: idCallback, data: currentPhotoBrowser.activeIndex });
        } else {
            this.callback$.next({ action: 'addToStack', id: idCallback, data: currentPhotoBrowser.activeIndex });
        }
    }
    removeOutOfStack(idCallback: number, context?: string) {
        console.log(idCallback);
        const currentPhotoBrowser = this.rootServices.f7app.photoBrowser.get(undefined);
        if (context && context == 'rootServices') {
            this.rootServices.callback$.next({ action: 'removeOutOfStack', id: idCallback, data: currentPhotoBrowser.activeIndex });
        } else {
            this.callback$.next({ action: 'removeOutOfStack', id: idCallback, data: currentPhotoBrowser.activeIndex });
        }
    }
    onPhotoBrowserButtonClick(idCallback: number, buttonName: string, context?: string) {
        console.log(idCallback);
        const currentPhotoBrowser = this.rootServices.f7app.photoBrowser.get(undefined);
        if (context && context == 'rootServices') {
            this.rootServices.callback$.next({ action: 'onPhotoBrowserButtonClick', id: idCallback, buttonName, data: currentPhotoBrowser.activeIndex });
        } else {
            this.callback$.next({ action: 'onPhotoBrowserButtonClick', id: idCallback, data: currentPhotoBrowser.activeIndex });
        }
    }

    navigate(url: string, option?: { context?: any, view?: F7View }) {
        let view: F7View = this.currentState && this.currentState.instance.view || option && option.view;

        // Find relate view
        const views: F7View[] = this.rootServices.f7app.views as any;
        for (const v of views) {
            if (v.history.some(f => f === url)) {
                view = v;
                break;
            }
        }

        if (!view) {
            console.error('view was not provided');
            return false;
        }

        // Active tab contain view
        this.rootServices.f7app.tab.show(view.el, this.rootServices.f7app.$('a[href="' + view.selector + '"]')[0] as HTMLElement);

        if (view.history.some(f => f === url)) {
            view.router.back(url, { force: true });
        } else {
            view.router.navigate(url);
        }
    }

    createAutocompleteField(
        fieldEle: HTMLElement,
        currentState?: ComponentState,
        ajax?: (query: string) => Promise<any[]>,
        onChange?: (value: any) => void,
        onClose?: (value: any) => void,
        option?: Partial<Autocomplete.Parameters> & {
            [key: string]: any,
            minSearchInputLength?: number,
        }) {
        const $this = this;
        if (!option) option = {} as any;
        if (typeof option.minSearchInputLength == 'undefined') {
            option.minSearchInputLength = 1;
        }
        const autocompleteStandaloneAjax = (currentState?.instance?.$app || this.rootServices.f7app).autocomplete.create({
            openIn: 'popup', //open in page
            openerEl: fieldEle, //link that opens autocomplete
            multiple: false, //allow multiple values
            valueProperty: 'id', //object's "value" property name
            textProperty: 'text', //object's "text" property name
            limit: 50,
            preloader: true, //enable preloader
            closeOnSelect: true,
            autoFocus: true,
            popupSwipeToClose: true,
            ...option,
            source: function (query, render) {
                var autocomplete = this;
                var results = [];
                if (query.length < option?.minSearchInputLength) {
                    render(results);
                    return;
                }
                // Show Preloader
                autocomplete.preloaderShow();
                $this.rootServices.commonService.takeUntil('autocomplete-field-', 300).then(status => {
                    ajax(query).then(results => {
                        // Hide Preoloader
                        autocomplete.preloaderHide();
                        // Render items by passing array with result items
                        render(results);

                    });
                });
            },
            on: {
                change: function (value) {
                    onChange(value);
                },
                close: function (value) {
                    onClose(value);
                },
                open: function (autocomplete) {
                    option?.onOpen && option.onOpen();
                    // autocomplete.searchbar.search('a');
                    // autocomplete.source('');
                    if (option?.minSearchInputLength === 0) {
                        autocomplete['source']('');
                    }
                }
            },
        });
        // ajax('').then(rs => {
        //     autocompleteStandaloneAjax.preloaderHide();
        //     // Render items by passing array with result items
        //     autocompleteStandaloneAjax.value = rs;
        // });
        // this.rootServices.waitFor(300, 1000, () => !!autocompleteStandaloneAjax.searchbar).then(() => {
        //     autocompleteStandaloneAjax.searchbar.search('a');
        // });
        // autocompleteStandaloneAjax.on();
        return autocompleteStandaloneAjax;
    }

    createDatePickerField(fieldEle: HTMLElement, currentState: ComponentState, onChange: (value: any, calendar?: Calendar.Calendar) => void, onClose: (value: any, calendar?: Calendar.Calendar) => void, option?: Calendar.Parameters) {
        const $this = this;
        const datePicker = currentState.instance.$app.calendar.create({
            inputEl: fieldEle,
            dateFormat: 'h:m, d/m/yyyy',
            timePicker: true,
            rangePicker: false,
            closeOnSelect: true,
            on: {
                change(calendar, value: Date[]) {
                    onChange(value, calendar);
                    // calendar.close();
                },
                close(calendar) {
                    onClose(calendar.getValue(), calendar);
                }
            },
            ...option
        });
        return datePicker;
    }

    createSmartSelectField(fieldEle: HTMLElement, currentState: ComponentState, onClosed: (value: any) => void, option?: any) {
        const $this = this;
        const smartSelectObject = currentState.instance.$app.smartSelect.create({
            el: fieldEle,
            virtualList: true,
            openIn: 'sheet',
            closeOnSelect: true,
            popupSwipeToClose: true,
            searchbar: true,
            scrollToSelectedItem: true,
            ...option,
            on: {
                closed: (smartSelect) => {
                    onClosed(smartSelectObject.getValue());
                }
            }
        });
        if (smartSelectObject) {
            smartSelectObject.on('opened', (smartSelect) => {
                console.log(smartSelect);
                smartSelect['vl']['params'].searchByItem = (query: string, item: any, index: number) => {
                    return $this.rootServices.commonService.smartFilter(item.text, query);
                }
            });
            // smartSelectObject.setValue($this.rootServices.commonService.getObjectId(currentState.voucher.Object as any) as any);
        };
        return smartSelectObject;
    }

}
