import { Dialog } from "framework7/build/core/components/dialog/dialog";
import { Router } from "framework7/build/core/modules/router/router";
import { take } from "rxjs/operators";
import { CollaboratorOrderModel, CollaboratorOrderDetailModel } from "src/app/model/collaborator.model";
import { RootServices } from "src/app/services/root.services";
import { F7ComponentContext, F7Page } from "src/app/types/framework7-types";
import { CollaboratorStateMap } from "vendors/smart-bot-app/src/app/components/collaborator/collaborator.state.map";
import { FormComponentStateExtend, BaseFormComponent, FormSchema } from "vendors/smart-bot-app/src/app/lib/base-form-component";
import { ChatRoomModel } from "vendors/smart-bot-app/src/app/model/chat-room.model";
import { ContactModel } from "vendors/smart-bot-app/src/app/model/contact.model";
import { FileModel } from "vendors/smart-bot-app/src/app/model/file.model";
import { UnitModel, ProductModel } from "vendors/smart-bot-app/src/app/model/product.model";
import { SalesMasterPriceTable } from "vendors/smart-bot-app/src/app/model/sales.model";
import { CommonService } from "vendors/smart-bot-app/src/app/services/common.service";

// import * as $ from 'jquery';
declare const $: any;

// declare const $: any;
export interface ComponentStateExtend extends FormComponentStateExtend<CollaboratorOrderModel> {
  [key: string]: any;
  instance: F7ComponentContextExtend,
  data?: CollaboratorOrderModel;
  form?: any;
}

export class F7ComponentContextExtend extends F7ComponentContext {
  responseInProgress?: boolean;

  detailDialog?: Dialog.Dialog;

  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, [key: string]: any } };
}

/** Component group manager */
export class PageCollaboratorOrderFormComponent extends BaseFormComponent<ComponentStateExtend, CollaboratorOrderModel> {
  // states: { [key: string]: State } = {};
  currentState: ComponentStateExtend;
  title: 'Đơn Hàng CTV';

  idKey = 'Code';

  apiPath = '/collaborator/orders';

  taxList = [
    {
      id: 'VAT10',
      text: '+ 10% VAT',
      Tax: 10,
      Type: 'NONE',
      Name: 'Chưa bao gồm VAT 10%',
      selected: false,
    },
    {
      id: 'FULLVAT10',
      text: 'Đã bao gồm VAT 10%',
      Tax: 0,
      Type: 'NONE',
      Name: 'Đã Full VAT 10%',
      selected: false,
    },
    {
      id: 'NOTAX',
      text: 'n/a',
      Tax: 0,
      Type: 'NONE',
      Name: 'Không thuế',
      selected: false,
    },
  ];

  unitList: UnitModel[] = [];
  pageList: UnitModel[] = [];
  priceTableList: SalesMasterPriceTable[] = [];

  contactList: ContactModel[] = [];

  schema: FormSchema = {
    // Object: {
    //   type: 'smart-select',
    //   label: 'Khách hàng',
    //   validators: [
    //     'required'
    //   ]
    // },
    ObjectName: {
      type: 'input',
      label: 'Tên nhà cung cấp',
      validators: [
        'required'
      ]
    },
    Object: {
      type: 'autocomplete',
      autocompleteOption: { minSearchInputLength: 0, autoFocus: false, limit: 20 },
      ajax: (query: any) => {
        return this.rootServices.apiService.getPromise<ContactModel[]>('/contact/contacts', { search: query, includeIdText: true, includeGroups: true, sort_SearchRank: 'desc' }).then(results => {
          return results.map(m => {
            m.text = `${m.Name}${m.Groups ? ' (' + m.Groups.map(g => this.commonService.getObjectText(g as any)).join(', ') + ')' : ''}`;
            return m;
          });
        });
      },
      label: 'Khách hàng',
      validators: [
        'required'
      ]
    },
    Title: {
      type: 'input',
      label: 'Tiêu đề',
      validators: [
        'required'
      ]
    },
    DeliveryProvince: {
      label: 'Tỉnh/TP',
      type: 'autocomplete',
      autocompleteOption: { minSearchInputLength: 0, autoFocus: false, limit: 20 },
      ajax: (query: any) => {
        return this.rootServices.apiService.getPromise('/general/locations', { select: 'id=>Code,text=>CONCAT(TypeLabel;\' \';FullName)', limit: 100, 'search': query, eq_Type: '[PROVINCE,CITY]' });
      },
      validators: [
        'required'
      ]
    },
    DeliveryDistrict: {
      label: 'Quận/Huyện',
      type: 'autocomplete',
      autocompleteOption: { minSearchInputLength: 0, autoFocus: false, limit: 20 },
      ajax: (query: any) => {
        const data = this.currentState.data;
        return this.rootServices.apiService.getPromise('/general/locations', { select: 'id=>Code,text=>CONCAT(TypeLabel;\' \';FullName)', limit: 100, 'search': query, eq_Type: '[CDISTRICT,PDISTRICT,BURG,CITYDISTRICT]', eq_Parent: this.commonService.getObjectId(data.DeliveryProvince) });
      },
      validators: [
        'required'
      ]
    },
    DeliveryWard: {
      label: 'Phường/Xã',
      type: 'autocomplete',
      autocompleteOption: { minSearchInputLength: 0, autoFocus: false, limit: 20 },
      ajax: (query: any) => {
        const data = this.currentState.data;
        return this.rootServices.apiService.getPromise('/general/locations', { select: 'id=>Code,text=>CONCAT(TypeLabel;\' \';FullName)', limit: 100, 'search': query, eq_Type: '[VILLAGE,WARD,TOWNS]', eq_Parent: this.commonService.getObjectId(data.DeliveryDistrict) });
      },
      validators: [
        'required'
      ]
    },
    DeliveryAddress: {
      type: 'input',
      label: 'Số nhà, tên đường',
      validators: [
        'required'
      ]
    },
    Note: {
      type: 'texteditor',
    },
    Details: {
      type: 'list',
      makeModel: (properties: CollaboratorOrderDetailModel) => {
        return new CollaboratorOrderModel({ ...properties, Page: this.currentState.instance.$route.params['pageId'] });
      },
      Unit: {
        type: 'smart-select',
        label: 'Đơn vị tính',
        validators: [
          'required'
        ]
      },
      Quantity: {
        type: 'stepper',
        label: 'Số lượng',
        validators: [
          'required'
        ]
      },
      Price: { type: 'inputmask', format: { mask: 'decimal', option: { radixPoint: ',', groupSeparator: '.' } } },
      ToMoney: { type: 'inputmask', format: { mask: 'decimal', option: { radixPoint: ',', groupSeparator: '.' } } },
    }
  };

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
  ) {
    super(rootServices, commonService);
    // // console.log('Click here to open ts file');

    this.rootServices.platform.pause.subscribe(status => {
      // Auto save
      this.currentState?.data?.Code && (!this.currentState?.data?.State || this.currentState?.data?.State == 'NOTJUSTAPPROVED') && this.currentState.instance.save();
    });
  }

  makeModel(properties?: CollaboratorOrderModel): CollaboratorOrderModel {
    return new CollaboratorOrderModel({ ...properties, Page: this.currentState.instance.$route.params['pageId'] });
  }

  async makeNewData() {
    return new CollaboratorOrderModel({ Page: this.currentState.instance.$route.params['pageId'] });
  }

  getFormData(params?: any): Promise<CollaboratorOrderModel> {
    return super.getFormData({
      includeObject: true,
      includeContact: true,
      includeDetails: true,
      includeRelativeVouchers: true,
      page: this.currentState.instance.$route.params['pageId'],
      ...params,
    });
  }

  prepareData(data: CollaboratorOrderModel): CollaboratorOrderModel {
    if (data.Details) {
      for (const detail of data.Details) {

        if (detail?.Unit && typeof detail?.Unit == 'string') {
          detail.Unit = this.unitList.find(f => f.id == detail.Unit as any);
        }

        if (!data.Pictures) {
          data.Pictures = [];
        }
      };
    }
    data.State = CollaboratorStateMap.orderStateMap[this.commonService.getObjectId(data.State)] || {};
    return data;
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  async onComponentInit(state: ComponentStateExtend, index: string, asCase?: string, f7Page?: F7Page) {
    const $this = this;

    // Start loading status
    state.instance.$setState({
      loading: true,
      schema: this.schema,
    });
    this.commonService.showPreloader();

    // Load Contact Member list
    if (state?.instance?.$route?.context?.chatRoom?.memberList$) {
      await state?.instance?.$route?.context?.chatRoom?.memberList$?.pipe(take(1)).toPromise().then(memberList => {
        state.instance.contactList = memberList.filter(f => f.Type == 'CONTACT').map(m => { m['text'] = m.Name; return m; }) as any;
        if (state.instance.contactList && state.instance.contactList.length > 0) {
          this.schema['Object'].validators = [];
        } else {
          // this.schema['Object'].validators = [];
        }
      });

    }

    // Get static data
    await this.rootServices.apiService.getPromise<UnitModel[]>('/admin-product/units', { onlyIdText: true, limit: 'nolimit' }).then(rs => {
      state.instance.unitList = rs;
    });
    await this.rootServices.apiService.getPromise<any[]>('/collaborator/pages', { onlyIdText: true, limit: 'nolimit' }).then(rs => {
      state.instance.pageList = rs;
    });
    // End

    // set static data
    state.instance.$setState({
      unitList: state.instance.unitList,
      contactList: state.instance.contactList,
      processMap: CollaboratorStateMap.orderStateMap,
    });

    const currentState: ComponentStateExtend = await super.onComponentInit(state, index, asCase) as any;

    if (currentState.instance.$route?.context?.copyFromId) {
      // currentState.instance.data.SubNote = 'Copy of ' + currentState.instance.$route?.context?.copyFromId + ': ' + (currentState.instance.data.SubNote ? ('<br>' + currentState.instance.data.SubNote) : '');
      this.setData(currentState.instance.data);
    }

    if (currentState?.instance?.$route?.context?.relativeVouchers && currentState.instance.$route.context.relativeVouchers[0]?.text) {
      if (!currentState.data.Title) {
        currentState.data.Title = currentState.instance.$route.context.relativeVouchers[0]?.text;
        currentState.instance.$setState({ data: currentState.data });
        this.validate();
      }
    }

    // Prepare data
    // if (currentState.data) {
    //   // for (const detail of currentState.data?.Details) {
    //   //   detail.__unitList = detail.Product?.Units || [];
    //   //   detail.ToMoney = detail.Price * detail.Quantity;
    //   // }
    //   // currentState.data.Object2 = currentState.data.Object;
    //   // currentState.data.Contact2 = currentState.data.Contact;
    //   await this.setData(currentState.data, { prepareControl: true });
    //   // this.calculateTotal();
    // }
    // End Prepare data

    const id = currentState.instance.$route.params['id'];
    if (id === 'new' && currentState.instance.$route.context?.product) {
      const product = currentState.instance.$route.context?.product;
      $this.addItemForList('Details', {
        ...new CollaboratorOrderDetailModel(),
        Page: currentState.instance.$route.params['pageId'],
        Product: product,
        Description: product.Name,
        Unit: product.Unit,
        Quantity: 1,
        // Tax: 'NOTAX',
        Price: product.Price,
        Token: product.Token,
        __unitList: [product.UnitLabel ? { id: this.commonService.getObjectId(product.Unit), text: product.UnitLabel } : product.Unit],
        Image: [
          ...[product.FeaturePicture],
          ...(Array.isArray(product.Pictures) && product.Pictures || []).filter(f => f.Id != product?.FeaturePicture?.Id)
        ]
      });
    }

    // Stop loading status
    currentState.instance.$setState({ loading: false });
    this.commonService.hidePreloader();
    return currentState;
  }

  async setData(data: CollaboratorOrderModel, option?: { onlyList?: string[]; onlyKeys?: string[]; prepareControl?: boolean; }): Promise<boolean> {

    if (data?.Details) {
      const productIds = data.Details.filter(f => this.commonService.getObjectId(f.Type) != 'CATEGORY').map(m => `${this.commonService.getObjectId(this.currentState.data['Page'])}-${this.commonService.getObjectId(m.Product)}`);
      const productInfoMap: { [key: string]: ProductModel } = await this.rootServices.apiService.getPromise<ProductModel[]>('/collaborator/page-products', {
        id: productIds,
        includeIdText: true,
        includeProduct: true,
        includeUnit: true,
        includeUnitPrices: true,
        productOfPage: true,
      }).then(rs => rs.reduce((prev, next, i) => {
        prev[this.commonService.getObjectId(next)] = next;
        return prev;
      }, {}));

      // const unitPriceMap = await this.rootServices.apiService.getPromise<SalesMasterPriceTableDetailModel[]>('/sales/master-price-table-details', {
      //   priceTable: 'default',
      //   eq_Code: '[' + productIds.join(',') + ']',
      // }).then(rs => rs.reduce((result, current, index) => { result[current.Code + '-' + this.commonService.getObjectId(current.Unit)] = current.Price; return result; }, {}));

      if (data.Details) {
        for (const detail of data.Details) {
          if (!detail.__unitList || detail.__unitList.length == 0) {
            detail.__unitList = productInfoMap[this.commonService.getObjectId(detail.Product)]?.Units.map(m => {
              // m.Price = unitPriceMap[this.commonService.getObjectId(detail.Product) + '-' + this.commonService.getObjectId(m)];
              return m;
            });
            // detail.__unitList = detail.Product?.Units || [];
          }
          // for (const unit of detail.__unitList) {
          //   unit.Price = unitPriceMap[this.commonService.getObjectId(detail.Product) + '-' + this.commonService.getObjectId(unit)];
          // }
          detail.ToMoney = detail.Price * detail.Quantity;
        }
      }
    }

    // data.Object = data.Object;
    // data.Publisher = data.Publisher;

    const total = this.calculateTotal(data);
    return super.setData(data, option).then(status => {
      this.currentState.instance.$setState({ total });

      // Update tabs

      return status;
    });
  }

  async addItemForList<CollaboratorOrderDetailModel>(listName: string, item?: CollaboratorOrderDetailModel): Promise<CollaboratorOrderDetailModel> {
    return super.addItemForList(listName, item).then(newItem => {
      this.commonService.showInfo('Đã thêm sản phẩm', { position: 'bottom' });
      return newItem;
    }).then(rs => {
      this.activeItem((this.currentState.data.Details || []).length - 1);
      this.rootServices.playNewPipSound();
      return rs;
    });
  }

  activeItem(index: number, newData?: CollaboratorOrderDetailModel) {

    if (this.currentState.data.Details) {
      this.currentState.data.Details.map(detail => {
        detail.__active = false;
      });
      this.currentState.data.Details[index].__active = true;
    }

    const total = this.calculateTotal();
    this.currentState.instance.$setState({ data: this.currentState.data, total });
    // if (newData?.AccessNumbers) {
    //   setTimeout(() => {
    //     this.setFieldValue('smart-select', 'AccessNumbers', newData.AccessNumbers, 'Details', index);
    //   }, 300);
    // }
    setTimeout(() => {
      const itemEl = $(this.currentState.instance.el).find('.list-item.index-' + index);
      // itemEl[0] && itemEl[0].scrollIntoView();
      if (itemEl.length > 0) {
        const pageContentEl = $(this.currentState.instance.el).find('.page-content');
        pageContentEl.animate({ scrollTop: pageContentEl.scrollTop() + itemEl.offset().top - itemEl.height() }, 300);
      }
    }, 300);


  }

  async updateImages(state: (progress) => void, onAfterImageUpload?: (image: FileModel) => void) {

    let proloaderProcessing;
    try {
      const localFiles = await this.rootServices.pickFiles();
      console.debug(localFiles);

      this.commonService.showPreloader();
      proloaderProcessing = setTimeout(() => {
        this.commonService.hidePreloader();
      }, 16000);

      let images: FileModel[] = [];
      for (const i in localFiles) {
        // let image: FileModel = null;
        images[i] = await ((progress) => {
          if (localFiles[i].dataType === 'url') {
            return this.rootServices.uploadLocalFile(localFiles[i].url as string, (event) => {
              progress(event)
            });
          } else {
            const formData = new FormData();
            const imgBlob = new Blob([localFiles[i].url], {
              type: localFiles[i].type
            });
            formData.append('file', imgBlob, 'smart-bot-' + Date.now() + '.' + localFiles[i].ext);
            return this.rootServices.uploadFileData(formData, (event) => {
              progress(event)
            });
          }
        })((progress) => {
          console.log(progress);
          state(progress);
        });
        onAfterImageUpload && onAfterImageUpload(images[i]);
      }
      console.debug(images);
      clearTimeout(proloaderProcessing);
      this.commonService.hidePreloader();
      return images;
    } catch (err) {
      console.error(err);
      this.commonService.showError(err, { position: 'bottom' }); clearTimeout(proloaderProcessing);
      this.commonService.hidePreloader();
      throw new Error(err);
    }
  }

  async onFieldChange(self: F7ComponentContext, e: any, ...args: any) {
    const currentState = this.currentState;
    return super.onFieldChange(self, e, args).then(async fieldInfo => {

      if (!fieldInfo) {
        return null;
      }

      let { field, fieldName, fieldValue, index, listName, previousValue: previousUnit } = fieldInfo;
      let requireSetData = false;

      console.log('on field change', listName, index, fieldName, fieldValue);
      if (listName == 'Details' && fieldName == 'Quantity') {

        currentState.data[listName][index]['ToMoney'] = (currentState.data[listName][index]['Quantity'] || 0) * (currentState.data[listName][index]['Price'] || 0);
        // currentState.instance.$setState({ data: currentState.data });
        this.rootServices.playDecreasePipSound();
        requireSetData = true;
      }

      if (listName == 'Details' && fieldName == 'Price') {

        currentState.data[listName][index]['ToMoney'] = (currentState.data[listName][index]['Quantity'] || 0) * (currentState.data[listName][index]['Price'] || 0);
        currentState.instance.$setState({ data: currentState.data });
        // $(currentState.instance.el).find('.list-name[name="' + listName + '"] .index-' + index + ' [name="ToMoney"]').val(currentState.data[listName][index]['ToMoney']);
      }
      if (listName == 'Details' && fieldName == 'ToMoney') {

        if (currentState.data[listName][index]['Quantity'] > 0) {
          currentState.data[listName][index]['Price'] = (currentState.data[listName][index]['ToMoney'] || 0) / currentState.data[listName][index]['Quantity'];
        }
        // currentState.instance.$setState({ data: currentState.data });
        requireSetData = true;
        // $(currentState.instance.el).find('.list-name[name="' + listName + '"] .index-' + index + ' [name="Price"]').val(currentState.data[listName][index]['Price']);
      }

      if ((fieldName == 'Object') && fieldValue && !currentState.instance.loading) {
        await this.rootServices.apiService.getPromise<ContactModel[]>('/contact/contacts/' + this.commonService.getObjectId(fieldValue), {}).then(contacts => contacts[0]).then(contact => {

          // Auto fill object
          currentState.data.Object = fieldValue;
          currentState.data.ObjectName = contact.Name;
          currentState.data.ObjectPhone = contact.Phone;
          currentState.data.ObjectEmail = contact.Email;
          currentState.data.ObjectAddress = contact.Address;

          currentState.data.DeliveryProvince = contact.Province;
          currentState.data.DeliveryDistrict = contact.District;
          currentState.data.DeliveryWard = contact.Ward;
          currentState.data.DeliveryAddress = contact.Address;
          // currentState.data.ObjectIdentifiedNumber = contact.IdentifiedNumber;

          // currentState.instance.$setState({
          //   data: currentState.data,
          // });
          requireSetData = true;
          this.validate();
        });
      }
      if ((fieldName == 'Publisher') && fieldValue && !currentState.instance.loading) {
        await this.rootServices.apiService.getPromise<ContactModel[]>('/contact/contacts/' + this.commonService.getObjectId(fieldValue), {}).then(contacts => contacts[0]).then(contact => {

          // Auto fill object
          currentState.data.Contact = fieldValue;
          currentState.data.ContactName = contact.Name;
          currentState.data.ContactPhone = contact.Phone;
          currentState.data.ContactEmail = contact.Email;
          currentState.data.ContactAddress = contact.Address;
          // currentState.data.ContactIdentified = contact.TaxCode;

          // currentState.instance.$setState({
          //   data: currentState.data,
          // });

          this.validate();
          requireSetData = true;
        });
      }

      if (listName == 'Details' && fieldName == 'Unit') {
        // let price = currentState.data[listName][index]?.Price;
        const detail = currentState.data[listName][index];
        previousUnit = detail.__unitList.find(f => this.commonService.getObjectId(f) == this.commonService.getObjectId(previousUnit));
        if (previousUnit && this.commonService.getObjectId(previousUnit) != this.commonService.getObjectId(detail.Unit)) {
          const nextUnit = detail.__unitList.find(f => this.commonService.getObjectId(f) == this.commonService.getObjectId(fieldValue));
          if (previousUnit.ConversionRatio && nextUnit.ConversionRatio) {
            detail['Quantity'] = detail['Quantity'] * previousUnit.ConversionRatio;
            // detail['Price'] = detail['Price'] / previousUnit.ConversionRatio;
            detail['Quantity'] = detail['Quantity'] / nextUnit.ConversionRatio;
          }
          detail['Price'] = nextUnit.Price;
          detail['ToMoney'] = detail['Price'] * detail['Quantity'];

          let business = [];
          const product = detail.Product;
          // if (product) {
          //   if (this.commonService.getObjectId(detail.Type) == 'PRODUCT') {
          //     const unit = detail.__unitList.find(f => this.commonService.getObjectId(f) == this.commonService.getObjectId(detail.Unit)) || null;
          //     if (unit) {
          //       if (unit.IsAutoAdjustInventory === false) {
          //         business = [{
          //           id: 'PURCHASESKIPWAREHOUSE',
          //           text: 'Mua hàng nhưng không nhập kho (công nợ)',
          //         }];
          //       }
          //       if (unit.IsAutoAdjustInventory === true) {
          //         business = [{
          //           id: 'PURCHASEWAREHOUSE',
          //           text: 'Mua hàng sau đó nhập kho (công nợ)',
          //         }];
          //       }
          //     }
          //   }
          //   if (this.commonService.getObjectId(detail.Type) == 'SERVICE') {
          //     business = [{
          //       id: 'PURCHASECOST',
          //       text: 'Chi phí phát sinh khi mua hàng: vận chuyển, xăng dầu,... (công nợ)',
          //     }];
          //   }
          //   detail['Business'] = business;
          // }
        }
      }

      if (requireSetData) {
        const total = this.calculateTotal();
        console.log('collaborator form set data: ', currentState.data);
        currentState.instance.$setState({
          data: currentState.data,
          total: total,
        });
      } else {
        currentState.instance.$setState({ total: this.calculateTotal() });
      }

      return fieldInfo;
    });
  }

  async removeDetailItem(self: F7ComponentContext, e: any, ...args: any): Promise<{ detail?: any, listName?: string; index?: string | number; }> {
    const currentState = this.currentState;
    return new Promise((resolve, reject) => {
      self.$app.dialog.confirm('Bạn có chắc là muốn gở chi tiết này ?', 'Gở chi tiết', () => {
        resolve(super.removeDetailItem(self, e, args).then(detailInfo => {
          let { detail, listName, index } = detailInfo;
          if (listName == 'UnitConversions') {
            self.$app.accordion.close(detail[0]);
          }
          this.calculateTotal();
          this.rootServices.playDecreasePipSound();
          return detailInfo;
        }).catch(err => {
          return Promise.reject(err);
        }));
      });
    });
  }


  async chooseProduct(self: F7ComponentContextExtend) {
    // const self: F7ComponentContextExtend = this;
    const $this = this;
    const currentState = this.currentState;
    this.rootServices.navigate('/collaborator/page-product-list/choose', {
      context: {
        pageId: self.$route.params['pageId'],
        backTitle: this.title,
        textColorClass: self.textColorClass,
        async onChoose(product: ProductModel, unitId?: string) {
          currentState.instance.$router.back();
          console.log('Choosed product', product);

          // const productInfo = (await $this.rootServices.apiService.getPromise<ProductModel[]>('/admin-product/products/' + product.Code, { includeIdText: true, includeUnits: true }))[0];
          // product.id = product.Product;
          // product.text = product.Name;
          // const unit = product.UnitConversions && product.UnitConversions.find(f => f.IsDefaultPurchase) || product.UnitConversions[0]
          // const unit = product.UnitConversions && product.UnitConversions.length == 1 && product.UnitConversions[0] || null;
          // const unit = product.UnitList.find(f => f.id == $this.commonService.getObjectId(product.Unit)) || product.UnitList[0] || product.Unit || null;

          // Load price
          // const unitPriceMap = await $this.rootServices.apiService.getPromise<SalesMasterPriceTableDetailModel[]>('/sales/master-price-table-details', {
          //   priceTable: 'default',
          //   eq_Code: product.Code,
          // }).then(rs => rs.reduce((result, current, index) => {
          //   result[current.Code + '-' + $this.commonService.getObjectId(current.Unit)] = current.Price;
          //   return result;
          // }, {}));

          // for (const unit of product.Units) {
          //   unit.Price = unitPriceMap[product.Code + '-' + $this.commonService.getObjectId(unit)];
          // }


          let unit = null;
          if (unitId) {
            unit = product.Units.find(f => $this.commonService.getObjectId(f) == unitId);
          } else {
            unit = product.Units.find(f => f.IsDefaultSales) || product.Units[0] || null;
          }

          const index = (currentState.data.Details || []).findIndex(f => $this.commonService.getObjectId(f.Product) == product.id && $this.commonService.getObjectId(f.Unit) == $this.commonService.getObjectId(unit));
          if (index > -1) {
            let item = currentState.data['Details'] && currentState.data['Details'][index] || null;
            if (item) {
              item.Quantity++;
              $this.updateItemForList('Details', index, item);
              $this.commonService.showInfo('Tăng số lượng lên ' + item.Quantity + ' cho sản phẩm ' + $this.commonService.getObjectText(product) + ' !', { position: 'bottom' });
            }
            return;
          }

          let business = [];
          if ($this.commonService.getObjectId(product.Type) == 'PRODUCT') {
            if (unit) {
              if (unit.IsAutoAdjustInventory === false) {
                business = [{
                  id: 'PURCHASESKIPWAREHOUSE',
                  text: 'Mua hàng nhưng không nhập kho (công nợ)',
                }];
              }
              if (unit.IsAutoAdjustInventory === true) {
                business = [{
                  id: 'PURCHASEWAREHOUSE',
                  text: 'Mua hàng sau đó nhập kho (công nợ)',
                }];
              }
            }
          }
          if ($this.commonService.getObjectId(product.Type) == 'SERVICE') {
            business = [{
              id: 'PURCHASECOST',
              text: 'Chi phí phát sinh khi mua hàng: vận chuyển, xăng dầu,... (công nợ)',
            }];
          }

          $this.addItemForList('Details', {
            ...new CollaboratorOrderDetailModel(),
            Type: product.Type || 'PRODUCT',
            // Business: business,
            Product: {
              id: product.Code,
              text: product.text,
              Units: product.Units,
            },
            Description: product.Name,
            Unit: unit,
            Price: unit.Price || 0,
            Quantity: 1,
            // Tax: 'NOTAX',
            __unitList: product.Units,
            Image: [
              ...[product.FeaturePicture],
              ...(Array.isArray(product.Pictures) && product.Pictures || []).filter(f => f.Id != product?.FeaturePicture?.Id)
            ]
          });

        },
      }
    });
  }

  calculateTotal(data?: CollaboratorOrderModel) {
    const currentState = this.currentState;
    let total = 0;
    const calculateData = data || currentState.data;
    if (calculateData.Details) {
      for (const detail of calculateData.Details) {
        const toMoney = detail.ToMoney = (detail.Quantity || 0) * (detail.Price || 0);
        total += toMoney;
      }
    }

    // currentState.instance.$setState({ total });
    return total;
  }

  async save(self: F7ComponentContext, option?: { postParams?: any, updateProperties?: string[] }) {
    const $this = this;
    if (!option) option = {};
    if (!option.postParams) option.postParams = {};
    option.postParams.page = $this.commonService.getObjectId($this.currentState.data.Page);
    return super.save(self, option).then(rs => {
      this.currentState.data.State = rs.State;
      self.$setState({ data: this.currentState.data });
      return rs;
    });
  }

  async savePdf(self: F7ComponentContextExtend, e: any) {
    if (this.currentState.data.State !== 'APPROVED') {
      await this.save(self);
    }
    this.rootServices.iab.create(this.rootServices.apiService.buildApiUrl(this.apiPath, {
      id: this.currentState.data.Code,
      includeContact: true,
      includeDetails: true,
      includeUnit: true,
      renderPdf: 'download',
      page: self.$route.params['pageId'],
    }) as string, '_system');
  }
  async changeState(self: F7ComponentContextExtend, e: any) {
    // const newState = this.currentState.data.State == 'APPROVED' ? 'NOTJUSTAPPROVED' : 'APPROVED';
    const currentState = CollaboratorStateMap.orderStateMap[this.commonService.getObjectId(this.currentState.data.State)];
    if (currentState && currentState.nextStates) {
      const supplierDialog = this.currentState.instance.$app.dialog.create({
        cssClass: 'dialog-large',
        title: 'Chuyển trạng thái',
        text: 'Phiếu đang ở trạng thái `' + currentState.label + '`, bạn có muốn chuyển sang các trạng thái bên dưới ?',
        buttons: [
          ...currentState.nextStates.map((m, index) => ({
            text: (index + 1) + '. ' + m.confirmLabel,
            color: 'success',
            onClick: () => {
              this.rootServices.apiService.putPromise(this.apiPath + '/' + this.currentState.data.Code, { page: self.$route.params['pageId'], changeState: m.state }, [{ Code: this.currentState.data.Code }]).then(rs => {
                this.currentState.data.State = m.state;
                this.setData(this.currentState.data);
              }).catch(err => {
                this.commonService.showError(err);
              });
            }
          })),
          {
            text: 'Trở về',
            bold: true,
            color: 'red'
          },
        ],
        verticalButtons: true,
      });
      supplierDialog.open();
    }

    // this.currentState.instance.$app.dialog.confirm('Bạn có muốn ' + (newState == 'APPROVED' ? 'duyệt' : 'bỏ duyệt') + ' phiếu báo giá `' + this.currentState.data.Title + '`', 'Thay đổi trạng thái báo giá', async () => {

    //   if (this.currentState.data.State !== 'APPROVED') {
    //     await this.save(self);
    //   }
    //   await this.rootServices.apiService.putPromise<CollaboratorOrderModel>(this.apiPath, {
    //     changeState: newState,
    //   }, [{ Code: this.currentState.data.Code }]).then(rs => {
    //     if (newState == 'APPROVED') {
    //       this.commonService.showInfo('Đã duyệt phiếu đặt mua hàng !', { position: 'bottom' });
    //     } else {
    //       this.commonService.showInfo('Đã bỏ duyệt phiếu đặt mua hàng !', { position: 'bottom' });
    //     }
    //   });
    //   this.currentState.data.State = newState;
    //   this.setData(this.currentState.data);

    // });
  }



  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: 'page-collaborator-order',
      path: '/page-collaborator-order/:pageId/:id',
      component: {
        template: /*html*/`
            <div class="page page-form collaborator-order-form no-toolbar-x" data-name="collaborator-order-form">
              <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a class="link back {{textColorClass}}">
                      <i class="icon icon-back"></i>
                      <span class="if-not-md {{textColorClass}}">{{backTitle}}</span>
                    </a>
                  </div>
                  <div class="title">Đơn Hàng CTV</div>
                  <div class="right">
                    <a class="link icon-only {{textColorClass}}">
                      <i class="icon f7-icons">arrowshape_turn_up_right</i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="page-content ptr-content infinite-scroll-content" @ptr:refresh="refresh" data-ptr-distance="150">
                <div class="ptr-preloader">
                  <div class="preloader"></div>
                  <div class="ptr-arrow"></div>
                </div>

                <form class="data-form">
                  <div class="list profile-form no-hairlines-md inset main-form">
                    <ul>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label">ID phiếu (*)</div>
                          <div class="item-input-wrap">
                            <input @change="onFieldChange" disabled class="field text-color-blue" name="Code" type="text" placeholder="ID phiếu, auto..."
                              value="{{data.Code}}">
                          </div>
                        </div>
                      </li>
                      {{#if copyFromId}}
                      <li class="item-content">
                        <div class="item-inner">
                          <div class="item-input-wrap text-color-orange">
                            <span style="font-weight: bold; font-style: italic">Bạn đang thao tác trên phiếu copy</span>
                          </div>
                        </div>
                      </li>
                      {{/if}}
                    </ul>
                  </div>

                  <div class="block-title">Thông tin khách hàng <a style="float: right" @click="createNewCustomer" class="{{textColorClass}}">+ mới</a></div>
                  <div class="list profile-form no-hairlines-md inset main-form">
                    <ul>
                      <li class="autocomplete" name="Object">
                        <div class="item-link item-content" href="#">
                          <div class="item-inner">
                            <div class="item-title">Liên hệ (*)</div>
                            <div class="item-after text-color-blue {{validate data.Object schema 'Object'}}">{{objecttext this 'data.Object' 'Khách hàng(*)...'}}</div>
                          </div>
                        </div>
                      </li>

                      
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label {{validate data.ObjectName schema 'ObjectName'}}">Tên (*)</div>
                          <div class="item-input-wrap">
                            <input disabled @keyup="onFieldChange" class="field text-color-bluex" name="ObjectName" type="text" placeholder="Tên khách hàng..."
                              value="{{data.ObjectName}}">
                            
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label">Số điện thoại (*)</div>
                          <div class="item-input-wrap">
                            <input disabled @keyup="onFieldChange" class="field text-color-bluex" name="ObjectPhone" type="text" placeholder="SĐT khách hàng..."
                              value="{{data.ObjectPhone}}">
                            
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label">Email (*)</div>
                          <div class="item-input-wrap">
                            <input disabled @keyup="onFieldChange" class="field text-color-bluex" name="ObjectEmail" type="text" placeholder="Email khách hàng..."
                              value="{{data.ObjectEmail}}">
                            
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label">Địa chỉ (*)</div>
                          <div class="item-input-wrap">
                            <input @keyup="onFieldChange" class="field text-color-blue" name="ObjectAddress" type="text" placeholder="Số nhà, tên đường"
                              value="{{data.ObjectAddress}}">
                            
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  
                  <div class="block-title">Địa chỉ nhận hàng</div>
                  <div class="list profile-form no-hairlines-md inset main-form">
                    <ul>
                      <li class="autocomplete {{validate data.DeliveryProvince schema 'DeliveryProvince'}}" name="DeliveryProvince">
                        <div class="item-link item-content" href="#">
                          <div class="item-inner">
                            <div class="item-title">Tỉnh/TP (*)</div>
                            <div class="item-after text-color-blue">{{objecttext this "data.DeliveryProvince" "Tỉnh/TP (*)..."}}</div>
                          </div>
                        </div>
                      </li>
                      <li class="autocomplete {{validate data.DeliveryDistrict schema 'DeliveryDistrict'}}" name="DeliveryDistrict">
                        <div class="item-link item-content" href="#">
                          <div class="item-inner">
                            <div class="item-title">Quận/Huyện (*)</div>
                            <div class="item-after text-color-blue">{{objecttext this "data.DeliveryDistrict" "Quận/Huyện (*)..."}}</div>
                          </div>
                        </div>
                      </li>
                      <li class="autocomplete {{validate data.DeliveryWard schema 'DeliveryWard'}}" name="DeliveryWard">
                        <div class="item-link item-content" href="#">
                          <div class="item-inner">
                            <div class="item-title">Phường/Xã (*)</div>
                            <div class="item-after text-color-blue">{{objecttext this "data.DeliveryWard" "Phường/Xã (*)..."}}</div>
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input"> 
                        <div class="item-inner">
                          <div class="item-title item-label">Địa chỉ (*)</div>
                          <div class="item-input-wrap">
                            <input @keyup="onFieldChange" class="field text-color-blue" name="DeliveryAddress" type="text" placeholder="Số nhà, tên đường..."
                              value="{{data.DeliveryAddress}}">
                            
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div class="block-title">Thông tin chung</div>
                  <div class="list profile-form no-hairlines-md inset main-form">
                    <ul>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label {{validate data.Title schema 'Title'}}">Tiêu đề (*)</div>
                          <div class="item-input-wrap">
                            <input @keyup="onFieldChange" class="field text-color-blue" name="Title" type="text" placeholder="Tiêu đề..."
                              value="{{data.Title}}">
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div class="block-title" style="margin-top: 0.5rem">Chi tiết</div>
                  <div class="list-name thumbnail-list" name="Details">
                    {{#each data.Details}}
                      <div class="block block-strong inset list-item index-{{@index}} {{js "this.__hasWarning ? 'bg-color-orange' : (this.__active ? 'bg-color-red text-color-white' : '')"}}" data-id="{{Uuid}}" data-index="{{@index}}">
                        <div class="item-content">
                          <div class="detail-wrap">
                            <div class="detail-header" >
                              <div class="header-label">{{js "this.Description || this.Product && this.Product.Name"}}</div>
                              <div class="detail-remove" @click="removeDetailItem"><i class="icon f7-icons {{js "this.__active ? 'color-white' : 'color-red'"}}">xmark_circle_fill</i></div>
                            </div>
                            <div class="detail-body">
                              <div class="image-wrap">
                                <div class="image"  @click="previewDetailAcctachment" data-index="0" style="{{js "this.Image && this.Image[0] && this.Image[0].Thumbnail && ('background-image: url('+this.Image[0].Thumbnail+')') || ''"}}"></div>
                              </div>
                              <div class="detail-content-wrap">
                                <div class="list inline-labels no-hairlines">
                                  <ul style="background: none">
                                    <li class="item-content item-input">
                                      <div class="item-inner" dtyle="min-height: 2rem; padding-top: 0; padding-bottom: 0">
                                        <div class="item-title item-label">SL</div>
                                        <div class="item-input-wrap">
                                          <div class="stepper stepper-fill stepper-init" data-manual-input-mode="true" data-autorepeat="true" data-autorepeat-dynamic="true" name="Quantity" @stepper:change="onFieldChange">
                                            <div class="stepper-button-minus"></div>
                                            <div class="stepper-input-wrap">
                                              <input type="text" value="{{Quantity}}" min="0" max="999999999999999999999" step="1" inputmode="decimal" class="{{js "this.__active ? 'text-color-white' : ''"}}" name="Quantity" @keyup="onFieldChange">
                                            </div>
                                            <div class="stepper-button-plus"></div>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li class="item-link smart-select smart-select-init" name="Unit" data-open-in="popup" data-searchbar="true" data-virtual-list="true" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFieldChange" data-list-name="UnitConversions" data-index="{{@index}}">
                                      <select>
                                        <option value="">Chon...</option>
                                        {{#js_if "this.__unitList && this.__unitList.length > 0"}}
                                          {{#each __unitList}}
                                          <option value="{{id}}">{{text}}</option>
                                          {{/each}}
                                        {{else}}
                                          {{#each ../../unitList}}
                                          <option value="{{id}}">{{text}}</option>
                                          {{/each}}
                                        {{/js_if}}
                                      </select>
                                      <div class="item-content">
                                        <div class="item-inner">
                                          <div class="item-title {{validate Unit ../schema 'Details.Unit'}}">ĐVT (*)</div>
                                          <div class="item-after {{js "this.__active ? 'text-color-white' : 'text-color-blue'"}}"></div>
                                        </div>
                                      </div>
                                    </li>
                                    <li class="item-content item-input">
                                      <div class="item-inner">
                                        <div class="item-title item-label">Giá</div>
                                        <div class="item-input-wrap">
                                          <input disabled type="text" class="inputmask field {{js "this.__active ? 'text-color-white' : 'text-color-blue'"}}" name="Price" value="{{decimal Price}}" placeholder="Đơn giá" @keyup="onFieldChange">
                                          <div class="currency-symbol {{js "this.__active ? 'text-color-white' : 'text-color-blue'"}}">₫</div>
                                        </div>
                                      </div>
                                    </li>
                                    <li class="item-content item-input">
                                      <div class="item-inner">
                                        <div class="item-title item-label">T.Tiền</div>
                                        <div class="item-input-wrap">
                                          <input disabled type="text" class="inputmask field {{js "this.__active ? 'text-color-white' : 'text-color-blue'"}}" name="ToMoney" style="font-weight: bold;" value="{{decimal ToMoney}}" placeholder="Thành tiền" @keyup="onFieldChange">
                                          <div style="font-weight: bold" class="currency-symbol {{js "this.__active ? 'text-color-white' : 'text-color-blue'"}}">₫</div>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div class="detail-footer">SKU: {{js "this.Product && this.Product.Sku"}}, ID: {{js "this.Product && this.Product.Code"}}</div>
                          </div>
                        </div>
                      </div>
                      {{/each}}
                  </div>
              
                  <div class="block block-strong inset" style="text-align: right; font-weight: bold; font-size: 1rem;">
                    Tổng cộng: <span class="text-color-blue">{{currency total}}</span>
                  </div>

                  <div class="block">
                    <a class="col button  button-large button-outline link color-orange" style="width: 100%" @click="chooseProduct"><i class="icon f7-icons if-not-md-x">cart</i> Chọn hàng hóa/dịch vụ</a>
                  </div>

                  <div class="block">
                    <p class="row">
                      <a class="col-50 button button-fill button-large color-blue {{#if validates}}disabled{{/if}}"  @click="save"> Lưu lại</a>
                      <a class="col-50 button button-fill button-large color-red  {{#if validates}}disabled{{/if}}" @click="savePdf">Save PDF</a>
                    </p>
                    <p class="row">
                      <!--<a class="col-50 button button-fill button-large color-{{js "this.data.State && this.processMap && this.processMap[this.data.State] && this.processMap[this.data.State].color"}}  {{#if validates}}disabled{{/if}}" @click="changeState">{{js "this.data.State && this.processMap && this.processMap[this.data.State] && this.processMap[this.data.State].label"}}</a>-->
                      <a class="col-50 button button-fill button-large color-  {{#if validates}}disabled{{/if}}" @click="changeState">{{objecttext this "data.State" "---"}}</a>
                      <a class="col-50 button button-fill button-large color-yellow  {{#if validates}}disabled{{/if}}" @click="openRelativeTask">Mở task</a>
                    </p>
                  </div>

                  <div class="block-title">Ghi chú <i>{{js "this.data.Seller && ' - bạn không thể thay đổi ghi chú !' || ''"}}</i></div>
                  <div class="list profile-form no-hairlines-md main-form">
                    <div class="text-editor text-editor-init Description" name="Note" data-placeholder="Ghi chú..." @texteditor:change="onFieldChange">
                      <div class="text-editor-content" contenteditable></div>
                    </div>
                  </div>

                  {{#if validates}}
                  <div class="block-title text-color-arange">Cảnh báo</div>
                  <div class="list inset">
                    <ul>
                      {{#each validates}}
                      <li>
                        <div class="item-content">
                          <div class="item-inner">
                            <div class="item-title">{{validates[0].label}}</div>
                            <div class="item-after text-color-orange">{{validates[0].text}}</div>
                          </div>
                        </div>
                      </li>
                      {{/each}}
                    </ul>
                  </div>
                  {{/if}}
                </form>
            
                <div class="block"><br></div>
              </div>
            </div>
        `,
        style: /*css*/`
          .page.collaborator-order-form .demo-card-header-pic .card-header {
            height: calc(100vw - 2rem);
            background-size: cover;
            background-position: center;
            color: #fff;
          }

          .page.collaborator-order-form .demo-card-header-pic .card-content-padding .date {
            color: #8e8e93;
          }
          .page.collaborator-order-form .pictures::-webkit-scrollbar {
            display: none;
          }

          .page.collaborator-order-form .card .background {
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-image: url(/assets/images/no-image-available.png);
          }

          .page.collaborator-order-form .inline-labels .item-label, .inline-label .item-label, .inline-labels .item-floating-label, .inline-label .item-floating-label {
            width: auto !important;
          }
        `,
        data() {
          return {
            title: $this.title,
            reminder: {
              No: '234234',
            },
            data: new CollaboratorOrderModel(),
            taxList: $this.taxList,
            contactMemebets: [],
            // contactList: [],
            validates: null,
          };
        },
        methods: {
          createNewCustomer(e) {
            const self = this;
            $this.rootServices.createNewContactForm('Khách hàng mới', { Groups: [{ id: 'CUSTOMER', text: 'Khách hàng' }] }).then(newContact => {
              $this.currentState.data.Object = { id: newContact.Code, text: newContact.Name };
              $this.currentState.data.ObjectName = newContact.Name;
              $this.currentState.data.ObjectPhone = newContact.Phone;
              $this.currentState.data.ObjectEmail = newContact.Email;
              $this.currentState.data.ObjectAddress = newContact.Address;

              $this.currentState.data.DeliveryProvince = newContact.Province;
              $this.currentState.data.DeliveryDistrict = newContact.District;
              $this.currentState.data.DeliveryWard = newContact.Ward;
              $this.currentState.data.DeliveryAddress = newContact.Address;
              $this.setData($this.currentState.data);
            }).catch(err => {
              $this.commonService.showError(err);
            });
          },
          createNewPublisher(e) {
            const self = this;
            $this.rootServices.createNewContactForm('CTV Bán hàng mới', { Groups: [{ id: 'PUBLISHER', text: 'CTV' }, { id: 'SALESCOLLABORATOR', text: 'CTV Bán Hàng ™' }] }).then(newContact => {
              $this.currentState.data.Publisher = { id: newContact.Code, text: newContact.Name };
              $this.currentState.data.PublisherName = newContact.Name;
              $this.currentState.data.PublisherPhone = newContact.Phone;
              $this.currentState.data.PublisherEmail = newContact.Email;
              $this.currentState.data.PublisherAddress = newContact.Address;
              $this.setData($this.currentState.data);
            }).catch(err => {
              $this.commonService.showError(err);
            });
          },
          previewPictures(e) {
            const self: F7ComponentContextExtend = this;
            const currentState = $this.currentState;

            let index = $(e.target).data('index');
            if (typeof index == 'undefined') {
              const id = $(e.target).attr('data-id');
              index = currentState.data.Attachments.findIndex(f => f.Id == id);
            }
            $this.rootServices.previewPictures(currentState.data.Attachments, index || 0, { addToStackButton: true });
          },
          addAcctachment() {
            const self: F7ComponentContextExtend = this;
            const currentState = $this.currentState;
            $this.rootServices.pickAndUploadFiles().subscribe(state => {
              if (state.state == 'UPLOADING') {
                const progress = state.progress;
                console.log('Upload progress: ', progress);
              }
              if (state.state == 'COMPLETE') {
                const images = state.results;
                // currentState.data.Pictures.unshift(images[0]);
                // currentState.data.FeaturePicture = images[0];
                // self.$setState({ data: currentState.data });
                console.log('upload pictures success', images);
                if (!$this.currentState.data.Attachments) {
                  $this.currentState.data.Attachments = [];
                }
                for (const image of images) {
                  $this.currentState.data.Attachments.push(image);
                }
                $this.currentState.instance.$setState({ data: $this.currentState.data });
              }
              if (state.state == 'ERROR') {
                console.error(state);
              }
            });
          },
          changeState(e) {
            $this.changeState(this, e);
          },
          savePdf(e) {
            $this.savePdf(this, e);
          },
          openRelativeTask(e) {
            const self: F7ComponentContextExtend = this;
            const currentState = $this.currentState;
            const voucher = currentState.data;
            if (voucher) {
              const relativeTask = voucher.RelativeVouchers && voucher.RelativeVouchers.find(f => f.type == 'CHATROOM' || f.type == 'TASK') || null;
              if (relativeTask) {
                $this.rootServices.navigate('/chat-room/' + relativeTask.id);
              } else {
                if (!voucher.Code || !voucher.Title) {
                  $this.commonService.showError('Bạn phải lưu phiếu trước và phiếu phải có tiêu đề !', { position: 'bottom' });
                  return;
                }
                self.$app.dialog.create({
                  title: 'Tạo task',
                  content: 'Bạn muốn tạo task cho phiếu này hày liên kết với task sẵn có ?',
                  verticalButtons: true,
                  buttons: [
                    {
                      text: 'Tạo mới',
                      bold: false,
                      color: 'green',
                      onClick: () => {
                        self.$f7.dialog.prompt('Mô tả cho task mới', function (description) {
                          const chatRoomData = {
                            Type: 'WORKPLACE',
                            Description: description,
                            RelativeVouchers: [
                              {
                                id: voucher.Code,
                                text: voucher.Title,
                                type: 'PURCHASEORDER'
                              }
                            ],
                          };
                          self.$app.preloader.show();
                          $this.rootServices.apiService.postPromise<ChatRoomModel[]>('/chat/rooms', {}, [chatRoomData]).then(async rs => {
                            // Update relative vouchers for current voucher
                            currentState.data.RelativeVouchers = await $this.rootServices.apiService.getPromise<CollaboratorOrderModel[]>($this.apiPath + '/' + currentState.data.Code, { includeRelativeVouchers: true }).then(rs => rs[0].RelativeVouchers);
                            currentState.instance.$setState({ data: currentState.data });

                            const contactMembers = [];
                            if (voucher.Object) {
                              contactMembers.push({
                                Type: 'CONTACT',
                                id: $this.commonService.getObjectId(voucher.Object),
                                Name: voucher.ObjectName
                              });
                            }
                            if (voucher.Contact) {
                              contactMembers.push({
                                Type: 'CONTACT',
                                id: $this.commonService.getObjectId(voucher.Contact),
                                Name: voucher.ContactName
                              });
                            }

                            if (contactMembers.length > 0) {
                              await $this.rootServices.apiService.postPromise<ChatRoomModel[]>('/chat/room-members', { chatRoom: rs[0].Code }, contactMembers).catch(err => {
                                console.log('skip for can not add cotnact', err);
                                return false;
                              });
                            }

                            console.log(rs);
                            if (rs && rs[0] && rs[0].Code) {
                              $this.commonService.navigate('/chat-room/' + rs[0].Code, {
                                context: {
                                  // backTitle: 'Workplace',
                                  title: rs[0].Description,
                                }
                              });
                              self.$app.preloader.hide();
                            }
                          }).catch(err => {
                            console.error(err);
                            self.$app.preloader.hide();
                            $this.commonService.showError(err, { position: 'bottom' });
                          });
                        }, null, voucher.Title);
                      }
                    },
                    {
                      text: 'Task sẵn có',
                      bold: false,
                      color: 'blue',
                      onClick: () => {
                        $this.commonService.navigate('/workplace/choose-parent', {
                          context: {
                            backTitle: 'Chat GUI',
                            title: 'Chọn task',
                            extendComponentId: 'choose-for-' + voucher.Code,
                            callback: async (task: ChatRoomModel) => {
                              console.log('choosed task:', task);
                              self.$app.preloader.show();
                              const taskRelativeVouchers = await $this.rootServices.apiService.getPromise<ChatRoomModel[]>('/chat/rooms/' + task.Code, { includeRelativeVouchers: true }).then(rs => rs[0].RelativeVouchers);
                              $this.rootServices.apiService.putPromise<ChatRoomModel[]>('/chat/rooms/' + task.Code, {}, [{
                                Code: task.Code, RelativeVouchers: [
                                  ...(taskRelativeVouchers || []),
                                  {
                                    id: voucher.Code,
                                    text: voucher.Title,
                                    type: 'PURCHASEORDER',
                                  }
                                ]
                              }]).then(async rs => {

                                // Update relative vouchers for current voucher
                                currentState.data.RelativeVouchers = await $this.rootServices.apiService.getPromise<CollaboratorOrderModel[]>($this.apiPath + '/' + currentState.data.Code, { includeRelativeVouchers: true }).then(rs => rs[0].RelativeVouchers);
                                currentState.instance.$setState({ data: currentState.data });

                                const contactMembers = [];
                                if (voucher.Object) {
                                  contactMembers.push({
                                    Type: 'CONTACT',
                                    id: $this.commonService.getObjectId(voucher.Object),
                                    Name: voucher.ObjectName
                                  });
                                }
                                if (voucher.Contact) {
                                  contactMembers.push({
                                    Type: 'CONTACT',
                                    id: $this.commonService.getObjectId(voucher.Contact),
                                    Name: voucher.ContactName
                                  });
                                }

                                // Add contacts to task
                                if (contactMembers.length > 0) {
                                  await $this.rootServices.apiService.postPromise<ChatRoomModel[]>('/chat/room-members', { chatRoom: task.Code }, contactMembers).catch(err => {
                                    console.log('skip for can not add cotnact', err);
                                    return false;
                                  });
                                }

                                setTimeout(() => {
                                  self.$app.preloader.hide();
                                  $this.commonService.navigate('/chat-room/' + task.Code, {
                                    context: {
                                      // backTitle: 'Workplace',
                                      title: voucher.Title,
                                    }
                                  });
                                }, 700);
                              }).catch(err => {
                                self.$app.preloader.hide();
                              });
                            },
                          }
                        });
                      }
                    },
                    {
                      text: 'Đóng',
                      bold: true,
                      color: 'red',
                      onClick: () => {

                      }
                    },
                  ]
                }).open();

              }
            }
          },
          previewDetailAcctachment(e) {
            const self: F7ComponentContextExtend = this;
            const currentState = $this.currentState;
            let index = $(e.target).closest('.list-item').data('index');
            let realIndex = 0;
            let pictures = [];
            if (currentState.data.Details) {
              for (const detailIndex in currentState.data.Details) {
                const detail = currentState.data.Details[detailIndex];
                const detailImages = detail.Image;
                if (Array.isArray(detailImages)) {
                  if (detailIndex == index) {
                    realIndex = pictures.length;
                  }
                  pictures = pictures.concat(detailImages.filter(f => !!f).map(m => { m.Description = detail.Description; return m; }));
                }
              }
            }
            $this.rootServices.previewPictures(pictures, realIndex, { addToStackButton: true });
          },
          setFeaturePicture(e) {
            const self: F7ComponentContextExtend = this;
            const currentState = $this.currentState;
            let index = $(e.target).closest('a').data('index');
            if (typeof index != 'undefined') {
              self.$app.dialog.confirm('Bạn có muốn đặt làm hình đại diện không ?', 'Đặt hình đại diện', () => {
                currentState.data.FeaturePicture = currentState.data.Pictures[index];
                self.$setState({ data: currentState.data });
              })
            }
          },
          uploadPicture(e) {
            const self: F7ComponentContextExtend = this;
            const currentState = $this.currentState;
            $this.updateImages((progress) => {
              console.log(progress);
            }, (image) => {
              currentState.data.Pictures.unshift(image);
              currentState.data.FeaturePicture = image;
              self.$setState({ data: currentState.data });

            }).then(images => {
              console.log('upload pictures success', images);
            });
          },
          addDetail(e) {
            const self: F7ComponentContextExtend = this;
            const currentState = $this.currentState;

            $this.chooseProduct(self);

            // let listName = $(e.target).data('list-name');
            // $this.addItemForList(listName);

          },
          chooseProduct(e) {
            const self: F7ComponentContextExtend = this;
            $this.chooseProduct(self);
          },
          scanProductQrCode(e) {
            const self: F7ComponentContextExtend = this;
            const currentState = $this.currentState;
            const systemConfigs = $this.rootServices.systemSettings$.value;

            $this.rootServices.barcodeScanner.scan({ showTorchButton: true }).then(barcodeData => {
              // const data = barcodeData && barcodeData.text.split('|');
              const data = barcodeData && barcodeData.text;

              let tmpcode = data.substring(1);
              const findOrderLength = parseInt(tmpcode.substring(0, 1));
              tmpcode = tmpcode.substring(1);
              const findOrder = tmpcode.substring(0, findOrderLength);
              tmpcode = tmpcode.substring(findOrderLength);
              const unitSeqLength = parseInt(tmpcode.substring(0, 1));
              tmpcode = tmpcode.substring(1);
              let unitSeq = tmpcode.substring(0, unitSeqLength);
              tmpcode = tmpcode.substring(unitSeqLength);
              let productId = tmpcode;


              let goodsId = `118${systemConfigs.ROOT_CONFIGS.coreEmbedId}${productId}`;
              if (!data[0]) return false;
              if (productId) {

                // const [productId, unitId] = data[0].split('-');
                if (!data[0]) return false;
                $this.rootServices.apiService.getPromise<any[]>('/admin-product/products/' + goodsId, {
                  select: 'id=>Code,text=>Name,Code=>Code,Name,OriginName=>Name,Sku,FeaturePicture,Pictures',
                  includeSearchResultLabel: true,
                  includeUnits: true,
                  ...self.filter,
                }).then(products => {
                  // self.$$(page.el).find('.page-content').scrollTo(0, 0);
                  // self.$setState({ productList: products.map(t => $this.preapreProduct(t)), infiniteLoadLast: products.length === 0 });
                  // self.infiniteOffset = 0;

                  const product = products[0];
                  if (!product) return;

                  product.Unit = product.Units?.find(f => f.Sequence == unitSeq);
                  product.UnitList = product.Units;

                  let existsProduct: ProductModel = (currentState.voucher.Details || []).find(f => $this.commonService.getObjectId(f.Product as any) === product.Code && $this.commonService.getObjectId(f.Unit as any) === $this.commonService.getObjectId(product.Unit));
                  if (!existsProduct) {
                    existsProduct = {
                      Product: product.Code,
                      Description: product.Name,
                      Quantity: 1,
                      // Price: product.Price,
                      Unit: product.Unit,
                      // PriceText: $this.rootServices.currencyPipe.transform(product.Price, 'VND'),
                      OriginPrice: product.OriginPrice,
                      OriginPriceText: $this.rootServices.currencyPipe.transform(product.OriginPrice, 'VND'),
                      // Tax: { id: 'NOTAX', text: 'n/a' } as any,
                      // TaxText: product.TaxText,
                      // ToMoney: (existsProduct.Quantity * existsProduct.Price) + (existsProduct.Quantity * existsProduct.Price * (existsProduct.Tax && existsProduct.Tax.Tax || 0)),
                      // ToMoneyText: $this.rootServices.currencyPipe.transform(product.Price, 'VND'),
                      Image: product.Pictures,
                      Thumbnail: product.FeaturePicture && product.FeaturePicture?.Thumbnail || null,
                      Promotion: product.Promotion,
                      PromotionText: product.PromotionText,
                      Categories: product.Categories,
                      CategoriesText: product.Categories?.map(m => m.text).join(', '),
                      // TaxList: product.UnitList,
                      // UnitList: [],
                    };

                    // if (product.Tax && !product.Tax.id) {
                    //   existsProduct.Tax = $this.taxList.find(f => f.id === (product.Tax as any));
                    // } else {
                    //   existsProduct.Tax = $this.taxList.find(f => f.id === 'NOTAX');
                    // }
                    if (product.Unit && !product.Unit.id) {
                      existsProduct.Unit = { id: product.Unit, text: product.Unit } as any;
                    } else {
                      existsProduct.Unit = product.Unit
                    }

                    // existsProduct.TaxList = [];
                    // for (const tax of $this.taxList) {
                    //   existsProduct.TaxList.push({ ...tax, selected: tax.id == ($this.commonService.getObjectId(existsProduct.Tax as any) || 'NOTAX') })
                    // }

                    existsProduct.UnitList = [];
                    if (product.UnitList && product.UnitList.length > 0) {
                      for (const unit of product.UnitList) {
                        const isSelected = unit.id === $this.commonService.getObjectId(product.Unit);
                        if (isSelected && unit.Price) {
                          existsProduct.Price = unit.Price;
                          existsProduct.PriceText = $this.rootServices.currencyPipe.transform(unit.Price, 'VND');
                        }
                        existsProduct.UnitList.push({ ...unit, selected: isSelected });
                      }
                    } else {
                      for (const unit of $this.unitList) {
                        existsProduct.UnitList.push({ ...unit, selected: product.Unit && unit.id === $this.commonService.getObjectId(existsProduct.Unit) });
                      }
                    }
                    if (!currentState.voucher.Details) {
                      currentState.voucher.Details = [];
                    }
                    currentState.voucher.Details.push(existsProduct);
                  } else {
                    existsProduct.Quantity++;
                  }
                  // existsProduct.ToMoney = (existsProduct.Quantity * existsProduct.Price) + (existsProduct.Quantity * existsProduct.Price * (existsProduct.Tax && existsProduct.Tax.Tax || 0) / 100);
                  existsProduct.ToMoney = existsProduct.Quantity * existsProduct.Price;
                  existsProduct.ToMoneyText = $this.rootServices.currencyPipe.transform(existsProduct.ToMoney, 'VND');

                  self.calculateTotal();
                  self.$setState({
                    voucher: currentState.voucher,
                  });

                  $this.commonService.showInfo('Đã thêm hàng hóa/dịch vụ vào báo giá', { position: 'bottom' });


                  // setTimeout(() => {
                  //   self.scanProductQrCode(e);
                  // }, 300);

                }).catch(err => {
                  console.error(err);
                  $this.commonService.showError(err, { position: 'bottom' });

                });
              }
            });
          },
          removeDetailItem(e) {
            return $this.removeDetailItem(this, e);
          },
          onFieldChange(e) {
            $this.onFieldChange(this, e);
          },
          async refresh(e, done) {

            done && setTimeout(() => done(), 10000);
            $this.refresh(this).then(data => {
              done && done();
            });

            return true;
          },
          save() {
            const self: F7ComponentContextExtend = this;
            const currentState = $this.currentState;
            const relativeVouchers = self.$route.context.relativeVouchers;
            const id = currentState.data.Code || self.$route.params['id'];

            $this.commonService.showPreloader();

            const preloaderProcess = setTimeout(() => {
              $this.commonService.hidePreloader();
            }, 15000);

            if (id === 'new') {
              if (relativeVouchers) {
                currentState.data.RelativeVouchers = relativeVouchers;
              }
            }

            $this.save(self, {
              postParams: {
                page: self.$route.params['pageId'],
              }
            }).then(rs => {
              $this.commonService.showInfo("Đã lưu đon đặt mua hàng", { position: 'bottom' });
              clearTimeout(preloaderProcess);
              $this.commonService.hidePreloader();
            }).catch(err => {
              clearTimeout(preloaderProcess);
              $this.commonService.hidePreloader();
              console.error(err);
            });
          },
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          pageInit(e, page: F7Page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;

            $this.onComponentInit({ instance: self, page: page }, self.$route.params['id'], null, page).then(curentState => {
              // self.detailDialog = self.$app.dialog.create({
              //   el: page.$el.find('.dialog-detail'),
              //   closeByBackdropClick: true,
              // });
            });

            $this.rootServices.authService.isAuthenticatedOrRefresh().pipe(take(1)).toPromise().then(() => {
              self.$setState({
                backTitle: self.$route.context && self.$route.context.backTitle || 'Back', title: self.$route.context && self.$route.context.title || 'Chat GUI',
              });
            }).catch(err => {
              console.error(err);
              $this.commonService.showError(err, { position: 'bottom' });
            });
          },
          // pageBeforeIn(e, page) {
          //     console.log('page before in');
          //     const self: F7ComponentContextExtend = this;
          //     self.refresh();
          // },
          // pageAfterIn(e, page) {
          //     console.log('page after in');
          // },
          pageBeforeOut(e, page) {
            console.log('page before out');
            const self: F7ComponentContextExtend = this;
            // Call caback function
            if (this.$route?.context['callback']) {
              this.$route?.context['callback']($this.currentState.data, { lastAction: $this.currentState.lastAction });
            }

            // Auto save
            if (0) $this.currentState?.data?.Code && (!$this.currentState?.data?.State || $this.currentState?.data?.State == 'NOTJUSTAPPROVED') && self.save();
          },
          // pageAfterOut(e, page) {
          //     console.log('page after out');
          // },
          // pageBeforeUnmount(e, page) {
          //     console.log('page before unmount');
          // },
          // pageBeforeRemove(e, page) {
          //     console.log('page before remove');
          // },
          pageBeforeRemove(e, page) {
            console.log('[page event] before remove', page.route.url);
            const self: F7ComponentContextExtend = this;
            // const starmtSelectRoles = self.$app.smartSelect.get('.smart-select');
            // starmtSelectRoles.destroy();
            const currentState = $this.state[self.$route.params.id];
            if (self.$route.params.id === 'new') {
              if (currentState && currentState.data && currentState.data.Code) {
                delete currentState.data;
              }
            }
          },
        },
      },
    };
  }
}
