import { CommonService } from './../../services/common.service';
import { WarehouseGoodsInContainerModel } from './../../model/warehouse.model';
import Inputmask from "inputmask";
import { SmartSelect } from 'framework7/build/core/components/smart-select/smart-select';
import { take } from 'rxjs/operators';
import { Router } from "framework7/build/core/modules/router/router";
import { ComponentState } from "../../../../src/app/lib/base-component";
import { BaseListComponent, F7ListComponentContext } from "../../../../src/app/lib/base-list-component";
import { ChatRoom } from "../../../../src/app/lib/nam-chat/chat-room";
import { FileModel } from "../../../../src/app/model/file.model";
import { ProductCategoryModel, ProductGroupModel, ProductModel } from "../../../../src/app/model/product.model";
import { RootServices } from "../../../../src/app/services/root.services";
import { F7Page } from "../../../../src/app/types/framework7-types";
// import * as $ from 'jquery';
declare const $: any;

// declare const $: any;
export interface ComponentStateExtend extends ComponentState {
  [key: string]: any;
  instance?: F7ComponentContextExtend;

  filter?: {
    [key:string]: any,
    Categories?: string[],
    Groups?: string[];
  };
}

export interface F7ComponentContextExtend extends F7ListComponentContext<ProductModel> {
  responseInProgress?: boolean;
  productList?: ProductModel[];
  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, chatRoom?: ChatRoom, [key: string]: any } };
  categoryList?: ProductCategoryModel[],
  groupList?: ProductGroupModel[],

}

/** Component group manager */
export class AdminProductProductListComponent extends BaseListComponent<ComponentStateExtend, ProductModel> {
  namespace: string = 'admin-product-list';
  itemHeight = 208;

  apiPath = '/admin-product/products';

  limit = 20;
  title: 'Sản phẩm'
  idKey = 'Code';

  componentName = 'admin-product-product-list';
  route = '/admin-product/product-list';

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
  ) {
    super(rootServices, commonService);
    // // console.log('Click here to open ts file');
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  filterSchema = {
    Categories: {
      type: 'smart-select',
      dataType: 'multiple'
    },
    Groups: {
      type: 'smart-select',
      dataType: 'multiple'
    },
  };

  async onComponentInit(state: ComponentStateExtend, index: string, asCase?: string, f7Page?: F7Page) {
    // const cs = this.state[index];
    this.commonService.showPreloader();

    // Get static data
    state.instance.categoryList = await this.rootServices.apiService.getPromise<ProductCategoryModel[]>('/admin-product/categories', { onlyIdText: true, limit: 'nolimit' });
    state.instance.groupList = await this.rootServices.apiService.getPromise<ProductCategoryModel[]>('/admin-product/groups', { onlyIdText: true, limit: 'nolimit' });

    state.instance.$setState({
      categoryList: state.instance.categoryList,
      groupList: state.instance.groupList,
    });

    return super.onComponentInit(state, index, asCase, f7Page).then(currentState => {


      this.commonService.hidePreloader();
      return currentState;
    });
  }

  onItemClick(self: F7ListComponentContext<ProductModel>, item: ProductModel): void {
    return;
  }

  prepareItem(item: ProductModel, index?: number): ProductModel {
    if (item.Units && item.Units.length > 0) {
      let defaultSalesUnit = item.Units.find(f => f.IsDefaultSales);
      if (defaultSalesUnit) {
        item.Unit = defaultSalesUnit;
        const price = item.UnitList && item.UnitList.find(f => f.id === item.Unit.id);
        if (price) {
          item.Price = price.Price;
        }
      } else {
        defaultSalesUnit = item.Units[0];
        item.Unit = defaultSalesUnit;
      }
    } else {
      item.Unit = item.WarehouseUnit && item.WarehouseUnit.id ? item.WarehouseUnit : (item.WarehouseUnit ? { id: item.WarehouseUnit, text: item.WarehouseUnit } as any : { id: '', text: '--' });
    }
    return item;
  }

  async getList(self?: F7ComponentContextExtend, extParams?: any): Promise<ProductModel[]> {

    return this.rootServices.apiService.getPromise<any[]>(this.apiPath, {
      includeCategories: true,
      includeGroups: true,
      sort_Id: 'desc',
      includeWarehouseUnit: true,
      // includeUnitConversions: true,
      includeUnits: true,
      ...this.parseFilter(this.currentState?.filter),
      ...extParams
    }).then(list => {
      self.$setState({ productList: list.map((t, i) => this.prepareItem(t, i)), infiniteLoadLast: list.length === 0 });
      self.infiniteOffset = 10;
      return list;
    }).catch(err => {
      console.error(err);
      this.commonService.showError(err);
      return Promise.reject(err);
    });
  }

  itemBeforeInsert(element: HTMLElement, item: ProductModel): void {
    const $this = this;
    if (this.currentState.instance?.$route.params?.case == 'choose') {
      $(element).find('.chooseOne').unbind('click').click((e) => {
        // console.log(e);
        const chooseUnit = $(e.currentTarget).data('unit');
        this.currentState.instance.$route.context['onChoose'] && this.currentState.instance.$route.context['onChoose'](item, chooseUnit);
      });
    }
    $(element).find('.editBtn').unbind('click').click(() => {
      $this.rootServices.navigate('/admin-product/product/' + item.Code, {
        context: {
          backTitle: $this.title,
          textColorClass: this.currentState.instance.textColorClass,
          callback: (data: ProductModel, state: any) => {
            const self = this.currentState.instance;
            console.log('Callback data: ', data);
            // this.refresh();
            if (state?.lastAction == 'UPDATE_SUCCESS') {
              if (data?.Code) {
                const index = self.virtualList.items.findIndex(f => f.Code == item.Code);
                if (index > -1) {
                  self.virtualList.replaceItem(item.__index, { ...item, ...this.prepareItem(data) });
                }
              }
            }
          }
        }
      });

    });

    $(element).find('.openProduct').unbind('click').click(() => {
      this.rootServices.navigate('/admin-product/product/' + item.Code, {
        context: {
          backTitle: this.title,
          textColorClass: this.currentState.instance.textColorClass,
          callback: (data: ProductModel) => {
            console.log(data);
            this.updateItem(data);
          }
        }
      });
    });
    $(element).find('.copyProduct').unbind('click').click(() => {
      this.rootServices.navigate('/admin-product/product/new-' + item.Code, {
        context: {
          backTitle: this.title,
          copyFromId: item.Code,
          textColorClass: this.currentState.instance.textColorClass,
          callback: (data: ProductModel) => {
            console.log(data);
            this.refresh(this.currentState.instance);
          }
        }
      });
    });

    $(element).find('.previewPictures').unbind('click').click(() => {
      this.previewPictures([
        ...(item.FeaturePicture ? [item.FeaturePicture] : []),
        ...(item.pictures || []).filter(f => f.Id != item.FeaturePicture?.Id)
      ], 0);
    });
    $(element).find('.unitBtn').unbind('click').click(function () {
      const unitCode = $(this).attr('data-unit');

      $this.commonService.showPreloader();
      $this.rootServices.apiService.getPromise<WarehouseGoodsInContainerModel[]>('/warehouse/goods-in-containers', {
        includeCategories: true,
        includeFeaturePicture: true,
        includeUnit: true,
        includeContainer: true,
        offset: 0,
        limit: 100,
        eq_Goods: item.Code,
        eq_Unit: unitCode,
        ...(unitCode && { eq_Unit: unitCode } || {})
      }).then(rs => {
        $this.commonService.hidePreloader();
        if (rs.length > 1) {
          const buttons = rs.map((goodsInContainer, index) => ({
            text: goodsInContainer.ContainerShelfName + '/' + goodsInContainer.ContainerFindOrder,
            color: 'blue',
            onClick: (dialog, e) => {
              const id = [];
              id['id' + index] = `${goodsInContainer.Goods}-${goodsInContainer.Unit}-${goodsInContainer.Container}`;
              $this.commonService.showPreloader();
              $this.rootServices.apiService.getPromise<any[]>('/warehouse/find-order-tems', {
                ...id,
                includeWarehouse: true,
                renderBarCode: true,
                masterPriceTable: 'default',
                includeGroups: true,
                includeUnit: true,
                includeFeaturePicture: true,
                group_Unit: true,
                includeContainers: true,
                limit: 'nolimit',
              }).then(rs2 => {
                console.log(rs2);
                $this.commonService.hidePreloader();
                $this.currentState.instance.$app.photoBrowser.create({
                  photos: [
                    {
                      caption: $this.commonService.getObjectText(item.Sku + ' - ' + item.Code + ' - ' + item.Name + ' (' + goodsInContainer.UnitLabel + ') ' + ' - ' + goodsInContainer.ContainerShelfName + '/' + goodsInContainer.ContainerFindOrder),
                      html: `<div style="background: #fff"><img src="${rs2[0].BarCode}"</div>`
                    }
                  ],
                  theme: 'light',
                  type: 'standalone' as any,
                }).open();
              }).catch(err => {
                $this.commonService.hidePreloader();
              });
            }
          }));
          $this.currentState.instance.$app.dialog.create({
            title: item.Name,
            text: 'Chọn vị trí để hiển thị barcode',
            verticalButtons: true,
            buttons: [
              ...buttons,
              {
                text: 'Trở về',
                bold: true,
                color: 'red'
              },
            ],
          }).open();
        } else if (rs[0]) {
          $this.commonService.showPreloader();
          $this.rootServices.apiService.getPromise<any[]>('/warehouse/find-order-tems', {
            id0: `${rs[0].Goods}-${rs[0].Unit}-${rs[0].Container}`,
            includeWarehouse: true,
            renderBarCode: true,
            masterPriceTable: 'default',
            includeGroups: true,
            includeUnit: true,
            includeFeaturePicture: true,
            group_Unit: true,
            includeContainers: true,
            limit: 'nolimit',
          }).then(rs2 => {
            console.log(rs2);
            if (rs2 && rs2.length > 0) {
              $this.commonService.hidePreloader();
              $this.currentState.instance.$app.photoBrowser.create({
                photos: rs2.map((m, index) => {

                  return {
                    caption: $this.commonService.getObjectText(item.Sku + ' - ' + item.Code + ' - ' + item.Name + ' (' + rs[0].UnitLabel + ') ' + ' - ' + rs[index].ContainerShelfName + '/' + rs[index].ContainerFindOrder),
                    html: `<div style="background: #fff"><img src="${m.BarCode}"</div>`
                  };

                }),
                theme: 'light',
                type: 'standalone' as any,
              }).open();
            } else {
              $this.commonService.showError('Không thể tạo barcode cho hàng hóa này');
            }
          }).catch(err => {
            $this.commonService.hidePreloader();
          });
        } else {
          $this.commonService.showError('Hàng hóa chưa cài đặt vị trí');
        }
      });

      // const unit = item.UnitConversions.find(f => f.id == unitCode);
      // $this.currentState.instance.$app.dialog.create({
      //   title: 'Sản phẩm',
      //   text: item.Name + ' (' + unitCode + ')"',
      //   buttons: [
      //     {
      //       text: 'Chỉnh giá',
      //       color: 'orange',
      //       onClick: (dialog, e) => {

      //       }
      //     },
      //     {
      //       text: 'Hiển thị barcode',
      //       color: 'blue',
      //       onClick: (dialog, e) => {

      //         $this.commonService.showPreloader(5000);
      //         $this.rootServices.apiService.getPromise<any[]>('/warehouse/goods', {
      //           includeCategories: true,
      //           includeFeaturePicture: true,
      //           includeUnit: true,
      //           includeContainer: true,
      //           offset: 0,
      //           limit: 100,
      //           eq_Goods: item.Code,
      //           eq_Unit: unitCode,
      //           ...(unitCode && { eq_Unit: unitCode } || {})
      //         }).then(rs => {
      //           const id = {};
      //           for (const index in rs) {
      //             if (rs[index].Container) {
      //               id['id' + index] = `${$this.rootServices.apiService.encodeId(item.Goods)}-${$this.rootServices.apiService.encodeId($this.commonService.getObjectId(rs[index].Unit))}-${$this.rootServices.apiService.encodeId($this.commonService.getObjectId(rs[index].Container))}`;
      //             }
      //           }
      //           if (Object.keys(id).length == 0) {
      //             $this.commonService.showError('Đơn vị tính chưa được cài đặt vị trí !');
      //             $this.commonService.hidePreloader();
      //             return;
      //           }
      //           $this.rootServices.apiService.getPromise<any[]>('/warehouse/find-order-tems', {
      //             ...id,
      //             includeWarehouse: true,
      //             renderBarCode: true,
      //             masterPriceTable: 'default',
      //             includeGroups: true,
      //             includeUnit: true,
      //             includeFeaturePicture: true,
      //             group_Unit: true,
      //             includeContainers: true,
      //             limit: 'nolimit',
      //           }).then(rs2 => {
      //             console.log(rs2);
      //             $this.commonService.hidePreloader();
      //             $this.currentState.instance.$app.photoBrowser.create({
      //               photos: rs2.map((m, index) => {

      //                 return {
      //                   caption: $this.commonService.getObjectText(rs[index].Container),
      //                   html: `<div style="background: #fff"><img src="${m.BarCode}"</div>`
      //                 };

      //               }),
      //               theme: 'light',
      //               type: 'standalone' as any,
      //             }).open();
      //           }).catch(err => {
      //             $this.commonService.hidePreloader();
      //           });
      //         }).catch(err => {
      //           $this.commonService.hidePreloader();
      //         });
      //       }
      //     },
      //     {
      //       text: 'Trở về',
      //       bold: true,
      //       color: 'red'
      //     },
      //   ],
      //   verticalButtons: true,
      // }).open();



    });
    return;
  }

  async onFilterFieldChange(self: F7ComponentContextExtend, e: any) {
    return super.onFilterFieldChange(self, e);
  }

  itemTemplate = /*html*/`
  <div class="block block-strong inset list-item index-{{__index}}" data-id="{{Code}}" data-index="{{__index}}" style="height: 197px; overflow: hidden; margin-top: 0; margin-bottom: 10px">
    <div class="item-content">
      <div class="detail-wrap">
        <div class="detail-header">
          <div class="header-label">{{Name}}</div>
          <div class="detail-choose chooseOne link"><i class="icon f7-icons color-orange" style="font-size: 2rem;">cart_fill_badge_plus</i></div>
        </div>
        <div class="detail-body" style="padding-bottom: 0.5rem;">
          <div class="image-wrap">
            <div class="image previewPictures" data-index="0" style="height: 112px; {{js "this.FeaturePicture && this.FeaturePicture.Thumbnail && ('background-image: url('+this.FeaturePicture.Thumbnail+')') || ''"}}"></div>
          </div>
          <div class="detail-content-wrap">
            <div class="list inline-labels no-hairlines">
              <ul>
                <li class="item-content item-input item-link showBarcode" data-unit="{{id}}"  style="padding-left: 0; min-height: 2rem;">
                  <div class="item-inner">
                    <div class="item-title item-label">ĐVT</div>
                    <div class="unit" style="text-align: right">Tỷ lệ</div>
                  </div>
                </li>
                {{#each Units}}
                <li class="item-content item-input item-link showBarcode chooseOne" data-unit="{{id}}"  style="padding-left: 0; min-height: 2rem;">
                  <div class="item-inner">
                    <div class="item-title item-label">{{text}}</div>
                    <div class="unit text-color-blue" style="text-align: right">
                      {{ConversionRatio}} {{text ../WarehouseUnit}}
                    </div>
                  </div>
                </li>
                {{/each}}
                <li class="item-content item-input" style="padding-left: 0; min-height: 2rem;">
                  <div class="item-inner" style="padding-top: var(--f7-list-item-padding-vertical);
                  padding-bottom: var(--f7-list-item-padding-vertical);">
                    <div>
                      <i class="openProduct f7-icons text-color-gray link" style="font-size: 21px; float: left; line-height: 26px; margin-right: 0.5rem;">square_pencil</i>
                      <i class="copyProduct f7-icons text-color-gray link" style="font-size: 20px; float: left; line-height: 26px; margin-right: 0.5rem; margin-top: 2px;">square_on_square</i>
                    </div>
                    <!--<i class="chooseOne f7-icons text-color-orange link" style="font-size: 32px; float: left; line-height: 26px; margin-left: 1rem">cart_fill_badge_plus</i>-->
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="detail-footer">SKU: {{Sku}}, ID: {{Code}}{{#js_if "this.Categories && this.Categories.length > 0"}}, DM: {{text Categories}}{{/js_if}}</div>
      </div>
    </div>
  </div>
  `;

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: $this.componentName,
      path: $this.route,
      component: {
        template: /*html*/`
            <div class="page page-${$this.namespace} no-toolbar-x" data-name="page-${$this.namespace}">
              <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a class="link back {{textColorClass}}">
                      <i class="icon icon-back"></i>
                      <span class="if-not-md {{textColorClass}}">{{backTitle}}</span>
                    </a>
                  </div>
                  <div class="title">Hàng hóa/Dịch vụ</div>
                  <div class="right">
                    <!-- Link to enable searchbar -->
                    <a class="link icon-only searchbar-enable {{textColorClass}}" data-searchbar=".${$this.namespace}-searchbar">
                      <i class="icon f7-icons if-not-md">search</i>
                      <i class="icon material-icons md-only">search</i>
                    </a>
                  </div>
                  <!-- Searchbar is a direct child of "navbar-inner" -->
                  <form class="searchbar ${$this.namespace}-searchbar searchbar-expandable">
                    <div class="searchbar-inner">
                      <div class="searchbar-input-wrap">
                        <input type="search" placeholder="Search" />
                        <i class="searchbar-icon"></i><span class="input-clear-button"></span>
                      </div>
                      <span class="searchbar-disable-button {{textColorClass}}">Hủy</span>
                    </div>
                  </form>
                </div>
              </div>
              <div class="page-content ptr-content infinite-scroll-content" @ptr:refresh="refresh">
                <div class="ptr-preloader">
                  <div class="preloader"></div>
                  <div class="ptr-arrow"></div>
                </div>
                <div class="searchbar-backdrop ${$this.namespace}-backdrop"></div>

                <div class="block-title" style="margin-top: 2rem">Bộ lọc <a style="float: right" @click="resetFilter"
                class="text-color-red">đặt lại</a></div>
                <div class="block block-strong inset filter" style="padding: 0; overflow: hidden">
                  <div class="list accordion-list inline-labels no-hairlines" style="margin: 0;">
                    <ul>
                      <li>
                        <a class="item-link smart-select smart-select-init" name="Categories" data-open-in="popup" data-searchbar="true" data-virtual-list="true" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFilterFieldChange">
                            <select multiple>
                              <option value="">Chọn</option>
                              {{#each categoryList}}
                              <option value="{{id}}">{{text}}</option>
                              {{/each}}
                            </select>
                            <div class="item-content">
                              <div class="item-inner">
                                <div class="item-title">Danh mục</div>
                              </div>
                            </div>
                          </a>
                      </li>
                      <li>
                        <a class="item-link smart-select smart-select-init" name="Groups" data-open-in="popup" data-searchbar="true" data-virtual-list="true" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFilterFieldChange">
                            <select multiple>
                              <option value="">Chọn</option>
                              {{#each groupList}}
                              <option value="{{id}}">{{text}}</option>
                              {{/each}}
                            </select>
                            <div class="item-content">
                              <div class="item-inner">
                                <div class="item-title">Nhóm</div>
                              </div>
                            </div>
                          </a>
                      </li>
                    </ul>
                  </div>
                </div>
            
                <div class="block-title">Hàng hóa/dịch vụ</div>
                <div class="virtual-list ${$this.namespace}-virtual-list thumbnail-list"></div>


                {{#if infiniteLoadLast}}
                <div style="text-align: center" class="text-color-gray">end</div>
                {{else}}
                <div class="preloader color-blue infinite-scroll-preloader">
                    <span class="preloader-inner">
                        <span class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                            class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                            class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                            class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                            class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                            class="preloader-inner-line"></span><span class="preloader-inner-line"></span>
                    </span>
                </div>
                {{/if}}
            
                <div class="block"><br></div>
            
              </div>


              <!-- Dialog area -->
              <div class="dialog dialog-wrap ${$this.namespace}-price-dialog">
                <div class="dialog-padding">
                  <div class="dialog dialog-inside">
                    <div class="dialog-inner" style="padding-bottom: 0;">
                        <div class="dialog-title">{{priceDialogData.title}}</div>
                        <div class="dialog-text" style="width: 100%; user-select: initial; text-align: center; margin-bottom: 0.5rem">{{priceDialogData.content}}</div>

                        <div class="list inline-labels" style="margin-left: -15px; margin-right: -15px; margin-bottom: 0; margin-top: 0; text-align: left;">
                            <ul>
                                {{#js_if "this.priceDialogData && this.priceDialogData.product && this.priceDialogData.product.UnitList"}}
                                {{#each priceDialogData.product.UnitList}}
                                <li class="item-content item-input">
                                    <div class="item-inner">
                                        <div class="item-title item-label" style="overflow: initial;">{{text}}</div>
                                        <div class="item-input-wrap">
                                          <input class="field price text-color-red" name="{{id}}" type="text" placeholder="Giá bán" value="{{Price}}" style="text-align: right">
                                        </div>
                                    </div>
                                </li>
                                {{/each}}
                                {{/js_if}}
                            </ul>
                        </div>
                    </div>
                    <div class="dialog-buttons">
                        <span class="dialog-button color-red closeBtn">Đóng</span>
                        <span class="dialog-button updateBtn">Xác nhận</span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End Dialog area -->
            </div>
        `,
        style: /*css*/`
          .page-${$this.namespace} .thumbnail-list ul {
            padding: 0;
            margin: 0;
          }
        `,
        data() {
          return {
            title: $this.title,
            reminder: {
              No: '234234',
            },
            priceReport: { Details: [] },
            infiniteLoadLast: false,
            priceDialogData: {}
          };
        },
        methods: {
          resetFilter(e) {
            $this.resetFilter(this, e);
          },
          onFilterFieldChange(e) {
            $this.onFilterFieldChange(this, e);
          },
          openProduct(e, id?: string) {
            const self: F7ComponentContextExtend = this;
            // id = id || self.$(e.target).closest('li').data('id') || 'new';

            // $this.rootServices.navigate('/admin-product/product/' + id, {
            //   context: {
            //     backTitle: $this.title,
            //     textColorClass: self.textColorClass,
            //     callback: (data: { case: string, product: ProductModel }) => {
            //       console.log(data);
            //       self.refresh();
            //     }
            //   }
            // });
            $this.rootServices.navigate('/admin-product/product/new', {
              context: {
                backTitle: $this.title,
                textColorClass: self.textColorClass,
                callback: (data: ProductModel, state: any) => {
                  console.log('Callback data: ', data);
                  if (state?.lastAction == 'CREATE_SUCCESS') {
                    $this.refresh();
                  }
                }
              }
            });
          },
          // createNewProduct(e) {
          //   const self: F7ComponentContextExtend = this;

          //   $this.rootServices.navigate('/admin-product/product/new', {
          //     context: {
          //       backTitle: $this.title,
          //       textColorClass: self.textColorClass,
          //       calback: (data: { case: string, product: ProductModel }) => {
          //         console.log(data);
          //       }
          //     }
          //   });
          // },
          chooseOne(e) {
            const self: F7ComponentContextExtend = this;
            const id = self.$(e.target).closest('li').data('id');
            self.$route.context['onChoose'] && self.$route.context['onChoose'](self.productList.find(f => f.Code === id));
          },
          chooseProduct(e) {

          },
          async refresh(e, done) {

            $this.refresh(this).then(rs => {
              done && done();
            });

            return true;
          },
          async addGoodsPicture(e) {
            console.debug(e);
            const self: F7ComponentContextExtend = this;
            const id = self.$(e.target).closest('.product-item').data('id');
            const oldProduct = self.productList.find(f => f.Code == id);
            if (!oldProduct) {
              console.error('Not found');
            }

            try {

              self.$f7.dialog.create({
                title: 'Hình ảnh sản phẩm',
                text: 'Bạn muốn xem hình hay thêm hình cho sản phẩm ?',
                buttons: [
                  {
                    text: 'Xem hình',
                    onClick: () => {


                      $this.browseAttachment(oldProduct?.Pictures.map(m => ({
                        Id: m.Id,
                        Type: m.Type,
                        DataType: m.MimeType,
                        Ext: m.Extension,
                        Name: oldProduct.Name,
                        Description: oldProduct.Name,
                        DateOfAttach: oldProduct.Created,
                        // File: string;
                        Thumbnail: m.Thumbnail,
                        Url: m.OriginImage,
                        SmallUrl: m.SmallImage,
                        LargeUrl: m.LargeImage,
                        OriginUrl: m.OriginImage,
                      })) as any, oldProduct.FeaturePicture?.Id, (attachment => {
                        console.debug('download index', attachment);
                        if (/image/.test(attachment.Type)) {
                          // $this.currentState.instance.$app.dialog.confirm([attachment.name, attachment.description].filter(f => !!f).join('<br>') || 'Đính kèm', 'Xác nhận tải về', async () => {
                          //   $this.rootServices.saveImageToPhone(attachment.payload.url as string);
                          // });
                          $this.currentState.instance.$app.dialog.create({
                            title: 'Xác nhận tải về',
                            content: 'Đính kèm',
                            buttons: [
                              {
                                text: 'Đóng',
                                color: 'red',
                                close: true,
                              },
                              {
                                text: 'Mở link',
                                onClick: () => {
                                  $this.rootServices.saveImageToPhone(attachment.Url as string);
                                }
                              }
                            ],
                          }).open();
                        } else if (/video/.test(attachment.Type)) {
                          $this.playVideo((attachment.OriginUrl || attachment.Url) as string);
                        } else {
                          $this.rootServices.iab.create((attachment.OriginUrl || attachment.Url) as string, '_system');
                        }
                      })).then(() => {
                        console.log('Photo browser was closed');
                      });
                    }
                  },
                  {
                    text: 'Thêm hình',
                    onClick: async () => {
                      const localFiles = await $this.rootServices.pickFiles();
                      console.debug(localFiles);

                      let image: FileModel = null;
                      if (localFiles[0].dataType === 'url') {
                        image = await $this.rootServices.uploadLocalFile(localFiles[0].url as string);
                      } else {
                        const formData = new FormData();
                        const imgBlob = new Blob([localFiles[0].url], {
                          type: localFiles[0].type
                        });
                        formData.append('file', imgBlob, 'smart-bot-' + Date.now() + '.' + localFiles[0].ext);
                        image = await $this.rootServices.uploadFileData(formData);
                      }
                      console.debug(image);
                      e.target.style.backgroundImage = 'url(' + image.Thumbnail + ')';

                      // const updateProfileResult = await $this.rootServices.apiService.putPromise('/user/users/updateAccount', {}, { Avatar: image.GlobalId, AvatarThumbnail: image.Thumbnail })
                      const pictures = oldProduct.Pictures || [];
                      pictures.push(image);
                      const updateProfileResult = await $this.rootServices.apiService.putPromise('/admin-product/products/' + id, {}, [{ Code: id, FeaturePicture: image, Pictures: pictures }]);
                      console.debug('Update product picture sucess: ', updateProfileResult);
                      $this.commonService.showInfo('Đã thêm hình ảnh cho hàng hóa: ' + oldProduct.Name);

                      // Update auth user
                      const oldUser = $this.rootServices.authService.user$.getValue();
                      oldUser.avatar = oldUser.avatar || { id: '', payload: {} };
                      oldUser.avatar = { ...oldUser.avatar, payload: { ...oldUser.avatar.payload, thumbnail: image.Thumbnail, url: image.DownloadLink } }
                      $this.rootServices.authService.user$.next(oldUser);

                    }
                  },
                  {
                    text: 'Trở về',
                  },
                ],
                verticalButtons: true,
              }).open();


            } catch (err) {
              console.error(err);
              $this.commonService.showError(err);
            }

          },
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          pageInit(e, page: F7Page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;
            const index = self.$route?.context?.index;

            self.calendarDateTime = self.$f7.calendar.create({
              // openIn: 'customModal',
              inputEl: page.$el.find('input[name="RemindAtDateTime"]'),
              timePicker: true,
              dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' },
              // header: true,
              footer: true,
            });

            $this.onComponentInit({ instance: self }, index || 'main', null, page);

            $this.rootServices.authService.isAuthenticatedOrRefresh().pipe(take(1)).toPromise().then(() => {
              self.$setState({
                backTitle: self.$route.context && self.$route.context.backTitle || 'Back', title: self.$route.context && self.$route.context.title || 'Profile',
              });

            }).catch(err => {
              console.error(err);
              $this.commonService.showError(err);
            });

          },
          // pageBeforeIn(e, page) {
          //     console.log('page before in');
          //     const self: F7ComponentContextExtend = this;
          //     self.refresh();
          // },
          // pageAfterIn(e, page) {
          //     console.log('page after in');
          // },
          pageBeforeOut(e, page) {
            const self: F7ComponentContextExtend = this;
            console.log('page before out');
            $this.onBeforeChangedState({ instance: self, page, scrollPosition: $(page.el).find('.page-content').scrollTop() }, self.componentIndex);
          },
          // pageAfterOut(e, page) {
          //     console.log('page after out');
          // },
          // pageBeforeUnmount(e, page) {
          //     console.log('page before unmount');
          // },
          // pageBeforeRemove(e, page) {
          //     console.log('page before remove');
          // },
          pageBeforeRemove(e, page) {
            console.log('[page event] before remove', page.route.url);
            const self: F7ComponentContextExtend = this;
            // const starmtSelectRoles = self.$app.smartSelect.get('.smart-select');
            // starmtSelectRoles.destroy();
          },
        },
      },
    };
  }
}