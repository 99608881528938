import { F7ComponentContext, F7Page } from '../types/framework7-types';
import { Router } from 'framework7/build/core/modules/router/router';
import { ComponentState, BaseComponent } from '../lib/base-component';
import { ProductModel } from '..//model/product.model';
import { CommonService } from '../services/common.service';
import { RootServices } from '../services/root.services';
import * as moment from 'moment';

declare const $: any;

export interface ComponentStateExtend extends ComponentState {
  [key: string]: any;
}

export class F7ComponentContextExtend extends F7ComponentContext {
  responseInProgress?: boolean;
  productList?: ProductModel[];
  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string } };
}

/** Component group manager */
export class AboutComponent extends BaseComponent<ComponentStateExtend> {
  // states: { [key: string]: State } = {};

  title: 'Giới Thiệu'

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
  ) {
    super(rootServices);
    // console.log('Click here to open ts file');
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  preapreProduct(p: ProductModel) {
    // const unread = t.NumOfMessage - t.LoggedNumOfMessage - t.LoggedNumOfReadMessage;
    // t.LastUpdate_Moment = moment(t.LastUpdate).fromNow();
    // t.DateOfCreate = this.datePipe.transform(t.DateOfCreate, 'short');
    // t.StateLabel = { 'OPEN': 'Mở', 'NEWSESSION': 'Mới', 'ACCEPT': 'Tiếp nhận', 'CLOSE': 'Đóng', 'COMPLETE': 'Hoàn tất', 'CANCEL': 'Hủy' }[t.State];
    // if (t.State in ['OPEN', 'NEWSESSION']) {
    //     t.ActionMemberName = t.OpenByMemberName;
    // }
    // if (t.State === 'ACCEPT') {
    //     t.ActionMemberName = t.AcceptByMemberName;
    // }
    // if (t.State === 'CLOSE') {
    //     t.ActionMemberName = t.CloseByMemberName;
    // }
    // if (t.State === 'COMPLETE') {
    //     t.ActionMemberName = t.CompleteByMemberName;
    // }
    // if (t.State === 'CANCEL') {
    //     t.ActionMemberName = t.CancelByMemberName;
    // }
    // t.NumOfUnreadMessage = unread > 0 ? unread : '';
    // if (p.UnitConversions && p.UnitConversions.length > 0) {
    // let defaultSalesUnit = p.UnitConversions.find(f => f.IsDefaultSales);
    // if (defaultSalesUnit) {
    //   p.Unit = defaultSalesUnit;
    //   const price = p.UnitList && p.UnitList.find(f => f.id === p.Unit.id);
    //   if (price) {
    //     p.Price = price.Price;
    //     p.PriceText = this.rootServices.currencyPipe.transform(p.Price, price.Currency || 'VND');
    //   }
    // } else {
    // defaultSalesUnit = p.UnitConversions[0];
    // p.Unit = defaultSalesUnit;
    // }
    // } else {
    //   p.Unit = p.WarehouseUnit && p.WarehouseUnit.id ? p.WarehouseUnit : (p.WarehouseUnit ? { id: p.WarehouseUnit, text: p.WarehouseUnit } as any : { id: '', text: '--' });
    // }
    if (p.Categories && p.Categories.length > 0) {
      p.CategoriesText = p.Categories.map(m => m.text).join(', ');
    }
    if (p.FeaturePicture && p.FeaturePicture.Thumbnail) {
      p.FeaturePictureThumbnail = p.FeaturePicture.Thumbnail;
    }
    const taxValue = p.Tax && p.Tax.Tax || 0;
    p.ToMoney = parseInt(p.Price as any) + parseInt(p.Price as any) * parseInt(taxValue as any) / 100;
    p.ToMoneyText = this.rootServices.currencyPipe.transform(p.ToMoney, 'VND');;
    p.PriceText = this.rootServices.currencyPipe.transform(p.Price, 'VND');
    if (p.OriginPrice) {
      p.OriginPriceText = this.rootServices.currencyPipe.transform(p.OriginPrice, 'VND');
    }
    return p;
  }

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: 'about',
      path: '/about',
      component: {
        template: /*html*/`
            <div class="page about" data-name="about">
              <div class="navbar navbar-page">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  
                  <div class="left">
                    <a class="link back {{textColorClass}}">
                      <i class="icon icon-back"></i>
                      <span class="if-not-md {{textColorClass}}">{{backTitle}}</span>
                    </a>
                  </div>
                  <div class="title">Giới Thiệu</div>
                  <div class="right">
                    <!-- Link to enable searchbar -->
                    <a class="link icon-only searchbar-enable {{textColorClass}}" data-searchbar=".product-list-searchbar">
                      <i class="icon f7-icons if-not-md">search</i>
                      <i class="icon material-icons md-only">search</i>
                    </a>
                  </div>
                  <!-- Searchbar is a direct child of "navbar-inner" -->
                  <form class="searchbar product-list-searchbar searchbar-expandable">
                    <div class="searchbar-inner">
                      <div class="searchbar-input-wrap">
                        <input type="search" placeholder="Search" />
                        <i class="searchbar-icon"></i>
                        <span class="input-clear-button"></span>
                      </div>
                      <span class="searchbar-disable-button {{textColorClass}}">Hủy</span>
                    </div>
                  </form>
                </div>
              </div>
              <div class="page-content" @ptr:refresh="refresh">

                <div class="block inset" style="padding: 0.5rem">
                  <div class="profile-info" style="display: flex">
                    <div class="profile-logo-x bg-color-white" style="flex: 1; border-radius: 1rem; padding: 1rem; overflow: hidden">
                      <img src="assets/images/logo_probox_one.png" style="margin-bottom: -6px;">
                    </div>
                    
                  </div>
                </div>
              
                
                <div class="block block-strong inset">

                Cộng Tác Viên Bán Hàng (CTV Bán Hàng) được doanh nghiệp, tổ chức, hộ kinh doanh hiểu như là một sự hổ trợ thuê ngoài trong khâu bán hàng đến tay người tiêu dùng. Xã hội 4.0 phát triển kèm theo đó xu hướng làm việc cộng tác cũng phát triển và hiện nay công việc CTV Bán Hàng gần như được xem là công việc tự do tốt nhất. Điều đó đang là hiện thực trong đời sống hàng ngày, khi CTV Bán Hàng đã sử dụng tốt các công cụ mạng xã miễn phí để tạo ra các kết nối với người tiêu dùng.
                Với doanh nghiệp, để có thể quản trị cộng đồng CTV Bán Hàng của riêng mình sẽ cần đến 1 giải pháp quản trị chuyên sâu với mục tiêu tạo ra sự khác biệt khi triển khai với kênh CTV Bán Hàng.
                <br>
                Module CTV Bán Hàng trên phần mềm ProBox One đã có kinh nghiệm sâu trong lĩnh vực này và hiểu sâu tâm lý tiêu dùng thông qua CTV Bán Hàng. nay đã hoàn thiện Module CTV Bán Hàng, giúp doanh nghiệp triển khai cộng đồng CTV Bán Hàng với tiêu chí CTV Bán Hàng có thể thấy thông tin tuyển dụng của doanh nghiệp và đăng ký làm việc nhanh.
                <br>
                Công ty ProBox hổ trợ đầy đủ quy trình pháp lý lao động cộng tác viên, quy trình kiểm soát chặc chẻ. Giúp doanh nghiệp an tâm vận hành kênh CTV Bán Hàng với số lượng lớn người tham gia.


                <br>

                <div style="text-align: center">{{appTitle}}</div>
                <div style="text-align: center">{{appCopyright}}</div>
                <br>              
            
              </div>
            </div>
        `,
        style: /*css*/`
          .navbar-page .titlex {
            white-space: normal;
            line-height: 1.4em;
            display: flex;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-size: 12px;
          }
        `,
        data() {
          return {
            title: $this.title,
            backTitle: 'Back',
            pageInfo: {},
            priceReport: { Details: [] },
            appName: $this.rootServices.env.name,
            appTitle: $this.rootServices.env.title,
            appCopyright: $this.rootServices.env.copyright,
            coreVersion: $this.rootServices.env.coreVersion,
            platform: $this.rootServices.device?.platform || 'browser',
          };
        },
        methods: {
          openExternalLink(e) {
            const self: F7ComponentContextExtend = this;
            const link = $(e.currentTarget).data('href');
            $this.rootServices.openExtenalLink(link);
          },
          subscribe(e) {
            const self: F7ComponentContextExtend = this;
            const id = self.$(e.target).closest('li').data('id');
            self.$route.context['onChoose'] && self.$route.context['onChoose'](self.productList.find(f => f.Code === id));
          },
          chooseOne(e) {
            const self: F7ComponentContextExtend = this;
            const id = self.$(e.target).closest('li').data('id');
            self.$route.context['onChoose'] && self.$route.context['onChoose'](self.productList.find(f => f.Code === id));
          },
          chooseProduct(e) {

          },
          async refresh(e, done) {
            const self: F7ComponentContextExtend = this;
            const pageId = self.$route.params?.id;
            let doneTimeout = null;
            if (done) {
              doneTimeout = setTimeout(() => {
                done();
              }, 10000);
            };

            try {
              const pageInfo = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/pages/' + pageId, {}).then(rs => {
                return {
                  ...rs[0],
                  Logo: 'assets/icon/favicon.png',
                  Banner: 'assets/images/logo_probox_one.png',
                  Summary: 'Summary...',
                };
              });

              // console.log(self.searchBar);

              const products = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/products', {
                includeCategories: true,
                includeGroups: true,
                filterByLevelAndBadge: true,
                sort_Id: true,
                search: self?.searchBar?.query && encodeURIComponent(self.searchBar.query) || '',
                includeFeaturePicture: true,
                includeUnit: true,
                includePage: true,
                offset: 0,
                limit: 40,
                ...self.filter,
              }).then(products => {
                // self.$$(page.el).find('.page-content').scrollTo(0, 0);
                return products.map(t => $this.preapreProduct(t));
              });

              self.$setState({
                productList: products,
                infiniteLoadLast: products.length === 0,
                pageInfo: pageInfo,
                lastUpdateMoment: moment(Date.now()).fromNow(),
              });
              self.infiniteOffset = 0;

            } catch (err) {
              console.error(err);
            }

            if (done) done();
            return true;
          },
          saveTaskReminder() {
            const self: F7ComponentContextExtend = this;
            const no = self.$route.params['id'];
          },
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          pageInit(e, page: F7Page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;
            // Loading flag
            let allowInfinite = true;

            self.refresh();
            self.searchBar = self.$app.searchbar.create({
              el: '.product-list-searchbar',
              backdrop: true,
              backdropEl: '.product-list-backdrop',
              searchContainer: '.list',
              searchIn: '.item-text',
              customSearch: true,
              on: {
                search(sb, query, previousQuery) {
                  $this.commonService.takeUntil('product-search', 600).then(() => {
                    console.log(query, previousQuery);
                    $this.rootServices.apiService.getPromise<any[]>('/sales/master-price-tables/products', {
                      search: encodeURIComponent(query),
                      priceTable: $this.commonService.getObjectId(self.$route.context['priceTable']) || null,
                      includeFeaturePicture: true,
                      limit: 10,
                      offset: 0,
                      includeCategories: true,
                      ...self.filter,
                    }).then(products => {
                      self.$$(page.el).find('.page-content').scrollTo(0, 0);
                      self.$setState({ productList: products.map(t => $this.preapreProduct(t)), infiniteLoadLast: products.length === 0 });
                      self.infiniteOffset = 0;
                    }).catch(err => {
                      console.error(err);
                      $this.commonService.showError(err);
                    });
                  });
                }
              }
            });

            self.$$(page.el).find('.infinite-scroll-content').on('infinite', function () {
              // Exit, if loading in progress
              if (!allowInfinite) return;

              // Set loading flag
              allowInfinite = false;
              // Last loaded index
              var lastItemIndex = self.$$('.list li').length;

              // Emulate 1s loading
              $this.rootServices.apiService.getPromise<any[]>('/sales/master-price-tables/products', {
                search: self.searchBar.query,
                sort_LastUpdate: 'desc',
                limit: 10,
                offset: self.infiniteOffset,
                // sort_LastUpdate: 'desc',
                priceTable: $this.commonService.getObjectId(self.$route.context['priceTable']) || null,
                includeFeaturePicture: true,
                includeCategories: true,
                ...self.filter,
              }).then(products => {
                products.map(t => {
                  $this.preapreProduct(t);
                  return t;
                });
                self.$setState({ productList: self.productList.concat(products), infiniteLoadLast: products.length === 0 });
                self.infiniteOffset += 10;
                lastItemIndex = self.$$('.list li').length;
                allowInfinite = true;

                // self.updateBadge();
              }).catch(err => {
                console.error(err);
                $this.commonService.showError(err);
              });
            });

            // self.productList = [
            //   {
            //     No: 1,
            //     CategoriesText: 'Thiết bị mạng',
            //     Thumbnail: '/assets/images/products/product-01.png',
            //     Code: 'SP15620277',
            //     Name: 'Công Tắc Cảm Ứng Wifi SmartZ PM màu trắng, không viền, 4 nút WS1 (mã con WS1.4W)',
            //     Price: 770000,
            //     PriceText: '770.000 đ',
            //     OriginPrice: 800000,
            //     OriginPriceText: '800.000 đ',
            //     Unit: { id: 'CAI', text: 'Cái' },
            //     UnitText: 'Cái',
            //     Tax: {
            //       id: 'VAT10',
            //       text: '+ 10% VAT',
            //       Code: 'VAT10',
            //       Name: 'Chưa bao gồm VAT 10%',
            //       Label2: '+ 10% VAT',
            //       Tax: 10,
            //     },
            //     TaxText: '10% vat',
            //     Promotion: 'GIAM30%',
            //     PromotionText: 'giảm 30%'
            //   },
            //   {
            //     No: 2,
            //     CategoriesText: 'Thiết bị mạng',
            //     Thumbnail: '/assets/images/products/product-02.jpeg',
            //     Code: 'SP166204228',
            //     Name: 'Công Tắc Cảm Ứng Wifi SmartZ PM màu trắng, viền bạc, 1 nút WS1 (mã con WS1F.1)',
            //     Price: 800000,
            //     PriceText: '800.000 đ',
            //     OriginPrice: 900000,
            //     OriginPriceText: '900.000 đ',
            //     Unit: { id: 'CAI', text: 'Cái' },
            //     UnitText: 'Cái',
            //     Tax: {
            //       id: 'VAT10',
            //       text: '+ 10% VAT',
            //       Code: 'VAT10',
            //       Name: 'Chưa bao gồm VAT 10%',
            //       Label2: '+ 10% VAT',
            //       Tax: 10,
            //     },
            //     TaxText: '10% vat',
            //     // Promotion: 'GIAM15%',
            //     // PromotionText: 'giảm 15%'
            //   },
            //   {
            //     No: 3,
            //     CategoriesText: 'Thiết bị mạng',
            //     Thumbnail: '/assets/images/products/product-03.jpg',
            //     Code: 'SP166204226',
            //     Name: 'Cảm Biến Cửa Wifi Cho Nhà Thông Minh SmartZ PM WD1',
            //     Price: 450000,
            //     PriceText: '450.000 đ',
            //     OriginPrice: 500000,
            //     OriginPriceText: '500.000 đ',
            //     Unit: { id: 'CAI', text: 'Cái' },
            //     UnitText: 'Cái',
            //     Tax: {
            //       id: 'VAT10',
            //       text: '+ 10% VAT',
            //       Code: 'VAT10',
            //       Name: 'Chưa bao gồm VAT 10%',
            //       Label2: '+ 10% VAT',
            //       Tax: 10,
            //     },
            //     TaxText: '10% vat',
            //     // Promotion: 'GIAM20%',
            //     // PromotionText: 'giảm 20%'
            //   },
            //   {
            //     No: 4,
            //     CategoriesText: 'Thiết bị mạng',
            //     Thumbnail: '/assets/images/products/product-04.jpeg',
            //     Code: 'SP166204197',
            //     Name: 'Bộ Báo Động Chống Trộm Qua WIFI + Sim GSM GW05 Cao Cấp',
            //     Price: 4500000,
            //     PriceText: '4.500.000 đ',
            //     OriginPrice: 5000000,
            //     OriginPriceText: '5.000.000 đ',
            //     Unit: { id: 'CAI', text: 'Cái' },
            //     UnitText: 'Cái',
            //     Tax: {
            //       id: 'VAT10',
            //       text: '+ 10% VAT',
            //       Code: 'VAT10',
            //       Name: 'Chưa bao gồm VAT 10%',
            //       Label2: '+ 10% VAT',
            //       Tax: 10,
            //     },
            //     TaxText: '10% vat',
            //     // Promotion: 'GIAM20%',
            //     // PromotionText: 'giảm 20%'
            //   },
            // ];
            // self.productList.map(detail => {
            //   const taxValue = detail.Tax && detail.Tax.Tax || 0;
            //   detail.ToMoney = detail.Price + detail.Price * taxValue / 100;
            //   detail.ToMoneyText = $this.rootServices.currencyPipe.transform(detail.ToMoney, 'VND');;
            //   detail.PriceText = $this.rootServices.currencyPipe.transform(detail.Price, 'VND');
            //   detail.OriginPriceText = $this.rootServices.currencyPipe.transform(detail.OriginPrice, 'VND');
            //   return detail;
            // })

            self.calendarDateTime = self.$f7.calendar.create({
              // openIn: 'customModal',
              inputEl: page.$el.find('input[name="RemindAtDateTime"]'),
              timePicker: true,
              dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' },
              // header: true,
              footer: true,
            });

            // Set back title
            // self.$setState({ backTitle: self.$route.context && self.$route.context.backTitle || 'Back', title: self.$route.context && self.$route.context.title || 'Tài khoản' });

            $this.onComponentInit({ instance: self }, 'main');


            setTimeout(() => {
              // const starmtSelect = self.$app.smartSelect.get($(page.el).find('.field[name="MentionToMembers"]').closest('.smart-select')[0] as any);
              // // const remindeAtDateTime = self.$app.calendar.get(self.$('.field[name="RemindAtDateTime"]')[0] as any);

              // if (reminder.MentionToMembers) {
              //   starmtSelect.setValue(reminder.MentionToMembers.map(member => $this.commonService.getObjectId(member)));
              // }
              // if (reminder.RemindAtDateTime) {
              //   self.calendarDateTime.setValue([new Date(reminder.RemindAtDateTime)]);
              // }

              // starmtSelect.on('closed', (smartSelect) => {
              //   const selectedRoles: string & string[] = (smartSelect.getValue() as string[]).sort() as any;
              //   console.log('on smart select closed', selectedRoles);
              //   // if ((reminder.roles || []).toString() !== (selectedRoles || []).toString()) {
              //   //   reminder.roles = selectedRoles;
              //   //   chatRoom.updateMember(reminder).then(rs => {
              //   //     console.log('chat room member was updated', rs);
              //   //     $this.commonService.showInfo(`Đã thay đổi vai trò của ${reminder.shortName || reminder.name}`);
              //   //   }).catch(err => {
              //   //     console.error(err);
              //   //     $this.commonService.showError(err);
              //   //   });
              //   // }
              // });
            }, 500);
          },
          // pageBeforeIn(e, page) {
          //     console.log('page before in');
          //     const self: F7ComponentContextExtend = this;
          //     self.refresh();
          // },
          // pageAfterIn(e, page) {
          //     console.log('page after in');
          // },
          // pageBeforeOut(e, page) {
          //     console.log('page before out');
          // },
          // pageAfterOut(e, page) {
          //     console.log('page after out');
          // },
          // pageBeforeUnmount(e, page) {
          //     console.log('page before unmount');
          // },
          // pageBeforeRemove(e, page) {
          //     console.log('page before remove');
          // },
          pageBeforeRemove(e, page) {
            console.log('[page event] before remove', page.route.url);
            const self: F7ComponentContextExtend = this;
            // const starmtSelectRoles = self.$app.smartSelect.get('.smart-select');
            // starmtSelectRoles.destroy();
          },
        },
      },
    };
  }
}
