import { ContactsComponent } from './../../vendors/smart-bot-app/src/app/components/contacts.component';
import { LoginComponent } from './components/login.component';
import { CurrencyPipe, DatePipe } from "@angular/common";
import { ImagePicker } from "@ionic-native/image-picker/ngx";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { isPlatform, NavController, Platform } from "@ionic/angular";
import { ApiService } from "vendors/smart-bot-app/src/app/services/api.service";
import { AuthService } from "vendors/smart-bot-app/src/app/services/auth.service";
import { CommonService } from "vendors/smart-bot-app/src/app/services/common.service";
import { RootServices } from "src/app/services/root.services";
import { AppComponentProcessor as SmartBotAppComponentProcessor } from "vendors/smart-bot-app/src/app/app.component.processor";
import { Storage } from '@ionic/storage';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { HomeComponent } from "./components/home.component";
import { RegisterComponent } from './components/register.component';
import { CompanyListComponent } from './components/company-list';
import { F7Framework7, F7TabContext } from 'vendors/smart-bot-app/src/app/types/framework7-types';
import { View as F7View, View } from 'framework7/build/core/components/view/view';
import { SettingsComponent } from './components/settings.component';
import { ChatRoomControlComponent } from './components/chat-room-control.component';
import { WorkplaceComponent } from './components/workplace.component';
import { AccountComponent } from './components/account.component';
import { ProfileComponent } from './components/profile.component';
import { PagesComponent } from './components/pages.component';
import { PageComponent } from './components/page.component';
import { ECommercePlatformComponent } from './components/e-commerce-platform.component';
import { NewsfeedComponent } from './components/newsfeed.component';
import { CsrComponent } from './components/csr.component';
import { ProductComponent } from './components/page/collaborator/product.component';
import { PageCollaboratorOrderFormComponent } from './components/page/collaborator/page-collaborator-order-form.compnent';
import { PageCollaboratorOrdersComponent } from './components/page/collaborator/page-collaborator-orders.component';
import { PageCollaboratorCommissionsComponent } from './components/page/collaborator/page-collaborator-commissions.component';
import { PageCollaboratorCommissionComponent } from './components/page/collaborator/page-collaborator-commission.component';
import { PageCollaboratorCommissionPaymentsComponent } from './components/page/collaborator/page-collaborator-commission-payments.component';
import { PageCollaboratorCommissionPaymentComponent } from './components/page/collaborator/page-collaborator-commission-payment.component';
import { UserProfileComponent } from './components/user-profile.component';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { filter, take } from 'rxjs/operators';
import { EditorComponent } from './components/editor.component';
import { ProductBlockComponent } from './components/user-profile/product-block.component';
import { PageCollaboratorAwardsComponent } from './components/page/collaborator/page-collaborator-awards.component';
import { PageCollaboratorAwardDetailsComponent as PageCollaboratorAwardComponent } from './components/page/collaborator/page-collaborator-award.component';
import { PageCollaboratorAwardDetailOrdersComponent } from './components/page/collaborator/page-collaborator-award-detail-commissions.component';
import { PageCollaboratorCommissionDetailOrdersComponent as PageCollaboratorCommissionDetailCommissionsComponent } from './components/page/collaborator/page-collaborator-commission-detail-orders.component';
import { PageEducationArticleComponent } from './components/page/collaborator/page-education-article';
import { environment } from 'src/environments/environment';
import { NotificationModel } from 'vendors/smart-bot-app/src/app/model/notification.model';
import * as moment from 'moment';
import { AccessNumberComponent } from './components/page/collaborator/access-number.component';
import { CollaboratorPageProductListComponent } from './components/page/product-list';
import { ContactListComponent } from './components/contact/contact-list';
import { ContactFormComponent } from './components/contact/contact-form';
import { AboutComponent } from './components/about.component';
import { PageCollaboratorOpportunityFormComponent } from './components/page/collaborator/page-collaborator-opportunity-form.compnent';
import { PageCollaboratorOpportunitiesComponent } from './components/page/collaborator/page-collaborator-opportunities.component';
import { BioPageComponent } from './components/bio-page.component';
import { BioPageSettingComponent } from './components/bio-page-setting.component';
import { Title } from "@angular/platform-browser";
import { IdentityCardVerificationComponent } from './components/identity-card-verification.component';
import { BioPageSocialComponent } from './components/bio-page-social';
import { RequestDeleteAccountComponent } from './components/request-delete-account';
import { ECardSettingComponent } from './components/e-card-setting.component';
import { ChatRoomComponent } from './components/chat-room.component';
import { PageCollaboratorReportPublisherCommissionListComponent } from './components/page/collaborator/report/publisher/commission/commission-list.component';
import { PageCollaboratorReportPublisherCommissionFormComponent } from './components/page/collaborator/report/publisher/commission/commission-form.component';
import { PageCollaboratorPosOrdersComponent } from './components/page/collaborator/page-collaborator-pos-orders.component';
import { PageCollaboratorPosOrderPrintComponent } from './components/page/collaborator/page-collaborator-pos-order-print.component';

// declare const $: any;
// @Component({
//   selector: 'app-root',
//   templateUrl: 'app.component.html',
//   styleUrls: ['app.component.scss'],
//   // providers: [DatePipe],
// })
export class AppComponentProcessor extends SmartBotAppComponentProcessor {

  registerComponent: RegisterComponent;
  contactsViewComponent: ContactsComponent;
  companyListComponent: CompanyListComponent;
  companyListView: F7View.View;
  contactsView: F7View.View;
  pagesView: F7View.View;
  eCommercePlatformView: F7View.View;
  newsFeedView: F7View.View;
  csrView: F7View.View;

  pagesComponent: PagesComponent;
  pageComponent: PageComponent;
  eCommercePlatFormComponent: ECommercePlatformComponent;
  newsfeesComponent: NewsfeedComponent;
  csrComponent: CsrComponent;
  productComponent: ProductComponent;
  originProductComponent: ProductComponent;
  accessNumberComponent: AccessNumberComponent;
  pageCollaboratorOrderFormComponent: PageCollaboratorOrderFormComponent;
  pageCollaboratorOrdersComponent: PageCollaboratorOrdersComponent;
  pageCollaboratorPosOrdersComponent: PageCollaboratorPosOrdersComponent;
  pageCollaboratorCommissionsComponent: PageCollaboratorCommissionsComponent;
  pageCollaboratorCommissionComponent: PageCollaboratorCommissionComponent;
  pageCollaboratorCommissionPaymentsComponent: PageCollaboratorCommissionPaymentsComponent;
  pageCollaboratorCommissionPaymentComponent: PageCollaboratorCommissionPaymentComponent;
  pageCollaboratorOpportunityFormComponent: PageCollaboratorOpportunityFormComponent;
  pageCollaboratorOpportunitiesComponent: PageCollaboratorOpportunitiesComponent;
  userProfileComponent: UserProfileComponent;
  bioPageComponent: BioPageComponent;
  bioPageSettingComponent: BioPageSettingComponent;
  bioPageSocialComponent: BioPageSocialComponent;
  editorComponent: EditorComponent;
  contactListComponent: ContactListComponent;
  contactFormComponent: ContactFormComponent;
  aboutComponent: AboutComponent;
  identityCardVerificationComponent: IdentityCardVerificationComponent;
  requestDeleteAccountComponent: RequestDeleteAccountComponent;
  eCardSettingComponent: ECardSettingComponent;

  constructor(
    public platform: Platform,
    public splashScreen: SplashScreen,
    public statusBar: StatusBar,
    public authService: AuthService,
    public storage: Storage,
    public apiService: ApiService,
    public rootServices: RootServices,
    public datePipe: DatePipe,
    public currencyPipe: CurrencyPipe,
    public imagePicker: ImagePicker,
    public commonService: CommonService,
    public iab: InAppBrowser,
    public fileTransfer: FileTransfer,
    public file: File,
    public deeplinks: Deeplinks,
    public navController: NavController,
    public titleService: Title
  ) {
    rootServices.env = authService.env = apiService.env = environment;
    super(platform, splashScreen, statusBar, authService, storage, apiService, rootServices, datePipe, currencyPipe, imagePicker, commonService, iab, fileTransfer, file, deeplinks, navController);
  }

  async initF7View(app: F7Framework7) {
    await this.rootServices.waitFor(50, 1000, () => $('#view-index').length > 0);
    this.mainView = await new Promise<any>(resolve => app.views.create('#view-index', { stackPages: true, name: 'index', url: '/home', on: { pageInit: (e) => resolve(e.view) } }));
    await this.rootServices.waitFor(50, 1000, () => $('#view-home').length > 0);
    this.homeView = await new Promise<any>(resolve => app.views.create('#view-home', { stackPages: true, name: 'home', url: '/home', on: { pageInit: (e) => resolve(e.view) } }));
    await this.rootServices.waitFor(50, 1000, () => $('#view-workplace').length > 0);
    this.workplaceView = await new Promise<any>(resolve => app.views.create('#view-workplace', { stackPages: true, name: 'workplace', url: '/workplace', on: { pageInit: (e) => resolve(e.view) } }));
    await this.rootServices.waitFor(50, 1000, () => $('#view-settings').length > 0);
    this.settingsView = await new Promise<any>(resolve => app.views.create('#view-settings', { stackPages: true, name: 'settings', url: '/settings', on: { pageInit: (e) => resolve(e.view) } }));
    await this.rootServices.waitFor(50, 1000, () => $('#view-dialpad').length > 0);
    this.dialpadView = await new Promise<any>(resolve => app.views.create('#view-dialpad', { stackPages: true, name: 'dialpad', url: '/dialpad', on: { pageInit: (e) => resolve(e.view) } }));
    await this.rootServices.waitFor(50, 1000, () => $('#view-company-list').length > 0);
    this.companyListView = await new Promise<any>(resolve => app.views.create('#view-company-list', { stackPages: true, name: 'company-list', url: '/company-list', on: { pageInit: (e) => resolve(e.view) } }));
    await this.rootServices.waitFor(50, 1000, () => $('#view-contacts').length > 0);
    this.contactsView = await new Promise<any>(resolve => app.views.create('#view-contacts', { stackPages: true, name: 'contacts-view', url: '/contacts-view', on: { pageInit: (e) => resolve(e.view) } }));

    // Page
    await this.rootServices.waitFor(50, 1000, () => $('#view-pages').length > 0);
    this.pagesView = await new Promise<any>(resolve => app.views.create('#view-pages', { stackPages: true, name: 'pages', url: '/pages', on: { pageInit: (e) => resolve(e.view) } }));
    await this.rootServices.waitFor(50, 1000, () => $('#view-e-commerce-platform').length > 0);
    this.eCommercePlatformView = await new Promise<any>(resolve => app.views.create('#view-e-commerce-platform', { stackPages: true, name: 'e-commerce-platform', url: '/e-commerce-platform', on: { pageInit: (e) => resolve(e.view) } }));

    // Newsfeed
    await this.rootServices.waitFor(50, 1000, () => $('#view-newsfeed').length > 0);
    this.newsFeedView = await new Promise<any>(resolve => app.views.create('#view-newsfeed', { stackPages: true, name: 'newsfeed', url: '/newsfeed', on: { pageInit: (e) => resolve(e.view) } }));

    // CSR
    await this.rootServices.waitFor(50, 1000, () => $('#view-csr').length > 0);
    this.csrView = await new Promise<any>(resolve => app.views.create('#view-csr', { stackPages: true, name: 'csr', url: '/csr', on: { pageInit: (e) => resolve(e.view) } }));
  }

  get appName() {
    return environment.name;
  }

  async onF7AppInit(app: F7Framework7) {
    const rs = await super.onF7AppInit(app);
    // this.companyListView = app.views.create('#view-company-list', { stackPages: true, name: 'company-list', url: '/company-list' });
    // this.contactsView = app.views.create('#view-contacts', { stackPages: true, name: 'contacts-view', url: '/contacts-view' });

    app.on('tabShow', async (tabEle: F7TabContext) => {
      let view: View.View;
      switch (tabEle.id) {
        case 'view-company-list':
          console.log('view-company-list tab show');
          view = tabEle.f7View;
          if (!(await this.companyListComponent.refresh())) {
            console.log('refresh error');
          }
          break;
        case 'view-contacts':
          console.log('view-contacts tab show');
          view = tabEle.f7View;
          if (!(await this.contactsComponent.refresh())) {
            console.log('refresh error');
          }
          break;
      }
    });
    if (!this.rootServices.isMobile) {// Register after routes register
      this.registerDeeplinks();
    }
    return rs;
  }

  /** Override initialize app mothod */
  async initializeApp() {
    if (this.rootServices.isMobile) {// Register on begin
      this.registerDeeplinks();
    }
    return super.initializeApp().then(rs => {
      return true;
    });
  }

  async prepareRouter() {
    return super.prepareRouter().then(rs => {

      // Override home component
      const homeComponentIndex = this.routes.findIndex(f => f.name === 'home');
      this.components['home'] = this.homeComponent = new HomeComponent(this.rootServices, this.commonService);
      this.routes[homeComponentIndex] = this.homeComponent.f7Component;

      // Override login component
      const loginComponentIndex = this.routes.findIndex(f => f.name === 'login');
      this.components['login'] = this.loginComponent = new LoginComponent(this.rootServices, this.commonService);
      this.routes[loginComponentIndex] = this.loginComponent.f7Component;

      // Override settings component
      const settingsComponentIndex = this.routes.findIndex(f => f.name === 'settings');
      this.components['settings'] = this.settingsComponent = new SettingsComponent(this, this.rootServices, this.commonService);
      this.routes[settingsComponentIndex] = this.settingsComponent.f7Component;

      // Override chat-room-control component
      const chatRoomControlComponentIndex = this.routes.findIndex(f => f.name === 'chat-room-control');
      this.components['chat-room-control'] = this.chatRoomControlComponent = new ChatRoomControlComponent(this.rootServices, this.commonService, this.datePipe);
      this.routes[chatRoomControlComponentIndex] = this.chatRoomControlComponent.f7Component;

      // Override account component
      const accontComponentIndex = this.routes.findIndex(f => f.name === 'account');
      this.components['account'] = this.accountComponent = new AccountComponent(this.rootServices, this.commonService, this);
      this.routes[accontComponentIndex] = this.accountComponent.f7Component;

      // Override workplace component
      const workplaceComponentIndex = this.routes.findIndex(f => f.name === 'workplace');
      this.components['workplace'] = this.workplaceComponent = new WorkplaceComponent(this.rootServices, this.commonService, this.datePipe);
      this.routes[workplaceComponentIndex] = this.workplaceComponent.f7Component;

      // Override Chat room component
      const chatRoomComponent = this.routes.findIndex(f => f.name === 'chat-room');
      this.components['chat-room'] = new ChatRoomComponent(this.rootServices, this.commonService, this.datePipe, this.imagePicker,
        this.iab,
        this.fileTransfer,
        this.file,
        this,
      );
      this.routes[chatRoomComponent] = this.components['chat-room'].f7Component;

      // Override profile component
      const profileComponentIndex = this.routes.findIndex(f => f.name === 'profile');
      this.components['profile'] = this.profileComponent = new ProfileComponent(this.rootServices, this.commonService);
      this.routes[profileComponentIndex] = this.profileComponent.f7Component;

      // Add register route
      this.registerComponent = new RegisterComponent(this.rootServices, this.commonService);
      this.routes.push(this.registerComponent.f7Component);
      this.components['register'] = this.registerComponent;

      // Add company list route
      this.companyListComponent = new CompanyListComponent(this.rootServices, this.commonService);
      this.routes.push(this.companyListComponent.f7Component);
      this.components['company-list'] = this.companyListComponent;

      // Add company list route
      this.contactsViewComponent = new ContactsComponent(this.rootServices, this.commonService, true);
      this.routes.push({ ...this.contactsViewComponent.f7Component, path: '/contacts-view' });
      this.components['contacts-view'] = this.contactsViewComponent;

      // Pages component
      this.components['pages'] = this.pagesComponent = new PagesComponent(this.rootServices, this.commonService);
      this.routes.push(this.pagesComponent.f7Component);

      // Page component
      this.components['page'] = this.pageComponent = new PageComponent(this.rootServices, this.commonService);
      this.routes.push(this.pageComponent.f7Component);

      // E-Commerce Platform component
      this.components['e-commerce-platform'] = this.eCommercePlatFormComponent = new ECommercePlatformComponent(this.rootServices, this.commonService);
      this.routes.push(this.eCommercePlatFormComponent.f7Component);

      // Newsfeed component
      this.components['newsfeed'] = this.newsfeesComponent = new NewsfeedComponent(this.rootServices, this.commonService);
      this.routes.push(this.newsfeesComponent.f7Component);

      // CSR component
      this.components['csr'] = this.csrComponent = new CsrComponent(this.rootServices, this.commonService);
      this.routes.push(this.csrComponent.f7Component);

      // Product component
      this.components['product'] = this.productComponent = new ProductComponent(this.rootServices, this.commonService);
      this.routes.push(this.productComponent.f7Component);

      // Origin Product
      this.components['origin-product'] = this.originProductComponent = new ProductComponent(this.rootServices, this.commonService);
      this.routes.push({ ...this.originProductComponent.f7Component, path: '/:pageId/product/:id' });

      // Access Number
      this.components['access-number'] = this.accessNumberComponent = new AccessNumberComponent(this.rootServices, this.commonService);
      this.routes.push(this.accessNumberComponent.f7Component);

      // Page Collaborator Order Form component
      this.components['page-collaborator-order-form'] = this.pageCollaboratorOrderFormComponent = new PageCollaboratorOrderFormComponent(this.rootServices, this.commonService);
      this.routes.push(this.pageCollaboratorOrderFormComponent.f7Component);

      // Page Collaborator Order Form component
      this.components['page-collaborator-orders'] = this.pageCollaboratorOrdersComponent = new PageCollaboratorOrdersComponent(this.rootServices, this.commonService, this.datePipe);
      this.routes.push(this.pageCollaboratorOrdersComponent.f7Component);

      // Page Collaborator POS Orders component
      this.components['page-collaborator-pos-orders'] = this.pageCollaboratorPosOrdersComponent = new PageCollaboratorPosOrdersComponent(this.rootServices, this.commonService, this.datePipe);
      this.routes.push(this.pageCollaboratorPosOrdersComponent.f7Component);
      this.routes.push(new PageCollaboratorPosOrderPrintComponent(this.rootServices, this.commonService).f7Component);

      // Page Collaborator Opportunity Form component
      this.components['page-collaborator-opportunity-form'] = this.pageCollaboratorOpportunityFormComponent = new PageCollaboratorOpportunityFormComponent(this.rootServices, this.commonService);
      this.routes.push(this.pageCollaboratorOpportunityFormComponent.f7Component);
      this.components['page-collaborator-opportunities'] = this.pageCollaboratorOpportunitiesComponent = new PageCollaboratorOpportunitiesComponent(this.rootServices, this.commonService, this.datePipe);
      this.routes.push(this.pageCollaboratorOpportunitiesComponent.f7Component);

      // Page Collaborator Commissions component
      this.components['page-collaborator-commissions'] = this.pageCollaboratorCommissionsComponent = new PageCollaboratorCommissionsComponent(this.rootServices, this.commonService, this.datePipe, this.currencyPipe);
      this.routes.push(this.pageCollaboratorCommissionsComponent.f7Component);

      // Page Collaborator Commissions component
      this.components['page-collaborator-commission'] = this.pageCollaboratorCommissionComponent = new PageCollaboratorCommissionComponent(this.rootServices, this.commonService, this.datePipe, this.currencyPipe);
      this.routes.push(this.pageCollaboratorCommissionComponent.f7Component);

      // Page Collaborator Commission Payemnts component
      this.components['page-collaborator-commission-payments'] = this.pageCollaboratorCommissionPaymentsComponent = new PageCollaboratorCommissionPaymentsComponent(this.rootServices, this.commonService, this.datePipe, this.currencyPipe);
      this.routes.push(this.pageCollaboratorCommissionPaymentsComponent.f7Component);

      // Page Collaborator Commission Payment component
      this.components['page-collaborator-commission-payment'] = this.pageCollaboratorCommissionPaymentComponent = new PageCollaboratorCommissionPaymentComponent(this.rootServices, this.commonService, this.datePipe, this.currencyPipe);
      this.routes.push(this.pageCollaboratorCommissionPaymentComponent.f7Component);

      // User Profile component
      this.components['user-profile'] = this.userProfileComponent = new UserProfileComponent(this.rootServices, this.commonService, this.datePipe, this.currencyPipe);
      this.routes.push(this.userProfileComponent.f7Component);

      // Bio Page component
      this.components['bio-page'] = this.bioPageComponent = new BioPageComponent(this.rootServices, this.commonService, this.datePipe, this.currencyPipe);
      this.routes.push(this.bioPageComponent.f7Component);
      this.components['bio-page-setting'] = this.bioPageSettingComponent = new BioPageSettingComponent(this.rootServices, this.commonService, this.datePipe, this.currencyPipe);
      this.routes.push(this.bioPageSettingComponent.f7Component);
      this.components['bio-page-social'] = this.bioPageSocialComponent = new BioPageSocialComponent(this.rootServices, this.commonService);
      this.routes.push(this.bioPageSocialComponent.f7Component);
      this.routes.push({ ...this.bioPageSocialComponent.f7Component, path: '/bio-page-social/:search/:query' });

      // Editor component
      this.components['editor'] = this.editorComponent = new EditorComponent(this.rootServices, this.commonService, this.datePipe, this.currencyPipe);
      this.routes.push(this.editorComponent.f7Component);

      // Product Block component
      this.components['product-block'] = new ProductBlockComponent(this.rootServices, this.commonService, this.datePipe, this.currencyPipe);
      this.routes.push(this.components['product-block'].f7Component);

      // Award list component
      this.components['page-collaborator-awards'] = new PageCollaboratorAwardsComponent(this.rootServices, this.commonService, this.datePipe, this.currencyPipe);
      this.routes.push(this.components['page-collaborator-awards'].f7Component);

      // Award details component
      this.components['page-collaborator-award'] = new PageCollaboratorAwardComponent(this.rootServices, this.commonService, this.datePipe, this.currencyPipe);
      this.routes.push(this.components['page-collaborator-award'].f7Component);

      // Award detail orders component
      this.components['page-collaborator-award-detail-orders'] = new PageCollaboratorAwardDetailOrdersComponent(this.rootServices, this.commonService, this.datePipe, this.currencyPipe);
      this.routes.push(this.components['page-collaborator-award-detail-orders'].f7Component);

      // // Award detail reforders component
      // this.components['page-collaborator-award-detail-reforders'] = new PageCollaboratorAwardDetailRefOrdersComponent(this.rootServices, this.commonService, this.datePipe, this.currencyPipe);
      // this.routes.push(this.components['page-collaborator-award-detail-reforders'].f7Component);

      // Award detail reforders component
      this.components['page-collaborator-award-detail-commissions'] = new PageCollaboratorCommissionDetailCommissionsComponent(this.rootServices, this.commonService, this.datePipe, this.currencyPipe);
      this.routes.push(this.components['page-collaborator-award-detail-commissions'].f7Component);

      // Collaborator education article component
      this.components['page-education-article'] = new PageEducationArticleComponent(this.rootServices, this.commonService, this.datePipe, this.currencyPipe);
      this.routes.push(this.components['page-education-article'].f7Component);

      // Collaborator product list component
      this.components['page-product-list'] = new CollaboratorPageProductListComponent(this.rootServices, this.commonService);
      this.routes.push(this.components['page-product-list'].f7Component);

      // Contact List Components
      this.components['contact-list'] = this.contactListComponent = new ContactListComponent(this.rootServices, this.commonService);
      this.routes.push(this.contactListComponent.f7Component);

      // Contact Form Components
      this.components['contact-form'] = this.contactFormComponent = new ContactFormComponent(this.rootServices, this.commonService);
      this.routes.push(this.contactFormComponent.f7Component);

      // About Components
      this.components['about'] = this.aboutComponent = new AboutComponent(this.rootServices, this.commonService);
      this.routes.push(this.aboutComponent.f7Component);

      // Identity Card Verification Components
      this.components['identity-card-verification'] = this.identityCardVerificationComponent = new IdentityCardVerificationComponent(this.rootServices, this.commonService, this);
      this.routes.push(this.identityCardVerificationComponent.f7Component);

      // Request delete account
      this.components['request-delete-account'] = this.requestDeleteAccountComponent = new RequestDeleteAccountComponent(this.rootServices, this.commonService);
      this.routes.push(this.requestDeleteAccountComponent.f7Component);

      // E-Card setting
      this.components['e-card-setting'] = this.eCardSettingComponent = new ECardSettingComponent(this.rootServices, this.commonService);
      this.routes.push(this.eCardSettingComponent.f7Component);


      // Report
      // - Publisher
      //   + Commission
      this.routes.push(new PageCollaboratorReportPublisherCommissionListComponent(this.rootServices, this.commonService).f7Component);
      this.routes.push(new PageCollaboratorReportPublisherCommissionFormComponent(this.rootServices, this.commonService).f7Component);

      return rs;
    });
  }

  async deepLinkProcess(path: string) {
    if (/^\/profile/.test(path)) {
      await this.rootServices.waitingForAllReady();
      this.rootServices.navigate(path.replace('\/profile', '\/user-profile'), { animate: false });
      this.rootServices.allowNavigate = false;
      await this.userProfileComponent?.currentState?.loaded$.pipe(filter(f => f), take(1)).toPromise();
      setTimeout(() => {
        this.rootServices.allowNavigate = true;
      }, 2000);
    } else if (/^\/bio\//.test(path)) {
      await this.rootServices.waitingForAllReady();
      this.rootServices.navigate(path.replace('\/bio\/', '\/bio-page\/'), { animate: false });
      this.rootServices.allowNavigate = false;
      await this.bioPageComponent?.currentState?.loaded$.pipe(filter(f => f), take(1)).toPromise();
      setTimeout(() => {
        this.rootServices.allowNavigate = true;
      }, 2000);
    } else if (/^\/(\w+)\/ctvbanhang\/edu\/(\w+)/.test(path)) {
      const mathched = /^\/(\w+)\/ctvbanhang\/edu\/(\w+)/.exec(path);
      console.log(mathched);
      await this.rootServices.waitingForAllReady();
      // this.commonService.navigate(`/page-education-article/${mathched[1]}/${mathched[2]}`, { animate: false });
      this.commonService.navigate(path, { animate: false });
      this.rootServices.allowNavigate = false;
      await this.userProfileComponent?.currentState?.loaded$.pipe(filter(f => f), take(1)).toPromise();
      setTimeout(() => {
        this.rootServices.allowNavigate = true;
      }, 2000);
    } else if (/^\/(\d+)\/refcode\/(\d+)/.test(path)) {
      const mathched = /^\/(\d+)\/refcode\/(\d+)/.exec(path);
      console.log(mathched);
      await this.rootServices.waitingForAllReady();
      this.commonService.navigate('/page/' + mathched[1] + '/collaborator/products/refcode/' + mathched[2], { animate: false });
      this.rootServices.allowNavigate = false;
      setTimeout(() => {
        this.rootServices.allowNavigate = true;
      }, 2000);
    } else if (/^\/(\d+)$/.test(path)) {
      const mathched = /^\/(\d+)$/.exec(path);
      await this.rootServices.waitingForAllReady();
      this.commonService.navigate('/page/' + mathched[1] + '/collaborator/products/', { animate: false });
      this.rootServices.allowNavigate = false;
      setTimeout(() => {
        this.rootServices.allowNavigate = true;
      }, 2000);
    } else if (/^\/([\w\.]+)$/.test(path)) {
      const mathched = /^\/([\w\.]+)\/?/.exec(path);
      const pageTag = mathched[1];
      this.rootServices.allowNavigate = false;
      // Load global tag
      try {
        const globalTag = await this.rootServices.apiService.getPromise<any[]>('/system/global-tags/' + pageTag, { skipAuth: true }).then(rs => rs[0]);

        // Check page tag exists
        if (globalTag?.RelativeModel) {
          if (globalTag.RelativeModel == 'Page_Model') {
            this.commonService.navigate('/page/' + pageTag + '/', { animate: false, context: { pageTag: pageTag } });
          }
          if (globalTag.RelativeModel == 'Model_Business_User') {
            this.commonService.navigate('/bio-page/' + pageTag + '/', { animate: false, context: { pageTag: pageTag } });
          }
        } else {
          this.commonService.navigate('/page-not-found/', { animate: false });
        }
        setTimeout(() => {
          this.rootServices.allowNavigate = true;
        }, 2000);
      } catch (err) {
        console.error(err);
      }
    } else if (/^\/(\w+)\/ctvbanhang\/ref\/(\w+)$/i.test(path)) {
      const mathched = /^\/(\w+)\/ctvbanhang\/ref\/(\w+)$/i.exec(path);
      const pageTag = mathched[1];
      const refId = mathched[2];
      await this.rootServices.waitingForAllReady();
      this.commonService.navigate('/page/' + pageTag + '/', { animate: false, context: { pageTag: pageTag, refId: refId } });
      this.rootServices.allowNavigate = false;
      setTimeout(() => {
        this.rootServices.allowNavigate = true;
      }, 2000);
    } else if (/^\/(\w+)\/ctvbanhang$/.test(path)) {
      const mathched = /^\/(\w+)\/ctvbanhang$/.exec(path);
      // console.log(mathched);
      const pageTag = mathched[1];
      await this.rootServices.waitingForAllReady();
      this.commonService.navigate('/page/' + pageTag + '/', { animate: false, context: { pageTag: pageTag } });
      this.rootServices.allowNavigate = false;
      setTimeout(() => {
        this.rootServices.allowNavigate = true;
      }, 2000);
    } else {
      if (path !== '/') {
        const params = this.commonService.getUrlParams(location.search);
        await this.rootServices.waitingForAllReady();
        setTimeout(() => {
          this.commonService.navigate(path, { context: { params: params }, animate: false });
        }, 0);// fix routes register delay
      }
    }
  }

  async registerDeeplinks() {
    // console.log(getPlatforms());
    await this.rootServices.waitingForAllReady();
    console.log('[deeplink] ' + this.rootServices?.device?.platform);

    let path = '';
    if (!this.rootServices.device.platform || this.rootServices.device.platform == 'browser') {
      console.log('[deeplink] Register deeplink for BROWSER');
      path = location.pathname;
      console.log('[deeplink] path:' + path);
      this.deepLinkProcess(path);
    } else {
      console.log('[deeplink] Register deeplink for NATIVE');
      this.deeplinks.route({
        // '/:tag': '/:tag',
        // '/:tag/ctvbanhang': '/:tag/ctvbanhang',
        // '/:tag/ctvbanhang/ref/:refId': '/:tag/ctvbanhang/ref/:refId',
        // '/chat-room/:id': '/chat-room/:id',
        // // 'product': '/product/:id',
        // '/profile/:id': '/profile/:id',
        // 'eduation-article': '/:pageId/ctvbanhang/edu/:id',
        // 'product': '/:pageId/ctvbanhang/product/:id',
        // 'origin-product': '/:pageId/product/:id',
        // '/:pageId/truyxuat/:accessNumber': '/:pageId/truyxuat/:accessNumber',
        // // 'memcard': '/:pageId/memcard/:memberCardId',
        // '/:pageId/refcode/:refcode': '/:pageId/refcode/:refcode',
        // '/request-delete-account': '/request-delete-account',
        // 'index': '/',
        // 'any': '/',
      }).subscribe(match => {
        // path = match.$link.path;
        console.log('[deeplink] match:', match);
        // console.log('[deeplink] path:' + path);
        // this.deepLinkProcess(path);
      }, matchall => {
        path = matchall.$link.path;
        console.log('[deeplink] matchall:', matchall);
        console.log('[deeplink] matchall path:' + path);
        this.deepLinkProcess(path);
      });
    }
    return true;
  }

  checkUpdate(e: MouseEvent) {
    if (isPlatform('ios')) {
      this.rootServices.market.open('1565916654');
    }
    if (isPlatform('android')) {
      this.rootServices.market.open(this.rootServices.env.bundleId);
    }
  }

  stateMap = {
    NEW: {
      text: 'mới',
      color: 'color-red',
    },
    READ: {
      text: 'đã xem',
      color: 'color-gray',
    },
    ACTIVE: {
      text: 'đã mở',
      color: 'color-green',
    },
  };
  preapreTaskState(notification: NotificationModel) {
    // notification.DateOfCreate = moment(notification.DateOfCreate).fromNow();
    notification.DateOfCreateMoment = moment(notification.DateOfCreate).fromNow();
    notification.Content = this.commonService.cleanMentionTags(notification.Content);
    notification['SenderAvatarThumbnail'] = this.rootServices.apiService.getBaseApiUrl() + '/user/users/' + this.rootServices.commonService.getObjectId(notification['Sender']) + '/avatar';
    const state = this.stateMap[notification.State];
    notification['StateText'] = state?.text || 'mới';
    notification['StateColor'] = state?.color || 'mới';
    return notification;
  }

}
