import { F7ComponentContext } from '../types/framework7-types';
import { Messages } from 'framework7/build/core/components/messages/messages';
import { F7Messagebar } from '../types/framework7-types';
import { Router } from 'framework7/build/core/modules/router/router';
import { Dialog } from 'framework7/build/core/components/dialog/dialog';
import { isPlatform } from '@ionic/angular';
import { AppComponentProcessor } from '../app.component.processor';
import { BaseComponent, ComponentState } from 'vendors/smart-bot-app/src/app/lib/base-component';
import { RootServices } from 'vendors/smart-bot-app/src/app/services/root.services';
import { CommonService } from 'vendors/smart-bot-app/src/app/services/common.service';
import { AccountModel } from 'vendors/smart-bot-app/src/app/model/account.model';
import { FileModel } from 'vendors/smart-bot-app/src/app/model/file.model';

export interface ComponentStateExtend extends ComponentState {
  [key: string]: any;
}

export class F7ComponentContextExtend extends F7ComponentContext {
  messagebar?: F7Messagebar;
  messages?: Messages.Messages;
  images?: string[];
  responseInProgress?: boolean;
  answers?: string[];
  people?: { name?: string, avatar?: string }[];
  hideToolbar?: () => void;
  sheetToggle?: () => void;
  deleteAttachment?: (e: MouseEvent, index: number) => void;
  handleAttachment?: (e: MouseEvent) => void;
  checkAttachments?: () => void;
  sendMessage?: () => void;
  $root: any;
  // [key: string]: any;

  updatePinCodeDialog?: Dialog.Dialog;
  changePasswordDialog?: Dialog.Dialog;
}

/** Component group manager */
export class IdentityCardVerificationComponent extends BaseComponent<ComponentStateExtend> {
  // states: { [key: string]: State } = {};

  title: 'Xác minh CCCD'

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
    public appComponent: AppComponentProcessor,
  ) {
    super(rootServices);
    // console.log('Click here to open ts file');
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: 'identity-card-verification',
      path: '/identity-card-verification',
      component: {
        template: /*html*/`
            <div class="page identity-card-verification no-toolbar" data-name="identity-card-verification">
              <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a class="link back">
                      <i class="icon icon-back"></i>
                      <span class="if-not-md">{{backTitle}}</span>
                    </a>
                  </div>
                  <div class="title">Định danh</div>
                  <div class="right">
                    <a class="link icon-only">
                      <i class="icon f7-icons">ellipsis</i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="page-content">
            
                <div class="profile-info">
                  <div @click="changeAvatar" class="profile-logo bg-color-gray"
                    style="background-image: url({{js "this.account && this.account.Avatar && this.account.Avatar.payload && this.account.Avatar.payload.thumbnail || ''"}})">
                  </div>
                  <div class="profile-title text-color-default">{{js "this.account && this.account.Name || ''"}}</div>
                  <div class="profile-subtitle text-color-gray">ID: {{js "this.account && this.account.Code || ''"}}</div>
                </div>
            
                <!--<div class="block-header">Cài đặt tài khoản</div>
                            <div class="list simple-list inset">
                              <ul>
                                <li>
                                  <span>Online</span>
                                  <label class="toggle toggle-init color-blue">
                                    <input type="checkbox" checked>
                                    <span class="toggle-icon"></span>
                                  </label>
                                </li>
                                <li>
                                  <span>Chia sẻ vị trí</span>
                                  <label class="toggle toggle-init color-black">
                                    <input type="checkbox" checked>
                                    <span class="toggle-icon"></span>
                                  </label>
                                </li>
                                <li>
                                  <span>Nhận job</span>
                                  <label class="toggle toggle-init color-orange">
                                    <input type="checkbox" checked>
                                    <span class="toggle-icon"></span>
                                  </label>
                                </li>
                              </ul>
                            </div>-->
            
                <div class="block-header">Thông tin CCCD</div>
                <div class="list inset profile-form no-hairlines-md">
                  <form autocomplete="off">
                    <ul>
                      <li class="item-content item-input">
                        <div class="item-media"><i class="icon f7-icons text-color-default">person_circle_fill</i></div>
                        <div class="item-inner">
                          <div class="item-title item-label">Tên đầy đủ</div>
                          <div class="item-input-wrap">
                            <input class="profile-field" name="Name" type="text" placeholder="Họ và tên"
                              value="{{js "this.account && this.account.Name"}}" disabled>
                            
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-media"><i class="icon f7-icons text-color-default">creditcard_fill</i></div>
                        <div class="item-inner">
                          <div class="item-title item-label">Số CCCD</div>
                          <div class="item-input-wrap">
                            <input class="profile-field" name="IdentityCardNumber" type="text" placeholder="Số CCCD của bạn..."
                              value="{{js "this.account && this.account.IdentityCardNumber || ''"}}" autocomplete="new-password"
                              autocorrect="off">
                            
                          </div>
                        </div>
                      </li>
                    </ul>
                  </form>
                </div>

                <div class="block inset" style="display: flex; flex-wrap: wrap; padding: 0;">
                  <div style="flex: 50%;">
                    <div class="image" style="background-image: url({{js "this.account && this.account.IdentityCardImage1 && this.account.IdentityCardImage1.SmallImage"}})">
                      <div @click="uploadIdentityCardImage1" class="upload-banner-btn" style="right: 3vw; bottom: 6vw; right: 5px; top: 5px; left: initial; bottom: initial">
                        <i class="icon f7-icons upload-banner-icon">camera_fill</i>
                      </div>
                      <div class="placeholder">Mặt trước CCCD</div>
                    </div>
                  </div>
                  <div style="flex: 50%;">
                    <div class="image" style="background-image: url({{js "this.account && this.account.IdentityCardImage2 && this.account.IdentityCardImage2.SmallImage"}})">
                      <div @click="uploadIdentityCardImage2" class="upload-banner-btn" style="right: 3vw; bottom: 6vw; right: 5px; top: 5px; left: initial; bottom: initial">
                        <i class="icon f7-icons upload-banner-icon">camera_fill</i>
                      </div>
                      <div class="placeholder">Mặt sau CCCD</div>
                    </div>
                  </div>
                </div>

                <div class="list inset bio-form no-hairlines-md">
                  <form autocomplete="off">
                    <ul>
                      <li class="item-content item-input" style="padding-left: 0">
                        <a class="col button button-fill button-large link" @click="updateAccount" style="width: 100%">Cập nhật thông tin định danh</a>
                      </li>
                    </ul>
                  </form>
                </div>
                
                <div class="block"><br></div>
              
              </div>
            
            </div>
        `,
        style: /*css*/`
          .page.identity-card-verification .profile-info {
            margin-top: 20px;
          }

          .page.identity-card-verification .image {
            border-radius: var(--f7-list-inset-border-radius);
            margin: 5px; 
            height: 150px; 
            position: relative; 
            background-repeat: no-repeat; 
            overflow: hidden; 
            background-size: cover; 
            background-position: center;
            background-color: var(--f7-block-strong-bg-color);

            padding: 18%;
            font-weight: bold;

          }

          .page.identity-card-verification .image .placeholder {
            position: absolute;
            background-color: var(--f7-color-white);
            border-radius: 0.5rem;
            padding: 0.2rem;
            opacity: 80%;
          }
          
          .page.identity-card-verification .profile-logo {
            height: 100px;
            width: 100px;
            border-radius: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            margin: 0 auto;
          }
          .page.identity-card-verification .upload-banner-btn {
            position: absolute;
            border-radius: 50%;
            background: #fff;
            border: 1.5px solid #000;
            padding: 2px;
            width: 28px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
          .page.identity-card-verification .upload-banner-btn .upload-banner-icon {
            font-size: 14px;
            color: black;
          }
          
          .page.identity-card-verification .profile-title {
            text-align: center;
            font-weight: bold;
          }
          
          .page.identity-card-verification .profile-subtitle {
            text-align: center;
          }

          .page.identity-card-verification .image {
            border-radius: var(--f7-list-inset-border-radius);
            margin: 5px; 
            height: 150px; 
            position: relative; 
            background-repeat: no-repeat; 
            overflow: hidden; 
            background-size: cover; 
            background-position: center;
            background-color: var(--f7-block-strong-bg-color);
          }
        `,
        data() {
          return {
            title: $this.title,
            isDesktop: isPlatform('desktop'),
          };
        },
        methods: {
          uploadIdentityCardImage1(e) {
            const self: F7ComponentContextExtend = this;
            $this.rootServices.pickAndUploadFile().then(file => {
              console.log(file);
              self.account.IdentityCardImage1 = file;
              self.$setState({
                account: self.account,
              });
            });
          },
          uploadIdentityCardImage2(e) {
            const self: F7ComponentContextExtend = this;
            $this.rootServices.pickAndUploadFile().then(file => {
              console.log(file);
              self.account.IdentityCardImage2 = file;
              self.$setState({
                account: self.account,
              });
            });
          },
          async verifyIdentifiedNumber(e) {
            const self: F7ComponentContextExtend = this;
            try {
              if (!self.IdentityCardImage1) {
                self.IdentityCardImage1 = await $this.rootServices.pickAndUploadFile();
                self.$setState({ IdentityCardImage1: self.IdentityCardImage1 });
                $this.commonService.showInfo('Bấm lần nữa để upload mặt sau CCCD', { position: 'center' });
                return;
              }
              if (!self.IdentityCardImage2) {
                self.IdentityCardImage2 = await $this.rootServices.pickAndUploadFile();
                self.$setState({ IdentityCardImage2: self.IdentityCardImage2 });
                $this.rootServices.apiService.putPromise('/user/users/updateAccount', {}, {
                  IdentityCardImage1: self.IdentityCardImage1,
                  IdentityCardImage2: self.IdentityCardImage2,
                }).then(rs => {
                  $this.commonService.showInfo('Đã gửi yêu cầu xác thực CCCD', { position: 'center' });
                  self.IdentityCardImage1 = self.IdentityCardImage2 = null;
                  // $this.refresh();
                }).catch(err => {
                  console.error(err);
                  self.$app.preloader.hide();
                  $this.commonService.showError(err);
                });
              }

            } catch (err) {
              console.error(err);
            }
          },
          verifyEmail() {
            const self: F7ComponentContextExtend = this;
            self.$app.preloader.show();
            $this.rootServices.apiService.putPromise<any>('/user/users/requestVerifyEmail', {}, []).then(async rs => {
              self.$app.preloader.hide();
              let tryTime = 1;
              return await $this.rootServices.enterPasscodePopup(
                'Điền mã xác thực OTP',
                  /*html*/ `
                      <div class="block block-strong inset">Bạn hãy vào hộp thư cá nhân của bạn để lấy OPT, nếu không có trong Inbox vui lòng kiểm tra trong spam!</div>
                      <div class="block block-strong inset" style="padding: 0; overflow: hidden; line-height: 0">
                          
                      </div>
                  `, 4, {
                swipeToClose: false,
                backLabel: 'Skip',
                onFullFill: (otp_1) => {
                  self.$app.preloader.show();
                  return $this.rootServices.apiService.putPromise('/user/users/verifyEmail', {}, [{
                    Otp: otp_1,
                  }]).then(async (rs_1) => {
                    self.$app.preloader.hide();
                    $this.commonService.showInfo('Xác thực thành công !', { position: 'center' });

                    $this.refresh();
                    // $this.commonService.showInfo('');
                    // self.register.Code = rs[0].Code;
                    // self.$setState({
                    //   register: self.register,
                    // });

                    return true;
                  }).catch(err_3 => {
                    self.$app.preloader.hide();
                    $this.commonService.showError(err_3, { position: 'bottom' });
                    tryTime++;
                    if (tryTime <= 3) {
                      // self.tryVerifiedOtp(phone, 'Mã OTP không hợp lệ, hãy thử lại (' + tryTime + '/3)', tryTime);
                      return Promise.reject({ error: 422, message: 'Mã OTP không hợp lệ, hãy thử lại (' + tryTime + '/3)' });
                    }
                    tryTime = 1;
                    return Promise.reject({ error: 400, message: 'Mã OTP đã hết hiệu lực !' });
                  });
                }
              }).catch(errOrSkip => {
                self.$app.preloader.hide();
                // if (errOrSkip == 'CLOSE') {

                // } else {
                //   $this.commonService.showError(errOrSkip);
                //   self.$setState({ notvalidated: false, regnotvalidated: false });
                // }
              });
            }).catch(err => {
              self.$app.preloader.hide();
              return Promise.reject(err);
            });
          },
          verifyPhone() {
            const self: F7ComponentContextExtend = this;
            self.$app.preloader.show();
            $this.rootServices.apiService.putPromise<any>('/user/users/requestVerifyPhoneByZaloOtp', {}, []).then(async rs => {
              self.$app.preloader.hide();
              let tryTime = 1;
              return await $this.rootServices.enterPasscodePopup(
                'Điền mã xác thực OTP',
                  /*html*/ `
                      <div class="block-title">Bạn hãy vào Zalo để kiểm tra tin nhắn OTP</div>
                      <div class="block block-strong inset" style="padding: 0; overflow: hidden; line-height: 0">
                          <img src="assets/images/zalo-otp.png" style="width: 100%">
                      </div>
                  `, 4, {
                swipeToClose: false,
                backLabel: 'Skip',
                onFullFill: (otp_1) => {
                  self.$app.preloader.show();
                  return $this.rootServices.apiService.putPromise('/user/users/verifyPhoneByZaloOtp', {}, [{
                    Otp: otp_1,
                  }]).then(async (rs_1) => {
                    self.$app.preloader.hide();
                    $this.commonService.showInfo('Xác thực thành công !', { position: 'center' });

                    $this.refresh();
                    // $this.commonService.showInfo('');
                    // self.register.Code = rs[0].Code;
                    // self.$setState({
                    //   register: self.register,
                    // });

                    return true;
                  }).catch(err_3 => {
                    self.$app.preloader.hide();
                    $this.commonService.showError(err_3, { position: 'bottom' });
                    tryTime++;
                    if (tryTime <= 3) {
                      // self.tryVerifiedOtp(phone, 'Mã OTP không hợp lệ, hãy thử lại (' + tryTime + '/3)', tryTime);
                      return Promise.reject({ error: 422, message: 'Mã OTP không hợp lệ, hãy thử lại (' + tryTime + '/3)' });
                    }
                    tryTime = 1;
                    return Promise.reject({ error: 400, message: 'Mã OTP đã hết hiệu lực !' });
                  });
                }
              }).catch(errOrSkip => {
                self.$app.preloader.hide();
                // if (errOrSkip == 'CLOSE') {

                // } else {
                //   $this.commonService.showError(errOrSkip);
                //   self.$setState({ notvalidated: false, regnotvalidated: false });
                // }
              });
            }).catch(err => {
              self.$app.preloader.hide();
              return Promise.reject(err);
            });
          },
          scan2login() {
            const self: F7ComponentContextExtend = this;
            $this.appComponent.scanQRCode();
          },
          openChangePasswordDialog(e: MouseEvent) {
            const self: F7ComponentContextExtend = this;
            if (self.changePasswordDialog) {
              self.changePasswordDialog.open();
              self.changePasswordDialog.$el.find('.field[name="Password"]').focus();
            }
          },
          closeChangePasswordDialog() {
            const self: F7ComponentContextExtend = this;
            self.changePasswordDialog && self.changePasswordDialog.close();
          },
          changePassword(e: MouseEvent) {
            const self: F7ComponentContextExtend = this;
            const formData: any = {};
            const fieldEles = self.changePasswordDialog.$el.find('.field');
            fieldEles.each((index, fieldEle) => {
              console.log(fieldEle);
              console.log(fieldEle.value);
              formData[fieldEle.name] = fieldEle.value;
            });
            if (!formData.Password) {
              $this.commonService.showError('Bạn chưa nhập mật khẩu hiện tại');
              return;
            }
            if (!formData.NewPassword) {
              $this.commonService.showError('Bạn chưa nhập mật khẩu mới');
              return;
            }
            if (!formData.PinCode) {
              $this.commonService.showError('Bạn chưa nhập mã PIN');
              return;
            }
            if (formData.ReNewPassword !== formData.NewPassword) {
              $this.commonService.showError('Nhập lại mật khẩu chưa khớp');
              return;
            }
            console.log(formData);
            self.$app.preloader.show();
            try {
              $this.rootServices.apiService.putPromise('/user/users', { changePassword: true }, formData).then(rs => {
                // console.log(rs);
                self.changePasswordDialog.close();
                self.$app.preloader.hide();
                fieldEles.each((index, fieldEle) => {
                  fieldEle.value = '';
                });
                $this.commonService.showInfo('Đổi mật khẩu thành công');
              }).catch(err => {
                console.error(err);
                self.$app.preloader.hide();
                $this.commonService.showError(err);
              });
            } catch (err) {
              console.error(err);
              self.$app.preloader.hide();
              $this.commonService.showError(err);
            }
          },
          openUpdatePinCodeDialog(e: MouseEvent) {
            const self: F7ComponentContextExtend = this;
            if (self.updatePinCodeDialog) {
              self.updatePinCodeDialog.open();
              self.updatePinCodeDialog.$el.find('.field[name="PinCode"]').focus();
            }
          },
          closeUpdatePinCodeDialog() {
            const self: F7ComponentContextExtend = this;
            self.updatePinCodeDialog && self.updatePinCodeDialog.close();
          },
          updatePinCode(e: MouseEvent) {
            const self: F7ComponentContextExtend = this;
            const formData: any = {};
            const fieldEles = self.updatePinCodeDialog.$el.find('.field');
            fieldEles.each((index, fieldEle) => {
              console.log(fieldEle);
              console.log(fieldEle.value);
              formData[fieldEle.name] = fieldEle.value;
            });
            if (!formData.PinCode) {
              console.error('Bạn chưa nhập mã pin');
              $this.commonService.showError('Bạn chưa nhập mã pin');
              return;
            }
            if (!formData.Password) {
              console.error('Bạn chưa nhập mật khẩu hiện tại');
              $this.commonService.showError('Bạn chưa nhập mật khẩu hiện tại');
              return;
            }
            console.log(formData);
            self.$app.preloader.show();
            try {
              $this.rootServices.apiService.putPromise('/user/users', { updatePinCode: true }, formData).then(rs => {
                // console.log(rs);
                self.updatePinCodeDialog.close();
                self.$app.preloader.hide();
                fieldEles.each((index, fieldEle) => {
                  fieldEle.value = '';
                });
                $this.commonService.showInfo('Đã cập nhật mã PIN mới');
              }).catch(err => {
                console.error(err);
                self.$app.preloader.hide();
                $this.commonService.showError(err);
              });
            } catch (err) {
              console.error(err);
              self.$app.preloader.hide();
              $this.commonService.showError(err);
            }
          },
          async refresh(e, done) {
            const self: F7ComponentContextExtend = this;

            $this.rootServices.apiService.getPromise<AccountModel>('/user/users/getInfo', {
              includeIdentityCardInfo: true
            }).then(userInfo => {
              console.log(userInfo);
              let coreDomain = $this.rootServices.apiService.token && $this.rootServices.apiService.token.api_url;
              if (coreDomain) {
                const url = new URL(coreDomain);
                coreDomain = url.host;
              }
              // if (userInfo.Avatar) {
              if (!userInfo.Avatar) {
                userInfo.Avatar = {
                  payload: {

                  }
                } as any;
              }
              userInfo.Avatar.payload.thumbnail = $this.rootServices.apiService.getBaseApiUrl() + '/user/users/' + userInfo.Code + '/avatar';
              // }
              self.$setState({
                account: { ...userInfo, Core: coreDomain }
              });

              if (done) {
                done();
              }
            }).catch(err => {
              console.error(err);
              if (done) {
                done();
              }
            });

            return true;
          },
          logout(e: MouseEvent) {
            $this.rootServices.authService.logout();
          },
          switchAccount(e: MouseEvent) {
            $this.rootServices.authService.logout();
          },
          deleteAccount(e: MouseEvent) {
            const self: F7ComponentContextExtend = this;
            self.$app.dialog.confirm('Bạn có chắc là muốn xóa tài khoản của mình ?', 'Xóa tài khoản', () => {
              self.$app.dialog.prompt('Điền mật khẩu để xác nhận lại lần nữa, sau khi xóa tài khoản bạn không thể đăng ký lại bằng số điện thoại này trong vòng 30 ngày tiếp theo !', 'Xóa tài khoản', (password) => {
                $this.rootServices.apiService.deletePromise('/user/users', { deleteMyAccount: true, password: password }).then(rs => {
                  if (rs['message']) {
                    $this.commonService.showInfo(rs['message']);
                    $this.rootServices.authService.logout().then(status => {
                      location.reload();
                    });
                  }
                }).catch(err => {
                  $this.commonService.showError(err);
                });
              });
            });
          },
          updateAccount() {
            const self: F7ComponentContextExtend = this;
            if (!self.account.IdentityCardImage1) {
              $this.commonService.showError('Bạn chưa tải lên mặt trước CCCD');
              return false;
            }
            if (!self.account.IdentityCardImage2) {
              $this.commonService.showError('Bạn chưa tải lên mặt sau CCCD');
              return false;
            }
            const profileData: AccountModel = {
              IdentityCardImage1: self.account.IdentityCardImage1,
              IdentityCardImage2: self.account.IdentityCardImage2,
            };
            
            const fieldEles = self.$el.find('.profile-field');
            fieldEles.each((index, fieldEle) => {
              console.log(fieldEle);
              console.log(fieldEle.value);
              profileData[fieldEle.name] = fieldEle.value;
            });

            if (!profileData.IdentityCardNumber) {
              $this.commonService.showError('Bạn chưa điền số CCCD');
              return false;
            }

            console.log(profileData);
            self.$app.preloader.show();
            $this.rootServices.apiService.putPromise<AccountModel>('/user/users/updateAccount', {}, profileData).then(rs => {
              console.log(rs);
              self.$app.preloader.hide();
              $this.commonService.showInfo("Đã cập nhật thông tin tài khoản");
              $this.refresh();
            }).catch(err => {
              console.error(err);
              $this.commonService.showError(err);
              self.$app.preloader.hide();
            });
          },
          async changeAvatar(e) {
            const self: F7ComponentContextExtend = this;
            console.debug(e);

            try {
              const localFiles = await $this.rootServices.pickFiles();
              console.debug(localFiles);

              let image: FileModel = null;
              if (localFiles[0].dataType === 'url') {
                image = await $this.rootServices.uploadLocalFile(localFiles[0].url as string);
              } else {
                const formData = new FormData();
                const imgBlob = new Blob([localFiles[0].url], {
                  type: localFiles[0].type
                });
                formData.append('file', imgBlob, 'smart-bot-' + Date.now() + '.' + localFiles[0].ext);
                image = await $this.rootServices.uploadFileData(formData, null, { weight: localFiles[0].size });
              }
              console.debug(image);
              e.target.style.backgroundImage = 'url(' + image.Thumbnail + ')';

              // const updateProfileResult = await $this.rootServices.apiService.putPromise('/user/users/updateAccount', {}, { Avatar: image.GlobalId, AvatarThumbnail: image.Thumbnail })
              const updateProfileResult = await $this.rootServices.apiService.putPromise('/user/users/updateAccount', {}, { Avatar: image });
              console.debug('Update profile avatar: ', updateProfileResult);

              // Update auth user
              const oldUser = $this.rootServices.authService.user$.getValue();
              oldUser.avatar = oldUser.avatar || { id: '', payload: {} };
              oldUser.avatar = { ...oldUser.avatar, payload: { ...oldUser.avatar.payload, thumbnail: image.Thumbnail, url: image.DownloadLink } }
              $this.rootServices.authService.user$.next(oldUser);

            } catch (err) {
              console.error(err);
              $this.commonService.showError(err);
            }

          },
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          pageInit(e, page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;

            // Set back title
            self.$setState({ backTitle: self.$route.context && self.$route.context.backTitle || 'Back', title: self.$route.context && self.$route.context.title || 'Tài khoản' });

            self.updatePinCodeDialog = self.$app.dialog.create({
              el: page.$el.find('.dialog-update-pin-code'),
              closeByBackdropClick: true,
            });
            self.changePasswordDialog = self.$app.dialog.create({
              el: page.$el.find('.dialog-change-password'),
              closeByBackdropClick: true,
            });

            $this.onChangedState({ instance: self }, 'main');

            $this.refresh();
          },
          // pageBeforeIn(e, page) {
          //     console.log('page before in');
          // },
          // pageAfterIn(e, page) {
          //     console.log('page after in');
          // },
          // pageBeforeOut(e, page) {
          //     console.log('page before out');
          // },
          // pageAfterOut(e, page) {
          //     console.log('page after out');
          // },
          // pageBeforeUnmount(e, page) {
          //     console.log('page before unmount');
          // },
          // pageBeforeRemove(e, page) {
          //     console.log('page before remove');
          // },
        },
      },
    };
  }
}
