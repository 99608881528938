import { UnitConversion } from '../../model/product.model';
import { take } from 'rxjs/operators';
import { Dialog } from "framework7/build/core/components/dialog/dialog";
import { Router } from "framework7/build/core/modules/router/router";
import { ContactModel } from "../../../../src/app/model/contact.model";
import { ProductCategoryModel, ProductGroupModel, ProductModel, UnitModel } from "../../../../src/app/model/product.model";
import { SalesMasterPriceTable } from "../../../../src/app/model/sales.model";
import { CommonService } from "../../../../src/app/services/common.service";
import { RootServices } from "../../../../src/app/services/root.services";
import { F7ComponentContext, F7Page } from "../../../../src/app/types/framework7-types";
import { FileModel } from '../../../../src/app/model/file.model';
import { BaseFormComponent, FormComponentStateExtend, FormSchema } from '../../../../src/app/lib/base-form-component';
// import * as $ from 'jquery';
declare const $: any;

// declare const $: any;
export interface ComponentStateExtend extends FormComponentStateExtend<ProductModel> {
  [key: string]: any;
  instance: F7ComponentContextExtend,
  data?: ProductModel;
  form?: any;
}

export class F7ComponentContextExtend extends F7ComponentContext {
  responseInProgress?: boolean;

  detailDialog?: Dialog.Dialog;

  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, [key: string]: any } };
}

/** Component group manager */
export class AdminProductProductFormComponent extends BaseFormComponent<ComponentStateExtend, ProductModel> {

  // states: { [key: string]: State } = {};
  currentState: ComponentStateExtend;
  title: 'Sản phẩm';

  idKey = 'Code';

  apiPath = '/admin-product/products';
  schema: FormSchema = {
    Name: {
      type: 'input',
      label: 'Tên sản phẩm',
      validators: [
        'required'
      ]
    },
    WarehouseUnit: {
      type: 'smart-select',
    },
    Type: {
      type: 'smart-select',
    },
    Groups: {
      type: 'smart-select',
    },
    Categories: {
      type: 'smart-select',
    },
    Description: {
      type: 'texteditor',
    },
    Technical: {
      type: 'texteditor',
    },
    UnitConversions: {
      type: 'list',
      makeModel: () => new UnitConversion(),
      Unit: { type: 'smart-select' },
      IsDefaultSales: { type: 'toggle' },
      IsDefaultPurchase: { type: 'toggle' },
      IsManageByAccessNumber: { type: 'toggle' },
      IsAutoAdjustInventory: { type: 'toggle' },
      IsExpirationGoods: { type: 'toggle' },
    }
  };

  taxList = [
    {
      id: 'VAT10',
      text: '+ 10% VAT',
      Tax: 10,
      Type: 'NONE',
      Name: 'Chưa bao gồm VAT 10%',
      selected: false,
    },
    {
      id: 'FULLVAT10',
      text: 'Đã bao gồm VAT 10%',
      Tax: 0,
      Type: 'NONE',
      Name: 'Đã Full VAT 10%',
      selected: false,
    },
    {
      id: 'NOTAX',
      text: 'n/a',
      Tax: 0,
      Type: 'NONE',
      Name: 'Không thuế',
      selected: false,
    },
  ];

  unitList: UnitModel[] = [];
  priceTableList: SalesMasterPriceTable[] = [];

  contactList: ContactModel[] = [];

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
  ) {
    super(rootServices, commonService);
    // // console.log('Click here to open ts file');

    // this.rootServices.apiService.getPromise<UnitModel[]>('/admin-product/units', { limit: 'nolimit' }).then(unitList => {
    //   this.currentState.instance.unitList = unitList.map(m => {
    //     m.id = m.Code;
    //     m.text = m.Name;
    //     return m;
    //   })
    // });
  }

  makeModel(properties?: ProductModel): ProductModel {
    return new ProductModel();
  }

  async makeNewData() {
    return new ProductModel();
  }

  async getFormData(params?: any): Promise<ProductModel> {
    return super.getFormData({
      includeCategories: true,
      includeGroups: true,
      includePictures: true,
      includeUnitConversions: true,
      includeWarehouseUnit: true,
      ...params,
    }).then(rs => {
      if (this.currentState.instance.$route?.context?.copyFromId) {
        delete rs.Sku;
      }
      return rs;
    });
  }

  prepareData(data: ProductModel): ProductModel {
    for (const unitConversion of data.UnitConversions) {

      if (unitConversion?.Unit && typeof unitConversion?.Unit == 'string') {
        unitConversion.Unit = this.currentState.instance.unitList.find(f => f.id == unitConversion.Unit as any);
        console.log('prepare data: ', unitConversion.Unit, this.currentState.instance.unitList, data)
      }

      if (!data.Pictures) {
        data.Pictures = [];
      }
    };
    return data;
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  async onComponentInit(state: ComponentStateExtend, index: string, asCase?: string, f7Page?: F7Page) {
    const $this = this;
    // Start loading status

    this.commonService.showPreloader();
    state.instance.$setState({
      loading: true,
      schema: this.schema,
    });

    // Get static data
    await this.rootServices.apiService.getPromise<UnitModel[]>('/admin-product/units', { onlyIdText: true, limit: 'nolimit' }).then(rs => {
      state.instance.unitList = rs;
    });
    await this.rootServices.apiService.getPromise<ProductGroupModel[]>('/admin-product/groups', { onlyIdText: true, limit: 'nolimit' }).then(rs => {
      state.instance.productGroupList = rs;
    });
    await this.rootServices.apiService.getPromise<ProductCategoryModel[]>('/admin-product/categories', { onlyIdText: true, limit: 'nolimit' }).then(rs => {
      state.instance.productCategoryList = rs;
    });


    // if (state.data) {
    state.instance.$setState({
      // contactList: state.instance.contactList,
      unitList: state.instance.unitList,
      productGroupList: state.instance.productGroupList,
      productCategoryList: state.instance.productCategoryList,
    });
    // await this.setData(currentState.data);
    // }

    const currentState: ComponentStateExtend = await super.onComponentInit(state, index, asCase) as any;

    if (currentState.instance.$route?.context?.copyFromId) {
      currentState.instance.data.Sku = '';
      this.setData(currentState.instance.data);
    }
    // End

    // set data

    // Stop loading status
    currentState.instance.$setState({ loading: false });
    this.commonService.hidePreloader();
    return currentState;
  }

  async setData(data: ProductModel, option?: { onlyList?: string[], onlyKeys?: string[], prepareControl?: boolean }) {
    // data.Name = data.Name.replace('"', '"');
    return super.setData(data, option);
  }

  async addItemForList<PurchaseOrderDetailModel>(listName: string, item?: PurchaseOrderDetailModel): Promise<PurchaseOrderDetailModel> {
    return super.addItemForList(listName, item).then(newItem => {
      // this.commonService.showInfo('Đã thêm đơn vị tính chuyển đổi');
      return newItem;
    });
  }

  async uploadImages(state: (progress) => void, onAfterImageUpload?: (image: FileModel) => void) {

    // let proloaderProcessing;
    try {
      const localFiles = await this.rootServices.pickFiles();
      console.debug(localFiles);

      const uploadProgressBar = $(this.currentState.instance.el).find('.uploadProgressBar');

      // this.commonService.showPreloader();
      // proloaderProcessing = setTimeout(() => {
      //   this.commonService.hidePreloader();
      // }, 16000);
      this.currentState.instance.$setState({ uploading: true });

      let images: FileModel[] = [];
      for (const i in localFiles) {
        // let image: FileModel = null;
        images[i] = await ((progress) => {
          if (localFiles[i].dataType === 'url') {
            return this.rootServices.uploadLocalFile(localFiles[i].url as string, (event) => {
              progress(event)
            });
          } else {
            const formData = new FormData();
            const imgBlob = new Blob([localFiles[i].url], {
              type: localFiles[i].type
            });
            formData.append('file', imgBlob, 'smart-bot-' + Date.now() + '.' + localFiles[i].ext);
            return this.rootServices.uploadFileData(formData, (event) => {
              progress(event)
            });
          }
        })((progress) => {
          console.log(progress);
          this.currentState.instance.$app.progressbar.set(uploadProgressBar[0] as any, parseInt(progress.loaded / progress.total as any) * 100);
          state(progress);
        });
        onAfterImageUpload && onAfterImageUpload(images[i]);
      }
      console.debug(images);
      // clearTimeout(proloaderProcessing);
      // this.commonService.hidePreloader();
      this.currentState.instance.$setState({ uploading: false });
      return images;
    } catch (err) {
      console.error(err);
      this.commonService.showError(err);
      // clearTimeout(proloaderProcessing);
      // this.commonService.hidePreloader();
      this.currentState.instance.$setState({ uploading: false });
      throw new Error(err);
    }
  }

  async onFieldChange(self: F7ComponentContext, e: any, ...args: any) {
    const currentState = this.currentState;
    return super.onFieldChange(self, e, args).then(fieldInfo => {


      let { field, fieldName, fieldValue, index, listName } = fieldInfo;
      if (fieldName == 'WarehouseUnit') {
        currentState.data[fieldName] = this.currentState.instance.unitList.find(f => f.id == fieldValue);
        if (!currentState.data.UnitConversions[0]) {
          currentState.data.UnitConversions[0] = new UnitConversion();
        }
        currentState.data.UnitConversions[0].Unit = currentState.data[fieldName];
        currentState.data.UnitConversions[0].id = this.commonService.getObjectId(currentState.data[fieldName]);
        currentState.data.UnitConversions[0].text = this.commonService.getObjectText(currentState.data[fieldName]);

        currentState.instance.$setState({ data: currentState.data });
        this.setFieldValue('smart-select', 'Unit', fieldValue, 'UnitConversions', 0);
        // this.setData(currentState.data);
      }
      if (listName == 'UnitConversions' && fieldName == 'Unit') {
        currentState.data[listName][index][fieldName] = this.currentState.instance.unitList.find(f => f.id == fieldValue);
        currentState.instance.$setState({ data: currentState.data });

      }
      currentState.instance.$setState({ total: 0 });
      return fieldInfo;
    });
  }

  async removeDetailItem(self: F7ComponentContext, e: any, ...args: any): Promise<{ detail?: any, listName?: string; index?: string | number; }> {
    return super.removeDetailItem(self, e, args).then(detailInfo => {

      let { detail, listName, index } = detailInfo;
      if (listName == 'UnitConversions') {
        self.$app.accordion.close(detail[0]);
      }

      return detailInfo;
    });
  }

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: 'admin-product-product-form',
      path: '/admin-product/product/:id',
      component: {
        template: /*html*/`
            <div class="page product-form no-toolbar-x" data-name="task-reminder">
              <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a class="link back {{textColorClass}}">
                      <i class="icon icon-back"></i>
                      <span class="if-not-md {{textColorClass}}">{{backTitle}}</span>
                    </a>
                  </div>
                  <div class="title">Sản phẩm</div>
                  <div class="right">
                    <a class="link icon-only {{textColorClass}}">
                      <i class="icon f7-icons">arrowshape_turn_up_right</i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="page-content ptr-content infinite-scroll-content" @ptr:refresh="refresh" data-ptr-distance="150">
                <div class="ptr-preloader">
                  <div class="preloader"></div>
                  <div class="ptr-arrow"></div>
                </div>
                <div class="block-title">Hình ảnh</div>
                <div class="block">
                  <div class="card demo-card-header-pic" style="margin: 0;">
                    <div @click="previewPictures" data-id="{{js "this.data && this.data.FeaturePicture && this.data.FeaturePicture.Id || ''"}}" style="{{js "this.data.FeaturePicture && this.data.FeaturePicture.LargeImage && ('background-image:url(' + this.data.FeaturePicture.LargeImage + ')') || ''" }}"
                      class="card-header background align-items-flex-end">Hình đại diện</div>
                    <div class="progressbar color-red uploadProgressBar" data-progress="0"></div>
                    <div class="card-footer">
                      <a href="#" class="link" @click="uploadPicture">Upload</a>
                      <a href="#" class="link">Preview</a>
                    </div>
                  </div>
                </div>
                <div class="blockx">
                  <div class="pictures" style="overflow-x: auto">
                    <div class="product-pictures list-name" style="display: flex; flex-direction: row; padding-left: 0.5rem;" name="Pictures">
                      {{#each data.Pictures}}
                      <div class="picture list-item" style="padding: 0.5rem" data-index="{{@index}}">
                        <div class="card demo-card-header-pic" style="margin: 0">
                          <div @click="previewPictures" data-index="{{@index}}" style="width: 100%; height: 67px; {{js "this.LargeImage && ('background-image:url(' + this.LargeImage + ')') || ''" }}" class="card-header background align-items-flex-end"></div>
                          <div class="card-footer">
                            <a href="#" class="link" @click="removeDetailItem"><i class="icon f7-icons color-red">minus_circle_fill</i></a>
                            &nbsp|&nbsp
                            <a href="#" class="link" @click="setFeaturePicture" data-index="{{@index}}"><i class="icon f7-icons color-green">checkmark_alt_circle_fill</i></a>
                          </div>
                        </div>
                      </div>
                      {{/each}}
                    </div>
                  </div>
                </div>

                <div class="block-title">Thông tin chính</div>
                <form class="data-form">
                  <div class="list media-list profile-form no-hairlines-md inset main-form">
                    <ul>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label">ID Sản phẩm (*)</div>
                          <div class="item-input-wrap">
                            <input @change="onFieldChange" disabled class="field text-color-blue" name="Code" type="text" placeholder="ID sản phẩm, auto..."
                              value="{{data.Code}}">
                          </div>
                        </div>
                      </li>
                      {{#if copyFromId}}
                      <li class="item-content">
                        <div class="item-inner">
                          <div class="item-input-wrap text-color-orange">
                            <span style="font-weight: bold; font-style: italic">Bạn đang thao tác trên sản phẩm copy</span>
                          </div>
                        </div>
                      </li>
                      {{/if}}
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label {{validate data.Name schema 'Name'}}">Tên sản phẩm (*)</div>
                          <div class="item-input-wrap">
                            <input @keyup="onFieldChange" class="field text-color-blue" name="Name" type="text" placeholder="Tên sản phẩm">
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label">Sku (*)</div>
                          <div class="item-input-wrap">
                            <input @keyup="onFieldChange" class="field text-color-blue" name="Sku" type="text" placeholder="Sku, auto...">
                          </div>
                        </div>
                      </li>
                      <li class="item-link smart-select smart-select-init" name="WarehouseUnit" data-open-in="popup" data-searchbar="true" data-virtual-list="true" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFieldChange">
                        <select>
                          <option value="">Chon...</option>
                          {{#each unitList}}
                          <option value="{{id}}">{{text}}</option>
                          {{/each}}
                        </select>
                        <div class="item-content">
                          <div class="item-inner" style="display: flex;">
                            <div class="item-title">Đơn vị tính (*)</div>
                            <div class="item-after text-color-blue"></div>
                          </div>
                        </div>
                      </li>
                      <li class="item-link smart-select smart-select-init" name="Type" data-open-in="popup" data-searchbar="true" data-virtual-list="true" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFieldChange">
                        <select name="Type">
                          <option value="">Chon...</option>
                          <option value="PRODUCT">Hàng hóa</option>
                          <option value="SERVICE">Dịch vụ</option>
                        </select>
                        <div class="item-content">
                          <div class="item-inner" style="display: flex;">
                            <div class="item-title">Loại (*)</div>
                            <div class="item-after text-color-blue"></div>
                          </div>
                        </div>
                      </li> 
                      <li class="item-link smart-select smart-select-init" name="Categories" data-open-in="popup" data-searchbar="true" data-virtual-list="true" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFieldChange">
                        <select multiple>
                          <option value="">Chon...</option>
                          {{#each productCategoryList}}
                          <option value="{{id}}">{{text}}</option>
                          {{/each}}
                        </select>
                        <div class="item-content">
                          <div class="item-inner" style="display: flex;">
                            <div class="item-title">Danh mục</div>
                            <div class="item-after text-color-blue"></div>
                          </div>
                        </div>
                      </li> 
                      <li class="item-link smart-select smart-select-init" name="Groups" data-open-in="popup" data-searchbar="true" data-virtual-list="true" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFieldChange">
                        <select name="Groups" multiple>
                          <option value="">Chon...</option>
                          {{#each productGroupList}}
                          <option value="{{id}}">{{text}}</option>
                          {{/each}}
                        </select>
                        <div class="item-content">
                          <div class="item-inner" style="display: flex;">
                            <div class="item-title">Nhóm</div>
                            <div class="item-after text-color-blue"></div>
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label">Thuế VAT (%)</div>
                          <div class="item-input-wrap" style="display: flex; flex-direction: row; align-items: center;">
                            <input @change="onFieldChange" class="field" name="VatTax" type="text" placeholder="Thuế VAT...."
                              value="{{data.VatTax}}">
                            <label class="toggle toggle-init color-orange" @toggle:change="onFieldChange" name="RequireVatTax">
                              <input name="RequireVatTax" type="checkbox" {{js "this.data && this.data.RequireVatTax && 'checked' || ''"}}/>
                              <span class="toggle-icon"></span>
                            </label>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
              
                  <div class="block-title" style="margin-top: 0.5rem">Đơn vị tính chuyển đổi<a @click="addUnitConversion" data-list-name="UnitConversions" class="button button-small button-fill" style="float: right; font-size: 0.7rem; font-weight: bold">Thêm</a></div>
                  <div class="list accordion-list inset no-hairlines list-name" name="UnitConversions">
                    <ul>
                    {{#each data.UnitConversions}}
                      <li class="accordion-item list-item index-{{@index}}" data-id="Unit.id" data-index="{{@index}}">
                        <a class="item-content item-link">
                          <div class="item-media" @click="removeDetailItem"><i class="icon f7-icons color-red">minus_circle_fill</i></div>
                          <div class="item-inner">
                            <div class="item-title" style="font-weight: bold">{{text Unit coalesce="Chọn..."}}</div>
                            <div class="item-after">
                              <span class="badge color-yellow">{{ConversionRatio}}</span>
                              &nbsp<span class="badge {{js "this.IsDefaultSales && 'color-green' || ''"}}">B</span>
                              &nbsp<span class="badge {{js "this.IsDefaultPurchase && 'color-blue' || ''"}}">M</span>
                              &nbsp<span class="badge {{js "this.IsManageByAccessNumber && 'color-red' || ''"}}">T</span>
                              &nbsp<span class="badge {{js "this.IsAutoAdjustInventory && 'color-orange' || ''"}}">K</span>
                              &nbsp<span class="badge {{js "this.IsExpirationGoods && 'color-pink' || ''"}}">H</span>
                            </div>
                          </div>
                        </a>
                        <div class="accordion-item-content">
                            <div class="list media-list inline-labels profile-form">
                              <ul>
                                <li class="item-link smart-select smart-select-init" name="Unit" data-open-in="popup" data-searchbar="true" data-virtual-list="true" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFieldChange" data-list-name="UnitConversions" data-index="{{@index}}">
                                  <select>
                                    <option value="">Chon...</option>
                                    {{#each ../unitList}}
                                    <option value="{{id}}">{{text}}</option>
                                    {{/each}}
                                  </select>
                                  <div class="item-content">
                                    <div class="item-inner" style="display: flex;">
                                      <div class="item-title">Đơn vị tính (*)</div>
                                      <div class="item-after text-color-red"></div>
                                    </div>
                                  </div>
                                </li>
                                <li class="item-content item-input">
                                  <div class="item-inner">
                                    <div class="item-title item-label">Tỷ lệ</div>
                                    <div class="item-input-wrap">
                                      <input type="text" class="field text-color-blue" name="ConversionRatio" @change="onFieldChange" placeholder="Tỷ lệ chuyển đổi"
                                        value="{{ConversionRatio}}" style="text-align: right" inputmode="decimal">
                                    </div>
                                  </div>
                                </li>
                                <li class="item-content item-input">
                                  <div class="item-inner">
                                    <div class="item-title item-label">Mặc định bán</div>
                                    <div class="item-input-wrap" style="text-align: right;">
                                      <label class="toggle toggle-init color-green" @toggle:change="onFieldChange" name="IsDefaultSales">
                                        <input type="checkbox"/>
                                        <span class="toggle-icon"></span>
                                      </label>
                                    </div>
                                  </div>
                                </li>
                                <li class="item-content item-input">
                                  <div class="item-inner">
                                    <div class="item-title item-label">Mặc định mua</div>
                                    <div class="item-input-wrap" style="text-align: right;">
                                      <label class="toggle toggle-init color-blue" @toggle:change="onFieldChange" name="IsDefaultPurchase">
                                        <input type="checkbox"/>
                                        <span class="toggle-icon"></span>
                                      </label>
                                    </div>
                                  </div>
                                </li>
                                <li class="item-content item-input">
                                  <div class="item-inner">
                                    <div class="item-title item-label">Truy xuất</div>
                                    <div class="item-input-wrap" style="text-align: right;">
                                      <label class="toggle toggle-init color-red" @toggle:change="onFieldChange" name="IsManageByAccessNumber">
                                        <input type="checkbox"/>
                                        <span class="toggle-icon"></span>
                                      </label>
                                    </div>
                                  </div>
                                </li>
                                <li class="item-content item-input">
                                  <div class="item-inner">
                                    <div class="item-title item-label">Trừ kho</div>
                                    <div class="item-input-wrap" style="text-align: right;">
                                      <label class="toggle toggle-init color-orange" @toggle:change="onFieldChange" name="IsAutoAdjustInventory">
                                        <input type="checkbox"/>
                                        <span class="toggle-icon"></span>
                                      </label>
                                    </div>
                                  </div>
                                </li>
                                <li class="item-content item-input">
                                  <div class="item-inner">
                                    <div class="item-title item-label">Có HSD</div>
                                    <div class="item-input-wrap" style="text-align: right;">
                                      <label class="toggle toggle-init color-pink" @toggle:change="onFieldChange" name="IsExpirationGoods">
                                        <input type="checkbox"/>
                                        <span class="toggle-icon"></span>
                                      </label>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                          </div>
                        </div>
                      </li>
                      {{/each}}
                    </ul>
                  </div>

                  <div class="block-title">Mô tả</div>
                  <div class="list profile-form no-hairlines-md main-form">
                    <div class="text-editor text-editor-init Description" name="Description" data-placeholder="Mô tả sản phẩm..." @texteditor:change="onFieldChange">
                      <div class="text-editor-content" contenteditable></div>
                    </div>
                  </div>
                  <div class="block-title">Thông số kỹ thuật</div>
                    <div class="list profile-form no-hairlines-md main-form">
                      <div class="text-editor text-editor-init Technical" name="Technical" data-placeholder="Thông số kỹ thuật sản phẩm..." @texteditor:change="onFieldChange">
                      <div class="text-editor-content" contenteditable></div>
                    </div>
                  </div>
              
                  <div class="block">
                    <p class="row">
                      <a class="col button button-fill button-large color-green {{#if validates}}disabled{{/if}}" @click="save">Lưu lại</a>
                    </p>
                  </div>

                  {{#if validates}}
                  <div class="block-title text-color-arange">Cảnh báo</div>
                  <div class="list inset">
                    <ul>
                      {{#each validates}}
                      <li>
                        <div class="item-content">
                          <div class="item-inner">
                            <div class="item-title">{{validates[0].label}}</div>
                            <div class="item-after text-color-orange">{{validates[0].text}}</div>
                          </div>
                        </div>
                      </li>
                      {{/each}}
                    </ul>
                  </div>
                  {{/if}}
                </form>
            
                <div class="block"><br></div>
              </div>
            </div>
        `,
        style: /*css*/`
          .page.product-form .demo-card-header-pic .card-header {
            height: calc(100vw - 2rem);
            background-size: cover;
            background-position: center;
            color: #fff;
          }

          .page.product-form .demo-card-header-pic .card-content-padding .date {
            color: #8e8e93;
          }
          .page.product-form .pictures::-webkit-scrollbar {
            display: none;
          }

          .page.product-form .card .background {
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-image: url(/assets/images/no-image-available.png);
        }
        `,
        data() {
          return {
            title: $this.title,
            reminder: {
              No: '234234',
            },
            data: new ProductModel(),
            taxList: $this.taxList,
            contactMemebets: [],
            validates: null,
          };
        },
        methods: {
          previewPictures(e) {
            const self: F7ComponentContextExtend = this;
            const currentState = $this.currentState;
            let index = $(e.target).data('index');
            if (typeof index == 'undefined') {
              const id = $(e.target).attr('data-id');
              index = currentState.data.Pictures.findIndex(f => f.Id == id);
            }
            $this.previewPictures(currentState.data.Pictures, index || 0);
          },
          setFeaturePicture(e) {
            const self: F7ComponentContextExtend = this;
            const currentState = $this.currentState;
            let index = $(e.target).closest('a').data('index');
            if (typeof index != 'undefined') {
              self.$app.dialog.confirm('Bạn có muốn đặt làm hình đại diện không ?', 'Đặt hình đại diện', () => {
                currentState.data.FeaturePicture = currentState.data.Pictures[index];
                self.$setState({ data: currentState.data });
              })
            }
          },
          uploadPicture(e) {
            const self: F7ComponentContextExtend = this;
            const currentState = $this.currentState;
            $this.uploadImages((progress) => {
              console.log(progress);
            }, (image) => {
              currentState.data.Pictures.unshift(image);
              currentState.data.FeaturePicture = image;
              self.$setState({ data: currentState.data });

            }).then(images => {
              console.log('upload pictures success', images);
            });
          },
          addUnitConversion(e) {
            const self: F7ComponentContextExtend = this;
            const currentState = $this.currentState;

            let listName = $(e.target).data('list-name');
            $this.addItemForList(listName);

          },
          removeDetailItem(e) {
            return $this.removeDetailItem(this, e);
          },
          onFieldChange(e) {
            $this.onFieldChange(this, e);
          },
          async refresh(e, done) {
            const self: F7ComponentContextExtend = this;
            let doneTimeout = null;
            if (done) {
              doneTimeout = setTimeout(() => {
                done();
              }, 10000);
            };

            try {
              $this.getFormData().then(data => {
                $this.setData(data);
                done && done();
              });
            } catch (err) {
              console.error(err);
              done && done();
            }
            return true;
          },
          save() {
            const self: F7ComponentContextExtend = this;

            $this.commonService.showPreloader();
            const preloaderProcess = setTimeout(() => {
              $this.commonService.hidePreloader();
            }, 15000);

            $this.save(self, { updateProperties: ['Sku'] }).then(rs => {
              $this.commonService.showInfo("Đã lưu thông tin sản phẩm", {position: 'bottom'});
              clearTimeout(preloaderProcess);
              $this.commonService.hidePreloader();
            }).catch(err => {
              clearTimeout(preloaderProcess);
              $this.commonService.hidePreloader();
              console.error(err);
            });
          },
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          pageInit(e, page: F7Page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;

            $this.onComponentInit({ instance: self, page: page }, self.$route.params['id'], null, page).then(curentState => {
              // self.detailDialog = self.$app.dialog.create({
              //   el: page.$el.find('.dialog-detail'),
              //   closeByBackdropClick: true,
              // });
            });

            $this.rootServices.authService.isAuthenticatedOrRefresh().pipe(take(1)).toPromise().then(() => {
              self.$setState({
                backTitle: self.$route.context && self.$route.context.backTitle || 'Back', title: self.$route.context && self.$route.context.title || 'Chat GUI',
              });
            }).catch(err => {
              console.error(err);
              $this.commonService.showError(err);
            });
          },
          // pageBeforeIn(e, page) {
          //     console.log('page before in');
          //     const self: F7ComponentContextExtend = this;
          //     self.refresh();
          // },
          // pageAfterIn(e, page) {
          //     console.log('page after in');
          // },
          pageBeforeOut(e, page) {
            console.log('page before out');
            // Call caback function
            if (this.$route?.context['callback']) {
              this.$route?.context['callback']($this.currentState.data, { lastAction: $this.currentState.lastAction });
            }
          },
          // pageAfterOut(e, page) {
          //     console.log('page after out');
          // },
          // pageBeforeUnmount(e, page) {
          //     console.log('page before unmount');
          // },
          // pageBeforeRemove(e, page) {
          //     console.log('page before remove');
          // },
          pageBeforeRemove(e, page) {
            console.log('[page event] before remove', page.route.url);
            const self: F7ComponentContextExtend = this;
            // const starmtSelectRoles = self.$app.smartSelect.get('.smart-select');
            // starmtSelectRoles.destroy();
            const currentState = $this.state[self.$route.params.id];
            if (self.$route.params.id === 'new') {
              if (currentState && currentState.data && currentState.data.Code) {
                delete currentState.data;
              }
            }
          },
        },
      },
    };
  }
}
