import { Model } from './model';

export class FileModel extends Model {
  Id?: number & string;
  Name?: string;
  Description?: string;
  Extension?: string;
  Revision?: number;
  Created?: string;
  Updated?: string;
  ClassName?: string;
  Protected?: string;
  Owner?: string;
  Store?: string & FileStoreModel;
  Thumbnail?: string;
  SmallImage?: string;
  LargeImage?: string;
  OriginImage?: string;
  DownloadLink?: string;
  Type?: string;
  Data?: string;
  MimeType?: string;

  get GlobalId() {
    return `${this.Store}/${this.Id}.${this.Extension}`;
  }

  constructor(properties?: any) { super(properties); }

  public toString = (): string => {
    return this.SmallImage;
  }
}

export class FileStoreModel extends Model {
  Id?: string;
  Code?: string;
  Name?: string;
  Protocol?: string;
  Host?: string;
  Port?: number;
  Path?: string;
  requestCookieUrl?: string;
  UploadToken?: string;

  constructor(properties?: any) { super(properties); }

}

export interface ImageModel {
  Id?: string;
  Thumbnail?: string;
  SmallImage?: string;
  LargeImage?: string;
  OriginImage?: string;
  ClassName?: string;
  Created?: string;
  Description?: string;
  DownloadLink?: string;
  Extension?: string;
  Filename?: string;
  Name?: string;
  Owner?: string;
  Protected?: string;
  Revision?: string;
  Store?: string;
  Type?: string;
  Updated?: string;
}