import { F7ComponentContext } from '../types/framework7-types';
import { Messages } from 'framework7/build/core/components/messages/messages';
import { F7Messagebar } from '../types/framework7-types';
import { Router } from 'framework7/build/core/modules/router/router';
import { filter, take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
import { isPlatform } from '@ionic/angular';
import { ComponentState, BaseComponent } from 'vendors/smart-bot-app/src/app/lib/base-component';
import { NotificationModel } from 'vendors/smart-bot-app/src/app/model/notification.model';
import { CommonService } from 'vendors/smart-bot-app/src/app/services/common.service';
import { RootServices } from 'vendors/smart-bot-app/src/app/services/root.services';

export interface ComponentStateExtend extends ComponentState {
  [key: string]: any;
}

export class F7ComponentContextExtend extends F7ComponentContext {
  messagebar?: F7Messagebar;
  messages?: Messages.Messages;
  images?: string[];
  responseInProgress?: boolean;
  answers?: string[];
  people?: { name?: string, avatar?: string }[];
  hideToolbar?: () => void;
  sheetToggle?: () => void;
  deleteAttachment?: (e: MouseEvent, index: number) => void;
  handleAttachment?: (e: MouseEvent) => void;
  checkAttachments?: () => void;
  sendMessage?: () => void;
  $root: any;
  notifications?: NotificationModel[];
  // [key: string]: any;
}

/** Component group manager */
export class HomeComponent extends BaseComponent<ComponentStateExtend> {
  // states: { [key: string]: State } = {};

  title = 'Thông báo';
  newNotification$ = new BehaviorSubject<NotificationModel>(null);

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
  ) {
    super(rootServices);
    // console.log('Click here to open ts file');
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: 'home',
      path: '/home',
      component: {
        template: /*html*/`
            <div class="page home" data-name="home">
              <div class="navbar"> 
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a class="link icon-only searchbar-enable text-color-default" data-searchbar="{{js "this.extendComponentId && ('.' + this.extendComponentId+' ') || ''"}}.workplace-searchbar">
                      <i class="icon f7-icons if-not-md">search</i>
                      <i class="icon material-icons md-only">search</i>
                    </a>
                  </div>
                  <div class="title">Thông báo</div>
                  <div class="right">
                    <a class="link icon-only text-color-default">
                      <i class="icon f7-icons">qrcode_viewfinder</i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="page-content ptr-content infinite-scroll-content" data-ptr-mousewheel="false" @ptr:refresh="refresh">
                <div class="ptr-preloader">
                  <div class="preloader"></div>
                  <div class="ptr-arrow"></div>
                </div>
                <div class="searchbar-backdrop workplace-searchbar-backdrop"></div>
                <div class="list media-list inset">
                  <ul>
                    <li class="item-link" @click="gotoAccount">
                      <div class="item-content">
                        <div class="item-media">
                          <div
                            style="width: 44px; height: 44px; border-radius: 50%; background-image: url({{js "this.profile && this.profile.Avatar && this.profile.Avatar.payload && this.profile.Avatar.payload.thumbnail"}}); background-repeat: no-repeat; background-position: center; background-size: cover;">
                          </div>
                        </div>
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title">{{js "this.profile && this.profile.Name"}}</div>
                          </div>
                          <div class="item-subtitle">@{{js "this.profile && this.profile.TagName && this.profile.TagName.toLowerCase()"}}
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
            
                {{#if notifications}}
                <!--<div class="block-title">Thông báo mới
                  <div class="chip link" style="float: right" @click="clearNotifications">
                    <div class="chip-label">Dọn dẹp</div><a href="#" class="chip-delete"></a>
                  </div>
                </div>-->
                {{/if}}
                <div class="notification-list list">
                  <ul style="background: inherit">
                    {{#each notifications}}
                    <li class="swipeout sw-notification" data-id="{{Id}}">
                      <div class="notification home-notification modal-in item-link">
                        <div class="swipeout-content" @click="openNotification">
                          <div class="notification-header">
                            <!--<div class="notification-icon"><div class="notification-icon" style="background-image: url({{SenderAvatarThumbnail}})"></div></div>-->
                            <div class="notification-title">{{SenderName}}</div>
            
                            <span class="notification-title-right-text"><span class="badge {{StateColor}}">{{StateText}}</span>
                          </div>
                          <div class="notification-content" style="display: flex;">
                            <div class="avatar">
                                <div style="background-image: url({{SenderAvatarThumbnail}});"></div>
                            </div>
                            <div style="flex: 1; margin-left: 0.5rem">
                              <div class="notification-text notification-content">{{PlanContent}}</div>
                              <div class="notification-text notification-title">{{Title}}</div>
                              <div class="notification-text notification-moment">{{DateOfCreateMoment}}</div>
                            </div>
                          </div>
                        </div>
                        <!--<div class="swipeout-actions-right">
                          <a href="#" @click="removeNotification" class="color-red swipeout-overswipe">Delete</a>
                        </div>-->
                      </div>
                    </li>
                    {{/each}}
                  </ul>
                </div>
                {{#if infiniteLoadLast}}
                    <div style="text-align: center" class="text-color-gray">end</div>
                {{else}}
                    <div class="preloader color-gray infinite-scroll-preloader">
                        <span class="preloader-inner">
                            <span class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span>
                        </span>
                    </div>
                {{/if}}
              </div>
            </div>
        `,
        style: /*css*/`
         .notification-icon {
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: 50%;
        }
        .page.home .notification-content {
          
        }
        .page.home .notification-title {
          opacity: 0.6;
        }
        .page.home .notification-moment {
          opacity: 0.5;
          font-size: 11px;
        }
        .page.home .notification .avatar {
          width: 3.8rem; 
          height: 3.8rem; 
          overflow: hidden; 
          border-radius: 50%; 
          padding-right: 0.5rem; 
          background-image: url(assets/icon/user-icon.png); 
          background-repeat: no-repeat; 
          background-size: cover
        }
        .page.home .notification .avatar div {
          width: 3.8rem; 
          height: 3.8rem; 
          background-repeat: no-repeat; 
          background-size: cover
        }
        `,
        data() {
          const self: F7ComponentContextExtend = this;
          return {
            title: $this.title,
            // notifications: self.$root.notifications,
            disabledLeftPannel: false //isPlatform('desktop'),
          };
        },
        methods: {
          async refresh(e, done) {
            console.debug('refresh home');

            let doneTimeout = null;
            if (done) {
              doneTimeout = setTimeout(() => {
                done();
              }, 10000);
            };

            const self: F7ComponentContextExtend = this;
            // if ($this.rootServices.authService.loginState$) {
            $this.rootServices.authService.loginState$.pipe(filter(state => state === true), take(1)).toPromise().then(status => {
              $this.rootServices.apiService.getPromise<NotificationModel[]>('/notification/notifications/byCurrentUser', {
                limit: 10,
                sort_Id: 'desc',
              }).then(notifications => {
                self.$setState({
                  notifications: notifications.map(m => {
                    return $this.preapreTaskState(m);
                    // m.DateOfCreate = moment(m.DateOfCreate).fromNow();
                    // m.Content = $this.commonService.cleanMentionTags(m.Content);
                    // return m;
                  }),
                  infiniteLoadLast: notifications.length === 0
                });
                self.updateBadge();
                self.infiniteOffset = 10;
                if (doneTimeout) clearTimeout(doneTimeout);
                if (done) done();
              }).catch(err => {
                console.error(err);
                // $this.commonService.showError(err);
                if (done) done();
                if (doneTimeout) clearTimeout(doneTimeout);
              });
            });
            // }
            return true;
          },
          onNotificationClick() {
            const self: F7ComponentContextExtend = this;
            const router: Router.Router = this.$f7router;
            router.navigate('/chat-room/3214234', { context: { backTitle: self.title } });
          },
          gotoAccount() {
            const self: F7ComponentContextExtend = this;
            $this.rootServices.navigate('/bio-page/default', { context: { backTitle: 'Home' } });
          },
          removeNotification(e) {
            const self: F7ComponentContextExtend = this;
            const currentSwipeout: any = self.$(e.target).closest('li');
            const notificationId = currentSwipeout.data('id') || self.$(e.target).data('id');

            $this.rootServices.apiService.deletePromise('/notification/notifications/' + notificationId, { byCurrentUser: true }).then(rs => {
              console.debug(rs);
              self.$app.swipeout.delete(currentSwipeout[0], () => {
              });
              $this.rootServices.homeBadge--;
            }).catch(err => {
              console.error(err);
              self.$app.swipeout.close(currentSwipeout[0]);
              $this.commonService.showError(err);
            });

          },
          openNotification(e) {
            const self: F7ComponentContextExtend = this;
            const el = self.$(e.target).closest('li');
            const notificationId = el.data('id');
            if (self.notifications) {
              const notification = self.notifications.find(f => f.Id === notificationId);
              if (notification) {
                switch (notification.Type) {
                  case 'CHATROOM':
                    // self.$app.swipeout.delete(el[0] as HTMLElement, () => {
                    $this.rootServices.navigate('/chat-room/' + notification.Data.room);
                    // });
                    $this.rootServices.apiService.putPromise('/notification/notifications/updateReceiverState', { state: 'ACTIVE' }, [{ Id: notification.Id }]).then(rs => {
                      console.debug(rs);
                      // $this.rootServices.homeBadge--;
                      self.updateBadge();
                    }).catch(err => {
                      console.error(err);
                      $this.commonService.showError(err);
                    });
                    // $this.rootServices.apiService.deletePromise('/notification/notifications/' + notificationId, { byCurrentUser: true }).then(rs => {
                    //   console.debug(rs);
                    //   // $this.rootServices.homeBadge--;
                    //   self.updateBadge();
                    // }).catch(err => {
                    //   console.error(err);
                    //   $this.commonService.showError(err);
                    // });
                    break;
                  case 'ACTIVITY':
                    if (notification?.Data?.Action == 'NAVIGATE') {
                      $this.rootServices.navigate(notification?.Data?.Path);
                    }
                    $this.rootServices.apiService.putPromise('/notification/notifications/updateReceiverState', { state: 'ACTIVE' }, [{ Id: notification.Id }]).then(rs => {
                      console.debug(rs);
                      self.updateBadge();
                    }).catch(err => {
                      console.error(err);
                      $this.commonService.showError(err);
                    });
                    break;
                }
              }
            }
          },
          clearNotifications(e) {
            const self: F7ComponentContextExtend = this;
            const confirmRemove = self.$app.actions.create({
              buttons: [
                [
                  {
                    text: 'Tất cả thông báo sẽ được lưu trữ trong vòng 7 ngày !',
                    label: true,
                  },
                  {
                    text: 'Lưu trữ tất cả',
                    color: 'red',
                    icon: '<i class="icon f7-icons">text_badge_minus</i>',
                    bold: true,
                    async onClick() {
                      while (true) {
                        const buffer = self.notifications.splice(0, 50).map(notf => notf.Id);
                        // const buffer = notificationIds;
                        if (buffer && buffer.length > 0) {
                          $this.rootServices.apiService.deletePromise('/notification/notifications', { id: buffer, byCurrentUser: true }).then(rs => {
                            self.$setState({
                              notifications: self.notifications,
                            });
                          }).catch(err => {
                            console.error(err);
                            $this.commonService.showError(err);
                          });
                        } else {
                          break;
                        }
                      }

                      // for (const notification of self.notifications) {
                      //   const currentSwipeout = self.$('.sw-notification[data-id="' + notification.Id + '"]');
                      //   if (currentSwipeout.length === 0) {
                      //     continue;
                      //   }
                      //   await new Promise(resolve => {
                      //     $this.rootServices.apiService.deletePromise('/notification/notifications/' + notification.Id, { byCurrentUser: true }).then(rs => {
                      //       console.debug(rs);
                      //       self.$app.swipeout.delete(currentSwipeout[0] as HTMLElement, () => {
                      //         resolve(true);
                      //       });
                      //     }).catch(err => {
                      //       console.error(err);
                      //       self.$app.swipeout.close(currentSwipeout[0] as HTMLElement);
                      //       $this.commonService.showError(err);
                      //       resolve(false);
                      //     });
                      //   });
                      // }
                      // self.notifications = [];
                      self.refresh();
                      // self.$setState({
                      //   notifications: self.notifications,
                      // });
                      // $this.rootServices.homeBadge = 0;

                    }
                  }
                ],
                [{
                  text: 'Trở về',
                  icon: '<i class="icon f7-icons">arrow_down_circle_fill</i>',
                  bold: true,
                  onClick() {
                  }
                }],
              ] as any
            });
            confirmRemove.open();

          },
          updateBadge(e) {
            const self: F7ComponentContextExtend = this;
            setTimeout(() => {
              if (self.notifications) {
                $this.rootServices.homeBadge = self.notifications.length;
                $this.rootServices.badge.set($this.rootServices.homeBadge);
              }
            }, 300);
          },
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          async pageInit(e, page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;
            $this.onChangedState({ instance: self }, 'main');

            $this.rootServices.authService.user$.pipe(filter(profile => !!profile)).subscribe(async profile => {
              // profile.avatar.payload.thumbnail += ('?token=' + $this.rootServices.apiService.token.access_token); 
              // profile.Role = 'Member';
              let coreDomain = await $this.rootServices.authService.token$.pipe(filter(token => !!token), take(1)).toPromise().then(token => token.getPayload().api_url);
              if (coreDomain) {
                const url = new URL(coreDomain);
                coreDomain = url.host;
              }
              self.$setState({
                profile: {
                  Code: profile.id,
                  Name: profile.name,
                  TagName: profile.tagName,
                  ShortName: profile.shortName,
                  Avatar: profile.avatar && {
                    id: profile.avatar.id,
                    payload: {
                      thumbnail: profile.avatar.payload.thumbnail,
                    }
                  },
                  Role: profile.role || 'Undefined',
                  Core: coreDomain || 'Undefined'
                },
              });
            });

            // Load notifications
            // $this.rootServices.authService.loginState$.pipe(filter(state => state), take(1)).subscribe(state => {
            //   $this.rootServices.apiService.getPromise<NotificationModel[]>('/notification/notifications/byCurrentUser', { limit: 10 }).then(notifications => {
            //     self.$setState({
            //       notifications: notifications.map(m => {
            //         m.DateOfCreate = moment(m.DateOfCreate).fromNow();
            //         m.Content = $this.commonService.cleanMentionTags(m.Content);
            //         return m;
            //       }),
            //     });
            //     // $this.rootServices.homeBadge = notifications.length;
            //     self.updateBadge();
            //   }).catch(err => {
            //     console.error(err);
            //     // $this.commonService.showError(err);
            //   });
            // });

            // $this.rootServices.platform.resume.subscribe(() => {
            //   $this.rootServices.apiService.getPromise<NotificationModel[]>('/notification/notifications/byCurrentUser', { limit: 10 }).then(notifications => {
            //     self.$setState({
            //       notifications: notifications.map(m => {
            //         m.DateOfCreate = moment(m.DateOfCreate).fromNow();
            //         m.Content = $this.commonService.cleanMentionTags(m.Content);
            //         return m;
            //       }),
            //     });
            //     // $this.rootServices.homeBadge = notifications.length;
            //     self.updateBadge();
            //   }).catch(err => {
            //     console.error(err);
            //     $this.commonService.showError(err);
            //   });
            // });
            await $this.rootServices.authService.loginState$.pipe(filter(f => f), take(1)).toPromise();
            self.refresh();

            // self.$app.on('swipeoutDelete', (el => {
            //   console.log(el);
            //   const notificationId = self.$(el).data('id');
            //   $this.rootServices.apiService.deletePromise('/notification/notifications/' + notificationId, { byCurrentUser: true }).then(rs => {
            //     console.debug(rs);
            //   });
            // }));

            $this.newNotification$.pipe(filter(f => !!f)).subscribe(newNotification => {
              newNotification.DateOfCreateMoment = moment(new Date()).fromNow();
              self.notifications && self.notifications.unshift(newNotification);
              self.$setState({
                notifications: self.notifications,
              });
              self.updateBadge();
            });

            // Attach 'infinite' event handler
            // Loading flag
            let allowInfinite = true;

            // Last loaded index
            var lastItemIndex = self.$$(page.el).find('.list li').length;
            self.infiniteOffset = 0;

            self.$$(page.el).find('.infinite-scroll-content').on('infinite', function () {
              // Exit, if loading in progress
              if (!allowInfinite) return;

              // Set loading flag
              allowInfinite = false;

              // Emulate 1s loading
              $this.rootServices.apiService.getPromise<NotificationModel[]>('/notification/notifications/byCurrentUser', {
                // search: self.searchBar.query,
                // sort_LastUpdate: 'desc',
                sort_Id: 'desc',
                limit: 10,
                offset: self.infiniteOffset,
                // ne_Type: 'HELPDESK',
                // ...self.filter,
              }).then(notifications => {
                // chatRooms.map(t => {
                //     // Moment.now()
                //     // const unread = t.NumOfMessage - t.LoggedNumOfMessage - t.LoggedNumOfReadMessage;
                //     // t.LastUpdate_Moment = moment(t.LastUpdate).fromNow();
                //     // t.DateOfCreate = new Date(t.DateOfCreate).toLocaleString();
                //     // // t.Infos.Request_Date_Time = new Date(t.Infos.Request_Date_Time).toLocaleTimeString();
                //     // t.NumOfUnreadMessage = unread > 0 ? unread : '';
                //     // $this.preapreTaskState(t);
                //     return t;
                // });
                self.notifications = self.notifications.concat(notifications);
                self.infiniteOffset += 10;
                self.$setState({
                  notifications: self.notifications.map(m => {
                    return $this.preapreTaskState(m);
                  }),
                  infiniteOffset: self.infiniteOffset,
                  infiniteLoadLast: notifications.length === 0
                });
                lastItemIndex = self.$$(page.el).find('.list li').length;
                allowInfinite = true;

                self.updateBadge();
              }).catch(err => {
                console.error(err);
                // $this.commonService.showError(err);
                if (/403/.test(err)) {
                  self.$setState({
                    infiniteLoadLast: true
                  });
                }
              });
            });
          },
          pageBeforeIn(e, page) {
            console.log('page before in');
          },
          pageAfterIn(e, page) {
            console.log('page after in');
          },
          pageBeforeOut(e, page) {
            console.log('page before out');
          },
          pageAfterOut(e, page) {
            console.log('page after out');
          },
          pageBeforeUnmount(e, page) {
            console.log('page before unmount');
          },
          pageBeforeRemove(e, page) {
            console.log('page before remove');
          },
        },
      },
    };
  }

  stateMap = {
    NEW: {
      text: 'mới',
      color: 'color-red',
    },
    READ: {
      text: 'đã xem',
      color: 'color-gray',
    },
    ACTIVE: {
      text: 'đã mở',
      color: 'color-gray',
    },
  };
  preapreTaskState(notification: NotificationModel) {
    // notification.DateOfCreate = moment(notification.DateOfCreate).fromNow();
    notification.DateOfCreateMoment = moment(notification.DateOfCreate).fromNow();
    notification.Content = this.commonService.cleanMentionTags(notification.Content);
    notification['SenderAvatarThumbnail'] = notification['Picture'] || (this.rootServices.apiService.getBaseApiUrl() + '/user/users/' + this.rootServices.commonService.getObjectId(notification['Sender']) + '/avatar');
    const state = this.stateMap[notification.State];
    notification['StateText'] = state?.text || 'mới';
    notification['StateColor'] = state?.color || 'mới';
    return notification;
  }
}
