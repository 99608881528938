import { ChatRoomMemberModel } from './../../model/chat-room.model';
// import { Contact } from './../../../../vendors/SIPjs/src/core/user-agent-core/user-agent-core-configuration';
import { ChatRoomModel } from './../../model/chat-room.model';
import { RootServices } from './../../services/root.services';
// import { RoomSocket } from './room-socket';
import { User } from './model/user';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { Message, Attachment } from './model/message';
// import { SocketService } from '../../services/socket.service';
import { Member } from './model/member';
import { IMySocketContext } from '../nam-socket/my-socket';
import { RoomInfoModel } from './model/room-info';
import { filter, take, takeUntil } from 'rxjs/operators';
// import { environment } from 'src/environments/environment';
// import { info } from 'console';
// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

export interface NotificationSettings {
  DISABLEDNEWMESSAGE?: boolean,
  DISABLEDMENTION?: boolean,
  DISABLEDREPLY?: boolean,
  ENABLEDONRETURN?: boolean,
  ENABLEDONTOMORROW?: any,
}

export interface IChatRoomContext extends IMySocketContext {
  // refreshToken: () => Promise<string>;
  // getAuthenticateToken(): Promise<JWTToken>;
  rootServices: RootServices;
  getLastMesssage(): Message;
  onChatRoomInit(): Promise<boolean>;
  onChatRoomConnect(): Promise<boolean>;
  onChatRoomReconnect(): Promise<boolean>;
  onNewMessage(newMessage: Message): Promise<boolean>;
  restoreMessages(namespace: string, messages: Message[]): Promise<boolean>;
  getTopMessageNo(chatRoom: ChatRoom): string;
  getEndMessageNo(chatRoom: ChatRoom): string;
  isLive(chatRoom: ChatRoom): boolean;
}

export class ChatRoom {

  messageList: Message[] = [];
  rooInfo$ = new BehaviorSubject<ChatRoomModel>(null);
  attachments$ = new BehaviorSubject<Attachment[]>([]);
  memberList$ = new BehaviorSubject<Member[]>([]);
  references$ = new BehaviorSubject<ChatRoomModel[]>([]);
  isFirstReady: boolean = true;

  sending$ = new BehaviorSubject<boolean>(false);

  // Protected message typing status event
  protected messageTyping = new BehaviorSubject<{ from: Member, status: 'typing' | 'clear' }>(null);
  public messageTyping$ = this.messageTyping.asObservable();

  lastMessageIndex = 0;
  protected stateSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public state$: Observable<string> = this.stateSubject.asObservable();

  protected eventSubject: BehaviorSubject<{ name: string, data?: any }> = new BehaviorSubject<{ name: string, data?: any }>(null);
  public event$: Observable<{ name: string, data?: any }> = this.eventSubject.asObservable();

  get currentState() {
    return this.stateSubject.getValue();
  }

  private takeUltilCount = {};
  private takeUltilPastCount = {};
  private takeOnceCount = {};
  private takeOncePastCount = {};

  // public roomSocket: RoomSocket;
  // public roomSocket: MySocket;
  public namespaceCheckpoint: number;

  readyStates = ['ready', 'socket-ready', 'activate', 'deactivate'];

  protected isFullLoadMessageListSubject = new BehaviorSubject<boolean>(false);
  public isFullLoadMessageList$ = this.isFullLoadMessageListSubject.asObservable();

  constructor(
    public id: string,
    // public roomSocket: RoomSocket,
    public user: User,
    // public chatManager: ISocketService,
    public context: IChatRoomContext,
    // public socketService: SocketService,
    public rootServices: RootServices,
  ) {
    this.state$.subscribe(state => console.debug(`[${this.id}] ` + `Chat room ${this.id} change state to "${state}" `));
    // this.initNamespaceSocket();
    this.stateSubject.next('construct');


  }
  getLoginInfo: () => { [key: string]: any; token: string; user: { [key: string]: any; id: string; name: string; }; };
  refreshToken(): Promise<string> {
    return this.context.refreshToken();
  }

  public async setContextAndSync(context: IChatRoomContext) {
    this.context = context;
    await this.syncToContext();
    return true;
  }

  public convertToPlanText(text: string) {
    if (!text) return '';
    return text.replace(/([\@\#]\[)([^\[\]]+)(\]\(\w+\:\w+\))/g, '$2');
  }

  public async syncToContext() {
    console.debug(`[${this.id}] ` + 'load last messages...');
    // const lastMessage = this.messageList[this.messageList.length - 1];
    // const lastMessages = await this.roomSocket.emit<Message[]>('request-last-messages', lastMessage ? lastMessage.index : 0);

    // if (lastMessages && lastMessages.length > 0) {
    //   for (let i = 0; i < lastMessages.length; i++) {
    //     this.messageList.push(lastMessages[i]);
    //   }
    // }
    // const contextLastMessage = this.context.getLastMesssage();
    // for (let i = 0; i < this.messageList.length; i++) {
    //   // if (!contextLastMessage || contextLastMessage.index < this.messageList[i].index) {
    //     this.context.onNewMessage(this.messageList[i], true);
    //   // }
    // }
    // if (!this.messageList || this.messageList.length === 0) {
    //   await this.state$.pipe(filter(s => this.readyStates.indexOf(s) > -1), take(1)).toPromise();
    //   this.messageList = await this.roomSocket.emit<Message[]>(`${this.id}-` + 'request-last-messages', 0).then(rs => rs.map(msg => ({
    //     ...msg,
    //     planContent: this.convertToPlanText(msg.Text),
    //   }))).catch(err => this.socketErrorHandler(err));
    // }
    // return this.context.onRestoreMessages(this.id, this.messageList);
  }

  async syncMessageList(option?: { limit?: number, force?: boolean }) {
    return false;
    // console.debug(`[${this.id}] ` + 'sync messages...');
    // await this.state$.pipe(filter(s => this.readyStates.indexOf(s) > -1), take(1)).toPromise();
    // let lastMessageIndex = null;
    // if (!option || !option.force) {
    //   const lastMessage = this.messageList && this.messageList[this.messageList.length - 1];
    //   lastMessageIndex = lastMessage && lastMessage.No;
    // }
    // const lastMessages = await this.roomSocket.emit<Message[]>(`${this.id}-` + 'sync-messages', { fromIndex: lastMessageIndex || undefined, limit: option && option.limit }).then(rs => rs.map(msg => ({
    //   ...msg,
    //   planContent: this.convertToPlanText(msg.Text),
    // }))).catch(err => this.socketErrorHandler(err));
    // if (lastMessageIndex) {
    //   this.messageList = (this.messageList || []).concat(lastMessages);
    // } else {
    //   this.messageList = lastMessages;
    // }
    // return this.messageList;
  }

  async socketErrorHandler(err: any) {
    if (err && err.status == 403) {
      // await this.state$.pipe(filter(s => this.readyStates.indexOf(s) > -1), take(1)).toPromise();
      this.eventSubject.next({ name: 'forbidden', data: err });
    }
    return Promise.reject(err);
  }

  async syncAttachmentList() {
    // await this.state$.pipe(filter(s => this.readyStates.indexOf(s) > -1), take(1)).toPromise();
    // const attachments = await this.roomSocket.emit<Attachment[]>(`${this.id}-` + 'sync-attachments', {}).catch(err => this.socketErrorHandler(err)) || [];
    // console.log(`[${this.id}] ` + 'Attachments', attachments);
    // this.attachments$.next(attachments);

    return this.rootServices.apiService.getPromise<Attachment[]>('/chat/message-attachments', { eq_ChatRoom: this.id, sort_Id: 'asc', limit: 'nolimit' }).then(attachments => {
      const modifiedAttachments = attachments.map(att => ({
        ...att, Id: `${att.ChatRoom}/${att.MessageNo}/${att.Id}`,
        Description: this.convertToPlanText(att.Description),
      }));
      this.attachments$.next(modifiedAttachments);
      return modifiedAttachments;
    });
  }

  async synchronous() {
    this.takeUntil('sync-chat-room-' + this.id, 500).then(async () => {
      console.debug(`[${this.id}] ` + 'sync chat room...');
      // Sync attachments
      // const roomInfo = await this.roomSocket.emit<RoomInfoModel>('sync-info', { token: (await this.context.getAuthenticateToken()).access_token });
      // if(this.stateSubject.getValue() !== 'socket-ready' && this.stateSubject.getValue() !== 'ready') {

      // }
      // await this.state$.pipe(filter(s => s === 'socket-ready' || s === 'ready'), take(1)).toPromise();

      // Sync message list
      // await this.syncMessageList({ limit: 30 });
      this.stateSubject.next('ready');
      await this.syncInfo();
      await this.syncMemberList();
      await this.syncAttachmentList();
      await this.syncReferenceList();


      // const lastMessage = this.messageList[this.messageList.length - 1];
      // const lastMessages = await this.roomSocket.emit<Message[]>('get-messages', this.isFirstReady ? { limit: 30, offset: 0 } : { fromIndex: lastMessage && lastMessage.index }).then(rs => rs.map(msg => ({
      //   ...msg,
      //   planContent: this.convertToPlanText(msg.content),
      // })));

      // const lastMessages = await this.roomSocket.emit<Message[]>('sync-messages', { fromIndex: this.messageList.length - 1 }).then(rs => rs.map(msg => ({
      //   ...msg,
      //   planContent: this.convertToPlanText(msg.content),
      // })));

      // if (this.isFirstReady) {
      //   this.messageList = lastMessages;
      // } else {
      //   this.messageList = (this.messageList || []).concat(lastMessages);
      // }


      // this.context.onRestoreMessages(this.id, lastMessages);

      // Sync message list
      if (this.isFirstReady) {
        //   this.isFullLoadMessageListSubject.next(false);
        //   setTimeout(() => {
        //     this.syncMessageList({ force: true }).then(() => {
        //       this.isFullLoadMessageListSubject.next(true);
        //     });
        //   }, 4000);
        this.isFirstReady = false;
      }


      // Sync attachments
      // const attachments = await this.roomSocket.emit<Attachment[]>('sync-attachments', { token: (await this.context.getAuthenticateToken()).access_token }) || [];
      // const attachments = await this.roomSocket.emit<Attachment[]>('sync-attachments', {}) || [];
      // this.attachments$.next(attachments);

      // Sync attachment list
      // setTimeout(() => {
      //   // this.syncAttachmentList();
      //   // this.syncMemberList();
      //   this.roomSocket.emit<RoomInfoModel>(`${this.id}-` + 'sync-info', {}).then(roomInfo => {
      //     if (roomInfo) {
      //       this.rooInfo$.next(roomInfo);
      //     }
      //   }).catch(err => this.socketErrorHandler(err));
      // }, 1000);
      // setTimeout(() => {
      //   this.syncAttachmentList();
      //   this.syncMemberList();
      //   // this.roomSocket.emit<RoomInfoModel>('sync-info', {}).then(roomInfo => {
      //   //   if (roomInfo) {
      //   //     this.rooInfo$.next(roomInfo);
      //   //   }
      //   // });
      // }, 3000);

      // sync member list
      // const memberList = await this.roomSocket.emit<Member[]>('sync-member-list', { token: (await this.context.getAuthenticateToken()).access_token }) || [];
      // const memberList = await this.roomSocket.emit<Member[]>('sync-member-list', {}) || [];
      // this.memberList$.next(memberList);



      // Sync all messages
      // if (this.isFirstReady) {
      //   this.messageList = await this.roomSocket.emit<Message[]>('get-messages', {}).then(rs => rs.map(msg => ({
      //     ...msg,
      //     planContent: this.convertToPlanText(msg.content),
      //   })));
      // }
    });

    // if (this.isFirstReady) {
    //   this.isFirstReady = false;
    // }
  }

  async initNamespaceSocket() {


    // console.debug(`[${this.id}] ` + 'Init namespace socket : ' + this.id);
    // await this.socketService.ready$.pipe(filter(f => f), take(1)).toPromise();
    // const result = await this.checkAndOpenNamespace();

    // this.namespaceCheckpoint = result.checkpoint;

    // console.debug(`[${this.id}] ` + 'connect to namespace : ' + this.id);
    // this.roomSocket = this.socketService.mainSocket;

    // this.roomSocket.on(`${this.id}-` + 'destroy-socket').pipe(take(1)).toPromise().then(data => {
    //   this.eventSubject.next({ name: 'forbidden', data });
    // });

    // this.roomSocket.state$.subscribe(state => {
    //   if (state === 'emit-timeout') {
    //     this.stateSubject.next('socket-timeout');
    //   }
    // });


    // Apply namespace event
    // this.roomSocket.onReconnect$.pipe(filter(status => !!status)).subscribe(async att => {
    //   console.debug(`[${this.id}] ` + this.id + ' reconnected : ' + att);
    //   // Do not reinit ultil socket update to action

    //   if (this.stateSubject.getValue() !== 'deactivate') {
    //     await this.reInit();
    //   }
    // });

    // if (this.stateSubject.getValue() !== 'deactivate') {
    //   console.debug(`[${this.id}] ` + 'namespace connected - ' + this.id);
    //   console.debug(`[${this.id}] ` + 'Register for connect after 5s...');
    //   if (this.stateSubject.getValue() !== 'deactivate') {
    //     this.register().catch(e => {
    //       console.debug(`[${this.id}] ` + 'Chat room reconnect when register failed on socket connect');
    //       this.roomSocket.connect();
    //     });
    //   }
    // }
    // });
    // this.roomSocket.socket.on('connect', () => {
    // });
    // this.roomSocket.socket.on('message', (request: ISocketResult<Message>) => {
    //   this.context.onChatRoomHadNewMessage(request.data);
    // });
    // this.roomSocket.on<Message>(`${this.id}-` + 'message').subscribe(async newMessage => {
    //   console.debug(`[${this.id}] ` + 'Receive new message:', newMessage);
    //   // await this.state$.pipe(filter(s => s === 'ready'), take(1)).toPromise();
    //   this.messageList.push(newMessage.data);

    //   // Update attachments
    //   if (newMessage.data.attachments) {
    //     for (const attachment of newMessage.data.attachments) {
    //       const updateAttachments = this.attachments$.getValue();
    //       updateAttachments.push(attachment);
    //       this.attachments$.next(updateAttachments);
    //     }
    //   }
    //   if (newMessage.data.quote && newMessage.data.quote.attachments) {
    //     for (const attachment of newMessage.data.quote.attachments) {
    //       const updateAttachments = this.attachments$.getValue();
    //       updateAttachments.push(attachment);
    //       this.attachments$.next(updateAttachments);
    //     }
    //   }
    //   this.context.onNewMessage(newMessage.data);
    // });

    // this.roomSocket.on<{ from: Member, status: 'typing' | 'clear' }>(`${this.id}-` + 'typing').subscribe(msg => {
    //   console.debug(`[${this.id}] ` + 'Receive typing status:', msg);

    //   // this.context.onNewMessage(newMessage.data);
    //   this.messageTyping.next(msg.data);
    // });

    // Listen sync member list form chat service
    // this.roomSocket.on<Member[]>(`${this.id}-` + 'sync-member-list').subscribe(async memberList => {
    //   await this.state$.pipe(filter(s => this.readyStates.indexOf(s) > -1), take(1)).toPromise();
    //   // if (this.stateSubject.getValue() !== 'deactivate') {
    //   console.debug(`[${this.id}] ` + 'Event: sync member list:', memberList);
    //   if (memberList.data) {
    //     this.memberList$.next(memberList.data);
    //   }
    //   // }
    // });

    // let isFirstReady = true;
    // this.state$.subscribe(async (state) => {
    //   // Load last messages
    //   if (state === 'socket-ready') {

    //     // Synchronous chat room
    await this.synchronous();




    //     // this.takeUntil('sync-chat-room', 500).then(async () => {
    //     //   console.debug(`[${this.id}] ` + 'load last messages...');
    //     //   // Sync attachments
    //     //   // const roomInfo = await this.roomSocket.emit<RoomInfoModel>('sync-info', { token: (await this.context.getAuthenticateToken()).access_token });
    //     //   const roomInfo = await this.roomSocket.emit<RoomInfoModel>('sync-info', {});
    //     //   if (roomInfo) {
    //     //     this.rooInfo$.next(roomInfo);
    //     //   }

    //     //   const lastMessage = this.messageList[this.messageList.length - 1];
    //     //   const lastMessages = await this.roomSocket.emit<Message[]>('get-messages', isFirstReady ? { limit: 30, offset: 0 } : { fromIndex: lastMessage && lastMessage.index }).then(rs => rs.map(msg => ({
    //     //     ...msg,
    //     //     planContent: this.convertToPlanText(msg.content),
    //     //   })));

    //     //   if (isFirstReady) {
    //     //     this.messageList = lastMessages;
    //     //   } else {
    //     //     this.messageList = (this.messageList || []).concat(lastMessages);
    //     //   }
    //     //   // this.context.onRestoreMessages(this.id, lastMessages);
    //     //   this.stateSubject.next('ready');

    //     //   // Sync attachments
    //     //   // const attachments = await this.roomSocket.emit<Attachment[]>('sync-attachments', { token: (await this.context.getAuthenticateToken()).access_token }) || [];
    //     //   const attachments = await this.roomSocket.emit<Attachment[]>('sync-attachments', {}) || [];
    //     //   this.attachments$.next(attachments);

    //     //   // sync member list
    //     //   // const memberList = await this.roomSocket.emit<Member[]>('sync-member-list', { token: (await this.context.getAuthenticateToken()).access_token }) || [];
    //     //   const memberList = await this.roomSocket.emit<Member[]>('sync-member-list', {}) || [];
    //     //   this.memberList$.next(memberList);


    //     //   // Sync all messages
    //     //   if (isFirstReady) {
    //     //     this.messageList = await this.roomSocket.emit<Message[]>('get-messages', {}).then(rs => rs.map(msg => ({
    //     //       ...msg,
    //     //       planContent: this.convertToPlanText(msg.content),
    //     //     })));
    //     //   }
    //     // });

    //     // if (isFirstReady) {
    //     //   isFirstReady = false;
    //     // }
    //   }
    // });

    // this.stateSubject.next('init');
  }

  cancelRequest: Subject<void>;
  protected subscriptionUpdate = false;
  protected updateLoopCounter = 0;
  unscribeUpdate() {
    this.cancelRequest.next();
    this.cancelRequest.complete();
    this.subscriptionUpdate = false;
  }
  async subscribeUpdate() {
    if (this.subscriptionUpdate) {
      return true;
    }
    this.subscriptionUpdate = true;
    while (this.subscriptionUpdate) {
      try {
        this.cancelRequest = new Subject<void>();
        if (this.updateLoopCounter > 30) {
          console.error(`[${this.id}] ` + 'Max try check chat room update :' + this.updateLoopCounter);
          break;
        }
        this.updateLoopCounter++;
        if (!this.context.isLive(this)) {
          console.error(`[${this.id}] ` + 'Chat room context was destroyed :' + this.updateLoopCounter);
          break;
        }
        await this.rootServices.apiService.getObservable<[{ Messages: Message[], MembersCheckPoint: number, ReferencesCheckpoint: number }]>('/chat/rooms/' + this.id, {
          // eq_ChatRoom: this.id,
          listenUpdate: true,
          includeUser: true,
          includeAttachments: true,
          lastMessageNo: this.context.getEndMessageNo(this) || -1,
          membersCheckPoint: this.rooInfo$.value?.MembersCheckPoint,
          referencesCheckpoint: this.rooInfo$.value?.ReferencesCheckpoint,
          sort_No: 'asc',
          limit: 30
        }).pipe(takeUntil(this.cancelRequest.asObservable())).toPromise().then(async rs => {
          if (!rs?.body) {
            return [];
          }
          const newMessages = rs.body[0]?.Messages;
          // const members = rs.body[0]?.Members;
          const membersCheckPoint = rs.body[0]?.MembersCheckPoint;
          const referencesCheckpoint = rs.body[0]?.ReferencesCheckpoint;

          // let newMessages: Message[] = ;
          setTimeout(() => {
            this.updateLoopCounter--;
          }, 300);
          if (!newMessages && !membersCheckPoint && !referencesCheckpoint) return [];
          await this.sending$.pipe(filter(status => status === false), take(1)).toPromise();

          // Fire new message event to context
          if (newMessages) for (const newMessage of newMessages as any) {
            // const newMessage = { data: newMessages[0] };
            if (newMessage.No <= this.context.getEndMessageNo(this)) {
              continue;
            }
            console.debug(`[${this.id}] ` + 'Receive new message:', newMessage);
            this.messageList.push(newMessage);

            // Update attachments
            if (newMessage.Attachments) {
              for (const attachment of newMessage.Attachments) {
                const updateAttachments = this.attachments$.getValue();
                updateAttachments.push({
                  ...attachment,
                  Id: `${attachment.ChatRoom}/${attachment.MessageNo}/${attachment.Id}`,
                  Description: this.convertToPlanText(attachment.Description),
                });
                this.attachments$.next(updateAttachments);
              }
            }
            if (newMessage.Quote && newMessage.Quote.Attachments) {
              for (const attachment of newMessage.Quote.Attachments) {
                const updateAttachments = this.attachments$.getValue();
                updateAttachments.push({
                  ...attachment,
                  Id: `${attachment.ChatRoom}/${attachment.MessageNo}/${attachment.Id}`,
                  Description: this.convertToPlanText(attachment.Description),
                });
                this.attachments$.next(updateAttachments);
              }
            }
            await this.context.onNewMessage(newMessage);
            this.lastMessageIndex = newMessage.No;
          }

          // Update member list
          if (membersCheckPoint) {
            this.rooInfo$.next({ ...this.rooInfo$?.value, MembersCheckPoint: membersCheckPoint });
            this.syncMemberList();
          }

          // Update reference chat rooms
          if (referencesCheckpoint) {
            this.rooInfo$.next({ ...this.rooInfo$?.value, ReferencesCheckpoint: referencesCheckpoint });
            this.syncReferenceList();
          }
          return newMessages;
        });
      } catch (err) {
        console.error(err);
        console.log(`[${this.id}] ` + 'Keep alive error => re-connect...');
        this.unscribeUpdate();
        setTimeout(() => {
          this.subscribeUpdate();
        }, 1000);
        break;
      }
    }
  }

  async syncMemberList(option?: { force: boolean }) {
    // await this.state$.pipe(filter(s => this.readyStates.indexOf(s) > -1), take(1)).toPromise();
    // const memberList = await this.roomSocket.emit<Member[]>(`${this.id}-` + 'sync-member-list', option).catch(err => this.socketErrorHandler(err)) || [];
    // this.memberList$.next(memberList);
    // return memberList;
    return this.rootServices.apiService.getPromise<Member[]>('/chat/room-members', { eq_ChatRoom: this.id }).then(memberList => {
      const baseUrl = this.rootServices.apiService.getBaseApiUrl() + '/user/users/';
      this.memberList$.next(memberList.map(member => {
        if (member.Type == 'USER' || member.Type == 'GROUP') {
          member.AvatarThumbnail = baseUrl + member.id + '/avatar';
        }
        if (member.Type == 'USER') member.id = this.rootServices.commonService.getObjectId(member.User) as any;
        if (member.Type == 'GROUP') member.id = this.rootServices.commonService.getObjectId(member.Group) as any;
        if (member.Type == 'CONTACT') member.id = this.rootServices.commonService.getObjectId(member.Contact) as any;
        return member;
      }));
      // const members = memberList && Array.isArray(memberList) ? memberList.filter(f => this.rootServices.commonService.getObjectId(f) != 'SMARTBOT').map(m => ({ id: this.rootServices.commonService.getObjectId(m), text: m.Name, avatar: this.rootServices.apiService.getBaseApiUrl() + '/user/users/' + this.rootServices.commonService.getObjectId(m) + '/avatar' })) : [];
      const members = memberList && Array.isArray(memberList) ? memberList.map(m => ({ id: this.rootServices.commonService.getObjectId(m), text: m.Name, avatar: this.rootServices.apiService.getBaseApiUrl() + '/user/users/' + this.rootServices.commonService.getObjectId(m) + '/avatar' })) : [];
      this.rooInfo$.next({ ...this.rooInfo$.value, Members: members });
      return this.memberList$.value;
    });
  }

  async syncReferenceList() {
    return this.rootServices.apiService.getPromise<Member[]>('/chat/rooms', { referenceChatRoom: this.id }).then(references => {
      this.references$.next(references);
      return this.references$.value;
    });
  }

  async checkAndOpenNamespace() {
    // return this.socketService.mainSocket.emit<{ namespace: string, checkpoint: number }>('open-namespace', { namespace: this.id, option: { user: this.user, } }).then(rs => {
    //   console.debug(`[${this.id}] ` + 'response of open-namespace');
    //   console.debug(`[${this.id}] ` + rs);
    //   // this.namespaceCheckpoint = rs.checkpoint;
    //   return rs;
    // }).catch(err => {
    //   console.debug(`[${this.id}] ` + 'open namespace error');
    //   console.error(err);
    //   return Promise.reject(err);
    // });
    return true;
  }

  async reInit() {
    // console.debug(`[${this.id}] ` + 'Re-Init namespace socket : ' + this.id);
    // await this.socketService.ready$.pipe(filter(f => f), take(1)).toPromise();
    // const result = await this.checkAndOpenNamespace();

    // if (result && result.checkpoint === this.namespaceCheckpoint) {
    //   console.log(`[${this.id}] ` + 'reload chat room socket...');
    // } else {
    //   console.log(`[${this.id}] ` + 'reset chat room socket...');
    // }
    // this.namespaceCheckpoint = result.checkpoint;

    // console.log(`[${this.id}] ` + 'register for reinit chat room socket')
    // await this.register();

    return true;
  }

  async register(timeout?: number): Promise<boolean> {
    // timeout = timeout ? timeout : 1000;
    // return this.takeOnce('chat-room-socket-register-' + this.id, 1000).then(async status => {
    //   if (status) {
    //     console.debug(`[${this.id}] ` + 'socket register...');
    //     return this.roomSocket.emit<boolean>(`${this.id}-` + 'register', {
    //       user: this.user,
    //       device: {
    //         uuid: await this.rootService.getDeviceUuid() + environment.bundleId,
    //         platform: this.rootService.devicePlatform,
    //       },
    //     }, timeout).then(rs2 => {
    //       console.debug(`[${this.id}] ` + `Registered user ${this.user.id} to chat room ${this.id} : ${rs2}`);
    //       this.stateSubject.next('socket-ready');

    //       // this.roomSocket.on(`${this.id}-` + 'destroy-socket').pipe(take(1)).toPromise().then(data => {
    //       //   this.eventSubject.next({ name: 'forbidden', data });
    //       // });

    //       return true;
    //     }).catch(err => {
    //       console.debug(`[${this.id}] ` + ' error');
    //       console.error('Register failed', err);
    //       // return Promise.reject(err);
    //       return this.socketErrorHandler(err);
    //     });
    //   } else {
    //     console.log(`[${this.id}] ` + 'register was execute 1s ago');
    //   }
    // });
    return true;
  }

  async sendMessage(message: Message, user: User): Promise<Message> {
    // this.sending$.next(true);
    console.debug(`[${this.id}] ` + 'Send message ' + JSON.stringify(message));
    message.No = Date.now();
    try {
      await this.state$.pipe(filter(s => this.readyStates.indexOf(s) > -1), take(1)).toPromise();
      // const result = await this.roomSocket.emit<Message>(`${this.id}-` + 'message', message).catch(err => this.socketErrorHandler(err));
      // resolve(result);
      return this.rootServices.apiService.postPromise<Message[]>('/chat/messages', { chatRoom: this.id }, [message]).then(newMessages => {
        const newMessage = newMessages[0];
        this.lastMessageIndex = newMessage.No;
        this.messageList.push(newMessage);

        // Update attachments
        if (newMessage.Attachments) {

          const updateAttachments = this.attachments$.getValue();
          for (const attachment of newMessage.Attachments) {
            updateAttachments.push({ ...attachment, Id: `${message.ChatRoom}/${newMessage.No}/${attachment.Id}` });
          }
          this.attachments$.next([...updateAttachments]);
        }
        console.log(`[${this.id}] ` + newMessage.No);
        // this.sending$.next(false);
        return newMessage;
      });
    } catch (err) {
      // reject(e);
      // this.sending$.next(false);
      console.error(err);
    }
    // return new Promise<Message>(async (resolve, reject) => {

    // });
  }
  // sendMessage(message: Message, user: User): Promise<Message> {
  //   return new Promise<Message>(async (resolve, reject) => {
  //     // const maxTry = 10;
  //     // let tryCount = 0;
  //     // while (tryCount++ < maxTry) {
  //     // if (this.stateSubject.value === 'ready') {
  //     console.debug(`[${this.id}] ` + 'Send message ' + JSON.stringify(message));
  //     message.index = Date.now();
  //     try {
  //       await this.state$.pipe(filter(s => this.readyStates.indexOf(s) > -1), take(1)).toPromise();
  //       const result = await this.roomSocket.emit<Message>(`${this.id}-` + 'message', message).catch(err => this.socketErrorHandler(err));
  //       this.messageList.push(result);

  //       // Update attachments
  //       if (result.attachments) {

  //         const updateAttachments = this.attachments$.getValue();
  //         for (const attachment of result.attachments) {
  //           updateAttachments.push(attachment);
  //         }
  //         this.attachments$.next(updateAttachments);
  //       }
  //       console.log(`[${this.id}] ` + result);
  //       resolve(result);
  //     } catch (e) {
  //       reject(e);
  //     }
  //     // break;
  //     // } else {
  //     //   // Check socket connect status: false => reconnect, true => register
  //     //   this.connect();
  //     //   console.debug(`[${this.id}] ` + 'socket was not ready => reconnect...');
  //     //   await new Promise(resolve2 => setTimeout(() => resolve2(), 1000));
  //     // }
  //     // }
  //     // reject('socket was not ready, retry againt !');
  //   });
  // }

  async sendTypingStatus(msg: { status: 'typing' | 'clear' }) {
    // await this.state$.pipe(filter(s => this.readyStates.indexOf(s) > -1), take(1)).toPromise();
    // return await this.roomSocket.emit<Message>(`${this.id}-` + 'mesage-typing', msg).catch(err => this.socketErrorHandler(err));
  }

  async getMessages(limit: number, offset: number, topIndex?: any) {


    const params = { eq_ChatRoom: this.id, includeUser: true, includeAttachments: true, includeReadState: true, sort_No: 'desc', limit: limit };
    if (topIndex) {
      params['lt_No'] = topIndex;
    }
    return this.rootServices.apiService.getPromise<Message[]>('/chat/messages', params).then(messages => {
      return messages.reverse();
    }).then(messages => {
      // if (messages.length > 0 && offset == 0) {
      //   this.lastMessageIndex = messages[messages.length - 1].No;
      // }
      this.messageList = this.messageList.concat(messages);
      return messages;
    });







    // let startMsgIndex = this.messageList.length - offset - limit;
    // startMsgIndex = startMsgIndex > 0 ? startMsgIndex : 0;
    // const endMsgINdex = this.messageList.length - offset;

    // if (endMsgINdex < 0) {
    //   return [];
    // }
    // console.debug(`[${this.id}] ` + 'real start ' + startMsgIndex + ', end ' + endMsgINdex);
    // const rs = this.messageList.slice(startMsgIndex, endMsgINdex);

    // return rs;
  }

  async getMessagesFromIndex(msgIndex: number) {
    // await this.syncMessageList();
    // const index = this.messageList.findIndex(f => f.No === msgIndex);
    // return this.messageList.slice(index + 1);

    return this.rootServices.apiService.getPromise<Message[]>('/chat/messages', { eq_ChatRoom: this.id, includeUser: true, includeAttachments: true, includeReadState: true, gt_No: msgIndex, sort_No: 'asc' }).then(rs => {
      if (rs.length > 0) {
        this.lastMessageIndex = rs[rs.length - 1].No;
      }
      this.messageList = this.messageList.concat(rs);
      return rs;
    });
  }

  async updateMessagesReadState(readMessages: number[]) {
    // await this.state$.pipe(filter(s => this.readyStates.indexOf(s) > -1), take(1)).toPromise();
    // return this.roomSocket.emit(`${this.id}-` + 'update-messages-read-state', readMessages).catch(err => this.socketErrorHandler(err));
    return this.rootServices.apiService.putPromise<any>('/chat/messages', { updateMessagesReadState: true }, readMessages.map(msgNo => ({ ChatRoom: this.id, No: msgNo })));
    // return true;
  }
  async getMessageReadState(msgNo: number): Promise<({ Time: number } & Member)[]> {
    // await this.state$.pipe(filter(s => this.readyStates.indexOf(s) > -1), take(1)).toPromise();
    // return this.roomSocket.emit<(Member & { time?: number })[]>(`${this.id}-` + 'get-message-read-state', msgNo).catch(err => this.socketErrorHandler(err));

    return this.rootServices.apiService.getPromise<Message[]>('/chat/messages', { chatRoom: this.id, eq_No: msgNo, includeUser: true }).then(rs => rs[0]).then(message => {
      console.log(message);
      const readStateMembers = this.memberList$?.value?.map((member) => {
        if (message?.ReadState && message?.ReadState[member.id]) {
          const readState: { Time: number } & Member = { ...member, Time: message.ReadState[member.id] };
          return readState;
        }
        return null;
      }).filter(f => !!f);
      return readStateMembers;
    });

    // return []
  }

  onMessage(): Observable<{ data: Message, callback?: (response: any) => void }> {
    // return this.roomSocket.on<Message>(`${this.id}-` + 'message');
    // return new Observable<Message>(obs => {
    //   this.socket.on<Message>('message').subscribe(request => {
    //     obs.next(request.data);
    //   });
    // });
    return;
  }


  takeUntil(context: string, delay: number): Promise<any> {
    return new Promise<any>(resolve => {
      if (delay === 0) {
        resolve(true);
        return;
      }
      if (!this.takeUltilCount[context]) { this.takeUltilCount[context] = 0; }
      this.takeUltilCount[context]++;
      ((takeCount) => {
        setTimeout(() => {
          this.takeUltilPastCount[context] = takeCount;
        }, delay);
      })(this.takeUltilCount[context]);
      setTimeout(() => {
        if (this.takeUltilPastCount[context] === this.takeUltilCount[context]) {
          resolve(true);
        }
      }, delay);
    });

  }

  /** Anti duplicate action */
  async takeOnce(context: string, delay: number): Promise<boolean> {
    const result = new Promise<boolean>(resolve => {
      // resolve(true);
      // if (delay === 0) {
      //   resolve(true);
      //   return;
      // }
      if (this.takeOncePastCount[context] === this.takeOnceCount[context]) {
        resolve(true);
      } else {
        resolve(false);
      }
      if (!this.takeOnceCount[context]) { this.takeOnceCount[context] = 0; }
      this.takeOnceCount[context]++;
      ((takeCount) => {
        setTimeout(() => {
          this.takeOncePastCount[context] = takeCount;
        }, delay);
      })(this.takeOnceCount[context]);
      setTimeout(() => {
        if (this.takeOncePastCount[context] === this.takeOnceCount[context]) {
          this.takeOncePastCount[context] = null;
          this.takeOnceCount[context] = null;
          // resolve(true);
        }
      }, delay);
    });
    return result;
  }

  async addMember(member: Member) {
    // console.debug(`[${this.id}] ` + `add member ${member.Name} to chat room ${this.id}`);
    // const newMember = await this.roomSocket.emit<Member>(`${this.id}-` + 'add-member', { member: member }).catch(err => this.socketErrorHandler(err));
    // await this.syncMemberList();
    // console.debug(`[${this.id}] ` + 'member added to this chat room');
    return this.rootServices.apiService.postPromise<Member[]>('/chat/room-members', { chatRoom: this.id }, [member]).then(rs => {
      this.syncMemberList();
      return rs[0];
    });
    // return true;
  }

  async removeMember(member: Member) {
    // console.debug(`[${this.id}] ` + `remove member ${member.id} to chat room ${this.id}`);
    // await this.state$.pipe(filter(s => this.readyStates.indexOf(s) > -1), take(1)).toPromise();
    // const result = await this.roomSocket.emit<Member>(`${this.id}-` + 'remove-member', { member: member }).catch(err => this.socketErrorHandler(err));

    // await this.syncMemberList();
    // console.debug(`[${this.id}] ` + 'member remove out of this chat room');
    return this.rootServices.apiService.deletePromise<Member[]>('/chat/room-members', { chatRoom: this.id, type: member.Type, memberId: member.id }).then(rs => {
      this.syncMemberList();
      return rs;
    });
    // return true;
  }

  async updateMember(member: Member) {
    //   console.debug(`[${this.id}] ` + `update member ${member.Name} to chat room ${this.id}`);
    //   await this.state$.pipe(filter(s => this.readyStates.indexOf(s) > -1), take(1)).toPromise();
    //   const updatedMember = await this.roomSocket.emit<Member>(`${this.id}-` + 'update-member', { member: member }).catch(err => this.socketErrorHandler(err));
    //   await this.syncMemberList();
    //   console.debug(`[${this.id}] ` + 'member updated on this chat room');
    return this.rootServices.apiService.putPromise<Member[]>('/chat/room-members', { chatRoom: this.id }, [member]).then(rs => {
      this.syncMemberList();
      return rs[0];
    });
    // return true;
  }

  async active() {
    // console.log(`[${this.id}] ` + `chat room socket ${this.id} update state to activate`);
    // this.messageTyping.next(null);
    // this.stateSubject.next('activate');
    // await this.state$.pipe(filter(s => this.readyStates.indexOf(s) > -1), take(1)).toPromise();
    // await this.reInit();
    // await this.roomSocket.emit<boolean>(`${this.id}-` + 'update-state', 'activate').catch(err => this.socketErrorHandler(err));
    if (!this.isFirstReady) {
      this.stateSubject.next('ready');
      this.subscribeUpdate();
    }
    return true;
  }

  async deactive() {
    // console.log(`[${this.id}] ` + `chat room socket ${this.id} update state to deactivate`);
    // this.messageTyping.next(null);
    // await this.state$.pipe(filter(s => this.readyStates.indexOf(s) > -1), take(1)).toPromise();
    // return this.roomSocket.emit<boolean>(`${this.id}-` + 'update-state', 'deactivate').then(rs => {
    this.stateSubject.next('deactivate');
    //   return rs;
    // }).catch(err => this.socketErrorHandler(err));
    this.unscribeUpdate();
  }

  async updateRoomInfo(roomInfo: { description?: string, tags?: string[] }) {
    // console.log(`[${this.id}] ` + `chat room socket ${this.id} update chat romo info`);
    // await this.state$.pipe(filter(s => this.readyStates.indexOf(s) > -1), take(1)).toPromise();
    // return this.roomSocket.emit<RoomInfoModel>(`${this.id}-` + 'update-info', roomInfo).then(roomInfo => {
    //   this.rooInfo$.next(roomInfo);
    //   return roomInfo;
    // }).catch(err => this.socketErrorHandler(err));
    const data: ChatRoomModel = {};
    if (roomInfo.description) {
      data.Description = roomInfo.description;
    }
    if (roomInfo.tags) {
      data.Tags = roomInfo.tags;
    }
    return this.rootServices.apiService.putPromise<ChatRoomModel[]>('/chat/rooms', {
      id: this.id,
    }, [data]).then(results => {
      const currentInfo = this.rooInfo$.value;
      const newInfo = results[0];
      currentInfo.Tags = newInfo.Tags;
      currentInfo.Description = newInfo.Description;
      this.rooInfo$.next({ ...currentInfo });
      return results[0];
    });
  }

  async syncInfo() {
    // const roomInfo = await this.roomSocket.emit<RoomInfoModel>(`${this.id}-` + 'sync-info', {}).catch(err => this.socketErrorHandler(err));
    // this.rooInfo$.next(roomInfo);
    // return roomInfo;
    if (this.id) {
      return this.rootServices.apiService.getPromise<ChatRoomModel[]>('/chat/rooms/' + this.id, { includeMembersCheckPoint: true, includeReferencesCheckpoint: true }).then(rs => rs[0]).then(info => {
        // info.Members = info.MembersIndex && Array.isArray(info.MembersIndex) ? info.MembersIndex.filter(f => f != 'SMARTBOT').map(m => ({ id: m, text: m, avatar: this.rootServices.apiService.getBaseApiUrl() + '/user/users/' + m + '/avatar' })) : [];
        this.rooInfo$.next(info);
        return this.rooInfo$.value;
      });
    }
    return null;
  }

  async addTag(tag: string) {
    try {
      // console.debug(`[${this.id}] ` + `add chat room tag ${tag} to chat room ${this.id}`);
      // await this.roomSocket.emit<string>(`${this.id}-` + 'add-tag', tag).catch(err => this.socketErrorHandler(err));
      // await this.syncInfo();
      return true;
    } catch (err) {
      console.debug(`[${this.id}] ` + ' error');
      console.error(err);
      return false;
    }
  }

  async removeTag(tag: string) {
    try {
      // console.debug(`[${this.id}] ` + `remove member ${tag} to chat room ${this.id}`);
      // await this.state$.pipe(filter(s => this.readyStates.indexOf(s) > -1), take(1)).toPromise();
      // await this.roomSocket.emit<Member>(`${this.id}-` + 'remove-tag', tag).catch(err => this.socketErrorHandler(err));
      // await this.syncInfo();
      // console.debug(`[${this.id}] ` + 'member remove out of this chat room');
      return true;
    } catch (err) {
      console.debug(`[${this.id}] ` + ' error');
      console.error(err);
      return false;
    }
  }

  async updateMemberNotificationSettings(settings: NotificationSettings) {
    try {
      // console.debug(`[${this.id}] ` + `update self member notification settings:`, settings);
      // await this.state$.pipe(filter(s => this.readyStates.indexOf(s) > -1), take(1)).toPromise();
      // await this.roomSocket.emit<Member>(`${this.id}-` + 'update-self-member-notification-settings', settings).catch(err => this.socketErrorHandler(err));
      // await this.syncMemberList();
      // console.debug(`[${this.id}] ` + 'update self member notification settings success');
      return this.rootServices.apiService.putPromise<Member[]>(`/chat/room-members/${this.id}-${this.rootServices.authService.getUser().id}--`, { chatRoom: this.id, updateMemberNofiticationSettings: true }, [{
        User: this.rootServices.authService.getUser().id as any,
        DisabledNotification: settings,
      }]).then(rs => {
        this.syncMemberList();
        return rs[0];
      });
      // return true;
    } catch (err) {
      console.debug(`[${this.id}] ` + ' error');
      console.error(err);
      return false;
    }
  }

  async connect() {
    // if (!this.roomSocket) {
    //   await this.initNamespaceSocket();
    // }
    // this.roomSocket.connect();
    return true;
    // const timmer = setTimeout(() => {
    //   // this.roomSocket.reInit();
    //   // this.roomSocket.retryConnect();

    //   console.log(`[${this.id}] ` + 'retry connect main socket');
    //   this.socketService.mainSocket.retryConnect();
    //   this.connect();
    // }, 300000);
    // this.roomSocket.state$.pipe(filter(f => f === 'ready' || f === 'socket-ready'), take(1)).toPromise().then(() => {
    //   clearTimeout(timmer);
    // });
  }

  async createReferenceChatRoom(description: string, option?: { type?: string, referenceMessage?: Message, members?: string[] }) {
    // try {
    // console.debug(`[${this.id}] ` + `create reference chat room ${description} for chat room ${this.id}`);
    // const postMembers = option.members && this.memberList$.getValue().filter(f => option.members.some(m => f.id === m));
    // await this.state$.pipe(filter(s => this.readyStates.indexOf(s) > -1), take(1)).toPromise();
    // // const newMember = await this.roomSocket.emit<Member>('add-member', { member: member, option: { token: (await this.context.getAuthenticateToken()).access_token } });
    // const newChatRoom = await this.roomSocket.emit<{ id: string, description: string }>(`${this.id}-` + 'create-reference-chat-room', { type: 'CHILD', description: description, byMessage: option.referenceMessage, members: postMembers }).catch(err => this.socketErrorHandler(err));

    // const memberList = this.memberList$.getValue();
    // memberList[newMember.id] = newMember;
    // this.memberList$.next(memberList);
    // await this.syncMemberList();

    // console.debug(`[${this.id}] ` + 'complete create reference chat room', newChatRoom);
    // return newChatRoom;
    // } catch (err) {
    //   console.error(err);
    //   return false;
    // }
    return this.rootServices.apiService.postPromise<{
      // Store interface
      Code?: string,
      Description: string,
      ByMessage: {
        No: number,
        User: string,
        ChatRoom: string,
        Text: string,
      },
      Members: { Type: string, User?: string, Group?: string, Roles?: string[] }[]
    }[]>('/chat/rooms', {
      // ...params,
      createReferenceChatRoom: true,
      parentChatRoom: this.id,
      description: description,
      // byMessage: byMessage,
    }, [{
      ByMessage: option?.referenceMessage && {
        ChatRoom: option?.referenceMessage.ChatRoom,
        No: option?.referenceMessage.No,
        User: option?.referenceMessage.From && option?.referenceMessage.From.id,
        Text: option?.referenceMessage.Text,
      },
      Description: description,
      Members: option?.members && this.memberList$.value?.filter(f => option.members.some(s => s === f.id)).map(m => ({ Type: m.Type, User: m.User, Group: m.Group, Roles: m.Roles })),
    }]).then(rs => {
      // Convert to ochat interface
      return {
        id: rs[0].Code,
        description: rs[0].Description,
      };
    });
  }

  disconnect() {
    // this.roomSocket.disconnect();
  }

  destroy() {
    // this.roomSocket.destroySocket();
  }
}
