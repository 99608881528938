import { ProductModel } from './../../../vendors/smart-bot-app/src/app/model/product.model';
import { F7ComponentContext } from '../types/framework7-types';
import { Messages } from 'framework7/build/core/components/messages/messages';
import { F7Messagebar } from '../types/framework7-types';
import { Router } from 'framework7/build/core/modules/router/router';
import { filter, take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { ComponentState, BaseComponent } from 'vendors/smart-bot-app/src/app/lib/base-component';
import { NotificationModel } from 'vendors/smart-bot-app/src/app/model/notification.model';
import { RecentLoginModel } from 'vendors/smart-bot-app/src/app/model/recent-login.model';
import { CommonService } from 'vendors/smart-bot-app/src/app/services/common.service';
import { RootServices } from 'vendors/smart-bot-app/src/app/services/root.services';
import { TestabilityRegistry } from '@angular/core';

export interface ComponentStateExtend extends ComponentState {
  [key: string]: any;
}

export class F7ComponentContextExtend extends F7ComponentContext {
  messagebar?: F7Messagebar;
  messages?: Messages.Messages;
  images?: string[];
  responseInProgress?: boolean;
  answers?: string[];
  people?: { name?: string, avatar?: string }[];
  hideToolbar?: () => void;
  sheetToggle?: () => void;
  deleteAttachment?: (e: MouseEvent, index: number) => void;
  handleAttachment?: (e: MouseEvent) => void;
  checkAttachments?: () => void;
  sendMessage?: () => void;
  $root: any;
  notifications?: NotificationModel[];
  // [key: string]: any;
}

/** Component group manager */
export class PagesComponent extends BaseComponent<ComponentStateExtend> {
  // states: { [key: string]: State } = {};

  title: 'Trang';
  newNotification$ = new BehaviorSubject<NotificationModel>(null);

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
  ) {
    super(rootServices);
    // console.log('Click here to open ts file');
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: 'pages',
      path: '/pages',
      component: {
        template: /*html*/`
            <div class="page" data-name="pages">
              <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                    {{#if backTitle}}
                    <a class="link back {{textColorClass}}">
                      <i class="icon icon-back"></i>
                      <span class="if-not-md {{textColorClass}}">{{backTitle}}</span>
                    </a>
                    {{else}}
                    <!-- Link to enable searchbar -->
                    <a class="link icon-only searchbar-enable text-color-default" data-searchbar=".pages-searchbar">
                      <i class="icon f7-icons if-not-md">search</i>
                      <i class="icon material-icons md-only">search</i>
                    </a>
                    {{/if}}
                  </div>
                  <div class="title text-color-default">Trang Bạn Đang Tương Tác</div>
                  <div class="right">
                    <a class="link icon-only text-color-default">
                      <i class="icon f7-icons">qrcode_viewfinder</i>
                    </a>
                  </div>
                  <!-- Searchbar is a direct child of "navbar-inner" -->
                  <form class="searchbar pages-searchbar searchbar-expandable">
                    <div class="searchbar-inner">
                      <div class="searchbar-input-wrap">
                        <input type="search" placeholder="id trang" />
                        <i class="searchbar-icon"></i>
                        <span class="input-clear-button"></span>
                      </div>
                      <span class="searchbar-disable-button text-color-default">Cancel</span>
                    </div>
                  </form>
                </div>
              </div>
              <div class="page-content ptr-content infinite-scroll-content" @ptr:refresh="refresh">
                <div class="ptr-preloader">
                  <div class="preloader"></div>
                  <div class="ptr-arrow"></div>
                </div>
                <div class="searchbar-backdrop pages-searchbar-backdrop"></div>
            
                <br>
                {{#if searchResults}}
                <div class="block-title">Kết quả tìm kiếm</div>
                <div class="list media-list inset">
                  <ul>
                    {{#each searchResults}}
                    <li data-id="{{Code}}" @click="gotoPage">
                      <a href="#" class="item-link item-content">
                        <div class="item-media"><i class="icon list-item-img"
                            style="background-image: url({{js "this.Logo && this.Logo.OriginImage"}}); width: 44px; height: 44px"></i></div>
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title">{{Name}}</div>
                          </div>
                          <div class="item-subtitle">{{Summary}}</div>
                        </div>
                      </a>
                    </li>
                    {{/each}}
                  </ul>
                </div>
                {{else}}
                <!--<div class="block-title">Trang đang theo dõi</div>-->
                {{#js_if "this.pages && this.pages.length > 0"}}
                <div class="list media-list inset">
                  <ul>
                    {{#each pages}}
                    <li data-id="{{Page}}" @click="gotoPage">
                      <a href="#" class="item-link item-content">
                        <div class="item-media"><i class="icon list-item-img"
                            style="background-image: url({{js "this.Logo && this.Logo.OriginImage"}}); width: 44px; height: 44px"></i></div>
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title">{{Name}}</div>
                            <!--<div class="item-after text-color-blue" style="font-size: 0.8rem"><span
                                class="badge color-orange unread">{{Level.text}}</span></div>-->
                          </div>
                          <div class="item-text">{{Summary}}</div>
                        </div>
                      </a>
                    </li>
                    {{/each}}
                  </ul>
                </div>
                <div class="block-footer">
                  <div>
                    Bạn sẽ nhận được thông báo, các thông tin về chương trình bán hàng, các ưu đãi,... từ các trang này.
                  </div>
                </div>
                {{else}}
                <div class="block block-strong inset bg-color-orange text-color-white">
                    Tìm trang doanh nghiệp bạn muốn trở thành CTV bằng cách gõ vào ổ tìm kiếm ID của trang doanh nghiệp !
                </div>
                {{/js_if}}            
                <div class="block"></div>
                {{/if}}
              </div>
            </div>
        `,
        style: /*css*/`
          .site-info {
            margin-top: 20px;
          }
          .site-logo {
            height: 100px;
            border-radius: 3px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            margin: 0 auto;
          }
          .site-title {
            text-align: center;
            font-weight: bold;
          }
          .site-subtitle {
            text-align: center;
          }
          .notification-list {
            margin-left: calc(var(--f7-list-inset-side-margin) + var(--f7-safe-area-outer-left));
            margin-right: calc(var(--f7-list-inset-side-margin) + var(--f7-safe-area-outer-right));
          }
          .notification-list ul:before,
          .notification-list ul:after {
            height: 0;
          }
          .notification.home-notification {
            position: inherit;
            margin-bottom: 10px;
            box-shadow: inherit;
            display: block;
            left: inherit;
            top: inherit;
            width: inherit;
            overflow: hidden;
            max-width: inherit;
          }
          .notification.home-notification.swipeout-deleting {
              padding: inherit;
          }
          .notification.home-notification-list.list ul:before {
            height: 0px;
          }
          .notification.home-notification-list.list ul:after {
            height: 0px;
          }
          @media (min-width: 568px) {
            .notification.home-notification {
              left: inherit;
              width: inherit;
              margin-left: inherit;
            }
          }
        `,
        data() {
          const self: F7ComponentContextExtend = this;
          return {
            appVersion: $this.rootServices.env.version,
            coreVersion: '4.0',
            title: $this.title,
            pages: [],
            appName: $this.rootServices.env.name,
            appTitle: $this.rootServices.env.title,
            appCopyright: $this.rootServices.env.copyright,
            // notifications: self.$root.notifications,
          };
        },
        methods: {
          async refresh(e, done) {
            console.debug('refresh home');
            const self: F7ComponentContextExtend = this;
            const pageId = self.$route.params?.id;
            let doneTimeout = null;
            if (done) {
              doneTimeout = setTimeout(() => {
                done();
              }, 10000);
            };

            try {
              await $this.rootServices.authService.loginState$.pipe(filter(f => !!f), take(1)).toPromise();
              const pages = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/subscription-pages');

              self.$setState({
                pages: pages,
              });
            } catch (err) {
              console.error(err);
            }

            done && done();
            return true;
          },
          gotoLogin() {
            const self: F7ComponentContextExtend = this;
            self.$router.view.router.navigate('/login', { force: true });
          },
          subscribe(e: MouseEvent) {
            const self: F7ComponentContextExtend = this;
            const id = self.$(e.target).closest('.card').data('id');
            const recentLogin: RecentLoginModel = (self.recentLogins || []).find(f => f.id == id);
            // if(recentLogin) {
            //   $this.rootServices.authService.token$.next(new AuthToken(recentLogin.token));
            //   $this.rootServices.authService.loadUserInfoByToken(recentLogin.token);
            // }

            try {
              // $this.rootServices.authService.restoreLogin(id).then(async authToken => {
              //   if (authToken) {
              //     const token = (await $this.rootServices.authService.token$.pipe(filter(f => !!f), take(1)).toPromise()).getPayload();
              //     $this.rootServices.storage.get('recent_logins')
              //       .then((recentLogins: RecentLoginModel[]) => {
              //         recentLogins = recentLogins || [];
              //         let currentLogin = recentLogins.find(f => f.core === token.core && f.user === token.user);
              //         recentLogins = recentLogins.filter(f => f.core !== currentLogin.core || f.user !== currentLogin.user);
              //         const loginUser = $this.rootServices.authService.getUser();
              //         currentLogin.name = loginUser.name;
              //         currentLogin.avatar = loginUser.avatar && loginUser.avatar.payload && loginUser.avatar.payload.thumbnail;
              //         currentLogin.coreName = loginUser.core && loginUser.core.name;
              //         currentLogin.coreBanner = loginUser.core && loginUser.core.banner || '/assets/images/no-image-available.png';
              //         currentLogin.token = token;
              //         recentLogins.unshift(currentLogin);
              //         $this.rootServices.storage.set('recent_logins', recentLogins);

              //         const router: Router.Router = this.$f7router;
              //         self.$router.back('/home', { force: true });
              //       }).catch(err => {
              //         console.error(err);
              //         $this.commonService.showError(err);
              //       });


              //     self.$router.back('/');
              //   } else {
              //     self.$router.navigate('/login', {
              //       context: {
              //         recentLogin: recentLogin,
              //       }
              //     });
              //   }
              // }).catch(err => {
              //   self.$router.navigate('/login', {
              //     context: {
              //       recentLogin: recentLogin,
              //     }
              //   });
              //   console.error(err);
              //   $this.commonService.showError(err);
              // });
            } catch (err) {
              console.error(err);
              $this.commonService.showError(err);
            }
          },
          gotoPage(e: MouseEvent) {
            const self: F7ComponentContextExtend = this;
            const id = self.$(e.target).closest('li').data('id');
            $this.rootServices.navigate('/page/' + id + '/collaborator/products', {context: {onProductChoose: self.onProductChoose}});
          }
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          pageInit(e, page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;
            $this.onChangedState({ instance: self }, 'main');

            // $this.rootServices.storage.get('recent_logins').then((recentLogins: RecentLoginModel[]) => {
            //   if (!recentLogins || recentLogins.length === 0) {
            //     self.$router.view.router.navigate('/login', { animate: false });
            //   } else {
            //     self.$setState({
            //       recentLogins: recentLogins.map(item => {
            //         item.coreName = new URL(item.url).host;
            //         return item;
            //       }),
            //     });
            //   }
            // }).catch(err => {
            //   console.error(err);
            //   $this.commonService.showError(err);
            // });

            self.refresh().then(status => {
              console.log('load data for pages component success')
            });

            self.searchBar = self.$app.searchbar.create({
              el: '.pages-searchbar',
              backdrop: true,
              backdropEl: '.pages-searchbar-backdrop',
              searchContainer: '.list',
              searchIn: '.item-text',
              customSearch: true,
              on: {
                search(sb, query, previousQuery) {
                  if (query) {
                    $this.commonService.takeUntil('pages-searchbar', 600).then(() => {
                      console.log(query, previousQuery);
                      $this.rootServices.apiService.getPromise<any[]>('/collaborator/pages', {
                        // search: encodeURIComponent(query),
                        eq_Code: encodeURIComponent(query),
                        sort_Name: 'desc',
                        limit: 10,
                        offset: 0,
                      }).then(pages => {
                        self.$$(page.el).find('.page-content').scrollTo(0, 0);
                        self.$setState({ searchResults: pages.map(t => $this.prepareItem(t))});
                        self.infiniteOffset = 0;
                      }).catch(err => {
                        console.error(err);
                        $this.commonService.showError(err);
                      });
                    });
                  } else {
                    self.$setState({ searchResults: null });
                  }
                }
              }
            });
          },
          pageBeforeIn(e, page) {
            console.log('page before in');
          },
          pageAfterIn(e, page) {
            console.log('page after in');
          },
          pageBeforeOut(e, page) {
            console.log('page before out');
          },
          pageAfterOut(e, page) {
            console.log('page after out');
          },
          pageBeforeUnmount(e, page) {
            console.log('page before unmount');
          },
          pageBeforeRemove(e, page) {
            console.log('page before remove');
          },
        },
      },
    };
  }

  prepareItem(item: any) {
    return item;
  }

}
