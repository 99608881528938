import { DatePipe, CurrencyPipe } from '@angular/common';
import { filter, take } from 'rxjs/operators';
import { F7ComponentContext, F7Page } from '../../../types/framework7-types';
import { Router } from 'framework7/build/core/modules/router/router';
import { BehaviorSubject } from 'rxjs';
import { ComponentState, BaseComponent } from 'vendors/smart-bot-app/src/app/lib/base-component';
import { ChatRoom } from 'vendors/smart-bot-app/src/app/lib/nam-chat/chat-room';
import { TaskReminderModel } from 'vendors/smart-bot-app/src/app/model/task.model';
import { CommonService } from 'vendors/smart-bot-app/src/app/services/common.service';
import { RootServices } from 'vendors/smart-bot-app/src/app/services/root.services';
import { PhotoBrowser } from 'framework7/build/core/components/photo-browser/photo-browser';
import { CollaboratorEducationArticleModel } from 'src/app/model/collaborator.model';
import * as $ from 'jquery';

// declare const $: any;

export interface ComponentStateExtend extends ComponentState {
  [key: string]: any;
  articleList?: any[];
}

export class F7ComponentContextExtend extends F7ComponentContext {
  responseInProgress?: boolean;
  article?: CollaboratorEducationArticleModel;
  $root: any;
  photoBrowser?: PhotoBrowser.PhotoBrowser;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, reminder?: TaskReminderModel, chatRoom?: ChatRoom, reminderList$?: BehaviorSubject<TaskReminderModel[]> } };
}

/** Component group manager */
export class PageEducationArticleComponent extends BaseComponent<ComponentStateExtend> {
  // states: { [key: string]: State } = {};

  title = 'ProBox'

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
    public datePipe: DatePipe,
    public currencyPipe: CurrencyPipe,
  ) {
    super(rootServices);
    // console.log('Click here to open ts file');

  }

  get isPublicComponent() {
    return true;
  }

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      // Tab path
      name: 'page-education-article',
      path: '/:pageId/ctvbanhang/edu/:id',
      // Tab id
      id: 'page-education-article',
      // Fill this tab content from content string
      component: {
        template: /*html*/`
        <div class="page education-article" data-name="page-education-article">
          <div class="navbar">
            <div class="navbar-bg"></div>
            <div class="navbar-inner sliding">
              <div class="left">
                <a class="link back">
                  <i class="icon icon-back"></i>
                  <span class="if-not-md {{textColorClass}}">Back</span>
                </a>
              </div>
              <div class="title">{{objecttext this 'pageInfo.Name' 'ProBox'}}</div>
              <div class="right">
                <a class="link icon-only">
                  <i class="icon f7-icons">ellipsis</i>
                </a>
              </div>
            </div>
          </div>
          <div class="page-content ptr-content infinite-scroll-content" @ptr:refresh="refresh" data-ptr-distance="150">
            <div class="ptr-preloader">
              <div class="preloader"></div>
              <div class="ptr-arrow"></div>
            </div>
            <div class="block block-strong">
              {{article.Summary}}
            </div>
            <div class="block block-strong">
              {{article.ContentBlock1}}
            </div>
            <div class="block block-strong">
              {{article.ContentBlock2}}
            </div>
            <div class="block block-strong">
              {{article.ContentBlock3}}
            </div>
            <div class="block-title">Video đào tạo</div>
            <div class="block block-strong">
              <a data-href="{{article.VideoLink}}" @click="openLink">{{article.VideoLink}}</a>
            </div>
            <div class="block block-strong">
              {{article.VideoEmbed}}
            </div>
          </div>
        </div>
        `,
        style: /*css*/`
          .page.education-article .feature-image {
            border-radius: 0.3rem;
            height: 320px;
            background-repeat: no-repeat;
            background-size: cover;
          }
          .ios .page.education-article .feature-image {
            box-shadow: var(--f7-dialog-box-shadow);
          }
          .page.education-article .description img, 
          .page.education-article .description img {

          }
          .page.education-article .description *,
          .page.education-article .technical * {
            max-width: 100%;
          }
          .page.education-article .description figure,
          .page.education-article .technical figure {
            width: initial !important;
          }
          .page.education-article .description figure img,
          .page.education-article .technical figure img {
            border-radius: 0.3rem;
          }
          .page.education-article figure.media {
            margin: 0;
            border-radius: 0.4rem;
            overflow: hidden;
          }
        `,
        data() {
          return {
            title: $this.title,
            isLoggedIn: false,
            pageInfo: {},
            article: {},
          };
        },
        methods: {
          openLink(e) {
            const self: F7ComponentContextExtend = this;
            const link = $(e.currentTarget).data('href');
            $this.rootServices.openExtenalLink(link);
          },
          share(e) {
            const self: F7ComponentContextExtend = this;
            const link = `${self.article?.Summary}<br>
            Link đăng ký<br>
            https://probox.vn/${self.article.Page.toLowerCase()}/ctvbanhang/edu/${self.article.Code.toLowerCase()}`;
            // $this.rootServices.copyHtmlToClipboard(link);
            $this.rootServices.copyTextToClipboard(link);
          },
          gotoProduct(e) {
            const self: F7ComponentContextExtend = this;
            // const pageId = self.$route.params?.id;
            // const currentState = $this.state[]
            const id = self.$(e.target).closest('li').data('id')?.replace(/_\w+/, '')?.toLowerCase();
            $this.rootServices.navigate(`/${self.pageId.toLowerCase()}/ctvbanhang/product/` + id, { context: { registerRequired: true, pageId: self.pageId, pageInfo: self.pageInfo, onProductChoose: self.onProductChoose } });
          },
          copyLink(e) {
            const self: F7ComponentContextExtend = this;
            const link = `https://probox.vn/${self.pageId.toLowerCase()}/dtvbanhang/product/${self.$route.params?.id?.replace(/_\w+/, '')?.toLowerCase()}`;
            $this.rootServices.copyTextToClipboard(link);
          },
          copyContentBlock1(e) {
            const self: F7ComponentContextExtend = this;
            $this.rootServices.copyHtmlToClipboard(self.article.ContentBlock1);
          },
          copyContentBlock2(e) {
            const self: F7ComponentContextExtend = this;
            $this.rootServices.copyHtmlToClipboard(self.article.ContentBlock2);
          },
          copyContentBlock3(e) {
            const self: F7ComponentContextExtend = this;
            $this.rootServices.copyHtmlToClipboard(self.article.ContentBlock3);
          },
          orderProduct(e) {
            const self: F7ComponentContextExtend = this;
            const cardEle = $(e.target).closest('.order-form');
            const nameEle = cardEle.find('input[name="CustomerName"]');
            const phoneEle = cardEle.find('input[name="CustomerPhone"]');
            const addressEle = cardEle.find('input[name="CustomerAddress"]');

            let article = self.article;

            if (!article) {
              $this.commonService.showError('Sản phẩm không tồn tại');
              return;
            }
            if (!nameEle.val()) {
              $this.commonService.showError('Quý khách chưa điền tên !');
              return;
            }
            if (!phoneEle.val()) {
              $this.commonService.showError('Quý khách chưa điền số điện thoại !');
              return;
            }
            if (!addressEle.val()) {
              $this.commonService.showError('Quý khách chưa điền địa chỉ nhận hàng !');
              return;
            }

          },
          order(e) {
            const self: F7ComponentContextExtend = this;
            $this.commonService.navigate('/page-collaborator-order/new', {
              context: {
                article: self.article,
                pageId: self.pageId,
                pageInfo: self.pageInfo,
              }
            });
          },
          browsePictures(e) {
            // const self: F7ComponentContextExtend = this;
            // const article = self.article;
            // $this.browseAttachment(article.PicturesPreview as any, `${article.FeaturePicture.Store}/${article.FeaturePicture.Id}`);
          },
          subscribe(e) {
            const self: F7ComponentContextExtend = this;
            self.$app.dialog.confirm('Bạn có muốn đăng ký sản phẩm này không ? !', () => {
              $this.rootServices.apiService.putPromise('/collaborator/articles', { id: [self.article.Code], subscribe: true, page: self.article.Page }, [{ Code: self.article.Code }]).then(rs => {
                $this.commonService.showInfo('Đăng ký thành công');
                self.refresh();
              }).catch(err => {
                $this.commonService.showError(err);
              });
            });
          },
          chooseOne(e) {
            const self: F7ComponentContextExtend = this;
            const id = self.$(e.target).closest('li').data('id');
            self.$route.context['onChoose'] && self.$route.context['onChoose'](self.articleList.find(f => f.Code === id));
          },
          chooseProduct(e) {

          },
          async refresh(e, done) {
            const self: F7ComponentContextExtend = this;
            const articleId = self.$route.params?.id;
            const pageId = self.$route.params?.pageId;
            let doneTimeout = null;
            if (done) {
              doneTimeout = setTimeout(() => {
                done();
              }, 10000);
            };

            const article = await $this.rootServices.apiService.getPromise(`/collaborator/education-articles/${articleId.toUpperCase()}`, { skipAuth: true, page: self.$route.context['page'] }).then(rs => {
              return $this.preapreProduct({ ...rs[0], FeaturePicture: { ...rs[0].FeaturePicture, SmallImage: rs[0].FeaturePicture?.SmallImage } });
            });
            // if (article?.Units) {
            //   article.Unit = article.Unit || (article.Units && article.Units[0] && article.Units[0]['Unit'] || '');
            //   const unit = article.Units.find(f => f.Unit == article.Unit);
            //   if (unit) {
            //     article.Price = unit.Price;
            //     article.PriceText = $this.rootServices.currencyPipe.transform(article.Price, 'VND');
            //   }
            // }
            const pageInfo = self.pageInfo || await $this.rootServices.apiService.getPromise<any[]>('/collaborator/pages/' + pageId.toUpperCase(), { skipAuth: true }).then(rs => rs[0]);
            self.$setState({ article: { ...article || (self.$route.context && self.$route.context['params'] && self.$route.context['params'].articleToken) }, pageInfo: pageInfo });

            $this.rootServices.meta.updateTag({ property: 'or:url', content: `https://probox.vn/${self.article.Page.toLowerCase()}/ctvbanhang/edu/${self.article.Code.toLowerCase()}` });
            $this.rootServices.meta.updateTag({ property: 'or:title', content: article.Title });
            $this.rootServices.meta.updateTag({ property: 'or:description', content: article.Summary });

            if (done) done();
            return article;
          },
        },
        on: {
          pageInit(e, page: F7Page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;
            const articleId = self.$route.params?.id;

            const toolbarEl = self.$('.toolbar.main');
            // const navbarEl = self.$('.navbar') as any;
            const navbarEl = self.$app.navbar.getElByPage(page.el);
            toolbarEl.hide();
            self.$app.navbar.hide(navbarEl);
            $this.rootServices.authService?.loginState$.subscribe(status => {
              self.$setState({ isLoggedIn: status || false });
              if (status === true) {
                self.refresh();
                toolbarEl.show();
                self.$app.navbar.show(navbarEl);
              } else {
                toolbarEl.hide();
                self.$app.navbar.hide(navbarEl);
              }
            });

            $this.rootServices.authService?.loginState$.pipe(filter(f => f !== null), take(1)).toPromise().then(status => {
              self.$setState({ isLoggedIn: status || false });
            });
            self.$setState({ pageInfo: self.$route?.context['pageInfo'] });

            if ((self.$route?.context as any)?.onProductChoose) {
              self.$setState({
                onProductChoose: (e) => {
                  (self.$route?.context as any)?.onProductChoose(self.article);
                }
              })
            }

            $this.onComponentInit({ instance: self }, articleId, 'init').then(async currentState => {
              self.refresh();
            });

          },
          pageBeforeRemove(e, page) {
            console.log('[tab event] before remove', page?.route?.url);
            const self: F7ComponentContextExtend = this;
            const articleId = self.$route.params?.id;
            $this.onComponentRemove({ instance: self }, articleId);
          },
        },
      },
    };
  }

  onF7pageRemove(chatRoomId: string) {
  }

  preapreProduct(p: CollaboratorEducationArticleModel) {
    // p.Summary = this.rootServices.getTextVersion(p.Summary);
    // if (p.Categories && p.Categories.length > 0) {
    //   p.CategoriesText = p.Categories.map(m => m.text).join(', ');
    // }
    // if (p.FeaturePicture && p.FeaturePicture.Thumbnail) {
    //   p.FeaturePictureThumbnail = p.FeaturePicture.Thumbnail;
    // }
    // const taxValue = p.Tax && p.Tax.Tax || 0;
    // p.ToMoney = parseInt(p.Price as any) + parseInt(p.Price as any) * parseInt(taxValue as any) / 100;
    // p.ToMoneyText = this.rootServices.currencyPipe.transform(p.ToMoney, 'VND');;
    // p.PriceText = this.rootServices.currencyPipe.transform(p.Price, 'VND');
    // if (p.OriginPrice) {
    //   p.OriginPriceText = this.rootServices.currencyPipe.transform(p.OriginPrice, 'VND');
    // }

    // if (p.Pictures) {
    //   try {
    //     p.PicturesPreview = p.Pictures.map(picture => ({
    //       id: `${picture.Store}/${picture.Id}`,
    //       type: 'image',
    //       dataType: picture.Type,
    //       ext: picture.Extension,
    //       name: '',
    //       description: '',
    //       payload: {
    //         thumbnail: picture.Thumbnail,
    //         url: picture.DownloadLink,
    //         small: picture.SmallImage,
    //         large: picture.LargeImage,
    //         origin: picture.OriginImage,
    //       }
    //     })) as any;
    //   } catch (err) {
    //     console.warn(err);
    //     p.PicturesPreview = [];
    //   }
    // }

    return p;
  }

  async onComponentInit(state: ComponentStateExtend, index: string, asCase?: string) {
    return super.onComponentInit(state, index, asCase).then(currentState => {

      currentState.instance.refresh();
      return currentState;

    });
  }
}
