import { Messages } from 'framework7/build/core/components/messages/messages';
import { F7Messagebar, F7ComponentContext } from '../types/framework7-types';
import { Router } from 'framework7/build/core/modules/router/router';
import { Storage } from '@ionic/storage';
import { ComponentState, BaseComponent } from 'vendors/smart-bot-app/src/app/lib/base-component';
import { RecentLoginModel } from 'vendors/smart-bot-app/src/app/model/recent-login.model';
import { CommonService } from 'vendors/smart-bot-app/src/app/services/common.service';
import { RootServices } from 'vendors/smart-bot-app/src/app/services/root.services';

export interface ComponentStateExtend extends ComponentState {
    [key: string]: any;
}

export class F7ComponentContextExtend extends F7ComponentContext {
    messagebar?: F7Messagebar;
    messages?: Messages.Messages;
    images?: string[];
    responseInProgress?: boolean;
    answers?: string[];
    people?: { name?: string, avatar?: string }[];
    hideToolbar?: () => void;
    sheetToggle?: () => void;
    deleteAttachment?: (e: MouseEvent, index: number) => void;
    handleAttachment?: (e: MouseEvent) => void;
    checkAttachments?: () => void;
    sendMessage?: () => void;
    $root: any;
    // [key: string]: any;
}

/** Component group manager */
export class RegisterComponent extends BaseComponent<ComponentStateExtend>  {
    // states: { [key: string]: State } = {};

    constructor(
        public rootServices: RootServices,
        public commonService: CommonService,
    ) {
        super(rootServices);
        // console.log('Click here to open ts file');
    }

    onF7pageRemove(chatRoomId: string) {
        // if (this.chatRoomCacheList[chatRoomId]) {
        //   this.chatRoomCacheList[chatRoomId].disconnect();
        // }
    }

    get f7Component(): Router.RouteParameters {
        const $this = this;
        return {
            name: 'register',
            path: '/register',
            component: {
                template: /*html*/`
                <div class="page register no-toolbar no-navbar login-screen-pagex">
                    <div class="page-content login-screen-contentx">
                        <div class="block inset" style="padding: 0.5rem">
                            <div class="profile-info" style="display: flex">
                                <div class="profile-logo-x bg-color-gray" style="flex: 1; border-radius: 1rem; padding: 0; overflow: hidden">
                                    <img src="assets/images/baner-probox.vn.jpg" style="margin-bottom: -6px;">
                                </div>
                            </div>
                        </div>
                        <div class="block reason text-color-red">{{reason}}</div>
                
                        <form autocomplete="off">
                            <div class="list inset">
                                <ul>
                                    <li class="item-content item-input item-input-with-value">
                                        <div class="item-inner">
                                            <div class="item-title item-label">Tên <span class="text-color-red">(*)</span></div>
                                            <div class="item-input-wrap">
                                                <input @keypress="keyenter" autocomplete="new-password" autocapitalize="off" type="text"
                                                    placeholder="Tên đầy đủ của bạn" name="Name" class="input-with-value"
                                                    value="{{js "this.recentLogin && this.recentLogin.url || ''"}}">
                                                <span class="input-clear-button"></span>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="item-content item-input item-input-with-value">
                                        <div class="item-inner">
                                            <div class="item-title item-label">Số điện thoại <span class="text-color-red">(*)</span>
                                            </div>
                                            <div class="item-input-wrap">
                                                <input @keypress="keyenter" autocomplete="new-password" autocorrect="off"
                                                    autocapitalize="off" type="tel" placeholder="Số điện thoại của bạn" name="Phone"
                                                    value="{{js "this.recentLogin && this.recentLogin.username || ''"}}"
                                                    class="input-with-value">
                                                <span class="input-clear-button"></span>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="item-content item-input">
                                        <div class="item-inner">
                                            <div class="item-title item-label">Mật khẩu <span class="text-color-red">(*)</span></div>
                                            <div class="item-input-wrap">
                                                <input @keypress="keyenter" autocomplete="new-password" autocorrect="off"
                                                    autocapitalize="off" type="password" placeholder="Mật khẩu" name="Password"
                                                    value=""><span class="input-clear-button"></span>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="item-content item-input">
                                        <div class="item-inner">
                                            <div class="item-title item-label">Nhập lại mật khẩu <span class="text-color-red">(*)</span>
                                            </div>
                                            <div class="item-input-wrap">
                                                <input @keypress="keyenter" autocomplete="new-password" autocorrect="off"
                                                    autocapitalize="off" type="password" placeholder="Nhập lại mật khẩu"
                                                    name="RePassword" value=""><span class="input-clear-button"></span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="block">
                                <div class="row">
                                    <a href="#" class="col color-gray button button-large button-outline back"><i class="icon f7-icons if-not-md">chevron_compact_left</i>Trở về</a>
                                    <a href="#" class="col color-green button button-large button-fill" @click="register">Đăng ký<i class="icon f7-icons if-not-md">chevron_compact_right</i></a>
                                </div>
                            </div>
                        </form>
                        <div class="block reason text-color-red">{{term}}</div>

                        <br>

                        <div style="text-align: center">{{appTitle}}</div>
                        <div style="text-align: center">{{appCopyright}}</div>
                        <br>
                        <br>
                        {{#js_if "this.platform == 'browser'"}}
                        <div style="text-align: center">
                            <a data-href="https://probox.one" @click="openExternalLink">Bạn đang tìm giải pháp quản trị CTV ?</a>
                        </div>
                        {{/js_if}}
                    </div>
                </div>
                `,
                style: /*css*/`
                `,
                data() {
                    const self: F7ComponentContextExtend = this;
                    return {
                        notifications: self.$root.notifications,
                        // reason: '',
                        // navigateOnSuccess: '',
                        appName: $this.rootServices.env.name,
                        appTitle: $this.rootServices.env.title,
                        appCopyright: $this.rootServices.env.copyright,
                        platform: $this.rootServices.device?.platform || 'browser',
                    };
                },
                methods: {
                    async refresh() {
                        const self: F7ComponentContextExtend = this;
                        return true;
                    },
                    keyenter(e: KeyboardEvent) {
                        const self: F7ComponentContextExtend = this;
                        // console.log(e);
                        if (e.key === 'Enter') {
                            self.login();
                        }
                    },
                    async tryVerifiedOtp(otpToken: string, phone: string, password: string, name: string, agent: string, message?: string, tryTime?: number) {
                        let url: string = $this.rootServices.env.api.defaultUrl;
                        const self: F7ComponentContextExtend = this;
                        return new Promise((resolve, reject) => {
                            let dialog = self.$app.dialog.prompt(message || 'Hệ thống đã gửi mã OTP, hãy kiểm tra tin nhắn và cung cấp mã OTP để xác thực (hết hạn trong 120s)', 'Xác thực OTP', (otp) => {
                                if (otpToken) {
                                    self.$app.preloader.show();
                                    $this.rootServices.authService.register({ url, phone, password, name, agent: agent, otpToken, otp }).then(async rs => {
                                        self.$app.preloader.hide();
                                        resolve(rs);
                                        // dialog.close();
                                    }).catch(err => {
                                        self.$app.preloader.hide();
                                        $this.commonService.showError(err);
                                        tryTime = (tryTime || 0) + 1;
                                        if (tryTime < 3) {
                                            resolve(self.tryVerifiedOtp(otpToken, phone, password, name, agent, 'Mã OTP không hợp lệ, hãy thử lại (' + tryTime + '/3)', tryTime));
                                        } else {
                                            reject('max try count');
                                        }
                                    });
                                } else {
                                    $this.commonService.showError('Bạn chưa điền mã OPT !');
                                    resolve(self.tryVerifiedOtp(otpToken, phone, password, name, agent));
                                }
                            });
                            dialog.open();
                            // dialog.on('close', () => {
                            //     reject('cancel');
                            // });
                        });
                    },
                    register(e: MouseEvent) {
                        const self: F7ComponentContextExtend = this;

                        const nameEle = self.$('form').find('input[name="Name"]');
                        const phoneEle = self.$('form').find('input[name="Phone"]');
                        const passwordEle = self.$('form').find('input[name="Password"]');
                        const rePasswordEle = self.$('form').find('input[name="RePassword"]');

                        const name: string = (nameEle.val() || '').trim();
                        const phone: string = (phoneEle.val() || '').replace(/[^0-9]/g, '');
                        const password: string = (passwordEle.val() || '').trim();
                        const rePassword: string = (rePasswordEle.val() || '').trim();
                        const url = $this.rootServices.env.api.defaultUrl;

                        if (!name) {
                            $this.commonService.showError('Bạn chưa điền tên');
                            return false;
                        }
                        if (!phone) {
                            $this.commonService.showError('Bạn chưa điền số điện thoại');
                            return false;
                        }
                        if (phone.length < 10 || phone.length > 12) {
                            $this.commonService.showError('Số điện thoại không hợp lệ');
                            return false;
                        }
                        if (!password) {
                            $this.commonService.showError('Bạn chưa điền mật khẩu');
                            return false;
                        }
                        if (password.length < 6) {
                            $this.commonService.showError('Mật khẩu quá yếu');
                            return false;
                        }
                        if (password !== rePassword) {
                            $this.commonService.showError('Mật khẩu lần 2 chưa khớp');
                            return false;
                        }
                        self.$app.preloader.show();
                        $this.rootServices.authService.register({ url, phone, password, name, agent: 'proboxapp' }).then(async rs => {
                            self.$app.preloader.hide();
                            if (rs['error'] == 201) {
                                console.error(rs['message']);
                                // await new Promise((resolve, reject) => {
                                // self.tryVerifiedOtp(rs['otpToken'], phone, password, name, 'proboxapp').then(rs => resolve(rs)).catch(err => reject(err));
                                let tryTime = 1
                                await $this.rootServices.enterPasscodePopup(
                                    'Điền mã xác thực OTP',
                                        /*html*/`
                                            <div class="block-title">Bạn hãy vào Zalo để kiểm tra tin nhắn OTP</div>
                                            <div class="block block-strong inset" style="padding: 0; overflow: hidden; line-height: 0">
                                                <img src="assets/images/zalo-otp.png" style="width: 100%">
                                            </div>
                                        `, 4, {
                                    swipeToClose: false,
                                    onFullFill: (otp) => {
                                        self.$app.preloader.show();
                                        return $this.rootServices.authService.register({ url, phone, password, name, agent: 'proboxapp', otpToken: rs['otpToken'], otp }).then(async rs => {
                                            self.$app.preloader.hide();
                                            return true;
                                        }).catch(err => {
                                            self.$app.preloader.hide();
                                            $this.commonService.showError(err);
                                            tryTime++;
                                            if (tryTime <= 3) {
                                                // self.tryVerifiedOtp(phone, 'Mã OTP không hợp lệ, hãy thử lại (' + tryTime + '/3)', tryTime);
                                                return Promise.reject({ error: 422, message: 'Mã OTP không hợp lệ, hãy thử lại (' + tryTime + '/3)' });
                                            }
                                            tryTime = 1;
                                            return Promise.reject({ error: 400, message: 'Mã OTP đã hết hiệu lực !' });
                                        });

                                    }
                                });
                                // });
                            }
                            self.$app.preloader.show();
                            $this.rootServices.authService.login({ username: phone, password: password, url }).then(async authToken => {
                                console.log(authToken);
                                self.$app.preloader.hide();
                                if (self.navigateOnSuccess) {
                                    if (self.navigateOnSuccess == 'goback') {
                                        const view = self.$router.view;
                                        while (view.history[view.history.length - 1] == '/login' || view.history[view.history.length - 1] == '/register') {
                                            console.log('Route back');
                                            view.router.back();
                                            await $this.commonService.waiting(300);
                                        }
                                    }
                                    if (self.$route?.context['afterRegister']) {
                                        self.$route?.context['afterRegister']();
                                    }
                                } else {
                                    self.$router.back('/home', { force: true });
                                }
                            }).catch(err => {
                                self.$app.preloader.hide();
                            });
                        }).catch(err => {
                            console.error(err);
                            self.$app.preloader.hide();
                            $this.commonService.showError(err && err.status === 0 ? 'Domain not found' : (err && err.error && err.error.logs && err.error.logs[0]));
                        });
                    }
                },
                on: {
                    pageInit(e, page) {
                        console.log('page init');
                        const self: F7ComponentContext = this;
                        $this.onChangedState({ instance: self }, 'main');
                    },
                }
            },
        };
    }
}
