import { F7ComponentContext } from '../types/framework7-types';
import { Messages } from 'framework7/build/core/components/messages/messages';
import { F7Messagebar } from '../types/framework7-types';
import { Router } from 'framework7/build/core/modules/router/router';
import { BaseComponent, ComponentState } from '../lib/base-component';
import { RootServices } from '../services/root.services';
import { filter, take } from 'rxjs/operators';
import { NotificationModel } from '../model/notification.model';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
import { isPlatform } from '@ionic/angular';
import { CommonService } from '../services/common.service';
// import * as $ from 'jquery';
declare const $: any;

// declare const $: any;

export interface ComponentStateExtend extends ComponentState {
  [key: string]: any;
}

export class F7ComponentContextExtend extends F7ComponentContext {
  messagebar?: F7Messagebar;
  messages?: Messages.Messages;
  images?: string[];
  responseInProgress?: boolean;
  answers?: string[];
  people?: { name?: string, avatar?: string }[];
  hideToolbar?: () => void;
  sheetToggle?: () => void;
  deleteAttachment?: (e: MouseEvent, index: number) => void;
  handleAttachment?: (e: MouseEvent) => void;
  checkAttachments?: () => void;
  sendMessage?: () => void;
  $root: any;
  notifications?: NotificationModel[];
  // [key: string]: any;
}

/** Component group manager */
export class HomeComponent extends BaseComponent<ComponentStateExtend> {
  // states: { [key: string]: State } = {};

  title = 'Thông báo';
  newNotification$ = new BehaviorSubject<NotificationModel>(null);

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
  ) {
    super(rootServices);
    // // console.log('Click here to open ts file');
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: 'home',
      path: '/home',
      component: {
        template: /*html*/`
            <div class="page home" data-name="home">
              <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a class="panel-open{{js "this.disabledLeftPannel ? '-disable' : ''"}} link icon-only">
                      <i class="icon f7-icons">bars</i>
                    </a>
                  </div>
                  <div class="title">Smart-BOT</div>
                  <div class="right">
                    <a class="link icon-only">
                      <i class="icon f7-icons">ellipsis</i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="page-content ptr-content infinite-scroll-content" data-ptr-mousewheel="false" @ptr:refresh="refresh">
                <div class="ptr-preloader">
                  <div class="preloader"></div>
                  <div class="ptr-arrow"></div>
                </div>
                <div class="searchbar-backdrop workplace-searchbar-backdrop"></div>
            
                <div class="block block-strong inset site-info" style="{{js "this.backgroundLogoColor ? ('background-color: ' + this.backgroundLogoColor) : ''"}}">
                  <div class="site-logo" style="background-image: url({{js "this.logo || 'assets/images/logo_probox_one.png'"}})"></div>
                  <!--<div class="site-subtitle">Công Ty Cổ Phần Công Nghệ ProBox</div>-->
                </div>
            
            
                <div class="list media-list inset">
                  <ul>
                    <li class="item-link" @click="gotoAccount">
                      <div class="item-content">
                        <div class="item-media">
                          <div
                            style="width: 44px; height: 44px; border-radius: 50%; background-image: url({{js "this.profile && this.profile.Avatar && this.profile.Avatar.payload && this.profile.Avatar.payload.thumbnail"}}); background-repeat: no-repeat; background-position: center; background-size: cover;">
                          </div>
                        </div>
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title">{{js "this.profile && this.profile.Name"}}</div>
                          </div>
                          <div class="item-subtitle">{{js "this.profile && this.profile.Core"}}</div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                <div class="row block shortcut-board">
                  <div class="col-50 shortcut-item">
                    <div class="card" @click="scanToCheckAccessNumber">
                      <div class="card-content card-content-padding"><i class="icon f7-icons text-color-default" style="{{js "this.backgroundLogoColor ? ('color: ' + this.backgroundLogoColor + ' !important') : ''"}}">barcode_viewfinder</i></div>
                      <div class="card-footer">Truy xuất</div>
                    </div>
                  </div>
                  <div class="col-50 shortcut-item">
                    <div class="card" @click="goto" data-href="/commerce-pos/gui/new">
                      <div class="card-content card-content-padding"><i class="icon f7-icons text-color-default" style="{{js "this.backgroundLogoColor ? ('color: ' + this.backgroundLogoColor + ' !important') : ''"}}">sidebar_right</i></div>
                      <div class="card-footer">POS</div>
                    </div>
                  </div>
                  <div class="col-50 shortcut-item">
                    <div class="card" @click="goto" data-href="/purchase/product-list/preview">
                      <div class="card-content card-content-padding"><i class="icon f7-icons text-color-default" style="{{js "this.backgroundLogoColor ? ('color: ' + this.backgroundLogoColor + ' !important') : ''"}}">tray_arrow_down</i></div>
                      <div class="card-footer">Giá nhập</div>
                    </div>
                  </div>
                  <div class="col-50 shortcut-item">
                    <div class="card" @click="goto" data-href="/sales/goods-list">
                      <div class="card-content card-content-padding"><i class="icon f7-icons text-color-default" style="{{js "this.backgroundLogoColor ? ('color: ' + this.backgroundLogoColor + ' !important') : ''"}}">doc_text_search</i></div>
                      <div class="card-footer">Bảng giá gốc</div>
                    </div>
                  </div>
                  <div class="col-50 shortcut-item">
                    <div class="card" @click="goto" data-href="/accounting/dashboard">
                      <div class="card-content card-content-padding"><i class="icon f7-icons text-color-default" style="{{js "this.backgroundLogoColor ? ('color: ' + this.backgroundLogoColor + ' !important') : ''"}}">chart_bar_square</i></div>
                      <div class="card-footer">Dashboard</div>
                    </div>
                  </div>
                  <div class="col-50 shortcut-item">
                    <div class="card" @click="goto" data-href="/purchase/order/new">
                      <div class="card-content card-content-padding"><i class="icon f7-icons text-color-default" style="{{js "this.backgroundLogoColor ? ('color: ' + this.backgroundLogoColor + ' !important') : ''"}}">cart</i></div>
                      <div class="card-footer">Đặt mua hàng</div>
                    </div>
                  </div>
                  <div class="col-50 shortcut-item">
                    <div class="card" @click="goto" data-href="/warehouse/goods-list">
                      <div class="card-content card-content-padding"><i class="icon f7-icons text-color-default" style="{{js "this.backgroundLogoColor ? ('color: ' + this.backgroundLogoColor + ' !important') : ''"}}">cube</i></div>
                      <div class="card-footer">Tồn kho</div>
                    </div>
                  </div>
                  <div class="col-50 shortcut-item">
                    <div class="card" @click="goto" data-href="/warehouse/inventory-adjust-note-form/new">
                      <div class="card-content card-content-padding"><i class="icon f7-icons text-color-default" style="{{js "this.backgroundLogoColor ? ('color: ' + this.backgroundLogoColor + ' !important') : ''"}}">text_badge_checkmark</i></div>
                      <div class="card-footer">Kiểm kho</div>
                    </div>
                  </div>
                  <div class="col-50 shortcut-item">
                    <div class="card" @click="goto" data-href="/contact/contact-list">
                      <div class="card-content card-content-padding"><i class="icon f7-icons text-color-default" style="{{js "this.backgroundLogoColor ? ('color: ' + this.backgroundLogoColor + ' !important') : ''"}}">person_alt_circle</i></div>
                      <div class="card-footer">Liên hệ</div>
                    </div>
                  </div>
                  <div class="col-50 shortcut-item">
                    <div class="card" @click="goto" data-href="/settings">
                      <div class="card-content card-content-padding"><i class="icon f7-icons text-color-default" style="{{js "this.backgroundLogoColor ? ('color: ' + this.backgroundLogoColor + ' !important') : ''"}}">gear</i></div>
                      <div class="card-footer">Cài đặt</div>
                    </div>
                  </div>
                </div>

                <div class="block">
                  <button class="button button-outline button-large disabled">Thêm lối tắc</button>
                </div>
            
                
              </div>
            </div>
        `,
        style: /*css*/`
        .shortcut-board {

        }
        .shortcut-item {
          margin-bottom: 1rem;
        }
        .shortcut-item .card {
          margin: 0; cursor: pointer
        }
        .shortcut-item .card .card-footer {
          justify-content: center;
        }
        .shortcut-item .card .card-content {
          text-align: center;
        }
        .shortcut-item .card .card-content .icon {
          font-size: 4rem;
        }
        .page.home .notification-icon {
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: 50%;
        }
        .page.home .notification-content {
          
        }
        .page.home .notification-title {
          opacity: 0.6;
        }
        .page.home .notification-moment {
          opacity: 0.5;
          font-size: 11px;
        }
        `,
        data() {
          const self: F7ComponentContextExtend = this;
          return {
            title: $this.title,
            // notifications: self.$root.notifications,
            disabledLeftPannel: false && isPlatform('desktop'),
            infiniteLoadLast: true,
          };
        },
        methods: {
          scanToPO(){
            const self: F7ComponentContextExtend = this;
            $this.rootServices.appComponent.processor.scan2PO();
          },
          scanToCheckAccessNumber(){
            const self: F7ComponentContextExtend = this;
            $this.rootServices.appComponent.processor.scanToCheckAccessNumber();
          },
          goto(e) {
            const self: F7ComponentContextExtend = this;
            let href = $(e.currentTarget).data('href');

            $this.rootServices.navigate(href);
            return true;
          },
          async refresh(e, done) {
            console.debug('refresh home');

            let doneTimeout = null;
            if (done) {
              doneTimeout = setTimeout(() => {
                done();
              }, 10000);
            };

            const self: F7ComponentContextExtend = this;
            // if ($this.rootServices.authService.loginState$) {
            $this.rootServices.authService.loginState$.pipe(filter(state => state === true), take(1)).toPromise().then(status => {
              $this.rootServices.apiService.getPromise<NotificationModel[]>('/notification/notifications/byCurrentUser', {
                limit: 10,
                sort_Id: 'desc',
                silient: true,
              }).then(notifications => {
                self.$setState({
                  notifications: notifications.map(m => {
                    return $this.preapreTaskState(m);
                    // m.DateOfCreate = moment(m.DateOfCreate).fromNow();
                    // m.Content = $this.commonService.cleanMentionTags(m.Content);
                    // return m;
                  }),
                  infiniteLoadLast: notifications.length === 0
                });
                self.updateBadge();
                self.infiniteOffset = 10;
                if (doneTimeout) clearTimeout(doneTimeout);
                if (done) done();
              }).catch(err => {
                console.error(err);
                // $this.commonService.showError(err);
                if (done) done();
                if (doneTimeout) clearTimeout(doneTimeout);
              });
            });
            // }
            return true;
          },
          onNotificationClick() {
            const self: F7ComponentContextExtend = this;
            const router: Router.Router = this.$f7router;
            router.navigate('/chat-room/3214234', { context: { backTitle: self.title } });
          },
          gotoAccount() {
            const self: F7ComponentContextExtend = this;
            $this.rootServices.navigate('/account', { context: { backTitle: 'Home' } });
          },
          removeNotification(e) {
            const self: F7ComponentContextExtend = this;
            const currentSwipeout: any = self.$(e.target).closest('li');
            const notificationId = currentSwipeout.data('id') || self.$(e.target).data('id');

            $this.rootServices.apiService.deletePromise('/notification/notifications/' + notificationId, { byCurrentUser: true }).then(rs => {
              console.debug(rs);
              self.$app.swipeout.delete(currentSwipeout[0], () => {
              });
              $this.rootServices.homeBadge--;
            }).catch(err => {
              console.error(err);
              self.$app.swipeout.close(currentSwipeout[0]);
              $this.commonService.showError(err);
            });

          },
          openNotification(e) {
            const self: F7ComponentContextExtend = this;
            const el = self.$(e.target).closest('li');
            const notificationId = el.data('id');
            if (self.notifications) {
              const notification = self.notifications.find(f => f.Id === notificationId);
              if (notification) {
                switch (notification.Type) {
                  case 'CHATROOM':
                    // self.$app.swipeout.delete(el[0] as HTMLElement, () => {
                    $this.rootServices.navigate('/chat-room/' + notification.Data.room);
                    // });
                    $this.rootServices.apiService.putPromise('/notification/notifications/updateReceiverState', { state: 'ACTIVE' }, [{ Id: notification.Id }]).then(rs => {
                      console.debug(rs);
                      // $this.rootServices.homeBadge--;
                      self.updateBadge();
                    }).catch(err => {
                      console.error(err);
                      $this.commonService.showError(err);
                    });
                    // $this.rootServices.apiService.deletePromise('/notification/notifications/' + notificationId, { byCurrentUser: true }).then(rs => {
                    //   console.debug(rs);
                    //   // $this.rootServices.homeBadge--;
                    //   self.updateBadge();
                    // }).catch(err => {
                    //   console.error(err);
                    //   $this.commonService.showError(err);
                    // });
                    break;
                }
              }
            }
          },
          clearNotifications(e) {
            const self: F7ComponentContextExtend = this;
            const confirmRemove = self.$app.actions.create({
              buttons: [
                [
                  {
                    text: 'Tất cả thông báo sẽ được lưu trữ trong vòng 7 ngày !',
                    label: true,
                  },
                  {
                    text: 'Lưu trữ tất cả',
                    color: 'red',
                    icon: '<i class="icon f7-icons">text_badge_minus</i>',
                    bold: true,
                    async onClick() {
                      while (true) {
                        const buffer = self.notifications.splice(0, 50).map(notf => notf.Id);
                        // const buffer = notificationIds;
                        if (buffer && buffer.length > 0) {
                          $this.rootServices.apiService.deletePromise('/notification/notifications', { id: buffer, byCurrentUser: true }).then(rs => {
                            self.$setState({
                              notifications: self.notifications,
                            });
                          }).catch(err => {
                            console.error(err);
                            $this.commonService.showError(err);
                          });
                        } else {
                          break;
                        }
                      }

                      // for (const notification of self.notifications) {
                      //   const currentSwipeout = self.$('.sw-notification[data-id="' + notification.Id + '"]');
                      //   if (currentSwipeout.length === 0) {
                      //     continue;
                      //   }
                      //   await new Promise(resolve => {
                      //     $this.rootServices.apiService.deletePromise('/notification/notifications/' + notification.Id, { byCurrentUser: true }).then(rs => {
                      //       console.debug(rs);
                      //       self.$app.swipeout.delete(currentSwipeout[0] as HTMLElement, () => {
                      //         resolve(true);
                      //       });
                      //     }).catch(err => {
                      //       console.error(err);
                      //       self.$app.swipeout.close(currentSwipeout[0] as HTMLElement);
                      //       $this.commonService.showError(err);
                      //       resolve(false);
                      //     });
                      //   });
                      // }
                      // self.notifications = [];
                      self.refresh();
                      // self.$setState({
                      //   notifications: self.notifications,
                      // });
                      // $this.rootServices.homeBadge = 0;

                    }
                  }
                ],
                [{
                  text: 'Trở về',
                  icon: '<i class="icon f7-icons">arrow_down_circle_fill</i>',
                  bold: true,
                  onClick() {
                  }
                }],
              ] as any
            });
            confirmRemove.open();

          },
          updateBadge(e) {
            const self: F7ComponentContextExtend = this;
            setTimeout(() => {
              if (self.notifications) {
                $this.rootServices.homeBadge = self.notifications.length;
                $this.rootServices.badge.set($this.rootServices.homeBadge);
              }
            }, 300);
          },
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          pageInit(e, page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;
            $this.onChangedState({ instance: self }, 'main');

            $this.rootServices.systemSettings$.pipe(filter(f => !!f)).subscribe(systemConfigs => {
              self.$setState({
                logo: systemConfigs.LICENSE_INFO.register.mainLogo,
                backgroundLogoColor: systemConfigs.LICENSE_INFO.register.backgroundLogoColor,
              });
            });

            $this.rootServices.authService.user$.pipe(filter(profile => !!profile)).subscribe(async profile => {
              // profile.avatar.payload.thumbnail += ('?token=' + $this.rootServices.apiService.token.access_token); 
              // profile.Role = 'Member';
              let coreDomain = await $this.rootServices.authService.token$.pipe(filter(token => !!token), take(1)).toPromise().then(token => token.getPayload().api_url);
              if (coreDomain) {
                const url = new URL(coreDomain);
                coreDomain = url.host;
              }

              self.$setState({
                profile: {
                  Code: profile.id,
                  Name: profile.name,
                  ShortName: profile.shortName,
                  Avatar: profile.avatar && {
                    id: profile.avatar.id,
                    payload: {
                      thumbnail: profile.avatar.payload.thumbnail,
                    }
                  },
                  Role: profile.role || 'Undefined',
                  Core: coreDomain || 'Undefined'
                },
              });
            });

            // Load notifications
            // $this.rootServices.authService.loginState$.pipe(filter(state => state), take(1)).subscribe(state => {
            //   $this.rootServices.apiService.getPromise<NotificationModel[]>('/notification/notifications/byCurrentUser', { limit: 10 }).then(notifications => {
            //     self.$setState({
            //       notifications: notifications.map(m => {
            //         m.DateOfCreate = moment(m.DateOfCreate).fromNow();
            //         m.Content = $this.commonService.cleanMentionTags(m.Content);
            //         return m;
            //       }),
            //     });
            //     // $this.rootServices.homeBadge = notifications.length;
            //     self.updateBadge();
            //   }).catch(err => {
            //     console.error(err);
            //     // $this.commonService.showError(err);
            //   });
            // });

            // $this.rootServices.platform.resume.subscribe(() => {
            //   $this.rootServices.apiService.getPromise<NotificationModel[]>('/notification/notifications/byCurrentUser', { limit: 10 }).then(notifications => {
            //     self.$setState({
            //       notifications: notifications.map(m => {
            //         m.DateOfCreate = moment(m.DateOfCreate).fromNow();
            //         m.Content = $this.commonService.cleanMentionTags(m.Content);
            //         return m;
            //       }),
            //     });
            //     // $this.rootServices.homeBadge = notifications.length;
            //     self.updateBadge();
            //   }).catch(err => {
            //     console.error(err);
            //     $this.commonService.showError(err);
            //   });
            // });
            self.refresh();

            // self.$app.on('swipeoutDelete', (el => {
            //   console.log(el);
            //   const notificationId = self.$(el).data('id');
            //   $this.rootServices.apiService.deletePromise('/notification/notifications/' + notificationId, { byCurrentUser: true }).then(rs => {
            //     console.debug(rs);
            //   });
            // }));

            $this.newNotification$.pipe(filter(f => !!f)).subscribe(newNotification => {
              newNotification.DateOfCreateMoment = moment(new Date()).fromNow();
              self.notifications && self.notifications.unshift(newNotification);
              self.$setState({
                notifications: self.notifications,
              });
              self.updateBadge();
            });

            // Attach 'infinite' event handler
            // Loading flag
            let allowInfinite = true;

            // Last loaded index
            var lastItemIndex = self.$$(page.el).find('.list li').length;
            self.infiniteOffset = 0;

            self.$$(page.el).find('.infinite-scroll-content').on('infinite', function () {
              // Exit, if loading in progress
              if (!allowInfinite) return;

              // Set loading flag
              allowInfinite = false;

              // Emulate 1s loading
              $this.rootServices.apiService.getPromise<NotificationModel[]>('/notification/notifications/byCurrentUser', {
                // search: self.searchBar.query,
                // sort_LastUpdate: 'desc',
                sort_Id: 'desc',
                limit: 10,
                offset: self.infiniteOffset,
                silient: true,
                // ne_Type: 'HELPDESK',
                // ...self.filter,
              }).then(notifications => {
                // chatRooms.map(t => {
                //     // Moment.now()
                //     // const unread = t.NumOfMessage - t.LoggedNumOfMessage - t.LoggedNumOfReadMessage;
                //     // t.LastUpdate_Moment = moment(t.LastUpdate).fromNow();
                //     // t.DateOfCreate = new Date(t.DateOfCreate).toLocaleString();
                //     // // t.Infos.Request_Date_Time = new Date(t.Infos.Request_Date_Time).toLocaleTimeString();
                //     // t.NumOfUnreadMessage = unread > 0 ? unread : '';
                //     // $this.preapreTaskState(t);
                //     return t;
                // });
                self.notifications = self.notifications.concat(notifications);
                self.infiniteOffset += 10;
                self.$setState({
                  notifications: self.notifications.map(m => {
                    return $this.preapreTaskState(m);
                  }),
                  infiniteOffset: self.infiniteOffset,
                  infiniteLoadLast: notifications.length === 0
                });
                lastItemIndex = self.$$(page.el).find('.list li').length;
                allowInfinite = true;

                self.updateBadge();
              }).catch(err => {
                console.error(err);
                // $this.commonService.showError(err);
              });
            });
          },
          pageBeforeIn(e, page) {
            console.log('page before in');
          },
          pageAfterIn(e, page) {
            console.log('page after in');
          },
          pageBeforeOut(e, page) {
            console.log('page before out');
          },
          pageAfterOut(e, page) {
            console.log('page after out');
          },
          pageBeforeUnmount(e, page) {
            console.log('page before unmount');
          },
          pageBeforeRemove(e, page) {
            console.log('page before remove');
          },
        },
      },
    };
  }

  stateMap = {
    NEW: {
      text: 'mới',
      color: 'color-red',
    },
    READ: {
      text: 'đã xem',
      color: 'color-gray',
    },
    ACTIVE: {
      text: 'đã mở',
      color: 'color-green',
    },
  };
  preapreTaskState(notification: NotificationModel) {
    // notification.DateOfCreate = moment(notification.DateOfCreate).fromNow();
    notification.DateOfCreateMoment = moment(notification.DateOfCreate).fromNow();
    notification.Content = this.commonService.cleanMentionTags(notification.Content);
    notification['SenderAvatarThumbnail'] = this.rootServices.apiService.getBaseApiUrl() + '/user/users/' + this.rootServices.commonService.getObjectId(notification['Sender']) + '/avatar';
    const state = this.stateMap[notification.State];
    notification['StateText'] = state?.text || 'mới';
    notification['StateColor'] = state?.color || 'mới';
    return notification;
  }
}
