import { Messages } from 'framework7/build/core/components/messages/messages';
import { F7Messagebar, F7ComponentContext } from '../types/framework7-types';
import { Router } from 'framework7/build/core/modules/router/router';
import { AuthService } from '../services/auth.service';
import { Storage } from '@ionic/storage';
import { BaseComponent, ComponentState } from '../lib/base-component';
import { RootServices } from '../services/root.services';
import { RecentLoginModel } from '../model/recent-login.model';
import { CommonService } from '../services/common.service';

export interface ComponentStateExtend extends ComponentState {
    [key: string]: any;
}

export class F7ComponentContextExtend extends F7ComponentContext {
    messagebar?: F7Messagebar;
    messages?: Messages.Messages;
    images?: string[];
    responseInProgress?: boolean;
    answers?: string[];
    people?: { name?: string, avatar?: string }[];
    hideToolbar?: () => void;
    sheetToggle?: () => void;
    deleteAttachment?: (e: MouseEvent, index: number) => void;
    handleAttachment?: (e: MouseEvent) => void;
    checkAttachments?: () => void;
    sendMessage?: () => void;
    $root: any;
    // [key: string]: any;
}

/** Component group manager */
export class LoginComponent extends BaseComponent<ComponentStateExtend>  {
    // states: { [key: string]: State } = {};

    constructor(
        public rootServices: RootServices,
        public commonService: CommonService,
    ) {
        super(rootServices);
        // // console.log('Click here to open ts file');
    }

    onF7pageRemove(chatRoomId: string) {
        // if (this.chatRoomCacheList[chatRoomId]) {
        //   this.chatRoomCacheList[chatRoomId].disconnect();
        // }
    }

    get f7Component(): Router.RouteParameters {
        const $this = this;
        return {
            name: 'login',
            path: '/login',
            component: {
                template: /*html*/`
                <div class="page no-toolbar no-navbar no-swipeback login-screen-pagex">
                    <div class="page-content login-screen-contentx">
                        <div class="block inset" style="padding: 0.5rem">
                            <div class="profile-info" style="display: flex">
                                <div class="avatar" style="">
                                    <div class="profile-logo bg-color-gray" style="border-radius: 20%; background-image: url(assets/icon/icon-1024.png)"></div>
                                </div>
                                <div class="info" style="flex: 1; padding-left: 0.5rem; font-size: 0.7rem">
                                    <div style="font-size: 2rem; font-weight: bold; line-height: 2rem;">Smart-BOT</div>
                                    <div class="text-color-gray" style="text-align: left">{{appTitle}}<br>{{appCopyright}}<br>Version {{appVersion}} core {{coreVersion}}</div>
                                </div>
                            </div>
                        </div>

                        <form autocomplete="off">
                            <div class="list inset">
                                <ul>
                                    <li class="item-content item-input item-input-with-value">
                                    <div class="item-inner">
                                        <div class="item-title item-label">Máy chủ (tên miền/domain *)</div>
                                        <div class="item-input-wrap">
                                        <input @keypress="keyenter" autocomplete="new-password" autocapitalize="off" type="url" placeholder="Tên miền/domain" name="coreUrl" class="input-with-value" value="{{js "this.recentLogin && this.recentLogin.url || ''"}}">
                                        </div>
                                    </div>
                                    </li>
                                    <li class="item-content item-input item-input-with-value">
                                    <div class="item-inner">
                                        <div class="item-title item-label">Tài khoản (SĐT/Email *)</div>
                                        <div class="item-input-wrap">
                                        <input @keypress="keyenter" autocomplete="new-password" autocorrect="off" autocapitalize="off" type="text" placeholder="SĐT/Email" name="username" value="{{js "this.recentLogin && this.recentLogin.username || ''"}}" class="input-with-value">
                                        </div>
                                    </div>
                                    </li>
                                    <li class="item-content item-input">
                                    <div class="item-inner">
                                        <div class="item-title item-label">Mật khẩu (*)</div>
                                        <div class="item-input-wrap">
                                        <input @keypress="keyenter" autocomplete="new-password" autocorrect="off" autocapitalize="off" type="password" placeholder="Mật khẩu" name="password" value="">
                                        </div>
                                    </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="block">
                                <div class="row">
                                    <a href="#" class="col back color-blue button button-large button-outline">Trở về</a>
                                    <a href="#" class="col color-green button button-large button-fill" @click="login">Đăng nhập</a>
                                </div>
                            </div>
                            <div class="block" style="text-align: center">
                                <a @click="fogetLogin" class="link">Quên mật khẩu</a>
                            </div>
                        </form> 
                    </div> 
                </div>
                `,
                style: /*css*/`
                .page.login .profile-info {
                    margin-top: 20px;
                }
                  
                .page.login .profile-logo {
                    height: 100px;
                    width: 100px;
                    border-radius: 50%;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    margin: 0 auto;
                }
                
                .page.login .profile-title {
                    text-align: center;
                    font-weight: bold;
                }
                
                .page.login .profile-subtitle {
                    text-align: center;
                }
                `,
                data() {
                    const self: F7ComponentContextExtend = this;
                    return {
                        notifications: self.$root.notifications,

                        appVersion: $this.rootServices.env.version,
                        coreVersion: $this.rootServices.env.coreVersion,
                        title: $this.title,
                        recentLogins: [],
                        appName: $this.rootServices.env.name,
                        appTitle: $this.rootServices.env.title,
                        appCopyright: $this.rootServices.env.copyright,
                    };
                },
                methods: {
                    entrerNewPassword(phone: string, otpToken: string) {
                        // let url: string = $this.rootServices.env.api.defaultUrl;
                        const self: F7ComponentContextExtend = this;

                        const urlEle = self.$('form').find('input[name="coreUrl"]');
                        const usernameEle = self.$('form').find('input[name="username"]');
                        let url: string = urlEle.val().trim('/');
                        let phonenumber = usernameEle.val().trim().replace(/[^0-9]/, '');
                        if (!url) {
                            throw new Error('Bạn chưa điền máy chủ !');
                        }
                        if (!/v\d+$/.test(url)) {
                            url += '/v3';
                        }
                        if (!/^http/i.test(url)) {
                            url = 'https://' + url;
                        }

                        let passDialog = self.$app.dialog.password('Bạn hãy điền mật khẩu mới (hết hạn trong 120s)', 'Đặt lại mật khẩu', (newPassword) => {
                            passDialog.close();
                            passDialog = self.$app.dialog.password('Xác nhận mật khẩu mới (hết hạn trong 120s)', 'Đặt lại mật khẩu', (newPassword2) => {
                                passDialog.close();
                                if (newPassword && newPassword === newPassword2) {
                                    self.$app.preloader.show();
                                    $this.rootServices.apiService.postPromise(url + '/user/login/changePasswordByOtp', { otpToken: otpToken }, { newPassword: newPassword }).then(rs => {
                                        self.$app.preloader.hide();
                                        $this.commonService.showInfo('Đặt lại mật khẩu thành công', { position: 'bottom' });
                                        // Auto login
                                        self.login(null, url, phone, newPassword);
                                    }).catch(err => {
                                        self.$app.preloader.hide();
                                        $this.commonService.showError(err, { position: 'bottom' });
                                    });
                                } else {
                                    $this.commonService.showError('Bạn chưa điền mã OPT !', { position: 'bottom' });
                                    self.entrerNewPassword(phone, otpToken);
                                }

                            });
                            passDialog.open();
                        });
                        passDialog.open();
                    },
                    tryVerifiedOtp(phone: string, message?: string, tryTime?: number) {

                        // let url: string = $this.rootServices.env.api.defaultUrl;
                        const self: F7ComponentContextExtend = this;
                        const urlEle = self.$('form').find('input[name="coreUrl"]');
                        let url: string = urlEle.val().trim('/');
                        if (!url) {
                            throw new Error('Bạn chưa điền máy chủ !');
                        }
                        if (!/v\d+$/.test(url)) {
                            url += '/v3';
                        }
                        if (!/^http/i.test(url)) {
                            url = 'https://' + url;
                        }

                        let dialog = self.$app.dialog.prompt(message || 'Hệ thống đã gửi mã OTP, hãy kiểm tra tin nhắn và cung cấp mã OTP để xác thực', 'Xác thực OTP', (otp) => {
                            dialog.close();
                            if (phone) {
                                self.$app.preloader.show();
                                $this.rootServices.apiService.postPromise(url + '/user/login/verifyOtp', { phone: phone, otp: otp }, []).then(rs => {
                                    self.$app.preloader.hide();
                                    self.entrerNewPassword(phone, rs['otpToken']);
                                }).catch(err => {
                                    self.$app.preloader.hide();
                                    $this.commonService.showError(err, { position: 'bottom' });
                                    tryTime = (tryTime || 0) + 1;
                                    if (tryTime < 3) {
                                        self.tryVerifiedOtp(phone, 'Mã OTP không hợp lệ, hãy thử lại (' + tryTime + '/3)', tryTime);
                                    }
                                });
                            } else {
                                $this.commonService.showError('Bạn chưa điền mã OPT !', { position: 'bottom' });
                                self.tryVerifiedOtp(phone);
                            }
                        });
                        dialog.open();
                    },
                    async fogetLogin() {
                        const self: F7ComponentContextExtend = this;
                        try {
                            const urlEle = self.$('form').find('input[name="coreUrl"]');
                            const usernameEle = self.$('form').find('input[name="username"]');
                            let url: string = urlEle.val().trim('/');
                            let phonenumber = usernameEle.val().trim().replace(/[^0-9]/, '');
                            if (!url) {
                                throw new Error('Bạn chưa điền máy chủ !');
                            }
                            if (!/v\d+$/.test(url)) {
                                url += '/v3';
                            }
                            if (!/^http/i.test(url)) {
                                url = 'https://' + url;
                            }
                            const data = await $this.rootServices.openDynamicFormDialog<{ Phone: string; }>('Đặt lại mật khẩu', /*html*/ ``, [
                                {
                                    name: 'Phone',
                                    type: 'decimal',
                                    label: 'Số điện thoại',
                                    placeholder: 'SĐT nhận mã OTP...',
                                    required: true,
                                    focus: true,
                                    initValue: phonenumber
                                },
                            ], null, { submitButtonLabel: 'Gửi mã OTP' });
                            const phone = data.Phone;
                            try {
                                const rs = await $this.rootServices.apiService.postPromise(url + '/user/login/resetPassword', { phone: phone }, []);
                                self.$app.preloader.hide();
                                // self.tryVerifiedOtp(phone);
                                let tryTime = 1;
                                return await $this.rootServices.enterPasscodePopup(
                                    'Điền mã xác thực OTP',
                                    /*html*/ `
                                        <div class="block-title">Bạn hãy vào Zalo để kiểm tra tin nhắn OTP</div>
                                        <div class="block block-strong inset" style="padding: 0; overflow: hidden; line-height: 0">
                                            <img src="assets/images/zalo-otp.png" style="width: 100%">
                                        </div>
                                    `, 4, {
                                    swipeToClose: false,
                                    onFullFill: (otp_1) => {
                                        return $this.rootServices.apiService.postPromise(url + '/user/login/verifyOtp', { phone: phone, otp: otp_1 }, []).then(async (rs_1) => {
                                            self.$app.preloader.hide();
                                            $this.commonService.showInfo('Xác thực thành công !');
                                            // self.entrerNewPassword(phone, rs['otpToken']);
                                            let breaking = null;
                                            for (let tryTime_1 = 1; tryTime_1 <= 3; tryTime_1++) {
                                                breaking = await new Promise(resolve => {
                                                    $this.rootServices.openDynamicFormDialog<{ Password: string; RePassword: string; }>('Đặt lại mật khẩu mới', /*html*/ ``, [
                                                        {
                                                            name: 'Password',
                                                            type: 'password',
                                                            label: 'Mật khẩu mới',
                                                            placeholder: 'Mật khẩu mới...',
                                                            required: true,
                                                            focus: true
                                                        },
                                                        {
                                                            name: 'RePassword',
                                                            type: 'password',
                                                            label: 'Xác nhận mật khẩu mới',
                                                            placeholder: 'Nhập lại mật khẩu mới...',
                                                            required: true
                                                        },
                                                    ], null, { submitButtonLabel: 'Đổi mật khẩu' }).then(data_1 => {
                                                        if (data_1 && data_1.Password && data_1.Password === data_1.RePassword) {
                                                            self.$app.preloader.show();
                                                            $this.rootServices.apiService.postPromise(url + '/user/login/changePasswordByOtp', { otpToken: rs_1['otpToken'] }, { newPassword: data_1.Password }).then(rs_2 => {
                                                                self.$app.preloader.hide();
                                                                $this.commonService.showInfo('Đặt lại mật khẩu thành công', { position: 'bottom' });
                                                                // Auto login
                                                                resolve(true);
                                                                self.login(null, url, phone, data_1.Password);
                                                            }).catch(err => {
                                                                resolve(null);
                                                                self.$app.preloader.hide();
                                                                $this.commonService.showError(err, { position: 'bottom' });
                                                            });
                                                        } else {
                                                            $this.commonService.showError('Mật khẩu không hợp lệ ! (thử lại ' + tryTime_1 + '/3)');
                                                            resolve(null);
                                                        }
                                                    }).catch(err_2 => {
                                                        resolve(true);
                                                        err_2 && $this.commonService.showError(err_2);
                                                    });
                                                });
                                                if (breaking) {
                                                    break;
                                                }
                                            }
                                            // Change password
                                            return true;
                                        }).catch(err_3 => {
                                            self.$app.preloader.hide();
                                            $this.commonService.showError(err_3, { position: 'bottom' });
                                            tryTime++;
                                            if (tryTime <= 3) {
                                                // self.tryVerifiedOtp(phone, 'Mã OTP không hợp lệ, hãy thử lại (' + tryTime + '/3)', tryTime);
                                                return Promise.reject({ error: 422, message: 'Mã OTP không hợp lệ, hãy thử lại (' + tryTime + '/3)' });
                                            }
                                            tryTime = 1;
                                            return Promise.reject({ error: 400, message: 'Mã OTP đã hết hiệu lực !' });
                                        });
                                    }
                                });
                            } catch (err_4) {
                                self.$app.preloader.hide();
                                if (err_4)
                                    $this.commonService.showError(err_4, { position: 'bottom' });
                            }
                        } catch (err_5) {
                            return err_5 && $this.commonService.showError(err_5);
                        }
                    },
                    async refresh() {
                        const self: F7ComponentContextExtend = this;
                        return true;
                    },
                    keyenter(e: KeyboardEvent) {
                        const self: F7ComponentContextExtend = this;
                        // console.log(e);
                        if (e.key === 'Enter') {
                            self.login();
                        }
                    },
                    login(e: MouseEvent, url?: string, username?: string, password?: string) {
                        const self: F7ComponentContextExtend = this;

                        const urlEle = self.$('form').find('input[name="coreUrl"]');
                        const usernameEle = self.$('form').find('input[name="username"]');
                        const passwordEle = self.$('form').find('input[name="password"]');

                        if (!url) {
                            url = (urlEle.val() || '').trim();
                        }
                        if (!username) {
                            username = (usernameEle.val() || '').trim();
                        }
                        if (!password) {
                            password = (passwordEle.val() || '').trim();
                        }

                        if (!/^http/i.test(url)) {
                            url = 'https://' + url;
                        }

                        // if (!/\/v\d{1,2}$/i.test(url)) {
                        //     url = url + '/v3';
                        // }


                        if (url && username && password) {
                            try {
                                if (/\/v\d+/.test(url)) {
                                    url = url.replace(/\/v\d+/, '');
                                }
                                self.$app.preloader.show();
                                $this.rootServices.authService.login({ username, password, url }).then(authToken => {
                                    console.log(authToken);
                                    // $this.storage.set('api_url', url);
                                    // Update recent login list
                                    const token = authToken.getPayload();
                                    $this.rootServices.storage.get('recent_logins')
                                        .then((recentLogins: RecentLoginModel[]) => {
                                            recentLogins = recentLogins || [];
                                            let currentLogin = recentLogins.find(f => f.core === token.core && f.user === token.user);
                                            if (currentLogin) {
                                                currentLogin.remember = true;
                                                if (/\/v\d+/.test(currentLogin.url)) {
                                                    currentLogin.url = currentLogin.url.replace(/\/v\d+/, '');
                                                }
                                            } else {
                                                currentLogin = { url, username, remember: true };
                                                recentLogins.unshift(currentLogin);
                                            }
                                            const loginUser = $this.rootServices.authService.getUser();
                                            currentLogin.id = url + loginUser.id;
                                            currentLogin.core = authToken.getPayload().core;
                                            currentLogin.user = token.user;
                                            currentLogin.name = loginUser.name;
                                            currentLogin.avatar = loginUser.avatar && loginUser.avatar.payload && loginUser.avatar.payload.thumbnail;
                                            currentLogin.banner = loginUser.core && loginUser.core.banner;
                                            currentLogin.backgroundBannerColor = loginUser.core && loginUser.core.backgroundBannerColor;
                                            currentLogin.coreName = loginUser.core && loginUser.core.name;
                                            currentLogin.coreBanner = loginUser.core && loginUser.core.banner;
                                            currentLogin.token = authToken.getPayload();
                                            $this.rootServices.storage.set('recent_logins', recentLogins);

                                            const router: Router.Router = this.$f7router;
                                            self.$router.back('/notifications', { force: true });
                                            self.$app.preloader.hide();
                                        }).catch(err => {
                                            console.error(err);
                                            $this.commonService.showError(err);
                                            self.$app.preloader.hide();
                                        });
                                }).catch(err => {
                                    console.error(err);
                                    self.$app.preloader.hide();
                                    // self.$app.toast.create({
                                    //     text: err && err.status === 0 ? 'Domain not found' : (err && err.error && err.error.logs && err.error.logs[0]),
                                    //     closeTimeout: 5000,
                                    //     cssClass: 'text-color-red', 
                                    // }).open();
                                    $this.commonService.showError(err && err.status === 0 ? 'Domain not found' : (err && err.error && err.error.logs && err.error.logs[0]));
                                });
                            } catch (err) {
                                console.error(err);
                                // self.$app.toast.create({
                                //     text: err && err.status === 0 ? 'Domain not found' : (err && err.error && err.error.logs && err.error.logs[0]),
                                //     closeTimeout: 5000,
                                //     cssClass: 'text-color-red', 
                                // }).open();
                                $this.commonService.showError(err && err.status === 0 ? 'Domain not found' : (err && err.error && err.error.logs && err.error.logs[0]));
                                // $this.rootServices.toastController.create({
                                //     header: 'Login failed',
                                //     message: 'Click to Close',
                                //     color: 'danger',
                                //     buttons: [
                                //         {
                                //             side: 'start',
                                //             icon: 'star',
                                //             text: 'Favorite',
                                //             handler: () => {
                                //                 console.log('Favorite clicked');
                                //             }
                                //         }, {
                                //             text: 'Done',
                                //             role: 'cancel',
                                //             handler: () => {
                                //                 console.log('Cancel clicked');
                                //             }
                                //         }
                                //     ]
                                // });
                            }
                        } else {
                            // self.$app.toast.create({
                            //     text: 'You must provided Core domain, username and password',
                            //     closeTimeout: 5000,
                            //     cssClass: 'text-color-red',
                            // }).open();
                            $this.commonService.showError('Bạn phải nhập đầy đủ tên miền, tên đăng nhập và mật khẩu');
                        }

                        // localStorage.setItem('access_token', '1231231231235sdfdsfq234tqetrq4tvaawrtwet');
                        // localStorage.setItem('refresh_token', '1231231231235sdfdsfq234tqetrq4tvaawrtwet');

                        // $this.rootServices.authService.login({email: })

                    }
                },
                on: {
                    pageInit(e, page) {
                        console.log('page init');
                        const self: F7ComponentContext = this;
                        $this.onChangedState({ instance: self }, 'main');
                    },
                }
            },
        };
    }
}
