import { F7ComponentContext, F7Page } from '../../../types/framework7-types';
import { Router } from 'framework7/build/core/modules/router/router';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { ComponentState, BaseComponent } from 'vendors/smart-bot-app/src/app/lib/base-component';
import { ChatRoom } from 'vendors/smart-bot-app/src/app/lib/nam-chat/chat-room';
import { ProductModel } from 'vendors/smart-bot-app/src/app/model/product.model';
import { TaskReminderModel } from 'vendors/smart-bot-app/src/app/model/task.model';
import { CommonService } from 'vendors/smart-bot-app/src/app/services/common.service';
import { RootServices } from 'vendors/smart-bot-app/src/app/services/root.services';
import * as moment from 'moment';
import { PageComponent } from '../../page.component';
import { CollaboratorOrderModel } from 'src/app/model/collaborator.model';

export interface ComponentStateExtend extends ComponentState {
  [key: string]: any;
  productList?: any[];
}

export class F7ComponentContextExtend extends F7ComponentContext {
  // messagebar?: F7Messagebar;
  // messages?: Messages.Messages;
  // images?: string[];
  responseInProgress?: boolean;
  // answers?: string[];
  // people?: { name?: string, avatar?: string }[];
  // hideToolbar?: () => void;
  // sheetToggle?: () => void;
  // deleteAttachment?: (e: MouseEvent, index: number) => void;
  // handleAttachment?: (e: MouseEvent) => void;
  // checkAttachments?: () => void;
  // sendMessage?: () => void;
  productList?: ProductModel[];
  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, reminder?: TaskReminderModel, chatRoom?: ChatRoom, reminderList$?: BehaviorSubject<TaskReminderModel[]> } };
}

/** Component group manager */
export class PageProductComponent extends BaseComponent<ComponentStateExtend> {
  // states: { [key: string]: State } = {};

  title: 'Sản phẩm'

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
    public parentComponent: PageComponent,
  ) {
    super(rootServices);
    // console.log('Click here to open ts file');

    this.parentComponent.eventEmitter.subscribe((e: { name: string, data: any }) => {
      switch (e.name) {
        case 'refresh':
          this.currentState && this.currentState.instance.refresh();
          break;
      }
    });
  }

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      // Tab path
      name: 'page-products',
      path: '/collaborator/products',
      // Tab id
      id: 'page-products',
      // Fill this tab content from content string
      component: {
        template: /*html*/`
            <div class="page-content" style="padding-top: 0">

            {{#js_if "this.pageInfo && !this.pageInfo.IsSubscribed"}}
            <div class="block" style="margin-top: 0">
              <button class="button button-fill button-large color-orange" @click="registerPublisher">Đăng ký CTV bán hàng</button>
            </div>
            {{/js_if}}
            
              <!-- Product List -->
              {{#js_if "this.productList && this.productList.length > 0"}}
              <div class="block-title">Sản phẩm kinh doanh
              <a class="button button-small button-fill tab-link" @click="createNewOrder" style="float: right; font-size: 0.7rem; font-weight: bold;">Tạo đơn</a></div>
              <div class="list media-list inset">
                <ul>
                  {{#each productList}}
                  <li data-id="{{Code}}" @click="gotoProduct">
                    <a href="#" class="item-link item-content">
                      <div class="item-media">
                        <i class="icon list-item-img" style="border-radius:0.3rem; background-image: url({{js "this.FeaturePictureThumbnail"}}); width: 44px; height: 44px"></i></div>
                      <div class="item-inner">
                        <div class="item-title-row">
                          <div class="item-title">{{Name}}</div>
                        </div>
                        <div class="item-subtitle">ĐVT: {{text Unit}}</div>
                        <div class="item-subtitle">{{CategoriesText}}</div>
                        <div class="item-subtitle">Loại: {{text Type}}</div>
                      </div>
                    </a>
                  </li>
                  {{/each}}
                </ul>
              </div>
              {{else}}
              <div class="block-title skeleton-text skeleton-effect-blink">Sản phẩm kinh doanh</div>
              <div class="list media-list inset skeleton-text skeleton-effect-blink">
                <ul>
                  <li>
                    <a href="#" class="item-link item-content">
                      <div class="item-media">
                        <i class="icon list-item-img" style="border-radius:0.3rem; background-image: url(assets/images/no-image-available.png); width: 44px; height: 44px"></i></div>
                      <div class="item-inner">
                        <div class="item-title-row">
                          <div class="item-title">Name Name Name Name Name </div>
                        </div>
                        <div class="item-subtitle">Categories Text</div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              {{/js_if}}
              <div class="block"></div>
              <!--<div class="block-footer">
                <div>
                  Các sản phẩm trang này đang kinh doanh, hãy chọn sản phẩm phù hợp với mình và đăng ký làm cộng tác viên của trang.
                </div>
              </div>-->
              <!-- End Product List -->
            
            </div>
        `,
        style: /*css*/`
          #page-products .item-title{
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
          }
        `,
        // data() {
        //   return {
        //     title: $this.title,
        //     backTitle: 'Back',
        //     productList: [],
        //   };
        // },
        methods: {
          createNewOrder() {
            const self: F7ComponentContextExtend = this;
            $this.rootServices.navigate(`/page-collaborator-order/${self.pageId}/new`, {
              context: {
                pageId: self.pageId,
                callback: (data: CollaboratorOrderModel, state: any) => {
                  console.log('Callback data: ', data);
                  if (state?.lastAction == 'CREATE_SUCCESS') {
                    $this.rootServices.navigate(`/page-collaborator-orders/${self.pageId}`);
                  }
                  if (state?.lastAction == 'UPDATE_SUCCESS') {
                    $this.rootServices.navigate(`/page-collaborator-orders/${self.pageId}`);
                  }
                }
              }
            });
          },
          registerPublisher() {
            const self: F7ComponentContextExtend = this;
            self.$app.dialog.confirm(`Bạn có muốn trở thành CTV bán hàng của ${self.pageInfo?.Name}`, 'Đăng ký CTV bán hàng', () => {
              $this.rootServices.apiService.putPromise('/collaborator/pages/' + self.$route?.params?.id, { registerPublisher: true }, [{
                Code: self.$route?.params?.id
              }]).then(rs => {
                $this.commonService.showInfo('Chúc mừng bạn đã trở thành CTV bán hàng của ' + self.pageInfo?.Name + ' !');
                $this.parentComponent && $this.parentComponent.currentState && $this.parentComponent.currentState.instance && $this.parentComponent.currentState.instance.$setState({
                  pageInfo: {
                    ...$this.parentComponent.currentState.instance.pageInfo,
                    IsSubscribed: true,
                  }
                });
                $this.parentComponent && $this.parentComponent.currentState && $this.parentComponent.currentState.instance.refresh(null, null, true);
                self.$setState({
                  pageInfo: {
                    ...$this.parentComponent.currentState.instance.pageInfo,
                    IsSubscribed: true,
                  }
                });
              }).catch(err => {
                $this.commonService.showError(err, { timeout: 120000 });
              });
            });
          },
          subscribe(e) {
            const self: F7ComponentContextExtend = this;
            const id = self.$(e.target).closest('li').data('id');
            self.$route.context['onChoose'] && self.$route.context['onChoose'](self.productList.find(f => f.Code === id));
          },
          chooseOne(e) {
            const self: F7ComponentContextExtend = this;
            const id = self.$(e.target).closest('li').data('id');
            self.$route.context['onChoose'] && self.$route.context['onChoose'](self.productList.find(f => f.Code === id));
          },
          chooseProduct(e) {

          },
          gotoProduct(e) {
            const self: F7ComponentContextExtend = this;
            // const pageId = self.$route.params?.id;
            // const currentState = $this.state[]
            const id = self.$(e.target).closest('li').data('id').replace(/_\w+/, '').toLowerCase();
            $this.rootServices.navigate(`/${self.pageId.toLowerCase()}/ctvbanhang/product/${id}`, { context: { pageId: self.pageId, pageInfo: self.pageInfo, onProductChoose: self.onProductChoose } });
          },
          async refresh(e, done, force?: boolean) {
            const self: F7ComponentContextExtend = this;
            const pageId = self.pageId || self.$route.params?.id;
            let doneTimeout = null;
            if (done) {
              doneTimeout = setTimeout(() => {
                done();
              }, 10000);
            };

            let products = [];
            try {
              if (!self.pageInfo || force) {
                self.pageInfo = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/pages/' + pageId, {}).then(rs => rs[0]);
              }

              // console.log(self.searchBar);

              // await $this.commonService.waiting(2000);
              products = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/products', {
                includeCategories: true,
                includeGroups: true,
                // filterByLevelAndBadge: true,
                includeSubscribed: true,
                sort_Id: true,
                page: pageId,
                search: self?.searchBar?.query && encodeURIComponent(self.searchBar.query) || '',
                includeFeaturePicture: true,
                includeUnit: true,
                offset: 0,
                limit: 40,
                ...self.filter,
              }).then(products => {
                // self.$$(page.el).find('.page-content').scrollTo(0, 0);
                return products.map(t => $this.preapreProduct(t));
              });
              self.$setState({
                title: 'page products',
                productList: products,
                infiniteLoadLast: products.length === 0,
                pageInfo: self.pageInfo,
                lastUpdateMoment: moment(Date.now()).fromNow(),
              });
              self.infiniteOffset = 0;

            } catch (err) {
              console.error(err);
            }

            if (done) done();
            return products;
          },
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          tabInit(e, page: F7Page) {
            console.log('tab init');
            const self: F7ComponentContextExtend = this;
            const pageId = self.$route.params?.id;
            const refcode = self.$route.params?.refcode;

            const newState: any = {
              pageId: pageId,
              // pageInfo: $this.parentComponent?.currentState?.instance?.pageInfo,
            };

            $this.parentComponent.setActiveTab('page-products');
            $this.parentComponent.currentState.instance.$setState({hideTabBar: false});

            if ((self.$router?.previousRoute?.context as any)?.onProductChoose) {
              newState.onProductChoose = (self.$router?.previousRoute?.context as any)?.onProductChoose;
            }
            self.$setState(newState);

            $this.onComponentInit({ instance: self }, pageId, 'init').then(async currentState => {
              if (refcode) {
                self.$app.dialog.confirm(`Bạn đang thực hiện liên kết dữ liệu CTV Bán Hàng và đồng ý đăng ký CTV Bán Hàng với ${self.pageInfo?.Name}`, 'Thỏa thuận giao dịch', () => {
                  $this.rootServices.apiService.putPromise('/collaborator/pages/' + self.$route?.params?.id, { registerPublisher: true }, [{
                    Code: self.$route?.params?.id,
                    RefCode: refcode,
                  }]).then(rs => {
                    $this.commonService.showInfo('Chúc mừng bạn đã liên kết dữ liệu thành công và trở thành CTV bán hàng của ' + self.pageInfo?.Name + ' !');
                    $this.parentComponent && $this.parentComponent.currentState && $this.parentComponent.currentState.instance && $this.parentComponent.currentState.instance.$setState({
                      pageInfo: {
                        ...$this.parentComponent.currentState.instance.pageInfo,
                        IsSubscribed: true,
                      }
                    });
                    $this.parentComponent && $this.parentComponent.currentState && $this.parentComponent.currentState.instance.refresh(null, null, true);
                    self.$setState({
                      pageInfo: {
                        ...$this.parentComponent.currentState.instance.pageInfo,
                        IsSubscribed: true,
                      }
                    });
                  }).catch(err => {
                    $this.commonService.showError(err, { timeout: 120000 });
                  });
                });
              }
            });

            // $this.onComponentInit({ instance: self }, 'main');

          },
          tabMounted(e, page) {
            console.log('tab before mount');
          },
          tabBeforeRemove(e, page) {
            console.log('[tab event] before remove', page?.route?.url);
            const self: F7ComponentContextExtend = this;
            const pageId = self.$route.params?.id;
            $this.onComponentRemove({ instance: self }, pageId);
            // const starmtSelectRoles = self.$app.smartSelect.get('.smart-select');
            // starmtSelectRoles.destroy();
          },
        },
      },
    };
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  preapreProduct(p: ProductModel) {
    if (p.Categories && p.Categories.length > 0) {
      p.CategoriesText = p.Categories.map(m => m.text).join(', ');
    }
    if (p.FeaturePicture && p.FeaturePicture.Thumbnail) {
      p.FeaturePictureThumbnail = p.FeaturePicture.Thumbnail;
    }
    const taxValue = p.Tax && p.Tax.Tax || 0;
    p.ToMoney = parseInt(p.Price as any) + parseInt(p.Price as any) * parseInt(taxValue as any) / 100;
    p.ToMoneyText = this.rootServices.currencyPipe.transform(p.ToMoney, 'VND');;
    p.PriceText = this.rootServices.currencyPipe.transform(p.Price, 'VND');
    if (p.OriginPrice) {
      p.OriginPriceText = this.rootServices.currencyPipe.transform(p.OriginPrice, 'VND');
    }
    return p;
  }

  async onComponentInit(state: ComponentStateExtend, index: string, asCase?: string) {
    const currentState = await super.onComponentInit(state, index, asCase);
    if (!currentState.productList || currentState.productList.length === 0) {
      currentState.productList = (await currentState.instance.refresh()) as any;
    }
    currentState.instance.$setState({ productList: currentState.productList });
    return currentState;
  }
}
