import { isPlatform } from '@ionic/angular';
import { CommercePosOrderDetailModel, CommercePosOrderModel } from './../../../model/commerce-pos.model';
import { ProductSearchIndexModel } from './../../../model/product.model';
import { take, filter, map } from 'rxjs/operators';
import { Dialog } from "framework7/build/core/components/dialog/dialog";
import { Router } from "framework7/build/core/modules/router/router";
import { ContactModel } from "../../../../../src/app/model/contact.model";
import { SalesMasterPriceTable } from "../../../../../src/app/model/sales.model";
import { CommonService } from "../../../../../src/app/services/common.service";
import { RootServices } from "../../../../../src/app/services/root.services";
import { F7ComponentContext, F7Page, F7TabContext } from "../../../../../src/app/types/framework7-types";
import { FileModel } from '../../../../../src/app/model/file.model';
import { BaseFormComponent, FormComponentStateExtend, FormSchema } from '../../../../../src/app/lib/base-form-component';
import { ProductModel, UnitModel } from '../../../../../src/app/model/product.model';
import { ChatRoomModel } from '../../../../../src/app/model/chat-room.model';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ComponentState } from '../../../../../src/app/lib/base-component';
import { __ } from '../../../../../src/app/app.component.processor';
import { Template7 } from 'framework7/build/core/framework7.esm.bundle';
// import { Printer, PrintOptions } from '@awesome-cordova-plugins/printer/ngx';
// import { EmbeddedBarcodeReader } from 'plugins/cordova-plugin-embedded-barcode-reader/typescript/EmbeddedBarcodeReader';
import { Toast } from 'framework7/build/core/components/toast/toast';
// import * as $ from 'jquery';
declare const $: any;

// declare const $: any;

declare const EmbeddedBarcodeReader: any & { [key: string]: any };
export interface ComponentStateExtend extends FormComponentStateExtend<CommercePosOrderModel> {
  [key: string]: any;
  instance: F7ComponentContextExtend,
  data?: CommercePosOrderModel;
  form?: any;
}

export class F7ComponentContextExtend extends F7ComponentContext {
  responseInProgress?: boolean;

  detailDialog?: Dialog.Dialog;

  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, [key: string]: any } };
}

/** Component group manager */
export class CommercePosGuiComponent extends BaseFormComponent<ComponentStateExtend, CommercePosOrderModel> {
  // states: { [key: string]: State } = {};
  currentState: ComponentStateExtend;
  title: 'POS';

  idKey = 'Code';

  apiPath = '/commerce-pos/orders';

  unitList: UnitModel[] = null;
  // unitMapById: { [key: string]: UnitModel } = null;
  // unitMapBySeq: { [key: string]: UnitModel } = null;
  // productMapByUnit: { [key: string]: ProductSearchIndexModel } = null;
  // priceTableList: SalesMasterPriceTable[] = [];

  contactList: ContactModel[] = [];
  // productSearchIndexs: ProductSearchIndexModel[] = null;
  progress = 0;
  progressStatus = '';
  progressLabel = '';

  // private unitMap: { [key: string]: UnitModel } = {};
  // private findOrderMap: { [key: string]: { Goods?: string, Unit?: any, UnitLabel?: string, Container?: any } } = {};
  // private productMap: { [key: string]: ProductModel } = {};
  // private masterPriceTable: { [key: string]: number } = {};

  schema: FormSchema = {
    Object: {
      type: 'autocomplete-contact',
      label: 'Khách hàng',
      validators: [
        // 'required'
      ]
    },
    ObjectName: {
      type: 'input',
      label: 'Tên khách hàng',
      validators: [
        // 'required'
      ]

    },
    Note: {
      type: 'input',
    },
    IsDebt: {
      type: 'toggle',
    },
    Details: {
      type: 'list',
      makeModel: (properties: CommercePosOrderDetailModel) => {
        return new CommercePosOrderDetailModel(properties);
      },
      Unit: {
        type: 'smart-select',
        label: 'Đơn vị tính',
        validators: [
          'required'
        ]
      },
      AccessNumbers: {
        type: 'smart-select',
        label: 'Số truy xuất',
        validators: [

        ]
      },
      Quantity: {
        type: 'stepper',
        label: 'Số lượng',
        validators: [
          'required'
        ]
      },
      Price: { type: 'inputmask', format: { mask: 'decimal', option: { radixPoint: ',', groupSeparator: '.' } } },
      ToMoney: { type: 'inputmask', format: { mask: 'decimal', option: { radixPoint: ',', groupSeparator: '.' } } },
    }
  };

  orderList: ComponentStateExtend[] = [];

  printPptions = {
    name: 'MyDocument',
    duplex: true,
    orientation: 'landscape',
    monochrome: true
  }
  // isEmbedBarcodeScan: boolean = false;;

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
    // public printer: Printer,
  ) {
    super(rootServices, commonService);
    // // console.log('Click here to open ts file');

    // this.printer.isAvailable().then(() => {
    //   console.log('printer available');
    //   this.commonService.showInfo('printer available');
    // }, () => {
    //   console.log('printer not available');
    //   this.commonService.showError('printer not available');
    // });

  }

  makeModel(properties?: CommercePosOrderModel): CommercePosOrderModel {
    return new CommercePosOrderModel(properties);
  }

  async makeNewData() {
    return new CommercePosOrderModel();
  }

  getFormData(params?: any): Promise<CommercePosOrderModel> {
    return super.getFormData({
      includeDetails: true,
      includeTax: true,
      includeUnit: true,
      includeProductPrice: true,
      includeProductUnitList: true,
      includeProductCategories: true,
      includeRelativeVouchers: true,
      renderBarCode: true,
      ...params,
    });
  }

  prepareData(data: CommercePosOrderModel): CommercePosOrderModel {
    for (const detail of data.Details) {

      if (detail?.Unit && typeof detail?.Unit == 'string') {
        detail.Unit = this.unitList.find(f => f.id == detail.Unit as any);
      }

      if (!data.Pictures) {
        data.Pictures = [];
      }
    };
    return data;
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  async onComponentInit(state: ComponentStateExtend, index: string, asCase?: string, f7Page?: F7Page) {
    const $this = this;

    // Start loading status
    state.instance.$setState({
      loading: true,
      schema: this.schema,
    });
    this.commonService.showPreloader(300000);

    // this.rootServices.productSearchIndexs$.pipe(filter(f => !!f)).subscribe(productSearchIndexs => {
    //   this.productSearchIndexs = productSearchIndexs;
    //   // console.log('POS GUI: Product Search Indexs', this.productSearchIndexs);
    //   this.unitList = this.rootServices.unitList;
    //   // this.productMap = this.rootServices.productMap;
    //   // this.unitMapById = this.rootServices.unitMap;
    //   // this.unitMapBySeq = this.rootServices.unitSeqMap;
    //   // this.productMapByUnit = this.rootServices.productMapByUnit;
    //   // this.unitMap = this.rootServices.unitMap;
    //   // this.findOrderMap = {};
    //   for (const key in this.rootServices.findOrderMap) {
    //     const m = this.rootServices.findOrderMap[key];
    //     // this.findOrderMap[key] = {
    //     //   ...m,
    //     //   Unit: this.commonService.getObjectId(m.Unit),
    //     //   Container: this.commonService.getObjectId(m.Container)
    //     // };
    //   }
    //   // set static data
    //   state.instance.$setState({
    //     unitList: state.instance.unitList,
    //     // contactList: state.instance.contactList,
    //     // priceTableList: state.instance.priceTableList
    //   });
    // });
    // End


    const currentState: ComponentStateExtend = await super.onComponentInit(state, index, asCase) as any;

    if (currentState.instance.$route?.context?.copyFromId) {
      currentState.instance.data.SubNote = 'Copy of ' + currentState.instance.$route?.context?.copyFromId + ': ' + (currentState.instance.data.SubNote ? ('<br>' + currentState.instance.data.SubNote) : '');
      this.setData(currentState.instance.data);
    }


    // Stop loading status
    currentState.instance.$setState({ loading: false });
    this.commonService.hidePreloader();

    if (this.orderList.findIndex(f => f.id == this.currentState.instance?.$route?.params?.id) < 0) {
      if (/^new\-?/.test(this.currentState.id)) {
        this.orderList.push(this.currentState);
      } else {
        this.orderList.unshift(this.currentState);
      }
    }
    this.currentState.instance.$setState({
      isLastOrder: this.currentState.id == this.orderList[this.orderList.length - 1]?.id,
      isFirstOrder: this.currentState.id == this.orderList[0]?.id,
    });
    console.log(this.orderList);

    // Embed barcode scanner frame preview
    // this.embedBarcodeSanner();

    // const skuInput = currentState.instance.$el.find('#SkuInput');
    // skuInput.focus(() => {
    //   this.removeBarcodeScanner();
    // });

    this.rootServices.enableAssistiveTouchFeature($(currentState.instance.el).find('.assistiveTouch')[0]);

    return currentState;
  }

  embedBarcodeSanner() {
    this.rootServices.openEmbedBarcodeScanner(barcode => {
      console.log(barcode);
      // if (/^https/.test(barcode)) {
      //   barcode = barcode.replace(/^https:\/\/[^\/]+\/\d+\/truyxuat\/(\d+)/, '$1');
      // }
      barcode = this.rootServices.getAccessNumberFromUrl(barcode);
      this.barcodeProcess(barcode);
    }, { flashMode: 'on' });
    this.rootServices.isEmbedBarcodeScan = true;
  }

  removeBarcodeScanner() {
    if (this.rootServices.isEmbedBarcodeScan) {
      this.rootServices.isEmbedBarcodeScan = false;
      this.rootServices.closeEmbedBarcodeScanner();
    }
  }

  openEmbedBarcodeScanner(barcodeReadListener: (barcode: string) => void, option?: {
    x?: number,
    y?: number,
    width?: number,
    height?: number,
    camera?: string,
    toBack?: boolean,
    flashMode?: string,
  }): any {
    if (isPlatform('ios') || isPlatform('android')) {
      const pageContent = $(this.currentState.instance.el).find('.page-content');
      if (!pageContent.is('.embed-barcode-scanner')) {
        pageContent.addClass('embed-barcode-scanner');
        let options = {
          x: 0,
          y: 55,
          width: window.screen.width,
          height: 200,
          camera: EmbeddedBarcodeReader.CAMERA_DIRECTION.BACK,
          toBack: false
        };
        EmbeddedBarcodeReader.addBarcodeReadListener(function (readBarcode) {
          console.log('We just read another barcode', readBarcode);
          barcodeReadListener(Array.isArray(readBarcode) && readBarcode[0] || readBarcode || '');
        });
        EmbeddedBarcodeReader.startCamera(options, () => {
          if (option?.flashMode) {
            EmbeddedBarcodeReader.setFlashMode(option?.flashMode, () => { console.log('flash on') }, err => { console.log('flash error', err) });
          }
        });
      }
    }
    return EmbeddedBarcodeReader;
  }

  embedBarcodeSannerFlashStatus = false;
  toggleEmbedBarcodeSannerFlash() {
    this.embedBarcodeSannerFlashStatus = !this.embedBarcodeSannerFlashStatus;
    EmbeddedBarcodeReader.setFlashMode(this.embedBarcodeSannerFlashStatus && 'on' || 'off', () => console.log('toggle flash success'), err => console.error(err));
  }

  closeEmbedBarcodeScanner() {
    const pageContent = $(this.currentState.instance.el).find('.page-content');
    pageContent.removeClass('embed-barcode-scanner');
    EmbeddedBarcodeReader.stopCamera();
    return EmbeddedBarcodeReader;
  }

  onChangedState(state: ComponentState, index: string, asCase?: string, option?: { doNotRefresh?: boolean; }): Promise<ComponentState> {
    return super.onChangedState(state, index, asCase, option).then(rs => {
      this.currentState.instance.$setState({
        isLastOrder: this.currentState.id == this.orderList[this.orderList.length - 1]?.id,
        isFirstOrder: this.currentState.id == this.orderList[0]?.id,
      });
      return rs;
    });
  }

  async setData(data: CommercePosOrderModel, option?: { onlyList?: string[]; onlyKeys?: string[]; prepareControl?: boolean; }): Promise<boolean> {
    let priceTableDetails: { [key: string]: { Price: number } } = {};
    if (data?.Details && data.Details.length > 0 && !data?.Details[0].__unitList?.length) {

      await this.rootServices.apiService.getPromise<any>('/sales/master-price-table-details', { masterPriceTable: 'default', eq_Code: '[' + data.Details.map(detail => this.commonService.getObjectId(detail.Product)).join(',') + ']' }).then(rs => {
        for (const entry of rs) {
          priceTableDetails[this.commonService.getObjectId(entry.Product)] = entry.UnitList;
        }
        return rs;
      });

    }
    for (const detail of data?.Details) {
      // detail.__unitList = detail.__unitList || detail.Product?.Units || [];
      if (!detail.__unitList && Object.keys(priceTableDetails).length > 0) {
        detail.__unitList = priceTableDetails[this.commonService.getObjectId(detail.Product)];
      }
      detail.ToMoney = detail.Price * detail.Quantity;
    }
    // if (!data?.PriceTable && !data[this.idKey]) {
    //   data.PriceTable = this.currentState.instance.priceTableList[0];
    // } else if (data?.PriceTable) {
    //   if (typeof data?.PriceTable == 'string') {
    //     data.PriceTable = this.currentState.instance.priceTableList.find(f => f.id == data?.PriceTable);
    //   }
    // }

    const total = this.calculateTotal(data);
    return super.setData(data, option).then(status => {
      this.currentState.instance.$setState({ total });
      return status;
    });
  }

  async addItemForList<D extends CommercePosOrderDetailModel>(listName: string, item?: D): Promise<D> {
    return super.addItemForList(listName, item).then(newItem => {
      return newItem;
    }).then(rs => {
      this.activeItem(this.currentState.data.Details.length - 1);
      this.rootServices.playNewPipSound();
      return rs;
    });
  }


  async updateImages(state: (progress) => void, onAfterImageUpload?: (image: FileModel) => void) {

    let proloaderProcessing;
    try {
      const localFiles = await this.rootServices.pickFiles();
      console.debug(localFiles);

      this.commonService.showPreloader();
      proloaderProcessing = setTimeout(() => {
        this.commonService.hidePreloader();
      }, 16000);

      let images: FileModel[] = [];
      for (const i in localFiles) {
        // let image: FileModel = null;
        images[i] = await ((progress) => {
          if (localFiles[i].dataType === 'url') {
            return this.rootServices.uploadLocalFile(localFiles[i].url as string, (event) => {
              progress(event)
            });
          } else {
            const formData = new FormData();
            const imgBlob = new Blob([localFiles[i].url], {
              type: localFiles[i].type
            });
            formData.append('file', imgBlob, 'smart-bot-' + Date.now() + '.' + localFiles[i].ext);
            return this.rootServices.uploadFileData(formData, (event) => {
              progress(event)
            });
          }
        })((progress) => {
          console.log(progress);
          state(progress);
        });
        onAfterImageUpload && onAfterImageUpload(images[i]);
      }
      console.debug(images);
      clearTimeout(proloaderProcessing);
      this.commonService.hidePreloader();
      return images;
    } catch (err) {
      console.error(err);
      this.commonService.showError(err); clearTimeout(proloaderProcessing);
      this.commonService.hidePreloader();
      throw new Error(err);
    }
  }

  async onFieldChange(self: F7ComponentContext, e: any, ...args: any) {
    const currentState = this.currentState;
    return super.onFieldChange(self, e, args).then(async fieldInfo => {

      if (!fieldInfo) {
        return null;
      }

      let { field, fieldName, fieldValue, index, listName } = fieldInfo;

      let requireSetData = false;

      console.log('on field change', listName, index, fieldName, fieldValue);
      if (listName == 'Details' && fieldName == 'Quantity') {

        currentState.data[listName][index]['ToMoney'] = (currentState.data[listName][index]['Quantity'] || 0) * (currentState.data[listName][index]['Price'] || 0);
        // currentState.instance.$setState({ data: currentState.data });
        this.rootServices.playIncreasePipSound();
        requireSetData = true;
      }

      if (listName == 'Details' && fieldName == 'Price') {

        currentState.data[listName][index]['ToMoney'] = (currentState.data[listName][index]['Quantity'] || 0) * (currentState.data[listName][index]['Price'] || 0);
        currentState.instance.$setState({ data: currentState.data });
        // $(currentState.instance.el).find('.list-name[name="' + listName + '"] .index-' + index + ' [name="ToMoney"]').val(currentState.data[listName][index]['ToMoney']);
        requireSetData = true;
      }
      if (listName == 'Details' && fieldName == 'ToMoney') {

        if (currentState.data[listName][index]['Quantity'] > 0) {
          currentState.data[listName][index]['Price'] = (currentState.data[listName][index]['ToMoney'] || 0) / currentState.data[listName][index]['Quantity'];
          requireSetData = true;
        }
        // currentState.instance.$setState({ data: currentState.data });
        // $(currentState.instance.el).find('.list-name[name="' + listName + '"] .index-' + index + ' [name="Price"]').val(currentState.data[listName][index]['Price']);
      }

      if (fieldName == 'Object' && fieldValue && !currentState.instance.loading) {
        await this.rootServices.apiService.getPromise<ContactModel[]>('/contact/contacts/' + this.commonService.getObjectId(fieldValue), {}).then(contacts => contacts[0]).then(contact => {

          // Auto fill object
          currentState.data.Object = fieldValue;
          currentState.data.ObjectName = contact.Name;
          currentState.data.ObjectPhone = contact.Phone;
          currentState.data.ObjectEmail = contact.Email;
          currentState.data.ObjectAddress = contact.Address;
          currentState.data.ObjectIdentified = contact.TaxCode;

          // currentState.instance.$setState({
          //   data: currentState.data,
          // });
          this.validate();
          requireSetData = true;
        });
      }

      if (fieldName == 'Contact' && fieldValue && !currentState.instance.loading) {
        await this.rootServices.apiService.getPromise<ContactModel[]>('/contact/contacts/' + this.commonService.getObjectId(fieldValue), {}).then(contacts => contacts[0]).then(contact => {

          // Auto fill object
          currentState.data.Contact = fieldValue;
          currentState.data.ContactName = contact.Name;
          currentState.data.ContactPhone = contact.Phone;
          currentState.data.ContactEmail = contact.Email;
          currentState.data.ContactAddress = contact.Address;
          currentState.data.ContactIdentified = contact.TaxCode;

          // currentState.instance.$setState({
          //   data: currentState.data,
          // });
          this.validate();
          requireSetData = true;
        });
      }

      if (listName == 'Details' && fieldName == 'Unit') {
        const item = this.currentState.data.Details[index];
        // const productSearchIndex = this.productSearchIndexs.find(f => f.Code == this.commonService.getObjectId(item.Product) && this.commonService.getObjectId(f.Unit) == this.commonService.getObjectId(fieldValue));
        const productSearchIndex = await this.rootServices.searchFromSql('product_search_index', {Code: this.commonService.getObjectId(item.Product), UnitId: this.commonService.getObjectId(fieldValue)}).then(rs => rs[0]);
        // const unitPrice = currentState.data[listName][index]?.__unitList?.find(f => f.id == this.commonService.getObjectId(fieldValue));
        if (productSearchIndex && productSearchIndex.Price) {
          currentState.data[listName][index]['Price'] = parseFloat(productSearchIndex.Price as any || 0);
          // currentState.data[listName][index]['ToMoney'] = (currentState.data[listName][index]['Quantity'] || 0) * (currentState.data[listName][index]['Price'] || 0);
        } else {
          currentState.data[listName][index]['Price'] = 0;
          this.commonService.showError('Đơn vị tính ' + currentState.instance.unitList.find(f => f.id == this.commonService.getObjectId(fieldValue))?.Name + ' không có giá bán !');
        }
        requireSetData = true;
      }

      if (listName == 'Details' && fieldName == 'AccessNumbers') {
        currentState.data[listName][index]['Quantity'] = currentState.data[listName][index]['AccessNumbers'].length;
        this.currentState.instance.$setState({ data: this.currentState.data });
      }
      // if (fieldName == 'IsDebt') {
      //   currentState.data['IsDebt'] = typeof fieldValue == 'string' && fieldValue == 'on';
      //   this.currentState.instance.$setState({ data: this.currentState.data });
      // }

      if (requireSetData) {
        const total = this.calculateTotal();
        currentState.instance.$setState({
          data: currentState.data,
          total: total,
        });
      } else {
        currentState.instance.$setState({ total: this.calculateTotal() });
      }
      return fieldInfo;
    });
  }

  async removeDetailItem(self: F7ComponentContext, e: any, ...args: any): Promise<{ detail?: any, listName?: string; index?: string | number; }> {
    const currenntState = this.currentState;
    return new Promise((resolve, reject) => {
      self.$app.dialog.confirm('Bạn có chắc là muốn gở chi tiết này ?', 'Gở chi tiết', () => {
        resolve(super.removeDetailItem(self, e, args).then(detailInfo => {
          let { detail, listName, index } = detailInfo;
          if (listName == 'UnitConversions') {
            self.$app.accordion.close(detail[0]);
          }
          this.calculateTotal();
          this.rootServices.playDecreasePipSound();
          return detailInfo;
        }).catch(err => {
          console.error(err);
          return Promise.reject(err);
        }));
      });
    });
  }


  async chooseProduct(self: F7ComponentContextExtend) {
    // const self: F7ComponentContextExtend = this;
    const $this = this;
    const currentState = this.currentState;
    this.rootServices.navigate('/commerce-pos/goods-list/choose', {
      context: {
        backTitle: this.title,
        textColorClass: self.textColorClass,
        priceTable: currentState.data.PriceTable,
        async onChoose(product: ProductSearchIndexModel) {
          currentState.instance.$router.back();
          console.log('Choosed product', product);

          // const productInfo = (await $this.rootServices.apiService.getPromise<ProductModel[]>('/admin-product/products/' + product.Code, { includeIdText: true, includeUnits: true }))[0];
          product.id = product.Code;
          product.text = product.Name;
          // const unit = product.UnitConversions && product.UnitConversions.find(f => f.IsDefaultPurchase) || product.UnitConversions[0]
          const unit = $this.commonService.getObjectId(product.Unit);
          // let unitList = $this.productSearchIndexs.filter(f => f.Code == $this.commonService.getObjectId(product)).map(m => m.Unit);
          let unitList = await $this.rootServices.searchFromSql('product_search_index', {Code: $this.commonService.getObjectId(product)}).then(rs => rs.map(m => m.Unit));
          unitList = [...new Set(unitList.map(m => $this.commonService.getObjectId(m)))].map(n => unitList.find(f => $this.commonService.getObjectId(f) == n));

          const index = currentState.data.Details.findIndex(f => $this.commonService.getObjectId(f.Product) == $this.commonService.getObjectId(product) && $this.commonService.getObjectId(f.Unit) == $this.commonService.getObjectId(unit) && $this.commonService.getObjectId(f.Container) == $this.commonService.getObjectId(product.Container));
          if (index > -1) {
            let item = currentState.data['Details'][index];
            item.Quantity++;
            $this.updateItemForList('Details', index, item);
            $this.rootServices.playIncreasePipSound();
            $this.commonService.showInfo('Tăng số lượng lên ' + item.Quantity + ' cho sản phẩm ' + $this.commonService.getObjectText(product) + ' !');
            return;
          }

          $this.addItemForList('Details', {
            ...new CommercePosOrderDetailModel(),
            Product: product,
            Description: product.Name,
            Unit: unit,
            Price: parseFloat(product.Price || 0 as any),
            Quantity: 1,
            Container: $this.commonService.getObjectId(product.Container),
            FindOrder: product.ContainerFindOrder,
            // Tax: 'NOTAX',
            __unitList: unitList,
            // __unitPrices: product.UnitList,
            Image: [
              ...[product.FeaturePicture],
              ...(Array.isArray(product.Pictures) && product.Pictures || []).filter(f => f.Id != product?.FeaturePicture?.Id)
            ]
          });

          $this.commonService.showInfo('Đã thêm sản phẩm vào danh sách');

        },
      }
    });
  }

  calculateTotal(data?: CommercePosOrderModel) {
    const currentState = this.currentState;
    let total = 0;
    const calculateData = data || currentState.data;
    for (const detail of calculateData.Details) {
      const toMoney = detail.ToMoney = (detail.Quantity || 0) * (detail.Price || 0);
      total += toMoney;
    }

    // currentState.instance.$setState({ total });
    return total;
  }

  async savePdf(self: F7ComponentContextExtend, e: any) {
    if (this.currentState.data.State !== 'APPROVED') {
      await this.save(self, { updateProperties: ['BarCode'] });
    }
    this.rootServices.iab.create(this.rootServices.apiService.buildApiUrl(this.apiPath, { id: this.currentState.data.Code, includeContact: true, includeDetails: true, includeUnit: true, renderPdf: 'download' }) as string, '_system');
  }
  async changeState(self: F7ComponentContextExtend, e: any) {
    const newState = this.currentState.data.State == 'APPROVED' ? 'UNRECORDED' : 'APPROVED';
    this.currentState.instance.$app.dialog.confirm('Bạn có muốn ' + (newState == 'APPROVED' ? 'thanh toán cho' : 'hủy') + ' đơn hàng này ?', 'Thanh toán', async () => {

      // if (this.currentState.data.State !== 'APPROVED') {
      //   await this.save(self);
      // }
      // if (!this.currentState.data.Code) {
      await this.rootServices.apiService.putPromise<CommercePosOrderModel[]>(this.apiPath + (this.currentState.data.Code ? ('/' + this.currentState.data.Code) : ''), {
        payment: newState == 'APPROVED',
        changeState: newState,
        renderBarCode: true
      }, [{
        Code: this.currentState.data.Code,
        ...(newState == 'APPROVED' ? this.currentState.data : {})
      }]).then(rs => rs[0]).then(newOrder => {

        this.currentState.data.Code = newOrder.Code;
        this.currentState.data.State = newOrder.State;
        this.currentState.data.BarCode = newOrder.BarCode;

        if (/^new\-?/.test(this.currentState.instance?.$route?.params?.id)) {
          this.state[newOrder.Code] = this.currentState;
          this.state[this.currentState.instance?.$route?.params?.id] = null;
        }

        this.currentState.instance.$route.params.id = newOrder.Code;
        this.currentState.id = newOrder.Code;



        if (newState == 'APPROVED') {
          this.commonService.showInfo('Đã thanh toán đơn hàng !');
        } else {
          this.commonService.showInfo('Đã hủy đơn hàng !');
        }
      }).catch(err => {
        this.commonService.showError(err);
        console.error(err);
      });

      // }
      this.currentState.data.State = newState;
      this.setData(this.currentState.data);

    });
  }


  // async barcodeExtract(inputValue: string, type?: string): Promise<{ product?: ProductModel, unit?: UnitModel, container?: string, findOrder?: string, price?: number, accessNumber?: string, unitSeq?: string }> {

  //   let toast: Toast.Toast;
  //   try {
  //     // if (!this.rootServices.productSearchIndexs$?.value) {
  //     //   toast = this.commonService.showInfo('Đang tải thông tin hàng hóa...', { position: 'bottom', timeout: 30000, cssClass: 'text-color-orange' });
  //     //   this.rootServices.cacheUpdate();
  //     //   await this.rootServices.productSearchIndexs$.pipe(filter(f => !!f), take(1)).toPromise();
  //     //   this.commonService.showInfo('Đã tải thông tin hàng hóa', { position: 'bottom' });
  //     //   toast.close();
  //     // }
  //     if (/^9\d+/.test(inputValue)) {
  //       // Đây là barcode vị trí hàng hóa
  //       let tmpcode = inputValue.substring(1);
  //       const findOrderLength = parseInt(tmpcode.substring(0, 1));
  //       tmpcode = tmpcode.substring(1);
  //       const findOrder = tmpcode.substring(0, findOrderLength);
  //       tmpcode = tmpcode.substring(findOrderLength);
  //       const unitSeqLength = parseInt(tmpcode.substring(0, 1));
  //       tmpcode = tmpcode.substring(1);
  //       let unitSeq = tmpcode.substring(0, unitSeqLength);
  //       tmpcode = tmpcode.substring(unitSeqLength);
  //       let productId = tmpcode;
  //       // let unit = this.unitMapBySeq[unitSeq];
  //       // let goodsInfo = this.findOrderMap[findOrder];
  //       let product = await this.rootServices.searchFromSql('product_Search_index', { FindOrder: findOrder }).then(rs => rs[0]);
  //       let unit = product.Unit;
  //       let unitId = product.UnitId;
  //       // let goodsInfo = this.findOrderMap[findOrder];
  //       // if (!goodsInfo) {
  //       //   throw new Error('Không tìm thấy hàng hóa !');
  //       // }
  //       // let container = goodsInfo.Container;
  //       let container = product.Container;
  //       // let product = this.productMap[goodsInfo.Goods];
  //       if (!product) {
  //         throw new Error('Không tìm thấy hàng hóa !');
  //       }
  //       // let price = this.productMapByUnit[`${product.Code}-${unitId}`]?.Price;
  //       const productByUnit = await this.rootServices.searchFromSql('product_Search_index', { Code: product.Code, UnitId: unitId }).then(rs => rs[0]);
  //       let price = parseFloat(productByUnit?.Price || 0 as any);

  //       // if (product && unitSeq) {
  //       if (productByUnit) {
  //         unit = productByUnit.Unit;
  //         if (unit) {
  //           unitId = unit.Code || unit.id;
  //           // product.Unit = { ...unit, id: unit.Code, text: unit.Name };
  //           product.Unit = { ...unit, id: unitId, text: unit.Name || unit.text };
  //         }
  //       }

  //       if (!product) {
  //         throw new Error('Không tìm thấy hàng hóa !');
  //       }

  //       unitId = unitId || this.commonService.getObjectId(product.Unit);
  //       // product.Price =  this.productMapByUnit[`${product.Code}-${unitId}`]?.Price;
  //       product.Price = price;
  //       productId = product.Code;
  //       product.FindOrder = findOrder;

  //       return { product, unit, container, findOrder, price };
  //     } else {
  //       // Trường hợp số truy xuất
  //       let accessNumber, productId, unitSeq, unit, unitId, product, price, container;
  //       if (type == 'ACCESS_NUMBER' || /^127/.test(inputValue)) {// Sử dụng tạm thời cho các mã qr code phiên bản v1
  //         accessNumber = inputValue;
  //         await this.rootServices.apiService.getPromise<ProductModel[]>('/commerce-pos/products', {
  //           accessNumber: accessNumber,
  //           includeUnit: true,
  //           includePrice: true,
  //           includeInventory: true,
  //           includePreviousOrder: true,
  //         }).then(async rs => {
  //           console.log(rs);
  //           const goods = rs[0];

  //           productId = goods.Code;
  //           unitId = this.commonService.getObjectId(goods.Unit);
  //           // unit = this.unitMapById[unitId];
  //           product = await this.rootServices.searchFromSql('product_search_index', { Code: productId, UnitId: unitId }).then(rs => rs[0]);
  //           // unit = this.unitMapById[unitId];
  //           unit = product.Unit;
  //           // unitSeq = unit.Sequence;
  //           unitSeq = product.UnitSeq;
  //           // product = this.productMap[productId];
  //           // price = this.productMapByUnit[`${product.Code}-${unitId}`]?.Price;
  //           price = parseFloat(product.Price);
  //           container = goods.Container;
  //           return product;
  //         });
  //       } else if (type == 'FIND_ORDER') {
  //         // const findOrder = this.findOrderMap[inputValue];
  //         const findOrder = await this.rootServices.searchFromSql('product_Search_index', { FindOrder: inputValue }).then(rs => rs[0]);
  //         // productId = findOrder.Goods;
  //         productId = findOrder.Code;
  //         // product = this.productMap[productId];
  //         product = findOrder;
  //         if (!product) {
  //           return Promise.reject('Không tim thấy hàng hóa !');
  //         }
  //         unitId = findOrder.Unit;
  //         // unit = this.unitMapById[unitId];
  //         unit = findOrder.Unit;
  //         // unitSeq = unit.Sequence;
  //         unitSeq = findOrder.UnitSeq;
  //         // price = this.productMapByUnit[`${product.Code}-${unitId}`]?.Price;
  //         price = parseFloat(findOrder.Price as any);
  //         container = findOrder.Container;

  //       } else {
  //         const extracted = this.rootServices.extractGoodsBarcode(inputValue);
  //         accessNumber = extracted.accessNumber;
  //         productId = extracted.productId;
  //         unitSeq = extracted.unitSeq;
  //         // unit = this.unitMapBySeq[unitSeq];
  //         product = await this.rootServices.searchFromSql('product_search_index', { Code: productId, UnitSeq: unitSeq }).then(rs => rs[0]);
  //         unit = product.Unit;
  //         unitId = this.commonService.getObjectId(unit);
  //         // product = this.productMap[productId];
  //         if (!product) {
  //           return Promise.reject('Không tim thấy hàng hóa !');
  //         }
  //         // price = this.productMapByUnit[`${product.Code}-${unitId}`]?.Price;
  //         price = parseFloat(product.Price);

  //         // if (product && unitSeq) {
  //         //   unit = this.unitMapBySeq[unitSeq];
  //         //   if (unit) {
  //         //     unitId = unit.Code;
  //         //     product.Unit = { ...unit, id: unit.Code, text: unit.Name };
  //         //   }
  //         // }
  //       }

  //       return { product, unit, price, accessNumber, unitSeq, container };
  //     }
  //   } catch (err) {
  //     this.commonService.showError(err);
  //     toast && toast.close();
  //     return Promise.reject(err);
  //   }
  //   // return null;
  // }

  activeItem(index: number, newData?: CommercePosOrderDetailModel) {

    this.currentState.data.Details.map(detail => {
      detail.__active = false;
    });
    this.currentState.data.Details[index].__active = true;

    const total = this.calculateTotal();
    this.currentState.instance.$setState({ data: this.currentState.data, total });
    if (newData?.AccessNumbers) {
      setTimeout(() => {
        this.setFieldValue('smart-select', 'AccessNumbers', newData.AccessNumbers, 'Details', index);
      }, 300);
    }
    setTimeout(() => {
      const itemEl = $(this.currentState.instance.el).find('.list-item.index-' + index);
      // itemEl[0] && itemEl[0].scrollIntoView();
      if (itemEl.length > 0) {
        const pageContentEl = $(this.currentState.instance.el).find('.page-content');
        pageContentEl.animate({ scrollTop: pageContentEl.scrollTop() + itemEl.offset().top - itemEl.height() }, 300);
      }
    }, 300);

  }

  barcodeProcessCount = -1;
  isBarcodeProcessing = new BehaviorSubject<number>(0);
  async barcodeProcess(barcode: string, type?: string) {
    const $this = this;

    this.barcodeProcessCount++;
    const queueId = this.barcodeProcessCount;
    const toastOption: any = { position: 'bottom' };

    // Wait for previous barcode process finish
    await this.isBarcodeProcessing.pipe(filter(f => {
      console.log(`Barcode processing queue check: ${f} === ${queueId}`);
      return f === queueId;
    }), take(1)).toPromise();

    return await $this.rootServices.barcodeExtract(barcode, type).then(async result => {
      if (!result) {
        $this.commonService.showError('Không tìm thấy hàng hóa !', toastOption);
        return false;
      }

      let product = result.product;
      let unit = result.unit;
      let containerList = [];
      let container;
      let accessNumber = result.accessNumber;
      let hadWarning = false;
      let price = result.price;

      if (!price) {
        $this.rootServices.playErrorPipSound();
        $this.commonService.showError('Hàng hoá chưa có giá bán !', toastOption);
      }

      const addedResult = this.checkAndAddDetail({ product, unit, container, accessNumber, price });
      this.isBarcodeProcessing.next(queueId + 1);
      return addedResult;

    }).catch(err => {
      console.error(err);
      this.commonService.showError(err, toastOption);
      this.isBarcodeProcessing.next(queueId + 1);
      return Promise.reject(err);
    });
  }

  async checkAndAddDetail(info: { product?: ProductModel, unit?: UnitModel, container?: any, accessNumber?: string, price?: number, quantity?: number }) {
    const $this = this;
    let { product, unit, container, accessNumber, price, quantity } = info;
    let oldIndex = null;

    const toastOption: any = { position: 'bottom' };

    let containerList;
    let hadWarning = false;
    // if ($this.productSearchIndexs) {
    //   containerList = $this.productSearchIndexs.filter(f => f.Code == this.commonService.getObjectId(product) && this.commonService.getObjectId(f.Unit) == this.commonService.getObjectId(unit))
    //     .map(m => ({ id: m.Container.id, text: m.Container.ShelfName + '/' + m.Container.FindOrder, findOrder: m.Container.FindOrder }));
    // }
    containerList = (await $this.rootServices.searchFromSql('product_search_index', { Code: this.commonService.getObjectId(product), UnitId: this.commonService.getObjectId(unit) }))
      .map(m => ({ id: m.Container.id, text: m.Container.ShelfName + '/' + m.Container.FindOrder, findOrder: m.Container.FindOrder }));
    if (!accessNumber) {
      container = containerList.find(f => f.id == this.commonService.getObjectId(container));
    } else {
      if (containerList && containerList.length > 0) {
        if (accessNumber) {
        } else {
          if (containerList.length == 1) {
            container = this.commonService.getObjectId(containerList[0]);
          }
        }
        // }
      } else {
        $this.rootServices.playErrorPipSound();
        $this.commonService.showError(`${product.Name} chưa được cài đặt vị trí !`, toastOption);
        hadWarning = true;
      }

    }

    console.log('Container list: ', containerList);

    if (accessNumber) {
      oldIndex = $this.currentState.data.Details.findIndex(f => $this.commonService.getObjectId(f.Product) == $this.commonService.getObjectId(product) && $this.commonService.getObjectId(f.Unit) == $this.commonService.getObjectId(unit))
    } else {
      oldIndex = $this.currentState.data.Details.findIndex(f => $this.commonService.getObjectId(f.Product) == $this.commonService.getObjectId(product) && $this.commonService.getObjectId(f.Unit) == $this.commonService.getObjectId(unit) && $this.commonService.getObjectId(f.Container) == $this.commonService.getObjectId(container))
    }
    if (oldIndex < 0) {

      $this.addItemForList('Details', {
        ...new CommercePosOrderDetailModel(),
        Product: product,
        Description: product?.Name,
        Unit: unit,
        Price: parseFloat(price || 0 as any),
        Quantity: accessNumber ? 1 : (quantity || 0),
        Container: container,
        AccessNumbers: (accessNumber ? [accessNumber] : []),
        __containerList: containerList,
        __unitList: [unit],
        Image: [
          ...(product?.FeaturePicture ? [product?.FeaturePicture] : []),
          ...(Array.isArray(product.Pictures) && product.Pictures || []).filter(f => f.Id != product?.FeaturePicture?.Id)
        ]
      });

      // $this.activeItem($this.currentState.data.Details.length - 1, );
      // if (!hadWarning) $this.rootServices.playNewPipSound();
      if (!hadWarning) $this.commonService.showInfo('Đã thêm hàng hóa', toastOption);

    } else {

      const existsDetail = $this.currentState.data.Details[oldIndex];
      if (!accessNumber) {
        $this.rootServices.playErrorPipSound();
        if (quantity) {
          existsDetail.Quantity += quantity;
        }
        $this.activeItem(oldIndex, existsDetail);
        if (!quantity) {
          $this.commonService.showError('Hàng hóa đã có trong danh sách ! - ' + product.text + ' (' + unit.text + ') - ' + (containerList[0] && containerList[0].text), toastOption);
        }
      } else {
        let currentAccessNumbers: string[] = existsDetail.AccessNumbers = existsDetail.AccessNumbers || [];
        if (currentAccessNumbers.indexOf(accessNumber) < 0) {
          currentAccessNumbers.unshift(accessNumber);
          currentAccessNumbers = [...new Set(currentAccessNumbers)];
          existsDetail.Quantity = currentAccessNumbers.length;
          $this.activeItem(oldIndex, existsDetail);
          if (!hadWarning) $this.rootServices.playIncreasePipSound();
        } else {
          $this.activeItem(existsDetail.__index);
          $this.commonService.showError(`${accessNumber} đang có trong danh sách rồi !`, toastOption);
          if (!hadWarning) $this.rootServices.playErrorPipSound();
          return false;
        }
      }
    }

    if (accessNumber) {
      // Check inventory
      $this.rootServices.apiService.getPromise<any[]>('/commerce-pos/products', {
        accessNumber: accessNumber,
        includeUnit: true,
        includePrice: true,
        includeInventory: true,
        includePreviousOrder: true,
      }).then(async rs => {
        console.log(rs);
        const goods = rs[0];
        if (accessNumber) {

          const index = this.currentState.data.Details.findIndex(detail => detail.AccessNumbers && detail.AccessNumbers.some(ac => ac == accessNumber));
          const detail = this.currentState.data.Details[index];
          if (!goods?.Inventory) {

            if (detail) {
              detail.AccessNumbers = detail.AccessNumbers.filter(f => f != accessNumber);
              detail.Quantity = detail.AccessNumbers.length;
              detail.__hasWarning = true;
              detail.__hasWarningText = 'Số truy xuất không có trong kho !';
              const total = this.calculateTotal();
              this.currentState.instance.$setState({ data: this.currentState.data, total });
              setTimeout(() => {
                this.setFieldValue('smart-select', 'AccessNumbers', detail.AccessNumbers, 'Details', index);
              }, 300);
            }

            $this.commonService.showError(`Số truy xuất ${accessNumber} của ${goods.Name} không có trong kho !`, toastOption);
            $this.rootServices.playErrorPipSound();
          } else {
            if (typeof goods.Container == 'string') {
              // const container = this.productSearchIndexs.find(f => f.Code == product.Code && this.commonService.getObjectId(f.Unit) == this.commonService.getObjectId(unit) && this.commonService.getObjectId(f.Container) == goods.Container)?.Container;
              const container = await $this.rootServices.searchFromSql('product_search_index', { Code: product.Code, UnitId: this.commonService.getObjectId(unit), ContainerId: this.commonService.getObjectId(goods.Container) }).then(rs => rs[0]?.Container);
              container.text = container.ShelfName + '/' + container.FindOrder;
              detail.Container = container;
            } else {
              detail.Container = goods.Container;
            }
            this.currentState.instance.$setState({ data: this.currentState.data });
          }
        }

        return rs[0];
      });
    }
    return true;
  }

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: 'commerce-pos-gui',
      path: '/commerce-pos/gui/:id',
      component: {
        template: /*html*/`
            <div class="page pos-gui page-form">
              <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a class="link back {{textColorClass}}">
                      <i class="icon icon-back"></i>
                      <span class="if-not-md {{textColorClass}}">{{backTitle}}</span>
                    </a>
                  </div>
                  <div class="title">POS</div>
                  <!--<div class="right">
                    <a class="link {{textColorClass}}" @click="next">
                      <span class="if-not-md {{textColorClass}} {{js "this.isLastOrder ? 'text-color-green' : ''"}}">{{#if isLastOrder}}New{{else}}Next{{/if}}</span>
                      <i class="icon icon-forward {{js "this.isLastOrder ? 'text-color-green' : ''"}}"></i>
                    </a>
                  </div>-->
                  <div class="right">
                    <!--<a class="link {{textColorClass}} text-color-orange" @click="toggleFlashLight">
                      <i class="icon f7-icons">bolt</i>
                    </a>-->
                    <a class="link {{textColorClass}}" @click="scanBarcode">
                      <i class="icon f7-icons">barcode_viewfinder</i>
                    </a>
                  </div>
                </div>
              </div>


              <div class="fab fab-center-bottom color-orange assistiveTouch">
                <a href="#" @click="scanBarcode">
                  <i class="icon f7-icons">barcode_viewfinder</i>
                </a>
              </div>
              
              <div class="page-content ptr-content infinite-scroll-content" @ptr:refresh="refresh" data-ptr-distance="150">
                <div class="ptr-preloader">
                  <div class="preloader"></div>
                  <div class="ptr-arrow"></div>
                </div>

                <form class="data-form">
                  <div class="list profile-form no-hairlines-md inset main-form">
                    <ul>
                      {{#if data.BarCode}}
                      <li class="item-content item-input" style="background: #fff;">
                        <div class="item-inner">
                          <div class="item-input-wrap">
                            <div style="padding-top: 0.5rem">
                              <img src="{{data.BarCode}}" style="width: 100%;">
                            </div>
                          </div>
                        </div>
                      </li>
                      {{/if}}
                      <li class="item-content item-input inline-labels">
                        <div class="item-inner">
                          <div class="item-title item-label">ID phiếu (*)</div>
                          <div class="item-input-wrap">
                            <input @change="onFieldChange" disabled class="field text-color-blue" name="Code" type="text" placeholder="đơn mới"
                              value="{{data.Code}}" style="text-align: right">
                          </div>
                        </div>
                      </li>
                      {{#if copyFromId}}
                      <li class="item-content">
                        <div class="item-inner">
                          <div class="item-input-wrap text-color-orange">
                            <span style="font-weight: bold; font-style: italic">Bạn đang thao tác trên phiếu copy</span>
                          </div>
                        </div>
                      </li>
                      {{/if}}
                    </ul>
                  </div>

                  <div class="block-title" style="margin-top: 0.5rem">Chi tiết<a @click="addDetail" data-list-name="Details" class="button button-small button-fill" style="float: right; font-size: 0.7rem; font-weight: bold">Thêm</a></div>
                  <div class="list-name thumbnail-list" name="Details">
                    {{#each data.Details}}
                      <div class="block block-strong inset list-item index-{{@index}} {{js "this.__hasWarning ? 'bg-color-orange' : (this.__active ? 'bg-color-red text-color-white' : '')"}}" data-id="{{Uuid}}" data-index="{{@index}}">
                        <div class="item-content">
                          <div class="detail-wrap">
                            <div class="detail-header">
                              <div class="header-label">{{js "this.Product && this.Product.Name"}}</div>
                              <div class="detail-remove" @click="removeDetailItem"><i class="icon f7-icons {{js "this.__active ? 'color-white' : 'color-red'"}}">xmark_circle_fill</i></div>
                            </div>
                            <div class="detail-body">
                              <div class="image-wrap">
                                <div class="image"  @click="previewPictures" data-index="0" style="{{js "this.Image && this.Image[0] && this.Image[0].Thumbnail && ('background-image: url('+this.Image[0].Thumbnail+')') || ''"}}"></div>
                              </div>
                              <div class="detail-content-wrap">
                                <div class="list inline-labels no-hairlines">
                                  <ul style="background: none">
                                    <li class="item-content item-input">
                                      <div class="item-inner">
                                        <div class="item-title item-label">SL</div>
                                        <div class="item-input-wrap">
                                          <div class="stepper stepper-fill stepper-init" data-manual-input-mode="true" data-autorepeat="true" data-autorepeat-dynamic="true" name="Quantity" @stepper:change="onFieldChange">
                                            <div class="stepper-button-minus"></div>
                                            <div class="stepper-input-wrap">
                                              <input type="text" value="{{Quantity}}" min="0" max="99999999999999999999" class="{{js "this.__active ? 'text-color-white' : ''"}}" name="Quantity" @keyup="onFieldChange">
                                            </div>
                                            <div class="stepper-button-plus"></div>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li class="item-link smart-select smart-select-init" name="Unit" data-open-in="popup" data-searchbar="true" data-virtual-list="true" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFieldChange" data-list-name="UnitConversions" data-index="{{@index}}">
                                      <select>
                                        <option value="">Chon...</option>
                                        {{#js_if "this.__unitList && this.__unitList.length > 0"}}
                                          {{#each __unitList}}
                                          <option value="{{id}}">{{text}}</option>
                                          {{/each}}
                                        {{else}}
                                          {{#each ../../unitList}}
                                          <option value="{{id}}">{{text}}</option>
                                          {{/each}}
                                        {{/js_if}}
                                      </select>
                                      <div class="item-content">
                                        <div class="item-inner">
                                          <div class="item-title {{validate Unit ../schema 'Details.Unit'}}">ĐVT (*)</div>
                                          <div class="item-after {{js "this.__active ? 'text-color-white' : 'text-color-blue'"}}"></div>
                                        </div>
                                      </div>
                                    </li>
                                    <li class="item-link item-content item-input" style="padding-left: 0; min-height: 2rem;">
                                      <div class="item-inner">
                                        <div class="item-title item-label">Giá</div>
                                        <div class="item-input-wrap">
                                          <input type="text" class="inputmask field {{js "this.__active ? 'text-color-white' : 'text-color-blue'"}}" name="Price" value="{{decimal Price}}" placeholder="Đơn giá" @keyup="onFieldChange">
                                          <div class="currency-symbol {{js "this.__active ? 'text-color-white' : 'text-color-blue'"}}">₫</div>
                                        </div>
                                      </div>
                                    </li>
                                    <li class="item-link item-content item-input" style="padding-left: 0; min-height: 2rem;">
                                      <div class="item-inner">
                                        <div class="item-title item-label">T.Tiền</div>
                                        <div class="item-input-wrap">
                                          <input type="text" class="inputmask field {{js "this.__active ? 'text-color-white' : 'text-color-blue'"}}" name="ToMoney" style="font-weight: bold" value="{{decimal ToMoney}}" placeholder="Thành tiền" @keyup="onFieldChange">
                                          <div style="font-weight: bold" class="currency-symbol {{js "this.__active ? 'text-color-white' : 'text-color-blue'"}}">₫</div>
                                        </div>
                                      </div>
                                    </li>
                                    <li class="item-link smart-select smart-select-init" name="AccessNumbers" data-open-in="popup" data-searchbar="true" data-virtual-list="true" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-to-selected-item="true" data-index="{{@index}}" @smartselect:close="onFieldChange">
                                      <select multiple>
                                        <option value="">Chon...</option>
                                        {{#each AccessNumbers}}
                                        <option value="{{this}}">{{this}}</option>
                                        {{/each}}
                                      </select>
                                      <div class="item-content">
                                        <div class="item-inner">
                                          <div class="item-title {{validate AccessNumbers ../schema 'Details.AccessNumbers'}}" style="width: 10%">Số truy xuất</div>
                                          <div class="item-after {{js "this.__active ? 'text-color-white' : 'text-color-blue'"}}" style="max-width: 90%;"></div>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div class="detail-footer">{{__hasWarningText}} SKU: {{js "this.Product && this.Product.Sku"}}, ID: {{js "this.Product && this.Product.Code"}}, VT: {{text Container}}</div>
                          </div>
                        </div>
                      </div>
                      {{/each}}
                  </div>
              
                  <div class="block block-strong inset" style="text-align: right; font-weight: bold; font-size: 1rem;">
                    Tổng cộng: <span class="text-color-blue">{{currency total}}</span>
                  </div>

                  <div class="list no-hairlines-md inset" style="margin-bottom: 0.5rem">
                    <ul>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label">Thêm hàng hóa theo SKU/ví trí/số truy xuất</div>
                          <div class="item-input-wrap" style="display: flex; align-items: center;">
                            <input id="SkuInput" class="field text-color-blue" name="Sku" type="text" placeholder="SKU/ví trí/số truy xuất..." @keyup="addProductBySku" style="text-transform: uppercase;">
                            <i class="icon f7-icons if-not-md-x link text-color-blue" @click="addProductBySku">arrow_up_circle_fill</i>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div class="block">
                    <p class="row">
                      <a class="col-50 button button-large button-outline link color-orange" @click="scanBarcode" data-infinite-scan="true">
                        <i class="icon f7-icons if-not-md-x">barcode_viewfinder</i> Quét liên tục</a>
                      <a class="col-50 button  button-large button-outline link color-orange" @click="chooseProduct">
                        <i class="icon f7-icons if-not-md-x">cube_box</i> Hàng hóa</a>
                    </p>
                    <p class="row">
                      <a class="col-50 button button-outline button-large color-blue {{#if validates}}disabled{{/if}}"  @click="save"><i class="icon f7-icons if-not-md-x">floppy_disk</i> Lưu lại</a>
                      <a class="col-50 button button-outline button-large color-yellow" @click="print"><i class="icon f7-icons if-not-md-x">printer</i> Print</a>
                    </p>
                    <p class="row">
                      <a class="col-100 button button-fill button-large color-{{js "this.data.State == 'APPROVED' ? 'orange' : 'green'"}} {{#if validates}}disabled{{/if}}" @click="changeState"><i class="icon f7-icons if-not-md-x">cart</i> {{js "this.data.State == 'APPROVED' ? 'Bỏ ghi' : 'Thanh toán'"}}</a>
                    </p>
                    <p class="row">
                      <b class="col-50 button button-outlinex button-large color-orange {{js "this.isFirstOrder ? 'disabled' : ''"}}" @click="previous"><i class="icon f7-icons if-not-md-x">chevron_compact_left</i> Đơn trước</b>
                      <a class="col-50 button button-outlinex button-large {{js "this.isLastOrder ? 'color-green' : 'color-orange'"}}" @click="next">{{#if isLastOrder}}Đơn mới{{else}}Đơn sau{{/if}}<i class="icon f7-icons if-not-md-x">chevron_compact_right</i></a>
                    </p>
                  </div>

                  <div class="block-title">Thông tin khách hàng</div>
                  <div class="list profile-form no-hairlines-md inset main-form">
                    <ul>
                      <li class="autocomplete {{validate data.Object schema 'Object'}}" name="Object">
                        <div class="item-link item-content" href="#">
                          <div class="item-inner">
                            <div class="item-title">Khách hàng</div>
                            <div class="item-after text-color-blue">{{js "this.data && this.data.Object && this.data.Object.text || 'Chọn...'"}}</div>
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label {{validate data.ObjectName schema 'ObjectName'}}">Tên</div>
                          <div class="item-input-wrap">
                            <input @keyup="onFieldChange" class="field text-color-blue" name="ObjectName" type="text" placeholder="Tên khách hàng..."
                              value="{{data.ObjectName}}">
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label">Số điện thoại</div>
                          <div class="item-input-wrap">
                            <input @keyup="onFieldChange" class="field text-color-blue" name="ObjectPhone" type="text" placeholder="Số điện thoại..."
                              value="{{data.ObjectPhone}}">
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label">Email</div>
                          <div class="item-input-wrap">
                            <input @keyup="onFieldChange" class="field text-color-blue" name="ObjectEmail" type="text" placeholder="Email..."
                              value="{{data.ObjectEmail}}">
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label">Địa chỉ</div>
                          <div class="item-input-wrap">
                            <input @keyup="onFieldChange" class="field text-color-blue" name="ObjectAddress" type="text" placeholder="Địa chỉ..."
                              value="{{data.ObjectAddress}}">
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label">Ghi chú</div>
                          <div class="item-input-wrap">
                            <input @keyup="onFieldChange" class="field text-color-blue" name="Note" type="text" placeholder="Ghi chú..."
                              value="{{data.Note}}">
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input inline-labels">
                        <div class="item-inner">
                          <div class="item-title item-label">Công nợ</div>
                          <div class="item-input-wrap" style="text-align: right;">
                          <label class="toggle toggle-init color-orange" name="IsDebt">
                            <input type="checkbox" class="field" name="IsDebt" @change="onFieldChange"/>
                            <span class="toggle-icon"></span>
                          </label>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>

                  {{#if validates}}
                  <div class="block-title text-color-arange">Cảnh báo</div>
                  <div class="list inset">
                    <ul>
                      {{#each validates}}
                      <li>
                        <div class="item-content">
                          <div class="item-inner">
                            <div class="item-title">{{validates[0].label}}</div>
                            <div class="item-after text-color-orange">{{validates[0].text}}</div>
                          </div>
                        </div>
                      </li>
                      {{/each}}
                    </ul>
                  </div>
                  {{/if}}
                </form>
            
                <div class="block"><br></div>
              </div>
            </div>
        `,
        style: /*css*/`
          .page.pos-gui .demo-card-header-pic .card-header {
            height: calc(100vw - 2rem);
            background-size: cover;
            background-position: center;
            color: #fff;
          }

          .page.pos-gui .demo-card-header-pic .card-content-padding .date {
            color: #8e8e93;
          }
          .page.pos-gui .pictures::-webkit-scrollbar {
            display: none;
          }

          .page.pos-gui .card .background {
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-image: url(/assets/images/no-image-available.png);
          }

          .page.pos-gui .inline-labels .item-label, .inline-label .item-label, .inline-labels .item-floating-label, .inline-label .item-floating-label {
            width: auto !important;
          }
        `,
        data() {
          return {
            title: $this.title,
            reminder: {
              No: '234234',
            },
            data: new CommercePosOrderModel(),
            contactMemebets: [],
            // contactList: [],
            validates: null,
            isLastOrder: false,
            isFirstOrder: true,
          };
        },
        methods: {
          toggleFlashLight() {
            const self: F7ComponentContextExtend = this;
            $this.rootServices.toggleEmbedBarcodeSannerFlash();



            // (window as any).plugins.flashlight.available(function (isAvailable) {
            //   if (isAvailable) {
            //     if (!self.isFlashOn) {
            //       self.isFlashOn = true;

            //       // switch on
            //       (window as any).plugins.flashlight.switchOn(
            //         function () { }, // optional success callback
            //         function () { }, // optional error callback
            //         { intensity: 0.3 } // optional as well
            //       );

            //       // switch off after 3 seconds
            //       // setTimeout(function () {
            //       // }, 3000);

            //     } else {
            //       (window as any).plugins.flashlight.switchOff(); // success/error callbacks may be passed
            //       self.isFlashOn = false;
            //     }
            //   } else {
            //     alert("Flashlight not available on this device");
            //   }
            // });

          },
          print() {
            const self: F7ComponentContextExtend = this;
            // $this.printer.print(JSON.stringify($this.currentState.data), $this.printPptions).then(() => {
            //   $this.commonService.showInfo('Print success');
            // }, () => {
            //   $this.commonService.showError('Print error');
            // });
            $this.rootServices.navigate('/commerce-pos/order-print/' + $this.currentState.data.Code, {
              context: {
                backTitle: 'Back',
                textColorClass: self.textColorClass,
                doNotReload: true,
                callback: (data: CommercePosOrderModel, state: any) => {
                  console.log('Callback data: ', data);
                }
              }
            });
          },
          async addProductBySku(e: any) {
            const self: F7ComponentContextExtend = this;
            const skuInput = self.$el.find('#SkuInput');
            const code: string = skuInput.val().trim();

            // Sku
            if (e instanceof KeyboardEvent) {
              if (e.key != 'Enter') {
                return;
              }
            }

            // Access number case
            if (/^127/.test(code)) {
              $this.barcodeProcess(code, 'ACCESS_NUMBER').then(rs => {
                $this.commonService.hidePreloader();
              }).catch(err => {
                $this.commonService.hidePreloader();
              });
              skuInput.val('');
              // $this.removeBarcodeScanner();
              return;
            }

            // Find order case
            if (!/^[a-z]/i.test(code) && code.length < 6) {
              $this.barcodeProcess(code, 'FIND_ORDER');
              // $this.removeBarcodeScanner();
              skuInput.val('');
              return;
            }

            $this.removeBarcodeScanner();

            // Wait for load product index
            // if (!$this.rootServices.productSearchIndexs$?.value) {
            //   const toast = $this.commonService.showInfo('Đang tải thông tin hàng hóa...', { position: 'bottom', timeout: 30000, cssClass: 'text-color-orange' });
            //   await $this.rootServices.productSearchIndexs$.pipe(filter(f => !!f), take(1)).toPromise();
            //   $this.commonService.showInfo('Đã tải thông tin hàng hóa', { position: 'bottom' });
            //   toast.close();
            // }

            // const goodsList = $this.productSearchIndexs.find(f => f.Sku.toLowerCase() == sku.toLowerCase() && __.objectid(f.BaseUnit) == __.objectid(f.Unit));
            skuInput.val('');

            // const goodsList = $this.productSearchIndexs.filter(f => $this.commonService.rightDetectiveFilter(f.Sku, code) && __.objectid(f.BaseUnit) == __.objectid(f.Unit));
            let goodsList = await $this.rootServices.searchFromSql('product_search_index', { SearchText: `${code}%` });
            goodsList = goodsList.filter(f => __.objectid(f.BaseUnit) == __.objectid(f.Unit));

            if (goodsList.length == 1) {
              const goods = goodsList[0];
              $this.checkAndAddDetail({ product: goods, unit: goods.Unit, container: goods.Container, price: goods.Price, quantity: 1 });
              skuInput.focus();
            } else {
              if (goodsList.length > 1) {

                skuInput.blur();

                let listItemTemplate = Template7.compile(/*html*/`
                <div class="list media-list" style="margin-left: -1rem; margin-right: -1rem; margin-bottom: 0.5rem; margin-top: 0.5rem; text-align: left;">
                  <ul>
                    {{#each goodsList}}
                    <li data-id="{{id this}}">
                      <a href="#" class="item-link item-content">
                        <div class="item-media"><div class="bg-color-gray" style="border-radius: 50%; overflow: hidden; width: 50px; height: 50px; background-repeat: no-repeat; background-size: cover; background-image: url({{js "this.FeaturePicture && this.FeaturePicture.Thumbnail || ''"}})"></div></div>
                        <div class="item-inner">
                          <div class="item-title" style="white-space: normal;">{{text this}}</div>
                          <div class="item-subtitle text-color-gray" style="font-size: 0.7rem; font-weight: bold;">{{Sku}} - {{text Container}}</div>
                          <div class="item-subtitle text-color-red">Giá: {{currency Price}}/{{text Unit}}</div>
                        </div>
                      </a>
                    </li>
                    {{/each}}
                  </ul>
                </div>
                `);

                const dialog = $this.currentState.instance.$app.dialog.create({
                  title: 'Chọn hàng hóa',
                  text: 'Có nhiều hàng hóa có sku gần giống với sku cần tìm, bạn hãy chọn 1 trong các hàng hóa bên dưới !',
                  content: listItemTemplate({ goodsList }),
                  buttons: [
                    {
                      text: 'Trở về',
                      bold: true,
                      color: 'red',
                      onClick: () => {
                        skuInput.focus();
                      }
                    },
                  ],
                  verticalButtons: true,
                });
                dialog.open();
                const listItem = dialog.$el.find('.list li');
                listItem.click(function () {
                  const goodsId = $(this).attr('data-id');
                  const goods = goodsList.find(f => __.objectid(f) == goodsId);
                  $this.checkAndAddDetail({ product: goods, unit: goods.Unit, container: goods.Container, price: goods.Price, quantity: 1 });
                  skuInput.focus();
                  dialog.close();
                });

              } else {
                $this.commonService.showError('Không tìm thấy hàng hóa theo SKU');
                skuInput.focus();
              }
            }
          },
          async next(e) {
            const self: F7ComponentContextExtend = this;
            const index = $this.orderList.findIndex(f => f.id == $this.currentState.id);
            let nextId;
            if (index == $this.orderList.length - 1) {
              // End of list => create new
              if ($this.currentState.data.State != 'APPROVED') {
                await $this.save(self);
              }
              nextId = 'new-' + Date.now();
            } else {
              nextId = $this.orderList[index + 1].id;
            }
            $this.rootServices.navigate('/commerce-pos/gui/' + nextId, {
              context: {
                // backTitle: ,
                textColorClass: self.textColorClass,
                doNotReload: true,
                callback: (data: CommercePosOrderModel, state: any) => {
                  console.log('Callback data: ', data);
                }
              }
            });
          },
          async previous(e) {
            const self: F7ComponentContextExtend = this;
            const index = $this.orderList.findIndex(f => f.id == $this.currentState.id);
            // let nextId;
            self.$router.back();
            // if (index > 0) {
            //   $this.orderList.splice(index, 1);
            //   console.log($this.orderList);
            //   //   // End of list => create new
            //   //   if ($this.currentState.data.State != 'APPROVED') {
            //   //     await $this.save(self);
            //   //   }
            //   //   // nextId = 'new-' + Date.now();
            //   //   nextId = $this.orderList[index - 1].id;
            //   //   $this.rootServices.navigate('/commerce-pos/gui/' + nextId, {
            //   //     context: {
            //   //       // backTitle: ,
            //   //       textColorClass: self.textColorClass,
            //   //       doNotReload: true,
            //   //       callback: (data: CommercePosOrderModel, state: any) => {
            //   //         console.log('Callback data: ', data);
            //   //       }
            //   //     }
            //   //   });
            // }
          },
          enterAccessNumber(e) {
            const self: F7ComponentContextExtend = this;
            self.$app.dialog.prompt('Nhập số truy xuất', 'Thêm hàng hóa bằng số truy xuất', (accessNumber => {
              $this.commonService.showPreloader();
              $this.barcodeProcess(accessNumber, 'ACCESS_NUMBER').then(rs => {
                $this.commonService.hidePreloader();
              }).catch(err => {
                $this.commonService.hidePreloader();
              });
            }));
          },
          enterFindOrder(e) {
            const self: F7ComponentContextExtend = this;
            self.$app.dialog.prompt('Nhập số truy xuất', 'Thêm hàng hóa bằng số truy xuất', (findOrder => {
              $this.barcodeProcess(findOrder, 'FIND_ORDER');
            }));
          },
          changeState(e) {
            $this.changeState(this, e);
          },
          savePdf(e) {
            $this.savePdf(this, e);
          },
          openRelativeTask(e) {
            const self: F7ComponentContextExtend = this;
            const currenntState = $this.currentState;
            const voucher = currenntState.data;
            if (voucher) {
              const relativeTask = voucher.RelativeVouchers && voucher.RelativeVouchers.find(f => f.type == 'CHATROOM' || f.type == 'TASK') || null;
              if (relativeTask) {
                $this.rootServices.navigate('/chat-room/' + relativeTask.id);
              } else {
                if (!voucher.Code || !voucher.Title) {
                  $this.commonService.showError('Bạn phải lưu phiếu trước và phiếu phải có tiêu đề !');
                  return;
                }
                self.$f7.dialog.prompt('Mô tả cho task mới', function (description) {
                  const chatRoomData = {
                    Type: 'WORKPLACE',
                    Description: description,
                    // Members: [
                    //   {
                    //     type: 'CONTACT',
                    //     id: $this.commonService.getObjectId(voucher.Object),
                    //     text: voucher.ObjectName,
                    //   }
                    // ],
                    RelativeVouchers: [
                      {
                        id: voucher.Code,
                        text: voucher.Title,
                        type: 'PURCHASEORDER'
                      }
                    ],
                  };
                  self.$app.preloader.show();
                  $this.rootServices.apiService.postPromise<ChatRoomModel[]>('/chat/rooms', {}, [chatRoomData]).then(async rs => {
                    const contactMembers = [];
                    if (voucher.Object) {
                      contactMembers.push({
                        Type: 'CONTACT',
                        id: $this.commonService.getObjectId(voucher.Object),
                        Name: voucher.ObjectName
                      });
                    }
                    if (voucher.Contact) {
                      contactMembers.push({
                        Type: 'CONTACT',
                        id: $this.commonService.getObjectId(voucher.Contact),
                        Name: voucher.ContactName
                      });
                    }

                    if (contactMembers.length > 0) {
                      await $this.rootServices.apiService.postPromise<ChatRoomModel[]>('/chat/room-members', { chatRoom: rs[0].Code }, contactMembers);
                    }

                    console.log(rs);
                    if (rs && rs[0] && rs[0].Code) {
                      $this.commonService.navigate('/chat-room/' + rs[0].Code, {
                        context: {
                          // backTitle: 'Workplace',
                          title: rs[0].Description,
                        }
                      });
                      self.$app.preloader.hide();
                    }
                  }).catch(err => {
                    console.error(err);
                    self.$app.preloader.hide();
                    $this.commonService.showError(err);
                  });
                });
              }
            }
          },
          previewPictures(e) {
            const self: F7ComponentContextExtend = this;
            const currenntState = $this.currentState;
            let index = $(e.target).closest('.list-item').data('index');
            $this.previewPictures(currenntState.data.Details[index].Image, 0);
          },
          setFeaturePicture(e) {
            const self: F7ComponentContextExtend = this;
            const currenntState = $this.currentState;
            let index = $(e.target).closest('a').data('index');
            if (typeof index != 'undefined') {
              self.$app.dialog.confirm('Bạn có muốn đặt làm hình đại diện không ?', 'Đặt hình đại diện', () => {
                currenntState.data.FeaturePicture = currenntState.data.Pictures[index];
                self.$setState({ data: currenntState.data });
              })
            }
          },
          uploadPicture(e) {
            const self: F7ComponentContextExtend = this;
            const currenntState = $this.currentState;
            $this.updateImages((progress) => {
              console.log(progress);
            }, (image) => {
              currenntState.data.Pictures.unshift(image);
              currenntState.data.FeaturePicture = image;
              self.$setState({ data: currenntState.data });

            }).then(images => {
              console.log('upload pictures success', images);
            });
          },
          addDetail(e) {
            const self: F7ComponentContextExtend = this;
            const currenntState = $this.currentState;

            $this.chooseProduct(self);

            // let listName = $(e.target).data('list-name');
            // $this.addItemForList(listName);

          },
          chooseProduct(e) {
            const self: F7ComponentContextExtend = this;
            $this.chooseProduct(self);
          },
          embedBarcodeScan(e) {
            const self: F7ComponentContextExtend = this;
            if (!isPlatform('ios') && !isPlatform('android')) {
              self.$app.dialog.prompt('Barcode', 'Điền barcode vào ô bên dưới', barcode => {
                // if (/^https/.test(barcode)) {
                //   barcode = barcode.replace(/^https:\/\/[^\/]+\/\d+\/truyxuat\/(\d+)/, '$1');
                // }
                barcode = $this.rootServices.getAccessNumberFromUrl(barcode);
                $this.barcodeProcess(barcode);
              })
            } else {
              if ($this.rootServices.isEmbedBarcodeScan) {
                $this.removeBarcodeScanner();
              } else {
                $this.embedBarcodeSanner();
              }
            }
          },
          async scanBarcode(e) {
            const self: F7ComponentContextExtend = this;
            const defaultOptions = {
              barcodeFormats: {
                Code128: true,
                Code39: true,
                Code93: true,
                CodaBar: true,
                DataMatrix: true,
                EAN13: true,
                EAN8: true,
                ITF: true,
                QRCode: true,
                UPCA: true,
                UPCE: true,
                PDF417: true,
                Aztec: true,
              },
              beepOnSuccess: false,
              vibrateOnSuccess: true,
              detectorSize: 0.7,
              rotateCamera: false,
            };
            console.log('Start scan barcode');
            const infiniteScan = $(e.target).data('infinite-scan');
            // self.prevBarcode = '';
            window['cordova']['plugins']['mlkit'].barcodeScanner.scan(
              defaultOptions,
              async (barcodeData) => {
                console.log('[' + Date.now() + '] barcode result: ', barcodeData);
                let barcode = barcodeData && barcodeData.text || '';
                if (barcode) {
                  // if (/^https/.test(barcode)) {
                  //   barcode = barcode.replace(/^https:\/\/[^\/]+\/\d+\/truyxuat\/(\d+)/, '$1');
                  // }
                  barcode = $this.rootServices.getAccessNumberFromUrl(barcode);


                  if (barcode) {
                    // console.log(`${self.prevBarcode} == ${barcode}`);
                    // if (self.prevBarcode && self.prevBarcode == barcode) {
                    //   // self.prevBarcode = barcode;
                    //   self.scanBarcode(e);
                    // } else {
                    console.log('[' + Date.now() + '] barcode process: ', barcode);
                    const processReuslt = await $this.barcodeProcess(barcode).catch(err => console.error(err)).catch(err => { Promise.resolve(false) });
                    // if (processReuslt) {
                    // }
                    // self.prevBarcode = barcode;
                    if (isPlatform('android')) {
                      if (infiniteScan) {
                        self.scanBarcode(e);
                      }
                    }
                    // }
                  }
                } else {
                  console.log('Stop infinite scan');
                }
              },
              (error) => {
                // Error handling
                $this.commonService.showError(error, { position: 'bottom' });
              },
            );
          },
          scanProductQrCodeBk(e) {
            const self: F7ComponentContextExtend = this;
            // const currenntState = $this.currentState;
            // const systemConfigs = $this.rootServices.systemConfigs$.value;

            $this.rootServices.barcodeScanner.scan({ showTorchButton: true }).then(barcodeData => {
              // const data = barcodeData && barcodeData.text.split('|');
              console.log(barcodeData);
              let barcode = barcodeData && barcodeData.text;
              // if (/^https/.test(barcode)) {
              //   barcode = barcode.replace(/^https:\/\/[^\/]+\/\d+\/truyxuat\/(\d+)/, '$1');
              // }
              barcode = $this.rootServices.getAccessNumberFromUrl(barcode);
              $this.barcodeProcess(barcode);

            });
          },
          removeDetailItem(e) {
            return $this.removeDetailItem(this, e);
          },
          onFieldChange(e) {
            $this.onFieldChange(this, e);
          },
          async refresh(e, done) {

            done && setTimeout(() => done(), 10000);
            $this.refresh(this).then(data => {
              done && done();
            });

            return true;
          },
          save() {
            const self: F7ComponentContextExtend = this;
            const currenntState = $this.currentState;
            // const relativeVouchers = self.$route.context.relativeVouchers;
            const id = currenntState.data.Code || self.$route.params['id'];

            $this.commonService.showPreloader();

            const preloaderProcess = setTimeout(() => {
              $this.commonService.hidePreloader();
            }, 15000);

            // if (id === 'new') {
            //   if (relativeVouchers) {
            //     currenntState.data.RelativeVouchers = relativeVouchers;
            //   }
            // }

            $this.save(self, { postParams: { renderBarCode: true }, updateProperties: ['BarCode'] }).then(rs => {
              $this.commonService.showInfo("Đã lưu đơn hàng");
              clearTimeout(preloaderProcess);
              $this.commonService.hidePreloader();
            }).catch(err => {
              clearTimeout(preloaderProcess);
              $this.commonService.hidePreloader();
              console.error(err);
            });
          },
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          pageInit(e, page: F7Page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;

            $this.onComponentInit({ instance: self, page: page }, self.$route.params['id'], null, page).then(curentState => {
              // self.detailDialog = self.$app.dialog.create({
              //   el: page.$el.find('.dialog-detail'),
              //   closeByBackdropClick: true,
              // });
            });

            $this.rootServices.authService.isAuthenticatedOrRefresh().pipe(take(1)).toPromise().then(() => {
              self.$setState({
                backTitle: self.$route.context && self.$route.context.backTitle || 'Back', title: self.$route.context && self.$route.context.title || 'Chat GUI',
              });
            }).catch(err => {
              console.error(err);
              $this.commonService.showError(err);
            });
          },
          // pageBeforeIn(e, page) {
          //     console.log('page before in');
          //     const self: F7ComponentContextExtend = this;
          //     self.refresh();
          // },
          pageAfterIn(e, page) {
            console.log('page after in');
            const self: F7ComponentContextExtend = this;
            self.keydownSubscription && (self.keydownSubscription as Subscription).unsubscribe();
            self.keydownSubscription = $this.commonService.onKeydownEvent$.subscribe(event => {
              // console.log(event.key);
              if ((document.activeElement as HTMLElement).tagName == 'BODY') {
                $this.commonService.barcodeScanDetective(event.key, barcode => {
                  console.log('barcode: ' + barcode);
                  $this.barcodeProcess(barcode);
                });
              }
            });
            $this.onChangedState({ instance: self, page }, self.$route?.params?.id, 'before-in', { doNotRefresh: true }).then(currentState => {
              // self.$setState({
              //     lastUpdateMoment: moment(self.lastUpdate).fromNow(),
              // });
            });
            // setTimeout(() => {
            //   $this.embedBarcodeSanner();
            // }, 500);
          },
          pageBeforeOut(e, page) {
            // console.log('page before out');
            // // Call caback function
            const self: F7ComponentContextExtend = this;
            // if (this.$route?.context && this.$route?.context['callback']) {
            //   this.$route?.context['callback']($this.currentState.data, { lastAction: $this.currentState.lastAction });
            // }
            // (self.keydownSubscription as Subscription).unsubscribe();
            // const index = $this.orderList.findIndex(f => f.id == self.$route?.params?.id);
            // $this.orderList.splice(index, 1);
            // console.log($this.orderList);
            console.log('Before out');
            self.keydownSubscription && (self.keydownSubscription as Subscription).unsubscribe();
            $this.removeBarcodeScanner();
          },
          // pageAfterOut(e, page) {
          //     console.log('page after out');
          // },
          // pageBeforeUnmount(e, page) {
          //     console.log('page before unmount');
          // },
          // pageBeforeRemove(e, page) {
          //     console.log('page before remove');
          // },
          pageBeforeRemove(e, page) {
            console.log('[page event] before remove', page.route.url);
            const self: F7ComponentContextExtend = this;
            // const starmtSelectRoles = self.$app.smartSelect.get('.smart-select');
            // starmtSelectRoles.destroy();
            const currentState = $this.state[self.$route.params.id];
            if (/^new\-?/.test(self.$route.params.id)) {
              if (currentState && currentState.data && currentState.data.Code) {
                delete currentState.data;
              }
            }

            console.log('page before out');
            // Call caback function
            if (this.$route?.context && this.$route?.context['callback']) {
              this.$route?.context['callback']($this.currentState.data, { lastAction: $this.currentState.lastAction });
            }
            (self.keydownSubscription as Subscription).unsubscribe();
            // const index = $this.orderList.findIndex(f => f.id == self.$route?.params?.id);
            // $this.orderList.splice(index, 1);
            // console.log($this.orderList);
            $this.removeBarcodeScanner();
          },
        },
      },
    };
  }
}
