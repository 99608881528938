import { Messages } from 'framework7/build/core/components/messages/messages';
import { F7Messagebar, F7ComponentContext } from '../types/framework7-types';
import { Router } from 'framework7/build/core/modules/router/router';
import { Storage } from '@ionic/storage';
import { ComponentState, BaseComponent } from 'vendors/smart-bot-app/src/app/lib/base-component';
import { RecentLoginModel } from 'vendors/smart-bot-app/src/app/model/recent-login.model';
import { CommonService } from 'vendors/smart-bot-app/src/app/services/common.service';
import { RootServices } from 'vendors/smart-bot-app/src/app/services/root.services';
import { isPlatform } from '@ionic/angular';

export interface ComponentStateExtend extends ComponentState {
    [key: string]: any;
}

export class F7ComponentContextExtend extends F7ComponentContext {
    messagebar?: F7Messagebar;
    messages?: Messages.Messages;
    images?: string[];
    responseInProgress?: boolean;
    answers?: string[];
    people?: { name?: string, avatar?: string }[];
    hideToolbar?: () => void;
    sheetToggle?: () => void;
    deleteAttachment?: (e: MouseEvent, index: number) => void;
    handleAttachment?: (e: MouseEvent) => void;
    checkAttachments?: () => void;
    sendMessage?: () => void;
    $root: any;
    // [key: string]: any;
}

/** Component group manager */
export class RequestDeleteAccountComponent extends BaseComponent<ComponentStateExtend>  {
    // states: { [key: string]: State } = {};

    constructor(
        public rootServices: RootServices,
        public commonService: CommonService,
    ) {
        super(rootServices);
        // console.log('Click here to open ts file');
    }

    onF7pageRemove(chatRoomId: string) {
        // if (this.chatRoomCacheList[chatRoomId]) {
        //   this.chatRoomCacheList[chatRoomId].disconnect();
        // }
    }

    get f7Component(): Router.RouteParameters {
        const $this = this;
        return {
            name: 'request-delete-account',
            path: '/request-delete-account',
            component: {
                template: /*html*/`
                <div class="page login no-toolbar no-navbar no-swipeback login-screen-pagex">
                    <div class="page-content login-screen-contentx">
                        <div class="block inset" style="padding: 0.5rem">
                            <div class="profile-info" style="display: flex">
                                <div class="profile-logo-x bg-color-gray" style="flex: 1; border-radius: 1rem; padding: 0; overflow: hidden">
                                    <img src="assets/images/baner-probox.vn.jpg" style="margin-bottom: -6px;">
                                </div>
                                <!--<div class="avatar" style="">
                                    <div class="profile-logo bg-color-gray" style="border-radius: 20%; background-image: url(assets/icon/icon-1024.png)"></div>
                                </div>
                                <div class="info">
                                    <div style="font-size: 2rem; font-weight: bold; line-height: 2rem;">ProBox.vn</div>
                                    <div class="text-color-gray" style="text-align: left">{{appTitle}}<br>Version {{appVersion}} core {{coreVersion}}</div>
                                </div>
                                -->
                            </div>
                        </div>
                
                        <div class="block reason text-color-red" style="font-size: 3rem">Gửi yêu cầu xóa tài khoản</div>
                        <form autocomplete="off">
                            <div class="list inset">
                                <ul>
                                    <li class="item-content item-input item-input-with-value">
                                        <div class="item-inner">
                                            <div class="item-title item-label">Số điện thoại <span class="text-color-red">(*)</span>
                                            </div>
                                            <div class="item-input-wrap">
                                                <input @keypress="keyenter" autocomplete="new-password" autocorrect="off"
                                                    autocapitalize="off" type="tel" placeholder="Số điện thoại của bạn" name="username"
                                                    value="{{js "this.recentLogin && this.recentLogin.username || ''"}}"
                                                    class="input-with-value"><span class="input-clear-button"></span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="block">
                                <div class="row">
                                    <a href="#" class="col color-green button button-large button-fill" @click="login">Gửi yêu cầu<i class="icon f7-icons if-not-md">chevron_compact_right</i></a>
                                </div>
                            </div>
                        </form>
                        <br>
                        <div style="display: ${isPlatform('ios') || isPlatform('android') ? 'none' : 'flex'}; margin-left: 1rem; margin-right: 1rem;">
                            <div style="flex: 1; text-align: center; padding: 1rem;">
                                <a @click="openExternalLink" data-href="https://apps.apple.com/vn/app/probox/id1565916654">
                                    <img src="assets/images/appstore-logo.png" style="width: 130px;">
                                </a>
                            </div>
                            <div style="flex: 1; text-align: center; padding: 1rem;">
                                <a @click="openExternalLink" data-href="https://play.google.com/store/apps/details?id=com.namsoftware.probox&pcampaignid=web_share">
                                    <img src="assets/images/playstore-logo.png" style="width: 130px;">
                                </a>
                            </div>
                        </div>
                        <br>

                        <div style="text-align: center">{{appTitle}}</div>
                        <div style="text-align: center">{{appCopyright}}</div>
                        <br>
                        <br>
                        {{#js_if "this.platform == 'browser'"}}
                        <div style="text-align: center">
                            <a data-href="https://probox.one" @click="openExternalLink">Bạn đang tìm giải pháp quản trị CTV ?</a>
                        </div>
                        {{/js_if}}
                    </div>
                </div>
                `,
                style: /*css*/`
                `,
                data() {
                    const self: F7ComponentContextExtend = this;
                    return {
                        notifications: self.$root.notifications,
                        // reason: '',
                        // navigateOnSuccess: '',
                        appName: $this.rootServices.env.name,
                        appTitle: $this.rootServices.env.title,
                        appCopyright: $this.rootServices.env.copyright,
                        coreVersion: $this.rootServices.env.coreVersion,
                        platform: $this.rootServices.device?.platform || 'browser',
                    };
                },
                methods: {
                    openExternalLink(e) {
                        const self: F7ComponentContextExtend = this;
                        const link = $(e.currentTarget).data('href');
                        $this.rootServices.openExtenalLink(link);
                    },
                    entrerNewPassword(phone: string, otpToken: string) {
                        let url: string = $this.rootServices.env.api.defaultUrl;
                        const self: F7ComponentContextExtend = this;
                        let passDialog = self.$app.dialog.password('Bạn hãy điền mật khẩu mới (hết hạn trong 120s)', 'Đặt lại mật khẩu', (newPassword) => {
                            passDialog.close();
                            passDialog = self.$app.dialog.password('Xác nhận mật khẩu mới (hết hạn trong 120s)', 'Đặt lại mật khẩu', (newPassword2) => {
                                passDialog.close();
                                if (newPassword && newPassword === newPassword2) {
                                    self.$app.preloader.show();
                                    $this.rootServices.apiService.postPromise(url + '/user/login/changePasswordByOtp', { otpToken: otpToken }, { newPassword: newPassword }).then(rs => {
                                        self.$app.preloader.hide();
                                        $this.commonService.showInfo('Đặt lại mật khẩu thành công', { position: 'bottom' });
                                        // Auto login
                                        self.login(null, phone, newPassword);
                                    }).catch(err => {
                                        self.$app.preloader.hide();
                                        $this.commonService.showError(err, { position: 'bottom' });
                                    });
                                } else {
                                    $this.commonService.showError('Bạn chưa điền mã OPT !', { position: 'bottom' });
                                    self.entrerNewPassword(phone, otpToken);
                                }

                            });
                            passDialog.open();
                        });
                        passDialog.open();
                    },
                    tryVerifiedOtp(phone: string, message?: string, tryTime?: number) {

                        let url: string = $this.rootServices.env.api.defaultUrl;
                        const self: F7ComponentContextExtend = this;
                        let dialog = self.$app.dialog.prompt(message || 'Hệ thống đã gửi mã OTP, hãy kiểm tra tin nhắn và cung cấp mã OTP để xác thực', 'Xác thực OTP', (otp) => {
                            dialog.close();
                            if (phone) {
                                self.$app.preloader.show();
                                $this.rootServices.apiService.postPromise(url + '/user/login/verifyOtp', { phone: phone, otp: otp }, []).then(rs => {
                                    self.$app.preloader.hide();
                                    self.entrerNewPassword(phone, rs['otpToken']);
                                }).catch(err => {
                                    self.$app.preloader.hide();
                                    $this.commonService.showError(err, { position: 'bottom' });
                                    tryTime = (tryTime || 0) + 1;
                                    if (tryTime < 3) {
                                        self.tryVerifiedOtp(phone, 'Mã OTP không hợp lệ, hãy thử lại (' + tryTime + '/3)', tryTime);
                                    }
                                });
                            } else {
                                $this.commonService.showError('Bạn chưa điền mã OPT !', { position: 'bottom' });
                                self.tryVerifiedOtp(phone);
                            }
                        });
                        dialog.open();
                    },
                    fogetLogin() {
                        const self: F7ComponentContextExtend = this;
                        let url: string = $this.rootServices.env.api.defaultUrl;
                        return $this.rootServices.openDynamicFormDialog<{ Phone: string }>('Đặt lại mật khẩu', /*html*/``, [
                            {
                                name: 'Phone',
                                type: 'decimal',
                                label: 'Số điện thoại',
                                placeholder: 'SĐT nhận mã OTP...',
                                required: true,
                                focus: true
                            },
                        ], null, { submitButtonLabel: 'Gửi mã OTP' }).then(data => {
                            const phone = data.Phone;
                            return $this.rootServices.apiService.postPromise(url + '/user/login/resetPassword', { phone: phone }, []).then(rs => {
                                self.$app.preloader.hide();
                                // self.tryVerifiedOtp(phone);
                                let tryTime = 1
                                return $this.rootServices.enterPasscodePopup(
                                    'Điền mã xác thực OTP',
                                    /*html*/`
                                        <div class="block-title">Bạn hãy vào Zalo để kiểm tra tin nhắn OTP</div>
                                        <div class="block block-strong inset" style="padding: 0; overflow: hidden; line-height: 0">
                                            <img src="assets/images/zalo-otp.png" style="width: 100%">
                                        </div>
                                    `, 4, {
                                    swipeToClose: false,
                                    onFullFill: (otp) => {
                                        return $this.rootServices.apiService.postPromise(url + '/user/login/verifyOtp', { phone: phone, otp: otp }, []).then(async rs => {
                                            self.$app.preloader.hide();
                                            $this.commonService.showInfo('Xác thực thành công !');
                                            // self.entrerNewPassword(phone, rs['otpToken']);

                                            let breaking = null;
                                            for (let tryTime = 1; tryTime <= 3; tryTime++) {
                                                breaking = await new Promise(resolve => {
                                                    $this.rootServices.openDynamicFormDialog<{ Password: string, RePassword: string }>('Đặt lại mật khẩu mới', /*html*/``, [
                                                        {
                                                            name: 'Password',
                                                            type: 'password',
                                                            label: 'Mật khẩu mới',
                                                            placeholder: 'Mật khẩu mới...',
                                                            required: true,
                                                            focus: true
                                                        },
                                                        {
                                                            name: 'RePassword',
                                                            type: 'password',
                                                            label: 'Xác nhận mật khẩu mới',
                                                            placeholder: 'Nhập lại mật khẩu mới...',
                                                            required: true
                                                        },
                                                    ], null, { submitButtonLabel: 'Đổi mật khẩu' }).then(data => {
                                                        if (data && data.Password && data.Password === data.RePassword) {
                                                            self.$app.preloader.show();
                                                            $this.rootServices.apiService.postPromise(url + '/user/login/changePasswordByOtp', { otpToken: rs['otpToken'] }, { newPassword: data.Password }).then(rs => {
                                                                self.$app.preloader.hide();
                                                                $this.commonService.showInfo('Đặt lại mật khẩu thành công', { position: 'bottom' });
                                                                // Auto login
                                                                resolve(true);
                                                                self.login(null, phone, data.Password);
                                                            }).catch(err => {
                                                                resolve(null);
                                                                self.$app.preloader.hide();
                                                                $this.commonService.showError(err, { position: 'bottom' });
                                                            });
                                                        } else {
                                                            $this.commonService.showError('Mật khẩu không hợp lệ ! (thử lại ' + tryTime + '/3)');
                                                            resolve(null);
                                                        }
                                                    }).catch(err => {
                                                        resolve(true);
                                                        err && $this.commonService.showError(err);
                                                    });
                                                });
                                                if (breaking) {
                                                    break;
                                                }
                                            }
                                            // Change password


                                            return true;
                                        }).catch(err => {
                                            self.$app.preloader.hide();
                                            $this.commonService.showError(err, { position: 'bottom' });
                                            tryTime++;
                                            if (tryTime <= 3) {
                                                // self.tryVerifiedOtp(phone, 'Mã OTP không hợp lệ, hãy thử lại (' + tryTime + '/3)', tryTime);
                                                return Promise.reject({ error: 422, message: 'Mã OTP không hợp lệ, hãy thử lại (' + tryTime + '/3)' });
                                            }
                                            tryTime = 1;
                                            return Promise.reject({ error: 400, message: 'Mã OTP đã hết hiệu lực !' });
                                        });
                                    }
                                });
                            }).catch(err => {
                                self.$app.preloader.hide();
                                if (err) $this.commonService.showError(err, { position: 'bottom' });
                            });
                        }).catch(err => err && $this.commonService.showError(err));
                    },
                    openRegisterForm(e: KeyboardEvent) {
                        const self: F7ComponentContextExtend = this;
                        // self.$router.navigate('/register');
                        $this.commonService.navigate('/register', {
                            context: {
                                reason: self.reason,
                                navigateOnSuccess: self.navigateOnSuccess
                            }
                        });
                    },
                    async refresh() {
                        const self: F7ComponentContextExtend = this;
                        return true;
                    },
                    keyenter(e: KeyboardEvent) {
                        const self: F7ComponentContextExtend = this;
                        // console.log(e);
                        if (e.key === 'Enter') {
                            self.login();
                        }
                    },
                    login(e: MouseEvent, username?: string, password?: string) {
                        
                        $this.commonService.showInfo('Cảm ơn bạn đã gửi yêu cầu, chúng tôi sẽ liên hệ lại để xác nhận yêu cầu xóa tài khoản này !');

                    }
                },
                on: {
                    pageInit(e, page) {
                        console.log('page init');
                        const self: F7ComponentContext = this;
                        $this.onComponentInit({ instance: self }, 'main').then(currentState => {
                            if (self.$route && self.$route.context && self.$route?.context['reason']) {
                                self.$setState({ reason: self.$route.context['reason'] });
                            }
                            if (self.$route && self.$route.context && self.$route.context['navigateOnSuccess']) {
                                self.$setState({ navigateOnSuccess: self.$route.context['navigateOnSuccess'] });
                            }
                        });
                    },
                }
            },
        };
    }
}
