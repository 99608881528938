import { ProductModel } from 'vendors/smart-bot-app/src/app/model/product.model';
import { F7ComponentContext } from '../../types/framework7-types';
import { Router } from 'framework7/build/core/modules/router/router';
import { TextEditor } from 'framework7/build/core/components/text-editor/text-editor';
import { take, filter } from 'rxjs/operators';
import { BaseComponent, ComponentState } from 'vendors/smart-bot-app/src/app/lib/base-component';
import { ChatRoom } from 'vendors/smart-bot-app/src/app/lib/nam-chat/chat-room';
import { Member } from 'vendors/smart-bot-app/src/app/lib/nam-chat/model/member';
import { CommonService } from 'vendors/smart-bot-app/src/app/services/common.service';
import { RootServices } from 'vendors/smart-bot-app/src/app/services/root.services';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { AccountModel } from 'vendors/smart-bot-app/src/app/model/account.model';
import { FileModel } from 'vendors/smart-bot-app/src/app/model/file.model';
import * as $ from 'jquery';

// declare const $: any;
export interface ComponentStateExtend extends ComponentState {
  [key: string]: any;
}

export class F7ComponentContextExtend extends F7ComponentContext {
  // messagebar?: F7Messagebar;
  // messages?: Messages.Messages;
  // images?: string[];
  responseInProgress?: boolean;
  // answers?: string[];
  // people?: { name?: string, avatar?: string }[];
  // hideToolbar?: () => void;
  // sheetToggle?: () => void;
  // deleteAttachment?: (e: MouseEvent, index: number) => void;
  // handleAttachment?: (e: MouseEvent) => void;
  // checkAttachments?: () => void;
  // sendMessage?: () => void;
  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, memberInfo?: Member, chatRoom?: ChatRoom, type?: string } };
}

/** Component group manager */
export class ProductBlockComponent extends BaseComponent<ComponentStateExtend> {
  // states: { [key: string]: State } = {};

  title: 'Block sản phẩm'

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
    public datePipe: DatePipe,
    public currencyPipe: CurrencyPipe,
  ) {
    super(rootServices);
    // console.log('Click here to open ts file');
  }

  get isPublicComponent() {
    return true;
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: 'product-block',
      path: '/product-block/:id',
      component: {
        template: /*html*/`
          <div class="page product-block" data-name="product-block">
            <div class="navbar product-block">
              <div class="navbar-bg"></div>
              <div class="navbar-inner sliding">
                <div class="left">
                  <a class="link back {{textColorClass}}">
                    <i class="icon icon-back"></i>
                    <span class="if-not-md {{textColorClass}}">{{backTitle}}</span>
                  </a>
                </div>
                <div class="title">{{title}}</div>
                <div class="right">
                  <a class="link icon-only"
                    @click="publish">
                    Lưu
                  </a>
                </div>
              </div>
            </div>
            <div class="page-content">
          
              <div class="block-title">Sản phẩm <a class="sortable-toggle" data-sortable=".sortable" style="float: right">Sắp xếp</a></div>
              <div class="list media-list sortable">
                <ul>
                  {{#js_if "this.productList"}}
                  {{#each productList}}
                  <li class="block-product swipeout" data-id="{{Code}}" @deleted="removeProduct">
                    <a href="#" class="item-link item-content swipeout-content">
                      <div class="item-media"><img src="{{js "this.FeaturePicture && this.FeaturePicture.SmallImage"}}"
                          width="80" /></div>
                      <div class="item-inner">
                        <div class="item-title-row">
                          <div class="item-title">{{PageName}}</div>
                          <div class="item-after">{{UnitLabel}}</div>
                        </div>
                        <div class="item-subtitle">Giá: {{PriceText}}</div>
                        <div class="item-text">{{Name}}</div>
                      </div>
                    </a>
                    <div class="swipeout-actions-right">
                      <a @click="removeProduct" data-id="{{Code}}" href="#" class="swipeout-delete swipeout-overswipe"  data-confirm="Bạn có chắc là muốn gở sản phẩm “{{Name}}” ra khỏi bock ?" data-confirm-title="Gở sản phẩm khỏi block?">Delete</a>
                    </div>
                    <div class="sortable-handler"></div>
                  </li>
                  {{/each}}
                  {{/js_if}}
                </ul>
              </div>
              <div class="block">
                <button class="button button-large button-fill" @click="addProduct">Thêm sản phẩm</button>
              </div>
            </div>
          </div>
        `,
        style: /*css*/`
        .page.product-block .list .item-media img {
          border-radius: 10%;
        }
        `,
        data() {
          return {
            title: $this.title,
            editable: false,
            backTitle: 'Back',
            // productList: [],
          };
        },
        methods: {
          addProduct(e) {
            const self: F7ComponentContextExtend = this;
            $this.rootServices.navigate('/pages', {
              context: {
                chooseProductMode: true, backTitle: 'Back', onProductChoose: (product: ProductModel) => {
                  console.log(product);
                  $this.commonService.showInfo(`${product?.Name} đã được thêm vào profile`);
                  // $this.rootServices.navigate(self.$route.url);
                  if (!Array.isArray(self.productList)) self.productList = [];
                  self.productList.push({
                    ...product,
                    FeaturePicture: product.FeaturePicture && product.FeaturePicture.OriginImage ? product.FeaturePicture : {
                      ThumbnalImage: 'assets/images/no-image-available.png',
                      SmallImage: 'assets/images/no-image-available.png',
                      OriginImage: 'assets/images/no-image-available.png',
                    }
                  });
                  self.$setState({ productList: self.productList });
                }
              }, force: true
            });
            // self.$router.view.router.navigate('/pages', { context: {chooseProductMode: true} });
            // $this.rootServices.navigate('/page/PAG058212/collaborator/products', {context: {chooseProductMode: true}});
          },
          removeProduct(e) {
            const self: F7ComponentContextExtend = this;
            console.log('remove product');
            const productCode = $(e.target).data('id');
            console.log('removed product: ' + productCode);
            self.productList = self.productList.filter(f => f.Code !== productCode);
          },
          async refresh() {
            const self: F7ComponentContextExtend = this;
            return true;
          },
          publish(e) {
            const self: F7ComponentContextExtend = this;
            if (self.onSave) {
              self.onSave(self.productList);
            }
            self.$router.back();
          }
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          pageInit(e, page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;
            const id = self.$route.params?.id;

            $this.onComponentInit({ instance: self }, id).then(currentState => {

              if (id) {
                // $this.rootServices.apiser
              }

              // console.log(memberInfo);
              // $this.rootServices.authService.isAuthenticatedOrRefresh().pipe(take(1)).toPromise().then(() => {
              //   $this.rootServices.apiService.getPromise<AccountModel>('/user/profiles/' + self.$route.params?.id, { skipAuth: true }).then(rs => rs[0]).then(userInfo => {
              //     console.log(userInfo);
              //     let coreDomain = $this.rootServices.apiService.token && $this.rootServices.apiService.token.api_url;
              //     if (coreDomain) {
              //       const url = new URL(coreDomain);
              //       coreDomain = url.host;
              //     }
              //     self.$setState({
              //       profile: { ...userInfo, Core: coreDomain },
              //       title: userInfo.Name,
              //       // backTitle: userInfo.Name,
              //     });
              //     currentState.loaded$ && currentState.loaded$.next(true);
              //   });
              // }).catch(err => {
              //   console.error(err);
              //   $this.commonService.showError(err);
              // });


            });

            self.$app.on('sortableSort', (listEl, indexes) => {
              console.log(listEl);
              if ($(listEl).is('.block-product')) {
                console.log(indexes);
                const tmpItem = self.productList.splice(indexes.from, 1);
                console.log('tmp item', tmpItem);
                console.log('current list', self.productList);
                self.productList.splice(indexes.to, 0, tmpItem[0]);
                console.log('current list', self.productList);
              }

            });

          },
          // pageBeforeIn(e, page) {
          //     console.log('page before in');
          // },
          // pageAfterIn(e, page) {
          //     console.log('page after in');
          // },
          // pageBeforeOut(e, page) {
          //     console.log('page before out');
          // },
          // pageAfterOut(e, page) {
          //     console.log('page after out');
          // },
          // pageBeforeUnmount(e, page) {
          //     console.log('page before unmount');
          // },
          // pageBeforeRemove(e, page) {
          //     console.log('page before remove');
          // },
          pageBeforeRemove(e, page) {
            console.log('[page event] before remove', page.route.url);
            const self: F7ComponentContextExtend = this;
            // const smartSelectRoles = self.$app.smartSelect.get('.smart-select-roles');
            // smartSelectRoles && smartSelectRoles.destroy();
            self.textEditorCustomButtons && self.textEditorCustomButtons.destroy()
          },
        },
      },
    };
  }
}
