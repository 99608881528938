import { FileModel } from '../../../src/app/model/file.model';
import { Model } from "./model";
import { ProductModel } from "./product.model";


export class PurchaseOrderModel extends Model {
    [key: string]: any;
    Code?: string;
    State?: string;
    Object?: string;
    ObjectName?: string;
    ObjectPhone?: string;
    ObjectEmail?: string;
    ObjectAddress?: string;
    ObjectIdentified?: string;
    Contact?: string;
    ContactName?: string;
    ContactPhone?: string;
    ContactEmail?: string;
    ContactAddress?: string;
    ContactIdentified?: string;
    DateOfOrder?: any;
    DateOfCreated?: string;
    Creator?: string;
    DeliveryAddress?: string;
    Title?: string;
    Note?: string;
    SubNote?: string;
    Attachments?: FileModel[] = [];
    Details?: PurchaseOrderDetailModel[];

    constructor(properties?: PurchaseOrderModel) {
        super(properties);
        this.DateOfOrder = new Date();
        this.Details = [];
    }
}

export class PurchaseOrderDetailModel extends Model {
    [key: string]: any;
    No?: number;
    Image?: FileModel[];
    Product?: ProductModel;
    Description?: string;
    Unit?: any;
    Quantity?: number = 0;
    Price?: number = 0;
    Tax?: any;
    ToMoney?: number = 0;

    constructor(properties?: PurchaseOrderDetailModel) {
        super(properties);
        this.Quantity = 1;
        this.Price = 0;
    }
}