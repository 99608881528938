import { RecentLoginModel } from './../model/recent-login.model';
// import { AppComponent } from './../app.component';
import { Printer } from '@awesome-cordova-plugins/printer/ngx';
import { ProductSearchIndexModel, ProductModel, UnitModel } from '../../../src/app/model/product.model';
import { filter, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { ApiService } from './api.service';
// import { SocketService } from './socket.service';
import { Storage } from '@ionic/storage';
import { HttpClient, HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { File, FileEntry } from '@ionic-native/file/ngx';
import { FileModel, FileStoreModel } from '../model/file.model';
import { ImageResizer } from '@ionic-native/image-resizer/ngx';
import { Base64 } from '@ionic-native/base64/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { Platform, ToastController, isPlatform } from '@ionic/angular';
import { Keyboard, KeyboardResizeMode } from '@ionic-native/keyboard/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { Chooser } from '@ionic-native/chooser/ngx';
import { NotificationService } from './notification.service';
import { Push } from '@ionic-native/push/ngx';
import { environment } from '../../../src/environments/environment';
import { Device } from '@ionic-native/device/ngx';
import { v4 as uuidv4 } from 'uuid';
import { DeviceModel } from '../model/device.model';
// import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { FirebaseMessaging } from '@awesome-cordova-plugins/firebase-messaging/ngx';
import { Dom7Instance } from 'dom7';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { F7View } from '../types/framework7-types';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ImagePicker } from '@ionic-native/image-picker/ngx';
import { PickerFile } from '../components/chat-room.component';
import { CameraOptions } from '@ionic-native/camera/ngx';
import { DatePipe, DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { MicroserviceModel } from '../model/microservice.model';
import { CodePush, DownloadProgress, SyncStatus } from '@ionic-native/code-push/ngx';
import { CommonService } from './common.service';
import { Base64ToGallery } from '@ionic-native/base64-to-gallery/ngx';
import { StreamingMedia, StreamingVideoOptions } from '@ionic-native/streaming-media/ngx';
import { MediaCapture } from '@ionic-native/media-capture/ngx';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { Coordinates, Geolocation } from '@ionic-native/geolocation/ngx';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { Market } from '@ionic-native/market/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Badge } from '@ionic-native/badge/ngx';
import { Dialogs } from '@ionic-native/dialogs/ngx';
import { F7Framework7 } from '../types/framework7-types';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { CurrencyPipe } from '@angular/common';
import * as textVersion from 'textversionjs';
import { SystemSettingsModel } from '../model/system.model';
// import { SystemSettingsModel } from '../model/model';
// import { settings } from 'cluster';
import localeVi from '@angular/common/locales/vi';
import localeEn from '@angular/common/locales/en';
import { SQLite, SQLiteObject } from '@awesome-cordova-plugins/sqlite/ngx';
import { EmbeddedBarcodeReader } from '../types/EmbeddedBarcodeReader';
import { DocumentViewer } from '@awesome-cordova-plugins/document-viewer/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { DocumentScanner } from '@awesome-cordova-plugins/document-scanner/ngx';
import { ZBar } from '@awesome-cordova-plugins/zbar/ngx';
import { NativeAudio } from '@ionic-native/native-audio/ngx';
import { AudioManagement } from '@ionic-native/audio-management/ngx';
import { ILocalPackage } from '@ionic-native/code-push/ngx';
import { PhotoBrowser } from 'framework7/build/core/components/photo-browser/photo-browser';
import { Template7 } from 'framework7/build/core/framework7.esm.bundle';
import { Toast } from 'framework7/build/core/components/toast/toast';
import { Dialog } from 'framework7/build/core/components/dialog/dialog';
import { Popup } from 'framework7/build/core/components/popup/popup';
import { CollaboratorService } from '../components/collaborator/service';
import { ContactModel } from '../model/contact.model';
import { Autocomplete } from 'framework7/build/core/components/autocomplete/autocomplete';
import { SalesService } from '../components/sales/service';
import { DeplpoymentService } from '../components/deployment/service';
// import * as $ from 'jquery';
import { Title } from "@angular/platform-browser";

declare const $: any;

declare const EmbeddedBarcodeReader: EmbeddedBarcodeReader & { [key: string]: any };
// declare const $: any;

export interface SocketConnectInfo {
    protocol?: string;
    domain?: string;
    port?: number;
}

declare const cordova: any;
@Injectable({
    providedIn: 'root',
})
export class RootServices {

    private takeUltilCount = {};
    private takeUltilPastCount = {};
    private $deviceUuid: string;
    public microservices$ = new BehaviorSubject<MicroserviceModel[]>(null);
    // isFrameMode = false;

    updateProgress$ = new BehaviorSubject<DownloadProgress>(null);
    updateStatus$ = new BehaviorSubject<SyncStatus>(null);
    version$ = new BehaviorSubject<{ appVersion: string, coreVersion: string }>({ appVersion: '1.0.0', coreVersion: '1.0.0' });
    allReady$ = new BehaviorSubject<boolean>(false);
    allowNavigate = true;

    settings$ = new BehaviorSubject<any>(null);
    systemSettings$ = new BehaviorSubject<SystemSettingsModel>(null);

    env = environment;

    bufferArea: Dom7Instance;

    public isMobile = this.device?.platform && (['ios', 'android'].indexOf(this.device.platform) > -1) || false;
    public isAndroid = this.device?.platform == 'android';
    public isIos = this.device?.platform == 'ios';

    // protected callStateSubject = new BehaviorSubject<CallState>({ state: 'normal' });
    // public callState$ = this.callStateSubject.asObservable();

    /** Changed chat room list */
    public changedChatRooms: { helpdesk: string[], workplace: string[] } = {
        helpdesk: [],
        workplace: [],
    };

    public pagesCache: any;

    public appComponent: any;

    homeBadge = 0;
    helpdeskBadge = 0;
    workplaceBadge = 0;
    settingsBadge = 0;
    // systemSettings$ = new BehaviorSubject<SystemSettingsModel>(null);

    themeColors = {
        textColor: '#000',
        default: {
            primary: '#428cff',
            succuess: '#2fdf75',
            warning: '#ffd534',
            danger: '#ff4961',
            info: '#50c8ff',
        },
        text: {
            primary: '#428cff',
            succuess: '#2fdf75',
            warning: '#ffd534',
            danger: '#ff4961',
            info: '#50c8ff',
        },
        tint: {
            primary: '#5598ff',
            succuess: '#62ceff',
            warning: '#ffd948',
            danger: '#ff5b71',
            info: '#62ceff',
        },
        shade: {
            primary: '#3a7be0',
            succuess: '#29c467',
            warning: '#e0bb2e',
            danger: '#e04055',
            info: '#46b0e0',
        },
        dim10: {
            primary: '#428cff2b',
            succuess: '#2fdf7526',
            warning: '#ffd5342b',
            danger: '#ff496126',
            info: '#50c8ff2e',
        }
    };

    sqliteDb: SQLiteObject;
    productSearchIndexs$ = new BehaviorSubject<ProductSearchIndexModel[]>(null);
    // productSkuMap: { [key: string]: ProductModel } = {};
    // productMap: { [key: string]: ProductModel } = {};
    // goodsInContainerMap: { [key: string]: WarehouseGoodsInContainerModel } = {};
    // productMapByUnit: { [key: string]: ProductModel & { Unit?: UnitModel, Containers?: WarehouseGoodsContainerModel[] } } = {};
    // unitSeqMap: { [key: string]: UnitModel } = {};
    // unitMap: { [key: string]: UnitModel } = {};
    // findOrderMap: { [key: number]: WarehouseGoodsInContainerModel } = {};
    // unitList: UnitModel[] = [];

    constructor(
        public authService: AuthService,
        public apiService: ApiService,
        // public socketService: SocketService,
        public storage: Storage,
        public http: HttpClient,
        public file: File,
        public imageResizer: ImageResizer,
        public base64: Base64,
        public webview: WebView,
        public sanitizer: DomSanitizer,
        public platform: Platform,
        public keyboard: Keyboard,
        public camera: Camera,
        public chooser: Chooser,
        public notification: NotificationService,
        public toastController: ToastController,
        public push: Push,
        public device: Device,
        // public firebaseX: FirebaseX,
        public firebaseMessaging: FirebaseMessaging,
        public backgroundMode: BackgroundMode,
        public statusBar: StatusBar,
        public imagePicker: ImagePicker,
        public datePipe: DatePipe,
        public translate: TranslateService,
        public codePush: CodePush,
        public commonService: CommonService,
        public base64ToGallery: Base64ToGallery,
        public streamingMedia: StreamingMedia,
        public mediaCapture: MediaCapture,
        public clipboard: Clipboard,
        public geolocation: Geolocation,
        public barcodeScanner: BarcodeScanner,
        public market: Market,
        public iab: InAppBrowser,
        public badge: Badge,
        public dialogs: Dialogs,
        public transfer: FileTransfer,
        public currencyPipe: CurrencyPipe,
        public decimalPipe: DecimalPipe,
        public meta: Meta,
        public sqlite: SQLite,
        public printer: Printer,
        public documentView: DocumentViewer,
        public fileOpener: FileOpener,
        public documentScanner: DocumentScanner,
        public zbar: ZBar,
        private nativeAudio: NativeAudio,
        private audio: AudioManagement,
        public collaboratorService: CollaboratorService,
        public salesService: SalesService,
        public deploymentService: DeplpoymentService,
        public titleService: Title
    ) {
        window['rootServices'] = this;
        // this.keyboard.setResizeMode(KeyboardResizeMode.None);
        window['rootServices'] = this;
        translate.addLangs(['en-US', 'vi-VN']);
        translate.setDefaultLang('en-US');
        translate.use('vi-VN');
        moment.locale('vi');

        moment.updateLocale('vi', {
            relativeTime: {
                past: '%s',
                m: '1 phút',
                h: '1 giờ',
                d: '1 ngày',
                w: '1 tuần',
                M: '1 tháng',
                y: '1 năm',
            }
        });

        // this.syncInfo();
        // setInterval(() => {
        //     this.syncInfo();
        // }, 30000);
        this.platform.resume.subscribe(() => {
            this.syncInfo();
        });

        this.authService.loginState$.pipe(filter(f => !!f)).subscribe(status => {
            this.syncInfo();
        });

        // init sqlite db
        // if (this.isMobile) {
        //     this.sqlite.create({
        //         name: 'smart_bot',
        //         location: 'default',
        //     }).then(db => {
        //         this.sqliteDb = db;
        //         this.sqliteDb.executeSql('create table product_search_index (\
        //         Code VARCHAR(30),\
        //         Sku VARCHAR(30),\
        //         Type VARCHAR(30),\
        //         Name VARCHAR(1024),\
        //         BaseUnit VARCHAR(30),\
        //         BaseUnitLabel VARCHAR(128),\
        //         Unit VARCHAR(30),\
        //         UnitSeq INTEGER(11),\
        //         UnitNo INTEGER(11),\
        //         ConversionRatio INTEGER(11),\
        //         IsDefaultSales INTEGER(1),\
        //         IsDefaultPurchase INTEGER(1),\
        //         IsManageByAccessNumber INTEGER(1),\
        //         IsAutoAdjustInventory INTEGER(1),\
        //         IsExpirationGoods INTEGER(1),\
        //         UnitLabel VARCHAR(128),\
        //         Price DOUBLE,\
        //         Container VARCHAR(30),\
        //         ContainerName VARCHAR(1024),\
        //         ContainerShelf VARCHAR(30),\
        //         ContainerShelfName VARCHAR(128),\
        //         ContainerFindOrder INTEGER,\
        //         ContainerAccAccount VARCHAR(30),\
        //         ContainerAccAccountName VARCHAR(128),\
        //         Warehouse VARCHAR(30),\
        //         WarehouseName VARCHAR(128),\
        //         SearchIndex INTEGER,\
        //         SearchText VARCHAR(1024),\
        //         Group VARCHAR(30),\
        //         GroupName VARCHAR(128),\
        //         Category VARCHAR(30),\
        //         CategoryName VARCHAR(128),\
        //         FeaturePicture TEXT,\
        //         Pictures TEXT,\
        //         Technical TEXT,\
        //         Description TEXT,\
        //         Inventory DOUBLE(30, 4),\
        //         LastUpdate VARCHAR(30),\
        //         IsNotBusiness INTEGER(1)\
        //         )', [])
        //             .then(() => console.log('Executed SQL'))
        //             .catch(e => console.log(e));
        //     });
        // }

        // Check core version compatible
        this.systemSettings$.subscribe(systemSettings => {
            if (systemSettings?.ROOT_CONFIGS?.coreVersion) {
                if (this.compareVersion(systemSettings?.ROOT_CONFIGS?.coreVersion, '4.1') === -1) {
                    this.f7app.dialog.alert('Ứng dụng chỉ hoạt động tốt với ProBox One v4.1 trở lên !', 'Hệ thống');
                }
            }
        });

        // Monitor and update services (microservices)
        this.authService.loginState$.pipe(filter(status => status === true)).subscribe(async status => {
            // if (status) {
            this.apiService.getPromise<{ services: MicroserviceModel[] }>('/system/microservices/getinfo', {}).then(rs => {
                this.microservices$.next(rs.services);
            });
            // } else {
            //     this.microservices$.next(null);
            // }
            // this.allReady$.next(true);
            this.syncInfo();
        });

        // this.authService.loginState$.pipe(filter(status => status === true), take(1)).toPromise().then(async status => {

        // const limit = 100;
        // let offset = 0;

        // try {
        //     this.commonService.showInfo('Đang tải thông tin hàng hóa...');
        //     const productSearchIndexs: ProductSearchIndexModel[] = [];
        //     // while (true) {
        //     const products = await this.apiService.getPromise<ProductSearchIndexModel[]>('/commerce-pos/product-search-indexs', { limit: limit, offset: offset, fromCache: true });
        //     // if (products.length == 0) break;
        //     for (const product of products) {
        //         product.id = product.Code;
        //         product.text = product.Name;
        //         const unitId = this.commonService.getObjectId(product.Unit);
        //         const baseUnitId = this.commonService.getObjectId(product.BaseUnit);
        //         const containerId = this.commonService.getObjectId(product.Container);
        //         if (typeof product.Unit == 'string') {
        //             product.Unit = { id: unitId, text: product.UnitLabel, Sequence: product.UnitSeq };
        //         }
        //         if (typeof product.BaseUnit == 'string') {
        //             product.BaseUnit = { id: baseUnitId, text: product.BaseUnitLabel };
        //         }
        //         if (typeof product.Container == 'string') {
        //             product.Container = { id: containerId, text: `${product.ContainerShelfName}/${product.ContainerFindOrder}`, FindOrder: product.ContainerFindOrder, Code: containerId, Name: product.ContainerName, Shelf: product.ContainerShelf, ShelfName: product.ContainerShelfName };
        //         }
        //         productSearchIndexs.push(product);
        //         if (!this.productMap[product.Code]) {
        //             this.productMap[product.Code] = {
        //                 ...product,
        //                 WarehouseUnit: { id: baseUnitId, text: product.BaseUnitLabel },
        //             };
        //         }
        //         if (!this.productMap[product.Code].UnitConversions) {
        //             this.productMap[product.Code].UnitConversions = [];
        //         }
        //         if (this.productMap[product.Code].UnitConversions.findIndex(f => this.commonService.getObjectId(f.Unit) == unitId) < 0) {
        //             this.productMap[product.Code].UnitConversions.push({
        //                 id: unitId,
        //                 text: product.UnitLabel,
        //                 Unit: product.Unit,
        //                 No: product.UnitNo,
        //                 ConversionRatio: product.ConversionRatio,
        //                 IsDefaultSales: product.IsDefaultSales,
        //                 IsDefaultPurchase: product.IsDefaultPurchase,
        //                 IIsManageByAccessNumbers: product.IsManageByAccessNumber,
        //                 IsAutoAdjustInventory: product.IsAutoAdjustInventory,
        //                 IsExpirationGoods: product.IsExpirationGoods,
        //             });
        //         }
        //         this.productSkuMap[product.Sku] = this.productMap[product.Code];
        //         if (!this.productMapByUnit[`${product.Code}-${unitId}`]) {
        //             this.productMapByUnit[`${product.Code}-${unitId}`] = product;
        //         }
        //         if (!this.productMapByUnit[`${product.Code}-${unitId}`].Containers) {
        //             this.productMapByUnit[`${product.Code}-${unitId}`].Containers = [];
        //         }
        //         if (this.productMapByUnit[`${product.Code}-${unitId}`].Containers.findIndex(f => this.commonService.getObjectId(f) == product.Container) < 0) {
        //             this.productMapByUnit[`${product.Code}-${unitId}`].Containers.push({
        //                 id: containerId,
        //                 text: `${product.ContainerShelfName}/${product.ContainerFindOrder}`,
        //                 Code: containerId,
        //                 Name: product.ContainerName,
        //                 Shelf: product.ContainerShelf,
        //                 ShelfName: product.ContainerShelfName,
        //                 FindOrder: product.ContainerFindOrder,
        //             });
        //         }

        //         if (product.UnitSeq && !this.unitSeqMap[product.UnitSeq]) {
        //             this.unitSeqMap[product.UnitSeq] = {
        //                 id: unitId,
        //                 text: product.UnitLabel,
        //                 Seq: product.UnitSeq,
        //                 Code: unitId,
        //                 Name: product.UnitLabel
        //             };
        //         }
        //         if (!this.unitMap[unitId]) {
        //             const unit = {
        //                 id: unitId,
        //                 text: product.UnitLabel,
        //                 Code: unitId,
        //                 Name: product.UnitLabel,
        //                 Sequence: product.UnitSeq,
        //             };
        //             this.unitMap[unitId] = unit;
        //             this.unitList.push(unit);
        //         }
        //         if (!this.goodsInContainerMap[`${product.Code}-${unitId}-${containerId}`]) {
        //             this.goodsInContainerMap[`${product.Code}-${unitId}-${containerId}`] = product;
        //             product['Goods'] = product.Code;
        //             product['GoodsName'] = product.Name;
        //             product['GoodsSku'] = product.Sku;
        //             this.goodsInContainerMap[product.ContainerFindOrder] = product;
        //         }

        //         // if (products.length < limit) break;
        //     }

        //     offset += limit;
        //     // }

        //     this.productSearchIndexs$.next(productSearchIndexs);
        //     this.putFileData('cache', 'product_search_indexs.json', JSON.stringify(productSearchIndexs));
        //     this.commonService.showInfo('Hoàn tất tải thông tin hàng hóa !');
        // } catch (err) {
        //     this.commonService.showError(err);
        //     console.error(err);
        // }
        // console.log('Load product search index complete');
        this.authService.loginState$.pipe(filter(f => !!f), take(1)).toPromise().then(async status => {
            if (!isPlatform('ios') && !isPlatform('android')) {
                try { await this.cacheUpdate({ force: true }); } catch (err) { this.commonService.showError(err); }
            }
            try { await this.cacheUpdate(); } catch (err) { this.commonService.showError(err); }
            setInterval(() => {
                this.cacheUpdate({ silent: true });
            }, 15000);
        })
        // if (isPlatform('android') || isPlatform('ios')) {
        // }
        // });

        // Remap product info
        // this.productSearchIndexs$.subscribe(productSearchIndexs => {
        //     if (productSearchIndexs) {
        //         for (const product of productSearchIndexs) {
        //             // product.id = product.Code;
        //             // product.text = product.Name;
        //             const unitId = this.commonService.getObjectId(product.Unit);
        //             const baseUnitId = this.commonService.getObjectId(product.BaseUnit);
        //             const containerId = this.commonService.getObjectId(product.Container);
        //             // if (typeof product.Unit == 'string') {
        //             //     product.Unit = { id: unitId, text: product.UnitLabel, Sequence: product.UnitSeq };
        //             // }
        //             // if (typeof product.BaseUnit == 'string') {
        //             //     product.BaseUnit = { id: baseUnitId, text: product.BaseUnitLabel };
        //             // }
        //             // if (typeof product.Container == 'string') {
        //             //     product.Container = { id: containerId, text: `${product.ContainerShelfName}/${product.ContainerFindOrder}`, FindOrder: product.ContainerFindOrder, Code: containerId, Name: product.ContainerName, Shelf: product.ContainerShelf, ShelfName: product.ContainerShelfName };
        //             // }


        //             if (!this.productMap[product.Code]) {
        //                 this.productMap[product.Code] = {
        //                     ...product,
        //                     WarehouseUnit: { id: baseUnitId, text: product.BaseUnitLabel },
        //                 };
        //             }

        //             if (!this.productMap[product.Code].UnitConversions) {
        //                 this.productMap[product.Code].UnitConversions = [];
        //             }
        //             if (this.productMap[product.Code].UnitConversions.findIndex(f => this.commonService.getObjectId(f.Unit) == unitId) < 0) {
        //                 this.productMap[product.Code].UnitConversions.push({
        //                     id: unitId,
        //                     text: product.UnitLabel,
        //                     Unit: product.Unit,
        //                     No: product.UnitNo,
        //                     ConversionRatio: product.ConversionRatio,
        //                     IsDefaultSales: product.IsDefaultSales,
        //                     IsDefaultPurchase: product.IsDefaultPurchase,
        //                     IIsManageByAccessNumbers: product.IsManageByAccessNumber,
        //                     IsAutoAdjustInventory: product.IsAutoAdjustInventory,
        //                     IsExpirationGoods: product.IsExpirationGoods,
        //                 });
        //             }
        //             this.productSkuMap[product.Sku] = this.productMap[product.Code];
        //             if (!this.productMapByUnit[`${product.Code}-${unitId}`]) {
        //                 this.productMapByUnit[`${product.Code}-${unitId}`] = product;
        //             }
        //             if (!this.productMapByUnit[`${product.Code}-${unitId}`].Containers) {
        //                 this.productMapByUnit[`${product.Code}-${unitId}`].Containers = [];
        //             }
        //             if (this.productMapByUnit[`${product.Code}-${unitId}`].Containers.findIndex(f => this.commonService.getObjectId(f) == product.Container) < 0) {
        //                 this.productMapByUnit[`${product.Code}-${unitId}`].Containers.push({
        //                     id: containerId,
        //                     text: `${product.ContainerShelfName}/${product.ContainerFindOrder}`,
        //                     Code: containerId,
        //                     Name: product.ContainerName,
        //                     Shelf: product.ContainerShelf,
        //                     ShelfName: product.ContainerShelfName,
        //                     FindOrder: product.ContainerFindOrder,
        //                 });
        //             }
        //             if (!this.findOrderMap[`${product.ContainerFindOrder}`]) {
        //                 this.findOrderMap[`${product.ContainerFindOrder}`] = product;
        //             }

        //             if (product.UnitSeq && !this.unitSeqMap[product.UnitSeq]) {
        //                 this.unitSeqMap[product.UnitSeq] = {
        //                     id: unitId,
        //                     text: product.UnitLabel,
        //                     Seq: product.UnitSeq,
        //                     Code: unitId,
        //                     Name: product.UnitLabel
        //                 };
        //             }
        //             if (!this.unitMap[unitId]) {
        //                 const unit = {
        //                     id: unitId,
        //                     text: product.UnitLabel,
        //                     Code: unitId,
        //                     Name: product.UnitLabel,
        //                     Sequence: product.UnitSeq,
        //                 };
        //                 this.unitMap[unitId] = unit;
        //                 this.unitList.push(unit);
        //             }
        //             if (!this.goodsInContainerMap[`${product.Code}-${unitId}-${containerId}`]) {
        //                 this.goodsInContainerMap[`${product.Code}-${unitId}-${containerId}`] = product;
        //                 product['Goods'] = product.Code;
        //                 product['GoodsName'] = product.Name;
        //                 product['GoodsSku'] = product.Sku;
        //                 this.goodsInContainerMap[product.ContainerFindOrder] = product;
        //             }
        //         }
        //     }
        // });

        this.commonService.frameSocket.event$.subscribe(event => {
            if (event && event.name === 'set-token' && !this.authService?.user$?.value) {
                this.authService.loadStoredToken().then(status => {
                    if (status) {
                        if (/login|recent\-logins/.test(this.f7app.view.current.router['url'])) {
                            this.commonService.navigate('/home');
                        }
                    }
                });
            }
        });

        // Register native sounds
        document.addEventListener("deviceready", () => {
            this.nativeAudio.preloadSimple('newPipSound', 'assets/sounds/beep-08b.wav');
            this.nativeAudio.preloadSimple('increaseSound', 'assets/sounds/beep-07a.wav');
            this.nativeAudio.preloadSimple('decreaseSound', 'assets/sounds/beep-07a.wav');
            this.nativeAudio.preloadSimple('errorSound', 'assets/sounds/beep-03.wav');
            this.nativeAudio.preloadSimple('paymentSound', 'assets/sounds/benboncan_till-with-bell.wav');

            // Fix resize app on keyboard show/hide
            this.keyboard.setResizeMode(KeyboardResizeMode.None);

            // Test sqlite
            const swcase: number = 0;
            switch (swcase) {
                case 1:
                    this.sqlite.create({
                        name: 'smart-bot.db',
                        location: 'default'
                    })
                        .then((db: SQLiteObject) => {
                            db.executeSql('create table test(name VARCHAR(32))', [])
                                .then(() => console.log('Executed SQL'))
                                .catch(e => console.log(e));
                            db.executeSql('insert into test values(?)', ['name ' + Date.now()])
                                .then(() => console.log('Executed SQL'))
                                .catch(e => console.log(e));
                            db.executeSql('select * from test', [])
                                .then((rs) => console.log('Select SQL: ', rs, rs.rows, rs.rows.item(rs.rows.length - 1)))
                                .catch(e => console.log(e));
                        });
                    break;
                case 2:
                    this.sqlite.create({
                        name: 'smart-bot.db',
                        location: 'default'
                    })
                        .then((db: SQLiteObject) => {
                            db.sqlBatch([
                                'drop table test',
                                'create table test(name VARCHAR(32))',
                                ['insert into test values(?)', ['name ' + Date.now()]],
                                ['insert into test values(?)', ['name ' + Date.now()]],
                                ['insert into test values(?)', ['name ' + Date.now()]],
                                ['insert into test values(?)', ['name ' + Date.now()]],
                                ['insert into test values(?)', ['name ' + Date.now()]],
                                ['insert into test values(?)', ['name ' + Date.now()]],
                                ['insert into test values(?)', ['name ' + Date.now()]],
                                ['insert into test values(?)', ['name ' + Date.now()]],
                            ]);

                            db.executeSql('select * from test', [])
                                .then((rs) => {
                                    console.log('Select SQL:');
                                    for (let i = 0; i < rs.rows.length; i++) {
                                        console.log(rs.rows.item(i));
                                    }
                                })
                                .catch(e => console.log(e));
                        });
                    break;
                case 3:

                    this.batchDataToSql('test', [{ id: '123', name: 'triết' }, { id: '456', name: 'lâm' }], { orverride: true }).then(async rs => {
                        const data = await this.getDataFromSql('test');
                        console.log('Select SQL:');
                        // for (let i = 0; i < data.rows.length; i++) {
                        //     console.log(data.rows.item(i));
                        // }
                    }).catch(console.error);
                    break;
                case 4:
                    console.log('Search test case: ');
                    this.searchFromSql('product_search_index', { SearchText: '%sieu thanh%' }).then(rs => {
                        console.log('Search result: ', rs);
                    }).catch(err => console.error(err));
                    break;
                case 5:
                    console.log('WebSQL test case: ');
                    this.batchDataToSql('test3', [{ id: '123', name: 'triết' }, { id: '456', name: 'lâm' }], { orverride: true }).then(async rs => {
                        const data = await this.getDataFromSql('test3');
                        console.log('Select SQL:');
                        // for (let i = 0; i < data.rows.length; i++) {
                        //     console.log(data.rows.item(i));
                        // }
                    }).catch(console.error);
                    break;
            }
        }, false);


    }

    createAutocompleteField(
        fieldEle: HTMLElement,
        ajax?: (query: string) => Promise<any[]>,
        onChange?: (value: any) => void,
        onClose?: (value: any) => void,
        option?: Partial<Autocomplete.Parameters> & {
            [key: string]: any,
            minSearchInputLength?: number,
        }) {
        const $this = this;
        if (!option) option = {} as any;
        if (typeof option.minSearchInputLength == 'undefined') {
            option.minSearchInputLength = 1;
        }
        const autocompleteStandaloneAjax = this.f7app.autocomplete.create({
            openIn: 'popup', //open in page
            openerEl: fieldEle, //link that opens autocomplete
            multiple: false, //allow multiple values
            valueProperty: 'id', //object's "value" property name
            textProperty: 'text', //object's "text" property name
            limit: 50,
            preloader: true, //enable preloader
            closeOnSelect: true,
            autoFocus: true,
            popupSwipeToClose: true,
            ...option,
            source: function (query, render) {
                var autocomplete = this;
                var results = [];
                if (query.length < option?.minSearchInputLength) {
                    render(results);
                    return;
                }
                // Show Preloader
                autocomplete.preloaderShow();
                $this.commonService.takeUntil('autocomplete-field-', 300).then(status => {
                    ajax(query).then(results => {
                        // Hide Preoloader
                        autocomplete.preloaderHide();
                        // Render items by passing array with result items
                        render(results);

                    });
                });
            },
            on: {
                change: function (value) {
                    onChange(value);
                },
                close: function (value) {
                    onClose(value);
                },
                open: function (autocomplete) {
                    option?.onOpen && option.onOpen();
                    if (option?.minSearchInputLength === 0) {
                        autocomplete['source']('');
                    }
                }
            },
        });
        return autocompleteStandaloneAjax;
    }

    async createNewContactForm(title: string, initData?: ContactModel, option?: any) {
        const $this = this;
        return new Promise<ContactModel>((resolve, reject) => {
            let template = Template7.compile(/*html*/`
            <div class="list inline-labels profile-form no-hairlines-md main-form" style="text-align: left; margin-left: -1rem; margin-right: -1rem; margin-bottom: 0; font-size: 1.5rem;">
                <ul>
                    <li class="item-content item-input">
                        <div class="item-inner">
                        <div class="item-title item-label">Tên (*)</div>
                        <div class="item-input-wrap">
                            <input class="field text-color-blue" name="Name" type="text" value="{{data.Name}}">
                        </div>
                        </div>
                    </li>
                    <li class="item-content item-input">
                        <div class="item-inner">
                        <div class="item-title item-label">SĐT</div>
                        <div class="item-input-wrap">
                            <input class="field text-color-blue" name="Phone" type="text" value="{{data.Phone}}">
                        </div>
                        </div>
                    </li>
                    <li class="item-content item-input">
                        <div class="item-inner">
                        <div class="item-title item-label">Email</div>
                        <div class="item-input-wrap">
                            <input class="field text-color-blue" name="Email" type="text" value="{{data.Email}}">
                        </div>
                        </div>
                    </li>

                    <li class="autocomplete {{validate data.Province schema 'Province'}}" name="Province">
                        <div class="item-link item-content" href="#">
                        <div class="item-inner">
                            <div class="item-title">Tỉnh/TP</div>
                            <div class="item-after text-color-blue">{{objecttext this 'data.Province' 'Tỉnh/TP...'}}</div>
                        </div>
                        </div>
                    </li>
                    <li class="autocomplete {{validate data.District schema 'District'}}" name="District">
                        <div class="item-link item-content" href="#">
                        <div class="item-inner">
                            <div class="item-title">Quận/Huyện</div>
                            <div class="item-after text-color-blue">{{objecttext this 'data.District' 'Quận/Huyện...'}}</div>
                        </div>
                        </div>
                    </li>
                    <li class="autocomplete {{validate data.Ward schema 'Ward'}}" name="Ward">
                        <div class="item-link item-content" href="#">
                        <div class="item-inner">
                            <div class="item-title">Phường/Xã</div>
                            <div class="item-after text-color-blue">{{objecttext this 'data.Ward' 'Phường/Xã...'}}</div>
                        </div>
                        </div>
                    </li>
                    <li class="item-content item-input">
                        <div class="item-inner">
                        <div class="item-title item-label">Địa chỉ</div>
                        <div class="item-input-wrap">
                            <input @keyup="onFieldChange" class="field text-color-blue" name="Address" type="text" placeholder="Số nhà, tên đường..."
                            value="{{data.Address}}">
                            
                        </div>
                        </div>
                    </li>
                </ul>
            </div>
            `);

            let dialog = null;
            let provinceAutocomplete = null;
            let districtAutocomplete = null;
            let wardAutocomplete = null;
            dialog = this.f7app.dialog.create({
                cssClass: 'dialog-large',
                title: title,
                content: template({ data: initData }),
                buttons: [
                    {
                        text: 'Trở về',
                        bold: true,
                        color: 'red'
                    },
                    {
                        text: 'Tạo',
                        bold: true,
                        color: 'blue',
                        onClick: async () => {
                            const form = $(dialog.el);

                            if (!form.find('[name="Name"]').val()) {
                                reject('Chưa điền tên liên hệ');
                                return;
                            }

                            const data = {
                                ...initData,
                                Name: form.find('[name="Name"]').val(),
                                Phone: form.find('[name="Phone"]').val(),
                                Email: form.find('[name="Email"]').val(),
                                Address: form.find('[name="Address"]').val(),
                                Province: provinceAutocomplete.value[0],
                                District: districtAutocomplete.value[0],
                                Ward: wardAutocomplete.value[0],
                            };

                            this.apiService.postPromise<ContactModel[]>('/contact/contacts', {}, [data])
                                .then(rs => resolve(rs[0]))
                                .catch(err => reject(err));
                        }
                    },
                ],
                // verticalButtons: true,
            });
            dialog.open();
            const form = $(dialog.el);
            const provinceEle = form.find('[name="Province"]');
            const districtEle = form.find('[name="District"]');
            const wardEle = form.find('[name="Ward"]');
            provinceAutocomplete = this.createAutocompleteField(provinceEle, query => {
                return this.apiService.getPromise('/general/locations', { select: 'id=>Code,text=>CONCAT(TypeLabel;\' \';FullName)', limit: 100, 'search': query, eq_Type: '[PROVINCE,CITY]' });
            }, changeValue => {
                provinceEle.find('.item-after').html(this.commonService.getObjectText(changeValue[0]));
            }, null, { minSearchInputLength: 0, autoFocus: false, limit: 20 });
            districtAutocomplete = this.createAutocompleteField(districtEle, query => {
                return this.apiService.getPromise('/general/locations', { select: 'id=>Code,text=>CONCAT(TypeLabel;\' \';FullName)', limit: 100, 'search': query, eq_Type: '[CDISTRICT,PDISTRICT,BURG,CITYDISTRICT]', eq_Parent: this.commonService.getObjectId(provinceAutocomplete.value && provinceAutocomplete.value[0] || null) });
            }, changeValue => {
                districtEle.find('.item-after').html(this.commonService.getObjectText(changeValue[0]));
            }, null, { minSearchInputLength: 0, autoFocus: false, limit: 20 });
            wardAutocomplete = this.createAutocompleteField(wardEle, query => {
                return this.apiService.getPromise('/general/locations', { select: 'id=>Code,text=>CONCAT(TypeLabel;\' \';FullName)', limit: 100, 'search': query, eq_Type: '[VILLAGE,WARD,TOWNS]', eq_Parent: this.commonService.getObjectId(districtAutocomplete.value && districtAutocomplete.value[0] || null) });
            }, changeValue => {
                wardEle.find('.item-after').html(this.commonService.getObjectText(changeValue[0]));
            }, null, { minSearchInputLength: 0, autoFocus: false, limit: 20 });
        });
    }

    async batchDataToSql(table: string, data: any[], option?: { orverride?: boolean }) {
        await this.authService.loginState$.pipe(filter(f => !!f), take(1)).toPromise();
        const dbName = this.authService.getRecentLoginId();
        let colums = Object.keys(data[0]);
        let batchData = [];
        if (option?.orverride) {
            batchData.push('drop table if exists ' + table);
        }
        batchData.push('create table if not exists ' + table + ' (\'' + colums.join('\', \'') + '\')');
        if (isPlatform('ios') || isPlatform('android')) {
            const insertCols = colums.map(m => '?').join(', ');
            for (const item of data) {
                for (const prop in item) {
                    if (typeof item[prop] == 'object') {
                        item[prop] = JSON.stringify(item[prop]);
                    }
                }
                batchData.push(['insert into ' + table + ' values (' + insertCols + ')', Object.keys(item).map(key => item[key])])
            }
            if (data && Array.isArray(data) && data.length > 0) {
                return this.sqlite.create({
                    name: dbName + '.db',
                    location: 'default'
                })
                    .then((db: SQLiteObject) => {
                        // window['sqlitedb'] = db;
                        // console.log('batch data: ', batchData);
                        return db.sqlBatch(batchData);
                    });
            }
        } else {
            // Web sql => limit 5M not enough
            // data = [data[0],data[1]];
            for (const item of data) {
                for (const prop in item) {
                    if (typeof item[prop] == 'object') {
                        item[prop] = JSON.stringify(item[prop]);
                    }
                }
                batchData.push("insert into " + table + " ('" + colums.join("', '") + "') values ('" + Object.keys(item).map(key => item[key]).join("', '") + "')")
            }
            const db = window['openDatabase'](
                dbName,
                1,
                dbName,
                100 * 1024 * 1024
            );

            db.transaction(function (tx) {
                for (const sql of batchData) {
                    tx.executeSql(sql);
                }
            });

        }
        return false;
    }

    async getDataFromSql(table: string, option?: {}) {
        await this.authService.loginState$.pipe(filter(f => !!f), take(1)).toPromise();
        const dbName = this.authService.getRecentLoginId();
        return this.sqlite.create({
            name: dbName + '.db',
            location: 'default'
        })
            .then((db: SQLiteObject) => {
                return db.executeSql('select * from ' + table, [])
                    .then((rs) => {
                        const data = [];
                        for (let i = 0; i < rs.rows.length; i++) {
                            const item = rs.rows.item(i);
                            for (const prop in item) {
                                if (/^\{|\[/.test(item[prop])) {
                                    item[prop] = JSON.parse(item[prop]) || item[prop];
                                }
                            }
                            data.push(item);
                        }
                        return data;
                    });
            });

    }

    async searchFromSql(table: string, params?: any, option?: {}): Promise<ProductSearchIndexModel[]> {
        if (isPlatform('ios') || isPlatform('android')) {
            // mobile: search from sqlite
            await this.authService.loginState$.pipe(filter(f => !!f), take(1)).toPromise();
            const dbName = this.authService.getRecentLoginId();
            return this.sqlite.create({
                name: dbName + '.db',
                location: 'default'
            })
                .then((db: SQLiteObject) => {
                    let whereCond = [];
                    for (const key in params) {
                        if (/%/.test(params[key])) {
                            whereCond.push(key + ' like \'' + params[key] + '\' ');
                        } else {
                            whereCond.push(key + ' = \'' + params[key] + '\' ');
                        }
                    }
                    let sql = 'select * from ' + table + ' where ' + whereCond.join(' and ');
                    console.log('search query : ', sql);
                    return db.executeSql(sql, [])
                        .then((rs) => {
                            const data = [];
                            for (let i = 0; i < rs.rows.length; i++) {
                                const item = rs.rows.item(i);
                                for (const prop in item) {
                                    if (/^\{|\[/.test(item[prop])) {
                                        item[prop] = JSON.parse(item[prop]) || item[prop];
                                    }
                                }
                                data.push(item);
                            }
                            console.log('Search result: ', data);
                            return data;
                        });
                });
        } else {
            // Web: search from memory
            if (this.productSearchIndexs$.value) {
                return this.productSearchIndexs$.value.filter(item => {

                    for (const key in params) {
                        if (/%/.test(params[key])) {
                            if (!new RegExp(this.commonService.convertUnicodeToNormal(params[key]).toLowerCase().replace(/%+/g, '.*'), 'ig').test(this.commonService.convertUnicodeToNormal(item[key]))) {
                                return false;
                            } else {
                                console.log('search matched', item);
                            }
                        } else {
                            if (!item[key] || item[key].toLowerCase() != params[key].toLowerCase()) {
                                return false;
                            } else {
                                console.log('search matched', item);
                            }
                        }
                    }
                    return true;

                });
            }
        }

    }

    async cacheUpdate(option?: { silent?: boolean, force?: boolean }) {
        // if (!this.isAppPlatform) {
        try {
            if (this.authService.loginState$?.value) {
                const loginId = this.authService.getRecentLoginId();
                const productSearchIndexs: ProductSearchIndexModel[] = [];
                let serverProductSearchIndexCheckPoint: any;
                if (!option?.force) {
                    serverProductSearchIndexCheckPoint = await this.apiService.getPromise<{ data: any }>('/commerce-pos/product-search-indexs', { cacheCheckPonit: true }).then(rs => {
                        console.log(rs);
                        return rs.data;
                    });
                    console.log(serverProductSearchIndexCheckPoint);
                    const productSearchCacheCheckPoint = localStorage.getItem(loginId + '_PRODUCT_SEARCH_INDEX_CACHE_CHECK_POINT');
                    // if (isPlatform('ios') || isPlatform('android')) {
                    // while (true) {


                    if (serverProductSearchIndexCheckPoint && serverProductSearchIndexCheckPoint == productSearchCacheCheckPoint) {
                        // Cache not modified
                        // if (!this.productSearchIndexs$.value) {
                        // if ((isPlatform('ios') || isPlatform('android'))) {
                        // !option?.silent && this.commonService.showInfo('Đang tải thông tin hàng hóa từ bộ nhớ...', { timeout: 10000 });
                        // const localProductSearchIndexCache = JSON.parse(await this.getFileData('cache', 'product_search_indexs.json'));
                        // const localProductSearchIndexCache = await this.getDataFromSqlite('product_search_index');
                        // console.log('Product search index: ', localProductSearchIndexCache);
                        // if (localProductSearchIndexCache) {
                        //     this.productSearchIndexs$.next(localProductSearchIndexCache);
                        //     !option?.silent && this.commonService.showInfo('Hoàn tất tải thông tin hàng hóa từ bộ nhớ...');
                        //     return true;
                        // } else {
                        //     !option?.silent && this.commonService.showInfo('Thông tin hàng hóa không có trong bộ nhớ !', { timeout: 10000, cssClass: 'text-color-orange' });
                        // }
                        console.log('product search index not change');
                        return true;
                        // }
                        // } else {
                        //     return true;
                        // }

                    }
                    // }
                }

                // Wait for logged in
                await this.authService.loginState$.pipe(filter(status => status === true), take(1)).toPromise();

                !option?.silent && this.commonService.showInfo('Đang tải thông tin hàng hóa...', { timeout: 10000 });
                // Download new product search index cache
                const products = await this.apiService.getPromise<ProductSearchIndexModel[]>('/commerce-pos/product-search-indexs', { fromCache: true });
                // if (products.length == 0) break;
                for (const product of products) {
                    product.id = product.Code;
                    product.text = product.Name;
                    const unitId = this.commonService.getObjectId(product.Unit);
                    const baseUnitId = this.commonService.getObjectId(product.BaseUnit);
                    const containerId = this.commonService.getObjectId(product.Container);
                    if (typeof product.Unit == 'string') {
                        product.Unit = { id: unitId, text: product.UnitLabel, Sequence: product.UnitSeq };
                    }
                    if (typeof product.BaseUnit == 'string') {
                        product.BaseUnit = { id: baseUnitId, text: product.BaseUnitLabel };
                    }
                    if (typeof product.Container == 'string') {
                        product.Container = { id: containerId, text: `${product.ContainerShelfName}/${product.ContainerFindOrder}`, FindOrder: product.ContainerFindOrder, Code: containerId, Name: product.ContainerName, Shelf: product.ContainerShelf, ShelfName: product.ContainerShelfName };
                    }
                    delete product?.Id;
                    product.SearchText = this.commonService.convertUnicodeToNormal(product.SearchText).toLowerCase();
                    product['UnitId'] = this.commonService.getObjectId(product.Unit);
                    product['BaseUnitId'] = this.commonService.getObjectId(product.BaseUnit);
                    product['ContainerId'] = this.commonService.getObjectId(product.Container);
                    productSearchIndexs.push(product);
                }



                if (isPlatform('ios') || isPlatform('android')) {
                    // Storage cache to file

                    // this.putFileData('cache', 'product_search_indexs.json', JSON.stringify(productSearchIndexs)).then(rs => {
                    //     console.log('put product search index to cache success');
                    //     // Update check point
                    //     localStorage.setItem('PRODUCT_SEARCH_INDEX_CACHE_CHECK_POINT', serverProductSearchIndexCheckPoint);
                    // }).catch(err => {
                    //     console.error(err);
                    // });

                    // const insertData = productSearchIndexs[0];
                    // delete insertData['Group'];
                    // delete insertData['Id'];
                    await this.batchDataToSql('product_search_index', productSearchIndexs, { orverride: true }).then(async rs => {
                        // const data = await this.getDataFromSqlite('product_search_index');
                        // console.log(data);
                        console.log('batch sqlite data success');
                        // console.log('Select SQL:');
                        // for (let i = 0; i < data.rows.length; i++) {
                        //     console.log(data.rows.item(i));
                        // }
                        // localStorage.setItem(loginId + '_PRODUCT_SEARCH_INDEX_CACHE_CHECK_POINT', serverProductSearchIndexCheckPoint);
                    }).catch(console.error);

                } else {
                    // Release new proudtc search index
                    this.productSearchIndexs$.next(productSearchIndexs);
                }
                localStorage.setItem(loginId + '_PRODUCT_SEARCH_INDEX_CACHE_CHECK_POINT', serverProductSearchIndexCheckPoint);
                !option?.silent && this.commonService.showInfo('Hoàn tất tải thông tin hàng hóa !');
            }
        } catch (err) {
            this.commonService.showError(err);
            console.error(err);
            return Promise.reject(err);
        }
        console.log('Load product search index complete');
        // }
        return true;
    }

    isSyncSettings = false;
    async syncInfo(retry?: boolean) {
        if (this.isSyncSettings) {
            return false;
        }
        this.isSyncSettings = true;
        const isAuthenticatedOrRefresh = await this.authService.isAuthenticatedOrRefresh().pipe(take(1)).toPromise();
        if (!isAuthenticatedOrRefresh) {
            console.error('Could not sync setting because not logged in');
            return false;
        }
        for (let i = 0; i < 3; i++) {
            const rs = await this.apiService.getPromise<SystemSettingsModel>('/system/settings', {}).then(systemSettings => {
                if (!systemSettings.LICENSE_INFO.register.mainLogo) {
                    systemSettings.LICENSE_INFO.register.mainLogo = systemSettings.LICENSE_INFO.register.voucherLogo;
                }
                this.systemSettings$.next(systemSettings);
                // this.systemSettings$.next(systemConfigs);
                this.isSyncSettings = false;
                this.authService.loadUserInfoByToken();
                if (retry) {
                    this.commonService.showInfo('Dịch vụ đã kết nối trở lại', { timeout: 10000 });
                }
                return systemSettings;
            }).catch(err => {
                this.isSyncSettings = false;
                console.error(err);
                if ([0, 500].indexOf(err?.status) > -1) {
                    if (this.authService.loginState$?.value) {
                        this.commonService.showError('Không thể kết nối đến dịch vụ, thử lại trong 10s...', { timeout: 10000 });
                    }
                }
                return this.commonService.waiting(10000).then(() => false);
                // setTimeout(() => {
                //     this.syncInfo(true);
                // }, 10000);
            });
            if (rs) {
                return rs;
            }
        }
    }

    compareVersion($a: string, $b: string) {
        const $aParts = $a.split('.');
        const $bParts = $b.split('.');

        const $loop = $aParts.length > $bParts.length ? $aParts.length : $bParts.length;
        for (let $i = 0; $i < $loop; $i++) {
            if (parseInt($aParts[$i]) > parseInt($bParts[$i])) {
                return 1;
            } else if (parseInt($aParts[$i]) < parseInt($bParts[$i])) {
                return -1;
            }
        }
        return 0;
    }

    // async updateSettings() {
    //     return this.apiService.getPromise('/user/users/settings', {}).then(settings => {
    //         this.settings$.next(settings);
    //         return settings;
    //     }).then(rs => {

    //         // Include update systen setting
    //         this.apiService.getPromise<SystemSettingsModel>('/system/settings', {}).then(systemConfigs => {
    //             console.log(systemConfigs);
    //             this.systemSettings$.next(systemConfigs);
    //         });

    //         return rs;
    //     });
    // }

    waitingForAllReady() {
        return this.allReady$.pipe(filter(f => f), take(1)).toPromise();
    }

    get f7app(): F7Framework7 {
        return (window as any).f7app;
    }

    async readFile(file: any): Promise<string | ArrayBuffer> {
        return new Promise<string | ArrayBuffer>((resolve) => {
            const reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.readAsArrayBuffer(file);
        });
    }

    getLightweightFileStore() {
        const fileServices = (this.microservices$.getValue() || []).filter(f => f.type === 'FILESTORE');
        let leightweightService: MicroserviceModel = null;
        if (fileServices && fileServices.length > 0) {
            for (const fileService of fileServices) {
                if (!leightweightService || leightweightService.weight > fileService.weight) {
                    leightweightService = fileService;
                }
            }
        }
        return leightweightService;
    }

    async uploadFileData(formData: FormData, progress?: (event: HttpEvent<any>) => void, option?: { weight: number }): Promise<FileModel> {
        return new Promise<FileModel>((resolve, reject) => {
            // this.http.post(this.apiService.buildApiUrl('/file/files'), formData).pipe(finalize(() => {
            //     //   loading.dismiss();
            // }), catchError(err => {
            //     reject(err);
            //     return err;
            // })).subscribe(res => {
            //     console.log('Upload complete', res);
            //     resolve(new FileModel(res[0]));
            // });
            // const fileServices = (this.microservices$.getValue() || []).filter(f => f.type === 'FILESTORE');
            // let leightweightService: MicroserviceModel = null;
            // if (fileServices && fileServices.length > 0) {
            //     for (const fileService of fileServices) {
            //         if (!leightweightService || leightweightService.weight > fileService.weight) {
            //             leightweightService = fileService;
            //         }
            //     }
            //     // this.apiService.postPromise(fileService.uri + '/' + fileService.version + '/file/files', {}, formData).then(res => {
            //     //     console.log('Upload complete', res);
            //     //     resolve(new FileModel(res[0]));
            //     // });
            // }
            this.getAvailableFileStores({ weight: option?.weight || 0 }).then(rs => rs[0]).then(fileStore => {

                this.apiService.uploadPromise(fileStore.Path + '/v1/file/files', { token: fileStore.UploadToken }, formData).subscribe(
                    event => {
                        console.log('Upload prgress', event);
                        if (progress) progress(event);
                        if (event.type === HttpEventType.UploadProgress) {
                        } else if (event instanceof HttpResponse) {
                            resolve(new FileModel(event.body[0]));
                        }
                    },
                    err => {
                        console.log('Upload error', err);
                    },
                );
            });

            // .catch(err => {
            //     console.error(err);
            //     this.commonService.showError(err);
            //     reject(err);
            // });
            // if (leightweightService) { leightweightService.weight++; }

        });
    }

    async uploadLocalFile(inputFile: string, progress?: (event: HttpEvent<any>) => void): Promise<FileModel> {
        return new Promise<FileModel>((resolve, reject) => {
            this.file.resolveLocalFilesystemUrl(inputFile)
                .then((entry: FileEntry) => {
                    // (entry as FileEntry).file(file => this.readFile(file).then(fileData => {
                    entry.file(file => this.readFile(file).then(fileData => {
                        const formData = new FormData();
                        const imgBlob = new Blob([fileData], {
                            type: file.type
                        });
                        // formData.append('file', imgBlob, file.name + (ext ? `.${ext}` : ''));
                        formData.append('file', imgBlob, file.name);
                        this.uploadFileData(formData, progress, { weight: file.size }).then((rs) => {
                            rs.MimeType = file.type;
                            resolve(rs);
                        }).catch(err => {
                            reject(err);
                        });
                    }));
                })
                .catch(err => {
                    // this.presentToast('Error while reading file.');
                    reject(err);
                    console.error(err);
                });
        });
    }

    async uploadLocalFileX(inputFile: string, ext?: string): Promise<FileModel> {
        // inputFile = inputFile.replace('file://', '');
        const fileTransfer: FileTransferObject = this.transfer.create();
        return this.file.resolveLocalFilesystemUrl(inputFile).then((entry: FileEntry) => {
            const lightweightFileStore = this.getLightweightFileStore();
            // Upload a file:
            let options: FileUploadOptions = {
                fileKey: 'file',
                fileName: entry.name,
                headers: {}
            }
            return fileTransfer.upload(entry.toURL(), (lightweightFileStore.uri + '/' + lightweightFileStore.version) + '/file/files', options).then(rs => {
                return new FileModel(rs[0]);
            }).catch(err => {
                console.error(err);
                return Promise.reject(err);
            });
        });
    }

    async waitFor(sleep: number, maxTry: number, check: () => boolean) {
        return new Promise<void>((resovle, reject) => {
            let counter = 0;
            (function loop() {

                if (++counter > maxTry) {
                    reject('Timeout');
                    return;
                }

                if (check()) {
                    resovle();
                } else {
                    setTimeout(() => {
                        loop();
                    }, sleep);
                }

            })();
        });
    }

    async waiting(miliSecond: number) {
        return new Promise(resovle => setTimeout(() => resovle(true), miliSecond));
    }

    makeLocalResourceUrl(path: string) {
        if (this.platform.is('ios')) {
            const trustImage: { changingThisBreaksApplicationSecurity: string } = this.sanitizer.bypassSecurityTrustResourceUrl(this.webview.convertFileSrc(path)) as any;
            return trustImage.changingThisBreaksApplicationSecurity;
        }
        return `http://localhost/${path}`;
    }

    convertBase64ToByteArray(base64Data: string) {
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        return new Uint8Array(byteNumbers);
    }

    async getDeviceUuid() {
        if (this.$deviceUuid) {
            return this.$deviceUuid;
        }
        this.$deviceUuid = await this.storage.get('uuid');
        if (!this.$deviceUuid) {
            if (isPlatform('ios') || isPlatform('android')) {
                this.$deviceUuid = this.device.uuid;
            } else {
                this.$deviceUuid = uuidv4();
            }
            await this.storage.set('uuid', this.$deviceUuid);
        }
        return this.$deviceUuid;
    }

    async registerDevice(option?: { pushRegId?: string }) {
        console.log('Register device...');
        return this.apiService.postPromise<DeviceModel>('/device/devices/', { registerDevice: true }, {
            RegisterId: option && option.pushRegId || undefined,
            Uuid: await this.getDeviceUuid() + this.env.bundleId,
            Name: isPlatform('android') ? 'android' : (isPlatform('ios') ? 'ios' : 'browser'),
            Platform: isPlatform('android') ? 'android' : (isPlatform('ios') ? 'ios' : 'browser'),
            Version: this.device.version,
            SenderIdentification: this.env.notification.senderId,
            Owner: this.authService.getUser() && this.authService.getUser().id,
            Mode: this.env.production ? 'Production' : 'Development',
            BundleId: this.env.bundleId,
            AppVersion: this.env.version,
        });
    }

    /**
     * Update last report time
     */
    async deviceUpdate() {
        console.log('device update last report...');
        // return this.socketService?.mainSocket?.emit<DeviceModel>('device-update', {
        //     Uuid: await this.getDeviceUuid() + this.env.bundleId,
        //     Owner: this.authService.getUser() && this.authService.getUser().id,
        // }).then(rs => {
        //     console.info(rs);
        //     return rs;
        // }).catch(err => {
        //     console.error(err);
        //     return Promise.reject(err);
        // });

        const deviceUuid = await this.getDeviceUuid() + this.env.bundleId;
        return this.apiService.putPromise('/device/devices', { id: deviceUuid }, [{
            Uuid: deviceUuid,
            Owner: this.authService.getUser() && this.authService.getUser().id,
        }]).then(rs => {
            console.info(rs);
            return rs;
        }).catch(err => {
            console.error(err);
            return Promise.reject(err);
        });
    }

    async unregisterDevice(option?: any) {
        return this.apiService.putPromise<DeviceModel[]>('/device/devices/', { unregisterDevice: true }, [{
            Uuid: await this.getDeviceUuid() + this.env.bundleId,
        }]).then(rs => {
            // this.firebaseX.unregister();
            return true;
        }).catch(err => {
            console.error(err);
            this.commonService.showError(err);
        });
    }

    async navigate(url: string, option?: { context?: any, view?: F7View, animate?: boolean, force?: boolean }) {
        if (!this.allowNavigate) return;

        if (/^https:\/\/probox.vn/.test(url)) {
            url = url.replace('https://probox.vn', '');
        }

        let view: F7View = option && option.view || this.f7app.view.current;
        if (!option?.force) {
            // Find relate view
            const views: F7View[] = this.f7app.views as any;
            for (const v of views) {
                if (v.history.some(f => f === url)) {
                    view = v;
                    break;
                }
            }

            if (!view) {
                console.error('view was not provided');
                return false;
            }

            // Active tab contain view
            // this.f7app.tab.show(this.f7app.$(''))

            this.f7app.tab.show(view.el, this.f7app.$('a[href="' + view.selector + '"]')[0] as HTMLElement);
        }

        // const historyIndex = view.history.some(f => f === url);
        if (!option?.force && view.history.some(f => f === url)) {
            let historySize = view.history.length;
            while (view.history[view.history.length - 1] !== url) {
                console.log('Route back');
                view.router.back();
                await this.waiting(300);
                // await this.commonService.waitFor(2000, 100, async() => {
                //     return historySize > view.history.length;
                // });
                // historySize = view.history.length;
            }
        } else {
            view.router.navigate(url, { context: option && option.context, animate: typeof option?.animate === 'undefined' ? true : option?.animate });
        }
    }

    async pickFiles() {
        return new Promise<PickerFile[]>(async (resolveLocalFile, reject3) => {
            if (this.platform.is('desktop') || this.platform.is('mobile')) {
                let input = null;
                const results = await new Promise<PickerFile[]>(resovle => {
                    input = document.createElement('input');
                    $('body').append(input);
                    input.type = 'file';
                    input.multiple = true;
                    input.onchange = (event: Event & { target: { files: FileList } }) => {

                        const progress = [];
                        // tslint:disable-next-line: prefer-for-of
                        for (let i = 0; i < event.target.files.length; i++) {
                            const file = event.target.files[i];
                            progress.push(new Promise<PickerFile>(resolve2 => {
                                // const file = e.target.files[0];
                                const reader = new FileReader();
                                reader.readAsArrayBuffer(file);

                                // here we tell the reader what to do when it's done reading...
                                reader.onload = readerEvent => {
                                    const localImage = readerEvent.target.result; // this is the content!
                                    resolve2({ url: readerEvent.target.result, thumbnail: 'data:image/jpeg;base64,' + btoa(new Uint8Array(localImage as any).reduce((data, byte) => data + String.fromCharCode(byte), '')), ext: 'jpeg', dataType: 'array-buffer', type: 'image/jpeg', size: file.size });
                                };
                            }));
                        }
                        resovle(Promise.all(progress));
                    };
                    input.click();
                });
                resolveLocalFile(results);
                $(input).remove();
            } else {

                const chooseCameraOrLibrary = this.f7app.actions.create({
                    buttons: [
                        [
                            {
                                text: 'Thư viện',
                                // color: 'gray',
                                // bold: true,
                                icon: '<i class="icon f7-icons">photo_fill_on_rectangle_fill</i>',
                                onClick: async (actions: any, e: unknown) => {
                                    // if (this.platform.is('desktop') || this.platform.is('mobile')) {
                                    //     // fallback to browser APIs
                                    //     const results = await new Promise<PickerFile[]>(resovle => {
                                    //         const input = document.createElement('input');
                                    //         input.type = 'file';
                                    //         input.multiple = true;
                                    //         input.onchange = (event: Event & { target: { files: FileList } }) => {

                                    //             const progress = [];
                                    //             // tslint:disable-next-line: prefer-for-of
                                    //             for (let i = 0; i < event.target.files.length; i++) {
                                    //                 const file = event.target.files[i];
                                    //                 progress.push(new Promise<PickerFile>(resolve2 => {
                                    //                     // const file = e.target.files[0];
                                    //                     const reader = new FileReader();
                                    //                     reader.readAsArrayBuffer(file);

                                    //                     // here we tell the reader what to do when it's done reading...
                                    //                     reader.onload = readerEvent => {
                                    //                         const localImage = readerEvent.target.result; // this is the content!
                                    //                         resolve2({ url: readerEvent.target.result, thumbnail: 'data:image/jpeg;base64,' + btoa(new Uint8Array(localImage as any).reduce((data, byte) => data + String.fromCharCode(byte), '')), ext: 'jpeg', dataType: 'array-buffer', type: 'image/jpeg', size: file.size });
                                    //                     };
                                    //                 }));
                                    //             }
                                    //             resovle(Promise.all(progress));
                                    //         };
                                    //         input.click();
                                    //     });
                                    //     resolveLocalFile(results);
                                    // } else {// make your native API calls
                                    const results = await this.imagePicker.getPictures({
                                        maximumImagesCount: 10,
                                        width: 1024,
                                        // outputType: 1,
                                    }).catch(err => {
                                        // self.$app.preloader.hide();
                                        console.error(err);
                                    });
                                    resolveLocalFile(results.map((result: string) => ({ url: result, ext: 'jpeg', type: 'image/jpeg', dataType: 'url' })));
                                    // }
                                },
                            },
                            {
                                text: 'Duyệt file',
                                // color: 'gray',
                                disabled: isPlatform('desktop'),
                                // bold: true,
                                icon: '<i class="icon f7-icons">folder_fill</i>',
                                onClick: (actions: any, e: unknown) => {
                                    this.chooser.getFile()
                                        .then(file => {
                                            console.log(file ? file.name : 'canceled');
                                            let ext = null;
                                            if (file.name) {
                                                ext = file.name.substr(file.name.lastIndexOf('.') + 1);
                                            } else {
                                                ext = file.mediaType.split('/')[1];
                                            }
                                            resolveLocalFile([file ? { url: file.uri, ext: ext, dataType: 'url', type: file.mediaType } : null]);
                                        }).catch((error: any) => console.error(error));
                                },
                            },
                            {
                                text: 'Chụp hình',
                                // color: 'gray',
                                disabled: isPlatform('desktop'),
                                // bold: true,
                                icon: '<i class="icon f7-icons">camera_on_rectangle_fill</i>',
                                onClick: (actions: any, e: unknown) => {
                                    const options: CameraOptions = {
                                        quality: 100,
                                        saveToPhotoAlbum: true,
                                        correctOrientation: true,
                                        destinationType: this.camera.DestinationType.FILE_URI,
                                        encodingType: this.camera.EncodingType.JPEG,
                                        mediaType: this.camera.MediaType.PICTURE
                                    };

                                    this.camera.getPicture(options).then((imagePath) => {
                                        resolveLocalFile([{ url: imagePath, ext: 'jpeg', dataType: 'url', type: 'image/jpeg' }]);
                                    }, (err) => {
                                        // Handle error
                                        reject3(err);
                                    });
                                },
                            },
                        ],
                        [
                            {
                                text: 'Trở về',
                                bold: true,
                                icon: '<i class="icon f7-icons">arrow_down_circle_fill</i>',
                                color: 'blue'
                            },
                        ],
                    ] as any
                });
                chooseCameraOrLibrary.open();
            }
        });
    }

    pickAndUploadFiles() {

        // let proloaderProcessing;
        const progress$ = new Subject<{ state: 'STARTUPLOAD' | 'UPLOADING' | 'ERROR' | 'COMPLETE', message?: any, progress?: number, loaded?: number, total?: number, results?: FileModel[] }>();
        new Promise(async (resolve) => {
            try {
                const localFiles = await this.pickFiles();
                console.debug(localFiles);

                // const uploadProgressBar = $(this.currentState.instance.el).find('.uploadProgressBar');
                progress$.next({ state: 'STARTUPLOAD', progress: 0 });

                let images: FileModel[] = [];
                for (const i in localFiles) {
                    // let image: FileModel = null;
                    images[i] = await ((progress) => {
                        if (localFiles[i].dataType === 'url') {
                            return this.uploadLocalFile(localFiles[i].url as string, (event) => {
                                progress(event)
                            });
                        } else {
                            const formData = new FormData();
                            const imgBlob = new Blob([localFiles[i].url], {
                                type: localFiles[i].type
                            });
                            formData.append('file', imgBlob, 'smart-bot-' + Date.now() + '.' + localFiles[i].ext);
                            return this.uploadFileData(formData, (event) => {
                                progress(event)
                            });
                        }
                    })((progress) => {
                        console.log(progress);
                        //   this.currentState.instance.$app.progressbar.set(uploadProgressBar[0], parseInt(progress.loaded / progress.total as any) * 100);
                        progress$.next({ state: 'UPLOADING', progress: parseInt(progress.loaded / progress.total as any) * 100, loaded: progress.loaded, total: progress.total });
                        // state(progress);
                    });
                    // onAfterImageUpload && onAfterImageUpload(images[i]);
                    progress$.next({ state: 'COMPLETE', progress: 100, results: images });
                }
                console.debug(images);
                // clearTimeout(proloaderProcessing);
                // this.commonService.hidePreloader();
                //   this.currentState.instance.$setState({ uploading: false });
                // return images;
                resolve(true);
            } catch (err) {
                console.error(err);
                // this.commonService.showError(err);
                // return null;
                progress$.next({ state: 'ERROR', message: err });
                resolve(false);
            }
        });
        return progress$;
    }

    async getVersion(pkg?: ILocalPackage) {
        let platform = 'desktop';
        if (isPlatform('ios')) {
            platform = 'ios';
        } else if (isPlatform('android')) {
            platform = 'android';
        } else {
            return { appVersion: this.env.version, coreVersion: this.env.coreVersion };
        }
        const packageInfo = pkg || await this.codePush.getCurrentPackage();
        // .then(packageInfo => {
        if (packageInfo) {
            const buildNumer = parseInt(packageInfo.label.split('v')[1]);
            // const baseVersion = packageInfo.appVersion.replace(/\.\d+$/, '');
            const baseVersion = packageInfo.appVersion.replace(/(\d+\.\d+\.\d+).*/, '$1');
            console.debug(packageInfo);
            console.debug('base version: ', baseVersion);
            return { appVersion: baseVersion + '.' + (buildNumer - this.env.deployment[platform].baseBuild), coreVersion: this.env.coreVersion };
        }
        return { appVersion: this.env.version, coreVersion: this.env.coreVersion };
        // });
    }

    getDeploymentKey() {
        let platform = 'desktop';
        if (isPlatform('ios')) {
            platform = 'ios';
        } else if (isPlatform('android')) {
            platform = 'android';
        } else {
            return '';
        }
        return this.env.deployment[platform].key;
    }

    /** Call */
    // updateCallState(incommingCallState: CallState) {
    //     this.f7app.tab.show('#dialpad');
    //     this.callStateSubject.next(incommingCallState);
    // }
    /** End call  */

    saveImageToPhone(url: string) {
        const downloader = window['imagedownloader'];
        if (downloader) {
            if (isPlatform('desktop')) {
                this.iab.create(url, '_system');
            } else {
                downloader.download(url,
                    () => {
                        //   alert("Image downloaded successfully");
                        this.commonService.showInfo('Hình ảnh đã được tải về ' + (isPlatform('ios') ? 'Ảnh' : isPlatform('android') ? 'Picture' : ''));
                    },
                    (err) => {
                        //   alert("Image download failed");
                        console.error('Download photo was failed', err);
                        this.commonService.showError('Không thể tải hình, chuyển sang tải bằng trình duyệt');
                        this.f7app.dialog.confirm('Ứng dụng không thể tải hình ảnh máy, bạn có muốn truy cập hình ảnh trên trình duyệt không ?', 'Tải hình ảnh', () => {
                            this.iab.create(url, '_system');
                        });
                    });
            }
        } else {
            this.commonService.showError('Plugin imagedownload was not installed');
        }
    }

    private lastGeoPositionUpdate: number = null;
    private lastGeoPosition: Coordinates = null;
    async getLastGeoLocation(option?: { retryAfterMilisecond?: number }): Promise<Coordinates> {
        const retryAfterMilisecond = option && option.retryAfterMilisecond;
        let timeout = null;
        if (retryAfterMilisecond && this.lastGeoPositionUpdate && this.lastGeoPositionUpdate + retryAfterMilisecond > Date.now()) {
            console.log('Old coors: ' + this.lastGeoPosition);
            return this.lastGeoPosition;
        }
        return new Promise(async resolve => {
            timeout = setTimeout(() => {
                console.log('!!! get geo location timeout 10s');
                timeout = null;
                this.lastGeoPositionUpdate = Date.now();
                resolve(null);
            }, 10000);

            const rs = await this.geolocation.getCurrentPosition().then<Coordinates>((resp) => {
                console.log('new coors: ' + resp.coords);
                this.lastGeoPositionUpdate = Date.now();

                return this.lastGeoPosition = {
                    latitude: resp.coords.latitude,
                    longitude: resp.coords.longitude,
                    accuracy: resp.coords.accuracy,
                    altitude: resp.coords.altitude,
                    altitudeAccuracy: resp.coords.altitudeAccuracy,
                    heading: resp.coords.heading,
                    speed: resp.coords.speed,
                };
            }).catch((err) => {
                console.log('Error getting location', err);
                // this.commonService.showError(err);
                return null;
            });
            if (timeout) {
                if (timeout) clearTimeout(timeout);
                resolve(rs);
            }
        });
    }

    get devicePlatform() {
        return isPlatform('android') ? 'android' : (isPlatform('ios') ? 'ios' : 'browser')
    }

    async getAvailableFileStores(option?: { weight?: number, limit?: number }) {
        return this.apiService.getPromise<FileStoreModel[]>('/file/file-stores', { filter_Type: 'REMOTE', sort_Weight: 'asc', eq_IsAvailable: true, eq_IsUpload: true, requestUploadToken: true, weight: option?.weight, limit: option?.limit || 1 });
    }

    get isAppPlatform() {
        const urlParsed = new URL(document.URL);
        if (!document.URL.startsWith('https') && !/^localhost:8200.*/.test(urlParsed.host)) {
            return true;
        }
        return false;
    }

    copyTextToClipboard(text: string) {
        if (this.isAppPlatform) {
            this.clipboard?.copy(text);
            this.commonService.showInfo('Đã copy vào clipboard');
        } else {
            var textArea = document.createElement("textarea");
            textArea.style.position = 'fixed';
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.width = '2em';
            textArea.style.height = '2em';
            textArea.style.padding = "0";
            textArea.style.border = 'none';
            textArea.style.outline = 'none';
            textArea.style.boxShadow = 'none';
            textArea.style.background = 'transparent';
            textArea.value = text;
            document.body.appendChild(textArea);
            textArea.select();
            try {
                var successful = document.execCommand('copy');
                var msg = successful ? 'successful' : 'unsuccessful';
                console.log('Copying text command was ' + msg);
                this.commonService.showInfo('Đã copy vào clipboard');
            } catch (err) {
                this.commonService.showError('Không thể copy vào clipboard');
            }
            document.body.removeChild(textArea);
        }
    }

    getTextVersion(html: string) {
        return textVersion(html, {
            imgProcess: (src: string, alt: string) => {
                return (alt || '') + '\n';
            }
        });
    }

    copyHtmlToClipboard(html: string) {
        this.copyTextToClipboard(textVersion(html, {
            imgProcess: (src: string, alt: string) => {
                return '\n';
            },
            linkProcess: (href: string, linkText: string) => {
                let result = '';
                if (href != linkText) {
                    result = linkText + ': ';
                }
                // if (!/^http/i.test(href)) {
                //     result += `https://probox.center${href}`;
                // } else {
                result += href;
                // }
                return result;
            }
        }));
    }

    copyHtmlElement(el) {
        // resolve the element
        el = (typeof el === 'string') ? document.querySelector(el) : el;

        // handle iOS as a special case
        if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {

            // save current contentEditable/readOnly status
            var editable = el.contentEditable;
            var readOnly = el.readOnly;

            // convert to editable with readonly to stop iOS keyboard opening
            el.contentEditable = true;
            el.readOnly = true;

            // create a selectable range
            var range = document.createRange();
            range.selectNodeContents(el);

            // select the range
            var selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            // el.setSelectionRange(0, 999999);

            // restore contentEditable/readOnly to original state
            el.contentEditable = editable;
            el.readOnly = readOnly;
        }
        else {
            el.select();
        }

        // execute copy command
        document.execCommand('copy');
    }

    async pickAndUploadFile() {
        return this.pickFiles().then(async localFiles => {
            try {
                this.commonService.showPreloader();
                console.debug(localFiles);
                let file: FileModel = null;
                if (localFiles[0].dataType === 'url') {
                    file = await this.uploadLocalFile(localFiles[0].url as string);
                } else {
                    const formData = new FormData();
                    const imgBlob = new Blob([localFiles[0].url], {
                        type: localFiles[0].type
                    });
                    formData.append('file', imgBlob, 'probox-' + Date.now() + '.' + localFiles[0].ext);
                    file = await this.uploadFileData(formData, null, { weight: localFiles[0].size });
                }
                this.commonService.hidePreloader();
                console.debug(file);
                return file;
            } catch (err) {
                this.commonService.hidePreloader();
                return Promise.reject(err);
            }
        });
    }

    getCurrentLoaleDataset() {
        const currentLocaleCode = this.translate.currentLang;
        if (currentLocaleCode) {
            switch (currentLocaleCode) {
                case 'vi-VN': return localeVi;
                case 'en-US': return localeEn;
            }
        }
        return null;
    }

    getNumberRadixPointChar() {
        return this.getCurrentLoaleDataset()[13][0];
    }
    getNumberGroupPointChar() {
        return this.getCurrentLoaleDataset()[13][1];
    }

    parseLocaleDecimal(value: string) {
        const groupsPointChar = this.getNumberGroupPointChar();
        const radixPointChar = this.getNumberRadixPointChar();
        if (value) {
            value = value.toString();
            let v = value.replace(new RegExp(`\\${groupsPointChar}`, 'g'), '');
            if (radixPointChar == ',') {
                v = v.replace(new RegExp(`\\${radixPointChar}`), '.');
            }
            return parseFloat(v);
        }
        return null;
    }

    extractGoodsBarcode(barcode: string): { unitSeq?: string, productId?: string, accessNumber?: string } {
        if (/^127/.test(barcode)) {
            return { accessNumber: barcode };
        }
        const coreId = this.systemSettings$.value.ROOT_CONFIGS.coreEmbedId;
        const productIdLength = parseInt(barcode.substring(0, 2)) - 10;
        let accessNumber = barcode.substring(productIdLength + 2);
        if (accessNumber) {
            accessNumber = '127' + accessNumber;
        }
        let productId = barcode.substring(2, 2 + productIdLength);
        let unitIdLength = parseInt(productId.slice(0, 1));
        let unitSeq = productId.slice(1, unitIdLength + 1);
        // let unit = unitMap[unitSeq];
        // let unitId = this.getObjectId(unit);
        productId = productId.slice(unitIdLength + 1);
        if (/^0/.test(productId)) {// trường hợp id sp ngoài core
            productId = '118' + productId.slice(1);
        } else {
            productId = '118' + coreId + productId;
        }

        return { unitSeq, productId, accessNumber };
    }

    isEmbedBarcodeScan = false;
    openEmbedBarcodeScanner(barcodeReadListener: (barcode: string) => void, option?: {
        x?: number,
        y?: number,
        width?: number,
        height?: number,
        camera?: string,
        toBack?: boolean,
        flashMode?: string,
    }): any {
        // if (isPlatform('ios') || isPlatform('android')) {
        //     if (!this.isEmbedBarcodeScan) {
        //         $('body').addClass('embed-barcode-scanner');
        //         let options = {
        //             x: 0,
        //             y: 0,
        //             width: window.screen.width,
        //             height: 200,
        //             camera: EmbeddedBarcodeReader.CAMERA_DIRECTION.BACK,
        //             toBack: false
        //         };
        //         EmbeddedBarcodeReader.addBarcodeReadListener(function (readBarcode) {
        //             console.log('We just read another barcode', readBarcode);
        //             barcodeReadListener(Array.isArray(readBarcode) && readBarcode[0] || readBarcode || '');
        //         });
        //         EmbeddedBarcodeReader.startCamera(options, () => {
        //             this.isEmbedBarcodeScan = true;
        //             if (option?.flashMode) {
        //                 EmbeddedBarcodeReader.setFlashMode(option?.flashMode, () => { console.log('flash on') }, err => { console.log('flash error', err) });
        //             }
        //         });
        //     }
        // }
        // return EmbeddedBarcodeReader;
    }

    embedBarcodeSannerFlashStatus = false;
    toggleEmbedBarcodeSannerFlash() {
        // this.embedBarcodeSannerFlashStatus = !this.embedBarcodeSannerFlashStatus;
        // EmbeddedBarcodeReader.setFlashMode(this.embedBarcodeSannerFlashStatus && 'on' || 'off', () => console.log('toggle flash success'), err => console.error(err));
    }

    closeEmbedBarcodeScanner() {
        // if (this.isEmbedBarcodeScan) {
        //     $('body').removeClass('embed-barcode-scanner');
        //     EmbeddedBarcodeReader.stopCamera();
        //     this.isEmbedBarcodeScan = false;
        // }
        // return EmbeddedBarcodeReader;
    }

    playNewPipSound() {
        return this.nativeAudio.play('newPipSound');
    }

    playIncreasePipSound() {
        return this.nativeAudio.play('increaseSound');
    }

    playDecreasePipSound() {
        return this.nativeAudio.play('decreaseSound');
    }

    playErrorPipSound() {
        return this.nativeAudio.play('errorSound');
    }

    playPaymentSound() {
        return this.nativeAudio.play('paymentSound');
    }

    async putFileData(directory: string, filePath: string, data: string) {
        const fileParts = filePath.split('/');
        const fileName = fileParts.pop();
        let path = '';
        if (directory == 'cache') {
            path = this.file.cacheDirectory + fileParts.join('/');
        } else {
            path = this.file.dataDirectory + (directory || '/') + fileParts.join('/');
        }
        return this.file.writeFile(path, fileName, data, { replace: true });
    }
    async getFileData(directory: string, filePath: string) {
        const fileParts = filePath.split('/');
        const fileName = fileParts.pop();
        let path = '';
        if (directory == 'cache') {
            path = this.file.cacheDirectory + fileParts.join('/');
        } else {
            path = this.file.dataDirectory + (directory || '/') + fileParts.join('/');
        }
        return this.file.readAsText(path, fileName);
    }
    // async putFileLine(directory: string, filePath: string, line: string) {
    //     const fileParts = filePath.split('/');
    //     const fileName = fileParts.pop();
    //     let path = '';
    //     if (directory == 'cache') {
    //         path = this.file.cacheDirectory + fileParts.join('/');
    //     } else {
    //         path = this.file.dataDirectory + (directory || '/') + fileParts.join('/');
    //     }
    //     return this.file.writeFile(path, fileName, '\n' + line, { append: true });
    // }
    // async getFileLine(directory: string, filePath: string) {
    //     const fileParts = filePath.split('/');
    //     const fileName = fileParts.pop();
    //     let path = '';
    //     if (directory == 'cache') {
    //         path = this.file.cacheDirectory + fileParts.join('/');
    //     } else {
    //         path = this.file.dataDirectory + (directory || '/') + fileParts.join('/');
    //     }
    //     return this.file.readAsText(path, fileName);
    // }


    async scanBarcodeByMlKit(options?: {
        barcodeFormats: {
            Code128: boolean,
            Code39: boolean,
            Code93: boolean,
            CodaBar: boolean,
            DataMatrix: boolean,
            EAN13: boolean,
            EAN8: boolean,
            ITF: boolean,
            QRCode: boolean,
            UPCA: boolean,
            UPCE: boolean,
            PDF417: boolean,
            Aztec: boolean,
        },
        beepOnSuccess: boolean,
        vibrateOnSuccess: boolean,
        detectorSize: number,
        rotateCamera: boolean,
    }): Promise<{ text: string, format: string }> {
        const defaultOptions = {
            ...options,
            barcodeFormats: {
                ...(options?.barcodeFormats || {}),
                Code128: true,
                Code39: true,
                Code93: true,
                CodaBar: true,
                DataMatrix: true,
                EAN13: true,
                EAN8: true,
                ITF: true,
                QRCode: true,
                UPCA: true,
                UPCE: true,
                PDF417: true,
                Aztec: true,
            },
            beepOnSuccess: false,
            vibrateOnSuccess: true,
            detectorSize: 0.7,
            rotateCamera: false,
        };
        // console.log('Start scan barcode');
        return new Promise<any>((resolve, reject) => {
            if (isPlatform('ios') || isPlatform('android')) {
                window['cordova']['plugins']['mlkit'].barcodeScanner.scan(
                    defaultOptions,
                    async (barcodeData) => {
                        resolve(barcodeData);
                    },
                    (error) => {
                        // Error handling
                        reject(error);
                    },
                );
            } else {
                this.f7app.dialog.prompt('Enter barcode data', 'Barcode', barcode => {
                    resolve({ text: barcode, format: 'manual' });
                }, () => {
                    reject('no barcode');
                });
            }
        });
    }

    getAccessNumberFromUrl(url: string) {
        if (/^https/.test(url)) {
            return url.replace(/^https:\/\/[^\/]+\/\d+\/truyxuat\/(\d+)/, '$1');
        }
        return url;
    }

    openExtenalLink(link: string) {
        this.iab.create(link, '_system');
    }

    async barcodeExtract(inputValue: string, type?: string): Promise<{ product?: ProductModel, unit?: UnitModel, container?: string, findOrder?: string, price?: number, accessNumber?: string, unitSeq?: string }> {

        let toast: Toast.Toast;
        try {

            if (/^9\d+/.test(inputValue)) {
                // Đây là barcode vị trí hàng hóa
                let tmpcode = inputValue.substring(1);
                const findOrderLength = parseInt(tmpcode.substring(0, 1));
                tmpcode = tmpcode.substring(1);
                const findOrder = tmpcode.substring(0, findOrderLength);
                tmpcode = tmpcode.substring(findOrderLength);
                const unitSeqLength = parseInt(tmpcode.substring(0, 1));
                tmpcode = tmpcode.substring(1);
                let unitSeq = tmpcode.substring(0, unitSeqLength);
                tmpcode = tmpcode.substring(unitSeqLength);
                let productId = tmpcode;

                let product = await this.searchFromSql('product_Search_index', { ContainerFindOrder: findOrder }).then(rs => rs[0]);
                if (!product) {
                    throw new Error('Không tìm thấy hàng hóa !');
                }
                let unit = product.Unit;
                let unitId = product.UnitId;

                let container = product.Container;

                if (!product) {
                    throw new Error('Không tìm thấy hàng hóa !');
                }

                const productByUnit = await this.searchFromSql('product_Search_index', { Code: product.Code, UnitId: unitId }).then(rs => rs[0]);
                let price = parseFloat(productByUnit?.Price || 0 as any);

                // if (product && unitSeq) {
                if (productByUnit) {
                    unit = productByUnit.Unit;
                    if (unit) {
                        unitId = unit.Code || unit.id;

                        product.Unit = { ...unit, id: unitId, text: unit.Name || unit.text };
                    }
                }

                if (!product) {
                    throw new Error('Không tìm thấy hàng hóa !');
                }

                unitId = unitId || this.commonService.getObjectId(product.Unit);

                product.Price = price;
                productId = product.Code;
                product.FindOrder = findOrder;

                return { product, unit, container, findOrder, price };
            } else {
                // Trường hợp số truy xuất
                let accessNumber, productId, unitSeq, unit, unitId, product, price, container;
                if (type == 'ACCESS_NUMBER' || /^127/.test(inputValue)) {// Sử dụng tạm thời cho các mã qr code phiên bản v1
                    accessNumber = inputValue;
                    await this.apiService.getPromise<ProductModel[]>('/commerce-pos/products', {
                        accessNumber: accessNumber,
                        includeUnit: true,
                        includePrice: true,
                        includeInventory: true,
                        includePreviousOrder: true,
                    }).then(async rs => {
                        console.log(rs);
                        const goods = rs[0];

                        productId = goods.Code;
                        unitId = this.commonService.getObjectId(goods.Unit);

                        product = await this.searchFromSql('product_search_index', { Code: productId, UnitId: unitId }).then(rs => rs[0]);
                        unit = product.Unit;
                        unitSeq = product.UnitSeq;
                        price = parseFloat(product.Price);
                        container = goods.Container;
                        return product;
                    });
                } else if (type == 'FIND_ORDER') {
                    const findOrder = await this.searchFromSql('product_Search_index', { FindOrder: inputValue }).then(rs => rs[0]);
                    productId = findOrder.Code;
                    product = findOrder;
                    if (!product) {
                        return Promise.reject('Không tim thấy hàng hóa !');
                    }
                    unitId = findOrder.Unit;
                    unit = findOrder.Unit;
                    unitSeq = findOrder.UnitSeq;
                    price = parseFloat(findOrder.Price as any);
                    container = findOrder.Container;

                } else {
                    const extracted = this.extractGoodsBarcode(inputValue);
                    accessNumber = extracted.accessNumber;
                    productId = extracted.productId;
                    unitSeq = extracted.unitSeq;
                    product = await this.searchFromSql('product_search_index', { Code: productId, UnitSeq: unitSeq }).then(rs => rs[0]);
                    if (!product) {
                        throw new Error('Không tìm thấy thông tin sản phẩm !');
                    }
                    unit = product.Unit;
                    unitId = this.commonService.getObjectId(unit);
                    if (!product) {
                        return Promise.reject('Không tim thấy hàng hóa !');
                    }
                    price = parseFloat(product.Price);
                }

                return { product, unit, price, accessNumber, unitSeq, container };
            }
        } catch (err) {
            this.commonService.showError(err);
            toast && toast.close();
            return Promise.reject(err);
        }
    }

    // async barcodeExtractx(inputValue: string): Promise<{ product?: ProductModel, unit?: UnitModel, container?: string, findOrder?: string, price?: number, accessNumber?: string, unitSeq?: string }> {

    //     try {

    //         if (/^9\d+/.test(inputValue)) {
    //             // Đây là barcode vị trí hàng hóa
    //             let tmpcode = inputValue.substring(1);
    //             const findOrderLength = parseInt(tmpcode.substring(0, 1));
    //             tmpcode = tmpcode.substring(1);
    //             const findOrder = tmpcode.substring(0, findOrderLength);
    //             tmpcode = tmpcode.substring(findOrderLength);
    //             const unitSeqLength = parseInt(tmpcode.substring(0, 1));
    //             tmpcode = tmpcode.substring(1);
    //             let unitSeq = tmpcode.substring(0, unitSeqLength);
    //             tmpcode = tmpcode.substring(unitSeqLength);
    //             let productId = tmpcode;
    //             let unit = this.unitSeqMap[unitSeq];
    //             let unitId = unit.Code;
    //             let goodsInfo = this.findOrderMap[findOrder];
    //             let container = goodsInfo.Container;
    //             let product = this.productMap[goodsInfo.Goods];
    //             let price = this.productMapByUnit[`${product.Code}-${unitId}`]?.Price || null;

    //             if (product && unitSeq) {
    //                 unit = this.unitSeqMap[unitSeq];
    //                 if (unit) {
    //                     unitId = unit.Code;
    //                     product.Unit = { ...unit, id: unit.Code, text: unit.Name };
    //                 }
    //             }

    //             if (!product) {
    //                 throw new Error('Không tìm thấy hàng hóa !');
    //             }

    //             unitId = this.commonService.getObjectId(product.Unit);
    //             product.Price = price;
    //             productId = product.Code;
    //             product.FindOrder = findOrder;

    //             return {
    //                 product, unit, container, findOrder, unitSeq,
    //                 price
    //             };
    //         } else {
    //             // Trường hợp số truy xuất
    //             const extracted = this.extractGoodsBarcode(inputValue);
    //             let accessNumber = extracted.accessNumber;
    //             let product, productId, unitSeq, unit, unitId, container, price;
    //             if (accessNumber && !extracted.productId) {
    //                 // Trường hợp chỉ lấy được số truy xuất, barcode không compile product id => fetch data from server
    //                 product = await this.apiService.getPromise<ProductModel[]>('/commerce-pos/products', {
    //                     accessNumber: accessNumber,
    //                     includeUnit: true,
    //                     includePrice: false,
    //                     includeInventory: true,
    //                 }).then(rs => rs[0]);
    //                 productId = product.Code;
    //                 unitId = this.commonService.getObjectId(product.Unit);
    //                 unit = this.unitMap[unitId];
    //                 unitSeq = unit.Sequence;
    //                 container = product.Container;
    //             } else {
    //                 productId = extracted.productId;
    //                 unitSeq = extracted.unitSeq;
    //                 unit = this.unitSeqMap[unitSeq];
    //                 unitId = this.commonService.getObjectId(unit);
    //             }
    //             product = this.productMap[productId];
    //             if (!product) {
    //                 return Promise.reject('Không tin thấy thông tin hàng hóa !');
    //             }
    //             // let productByUnit = this.goodsList
    //             price = this.productMapByUnit[`${product.Code}-${unitId}`]?.Price || null;
    //             // let price = 0;

    //             if (product && unitSeq) {
    //                 unit = this.unitSeqMap[unitSeq];
    //                 if (unit) {
    //                     unitId = unit.Code;
    //                     product.Unit = { ...unit, id: unit.Code, text: unit.Name };
    //                 }
    //             }

    //             return {
    //                 product, unit,
    //                 price,
    //                 accessNumber, unitSeq
    //             };
    //         }
    //     } catch (err) {
    //         this.commonService.showError(err);
    //         return Promise.reject(err);
    //     }
    // }

    callback$ = new Subject<{ action?: string, buttonName?: string, id: number, data?: any }>();


    playVideo(url: string) {
        if (isPlatform('desktop')) {
            this.iab.create(url, '_system');
        } else {
            let options: StreamingVideoOptions = {
                successCallback: () => { console.log('Video played') },
                errorCallback: (e) => { console.log('Error streaming') },
                // orientation: 'landscape',
                shouldAutoClose: false,
                controls: true
            };
            this.streamingMedia.playVideo(url, options);
        }
    }

    // previewStackIndex = 0;
    // photoBrowserIds: string[] = [];
    previewPictures(pictures: FileModel[], index: number, option?: {
        addToStackButton?: boolean,
        removeOutOfStackButton?: boolean,
        id?: string,
        imageSize?: string,
        on?: {
            slideChange: (photoBrowser: PhotoBrowser.PhotoBrowser) => void,
            // removeStack: (index: number) => void
        },
        buttons?: {
            name: string,
            icon: string,
            click: (index: number, photoBrowser: PhotoBrowser.PhotoBrowser) => void
        }[]
    }) {

        // Check attachments
        if (!pictures || pictures.length === 0) {
            return false;
        }

        if (option?.id) {
            if ($('.photo-browser-popup.' + option.id).length > 0) {

                const cpb = this.f7app.photoBrowser.get('.photo-browser-popup.' + option.id);
                if (cpb) {
                    console.error('photo browser id was exists');
                    return null;
                }

            }
            // this.photoBrowserIds.push(option.id);
        }

        const currentPhotoBrowsers = $('.photo-browser-popup');
        if ($('.photo-browser-popup').length > 0) {
            for (let i = 0; i < currentPhotoBrowsers.length; i++) {
                const currentPhotoBrowser = this.f7app.photoBrowser.get(currentPhotoBrowsers[i]);
                currentPhotoBrowser && currentPhotoBrowser.close();
            }
        }

        const idCallback = Date.now();
        const myPhotoBrowserPopupDark = this.f7app.photoBrowser.create({
            // on: {
            //     slideChange: (photoBrowser: PhotoBrowser.PhotoBrowser) => {
            //         // this.previewStackIndex = myPhotoBrowserPopupDark.activeIndex;
            //         option?.on?.slideChange && option.on.slideChange(myPhotoBrowserPopupDark);
            //     }
            // },
            photos: pictures.map(att => {
                const preview: any = {
                    caption: att.Description,
                };
                if (/^image/.test(att.MimeType || att.Type)) {
                    preview.url = att[option?.imageSize || 'OriginImage'];
                } else if (/^video/.test(att.MimeType)) {
                    preview.html = `<video controls width="100%" src="${att.OriginImage}" type="${att.MimeType}"></video>`;
                } else {
                    preview.url = att.Thumbnail;
                }
                return preview;
            }),
            theme: 'dark',
            type: 'standalone' as any,
            renderNavbar() {
                let html = /*html*/`
                    <div class="navbar">
                        <div class="navbar-bg"></div>
                        <div class="navbar-inner sliding">
                            <div class="left">
                            <a class="link back">
                                <i class="icon icon-back"></i>
                                <span class="if-not-md">Đóng</span>
                            </a>
                            </div>
                            <div class="title">Xem hình</div>
                            <div class="right">`;

                if (option?.buttons) {
                    for (const button of option.buttons) {
                        html += /*html*/`
                            <a href="javascript:f7app.data.activeComponent.onPhotoBrowserButtonClick(${idCallback}, '${button.name}', 'rootServices')" class="icon-only link">
                                <i class="icon f7-icons">${button.icon}</i>
                            </a>
                            `;
                    }
                }
                if (option?.addToStackButton) {
                    html += /*html*/`
                            <a href="javascript:f7app.data.activeComponent.addToStack(${idCallback}, 'rootServices')" class="icon-only link">
                                <i class="icon f7-icons">rectangle_fill_on_rectangle_angled_fill</i>
                            </a>
                            `;
                }
                html += /*html*/`
                            <a href="javascript:f7app.data.activeComponent.onPhotoBrowserDownloadAttachment(${idCallback}, 'rootServices')" class="icon-only link">
                                <i class="icon f7-icons if-not-md">cloud_download_fill</i>
                                <i class="icon material-icons if-md">cloud_download</i>
                            </a>
                            `;

                // if (option?.removeOutOfStackButton) {
                //     html += /*html*/`
                //             <a href="javascript:f7app.data.activeComponent.removeOutOfStack(${idCallback}, 'rootServices')" class="icon-only link">
                //                 <i class="icon f7-icons">multiply_circle_fill</i>
                //             </a>
                //             `;
                // }
                html += /*html*/`
                            </div>
                        </div>
                    </div>
                `;
                return html;
            },
        });

        myPhotoBrowserPopupDark.on('slideChange', (photoBrowser: PhotoBrowser.PhotoBrowser) => {
            option?.on?.slideChange && option.on.slideChange(myPhotoBrowserPopupDark);
        });

        myPhotoBrowserPopupDark.open(index as any);
        if (option?.id) {
            myPhotoBrowserPopupDark.$el.addClass(option.id);
        }


        let obs: Subscription = null;
        const download = (attachment: FileModel) => {
            console.debug('download index', attachment);
            if (/image/.test(attachment.MimeType)) {
                this.f7app.dialog.confirm([attachment.Name, attachment.Description].filter(f => !!f).join('<br>') || 'Đính kèm', 'Xác nhận tải về', async () => {
                    this.saveImageToPhone(attachment.DownloadLink as string);
                });
            } else if (/video/.test(attachment.Type)) {
                this.playVideo((attachment.OriginImage || attachment.DownloadLink) as string);
            } else {
                this.iab.create((attachment.OriginImage || attachment.DownloadLink) as string, '_system');
            }
        }

        obs = this.callback$.subscribe(params => {
            const attachment = pictures[params.data];
            if (params.action == 'onPhotoBrowserDownloadAttachment') {
                if (params.id === idCallback) {
                    download(pictures[params.data]);
                }
            }
            if (params.action == 'addToStack') {
                if (attachment) {
                    if (this.appComponent.processor.previewStack.findIndex(f => f.Id == attachment.Id) < 0) {
                        this.appComponent.processor.previewStack.unshift({
                            Id: attachment.Id,
                            SmallImage: attachment.SmallImage,
                            LargeImage: attachment.LargeImage,
                            OriginImage: attachment.OriginImage,
                            Thumbnail: attachment.Thumbnail,
                            MimeType: attachment.MimeType,
                        } as any);
                        this.appComponent.processor.savePreviewStack();
                        this.appComponent.ref.detectChanges();
                        this.commonService.showInfo('Đã thêm hình ảnh vào ngăn xếp', { position: 'bottom' });
                    } else {
                        this.commonService.showError('Hình ảnh này đã có trong ngăn xếp', { position: 'bottom' });
                    }
                }
            }
            // if (params.action == 'removeOutOfStack') {
            //     // option?.on?.removeStack && option?.on?.removeStack(myPhotoBrowserPopupDark.activeIndex);
            //     // this.appComponent.processor.previewStack = this.appComponent.processor.previewStack.filter(f => f.Id != attachment.Id);
            //     // this.appComponent.processor.previewStackIndex = this.appComponent.processor.previewStackIndex > 0 ? (this.appComponent.processor.previewStackIndex - 1) : 0;
            //     // this.appComponent.processor.savePreviewStack();
            //     // myPhotoBrowserPopupDark.close();
            //     // if (this.appComponent.processor.previewStack.length > 0) {
            //     //     this.previewPictures(this.appComponent.processor.previewStack, this.previewStackIndex, option);
            //     // }
            //     // this.commonService.showInfo('Đã gở hình ảnh khỏ ngăn xếp', { position: 'bottom' });
            // }
            if (params.action == 'onPhotoBrowserButtonClick') {
                const buttonName = params.buttonName;
                const button = option?.buttons?.find(f => f.name == buttonName);
                if (button) {
                    button.click(myPhotoBrowserPopupDark.activeIndex, myPhotoBrowserPopupDark);
                }
                // this.appComponent.processor.previewStack = this.appComponent.processor.previewStack.filter(f => f.Id != attachment.Id);
                // this.appComponent.processor.previewStackIndex = this.appComponent.processor.previewStackIndex > 0 ? (this.appComponent.processor.previewStackIndex - 1) : 0;
                // this.appComponent.processor.savePreviewStack();
                // myPhotoBrowserPopupDark.close();
                // if (this.appComponent.processor.previewStack.length > 0) {
                //     this.previewPictures(this.appComponent.processor.previewStack, this.previewStackIndex, option);
                // }
                // this.commonService.showInfo('Đã gở hình ảnh khỏ ngăn xếp', { position: 'bottom' });
            }
        });

        return new Promise<void>(resolve => {
            myPhotoBrowserPopupDark.on('close', () => {
                // if (option.id) {
                // this.photoBrowserIds = this.photoBrowserIds.filter(f => f != option.id)
                // }
                myPhotoBrowserPopupDark.destroy();
                if (obs) { obs.unsubscribe(); }
                resolve();
            });
        });

    }

    async switchToAccount(recentLogin: RecentLoginModel) {

        // Update current token to recent login
        await this.storage.get('recent_logins')
            .then((recentLogins: RecentLoginModel[]) => {
                recentLogins = recentLogins || [];
                const token = this.authService.token$.getValue().getPayload();
                let currentLogin = recentLogins.find(f => f.core === token.core && f.user == token.user);
                recentLogins = recentLogins.filter(f => f.core !== currentLogin.core || f.user !== currentLogin.user);
                const loginUser = this.authService.getUser();
                currentLogin.name = loginUser.name;
                currentLogin.avatar = loginUser.avatar && loginUser.avatar.payload && loginUser.avatar.payload.thumbnail;
                currentLogin.coreName = loginUser.core && loginUser.core.name;
                currentLogin.coreBanner = loginUser.core && loginUser.core.banner;
                if (currentLogin.token.user != token.user) {
                    return Promise.reject('token not matched');
                }
                currentLogin.token = token;
                recentLogins.unshift(currentLogin);
                return this.storage.set('recent_logins', recentLogins);
            }).catch(err => {
                console.error(err);
                this.commonService.showError(err);
            });


        await this.storage.remove(this.env.tokenStorageKey).then(() => {
        }).catch(err => {
            console.error(err);
            this.commonService.showError(err);
        });

        // Restore login
        await this.authService.restoreLogin(recentLogin.id).then(async authToken => {
            if (authToken) {
                const token = authToken.getPayload();
                return this.storage.get('recent_logins')
                    .then(async (recentLogins: RecentLoginModel[]) => {
                        recentLogins = recentLogins || [];
                        let currentLogin = recentLogins.find(f => f.core === token.core && f.user === token.user);
                        recentLogins = recentLogins.filter(f => f.core !== currentLogin.core || f.user !== currentLogin.user);

                        const loginUser = await this.authService.user$.value;
                        currentLogin.name = loginUser.name;
                        currentLogin.avatar = loginUser.avatar && loginUser.avatar.payload && loginUser.avatar.payload.thumbnail;
                        currentLogin.coreName = loginUser.core && loginUser.core.name;
                        currentLogin.coreBanner = loginUser.core && loginUser.core.banner || '/assets/images/no-image-available.png';
                        currentLogin.banner = loginUser.core && loginUser.core.banner;
                        currentLogin.backgroundBannerColor = loginUser.core && loginUser.core.backgroundBannerColor;
                        if (currentLogin.token.user != token.user) {
                            return Promise.reject('token not matched');
                        }
                        currentLogin.token = token;

                        if (/\/v\d+/.test(currentLogin.url)) {
                            currentLogin.url = currentLogin.url.replace(/\/v\d+/, '');
                        }

                        recentLogins.unshift(currentLogin);
                        this.storage.set('recent_logins', recentLogins);

                        this.commonService.showInfo('Đã chuyển máy chủ làm việc ' + currentLogin.name);

                        // const router: Router.Router = this.f7app.$f7router;
                    }).catch(err => {
                        console.error(err);
                        this.commonService.showError(err);
                    });


            } else {
                this.f7app.$router.navigate('/login', {
                    context: {
                        recentLogin: recentLogin,
                    }
                });
            }
        }).catch(err => {
            this.f7app.$router.navigate('/login', {
                context: {
                    recentLogin: recentLogin,
                }
            });
            console.error(err);
            this.commonService.showError(err);
        });
        // }
        return true;
    }

    async switchAccount(recentLoginIds?: string[], option?: { message?: string }) {

        return new Promise(async (resolve, reject) => {
            const $this = this;
            const recentLogins: RecentLoginModel[] = await this.storage.get('recent_logins').then((recentLogins: RecentLoginModel[]) => {
                return recentLogins.filter(f => (recentLoginIds && recentLoginIds.some(s => s == f.id) || true) && f.id != this.authService.token$.value?.getPayload().api_url + this.authService.user$.value?.id).map(item => {
                    item.coreName = new URL(item.url).host;
                    item.avatar = item.url + '/v3/user/users/' + item.user + '/avatar';
                    item.banner = item.url + '/v3/system/settings/banner';
                    return item;
                });
            }).catch(err => {
                console.error(err);
                this.commonService.showError(err);
                return [];
            });

            let listItemTemplate = Template7.compile(/*html*/`
              <div class="list media-list" style="margin-left: -1rem; margin-right: -1rem; margin-bottom: 0.5rem; margin-top: 0.5rem; text-align: left;">
                <ul>
                  {{#each recentLogins}}
                  <li data-id="{{id}}">
                    <a href="#" class="item-link item-content">
                      <div class="item-media"><div class="bg-color-gray" style="border-radius: 50%; overflow: hidden; width: 50px; height: 50px; background-repeat: no-repeat; background-size: cover; background-image: url({{js "this.avatar || ''"}})"></div></div>
                      <div class="item-inner">
                        <div class="item-title" style="white-space: normal;">{{name}}</div>
                        <div class="item-subtitle text-color-gray" style="font-size: 0.7rem; font-weight: bold;">{{coreName}}</div>
                      </div>
                    </a>
                  </li>
                  {{/each}}
                </ul>
              </div>
            `);

            const dialog = this.f7app.dialog.create({
                title: 'Chuyển máy chủ',
                text: option?.message || '',
                content: listItemTemplate({ recentLogins }),
                buttons: [
                    {
                        text: 'Trở về',
                        bold: true,
                        color: 'red',
                        onClick: () => {
                            reject(null);
                        }
                    },
                ],
                closeByBackdropClick: false,
                verticalButtons: true,
            });
            dialog.open();

            const listItem = dialog.$el.find('.list li');
            listItem.click(async function () {
                const id = $(this).attr('data-id');

                const recentLogin = recentLogins.find(f => f.id == id);
                if (recentLogin) {

                    // Update current token to recent login
                    await $this.storage.get('recent_logins')
                        .then((recentLogins: RecentLoginModel[]) => {
                            recentLogins = recentLogins || [];
                            const token = $this.authService.token$.getValue().getPayload();
                            let currentLogin = recentLogins.find(f => f.core === token.core && f.user == token.user);
                            recentLogins = recentLogins.filter(f => f.core !== currentLogin.core || f.user !== currentLogin.user);
                            const loginUser = $this.authService.getUser();
                            currentLogin.name = loginUser.name;
                            currentLogin.avatar = loginUser.avatar && loginUser.avatar.payload && loginUser.avatar.payload.thumbnail;
                            currentLogin.coreName = loginUser.core && loginUser.core.name;
                            currentLogin.coreBanner = loginUser.core && loginUser.core.banner;
                            if (currentLogin.token.user != token.user) {
                                return Promise.reject('token not matched');
                            }
                            currentLogin.token = token;
                            recentLogins.unshift(currentLogin);
                            return $this.storage.set('recent_logins', recentLogins);
                        }).catch(err => {
                            console.error(err);
                            $this.commonService.showError(err);
                        });


                    await $this.storage.remove($this.env.tokenStorageKey).then(() => {
                    }).catch(err => {
                        console.error(err);
                        $this.commonService.showError(err);
                    });

                    // Restore login
                    await $this.authService.restoreLogin(id).then(async authToken => {
                        if (authToken) {
                            const token = authToken.getPayload();
                            return $this.storage.get('recent_logins')
                                .then(async (recentLogins: RecentLoginModel[]) => {
                                    recentLogins = recentLogins || [];
                                    let currentLogin = recentLogins.find(f => f.core === token.core && f.user === token.user);
                                    recentLogins = recentLogins.filter(f => f.core !== currentLogin.core || f.user !== currentLogin.user);

                                    const loginUser = await $this.authService.user$.value;
                                    currentLogin.name = loginUser.name;
                                    currentLogin.avatar = loginUser.avatar && loginUser.avatar.payload && loginUser.avatar.payload.thumbnail;
                                    currentLogin.coreName = loginUser.core && loginUser.core.name;
                                    currentLogin.coreBanner = loginUser.core && loginUser.core.banner || '/assets/images/no-image-available.png';
                                    currentLogin.banner = loginUser.core && loginUser.core.banner;
                                    currentLogin.backgroundBannerColor = loginUser.core && loginUser.core.backgroundBannerColor;
                                    if (currentLogin.token.user != token.user) {
                                        return Promise.reject('token not matched');
                                    }
                                    currentLogin.token = token;

                                    if (/\/v\d+/.test(currentLogin.url)) {
                                        currentLogin.url = currentLogin.url.replace(/\/v\d+/, '');
                                    }

                                    recentLogins.unshift(currentLogin);
                                    $this.storage.set('recent_logins', recentLogins);
                                    resolve(currentLogin);
                                    // const router: Router.Router = $this.f7app.$f7router;
                                }).catch(err => {
                                    console.error(err);
                                    $this.commonService.showError(err);
                                    reject(err);
                                });


                        } else {
                            $this.f7app.$router.navigate('/login', {
                                context: {
                                    recentLogin: recentLogin,
                                }
                            });
                        }
                    }).catch(err => {
                        $this.f7app.$router.navigate('/login', {
                            context: {
                                recentLogin: recentLogin,
                            }
                        });
                        console.error(err);
                        $this.commonService.showError(err);
                        reject(err);
                    });
                }

                console.log(recentLogin);
                dialog.close();
            });

        });
    }

    enableAssistiveTouchFeature(element: HTMLElement) {
        document.addEventListener("deviceready", () => {
            var offset = [50, 500];
            // var assistiveTouch = document.getElementById("overlay");
            var isDown = false;
            element.addEventListener(isPlatform('ios') || isPlatform('android') ? 'touchstart' : 'mousedown', function (e) {
                isDown = true;
                if (isPlatform('android')) {
                    offset = [
                        element.offsetLeft - e['changedTouches'][0].pageX,
                        element.offsetTop - e['changedTouches'][0].pageY
                    ];
                } else {
                    offset = [
                        element.offsetLeft - e['pageX'],
                        element.offsetTop - e['pageY']
                    ];
                }
            }, true);
            document.addEventListener(isPlatform('ios') || isPlatform('android') ? 'touchend' : 'mouseup', function () {
                isDown = false;
            }, true);

            document.addEventListener(isPlatform('ios') || isPlatform('android') ? 'touchmove' : 'mousemove', function (e) {
                event.preventDefault();
                if (isDown) {
                    if (isPlatform('android')) {
                        element.style.left = (e['changedTouches'][0]['pageX'] + offset[0]) + 'px';
                        element.style.top = (e['changedTouches'][0]['pageY'] + offset[1]) + 'px';
                    } else {
                        element.style.left = (e['pageX'] + offset[0]) + 'px';
                        element.style.top = (e['pageY'] + offset[1]) + 'px';
                    }
                }
            }, true);
        });
    }

    async enterPasscodePopup(title: string, html: string, digits: 4 | 6, option?: Popup.Parameters & { [key: string]: any, onFullFill?: (passcode: string) => Promise<boolean>, backLabel?: string }) {
        const $this = this;
        return new Promise((resolve, reject) => {

            let params: Popup.Parameters = {
                swipeToClose: false,
                closeByBackdropClick: false,
                content: /*html*/`    
                <div class="popup my-popup">
                <div class="view">
                  <div class="page page-current">
                    <div class="navbar">
                      <div class="navbar-bg"></div>
                      <div class="navbar-inner">
                        <!--<div class="left">
                            <a class="link close-btn">
                                <i class="icon icon-back"></i>
                                <span class="if-not-md">${option && option.backLabel || 'Đóng'}</span>
                            </a>
                        </div>-->
                        <div class="title">${title}</div>
                        <div class="right">
                          <!--<a class="link popup-close">Trợ giúp</a>-->
                            <a class="link close-btn">
                                <span class="if-not-md">${option && option.backLabel || 'Đóng'}</span>
                                <i class="icon icon-forward"></i>
                            </a>
                        </div>
                      </div>
                    </div>
                    <div class="page-content">
                        <div class="block">
                            <div style="
                                padding: 0.5rem; 
                                border: 1px solid var(--f7-list-item-border-color); 
                                border-radius: 0.5rem;
                                background-color: var(--f7-block-strong-bg-color);">
                                <input class="passcode text-color-orange" type="text" style="
                                    width: 298px;
                                    font-size: 2rem;
                                    font-weight: bold;
                                    letter-spacing: ${digits == 4 ? '40' : '24'}px;
                                    margin: 0 auto;
                                    padding-left: ${digits == 4 ? '45' : '24'}px;
                                " placeholder="${new Array(digits + 1).join('_')}" inputmode="decimal" maxlength="${digits}">
                            </div>
                            <div class="popup-message" style="text-align: center; font-style: italic"></div>
                        </div>
                        ${html}
    
                    </div>
                  </div>
                </div>
                `,
                on: {
                    open: function (popup) {
                        console.log('Popup open');
                        const passcodeEle = popup.$el.find('.passcode');
                        const closeBtnEle = popup.$el.find('.close-btn');
                        const messageEle = popup.$el.find('.popup-message');
                        passcodeEle.focus();

                        passcodeEle.keyup(async e => {
                            console.log(e);
                            const passcode = passcodeEle.val();
                            messageEle.text('');
                            if (passcode.length == digits) {
                                if (option?.onFullFill) {
                                    try {
                                        $this.commonService.showPreloader()
                                        if (await option.onFullFill(passcodeEle.val())) {
                                            $this.commonService.hidePreloader();
                                            resolve(passcodeEle.val());
                                            messageEle.removeClass('text-color-red').addClass('text-color-green').text('Xác thực thành công');
                                            popup.close();
                                        } else {
                                            $this.commonService.hidePreloader();
                                            passcodeEle.val('');
                                            messageEle.text('');
                                        }
                                    } catch (err) {
                                        $this.commonService.hidePreloader();
                                        messageEle.removeClass('text-color-green').addClass('text-color-red').text(err?.message);
                                        if (err && err.error == 400) {
                                            reject(err?.message);
                                            popup.close();
                                        }
                                    }
                                } else {
                                    resolve(passcodeEle.val());
                                }
                            }
                        });


                        closeBtnEle.click(() => {
                            popup.close();
                            reject('CLOSE');
                        });
                    },
                    opened: function (popup) {
                        console.log('Popup opened');
                    },
                },
                animate: false,
                ...(option || {})
            };

            const dynamicPopup = this.f7app.popup.create(params);
            dynamicPopup.open();
        });
    }

    async openDynamicFormDialog<T>(
        title: string,
        content: string,
        fields: { name: string, type: string, placeholder?: string, label: string, required?: boolean, focus?: boolean, initValue?: any }[],
        buttons?: Dialog.Button[],
        option?: Dialog.Parameters & { [key: string]: any, submitButtonLabel?: string },
    ) {
        return new Promise<T>((resolve, reject) => {

            if (content) content = '';
            content += /*html*/`<div class="list inline-labelsx profile-form no-hairlines-md main-form" style="margin-left: -1rem; margin-right: -1rem; margin-bottom: 0; margin-top: 0; text-align: left">`;
            for (const field of fields) {
                if (field.type == 'text') {
                    content += /*html*/`<li class="item-content item-input">
                    <div class="item-inner">
                      <div class="item-title item-label">${field.label} ${field.required && '<span class="text-color-red">*</span>' || ''}</div>
                      <div class="item-input-wrap">
                        <input class="field text-color-blue" name="${field.name}" type="text" placeholder="${field.placeholder || field.label || ''}" value="${field.initValue || ''}">
                      </div>
                    </div>
                  </li>`;
                }
                if (field.type == 'password') {
                    content += /*html*/`<li class="item-content item-input">
                    <div class="item-inner">
                      <div class="item-title item-label">${field.label} ${field.required && '<span class="text-color-red">*</span>' || ''}</div>
                      <div class="item-input-wrap">
                        <input class="field text-color-blue" name="${field.name}" type="password" placeholder="${field.placeholder || field.label || ''}" value="${field.initValue || ''}">
                      </div>
                    </div>
                  </li>`;
                }
                if (field.type == 'decimal') {
                    content += /*html*/`<li class="item-content item-input">
                    <div class="item-inner">
                      <div class="item-title item-label">${field.label} ${field.required && '<span class="text-color-red">*</span>' || ''}</div>
                      <div class="item-input-wrap">
                        <input class="field text-color-blue" name="${field.name}" type="text" inputmode="decimal" placeholder="${field.placeholder || field.label || ''}" value="${field.initValue || ''}">
                      </div>
                    </div>
                  </li>`;
                }
                // if (field.type == 'password') {
                //     content += /*html*/`<li class="item-content item-input">
                //     <div class="item-inner">
                //       <div class="item-title item-label">${field.label} ${field.required && '*' || ''}</div>
                //       <div class="item-input-wrap">
                //         <input class="field text-color-blue" name="${field.name}" type="text" placeholder="${field.placeholder}">
                //       </div>
                //     </div>
                //   </li>`;
                // }
            }
            content += /*html*/`</div>`;

            if (!buttons) {
                buttons = [{
                    text: option?.submitButtonLabel,
                    bold: true,
                    onClick: (dialog) => {
                        const data: T = {} as any;
                        dialog.$el.find('.field').each((index, field) => {
                            data[field.name] = field.value;
                        });
                        resolve(data);
                    }
                }];
            }

            const dialog = this.f7app.dialog.create({
                title: title,
                content: content,
                buttons: [
                    {
                        text: 'Đóng',
                        color: 'red',
                        bold: true,
                        onClick: () => {
                            reject(null);
                        }
                    },
                    ...buttons,
                ]
            });
            dialog.on('open', (dialog: Dialog.Dialog) => {
                const focusFieldName = fields.find(f => f.focus)?.name;
                if (focusFieldName) {
                    dialog.$el.find('.field[name="' + focusFieldName + '"]').focus();
                }
            });
            dialog.open();
        });
    }

    async searchContactPromise(search: any): Promise<ContactModel[]> {
        return this.apiService.getPromise<ContactModel[]>('/contact/contacts', { search: search, select: 'id=>Code,text=>Name', includeGroups: true }).then(results => {
            return results.map(item => {
                item.text = `${item.text} (${item.Groups.map(g => this.commonService.getObjectText(g)).join(', ')})`;
                return item;
            });
        });
    }

    async searchUserContactPromise(search: any): Promise<ContactModel[]> {
        return this.apiService.getPromise<ContactModel[]>('/contact/contacts', { search: search, select: 'id=>User,text=>Name', isn_User: null, includeGroups: true }).then(results => {
            return results.map(item => {
                item.text = `${item.text} (${item.Groups.map(g => this.commonService.getObjectText(g)).join(', ')})`;
                return item;
            });
        });
    }

}
