import { AccountModel } from 'vendors/smart-bot-app/src/app/model/account.model';
import { take, filter } from 'rxjs/operators';
import { ChangeDetectorRef, Component, HostListener } from '@angular/core';

import { NavController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { ImagePicker } from '@ionic-native/image-picker/ngx';
import { ApiService } from 'vendors/smart-bot-app/src/app/services/api.service';
import { AuthService } from 'vendors/smart-bot-app/src/app/services/auth.service';
import { CommonService } from 'vendors/smart-bot-app/src/app/services/common.service';
import { RootServices } from 'src/app/services/root.services';
// import { SocketService } from 'vendors/smart-bot-app/src/app/services/socket.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { Storage } from '@ionic/storage';
import { SocketService } from 'vendors/smart-bot-app/src/app/services/socket.service';
import { AppComponentProcessor } from './app.component.processor';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import * as $ from 'jquery';
import { Title } from '@angular/platform-browser';

// declare const $: any;
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  // providers: [DatePipe],
})
export class AppComponent {

  processor: AppComponentProcessor;
  account: AccountModel = {};

  constructor(
    public platform: Platform,
    public splashScreen: SplashScreen,
    public statusBar: StatusBar,
    public authService: AuthService,
    public storage: Storage,
    public apiService: ApiService,
    public rootServices: RootServices,
    public datePipe: DatePipe,
    public currencyPipe: CurrencyPipe,
    public imagePicker: ImagePicker,
    public commonService: CommonService,
    public iab: InAppBrowser,
    public fileTransfer: FileTransfer,
    public file: File,
    public deeplinks: Deeplinks,
    public navController: NavController,
    public ref: ChangeDetectorRef,
    public titleService: Title
  ) {
    this.rootServices.appComponent = this;
    this.processor = new AppComponentProcessor(platform, splashScreen, statusBar, authService, storage, apiService, rootServices, datePipe, currencyPipe, imagePicker, commonService, iab, fileTransfer, file, deeplinks, navController, titleService);
    this.authService.loginState$.pipe(filter(f => f)).subscribe(state => {
      this.apiService.getPromise('/user/users/getInfo', {}).then(userInfo => {
        this.account = userInfo;
      });
    });
    this.authService.user$.pipe(filter(f => !!f)).subscribe(userInfo => {
      this.account = {
        Code: userInfo.id,
        Name: userInfo.name,
        TagName: userInfo.tagName,
        Username: userInfo.username,
        Avatar: userInfo.avatar as any,
      };
    });
    // this.registerDeeplinks();
    // (window as any).appComponent = this;
  }

  gotoAccount() {
    this.rootServices.navigate('/account');
    this.rootServices.f7app.panel.close();
  }

  gotoAbout() {
    if (this.rootServices.device.platform == 'browser') {
      this.rootServices.openExtenalLink('https://blog.probox.vn');
    } else {
      this.rootServices.navigate('/about');
      this.rootServices.f7app.panel.close();
    }
  }

  gotoProfile() {
    this.authService.user$.pipe(filter(f => !!f), take(1)).toPromise().then(userInfo => {
      this.rootServices.navigate('/bio-page/default');
      this.rootServices.f7app.panel.close();
    });
  }

  // registerDeeplinks() {
  //   console.log(getPlatforms());
  //   if (document.URL.startsWith('https')) {
  //     setTimeout(() => {
  //       if (location.pathname !== '/') {
  //         this.rootServices.socketService.ready$.pipe(filter(status => status), take(1)).toPromise().then(status => {
  //           this.processor.commonService.navigate(location.pathname);
  //         });
  //       }
  //     }, 3000);
  //   } else {
  //     this.platform.ready().then(() => {
  //       this.deeplinks.route({
  //         'chat-room': '/chat-room/:id',
  //         'product': '/product/:id',
  //       }).subscribe(match => {
  //         this.rootServices.socketService.ready$.pipe(filter(status => status), take(1)).toPromise().then(status => {
  //           setTimeout(() => {
  //             // match.$route - the route we matched, which is the matched entry from the arguments to route()
  //             // match.$args - the args passed in the link
  //             // match.$link - the full link data
  //             console.log('Deeplink: Successfully matched route', match);
  //             // alert('Deeplink: Successfully matched route');
  //             if (/^\/chat-room/.test(match?.$link?.path)) {
  //               this.processor.commonService.navigate('/chat-room/' + match?.$args?.id);
  //             }
  //           }, 1000);
  //         });
  //       }, nomatch => {
  //         // nomatch.$link - the full link data
  //         // alert('Deeplink: Got a deeplink that didn\'t match');
  //         // this.processor.commonService.navigate('/settings');
  //         console.log('Deeplink: Got a deeplink that didn\'t match', nomatch);
  //         if (nomatch !== 'plugin_not_installed') {
  //           this.registerDeeplinks();
  //         }
  //       });
  //     });
  //   }
  // }


  gotoToSettings() {
    this.rootServices.navigate('/settings');
    this.rootServices.f7app.panel.close();
    return true;
  }

  gotoToAccount() {
    this.rootServices.navigate('/account');
    this.rootServices.f7app.panel.close();
    return true;
  }

  goto(href: string) {
    this.rootServices.navigate(href);
    this.rootServices.f7app.panel.close();
    return true;
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    // console.log(event);
    return this.commonService.onKeydownEvent$.next(event);
  }
  @HostListener('document:keyup', ['$event'])
  handleKeyupEvent(event: KeyboardEvent) {
    // console.log(event);
    return this.commonService.onKeyupEvent$.next(event);
  }
}

