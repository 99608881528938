import { ContactModel } from '../../../model/contact.model';
import { SalesMasterPriceTable } from '../../../model/sales.model';
import { PriceReportVoucherDetailModel } from '../../../model/price-report.model';
import { ProductModel, UnitModel } from '../../../model/product.model';
import { F7ComponentContext, F7Page } from '../../../types/framework7-types';
import { Router } from 'framework7/build/core/modules/router/router';
import { BaseComponent, ComponentState } from '../../../lib/base-component';
import { RootServices } from '../../../services/root.services';
import { take, retry } from 'rxjs/operators';
import { ChatRoom } from '../../../lib/nam-chat/chat-room';
import { CommonService } from '../../../services/common.service';
import { TaskReminderModel } from '../../../model/task.model';
import { BehaviorSubject } from 'rxjs';
import { Dialog } from 'framework7/build/core/components/dialog/dialog';
import { CommercePosOrderModel } from '../../../../../src/app/model/commerce-pos.model';
import { Chart, registerables } from 'chart.js';
import { AccMasterBookModel } from '../../../../../src/app/model/accounting.model';
// import * as $ from 'jquery';
declare const $: any;

// declare const $: any;
export interface ComponentStateExtend extends ComponentState {
  [key: string]: any;
  instance: F7ComponentContextExtend,
  voucher?: CommercePosOrderModel;
  vouchers?: CommercePosOrderModel[];
}

export class F7ComponentContextExtend extends F7ComponentContext {
  // messagebar?: F7Messagebar;
  // messages?: Messages.Messages;
  // images?: string[];
  responseInProgress?: boolean;
  // answers?: string[];
  // people?: { name?: string, avatar?: string }[];
  // hideToolbar?: () => void;
  // sheetToggle?: () => void;
  // deleteAttachment?: (e: MouseEvent, index: number) => void;
  // handleAttachment?: (e: MouseEvent) => void;
  // checkAttachments?: () => void;
  // sendMessage?: () => void;

  detailDialog?: Dialog.Dialog;

  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, reminder?: TaskReminderModel, chatRoom?: ChatRoom, reminderList$?: BehaviorSubject<TaskReminderModel[]> } };
}

/** Component group manager */
export class CommercePosDashboardComponent extends BaseComponent<ComponentStateExtend> {
  // states: { [key: string]: State } = {};

  title: 'POS Dashboard'
  stateMap = {
    NOTJUSTAPPROVED: {
      label: 'Chưa duyệt',
      status: 'warning',
    },
    APPROVED: {
      label: 'Đã duyệt',
      status: 'success',
    },
  };

  masterBook: AccMasterBookModel = null;
  costAndRevenueStatisticsData: {};
  reportDate = new Date();

  dateReportList = [
    { id: 'DAY', text: 'Phân tích theo ngày', range: [new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0, 0), new Date(new Date().getFullYear(), new Date().getMonth(), 31, 23, 59, 59)] },
    { id: 'MONTH', text: 'Phân tích theo tháng', range: [new Date(new Date().getFullYear(), 0, 1), new Date(new Date().getFullYear(), 11, 31)] },
    { id: 'DAYOFWEEK', text: 'Phân tích theo tuần', range: [this.getUpcomingMonday(), this.getUpcomingSunday()] },
    { id: 'HOUR', text: 'Phân tích theo giờ', range: [new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0), new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59)] },
  ];

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
  ) {
    super(rootServices);
    // // console.log('Click here to open ts file');
    Chart.register(...registerables);
  }

  getUpcomingMonday() {
    const date = new Date();
    const today = date.getDate();
    const dayOfTheWeek = date.getDay();
    const newDate = date.setDate(today - dayOfTheWeek + 1);
    const result = new Date(newDate);
    result.setHours(0);
    result.setMinutes(0);
    result.setSeconds(0);
    return result;
  }
  getUpcomingSunday() {
    const date = new Date();
    const today = date.getDate();
    const dayOfTheWeek = date.getDay();
    const newDate = date.setDate(today - dayOfTheWeek + 7);
    const result = new Date(newDate);
    result.setHours(23);
    result.setMinutes(59);
    result.setSeconds(59);
    return result;
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  prepareItem(p: ProductModel) {
    p.DateOfOrderText = this.rootServices.datePipe.transform(p.DateOfOrder, 'short');
    p.StateLabel = this.stateMap[p.State]?.label || 'Unknow';
    p.StateStatus = this.stateMap[p.State]?.status || 'primary';
    return p;
  }

  async getList(params: any) {
    // console.log(self.searchBar);
    return this.rootServices.apiService.getPromise<any[]>('/purchase/order-vouchers', {
      includeCreator: true,
      includeRelativeVouchers: true,
      sort_Id: 'desc',
      limit: 10,
      offset: 0,
      ...params,
    });
  }


  dayOfWeekMap = {
    1: 'cn',
    2: 't2',
    3: 't3',
    4: 't4',
    5: 't5',
    6: 't6',
    7: 't7',
  };
  makeStaticLabel(item: any, reportType: string) {
    if (reportType === 'MONTH') {
      return (item['Month']).toString().padStart(2, "0") + '/' + (item['Year']).toString().padStart(2, "0");
    }
    if (reportType === 'DAY') {
      return (item['Day']).toString().padStart(2, "0") + '/' + (item['Month']).toString().padStart(2, "0");
    }
    if (reportType === 'HOUR') {
      return (item['Hour']).toString().padStart(2, "0");
    }
    // return (item['DayOfWeek']).toString().padStart(2, "0");
    return this.dayOfWeekMap[item['DayOfWeek']];
  }

  makeTimeline(item: any, reportType: string) {
    if (reportType === 'MONTH') {
      return (item['Year']).toString().padStart(2, "0") + '/' + (item['Month']).toString().padStart(2, "0");
    }
    if (reportType === 'DAY') {
      return item['Year'] + '/' + (item['Month']).toString().padStart(2, "0") + '/' + (item['Day']).toString().padStart(2, "0");
    }
    if (reportType === 'HOUR') {
      return item['Year'] + '/' + (item['Month']).toString().padStart(2, "0") + '/' + (item['Day']).toString().padStart(2, "0") + '/' + (item['Hour']).toString().padStart(2, "0");
    }
    return item['Year'] + '/' + (item['Month']).toString().padStart(2, "0") + '/' + (item['Day']).toString().padStart(2, "0");
  }

  // On component init
  async onComponentInit(state: ComponentStateExtend, index: string, asCase?: string, f7Page?: F7Page) {
    const currentState = await super.onComponentInit(state, index, asCase, f7Page);
    currentState.reportType = 'HOUR';
    currentState.reportForm = {};
    // const ctx = $('#myChart');

    // Load statistic data for sales activity
    await this.rootServices.apiService.getPromise<AccMasterBookModel[]>('/accounting/master-books/current', {}).then(rs => {
      this.masterBook = rs[0];
      const current = new Date();
      const previousMonth = new Date(current.getTime() - 31 * 24 * 60 * 60 * 1000);
      let previousYear = new Date();
      previousYear.setFullYear(previousYear.getFullYear() - 1);
      let fromDate = new Date(this.masterBook.DateOfBeginning);
      currentState.reportTypeList = [
        { id: 'DAY', reportType: 'DAY', text: '30 ngày gần nhất', range: [new Date(previousMonth.getFullYear(), previousMonth.getMonth(), previousMonth.getDate(), 0, 0, 0), current] },
        { id: 'MONTH', reportType: 'MONTH', text: '12 tháng gần nhất', range: [new Date(previousYear.getFullYear(), previousYear.getMonth(), previousYear.getDate()), current] },
        { id: 'FROMOPENED', reportType: 'MONTH', text: 'Đầu kỳ đến hiện tại', range: [new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate()), current] },
        { id: 'DAYOFWEEK', reportType: 'DAYOFWEEK', text: 'Các ngày trong tuần', range: [this.getUpcomingMonday(), this.getUpcomingSunday()] },
        { id: 'HOUR', reportType: 'HOUR', text: '24 giờ trong ngày', range: [new Date(current.getFullYear(), current.getMonth(), current.getDate(), 0, 0, 0), new Date(current.getFullYear(), current.getMonth(), current.getDate(), 23, 59, 59)] },
      ];
      currentState.instance.$setState({
        reportTypeList: currentState.reportTypeList
      });
    });


    currentState.reportQuery = { type: currentState.reportType, range: currentState.reportTypeList.find(f => f.id == currentState.reportType)?.range };

    const reportRanageControl = currentState.reportForm.range = currentState.instance.$f7.calendar.create({
      inputEl: '[name="ReportRange"]',
      dateFormat: 'd/m/yyyy',
      rangePicker: true,
      closeOnSelect: true,
      on: {
        change(calendar, value: Date[]) {
          console.log(calendar, value);
          if (value && value.length == 2) {
            currentState.reportQuery.range = value;
            currentState.instance.refresh();
          }
        },
      }
    });
    const reportTypeControl = currentState.reportForm.type = currentState.instance.$f7.smartSelect.get($(f7Page.el).find('[name="ReportType"]').closest('.smart-select') as any);
    reportTypeControl.on('change' as any, (smartSelect, value) => {
      console.log(smartSelect, value);
      currentState.reportQuery.type = value;
      currentState.reportQuery.range = currentState.reportTypeList.find(f => f.id == currentState.reportQuery.type)?.range;
      currentState.reportForm.range.setValue(currentState.reportQuery.range);
    });

    setTimeout(() => {
      currentState.reportForm.range.setValue(currentState.reportQuery.range);
      currentState.reportForm.type.setValue(currentState.reportType);
    }, 300);

    return currentState;
  }

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: 'commerce-pos-dashboard',
      path: '/commerce-pos/dashboard',
      component: {
        template: /*html*/`
            <div class="page pos-dashboard" data-name="pos-dashboard">
              <div class="navbar">
              <div class="navbar-bg"></div>
              <div class="navbar-inner sliding">
                <div class="left">
                  <a class="link back {{textColorClass}}">
                    <i class="icon icon-back"></i>
                    <span class="if-not-md {{textColorClass}}">{{js "this.backTitle || 'Back'"}}</span>
                  </a>
                </div>
                <div class="title">POS Dashboard</div>
                <div class="right">
                  <!-- Link to enable searchbar -->
                  <a class="link icon-only searchbar-enable {{textColorClass}}" data-searchbar=".inventory-adjust-note-list-searchbar">
                    <i class="icon f7-icons if-not-md">search</i>
                    <i class="icon material-icons md-only">search</i>
                  </a>
                </div>
                <!-- Searchbar is a direct child of "navbar-inner" -->
                <form class="searchbar inventory-adjust-note-list-searchbar searchbar-expandable">
                  <div class="searchbar-inner">
                    <div class="searchbar-input-wrap">
                      <input type="search" placeholder="Search" />
                      <i class="searchbar-icon"></i><span class="input-clear-button"></span>
                    </div>
                    <span class="searchbar-disable-button {{textColorClass}}">Hủy</span>
                  </div>
                </form>
              </div>
            </div>
            <div class="page-content ptr-content" @ptr:refresh="refresh" data-ptr-distance="80">
              <div class="ptr-preloader">
                <div class="preloader"></div>
                <div class="ptr-arrow"></div>
              </div>
              <div class="searchbar-backdrop product-list-backdrop"></div>

              <div class="block" style="margin-top: 1rem; margin-buttom: 0.3rem">
                <div class="row">
                  <div class="col-50">
                    <div class="card" style="">
                      <div class="card-content card-content-padding" style="color: var(--ion-color-primary);">
                        <div class="card-content-wrap">
                          <div class="icon-wrap">
                            <button class="col button button-large button-fill color-blue">
                              <i class="icon f7-icons">money_dollar_circle</i>
                            </button>
                          </div>
                          <div class="label-wrap">
                            <div class="label-text">Quỹ</div>
                            <div class="label-value">{{summaryReportData.Cash}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-50">
                    <div class="card" style="">
                      <div class="card-content card-content-padding" style="color: var(--ion-color-success);">
                        <div class="card-content-wrap">
                          <div class="icon-wrap">
                            <button class="col button button-large button-fill color-green">
                              <i class="icon f7-icons">chart_bar_circle</i>
                            </button>
                          </div>
                          <div class="label-wrap">
                            <div class="label-text">Doanh thu</div>
                            <div class="label-value" style="margin-top: -3px;">DT: {{summaryReportData.Revenues}}</div>
                            <div class="label-value" style="margin-top: -3px;">LK: {{summaryReportData.NumOfVouchers}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-50">
                    <div class="card" style="">
                      <div class="card-content card-content-padding" style="color: var(--ion-color-danger);">
                        <div class="card-content-wrap">
                          <div class="icon-wrap">
                            <button class="col button button-large button-fill color-red">
                              <i class="icon f7-icons">creditcard</i>
                            </button>
                          </div>
                          <div class="label-wrap">
                            <div class="label-text">Công nợ</div>
                            <div class="label-value" style="margin-top: -3px;">T: {{summaryReportData.GenerateCustomerReceivableDebtDebit}}</div>
                            <div class="label-value" style="margin-top: -3px;">G: {{summaryReportData.GenerateCustomerReceivableDebtCredit}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-50">
                    <div class="card" style="">
                      <div class="card-content card-content-padding" style="color: var(--ion-color-warning);">
                        <div class="card-content-wrap">
                          <div class="icon-wrap">
                            <button class="col button button-large button-fill color-orange">
                              <i class="icon f7-icons">heart_circle</i>
                            </button>
                          </div>
                          <div class="label-wrap">
                            <div class="label-text">Giảm DT</div>
                            <div class="label-value" style="margin-top: -3px;">TH: {{summaryReportData.DecreaseRevenuesByReturns}}</div>
                            <div class="label-value" style="margin-top: -3px;">GG: {{summaryReportData.DecreaseRevenuesByDiscount}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="block block-strong inset" style="padding: 0; overflow: hidden">
                <div class="list inline-labels no-hairlines" style="margin: 0;">
                  <ul>
                    <li>
                      <a class="item-link smart-select smart-select-init" data-open-in="sheet" data-close-on-select="true">
                          <select name="ReportType">
                            {{#each reportTypeList}}
                            <option value="{{id}}">{{text}}</option>
                            {{/each}}
                          </select>
                          <div class="item-content">
                            <div class="item-inner">
                              <div class="item-title">Báo cáo theo</div>
                            </div>
                          </div>
                        </a>
                    </li>
                    <li>
                      <div class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label">Phạm vi</div>
                          <div class="item-input-wrap">
                            <input name="ReportRange" type="text" placeholder="Ngày báo cáo" readonly="readonly" id="reportDate" @change="onReportDateChange" style="text-align: right;"/>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="tabs-swipeable-wrap" style="height: initial;">
                <div class="tabs">
                  <div id="pos-chart-tab-1" class="page-content tab tab-active" style="padding-top: 0px; padding-bottom: 0">
                    <div class="block-title" style="margin-top: 0.5rem">Danh thu/Giảm doanh thu<a class="button button-small button-fill tab-link" href="#pos-chart-tab-2" style="float: right; font-size: 0.7rem; font-weight: bold">next»</a></div>
                    <div class="block block-strong inset" style="margin-bottom: 0;">
                      <canvas id="posActivityChart" width="300" height="200"></canvas>
                    </div>
                  </div>
                  <div id="pos-chart-tab-2" class="page-content tab" style="padding-top: 0px; padding-bottom: 0">
                    <div class="block-title" style="margin-top: 0.5rem">Lượt khách<a class="button button-small button-fill tab-link" href="#pos-chart-tab-3" style="float: right; font-size: 0.7rem; font-weight: bold">next»</a></div>
                    <div class="block block-strong inset" style="margin-bottom: 0;">
                      <canvas id="posNumOfVouchersChart" width="300" height="200"></canvas>
                    </div>
                  </div>
                  <div id="pos-chart-tab-3" class="page-content tab" style="padding-top: 0px; padding-bottom: 0">
                    <div class="block-title" style="margin-top: 0.5rem">Công nợ</div>
                    <div class="block block-strong inset" style="margin-bottom: 0;">
                      <canvas id="posDebtChart" width="300" height="200"></canvas>
                    </div>
                  </div>
                </div>
              </div>

              

              <div class="block block inset" style="padding: 0rem; overflow: hidden; margin-bottom: 0;">
              </div>
              <div class="tabs-swipeable-wrap" style="height: initial;">
                <div class="tabs">
                  <div id="pos-top-tab-1" class="page-content tab tab-active" style="padding-top: 0px">
                    <div class="block-title">Top nhân viên theo doanh số<a class="button button-small button-fill tab-link" href="#pos-top-tab-2" style="float: right; font-size: 0.7rem; font-weight: bold">next»</a></div>
                    <div class="list media-list inset">
                      <ul>
                        {{#each topEmployeeList}}
                        <li data-name="remove-chat-room-member" data-id="{{Employee}}" @click="gotoProfile">
                          <a class="item-content item-link">
                            <div class="item-media">
                              {{#js_if "this.EmployeeAvatar"}}
                              <div class="list-item-img" style="width: 44px; height: 44px; background-image: url({{js "this.EmployeeAvatar &&  this.EmployeeAvatar.SmallImage"}});"></div>
                              {{else}}
                              <div class="list-item-img" style="width: 44px; height: 44px">
                                <i style="font-size: 16px" class="f7-icons">person_fill</i>
                              </div>
                              {{/js_if}}
                            </div>
                            <div class="item-inner">
                              <div class="item-title-row">
                                <div class="item-title">{{EmployeeName}}</div>
                                <div class="item-after">{{#if RefIcon}}<img style="height: 12px;" src="{{RefIcon}}">{{/if}}</div>
                              </div>
                              <div class="item-subtitle" style="color: var(--ion-color-primary)">{{Revenus}}/{{GenerateNumOfVouchers}} đơn</div>
                            </div>
                          </a>
                        </li>
                        {{/each}}
                      </ul>
                    </div>
                  </div>  
                  <div id="pos-top-tab-2" class="page-content tab" style="padding-top: 0px">
                    <div class="block-title">Top khách theo doanh số<a class="button button-small button-fill tab-link" href="#pos-top-tab-3" style="float: right; font-size: 0.7rem; font-weight: bold">next»</a></div>
                    <div class="list media-list inset">
                      <ul>
                        {{#each topCustomerList}}
                        <li data-name="remove-chat-room-member" data-id="{{Employee}}" @click="gotoProfile">
                          <a class="item-content item-link">
                            <div class="item-media">
                              {{#js_if "this.Avatar"}}
                              <div class="list-item-img" style="width: 44px; height: 44px; background-image: url({{js "this.EmployeeAvatar &&  this.Avatar.SmallImage"}});"></div>
                              {{else}}
                              <div class="list-item-img" style="width: 44px; height: 44px">
                                <i style="font-size: 16px" class="f7-icons">person_fill</i>
                              </div>
                              {{/js_if}}
                            </div>
                            <div class="item-inner">
                              <div class="item-title-row">
                                <div class="item-title">{{ObjectName}}</div>
                                <div class="item-after">{{#if RefIcon}}<img style="height: 12px;" src="{{RefIcon}}">{{/if}}</div>
                              </div>
                              <div class="item-subtitle" style="color: var(--ion-color-primary)">{{Revenus}}/{{GenerateNumOfVouchers}} đơn</div>
                            </div>
                          </a>
                        </li>
                        {{/each}}
                      </ul>
                    </div>
                  </div>  
                  <div id="pos-top-tab-3" class="page-content tab" style="padding-top: 0px">
                    <div class="block-title">Top hàng hóa theo doanh số</div>
                    <div class="list media-list inset">
                      <ul>
                        {{#each topGoodsList}}
                        <li data-name="remove-chat-room-member" data-id="{{Employee}}" @click="gotoProfile">
                          <a class="item-content item-link">
                            <div class="item-media">
                              {{#js_if "this.Thumbnail"}}
                              <div class="list-item-img" style="width: 44px; height: 44px; background-image: url({{js "this.Thumbnail &&  this.Thumbnail.SmallImage"}});"></div>
                              {{else}}
                              <div class="list-item-img" style="width: 44px; height: 44px">
                                <i style="font-size: 16px" class="f7-icons">person_fill</i>
                              </div>
                              {{/js_if}}
                            </div>
                            <div class="item-inner">
                              <div class="item-title-row">
                                <div class="item-title">{{Description}}</div>
                                <div class="item-after">{{#if RefIcon}}<img style="height: 12px;" src="{{RefIcon}}">{{/if}}</div>
                              </div>
                              <div class="item-subtitle" style="color: var(--ion-color-primary)">{{Revenus}}/{{GenerateNumOfVouchers}} đơn</div>
                            </div>
                          </a>
                        </li>
                        {{/each}}
                      </ul>
                    </div>
                  </div>  
                </div>
              </div>
            
            </div>
            </div>
        `,
        style: /*css*/`
          .warehouse-container-list .find-order {
            font-size: 16px;
            font-weight: bold;
          }
          .warehouse-container-list .action-bar {
            color: var(--f7-block-strong-text-color);
            padding-top: var(--f7-block-padding-vertical);
            padding-bottom: var(--f7-block-padding-vertical);
            background-color: var(--f7-block-strong-bg-color);
          }

          .page.pos-dashboard .card {
            margin-left: 0; 
            margin-right: 0
          }
          .page.pos-dashboard .card .card-content {
            padding: 0.5rem;
          }
          .page.pos-dashboard .card .card-content-wrap {
            display: flex; flex-direction: row
          }
          .page.pos-dashboard .card .icon-wrap {
            padding-right: 0.5rem;
          }
          .page.pos-dashboard .card .icon-wrap button {
            width: 3rem; height: 3rem
          }
          .page.pos-dashboard .card .label-wrap {

          }
          .page.pos-dashboard .card .label-wrap {
            display: flex; flex-direction: column
          }
          .page.pos-dashboard .card .label-wrap .label-text {
            font-weight: bold; font-size: 1rem
          }
          .page.pos-dashboard .card .label-wrap .label-value {
            font-weight: bold;
            font-size: 0.7rem;
            white-space: nowrap;
          }

        `,
        data() {
          return {
            title: $this.title,
            // reminder: {
            //   No: '234234',
            // },
            priceReport: { Details: [] },
            summaryReportData: {

            },
            reportDate: $this.reportDate.getDate() + '/' + ($this.reportDate.getMonth() + 1) + '/' + $this.reportDate.getFullYear(),
            dateReportList: [],
          };
        },
        methods: {
          onReportDateChange(e) {
            // const self: F7ComponentContextExtend = this;
            // console.log(e.currentTarget.value);
            // const inputDate = e.currentTarget.value.split('/');
            // $this.reportDate = new Date(inputDate[2], inputDate[1]-1, inputDate[0]);
            // $this.commonService.showPreloader();
            // self.refresh().then(rs => {
            //   $this.commonService.hidePreloader();
            // }).catch(err => {
            //   $this.commonService.hidePreloader();
            // });
          },
          gotoProfile(e) {
          },
          // Price report
          openVoucher(e, id?: string) {
            const self: F7ComponentContextExtend = this;
            id = id || self.$(e.target).closest('li').data('id') || 'new';

            // if ($this.commonService.frameSocket.isFrameMode && id && id !== 'new') {
            //   $this.commonService.frameSocket.emit('request-open-voucher', { id: id, type: 'PRICEREPORT' });
            // } else {
            $this.rootServices.navigate('/purchase/order/' + id, {
              context: {
                backTitle: $this.title,
                textColorClass: self.textColorClass,
                // memberInfo: $this.currentState.chatRoom.memberList$.getValue().find(f => f.id === id),
                // chatRoom: self.$route.context.chatRoom,
                // reminderList$: self.reminderList$,
              }
            });
            // }
          },
          goto(e) {
            const self: F7ComponentContextExtend = this;
            const url = self.$(e.target).closest('.goto').data('url');
            $this.rootServices.navigate(url);
          },
          chooseOne(e) {
            const self: F7ComponentContextExtend = this;
            const id = self.$(e.target).closest('li').data('id');
            self.$route.context['onChoose'] && self.$route.context['onChoose'](self.productList.find(f => f.Code === id));
          },
          chooseProduct(e) {

          },
          async refresh(e, done) {
            const self: F7ComponentContextExtend = this;
            const currentState = $this.state['main'];
            let doneTimeout = null;
            if (done) {
              doneTimeout = setTimeout(() => {
                done();
              }, 10000);
            };

            let reportType = currentState.reportTypeList.find(f => f.id == currentState.reportQuery.type)?.reportType;
            let pages = [];
            const range = currentState.reportQuery.range;
            if (range && range.length < 2 || !reportType) {
              done && done();
              return;
            }
            const dateRange: Date[] = [range[0], range[1]];

            let fromDate = dateRange && dateRange[0] && (new Date(dateRange[0].getFullYear(), dateRange[0].getMonth(), dateRange[0].getDate(), 0, 0, 0, 0)).toISOString() || null;
            let toDate = dateRange && dateRange[1] && new Date(dateRange[1].getFullYear(), dateRange[1].getMonth(), dateRange[1].getDate(), 23, 59, 59, 999).toISOString() || null;

            // Report summary
            let summaryReport = await $this.rootServices.apiService.getPromise<any[]>('/accounting/reports', {
              reportSummary: true,
              eq_Accounts: "1111,1121,511,512,515,521,632,635,642,641,623,131,331",
              skipHeader: true,
              branch: pages,
              toDate: toDate,
              fromDate: fromDate,
              limit: 'nolimit',
              entryGroup: 'COMMERCEPOS',
            });
            let summaryReportData: any = {
              Cash: 0,
              GenerateCashDebit: 0,
              GenerateCashCredit: 0,
              Revenues: 0,
              DecreaseRevenues: 0,
              DecreaseRevenuesByReturns: 0,
              DecreaseRevenuesByDiscount: 0,
              GenerateCustomerReceivableDebtDebit: 0,
              GenerateCustomerReceivableDebtCredit: 0,
              CostOfGoodsSold: 0,
              GrossProfit: 0,
              NumOfVouchers: 0,
            };

            for (const reportItem of summaryReport) {
              // if (/^111/.test(reportItem.Account)) summaryReportData.Cash += (reportItem.GenerateDebit - reportItem.GenerateCredit);
              // if (/^112/.test(reportItem.Account)) summaryReportData.CashInBank += (reportItem.GenerateDebit - reportItem.GenerateCredit);
              if (/^511|512|515/.test(reportItem.Account)) {
                summaryReportData.Revenues += (reportItem.GenerateCredit - reportItem.GenerateDebit);
                summaryReportData.NumOfVouchers += reportItem.GenerateNumOfVouchers;
              }
              if (/^521/.test(reportItem.Account)) {
                summaryReportData.DecreaseRevenues += (reportItem.GenerateDebit - reportItem.GenerateCredit);
              }
              if (/^5212/.test(reportItem.Account)) {
                summaryReportData.DecreaseRevenuesByReturns += (reportItem.GenerateDebit - reportItem.GenerateCredit);
              }
              if (/^5213/.test(reportItem.Account)) {
                summaryReportData.DecreaseRevenuesByDiscount += (reportItem.GenerateDebit - reportItem.GenerateCredit);
              }
              // if (/^632/.test(reportItem.Account)) {
              //   summaryReportData.CostOfGoodsSold += (reportItem.GenerateDebit - reportItem.GenerateCredit);
              // }
              // if (/^642|635|623|641/.test(reportItem.Account)) {
              //   summaryReportData.Cost += reportItem.GenerateDebit;
              // }
              if (/^131/.test(reportItem.Account)) {
                summaryReportData.GenerateCustomerReceivableDebtDebit += reportItem.GenerateDebit;
                summaryReportData.GenerateCustomerReceivableDebtCredit += reportItem.GenerateCredit;
              };
              if (/^111|112/.test(reportItem.Account)) {
                summaryReportData.Cash += reportItem.GenerateDebit - reportItem.GenerateCredit;
              };
            }

            self.$setState({
              // Summary data
              summaryReportData: {
                Cash: $this.rootServices.currencyPipe.transform(summaryReportData.Cash, 'VND'),
                Revenues: $this.rootServices.currencyPipe.transform(summaryReportData.Revenues, 'VND'),
                NumOfVouchers: summaryReportData.NumOfVouchers,
                DecreaseRevenuesByReturns: $this.rootServices.currencyPipe.transform(summaryReportData.DecreaseRevenuesByReturns, 'VND'),
                DecreaseRevenuesByDiscount: $this.rootServices.currencyPipe.transform(summaryReportData.DecreaseRevenuesByDiscount, 'VND'),
                CostOfGoodsSold: $this.rootServices.currencyPipe.transform(summaryReportData.CostOfGoodsSold, 'VND'),
                GenerateCustomerReceivableDebtDebit: $this.rootServices.currencyPipe.transform(summaryReportData.GenerateCustomerReceivableDebtDebit, 'VND'),
                GenerateCustomerReceivableDebtCredit: $this.rootServices.currencyPipe.transform(summaryReportData.GenerateCustomerReceivableDebtCredit, 'VND'),
                GrossProfit: $this.rootServices.currencyPipe.transform(summaryReportData.Revenues - summaryReportData.DecreaseRevenuesByReturns - summaryReportData.DecreaseRevenuesByDiscount - summaryReportData.CostOfGoodsSold, 'VND')
              },
            });



            /** Load data for sales activetity */
            let revenueStatistics = await $this.rootServices.apiService.getPromise<any[]>('/accounting/statistics', { eq_Account: "[511,512,515]", statisticsRevenue: true, branch: pages, reportBy: reportType, ge_VoucherDate: fromDate, le_VoucherDate: toDate, limit: 'nolimit', entryGroup: 'COMMERCEPOS' });
            let decreaseRevenueByReturnStatistics = await $this.rootServices.apiService.getPromise<any[]>('/accounting/statistics', { eq_Account: "[5213]", statisticsRevenue: true, branch: pages, reportBy: reportType, ge_VoucherDate: fromDate, le_VoucherDate: toDate, limit: 'nolimit', entryGroup: 'COMMERCEPOS' });
            let decreaseRevenueByDistcountStatistics = await $this.rootServices.apiService.getPromise<any[]>('/accounting/statistics', { eq_Account: "[5212]", statisticsRevenue: true, branch: pages, reportBy: reportType, ge_VoucherDate: fromDate, le_VoucherDate: toDate, limit: 'nolimit', entryGroup: 'COMMERCEPOS' });
            // let costStatistics632 = await $this.rootServices.apiService.getPromise<any[]>('/accounting/statistics', { eq_Account: "[632]", statisticsCost: true, branch: pages, reportBy: reportType, ge_VoucherDate: fromDate, le_VoucherDate: toDate, limit: 'nolimit', entryGroup: 'COMMERCEPOS' });

            /** Prepare data */
            let line1Data = revenueStatistics.map(statistic => { statistic.Label = $this.makeStaticLabel(statistic, reportType); statistic.Timeline = $this.makeTimeline(statistic, reportType); statistic.Value = statistic.SumOfCredit - statistic.SumOfDebit; return statistic; });
            let line2Data = decreaseRevenueByReturnStatistics.map(statistic => { statistic.Label = $this.makeStaticLabel(statistic, reportType); statistic.Timeline = $this.makeTimeline(statistic, reportType); statistic.Value = statistic.SumOfDebit - statistic.SumOfCredit; return statistic; });
            let line3Data = decreaseRevenueByDistcountStatistics.map(statistic => { statistic.Label = $this.makeStaticLabel(statistic, reportType); statistic.Timeline = $this.makeTimeline(statistic, reportType); statistic.Value = statistic.SumOfDebit - statistic.SumOfCredit; return statistic; });
            // let line3Data = costStatistics641.map(statistic => { statistic.Label = $this.makeStaticLabel(statistic, reportType); statistic.Timeline = $this.makeTimeline(statistic, reportType); statistic.Value = statistic.SumOfDebit - statistic.SumOfCredit; return statistic; });
            let timeline = [...new Set([
              ...line1Data.map(item => item['Timeline']),
              ...line2Data.map(item => item['Timeline']),
              ...line3Data.map(item => item['Timeline']),
              // ...line3Data.map(item => item['Timeline']),
            ].sort())];

            let labels = [];
            let mergeData: any;

            mergeData = timeline.map(t => {
              const point1 = line1Data.find(f => f.Timeline == t);
              const point2 = line2Data.find(f => f.Timeline == t);
              const point3 = line3Data.find(f => f.Timeline == t);
              labels.push(point1?.Label || point2?.Label);
              // labels.push(point1?.Label);
              return {
                Label: t,
                Line1: point1 || { Value: 0 },
                Line2: point2 || { Value: 0 },
                Line3: point3 || { Value: 0 },
              };
            });

            self.costAndRevenueStatisticsData = {
              labels: labels,
              datasets: [
                {
                  label: 'Doanh thu',
                  data: mergeData.map(point => point.Line1['Value']),
                  borderColor: $this.rootServices.themeColors.default.succuess,
                  backgroundColor: $this.rootServices.themeColors.default.succuess,
                  pointRadius: 1,
                  pointHoverRadius: 1,
                },
                {
                  label: 'Trả hàng',
                  data: mergeData.map(point => point.Line2['Value']),
                  borderColor: $this.rootServices.themeColors.default.danger,
                  backgroundColor: $this.rootServices.themeColors.default.danger,
                  pointRadius: 1,
                  pointHoverRadius: 1,
                },
                {
                  label: 'Giảm giá',
                  data: mergeData.map(point => point.Line2['Value']),
                  borderColor: $this.rootServices.themeColors.default.primary,
                  backgroundColor: $this.rootServices.themeColors.default.primary,
                  pointRadius: 1,
                  pointHoverRadius: 1,
                },
              ],
            };

            if (!self.posActivityChart) {
              self.posActivityChart = self.posActivityChart || new Chart('posActivityChart', {
                type: 'bar',
                data: self.costAndRevenueStatisticsData as any,
                options: {
                  scales: {
                    // y: {
                    //   beginAtZero: true
                    // }
                  }
                }
              });
            } else {
              self.posActivityChart.data = self.costAndRevenueStatisticsData;
              self.posActivityChart.update('active');
            }
            // End

            /** Load data for numOfVouchersChart */
            revenueStatistics = await $this.rootServices.apiService.getPromise<any[]>('/accounting/statistics', { eq_Account: "[511,512,515]", statisticsRevenue: true, branch: pages, reportBy: reportType, ge_VoucherDate: fromDate, le_VoucherDate: toDate, limit: 'nolimit', entryGroup: 'COMMERCEPOS' });
            let numOfVouchersLineData = revenueStatistics.map(statistic => { statistic.Label = $this.makeStaticLabel(statistic, reportType); statistic.Timeline = $this.makeTimeline(statistic, reportType); statistic.Value = statistic.NumOfVouchers; return statistic; });

            labels = [];
            mergeData = timeline.map(t => {
              const point1 = numOfVouchersLineData.find(f => f.Timeline == t);
              labels.push(point1?.Label);
              return {
                Label: t,
                Line1: point1 || { Value: 0 },
              };
            });

            self.numOfVouchersStatisticsData = {
              labels: labels,
              datasets: [
                {
                  label: 'Lượt khách',
                  data: mergeData.map(point => point.Line1['Value']),
                  borderColor: $this.rootServices.themeColors.default.succuess,
                  backgroundColor: $this.rootServices.themeColors.default.succuess,
                  pointRadius: 1,
                  pointHoverRadius: 1,
                },
              ],
            };

            if (!self.posNumOfVouchersChart) {
              self.posNumOfVouchersChart = self.posNumOfVouchersChart || new Chart('posNumOfVouchersChart', {
                type: 'bar',
                data: self.numOfVouchersStatisticsData as any,
                options: {
                  scales: {
                    // y: {
                    //   beginAtZero: true
                    // }
                  }
                }
              });
            } else {
              self.posNumOfVouchersChart.data = self.numOfVouchersStatisticsData;
              self.posNumOfVouchersChart.update('active');
            }
            // End

            // Load statistic data for debt
            /** Load data */
            let debtStatistics = await $this.rootServices.apiService.getPromise<any[]>('/accounting/statistics', { eq_Account: "[131]", increment: true, branch: pages, reportBy: reportType, ge_VoucherDate: fromDate, le_VoucherDate: toDate, limit: 'nolimit', entryGroup: 'COMMERCEPOS' });

            /** Prepare data */
            line1Data = debtStatistics.map(statistic => { statistic.Label = $this.makeStaticLabel(statistic, reportType); statistic.Timeline = $this.makeTimeline(statistic, reportType); statistic.Value = statistic.SumOfDebit - statistic.SumOfCredit; return statistic; });
            // let line2Data = costStatistics632.map(statistic => { statistic.Label = $this.makeStaticLabel(statistic, reportType); statistic.Timeline = $this.makeTimeline(statistic, reportType); statistic.Value = statistic.SumOfDebit - statistic.SumOfCredit; return statistic; });
            // let line3Data = costStatistics641.map(statistic => { statistic.Label = $this.makeStaticLabel(statistic, reportType); statistic.Timeline = $this.makeTimeline(statistic, reportType); statistic.Value = statistic.SumOfDebit - statistic.SumOfCredit; return statistic; });
            timeline = [...new Set([
              ...line1Data.map(item => item['Timeline']),
              // ...line2Data.map(item => item['Timeline']),
              // ...line3Data.map(item => item['Timeline']),
            ].sort())];

            labels = [];

            mergeData = timeline.map(t => {
              const point1 = line1Data.find(f => f.Timeline == t);
              // const point2 = line2Data.find(f => f.Timeline == t);
              // const point3 = line3Data.find(f => f.Timeline == t);
              // labels.push(point1?.Label || point2?.Label);
              labels.push(point1?.Label);
              return {
                Label: t,
                Line1: point1 || { Value: 0 },
                // Line2: point2 || { Value: 0 },
                // Line3: point3 || { Value: 0 },
              };
            });

            self.debtStatisticsData = {
              labels: labels,
              datasets: [
                {
                  label: 'Công nợ',
                  data: mergeData.map(point => point.Line1['Value']),
                  borderColor: $this.rootServices.themeColors.default.danger,
                  backgroundColor: $this.rootServices.themeColors.dim10.danger,
                  pointRadius: 1,
                  pointHoverRadius: 1,
                  fill: true,
                },
              ],
            };

            if (!self.posDebtChart) {
              self.posDebtChart = self.posDebtChart || new Chart('posDebtChart', {
                type: 'line',
                data: self.debtStatisticsData as any,
                options: {
                  scales: {
                    // y: {
                    //   beginAtZero: true
                    // }
                  }
                }
              });
            } else {
              self.posDebtChart.data = self.debtStatisticsData;
              self.posDebtChart.update('active');
            }

            // Report top
            let topEmployeeList = [];
            let topCustomerList = [];
            let topGoodsList = [];
            await $this.rootServices.apiService.getPromise<any>('/accounting/reports', { reportNetRevenusFromEmployee: true, branch: pages, fromDate: fromDate, toDate: toDate, sort_CreditGenerate: 'desc', limit: 100, entryGroup: 'COMMERCEPOS' }).then(rs => {
              topEmployeeList = rs.map(item => {
                item.Revenus = $this.rootServices.currencyPipe.transform((item.GenerateCredit - item.GenerateDebit) || 0, 'VND');
                return item;
              });
              console.log(rs);
            });
            await $this.rootServices.apiService.getPromise<any>('/accounting/reports', { reportNetRevenusFromCustomer: true, branch: pages, fromDate: fromDate, toDate: toDate, sort_CreditGenerate: 'desc', limit: 100, entryGroup: 'COMMERCEPOS' }).then(rs => {
              topCustomerList = rs.map(item => {
                item.Revenus = $this.rootServices.currencyPipe.transform((item.GenerateCredit - item.GenerateDebit) || 0, 'VND');
                return item;
              });
              console.log(rs);
            });
            await $this.rootServices.apiService.getPromise<any>('/accounting/reports', { reportNetRevenusFromGoods: true, branch: pages, fromDate: fromDate, toDate: toDate, sort_CreditGenerate: 'desc', limit: 100, entryGroup: 'COMMERCEPOS' }).then(rs => {
              topGoodsList = rs.map(item => {
                item.Revenus = $this.rootServices.currencyPipe.transform((item.GenerateCredit - item.GenerateDebit) || 0, 'VND');
                return item;
              });
              console.log(rs);
            });

            self.$setState({
              // Summary data
              // summaryReportData: {
              //   Cash: $this.rootServices.currencyPipe.transform(summaryReportData.Cash, 'VND'),
              //   Revenues: $this.rootServices.currencyPipe.transform(summaryReportData.Revenues, 'VND'),
              //   DecreaseRevenuesByReturns: $this.rootServices.currencyPipe.transform(summaryReportData.DecreaseRevenuesByReturns, 'VND'),
              //   DecreaseRevenuesByDiscount: $this.rootServices.currencyPipe.transform(summaryReportData.DecreaseRevenuesByDiscount, 'VND'),
              //   CustomerReceivableDebt: $this.rootServices.currencyPipe.transform(summaryReportData.CustomerReceivableDebt, 'VND'),
              // },
              // Top data
              topEmployeeList: topEmployeeList,
              topCustomerList: topCustomerList,
              topGoodsList: topGoodsList,
            });
            done && done();
            return true;
          },
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          pageInit(e, page: F7Page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;
            // Loading flag
            let allowInfinite = true;
            $this.onComponentInit({ instance: self }, 'main', null, page).then(currentState => {
              // self.refresh();
            });

          },
          // pageBeforeIn(e, page) {
          //     console.log('page before in');
          //     const self: F7ComponentContextExtend = this;
          //     self.refresh();
          // },
          pageAfterIn(e, page) {
            console.log('page after in');
            const self: F7ComponentContextExtend = this;
            const currentState = $this.state[self.$route.params.id];
            if (e['detail']?.from == 'previous') {
              self.refresh();
            }
          },
          // pageBeforeOut(e, page) {
          //     console.log('page before out');
          // },
          // pageAfterOut(e, page) {
          //     console.log('page after out');
          // },
          // pageBeforeUnmount(e, page) {
          //     console.log('page before unmount');
          // },
          // pageBeforeRemove(e, page) {
          //     console.log('page before remove');
          // },
          pageBeforeRemove(e, page) {
            console.log('[page event] before remove', page.route.url);
            const self: F7ComponentContextExtend = this;
            // const starmtSelectRoles = self.$app.smartSelect.get('.smart-select');
            // starmtSelectRoles.destroy();
          },
        },
      },
    };
  }
}
