import { Router } from "framework7/build/core/modules/router/router";
import { BaseListComponent, F7ListComponentContext, ListComponentStateExtend } from "../../../lib/base-list-component";
import { ProductModel } from "../../../model/product.model";
import { CommonService } from "../../../services/common.service";
import { RootServices } from "../../../services/root.services";
import { F7Page } from "../../../types/framework7-types";
import { ContactGroupModel } from "../../../model/contact.model";
import { Model } from "../../../model/model";
import { Template7 } from "framework7/build/core/framework7.esm.bundle";
import { CollaboratorOpportunityModel } from "src/app/model/collaborator.model";
import { Web } from "sip.js";

declare const $: any;

// declare const $: any;
export interface ComponentStateExtend extends ListComponentStateExtend<Model> {
  [key: string]: any;
  instance?: F7ComponentContextExtend;
}

export interface F7ComponentContextExtend extends F7ListComponentContext<Model> {
  responseInProgress?: boolean;
  listItems?: ProductModel[];
  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string } };
}

/** Component group manager */
export class MailClientInboxComponent extends BaseListComponent<ComponentStateExtend, Model> {

  namespace: string = 'mail-client-inbox';
  path = '/mail-client/inbox';
  title = 'Inbox';
  apiPath = '/mail-client/emails';
  idKey = 'uuid';
  limit = 20;

  filterSchema = {
    domainId: {
      type: 'smart-select',
      dataType: 'single'
    },
  };

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
  ) {
    super(rootServices, commonService);

    this.voucherStateMap['INQUEUE'] = {
      text: 'hàng đợi',
      color: 'color-orange',
      icon: 'square_stack_3d_down_right_fill'
    };
  }

  onF7pageRemove(chatRoomId: string) {
  }

  async onComponentInit(state: ComponentStateExtend, index: string, asCase?: string, f7Page?: F7Page) {

    // Load static data
    state.instance.pbxDomainList = await this.rootServices.apiService.getPromise<ContactGroupModel[]>('/ivoip/pbxs', { silent: true, includeDomains: true, limit: 'nolimit' }).then(rs => {
      console.log(rs);
      const domains = [];
      for (const pbx of rs) {
        for (const domain of pbx.Domains) {
          domain.id = domain.DomainUuid;
          domain.text = domain.Description;
          domains.push(domain);
        }
      }
      return domains;
    });
    state.filter = {
      domainId: state.instance.pbxDomainList[0].id
    };
    state.instance.$setState({
      groupList: state.instance.groupList,
    });

    return super.onComponentInit(state, index, asCase, f7Page).then(async currentState => {

      currentState.instance.$setState({
        pbxDomainList: state.instance.pbxDomainList,
      });


      //   const control = currentState.instance.$app.smartSelect.get(currentState.instance.$el.find('.smart-select[name="Page"]')[0] as any);
      //   await this.commonService.waitFor(100, 10, async () => {
      //     const element = $(currentState.instance.el).find('.filter [name="Page"]');
      //     if (element.length < 0) return false;
      //     await this.commonService.waiting(100);
      //     const field = state.instance.$app.smartSelect.get(element[0]);
      //     if (field) {
      //         field.setValue(state.instance.pbxDomainList[0].id);
      //         return true;
      //     }
      //     return false;
      // });

      return currentState;
    });
  }

  prepareItem(item: Model, index?: number) {
    // item.Title = this.commonService.getObjectText(item) || item.Name;
    // item.Subtitle = 'Chăm sóc CTV: ' + this.commonService.getObjectText(item.Manager);
    // if (!item.Avatar) {
    //   if (item.Publisher) {
    //     item.Avatar = 'https://appsv1.probox.vn/v3/user/users/' + item.Publisher + '/avatar';
    //   }
    // }
    return item;
  }

  async getList(self?: F7ComponentContextExtend, extParams?: any): Promise<Model[]> {
    const $this = this;
    const filter = $this.parseFilter($this.currentState?.filter);
    extParams.domainId = filter['eq_domainId'];
    return this.rootServices.apiService.getPromise<Model[]>(this.apiPath, {
      ...filter,
      ...extParams,
    }).then(list => {
      return list;
    }).catch(err => {
      console.error(err);
      this.commonService.showError(err);
      return Promise.reject(err);
    });
  }

  itemBeforeInsert(element: HTMLElement, item: Model): void {
    const $this = this;
    $(element).find('.click2call').click(() => {
      // this.click2call(item.Id);
      // $this.rootServices.iab.create('tel:' + item.Phone, '_system');
      $this.rootServices.copyTextToClipboard(item.Phone);
      return false;
    });
  }


  // Helper function to get an HTML audio element
  getAudioElement(id: string): HTMLAudioElement {
    const el = document.getElementById(id);
    if (!(el instanceof HTMLAudioElement)) {
      throw new Error(`Element "${id}" not found or not an audio element.`);
    }
    return el;
  }

  async onItemClick(self: F7ComponentContextExtend, item: Model) {
    const id = item[this.idKey];
    const $this = this;




    item.Recording = this.rootServices.apiService.buildApiUrl('/ivoip/recordings/' + item.Id, { play_audio: 1, domainId: item.DomainUuid + '@' + item.Pbx });

    let template = Template7.compile(/*html*/`
      <br>
      <audio controls style="width: 100%">
        <source src="{{Recording}}" type="audio/mp3" />
      </audio>
      <div class="list media-list" style="margin-left: -1rem; margin-right: -1rem; margin-bottom: 0rem; margin-top: 0.5rem; text-align: left;">
        <ul>
          <li data-id="{{id this}}">
            <a href="#" class="link item-content">
              <div class="avatar">
                  <div style="background-image: url({{image CallerNumberAvatar}})"></div>
              </div>
              {{#js_if "this.Direction == 'inbound' || this.Direction == 'local'"}}
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title">{{js "this.CallerNumberName ? (this.CallerNumberName + ' - ') : ''"}}{{CallerNumber}}</div>
                  <div class="item-after">{{moment Start coalesce="--"}}</div>
                </div>
                <div class="item-title-row">
                  <div class="item-subtitle">{{CallerNumber}}</div>
                  <div class="item-after"><img src="assets/icon/fusionpbx/icon_cdr_{{Direction}}_{{CallResult}}.png"></div>
                  <div class="item-text" style="font-size: 0.6rem">{{js "this.DestinationNumberName ? (this.DestinationNumberName + ' - ') : ''"}}{{DestinationNumber}}</div>
                </div>
              </div>
              {{/js_if}}
              {{#js_if "this.Direction == 'outbound'"}}
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title">{{js "this.DestinationNumberName ? (this.DestinationNumberName + ' - ') : ''"}}{{DestinationNumber}}</div>
                  <div class="item-after">{{moment Start coalesce="--"}}</div>
                </div>
                <div class="item-title-row">
                  <div class="item-subtitle">{{DestinationNumber}}</div>
                  <div class="item-after"><img src="assets/icon/fusionpbx/icon_cdr_{{Direction}}_{{CallResult}}.png"></div>
                  <div class="item-text" style="font-size: 0.6rem">{{js "this.CallerNumberName ? (this.CallerNumberName + ' - ') : ''"}}{{CallerNumber}}</div>
              </div>
              </div>
              {{/js_if}}
            </a>
          </li>
        </ul>
      </div>
      `);

    let contact = null;
    let vocuher = new CollaboratorOpportunityModel();
    if (item.Direction == 'inbound') {
      contact = { id: item.CallerNumberCode, text: item.CallerNumberName };
      vocuher.CustomerPhone = item.CallerNumber;
      vocuher.CustomerName = item.CallerNumberName;
    } else if (item.Direction == 'outbound') {
      contact = { id: item.DestinationNumberCode, text: item.DestinationNumberName };
      vocuher.CustomerPhone = item.DestinationNumber;
      vocuher.CustomerName = item.DestinationNumberName;
    }
    vocuher.Customer = contact;
    vocuher.Title = 'Cơ hội từ nhật ký cuộc gọi ' + vocuher.CustomerPhone + (vocuher.ObjectName ? (' từ ' + vocuher.ObjectName + ' ') : '') + new Date(item.Start).toUTCString();


    let outsideNumber = vocuher.CustomerPhone;
    if (item.Direction == 'local') {
      outsideNumber = item.DestinationNumber;
    }


    const dialog = $this.currentState.instance.$app.dialog.create({
      title: 'Hành động',
      // content: '<br>',
      content: template(item),
      verticalButtons: true,
      buttons: [
        {
          text: 'Tạo cơ hội bán hàng',
          bold: false,
          color: 'blue',
          onClick: () => {

            $this.rootServices.navigate('/collaborator/opportunity/new', {
              context: {
                backTitle: $this.title,
                data: vocuher,
                callback: (data: CollaboratorOpportunityModel, state: any) => {
                  console.log('Callback data: ', data);

                }
              }
            });

          }
        },
        {
          text: 'Tạo Ticket',
          bold: false,
          color: 'orange',
          onClick: () => {

          }
        },
        {
          text: 'Tạo thông tin',
          bold: false,
          color: 'yellow',
          onClick: () => {

          }
        },
        {
          text: 'Gọi lại',
          bold: false,
          color: 'red',
          onClick: async () => {
            // Demo sip

            const phoneExt = await $this.rootServices.apiService.getPromise<{ Extension: string, Host: string, Port: string, Domain: string, Password: string, Transport: string, DisplayName: string; DomainUuid: string }[]>('/user/users/getPhoneExtension', {}).then(rs => rs[0]);
            console.log(phoneExt);
            if (!phoneExt) {
              $this.commonService.showError('Bạn chưa cấu hình SIP');
              return;
            }

            let simpleUser: Web.SimpleUser = null;
            const callSceen = $this.currentState.instance.$app.dialog.create({
              title: 'Gọi điện',
              content: `<br><audio controls style="width: 100%" id="remoteAudio"></audio><br><br>`,
              // content: template(item),
              verticalButtons: true,
              buttons: [
                {
                  text: 'Gác máy',
                  bold: false,
                  color: 'blue',
                  onClick: () => {

                    if (simpleUser) {
                      simpleUser.hangup();
                      simpleUser.disconnect();
                    }

                  }
                },
              ]
            });
            callSceen.open();

            $this.rootServices.waitFor(300, 1000, () => callSceen.$el.find('#remoteAudio').length > 0).then(_ => {
              // Helper function to get an HTML audio element
              function getAudioElement(id: string): HTMLAudioElement {
                const el = document.getElementById(id);
                if (!(el instanceof HTMLAudioElement)) {
                  throw new Error(`Element "${id}" not found or not an audio element.`);
                }
                return el;
              }

              // Options for SimpleUser
              const options: Web.SimpleUserOptions = {
                aor: 'sip:' + phoneExt.Extension + '@' + phoneExt.Domain, // caller
                userAgentOptions: {
                  authorizationUsername: phoneExt.Extension,
                  authorizationPassword: phoneExt.Password,
                },
                media: {
                  constraints: { audio: true, video: false }, // audio only call
                  remote: { audio: getAudioElement("remoteAudio") } // play remote audio
                },
                delegate: {
                  /**
                   * Called when a call is answered.
                   * @remarks
                   * Callback for handling establishment of a new Session.
                   */
                  onCallAnswered() {
                    $this.commonService.showInfo('Đã bắt máy');
                  },
                  /**
                   * Called when a call is created.
                   * @remarks
                   * Callback for handling the creation of a new Session.
                   */
                  onCallCreated() {
                    $this.commonService.showInfo('Khởi tạo phiên');
                  },
                  /**
                   * Called when a call is received.
                   * @remarks
                   * Callback for handling incoming INVITE requests.
                   * The callback must either accept or reject the incoming call by calling `answer()` or `decline()` respectively.
                   */
                  onCallReceived() {
                    $this.commonService.showInfo('Đã nhận cuộc gọi');
                  },
                  /**
                   * Called when a call is hung up.
                   * @remarks
                   * Callback for handling termination of a Session.
                   */
                  onCallHangup() {
                    $this.commonService.showInfo('Gác máy');
                    callSceen.close();
                  },
                  /**
                   * Called when a call is put on hold or taken off hold.
                   * @remarks
                   * Callback for handling re-INVITE responses.
                   */
                  onCallHold(held: boolean) {
                    $this.commonService.showInfo('Giữ máy');
                  },
                  /**
                   * Called when a call receives an incoming DTMF tone.
                   * @remarks
                   * Callback for handling an incoming INFO request with content type application/dtmf-relay.
                   */
                  onCallDTMFReceived(tone: string, duration: number) {
                    $this.commonService.showInfo('Nhận DTMF');
                  },
                  /**
                   * Called upon receiving a message.
                   * @remarks
                   * Callback for handling incoming MESSAGE requests.
                   * @param message - The message received.
                   */
                  onMessageReceived(message: string) {
                    $this.commonService.showInfo(message);
                  },
                  /**
                   * Called when user is registered to received calls.
                   */
                  onRegistered() {
                    $this.commonService.showInfo('Đã đăng ký');
                  },
                  /**
                   * Called when user is no longer registered to received calls.
                   */
                  onUnregistered() {
                    $this.commonService.showInfo('Hủy đăng ký');
                  },
                  /**
                   * Called when user is connected to server.
                   * @remarks
                   * Callback for handling user becomes connected.
                   */
                  onServerConnect() {
                    $this.commonService.showInfo('Kết nối máy chủ');
                  },
                  /**
                   * Called when user is no longer connected.
                   * @remarks
                   * Callback for handling user becomes disconnected.
                   *
                   * @param error - An Error if server caused the disconnect. Otherwise undefined.
                   */
                  onServerDisconnect(error?: Error) {
                    $this.commonService.showInfo('Ngắt kết nối máy chủ');
                  },
                }
              };

              // WebSocket server to connect with
              const server = (phoneExt.Transport || 'wss') + '://' + phoneExt.Host + ':' + phoneExt.Port;

              // Construct a SimpleUser instance
              simpleUser = new Web.SimpleUser(server, options);

              // Connect to server and place call
              simpleUser.connect()
                .then((status) => simpleUser.call(`sip:${outsideNumber}@${phoneExt.Domain}`, {
                  earlyMedia: true,
                }))
                .catch((error: Error) => {
                  // Call failed
                });
            });
          }
        },
        {
          text: 'Trở về',
          bold: true,
          color: 'gray'
        },
      ],
    });
    dialog.open();
  }


  click2call(id) {
    const $this = this;
    const self: F7ComponentContextExtend = this.currentState.instance;
    // const id = self.$(e.target).closest('li').data('id') || self.$(e.target).data('id');

    self.$app.dialog.create({
      title: 'Click2Call',
      text: 'Tôi sẽ gọi tới số nội bộ của bạn trước, nếu offline tôi sẽ gọi qua số cá nhân, hãy kiểm tra app GS wave đã online chưa hoặc bạn đang ở kế bên điện thoại của mình !',
      buttons: [
        {
          text: 'Đóng',
          // color: 'red',
          onClick() {
          },
        },
        {
          text: 'Gọi',
          color: 'red',
          onClick() {
            $this.commonService.showInfo('Đang gọi tới số của bạn...', { timeout: 30000 });
            $this.rootServices.apiService.putPromise('/collaborator/publishers/' + id, { click2call: true }, [{ Id: id }]).then(rs => {
              console.log(rs);
              $this.commonService.showInfo('Đang chuyển cuộc gọi...', { timeout: 30000 });
            }).catch(err => {
              console.error(err);
              $this.commonService.showError('Lỗi kết nối cuộc gọi');
            });
          },
        }
      ]
    }).open();
  }

  itemHeight = 86;
  itemTemplate = /*html*/`
    <li class="swipeout" data-id="{{Id}}" style="height: ${this.itemHeight}px; overflow: hidden">
      <a href="#" class="link item-content swipeout-content">
        <div class="item-media">
          <div class="avatar">
              <div style="background-image: url({{image CallerNumberAvatar}})"></div>
          </div>
        </div>
        <div class="item-inner">
          <div class="item-title-row">
            <div class="item-title">{{objecttext this 'Sender.Name' '--'}}</div>
            <div class="item-after">{{moment Date coalesce="--"}}</div>
          </div>
          <div class="item-title-row">
            <div class="item-subtitle">{{objecttext this 'Sender.Email' '--'}}</div>
          </div>
          <div class="item-text">{{Subject}}</div>
        </div>
      </a>
      <div class="swipeout-actions-right">
      <a href="#" class="click2call color-red swipeout-overswipe">Copy SĐT</a>
    </div>
    </li>
  `;

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: this.namespace,
      path: this.path,
      component: {
        template: /*html*/`
            <div class="page no-toolbar-x ${$this.namespace}" data-name="${$this.namespace}">
              <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a class="link back {{textColorClass}}">
                      <i class="icon icon-back"></i>
                      <span class="if-not-md {{textColorClass}}">{{js "this.backTitle || 'Back'"}}</span>
                    </a>
                  </div>
                  <div class="title">${this.title}</div>
                  <div class="right">
                    <!-- Link to enable searchbar -->
                    <a class="link icon-only searchbar-enable {{textColorClass}}" data-searchbar=".${$this.namespace}-searchbar">
                      <i class="icon f7-icons if-not-md">search</i>
                      <i class="icon material-icons md-only">search</i>
                    </a>
                  </div>
                  <!-- Searchbar is a direct child of "navbar-inner" -->
                  <form class="searchbar ${$this.namespace}-searchbar searchbar-expandable">
                    <div class="searchbar-inner">
                      <div class="searchbar-input-wrap">
                        <input type="search" placeholder="Search" />
                        <i class="searchbar-icon"></i><span class="input-clear-button"></span>
                        
                      </div>
                      <span class="searchbar-disable-button {{textColorClass}}">Hủy</span>
                    </div>
                  </form>
                </div>
              </div>
              <div class="page-content ptr-content infinite-scroll-content" @ptr:refresh="refresh">
                <div class="ptr-preloader">
                  <div class="preloader"></div>
                  <div class="ptr-arrow"></div>
                </div>
                <div class="searchbar-backdrop ${$this.namespace}-backdrop"></div>

                <!--<div class="block block-strong inset">
                  <audio controls style="width: 100%" id="remoteAudio"></audio>
                </div>-->
            
                <div class="block-title" style="margin-top: 2rem">Bộ lọc <a style="float: right" @click="resetFilter"
                class="text-color-red">đặt lại</a></div>
                <div class="block block-strong inset filter" style="padding: 0; overflow: hidden">
                  <div class="list accordion-list inline-labels no-hairlines" style="margin: 0;">
                    <ul>
                      <li class="item-link smart-select smart-select-init" name="domainId" data-open-in="popup" data-searchbar="false" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFilterFieldChange">
                        <select>
                          <option value="">--</option>
                          {{#each pbxDomainList}}
                          <option value="{{id}}">{{text}}</option>
                          {{/each}}
                        </select>
                        <div class="item-content">
                          <div class="item-inner">
                            <div class="item-title">Account</div> 
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="block-title">Danh sách ${this.title}
                  <!--<a class="button button-small button-fill tab-link" @click="openContact" style="float: right; font-size: 0.7rem; font-weight: bold">+ Mới</a>-->
                </div>
                <div class="virtual-list list media-list ${$this.namespace}-virtual-list inset list-complex-infox">
                  
                </div>
                {{#if infiniteLoadLast}}
                    <div style="text-align: center" class="text-color-gray">end</div>
                {{else}}
                    <div class="preloader color-blue infinite-scroll-preloader">
                        <span class="preloader-inner">
                            <span class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span>
                        </span>
                    </div>
                {{/if}}
            
                <div class="block"><br></div>
            
              </div>
            </div>
        `,
        style: /*css*/`
          .${$this.namespace} {
            --f7-list-item-text-max-lines: 1;
          }
          .${$this.namespace} .find-order {
            font-size: 16px;
            font-weight: bold;
          }
          .${$this.namespace} .action-bar {
            color: var(--f7-block-strong-text-color);
            padding-top: var(--f7-block-padding-vertical);
            padding-bottom: var(--f7-block-padding-vertical);
            background-color: var(--f7-block-strong-bg-color);
          }
          .${$this.namespace} .virtual-list i.f7-icons {
            font-size: 16px;
          }
          .${$this.namespace} .list-complex-info ul li .item-title .item-title-text {
            -webkit-line-clamp: 1;
          }
          .${$this.namespace} .list-complex-info ul li .item-title .item-title-text {
            -webkit-line-clamp: 1;
          }
          .${$this.namespace} .list-complex-info ul li .item-title {
            height: 18px;
          }


          .page.${this.namespace} .item-text.probox-link {
            display: flex;
            align-items: center;
          }
          .page.${this.namespace} .item-text.probox-link img {
            width: 1.1rem;
            height: 1.1rem;
            margin-right: 0.25rem;
          }
          .page.${this.namespace} .media-list .item-media i.icon {
            width: 44px; height: 44px; 
            font-size: 28px;
            padding: 6px;
            color: gray;
          }
          .page.${this.namespace} .media-list .item-media, 
          .page.${this.namespace} li.media-item .item-media {
            align-self: center;
          }
          .page.${this.namespace} .list-complex-info ul li .item-title {
            height: 1.6rem;
          }
          .page.${this.namespace} .${this.namespace}-virtual-list .item-media div.avatar {
              border-radius: 50%;
              overflow: hidden;
              width: 3rem;
              height: 3rem;
              background-image: url(assets/icon/contact-icon.png);
              background-repeat: no-repeat; 
              background-size: cover;
              background-position: center;
          }
          .page.${this.namespace} .${this.namespace}-virtual-list .item-media div.avatar div {
              width: 3rem;
              height: 3rem;
              background-repeat: no-repeat; 
              background-size: cover;
              background-position: center;
          }
        `,
        data() {
          return {
            title: $this.title,
            // reminder: {
            //   No: '234234',
            // },
            priceReport: { Details: [] },
            filter: {},
            processMap: $this.rootServices.collaboratorService.processMap,
          };
        },
        methods: {
          reportCurrentFilterToPdf() {
            $this.rootServices.iab.create($this.rootServices.apiService.buildApiUrl($this.apiPath, {
              includeCreator: true,
              includeRelativeVouchers: true,
              sort_DateOfOrder: 'desc',
              ...$this.parseFilter($this.currentState?.filter),
              type: 'pdf'
            }) as string, '_system');
          },
          resetFilter(e) {
            $this.resetFilter(this, e);
          },
          onFilterFieldChange(e) {
            $this.onFilterFieldChange(this, e);
          },
          // Price report
          openContact(e, id?: string) {
            const self: F7ComponentContextExtend = this;
            id = id || self.$(e.target).closest('li').data('id') || 'new';

            // if ($this.commonService.frameSocket.isFrameMode && id && id !== 'new') {
            //   $this.commonService.frameSocket.emit('request-open-voucher', { id: id, type: 'PRICEREPORT' });
            // } else {
            $this.rootServices.navigate('/contact/contact-form/' + id, {
              context: {
                backTitle: $this.title,
                textColorClass: self.textColorClass,
                // memberInfo: $this.currentState.chatRoom.memberList$.getValue().find(f => f.id === id),
                // chatRoom: self.$route.context.chatRoom,
                // reminderList$: self.reminderList$,
                callback: (data: Model, state: any) => {
                  console.log('Callback data: ', data);
                  if (state?.lastAction == 'CREATE_SUCCESS') {
                    self.refresh();
                  }
                }
              }
            });
            // }
          },
          goto(e) {
            const self: F7ComponentContextExtend = this;
            const url = self.$(e.target).closest('.goto').data('url');
            $this.rootServices.navigate(url);
          },
          chooseOne(e) {
            const self: F7ComponentContextExtend = this;
            const id = self.$(e.target).closest('li').data('id');
            self.$route.context['onChoose'] && self.$route.context['onChoose'](self.listItems.find(f => f.Code === id));
          },
          chooseProduct(e) {

          },
          async refresh(e, done) {
            const self: F7ComponentContextExtend = this;
            $this.refresh(self).then(rs => done && done());
            return true;
          },
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          pageInit(e, page: F7Page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;
            // Loading flag
            let allowInfinite = true;

            $this.onComponentInit({ instance: self }, 'main', null, page);
          },
          // pageBeforeIn(e, page) {
          //     console.log('page before in');
          //     const self: F7ComponentContextExtend = this;
          //     self.refresh();
          // },
          // pageAfterIn(e, page) {
          //     console.log('page after in');
          // },
          // pageBeforeOut(e, page) {
          //     console.log('page before out');
          // },
          pageAfterOut(e, page) {
            console.log('page after out');
          },
          // pageBeforeUnmount(e, page) {
          //     console.log('page before unmount');
          // },
          // pageBeforeRemove(e, page) {
          //     console.log('page before remove');
          // },
          pageBeforeRemove(e, page) {
            console.log('[page event] before remove', page.route.url);
            const self: F7ComponentContextExtend = this;
            // const starmtSelectRoles = self.$app.smartSelect.get('.smart-select');
            // starmtSelectRoles.destroy();
          },
        },
      },
    };
  }
}
