import { Router } from "framework7/build/core/modules/router/router";
import { F7ListComponentContext, ListComponentStateExtend } from "../../../lib/base-list-component";
import { ChatRoom } from "../../../lib/nam-chat/chat-room";
import { ProductModel } from "../../../model/product.model";
import { CommonService } from "../../../services/common.service";
import { RootServices } from "../../../services/root.services";
import { F7Page } from "../../../types/framework7-types";
import { ContactListComponent } from "../../contact/contact-list";
import { ContactGroupModel, ContactModel } from "../../../model/contact.model";


declare const $: any;

// declare const $: any;
export interface ComponentStateExtend extends ListComponentStateExtend<ContactModel> {
  [key: string]: any;
  instance?: F7ComponentContextExtend;
}

export interface F7ComponentContextExtend extends F7ListComponentContext<ContactModel> {
  responseInProgress?: boolean;
  listItems?: ProductModel[];
  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, chatRoom?: ChatRoom } };
}

/** Component group manager */
export class CollaboratorCustomerListComponent extends ContactListComponent {

  namespace: string = 'collaborator-customer-list';
  path = '/collaborator/customer-list';
  title = 'Khách hàng';
  apiPath = '/collaborator/contacts';
  idKey = 'Code';
  limit = 20;

  filterSchema = {
    Groups: {
      type: 'smart-select',
      dataType: 'multiple'
    },
  };

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
  ) {
    super(rootServices, commonService);
    // // console.log('Click here to open ts file');

    this.voucherStateMap['INQUEUE'] = {
      text: 'hàng đợi',
      color: 'color-orange',
      icon: 'square_stack_3d_down_right_fill'
    };
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  async onComponentInit(state: ComponentStateExtend, index: string, asCase?: string, f7Page?: F7Page) {

    // Load static data
    state.instance.groupList = await this.rootServices.apiService.getPromise<ContactGroupModel[]>('/contact/groups', { includeIdText: true, limit: 'nolimit' });
    state.instance.$setState({
      groupList: state.instance.groupList,
    });

    return super.onComponentInit(state, index, asCase, f7Page).then(currentState => {
      return currentState;
    });
  }

  prepareItem(item: ContactModel, index?: number) {
    if (!item.Avatar) {
      if (item.PublisherRefId) {
        item.Avatar = 'https://appsv1.probox.vn/v3/user/users/' + item.PublisherRefId + '/avatar';
      }
    }
    return super.prepareItem(item, index);
  }

  async getList(self?: F7ComponentContextExtend, extParams?: any): Promise<ContactModel[]> {
    extParams['eq_Groups'] = '[CUSTOMER]';
    extParams['includePublisherRefId'] = true;
    return super.getList(self, extParams);
  }

  itemBeforeInsert(element: HTMLElement, item: ContactModel): void {
    const $this = this;
    return super.itemBeforeInsert(element, item);
  }

  // downloadPdf(item: ContactModel) {
  //   this.rootServices.iab.create(this.rootServices.apiService.buildApiUrl(this.apiPath, { id: item.Code, includeObject: true, includeContact: true, includeDetails: true, includeUnit: true, renderPdf: 'download' }) as string, '_system');
  // }

  async onItemClick(self: F7ComponentContextExtend, item: ContactModel) {
    // const self: F7ComponentContextExtend = this;
    // id = id || self.$(e.target).closest('li').data('id') || 'new';
    const id = item[this.idKey];

    this.rootServices.navigate('/collaborator/customer-form/' + id, {
      context: {
        backTitle: this.title,
        textColorClass: self.textColorClass,
        callback: (data: ContactModel, state: any) => {
          console.log('Callback data: ', data);
          // this.refresh();
          if (state?.lastAction == 'UPDATE_SUCCESS') {
            if (data?.Code) {
              const index = self.virtualList.items.findIndex(f => f.Code == item.Code);
              if (index > -1) {
                self.virtualList.replaceItem(index, this.prepareItem(data));
              }
            }
          }
        }
      }
    });
  }

  itemHeight = 70;
  itemTemplate = /*html*/`
    <li class="swipeout" data-id="{{Id}}" style="height: ${this.itemHeight}px; overflow: hidden">
      <a href="#" class="item-link item-content swipeout-content">
        <div class="item-media">
          <div class="avatar">
            <div style="background-image: url({{image Avatar}})"></div>
          </div>
        </div>
        <div class="item-inner">
          <div class="item-title-row">
            <div class="item-title">{{Name}}</div>
          </div>
          {{#if PublisherRefId}}
          <div class="item-text probox-link text-color-default"><img src="assets/icon/probox.ico"> ProBox.vn/{{PublisherRefId}}</div>
          {{else}}
          <div class="item-text">{{text Groups}}</div>
          {{/if}}
          <!--<div class="item-text">{{Subtitle}}</div>-->
        </div>
      </a>
      <div class="swipeout-actions-right">
      <a href="#" class="click2call color-red swipeout-overswipe">Copy SĐT</a>
    </div>
    </li>
  `;

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: this.namespace,
      path: this.path,
      component: {
        template: /*html*/`
            <div class="page no-toolbar-x ${$this.namespace}" data-name="${$this.namespace}">
              <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a class="link back {{textColorClass}}">
                      <i class="icon icon-back"></i>
                      <span class="if-not-md {{textColorClass}}">{{js "this.backTitle || 'Back'"}}</span>
                    </a>
                  </div>
                  <div class="title">${this.title}</div>
                  <div class="right">
                    <!-- Link to enable searchbar -->
                    <a class="link icon-only searchbar-enable {{textColorClass}}" data-searchbar=".${$this.namespace}-searchbar">
                      <i class="icon f7-icons if-not-md">search</i>
                      <i class="icon material-icons md-only">search</i>
                    </a>
                  </div>
                  <!-- Searchbar is a direct child of "navbar-inner" -->
                  <form class="searchbar ${$this.namespace}-searchbar searchbar-expandable">
                    <div class="searchbar-inner">
                      <div class="searchbar-input-wrap">
                        <input type="search" placeholder="Search" />
                        <i class="searchbar-icon"></i><span class="input-clear-button"></span>
                        
                      </div>
                      <span class="searchbar-disable-button {{textColorClass}}">Hủy</span>
                    </div>
                  </form>
                </div>
              </div>
              <div class="page-content ptr-content infinite-scroll-content" @ptr:refresh="refresh">
                <div class="ptr-preloader">
                  <div class="preloader"></div>
                  <div class="ptr-arrow"></div>
                </div>
                <div class="searchbar-backdrop ${$this.namespace}-backdrop"></div>
            
                <!--
                <div class="block-title" style="margin-top: 2rem">Bộ lọc <a style="float: right" @click="resetFilter"
                class="text-color-red">đặt lại</a></div>
                <div class="block block-strong inset filter" style="padding: 0; overflow: hidden">
                  <div class="list accordion-list inline-labels no-hairlines" style="margin: 0;">
                    <ul>
                      <li class="item-link smart-select smart-select-init" name="Groups" data-open-in="popup" data-searchbar="false" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFilterFieldChange">
                        <select multiple>
                          <option value="">--</option>
                          {{#each groupList}}
                          <option value="{{id}}">{{text}}</option>
                          {{/each}}
                        </select>
                        <div class="item-content">
                          <div class="item-inner">
                            <div class="item-title">Nhóm</div> 
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                -->

                <div class="block-title">Danh sách ${this.title}
                  <!--<a class="button button-small button-fill tab-link" @click="openContact" style="float: right; font-size: 0.7rem; font-weight: bold">+ Mới</a>-->
                </div>
                <div class="virtual-list list media-list ${$this.namespace}-virtual-list inset list-complex-info">
                  
                </div>
                {{#if infiniteLoadLast}}
                    <div style="text-align: center" class="text-color-gray">end</div>
                {{else}}
                    <div class="preloader color-blue infinite-scroll-preloader">
                        <span class="preloader-inner">
                            <span class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span>
                        </span>
                    </div>
                {{/if}}
            
                <div class="block"><br></div>
            
              </div>
            </div>
        `,
        style: /*css*/`
          .${$this.namespace} .find-order {
            font-size: 16px;
            font-weight: bold;
          }
          .${$this.namespace} .action-bar {
            color: var(--f7-block-strong-text-color);
            padding-top: var(--f7-block-padding-vertical);
            padding-bottom: var(--f7-block-padding-vertical);
            background-color: var(--f7-block-strong-bg-color);
          }
          .${$this.namespace} .virtual-list i.f7-icons {
            font-size: 16px;
          }
          .${$this.namespace} .list-complex-info ul li .item-title .item-title-text {
            -webkit-line-clamp: 1;
          }
          .${$this.namespace} .list-complex-info ul li .item-title .item-title-text {
            -webkit-line-clamp: 1;
          }
          .${$this.namespace} .list-complex-info ul li .item-title {
            height: 18px;
          }


          .page.${this.namespace} .item-text.probox-link {
            display: flex;
            align-items: center;
          }
          .page.${this.namespace} .item-text.probox-link img {
            width: 1.1rem;
            height: 1.1rem;
            margin-right: 0.25rem;
          }
          .page.${this.namespace} .media-list .item-media, 
          .page.${this.namespace} li.media-item .item-media {
            align-self: center;
          }
          .page.${this.namespace} .list-complex-info ul li .item-title {
            height: 1.6rem;
          }
          .page.${this.namespace} .${this.namespace}-virtual-list .item-media div.avatar {
              border-radius: 50%;
              overflow: hidden;
              width: 3rem;
              height: 3rem;
              background-image: url(assets/icon/contact-icon.png);
              background-repeat: no-repeat; 
              background-size: cover;
              background-position: center;
          }
          .page.${this.namespace} .${this.namespace}-virtual-list .item-media div.avatar div {
              width: 3rem;
              height: 3rem;
              background-repeat: no-repeat; 
              background-size: cover;
              background-position: center;
          }
        `,
        data() {
          return {
            title: $this.title,
            // reminder: {
            //   No: '234234',
            // },
            priceReport: { Details: [] },
            filter: {},
            processMap: $this.rootServices.collaboratorService.processMap,
          };
        },
        methods: {
          reportCurrentFilterToPdf() {
            $this.rootServices.iab.create($this.rootServices.apiService.buildApiUrl($this.apiPath, {
              includeCreator: true,
              includeRelativeVouchers: true,
              sort_DateOfOrder: 'desc',
              ...$this.parseFilter($this.currentState?.filter),
              type: 'pdf'
            }) as string, '_system');
          },
          resetFilter(e) {
            $this.resetFilter(this, e);
          },
          onFilterFieldChange(e) {
            $this.onFilterFieldChange(this, e);
          },
          // Price report
          openContact(e, id?: string) {
            const self: F7ComponentContextExtend = this;
            id = id || self.$(e.target).closest('li').data('id') || 'new';

            // if ($this.commonService.frameSocket.isFrameMode && id && id !== 'new') {
            //   $this.commonService.frameSocket.emit('request-open-voucher', { id: id, type: 'PRICEREPORT' });
            // } else {
            $this.rootServices.navigate('/contact/contact-form/' + id, {
              context: {
                backTitle: $this.title,
                textColorClass: self.textColorClass,
                // memberInfo: $this.currentState.chatRoom.memberList$.getValue().find(f => f.id === id),
                // chatRoom: self.$route.context.chatRoom,
                // reminderList$: self.reminderList$,
                callback: (data: ContactModel, state: any) => {
                  console.log('Callback data: ', data);
                  if (state?.lastAction == 'CREATE_SUCCESS') {
                    self.refresh();
                  }
                }
              }
            });
            // }
          },
          goto(e) {
            const self: F7ComponentContextExtend = this;
            const url = self.$(e.target).closest('.goto').data('url');
            $this.rootServices.navigate(url);
          },
          chooseOne(e) {
            const self: F7ComponentContextExtend = this;
            const id = self.$(e.target).closest('li').data('id');
            self.$route.context['onChoose'] && self.$route.context['onChoose'](self.listItems.find(f => f.Code === id));
          },
          chooseProduct(e) {

          },
          async refresh(e, done) {
            const self: F7ComponentContextExtend = this;
            $this.refresh(self).then(rs => done && done());
            return true;
          },
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          pageInit(e, page: F7Page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;
            // Loading flag
            let allowInfinite = true;

            $this.onComponentInit({ instance: self }, 'main', null, page);
          },
          // pageBeforeIn(e, page) {
          //     console.log('page before in');
          //     const self: F7ComponentContextExtend = this;
          //     self.refresh();
          // },
          // pageAfterIn(e, page) {
          //     console.log('page after in');
          // },
          // pageBeforeOut(e, page) {
          //     console.log('page before out');
          // },
          pageAfterOut(e, page) {
            console.log('page after out');
          },
          // pageBeforeUnmount(e, page) {
          //     console.log('page before unmount');
          // },
          // pageBeforeRemove(e, page) {
          //     console.log('page before remove');
          // },
          pageBeforeRemove(e, page) {
            console.log('[page event] before remove', page.route.url);
            const self: F7ComponentContextExtend = this;
            // const starmtSelectRoles = self.$app.smartSelect.get('.smart-select');
            // starmtSelectRoles.destroy();
          },
        },
      },
    };
  }
}
