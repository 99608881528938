import { environment } from '../../../src/environments/environment';
import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { take, switchMap, catchError } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AccountModel } from '../model/account.model';
import { RecentLoginModel } from '../model/recent-login.model';
import { CommonService } from './common.service';
import { JWTToken } from '../lib/nam-socket/my-socket';
import { NotificationSettings } from '../lib/nam-chat/chat-room';
// import { this.env } from 'src/this.envs/this.env';

const helper = new JwtHelperService();
const TOKEN_KEY = 'jwt-token';


export interface JwtToken {
    access_token?: string;
    refresh_token?: string;
    api_url?: string;
    core?: string,
    user?: string,
}

export class AuthToken {
    constructor(token: JwtToken) {
        this.payload = token;
    }

    protected payload?: JwtToken;
    protected accessToken?: string;
    protected refreshToken?: string;
    // abstract getValue(): string;
    isValid(): boolean {
        return !this.payload || !this.payload.access_token || !helper.isTokenExpired(this.payload.access_token);
    }
    // abstract getOwnerStrategyName(): string;
    // abstract getCreatedAt(): Date;
    // abstract toString(): string;
    // getName(): string;
    getPayload() {
        return this.payload;
    }
}

export interface AuthUser {
    [key: string]: any;
    id: string;
    username?: string;
    name: string;
    phone: string;
    email: string;
    address: string;
    tagName: string;
    shortName?: string;
    title?: string;
    role?: string;
    avatar?: {
        id?: string,
        payload?: {
            thumbnail?: string,
            url?: string,
        }
    };
    core?: {
        domain?: string,
        name?: string,
        banner?: string;
        backgroundBannerColor?: string;
    },
    settings?: {
        NOTIFICATION_SETTINGS?: NotificationSettings,
    },
}

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    public userObs: Observable<any>;
    public userData = new BehaviorSubject(null);
    public user$ = new BehaviorSubject<AuthUser>(null);

    token$ = new BehaviorSubject<AuthToken>(null);
    tokenRefresh$ = new BehaviorSubject<AuthToken>(null);
    public loginState$ = new BehaviorSubject<boolean>(null);
    public switchAccount$ = new BehaviorSubject<RecentLoginModel>(null);

    env = environment;

    constructor(
        private storage: Storage,
        private http: HttpClient,
        private plt: Platform,
        private router: Router,
        private commonService: CommonService,
    ) {
        this.loadStoredToken();
    }

    async loadStoredToken() {
        return this.plt.ready().then(() => {
            console.log('on platform ready');
            return this.storage.get(TOKEN_KEY).then(async (token: JwtToken) => {
                if (token) {
                    this.token$.next(new AuthToken(token));
                    await this.loadUserInfoByToken(token);
                    this.loginState$.next(true);
                }
                return true;
            });
        });
        // const platformObs = from(this.plt.ready());

        // this.userObs = platformObs.pipe(
        //     switchMap(() => {
        //         return from(this.storage.get(TOKEN_KEY));
        //     }),
        //     map(async (token: JwtToken) => {
        //         if (token) {
        //             this.token$.next(new AuthToken(token));
        //             await this.loadUserInfoByToken(token);
        //             this.loginState$.next(true);
        //             // const decoded: string = helper.decodeToken(token.access_token);
        //             // // this.userData.next(decoded);
        //             // if (decoded) {
        //             //     const userCode = decoded.split('/')[0];
        //             //     if (userCode) {
        //             //         this.http.get(token.api_url + '/user/users/getInfo?token=' + token.access_token).pipe(
        //             //             take(1),
        //             //         ).subscribe((rs: { user: { Code: string, Name: string, Avatar: string } }) => {
        //             //             this.user$.next({ id: rs.user.Code, name: rs.user.Name, avatar: rs.user.Avatar });
        //             //         });
        //             //     }
        //             // }
        //             return true;
        //         } else {
        //             return null;
        //         }
        //     })
        // );
    }

    /** Load user info by token */
    async loadUserInfoByToken(token?: JwtToken) {
        if (!token) {
            token = this.token$.value.getPayload();
        }
        if (token) {
            return new Promise<AuthUser>((resolve, reject) => {
                try {
                    if (/\/v\d+/.test(token.api_url)) {
                        token.api_url = token.api_url.replace(/\/v\d+/, '');
                    }
                    this.http.get(token.api_url + '/' + this.env.api.version + '/user/users/getInfo?token=' + token.access_token).pipe(
                        take(1),
                    ).subscribe((rs: AccountModel) => {
                        const user: AuthUser = {
                            // ...rs,
                            id: rs.Code,
                            name: rs.Name,
                            phone: rs.Phone,
                            email: rs.Email,
                            address: rs.Address,
                            shortName: rs.ShortName,
                            username: rs.Username,
                            tagName: rs.TagName,
                            title: rs.Title,
                            avatar: rs.Avatar,
                            role: rs.Role,
                            core: {
                                domain: new URL(token.api_url)?.hostname,
                                name: rs.Core?.Name,
                                banner: rs.Core?.Banner,
                                backgroundBannerColor: rs.Core?.BackgroundBannerColor,
                            },
                        };
                        this.user$.next(user);
                        // this.loginState$.next(true);
                        resolve(user);
                    });
                } catch (e) {
                    reject(e);
                    console.error(e);
                }
            });
        }
        return null;
    }

    /** Login  */
    async login(credentials: { username: string, password: string, url: string, remember?: boolean }): Promise<AuthToken> {
        // Normally make a POST request to your APi with your login credentials
        // if (credentials.email !== 'saimon@devdactic.com' || credentials.pw !== '123') {
        //     return of(null);
        // }
        return new Promise<AuthToken>((resolve, reject) => {
            try {
                if (/\/v\d+/.test(credentials.url)) {
                    credentials.url = credentials.url.replace(/\/v\d+/, '');
                }
                this.http.post(credentials.url + '/' + this.env.api.version + '/user/login', { email: credentials.username, password: credentials.password, rememberMe: true }).pipe(
                    take(1),
                    catchError(err => {
                        reject(err);
                        return throwError(err);
                    })
                ).subscribe(async (rs: { token: JwtToken }) => {
                    const token = { ...rs.token, api_url: credentials.url };
                    const decoded = helper.decodeToken(token.access_token);
                    this.userData.next(decoded);
                    this.storage.set(TOKEN_KEY, token);
                    const authToken = new AuthToken(token);
                    this.token$.next(authToken);
                    this.loadUserInfoByToken(token).then(result => {
                        this.loginState$.next(true);
                        resolve(authToken);
                    }).catch(err => {
                        reject(err);
                    });
                });
            } catch (e) {
                reject(e);
            }
            // );
        });
    }

    /** Login  */
    async register(credentials: { url: string, name: string, phone: string, password: string, agent?: string, otpToken?: string, otp?: string }): Promise<any> {
        // Normally make a POST request to your APi with your login credentials
        // if (credentials.email !== 'saimon@devdactic.com' || credentials.pw !== '123') {
        //     return of(null);
        // }
        return new Promise<any>((resolve, reject) => {
            try {
                if (/\/v\d+/.test(credentials.url)) {
                    credentials.url = credentials.url.replace(/\/v\d+/, '');
                }
                let extendParams = '';
                if (credentials?.otpToken) extendParams += '&otpToken=' + credentials.otpToken;
                if (credentials?.otp) extendParams += '&otp=' + credentials.otp;
                this.http.post(credentials.url + '/' + this.env.api.version + '/user/register?register=true&agent=' + credentials.agent + extendParams, [{
                    Name: credentials.name,
                    Phone: credentials.phone,
                    Password: credentials.password
                }]).pipe(
                    take(1),
                    catchError(err => {
                        reject(err);
                        return throwError(err);
                    })
                ).subscribe(async (rs: { user: any }) => {
                    resolve(rs);
                });
            } catch (e) {
                reject(e);
            }
            // );
        });
    }

    // async loginByToken(token: JwtToken, username: string) {
    //     const recentLoginId = token.api_url + username;
    //     const recentLogins: RecentLoginModel[] = (await this.storage.get('recent_logins')) || [];
    //     let recentLogin: RecentLoginModel = recentLogins.find(f => f.id == recentLoginId.toLowerCase());
    //     if(!recentLogin) {
    //         recentLogin = {
    //             url: token.api_url,
    //             username: username,
    //             token: token,
    //             remember: true,
    //         };
    //         recentLogins.push(recentLogin);
    //         this.storage.set('recent_logins', recentLogins);
    //     }
    // }

    async restoreLogin(recentLoginId: string): Promise<AuthToken> {
        if (!recentLoginId) {
            throw new Error('recent login was not provided');
        }
        const recentLogins: RecentLoginModel[] = await this.storage.get('recent_logins');
        const recentLogin: RecentLoginModel = (recentLogins || []).find(f => f.id.toLocaleLowerCase() == recentLoginId.toLowerCase());
        if (!recentLogin) {
            throw new Error('recent login id was not exists');
        }

        try {
            const authToken = await this.refreshToken('email', null, { token: recentLogin.token }).pipe(take(1)).toPromise();
            // const authToken = new AuthToken(recentLogin.token);
            if (!authToken) {
                return Promise.reject('Refresh token failed');
            }
            this.storage.set(TOKEN_KEY, recentLogin.token);
            this.token$.next(authToken);

            await this.loadUserInfoByToken(recentLogin.token);
            this.loginState$.next(true);
            this.switchAccount$.next(recentLogin);
            return authToken;
        } catch (err) {
            console.error(err);
            // return false;
            return Promise.reject(err);
        }
    }

    async suspendLogin() {
        this.user$.next(null);
        this.loginState$.next(false);
        this.userData.next(null);
        this.loginState$.next(false);
        this.token$.next(null);
        return true;
    }

    /** Login state changed event */
    onLoginStateChange() {
        return this.loginState$;
    }

    /** Token change event */
    onTokenChange() {
        return this.token$;
    }

    /** Get token */
    async getToken(): Promise<AuthToken> {
        return this.token$.getValue() || new AuthToken(await this.storage.get(TOKEN_KEY));
    }

    /** Check authenticated by access token and refresh if expired */
    isAuthenticatedOrRefresh(): Observable<AuthToken> {
        return this.isAuthenticated().pipe(take(1), switchMap((isAuth) => {
            if (!isAuth) {
                return new Observable<AuthToken>(subscriber => {
                    this.storage.get(TOKEN_KEY).then((token: JwtToken) => {
                        if (!token) {
                            subscriber.next(null);
                        } else {
                            try {
                                this.refreshToken('email', token.api_url, { token }).subscribe(rs => {
                                    subscriber.next(rs);
                                    if (!this.loginState$.value) {
                                        this.loginState$.next(true);
                                    }
                                });
                            } catch (e) {
                                console.error(e);
                                subscriber.next(null);
                            }
                        }
                    });
                });

                // return this.refreshToken('email', )
            } else {
                if (!this.loginState$.value) {
                    this.loginState$.next(true);
                }
            }
            return of(this.token$.getValue());
        }));
    }

    /** Check authenticated by access token */
    isAuthenticated(): Observable<boolean> {
        return new Observable<boolean>(subscriber => {
            this.storage.get(TOKEN_KEY).then((token: JwtToken) => {
                if (!token) {
                    subscriber.next(false);
                } else {
                    const authToken = new AuthToken(token);
                    subscriber.next(authToken.isValid());
                }
            });
        });
    }

    /** Refresh token */
    refreshToken(sterage?: string, url?: string, params?: { token: JwtToken }): Observable<AuthToken> {
        const paramToken = (params && params.token && params.token.refresh_token)
            ? params.token :
            (this.token$.getValue() && this.token$.getValue().getPayload() && this.token$.getValue().getPayload().refresh_token
                ? this.token$.getValue().getPayload()
                : null);
        // url = url || paramToken.api_url;

        return new Observable<AuthToken>(subscriber => {


            (async (callback: (token: JwtToken) => void) => {
                if (!paramToken) {
                    callback(await this.storage.get(TOKEN_KEY));
                } else {
                    callback(paramToken);
                }
            })((currentToken) => {
                if (!currentToken) {
                    // return throwError('can not refresh token because refresh_token was null');
                    // throw new Error('can not refresh token because refresh_token was null');
                    throwError('can not refresh token because refresh_token was null');
                    return false;
                }
                // subscriber.next();
                if (this.commonService.isFrameMode) {
                    console.log('refresh token by parent windows');
                    this.commonService.frameSocket.emit<JWTToken>('refresh-token', {}).then(async token => {
                        const token2: JwtToken = { ...token, api_url: currentToken.api_url };
                        console.log('refresh token by parent windows,', token);
                        // const decoded = helper.decodeToken(token.access_token);
                        // this.userData.next(decoded);
                        this.storage.set(TOKEN_KEY, token2);
                        const authToken = new AuthToken(token2);
                        // await this.loadUserInfoByToken(token2);
                        this.token$.next(authToken);
                        subscriber.next(authToken);
                        this.tokenRefresh$.next(authToken);
                    });
                } else {
                    console.log('refresh token by self');
                    const reqToken = params && params.token && params.token || currentToken;
                    if (/\/v\d+/.test(currentToken.api_url)) {
                        currentToken.api_url = currentToken.api_url.replace(/\/v\d+/, '');
                    }

                    this.http.post(currentToken.api_url + '/' + this.env.api.version + '/user/login/refresh?token=' + reqToken.access_token, {
                        token: params && params.token && params.token || currentToken,
                    }).pipe(
                        take(1),
                        catchError(e => {
                            subscriber.next(null);
                            return throwError(e);
                        })
                    ).subscribe(async (rs: { token: JwtToken }) => {
                        const token2: JwtToken = { ...rs.token, api_url: currentToken.api_url };
                        // const decoded = helper.decodeToken(token.access_token);
                        // this.userData.next(decoded);
                        this.storage.set(TOKEN_KEY, token2);
                        const authToken = new AuthToken(token2);
                        // await this.loadUserInfoByToken(token2);
                        this.token$.next(authToken);
                        subscriber.next(authToken);
                        this.tokenRefresh$.next(authToken);
                    });
                }
            });


        });


        // return new Observable<{ isSuccess: boolean, getToken: () => AuthToken }>(subcription => {
        //     subcription.next({
        //         isSuccess: true,
        //         getToken: () => {
        //             return new AuthToken('', '');
        //         },
        //     });
        // });
    }

    /** Get user */
    getUser() {
        return this.user$.getValue();
    }

    /** Logout */
    async logout(jwtToken?: JwtToken): Promise<boolean> {
        let isNotCurrent = !!jwtToken;
        jwtToken = jwtToken || this.token$.getValue() && this.token$.getValue().getPayload();
        if (!jwtToken) {
            // throw new Error('JWT token was not set');
            return false;
        }
        try {
            if (/\/v\d+/.test(jwtToken.api_url)) {
                jwtToken.api_url = jwtToken.api_url.replace(/\/v\d+/, '');
            }
            const logoutRs: { data: boolean } = (await this.http.delete(jwtToken.api_url + '/' + this.env.api.version + '/user/login?token=' + jwtToken.access_token).pipe(take(1)).toPromise()) as any;
            if (!logoutRs || !logoutRs.data) {
                throw new Error('logout request error');
            }
        } catch (err) {
            if (err.status !== 401) {
                throw new Error(err);
            }
        }

        return this.storage.remove(TOKEN_KEY).then(() => {
            // this.router.navigateByUrl('/login');
            // window.location.href = '/login';
            if (isNotCurrent) {
                this.user$.next(null);
                this.loginState$.next(false);
                this.userData.next(null);
            }
            return true;
        }).catch(err => {
            console.error(err);
            return false;
        });
    }

    getRecentLoginId() {
        if (!this.token$?.value) return null;
        return this.token$?.value?.getPayload()?.core + this.token$?.value?.getPayload()?.user;
    }

}
