/* eslint-disable eol-last */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-trailing-spaces */
/* eslint-disable object-shorthand */
/* eslint-disable curly */
/* eslint-disable space-before-function-paren */
/* eslint-disable @typescript-eslint/member-delimiter-style */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable eqeqeq */
/* eslint-disable max-len */
/* eslint-disable quote-props */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/dot-notation */
import { CashVoucherDetailModel } from './../../../model/accounting.model';
import { CashVoucherModel, BusinessModel, AccBankAccountModel, AccountModel } from '../../../model/accounting.model';
import { take } from 'rxjs/operators';
import { Dialog } from "framework7/build/core/components/dialog/dialog";
import { Router } from "framework7/build/core/modules/router/router";
import { ContactModel } from "../../../../../src/app/model/contact.model";
import { SalesMasterPriceTable } from "../../../../../src/app/model/sales.model";
import { CommonService } from "../../../../../src/app/services/common.service";
import { RootServices } from "../../../../../src/app/services/root.services";
import { F7ComponentContext, F7Page } from "../../../../../src/app/types/framework7-types";
import { FileModel } from '../../../../../src/app/model/file.model';
import { BaseFormComponent, FormComponentStateExtend, FormSchema } from '../../../../../src/app/lib/base-form-component';
import { ProductModel, UnitModel } from '../../../../../src/app/model/product.model';
import { ChatRoomModel } from '../../../../../src/app/model/chat-room.model';
import { PurchaseOrderDetailModel } from '../../../../../src/app/model/purchase.model';
import { jsPDF } from "jspdf";
// import * as $ from 'jquery';
declare const $: any;

// declare const $: any;
export interface ComponentStateExtend extends FormComponentStateExtend<CashVoucherModel> {
  [key: string]: any;
  instance: F7ComponentContextExtend,
  data?: CashVoucherModel;
  form?: any;
}

export class F7ComponentContextExtend extends F7ComponentContext {
  responseInProgress?: boolean;

  detailDialog?: Dialog.Dialog;

  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, [key: string]: any } };

  businessList?: BusinessModel[];
  bankAccountList?: any[];
  accountList?: AccountModel[];
  debitAccountList?: AccountModel[];
  creditAccountList?: AccountModel[];
}

/** Component group manager */
export class AccReceiptVoucherFormComponent extends BaseFormComponent<ComponentStateExtend, CashVoucherModel> {
  // states: { [key: string]: State } = {};
  currentState: ComponentStateExtend;
  title: 'Phiếu thu';

  idKey = 'Code';

  apiPath = '/accounting/cash-vouchers';

  taxList = [
    {
      id: 'VAT10',
      text: '+ 10% VAT',
      Tax: 10,
      Type: 'NONE',
      Name: 'Chưa bao gồm VAT 10%',
      selected: false,
    },
    {
      id: 'FULLVAT10',
      text: 'Đã bao gồm VAT 10%',
      Tax: 0,
      Type: 'NONE',
      Name: 'Đã Full VAT 10%',
      selected: false,
    },
    {
      id: 'NOTAX',
      text: 'n/a',
      Tax: 0,
      Type: 'NONE',
      Name: 'Không thuế',
      selected: false,
    },
  ];

  unitList: UnitModel[] = [];
  priceTableList: SalesMasterPriceTable[] = [];

  contactList: ContactModel[] = [];

  schema: FormSchema = {
    Object: {
      type: 'smart-select',
      label: 'Liên hệ',
      validators: [
        'required'
      ]
    },
    ObjectName: {
      type: 'input',
      label: 'Tên',
      validators: [
        'required'
      ]

    },
    Object2: {
      type: 'autocomplete-contact',
      label: 'Liên hệ',
      validators: [
        'required'
      ]
    },
    Description: {
      type: 'input',
      label: 'Tiêu đề',
      validators: [
        // 'required'
      ]

    },
    BankAccount: {
      type: 'smart-select',
      label: 'Tài khoản ngân hàng',
      validators: [
        // 'required'
      ]
    },
    DateOfVoucher: {
      type: 'datepicker',
      label: 'Ngày chứng từ',
      dataType: 'single',
      validators: [
        // 'required'
      ]
    },
    Details: {
      type: 'list',
      makeModel: (properties: CashVoucherDetailModel) => {
        return new CashVoucherDetailModel(properties);
      },
      AccountingBusiness: {
        type: 'smart-select',
        label: 'Nghiệp vụ',
        validators: [
          // 'required'
        ]
      },
      DebitAccount: {
        type: 'smart-select',
        label: 'Tài khoản nợ',
        validators: [
          'required'
        ]
      },
      CreditAccount: {
        type: 'smart-select',
        label: 'Tài khoản có',
        validators: [
          'required'
        ]
      },
      Description: {
        type: 'string',
        label: 'Diễn giải',
        validators: [
          'required'
        ]
      },
      Amount: {
        type: 'inputmask',
        format: {
          mask: 'decimal', option: { radixPoint: ',', groupSeparator: '.' }
        },
        validators: [
          'required'
        ]
      },
    }
  };

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
  ) {
    super(rootServices, commonService);
    // // console.log('Click here to open ts file');
  }

  makeModel(properties?: CashVoucherModel): CashVoucherModel {
    return new CashVoucherModel({
      ...properties,
      Type: 'RECEIPT'
    });
  }

  async makeNewData() {
    return new CashVoucherModel();
  }

  getFormData(params?: any): Promise<CashVoucherModel> {
    return super.getFormData({
      includeObject: true,
      includeContact: true,
      includeDetails: true,
      includeRelativeVouchers: true,
      ...params,
    });
  }

  prepareData(data: CashVoucherModel): CashVoucherModel {
    for (const detail of data.Details) {

      // if (detail?.Unit && typeof detail?.Unit == 'string') {
      //   detail.Unit = this.unitList.find(f => f.id == detail.Unit as any);
      // }

      // if (!data.Pictures) {
      //   data.Pictures = [];
      // }
    };
    return data;
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  async onComponentInit(state: ComponentStateExtend, index: string, asCase?: string, f7Page?: F7Page) {
    const $this = this;

    // Start loading status
    state.instance.$setState({
      loading: true,
      schema: this.schema,
    });
    this.commonService.showPreloader();

    // Load Contact Member list
    if (state?.instance?.$route?.context?.chatRoom?.memberList$) {
      await state?.instance?.$route?.context?.chatRoom?.memberList$?.pipe(take(1)).toPromise().then(memberList => {
        state.instance.contactList = memberList.filter(f => f.Type == 'CONTACT').map(m => { m['text'] = m.Name; return m; }) as any;
        if (state.instance.contactList && state.instance.contactList.length > 0) {
          this.schema['Object2'].validators = [];
        } else {
          this.schema['Object'].validators = [];
        }
      });

    }

    // Get static data
    state.instance.businessList = await this.rootServices.apiService.getPromise<BusinessModel[]>('/accounting/business', { select: 'id=>Code,text=>Name,DebitAccount=>DebitAccount,CreditAccount=>CreditAccount,Name=>Name,Code=>Code', limit: 'nolimit', eq_Type: 'RECEIPT' });
    state.instance.bankAccountList = await this.rootServices.apiService.getPromise<AccBankAccountModel[]>('/accounting/bank-accounts', { limit: 'nolimit', select: "id=>Code,text=>CONCAT(Owner;'/';AccountNumber;'/';Bank;'/';Branch)" });
    state.instance.accountList = await this.rootServices.apiService.getPromise<AccountModel[]>('/accounting/accounts', { limit: 'nolimit', includeIdText: true }).then(rs => rs.map(m => {
      m.text = `${m.Code} - ${m.text}`;
      return m;
    }));
    state.instance.debitAccountList = state.instance.accountList.filter(f => f.Group == 'CASH' || f.Group == 'CASHINBANK');
    state.instance.creditAccountList = state.instance.accountList.filter(f => f.Group != 'CASH' && f.Group != 'CASHINBANK');
    // End

    // set static data
    state.instance.$setState({
      businessList: state.instance.businessList,
      bankAccountList: state.instance.bankAccountList,
      contactList: state.instance.contactList,
      accountList: state.instance.accountList,
      debitAccountList: state.instance.debitAccountList,
      creditAccountList: state.instance.creditAccountList,
    });

    const currentState: ComponentStateExtend = await super.onComponentInit(state, index, asCase) as any;

    // if (currentState.instance.$route?.context?.copyFromId) {
    //   currentState.instance.data.SubNote = 'Copy of ' + currentState.instance.$route?.context?.copyFromId + ': ' + (currentState.instance.data.SubNote ? ('<br>'+currentState.instance.data.SubNote) : '');
    //   this.setData(currentState.instance.data);
    // }

    // Prepare data
    // if (currentState.data) {
    //   // for (const detail of currentState.data?.Details) {
    //   //   detail.__unitList = detail.Product?.Units || [];
    //   //   detail.ToMoney = detail.Price * detail.Quantity;
    //   // }
    //   // currentState.data.Object2 = currentState.data.Object;
    //   // currentState.data.Contact2 = currentState.data.Contact;
    //   await this.setData(currentState.data, { prepareControl: true });
    //   // this.calculateTotal();
    // }
    // End Prepare data

    // Stop loading status
    if (currentState.instance?.$route?.params['id'] == 'new') {
      if (currentState.data.Details.length == 0) {
        await this.addItemForList('Details');
      }
    }

    if (currentState?.instance?.$route?.context?.relativeVouchers && currentState.instance.$route.context.relativeVouchers[0]?.text) {
      if (!currentState.data.Title) {
        currentState.data.Description = currentState.instance.$route.context.relativeVouchers[0]?.text;
        currentState.instance.$setState({ data: currentState.data });
        this.validate();
      }
    }

    currentState.instance.$setState({ loading: false });
    this.commonService.hidePreloader();
    return currentState;
  }

  async setData(data: CashVoucherModel, option?: { onlyList?: string[]; onlyKeys?: string[]; prepareControl?: boolean; }): Promise<boolean> {

    for (const detail of data?.Details) {
      // if (!detail.__unitList || detail.__unitList.length == 0) {
      //   detail.__unitList = detail.Product?.Units || [];
      // }
      // detail.ToMoney = detail.Price * detail.Quantity;
      data.Object2 = data.Object;
      data.Contact2 = data.Contact;
    }

    const total = this.calculateTotal(data);
    return super.setData(data, option).then(status => {
      this.currentState.instance.$setState({ total });
      return status;
    });
  }

  async addItemForList<CashVoucherDetailModel>(listName: string, item?: CashVoucherDetailModel): Promise<CashVoucherDetailModel> {
    return super.addItemForList(listName, item).then(newItem => {
      this.commonService.showInfo('Đã thêm chi tiết');
      return newItem;
    });
  }

  async onFieldChange(self: F7ComponentContext, e: any, ...args: any) {
    const currentState = this.currentState;
    return super.onFieldChange(self, e, args).then(async fieldInfo => {

      if (!fieldInfo) {
        return null;
      }

      let { field, fieldName, fieldValue, index, listName } = fieldInfo;
      let requireSetData = false;
      let requireSetDataAndUpdateControl = false;

      console.log('on field change', listName, index, fieldName, fieldValue);
      if (listName == 'Details' && fieldName == 'AccountingBusiness') {

        // Auto fill debit and credit account
        const business = currentState.instance.businessList.find(f => f.Code == fieldValue);
        if (business) {
          currentState.data[listName][index]['DebitAccount'] = currentState.instance.debitAccountList.find(f => f.Code == business.DebitAccount)?.Code;
          currentState.data[listName][index]['CreditAccount'] = currentState.instance.creditAccountList.find(f => f.Code == business.CreditAccount)?.Code;

          // if (!currentState.data[listName][index]['Description']) {
          //   currentState.data[listName][index]['Description'] = business.Name;
          // }

          requireSetData = true;
          requireSetDataAndUpdateControl = true;
        }
      }

      if ((fieldName == 'Object' || fieldName == 'Object2') && fieldValue && !currentState.instance.loading) {
        await this.rootServices.apiService.getPromise<ContactModel[]>('/contact/contacts/' + this.commonService.getObjectId(fieldValue), {}).then(contacts => contacts[0]).then(contact => {

          // Auto fill object
          currentState.data.Object = fieldValue;
          currentState.data.ObjectName = contact.Name;
          currentState.data.ObjectPhone = contact.Phone;
          currentState.data.ObjectEmail = contact.Email;
          currentState.data.ObjectAddress = contact.Address;
          requireSetData = true;
          requireSetDataAndUpdateControl = true;
          this.validate();
        });
      }

      if (requireSetData) {
        const total = this.calculateTotal();
        if (requireSetDataAndUpdateControl) {
          this.setData(currentState.data);
        } else {
          currentState.instance.$setState({
            data: currentState.data,
            total: total,
          });
        }
      } else {
        currentState.instance.$setState({ total: this.calculateTotal() });
      }

      return fieldInfo;
    });
  }

  async removeDetailItem(self: F7ComponentContext, e: any, ...args: any): Promise<{ detail?: any, listName?: string; index?: string | number; }> {
    const currenntState = this.currentState;
    return new Promise((resolve, reject) => {
      self.$app.dialog.confirm('Bạn có chắc là muốn gở chi tiết này ?', 'Gở chi tiết', () => {
        resolve(super.removeDetailItem(self, e, args).then(detailInfo => {
          let { detail, listName, index } = detailInfo;
          this.calculateTotal();
          return detailInfo;
        }).catch(err => {
          return Promise.reject(err);
        }));
      });
    });
  }

  calculateTotal(data?: CashVoucherModel) {
    const currentState = this.currentState;
    let total = 0;
    const calculateData = data || currentState.data;
    for (const detail of calculateData.Details) {
      // const toMoney = detail.ToMoney = (detail.Quantity || 0) * (detail.Price || 0);
      total += parseFloat(detail.Amount as any);
    }

    // currentState.instance.$setState({ total });
    return total;
  }

  async savePdf(self: F7ComponentContextExtend, e: any) {
    if (this.currentState.data.State !== 'APPROVED') {
      await this.save(self);
    }
    this.rootServices.iab.create(this.rootServices.apiService.buildApiUrl(this.apiPath, { id: this.currentState.data.Code, includeContact: true, includeDetails: true, includeUnit: true, renderPdf: 'download' }) as string, '_system');
  }
  async changeState(self: F7ComponentContextExtend, e: any) {
    const newState = this.currentState.data.State == 'APPROVED' ? 'UNRECORDED' : 'APPROVED';
    this.currentState.instance.$app.dialog.confirm('Bạn có muốn ' + (newState == 'APPROVED' ? 'duyệt' : 'bỏ duyệt') + ' phiếu báo giá `' + this.currentState.data.Description + '`', 'Thay đổi trạng thái báo giá', async () => {

      if (this.currentState.data.State !== 'APPROVED') {
        await this.save(self);
      }
      await this.rootServices.apiService.putPromise<CashVoucherModel[]>(this.apiPath, {
        changeState: newState,
      }, [{ Code: this.currentState.data.Code }]).then(rs => {
        if (newState == 'APPROVED') {
          this.commonService.showInfo('Đã duyệt phiếu đặt mua hangf !');
        } else {
          this.commonService.showInfo('Đã bỏ duyệt phiếu đặt mua hangf !');
        }
      });
      this.currentState.data.State = newState;
      this.setData(this.currentState.data);

    });
  }

  async save(self: F7ComponentContext, option?: { postParams?: any }) {
    if (!this.currentState.data?.Description) {
      this.currentState.data.Description = this.currentState.data?.Details[0]?.Description;
      this.currentState.instance.$setState({ data: this.currentState.data });
    }
    return super.save(self, option);
  }

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: 'acc-receipt-voucher-form',
      path: '/accounting/receipt-voucher-form/:id',
      component: {
        template: /*html*/`
            <div class="page page-form page-acc-receipt-voucher-form no-toolbar-x" data-name="acc-receipt-voucher-form">
              <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a class="link back {{textColorClass}}">
                      <i class="icon icon-back"></i>
                      <span class="if-not-md {{textColorClass}}">{{backTitle}}</span>
                    </a>
                  </div>
                  <div class="title">Phiếu thu</div>
                  <div class="right">
                    <a class="link icon-only {{textColorClass}}">
                      <i class="icon f7-icons">arrowshape_turn_up_right</i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="page-content ptr-content infinite-scroll-content" @ptr:refresh="refresh" data-ptr-distance="150">
                <div class="ptr-preloader">
                  <div class="preloader"></div>
                  <div class="ptr-arrow"></div>
                </div>

                <form class="data-form">
                  <div class="list profile-form no-hairlines-md inset main-form">
                    <ul>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label">ID phiếu (*)</div>
                          <div class="item-input-wrap">
                            <input @change="onFieldChange" disabled class="field text-color-blue" name="Code" type="text" placeholder="ID phiếu, auto..."
                              value="{{data.Code}}">
                          </div>
                        </div>
                      </li>
                      {{#if copyFromId}}
                      <li class="item-content">
                        <div class="item-inner">
                          <div class="item-input-wrap text-color-orange">
                            <span style="font-weight: bold; font-style: italic">Bạn đang thao tác trên phiếu copy</span>
                          </div>
                        </div>
                      </li>
                      {{/if}}
                    </ul>
                  </div>

                  <div class="block-title">Thông tin liên hệ</div>
                  <div class="list profile-form no-hairlines-md inset main-form">
                    <ul>
                      <li class="item-link smart-select smart-select-init smart-select-roles {{validate data.Object schema 'Object'}}" name="Object" style="{{js "this.contactList && this.contactList.length > 0 ? 'display: block' : 'display: none'"}}" data-open-in="popup" data-searchbar="true" data-virtual-list="true" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFieldChange">
                        <select>
                          <option value="">Chon...</option>
                          {{#each contactList}}
                          <option value="{{id}}">{{text}}</option>
                          {{/each}}
                        </select>
                        <div class="item-content">
                          <div class="item-inner">
                            <div class="item-title">Liên hệ</div>
                            <div class="item-after text-color-blue"></div>
                          </div>
                        </div>
                      </li>
                      <li class="autocomplete {{validate data.Object schema 'Object2'}}" name="Object2" style="{{js "!this.contactList || this.contactList.length == 0 ? 'display: block' : 'display: none'"}}">
                        <div class="item-link item-content" href="#">
                          <div class="item-inner">
                            <div class="item-title">Liên hệ</div>
                            <div class="item-after text-color-blue">{{js "this.data && this.data.Object && this.data.Object.text || 'Chọn...'"}}</div>
                          </div>
                        </div>
                      </li>

                      
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label {{validate data.ObjectName schema 'ObjectName'}}">Tên (*)</div>
                          <div class="item-input-wrap">
                            <input @keyup="onFieldChange" class="field text-color-blue" name="ObjectName" type="text" placeholder="Tên người gửi tiền..."
                              value="{{data.ObjectName}}">
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label">Số điện thoại</div>
                          <div class="item-input-wrap">
                            <input @keyup="onFieldChange" class="field text-color-blue" name="ObjectPhone" type="text" placeholder="SĐT người gửi tiền..."
                              value="{{data.ObjectPhone}}">
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label">Email</div>
                          <div class="item-input-wrap">
                            <input @keyup="onFieldChange" class="field text-color-blue" name="ObjectEmail" type="text" placeholder="Email người gửi tiền..."
                              value="{{data.ObjectEmail}}">
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label">Địa chỉ</div>
                          <div class="item-input-wrap">
                            <input @keyup="onFieldChange" class="field text-color-blue" name="ObjectAddress" type="text" placeholder="ĐC người gửi tiền..."
                              value="{{data.ObjectAddress}}">
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div class="block-title">Thông tin chung</div>
                  <div class="list profile-form no-hairlines-md inset main-form">
                    <ul>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label {{validate data.Description schema 'Description'}}">Mô tả</div>
                          <div class="item-input-wrap">
                            <input @keyup="onFieldChange" class="field text-color-blue" name="Description" type="text" placeholder="Mô tả..."
                              value="{{data.Description}}">
                          </div>
                        </div>
                      </li>
                      <li class="item-link smart-select smart-select-init smart-select-roles {{validate data.BankAccount schema 'BankAccount'}}" name="BankAccount" data-open-in="popup" data-searchbar="true" data-virtual-list="true" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFieldChange">
                        <select>
                          <option value="">Chon...</option>
                          {{#each bankAccountList}}
                          <option value="{{id}}">{{text}}</option>
                          {{/each}}
                        </select>
                        <div class="item-content">
                          <div class="item-inner">
                            <div class="item-title {{validate data.BankAccount schema 'BankAccount'}}">Tài khoản ngân hàng</div>
                            <div class="item-after text-color-blue"></div>
                          </div>
                        </div>
                      </li>
                      <li class="inline-labels item-link">
                        <div class="item-content item-input">
                          <div class="item-inner">
                            <div class="item-title item-label {{validate data.DateOfVoucher schema 'DateOfVoucher'}}">Ngày</div>
                            <div class="item-input-wrap">
                              <input type="text" class="calendar" name="DateOfVoucher" value="{{date data.DateOfVoucher format="short"}}" placeholder="Ngày thu" readonly="readonly" @change="onFieldChange" style="text-align: right;"/>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
              
                  <div class="block-title" style="margin-top: 0.5rem">Chi tiết<a @click="addDetail" data-list-name="Details" class="button button-small button-fill" style="float: right; font-size: 0.7rem; font-weight: bold">Thêm</a></div>
                  <div class="list-name thumbnail-list" name="Details">
                    <ul style="padding: 0;">
                    {{#each data.Details}}
                      <li class="swipeout block block-strong inset list-item index-{{@index}}" data-id="{{Uuid}}" data-index="{{@index}}">

                        <div class="swipeout-content list inline-labels no-hairlines" style="margin: 0">
                          <ul>
                            <li class="item-link smart-select smart-select-init" name="AccountingBusiness" data-open-in="popup" data-searchbar="true" data-virtual-list="true" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFieldChange" data-list-name="Details" data-index="{{@index}}">
                              <select>
                                <option value="">Chon...</option>
                                {{#each ../businessList}}
                                <option value="{{id}}">{{text}}</option>
                                {{/each}}
                              </select>
                              <div class="item-content" style="padding-left: calc(var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-left));">
                                <div class="item-inner">
                                  <div class="item-title {{validate AccountingBusiness ../schema 'Details.AccountingBusiness'}}">Nghiệp vụ</div>
                                  <div class="item-after text-color-blue"></div>
                                </div>
                              </div>
                            </li>
                            <li class="item-link smart-select smart-select-init" name="DebitAccount" data-open-in="popup" data-searchbar="true" data-virtual-list="true" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFieldChange" data-list-name="Details" data-index="{{@index}}">
                              <select>
                                <option value="">Chon...</option>
                                {{#each ../debitAccountList}}
                                <option value="{{id}}">{{text}}</option>
                                {{/each}}
                              </select>
                              <div class="item-content" style="padding-left: calc(var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-left));">
                                <div class="item-inner">
                                  <div class="item-title {{validate DebitAccount ../schema 'Details.DebitAccount'}}">TK.Nợ (*)</div>
                                  <div class="item-after text-color-blue"></div>
                                </div>
                              </div>
                            </li>
                            <li class="item-link smart-select smart-select-init" name="CreditAccount" data-open-in="popup" data-searchbar="true" data-virtual-list="true" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFieldChange" data-list-name="Details" data-index="{{@index}}">
                              <select>
                                <option value="">Chon...</option>
                                {{#each ../creditAccountList}}
                                <option value="{{id}}">{{text}}</option>
                                {{/each}}
                              </select>
                              <div class="item-content" style="padding-left: calc(var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-left));">
                                <div class="item-inner">
                                  <div class="item-title {{validate CreditAccount ../schema 'Details.CreditAccount'}}">TK.Có (*)</div>
                                  <div class="item-after text-color-blue"></div>
                                </div>
                              </div>
                            </li>
                            <li class="item-content item-input" style="padding-left: calc(var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-left));">
                              <div class="item-inner">
                                <div class="item-title item-label {{validate Description ../schema 'Details.Description'}}">Diễn giải (*)</div>
                                <div class="item-input-wrap" style="display: flex">
                                  <input type="text" class="field text-color-blue" style="text-align: right;" name="Description" value="{{Description}}" placeholder="Diễn giải" @keyup="onFieldChange">
                                </div>
                              </div>
                            </li>
                            <li class="item-content item-input" style="padding-left: calc(var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-left));">
                              <div class="item-inner">
                                <div class="item-title item-label {{validate Amount ../schema 'Details.Amount'}}">Số tiền (*)</div>
                                <div class="item-input-wrap" style="display: flex">
                                  <input type="text" class="inputmask field text-color-blue" name="Amount" value="{{decimal Amount}}" placeholder="Số tiền" @keyup="onFieldChange">
                                  <div class="currency-symbol text-color-blue" style="line-height: 44px">₫</div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <div class="swipeout-actions-right">
                            <a href="#" class="moreBtn open-more-actions color-red swipeout-close swipeout-overswipe" @click="removeDetailItem">Gở</a>
                        </div>
                      </li>
                      {{/each}}
                      </ul>
                  </div>
              
                  <div class="block block-strong inset" style="text-align: right; font-weight: bold; font-size: 1rem;">
                    Tổng cộng: <span class="text-color-blue">{{currency total}}</span>
                  </div>


                  <div class="block">
                    <p class="row">
                      <a class="col-50 button button-fill button-large color-blue {{#if validates}}disabled{{/if}}"  @click="save"> Lưu lại</a>
                      <a class="col-50 button button-fill button-large color-red {{#if validates}}disabled{{/if}}" @click="savePdf">Save PDF</a>
                    </p>
                    <p class="row">
                      <a class="col-50 button button-fill button-large color-{{js "this.data.State == 'APPROVED' ? 'orange' : 'green'"}} {{#if validates}}disabled{{/if}}" @click="changeState">{{js "this.data.State == 'APPROVED' ? 'Bỏ ghi' : 'Duyệt'"}}</a>
                      <a class="col-50 button button-fill button-large color-yellow {{#if validates}}disabled{{/if}}" @click="openRelativeTask">Mở task</a>
                    </p>
                  </div>

                  {{#if validates}}
                  <div class="block-title text-color-arange">Cảnh báo</div>
                  <div class="list inset">
                    <ul>
                      {{#each validates}}
                      <li>
                        <div class="item-content">
                          <div class="item-inner">
                            <div class="item-title">{{validates[0].label}}</div>
                            <div class="item-after text-color-orange">{{validates[0].text}}</div>
                          </div>
                        </div>
                      </li>
                      {{/each}}
                    </ul>
                  </div>
                  {{/if}}
                </form>
            
                <div class="block"><br></div>
              </div>
            </div>
        `,
        style: /*css*/`
          .page.acc-receipt-voucher-form .demo-card-header-pic .card-header {
            height: calc(100vw - 2rem);
            background-size: cover;
            background-position: center;
            color: #fff;
          }

          .page.acc-receipt-voucher-form .demo-card-header-pic .card-content-padding .date {
            color: #8e8e93;
          }
          .page.acc-receipt-voucher-form .pictures::-webkit-scrollbar {
            display: none;
          }

          .page.acc-receipt-voucher-form .card .background {
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-image: url(/assets/images/no-image-available.png);
          }

          .page.acc-receipt-voucher-form .inline-labels .item-label, .inline-label .item-label, .inline-labels .item-floating-label, .inline-label .item-floating-label {
            width: auto !important;
          }
        `,
        data() {
          return {
            title: $this.title,
            reminder: {
              No: '234234',
            },
            data: new CashVoucherModel(),
            taxList: $this.taxList,
            contactMemebets: [],
            // contactList: [],
            validates: null,
          };
        },
        methods: {
          changeState(e) {
            $this.changeState(this, e);
          },
          savePdf(e) {
            $this.savePdf(this, e);
          },
          openRelativeTask(e) {
            const self: F7ComponentContextExtend = this;
            const currentState = $this.currentState;
            const voucher = currentState.data;
            if (voucher) {
              const relativeTask = voucher.RelativeVouchers && voucher.RelativeVouchers.find(f => f.type == 'CHATROOM' || f.type == 'TASK') || null;
              if (relativeTask) {
                $this.rootServices.navigate('/chat-room/' + relativeTask.id);
              } else {
                if (!voucher.Code || !voucher.Description) {
                  $this.commonService.showError('Bạn phải lưu phiếu trước và phiếu phải có tiêu đề !', { position: 'bottom' });
                  return;
                }
                self.$app.dialog.create({
                  title: 'Tạo task',
                  content: 'Bạn muốn tạo task cho phiếu này hày liên kết với task sẵn có ?',
                  verticalButtons: true,
                  buttons: [
                    {
                      text: 'Tạo mới',
                      bold: false,
                      color: 'green',
                      onClick: () => {
                        self.$f7.dialog.prompt('Mô tả cho task mới', function (description) {
                          const chatRoomData = {
                            Type: 'WORKPLACE',
                            Description: description,
                            RelativeVouchers: [
                              {
                                id: voucher.Code,
                                text: voucher.Description,
                                type: 'RECEIPT'
                              }
                            ],
                          };
                          self.$app.preloader.show();
                          $this.rootServices.apiService.postPromise<ChatRoomModel[]>('/chat/rooms', {}, [chatRoomData]).then(async rs => {
                            // Update relative vouchers for current voucher
                            currentState.data.RelativeVouchers = await $this.rootServices.apiService.getPromise<CashVoucherModel[]>($this.apiPath + '/' + currentState.data.Code, { includeRelativeVouchers: true, eq_Type: 'RECEIPT' }).then(rs => rs[0].RelativeVouchers);
                            currentState.instance.$setState({ data: currentState.data });

                            const contactMembers = [];
                            if (voucher.Object) {
                              contactMembers.push({
                                Type: 'CONTACT',
                                id: $this.commonService.getObjectId(voucher.Object),
                                Name: voucher.ObjectName
                              });
                            }
                            if (voucher.Contact) {
                              contactMembers.push({
                                Type: 'CONTACT',
                                id: $this.commonService.getObjectId(voucher.Contact),
                                Name: voucher.ContactName
                              });
                            }

                            if (contactMembers.length > 0) {
                              await $this.rootServices.apiService.postPromise<ChatRoomModel[]>('/chat/room-members', { chatRoom: rs[0].Code }, contactMembers).catch(err => {
                                console.log('skip for can not add cotnact', err);
                                return false;
                              });
                            }

                            console.log(rs);
                            if (rs && rs[0] && rs[0].Code) {
                              $this.commonService.navigate('/chat-room/' + rs[0].Code, {
                                context: {
                                  // backTitle: 'Workplace',
                                  title: rs[0].Description,
                                }
                              });
                              self.$app.preloader.hide();
                            }
                          }).catch(err => {
                            console.error(err);
                            self.$app.preloader.hide();
                            $this.commonService.showError(err, { position: 'bottom' });
                          });
                        }, null, voucher.Description);
                      }
                    },
                    {
                      text: 'Task sẵn có',
                      bold: false,
                      color: 'blue',
                      onClick: () => {
                        $this.commonService.navigate('/workplace/choose-parent', {
                          context: {
                            backTitle: 'Chat GUI',
                            title: 'Chọn task',
                            extendComponentId: 'choose-for-' + voucher.Code,
                            callback: async (task: ChatRoomModel) => {
                              console.log('choosed task:', task);
                              self.$app.preloader.show();
                              const taskRelativeVouchers = await $this.rootServices.apiService.getPromise<ChatRoomModel[]>('/chat/rooms/' + task.Code, { includeRelativeVouchers: true }).then(rs => rs[0].RelativeVouchers);
                              $this.rootServices.apiService.putPromise<ChatRoomModel[]>('/chat/rooms/' + task.Code, {}, [{
                                Code: task.Code, 
                                RelativeVouchers: [
                                  ...(taskRelativeVouchers || []),
                                  {
                                    id: voucher.Code,
                                    text: voucher.Description,
                                    type: 'RECEIPT',
                                  }
                                ]
                              }]).then(async rs => {
                                
                                // Update relative vouchers for current voucher
                                currentState.data.RelativeVouchers = await $this.rootServices.apiService.getPromise<CashVoucherModel[]>($this.apiPath + '/' + currentState.data.Code, { includeRelativeVouchers: true, eq_Type: 'RECEIPT' }).then(rs => rs[0].RelativeVouchers);
                                currentState.instance.$setState({ data: currentState.data });

                                const contactMembers = [];
                                if (voucher.Object) {
                                  contactMembers.push({
                                    Type: 'CONTACT',
                                    id: $this.commonService.getObjectId(voucher.Object),
                                    Name: voucher.ObjectName
                                  });
                                }
                                if (voucher.Contact) {
                                  contactMembers.push({
                                    Type: 'CONTACT',
                                    id: $this.commonService.getObjectId(voucher.Contact),
                                    Name: voucher.ContactName
                                  });
                                }

                                // Add contacts to task
                                if (contactMembers.length > 0) {
                                  await $this.rootServices.apiService.postPromise<ChatRoomModel[]>('/chat/room-members', { chatRoom: task.Code }, contactMembers).catch(err => {
                                    console.log('skip for can not add cotnact', err);
                                    return false;
                                  });
                                }

                                setTimeout(() => {
                                  self.$app.preloader.hide();
                                  $this.commonService.navigate('/chat-room/' + task.Code, {
                                    context: {
                                      // backTitle: 'Workplace',
                                      title: voucher.Description,
                                    }
                                  });
                                }, 700);
                              }).catch(err => {
                                self.$app.preloader.hide();
                              });
                            },
                          }
                        });
                      }
                    },
                    {
                      text: 'Đóng',
                      bold: true,
                      color: 'red',
                      onClick: () => {

                      }
                    },
                  ]
                }).open();

              }
            }
          },
          previewPictures(e) {
            const self: F7ComponentContextExtend = this;
            const currenntState = $this.currentState;
            let index = $(e.target).closest('.list-item').data('index');
            $this.previewPictures(currenntState.data.Details[index].Image, 0);
          },
          setFeaturePicture(e) {
            const self: F7ComponentContextExtend = this;
            const currenntState = $this.currentState;
            let index = $(e.target).closest('a').data('index');
            if (typeof index != 'undefined') {
              self.$app.dialog.confirm('Bạn có muốn đặt làm hình đại diện không ?', 'Đặt hình đại diện', () => {
                currenntState.data.FeaturePicture = currenntState.data.Pictures[index];
                self.$setState({ data: currenntState.data });
              })
            }
          },
          // uploadPicture(e) {
          //   const self: F7ComponentContextExtend = this;
          //   const currenntState = $this.currentState;
          //   $this.updateImages((progress) => {
          //     console.log(progress);
          //   }, (image) => {
          //     currenntState.data.Pictures.unshift(image);
          //     currenntState.data.FeaturePicture = image;
          //     self.$setState({ data: currenntState.data });

          //   }).then(images => {
          //     console.log('upload pictures success', images);
          //   });
          // },
          addDetail(e) {
            const self: F7ComponentContextExtend = this;
            const currenntState = $this.currentState;

            // $this.chooseProduct(self);


            // let listName = $(e.target).data('list-name');
            $this.addItemForList('Details');

          },
          // chooseProduct(e) {
          //   const self: F7ComponentContextExtend = this;
          //   $this.chooseProduct(self);
          // },
          scanProductQrCode(e) {
            const self: F7ComponentContextExtend = this;
            const currenntState = $this.currentState;
            const systemConfigs = $this.rootServices.systemSettings$.value;

            $this.rootServices.barcodeScanner.scan({ showTorchButton: true }).then(barcodeData => {
              // const data = barcodeData && barcodeData.text.split('|');
              const data = barcodeData && barcodeData.text;

              let tmpcode = data.substring(1);
              const findOrderLength = parseInt(tmpcode.substring(0, 1));
              tmpcode = tmpcode.substring(1);
              const findOrder = tmpcode.substring(0, findOrderLength);
              tmpcode = tmpcode.substring(findOrderLength);
              const unitSeqLength = parseInt(tmpcode.substring(0, 1));
              tmpcode = tmpcode.substring(1);
              let unitSeq = tmpcode.substring(0, unitSeqLength);
              tmpcode = tmpcode.substring(unitSeqLength);
              let productId = tmpcode;


              let goodsId = `118${systemConfigs.ROOT_CONFIGS.coreEmbedId}${productId}`;
              if (!data[0]) return false;
              if (productId) {

                // const [productId, unitId] = data[0].split('-');
                if (!data[0]) return false;
                $this.rootServices.apiService.getPromise<any[]>('/admin-product/products/' + goodsId, {
                  select: 'id=>Code,text=>Name,Code=>Code,Name,OriginName=>Name,Sku,FeaturePicture,Pictures',
                  includeSearchResultLabel: true,
                  includeUnits: true,
                  ...self.filter,
                }).then(products => {
                  // self.$$(page.el).find('.page-content').scrollTo(0, 0);
                  // self.$setState({ productList: products.map(t => $this.preapreProduct(t)), infiniteLoadLast: products.length === 0 });
                  // self.infiniteOffset = 0;

                  const product = products[0];
                  if (!product) return;

                  product.Unit = product.Units?.find(f => f.Sequence == unitSeq);
                  product.UnitList = product.Units;

                  let existsProduct: ProductModel = currenntState.voucher.Details.find(f => $this.commonService.getObjectId(f.Product as any) === product.Code && $this.commonService.getObjectId(f.Unit as any) === $this.commonService.getObjectId(product.Unit));
                  if (!existsProduct) {
                    existsProduct = {
                      Product: product.Code,
                      Description: product.Name,
                      Quantity: 1,
                      // Price: product.Price,
                      Unit: product.Unit,
                      // PriceText: $this.rootServices.currencyPipe.transform(product.Price, 'VND'),
                      OriginPrice: product.OriginPrice,
                      OriginPriceText: $this.rootServices.currencyPipe.transform(product.OriginPrice, 'VND'),
                      // Tax: { id: 'NOTAX', text: 'n/a' } as any,
                      // TaxText: product.TaxText,
                      // ToMoney: (existsProduct.Quantity * existsProduct.Price) + (existsProduct.Quantity * existsProduct.Price * (existsProduct.Tax && existsProduct.Tax.Tax || 0)),
                      // ToMoneyText: $this.rootServices.currencyPipe.transform(product.Price, 'VND'),
                      Image: product.Pictures,
                      Thumbnail: product.FeaturePicture && product.FeaturePicture?.Thumbnail || null,
                      Promotion: product.Promotion,
                      PromotionText: product.PromotionText,
                      Categories: product.Categories,
                      CategoriesText: product.Categories?.map(m => m.text).join(', '),
                      // TaxList: product.UnitList,
                      // UnitList: [],
                    };

                    // if (product.Tax && !product.Tax.id) {
                    //   existsProduct.Tax = $this.taxList.find(f => f.id === (product.Tax as any));
                    // } else {
                    //   existsProduct.Tax = $this.taxList.find(f => f.id === 'NOTAX');
                    // }
                    if (product.Unit && !product.Unit.id) {
                      existsProduct.Unit = { id: product.Unit, text: product.Unit } as any;
                    } else {
                      existsProduct.Unit = product.Unit
                    }

                    // existsProduct.TaxList = [];
                    // for (const tax of $this.taxList) {
                    //   existsProduct.TaxList.push({ ...tax, selected: tax.id == ($this.commonService.getObjectId(existsProduct.Tax as any) || 'NOTAX') })
                    // }

                    existsProduct.UnitList = [];
                    if (product.UnitList && product.UnitList.length > 0) {
                      for (const unit of product.UnitList) {
                        const isSelected = unit.id === $this.commonService.getObjectId(product.Unit);
                        if (isSelected && unit.Price) {
                          existsProduct.Price = unit.Price;
                          existsProduct.PriceText = $this.rootServices.currencyPipe.transform(unit.Price, 'VND');
                        }
                        existsProduct.UnitList.push({ ...unit, selected: isSelected });
                      }
                    } else {
                      for (const unit of $this.unitList) {
                        existsProduct.UnitList.push({ ...unit, selected: product.Unit && unit.id === $this.commonService.getObjectId(existsProduct.Unit) });
                      }
                    }

                    currenntState.voucher.Details.push(existsProduct);
                  } else {
                    existsProduct.Quantity++;
                  }
                  // existsProduct.ToMoney = (existsProduct.Quantity * existsProduct.Price) + (existsProduct.Quantity * existsProduct.Price * (existsProduct.Tax && existsProduct.Tax.Tax || 0) / 100);
                  existsProduct.ToMoney = existsProduct.Quantity * existsProduct.Price;
                  existsProduct.ToMoneyText = $this.rootServices.currencyPipe.transform(existsProduct.ToMoney, 'VND');

                  self.calculateTotal();
                  self.$setState({
                    voucher: currenntState.voucher,
                  });

                  $this.commonService.showInfo('Đã thêm hàng hóa/dịch vụ vào báo giá');


                  // setTimeout(() => {
                  //   self.scanProductQrCode(e);
                  // }, 300);

                }).catch(err => {
                  console.error(err);
                  $this.commonService.showError(err);

                });
              }
            });
          },
          removeDetailItem(e) {
            return $this.removeDetailItem(this, e);
          },
          onFieldChange(e) {
            $this.onFieldChange(this, e);
          },
          async refresh(e, done) {

            done && setTimeout(() => done(), 10000);
            $this.refresh(this).then(data => {
              done && done();
            });

            return true;
          },
          save() {
            const self: F7ComponentContextExtend = this;
            const currenntState = $this.currentState;
            const relativeVouchers = self.$route.context.relativeVouchers;
            const id = currenntState.data.Code || self.$route.params['id'];

            $this.commonService.showPreloader();

            const preloaderProcess = setTimeout(() => {
              $this.commonService.hidePreloader();
            }, 15000);

            if (id === 'new') {
              if (relativeVouchers) {
                currenntState.data.RelativeVouchers = relativeVouchers;
              }
            }

            $this.save(self).then(rs => {
              $this.commonService.showInfo("Đã lưu thông tin sản phẩm");
              clearTimeout(preloaderProcess);
              $this.commonService.hidePreloader();
            }).catch(err => {
              clearTimeout(preloaderProcess);
              $this.commonService.hidePreloader();
              console.error(err);
            });
          },
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          pageInit(e, page: F7Page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;

            $this.onComponentInit({ instance: self, page: page }, self.$route.params['id'], null, page).then(curentState => {
              // self.detailDialog = self.$app.dialog.create({
              //   el: page.$el.find('.dialog-detail'),
              //   closeByBackdropClick: true,
              // });
            });

            $this.rootServices.authService.isAuthenticatedOrRefresh().pipe(take(1)).toPromise().then(() => {
              self.$setState({
                backTitle: self.$route.context && self.$route.context.backTitle || 'Back', title: self.$route.context && self.$route.context.title || 'Chat GUI',
              });
            }).catch(err => {
              console.error(err);
              $this.commonService.showError(err);
            });
          },
          // pageBeforeIn(e, page) {
          //     console.log('page before in');
          //     const self: F7ComponentContextExtend = this;
          //     self.refresh();
          // },
          // pageAfterIn(e, page) {
          //     console.log('page after in');
          // },
          pageBeforeOut(e, page) {
            console.log('page before out');
            // Call caback function
            if (this.$route?.context['callback']) {
              this.$route?.context['callback']($this.currentState.data, { lastAction: $this.currentState.lastAction });
            }
          },
          // pageAfterOut(e, page) {
          //     console.log('page after out');
          // },
          // pageBeforeUnmount(e, page) {
          //     console.log('page before unmount');
          // },
          // pageBeforeRemove(e, page) {
          //     console.log('page before remove');
          // },
          pageBeforeRemove(e, page) {
            console.log('[page event] before remove', page.route.url);
            const self: F7ComponentContextExtend = this;
            // const starmtSelectRoles = self.$app.smartSelect.get('.smart-select');
            // starmtSelectRoles.destroy();
            const currentState = $this.state[self.$route.params.id];
            if (self.$route.params.id === 'new') {
              if (currentState && currentState.data && currentState.data.Code) {
                delete currentState.data;
              }
            }
          },
        },
      },
    };
  }
}
