import { Router } from "framework7/build/core/modules/router/router";
import { CollaboratorOrderModel } from "src/app/model/collaborator.model";
import { RootServices } from "src/app/services/root.services";
import { F7Page } from "src/app/types/framework7-types";
import { ListComponentStateExtend, F7ListComponentContext, BaseListComponent } from "vendors/smart-bot-app/src/app/lib/base-list-component";
import { ChatRoom } from "vendors/smart-bot-app/src/app/lib/nam-chat/chat-room";
import { ContactModel } from "vendors/smart-bot-app/src/app/model/contact.model";
import { ProductModel } from "vendors/smart-bot-app/src/app/model/product.model";
import { CommonService } from "vendors/smart-bot-app/src/app/services/common.service";

declare const $: any;

// declare const $: any;
export interface ComponentStateExtend extends ListComponentStateExtend<CollaboratorOrderModel> {
  [key: string]: any;
  instance?: F7ComponentContextExtend;
}

export interface F7ComponentContextExtend extends F7ListComponentContext<CollaboratorOrderModel> {
  responseInProgress?: boolean;
  listItems?: ProductModel[];
  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, chatRoom?: ChatRoom } };
}

/** Component group manager */
export class ContactListComponent extends BaseListComponent<ComponentStateExtend, CollaboratorOrderModel> {

  namespace: string = 'contact-list';
  // states: { [key: string]: State } = {};

  title: 'Liên Hệ';
  apiPath = '/contact/contacts';
  idKey = 'Code';
  limit = 20;

  // stateMap = {
  //   'NOTJUSTAPPROVED': {
  //     text: 'chưa duyệt',
  //     color: 'text-color-orange',
  //     icon: 'circle_fill'
  //   },
  //   'APPROVED': {
  //     text: 'đã duyệt',
  //     color: 'text-color-green',
  //     icon: 'person_crop_circle_fill_badge_checkmark'
  //   },
  //   'INQUEUE': {
  //     text: 'hàng đợi',
  //     color: 'text-color-orange',
  //     icon: 'square_stack_3d_down_right_fill'
  //   },
  // };

  filterSchema = {
    Publisher: {
      type: 'autocomplete',
      ajax: (query: any) => {
        return this.rootServices.apiService.getPromise<ContactModel[]>('/contact/contacts', { search: query, eq_Groups: '[PUBLISHER]', includeIdText: true, includeGroups: true, sort_SearchRank: 'desc' }).then(results => {
          return results.map(m => {
            m.text = `${m.Name}${m.Groups ? ' (' + m.Groups.map(g => this.commonService.getObjectText(g as any)).join(', ') + ')' : ''}`;
            return m;
          });
        });
      },
      dataType: 'multiple'
    },
    Object: {
      type: 'autocomplete-contact',
      dataType: 'multiple'
    },
    State: {
      type: 'smart-select',
      // dataType: 'multiple'
    },
    DateOfOrder: {
      type: 'datepicker',
      dataType: 'range',
      format: 'dd/mm/yyyy'
    },
  };

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
  ) {
    super(rootServices, commonService);
    // // console.log('Click here to open ts file');

    this.voucherStateMap['INQUEUE'] = {
      text: 'hàng đợi',
      color: 'color-orange',
      icon: 'square_stack_3d_down_right_fill'
    };
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  async onComponentInit(state: ComponentStateExtend, index: string, asCase?: string, f7Page?: F7Page) {
    return super.onComponentInit(state, index, asCase, f7Page).then(currentState => {
      return currentState;
    });
  }

  prepareItem(item: CollaboratorOrderModel, index?: number) {
    item.DateText = this.rootServices.datePipe.transform(item.Reported, 'short');
    // const state = this.commonService.getStateLabel(item.State);
    // item.StateLabel = state?.label;
    // item.StateIcon = state?.icon;
    // item.StateStatus = state?.status;
    const processMap = this.rootServices.collaboratorService.processMap[this.commonService.getObjectId(item.State)];
    item.State = {
      id: this.commonService.getObjectId(item.State),
      text: processMap && processMap.label || '--',
      ...processMap
    };
    item.CreatorText = this.commonService.getObjectText(item.Creator);
    return item;
  }

  // async getList(params: any) {
  //   // console.log(self.searchBar);
  //   return this.rootServices.apiService.getPromise<any[]>('/sales/price-reports', {
  //     includeCreator: true,
  //     includeRelativeVouchers: true,
  //     sort_Id: 'desc',
  //     limit: 10,
  //     offset: 0,
  //     ...params,
  //   });
  // }

  async getList(self?: F7ComponentContextExtend, extParams?: any): Promise<CollaboratorOrderModel[]> {
    return this.rootServices.apiService.getPromise<CollaboratorOrderModel[]>(this.apiPath, {
      includeCreator: true,
      includeRelativeVouchers: true,
      sort_Id: 'desc',
      ...this.parseFilter(this.currentState?.filter),
      ...extParams
    }).then(list => {
      return list;
    }).catch(err => {
      console.error(err);
      this.commonService.showError(err);
      return Promise.reject(err);
    });
  }
  async changeState(item: CollaboratorOrderModel) {
    // const newState = this.currentState.data.State == 'APPROVED' ? 'NOTJUSTAPPROVED' : 'APPROVED';
    const $this = this;
    const currentState = this.rootServices.collaboratorService.processMap[this.commonService.getObjectId(item.State)];
    if (currentState && currentState.nextStates) {
      const supplierDialog = this.currentState.instance.$app.dialog.create({
        cssClass: 'dialog-large',
        title: 'Chuyển trạng thái',
        text: 'Phiếu đang ở trạng thái `' + currentState.label + '`, bạn có muốn chuyển sang các trạng thái bên dưới ?',
        buttons: [
          ...currentState.nextStates.map((m, index) => ({
            text: (index + 1) + '. ' + m.confirmLabel,
            color: 'success',
            onClick: () => {
              $this.rootServices.apiService.putPromise($this.apiPath, { id: item.Code, changeState: m.state }, [{ Code: item.Code }]).then(rs => {
                // this.currentState.data.State = m.state;
                item = $this.prepareItem({
                  ...item,
                  State: {
                    id: m.state,
                    text: m.label,
                    ...m,
                  }
                });
                const vitem = $this.currentState.instance.virtualList.items.findIndex(f => f.Code == item.Code);
                $this.currentState.instance.virtualList.replaceItem(vitem, item);
                this.commonService.showInfo(m.responseText);
                // const itemEle = $($this.currentState.instance.virtualList.$el).find('[data-id="' + item.Code + '"]');
                // this.itemBeforeInsert(itemEle[0], item);
              }).catch(err => {
                this.commonService.showError(err);
              });
            }
          })),
          {
            text: 'Trở về',
            bold: true,
            color: 'red'
          },
        ],
        verticalButtons: true,
      });
      supplierDialog.open();
    }
  }

  itemBeforeInsert(element: HTMLElement, item: CollaboratorOrderModel): void {
    const $this = this;
    $(element).find('.approveBtn').unbind('click').click(function () {
      // if ($this.commonService.getObjectId(item.State) != 'APPROVED') {
      // $this.currentState.instance.$app.dialog.confirm('Bạn có muốn duyệt phiếu đặt mua hàng `' + item.Title + '` ?', 'Duyệt phiếu đặt mua hàng', () => {

      //   $this.rootServices.apiService.putPromise<CollaboratorOrderModel[]>($this.apiPath + '/' + item.Code, { changeState: 'APPROVED' }, [{ Code: item.Code }]).then(rs => {
      //     $this.commonService.showInfo('Đã duyệt phiếu đặt mua hàng `' + item.Title + '`');
      //     item = $this.prepareItem({
      //       ...item,
      //       State: 'APPROVED'
      //     });
      //     $this.currentState.instance.virtualList.replaceItem($this.currentState.instance.virtualList.items.findIndex(f => f.Code == item.Code), item);
      //   }).catch(err => $this.commonService.showError(err));
      // });
      $this.changeState(item);
      // } else {
      //   $this.currentState.instance.$app.dialog.confirm('Bạn bỏ duyệt phiếu đặt mua hàng `' + item.Title + '` ?', 'Bỏ duyệt phiếu đặt mua hàng', () => {
      //     $this.rootServices.apiService.putPromise<CollaboratorOrderModel[]>($this.apiPath + '/' + item.Code, { changeState: 'NOTJUSTAPPROVED' }, [{ Code: item.Code }]).then(rs => {
      //       $this.commonService.showInfo('Đã bỏ duyệt phiếu đặt mua hàng `' + item.Title + '`');
      //       item = $this.prepareItem({
      //         ...item,
      //         State: 'NOTJUSTAPPROVED'
      //       });
      //       $this.currentState.instance.virtualList.replaceItem($this.currentState.instance.virtualList.items.findIndex(f => f.Code == item.Code), item);
      //     }).catch(err => $this.commonService.showError(err));
      //   });
      // }
      return false;
    });
    $(element).find('.moreBtn').unbind('click').click(function () {
      $this.currentState.instance.$app.dialog.create({
        title: 'Phiếu báo giá',
        text: item.Title,
        buttons: [
          {
            text: 'Copy đơn hàng CTV',
            color: 'orange',
            onClick: (dialog, e) => {

              $this.rootServices.navigate('/collaborator/order/new', {
                context: {
                  copyFromId: item.Code,
                  backTitle: $this.title,
                  callback: (data: CollaboratorOrderModel, state: any) => {
                    console.log('Callback data: ', data);
                    if (state?.lastAction == 'CREATE_SUCCESS') {
                      $this.refresh();
                    }
                  }
                }
              });
            }
          },
          {
            text: 'Download PDF',
            color: 'green',
            onClick: (dialog, e) => {
              $this.downloadPdf(item);
            }
          },
          {
            text: 'Trở về',
            bold: true,
            color: 'red'
          },
        ],
        verticalButtons: true,
      }).open();
      return false;
    });
    return;
  }

  downloadPdf(item: CollaboratorOrderModel) {
    this.rootServices.iab.create(this.rootServices.apiService.buildApiUrl(this.apiPath, { id: item.Code, includeObject: true, includeContact: true, includeDetails: true, includeUnit: true, renderPdf: 'download' }) as string, '_system');
  }

  async onItemClick(self: F7ComponentContextExtend, item: CollaboratorOrderModel) {
    // const self: F7ComponentContextExtend = this;
    // id = id || self.$(e.target).closest('li').data('id') || 'new';
    const id = item[this.idKey];

    this.rootServices.navigate('/contact-form/' + id, {
      context: {
        backTitle: this.title,
        textColorClass: self.textColorClass,
        callback: (data: CollaboratorOrderModel, state: any) => {
          console.log('Callback data: ', data);
          // this.refresh();
          if (state?.lastAction == 'UPDATE_SUCCESS') {
            if (data?.Code) {
              const index = self.virtualList.items.findIndex(f => f.Code == item.Code);
              if (index > -1) {
                self.virtualList.replaceItem(index, this.prepareItem(data));
              }
            }
          }
        }
      }
    });
  }

  itemHeight = 70;
  itemTemplate = /*html*/`
    <li data-id="{{Code}}">
      <a href="#" class="item-link item-content">
      <div class="item-media">
        {{#if Avatar}}
        <i class="icon list-item-img" style="
          background-image: url({{image Avatar}}); 
          width: 44px; height: 44px;
        "></i>
        {{else}}
        <i class="icon f7-icons" style="
            width: 44px; height: 44px; 
            font-size: 28px;
            border: 1px solid;
            border-color: var(--f7-list-item-border-color);
            border-radius: 50%;overflow: hidden;
            padding: 6px;
            color: gray;
          ">person_alt</i>
        {{/if}}
      </div>
        <div class="item-inner">
          <div class="item-title-row">
            <div class="item-title">{{Name}}</div>
          </div>
          <div class="item-text">{{FullAddress}}</div>
        </div>
      </a>
    </li>
  `;

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: 'contact-list',
      path: '/contact-list',
      component: {
        template: /*html*/`
            <div class="page no-toolbar-x ${$this.namespace}" data-name="${$this.namespace}">
              <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a class="link back {{textColorClass}}">
                      <i class="icon icon-back"></i>
                      <span class="if-not-md {{textColorClass}}">{{js "this.backTitle || 'Back'"}}</span>
                    </a>
                  </div>
                  <div class="title">Danh bạ</div>
                  <div class="right">
                    <!-- Link to enable searchbar -->
                    <a class="link icon-only searchbar-enable {{textColorClass}}" data-searchbar=".${$this.namespace}-searchbar">
                      <i class="icon f7-icons if-not-md">search</i>
                      <i class="icon material-icons md-only">search</i>
                    </a>
                  </div>
                  <!-- Searchbar is a direct child of "navbar-inner" -->
                  <form class="searchbar ${$this.namespace}-searchbar searchbar-expandable">
                    <div class="searchbar-inner">
                      <div class="searchbar-input-wrap">
                        <input type="search" placeholder="Search" />
                        <i class="searchbar-icon"></i><span class="input-clear-button"></span>
                        
                      </div>
                      <span class="searchbar-disable-button {{textColorClass}}">Hủy</span>
                    </div>
                  </form>
                </div>
              </div>
              <div class="page-content ptr-content infinite-scroll-content" @ptr:refresh="refresh">
                <div class="ptr-preloader">
                  <div class="preloader"></div>
                  <div class="ptr-arrow"></div>
                </div>
                <div class="searchbar-backdrop ${$this.namespace}-backdrop"></div>
            
                <!--<div class="action-bar block block-strong inset">
                  <div class="row">
                    <div class="col-25 tablet-15 action-bar-item text-color-gray link goto" @click="goto" data-url="/sales/goods-list">
                      <div class="demo-icon-icon"><i class="icon f7-icons">cube_box</i></div>
                      <div class="action-bar-item-label">Bảng giá</div>
                    </div>
                    <div class="col-25 tablet-15 action-bar-item text-color-gray link goto" @click="goto" data-url="/sales/price-report-list">
                      <div class="demo-icon-icon"><i class="icon f7-icons">app_fill</i></div>
                      <div class="action-bar-item-label">Báo giá</div>
                    </div>
                    <div class="col-25 tablet-15 action-bar-item text-color-gray link goto" @click="goto" data-url="/warehouse/inventory-adjust-note-list">
                      <div class="demo-icon-icon"><i class="icon f7-icons">app</i></div>
                      <div class="action-bar-item-label">Nhóm</div>
                    </div>
                    <div class="col-25 tablet-15 action-bar-item text-color-gray link goto" @click="goto" data-url="/warehouse/book-form/current">
                      <div class="demo-icon-icon"><i class="icon f7-icons">app</i></div>
                      <div class="action-bar-item-label">Đơn vị tính</div>
                    </div>
                  </div>
                </div>-->

                <div class="block-title" style="margin-top: 2rem">Bộ lọc <a style="float: right" @click="resetFilter"
                class="text-color-red">đặt lại</a></div>
                <div class="block block-strong inset filter" style="padding: 0; overflow: hidden">
                  <div class="list accordion-list inline-labels no-hairlines" style="margin: 0;">
                    <ul>
                      <li class="item-link smart-select smart-select-init" name="State" data-open-in="popup" data-searchbar="false" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFilterFieldChange">
                        <select>
                          <option value="">--</option>
                          {{#each processMap}}
                          <option value="{{state}}">{{label}}</option>
                          {{/each}}
                        </select>
                        <div class="item-content">
                          <div class="item-inner">
                            <div class="item-title">Trạng thái</div> 
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="item-content item-input">
                          <div class="item-inner">
                            <div class="item-title item-label">Thời gian</div>
                            <div class="item-input-wrap">
                              <input class="calendar" name="DateOfOrder" type="text" placeholder="Ngày đặt hàng" readonly="readonly" style="text-align: right;"/>
                            </div>
                          </div>
                        </div>
                      </li>

                    </ul>
                  </div>
                </div>

                <div class="block-title">Danh sách liên hệ
                  <a class="button button-small button-fill tab-link" @click="openContact" style="float: right; font-size: 0.7rem; font-weight: bold">+ Mới</a>
                </div>
                <div class="virtual-list list media-list ${$this.namespace}-virtual-list inset list-complex-info">
                  
                </div>
                {{#if infiniteLoadLast}}
                    <div style="text-align: center" class="text-color-gray">end</div>
                {{else}}
                    <div class="preloader color-blue infinite-scroll-preloader">
                        <span class="preloader-inner">
                            <span class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span>
                        </span>
                    </div>
                {{/if}}
            
                <div class="block"><br></div>
            
              </div>
            </div>
        `,
        style: /*css*/`
          .${$this.namespace} .find-order {
            font-size: 16px;
            font-weight: bold;
          }
          .${$this.namespace} .action-bar {
            color: var(--f7-block-strong-text-color);
            padding-top: var(--f7-block-padding-vertical);
            padding-bottom: var(--f7-block-padding-vertical);
            background-color: var(--f7-block-strong-bg-color);
          }
          .${$this.namespace} .virtual-list i.f7-icons {
            font-size: 16px;
          }
          .${$this.namespace} .list-complex-info ul li .item-title .item-title-text {
            -webkit-line-clamp: 1;
          }
          .${$this.namespace} .list-complex-info ul li .item-title .item-title-text {
            -webkit-line-clamp: 1;
          }
          .${$this.namespace} .list-complex-info ul li .item-title {
            height: 18px;
          }
          .${$this.namespace} .list-complex-info li {
            height: 70px;
          }
          .${$this.namespace} .list-complex-info li .item-text {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1rem;
            white-space: normal;
            white-space: initial;
          }
        `,
        data() {
          return {
            title: $this.title,
            // reminder: {
            //   No: '234234',
            // },
            priceReport: { Details: [] },
            filter: {},
            processMap: $this.rootServices.collaboratorService.processMap,
          };
        },
        methods: {
          reportCurrentFilterToPdf() {
            $this.rootServices.iab.create($this.rootServices.apiService.buildApiUrl($this.apiPath, {
              includeCreator: true,
              includeRelativeVouchers: true,
              sort_DateOfOrder: 'desc',
              ...$this.parseFilter($this.currentState?.filter),
              type: 'pdf'
            }) as string, '_system');
          },
          resetFilter(e) {
            $this.resetFilter(this, e);
          },
          onFilterFieldChange(e) {
            $this.onFilterFieldChange(this, e);
          },
          // Price report
          openContact(e, id?: string) {
            const self: F7ComponentContextExtend = this;
            id = id || self.$(e.target).closest('li').data('id') || 'new';

            // if ($this.commonService.frameSocket.isFrameMode && id && id !== 'new') {
            //   $this.commonService.frameSocket.emit('request-open-voucher', { id: id, type: 'PRICEREPORT' });
            // } else {
            $this.rootServices.navigate('/contact-form/' + id, {
              context: {
                backTitle: $this.title,
                textColorClass: self.textColorClass,
                // memberInfo: $this.currentState.chatRoom.memberList$.getValue().find(f => f.id === id),
                // chatRoom: self.$route.context.chatRoom,
                // reminderList$: self.reminderList$,
                callback: (data: CollaboratorOrderModel, state: any) => {
                  console.log('Callback data: ', data);
                  if (state?.lastAction == 'CREATE_SUCCESS') {
                    self.refresh();
                  }
                }
              }
            });
            // }
          },
          goto(e) {
            const self: F7ComponentContextExtend = this;
            const url = self.$(e.target).closest('.goto').data('url');
            $this.rootServices.navigate(url);
          },
          chooseOne(e) {
            const self: F7ComponentContextExtend = this;
            const id = self.$(e.target).closest('li').data('id');
            self.$route.context['onChoose'] && self.$route.context['onChoose'](self.listItems.find(f => f.Code === id));
          },
          chooseProduct(e) {

          },
          async refresh(e, done) {
            const self: F7ComponentContextExtend = this;

            $this.refresh(self).then(rs => done && done());

            // let doneTimeout = null;
            // if (done) {
            //   doneTimeout = setTimeout(() => {
            //     done();
            //   }, 10000);
            // };

            // // console.log(self.searchBar);
            // // $this.rootServices.apiService.getPromise<any[]>('/warehouse/inventory-adjust-notes', {
            // //   search: self?.searchBar?.query && self.searchBar.query || '',
            // //   includeParent: true,
            // //   includePath: true,
            // //   includeWarehouse: true,
            // //   includeGoods: true,
            // //   sort_Id: 'desc',
            // //   limit: 10,
            // //   offset: 0,
            // //   ...self.filter,
            // // }).then(products => {
            // $this.getList({
            //   search: self?.searchBar?.query && self.searchBar.query || '',
            //   limit: 10,
            //   offset: 0,
            //   ...self.filter,
            // }).then(products => {
            //   self.$setState({ listItems: products.map(t => $this.prepareItem(t)), infiniteLoadLast: products.length === 0 });
            //   self.infiniteOffset = 10;
            //   if (done) done();
            // }).catch(err => {
            //   console.error(err);
            //   $this.commonService.showError(err);
            //   if (done) done();
            // });

            return true;
          },
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          pageInit(e, page: F7Page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;
            // Loading flag
            let allowInfinite = true;

            // self.refresh();
            // self.searchBar = self.$app.searchbar.create({
            //   el: '.inventory-adjust-note-list-searchbar',
            //   backdrop: true,
            //   backdropEl: '.product-list-backdrop',
            //   searchContainer: '.list',
            //   searchIn: '.item-text',
            //   customSearch: true,
            //   on: {
            //     search(sb, query, previousQuery) {
            //       $this.commonService.takeUntil('product-search', 600).then(() => {
            //         console.log(query, previousQuery);
            //         $this.getList({
            //           search: self?.searchBar?.query && self.searchBar.query || '',
            //           limit: 10,
            //           offset: 0,
            //           ...self.filter,
            //         }).then(products => {
            //           self.$$(page.el).find('.page-content').scrollTo(0, 0);
            //           self.$setState({ listItems: products.map(t => $this.prepareItem(t)), infiniteLoadLast: products.length === 0 });
            //           self.infiniteOffset = 0;
            //         }).catch(err => {
            //           console.error(err);
            //           $this.commonService.showError(err);
            //         });
            //       });
            //     }
            //   }
            // });

            // self.$$(page.el).find('.infinite-scroll-content').on('infinite', function () {
            //   // Exit, if loading in progress
            //   if (!allowInfinite) return;

            //   // Set loading flag
            //   allowInfinite = false;
            //   // Last loaded index
            //   var lastItemIndex = self.$$('.list li').length;

            //   // Emulate 1s loading
            //   $this.getList({
            //     search: self?.searchBar?.query && self.searchBar.query || '',
            //     limit: 10,
            //     offset: self.infiniteOffset,
            //     ...self.filter,
            //   }).then(products => {
            //     products.map(t => {
            //       $this.prepareItem(t);
            //       return t;
            //     });
            //     self.$setState({ listItems: self.listItems.concat(products), infiniteLoadLast: products.length === 0 });
            //     self.infiniteOffset += 10;
            //     lastItemIndex = self.$$('.list li').length;
            //     allowInfinite = true;

            //     // self.updateBadge();
            //   }).catch(err => {
            //     console.error(err);
            //     $this.commonService.showError(err);
            //   });
            // });

            // // const reminder = self.$route.context.reminder || {};
            // // const chatRoom = self.$route.context.chatRoom;


            // self.calendarDateTime = self.$f7.calendar.create({
            //   // openIn: 'customModal',
            //   inputEl: page.$el.find('input[name="RemindAtDateTime"]'),
            //   timePicker: true,
            //   dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' },
            //   // header: true,
            //   footer: true,
            // });

            // Set back title
            // self.$setState({ backTitle: self.$route.context && self.$route.context.backTitle || 'Back', title: self.$route.context && self.$route.context.title || 'Tài khoản' });

            $this.onComponentInit({ instance: self }, 'main', null, page);

            // console.log(reminder);
            // $this.rootServices.authService.isAuthenticatedOrRefresh().pipe(take(1)).toPromise().then(() => {
            //   self.$setState({
            //     backTitle: self.$route.context && self.$route.context.backTitle || 'Back', title: self.$route.context && self.$route.context.title || 'Profile',
            //     memberList: chatRoom.memberList$.getValue().filter(member => member.Type === 'USER'),
            //     // reminder: self.reminder,
            //   });

            //   // setTimeout(() => {
            //   //   self.$setState({
            //   //     reminder: self.reminder,
            //   //   });
            //   // }, 5000);
            // }).catch(err => {
            //   console.error(err);
            //   $this.commonService.showError(err);
            // });

          },
          // pageBeforeIn(e, page) {
          //     console.log('page before in');
          //     const self: F7ComponentContextExtend = this;
          //     self.refresh();
          // },
          // pageAfterIn(e, page) {
          //     console.log('page after in');
          // },
          // pageBeforeOut(e, page) {
          //     console.log('page before out');
          // },
          pageAfterOut(e, page) {
            console.log('page after out');
          },
          // pageBeforeUnmount(e, page) {
          //     console.log('page before unmount');
          // },
          // pageBeforeRemove(e, page) {
          //     console.log('page before remove');
          // },
          pageBeforeRemove(e, page) {
            console.log('[page event] before remove', page.route.url);
            const self: F7ComponentContextExtend = this;
            // const starmtSelectRoles = self.$app.smartSelect.get('.smart-select');
            // starmtSelectRoles.destroy();
          },
        },
      },
    };
  }
}
