import { Model } from './model';
import { FileModel } from './file.model';
import { TaxModel } from "./accounting.model";
import { WarehouseModel } from './warehouse.model';

export class ProductModel extends Model {
    [key: string]: any;
    Code?: string;
    Type?: string;
    Name?: string;
    Description?: string;
    Technical?: string;
    Price?: number;
    OriginPrice?: number;
    ToMoney?: number;
    Tax?: TaxModel;
    UnitList?: { id?: string, text?: string, [key: string]: any }[];
    Units?: UnitConversion[];
    Unit?: { id?: string, text?: string, [key: string]: any };
    UnitConversions?: UnitConversion[];
    Categories?: { id?: string; text?: string }[];
    Groups?: { id?: string; text?: string }[];
    WarehouseUnit?: { id?: string, text?: string };
    FeaturePicture?: FileModel;
    Pictures?: FileModel[];
    Token?: string;

    constructor(properties?: Model) {
        super(properties);
        this.RequireVatTax = false;
        this.Pictures = [];
        this.Categories = [];
        this.Groups = [];
        this.Type = 'PRODUCT';
        this.UnitConversions = [
            {
                Unit: {
                    id: 'n/a', text: 'n/a'
                },
                ConversionRatio: 1,
                IsDefaultSales: true,
                IsDefaultPurchase: true,
                IsManageByAccessNumber: true,
                IsAutoAdjustInventory: true,
                IsExpirationGoods: false,
            },
        ];
    }
}

interface IdTextModel {
    id?: string;
    text?: string;
}
export class UnitConversion {
    [key: string]: any;
    Id?: string;
    Product?: string;
    Unit?: UnitModel;
    ConversionRatio?: number = 1;
    IsDefaultSales?: boolean = false;
    IsDefaultPurchase?: boolean = false;
    IsDefaultImport?: boolean = false;
    IsDefaultExport?: boolean = false;
    IsManageByAccessNumber?: boolean = false;
    IsAutoAdjustInventory?: boolean = false;
    IsExpirationGoods?: boolean = false;

    constructor() {

    }
}


export class UnitModel {
    [key: string]: any;
    id?: string; text?: string;
    Id?: string;
    Code?: string;
    Sequence?: string;
    Name?: string;
    FullName?: string;
    Symbol?: string;
}

export class ProductGroupModel {
    [key: string]: any;
    id?: string;
    text?: string;

    Id?: string & number;
    Code?: string;
    Name?: string;
    Description?: string;
}

export class ProductCategoryModel {
    [key: string]: any;
    id?: string;
    text?: string;

    Id?: string & number;
    Code?: string;
    Name?: string;
    Description?: string;
}

export interface ProductSearchIndexModel {
    [key: string]: any;
    Code?: string;
    Sku?: string;
    Type?: string;
    Name?: string;
    BaseUnit?: any;
    BaseUnitLabel?: string;
    Unit?: any;
    UnitSeq?: string;
    UnitNo?: string;
    ConversionRatio?: number;
    IsDefaultSales?: boolean;
    IsDefaultPurchase?: boolean;
    IsManageByAccessNumber?: boolean;
    IsAutoAdjustInventory?: boolean;
    IsExpirationGoods?: boolean;
    UnitLabel?: string;
    Price?: number;
    Container?: any;
    ContainerName?: string;
    ContainerShelf?: string;
    ContainerShelfName?: string;
    ContainerFindOrder?: number;
    ContainerAccAccount?: string;
    ContainerAccAccountName?: string;
    Warehouse?: string & WarehouseModel;
    WarehouseName?: string;
    SearchIndex?: string;
    SearchText?: string;
    Group?: string;
    GroupName?: string;
    Category?: string;
    CategoryName?: string;
    FeaturePicture?: FileModel;
    Pictures?: FileModel[];
    Technical?: string;
    Description?: string;
    Inventory?: string;
    LastUpdate?: string;
    IsNotBusiness?: string;
  }
