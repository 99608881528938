import { ProductGroupModel } from '../../../src/app/model/product.model';
import { F7ListComponentContext } from './../lib/base-list-component';
import { F7Page } from '../types/framework7-types';
import { Router } from 'framework7/build/core/modules/router/router';
import { ComponentState } from '../lib/base-component';
import { RootServices } from '../services/root.services';
import { take } from 'rxjs/operators';
import { ChatRoom } from '../lib/nam-chat/chat-room';
import { CommonService } from '../services/common.service';
import { TaskReminderModel } from '../model/task.model';
import { BehaviorSubject } from 'rxjs';
import { ProductCategoryModel, ProductModel } from '../model/product.model';
import { FileModel } from '../model/file.model';
import { BaseListComponent } from '../lib/base-list-component';
// import * as $ from 'jquery';
declare const $: any;

// declare const $: any;
export interface ComponentStateExtend extends ComponentState {
  [key: string]: any;
}

export interface F7ComponentContextExtend extends F7ListComponentContext<ProductModel> {
  responseInProgress?: boolean;
  productList?: ProductModel[];
  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, reminder?: TaskReminderModel, chatRoom?: ChatRoom, reminderList$?: BehaviorSubject<TaskReminderModel[]> } };

  categoryList?: ProductCategoryModel[],
  groupList?: ProductGroupModel[],

}

/** Component group manager */
export class ProductListComponent extends BaseListComponent<ComponentStateExtend, ProductModel> {
  namespace: string = 'product-list';
  itemHeight = 208;

  apiPath = '/sales/master-price-tables/products';
  componentName = 'product-list';
  route = '/product-list/:id';

  limit = 20;
  title: 'Sản phẩm';
  idKey = 'Code';

  filterSchema = {
    Categories: {
      type: 'smart-select',
      dataType: 'multiple'
    },
    Groups: {
      type: 'smart-select',
      dataType: 'multiple'
    },
  };

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
  ) {
    super(rootServices, commonService);
    // // console.log('Click here to open ts file');
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  async onComponentInit(state: ComponentStateExtend, index: string, asCase?: string, f7Page?: F7Page) {

    // Get static data
    state.instance.categoryList = await this.rootServices.apiService.getPromise<ProductCategoryModel[]>('/admin-product/categories', { onlyIdText: true, limit: 'nolimit' });
    state.instance.groupList = await this.rootServices.apiService.getPromise<ProductGroupModel[]>('/admin-product/groups', { onlyIdText: true, limit: 'nolimit' });

    state.instance.$setState({
      categoryList: state.instance.categoryList,
      groupList: state.instance.groupList,
    });

    return super.onComponentInit(state, index, asCase, f7Page).then(currentState => {

      return currentState;
    });
  }

  onItemClick(self: F7ListComponentContext<ProductModel>, item: ProductModel): void {
    return;
  }

  prepareItem(item: ProductModel, index?: number): ProductModel {
    if (item.UnitConversions && item.UnitConversions.length > 0) {
      let defaultSalesUnit = item.UnitConversions.find(f => f.IsDefaultSales);
      if (defaultSalesUnit) {
        item.Unit = defaultSalesUnit;
        const price = item.UnitList && item.UnitList.find(f => f.id === item.Unit.id);
        if (price) {
          item.Price = price.Price;
          item.PriceText = this.rootServices.currencyPipe.transform(item.Price, price.Currency || 'VND');
        }
      } else {
        defaultSalesUnit = item.UnitConversions[0];
        item.Unit = defaultSalesUnit;
      }
    } else {
      item.Unit = item.WarehouseUnit && item.WarehouseUnit.id ? item.WarehouseUnit : (item.WarehouseUnit ? { id: item.WarehouseUnit, text: item.WarehouseUnit } as any : { id: '', text: '--' });
    }
    if (item.Categories && item.Categories.length > 0) {
      item.CategoriesText = (item.Categories || []).map(m => m.text).join(', ');
    }
    if (item.FeaturePicture && item.FeaturePicture.Thumbnail) {
      item.FeaturePictureThumbnail = item.FeaturePicture.Thumbnail;
    }
    const taxValue = item.Tax && item.Tax.Tax || 0;
    item.ToMoney = parseInt(item.Price as any) + parseInt(item.Price as any) * parseInt(taxValue as any) / 100;
    item.ToMoneyText = this.rootServices.currencyPipe.transform(item.ToMoney, 'VND');;
    item.PriceText = this.rootServices.currencyPipe.transform(item.Price, 'VND');
    if (item.OriginPrice) {
      item.OriginPriceText = this.rootServices.currencyPipe.transform(item.OriginPrice, 'VND');
    }
    return item;
  }

  async getList(self?: F7ComponentContextExtend, extParams?: any): Promise<ProductModel[]> {
    return this.rootServices.apiService.getPromise<any[]>(this.apiPath, {
      priceTable: this.commonService.getObjectId(self.$route.context['priceTable']) || null,
      // includeFeaturePicture: true,
      // includeCategories: true,
      includeFeaturePicture: true,
      includeCategories: true,
      includeUnits: true,
      sort_SearchRank: 'desc',
      ...this.parseFilter(this.currentState?.filter),
      ...extParams
    }).then(list => {
      // self.$$(page.el).find('.page-content').scrollTo(0, 0);
      self.$setState({ productList: list.map((t, i) => this.prepareItem(t, i)), infiniteLoadLast: list.length === 0 });
      self.infiniteOffset = 10;
      // if (done) done();
      return list;
    }).catch(err => {
      console.error(err);
      this.commonService.showError(err);
      // if (done) done();
      return Promise.reject(err);
    });
  }

  itemBeforeInsert(element: HTMLElement, item: ProductModel): void {
    $(element).find('.chooseOne').unbind('click').click(() => {
      this.currentState.instance.$route.context['onChoose'] && this.currentState.instance.$route.context['onChoose'](item);
    });
    $(element).find('.openProduct').unbind('click').click(() => {
      this.rootServices.navigate('/admin-product/product/' + item.Code, {
        context: {
          backTitle: this.title,
          textColorClass: this.currentState.instance.textColorClass,
          callback: (data: ProductModel) => {
            console.log(data);
            // this.refresh(this.currentState.instance);
            this.updateItem(data);
          }
        }
      });
    });
    $(element).find('.copyProduct').unbind('click').click(() => {
      this.rootServices.navigate('/admin-product/product/new-' + item.Code, {
        context: {
          backTitle: this.title,
          copyFromId: item.Code,
          textColorClass: this.currentState.instance.textColorClass,
          callback: (data: ProductModel) => {
            console.log(data);
            this.refresh(this.currentState.instance);
          }
        }
      });
    });
    $(element).find('.previewPictures').unbind('click').click(() => {
      this.previewPictures([
        ...(item.FeaturePicture ? [item.FeaturePicture] : []),
        ...(item.pictures || []).filter(f => f.Id != item.FeaturePicture?.Id)
      ], 0);
    });
    return;
  }

  itemTemplate = /*html*/`
  <div class="block block-strong inset list-item index-{{@index}}" data-id="{{Code}}" data-index="{{@index}}" style="height: 197px; overflow: hidden; margin-top: 0; margin-bottom: 10px">
    <div class="item-content">
      <div class="detail-wrap">
        <div class="detail-header">
          <div class="header-label">{{Sku}} - {{Name}} ({{Code}})</div>
          <div class="detail-remove chooseOne link"><i class="icon f7-icons color-orange" style="font-size: 2rem;">cart_fill_badge_plus</i></div>
        </div>
        <div class="detail-body" style="padding-bottom: 0.5rem;">
          <div class="image-wrap">
            <div class="image previewPictures" data-index="0" style="height: 112px; {{js "this.FeaturePicture && this.FeaturePicture.Thumbnail && ('background-image: url('+this.FeaturePicture.Thumbnail+')') || ''"}}"></div>
          </div>
          <div class="detail-content-wrap">
            <div class="list inline-labels no-hairlines thin-scrollbar" style="overflow: auto; max-height: 128px;">
              <ul>
                {{#each UnitList}}
                <li class="item-content item-input" style="padding-left: 0; min-height: 2rem;">
                  <div class="item-inner">
                    <div class="item-title item-label">{{text}}</div>
                    <div class="price text-color-red" style="text-align: right; font-weight: bold">
                      {{currency Price coalesce="--"}}
                    </div>
                  </div>
                </li>
                {{else}}
                {{#each ../UnitConversions}}
                <li class="item-content item-input" style="padding-left: 0; min-height: 2rem;">
                  <div class="item-inner">
                    <div class="item-title item-label">ĐVT</div>
                    <div class="unit text-color-blue" style="text-align: right">
                      {{text}}
                    </div>
                  </div>
                </li>
                {{else}}
                <li class="item-content item-input" style="padding-left: 0; min-height: 2rem;">
                  <div class="item-inner">
                    <div class="item-title item-label">ĐVT</div>
                    <div class="unit text-color-blue" style="text-align: right">
                      {{text ../Unit '--'}}
                    </div>
                  </div>
                </li>
                {{/each}}
                {{/each}}
                {{#if OriginPrice}}
                <li class="item-content item-input" style="padding-left: 0; min-height: 2rem;">
                  <div class="item-inner">
                    <div class="item-title item-label">Giá cũ</div>
                    <div class="price text-color-red" style="text-align: right; font-weight: bold">
                      {{currency OriginPrice coalesce="--"}}
                    </div>
                  </div>
                </li>
                {{/if}}
                <li class="item-content item-input" style="padding-left: 0; min-height: 2rem;">
                  <div class="item-inner" style="padding-top: var(--f7-list-item-padding-vertical);
                  padding-bottom: var(--f7-list-item-padding-vertical);">
                    <div>
                      <i class="openProduct f7-icons text-color-gray link" style="font-size: 21px; float: left; line-height: 26px; margin-right: 0.5rem;">square_pencil</i>
                      <i class="copyProduct f7-icons text-color-gray link" style="font-size: 20px; float: left; line-height: 26px; margin-right: 0.5rem; margin-top: 2px;">square_on_square</i>
                    </div>
                    <!--<i class="chooseOne f7-icons text-color-orange link" style="font-size: 32px; float: left; line-height: 26px; margin-left: 1rem">cart_fill_badge_plus</i>-->
                  </div>
                </li>
                {{#each Suppliers}}
                <li class="item-content item-input" style="padding-left: 0; min-height: 2rem;">
                  <div class="item-inner">
                    <div class="item-title item-label">{{text}}</div>
                    <div class="price text-color-red" style="text-align: right; font-weight: bold">
                      {{currency LastPurchasePrice coalesce="--"}}
                    </div>
                  </div>
                </li>
                {{/each}}
              </ul>
            </div>
          </div>
        </div>
        <div class="detail-footer">{{this.CategoriesText}}</div>
      </div>
    </div>
  </div>
  `;

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: this.componentName,
      path: this.route,
      component: {
        template: /*html*/`
            <div class="page page-product-list no-toolbar-x" data-name="page-product-list">
              <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a class="link back {{textColorClass}}">
                      <i class="icon icon-back"></i>
                      <span class="if-not-md {{textColorClass}}">{{backTitle}}</span>
                    </a>
                  </div>
                  <div class="title">Hàng hóa/Dịch vụ</div>
                  <div class="right">
                    <!-- Link to enable searchbar -->
                    <a class="link icon-only searchbar-enable {{textColorClass}}" data-searchbar=".product-list-searchbar">
                      <i class="icon f7-icons if-not-md">search</i>
                      <i class="icon material-icons md-only">search</i>
                    </a>
                  </div>
                  <!-- Searchbar is a direct child of "navbar-inner" -->
                  <form class="searchbar product-list-searchbar searchbar-expandable">
                    <div class="searchbar-inner">
                      <div class="searchbar-input-wrap">
                        <input type="search" placeholder="Search" />
                        <i class="searchbar-icon"></i><span class="input-clear-button"></span>
                      </div>
                      <span class="searchbar-disable-button {{textColorClass}}">Hủy</span>
                    </div>
                  </form>
                </div>
              </div>
              <div class="page-content ptr-content infinite-scroll-content" @ptr:refresh="refresh">
                <div class="ptr-preloader">
                  <div class="preloader"></div>
                  <div class="ptr-arrow"></div>
                </div>
                <div class="searchbar-backdrop product-list-backdrop"></div>

                <div class="block-title" style="margin-top: 2rem">Bộ lọc <a style="float: right" @click="resetFilter"
                class="text-color-red">đặt lại</a></div>
                <div class="block block-strong inset filter" style="padding: 0; overflow: hidden">
                  <div class="list accordion-list inline-labels no-hairlines" style="margin: 0;">
                    <ul>
                      <li>
                        <a class="item-link smart-select smart-select-init" name="Categories" data-open-in="popup" data-searchbar="true" data-virtual-list="true" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFilterFieldChange">
                            <select multiple>
                              <option value="">Chọn</option>
                              {{#each categoryList}}
                              <option value="{{id}}">{{text}}</option>
                              {{/each}}
                            </select>
                            <div class="item-content">
                              <div class="item-inner">
                                <div class="item-title">Danh mục</div>
                              </div>
                            </div>
                          </a>
                      </li>
                      <li>
                        <a class="item-link smart-select smart-select-init" name="Groups" data-open-in="popup" data-searchbar="true" data-virtual-list="true" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFilterFieldChange">
                            <select multiple>
                              <option value="">Chọn</option>
                              {{#each groupList}}
                              <option value="{{id}}">{{text}}</option>
                              {{/each}}
                            </select>
                            <div class="item-content">
                              <div class="item-inner">
                                <div class="item-title">Nhóm</div>
                              </div>
                            </div>
                          </a>
                      </li>
                    </ul>
                  </div>
                </div>
            
                <div class="block-title">Hàng hóa/dịch vụ <a class="button button-small button-fill tab-link" @click="openProduct" style="float: right; font-size: 0.7rem; font-weight: bold">+ Mới</a></div>
                <div class="virtual-list product-list-virtual-list thumbnail-list"></div>


                {{#if infiniteLoadLast}}
                <div style="text-align: center" class="text-color-gray">end</div>
                {{else}}
                <div class="preloader color-blue infinite-scroll-preloader">
                    <span class="preloader-inner">
                        <span class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                            class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                            class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                            class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                            class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                            class="preloader-inner-line"></span><span class="preloader-inner-line"></span>
                    </span>
                </div>
                {{/if}}
            
                <div class="block"><br></div>
            
              </div>
            </div>
        `,
        style: /*css*/`
          .page-product-list .thumbnail-list ul {
            padding: 0;
            margin: 0;
          }
        `,
        data() {
          return {
            title: $this.title,
            reminder: {
              No: '234234',
            },
            priceReport: { Details: [] },
            infiniteLoadLast: false,
            filter: {},
          };
        },
        methods: {
          resetFilter(e) {
            $this.resetFilter(this, e);
          },
          onFilterFieldChange(e) {
            $this.onFilterFieldChange(this, e);
          },
          openProduct(e, id?: string) {
            const self: F7ComponentContextExtend = this;
            id = id || self.$(e.target).closest('li').data('id') || 'new';

            $this.rootServices.navigate('/admin-product/product/' + id, {
              context: {
                backTitle: $this.title,
                textColorClass: self.textColorClass,
                callback: (data: { case: string, product: ProductModel }) => {
                  console.log(data);
                  self.refresh();
                }
              }
            });
          },
          // createNewProduct(e) {
          //   const self: F7ComponentContextExtend = this;

          //   $this.rootServices.navigate('/admin-product/product/new', {
          //     context: {
          //       backTitle: $this.title,
          //       textColorClass: self.textColorClass,
          //       calback: (data: { case: string, product: ProductModel }) => {
          //         console.log(data);
          //       }
          //     }
          //   });
          // },
          chooseOne(e) {
            const self: F7ComponentContextExtend = this;
            const id = self.$(e.target).closest('li').data('id');
            self.$route.context['onChoose'] && self.$route.context['onChoose'](self.productList.find(f => f.Code === id));
          },
          chooseProduct(e) {

          },
          async refresh(e, done) {
            // const self: F7ComponentContextExtend = this;

            // let doneTimeout = null;
            // if (done) {
            //   doneTimeout = setTimeout(() => {
            //     done();
            //   }, 10000);
            // };

            // // console.log(self.searchBar);
            // $this.rootServices.apiService.getPromise<any[]>('/sales/master-price-tables/products', {
            //   search: self?.searchBar?.query && self.searchBar.query || '',
            //   priceTable: $this.commonService.getObjectId(self.$route.context['priceTable']) || null,
            //   includeFeaturePicture: true,
            //   limit: 10,
            //   offset: 0,
            //   includeCategories: true,
            //   ...self.filter,
            // }).then(products => {
            //   // self.$$(page.el).find('.page-content').scrollTo(0, 0);
            //   self.$setState({ productList: products.map(t => $this.preapreProduct(t)), infiniteLoadLast: products.length === 0 });
            //   self.infiniteOffset = 10;
            //   if (done) done();
            // }).catch(err => {
            //   console.error(err);
            //   $this.commonService.showError(err);
            //   if (done) done();
            // });

            $this.refresh(this).then(rs => {
              done && done();
            });

            return true;
          },
          saveTaskReminder() {
            const self: F7ComponentContextExtend = this;
            const no = self.$route.params['id'];
            const chatRoom = self.$route.context.chatRoom;
            const taskReminderData: TaskReminderModel = {};
            const fieldEles = self.$el.find('.field');
            fieldEles.each((index, fieldEle) => {
              console.log(fieldEle);
              console.log(fieldEle.value);
              if (fieldEle.name === 'MentionToMembers') {
                const starmtSelect = self.$app.smartSelect.get(self.$(fieldEle).closest('.smart-select')[0] as any);
                taskReminderData[fieldEle.name] = starmtSelect.getValue();
              } else if (fieldEle.name === 'RemindAtDateTime') {
                // const dateTimePicker = self.$app.calendar.get('input[name="RemindAtDateTime"]');
                const datetime: Date = self.calendarDateTime.getValue()[0];
                if (datetime) {
                  taskReminderData[fieldEle.name] = datetime.toISOString();
                }
              } else {
                taskReminderData[fieldEle.name] = fieldEle.value;
              }
            });

            taskReminderData.Task = chatRoom.id;
            console.log(taskReminderData);
            self.$app.preloader.show();
            try {
              if (no === 'new') {
                $this.rootServices.apiService.postPromise<TaskReminderModel[]>('/task/reminders', { chatRoom: chatRoom.id }, [taskReminderData]).then(rs => {
                  console.log(rs);
                  self.$app.preloader.hide();
                  $this.commonService.showInfo("Đã lưu lời nhắc");

                  // let reminders: TaskReminderModel[] = self.$route.context.reminderList$.getValue();
                  // reminders.push(rs[0]);
                  // reminders = reminders.sort((a, b) => a.NextRemind > b.NextRemind ? 1 : -1);
                  // self.$route.context.reminderList$.next(reminders);

                  self.$router.back();
                }).catch(err => {
                  console.error(err);
                  $this.commonService.showError(err);
                  self.$app.preloader.hide();
                });
              } else {
                taskReminderData.No = no;
                $this.rootServices.apiService.putPromise<TaskReminderModel[]>('/task/reminders/' + chatRoom.id + '-' + no, { chatRoom: chatRoom.id }, [taskReminderData]).then(rs => {
                  console.log(rs);
                  self.$app.preloader.hide();
                  $this.commonService.showInfo("Đã lưu lời nhắc");
                  // let reminders: TaskReminderModel[] = self.$route.context.reminderList$.getValue();
                  // let reminderIndex: number = reminders.findIndex(f => f.No == no);
                  // reminders[reminderIndex] = { ...reminders[reminderIndex], ...rs[0] };
                  // reminders = reminders.sort((a, b) => a.NextRemind > b.NextRemind ? 1 : -1);
                  // self.$route.context.reminderList$.next(reminders);
                  self.$router.back();
                }).catch(err => {
                  console.error(err);
                  $this.commonService.showError(err);
                  self.$app.preloader.hide();
                });
              }
            } catch (err) {
              self.$app.preloader.hide();
            }
          },
          async addGoodsPicture(e) {
            console.debug(e);
            const self: F7ComponentContextExtend = this;
            const id = self.$(e.target).closest('.product-item').data('id');
            const oldProduct = self.productList.find(f => f.Code == id);
            if (!oldProduct) {
              console.error('Not found');
            }

            try {

              self.$f7.dialog.create({
                title: 'Hình ảnh sản phẩm',
                text: 'Bạn muốn xem hình hay thêm hình cho sản phẩm ?',
                buttons: [
                  {
                    text: 'Xem hình',
                    onClick: () => {
                      // self.$app.photoBrowser.create({
                      //   photos: oldProduct?.Pictures?.map(m => ({
                      //     caption: `${oldProduct.Sku} - ${oldProduct.Name}`,
                      //     url: m.OriginImage,
                      //   })) || [],
                      //   theme: 'light',
                      //   type: 'standalone' as any,
                      // }).open();

                      $this.browseAttachment(oldProduct?.Pictures.map(m => ({
                        Id: m.Id,
                        Type: m.Type,
                        DataType: m.MimeType,
                        Ext: m.Extension,
                        Name: oldProduct.Name,
                        Description: oldProduct.Name,
                        DateOfAttach: oldProduct.Created,
                        // File: string;
                        Thumbnail: m.Thumbnail,
                        Url: m.OriginImage,
                        SmallUrl: m.SmallImage,
                        LargeUrl: m.LargeImage,
                        OriginUrl: m.OriginImage,
                      })) as any, oldProduct.FeaturePicture?.Id, (attachment => {
                        console.debug('download index', attachment);
                        if (/image/.test(attachment.Type)) {
                          // $this.currentState.instance.$app.dialog.confirm([attachment.name, attachment.description].filter(f => !!f).join('<br>') || 'Đính kèm', 'Xác nhận tải về', async () => {
                          //   $this.rootServices.saveImageToPhone(attachment.payload.url as string);
                          // });
                          $this.currentState.instance.$app.dialog.create({
                            title: 'Xác nhận tải về',
                            content: 'Đính kèm',
                            buttons: [
                              {
                                text: 'Đóng',
                                color: 'red',
                                close: true,
                              },
                              {
                                text: 'Mở link',
                                onClick: () => {
                                  $this.rootServices.saveImageToPhone(attachment.Url as string);
                                }
                              }
                            ],
                          }).open();
                        } else if (/video/.test(attachment.Type)) {
                          $this.playVideo((attachment.OriginUrl || attachment.Url) as string);
                        } else {
                          $this.rootServices.iab.create((attachment.OriginUrl || attachment.Url) as string, '_system');
                        }
                      })).then(() => {
                        console.log('Photo browser was closed');
                      });
                    }
                  },
                  {
                    text: 'Thêm hình',
                    onClick: async () => {
                      const localFiles = await $this.rootServices.pickFiles();
                      console.debug(localFiles);

                      let image: FileModel = null;
                      if (localFiles[0].dataType === 'url') {
                        image = await $this.rootServices.uploadLocalFile(localFiles[0].url as string);
                      } else {
                        const formData = new FormData();
                        const imgBlob = new Blob([localFiles[0].url], {
                          type: localFiles[0].type
                        });
                        formData.append('file', imgBlob, 'smart-bot-' + Date.now() + '.' + localFiles[0].ext);
                        image = await $this.rootServices.uploadFileData(formData);
                      }
                      console.debug(image);
                      e.target.style.backgroundImage = 'url(' + image.Thumbnail + ')';

                      // const updateProfileResult = await $this.rootServices.apiService.putPromise('/user/users/updateAccount', {}, { Avatar: image.GlobalId, AvatarThumbnail: image.Thumbnail })
                      const pictures = oldProduct.Pictures || [];
                      pictures.push(image);
                      const updateProfileResult = await $this.rootServices.apiService.putPromise('/admin-product/products/' + id, {}, [{ Code: id, FeaturePicture: image, Pictures: pictures }]);
                      console.debug('Update product picture sucess: ', updateProfileResult);
                      $this.commonService.showInfo('Đã thêm hình ảnh cho hàng hóa: ' + oldProduct.Name);

                      // Update auth user
                      const oldUser = $this.rootServices.authService.user$.getValue();
                      oldUser.avatar = oldUser.avatar || { id: '', payload: {} };
                      oldUser.avatar = { ...oldUser.avatar, payload: { ...oldUser.avatar.payload, thumbnail: image.Thumbnail, url: image.DownloadLink } }
                      $this.rootServices.authService.user$.next(oldUser);

                    }
                  },
                  {
                    text: 'Trở về',
                  },
                ],
                verticalButtons: true,
              }).open();


            } catch (err) {
              console.error(err);
              $this.commonService.showError(err);
            }

          },
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          pageInit(e, page: F7Page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;
            // Loading flag
            let allowInfinite = true;

            // self.refresh();
            // self.searchBar = self.$app.searchbar.create({
            //   el: '.product-list-searchbar',
            //   backdrop: true,
            //   backdropEl: '.product-list-backdrop',
            //   searchContainer: '.list',
            //   searchIn: '.item-text',
            //   customSearch: true,
            //   on: {
            //     search(sb, query, previousQuery) {
            //       $this.commonService.takeUntil('product-search', 600).then(() => {
            //         console.log(query, previousQuery);
            //         $this.rootServices.apiService.getPromise<any[]>('/sales/master-price-tables/products', {
            //           search: query,
            //           priceTable: $this.commonService.getObjectId(self.$route.context['priceTable']) || null,
            //           includeFeaturePicture: true,
            //           limit: 10,
            //           offset: 0,
            //           includeCategories: true,
            //           ...self.filter,
            //         }).then(products => {
            //           self.$$(page.el).find('.page-content').scrollTo(0, 0);
            //           self.$setState({ productList: products.map(t => $this.preapreProduct(t)), infiniteLoadLast: products.length === 0 });
            //           self.infiniteOffset = 10;
            //         }).catch(err => {
            //           console.error(err);
            //           $this.commonService.showError(err);
            //         });
            //       });
            //     }
            //   }
            // });

            // self.$$(page.el).find('.infinite-scroll-content').on('infinite', function () {
            //   // Exit, if loading in progress
            //   if (!allowInfinite) return;

            //   // Set loading flag
            //   allowInfinite = false;
            //   // Last loaded index
            //   var lastItemIndex = self.$$('.list li').length;

            //   // Emulate 1s loading
            //   $this.rootServices.apiService.getPromise<any[]>('/sales/master-price-tables/products', {
            //     search: self.searchBar.query,
            //     sort_LastUpdate: 'desc',
            //     limit: 10,
            //     offset: self.infiniteOffset,
            //     priceTable: $this.commonService.getObjectId(self.$route.context['priceTable']) || null,
            //     includeFeaturePicture: true,
            //     includeCategories: true,
            //     ...self.filter,
            //   }).then(products => {
            //     products.map(t => {
            //       $this.preapreProduct(t);
            //       return t;
            //     });
            //     self.$setState({ productList: self.productList.concat(products), infiniteLoadLast: products.length === 0 });
            //     self.infiniteOffset += 10;
            //     lastItemIndex = self.$$('.list li').length;
            //     allowInfinite = true;

            //     // self.updateBadge();
            //   }).catch(err => {
            //     console.error(err);
            //     $this.commonService.showError(err);
            //   });
            // });

            // const reminder = self.$route.context.reminder || {};
            // const chatRoom = self.$route.context.chatRoom;

            // self.productList = [
            //   {
            //     No: 1,
            //     CategoriesText: 'Thiết bị mạng',
            //     Thumbnail: '/assets/images/products/product-01.png',
            //     Code: 'SP15620277',
            //     Name: 'Công Tắc Cảm Ứng Wifi SmartZ PM màu trắng, không viền, 4 nút WS1 (mã con WS1.4W)',
            //     Price: 770000,
            //     PriceText: '770.000 đ',
            //     OriginPrice: 800000,
            //     OriginPriceText: '800.000 đ',
            //     Unit: { id: 'CAI', text: 'Cái' },
            //     UnitText: 'Cái',
            //     Tax: {
            //       id: 'VAT10',
            //       text: '+ 10% VAT',
            //       Code: 'VAT10',
            //       Name: 'Chưa bao gồm VAT 10%',
            //       Label2: '+ 10% VAT',
            //       Tax: 10,
            //     },
            //     TaxText: '10% vat',
            //     Promotion: 'GIAM30%',
            //     PromotionText: 'giảm 30%'
            //   },
            //   {
            //     No: 2,
            //     CategoriesText: 'Thiết bị mạng',
            //     Thumbnail: '/assets/images/products/product-02.jpeg',
            //     Code: 'SP166204228',
            //     Name: 'Công Tắc Cảm Ứng Wifi SmartZ PM màu trắng, viền bạc, 1 nút WS1 (mã con WS1F.1)',
            //     Price: 800000,
            //     PriceText: '800.000 đ',
            //     OriginPrice: 900000,
            //     OriginPriceText: '900.000 đ',
            //     Unit: { id: 'CAI', text: 'Cái' },
            //     UnitText: 'Cái',
            //     Tax: {
            //       id: 'VAT10',
            //       text: '+ 10% VAT',
            //       Code: 'VAT10',
            //       Name: 'Chưa bao gồm VAT 10%',
            //       Label2: '+ 10% VAT',
            //       Tax: 10,
            //     },
            //     TaxText: '10% vat',
            //     // Promotion: 'GIAM15%',
            //     // PromotionText: 'giảm 15%'
            //   },
            //   {
            //     No: 3,
            //     CategoriesText: 'Thiết bị mạng',
            //     Thumbnail: '/assets/images/products/product-03.jpg',
            //     Code: 'SP166204226',
            //     Name: 'Cảm Biến Cửa Wifi Cho Nhà Thông Minh SmartZ PM WD1',
            //     Price: 450000,
            //     PriceText: '450.000 đ',
            //     OriginPrice: 500000,
            //     OriginPriceText: '500.000 đ',
            //     Unit: { id: 'CAI', text: 'Cái' },
            //     UnitText: 'Cái',
            //     Tax: {
            //       id: 'VAT10',
            //       text: '+ 10% VAT',
            //       Code: 'VAT10',
            //       Name: 'Chưa bao gồm VAT 10%',
            //       Label2: '+ 10% VAT',
            //       Tax: 10,
            //     },
            //     TaxText: '10% vat',
            //     // Promotion: 'GIAM20%',
            //     // PromotionText: 'giảm 20%'
            //   },
            //   {
            //     No: 4,
            //     CategoriesText: 'Thiết bị mạng',
            //     Thumbnail: '/assets/images/products/product-04.jpeg',
            //     Code: 'SP166204197',
            //     Name: 'Bộ Báo Động Chống Trộm Qua WIFI + Sim GSM GW05 Cao Cấp',
            //     Price: 4500000,
            //     PriceText: '4.500.000 đ',
            //     OriginPrice: 5000000,
            //     OriginPriceText: '5.000.000 đ',
            //     Unit: { id: 'CAI', text: 'Cái' },
            //     UnitText: 'Cái',
            //     Tax: {
            //       id: 'VAT10',
            //       text: '+ 10% VAT',
            //       Code: 'VAT10',
            //       Name: 'Chưa bao gồm VAT 10%',
            //       Label2: '+ 10% VAT',
            //       Tax: 10,
            //     },
            //     TaxText: '10% vat',
            //     // Promotion: 'GIAM20%',
            //     // PromotionText: 'giảm 20%'
            //   },
            // ];
            // self.productList.map(detail => {
            //   const taxValue = detail.Tax && detail.Tax.Tax || 0;
            //   detail.ToMoney = detail.Price + detail.Price * taxValue / 100;
            //   detail.ToMoneyText = $this.rootServices.currencyPipe.transform(detail.ToMoney, 'VND');;
            //   detail.PriceText = $this.rootServices.currencyPipe.transform(detail.Price, 'VND');
            //   detail.OriginPriceText = $this.rootServices.currencyPipe.transform(detail.OriginPrice, 'VND');
            //   return detail;
            // })

            self.calendarDateTime = self.$f7.calendar.create({
              // openIn: 'customModal',
              inputEl: page.$el.find('input[name="RemindAtDateTime"]'),
              timePicker: true,
              dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' },
              // header: true,
              footer: true,
            });

            // Set back title
            // self.$setState({ backTitle: self.$route.context && self.$route.context.backTitle || 'Back', title: self.$route.context && self.$route.context.title || 'Tài khoản' });

            $this.onComponentInit({ instance: self }, 'main', null, page);

            // console.log(reminder);
            $this.rootServices.authService.isAuthenticatedOrRefresh().pipe(take(1)).toPromise().then(() => {
              self.$setState({
                backTitle: self.$route.context && self.$route.context.backTitle || 'Back', title: self.$route.context && self.$route.context.title || 'Profile',
                // memberList: chatRoom?.memberList$.getValue().filter(member => member.Type === 'USER'),
                // reminder: self.reminder,
              });

              // setTimeout(() => {
              //   self.$setState({
              //     reminder: self.reminder,
              //   });
              // }, 5000);
            }).catch(err => {
              console.error(err);
              $this.commonService.showError(err);
            });

            setTimeout(() => {
              // const starmtSelect = self.$app.smartSelect.get($(page.el).find('.field[name="MentionToMembers"]').closest('.smart-select')[0] as any);
              // // const remindeAtDateTime = self.$app.calendar.get(self.$('.field[name="RemindAtDateTime"]')[0] as any);

              // if (reminder.MentionToMembers) {
              //   starmtSelect.setValue(reminder.MentionToMembers.map(member => $this.commonService.getObjectId(member)));
              // }
              // if (reminder.RemindAtDateTime) {
              //   self.calendarDateTime.setValue([new Date(reminder.RemindAtDateTime)]);
              // }

              // starmtSelect.on('closed', (smartSelect) => {
              //   const selectedRoles: string & string[] = (smartSelect.getValue() as string[]).sort() as any;
              //   console.log('on smart select closed', selectedRoles);
              //   // if ((reminder.roles || []).toString() !== (selectedRoles || []).toString()) {
              //   //   reminder.roles = selectedRoles;
              //   //   chatRoom.updateMember(reminder).then(rs => {
              //   //     console.log('chat room member was updated', rs);
              //   //     $this.commonService.showInfo(`Đã thay đổi vai trò của ${reminder.shortName || reminder.name}`);
              //   //   }).catch(err => {
              //   //     console.error(err);
              //   //     $this.commonService.showError(err);
              //   //   });
              //   // }
              // });
            }, 500);
          },
          // pageBeforeIn(e, page) {
          //     console.log('page before in');
          //     const self: F7ComponentContextExtend = this;
          //     self.refresh();
          // },
          // pageAfterIn(e, page) {
          //     console.log('page after in');
          // },
          pageBeforeOut(e, page) {
            const self: F7ComponentContextExtend = this;
            console.log('page before out');
            $this.onBeforeChangedState({ instance: self, page, scrollPosition: $(page.el).find('.page-content').scrollTop() }, self.componentIndex);
          },
          // pageAfterOut(e, page) {
          //     console.log('page after out');
          // },
          // pageBeforeUnmount(e, page) {
          //     console.log('page before unmount');
          // },
          // pageBeforeRemove(e, page) {
          //     console.log('page before remove');
          // },
          pageBeforeRemove(e, page) {
            console.log('[page event] before remove', page.route.url);
            const self: F7ComponentContextExtend = this;
            // const starmtSelectRoles = self.$app.smartSelect.get('.smart-select');
            // starmtSelectRoles.destroy();
          },
        },
      },
    };
  }
}
