import { EventEmitter } from '@angular/core';
import { PageHomeComponent } from './page/page-home.component';
import { F7ComponentContext, F7Page } from '../types/framework7-types';
import { Router } from 'framework7/build/core/modules/router/router';
import { BehaviorSubject } from 'rxjs';
import { ComponentState, BaseComponent } from 'vendors/smart-bot-app/src/app/lib/base-component';
import { ChatRoom } from 'vendors/smart-bot-app/src/app/lib/nam-chat/chat-room';
import { ProductModel } from 'vendors/smart-bot-app/src/app/model/product.model';
import { TaskReminderModel } from 'vendors/smart-bot-app/src/app/model/task.model';
import { CommonService } from 'vendors/smart-bot-app/src/app/services/common.service';
import { RootServices } from 'vendors/smart-bot-app/src/app/services/root.services';
import * as moment from 'moment';
import { PageProductComponent } from './page/collaborator/page-products.component';
import { PageCollaboratorMonitoringComponent } from './page/collaborator/page-collaborator-monitoring.component';
import { PageCollaboratorEducationComponent } from './page/collaborator/page-collaborator-education.component';
import { PageCollaboratorJobsComponent as PageJobsComponent } from './page/page-jobs.component';
import { PageCollaboratorArticlesComponent as PageArticlesComponent } from './page/page-articles.component';
import { PageCsrComponent } from './page/page-csr.component';
import { PageCollaboratorTangCuongComponent } from './page/collaborator/page-collaborator-tang-cuong.component';
import { PageCollaboratorOpportunityComponent } from './page/collaborator/page-collaborator-opportunity.component';
import { PageCollaboratorOrderTabComponent } from './page/collaborator/page-collaborator-order-tab.component';

export interface ComponentStateExtend extends ComponentState {
  [key: string]: any;
}

export class F7ComponentContextExtend extends F7ComponentContext {
  // messagebar?: F7Messagebar;
  // messages?: Messages.Messages;
  // images?: string[];
  responseInProgress?: boolean;
  // answers?: string[];
  // people?: { name?: string, avatar?: string }[];
  // hideToolbar?: () => void;
  // sheetToggle?: () => void;
  // deleteAttachment?: (e: MouseEvent, index: number) => void;
  // handleAttachment?: (e: MouseEvent) => void;
  // checkAttachments?: () => void;
  // sendMessage?: () => void;
  productList?: ProductModel[];
  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, reminder?: TaskReminderModel, chatRoom?: ChatRoom, reminderList$?: BehaviorSubject<TaskReminderModel[]> } };
}

/** Component group manager */
export class PageComponent extends BaseComponent<ComponentStateExtend> {
  // states: { [key: string]: State } = {};

  title: 'Trang'

  eventEmitter = new EventEmitter<{ name: string, data: any }>();

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
  ) {
    super(rootServices);
    // // console.log('Click here to open ts file');
  }

  get isPublicComponent() {
    return true;
  }

  setActiveTab(tabId: string) {
    this.currentState.instance.$setState({ activeTab: tabId });
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: 'page',
      path: '/page/:id/',
      tabs: [
        // First (default) tab has the same url as the page itself
        new PageHomeComponent(this.rootServices, this.commonService, this).f7Component,
        // Second tab
        new PageProductComponent(this.rootServices, this.commonService, this).f7Component,
        { ...new PageProductComponent(this.rootServices, this.commonService, this).f7Component, path: '/collaborator/products/refcode/:refcode' },
        new PageCollaboratorTangCuongComponent(this.rootServices, this.commonService, this).f7Component,
        new PageCollaboratorOrderTabComponent(this.rootServices, this.commonService, this).f7Component,
        new PageCollaboratorMonitoringComponent(this.rootServices, this.commonService, this).f7Component,
        new PageCollaboratorOpportunityComponent(this.rootServices, this.commonService, this).f7Component,
        new PageCollaboratorEducationComponent(this.rootServices, this.commonService, this).f7Component,
        new PageJobsComponent(this.rootServices, this.commonService, this).f7Component,
        new PageArticlesComponent(this.rootServices, this.commonService, this).f7Component,
        new PageCsrComponent(this.rootServices, this.commonService, this).f7Component,
      ],
      component: {
        template: /*html*/`
            <div class="page bm-page" data-name="page">
              <div class="navbar navbar-page">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a class="link back {{textColorClass}}">
                      <i class="icon icon-back"></i>
                      <span class="if-not-md {{textColorClass}}">{{backTitle}}</span>
                    </a>
                  </div>
                  <div class="title">{{js "this.pageInfo && this.pageInfo.Name || 'Trang'"}}</div>
                  <div class="right">
                    <!-- Link to enable searchbar -->
                    <a class="link icon-only searchbar-enable {{textColorClass}}" data-searchbar=".product-list-searchbar">
                      <i class="icon f7-icons if-not-md">search</i>
                      <i class="icon material-icons md-only">search</i>
                    </a>
                  </div>
                  <!-- Searchbar is a direct child of "navbar-inner" -->
                  <form class="searchbar page-searchbar searchbar-expandable">
                    <div class="searchbar-inner">
                      <div class="searchbar-input-wrap">
                        <input type="search" placeholder="Search" />
                        <i class="searchbar-icon"></i>
                        <span class="input-clear-button"></span>
                      </div>
                      <span class="searchbar-disable-button {{textColorClass}}">Hủy</span>
                    </div>
                  </form>
                </div>
              </div>
              <div class="page-content ptr-content infinite-scroll-content" @ptr:refresh="refresh">
                <div class="ptr-preloader">
                  <div class="preloader"></div>
                  <div class="ptr-arrow"></div>
                </div>
                <div class="searchbar-backdrop page-searchbar-backdrop"></div>
            
                <!--<div class="block inset block-strong page-banner bg-color-gray{{js "!this.pageInfo ? ' skeleton-text skeleton-effect-blink' : ''"}}" style="background-image: url({{js "this.pageInfo && this.pageInfo.Banner && this.pageInfo.Banner.OriginImage"}});"></div>-->
                <div class="block inset block-strong page-bannerx {{js "!this.pageInfo ? ' skeleton-text skeleton-effect-blink' : ''"}}" style="padding: 0; overflow: hidden">
                  <img src="{{js "this.pageInfo && this.pageInfo.Banner && this.pageInfo.Banner.OriginImage"}}" style="width: 100%; margin-bottom: -6px">
                </div>

                <div class="block block-strong inset{{js "!this.pageInfo ? ' skeleton-text skeleton-effect-blink' : ''"}}">
                    <div class="page-header">
                        <div class="logo bg-color-gray" style="background-image: url({{js "this.pageInfo && this.pageInfo.Logo && this.pageInfo.Logo.OriginImage"}});"></div>
                        <div class="info">
                            <div class="name">{{js "this.pageInfo && this.pageInfo.Name"}}</div>
                            <div class="summary">{{js "this.pageInfo && this.pageInfo.Summary"}}</div>
                            <div class="tag">@{{js "this.pageInfo && this.pageInfo.TagName"}}</div>
                            <div class="tag"><a @click="copyIdPage">copy id page {{js "this.pageInfo && this.pageInfo.Code || 'undefined'"}}</a></div>
                        </div>
                    </div>
                </div>
                <div class="block block-strong inset toolbar tabbar tabbar-scrollable collaborator-page" style="width: inherit;">
                  <div class="toolbar-inner">
                    <a href="/page/{{pageId}}/" class="tab-link" data-route-tab-id="home">Trang chủ</a>| 
                    <a href="/page/{{pageId}}/collaborator/products" class="tab-link tab-link-active" xdata-route-tab-id="page-collaborator">CTV Bán Hàng ™</a>
                  </div>
                </div>
                {{#unless hideTabBar}}
                <!-- Tab menu -->
                <div class="action-bar block block-strong inset">
                  <div class="row">
                    <a href="/page/{{pageId}}/collaborator/products" class="col-20 tablet-20 action-bar-item text-color-gray link {{js "this.activeTab == 'page-products' && 'action-bar-item-active' || ''"}}">
                      <div class="demo-icon-icon action-bar-icon"><i class="icon f7-icons">cube</i></div>
                      <div class="demo-icon-icon action-bar-icon if-active"><i class="icon f7-icons">cube_fill</i></div>
                      <div class="action-bar-item-label">Sản phẩm</div>
                    </a>
                    <a href="/page/{{pageId}}/collaborator/order-tab" class="col-20 tablet-20 action-bar-item text-color-gray link {{js "this.activeTab == 'page-order-tab' && 'action-bar-item-active' || ''"}}">
                      <div class="demo-icon-icon action-bar-icon"><i class="icon f7-icons">tv</i></div>
                      <div class="demo-icon-icon action-bar-icon if-active"><i class="icon f7-icons">tv_fill</i></div>
                      <div class="action-bar-item-label">Đơn hàng</div>
                    </a>
                    <a href="/page/{{pageId}}/collaborator/monitoring" class="col-20 tablet-20 action-bar-item text-color-gray link {{js "this.activeTab == 'page-monitoring' && 'action-bar-item-active' || ''"}}">
                      <div class="demo-icon-icon action-bar-icon"><i class="icon f7-icons">tv</i></div>
                      <div class="demo-icon-icon action-bar-icon if-active"><i class="icon f7-icons">tv_fill</i></div>
                      <div class="action-bar-item-label">Giám sát</div>
                    </a>
                    <a href="/page/{{pageId}}/collaborator/opportunity-tab" class="col-20 tablet-20 action-bar-item text-color-gray link {{js "this.activeTab == 'page-opportunity-tab' && 'action-bar-item-active' || ''"}}">
                      <div class="demo-icon-icon action-bar-icon"><i class="icon f7-icons">question_diamond</i></div>
                      <div class="demo-icon-icon action-bar-icon if-active"><i class="icon f7-icons">question_diamond_fill</i></div>
                      <div class="action-bar-item-label">Cơ hội</div>
                    </a>
                    <a href="/page/{{pageId}}/collaborator/education-tab" class="col-20 tablet-20 action-bar-item text-color-gray link {{js "this.activeTab == 'page-education' && 'action-bar-item-active' || ''"}}">
                      <div class="demo-icon-icon action-bar-icon"><i class="icon f7-icons">book</i></div>
                      <div class="demo-icon-icon action-bar-icon if-active"><i class="icon f7-icons">book_fill</i></div>
                      <div class="action-bar-item-label">Đào tạo</div>
                    </a>
                  </div>
                </div>
                <!-- End Tab menu -->
                {{/unless}}

                <div class="tabs tabs-routable">
                  <div class="tab" id="home"></div>
                  <div class="tab" id="page-products"></div>
                  <div class="tab" id="page-order-tab"></div>
                  <div class="tab" id="page-monitoring"></div>
                  <div class="tab" id="page-opportunity-tab"></div>
                  <div class="tab" id="page-education"></div>
                  <div class="tab" id="page-jobs"></div>
                  <div class="tab" id="page-articles"></div>
                  <div class="tab" id="page-phan-phoi"></div>
                  <div class="tab" id="page-ticket"></div>
                  <div class="tab" id="page-khuyen-mai"></div>
                  <div class="tab" id="page-voucher"></div>
                  <div class="tab" id="page-ctv-ship"></div>
                  <div class="tab" id="page-ctv-ky-thuat"></div>
                  <div class="tab" id="page-csr"></div>
                  <div class="tab" id="page-tang-cuong"></div>
                </div>
            
                
                <div class="block"><br></div>
                
                
              </div>
            </div>
        `,
        style: /*css*/`
          .navbar-page .title {
            white-space: normal;
            line-height: 1.4em;
            display: flex;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-size: 12px;
          }
        `,
        data() {
          return {
            title: $this.title,
            backTitle: 'Back',
            // pageInfo: {},
            priceReport: { Details: [] },
            hideTabBar: false,
          };
        },
        methods: {
          copyIdPage(e) {
            const self: F7ComponentContextExtend = this;
            $this.rootServices.copyTextToClipboard(self.$route?.params?.id || 'undefined');
            $this.commonService.showInfo('Đã copy id page vào clipboard');
          },
          subscribe(e) {
            const self: F7ComponentContextExtend = this;
            const id = self.$(e.target).closest('li').data('id');
            self.$route.context['onChoose'] && self.$route.context['onChoose'](self.productList.find(f => f.Code === id));
          },
          chooseOne(e) {
            const self: F7ComponentContextExtend = this;
            const id = self.$(e.target).closest('li').data('id');
            self.$route.context['onChoose'] && self.$route.context['onChoose'](self.productList.find(f => f.Code === id));
          },
          chooseProduct(e) {

          },
          async refresh(e, done) {
            const self: F7ComponentContextExtend = this;
            const pageId = self.pageId || self.$route.params?.id;
            const pageTag = self.$route?.context && self.$route.context['pageTag'];
            let doneTimeout = null;
            if (done) {
              doneTimeout = setTimeout(() => {
                done();
              }, 10000);
            };

            try {
              // await $this.commonService.waiting(3000);
              let pageInfo = null;
              if (pageTag) {
                pageInfo = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/pages', { eq_TagName: pageTag, publicInfo: true, skipAuth: true }).then(rs => rs[0]);
                self.$setState({ pageId: pageInfo.Code });
              } else if (pageId) {
                pageInfo = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/pages/' + pageId, { publicInfo: true, skipAuth: true }).then(rs => rs[0]);
              }

              // console.log(self.searchBar);

              self.$setState({
                pageId: pageInfo.Code,
                pageInfo: pageInfo,
              });

            } catch (err) {
              console.error(err);
            }

            if (done) done();
            $this.eventEmitter.emit({ name: 'refresh', data: {} });
            return true;
          },
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          pageInit(e, page: F7Page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;
            const pageId = self.$route.params?.id;

            self.$setState({ pageId: pageId });

            // Loading flag
            let allowInfinite = true;

            self.refresh();


            self.calendarDateTime = self.$f7.calendar.create({
              // openIn: 'customModal',
              inputEl: page.$el.find('input[name="RemindAtDateTime"]'),
              timePicker: true,
              dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' },
              // header: true,
              footer: true,
            });

            self.searchBar = self.$app.searchbar.create({
              el: '.page-searchbar',
              backdrop: true,
              backdropEl: '.page-searchbar-backdrop',
              searchContainer: '.list',
              searchIn: '.item-text',
              customSearch: true,
              on: {
                search(sb, query, previousQuery) {
                  $this.commonService.takeUntil('heldpesk-ticket-search', 600).then(() => {
                    console.log(query, previousQuery);
                    $this.rootServices.apiService.getPromise<any[]>('/chat/rooms', {
                      search: encodeURIComponent(query),
                      sort_LastUpdate: 'desc',
                      limit: 10,
                      offset: 0,
                      filter_Type: '[LOCALGROUP,LOCAL,WORKPLACE]',
                      ...self.filter,
                    }).then(chatRooms => {
                      self.$$(page.el).find('.page-content').scrollTo(0, 0);
                      self.$setState({ chatRooms: chatRooms.map(t => $this.prepareItem(t)), infiniteLoadLast: chatRooms.length === 0 });
                      self.infiniteOffset = 0;
                    }).catch(err => {
                      console.error(err);
                      $this.commonService.showError(err);
                    });
                  });
                }
              }
            });

            // Set back title
            // self.$setState({ backTitle: self.$route.context && self.$route.context.backTitle || 'Back', title: self.$route.context && self.$route.context.title || 'Tài khoản' });

            $this.onComponentInit({ instance: self }, 'main');
            $this.eventEmitter.emit({ name: 'pageInit', data: {} });
          },
          // pageBeforeIn(e, page) {
          //     console.log('page before in');
          //     const self: F7ComponentContextExtend = this;
          //     self.refresh();
          // },
          // pageAfterIn(e, page) {
          //     console.log('page after in');
          // },
          // pageBeforeOut(e, page) {
          //     console.log('page before out');
          // },
          // pageAfterOut(e, page) {
          //     console.log('page after out');
          // },
          // pageBeforeUnmount(e, page) {
          //     console.log('page before unmount');
          // },
          // pageBeforeRemove(e, page) {
          //     console.log('page before remove');
          // },
          pageBeforeRemove(e, page) {
            console.log('[page event] before remove', page.route.url);
            const self: F7ComponentContextExtend = this;
            // const starmtSelectRoles = self.$app.smartSelect.get('.smart-select');
            // starmtSelectRoles.destroy();
          },
        },
      },
    };
  }

  prepareItem(item: any) {
    return item;
  }
}
