import { FileModel } from '../../../src/app/model/file.model';
import { Model } from './model';
import { ProductModel, UnitModel } from '../../../src/app/model/product.model';
import { ImageModel } from './file.model';
export interface WarehouseGoodsContainerModel {
    [key: string]: any;
    id?: string,
    text?: string;
    Code?: string;
    Name?: string;
    Description?: string;
    Path?: string;
    FindOrder?: number;
    Goods?: WarehouseGoodsModel[];
}

export interface WarehouseGoodsModel {
    [key: string]: any;
    id?: string,
    text?: string;
    Code?: string;
    Name?: string;
    Description?: string;
    Container?: any;
    Containers?: any[];
    Unit?: any;
    Inventory?: number;
    GoodsThumbnail?: any;
}

export class WarehouseInventoryAdjustNoteModel extends Model {
    [key: string]: any;
    id?: string;
    text?: string;
    Id?: string;
    Code?: string;
    Title?: string;
    Description?: string;
    Shelf?: string;
    Details?: WarehouseInventoryAdjustNoteDetailModel[] = [];
    constructor(properties?: WarehouseInventoryAdjustNoteModel) {
        super(properties);
    }
}
export class WarehouseInventoryAdjustNoteDetailModel extends Model {
    [key: string]: any;
    Id?: string;
    Product?: any;
    ProductName?: string;
    Description?: string;
    Container?: string;
    Unit?: UnitModel;
    Inventory?: number = 0;
    Quantity?: any = 0;
    AccessNumbers?: string[] = [];
    Business?: any[];

    constructor(properties?: WarehouseInventoryAdjustNoteDetailModel) {
        super(properties);
    }
}

export class WarehouseBookModel {
    [key: string]: any;
    Id?: string;
    Code?: string;
    Note?: string;
    DateOfStart?: string;
    DateOfEnd?: string;
    DateOfBeginning?: string;
    State?: UnitModel;
    HeadEntries?: WarehouseBookEntryModel[];
}
export class WarehouseBookEntryModel {
    [key: string]: any;
    Id?: string;
    Branch?: string;
    Book?: string;
    Type?: string;
    WriteType?: string;
    Voucher?: string;
    VoucherType?: string;
    Account?: string;
    WriteNo?: string;
    Goods?: any;
    Unit?: any;
    UnitPrice?: string;
    Currency?: string;
    Warehouse?: string;
    Container?: any;
    Description?: string;
    VoucherDescription?: string;
    IncreaseWrite?: number;
    DecreaseWrite?: string;
    VoucherDate?: string;
    WritedDate?: string;
    Object?: string;
    ObjectName?: string;
    ObjectPhone?: string;
    ObjectEmail?: string;
    ObjectAddress?: string;
    Writer?: string;
    DateOfWriting?: string;
    Employee?: string;
}
export class WarehouseModel {
    Id?: string | number;
    Code?: string;
    Branch?: string;
    Name?: string;
    Description?: string;
    Director?: string;
    IsDefaultExport?: string;
    IsDefaultImport?: string;
    [key: string]: any;
}
export class WarehouseGoodsInContainerModel {
    Id?: string | number;
    Warehouse?: string & WarehouseModel;
    Goods?: string & GoodsModel;
    GoodsName?: string & GoodsModel;
    SearchIndex?: string;
    GoodsSku?: string;
    Unit?: string & UnitModel;
    UnitLabel?: string;
    UnitSeq?: string;
    UnitNo?: string;
    Container?: string & WarehouseGoodsContainerModel;
    ContainerName?: string;
    ContainerDescription?: string;
    ContainerFindOrder?: number;
    ContainerPath?: string;
    ContainerAccAccount?: string;
    ContainerAccAccountName?: string;
    ContainerShelf?: string;
    ContainerShelfName?: string;
    WarehouseName?: string;
    Inventory?: string;
    LastUpdate?: string;
    CostOfGoodsSold?: ImageModel;
    GoodsThumbnail?: FileModel;
    GoodsPictures?: FileModel[];
    AccessNumbers?: string[];

    // Groups by goods
    Units?: { id?: string, text?: string, Inventory?: number, NumOfContainer?: number }[];
}
export class WarehouseGoodsQueueModel {
    Id?: string | number;
    Goods?: string;
    Name?: string & GoodsModel;
    Sku?: string;
    Unit?: string & UnitModel;
    UnitLabel?: string;
    State?: string;
    Queue?: string;
    DateOfPush?: string;
    Thumbnail?: FileModel;
    Pictures?: FileModel[];
    Container?: string;
    ContainerFindOrder?: number;
    ContainerShelf?: string;
    ContainerShelfName?: string;
    Inventory?: string;
}

export class GoodsModel extends ProductModel {
    Containers?: WarehouseGoodsInContainerModel[];
}
