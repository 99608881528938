import { SmartSelect } from 'framework7/build/core/components/smart-select/smart-select';
import { take } from 'rxjs/operators';
import { Router } from "framework7/build/core/modules/router/router";
import { ComponentState } from "../../../../src/app/lib/base-component";
import { BaseListComponent, F7ListComponentContext } from "../../../../src/app/lib/base-list-component";
import { ChatRoom } from "../../../../src/app/lib/nam-chat/chat-room";
import { FileModel } from "../../../../src/app/model/file.model";
import { ProductCategoryModel, ProductGroupModel, ProductModel, UnitModel } from "../../../../src/app/model/product.model";
import { CommonService } from "../../../../src/app/services/common.service";
import { RootServices } from "../../../../src/app/services/root.services";
import { F7Page } from "../../../../src/app/types/framework7-types";
import { WarehouseGoodsContainerModel, WarehouseGoodsInContainerModel, WarehouseGoodsQueueModel } from '../../../../src/app/model/warehouse.model';
import { CashVoucherModel } from '../../../../src/app/model/accounting.model';
import { Template7 } from 'framework7/build/core/framework7.esm';
// import * as $ from 'jquery';
declare const $: any;

// declare const $: any;
export interface ComponentStateExtend extends ComponentState {
  [key: string]: any;
  instance?: F7ComponentContextExtend;

  filter?: {
    Categories?: string[],
    Units?: string[],
    ContainerShelfs?: string[],
  };
}

export interface F7ComponentContextExtend extends F7ListComponentContext<ProductModel> {
  responseInProgress?: boolean;
  productList?: ProductModel[];
  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, chatRoom?: ChatRoom } };
  categoryList?: ProductCategoryModel[],
  groupList?: ProductGroupModel[],
  shelfList?: WarehouseGoodsContainerModel[],
  unitList?: UnitModel[],

}

/** Component group manager */
export class WarehouseGoodsListComponent extends BaseListComponent<ComponentStateExtend, ProductModel> {
  namespace: string = 'warehouse-goods-list';

  apiPath = '/warehouse/goods-in-containers';

  limit = 50;
  title: 'Hàng hóa'

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
  ) {
    super(rootServices, commonService);
    // // console.log('Click here to open ts file');
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  filterSchema = {
    Categories: {
      type: 'smart-select',
      dataType: 'multiple'
    },
    ContainerShelf: {
      type: 'smart-select',
      dataType: 'multiple'
    },
    Unit: {
      type: 'smart-select',
      dataType: 'multiple'
    },
    OutOfStockType: {
      type: 'smart-select',
    },
  };

  async onComponentInit(state: ComponentStateExtend, index: string, asCase?: string, f7Page?: F7Page) {
    // const cs = this.state[index];
    this.commonService.showPreloader();

    // Get static data
    state.instance.categoryList = await this.rootServices.apiService.getPromise<ProductCategoryModel[]>('/admin-product/categories', { onlyIdText: true, limit: 'nolimit' });
    // state.instance.groupList = await this.rootServices.apiService.getPromise<ProductCategoryModel[]>('/admin-product/groups', { onlyIdText: true, limit: 'nolimit' });
    state.instance.shelfList = await this.rootServices.apiService.getPromise<ProductCategoryModel[]>('/warehouse/goods-containers', { includeIdText: true, eq_Type: 'SHELF', limit: 'nolimit' }).then(rs => rs.map(m => { m.text = m.Name; return m; }));
    state.instance.unitList = await this.rootServices.apiService.getPromise<ProductCategoryModel[]>('/admin-product/units', { includeIdText: true, limit: 'nolimit' });

    state.instance.$setState({
      categoryList: state.instance.categoryList,
      shelfList: state.instance.shelfList,
      unitList: state.instance.unitList,
    });

    return super.onComponentInit(state, index, asCase, f7Page).then(currentState => {


      this.commonService.hidePreloader();
      return currentState;
    });
  }

  onItemClick(self: F7ListComponentContext<ProductModel>, item: ProductModel): void {
    return;
  }

  prepareItem(item: ProductModel, index?: number): ProductModel {
    // if (item.UnitConversions && item.UnitConversions.length > 0) {
    //   let defaultSalesUnit = item.UnitConversions.find(f => f.IsDefaultSales);
    //   if (defaultSalesUnit) {
    //     item.Unit = defaultSalesUnit;
    //     const price = item.UnitList && item.UnitList.find(f => f.id === item.Unit.id);
    //     if (price) {
    //       item.Price = price.Price;
    //     }
    //   } else {
    //     defaultSalesUnit = item.UnitConversions[0];
    //     item.Unit = defaultSalesUnit;
    //   }
    // } else {
    //   // item.Unit = item.WarehouseUnit && item.WarehouseUnit.id ? item.WarehouseUnit : (item.WarehouseUnit ? { id: item.WarehouseUnit, text: item.WarehouseUnit } as any : { id: '', text: '--' });
    // }
    // item.global = this.currentState.instance;
    item.isChooseMode = this.currentState.instance.$route?.params?.action == 'choose';
    return item;
  }

  async getList(self?: F7ComponentContextExtend, extParams?: any): Promise<ProductModel[]> {

    return this.rootServices.apiService.getPromise<any[]>(this.apiPath, {
      priceTable: 'default',
      includeFeaturePicture: true,
      includeCategories: true,
      includeUnits: true,
      groupByGoods: true,
      sort_Goods: 'desc',
      sort_UnitNo: 'asc',
      ...this.parseFilter(this.currentState?.filter),
      ...extParams
    }).then(list => {
      self.$setState({ productList: list.map((t, i) => this.prepareItem(t, i)), infiniteLoadLast: list.length === 0 });
      self.infiniteOffset = 10;
      return list;
    }).catch(err => {
      console.error(err);
      this.commonService.showError(err);
      return Promise.reject(err);
    });
  }

  itemBeforeInsert(element: HTMLElement, item: WarehouseGoodsInContainerModel): void {
    const $this = this;
    $(element).find('.chooseBtn').unbind('click').click(() => {
      this.currentState.instance.$route.context['onChoose'] && this.currentState.instance.$route.context['onChoose'](item);
    });
    $(element).find('.openProduct').unbind('click').click(() => {
      this.rootServices.navigate('/admin-product/product/' + item.Goods, {
        context: {
          backTitle: this.title,
          textColorClass: this.currentState.instance.textColorClass,
          callback: (data: { case: string, product: ProductModel }) => {
            console.log(data);
            this.refresh(this.currentState.instance);
          }
        }
      });
    });
    $(element).find('.previewPictures').unbind('click').click(async () => {
      // const product = await this.rootServices.apiService.getPromise<ProductModel[]>('/admin-product/products/' + item.Goods).then(rs => rs[0]);
      this.previewPictures([
        ...(item.GoodsThumbnail ? [item.GoodsThumbnail] : []),
        ...(item.GoodsPictures || []).filter(f => f.Id != item.GoodsThumbnail?.Id)
      ], 0);
    });

    // const accessNumbers = this.currentState.instance.$app.smartSelect.create({
    //   el: $(element).find('.smart-select[name="AccessNumbers"]')[0],
    //   openIn: 'popup',
    //   searchbar: true,
    //   closeOnSelect: true,
    //   popupSwipeToClose: true,
    //   scrollToSelectedItem: true,
    // });
    // setTimeout(() => {
    //   accessNumbers.setValue((item.AccessNumbers || []).map(m => m.toString()));
    // }, 300);

    // $(element).find('.moreBtn').unbind('click').click(() => {
    //   $this.currentState.instance.$app.dialog.create({
    //     title: 'Hàng hóa',
    //     text: item.GoodsName,
    //     buttons: [
    //       {
    //         text: 'Cho vào hàng đợi',
    //         color: 'orange',
    //         onClick: (dialog, e) => {
    //           this.pushToGoodsQueue(item).then(rs => {
    //             this.commonService.showInfo('Đã đẩy hàng hóa và hàng đợi.');
    //           });
    //         }
    //       },
    //       {
    //         text: 'Trở về',
    //         bold: true,
    //         color: 'red'
    //       },
    //     ],
    //     verticalButtons: true,
    //   }).open();
    // });

    // $(element).find('.queueBtn').unbind('click').click(() => {
    //   $this.currentState.instance.$app.dialog.create({
    //     title: 'Hàng đợi',
    //     text: 'Cho "' + item.GoodsName + ' (' + item.UnitLabel + ')" và hàng đợi chờ xử lý sau',
    //     buttons: [
    //       {
    //         text: 'Xác nhận',
    //         color: 'orange',
    //         onClick: (dialog, e) => {
    //           this.pushToGoodsQueue(item).then(rs => {
    //             this.commonService.showInfo('Đã đẩy hàng hóa và hàng đợi.');
    //           });
    //         }
    //       },
    //       {
    //         text: 'Trở về',
    //         bold: true,
    //         color: 'red'
    //       },
    //     ],
    //     verticalButtons: true,
    //   }).open();
    // });

    $(element).find('.unitBtn').unbind('click').click(async function () {

      const unitId = $(this).attr('data-unit');
      if (item.Units && item.Units.length > 0) {
        const unit = item.Units.find(u => u.id == unitId);
        if (unit) {
          if (unit.NumOfContainer > 0) {

            const containers = await $this.rootServices.apiService.getPromise<WarehouseGoodsInContainerModel[]>($this.apiPath, { eq_Goods: item.Goods, eq_Unit: unitId }).then(rs => rs.map(container => ({
              id: container.Container,
              text: container.ContainerShelfName + '/' + container.ContainerFindOrder,
              Shelf: container.ContainerShelf,
              ShelfName: container.ContainerShelfName,
              FindOrder: container.ContainerFindOrder,
              Inventory: container.Inventory,
              Unit: {
                id: container.Unit,
                text: container.UnitLabel,
              }
            })));

            let listItemTemplate = Template7.compile(/*html*/`
            <div class="list" style="margin-left: -1rem; margin-right: -1rem; margin-bottom: 0">
              <ul>
                {{#each containers}}
                <li data-container="{{id this}}">
                  <a href="#" class="item-link item-content">
                    <!--<div class="item-media"><i class="icon icon-f7"></i></div>-->
                    <div class="item-inner">
                      <div class="item-title">{{text this}}</div>
                      <div class="item-after">{{decimal Inventory}} {{text Unit}}</div>
                    </div>
                  </a>
                </li>
                {{/each}}
              </ul>
            </div>
            `);

            const dialog = $this.currentState.instance.$app.dialog.create({
              title: $this.isChooseMode ? 'Chọn vị trí' : 'Vị trí hàng hóa',
              text: ($this.isChooseMode ? 'Chọn theo vị trí của ' : 'Hiển thị barcode cho ') + item.GoodsName + ' (' + item.UnitLabel + ')"',
              content: listItemTemplate({ containers }),
              buttons: [
                {
                  text: 'Trở về',
                  bold: true,
                  color: 'red'
                },
              ],
              verticalButtons: true,
            });
            dialog.open();

            // const listEle = dialog.$el.find('.list');
            const listItem = dialog.$el.find('.list li');
            listItem.click(function () {
              const containerId = $(this).attr('data-container');
              const container = containers.find(c => c.id == containerId);

              if ($this.isChooseMode) {
                $this.currentState.instance.$route.context['onChoose'] && $this.currentState.instance.$route.context['onChoose']({
                  ...item,
                  Unit: unit,
                  Container: container,
                })
              } else {

                $this.currentState.instance.$app.dialog.create({
                  // title: $this.isChooseMode ? 'Chọn vị trí' : 'Barcode',
                  // text: ($this.isChooseMode ? 'Chọn theo vị trí của ' : 'Hiển thị barcode cho ') + item.GoodsName + ' (' + item.UnitLabel + ')"',
                  title: 'Hành động',
                  text: item.GoodsName + ' (' + item.UnitLabel + ') - ' + $this.commonService.getObjectText(container),
                  buttons: [
                    {
                      text: 'Hiển thị barcode',
                      color: 'blue',
                      onClick: () => {
                        $this.rootServices.apiService.getPromise<any[]>('/warehouse/find-order-tems', {
                          id0: `${item.Goods}-${item.Unit}-${containerId}`,
                          includeWarehouse: true,
                          renderBarCode: true,
                          masterPriceTable: 'default',
                          includeGroups: true,
                          includeUnit: true,
                          includeFeaturePicture: true,
                          group_Unit: true,
                          includeContainers: true,
                          limit: 'nolimit',
                        }).then(rs2 => {
                          console.log(rs2);
                          if (rs2 && rs2.length > 0) {
                            $this.commonService.hidePreloader();
                            $this.currentState.instance.$app.photoBrowser.create({
                              photos: rs2.map((m, index) => {

                                return {
                                  caption: $this.commonService.getObjectText(item.GoodsSku + ' - ' + item.GoodsName + ' (' + item.Goods + ') ' + ' - ' + item.ContainerShelfName + '/' + item.ContainerFindOrder),
                                  html: `<div style="background: #fff"><img src="${m.BarCode}"</div>`
                                };

                              }),
                              theme: 'light',
                              type: 'standalone' as any,
                            }).open();
                          } else {
                            $this.commonService.showError('Không thể tạo barcode cho hàng hóa này');
                          }
                        }).catch(err => {
                          $this.commonService.hidePreloader();
                        });
                      }
                    },
                    {
                      text: 'Cho vào hàng đợi',
                      color: 'blue',
                      onClick: () => {
                        $this.pushToGoodsQueue({ 
                          ...item, 
                          Unit: $this.commonService.getObjectId(unit), 
                          UnitLabel: $this.commonService.getObjectText(unit),
                          Container: $this.commonService.getObjectId(container),
                          ContainerShelf: container.Shelf,
                          ContainerShelfName: container.ShelfName,
                          ContainerFindOrder: container.FindOrder,
                          Inventory: container.Inventory,
                         }).then(rs => {
                          $this.commonService.showInfo('Đã đẩy hàng hóa và hàng đợi.');
                        });
                      }
                    },
                    {
                      text: 'Trở về',
                      bold: true,
                      color: 'red'
                    },
                  ],
                  verticalButtons: true,
                }).open();
              }
              dialog.close();
              // dialog.destroy();
            });
          }
        }
      }


    });
    $(element).find('.showBarcode').unbind('click').click(() => {
      $this.commonService.showPreloader();
      $this.rootServices.apiService.getPromise<any[]>('/warehouse/find-order-tems', {
        id0: `${item.Goods}-${item.Unit}-${item.Container}`,
        includeWarehouse: true,
        renderBarCode: true,
        masterPriceTable: 'default',
        includeGroups: true,
        includeUnit: true,
        includeFeaturePicture: true,
        group_Unit: true,
        includeContainers: true,
        limit: 'nolimit',
      }).then(rs2 => {
        console.log(rs2);
        if (rs2 && rs2.length > 0) {
          $this.commonService.hidePreloader();
          $this.currentState.instance.$app.photoBrowser.create({
            photos: rs2.map((m, index) => {

              return {
                caption: $this.commonService.getObjectText(item.GoodsSku + ' - ' + item.GoodsName + ' (' + item.Goods + ') ' + ' - ' + item.ContainerShelfName + '/' + item.ContainerFindOrder),
                html: `<div style="background: #fff"><img src="${m.BarCode}"</div>`
              };

            }),
            theme: 'light',
            type: 'standalone' as any,
          }).open();
        } else {
          $this.commonService.showError('Không thể tạo barcode cho hàng hóa này');
        }
      }).catch(err => {
        $this.commonService.hidePreloader();
      });
    });

    return;
  }

  async pushToGoodsQueue(goods: WarehouseGoodsInContainerModel) {
    const product = await this.rootServices.apiService.getPromise<ProductModel[]>('/admin-product/products/' + goods.Goods).then(rs => rs[0]);
    if (!product) {
      this.commonService.showError('Hàng hóa không tồn tại');
      return Promise.reject('Hàng hóa không tồn tại');
    }
    return this.rootServices.apiService.postPromise<WarehouseGoodsQueueModel[]>('/warehouse/goods-queues', {}, [{
      Goods: goods.Goods,
      Unit: goods.Unit,
      UnitLabel: goods.UnitLabel,
      Name: goods.GoodsName,
      Sku: goods.GoodsSku,
      Queue: 'OUTOFSTOCK',
      Thumbnail: product.FeaturePicture,
      Pictures: product.Pictures,
      Container: this.commonService.getObjectId(goods.Container),
      ContainerShelf: this.commonService.getObjectId(goods.ContainerShelf),
      ContainerShelfName: goods.ContainerShelfName,
      ContainerFindOrder: goods.ContainerFindOrder,
      Inventory: goods.Inventory,
    }]).catch(err => {
      this.commonService.showError(err);
      return Promise.reject(err);
    }).then(rs => rs[0]);
  }

  async onFilterFieldChange(self: F7ComponentContextExtend, e: any) {
    return super.onFilterFieldChange(self, e);
  }

  itemHeight = 208;
  itemTemplate = /*html*/`
  <li class="swipeout block block-strong inset list-item index-{{@index}}" data-id="{{Code}}" data-index="{{@index}}" style="height: 197px; overflow: hidden; margin-top: 0; margin-bottom: 10px">
    <div class="item-content swipeout-content">
      <div class="detail-wrap">
        <div class="detail-header">
          <div class="header-label">{{GoodsName}}</div>
          {{#if isChooseMode}}
          <!--<div class="detail-remove chooseBtn link"><i class="icon f7-icons color-blue" style="font-size: 2rem;">checkmark_alt_circle_fill</i></div>-->
          {{else}}
          <!--<div class="detail-remove showBarcode link"><i class="icon f7-icons color-blue" style="font-size: 2rem;">barcode_viewfinder</i></div>-->
          {{/if}}
        </div>
        <div class="detail-body">
          <div class="image-wrap" style="padding-bottom: 0.5rem">
            <div class="image previewPictures" data-index="0" style="height: 112px; {{js "this.GoodsThumbnail && this.GoodsThumbnail.Thumbnail && ('background-image: url('+this.GoodsThumbnail.Thumbnail+')') || ''"}}"></div>
          </div>
          <div class="detail-content-wrap">
            <div class="list inline-labels no-hairlines thin-scrollbar" style="overflow: auto; max-height: 128px;">
              <ul>
                <li class="item-content item-input item-link" data-unit="{{id}}"  style="padding-left: 0; min-height: 2rem;">
                  <div class="item-inner">
                    <div class="item-title item-label">ĐVT</div>
                    <div class="unit text-color-blue item-after" style="text-align: right">
                      Tồn kho
                    </div>
                  </div>
                </li>
                {{#each Units}}
                <li class="item-content item-input item-link unitBtn" data-unit="{{id}}"  style="padding-left: 0; min-height: 2rem;">
                  <div class="item-inner">
                    <div class="item-title item-label">{{text}}</div>
                    <div class="unit text-color-blue item-after" style="text-align: right">
                      {{decimal Inventory}}
                    </div>
                  </div>
                </li>
                {{/each}}
                <!--<li class="item-content item-input item-link" data-unit="{{id}}"  style="padding-left: 0; min-height: 2rem;">
                  <div class="item-inner">
                    <div class="item-title item-label">Vị trí</div>
                    <div class="unit item-after text-color-blue" style="text-align: right">
                      {{ContainerShelfName}}/{{ContainerFindOrder}}
                    </div>
                  </div>
                </li>
                <li class="item-content item-input item-link" data-unit="{{id}}"  style="padding-left: 0; min-height: 2rem;">
                  <div class="item-inner">
                    <div class="item-title item-label">Tồn kho</div>
                    <div class="unit item-after text-color-orange" style="text-align: right">
                      {{decimal Inventory}}
                    </div>
                  </div>
                </li>
                <li class="item-link smart-select" name="AccessNumbers" data-open-in="popup" data-searchbar="true" data-virtual-list="true" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-to-selected-item="true" data-index="{{@index}}">
                  <select multiple>
                    <option value="">Chon...</option>
                    {{#each AccessNumbers}}
                    <option value="{{this}}">{{this}}</option>
                    {{/each}}
                  </select>
                  <div class="item-content">
                    <div class="item-inner">
                      <div class="item-title">Truy xuất</div>
                      <div class="item-after text-color-blue">{{text AccessNumbers}}</div>
                    </div>
                  </div>
                </li>
                -->
              </ul>
            </div>
          </div>
        </div>
        <div class="detail-footer">SKU: {{GoodsSku}}, ID: {{Goods}}{{#js_if "this.Categories && this.Categories.length > 0"}}, DM: {{text Categories}}{{/js_if}}</div>
      </div>
    </div>
    <div class="swipeout-actions-right">
      <a href="#" class="queueBtn open-more-actions swipeout-close swipeout-overswipe color-orange">more</a>
    </div>
  </li>
  `;

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: 'warehouse-goods-list',
      path: '/warehouse/goods-list',
      component: {
        template: /*html*/`
            <div class="page page-warehouse-goods-list no-toolbar-x" data-name="page-warehouse-goods-list">
              <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a class="link back {{textColorClass}}">
                      <i class="icon icon-back"></i>
                      <span class="if-not-md {{textColorClass}}">{{backTitle}}</span>
                    </a>
                  </div>
                  <div class="title">Hàng hóa</div>
                  <div class="right">
                    <!-- Link to enable searchbar -->
                    <a class="link icon-only searchbar-enable {{textColorClass}}" data-searchbar=".warehouse-goods-list-searchbar">
                      <i class="icon f7-icons if-not-md">search</i>
                      <i class="icon material-icons md-only">search</i>
                    </a>
                  </div>
                  <!-- Searchbar is a direct child of "navbar-inner" -->
                  <form class="searchbar warehouse-goods-list-searchbar searchbar-expandable">
                    <div class="searchbar-inner">
                      <div class="searchbar-input-wrap">
                        <input type="search" placeholder="Search" />
                        <i class="searchbar-icon"></i><span class="input-clear-button"></span>
                        
                      </div>
                      <span class="searchbar-disable-button {{textColorClass}}">Hủy</span>
                    </div>
                  </form>
                </div>
              </div>
              <div class="page-content ptr-content infinite-scroll-content" @ptr:refresh="refresh">
                <div class="ptr-preloader">
                  <div class="preloader"></div>
                  <div class="ptr-arrow"></div>
                </div>
                <div class="searchbar-backdrop warehouse-goods-list-backdrop"></div>

                <div class="block-title" style="margin-top: 2rem">Bộ lọc <a style="float: right" @click="resetFilter"
                class="text-color-red">đặt lại</a></div>
                <div class="block block-strong inset filter" style="padding: 0; overflow: hidden">
                  <div class="list accordion-list inline-labels no-hairlines" style="margin: 0;">
                    <ul>
                      <li>
                        <a class="item-link smart-select smart-select-init" name="Categories" data-open-in="popup" data-searchbar="true" data-virtual-list="true" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFilterFieldChange">
                            <select multiple>
                              <option value="">Chọn</option>
                              {{#each categoryList}}
                              <option value="{{id}}">{{text}}</option>
                              {{/each}}
                            </select>
                            <div class="item-content">
                              <div class="item-inner">
                                <div class="item-title">Danh mục</div>
                              </div>
                            </div>
                          </a>
                      </li>
                      <li>
                        <a class="item-link smart-select smart-select-init" name="ContainerShelf" data-open-in="popup" data-searchbar="true" data-virtual-list="true" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFilterFieldChange">
                            <select multiple>
                              <option value="">Chọn</option>
                              {{#each shelfList}}
                              <option value="{{id}}">{{text}}</option>
                              {{/each}}
                            </select>
                            <div class="item-content">
                              <div class="item-inner">
                                <div class="item-title">Kệ</div>
                              </div>
                            </div>
                          </a>
                      </li>
                      <li>
                        <a class="item-link smart-select smart-select-init" name="Unit" data-open-in="popup" data-searchbar="true" data-virtual-list="true" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFilterFieldChange">
                            <select multiple>
                              <option value="">Chọn</option>
                              {{#each unitList}}
                              <option value="{{id}}">{{text}}</option>
                              {{/each}}
                            </select>
                            <div class="item-content">
                              <div class="item-inner">
                                <div class="item-title">ĐVT</div>
                              </div>
                            </div>
                          </a>
                      </li>
                      <li>
                        <a class="item-link smart-select smart-select-init" name="OutOfStockType" data-open-in="popup" data-searchbar="true" data-virtual-list="true" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFilterFieldChange">
                            <select>
                              <option value="">Chọn</option>
                              <option value="LOWSTOCK">Sắp hết hàng</option>
                              <option value="OUTOFSTOCK">Hết hàng</option>
                              <option value="NAGATIVE">Tồn âm</option>
                            </select>
                            <div class="item-content">
                              <div class="item-inner">
                                <div class="item-title">Tồn kho</div>
                              </div>
                            </div>
                          </a>
                      </li>
                    </ul>
                  </div>
                </div>
            
                <div class="block-title">Hàng hóa/dịch vụ <a style="float: right" @click="openProduct"
                    class="{{textColorClass}}">+ thêm</a></div>
                <div class="virtual-list warehouse-goods-list-virtual-list thumbnail-list"></div>


                {{#if infiniteLoadLast}}
                <div style="text-align: center" class="text-color-gray">end</div>
                {{else}}
                <div class="preloader color-blue infinite-scroll-preloader">
                    <span class="preloader-inner">
                        <span class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                            class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                            class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                            class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                            class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                            class="preloader-inner-line"></span><span class="preloader-inner-line"></span>
                    </span>
                </div>
                {{/if}}
            
                <div class="block"><br></div>
            
              </div>
            </div>
        `,
        style: /*css*/`
          .page-warehouse-goods-list .thumbnail-list ul {
            padding: 0;
            margin: 0;
          }
        `,
        data() {
          return {
            title: $this.title,
            reminder: {
              No: '234234',
            },
            priceReport: { Details: [] },
            infiniteLoadLast: false,
          };
        },
        methods: {
          resetFilter(e) {
            $this.resetFilter(this, e);
          },
          onFilterFieldChange(e) {
            $this.onFilterFieldChange(this, e);
          },
          openProduct(e, id?: string) {
            const self: F7ComponentContextExtend = this;
            id = id || self.$(e.target).closest('li').data('id') || 'new';

            $this.rootServices.navigate('/admin-product/product/' + id, {
              context: {
                backTitle: $this.title,
                textColorClass: self.textColorClass,
                callback: (data: { case: string, product: ProductModel }) => {
                  console.log(data);
                  self.refresh();
                }
              }
            });
          },
          // createNewProduct(e) {
          //   const self: F7ComponentContextExtend = this;

          //   $this.rootServices.navigate('/admin-product/product/new', {
          //     context: {
          //       backTitle: $this.title,
          //       textColorClass: self.textColorClass,
          //       calback: (data: { case: string, product: ProductModel }) => {
          //         console.log(data);
          //       }
          //     }
          //   });
          // },
          chooseOne(e) {
            const self: F7ComponentContextExtend = this;
            const id = self.$(e.target).closest('li').data('id');
            self.$route.context['onChoose'] && self.$route.context['onChoose'](self.productList.find(f => f.Code === id));
          },
          chooseProduct(e) {

          },
          async refresh(e, done) {

            $this.refresh(this).then(rs => {
              done && done();
            }).catch(err => {
              done && done();
              $this.commonService.showError(err);
            });

            return true;
          },
          async addGoodsPicture(e) {
            console.debug(e);
            const self: F7ComponentContextExtend = this;
            const id = self.$(e.target).closest('.product-item').data('id');
            const oldProduct = self.productList.find(f => f.Code == id);
            if (!oldProduct) {
              console.error('Not found');
            }

            try {

              self.$f7.dialog.create({
                title: 'Hình ảnh sản phẩm',
                text: 'Bạn muốn xem hình hay thêm hình cho sản phẩm ?',
                buttons: [
                  {
                    text: 'Xem hình',
                    onClick: () => {


                      $this.browseAttachment(oldProduct?.Pictures.map(m => ({
                        Id: m.Id,
                        Type: m.Type,
                        DataType: m.MimeType,
                        Ext: m.Extension,
                        Name: oldProduct.Name,
                        Description: oldProduct.Name,
                        DateOfAttach: oldProduct.Created,
                        // File: string;
                        Thumbnail: m.Thumbnail,
                        Url: m.OriginImage,
                        SmallUrl: m.SmallImage,
                        LargeUrl: m.LargeImage,
                        OriginUrl: m.OriginImage,
                      })) as any, oldProduct.FeaturePicture?.Id, (attachment => {
                        console.debug('download index', attachment);
                        if (/image/.test(attachment.Type)) {
                          // $this.currentState.instance.$app.dialog.confirm([attachment.name, attachment.description].filter(f => !!f).join('<br>') || 'Đính kèm', 'Xác nhận tải về', async () => {
                          //   $this.rootServices.saveImageToPhone(attachment.payload.url as string);
                          // });
                          $this.currentState.instance.$app.dialog.create({
                            title: 'Xác nhận tải về',
                            content: 'Đính kèm',
                            buttons: [
                              {
                                text: 'Đóng',
                                color: 'red',
                                close: true,
                              },
                              {
                                text: 'Mở link',
                                onClick: () => {
                                  $this.rootServices.saveImageToPhone(attachment.Url as string);
                                }
                              }
                            ],
                          }).open();
                        } else if (/video/.test(attachment.Type)) {
                          $this.playVideo((attachment.OriginUrl || attachment.Url) as string);
                        } else {
                          $this.rootServices.iab.create((attachment.OriginUrl || attachment.Url) as string, '_system');
                        }
                      })).then(() => {
                        console.log('Photo browser was closed');
                      });
                    }
                  },
                  {
                    text: 'Thêm hình',
                    onClick: async () => {
                      const localFiles = await $this.rootServices.pickFiles();
                      console.debug(localFiles);

                      let image: FileModel = null;
                      if (localFiles[0].dataType === 'url') {
                        image = await $this.rootServices.uploadLocalFile(localFiles[0].url as string);
                      } else {
                        const formData = new FormData();
                        const imgBlob = new Blob([localFiles[0].url], {
                          type: localFiles[0].type
                        });
                        formData.append('file', imgBlob, 'smart-bot-' + Date.now() + '.' + localFiles[0].ext);
                        image = await $this.rootServices.uploadFileData(formData);
                      }
                      console.debug(image);
                      e.target.style.backgroundImage = 'url(' + image.Thumbnail + ')';

                      // const updateProfileResult = await $this.rootServices.apiService.putPromise('/user/users/updateAccount', {}, { Avatar: image.GlobalId, AvatarThumbnail: image.Thumbnail })
                      const pictures = oldProduct.Pictures || [];
                      pictures.push(image);
                      const updateProfileResult = await $this.rootServices.apiService.putPromise('/admin-product/products/' + id, {}, [{ Code: id, FeaturePicture: image, Pictures: pictures }]);
                      console.debug('Update product picture sucess: ', updateProfileResult);
                      $this.commonService.showInfo('Đã thêm hình ảnh cho hàng hóa: ' + oldProduct.Name);

                      // Update auth user
                      const oldUser = $this.rootServices.authService.user$.getValue();
                      oldUser.avatar = oldUser.avatar || { id: '', payload: {} };
                      oldUser.avatar = { ...oldUser.avatar, payload: { ...oldUser.avatar.payload, thumbnail: image.Thumbnail, url: image.DownloadLink } }
                      $this.rootServices.authService.user$.next(oldUser);

                    }
                  },
                  {
                    text: 'Trở về',
                  },
                ],
                verticalButtons: true,
              }).open();


            } catch (err) {
              console.error(err);
              $this.commonService.showError(err);
            }

          },
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          pageInit(e, page: F7Page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;

            self.calendarDateTime = self.$f7.calendar.create({
              // openIn: 'customModal',
              inputEl: page.$el.find('input[name="RemindAtDateTime"]'),
              timePicker: true,
              dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' },
              // header: true,
              footer: true,
            });

            $this.onComponentInit({ instance: self }, self.$route?.params?.action || 'main', null, page);

            $this.rootServices.authService.isAuthenticatedOrRefresh().pipe(take(1)).toPromise().then(() => {
              self.$setState({
                backTitle: self.$route.context && self.$route.context.backTitle || 'Back', title: self.$route.context && self.$route.context.title || 'Profile',
              });

            }).catch(err => {
              console.error(err);
              $this.commonService.showError(err);
            });

          },
          // pageBeforeIn(e, page) {
          //     console.log('page before in');
          //     const self: F7ComponentContextExtend = this;
          //     self.refresh();
          // },
          // pageAfterIn(e, page) {
          //     console.log('page after in');
          // },
          pageBeforeOut(e, page) {
            const self: F7ComponentContextExtend = this;
            console.log('page before out');
            $this.onBeforeChangedState({ instance: self, page, scrollPosition: $(page.el).find('.page-content').scrollTop() }, self.componentIndex);
          },
          // pageAfterOut(e, page) {
          //     console.log('page after out');
          // },
          // pageBeforeUnmount(e, page) {
          //     console.log('page before unmount');
          // },
          // pageBeforeRemove(e, page) {
          //     console.log('page before remove');
          // },
          pageBeforeRemove(e, page) {
            console.log('[page event] before remove', page.route.url);
            const self: F7ComponentContextExtend = this;
            // const starmtSelectRoles = self.$app.smartSelect.get('.smart-select');
            // starmtSelectRoles.destroy();
          },
        },
      },
    };
  }
}
