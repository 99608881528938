import { Dialog } from 'framework7/build/core/components/dialog/dialog';
import { Injectable } from '@angular/core';
import Framework7, { Template7 } from 'framework7/build/core/framework7.esm.bundle';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { FrameSocket } from '../lib/frame-socket/frame-socket';
import { F7View } from '../types/framework7-types';
import { Storage } from '@ionic/storage';
import { ProductModel } from '../model/product.model';
import { filter, take, takeUntil } from 'rxjs/operators';
import { ProcessMap } from '../model/model';
import { ContactModel } from '../model/contact.model';
import { FileModel } from '../model/file.model';
import { Attachment } from '../lib/nam-chat/model/message';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private DIACRITICS = {
    'Ⓐ': 'A', 'Ａ': 'A', 'À': 'A', 'Á': 'A', 'Â': 'A', 'Ầ': 'A', 'Ấ': 'A', 'Ẫ': 'A', 'Ẩ': 'A', 'Ã': 'A', 'Ā': 'A', 'Ă': 'A', 'Ằ': 'A', 'Ắ': 'A',
    'Ẵ': 'A', 'Ẳ': 'A', 'Ȧ': 'A', 'Ǡ': 'A', 'Ä': 'A', 'Ǟ': 'A', 'Ả': 'A', 'Å': 'A', 'Ǻ': 'A', 'Ǎ': 'A', 'Ȁ': 'A', 'Ȃ': 'A', 'Ạ': 'A', 'Ậ': 'A', 'Ặ': 'A', 'Ḁ': 'A',
    'Ą': 'A', 'Ⱥ': 'A', 'Ɐ': 'A', 'Ꜳ': 'AA', 'Æ': 'AE', 'Ǽ': 'AE', 'Ǣ': 'AE', 'Ꜵ': 'AO', 'Ꜷ': 'AU', 'Ꜹ': 'AV', 'Ꜻ': 'AV', 'Ꜽ': 'AY', 'Ⓑ': 'B', 'Ｂ': 'B', 'Ḃ': 'B',
    'Ḅ': 'B', 'Ḇ': 'B', 'Ƀ': 'B', 'Ƃ': 'B', 'Ɓ': 'B', 'Ⓒ': 'C', 'Ｃ': 'C', 'Ć': 'C', 'Ĉ': 'C', 'Ċ': 'C', 'Č': 'C', 'Ç': 'C', 'Ḉ': 'C', 'Ƈ': 'C', 'Ȼ': 'C', 'Ꜿ': 'C',
    'Ⓓ': 'D', 'Ｄ': 'D', 'Ḋ': 'D', 'Ď': 'D', 'Ḍ': 'D', 'Ḑ': 'D', 'Ḓ': 'D', 'Ḏ': 'D', 'Đ': 'D', 'Ƌ': 'D', 'Ɗ': 'D', 'Ɖ': 'D', 'Ꝺ': 'D', 'Ǳ': 'DZ', 'Ǆ': 'DZ', 'ǲ': 'Dz',
    'ǅ': 'Dz', 'Ⓔ': 'E', 'Ｅ': 'E', 'È': 'E', 'É': 'E', 'Ê': 'E', 'Ề': 'E', 'Ế': 'E', 'Ễ': 'E', 'Ể': 'E', 'Ẽ': 'E', 'Ē': 'E', 'Ḕ': 'E', 'Ḗ': 'E', 'Ĕ': 'E', 'Ė': 'E',
    'Ë': 'E', 'Ẻ': 'E', 'Ě': 'E', 'Ȅ': 'E', 'Ȇ': 'E', 'Ẹ': 'E', 'Ệ': 'E', 'Ȩ': 'E', 'Ḝ': 'E', 'Ę': 'E', 'Ḙ': 'E', 'Ḛ': 'E', 'Ɛ': 'E', 'Ǝ': 'E', 'Ⓕ': 'F', 'Ｆ': 'F',
    'Ḟ': 'F', 'Ƒ': 'F', 'Ꝼ': 'F', 'Ⓖ': 'G', 'Ｇ': 'G', 'Ǵ': 'G', 'Ĝ': 'G', 'Ḡ': 'G', 'Ğ': 'G', 'Ġ': 'G', 'Ǧ': 'G', 'Ģ': 'G', 'Ǥ': 'G', 'Ɠ': 'G', 'Ꞡ': 'G', 'Ᵹ': 'G',
    'Ꝿ': 'G', 'Ⓗ': 'H', 'Ｈ': 'H', 'Ĥ': 'H', 'Ḣ': 'H', 'Ḧ': 'H', 'Ȟ': 'H', 'Ḥ': 'H', 'Ḩ': 'H', 'Ḫ': 'H', 'Ħ': 'H', 'Ⱨ': 'H', 'Ⱶ': 'H', 'Ɥ': 'H', 'Ⓘ': 'I', 'Ｉ': 'I', 'Ì': 'I',
    'Í': 'I', 'Î': 'I', 'Ĩ': 'I', 'Ī': 'I', 'Ĭ': 'I', 'İ': 'I', 'Ï': 'I', 'Ḯ': 'I', 'Ỉ': 'I', 'Ǐ': 'I', 'Ȉ': 'I', 'Ȋ': 'I', 'Ị': 'I', 'Į': 'I', 'Ḭ': 'I', 'Ɨ': 'I', 'Ⓙ': 'J',
    'Ｊ': 'J', 'Ĵ': 'J', 'Ɉ': 'J', 'Ⓚ': 'K', 'Ｋ': 'K', 'Ḱ': 'K', 'Ǩ': 'K', 'Ḳ': 'K', 'Ķ': 'K', 'Ḵ': 'K', 'Ƙ': 'K', 'Ⱪ': 'K', 'Ꝁ': 'K', 'Ꝃ': 'K', 'Ꝅ': 'K', 'Ꞣ': 'K', 'Ⓛ': 'L',
    'Ｌ': 'L', 'Ŀ': 'L', 'Ĺ': 'L', 'Ľ': 'L', 'Ḷ': 'L', 'Ḹ': 'L', 'Ļ': 'L', 'Ḽ': 'L', 'Ḻ': 'L', 'Ł': 'L', 'Ƚ': 'L', 'Ɫ': 'L', 'Ⱡ': 'L', 'Ꝉ': 'L', 'Ꝇ': 'L',
    'Ꞁ': 'L', 'Ǉ': 'LJ', 'ǈ': 'Lj', 'Ⓜ': 'M', 'Ｍ': 'M', 'Ḿ': 'M', 'Ṁ': 'M', 'Ṃ': 'M', 'Ɱ': 'M', 'Ɯ': 'M', 'Ⓝ': 'N', 'Ｎ': 'N', 'Ǹ': 'N', 'Ń': 'N', 'Ñ': 'N', 'Ṅ': 'N',
    'Ň': 'N', 'Ṇ': 'N', 'Ņ': 'N', 'Ṋ': 'N', 'Ṉ': 'N', 'Ƞ': 'N', 'Ɲ': 'N', 'Ꞑ': 'N', 'Ꞥ': 'N', 'Ǌ': 'NJ', 'ǋ': 'Nj', 'Ⓞ': 'O', 'Ｏ': 'O', 'Ò': 'O', 'Ó': 'O', 'Ô': 'O',
    'Ồ': 'O', 'Ố': 'O', 'Ỗ': 'O', 'Ổ': 'O', 'Õ': 'O', 'Ṍ': 'O', 'Ȭ': 'O', 'Ṏ': 'O', 'Ō': 'O', 'Ṑ': 'O', 'Ṓ': 'O', 'Ŏ': 'O', 'Ȯ': 'O', 'Ȱ': 'O', 'Ö': 'O', 'Ȫ': 'O', 'Ỏ': 'O',
    'Ő': 'O', 'Ǒ': 'O', 'Ȍ': 'O', 'Ȏ': 'O', 'Ơ': 'O', 'Ờ': 'O', 'Ớ': 'O', 'Ỡ': 'O', 'Ở': 'O', 'Ợ': 'O', 'Ọ': 'O', 'Ộ': 'O', 'Ǫ': 'O', 'Ǭ': 'O', 'Ø': 'O', 'Ǿ': 'O', 'Ɔ': 'O',
    'Ɵ': 'O', 'Ꝋ': 'O', 'Ꝍ': 'O', 'Œ': 'OE', 'Ƣ': 'OI', 'Ꝏ': 'OO', 'Ȣ': 'OU', 'Ⓟ': 'P', 'Ｐ': 'P', 'Ṕ': 'P', 'Ṗ': 'P', 'Ƥ': 'P', 'Ᵽ': 'P', 'Ꝑ': 'P', 'Ꝓ': 'P', 'Ꝕ': 'P',
    'Ⓠ': 'Q', 'Ｑ': 'Q', 'Ꝗ': 'Q', 'Ꝙ': 'Q', 'Ɋ': 'Q', 'Ⓡ': 'R', 'Ｒ': 'R', 'Ŕ': 'R', 'Ṙ': 'R', 'Ř': 'R', 'Ȑ': 'R', 'Ȓ': 'R', 'Ṛ': 'R', 'Ṝ': 'R', 'Ŗ': 'R', 'Ṟ': 'R', 'Ɍ': 'R',
    'Ɽ': 'R', 'Ꝛ': 'R', 'Ꞧ': 'R', 'Ꞃ': 'R', 'Ⓢ': 'S', 'Ｓ': 'S', 'ẞ': 'S', 'Ś': 'S', 'Ṥ': 'S', 'Ŝ': 'S', 'Ṡ': 'S', 'Š': 'S', 'Ṧ': 'S', 'Ṣ': 'S', 'Ṩ': 'S', 'Ș': 'S',
    'Ş': 'S', 'Ȿ': 'S', 'Ꞩ': 'S', 'Ꞅ': 'S', 'Ⓣ': 'T', 'Ｔ': 'T', 'Ṫ': 'T', 'Ť': 'T', 'Ṭ': 'T', 'Ț': 'T', 'Ţ': 'T', 'Ṱ': 'T', 'Ṯ': 'T', 'Ŧ': 'T', 'Ƭ': 'T', 'Ʈ': 'T', 'Ⱦ': 'T',
    'Ꞇ': 'T', 'Ꜩ': 'TZ', 'Ⓤ': 'U', 'Ｕ': 'U', 'Ù': 'U', 'Ú': 'U', 'Û': 'U', 'Ũ': 'U', 'Ṹ': 'U', 'Ū': 'U', 'Ṻ': 'U', 'Ŭ': 'U', 'Ü': 'U', 'Ǜ': 'U', 'Ǘ': 'U', 'Ǖ': 'U', 'Ǚ': 'U',
    'Ủ': 'U', 'Ů': 'U', 'Ű': 'U', 'Ǔ': 'U', 'Ȕ': 'U', 'Ȗ': 'U', 'Ư': 'U', 'Ừ': 'U', 'Ứ': 'U', 'Ữ': 'U', 'Ử': 'U', 'Ự': 'U', 'Ụ': 'U', 'Ṳ': 'U', 'Ų': 'U', 'Ṷ': 'U', 'Ṵ': 'U',
    'Ʉ': 'U', 'Ⓥ': 'V', 'Ｖ': 'V', 'Ṽ': 'V', 'Ṿ': 'V', 'Ʋ': 'V', 'Ꝟ': 'V', 'Ʌ': 'V', 'Ꝡ': 'VY', 'Ⓦ': 'W', 'Ｗ': 'W', 'Ẁ': 'W', 'Ẃ': 'W', 'Ŵ': 'W', 'Ẇ': 'W', 'Ẅ': 'W',
    'Ẉ': 'W', 'Ⱳ': 'W', 'Ⓧ': 'X', 'Ｘ': 'X', 'Ẋ': 'X', 'Ẍ': 'X', 'Ⓨ': 'Y', 'Ｙ': 'Y', 'Ỳ': 'Y', 'Ý': 'Y', 'Ŷ': 'Y', 'Ỹ': 'Y', 'Ȳ': 'Y', 'Ẏ': 'Y', 'Ÿ': 'Y', 'Ỷ': 'Y', 'Ỵ': 'Y',
    'Ƴ': 'Y', 'Ɏ': 'Y', 'Ỿ': 'Y', 'Ⓩ': 'Z', 'Ｚ': 'Z', 'Ź': 'Z', 'Ẑ': 'Z', 'Ż': 'Z', 'Ž': 'Z', 'Ẓ': 'Z', 'Ẕ': 'Z', 'Ƶ': 'Z', 'Ȥ': 'Z', 'Ɀ': 'Z', 'Ⱬ': 'Z', 'Ꝣ': 'Z',
    'ⓐ': 'a', 'ａ': 'a', 'ẚ': 'a', 'à': 'a', 'á': 'a', 'â': 'a', 'ầ': 'a', 'ấ': 'a', 'ẫ': 'a', 'ẩ': 'a', 'ã': 'a', 'ā': 'a', 'ă': 'a', 'ằ': 'a', 'ắ': 'a', 'ẵ': 'a', 'ẳ': 'a',
    'ȧ': 'a', 'ǡ': 'a', 'ä': 'a', 'ǟ': 'a', 'ả': 'a', 'å': 'a', 'ǻ': 'a', 'ǎ': 'a', 'ȁ': 'a', 'ȃ': 'a', 'ạ': 'a', 'ậ': 'a', 'ặ': 'a', 'ḁ': 'a', 'ą': 'a', 'ⱥ': 'a',
    'ɐ': 'a', 'ꜳ': 'aa', 'æ': 'ae', 'ǽ': 'ae', 'ǣ': 'ae', 'ꜵ': 'ao', 'ꜷ': 'au', 'ꜹ': 'av', 'ꜻ': 'av', 'ꜽ': 'ay', 'ⓑ': 'b', 'ｂ': 'b', 'ḃ': 'b', 'ḅ': 'b',
    'ḇ': 'b', 'ƀ': 'b', 'ƃ': 'b', 'ɓ': 'b', 'ⓒ': 'c', 'ｃ': 'c', 'ć': 'c', 'ĉ': 'c', 'ċ': 'c', 'č': 'c', 'ç': 'c', 'ḉ': 'c', 'ƈ': 'c', 'ȼ': 'c', 'ꜿ': 'c', 'ↄ': 'c',
    'ⓓ': 'd', 'ｄ': 'd', 'ḋ': 'd', 'ď': 'd', 'ḍ': 'd', 'ḑ': 'd', 'ḓ': 'd', 'ḏ': 'd', 'đ': 'd', 'ƌ': 'd', 'ɖ': 'd', 'ɗ': 'd', 'ꝺ': 'd', 'ǳ': 'dz', 'ǆ': 'dz', 'ⓔ': 'e',
    'ｅ': 'e', 'è': 'e', 'é': 'e', 'ê': 'e', 'ề': 'e', 'ế': 'e', 'ễ': 'e', 'ể': 'e', 'ẽ': 'e', 'ē': 'e', 'ḕ': 'e', 'ḗ': 'e', 'ĕ': 'e', 'ė': 'e', 'ë': 'e', 'ẻ': 'e',
    'ě': 'e', 'ȅ': 'e', 'ȇ': 'e', 'ẹ': 'e', 'ệ': 'e', 'ȩ': 'e', 'ḝ': 'e', 'ę': 'e', 'ḙ': 'e', 'ḛ': 'e', 'ɇ': 'e', 'ɛ': 'e', 'ǝ': 'e', 'ⓕ': 'f', 'ｆ': 'f', 'ḟ': 'f',
    'ƒ': 'f', 'ꝼ': 'f', 'ⓖ': 'g', 'ｇ': 'g', 'ǵ': 'g', 'ĝ': 'g', 'ḡ': 'g', 'ğ': 'g', 'ġ': 'g', 'ǧ': 'g', 'ģ': 'g', 'ǥ': 'g', 'ɠ': 'g', 'ꞡ': 'g', 'ᵹ': 'g', 'ꝿ': 'g',
    'ⓗ': 'h', 'ｈ': 'h', 'ĥ': 'h', 'ḣ': 'h', 'ḧ': 'h', 'ȟ': 'h', 'ḥ': 'h', 'ḩ': 'h', 'ḫ': 'h', 'ẖ': 'h', 'ħ': 'h', 'ⱨ': 'h', 'ⱶ': 'h', 'ɥ': 'h', 'ƕ': 'hv', 'ⓘ': 'i', 'ｉ': 'i',
    'ì': 'i', 'í': 'i', 'î': 'i', 'ĩ': 'i', 'ī': 'i', 'ĭ': 'i', 'ï': 'i', 'ḯ': 'i', 'ỉ': 'i', 'ǐ': 'i', 'ȉ': 'i', 'ȋ': 'i', 'ị': 'i', 'į': 'i', 'ḭ': 'i', 'ɨ': 'i',
    'ı': 'i', 'ⓙ': 'j', 'ｊ': 'j', 'ĵ': 'j', 'ǰ': 'j', 'ɉ': 'j', 'ⓚ': 'k', 'ｋ': 'k', 'ḱ': 'k', 'ǩ': 'k', 'ḳ': 'k', 'ķ': 'k', 'ḵ': 'k', 'ƙ': 'k', 'ⱪ': 'k', 'ꝁ': 'k', 'ꝃ': 'k',
    'ꝅ': 'k', 'ꞣ': 'k', 'ⓛ': 'l', 'ｌ': 'l', 'ŀ': 'l', 'ĺ': 'l', 'ľ': 'l', 'ḷ': 'l', 'ḹ': 'l', 'ļ': 'l', 'ḽ': 'l', 'ḻ': 'l', 'ſ': 'l', 'ł': 'l', 'ƚ': 'l', 'ɫ': 'l', 'ⱡ': 'l',
    'ꝉ': 'l', 'ꞁ': 'l', 'ꝇ': 'l', 'ǉ': 'lj', 'ⓜ': 'm', 'ｍ': 'm', 'ḿ': 'm', 'ṁ': 'm', 'ṃ': 'm', 'ɱ': 'm', 'ɯ': 'm', 'ⓝ': 'n', 'ｎ': 'n', 'ǹ': 'n', 'ń': 'n', 'ñ': 'n', 'ṅ': 'n',
    'ň': 'n', 'ṇ': 'n', 'ņ': 'n', 'ṋ': 'n', 'ṉ': 'n', 'ƞ': 'n', 'ɲ': 'n', 'ŉ': 'n', 'ꞑ': 'n', 'ꞥ': 'n', 'ǌ': 'nj', 'ⓞ': 'o', 'ｏ': 'o', 'ò': 'o', 'ó': 'o', 'ô': 'o', 'ồ': 'o',
    'ố': 'o', 'ỗ': 'o', 'ổ': 'o', 'õ': 'o', 'ṍ': 'o', 'ȭ': 'o', 'ṏ': 'o', 'ō': 'o', 'ṑ': 'o', 'ṓ': 'o', 'ŏ': 'o', 'ȯ': 'o', 'ȱ': 'o', 'ö': 'o', 'ȫ': 'o', 'ỏ': 'o', 'ő': 'o',
    'ǒ': 'o', 'ȍ': 'o', 'ȏ': 'o', 'ơ': 'o', 'ờ': 'o', 'ớ': 'o', 'ỡ': 'o', 'ở': 'o', 'ợ': 'o', 'ọ': 'o', 'ộ': 'o', 'ǫ': 'o', 'ǭ': 'o', 'ø': 'o', 'ǿ': 'o', 'ɔ': 'o', 'ꝋ': 'o',
    'ꝍ': 'o', 'ɵ': 'o', 'œ': 'oe', 'ƣ': 'oi', 'ȣ': 'ou', 'ꝏ': 'oo', 'ⓟ': 'p', 'ｐ': 'p', 'ṕ': 'p', 'ṗ': 'p', 'ƥ': 'p', 'ᵽ': 'p', 'ꝑ': 'p', 'ꝓ': 'p', 'ꝕ': 'p', 'ⓠ': 'q',
    'ｑ': 'q', 'ɋ': 'q', 'ꝗ': 'q', 'ꝙ': 'q', 'ⓡ': 'r', 'ｒ': 'r', 'ŕ': 'r', 'ṙ': 'r', 'ř': 'r', 'ȑ': 'r', 'ȓ': 'r', 'ṛ': 'r', 'ṝ': 'r', 'ŗ': 'r', 'ṟ': 'r', 'ɍ': 'r', 'ɽ': 'r',
    'ꝛ': 'r', 'ꞧ': 'r', 'ꞃ': 'r', 'ⓢ': 's', 'ｓ': 's', 'ß': 's', 'ś': 's', 'ṥ': 's', 'ŝ': 's', 'ṡ': 's', 'š': 's', 'ṧ': 's', 'ṣ': 's', 'ṩ': 's', 'ș': 's', 'ş': 's', 'ȿ': 's',
    'ꞩ': 's', 'ꞅ': 's', 'ẛ': 's', 'ⓣ': 't', 'ｔ': 't', 'ṫ': 't', 'ẗ': 't', 'ť': 't', 'ṭ': 't', 'ț': 't', 'ţ': 't', 'ṱ': 't', 'ṯ': 't', 'ŧ': 't', 'ƭ': 't', 'ʈ': 't', 'ⱦ': 't',
    'ꞇ': 't', 'ꜩ': 'tz', 'ⓤ': 'u', 'ｕ': 'u', 'ù': 'u', 'ú': 'u', 'û': 'u', 'ũ': 'u', 'ṹ': 'u', 'ū': 'u', 'ṻ': 'u', 'ŭ': 'u', 'ü': 'u', 'ǜ': 'u', 'ǘ': 'u', 'ǖ': 'u', 'ǚ': 'u',
    'ủ': 'u', 'ů': 'u', 'ű': 'u', 'ǔ': 'u', 'ȕ': 'u', 'ȗ': 'u', 'ư': 'u', 'ừ': 'u', 'ứ': 'u', 'ữ': 'u', 'ử': 'u', 'ự': 'u', 'ụ': 'u', 'ṳ': 'u', 'ų': 'u', 'ṷ': 'u', 'ṵ': 'u',
    'ʉ': 'u', 'ⓥ': 'v', 'ｖ': 'v', 'ṽ': 'v', 'ṿ': 'v', 'ʋ': 'v', 'ꝟ': 'v', 'ʌ': 'v', 'ꝡ': 'vy', 'ⓦ': 'w', 'ｗ': 'w', 'ẁ': 'w', 'ẃ': 'w', 'ŵ': 'w', 'ẇ': 'w', 'ẅ': 'w', 'ẘ': 'w',
    'ẉ': 'w', 'ⱳ': 'w', 'ⓧ': 'x', 'ｘ': 'x', 'ẋ': 'x', 'ẍ': 'x', 'ⓨ': 'y', 'ｙ': 'y', 'ỳ': 'y', 'ý': 'y', 'ŷ': 'y', 'ỹ': 'y', 'ȳ': 'y', 'ẏ': 'y', 'ÿ': 'y', 'ỷ': 'y', 'ẙ': 'y',
    'ỵ': 'y', 'ƴ': 'y', 'ɏ': 'y', 'ỿ': 'y', 'ⓩ': 'z', 'ｚ': 'z', 'ź': 'z', 'ẑ': 'z', 'ż': 'z', 'ž': 'z', 'ẓ': 'z', 'ẕ': 'z', 'ƶ': 'z', 'ȥ': 'z', 'ɀ': 'z', 'ⱬ': 'z', 'ꝣ': 'z',
    'Ά': 'Α', 'Έ': 'Ε', 'Ή': 'Η', 'Ί': 'Ι', 'Ϊ': 'Ι', 'Ό': 'Ο', 'Ύ': 'Υ', 'Ϋ': 'Υ', 'Ώ': 'Ω', 'ά': 'α', 'έ': 'ε', 'ή': 'η', 'ί': 'ι', 'ϊ': 'ι', 'ΐ': 'ι', 'ό': 'ο', 'ύ': 'υ',
    'ϋ': 'υ', 'ΰ': 'υ', 'ώ': 'ω', 'ς': 'σ', '’': '\''
  };

  private takeUltilCount = {};
  private takeUltilPastCount = {};
  private takeOnceCount = {};
  private takeOncePastCount = {};

  // Custom dialogs
  public customListLinkDialog: Dialog.Dialog;

  onKeydownEvent$ = new Subject<KeyboardEvent>();
  onKeyupEvent$ = new Subject<KeyboardEvent>();

  private streams: { [key: string]: Observable<boolean> } = {};

  // Frame socket
  public frameSocket = new FrameSocket(window.top, this.storage);

  constructor(
    protected storage: Storage,
  ) {
    this.frameSocket.event$.subscribe(event => {
      if (event && event.name === 'remove-token') {
        this.navigate('/login');
      }
    });
  }

  get isFrameMode() {
    return this.frameSocket.isFrameMode;
  }

  convertUnicodeToNormal(text: string) {
    return text && text.replace(/[^\u0000-\u007E]/g, (a) => {
      return this.DIACRITICS[a] || a;
    }).replace(/[^a-z0-9 ]+/ig, '') || '';
  }

  smartFilter(value: string, query: string) {
    return (new RegExp(this.convertUnicodeToNormal(query).toLowerCase().replace(/\s+/g, '.*'), 'ig')).test(this.convertUnicodeToNormal(value));
  }
  rightDetectiveFilter(value: string, query: string) {
    return (new RegExp('^' + this.convertUnicodeToNormal(query).toLowerCase(), 'i')).test(this.convertUnicodeToNormal(value));
  }

  /** Anti duplicate action */
  // async takeUntil(context: string, delay: number): Promise<boolean> {
  //   const stream = this.streams[context] = this.streams[context] || new Observable<boolean>();
  //   stream.(true);
  // }
  async takeUntil(context: string, delay: number): Promise<boolean> {
    // console.debug('take until...');
    const result = new Promise<boolean>(resolve => {
      if (delay === 0) {
        resolve(true);
        return;
      }
      if (!this.takeUltilCount[context]) { this.takeUltilCount[context] = 0; }
      this.takeUltilCount[context]++;
      ((takeCount) => {
        setTimeout(() => {
          this.takeUltilPastCount[context] = takeCount;
        }, delay);
      })(this.takeUltilCount[context]);
      setTimeout(() => {
        if (this.takeUltilPastCount[context] === this.takeUltilCount[context]) {
          resolve(true);
        }
      }, delay);
    });
    return result;
  }

  /** Anti duplicate action */
  async takeOnce(context: string, delay: number): Promise<boolean> {
    const result = new Promise<boolean>(resolve => {
      // resolve(true);
      // if (delay === 0) {
      //   resolve(true);
      //   return;
      // }
      if (this.takeOncePastCount[context] === this.takeOnceCount[context]) {
        resolve(true);
      }
      if (!this.takeOnceCount[context]) { this.takeOnceCount[context] = 0; }
      this.takeOnceCount[context]++;
      ((takeCount) => {
        setTimeout(() => {
          this.takeOncePastCount[context] = takeCount;
        }, delay);
      })(this.takeOnceCount[context]);
      setTimeout(() => {
        if (this.takeOncePastCount[context] === this.takeOnceCount[context]) {
          this.takeOncePastCount[context] = null;
          this.takeOnceCount[context] = null;
          // resolve(true);
        }
      }, delay);
    });
    return result;
  }

  async waitFor(sleep: number, maxTry: number, check: () => Promise<boolean>) {
    return new Promise<void>((resovle, reject) => {
      let counter = 0;
      (async function loop() {

        if (++counter > maxTry) {
          reject('Timeout');
          return;
        }

        if (await check()) {
          resovle();
        } else {
          setTimeout(() => {
            loop();
          }, sleep);
        }

      })();
    });
  }

  async waiting(miliSecond: number) {
    return new Promise(resovle => setTimeout(() => resovle(true), miliSecond));
  }

  get f7app(): Framework7 {
    return (window as any).f7app;
  }

  navigateX(url: string, option?: { context?: any, view?: F7View, animate?: boolean }) {
    let view: F7View = option && option.view || (this.f7app && this.f7app.view && this.f7app.view.current);

    // Find relate view
    const views: F7View[] = (this.f7app && this.f7app.views) as any;
    if (views) for (const v of views) {
      if (v.history.some(f => f === url)) {
        view = v;
        break;
      }
    }

    if (!view) {
      console.error('view was not provided');
      return false;
    }

    // Active tab contain view
    // this.f7app.tab.show(this.f7app.$(''))
    this.f7app.tab.show(view.el, this.f7app.$('a[href="' + view.selector + '"]')[0] as HTMLElement);

    if (view.history.some(f => f === url)) {
      view.router.back(url, { force: true });
    } else {
      view.router.navigate(url, { context: option && option.context, animate: option && option.animate });
    }
  }

  async navigate(url: string, option?: { context?: any, view?: F7View, animate?: boolean }) {
    await this.waitFor(300, 200, async () => !!this.f7app);
    let view: F7View = option && option.view || this.f7app.view.current;

    // Find relate view
    const views: F7View[] = this.f7app.views as any;
    for (const v of views) {
      if (v.history.some(f => f === url)) {
        view = v;
        break;
      }
    }

    if (!view) {
      console.error('view was not provided');
      return false;
    }

    // Active tab contain view
    // this.f7app.tab.show(this.f7app.$(''))
    this.f7app.tab.show(view.el, this.f7app.$('a[href="' + view.selector + '"]')[0] as HTMLElement);

    // const historyIndex = view.history.some(f => f === url);
    if (view.history.some(f => f === url)) {
      let historySize = view.history.length;
      while (view.history[view.history.length - 1] !== url) {
        console.log('Route back');
        view.router.back();
        await this.waiting(300);
        // await this.commonService.waitFor(2000, 100, async() => {
        //     return historySize > view.history.length;
        // });
        // historySize = view.history.length;
      }
    } else {
      view.router.navigate(url, { context: option && option.context, animate: typeof option?.animate == 'undefined' ? true : option.animate });
    }
  }

  async goback(url: string, option?: { context?: any, view?: F7View, animate?: boolean }) {
    let view: F7View = option && option.view || this.f7app.view.current;

    // Find relate view
    const views: F7View[] = this.f7app.views as any;
    for (const v of views) {
      if (v.history.some(f => f === url)) {
        view = v;
        break;
      }
    }

    if (!view) {
      console.error('view was not provided');
      return false;
    }

    // Active tab contain view
    // this.f7app.tab.show(this.f7app.$(''))
    this.f7app.tab.show(view.el, this.f7app.$('a[href="' + view.selector + '"]')[0] as HTMLElement);

    // const historyIndex = view.history.some(f => f === url);
    if (view.history.some(f => f === url)) {
      let historySize = view.history.length;
      // while (view.history[view.history.length - 1] !== url) {
      console.log('Route back');
      view.router.back();
      // await this.waiting(300);
      // await this.commonService.waitFor(2000, 100, async() => {
      //     return historySize > view.history.length;
      // });
      // historySize = view.history.length;
      // }
    } else {
      view.router.navigate(url, { context: option && option.context });
    }
  }

  showError(err: any, option?: { timeout?: number, position?: 'top' | 'center' | 'bottom', cssClass?: string }) {
    console.error(err);
    if (err && err.column && err.line) {
      // Evaluting error => skip
      return;
    }
    if (err.error?.errorCode == 1062) {
      if (Array.isArray(err.error?.logs)) {
        err.error.logs.unshift('Dữ liệu đã tồn tại');
      }
    }
    return this.f7app?.toast?.create({
      text: err && err.logs && err.logs[0] && err.logs.join('<br>') || err && err.error && err.error.logs && err.error.logs.join('<br>') || err || 'Lỗi không xác định !',
      closeTimeout: option && option.timeout || 5000,
      cssClass: option?.cssClass || 'text-color-red',
      closeButton: true,
      position: option?.position || 'top',
    }).open();
  }

  showInfo(info: string, option?: { timeout?: number, position?: 'top' | 'center' | 'bottom', cssClass?: string }) {
    return this.f7app.toast.create({
      text: info || 'Không xác định !',
      closeTimeout: option && option.timeout || 5000,
      cssClass: option?.cssClass || 'text-color-default',
      closeButton: true,
      position: option?.position || 'top',
    }).open();
  }

  getObjectId(object: any) {
    return (['number', 'boolean'].indexOf(typeof object) > -1 || object instanceof Date) ? object : (typeof object === 'string' ? object : (object && object.id !== null && object.id || null));
  }
  getObjectText(object: any) {
    return (typeof object == 'number' || typeof object == 'boolean') ? object : (typeof object === 'string' ? object : (object && object.text !== null && object.text || null));
  }
  getStateLabel(id: string): { icon?: string, label?: string, status?: string } {
    return this.stateMap[id];
  }

  preloaderTimeoutProcess = null;
  showPreloader(timeout?: number) {
    if (this.f7app) {
      this.f7app.preloader.show();
      this.preloaderTimeoutProcess = setTimeout(() => {
        this.f7app.preloader.hide();
      }, timeout || 15000);
      return this.preloaderTimeoutProcess;
    }
  }

  hidePreloader() {
    if (this.f7app) {
      clearTimeout(this.preloaderTimeoutProcess);
      this.f7app.preloader.hide();
    }
  }

  htmlEncode(html: string) {
    var tagsToReplace = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;'
    };
    return html.replace(/[&<>]/g, function (tag) {
      return tagsToReplace[tag] || tag;
    });
  }

  getUrlParams(url: string) {
    var result = {};
    var tmp = [];

    url
      .substr(1)
      .split("&")
      .forEach(function (item) {
        tmp = item.split("=");
        result[tmp[0]] = decodeURIComponent(tmp[1]);
      });

    return result;
  }

  cleanMentionTags(content: string) {
    return content.replace(/([\@\#])\[([^\[\]]+)\]\((\w+)\:(\w+)\)/g, '$2').replace(/\n/g, '<br>');
  }

  private barcode = '';
  private barcodeProcessing = null;
  barcodeScanDetective(key: string, callback: (barcode: string) => void) {
    this.barcode += key;

    // this.takeUntil('barcode-scan-detective', 100).then(() => {
    //   this.barcode = '';
    // });
    clearTimeout(this.barcodeProcessing);
    this.barcodeProcessing = setTimeout(() => {
      console.log('clean barcode buffer');
      this.barcode = '';
    }, 300);
    console.log(this.barcode);
    if (this.barcode && /Enter$/.test(this.barcode)) {
      try {
        if (this.barcode.length > 5) {
          callback(this.barcode.replace(/Enter.*$/, ''));
        }
      } catch (err) {
        this.showError(err, { timeout: 5000 });
      }
      this.barcode = '';
    }
  }


  // Resource state definition
  newState: ProcessMap = {
    id: 'NEW',
    text: 'Mới',
    state: 'NEW',
    label: 'Mới',
    confirmLabel: 'Mới',
    status: 'success',
    color: 'red',
    outline: false,
    confirmTitle: 'Chuyển sang trạng thái MỚI',
    confirmText: 'Bạn có muốn chuyển sang trạng thái MỚI',
    responseTitle: 'Đã chuyển sang trạng thái MỚI',
    responseText: 'Đã chuyển sang trạng thái mới',
  };
  approvedState: ProcessMap = {
    id: 'APPROVED',
    text: 'Đã duyệt',
    state: 'APPROVED',
    label: 'Đã duyệt',
    confirmLabel: 'Duyệt',
    status: 'success',
    color: 'green',
    outline: true,
    confirmTitle: 'Duyệt phiếu',
    confirmText: 'Bạn có muốn duyệt phiếu này không ?',
    responseTitle: 'Duyệt phiếu',
    responseText: 'Đã duyệt phiếu',
  };
  priceReportState: ProcessMap = {
    id: 'PRICEREPORT',
    text: 'Báo giá',
    state: 'PRICEREPORT',
    label: 'Báo giá',
    confirmLabel: 'Báo giá',
    status: 'primary',
    color: 'blue',
    outline: true,
    confirmTitle: 'Báo giá',
    confirmText: 'Bạn có muốn báo giá',
    responseTitle: 'Đã báo giá',
    responseText: 'Đã báo giá',
  };
  processingState: ProcessMap = {
    id: 'PROCESSING',
    text: 'Đang xử lý',
    state: 'PROCESSING',
    label: 'Đang xử lý',
    confirmLabel: 'Xử lý',
    status: 'danger',
    color: 'red',
    outline: false,
    confirmTitle: 'Xử lý',
    confirmText: 'Bạn có muốn xử lý phiếu này không ?',
    responseTitle: 'Xử lý',
    responseText: 'Phiếu đã được chuyển sang thái đang xử lý !',
  };
  unrecordedState: ProcessMap = {
    id: 'UNRECORDED',
    text: 'Đã hủy',
    state: 'UNRECORDED',
    status: 'warning',
    color: 'orange',
    label: 'Đã hủy',
    confirmLabel: 'Hủy',
    confirmTitle: 'Hủy phiếu',
    confirmText: 'Bạn có muốn hủy phiếu',
    responseTitle: 'Hủy phiếu',
    responseText: 'Đã hủy phiếu',
  };
  queueState: ProcessMap = {
    id: 'QUEUE',
    text: 'Chờ xử lý',
    state: 'QUEUE',
    status: 'warning',
    color: 'orange',
    label: 'Chờ xử lý',
    confirmLabel: 'Chuyển sang chờ xử lý',
    confirmTitle: 'Chuyển sang chờ xử lý',
    confirmText: 'Bạn có muốn chuyển sang chờ xử lý',
    responseTitle: 'Chờ xử lý',
    responseText: 'Đã chuyển sang chờ xử lý',
  };
  confirmedState: ProcessMap = {
    id: 'CONFIRMED',
    text: 'Đã xác nhận',
    state: 'CONFIRMED',
    status: 'primary',
    color: 'blue',
    label: 'Đã xác nhận',
    confirmLabel: 'Xác nhận',
    confirmTitle: 'Xác nhận',
    confirmText: 'Bạn có muốn xác nhận không ?',
    responseTitle: 'Xác nhận',
    responseText: 'Đã xác nhận !',
  };
  convertedState: ProcessMap = {
    id: 'CONVERTED',
    text: 'Đã chuyển đổi',
    state: 'CONVERTED',
    label: 'Đã chuyển đổi',
    confirmLabel: 'Chuyển đổi',
    status: 'success',
    color: 'green',
    outline: true,
    confirmTitle: 'Chuyển đổi cơ hội',
    confirmText: 'Bạn có muốn chuyển đổi',
    responseTitle: 'Chuyển đổi cơ hội',
    responseText: 'Đã chuyển đổi',
    nextState: 'FREEZE',
  };
  notJustApprodedState: ProcessMap = {
    id: 'NOTJUSTAPPROVED',
    text: 'Chưa duyệt',
    state: 'NOTJUSTAPPROVED',
    label: 'Chưa duyệt',
    confirmLabel: 'Bỏ duyệt',
    status: 'warning',
    color: 'orange',
    outline: false,
    confirmTitle: 'Bỏ duyệt',
    confirmText: 'Bạn có muốn bỏ duyệt phiếu ?',
    responseTitle: 'Bỏ duyệt',
    responseText: 'Đã bỏ duyệt phiếu',
  };
  inQueueState: ProcessMap = {
    id: 'INQUEUE',
    text: 'Hàng đợi',
    state: 'INQUEUE',
    label: 'Hàng đợi',
    confirmLabel: 'Chuyển sang hàng đợi',
    status: 'warning',
    color: 'orange',
    outline: false,
    confirmTitle: 'Chuyển sang hàng đợi',
    confirmText: 'Bạn có muốn chuyển sang trạng thái đợi ?',
    responseTitle: 'Đã chuyển sang hàng đợi',
    responseText: 'Đã chuyển sang hàng đợi thành công',
  };
  confirmationRequestedState: ProcessMap = {
    id: 'CONFIRMATIONREQUESTED',
    text: 'Đã yêu cầu xác nhận',
    state: 'CONFIRMATIONREQUESTED',
    label: 'Đã yêu cầu xác nhận',
    confirmLabel: 'Yêu cầu xác nhận',
    status: 'info',
    color: 'blue',
    outline: false,
    confirmTitle: 'Yêu cầu xác nhận',
    confirmText: 'Bạn có muốn gửi yêu cầu xác nhận ?',
    responseTitle: 'Yêu cầu xác nhận',
    responseText: 'Đã gửi yêu cầu xác nhận',
  };
  acceptanceState: ProcessMap = {
    id: 'ACCEPTANCE',
    text: 'Đã chấp thuận',
    state: 'ACCEPTANCE',
    label: 'Đã chấp thuận',
    confirmLabel: 'Chấp thuận',
    status: 'info',
    color: 'blue',
    outline: false,
    confirmTitle: 'Chấp thuận',
    // nextStateLabel: '',
    confirmText: 'Bạn có chấp thuận phiếu này không ?',
    responseTitle: 'Chấp thuận',
    responseText: 'Đã chấp thuận !',
  };
  completeState: ProcessMap = {
    id: 'COMPLETED',
    text: 'Đã hoàn tất',
    state: 'COMPLETED',
    label: 'Đã hoàn tất',
    confirmLabel: 'Hoàn tất',
    status: 'basic',
    color: 'gray',
    outline: true,
    nextState: '',
    nextStateLabel: '',
    confirmTitle: 'Hoàn tất',
    confirmText: 'Bán có muốn hoàn tất không ?',
    responseTitle: 'Hoàn tất',
    responseText: 'Đã hòa tất !',
  };
  returnState: ProcessMap = {
    id: 'RETURN',
    text: 'Trả hàng',
    state: 'RETURN',
    label: 'Trả hàng',
    confirmLabel: 'Trả hàng',
    status: 'danger',
    color: 'red',
    outline: true,
    nextState: '',
    nextStateLabel: '',
    confirmText: 'Bạn có muốn chuyển sang trạng thái trả hàng',
    responseTitle: 'Trả hàng',
    responseText: 'Đã chuyển sang trạng thái trả hàng',
  };
  transportState: ProcessMap = {
    id: 'TRANSPORT',
    text: 'Đang vận chuyển',
    state: 'TRANSPORT',
    label: 'Đang vận chuyển',
    confirmLabel: 'Vận chuyển',
    status: 'primary',
    color: 'blue',
    outline: true,
    nextState: '',
    nextStateLabel: '',
    confirmText: 'Bạn có muốn chuyển sang trạng thái đang vận chuyển',
    responseTitle: 'Đang vận chuyển',
    responseText: 'Đã chuyển sang trạng thái đang vận chuyển',
  };
  deliveredState: ProcessMap = {
    id: 'DELIVERED',
    text: 'Đã giao hàng',
    state: 'DELIVERED',
    label: 'Đã giao hàng',
    confirmLabel: 'Đã giao hàng',
    status: 'success',
    color: 'green',
    outline: true,
    nextState: '',
    nextStateLabel: '',
    confirmText: 'Bạn có muốn chuyển sang trạng thái đã giao hàng',
    responseTitle: 'Đã giao hàng',
    responseText: 'Đã chuyển sang trạng thái đã giao hàng',
  };
  depploymentState: ProcessMap = {
    id: 'DEPLOYMENT',
    text: 'Đang triển khai',
    state: 'DEPLOYMENT',
    label: 'Đang triển khai',
    confirmLabel: 'Triển khai',
    status: 'primary',
    color: 'blue',
    outline: false,
    confirmTitle: 'Triển khai',
    // nextStateLabel: 'Common.deploy',
    confirmText: 'Bạn có muốn triển khai không ?',
    responseTitle: 'Triển khai',
    responseText: 'Đã chuyển sang trại thái đang triển khai !',
  };
  deployedState: ProcessMap = {
    id: 'DEPLOYED',
    text: 'Đã triển khai',
    state: 'DEPLOYED',
    label: 'Đã triển khai',
    confirmLabel: 'Hoàn tất triển khai',
    status: 'primary',
    color: 'blue',
    outline: false,
    confirmTitle: 'Hoàn tất triển khai',
    confirmText: 'Bạn có muốn hoàn tất triển khai ?',
    responseTitle: 'Hoàn tất triển khai',
    responseText: 'Đã hoàn tất triển khai !',
  };
  depploymentedState: ProcessMap = {
    id: 'DEPLOYMENTED',
    text: 'Đã triển khai',
    state: 'DEPLOYMENTED',
    label: 'Đã triển khai',
    confirmLabel: 'Hoàn tất triển khai',
    status: 'primary',
    color: 'blue',
    outline: false,
    confirmTitle: 'Hoàn tất triển khai',
    // nextStateLabel: 'Common.deploy',
    confirmText: 'Bạn có muốn hoàn tất triển khai ?',
    responseTitle: 'Hoàn tất triển khai',
    responseText: 'Đã hoàn tất triển khai',
  };
  approvalRequestedState: ProcessMap = {
    id: 'APPROVALREQUESTED',
    text: 'Đẫ duyệt yêu cầu',
    state: 'APPROVALREQUESTED',
    label: 'Đẫ duyệt yệu cầu',
    confirmLabel: 'Phê duyệt yêu cầu',
    status: 'warning',
    color: 'orange',
    outline: false,
    nextState: '',
    confirmTitle: 'Phê duyệt yêu cầu',
    confirmText: 'Bạn có muốn phê duyệt yêu cầu ?',
    responseTitle: 'Phê duyệt yêu cầu',
    responseText: 'Đã phê duyệt yêu cầu',
  };

  distributedState: ProcessMap = {
    id: 'DISTRIBUTED',
    text: 'Đã phát hành',
    state: 'DISTRIBUTED',
    label: 'Đã phát hành',
    confirmLabel: 'Bạn có muốn phát hành',
    status: 'info',
    color: 'blue',
    outline: true,
    confirmTitle: 'Bạn có muốn phát hành',
    confirmText: 'Bạn có muốn phát hành',
    responseTitle: 'Đã phát hành',
    responseText: 'Đã phát hành',
  };
  assignedState: ProcessMap = {
    id: 'ASSIGNED',
    text: 'Đã cấp phát',
    state: 'ASSIGNED',
    label: 'Đã cấp phát',
    confirmLabel: 'Bạn có muốn cấp phát',
    status: 'success',
    color: 'green',
    outline: true,
    confirmTitle: 'Bạn có muốn cấp phát',
    confirmText: 'Bạn có muốn cấp phát',
    responseTitle: 'Đã cấp phát',
    responseText: 'Đã cấp phát',
  };
  notJustDistributedState: ProcessMap = {
    id: 'NOTJUSTDISTRIBUTED',
    text: 'warning',
    state: 'NOTJUSTDISTRIBUTED',
    status: 'warning',
    color: 'orange',
    label: 'Chưa phát hành',
    confirmLabel: 'Hủy phát hành',
    confirmTitle: 'Bạn có muốn hủy phát hành ?',
    confirmText: 'Bạn có muốn hủy phát hành ?',
    responseTitle: 'Đã hủy phát hành',
    responseText: 'Đã hủy phát hành',
  };
  lockedState: ProcessMap = {
    id: 'LOCKED',
    text: 'Đã khóa',
    state: 'LOCKED',
    status: 'danger',
    color: 'red',
    label: 'Đã khóa',
    confirmLabel: 'Khóa',
    confirmTitle: 'Bạn có muốn khóa ?',
    confirmText: 'Bạn có muốn khóa ?',
    responseTitle: 'Đã khóa',
    responseText: 'Đã khóa',
  };


  public stateMap = {
    'LOCKED': {
      ...this.lockedState,
    },
    'NOTJUSTDISTRIBUTED': {
      ...this.notJustDistributedState,
    },
    'ASSIGNED': {
      ...this.assignedState,
    },
    'DISTRIBUTED': {
      ...this.distributedState,
    },
    'APPROVALREQUESTED': {
      ...this.approvalRequestedState,
    },
    'DEPLOYMENTED': {
      ...this.depploymentedState,
    },
    'DEPLOYED': {
      ...this.deployedState,
    },
    'DEPLOYMENT': {
      ...this.depploymentState,
    },
    'DELIVERED': {
      ...this.deliveredState,
    },
    'TRANSPORT': {
      ...this.transportState,
    },
    'RETURN': {
      ...this.returnState,
    },
    'ACCEPTANCE': {
      ...this.acceptanceState,
    },
    'CONFIRMATIONREQUESTED': {
      ...this.confirmationRequestedState,
    },
    'INQUEUE': {
      ...this.inQueueState,
    },
    'CONFIRMED': {
      ...this.confirmedState,
    },
    'QUEUE': {
      ...this.queueState,
    },
    'PROCESSING': {
      ...this.processingState,
    },
    'PRICEREPORT': {
      ...this.priceReportState,
    },
    'NEW': {
      ...this.newState,
    },
    'NOTJUSTAPPROVED': {
      ...this.notJustApprodedState,
    },
    'APPROVED': {
      ...this.approvedState,
    },
    'UNRECORDED': {
      ...this.unrecordedState,
    },
    'COMPLETE': {
      ...this.completeState,
    },
    'CONVERTED': {
      ...this.convertedState,
    },
  };
  

  // newPipSound: HTMLAudioElement = new Audio('assets/sounds/beep-08b.wav');
  playNewPipSound() {
    const sound: HTMLAudioElement = new Audio('assets/sounds/beep-08b.wav');
    sound.play();
  }

  // increasePipSound: HTMLAudioElement = new Audio('assets/sounds/beep-07a.wav');
  playIncreasePipSound() {
    const sound: HTMLAudioElement = new Audio('assets/sounds/beep-07a.wav');
    sound.play();
  }

  // decreasePipSound: HTMLAudioElement = new Audio('assets/sounds/beep-07a.wav');
  playDecreasePipSound() {
    const sound: HTMLAudioElement = new Audio('assets/sounds/beep-07a.wav');
    sound.play();
  }

  // errorPipSound: HTMLAudioElement = new Audio('assets/sounds/beep-03.wav');
  playErrorPipSound() {
    const sound: HTMLAudioElement = new Audio('assets/sounds/beep-03.wav');
    sound.play();
  }

  // paymentSound: HTMLAudioElement = new Audio('assets/sounds/benboncan_till-with-bell.wav');
  playPaymentSound() {
    const sound: HTMLAudioElement = new Audio('assets/sounds/benboncan_till-with-bell.wav');
    sound.play();
  }

  getBeginOfDate(date?: Date) {
    date = date || new Date();
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
  }
  getEndOfDate(date?: Date) {
    date = date || new Date();
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
  }
  getBeginOfMonth(date?: Date) {
    date = date || new Date();
    return new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0, 0);
  }
  getEndOfMonth(date?: Date) {
    date = date || new Date();
    const endOfMonth = new Date(date);
    endOfMonth.setMonth(endOfMonth.getMonth() + 1); // go to next month
    endOfMonth.setDate(0); // go to end of pevious month
    endOfMonth.setHours(23); // go to end of day
    endOfMonth.setMinutes(59); // go to end of day
    endOfMonth.setSeconds(59); // go to end of day
    endOfMonth.setMilliseconds(999); // go to end of day
    return endOfMonth;
  }

  voucherTypeMap: { [key: string]: { prefix: string, id: string, text: string, symbol: string, status?: string, icon?: string } } = {
    PAYMENT: { prefix: '101', id: 'PAYMENT', text: 'Phiếu chi', symbol: 'PC', status: 'primary' },
    RECEIPT: { prefix: '100', id: 'RECEIPT', text: 'Phiếu thu', symbol: 'PT', status: 'primary' },
    OTHERBUSINESSVOUCHER: { prefix: '103', id: 'OTHERBUSINESSVOUCHER', text: 'Chứng từ nghiệp vụ khác', symbol: 'NVK', status: 'warning' },
    SALES: { prefix: '104', id: 'SALES', text: 'Phiếu bán hàng', symbol: 'PBH', status: 'primary' },
    PRICEREPORT: { prefix: '106', id: 'PRICEREPORT', text: 'Báo giá', symbol: 'BG', status: 'info' },
    PRICEQUOTATION: { prefix: '106', id: 'PRICEQUOTATION', text: 'Báo giá', symbol: 'BG', status: 'info' },
    SALESRETURNS: { prefix: '126', id: 'SALESRETURNS', text: 'Phiếu trả hàng bán', symbol: 'PTHB', status: 'warning' },
    PURCHASE: { prefix: '107', id: 'PURCHASE', text: 'Phiếu mua hàng', symbol: 'PMH', status: 'success' },
    PURCHASEORDER: { prefix: '107', id: 'PURCHASEORDER', text: 'Đơn đặt mua hàng', symbol: 'DDMH', status: 'info' },
    GOODSRECEIPT: { prefix: '110', id: 'GOODSRECEIPT', text: 'Phiếu nhập kho', symbol: 'PNK', status: 'danger' },
    GOODSDELIVERY: { prefix: '111', id: 'GOODSDELIVERY', text: 'Phiếu xuất kho', symbol: 'PXK', status: 'warning' },
    INVENTORYADJUST: { prefix: '124', id: 'INVENTORYADJUST', text: 'Phiếu kiểm kho', symbol: 'PKK', status: 'warning' },
    COMMERCEPOSORDER: { prefix: '128', id: 'COMMERCEPOSORDER', text: 'Đơn hàng POS', symbol: 'DHPOS', status: 'success' },
    COMMERCEPOSRETURN: { prefix: '129', id: 'COMMERCEPOSRETURN', text: 'Phiếu trả hàng POS', symbol: 'PTHPOS', status: 'warning' },
    DEPLOYMENT: { prefix: '113', id: 'DEPLOYMENT', text: 'Phiếu triển khai', symbol: 'PTK', status: 'danger' },
    CLBRTCOMMISSIONINCURRED: { prefix: '142', id: 'CLBRTCOMMISSIONINCURRED', text: 'Chiết khấu phát sinh', symbol: 'CKPS', status: 'info' },
    CLBRTORDER: { prefix: '114', id: 'CLBRTORDER', text: 'Đơn hàng CTV', symbol: 'DHCTV', status: 'info' },
    CLBRTOPPORTUNITY: { prefix: '158', id: 'CLBRTOPPORTUNITY', text: 'Cơ hội CTV', symbol: 'CHCTV', status: 'warning' },
    CLBRTCOMMISSION: { prefix: '139', id: 'CLBRTCOMMISSION', text: 'Phiếu chốt chiết khấu CTV', symbol: 'CKCTV', status: 'success' },
    CHATROOM: { prefix: '120', id: 'CHATROOM', text: 'Task', symbol: 'TASK', status: 'warning' },
    TASK: { prefix: '120', id: 'TASK', text: 'Task', symbol: 'TASK', status: 'warning' },
    PRODUCTIONORDER: { prefix: '150', id: 'PRODUCTIONORDER', text: 'Lệnh sản xuất', symbol: 'PDS', status: 'danger' },
    MASTERPRICETABLEUPDATENOTE: { prefix: '140', id: 'MASTERPRICETABLEUPDATENOTE', text: 'Phiếu cập nhật giá bán', symbol: 'PCNG', status: 'success' },
    CLBRTSALARYSLIP: { prefix: '159', id: 'CLBRTSALARYSLIP', text: 'Phiếu lương CTV', symbol: 'CPL', status: 'primary' },
    CONTRACT: { prefix: '163', id: 'CONTRACT', text: 'Hợp đồng', symbol: 'CONTR', status: 'info' },
  };

}
