import { Router } from 'framework7/build/core/modules/router/router';
import { Messages } from 'framework7/build/core/components/messages/messages';
import { F7Messagebar, F7ComponentContext, F7Page, } from '../types/framework7-types';
import { BaseComponent, ComponentState } from '../lib/base-component';
import { RootServices } from '../services/root.services';
import { take } from 'rxjs/operators';
import { HelpdeskTicketModel } from '../model/helpdesk.model';
import * as moment from 'moment';
import { CommonService } from '../services/common.service';
import { Searchbar } from 'framework7/build/core/components/searchbar/searchbar';
import { isPlatform } from '@ionic/angular';
import { Dialog } from 'framework7/build/core/components/dialog/dialog';
import { ChatRoomModel } from '../model/chat-room.model';
import { DatePipe } from '@angular/common';
// import 'moment/locale/vi';

window.moment = moment;

export interface ComponentStateExtend extends ComponentState {
    [key: string]: any;
    tickets?: HelpdeskTicketModel[];
}

export class F7ComponentContextExtend extends F7ComponentContext {
    [key: string]: any;
    messagebar?: F7Messagebar;
    messages?: Messages.Messages;
    images?: string[];
    responseInProgress?: boolean;
    answers?: string[];
    people?: { name?: string, avatar?: string }[];
    $root: any;
    searchBar?: Searchbar.Searchbar;

    createTicketDialog?: Dialog.Dialog;
    filterDialog?: Dialog.Dialog;

    // data
    tickets?: HelpdeskTicketModel[];
    lastUpdate?: number;
    mode?: string;
    callback?: (task: ChatRoomModel) => void;

    // methods
    $setState: (mergeState?: { tickets?: any[], lastUpdate?: number, title?: string, infiniteLoadLast?: boolean, lastUpdateMoment?: string, filter?: any }) => void;
    hideToolbar?: () => void;

    refresh?: (e?: Event, done?: () => void) => Promise<boolean>;
}

/** Component group manager */
export class HelpdeskComponent extends BaseComponent<ComponentStateExtend> {
    // states: { [key: string]: State } = {};

    /** Component title */
    title = 'Tickets';

    constructor(
        public rootServices: RootServices,
        public commonService: CommonService,
        public datePipe: DatePipe,
    ) {
        super(rootServices);
        // // console.log('Click here to open ts file');
    }

    // async openCreateTicketDialog(msgEl?: any) {
    //     const $this = this;
    //     const $ = this.currentState.instance.$;
    //     // const msgEl = $(e.target).closest('.message');
    //     // const msgIndex = parseInt(msgEl.attr('globalid').split('/')[1]);
    //     // const message = this.currentState.chatRoom.messageList.find(msg => msg.index === msgIndex);
    //     // // const members = this.currentState.chatRoom.memberList$.getValue().filter(f => !f.group);
    //     const dialog = this.currentState.instance.createTicketDialog;
    //     // // dialog.$el.find('.dialog-text').html(message && message.content && this.renderMessageContent(message.content) || msgEl.find('.message-text').html());

    //     // // const readMessageMembers = members;
    //     // const readMessageMembers = await this.currentState.chatRoom.getMessageReadState(msgIndex);
    //     // this.currentState.instance.$setState({
    //     //     messageText: message && message.content && this.renderMessageText(message.content),
    //     //     mesageSender: [message.from].map(member => {
    //     //         // const member = memberList[userCode];
    //     //         return {
    //     //             ...member,
    //     //             time: moment(message && message.date || Date.now()).fromNow(),
    //     //         };
    //     //     })[0],
    //     //     readMessageMembers: readMessageMembers && readMessageMembers instanceof ArrayType && readMessageMembers.map(member => {
    //     //         // const member = memberList[userCode];
    //     //         return {
    //     //             ...member,
    //     //             time: moment(member && member.time || Date.now()).fromNow(),
    //     //         };
    //     //     }) as any,
    //     // });
    //     setTimeout(() => {
    //         // dialog.$el.data('msgindex', message.index);
    //         dialog.open();
    //         // this.currentState.instance.$(dialog.el).find('a.mention').click((e: MouseEvent) => {
    //         //     const memberId = e.target['attributes']['data-id'].value;
    //         //     $this.rootServices.navigate('/profile/' + memberId, {
    //         //         context: {
    //         //             backTitle: $this.title,
    //         //             memberInfo: $this.currentState.chatRoom.memberList$.getValue().find(f => f.id === memberId),
    //         //             chatRoom: $this.currentState.chatRoom,
    //         //         }
    //         //     });
    //         //     dialog.close();
    //         // });
    //     }, 300);
    //     return dialog;
    // }

    onF7pageRemove(chatRoomId: string) {
        // if (this.chatRoomCacheList[chatRoomId]) {
        //   this.chatRoomCacheList[chatRoomId].disconnect();
        // }
    }

    // updateItemInfo(item: any, data: ChatRoomModel) {
    //     if (item && data) {
    //         item.find('.description').html(data.Description);
    //         const unread = data.NumOfMessage - data.LoggedNumOfMessage - data.LoggedNumOfReadMessage;
    //         item.find('.unread').html(unread > 0 ? unread : '');
    //         item.find('.lastMessageUserName').html(data.LastMessageUserName);
    //         item.find('.lastMessageText').html(data.LastMessageText);
    //         item.find('.lastUpdateMoment').html(moment(data.LastUpdate).fromNow());
    //     }
    // }

    // preapreTicketItem(t: HelpdeskTicketModel) {
    //     const unread = t.NumOfMessage - t.LoggedNumOfMessage - t.LoggedNumOfReadMessage;
    //     t.Request_Date_Time_Moment = moment(t.DateOfOpen).fromNow();
    //     t.Request_Date_Time = this.datePipe.transform(t.DateOfOpen, 'short');
    //     t.NumOfUnreadMessage = unread > 0 ? unread : '';
    //     return t;
    // }
    prepareTicketItem(t: ChatRoomModel) {
        const unread = t.NumOfMessage - t.LoggedNumOfMessage - t.LoggedNumOfReadMessage;
        t.LastUpdate_Moment = moment(t.LastUpdate).fromNow();
        t.DateOfCreate = this.datePipe.transform(t.DateOfCreate, 'short');
        t.StateLabel = { 'OPEN': 'Mở', 'NEWSESSION': 'Mới', 'ACCEPT': 'Tiếp nhận', 'CLOSE': 'Đóng', 'COMPLETE': 'Hoàn tất', 'CANCEL': 'Hủy' }[t.State];
        if (t.State in ['OPEN', 'NEWSESSION']) {
            t.ActionMemberName = t.OpenByMemberName;
        }
        if (t.State === 'ACCEPT') {
            t.ActionMemberName = t.AcceptByMemberName;
        }
        if (t.State === 'CLOSE') {
            t.ActionMemberName = t.CloseByMemberName;
        }
        if (t.State === 'COMPLETE') {
            t.ActionMemberName = t.CompleteByMemberName;
        }
        if (t.State === 'CANCEL') {
            t.ActionMemberName = t.CancelByMemberName;
        }
        t.NumOfUnreadMessage = unread > 0 ? unread : '';
        return t;
    }

    get f7Component(): Router.RouteParameters {
        const $this = this;
        return {
            path: '/helpdesk',
            component: {
                template: /*html*/`
                <div class="page helpdesk" data-name="helpdesk">
                    <div class="navbar {{extendComponentId}}">
                        <div class="navbar-bg"></div>
                        <div class="navbar-inner sliding">
                            <div class="left">
                                {{#js_if "this.mode != ''"}}
                                <a class="link back {{textColorClass}}">
                                    <i class="icon icon-back"></i>
                                    <span class="if-not-md {{textColorClass}}">{{backTitle}}</span>
                                </a>
                                {{else}}
                                <a
                                    class="panel-open{{js "this.disabledLeftPannel ? '-disable' : ''"}} link icon-only {{textColorClass}}">
                                    <i class="icon f7-icons">bars</i>
                                </a>
                                {{/js_if}}
                            </div>
                            <div class="title helpdesk-txt-color-red">{{title}}</div>
                            <div class="right">
                                <!-- Link to enable searchbar -->
                                <a class="link icon-only searchbar-enable helpdesk-txt-color-red" data-searchbar="{{js "this.extendComponentId && ('.' + this.extendComponentId+' ') || ''"}}.helpdesk-searchbar">
                                    <i class="icon f7-icons if-not-md">search</i>
                                    <i class="icon material-icons md-only">search</i>
                                </a>
                            </div>
                            <!-- Searchbar is a direct child of "navbar-inner" -->
                            <form class="searchbar helpdesk-searchbar searchbar-expandable">
                                <div class="searchbar-inner">
                                    <div class="searchbar-input-wrap">
                                        <input type="search" placeholder="#thẻ/@thành viên/+tin nhắn/tiêu đề" />
                                        <i class="searchbar-icon"></i><span class="input-clear-button"></span>
                                    </div>
                                    <span class="searchbar-disable-button helpdesk-txt-color-red">Cancel</span>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="page-content ptr-content infinite-scroll-content" data-ptr-mousewheel="false" @ptr:refresh="refresh">
                        <div class="ptr-preloader">
                            <div class="preloader"></div>
                            <div class="ptr-arrow"></div>
                        </div>
                        <div class="searchbar-backdrop helpdesk-searchbar-backdrop"></div>
                
                        <div class="block-header">{{lastUpdateMoment}}, kéo xuống để cập nhật</div>
                        <div class="action-bar block block-strong inset" style="color: var(--f7-block-strong-text-color);
                                                        padding-top: var(--f7-block-padding-vertical);
                                                        padding-bottom: var(--f7-block-padding-vertical);
                                                        background-color: var(--f7-block-strong-bg-color); 
                                                        border-topx: 1px solid var(--f7-list-border-color);
                                                        border-bottomx: 1px solid var(--f7-list-border-color);">
                            <div class="row">
                                <div class="col-25 tablet-15 action-bar-item text-color-gray link" @click="openCreateTicketDialog">
                                    <div class="demo-icon-icon"><i class="icon f7-icons">tickets_fill</i></div>
                                    <div class="action-bar-item-label">Tiếp nhận</div>
                                </div>
                                <div class="col-25 tablet-15 action-bar-item text-color-gray link">
                                    <div class="demo-icon-icon"><i class="icon f7-icons">app_badge_fill</i></div>
                                    <div class="action-bar-item-label">Chưa đọc</div>
                                </div>
                                <div class="col-25 tablet-15 action-bar-item text-color-gray link">
                                    <div class="demo-icon-icon"><i class="icon f7-icons">bookmark_fill</i></div>
                                    <div class="action-bar-item-label">Quan trọng</div>
                                </div>
                                <div class="col-25 tablet-15 action-bar-item text-color-gray link{{js "this.filter ? ' helpdesk-txt-color-red' : ''"}}"
                                    @click="openFilterDialog">
                                    <div class="demo-icon-icon"><i class="icon f7-icons">line_horizontal_3_decrease_circle_fill</i>
                                    </div>
                                    <div class="action-bar-item-label{{js "this.filter ? ' helpdesk-txt-color-red' : ''"}}">Bộ lọc</div>
                                </div>
                            </div>
                        </div>
                
                
                        <div class="list media-list helpdesk-list inset">
                            <ul>
                                {{#each tickets}}
                                <li data-id="{{Code}}" data-chat-room="{{Code}}" @click="openChatRoom">
                                <a class="item-link item-content">
                                    <div class="item-inner">
                                    <div class="item-title-row">
                                        <div class="item-title helpdesk-txt-color-red description" style="font-size: 0.9rem">{{Description}}</div>
                                        <div class="item-after; text-color-blue" style="font-size: 0.8rem"><span class="badge color-red unread">{{NumOfUnreadMessage}}</span></div>
                                    </div>
                                    {{#if DateOfCreate}}<div class="item-subtitle text-color-gray"><div class="chat-list-item-label"><i class="icon f7-icons">timer_fill</i>{{DateOfCreate}}</div> <div class="text-color-blue lastUpdateMoment" style="float: right">{{LastUpdate_Moment}}</div></div>{{/if}}
                                    <div class="item-subtitle text-color-gray">
                                        <div class="chat-list-item-label">
                                            <i class="icon f7-icons">person_crop_square_fill</i>
                                            {{js "this.ActionMemberName || 'Trạng thái'"}}
                                        </div>
                                        {{#if StateLabel}}
                                        <div class="item-subtitle" style="float: right; color: {{js "this.State === 'OPEN' || this.State === 'NEWSESSION' ? 'var(--f7-theme-color)' : (this.State === 'ACCEPT' ? 'var(--f7-color-green-shade)' : (this.State === 'CANCEL' ? '#8e8e93' : '#8e8e93'))"}}">
                                            <div class="chat-list-item-label">
                                                {{js "this.StateLabel && this.StateLabel.toLocaleLowerCase()"}}
                                                <i class="icon f7-icons" style="text-align: right">{{js "this.State === 'OPEN' ? 'circle_fill' : (this.State === 'ACCEPT' ? 'person_crop_circle_fill_badge_checkmark' : (this.State === 'CANCEL' ? 'multiply_circle_fill' : (this.State === 'COMPLETE' ? 'checkmark_alt_circle_fill' : 'bookmark_fill-fill')))"}}</i>
                                            </div>
                                        </div>
                                        {{/if}}
                                    </div>
                                    {{#if Description}}<div class="item-text"><i class="icon f7-icons">tickets_fill</i><span class="description">{{Description}}</span></div>{{/if}}
                                    {{#if LastMessageText}}<div class="item-text chat-list-item-label"><i class="icon f7-icons">captions_bubble_fill</i><span class="lastMessageUserName">{{LastMessageUserName}}</span>:  <span class="lastMessageText">{{LastMessageText}}</span></div>{{/if}}
                                    <div class="item-text" style="clear: both; max-height: initial;"><span class="lastMessageText">
                                        <span class="tag">#{{Code}}</span>
                                        {{#each Tags}}
                                        <span class="tag">#{{this}}</span>
                                        {{/each}}
                                    </span></div>
                                    </div>
                                </a>
                                </li>
                                {{/each}}
                            </ul>
                            {{#if infiniteLoadLast}}
                                <div style="text-align: center" class="text-color-gray">đã hết</div>
                            {{else}}
                                <div class="preloader color-red infinite-scroll-preloader"></div>
                            {{/if}}
                        </div>
                
                        <div class="dialog dialog-wrap dialog-create-ticket">
                            <div class="dialog-padding">
                                <div class="dialog dialog-inside">
                                    <div class="dialog-inner" style="padding-bottom: 0;">
                                        <div class="dialog-title">Tiếp nhận yêu cầu</div>
                                        <div class="dialog-text" style="width: 100%; user-select: initial; text-align: center;">Tiếp
                                            nhận yêu cầu khách hàng khi nhận được thông tin bên ngoài hệ thống như: cuộc gọi, tin nhắn,
                                            email...</div>
                
                                        <div class="list media-list"
                                            style="margin-left: -15px; margin-right: -15px; margin-bottom: 0; margin-top: 0; text-align: left;">
                                            <ul>
                                                <li class="item-content item-input">
                                                    <div class="item-media"><i
                                                            class="icon f7-icons text-color-default">person_circle_fill</i></div>
                                                    <div class="item-inner">
                                                        <div class="item-title item-label">Mô tả (*)</div>
                                                        <div class="item-input-wrap">
                                                            <textarea class="field" name="Description" type="text"
                                                                placeholder="Nội dung yêu cầu của khách hàng"></textarea>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="dialog-buttons">
                                        <span class="dialog-button color-red closeBtn" @click="closeCreateTicketDialog">Đóng</span>
                                        <span class="dialog-button createReferenceChatRoomBtn" @click="createTicket">Tạo yêu cầu</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                
                        <div class="dialog dialog-wrap dialog dialog-filter">
                            <div class="dialog-padding">
                                <div class="dialog dialog-inside">
                                    <div class="dialog-inner" style="padding-bottom: 0;">
                                        <div class="dialog-title">Helpdesk</div>
                                        <div class="block-title" style="margin-top: 1rem;">Bộ lọc yêu cầu khách hàng</div>
                                        <div class="list simple-list"
                                            style="margin-left: -15px; margin-right: -15px; margin-bottom: 0; margin-top: 0; text-align: left;">
                                            <ul>
                                                <li>
                                                    <span>Chưa tiếp nhận</span>
                                                    <label class="toggle toggle-init color-black">
                                                        <input class="field" name="filter_State" value="OPEN" type="checkbox">
                                                        <span class="toggle-icon"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <span>Chưa hoàn thành</span>
                                                    <label class="toggle toggle-init color-blue">
                                                        <input class="field" name="filter_State" value="ACCEPT" type="checkbox">
                                                        <span class="toggle-icon"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <span>Đã hủy</span>
                                                    <label class="toggle toggle-init color-red">
                                                        <input class="field" name="filter_State" value="CANCEL" type="checkbox">
                                                        <span class="toggle-icon"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <span>Chưa đọc</span>
                                                    <label class="toggle toggle-init color-green">
                                                        <input class="field" name="filter_UnreadState" value="true" type="checkbox">
                                                        <span class="toggle-icon"></span>
                                                    </label>
                                                </li>
                                                <li>
                                                    <span>Quan trọng</span>
                                                    <label class="toggle toggle-init color-orange">
                                                        <input class="field" name="filter_ImportantFlag" value="true" type="checkbox">
                                                        <span class="toggle-icon"></span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="dialog-buttons">
                                        <span class="dialog-button color-red closeBtn" @click="closeFilterDialog">Đặt lại</span>
                                        <span class="dialog-button createReferenceChatRoomBtn" @click="applyFilter">Áp dụng</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                
                    </div>
              `,
                style: /*css*/`
                    .page.helpdesk .tag {
                        font-size: 12px;
                        font-style: italic;
                    }
                `,
                data() {
                    const self: F7ComponentContextExtend = this;
                    const tickets = [];

                    // const tickets = [];
                    return {
                        tickets,
                        lastUpdate: Date.now(),
                        title: self.title || $this.title,
                        disabledLeftPannel: false && isPlatform('desktop'),
                        lastUpdateMoment: moment(Date.now()).fromNow(),
                        filter: null,
                        mode: '',
                        textColorClass: 'helpdesk-txt-color-red',
                    };
                },
                methods: {
                    openFilterDialog(e) {
                        const self: F7ComponentContextExtend = this;
                        self.filterDialog && self.filterDialog.open();
                        console.log('Current filter: ', self.filter);
                    },
                    closeFilterDialog(e) {
                        const self: F7ComponentContextExtend = this;
                        if (self.filterDialog) {
                            const fieldEles = self.filterDialog.$el.find('.field');
                            fieldEles.each((index, el) => {
                                self.$(el).prop('checked', false);
                            });
                            self.$setState({ filter: null });
                            self.filterDialog.close();
                            self.refresh(null, () => { });
                        }
                    },
                    applyFilter(e) {
                        const self: F7ComponentContextExtend = this;
                        self.filterDialog && self.filterDialog.close();
                        const fieldEles = self.filterDialog.$el.find('.field');
                        console.log(fieldEles);
                        const filter: { [key: string]: any } = {};
                        fieldEles.each((index, el) => {
                            console.log(index, el.checked);
                            if (el.checked) {
                                filter[el.name] = el.value;
                            }
                        });
                        console.log(filter);
                        self.$setState({ filter: Object.keys(filter).length > 0 ? filter : null });
                        self.refresh(null, () => { });
                    },
                    closeCreateTicketDialog() {
                        const self: F7ComponentContextExtend = this;
                        self.createTicketDialog && self.createTicketDialog.close();
                    },
                    createTicket(e: MouseEvent) {
                        const self: F7ComponentContextExtend = this;
                        const ticketData: HelpdeskTicketModel = {};
                        const fieldEles = self.createTicketDialog.$el.find('.field');
                        fieldEles.each((index, fieldEle) => {
                            console.log(fieldEle);
                            console.log(fieldEle.value);
                            ticketData[fieldEle.name] = fieldEle.value;
                        });
                        if (!ticketData.Description) {
                            console.error('Bạn chưa điền mô tả');
                            $this.commonService.showError('Bạn chưa điền mô tả');
                            return;
                        }
                        console.log(ticketData);
                        self.$app.preloader.show();
                        try {
                            $this.rootServices.apiService.postPromise<HelpdeskTicketModel[]>('/helpdesk/tickets', { autoCreateChatRoom: true }, [ticketData]).then(rs => {
                                console.log(rs);
                                if (rs && rs[0] && rs[0].ChatRoom) {
                                    $this.commonService.navigate('/chat-room/' + (rs[0].ChatRoom && rs[0].ChatRoom['Code'] || rs[0].ChatRoom['Code']), {
                                        context: {
                                            backTitle: 'Helpdesk',
                                            title: rs[0].Description,
                                            textColorClass: 'helpdesk-text-red',
                                        }
                                    });
                                    self.createTicketDialog.close();
                                    fieldEles.each((index, fieldEle) => {
                                        fieldEle.value = null;
                                        self.$(fieldEle).change();
                                    });
                                    self.$app.preloader.hide();
                                }
                            }).catch(err => {
                                console.error(err);
                                self.$app.preloader.hide();
                                $this.commonService.showError(err);
                            });
                        } catch (err) {
                            console.error(err);
                            self.$app.preloader.hide();
                            $this.commonService.showError(err);
                        }
                    },
                    openCreateTicketDialog(e: MouseEvent) {
                        const self: F7ComponentContextExtend = this;
                        // $this.openCreateTicketDialog();
                        self.createTicketDialog && self.createTicketDialog.open();
                    },
                    async openChatRoom(e) {
                        const self: F7ComponentContextExtend = this;
                        const id = self.$(e.target).closest('li').data('id');
                        const ticket = self.tickets.find(f => f.Code === id);
                        if (!ticket) {
                            return false;
                        }

                        if (self.mode && self.callback) {
                            self.callback({ Code: ticket.ChatRoom, Description: ticket.Description });
                            self.$router.back();
                            return;
                        }


                        try {
                            if ($this.commonService.getObjectId(ticket.Creator) !== $this.rootServices.authService.user$.getValue().id && (ticket.State?.Code || ticket.State) === 'OPEN') {
                                await new Promise((resolve, reject) => {
                                    self.$app.dialog.confirm(ticket.Description, 'Tiếp nhận yêu cầu', () => {
                                        self.$app.preloader.show();
                                        $this.rootServices.apiService.putPromise('/helpdesk/tickets/' + ticket.Code, { changeStateTo: 'ACCEPT' }, [{ Code: ticket.Code }]).then(rs => {
                                            $this.commonService.showInfo('Đã tiếp nhận yêu cầu');
                                            self.$app.preloader.hide();
                                            resolve(true);
                                        }).catch(err => {
                                            console.error(err);
                                            self.$app.preloader.hide();
                                            $this.commonService.showError(err);
                                        });
                                    }, () => {
                                        reject('Không tiếp nhận yêu cầu');

                                    });
                                });
                            }
                            const roomId = self.$(e.target).closest('li').data('chat-room');
                            $this.rootServices.authService.isAuthenticatedOrRefresh().pipe(take(1)).toPromise().then(() => {
                                self.$router.navigate('/chat-room/' + ticket.ChatRoom, {
                                    context: {
                                        title: ticket.Description,
                                        backTitle: self.title,
                                        description: ticket.Description,
                                        textColorClass: 'helpdesk-text-red',
                                        // ticket: ticket,
                                    }
                                });
                            }).catch(err => {
                                console.error(err);
                                $this.commonService.showError(err);
                            });

                        } catch (err) {
                            console.error(err);
                            $this.commonService.showError(err);
                        }
                    },
                    async refresh(e, done) {
                        const self: F7ComponentContextExtend = this;
                        let doneTimeout = null;
                        if (done) {
                            doneTimeout = setTimeout(() => {
                                done();
                            }, 10000);
                        };
                        // Refresh whene overtime 5 minutes

                        if (done || ($this.rootServices.authService.loginState$.getValue() && (self.tickets.length === 0 || !self.lastUpdate || self.lastUpdate + 300000 < Date.now()))) {
                            console.log('refresh helpdesk');
                            self.tickets = await $this.rootServices.apiService.getPromise<any[]>('/helpdesk/tickets', {
                                search: self.searchBar.query,
                                sort_LastUpdate: 'desc',
                                limit: 10,
                                offset: 0,
                                includeState: true,
                                includeLastMessage: true,
                                includeInfosAsKeyValue: 'Accepted_User,Request_Date_Time',
                                loadAsChatRoom: true,
                                ...self.filter,
                                // includeAcceptedUser: true,
                                // includeProcedure: true,
                            }).catch(err => {
                                if (doneTimeout) clearTimeout(doneTimeout);
                                if (done) done();
                                return Promise.reject(err);
                            });
                            if (doneTimeout) clearTimeout(doneTimeout);

                            self.$setState({
                                tickets: self.tickets.map(t => {
                                    // Moment.now()
                                    // if (t.Infos && t.Infos.Request_Date_Time) {
                                    // const unread = t.NumOfMessage - t.LoggedNumOfMessage - t.LoggedNumOfReadMessage;
                                    // t.Infos.Request_Date_Time_Moment = moment(t.LastUpdate).fromNow();
                                    // t.Infos.Request_Date_Time = new Date(t.Infos.Request_Date_Time).toLocaleString();
                                    // t.NumOfUnreadMessage = unread > 0 ? unread : '';
                                    $this.prepareTicketItem(t);
                                    // }
                                    // t.Infos.Request_Date_Time = new Date(t.Infos.Request_Date_Time).toLocaleTimeString();
                                    return t;
                                }),
                                lastUpdate: Date.now(),
                                lastUpdateMoment: moment(Date.now()).fromNow(),
                            });

                            self.updateBadge();
                            self.infiniteOffset = 10;

                            self.$setState({ infiniteLoadLast: self.tickets.length < 10 });
                            if (done) done();
                        } else {
                            self.$setState({
                                lastUpdateMoment: moment(self.lastUpdate).fromNow(),
                            });
                        }
                        return true;
                    },
                    updateBadge(e) {
                        const self: F7ComponentContextExtend = this;
                        let unreadCount = 0;
                        if (self.tickets) {
                            for (const newTicket of self.tickets) {
                                if (newTicket.NumOfUnreadMessage) {
                                    unreadCount += parseInt(newTicket.NumOfUnreadMessage);
                                }
                            }
                            $this.rootServices.helpdeskBadge = unreadCount;
                        }
                    },
                },
                on: {
                    async pageInit(e, page: F7Page) {
                        console.log('page init', page.route.url);
                        const self: F7ComponentContextExtend = this;
                        if (self.$route.params['id']) {
                            self.mode = self.$route.params['id'];
                        }
                        const currentState = await $this.onComponentInit({ instance: self }, 'main').then(currentState => {
                            // Init create ticket dialog
                            self.createTicketDialog = self.$app.dialog.create({
                                el: page.$el.find('.dialog-create-ticket'),
                                closeByBackdropClick: true,
                            });
                            self.filterDialog = self.$app.dialog.create({
                                el: page.$el.find('.dialog-filter'),
                                closeByBackdropClick: true,
                            });
                            self.filterDialog.$el.find('.field').each((index, field) => {
                                self.$(field).change((e: Event) => {
                                    console.log(e);
                                    if (e.target['name'] === 'filter_State' && e.target['checked']) {
                                        self.filterDialog.$el.find('.field[name="filter_State"]').filter((index, el) => el.value !== e.target['value']).prop('checked', false);
                                    }
                                });
                            });
                            return currentState;
                        });

                        // Loading flag
                        let allowInfinite = true;
                        // Last loaded index
                        var lastItemIndex = self.$$('.list li').length;

                        // Max items to load
                        var maxItems = 200;

                        // Append items per load
                        var itemsPerLoad = 20;

                        self.infiniteOffset = 0;
                        // self.$setState({
                        //     infiniteLoadLast: false
                        // });

                        const extendComponentId = self.$route?.context?.extendComponentId;
                        self.searchBar = self.$app.searchbar.create({
                            el: (extendComponentId ? `.${extendComponentId} ` : '') + '.helpdesk-searchbar',
                            backdrop: true,
                            backdropEl: page.$el.find('.helpdesk-searchbar-backdrop'),
                            searchContainer: page.$el.find('.list'),
                            searchIn: page.$el.find('.item-text'),
                            customSearch: true,
                            on: {
                                search(sb, query, previousQuery) {
                                    $this.commonService.takeUntil('heldpesk-ticket-search', 600).then(() => {
                                        console.log(query, previousQuery);
                                        $this.rootServices.apiService.getPromise<any[]>('/helpdesk/tickets', {
                                            search: query,
                                            sort_LastUpdate: 'desc',
                                            limit: 10,
                                            offset: 0,
                                            includeState: true,
                                            loadAsChatRoom: true,
                                            includeLastMessage: true,
                                            includeInfosAsKeyValue: 'Accepted_User,Request_Date_Time',
                                            ...self.filter,
                                            // includeAcceptedUser: true,
                                            // includeProcedure: true,
                                        }).then(tickets => {
                                            self.$$(page.el).find('.page-content').scrollTo(0, 0);
                                            self.$setState({ tickets: tickets.map(t => $this.prepareTicketItem(t)), infiniteLoadLast: tickets.length < 10 });
                                            self.infiniteOffset = 0;
                                        }).catch(err => {
                                            console.error(err);
                                            $this.commonService.showError(err);
                                        });
                                    });
                                }
                            }
                        });

                        // Attach 'infinite' event handler
                        self.$$(page.el).find('.infinite-scroll-content').on('infinite', function () {
                            // Exit, if loading in progress
                            if (!allowInfinite || self.infiniteLoadLast) return;

                            // Set loading flag
                            allowInfinite = false;

                            // Emulate 1s loading
                            $this.rootServices.apiService.getPromise<any[]>('/helpdesk/tickets', {
                                search: self.searchBar.query,
                                sort_LastUpdate: 'desc',
                                limit: 10,
                                offset: self.infiniteOffset,
                                includeState: true,
                                loadAsChatRoom: true,
                                includeLastMessage: true,
                                includeInfosAsKeyValue: 'Accepted_User,Request_Date_Time',
                                ...self.filter,
                                // includeAcceptedUser: true,
                                // includeProcedure: true,
                            }).then(tickets => {
                                tickets.map(t => {
                                    // if (t.Infos && t.Infos.Request_Date_Time) {
                                    // const unread = t.NumOfMessage - t.LoggedNumOfMessage - t.LoggedNumOfReadMessage;
                                    // t.Infos.Request_Date_Time_Moment = moment(t.LastUpdate).fromNow();
                                    // t.Infos.Request_Date_Time = new Date(t.Infos.Request_Date_Time).toLocaleString();
                                    // t.NumOfUnreadMessage = unread > 0 ? unread : '';
                                    $this.prepareTicketItem(t)
                                    // }
                                });
                                const newTickets = self.tickets.concat(tickets);
                                self.$setState({ tickets: newTickets, infiniteLoadLast: tickets.length === 0 });
                                self.infiniteOffset += 10;
                                lastItemIndex = self.$$('.list li').length;
                                allowInfinite = true;

                                self.updateBadge();
                            }).catch(err => {
                                console.error(err);
                                $this.commonService.showError(err);
                            });
                        });

                        self.refresh();
                    },
                    pageMounted(e, page) {
                        console.log('[page event] mounted', page.route.url);
                    },
                    pageReinit(e, page) {
                        console.log('[page event] reinit', page.route.url);
                    },
                    pageBeforeIn(e, page) {
                        console.log('[page event] before in', page.route.url);
                        const self: F7ComponentContextExtend = this;

                        if ($this.rootServices.changedChatRooms.helpdesk.length > 0) {
                            const updatedRooms = [... new Set($this.rootServices.changedChatRooms.helpdesk)];
                            setTimeout(() => {
                                $this.rootServices.apiService.getPromise<ChatRoomModel[]>('/helpdesk/tickets', {
                                    id: updatedRooms,
                                    includeState: true,
                                    loadAsChatRoom: true,
                                    includeLastMessage: true,
                                    includeInfosAsKeyValue: 'Accepted_User,Request_Date_Time',
                                }).then(chatRoomInfos => {
                                    // for (const chatRoomInfo of chatRoomInfos) {
                                    // $this.updateItemInfo(page.$el.find('li[data-chat-room="' + chatRoomInfo.Code + '"]'), chatRoomInfo);
                                    // }
                                    const newTickets = self.tickets.map(oldTicket => {
                                        const newTicket = chatRoomInfos.find(f => f.Code === oldTicket.Code);
                                        // if (newChatRoom) {
                                        //     return newChatRoom;
                                        // }
                                        if (newTicket) {
                                            newTicket.Infos.Request_Date_Time_Moment = moment(newTicket.LastUpdate).fromNow();
                                            newTicket.Infos.Request_Date_Time = new Date(newTicket.Infos.Request_Date_Time).toLocaleString();
                                            const unread = newTicket.NumOfMessage - newTicket.LoggedNumOfMessage - newTicket.LoggedNumOfReadMessage;
                                            newTicket.NumOfUnreadMessage = unread > 0 ? unread : '';
                                            return newTicket;
                                        }
                                        return oldTicket;
                                    });
                                    self.$setState({
                                        tickets: newTickets,
                                    });
                                    $this.rootServices.changedChatRooms.helpdesk = [];
                                    self.updateBadge();
                                }).catch(err => {
                                    console.error(err);
                                    $this.commonService.showError(err);
                                });
                            }, 1500);
                        }
                    },
                    pageAfterIn(e, page) {
                        console.log('p[page event]age after in', page.route.url);
                        const self: F7ComponentContextExtend = this;
                        $this.onChangedState({ instance: self, page }, 'main', 'before-in').then(currentState => {
                            self.$setState({
                                lastUpdateMoment: moment(self.lastUpdate).fromNow(),
                            });
                        });
                    },
                    pageBeforeOut(e, page) {
                        console.log('[page event] before out', page.route.url);
                    },
                    pageAfterOut(e, page) {
                        console.log('[page event] after out', page.route.url);
                    },
                    pageBeforeUnmount(e, page) {
                        console.log('[page event] before unmount', page.route.url);
                    },
                    pageBeforeRemove(e, page) {
                        console.log('[page event] before remove', page.route.url);
                    },
                },
            },
        };
    }
}
