import { SalesMasterPriceTable } from './sales.model';
import { ProductModel } from './product.model';
import { Model } from './model';
export class PriceReportVoucherModel extends Model {
    [key: string]: any;
    Code?: string;
    Object?: string;
    ObjectName?: string;
    ObjectPhone?: string;
    ObjectEmail?: string;
    ObjectAddress?: string;
    ObjectIdentified?: string;
    Contact?: string;
    ContactName?: string;
    ContactPhone?: string;
    ContactEmail?: string;
    ContactAddress?: string;
    ContactIdentified?: string;
    DateOfReport?: string;
    DateOfCreated?: string;
    Creator?: string;
    DeliveryAddress?: string;
    Title?: string;
    Note?: string;
    SubNote?: string;
    PriceTable?: SalesMasterPriceTable;
    Details?: PriceReportVoucherDetailModel[];

    constructor(properties?: PriceReportVoucherModel) {
        super(properties);
        this.Details = [];
    }

    static getInstance() {
        return new PriceReportVoucherModel();
    }
}

export class PriceReportVoucherDetailModel extends Model {
    [key: string]: any;
    No?: number;
    Product?: ProductModel;
    Description?: string;
    Unit?: { id?: string, text?: string, [key: string]: any };
    Quantity?: number = 0;
    Price?: number = 0;
    Tax?: any;
    ToMoney?: number = 0;

    constructor(properties?: PriceReportVoucherDetailModel) {
        super(properties);
        this.Quantity = 1;
        this.Price = 0;
    }
}