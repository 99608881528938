import { ProcessMap } from '../../model/model';
import { stateMap } from '../../state.map';

export class DeploymentStateMap {

    static deploymentStateMap = {
        '': {
            ...stateMap.NOTJUSTAPPROVED,
            nextState: 'APPROVED',
            nextStates: [
                stateMap.APPROVED,
            ],
        } as ProcessMap,
        NOTJUSTAPPROVED: {
            ...stateMap.NOTJUSTAPPROVED,
            nextState: 'APPROVED',
            nextStates: [
                stateMap.APPROVED,
            ],
        } as ProcessMap,
        APPROVED: {
            ...stateMap.APPROVED,
            outline: true,
            nextState: 'COMPLETED',
            nextStates: [
                stateMap.COMPLETED,
            ],
        } as ProcessMap,
        QUEUE: {
            ...stateMap.QUEUE,
            nextState: 'APPROVED',
            nextStates: [
                stateMap.APPROVED,
            ],
        } as ProcessMap,
        COMPLETED: {
            ...stateMap.COMPLETED,
            nextState: 'UNRECORDED',
            nextStates: [
                stateMap.UNRECORDED,
            ],
        } as ProcessMap,
        UNRECORDED: {
            ...stateMap.UNRECORDED,
            nextState: 'APPROVED',
            nextStates: [
                stateMap.APPROVED,
            ],
        } as ProcessMap,
    };
}
