import { FileModel } from "./file.model";
import { Model } from "./model";

export class CollaboratorOrderModel extends Model {
    [key: string]: any;
    Id?: string;
    Page?: any;
    Publisher?: any;
    Code?: string;
    Object?: any;
    ObjectName?: string;
    ObjectAddress?: string;
    ObjectPhone?: string;
    ObjectEmail?: string;
    ObjectTaxCode?: string;
    ObjectBank?: string;
    ObjectBankName?: string;
    ObjectBankAccount?: string;
    ObjectIdentifiedNumber?: string;
    DateOfOrder?: string;
    DateOfDelivery?: string;
    DeliveryAddress?: string;
    DirectReceiverName?: string;
    IsExportVatInvoice?: string;
    Title?: string;
    Note?: string;
    SubNote?: string;
    State?: any;
    Permission?: string;
    PriceTable?: string;
    Thread?: string;
    RelativeVouchers?: any[];

    Details?: CollaboratorOrderDetailModel[];

    constructor(properties?: CollaboratorOrderModel) {
        super(properties);
        this.Details = [];
    }
}

export class CollaboratorOrderDetailModel extends Model {
    [key: string]: any;
    Id?: string;
    Voucher?: string;
    Type?: string;
    No?: number;
    Image?: string;
    Product?: any;
    Description?: string;
    Quantity?: number;
    Price?: number;
    Tax?: any;
    Currency?: string;
    Unit?: any;

    constructor(properties?: CollaboratorOrderDetailModel) {
        super(properties);
        this.Quantity = 1;
        this.Price = 0;
    }
}
export class CollaboratorOpportunityModel extends Model {
    [key: string]: any;
    Id?: string;
    Page?: any;
    Publisher?: any;
    Code?: string;
    Customer?: any;
    CustomerName?: string;
    CustomerAddress?: string;
    CustomerPhone?: string;
    CustomerEmail?: string;
    CustomerTaxCode?: string;
    CustomerBank?: string;
    CustomerBankName?: string;
    CustomerBankAccount?: string;
    CustomerIdentifiedNumber?: string;
    DateOfOrder?: string;
    DateOfDelivery?: string;
    DeliveryAddress?: string;
    DirectReceiverName?: string;
    IsExportVatInvoice?: string;
    Title?: string;
    Note?: string;
    SubNote?: string;
    State?: any;
    Permission?: string;
    PriceTable?: string;
    Thread?: string;
    RelativeVouchers?: any[];

    Details?: CollaboratorOpportunityDetailModel[];

    constructor(properties?: CollaboratorOpportunityModel) {
        super(properties);
        this.Details = [];
    }
}

export class CollaboratorOpportunityDetailModel extends Model {
    [key: string]: any;
    Id?: string;
    Voucher?: string;
    Type?: string;
    No?: number;
    Image?: string;
    Product?: any;
    Description?: string;
    Quantity?: number;
    Price?: number;
    Tax?: any;
    Currency?: string;
    Unit?: any;

    constructor(properties?: CollaboratorOpportunityDetailModel) {
        super(properties);
        this.Quantity = 1;
        this.Price = 0;
    }
}


export class CollaboratorOpportunityCommentModel extends Model {
    Id?: string;
    Opportunity?: any;
    Content?: string;
    Attachments?: FileModel[];
    DateOfPost?: Date;
    Sender?: any;
    SenderName?: string;
    SenderAvatar?: any;
    SystemUuid?: string;

    constructor(perperties?: CollaboratorOpportunityCommentModel) {
        super(perperties);
    }
}

export class ECardModel extends Model {
    Id?: string;
    SerialNumber?: string;
    Name?: string;
    Phone?: string;
    Email?: string;
    Address?: string;
    Wwebsite?: string;
    Avatar?: string;

    constructor(perperties?: ECardModel) {
        super(perperties);
    }
}