import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, isPlatform, ToastController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IonicStorageModule } from '@ionic/storage';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule, DatePipe, DecimalPipe, registerLocaleData } from '@angular/common';
import { ImagePicker } from '@ionic-native/image-picker/ngx';
import { File } from '@ionic-native/file/ngx';
import { ImageResizer } from '@ionic-native/image-resizer/ngx';
import { Base64 } from '@ionic-native/base64/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { Chooser } from '@ionic-native/chooser/ngx';
import { Push } from '@ionic-native/push/ngx';
import { Device } from '@ionic-native/device/ngx';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import localeVi from '@angular/common/locales/vi';
import localeViExtra from '@angular/common/locales/extra/vi';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { Globalization } from '@ionic-native/globalization/ngx';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CodePush } from '@ionic-native/code-push/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { Base64ToGallery } from '@ionic-native/base64-to-gallery/ngx';
import { StreamingMedia } from '@ionic-native/streaming-media/ngx';
import { MediaCapture } from '@ionic-native/media-capture/ngx';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { Market } from '@ionic-native/market/ngx';
import { Badge } from '@ionic-native/badge/ngx';
import { Dialogs } from '@ionic-native/dialogs/ngx';
import { CurrencyPipe } from '@angular/common';
import { NotificationService } from 'vendors/smart-bot-app/src/app/services/notification.service';
import { ApiInterceptor } from 'vendors/smart-bot-app/src/app/services/api.service';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { SQLite } from '@awesome-cordova-plugins/sqlite/ngx';
import { Printer } from '@awesome-cordova-plugins/printer/ngx';
import { DocumentViewer } from '@awesome-cordova-plugins/document-viewer/ngx';
import { DocumentScanner } from '@awesome-cordova-plugins/document-scanner/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { ZBar } from '@awesome-cordova-plugins/zbar/ngx';
import { AudioManagement } from '@ionic-native/audio-management/ngx';
import { NativeAudio } from '@ionic-native/native-audio/ngx';
import { FirebaseMessaging } from '@awesome-cordova-plugins/firebase-messaging/ngx';

// if (environment.mode === 'production') {
//   console.log = () => { }
//   console.info = () => { }
//   console.warn = () => { }
// }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

registerLocaleData(localeVi, 'vi', localeViExtra);
export function getTheme() {
  return isPlatform('android') ? 'md' : 'ios';
  // return isPlatform('ios') ? 'ios' : 'md';
}

export class DynamicLocaleId extends String {
  constructor(public translate: TranslateService) {
    super();
  }

  toString() {
    return this.translate.currentLang;
  }
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    CommonModule,
    BrowserModule,
    IonicStorageModule.forRoot({
      name: 'probox',
      driverOrder: ['localstorage', 'sqlite', 'indexeddb', 'websql']
    }),
    HttpClientModule,
    IonicModule.forRoot({
      mode: getTheme(),
    }),
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    DatePipe,
    ImagePicker,
    File,
    ImageResizer,
    Base64,
    WebView,
    Keyboard,
    Camera,
    Chooser,
    NotificationService,
    Push,
    Device,
    FirebaseX,
    FirebaseMessaging,
    BackgroundMode,
    Globalization,
    CodePush,
    ToastController,
    InAppBrowser,
    FileTransfer,
    Base64ToGallery,
    StreamingMedia,
    MediaCapture,
    Clipboard,
    Geolocation,
    BarcodeScanner,
    Market,
    Badge,
    Dialogs,
    CurrencyPipe,
    Deeplinks,
    DecimalPipe,
    SQLite,
    Printer,
    DocumentViewer,
    FileOpener,
    DocumentScanner,
    ZBar,
    NativeAudio,
    AudioManagement,
    Title,
    {
      provide: LOCALE_ID,
      useClass: DynamicLocaleId,
      deps: [TranslateService],
      // useValue: 'vi-VN',
    },
    // { provide: CurrencyPipe, useValue: 'vi-VN' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
