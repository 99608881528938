import { CurrencyPipe } from '@angular/common';
import { Autocomplete } from 'framework7/build/core/components/autocomplete/autocomplete';
import { FormSchema } from '../../../src/app/lib/base-form-component';
import { BaseComponent, ComponentState } from '../../../src/app/lib/base-component';
import { CommonService } from '../services/common.service';
import { RootServices } from '../services/root.services';
import { F7ComponentContext, F7Page } from '../types/framework7-types';
import { Model } from "../model/model";
import { VirtualList } from 'framework7/build/core/components/virtual-list/virtual-list';
import { Searchbar } from 'framework7/build/core/components/searchbar/searchbar';
import { SmartSelect } from 'framework7/build/core/components/smart-select/smart-select';
import { ContactModel } from '../model/contact.model';
// import * as $ from 'jquery';
declare const $: any;

// declare const $: any;

export interface ListComponentStateExtend<M extends Model> extends ComponentState {
    [key: string]: any;
    instance?: F7ListComponentContext<M>,
    list?: M[];
    limit?: number;
    offset?: number;
}

export interface F7ListComponentContext<M> extends F7ComponentContext {
    [key: string]: any;
    // list?: M[];
    virtualList?: VirtualList.VirtualList;
    searchBar?: Searchbar.Searchbar;

}

export abstract class BaseListComponent<S extends ListComponentStateExtend<M>, M> extends BaseComponent<ComponentState>  {

    apiPath: string;
    currentState: S;

    idKey: string;
    abstract namespace: string;
    limit = 100;

    extendComponentId: string;
    abstract itemTemplate: string;
    abstract itemHeight: number | Function;

    filterSchema: FormSchema;

    constructor(
        public rootServices: RootServices,
        public commonService: CommonService,
    ) {
        super(rootServices);
    }

    async onComponentInit(state: ComponentState, index: string, asCase?: string, page?: F7Page): Promise<ListComponentStateExtend<M>> {
        return super.onComponentInit(state, index, asCase, page).then(async (currentState: ListComponentStateExtend<M>) => {
            const $this = this;
            if (!currentState.filter) {
                currentState.filter = {};
            }
            for (const fieldName in this.filterSchema) {
                const prop = this.filterSchema[fieldName];
                if (prop.type == 'autocomplete') {
                    const autocomplete = this.createAutocompleteField($(currentState.instance.el).find('.autocomplete[name="' + fieldName + '"]')[0], currentState, (query) => {
                        return prop.ajax(query);
                    }, (value) => {
                        currentState.filter[fieldName] = value;
                        currentState.instance.$setState({
                            filter: currentState.filter,
                            // validates: this.formValidate()
                        });
                        currentState.instance.onFilterFieldChange({ target: fieldName, value: value });
                    }, (value) => {
                        console.log(value);
                    }, prop.autocompleteOption);
                }
                if (prop.type == 'autocomplete-contact') {
                    const autocomplete = this.createAutocompleteField($(currentState.instance.el).find('.autocomplete[name="' + fieldName + '"]')[0], currentState, (query) => {
                        return this.rootServices.apiService.getPromise<ContactModel[]>('/contact/contacts', { search: query, includeIdText: true, includeGroups: true }).then(results => {
                            return results.map(m => {
                                m.text = `${m.Name}${m.Groups ? ' (' + m.Groups.map(g => this.commonService.getObjectText(g as any)).join(', ') + ')' : ''}`;
                                return m;
                            });
                        });
                    }, (value) => {
                        currentState.filter[fieldName] = value;
                        currentState.instance.$setState({
                            filter: currentState.filter,
                            // validates: this.formValidate()
                        });
                        this.onFilterFieldChange(currentState.instance, { target: fieldName, value: value });
                    }, (value) => {
                        console.log(value);
                    }, prop.autocompleteOption);
                }
                if (prop.type == 'autocomplete-product') {
                    const autocomplete = this.createAutocompleteField($(currentState.instance.el).find('.autocomplete[name="' + fieldName + '"]')[0], currentState, (query) => {
                        return this.rootServices.apiService.getPromise<ContactModel[]>('/admin-product/products', { search: query, includeIdText: true }).then(results => {
                            return results;
                        });
                    }, (value) => {
                        currentState.filter[fieldName] = value;
                        currentState.instance.$setState({
                            data: currentState.filter,
                            // validates: this.formValidate()
                        });
                        this.onFilterFieldChange(currentState.instance, { target: fieldName, value: value });
                    }, (value) => {
                        console.log(value);
                    }, prop.autocompleteOption);
                }
                if (prop.type == 'datepicker') {
                    const datepicker = this.createDatePickerField($(currentState.instance.el).find('.calendar[name="' + fieldName + '"]')[0], currentState, (value) => {
                        currentState.filter[fieldName] = value;
                        currentState.instance.$setState({
                            data: currentState.filter,
                            // validates: this.formValidate()
                        });
                        if (prop.dataType == 'range') {
                            if (value.length == 2) {
                                this.onFilterFieldChange(currentState.instance, { target: fieldName, value: value });
                            }
                        } else {
                            this.onFilterFieldChange(currentState.instance, { target: fieldName, value: value });
                        }
                    }, (value) => {
                        console.log(value);
                    }, {
                        dateFormat: prop.format || 'HH::mm, dd/mm/yyyy',
                        rangePicker: true
                    });
                }
            }

            this.extendComponentId = currentState.instance.$route?.context?.extendComponentId;
            if (!currentState.offset) {
                currentState.offset = 0;
            }
            if (!currentState.limit) {
                currentState.limit = this.limit;
            }
            const cs: ListComponentStateExtend<M> = this.state[index];


            // Restore filter
            // if (cs && cs.filter && (cs?.filter?.Categories || cs?.filter?.Groups)) {
            if (cs && cs.filter) {

                for (const filterName in cs.filter) {
                    let field = null;
                    if (this.filterSchema[filterName]) {
                        await this.commonService.waitFor(100, 10, async () => {
                            const element = $(page.el).find('.filter [name="' + filterName + '"]');
                            if (element.length < 0) return false;
                            await this.commonService.waiting(100);
                            if(this.filterSchema[filterName].type == 'smart-select') {
                                field = state.instance.$app.smartSelect.get(element[0]);
                                if (field) {
                                    field.setValue(cs.filter[filterName]);
                                    return true;
                                }
                            }
                            if(this.filterSchema[filterName].type == 'autocomplete') {
                                field = state.instance.$app.autocomplete.get(element[0]);
                                if (field) {
                                    // field.setValue(cs.filter[filterName]);
                                    return true;
                                }
                            }
                            return false;
                        });
                    }
                    // await this.commonService.waiting(50);
                }
            }


            // Loading flag
            currentState.instance.allowInfinite = true;
            // Last loaded index
            // currentState.instance.lastItemIndex = $(page.el).find('.list li').length;

            // Max items to load
            currentState.instance.maxItems = 200;

            // Append items per load
            currentState.instance.itemsPerLoad = 20;

            currentState.instance.infiniteOffset = 0;

            // Virtual list
            currentState.instance.virtualList = currentState.instance.$app.virtualList.create({
                // List Element
                el: $(page.el).find('.' + this.namespace + '-virtual-list') as any,
                // Pass array with items
                items: [],
                // List item Template7 template
                itemTemplate: this.itemTemplate,
                // Item height
                height: this.itemHeight,
                on: {
                    itemBeforeInsert: (virtualList, element: HTMLElement, item: M) => {
                        // console.log('befor insert', virtualList, element, item);
                        this.itemBeforeInsert(element, item)
                        $(element).unbind('click').click(() => {
                            // self.openChatRoom(null, $(element).attr('data-id'));
                            this.onItemClick(currentState.instance, item);
                        });
                    }
                }
            });

            currentState.instance.searchBar = currentState.instance.$app.searchbar.create({
                el: (this.extendComponentId ? `.${this.extendComponentId} ` : '') + '.' + this.namespace + '-searchbar',
                backdrop: true,
                backdropEl: page.$el.find('.' + this.namespace + '-searchbar-backdrop'),
                searchContainer: page.$el.find('.' + this.namespace + '-virtual-list'),
                searchIn: page.$el.find('.item-text'),
                customSearch: true,
                on: {
                    search(sb, query, previousQuery) {
                        cs.searchQuery = query;
                        $this.commonService.takeUntil(this.namespace + '-search', 600).then(() => {
                            console.log(query, previousQuery);
                            currentState.offset = 0;
                            $this.getList(currentState.instance, {
                                search: query,
                            }).then(list => {
                                currentState.instance.$$(currentState.instance.el).find('.page-content').scrollTo(0, 0);
                                currentState.instance.$setState({
                                    infiniteLoadLast: list.length < currentState.limit
                                });

                                currentState.offset = currentState.limit;
                                list = currentState.list = list.map((m, i) => { m['__index'] = i; return m; });

                                // Update list
                                currentState.instance.virtualList.replaceAllItems(list.map((item, index) => {
                                    item['__index'] = index;
                                    $this.prepareItem(item, index);
                                    return item;
                                }));

                                currentState.instance.infiniteOffset = currentState.limit;
                            }).catch(err => {
                                console.error(err);
                                $this.commonService.showError(err);
                            });
                        });
                    },
                    clear(searchbar: Searchbar.Searchbar, previousQuery: string) {
                        cs.searchQuery = null;
                    }
                }
            });

            // Restore search query
            if (cs?.searchQuery) {
                currentState.instance.searchBar.$inputEl.val(cs.searchQuery);
                currentState.instance.searchBar.query = cs.searchQuery;
                currentState.instance.searchBar.toggle();
                currentState.instance.searchBar.$inputEl.blur();
            }

            // Restore list
            let skipRefresh = false;
            if (currentState && currentState.list && currentState.list.length > 0) {
                currentState.instance.virtualList.replaceAllItems(currentState.list);

                if (currentState.scrollPosition) {
                    $(page.el).find('.page-content').scrollTop(currentState.scrollPosition);
                }

                skipRefresh = true;
            }

            // Attach 'infinite' event handler
            $(currentState.instance.el).find('.infinite-scroll-content').on('infinite', function () {
                // Exit, if loading in progress
                if (currentState.instance.infiniteLoadLast) {
                    console.log('Load last !');
                    return;
                };
                if (!currentState.instance.allowInfinite) return;

                // Set loading flag
                currentState.instance.allowInfinite = false;

                // Emulate 1s loading
                $this.getList(currentState.instance, {
                    search: currentState.instance.searchBar.query,
                    offset: currentState.offset,
                    limit: currentState.limit,
                }).then(list => {
                    currentState.offset += currentState.limit;
                    list.map((item, index) => {
                        $this.prepareItem(item, index);
                        return item;
                    });
                    currentState.instance.$setState({
                        infiniteLoadLast: list.length < currentState.limit
                    });

                    currentState.list = currentState.instance.virtualList.items = currentState.instance.virtualList.items.concat(list);
                    currentState.list.map((m, i) => { m['__index'] = i; return m; });
                    currentState.instance.virtualList.update();

                    currentState.instance.allowInfinite = true;

                    // currentState.instance.updateBadge();
                }).catch(err => {
                    console.error(err);
                    $this.commonService.showError(err);
                });
            });


            // Load first time
            if (!skipRefresh) await this.refresh(currentState.instance);

            currentState.instance.$setState({filter: cs.filter});

            return currentState;
        });
    }

    abstract onItemClick(self: F7ListComponentContext<M>, item: M): void;
    abstract itemBeforeInsert(element: HTMLElement, item: M): void;
    abstract prepareItem(item: M, index: number): M;

    abstract getList(self?: F7ListComponentContext<M>, extParams?: any): Promise<M[]>;

    async refresh(self?: F7ListComponentContext<M>): Promise<M> {
        const $this = this;
        if (!self) {
            self = this.currentState.instance;
        }
        const currentState = this.state[self.componentIndex];

        console.log('refresh chat room list');
        currentState.offset = 0;
        currentState.list = await this.getList(self, {
            search: self?.searchBar?.query || '',
            limit: currentState.limit || this.limit,
            offset: currentState?.offset || 0,
        }).catch(err => {
            return Promise.reject(err);
        });
        currentState.offset = currentState.limit;
        self.$setState({
            infiniteLoadLast: currentState.list.length < currentState.limit
        });

        self.virtualList.replaceAllItems(currentState.list.map((item, index) => {
            item['__index'] = index;
            $this.prepareItem(item, index);
            return item;
        }));

        // currentState.instance.updateBadge();

        return null;
    }

    updateItem(item: M) {
        const $this = this;
        this.currentState.instance.virtualList;
        let itemIndex = this.currentState.list.findIndex(f => f[this.idKey] == item[this.idKey]);
        if (itemIndex > -1) {
            this.currentState.list[itemIndex] = {
                ...this.currentState.list[itemIndex],
                ...item,
            };
        }

        this.currentState.instance.virtualList.replaceAllItems(this.currentState.list.map((item, index) => {
            item['__index'] = index;
            $this.prepareItem(item, index);
            return item;
        }));
    }

    async onFilterFieldChange(self: F7ListComponentContext<M>, e: any): Promise<any> {

        const currentState: ComponentState = this.currentState;
        let field;
        let name;
        let fieldValue;
        let control: SmartSelect.SmartSelect | Autocomplete.Autocomplete;
        if (typeof e.target == 'string') {
            name = e.target;
            fieldValue = e.value;
        } else {
            field = $(e.target);
            name = field.attr('name');
            // fieldValue;
            // control;
        }

        if (!currentState.filter) currentState.filter = {};
        if (this.filterSchema[name].type == 'smart-select') {
            const control = self.$app.smartSelect.get(e.target);
            fieldValue = control.getValue();
            currentState.filter[name] = fieldValue;
        }

        this.commonService.showPreloader(10000);
        this.refresh(self).then(rs => {
            this.commonService.hidePreloader();
        });

        return { field, name, fieldValue, control };

    }

    parseFilter(filter: any) {
        if (!filter) {
            return {};
        }
        const params = {};
        for (const name in filter) {

            if (this.filterSchema[name].dataType == 'multiple') {
                if (Array.isArray(filter[name]) && filter[name].length > 0) {
                    params['eq_' + name] = '[' + filter[name].map(m => this.commonService.getObjectId(m))?.join(',') + ']';
                }
            } else if (this.filterSchema[name].dataType == 'range') {
                if (Array.isArray(filter[name]) && filter[name].length > 1) {
                    if (this.filterSchema[name].type == 'datepicker') {
                        const from: Date = filter[name][0];
                        const to: Date = filter[name][1];
                        params['ge_' + name] = new Date(from.getFullYear(), from.getMonth(), from.getDate(), 0, 0, 0, 0).toISOString();
                        params['le_' + name] = new Date(to.getFullYear(), to.getMonth(), to.getDate(), 23, 59, 59, 999).toISOString();
                    } else {
                        params['ge_' + name] = filter[name][0];
                        params['le_' + name] = filter[name][1];
                    }
                }
            } else if (this.filterSchema[name].searchType) {
                if (filter[name]) {
                    params[this.filterSchema[name].searchType + '_' + name] = Array.isArray(filter[name]) ? (filter[name].map(m => this.commonService.getObjectId(m))) : this.commonService.getObjectId(filter[name]);
                }
            } else {
                if (filter[name]) {
                    params['eq_' + name] = this.commonService.getObjectId(filter[name]);
                }
            }

        }
        return params;
    }

    resetFilter(self: F7ListComponentContext<M>, e?: any) {

        for (const name in this.currentState.filter) {
            if (this.filterSchema[name].type == 'smart-select') {
                self.$app.smartSelect.get($(self.el).find('[name="' + name + '"]')[0]).setValue(this.filterSchema[name].dataType == 'multiple' ? [] : null);
            }
            if (/^autocomplete/.test(this.filterSchema[name].type)) {
                self.$app.autocomplete.get($(self.el).find('[name="' + name + '"]')[0]).value = [];
            }
        }

        this.currentState.filter = {};
        this.currentState.instance.$setState({ filter: {} });
        this.commonService.showPreloader(10000);
        this.refresh(self).then(rs => {
            this.commonService.hidePreloader();
        });
    }

    get isChooseMode(): boolean {
        return this.currentState.instance.$route?.params?.action == 'choose'
    };

}