import { Dialog } from "framework7/build/core/components/dialog/dialog";
import { Router } from "framework7/build/core/modules/router/router";
import { take } from "rxjs/operators";
import { BaseFormComponent, FormComponentStateExtend, FormSchema } from "../../../lib/base-form-component";
import { ChatRoomModel } from "../../..//model/chat-room.model";
import { CollaboratorOrderModel, CollaboratorOrderDetailModel } from "../../..//model/collaborator.model";
import { ContactModel } from "../../..//model/contact.model";
import { FileModel } from "../../..//model/file.model";
import { ProductModel } from "../../..//model/product.model";
import { CommonService } from "../../..//services/common.service";
import { RootServices } from "../../..//services/root.services";
import { F7ComponentContext, F7Page } from "../../..//types/framework7-types";
import { ContactFormComponent } from "../../contact/contact-form";


// import * as $ from 'jquery';
declare const $: any;

// declare const $: any;
export interface ComponentStateExtend extends FormComponentStateExtend<CollaboratorOrderModel> {
  [key: string]: any;
  instance: F7ComponentContextExtend,
  data?: CollaboratorOrderModel;
  form?: any;
}

export class F7ComponentContextExtend extends F7ComponentContext {
  responseInProgress?: boolean;

  detailDialog?: Dialog.Dialog;

  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, [key: string]: any } };
}

/** Component group manager */
export class CollaboratorCustomerFormComponent extends ContactFormComponent {
  // states: { [key: string]: State } = {};
  currentState: ComponentStateExtend;
  title = 'Khách hàng';
  namespace = 'colaborator-customer-form';
  path = '/collaborator/customer-form/:id';
  idKey = 'Code';
  apiPath = '/collaborator/contacts';

  // unitList: UnitModel[] = [];
  // priceTableList: SalesMasterPriceTable[] = [];

  contactList: ContactModel[] = [];

  schema: FormSchema = {
    Name: {
      type: 'input',
      label: 'Tên liên hệ',
      validators: [
        'required'
      ]
    },
    Note: {
      type: 'texteditor',
    },
  };

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
  ) {
    super(rootServices, commonService);
    // // console.log('Click here to open ts file');

    this.rootServices.platform.pause.subscribe(status => {
      // Auto save
      this.currentState?.data?.Code && this.currentState?.data?.State != 'APPROVED' && this.currentState.instance.save();
    });
  }
}
