import { JwtToken } from './../../services/auth.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take, map } from 'rxjs/operators';
import { Storage } from '@ionic/storage';

export interface FrameSocketData<T> {
    seq: number;
    status: number;
    type: string;
    event: string;
    data: T;
}

export interface IFrameSocketResult<T> {
    data: T;
    callback?: (type: string, response: any) => void;
}

export class FrameSocket {

    protected seq = 0;
    protected emitCallbackSubject = new BehaviorSubject<FrameSocketData<any>>(null);
    public emitCallback$ = this.emitCallbackSubject.asObservable();

    protected receiverSubject = new BehaviorSubject<FrameSocketData<any>>(null);
    public receiver$ = this.receiverSubject.asObservable();
    public isFrameMode = false;

    protected event = new BehaviorSubject<{ name: string, data?: any }>(null);
    public event$ = this.event.asObservable();
    id: string;

    constructor(
        protected connection: Window,
        protected storage: Storage,
    ) {
        const url = new URL(window.location.href);
        this.id = url.searchParams.get('id');
        this.on<FrameSocketData<any>>('callback').subscribe((result) => {
            console.debug('On Callback : ', result);
            this.emitCallbackSubject.next(result.data);
            // this.stateSubject.next('init-event ');
        });
        window.onmessage = (e: { data: any }) => {
            console.debug(e);
            this.receiverSubject.next(e.data);
        }
        this.init();
    }

    async init() {
        this.emit('ready', true).then(rspData => {
            console.debug(rspData);
        });

        this.on<JwtToken>('set-token').subscribe(request => {
            console.debug(request);

            // Set frame mode
            this.isFrameMode = true;

            if (request && request.data && request.data.api_url && request.data.access_token && request.data.refresh_token) {
                this.storage.set('jwt-token', request.data);
                request.callback('success', true);
                this.event.next({ name: 'set-token' });
            } else {
                request.callback('error', 'request data was not an JwtToken');
            }
        });

        this.on<JwtToken>('remove-token').subscribe(request => {
            console.debug(request);
            this.storage.remove('jwt-token').then(() => {
                //   this.commonService.navigate('/login');
                this.event.next({ name: 'remove-token' });
            });
            request.callback('success', true);
        });
        return true;
    }

    async emit<T>(event: string, data: any): Promise<T> {
        const checkpointSeq = this.seq++;
        console.log('Smart-BOT:Frame: ', window);
        this.connection.postMessage({ type: event === 'callback' ? 'response' : 'request', id: this.id, seq: checkpointSeq, event: event, data }, '*');
        const result = await this.emitCallback$.pipe(filter(rs => rs && rs.seq === checkpointSeq), take(1)).toPromise();
        if (!result || result.type == 'error') {
            throw Error(result.data);
        }
        return result.data;
    }

    on<T>(event: string): Observable<IFrameSocketResult<T>> {
        return this.receiver$.pipe(filter(request => request && request.event == event), map(request => ({
            data: request.data,
            callback: (type: string, response) => {
                this.emit('callback', { type, seq: request.seq, data: response });
            }
        })));
    }

}
