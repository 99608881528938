import { F7ComponentContext } from '../types/framework7-types';
import { Router } from 'framework7/build/core/modules/router/router';
import { BaseComponent, ComponentState } from '../lib/base-component';
import { RootServices } from '../services/root.services';
import { take } from 'rxjs/operators';
import { Member } from '../lib/nam-chat/model/member';
import { ChatRoom } from '../lib/nam-chat/chat-room';
import { CommonService } from '../services/common.service';
import { ChatRoomMemberModel } from '../model/chat-room.model';
// import * as $ from 'jquery';
declare const $: any;

// declare const $: any;
export interface ComponentStateExtend extends ComponentState {
  [key: string]: any;
}

export class F7ComponentContextExtend extends F7ComponentContext {
  // messagebar?: F7Messagebar;
  // messages?: Messages.Messages;
  // images?: string[];
  responseInProgress?: boolean;
  // answers?: string[];
  // people?: { name?: string, avatar?: string }[];
  // hideToolbar?: () => void;
  // sheetToggle?: () => void;
  // deleteAttachment?: (e: MouseEvent, index: number) => void;
  // handleAttachment?: (e: MouseEvent) => void;
  // checkAttachments?: () => void;
  // sendMessage?: () => void;
  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, memberInfo?: Member, chatRoom?: ChatRoom, type?: string } };
}

/** Component group manager */
export class ProfileComponent extends BaseComponent<ComponentStateExtend> {
  // states: { [key: string]: State } = {};

  title: 'Profile'

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
  ) {
    super(rootServices);
    // // console.log('Click here to open ts file');
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: 'profile',
      path: '/profile/:id',
      component: {
        template: /*html*/`
            <div class="page no-toolbar-x" data-name="profile">
              <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a class="link back {{textColorClass}}">
                      <i class="icon icon-back"></i>
                      <span class="if-not-md {{textColorClass}}">{{backTitle}}</span>
                    </a>
                  </div>
                  <div class="title">{{title}}</div>
                  <div class="right">
                    <a class="link icon-only {{js "this.editable ? 'text-color-editable' : this.textColorClass"}}"
                      @click="toggleEditContact">
                      {{#if editable}}
                      <i class="icon f7-icons">checkmark_alt</i>
                      {{else}}
                      <i class="icon f7-icons">square_pencil_fill</i>
                      {{/if}}
                    </a>
                  </div>
                </div>
              </div>
              <div class="page-content">
            
                <div class="profile-info">
                  <div class="profile-logo bg-color-gray"
                    style="background-image: url({{js "this.memberInfo && this.memberInfo.AvatarThumbnail"}})"></div>
                  <div class="profile-title text-color-default {{textColorClass}}">
                    {{js "this.memberInfo && this.memberInfo.Name || ''"}}</div>
                  <div class="profile-subtitle text-color-gray">{{js "this.memberInfo && this.memberInfo.Role"}}</div>
                </div>
            
                <div class="list inset">
                  <ul>
                    <li>
                      <div class="item-content">
                        <div class="item-media"><i
                            class="icon f7-icons text-color-default {{js "this.editable ? 'text-color-editable' : this.textColorClass"}}">person_circle_fill</i>
                        </div>
                        <div class="item-inner">
                          <input type="text" {{js "this.editable ? '' : 'disabled'"}}
                            class="field item-title {{js "this.editable ? 'text-color-editable' : ''"}}" name="Name"
                            value="{{js "this.memberInfo && this.memberInfo.Name || ''"}}" style="height: 23px" />
                        </div>
                      </div>
                    </li>
                    <li class="item-link">
                      <div class="item-content">
                        <div class="item-media"><i
                            class="icon f7-icons text-color-default {{js "this.editable ? 'text-color-editable' : this.textColorClass"}}">phone_circle_fill</i>
                        </div>
                        <div class="item-inner">
                          <input type="tel" {{js "this.editable ? '' : 'disabled'"}}
                            class="field item-title {{js "this.editable ? 'text-color-editable' : ''"}}" name="Phone"
                            style="height: 23px"
                            placeholder="{{js "this.memberInfo && this.memberInfo.Phone || 'Thêm số điện thoại'"}}" />
                          <div class="item-after text-color-red" @click="click2call">Gọi</div>
                        </div>
                      </div>
                    </li>
                    <li class="item-link">
                      <div class="item-content">
                        <div class="item-media"><i
                            class="icon f7-icons text-color-default {{js "this.editable ? 'text-color-editable' : this.textColorClass"}}">at_circle_fill</i>
                        </div>
                        <div class="item-inner">
                          <input type="text" {{js "this.editable ? '' : 'disabled'"}}
                            class="field item-title {{js "this.editable ? 'text-color-editable' : ''"}}" name="Email"
                            style="height: 23px" placeholder="{{js "this.memberInfo && this.memberInfo.Email || 'Thêm email'"}}" />
                        </div>
                      </div>
                    </li>
                    <li class="item-link" @click="checkZaloAndConnnect">
                      <div class="item-content">
                        <div class="item-media">
                          <i class="icon f7-icons text-color-default {{textColorClass}}">chat_bubble_text_fill</i>
                        </div>
                        <div class="item-inner">
                          <div class="item-title">Zalo {{js "this.memberInfo && this.memberInfo.ZaloUser && (': ' + this.memberInfo.ZaloUser) || ''"}}</div>
                          {{#js_if "this.memberInfo && this.memberInfo.ZaloUserStatus"}}
                          <div class="item-after text-color-red">Đã kết nối</div>
                          {{else}}
                          <div class="item-after text-color-red">Chưa kết nối</div>
                          {{/js_if}}
                        </div>
                      </div>
                    </li>
                    {{#js_if "this.memberInfo && this.memberInfo.RefPlatform"}}
                    <li class="item-link" @click="checkRefPlatformAndConnnect">
                      <div class="item-content">
                        <div class="item-media">
                          {{#js_if "this.memberInfo && this.memberInfo.RefPlatform == 'ZALO'"}}
                          <i class="icon f7-icons text-color-default {{textColorClass}}">chat_bubble_text_fill</i>
                          {{/js_if}}
                          {{#js_if "this.memberInfo && this.memberInfo.RefPlatform == 'PROBOXONE'"}}
                          <img src="assets/icon/probox.ico" style="width: 28px;">
                          {{/js_if}}
                        </div>
                        <div class="item-inner">
                          <div class="item-title">{{js "this.memberInfo && this.memberInfo.Page || this.memberInfo.Core?.text"}}</div>
                          {{#js_if "this.memberInfo && this.memberInfo.RefConnectState == 'CONNECTED'"}}
                          <div class="item-after text-color-red">Đã kết nối</div>
                          {{else}}
                          <div class="item-after text-color-red">Chưa kết nối</div>
                          {{/js_if}}
                        </div>
                      </div>
                    </li>
                    {{/js_if}}
                    {{#js_if "this.memberInfo && this.memberInfo.Type === 'CONTACT'"}}
                    <li>
                      <div class="item-content">
                        <div class="item-media" style="margin-top: -60px;"><i
                            class="icon f7-icons text-color-default {{js "this.editable ? 'text-color-editable' : this.textColorClass"}}">map_fill</i>
                        </div>
                        <div class="item-inner">
                          <textarea {{js "this.editable ? '' : 'disabled'"}}
                            class="field {{js "this.editable ? 'text-color-editable' : ''"}}" name="Address" rows="3"
                            placeholder="{{js "this.memberInfo && this.memberInfo.Address || 'Thêm địa chỉ'"}}"></textarea>
                        </div>
                      </div>
                    </li>
                    {{/js_if}}
                    {{#js_if "this.memberInfo && this.memberInfo.Type == 'USER'"}}
                    <li class="item-link" @click="checkLastLocation">
                      <div class="item-content">
                        <div class="item-media"><i class="icon f7-icons text-color-default {{textColorClass}}">compass_fill</i>
                        </div>
                        <div class="item-inner">
                          <div class="item-title">Tương tác lần cuối</div>
                          <div class="item-after text-color-red">Kiểm tra</div>
                        </div>
                      </div>
                    </li>
                    <li class="item-link smart-select smart-select-init smart-select-roles" data-open-in="sheet">
                      <select name="ROLES" multiple>
                        <option value="CHATROOMMANAGER">Quản lý</option>
                        <option value="CHATROOMMEMBER">Thành viên</option>
                        <option value="HELPDESK">Hỗ trợ</option>
                        <option value="COORDINATORS">Điều phối</option>
                        <option value="ITHELPDESK">Hỗ trợ IT</option>
                        <option value="SALESHELPDESK">Hỗ trợ bán hàng</option>
                        <option value="ITCOORDINATORS">Điều phối IT</option>
                        <option value="SALESCOORDINATORS">Điều phối bán hàng</option>
                        <option value="CUSTOMER">Khách hàng</option>
                        <option value="HELPDESKCREATOR">Người tạo yêu cầu</option>
                        <option value="MONITORING">Giám sát</option>
                        <option value="CHATROOMCREATOR">Người tạo phòng chat</option>
                        <option value="CONTACT">Liên hệ</option>
                      </select>
                      <div class="item-content">
                        <div class="item-media"><i class="icon f7-icons text-color-default {{textColorClass}}">star_circle_fill</i>
                        </div>
                        <div class="item-inner">
                          <div class="item-title">Vai trò</div>
                          <div class="item-after text-color-red"></div>
                        </div>
                      </div>
                    </li>
                    {{/js_if}}
                  </ul>
                </div>
            
            
                <div class="list inset">
                  <ul>
                    <li>
                      <a @click="removeMember"
                        class="list-button text-color-red">{{js "this.memberInfo && this.memberInfo.Type === 'CONTACT' ? 'Gở liên hệ khỏi task' : (this.memberInfo.Type === 'GROUP' ? 'Gở nhóm khỏi task' : 'Gở khỏi thành viên khỏi task')"}}</a>
                    </li>
                  </ul>
                </div>
            
                <div class="block"><br></div>
            
              </div>
            </div>
        `,
        style: `
          .profile-info {
            margin-top: 20px;
          }
          .profile-logo {
            height: 100px;
            width: 100px;
            border-radius: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            margin: 0 auto;
          }
          .profile-title {
            text-align: center;
            font-weight: bold;
          }
          .profile-subtitle {
            text-align: center;
          }
          .profile-form li .item-media i {
            /* font-size: 44px;*/
          }
        `,
        data() {
          return {
            title: $this.title,
            editable: false,
          };
        },
        methods: {
          checkZaloAndConnnect() {
            const self: F7ComponentContextExtend = this;
            const memberInfo = self.memberInfo;
            if (memberInfo.ZaloUserStatus) {
              self.$app.dialog.create({
                title: $this.rootServices.env.name || 'Smart-BOT',
                // text: 'Liên hệ này chưa bấm quan tâm, bạn có muốn gửi lời mời (zns) không ?',
                text: 'Bạn có muốn ngắt kết nối zalo liên hệ này ?',
                buttons: [
                  {
                    text: 'Trở về',
                    // color: 'red'
                    onClick() {
                    },
                  },
                  {
                    text: 'Ngắt kết nối',
                    color: 'red',
                    onClick() {
                      // $this.commonService.showInfo('Lời mời quan tâm zalo page đã được gửi (zns), hệ thống sẽ tự động kết nối vào task này khi liên hệ bấm quan tâm.');
                      $this.rootServices.apiService.putPromise('/chat/room-members/' + memberInfo.id, { zaloDisconnect: true, chatRoom: self.$route.context.chatRoom.id }, [{ Type: memberInfo.Type, User: memberInfo.id, Contact: memberInfo.id }]).then(rs => {
                        $this.commonService.showInfo('Đã kết nối với tài khoản zalo của liên hệ !');
                        self.$route.context.chatRoom.syncMemberList({ force: true });
                        self.$setState({
                          memberInfo: {
                            ...self.memberInfo,
                            ZaloUserStatus: false,
                          }
                        });
                      }).catch(err => {
                        $this.commonService.showError(err);
                      });
                    },
                  }
                ]
              }).open();
            } else {
              self.$app.dialog.create({
                title: $this.rootServices.env.name || 'Smart-BOT',
                // text: 'Liên hệ này chưa bấm quan tâm, bạn có muốn gửi lời mời (zns) không ?',
                text: 'Mỗi liên hệ chỉ có thể tương tác mới 1 nhiệm vụ (task), nếu kết nối vào nhiệm vụ này thì nhiệm vụ trước đó sẽ tự động ngắt kết nối !',
                buttons: [
                  {
                    text: 'Trở về',
                    // color: 'red',
                    onClick() {
                    },
                  },
                  {
                    text: 'Kết nối',
                    color: 'red',
                    onClick() {
                      // $this.commonService.showInfo('Lời mời quan tâm zalo page đã được gửi (zns), hệ thống sẽ tự động kết nối vào task này khi liên hệ bấm quan tâm.');
                      $this.rootServices.apiService.putPromise('/chat/room-members/' + memberInfo.id, { zaloConnect: true, chatRoom: self.$route.context.chatRoom.id }, [{ Type: memberInfo.Type, User: memberInfo.id, Contact: memberInfo.id }]).then(rs => {
                        $this.commonService.showInfo('Đã kết nối với tài khoản zalo của liên hệ !');
                        self.$route.context.chatRoom.syncMemberList({ force: true });
                        self.$setState({
                          memberInfo: {
                            ...self.memberInfo,
                            ZaloUserStatus: true,
                          }
                        });
                      }).catch(err => {
                        $this.commonService.showError(err);
                      });
                    },
                  }
                ]
              }).open();
            }

          },
          checkRefPlatformAndConnnect() {
            const self: F7ComponentContextExtend = this;
            const memberInfo = self.memberInfo;
            if (memberInfo.RefConnectState == 'CONNECTED') {
              self.$app.dialog.create({
                title: $this.rootServices.env.name || 'Smart-BOT',
                // text: 'Liên hệ này chưa bấm quan tâm, bạn có muốn gửi lời mời (zns) không ?',
                text: 'Bạn có muốn ngắt kết nối liên hệ này ?',
                buttons: [
                  {
                    text: 'Trở về',
                    // color: 'red'
                    onClick() {
                    },
                  },
                  {
                    text: 'Ngắt kết nối',
                    color: 'red',
                    onClick() {
                      // $this.commonService.showInfo('Lời mời quan tâm zalo page đã được gửi (zns), hệ thống sẽ tự động kết nối vào task này khi liên hệ bấm quan tâm.');
                      $this.rootServices.apiService.putPromise('/chat/room-members/' + memberInfo.id, { disconnectRefContactMember: true, chatRoom: self.$route.context.chatRoom.id }, [{ Type: memberInfo.Type, User: memberInfo.id, Contact: memberInfo.id }]).then(rs => {
                        $this.commonService.showInfo('Đã kết nối với tài khoản của liên hệ !');
                        self.$route.context.chatRoom.syncMemberList({ force: true });
                        self.$setState({
                          memberInfo: {
                            ...self.memberInfo,
                            ...rs[0]
                          }
                        });
                      }).catch(err => {
                        $this.commonService.showError(err);
                      });
                    },
                  }
                ]
              }).open();
            } else {
              self.$app.dialog.create({
                title: $this.rootServices.env.name || 'Smart-BOT',
                // text: 'Liên hệ này chưa bấm quan tâm, bạn có muốn gửi lời mời (zns) không ?',
                text: 'Bạn có muốn kết nối liên hệ này ?',
                buttons: [
                  {
                    text: 'Trở về',
                    // color: 'red',
                    onClick() {
                    },
                  },
                  {
                    text: 'Kết nối',
                    color: 'red',
                    onClick() {
                      // $this.commonService.showInfo('Lời mời quan tâm zalo page đã được gửi (zns), hệ thống sẽ tự động kết nối vào task này khi liên hệ bấm quan tâm.');
                      $this.rootServices.apiService.putPromise('/chat/room-members/' + memberInfo.id, { connectRefContactMember: true, chatRoom: self.$route.context.chatRoom.id }, [{ Type: memberInfo.Type, User: memberInfo.id, Contact: memberInfo.id }]).then(rs => {
                        $this.commonService.showInfo('Đã kết nối với tài khoản của liên hệ !');
                        self.$route.context.chatRoom.syncMemberList({ force: true });
                        self.$setState({
                          memberInfo: {
                            ...self.memberInfo,
                            ...rs[0]
                          }
                        });
                      }).catch(err => {
                        $this.commonService.showError(err);
                      });
                    },
                  }
                ]
              }).open();
            }

          },
          checkLastLocation(e) {

          },
          toggleEditContact(e) {
            const self: F7ComponentContextExtend = this;
            if (self.type != 'CONTACT') return;



            const contactNameField = $(self.$el.find('input[name="ContactName"]'));
            const contactPhoneField = $(self.$el.find('input[name="ContactPhone"]'));
            // const contactNoteField = $(self.$el.find('textarea[name="ContactNote"]'));
            if (self.editable) {
              const member = self.$route.context.memberInfo;
              member.Name = contactNameField.val() as string;
              member.Phone = contactPhoneField.val() as string;
              // member.note = contactNoteField.val();

              const formFields = $(self.$el.find('.field'));
              member.References = {};
              for (let i = 0; i < formFields.length; i++) {
                const name = formFields.eq(i).attr('name');
                // formData[name] = formField.val();
                const val = formFields.eq(i).val();
                if (val) {
                  member.References[name] = val;
                }
              }

              const chatRoom = self.$route.context.chatRoom;
              $this.commonService.showPreloader();
              chatRoom.updateMember(member).then(rs => {
                console.log('chat room member was updated', rs);
                $this.commonService.showInfo(`Đã thay thông tin của ${member.ShortName || member.Name}`);
                $this.commonService.hidePreloader();
                self.$setState({ editable: false });
              }).catch(err => {
                console.error(err);
                $this.commonService.showError(err);
                $this.commonService.hidePreloader();

              });

              // $this.rootServices.apiService.putPromise<ChatRoomMemberModel[]>('/chat/room-members', { updateContact: true, chatRoom: self.$route.context.chatRoom.id, eq_Contact: self.$route.params['id'] }, [
              //   {
              //     ChatRoom: self.$route.context.chatRoom.id as any,
              //     Type: self.$route.context.type,
              //     Contact: self.$route.params['id'],
              //     Name: contactNameField.val(),
              //     Note: contactNoteField.val(),
              //   }]).then(rs => {
              //     console.log(rs);
              //     self.$route.context.chatRoom.syncMemberList({ force: true });
              //     self.$setState({ editable: false });
              //     $this.commonService.hidePreloader();
              //   }).catch(err => {
              //     console.error(err);
              //     $this.commonService.hidePreloader();
              //   });
            } else {
              self.$setState({ editable: true });
            }
            // $(self.$el.find('[name="ContactName"]')).force();
          },
          async refresh() {
            const self: F7ComponentContextExtend = this;
            return true;
          },
          click2call() {
            const self: F7ComponentContextExtend = this;
            self.$app.dialog.create({
              title: $this.rootServices.env.name || 'Smart-BOT',
              text: 'Tôi sẽ gọi tới số nội bộ của bạn trước, nếu offline tôi sẽ gọi qua số cá nhân, hãy kiểm tra app GS wave đã online chưa hoặc bạn đang ở kế bên điện thoại của mình !',
              buttons: [
                {
                  text: 'Đóng',
                  // color: 'red',
                  onClick() {
                  },
                },
                {
                  text: 'Gọi',
                  color: 'red',
                  onClick() {
                    const memberInfo = self.$route.context.memberInfo;
                    $this.commonService.showInfo('Đang gọi tới số của bạn...', { timeout: 30000 });
                    $this.rootServices.apiService.putPromise('/chat/room-members/' + memberInfo.id, { click2call: true, chatRoom: self.$route.context.chatRoom.id }, [{ Type: memberInfo.Type, User: memberInfo.id, Contact: memberInfo.id }]).then(rs => {
                      console.log(rs);
                      $this.commonService.showInfo('Đang chuyển cuộc gọi...', { timeout: 30000 });
                    }).catch(err => {
                      console.error(err);
                      $this.commonService.showError(err);
                    });
                  },
                }
              ]
            }).open();
          },
          // logout(e: MouseEvent) {
          //   $this.rootServices.authService.logout();
          // },
          // switchAccount(e: MouseEvent) {
          //   $this.rootServices.authService.logout();
          // },
          // updateProfile() {
          //   const self: F7ComponentContextExtend = this;
          //   const profileData: ProfileModel = {};
          //   const fieldEles = self.$el.find('.profile-field');
          //   fieldEles.each((index, fieldEle) => {
          //     console.log(fieldEle);
          //     console.log(fieldEle.value);
          //     profileData[fieldEle.name] = fieldEle.value;
          //   });
          //   console.log(profileData);
          //   $this.rootServices.apiService.putPromise<ProfileModel>('/user/users/updateProfile', {}, profileData).then(rs => {
          //     console.log(rs);
          //   });
          // },
          // async changeAvatar(e) {
          //   const self: F7ComponentContextExtend = this;
          //   console.debug(e);

          //   try {
          //     const localFiles = await $this.rootServices.pickFiles();
          //     console.debug(localFiles);
          //     const image = await $this.rootServices.uploadLocalFile(localFiles[0].url as string);
          //     console.debug(image);
          //     e.target.style.backgroundImage = 'url(' + image.Thumbnail + '?token=' + $this.rootServices.apiService.token.access_token + ')';

          //     const updateProfileResult = await $this.rootServices.apiService.putPromise('/user/users/updateProfile', {}, { Avatar: image.GlobalId })
          //     console.debug('Update profile avatar: ', updateProfileResult);

          //     // Update auth user
          //     const oldUser = $this.rootServices.authService.user$.getValue();
          //     oldUser.avatar = {...oldUser.avatar, payload: {...oldUser.avatar.payload, thumbnail: image.Thumbnail, url: image.DownloadLink}}
          //     $this.rootServices.authService.user$.next(oldUser);

          //   } catch (err) {
          //     console.error(err);
          //   }

          // },
          removeMember(e) {
            const self: F7ComponentContextExtend = this;
            // const memberId = self.$(e.target).closest('li').data('id') || self.$(e.target).data('id');
            const member = self.$route.context.memberInfo;
            const chatRoom = self.$route.context.chatRoom;
            // const currentSwipeout: any = self.$(e.target).closest('li');
            const confirmRemove = self.$app.actions.create({
              buttons: [
                [
                  {
                    text: 'Gở ' + (member.Type === 'GROUP' ? 'nhóm thành viên' : 'thành viên') + ' ' + member.Name + ' khỏi phòng chat ?',
                    label: true,
                  },
                  {
                    text: 'Gở ' + member.Name,
                    color: 'red',
                    icon: '<i class="icon f7-icons">text_badge_minus</i>',
                    bold: true,
                    onClick() {
                      // self.skipUpdateMemberList = true;
                      chatRoom.removeMember(member).then(rs => {
                        // self.$app.swipeout.delete(currentSwipeout[0]); // neu comment dong nay thi dong tiep theo cung tu dong delete
                        // self.skipUpdateMemberList = false;
                        // self.$app.swipeout.close(currentSwipeout[0], () => {
                        //   $this.currentState.chatRoom.syncMemberList();
                        // });
                        self.$router.back();
                        console.log(rs);
                      }).catch(err => {
                        // self.$app.swipeout.delete(currentSwipeout[0]);
                        // self.$app.swipeout.close(currentSwipeout[0]);
                        console.error(err);
                        $this.commonService.showError(err);
                      });
                    }
                  }
                ],
                [{
                  text: 'Trở về',
                  icon: '<i class="icon f7-icons">arrow_down_circle_fill</i>',
                  bold: true,
                  onClick() {
                    // self.$app.swipeout.close(currentSwipeout[0]);
                  }
                }],
              ] as any
            });
            confirmRemove.open();
            return true;
          },
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          pageInit(e, page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;

            const memberInfo = self.$route.context.memberInfo;
            const chatRoom = self.$route.context.chatRoom;

            // Set back title
            // self.$setState({ backTitle: self.$route.context && self.$route.context.backTitle || 'Back', title: self.$route.context && self.$route.context.title || 'Tài khoản' });

            $this.onComponentInit({ instance: self }, 'main');

            console.log(memberInfo);
            $this.rootServices.authService.isAuthenticatedOrRefresh().pipe(take(1)).toPromise().then(() => {
              self.$setState({
                backTitle: self.$route.context && self.$route.context.backTitle || 'Back', title: self.$route.context && self.$route.context.title || 'Profile',
                memberInfo: {
                  ...memberInfo,
                  User: memberInfo.User && {
                    ...(memberInfo.User as any),
                    Roles: memberInfo.Roles,
                    // avatar: memberInfo.user && memberInfo.user.avatar && ((memberInfo.avatar && memberInfo.avatar.payload && memberInfo.avatar.payload.thumbnail || memberInfo.avatar) || memberInfo.user.avatar.payload.thumbnail)
                  },
                  // avatar: memberInfo.avatar && memberInfo.avatar.payload && memberInfo.avatar.payload.thumbnail || memberInfo.avatar,
                  // Avatar: memberInfo.Type == 'CONTACT' ? (memberInfo.Avatar && memberInfo.Avatar.payload && memberInfo.Avatar.payload.thumbnail || memberInfo.Avatar) : ($this.rootServices.apiService.getBaseApiUrl() + '/user/users/' + memberInfo.id + '/avatar'),
                  // phone: memberInfo.References?.Phone,
                  // email: memberInfo.References?.Email,
                  // address: memberInfo.References?.Address,
                  // zaloUser: memberInfo.References?.ZaloUser,
                }
              });
            }).catch(err => {
              console.error(err);
              $this.commonService.showError(err);
            });

            setTimeout(() => {
              const starmtSelectRoles = self.$app.smartSelect.get('.smart-select-roles');
              if (starmtSelectRoles) {
                starmtSelectRoles.setValue(memberInfo.Roles);
                starmtSelectRoles.on('closed', (smartSelect) => {
                  const selectedRoles: string & string[] = (smartSelect.getValue() as string[]).sort() as any;
                  console.log('on smart select closed', selectedRoles);
                  if ((memberInfo.Roles || []).toString() !== (selectedRoles || []).toString()) {
                    memberInfo.Roles = selectedRoles;
                    chatRoom.updateMember(memberInfo).then(rs => {
                      console.log('chat room member was updated', rs);
                      $this.commonService.showInfo(`Đã thay đổi vai trò của ${memberInfo.ShortName || memberInfo.Name}`);
                    }).catch(err => {
                      console.error(err);
                      $this.commonService.showError(err);
                    });
                  }
                });
              }
            }, 500);
          },
          // pageBeforeIn(e, page) {
          //     console.log('page before in');
          // },
          // pageAfterIn(e, page) {
          //     console.log('page after in');
          // },
          // pageBeforeOut(e, page) {
          //     console.log('page before out');
          // },
          // pageAfterOut(e, page) {
          //     console.log('page after out');
          // },
          // pageBeforeUnmount(e, page) {
          //     console.log('page before unmount');
          // },
          // pageBeforeRemove(e, page) {
          //     console.log('page before remove');
          // },
          pageBeforeRemove(e, page) {
            console.log('[page event] before remove', page.route.url);
            const self: F7ComponentContextExtend = this;
            const smartSelectRoles = self.$app.smartSelect.get('.smart-select-roles');
            smartSelectRoles && smartSelectRoles.destroy();
          },
        },
      },
    };
  }
}
