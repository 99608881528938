import { ContactModel } from '../../../../src/app/model/contact.model';
import { WarehouseGoodsQueueModel } from './../../model/warehouse.model';
import { isObject } from 'underscore';
import { SmartSelect } from 'framework7/build/core/components/smart-select/smart-select';
import { take } from 'rxjs/operators';
import { Router } from "framework7/build/core/modules/router/router";
import { ComponentState } from "../../../../src/app/lib/base-component";
import { BaseListComponent, F7ListComponentContext } from "../../../../src/app/lib/base-list-component";
import { ChatRoom } from "../../../../src/app/lib/nam-chat/chat-room";
import { FileModel } from "../../../../src/app/model/file.model";
import { ProductCategoryModel, ProductGroupModel, ProductModel, UnitModel } from "../../../../src/app/model/product.model";
import { CommonService } from "../../../../src/app/services/common.service";
import { RootServices } from "../../../../src/app/services/root.services";
import { F7Page } from "../../../../src/app/types/framework7-types";
import { WarehouseGoodsContainerModel, WarehouseGoodsInContainerModel } from '../../../../src/app/model/warehouse.model';
import { CashVoucherModel } from '../../../../src/app/model/accounting.model';
import { PurchaseOrderModel } from '../../../../src/app/model/purchase.model';
import { Template7 } from 'framework7/build/core/framework7.esm.bundle';
// import * as $ from 'jquery';
declare const $: any;

// declare const $: any;
export interface ComponentStateExtend extends ComponentState {
  [key: string]: any;
  instance?: F7ComponentContextExtend;
  list?: WarehouseGoodsQueueModel[];

  filter?: {
    Categories?: string[],
    Units?: string[],
    ContainerShelfs?: string[],
  };
}

export interface F7ComponentContextExtend extends F7ListComponentContext<ProductModel> {
  responseInProgress?: boolean;
  productList?: ProductModel[];
  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, chatRoom?: ChatRoom } };
  categoryList?: ProductCategoryModel[],
  groupList?: ProductGroupModel[],
  shelfList?: WarehouseGoodsContainerModel[],
  unitList?: UnitModel[],

}

/** Component group manager */
export class WarehouseGoodsQueueComponent extends BaseListComponent<ComponentStateExtend, ProductModel> {
  namespace: string = 'warehouse-goods-queue';

  apiPath = '/warehouse/goods-queues';

  limit = 50;
  title: 'Hàng đợi'

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
  ) {
    super(rootServices, commonService);
    // // console.log('Click here to open ts file');
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  filterSchema = {
    Categories: {
      type: 'smart-select',
      dataType: 'multiple'
    },
    ContainerShelf: {
      type: 'smart-select',
      dataType: 'multiple'
    },
    Unit: {
      type: 'smart-select',
      dataType: 'multiple'
    },
    OutOfStockType: {
      type: 'smart-select',
    },
  };

  queueMap = {
    OUTOFSTOCK: {
      id: 'OUTOFSTOCK',
      text: 'Hết hàng',
      color: 'orange'
    }
  };

  async onComponentInit(state: ComponentStateExtend, index: string, asCase?: string, f7Page?: F7Page) {
    // const cs = this.state[index];
    this.commonService.showPreloader();

    // Get static data
    state.instance.categoryList = await this.rootServices.apiService.getPromise<ProductCategoryModel[]>('/admin-product/categories', { onlyIdText: true, limit: 'nolimit' });
    // state.instance.groupList = await this.rootServices.apiService.getPromise<ProductCategoryModel[]>('/admin-product/groups', { onlyIdText: true, limit: 'nolimit' });
    state.instance.shelfList = await this.rootServices.apiService.getPromise<ProductCategoryModel[]>('/warehouse/goods-containers', { includeIdText: true, eq_Type: 'SHELF', limit: 'nolimit' }).then(rs => rs.map(m => { m.text = m.Name; return m; }));
    state.instance.unitList = await this.rootServices.apiService.getPromise<ProductCategoryModel[]>('/admin-product/units', { includeIdText: true, limit: 'nolimit' });

    state.instance.$setState({
      categoryList: state.instance.categoryList,
      shelfList: state.instance.shelfList,
      unitList: state.instance.unitList,
    });

    return super.onComponentInit(state, index, asCase, f7Page).then(currentState => {


      this.commonService.hidePreloader();
      return currentState;
    });
  }

  onItemClick(self: F7ListComponentContext<ProductModel>, item: ProductModel): void {
    return;
  }

  prepareItem(item: ProductModel, index?: number): ProductModel {
    // if (item.UnitConversions && item.UnitConversions.length > 0) {
    //   let defaultSalesUnit = item.UnitConversions.find(f => f.IsDefaultSales);
    //   if (defaultSalesUnit) {
    //     item.Unit = defaultSalesUnit;
    //     const price = item.UnitList && item.UnitList.find(f => f.id === item.Unit.id);
    //     if (price) {
    //       item.Price = price.Price;
    //     }
    //   } else {
    //     defaultSalesUnit = item.UnitConversions[0];
    //     item.Unit = defaultSalesUnit;
    //   }
    // } else {
    //   item.Unit = item.WarehouseUnit && item.WarehouseUnit.id ? item.WarehouseUnit : (item.WarehouseUnit ? { id: item.WarehouseUnit, text: item.WarehouseUnit } as any : { id: '', text: '--' });
    // }
    if (!item['Objects']) {
      item['Objects'] = [];
    }
    // if (item['Objects'].length == 0) {
    //   item['Objects'].push({
    //     id: 'NCCCHUNGCHUNG',
    //     text: 'NCC Chung chung'
    //   });
    // }
    if (typeof item['Queue'] == 'string') {
      item['Queue'] = this.queueMap[item['Queue']] || { id: 'NA', text: 'n/a' };
    }
    return item;
  }

  async getList(self?: F7ComponentContextExtend, extParams?: any): Promise<ProductModel[]> {

    return this.rootServices.apiService.getPromise<any[]>(this.apiPath, {
      priceTable: 'default',
      includeFeaturePicture: true,
      includeCategories: true,
      includeUnits: true,
      // eq_State: 'INQUEUE',
      sort_Id: 'asc',
      ...this.parseFilter(this.currentState?.filter),
      ...extParams
    }).then(list => {
      list.map((t, i) => this.prepareItem(t, i));
      self.$setState({ productList: list, infiniteLoadLast: list.length === 0 });
      self.infiniteOffset = 10;
      return list;
    }).catch(err => {
      console.error(err);
      this.commonService.showError(err);
      return Promise.reject(err);
    });
  }

  async pushGoodsToPurchaseOrderQueue(supplier: ContactModel, product: ProductModel, unit: UnitModel, quantity: number, description?: string) {
    let queuePo = await this.rootServices.apiService.getPromise<PurchaseOrderModel[]>('/purchase/order-vouchers', { eq_State: 'INQUEUE', eq_Object: supplier.id, sort_Id: 'desc', includeDetails: true }).then(rs => rs[0]);
    if (!queuePo) {
      const contact = await this.rootServices.apiService.getPromise<ContactModel[]>('/contact/contacts/' + supplier.id).then(rs => rs[0]);
      queuePo = await this.rootServices.apiService.postPromise<PurchaseOrderModel[]>('/purchase/order-vouchers', { eq_State: 'INQUEUE', Object: supplier.id, sort_Id: 'desc' }, [{
        Object: this.commonService.getObjectId(supplier),
        ObjectName: supplier.text,
        ObjectPhone: contact.Phone,
        ObjectEmail: contact.Email,
        ObjectAddress: contact.Address,
        ObjectIdentified: contact.IdNumber,
        State: 'INQUEUE',
        Title: `Đặt hàng ${supplier.text} ${new Date().toLocaleString()}`,
      }]).then(rs => rs[0]);

      queuePo = await this.rootServices.apiService.putPromise<PurchaseOrderModel[]>('/purchase/order-vouchers/' + queuePo.Code, { changeState: 'INQUEUE' }, [{ Code: queuePo.Code }]).then(rs => rs[0]);

    }
    if (queuePo) {
      if (!queuePo.Details) {
        queuePo.Details = [];
      }

      if (queuePo.Details.findIndex(f => this.commonService.getObjectId(f.Product) == product.Goods && this.commonService.getObjectId(f.Unit) == product.Unit) > -1) {
        // this.rootServices.apiService.deletePromise<WarehouseGoodsQueueModel[]>(this.apiPath + '/' + this.commonService.getObjectId(product) + '-' + this.commonService.getObjectId(unit) + '-' + product.Container, {}).then(rs => {
        //   this.currentState.list = this.currentState.list.filter(f => f.Goods != product.Goods || f.Unit != product.Unit || f.Container != product.Container);
        //   this.currentState.list.map((m, i) => { m['__index'] = i; return m; });
        //   this.currentState.instance.virtualList.replaceAllItems(this.currentState.list);
        // });
        this.commonService.showError('Hàng hóa đã có trong phiếu đặt mua hàng ' + queuePo.Code);
        return Promise.reject({ errorCode: 'DUPPLICATE', logs: ['Hàng hóa đã có trong phiếu đặt mua hàng ' + queuePo.Code] });
      }
      queuePo.Details.push({
        Type: 'PRODUCT',
        Image: [
          ...(product.FeaturePicture ? [product.FeaturePicture] : []),
          ...(product.Pictures || []).filter(f => f.Id != product.FeaturePicture?.Id)
        ],
        Product: product,
        Unit: unit,
        Quantity: quantity || 0,
        Price: 0,
        Description: this.commonService.getObjectText(product) + (description ? (' - ' + description) : ''),
      });
      queuePo.DateOfOrder = new Date().toISOString();
      queuePo = await this.rootServices.apiService.putPromise<PurchaseOrderModel[]>('/purchase/order-vouchers/' + queuePo.Code, {}, [queuePo]).then(rs => rs[0]);

      this.commonService.showInfo('Đã thêm hàng hóa vào phiếu đặt mua hàng ' + queuePo.Code);
      // this.rootServices.apiService.deletePromise<WarehouseGoodsQueueModel[]>(this.apiPath + '/' + product.Goods + '-' + product.Unit + '-' + product.Container, {}).then(rs => {
      //   this.currentState.list = this.currentState.list.filter(f => f.Goods != product.Goods || f.Unit != product.Unit || f.Container != product.Container);
      //   this.currentState.list.map((m, i) => { m['__index'] = i; return m; });
      //   this.currentState.instance.virtualList.replaceAllItems(this.currentState.list);
      // });

    } else {
      this.commonService.showError('Không thể đặt hàng cho ' + product.Name + ' (' + product.UnitLabel + ')');
    }
    return queuePo;
  }

  itemBeforeInsert(element: HTMLElement, item: WarehouseGoodsQueueModel): void {
    const $this = this;
    const index = $(element).attr('data-index');
    $(element).find('.chooseOne').unbind('click').click(() => {
      this.currentState.instance.$route.context['onChoose'] && this.currentState.instance.$route.context['onChoose'](item);
    });
    $(element).find('.openProduct').unbind('click').click(() => {
      this.rootServices.navigate('/admin-product/product/' + item.Goods, {
        context: {
          backTitle: this.title,
          textColorClass: this.currentState.instance.textColorClass,
          callback: (data: { case: string, product: ProductModel }) => {
            console.log(data);
            this.refresh(this.currentState.instance);
          }
        }
      });
    });
    $(element).find('.previewPictures').unbind('click').click(async () => {
      const product = await this.rootServices.apiService.getPromise<ProductModel[]>('/admin-product/products/' + item.Goods).then(rs => rs[0]);
      this.previewPictures([
        ...(product.FeaturePicture ? [product.FeaturePicture] : []),
        ...(product.Pictures || []).filter(f => f.Id != product.FeaturePicture?.Id)
      ], 0);
    });

    let listItemTemplate = Template7.compile(/*html*/`
      <div class="block-title" style="text-align: left">Thông tin đặt hàng</div>
      <div class="list inline-labels profile-form no-hairlines-md main-form" style="text-align: left; margin-left: -1rem; margin-right: -1rem;">
        <ul>
          <li class="autocomplete" name="Object">
            <div class="item-link item-content" href="#">
              <div class="item-inner">
                <div class="item-title">Nhà cung cấp *</div>
                <div class="item-after {{js "this.data && this.data.Object && this.data.Object.id && 'text-color-blue' || 'text-color-red'"}}">{{js "this.data && this.data.Object && this.data.Object.id && this.data.Object.text || 'Chọn...'"}}</div>
              </div>
            </div>
          </li>
          <li class="item-content item-input">
            <div class="item-inner">
              <div class="item-title item-label">Số lượng</div>
              <div class="item-input-wrap">
                <input class="field text-color-blue" name="Quantity" type="text" placeholder="Số lượng...">
              </div>
            </div>
          </li>
          <li class="item-content item-input">
            <div class="item-inner">
              <div class="item-title item-label">Ghi chú thêm</div>
              <div class="item-input-wrap">
                <input class="field text-color-blue" name="Description" type="text" placeholder="Ghi chú thêm...">
              </div>
            </div>
          </li>
        </ul>
      </div>
    `);

    let supplier: ContactModel = null;
    let quantity: number = 0;
    let description: string = '';
    const buttons = [...item['Objects'], { id: null, text: 'Nhà cung cấp khác' }].map(m => ({
      text: m.text,
      color: 'orange',
      onClick: async (dialog, e) => {

        supplier = m;
        const orderSupplierDialog = $this.currentState.instance.$app.dialog.create({
          title: 'Chọn nhà cung cấp khác',
          content: listItemTemplate({ data: { Object: m } }),
          buttons: [
            {
              text: 'Đặt hàng',
              bold: true,
              color: 'blue',
              onClick: async () => {
                if (this.commonService.getObjectId(supplier)) {

                  quantity = $(orderSupplierDialog.el).find('[name="Quantity"]').val() as any;
                  description = $(orderSupplierDialog.el).find('[name="Description"]').val() as any;

                  await this.pushGoodsToPurchaseOrderQueue(supplier, { ...item, id: item.Goods, text: item.Name }, { id: item.Unit, text: item.UnitLabel }, quantity, description).then(queuePo => {
                    this.rootServices.apiService.deletePromise<WarehouseGoodsQueueModel[]>(this.apiPath + '/' + item.Goods + '-' + item.Unit + '-' + item.Container, {}).then(rs => {
                      this.currentState.list = this.currentState.list.filter(f => f.Goods != item.Goods || f.Unit != item.Unit || f.Container != item.Container);
                      this.currentState.list.map((m, i) => { m['__index'] = i; return m; });
                      this.currentState.instance.virtualList.replaceAllItems(this.currentState.list);
                    });
                  }).catch(err => {
                    if (err.errorCode == 'DUPPLICATE') {
                      this.rootServices.apiService.deletePromise<WarehouseGoodsQueueModel[]>(this.apiPath + '/' + item.Goods + '-' + item.Unit + '-' + item.Container, {}).then(rs => {
                        this.currentState.list = this.currentState.list.filter(f => f.Goods != item.Goods || f.Unit != item.Unit || f.Container != item.Container);
                        this.currentState.list.map((m, i) => { m['__index'] = i; return m; });
                        this.currentState.instance.virtualList.replaceAllItems(this.currentState.list);
                      });
                    }
                  });
                }
              }
            },
            {
              text: 'Trở về',
              bold: true,
              color: 'red'
            },
          ],
          verticalButtons: true,
        });
        orderSupplierDialog.open();
        const objectEle = $(orderSupplierDialog.el).find('[name="Object"]');
        this.createAutocompleteField(objectEle[0], this.currentState, (query => {
          return this.rootServices.apiService.getPromise<ContactModel[]>('/contact/contacts', { search: query, includeIdText: true });
        }), value => {
          objectEle.find('.item-after').html(this.commonService.getObjectText(value[0]));
          supplier = value[0];
        }, value => {
          // orderSupplierDialog.open();
        });
        return true;
      }
    }));
    // Dialog choose other supplier
    // End Dialog choose other supplier

    $(element).find('.purchaseOrderBtn').unbind('click').click(() => {
      $this.currentState.instance.$app.dialog.create({
        title: item.Name + ' (' + item.UnitLabel + ')',
        text: 'Hãy chọn 1 nhà cung cấp để đặt hàng',
        buttons: [
          ...buttons,
          {
            text: 'Trở về',
            bold: true,
            color: 'red'
          },
        ],
        verticalButtons: true,
      }).open();
    });
    $(element).find('.statisticsBtn').unbind('click').click(() => {
      $this.rootServices.navigate('/purchase/price-statistics', {
        context: {
          backTitle: $this.title,
          textColorClass: this.currentState.instance.textColorClass,
          product: { id: item.Goods, text: item.Name },
          unit: { id: item.Unit, text: item.UnitLabel },
        }
      });
    });

    return;
  }

  async onFilterFieldChange(self: F7ComponentContextExtend, e: any) {
    return super.onFilterFieldChange(self, e);
  }

  itemHeight = 208;
  itemTemplate = /*html*/`
  <li class="swipeout block block-strong inset list-item index-{{__index}}" data-id="{{Code}}" data-index="{{__index}}" style="height: 197px; overflow: hidden; margin-top: 0; margin-bottom: 10px">
    <div class="item-content swipeout-content">
      <div class="detail-wrap">
        <div class="detail-header">
          <div class="header-label">{{Sku}} - {{Name}}</div>
          <!--<div class="detail-remove openProduct link"><i class="icon f7-icons color-orange" style="font-size: 2rem;">pencil_circle_fill</i></div>-->
        </div>
        <div class="detail-body">
          <div class="image-wrap">
            <div class="image previewPictures" data-index="0" style="height: 112px; {{js "this.Thumbnail && this.Thumbnail.Thumbnail && ('background-image: url('+this.Thumbnail.Thumbnail+')') || ''"}}"></div>
          </div>
          <div class="detail-content-wrap">
            <div class="list inline-labels no-hairlines">
              <ul>
                <li class="item-content item-input item-link showBarcode" data-unit="{{id}}"  style="padding-left: 0; min-height: 2rem;">
                  <div class="item-inner">
                    <div class="item-title item-label">ĐVT</div>
                    <div class="unit text-color-blue item-after" style="text-align: right">
                      {{UnitLabel}}
                    </div>
                  </div>
                </li>
                <li class="item-content item-input item-link showBarcode" data-unit="{{id}}"  style="padding-left: 0; min-height: 2rem;">
                  <div class="item-inner">
                    <div class="item-title item-label">Vị trí</div>
                    <div class="unit item-after text-color-blue" style="text-align: right">
                      {{ContainerShelfName}}/{{ContainerFindOrder}}
                    </div>
                  </div>
                </li>
                <li class="item-content item-input item-link showBarcode" data-unit="{{id}}"  style="padding-left: 0; min-height: 2rem;">
                  <div class="item-inner">
                    <div class="item-title item-label">Tồn kho</div>
                    <div class="unit item-after text-color-orange" style="text-align: right">
                      {{decimal Inventory}}
                    </div>
                  </div>
                </li>
                <li class="item-content item-input item-link showBarcode" data-unit="{{id}}"  style="padding-left: 0; min-height: 2rem;">
                  <div class="item-inner">
                    <div class="item-title item-label">Hàng đợi</div>
                    <div class="unit item-after text-color-blue" style="text-align: right">
                      {{text Queue}}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="detail-footer">{{text Objects}}</div>
      </div>
    </div>
    <div class="swipeout-actions-right">
      <a href="#" class="statisticsBtn swipeout-close color-blue">Phân tích</a>
      <a href="#" class="purchaseOrderBtn swipeout-close swipeout-overswipe color-orange">Đặt hàng</a>
    </div>
  </li>
  `;

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: 'warehouse-goods-queue',
      path: '/warehouse/goods-queue',
      component: {
        template: /*html*/`
            <div class="page page-warehouse-goods-queue no-toolbar-x" data-name="page-warehouse-goods-queue">
              <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a class="link back {{textColorClass}}">
                      <i class="icon icon-back"></i>
                      <span class="if-not-md {{textColorClass}}">{{backTitle}}</span>
                    </a>
                  </div>
                  <div class="title">Hàng hóa</div>
                  <div class="right">
                    <!-- Link to enable searchbar -->
                    <a class="link icon-only searchbar-enable {{textColorClass}}" data-searchbar=".warehouse-goods-queue-searchbar">
                      <i class="icon f7-icons if-not-md">search</i>
                      <i class="icon material-icons md-only">search</i>
                    </a>
                  </div>
                  <!-- Searchbar is a direct child of "navbar-inner" -->
                  <form class="searchbar warehouse-goods-queue-searchbar searchbar-expandable">
                    <div class="searchbar-inner">
                      <div class="searchbar-input-wrap">
                        <input type="search" placeholder="Search" />
                        <i class="searchbar-icon"></i><span class="input-clear-button"></span>
                        
                      </div>
                      <span class="searchbar-disable-button {{textColorClass}}">Hủy</span>
                    </div>
                  </form>
                </div>
              </div>
              <div class="page-content ptr-content infinite-scroll-content" @ptr:refresh="refresh">
                <div class="ptr-preloader">
                  <div class="preloader"></div>
                  <div class="ptr-arrow"></div>
                </div>
                <div class="searchbar-backdrop warehouse-goods-queue-backdrop"></div>

                <div class="block-title" style="margin-top: 2rem">Bộ lọc <a style="float: right" @click="resetFilter"
                class="text-color-red">đặt lại</a></div>
                <div class="block block-strong inset filter" style="padding: 0; overflow: hidden">
                  <div class="list accordion-list inline-labels no-hairlines" style="margin: 0;">
                    <ul>
                      <li>
                        <a class="item-link smart-select smart-select-init" name="Categories" data-open-in="popup" data-searchbar="true" data-virtual-list="true" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFilterFieldChange">
                            <select multiple>
                              <option value="">Chọn</option>
                              {{#each categoryList}}
                              <option value="{{id}}">{{text}}</option>
                              {{/each}}
                            </select>
                            <div class="item-content">
                              <div class="item-inner">
                                <div class="item-title">Danh mục</div>
                              </div>
                            </div>
                          </a>
                      </li>
                      <li>
                        <a class="item-link smart-select smart-select-init" name="ContainerShelf" data-open-in="popup" data-searchbar="true" data-virtual-list="true" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFilterFieldChange">
                            <select multiple>
                              <option value="">Chọn</option>
                              {{#each shelfList}}
                              <option value="{{id}}">{{text}}</option>
                              {{/each}}
                            </select>
                            <div class="item-content">
                              <div class="item-inner">
                                <div class="item-title">Kệ</div>
                              </div>
                            </div>
                          </a>
                      </li>
                      <li>
                        <a class="item-link smart-select smart-select-init" name="Unit" data-open-in="popup" data-searchbar="true" data-virtual-list="true" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFilterFieldChange">
                            <select multiple>
                              <option value="">Chọn</option>
                              {{#each unitList}}
                              <option value="{{id}}">{{text}}</option>
                              {{/each}}
                            </select>
                            <div class="item-content">
                              <div class="item-inner">
                                <div class="item-title">ĐVT</div>
                              </div>
                            </div>
                          </a>
                      </li>
                      <li>
                        <a class="item-link smart-select smart-select-init" name="OutOfStockType" data-open-in="popup" data-searchbar="true" data-virtual-list="true" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFilterFieldChange">
                            <select>
                              <option value="">Chọn</option>
                              <option value="LOWSTOCK">Sắp hết hàng</option>
                              <option value="OUTOFSTOCK">Hết hàng</option>
                              <option value="NAGATIVE">Tồn âm</option>
                            </select>
                            <div class="item-content">
                              <div class="item-inner">
                                <div class="item-title">Tồn kho</div>
                              </div>
                            </div>
                          </a>
                      </li>
                    </ul>
                  </div>
                </div>
            
                <div class="block-title">Hàng hóa/dịch vụ <a style="float: right" @click="openProduct"
                    class="{{textColorClass}}">+ thêm</a></div>
                <div class="virtual-list warehouse-goods-queue-virtual-list thumbnail-list"></div>


                {{#if infiniteLoadLast}}
                <div style="text-align: center" class="text-color-gray">end</div>
                {{else}}
                <div class="preloader color-blue infinite-scroll-preloader">
                    <span class="preloader-inner">
                        <span class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                            class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                            class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                            class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                            class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                            class="preloader-inner-line"></span><span class="preloader-inner-line"></span>
                    </span>
                </div>
                {{/if}}
            
                <div class="block"><br></div>
            
              </div>
            </div>
        `,
        style: /*css*/`
          .page-warehouse-goods-queue .thumbnail-list ul {
            padding: 0;
            margin: 0;
          }
        `,
        data() {
          return {
            title: $this.title,
            reminder: {
              No: '234234',
            },
            priceReport: { Details: [] },
            infiniteLoadLast: false,
          };
        },
        methods: {
          resetFilter(e) {
            $this.resetFilter(this, e);
          },
          onFilterFieldChange(e) {
            $this.onFilterFieldChange(this, e);
          },
          openProduct(e, id?: string) {
            const self: F7ComponentContextExtend = this;
            id = id || self.$(e.target).closest('li').data('id') || 'new';

            $this.rootServices.navigate('/admin-product/product/' + id, {
              context: {
                backTitle: $this.title,
                textColorClass: self.textColorClass,
                callback: (data: { case: string, product: ProductModel }) => {
                  console.log(data);
                  self.refresh();
                }
              }
            });
          },
          // createNewProduct(e) {
          //   const self: F7ComponentContextExtend = this;

          //   $this.rootServices.navigate('/admin-product/product/new', {
          //     context: {
          //       backTitle: $this.title,
          //       textColorClass: self.textColorClass,
          //       calback: (data: { case: string, product: ProductModel }) => {
          //         console.log(data);
          //       }
          //     }
          //   });
          // },
          chooseOne(e) {
            const self: F7ComponentContextExtend = this;
            const id = self.$(e.target).closest('li').data('id');
            self.$route.context['onChoose'] && self.$route.context['onChoose'](self.productList.find(f => f.Code === id));
          },
          chooseProduct(e) {

          },
          async refresh(e, done) {

            $this.refresh(this).then(rs => {
              done && done();
            });

            return true;
          },
          async addGoodsPicture(e) {
            console.debug(e);
            const self: F7ComponentContextExtend = this;
            const id = self.$(e.target).closest('.product-item').data('id');
            const oldProduct = self.productList.find(f => f.Code == id);
            if (!oldProduct) {
              console.error('Not found');
            }

            try {

              self.$f7.dialog.create({
                title: 'Hình ảnh sản phẩm',
                text: 'Bạn muốn xem hình hay thêm hình cho sản phẩm ?',
                buttons: [
                  {
                    text: 'Xem hình',
                    onClick: () => {


                      $this.browseAttachment(oldProduct?.Pictures.map(m => ({
                        Id: m.Id,
                        Type: m.Type,
                        DataType: m.MimeType,
                        Ext: m.Extension,
                        Name: oldProduct.Name,
                        Description: oldProduct.Name,
                        DateOfAttach: oldProduct.Created,
                        // File: string;
                        Thumbnail: m.Thumbnail,
                        Url: m.OriginImage,
                        SmallUrl: m.SmallImage,
                        LargeUrl: m.LargeImage,
                        OriginUrl: m.OriginImage,
                      })) as any, oldProduct.FeaturePicture?.Id, (attachment => {
                        console.debug('download index', attachment);
                        if (/image/.test(attachment.Type)) {
                          // $this.currentState.instance.$app.dialog.confirm([attachment.name, attachment.description].filter(f => !!f).join('<br>') || 'Đính kèm', 'Xác nhận tải về', async () => {
                          //   $this.rootServices.saveImageToPhone(attachment.payload.url as string);
                          // });
                          $this.currentState.instance.$app.dialog.create({
                            title: 'Xác nhận tải về',
                            content: 'Đính kèm',
                            buttons: [
                              {
                                text: 'Đóng',
                                color: 'red',
                                close: true,
                              },
                              {
                                text: 'Mở link',
                                onClick: () => {
                                  $this.rootServices.saveImageToPhone(attachment.Url as string);
                                }
                              }
                            ],
                          }).open();
                        } else if (/video/.test(attachment.Type)) {
                          $this.playVideo((attachment.OriginUrl || attachment.Url) as string);
                        } else {
                          $this.rootServices.iab.create((attachment.OriginUrl || attachment.Url) as string, '_system');
                        }
                      })).then(() => {
                        console.log('Photo browser was closed');
                      });
                    }
                  },
                  {
                    text: 'Thêm hình',
                    onClick: async () => {
                      const localFiles = await $this.rootServices.pickFiles();
                      console.debug(localFiles);

                      let image: FileModel = null;
                      if (localFiles[0].dataType === 'url') {
                        image = await $this.rootServices.uploadLocalFile(localFiles[0].url as string);
                      } else {
                        const formData = new FormData();
                        const imgBlob = new Blob([localFiles[0].url], {
                          type: localFiles[0].type
                        });
                        formData.append('file', imgBlob, 'smart-bot-' + Date.now() + '.' + localFiles[0].ext);
                        image = await $this.rootServices.uploadFileData(formData);
                      }
                      console.debug(image);
                      e.target.style.backgroundImage = 'url(' + image.Thumbnail + ')';

                      // const updateProfileResult = await $this.rootServices.apiService.putPromise('/user/users/updateAccount', {}, { Avatar: image.GlobalId, AvatarThumbnail: image.Thumbnail })
                      const pictures = oldProduct.Pictures || [];
                      pictures.push(image);
                      const updateProfileResult = await $this.rootServices.apiService.putPromise('/admin-product/products/' + id, {}, [{ Code: id, FeaturePicture: image, Pictures: pictures }]);
                      console.debug('Update product picture sucess: ', updateProfileResult);
                      $this.commonService.showInfo('Đã thêm hình ảnh cho hàng hóa: ' + oldProduct.Name);

                      // Update auth user
                      const oldUser = $this.rootServices.authService.user$.getValue();
                      oldUser.avatar = oldUser.avatar || { id: '', payload: {} };
                      oldUser.avatar = { ...oldUser.avatar, payload: { ...oldUser.avatar.payload, thumbnail: image.Thumbnail, url: image.DownloadLink } }
                      $this.rootServices.authService.user$.next(oldUser);

                    }
                  },
                  {
                    text: 'Trở về',
                  },
                ],
                verticalButtons: true,
              }).open();


            } catch (err) {
              console.error(err);
              $this.commonService.showError(err);
            }

          },
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          pageInit(e, page: F7Page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;

            self.calendarDateTime = self.$f7.calendar.create({
              // openIn: 'customModal',
              inputEl: page.$el.find('input[name="RemindAtDateTime"]'),
              timePicker: true,
              dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' },
              // header: true,
              footer: true,
            });

            $this.onComponentInit({ instance: self }, 'main', null, page);

            $this.rootServices.authService.isAuthenticatedOrRefresh().pipe(take(1)).toPromise().then(() => {
              self.$setState({
                backTitle: self.$route.context && self.$route.context.backTitle || 'Back', title: self.$route.context && self.$route.context.title || 'Profile',
              });

            }).catch(err => {
              console.error(err);
              $this.commonService.showError(err);
            });

          },
          // pageBeforeIn(e, page) {
          //     console.log('page before in');
          //     const self: F7ComponentContextExtend = this;
          //     self.refresh();
          // },
          // pageAfterIn(e, page) {
          //     console.log('page after in');
          // },
          pageBeforeOut(e, page) {
            const self: F7ComponentContextExtend = this;
            console.log('page before out');
            $this.onBeforeChangedState({ instance: self, page, scrollPosition: $(page.el).find('.page-content').scrollTop() }, self.componentIndex);
          },
          // pageAfterOut(e, page) {
          //     console.log('page after out');
          // },
          // pageBeforeUnmount(e, page) {
          //     console.log('page before unmount');
          // },
          // pageBeforeRemove(e, page) {
          //     console.log('page before remove');
          // },
          pageBeforeRemove(e, page) {
            console.log('[page event] before remove', page.route.url);
            const self: F7ComponentContextExtend = this;
            // const starmtSelectRoles = self.$app.smartSelect.get('.smart-select');
            // starmtSelectRoles.destroy();
          },
        },
      },
    };
  }
}
