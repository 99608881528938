import { RoomInfoModel } from './../../lib/nam-chat/model/room-info';
import { WarehouseBookEntryModel, WarehouseBookModel, WarehouseGoodsModel } from '../../model/warehouse.model';

import { Dialog } from 'framework7/build/core/components/dialog/dialog';
import { Router } from 'framework7/build/core/modules/router/router';
import { take } from 'rxjs/operators';
import { ComponentState, BaseComponent } from '../../../../src/app/lib/base-component';
import { ContactModel } from '../../../../src/app/model/contact.model';
import { UnitModel } from '../../../../src/app/model/product.model';
import { WarehouseGoodsContainerModel, WarehouseInventoryAdjustNoteDetailModel, WarehouseInventoryAdjustNoteModel } from '../../../../src/app/model/warehouse.model';
import { CommonService } from '../../../../src/app/services/common.service';
import { RootServices } from '../../../../src/app/services/root.services';
import { F7ComponentContext, F7Page } from '../../../../src/app/types/framework7-types';

export interface ComponentStateExtend extends ComponentState {
  [key: string]: any;
  instance: F7ComponentContextExtend,
  voucher?: WarehouseBookModel;
}

export class F7ComponentContextExtend extends F7ComponentContext {
  // messagebar?: F7Messagebar;
  // messages?: Messages.Messages;
  // images?: string[];
  responseInProgress?: boolean;
  // answers?: string[];
  // people?: { name?: string, avatar?: string }[];
  // hideToolbar?: () => void;
  // sheetToggle?: () => void;
  // deleteAttachment?: (e: MouseEvent, index: number) => void;
  // handleAttachment?: (e: MouseEvent) => void;
  // checkAttachments?: () => void;
  // sendMessage?: () => void;

  detailDialog?: Dialog.Dialog;

  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string } };
}

/** Component group manager */
export class WarehouseBookFormComponent extends BaseComponent<ComponentStateExtend> {
  // states: { [key: string]: State } = {};

  title: 'Sổ kho'

  taxList = [
    {
      id: 'VAT10',
      text: '+ 10% VAT',
      Tax: 10,
      Type: 'NONE',
      Name: 'Chưa bao gồm VAT 10%',
      selected: false,
    },
    {
      id: 'FULLVAT10',
      text: 'Đã bao gồm VAT 10%',
      Tax: 0,
      Type: 'NONE',
      Name: 'Đã Full VAT 10%',
      selected: false,
    },
    {
      id: 'NOTAX',
      text: 'n/a',
      Tax: 0,
      Type: 'NONE',
      Name: 'Không thuế',
      selected: false,
    },
  ];

  unitList: UnitModel[] = [];
  // priceTableList: SalesMasterPriceTable[] = [];

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
  ) {
    super(rootServices);
    // // console.log('Click here to open ts file');

    this.rootServices.apiService.getPromise<UnitModel[]>('/admin-product/units').then(unitList => {
      this.unitList = unitList.map(m => {
        m.id = m.Code;
        m.text = m.Name;
        return m;
      })
    });
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  async onComponentInit(state: ComponentStateExtend, index: string, asCase?: string) {
    const currentState = await super.onComponentInit(state, index, asCase);

    // Load Contact Member list
    // currentState?.instance?.$route?.context?.chatRoom.memberList$.subscribe(memberList => {
    //   currentState.instance.$setState({
    //     contactMemebers: memberList.filter(f => f.Type == 'CONTACT'),
    //   });
    // });

    // this.priceTableList = await this.rootServices.apiService.getPromise<SalesMasterPriceTable[]>('/sales/master-price-tables').then(priceTableList => {
    //   return priceTableList.map(m => {
    //     m.id = m.Code;
    //     m.text = m.Title;
    //     return m;
    //   });
    // });

    if (currentState.instance.$route.params['id'] == 'current') {
      if (!currentState.voucher || !currentState.voucher.Code) {
        currentState.voucher = new WarehouseInventoryAdjustNoteModel();
        await this.rootServices.apiService.getPromise<WarehouseInventoryAdjustNoteModel[]>('/warehouse/books/current', {
          // includeHeadEntries: true,
        }).then(vouchers => {
          currentState.voucher = vouchers[0];
          if (!currentState.voucher.HeadEntries) {
            currentState.voucher.HeadEntries = [];
          }
          currentState.instance.$setState({
            voucher: currentState.voucher,
          });
        });
      } else {
        currentState.instance.$setState({
          voucher: currentState.voucher,
        });
      }
    }

    if (currentState.voucher) {
      currentState.instance.$setState({
        loading: true,
        voucher: currentState.voucher,
      });
    }

    setTimeout(() => {
      // const smartSelect = currentState.instance.$app.smartSelect.get('.smart-select-price-table');
      // if (smartSelect) {
      //   smartSelect.setValue(this.commonService.getObjectId(currentState.voucher.PriceTable as any) as any);
      // };
      // const smartSelectObject = currentState.instance.$app.smartSelect.get('.smart-select.object');
      // if (smartSelectObject) {
      //   smartSelectObject.setValue(this.commonService.getObjectId(currentState.voucher.Object as any) as any);
      // };
      // const smartSelectContact = currentState.instance.$app.smartSelect.get('.smart-select.contact');
      // if (smartSelectContact) {
      //   smartSelectContact.setValue(this.commonService.getObjectId(currentState.voucher.Contact as any) as any);
      // };
      currentState.instance.$setState({
        loading: false,
      });
    }, 300);


    return currentState;
  }

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: 'warehouse-book-form',
      path: '/warehouse/book-form',
      component: {
        template: /*html*/`
            <div class="page no-toolbar-x warehouse-book-form" data-name="warehouse-book-form">
              <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a class="link back {{textColorClass}}">
                      <i class="icon icon-back"></i>
                      <span class="if-not-md {{textColorClass}}">{{backTitle}}</span>
                    </a>
                  </div>
                  <div class="title">Sổ kho</div>
                  <div class="right">
                    <a class="link icon-only {{textColorClass}}">
                      <i class="icon f7-icons">arrowshape_turn_up_right</i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="page-content ptr-content infinite-scroll-content" @ptr:refresh="refresh">
                
              <div class="ptr-preloader">
                  <div class="preloader"></div>
                  <div class="ptr-arrow"></div>
                </div>

                <div class="block block-strong inset">
                  {{js "this.voucher && this.voucher.Code"}}<br>
                  {{js "this.voucher && this.voucher.Note"}}
                </div>
            
                <div class="block-title">Mới vừa thêm vào</div>
                <div class="list no-hairlines-md cart inset">
                  <input type="hidden" name="No" value="{{js "this.voucher && this.reminder.No || ''"}}">
                  <ul>
                    {{#each voucher.HeadEntries}}
                    {{#js_if "this.Type === 'CATEGORY'"}}
                    <li class="item-content product-item" data-index="{{@index}}" style="
                                      background: var(--f7-color-orange);
                                      border-top: #8686865c 3px solid;
                                      min-height: 32px;">
                      <div class="categories">{{Description}} <i @click="removeDetail" class="icon f7-icons remove-btn">xmark</i>
                      </div>
                    </li>
                    {{js_else}}
                    <li class="swipeout" data-index="{{@index}}">
                      <div class="item-content product-item swipeout-content">
                        <div class="categories">{{this.CategoriesText}} <i @click="removeDetail" class="icon f7-icons remove-btn">xmark</i></div>
                        <div class="image" style="width: 30%">
                          <div class="image-contain" style="background-image: url('{{this.Thumbnail}}')"></div>
                        </div>
                        <div class="detail" style="width: 70%">
                          <div class="name">{{GoodsName}}/{{UnitText}}</div>
                          <div class="quantity">
                            <div @click="decreaseQuantity" class="control">-</div>
                            <input name="IncreaseWrite" value="{{IncreaseWrite}}" @change="onDetailChange" data-field="IncreaseWrite" data-index="{{@index}}" />
                            <div @click="increaseQuantity" class="control">+</div>
                          </div>
                          <div class="voucher-price">
                            <input name="UnitPrice" value="{{UnitPrice}}" @change="onDetailChange" data-field="UnitPrice" data-index="{{@index}}" />
                          </div>
                          <div class="subject" style="    clear: both; font-weight: bold; font-size: 24px;">
                            {{FindOrder}}
                          </div>
                          <div class="access-numbers">{{AccessNumbers}}</div>
                        </div>
                        <div class="clear-both"></div>
                      </div>
                      <div class="swipeout-actions-right">
                        <a href="#" @click="openDetailDialog" class="color-orange">Chỉnh</a>
                        <a href="#" @click="removeDetail" class="color-red swipeout-overswipe">Gở</a>
                      </div>
                    </li>
                    {{/js_if}}
                    {{/each}}
                  </ul>
                </div>
            
                <div class="block">
                  <!--<a class="col button  button-large button-outline link color-green" style="width: 100%" @click="openDetailDialog">+ Thêm hàng hóa/dịch vụ mới</a>-->
                  <a class="col button  button-large button-outline link color-orange" style="width: 100%" @click="chooseGoods">
                  <i class="icon f7-icons if-not-md-x">cube_box</i> Chọn hàng hóa</a>
                    <br>
                  <a class="col button  button-large button-outline link color-blue" style="width: 100%" @click="scanContainerQrCode">
                  <i class="icon f7-icons if-not-md-x">qrcode_viewfinder</i> Quét mã QRCode vị trí hàng hóa</a>
                </div>
            
                <div class="block">
                  <a class="col button button-fill button-large link" style="width: 100%" @click="saveVoucher">Cập nhật tồn đầu</a>
                </div>
            
                <div class="block"><br></div>
            
              </div>
            
            
              <div class="dialog dialog-wrap dialog-detail">
                <div class="dialog-padding">
                  <div class="dialog dialog-inside">
                    <div class="dialog-inner" style="padding-bottom: 0;">
                      <div class="dialog-title">Chi tiết báo giá</div>
                      <div class="dialog-text" style="width: 100%; user-select: initial; text-align: center; margin-bottom: 0.5rem">
                        Mô tả thêm và điều chỉnh đơn giá cho hàng hóa/dịch vụ</div>
            
                      <div class="list media-list"
                        style="margin-left: -15px; margin-right: -15px; margin-bottom: 0; margin-top: 0; text-align: left;">
                        <ul>
                          <li class="item-content item-input">
                            <div class="item-media"><i class="icon f7-icons text-color-default">money_dollar_circle_fill</i></div>
                            <div class="item-inner">
                              <div class="item-title item-label">Đơn giá (*)</div>
                              <div class="item-input-wrap">
                                <input class="field" type="tel" name="Price" placeholder="Đơn giá">
                                
                              </div>
                            </div>
                          </li>
                          <li class="item-content item-input">
                            <div class="item-media"><i class="icon f7-icons text-color-default">doc_text_fill</i></div>
                            <div class="item-inner">
                              <div class="item-title item-label">Mô tả (*)</div>
                              <div class="item-input-wrap">
                                <textarea class="field" name="Description" placeholder="Mô tả hàng hóa dịch vụ"></textarea>
                                
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="dialog-buttons">
                      <span class="dialog-button color-red closeBtn" @click="closeDetailDialog">Đóng</span>
                      <span class="dialog-button createReferenceChatRoomBtn"
                        @click="updateDetail">{{js "this.isEditDetail ? 'Cập nhật' : 'Thêm'"}}</span>
                    </div>
                  </div>
                </div>
              </div>
            
            </div>
        `,
        style: `
        `,
        data() {
          return {
            title: $this.title,
            reminder: {
              No: '234234',
            },
            voucher: new WarehouseInventoryAdjustNoteModel(),
            taxList: $this.taxList,
            contactMemebets: [],
          };
        },
        methods: {
          // Add contact dialog
          openDetailDialog(e: MouseEvent) {
            const self: F7ComponentContextExtend = this;
            const currenntState = $this.currentState;
            const itemEle = self.$(e.target).closest('li');
            const index = itemEle.data('index');
            if (index) {
              self.$setState({ isEditDetail: true });
            } else {
              self.$setState({ isEditDetail: false });
            }
            const detail = currenntState.voucher.HeadEntries[index];

            self.detailDialog && self.detailDialog.open();

            const fieldEles = self.detailDialog.$el.find('.field');
            fieldEles.each((index, fieldEle) => {
              if (fieldEle.name === 'Price') {
                fieldEle.focus();
                fieldEle.value = detail && detail.Price || '';
              }
              if (fieldEle.name === 'Description') {
                fieldEle.value = detail && detail.Description || '';
              }
            });
            self.detailDialog['detailIndex'] = index;
          },
          onDetailChange(e) {
            const self: F7ComponentContextExtend = this;
            const field = self.$(e.target).data('field');
            const index = self.$(e.target).data('index');
            $this.currentState.voucher.HeadEntries[index][field] = e.target?.value || 0;
          },
          updateDetail(e: MouseEvent) {
            const self: F7ComponentContextExtend = this;
            const currenntState = $this.currentState;
            const index = self.detailDialog['detailIndex'];
            let detail = null;
            if (index) {
              detail = currenntState.voucher.HeadEntries[index];
            } else {
              detail = new WarehouseInventoryAdjustNoteDetailModel();
              // detail.TaxList = [];
              // for (const tax of $this.taxList) {
              //   detail.TaxList.push({ ...tax, selected: tax.id === detail.Tax.id });
              // }
            }
            const fieldEles = self.detailDialog.$el.find('.field');
            fieldEles.each((index, fieldEle) => {
              detail[fieldEle.name] = fieldEle.value;
            });
            if (!detail.Price) {
              $this.commonService.showError('Bạn chưa điền số đơn giá');
              return;
            }
            if (!detail.Description) {
              $this.commonService.showError('Bạn chưa điền mô tả');
              return;
            }
            console.log(detail);

            detail.Unit = { id: 'n/a', text: 'n/a' };
            detail.UnitList = [];
            for (const unit of $this.unitList) {
              detail.UnitList.push({ ...unit, selected: unit.id === 'n/a' });
            }

            detail.PriceText = $this.rootServices.currencyPipe.transform(detail.Price, detail.Currency || 'VND');
            // detail.ToMoney = (detail.Quantity * detail.Price) + (detail.Quantity * detail.Price * (detail.Tax && detail.Tax.Tax || 0) / 100);
            detail.ToMoney = detail.Quantity * detail.Price;
            detail.ToMoneyText = $this.rootServices.currencyPipe.transform(detail.ToMoney, 'VND');

            if (!index) {
              if (currenntState?.voucher?.HeadEntries) {
                currenntState.voucher.HeadEntries.push(detail);
              }
            }

            self.calculateTotal();
            self.$setState({ voucher: { ...currenntState.voucher } });

            self.closeDetailDialog();
            self.detailDialog['detailIndex'] = null;
            fieldEles.each((index, fieldEle) => {
              fieldEle.value = '';
            });

          },
          closeDetailDialog() {
            const self: F7ComponentContextExtend = this;
            const fieldEles = self.detailDialog.$el.find('.field');
            self.detailDialog['detailIndex'] = null;
            fieldEles.each((index, fieldEle) => {
              fieldEle.value = '';
            });
            self.detailDialog && self.detailDialog.close();
          },
          //
          addDetail(e) {
            const self: F7ComponentContextExtend = this;
          },
          // onTaxChange(e) {
          //   const self: F7ComponentContextExtend = this;
          //   const currenntState = $this.currentState;
          //   const itemEle = self.$(e.target).closest('li');
          //   const index = itemEle.data('index');
          //   const detail = currenntState.voucher.Details[index];
          //   if (e.target.value) {
          //     // const choosedTax = $this.taxList.find(f => f.id === e.target.value);
          //     // console.log(choosedTax);
          //     // detail.Tax = choosedTax;

          //     // detail.ToMoney = (detail.Quantity * detail.Price) + (detail.Quantity * detail.Price * (detail.Tax && detail.Tax.Tax || 0) / 100);
          //     detail.ToMoney = detail.Quantity * detail.Price;
          //     detail.ToMoneyText = $this.rootServices.currencyPipe.transform(detail.ToMoney, 'VND');

          //     self.calculateTotal();
          //     self.$setState({ voucher: currenntState.voucher });
          //   }
          // },
          // onUnitChange(e) {
          //   const self: F7ComponentContextExtend = this;
          //   const currenntState = $this.currentState;
          //   const itemEle = self.$(e.target).closest('li');
          //   const index = itemEle.data('index');
          //   const detail = currenntState.voucher.Details[index];
          //   if (e.target.value) {
          //     const choosedUnit = detail.UnitList.find(f => f.id === e.target.value);
          //     console.log(choosedUnit);
          //     detail.Unit = choosedUnit;
          //     if (choosedUnit && choosedUnit.Price) {
          //       detail.Price = choosedUnit.Price;
          //       detail.PriceText = $this.rootServices.currencyPipe.transform(choosedUnit.Price, choosedUnit.Currency || 'VND');
          //     }

          //     detail.ToMoney = (detail.Quantity * detail.Price) + (detail.Quantity * detail.Price * (detail.Tax && detail.Tax.Tax || 0) / 100);
          //     detail.ToMoneyText = $this.rootServices.currencyPipe.transform(detail.ToMoney, 'VND');

          //     self.calculateTotal();
          //     self.$setState({ voucher: currenntState.voucher });
          //   }
          // },
          fieldChange(e) {
            const self: F7ComponentContextExtend = this;
            const field = e.target;
            const fieldName = field.name;
            const fieldValue = field.value;
            // console.log('Field change', e, field.value);
            const currenntState = $this.currentState;
            if (currenntState.voucher) {
              currenntState.voucher[fieldName] = fieldValue;
            }

            if (fieldName == 'Object' && fieldValue && !currenntState.instance.loading) {
              // const contact = $this.currentState?.instance?.$route?.context?.chatRoom?.memberList$?.getValue()?.find(f => f.id == fieldValue);
              $this.rootServices.apiService.getPromise<ContactModel[]>('/contact/contacts/' + fieldValue, {}).then(contacts => contacts[0]).then(contact => {

                // Auto fill object
                currenntState.voucher.ObjectName = contact.Name;
                currenntState.voucher.ObjectPhone = contact.Phone;
                currenntState.voucher.ObjectEmail = contact.Email;
                currenntState.voucher.ObjectAddress = contact.Address;
                currenntState.voucher.ObjectIdentified = contact.TaxCode;

                currenntState.instance.$setState({
                  voucher: currenntState.voucher,
                });
              });
            }
            if (fieldName == 'Contact' && fieldValue && !currenntState.instance.loading) {
              $this.rootServices.apiService.getPromise<ContactModel[]>('/contact/contacts/' + fieldValue, {}).then(contacts => contacts[0]).then(contact => {

                // Auto fill object
                currenntState.voucher.ContactName = contact.Name;
                currenntState.voucher.ContactPhone = contact.Phone;
                currenntState.voucher.ContactEmail = contact.Email;
                currenntState.voucher.ContactAddress = contact.Address;
                currenntState.voucher.ContactIdentified = contact.TaxCode;

                currenntState.instance.$setState({
                  voucher: currenntState.voucher,
                });
              });
            }
          },
          increaseQuantity(e) {
            const self: F7ComponentContextExtend = this;
            const itemEle = self.$(e.target).closest('li');
            const quantityEle = itemEle.find('input[name="Quantity"]');
            const index = itemEle.data('index');
            const currenntState = $this.currentState;
            if (currenntState.voucher) {
              const detail = currenntState.voucher.HeadEntries[index];
              detail.IncreaseWrite++;
              // detail.ToMoney = (detail.IncreaseWrite * detail.Price) + (detail.Quantity * detail.Price * (detail.Tax && detail.Tax.Tax || 0) / 100);
              // detail.ToMoneyText = $this.rootServices.currencyPipe.transform(detail.ToMoney, 'VND');
              // quantityEle.val(currenntState.voucher.HeadEntries[index].Quantity);

              self.calculateTotal();
              self.$setState({
                voucher: currenntState.voucher,
              });
            }
          },
          decreaseQuantity(e) {
            const self: F7ComponentContextExtend = this;
            const itemEle = self.$(e.target).closest('li');
            const quantityEle = itemEle.find('input[name="Quantity"]');
            const index = itemEle.data('index');
            const currenntState = $this.currentState;
            if (currenntState.voucher) {
              if (currenntState.voucher.HeadEntries[index].IncreaseWrite > 0) {
                const detail = currenntState.voucher.HeadEntries[index];
                detail.IncreaseWrite--;
                // detail.ToMoney = (detail.Quantity * detail.Price) + (detail.Quantity * detail.Price * (detail.Tax && detail.Tax.Tax || 0) / 100);
                // detail.ToMoneyText = $this.rootServices.currencyPipe.transform(detail.ToMoney, 'VND');
                self.calculateTotal();
                self.$setState({
                  voucher: currenntState.voucher,
                });
              }
            }
          },
          chooseGoods(e) {
            const self: F7ComponentContextExtend = this;
            const currenntState = $this.currentState;

            $this.rootServices.navigate('/warehouse/goods-list/choose', {
              context: {
                backTitle: $this.title,
                textColorClass: self.textColorClass,
                // chatRoom: self.$route.context.chatRoom,
                // priceTable: currenntState.voucher.PriceTable,
                async onChoose(item: WarehouseGoodsModel) {
                  if (item && item.Goods && item.Goods.length > 0) {
                    for (const goods of item.Goods) {
                      let existsItem: WarehouseBookEntryModel = currenntState.voucher.HeadEntries.find(f => $this.commonService.getObjectId(f.Goods as any) === $this.commonService.getObjectId(goods) && $this.commonService.getObjectId(f.Unit as any) == $this.commonService.getObjectId(goods.Unit));
                      if (!existsItem) {

                        // Get head entry
                        existsItem = await $this.rootServices.apiService.getPromise<any[]>('/warehouse/books/' + currenntState.voucher.Code, { includeHeadEntries: true, byGoods: $this.commonService.getObjectId(goods), byUnit: $this.commonService.getObjectId(goods.Unit), byContainer: $this.commonService.getObjectId(item) }).then(rs => rs[0]['HeadEntries'][0]).then(entry => {
                          const existsItem: WarehouseBookEntryModel = {
                            Container: $this.commonService.getObjectId(item),
                            Goods: $this.commonService.getObjectId(goods),
                            Description: goods.GoodsName,
                            GoodsName: goods.GoodsName,
                            Unit: $this.commonService.getObjectId(goods.Unit),
                            IncreaseWrite: entry?.IncreaseWrite || 0,
                            UnitPrice: entry?.UnitPrice || 0,
                            UnitText: goods.UnitLabel,
                            Image: [goods.GoodsThumbnail],
                            Thumbnail: goods.GoodsThumbnail && goods.GoodsThumbnail.Thumbnail || null,
                            CategoriesText: item.Path,
                            FindOrder: item.FindOrder,
                          };
                          return existsItem;
                        });
                        currenntState.voucher.HeadEntries.push(existsItem);
                      }
                    }
                    self.$setState({
                      voucher: currenntState.voucher,
                    });
                    $this.commonService.showInfo('Đã thêm hàng hóa tại vị trí vào phiếu điều chỉnh tồn kho');
                  }
                },
              }
            });
          },
          scanContainerQrCode(e) {
            const self: F7ComponentContextExtend = this;
            const currenntState = $this.currentState;

            const systemConfigs = $this.rootServices.systemSettings$.value;

            $this.rootServices.barcodeScanner.scan({ showTorchButton: true }).then(barcodeData => {
              // const data = barcodeData && barcodeData.text.split('|');
              const data = barcodeData && barcodeData.text;
              if (data.length > 0 && data[0].length > 0) {
                // const [prefix, goodsId] = data[0].split('/');
                // if (prefix != 'GC') {
                //   $this.commonService.showError('QR code này không phải định dạng id vi trí hàng hóa');
                //   return;
                // }


                let tmpcode = data.substring(1);
                const findOrderLength = parseInt(tmpcode.substring(0, 1));
                tmpcode = tmpcode.substring(1);
                const findOrder = tmpcode.substring(0, findOrderLength);
                tmpcode = tmpcode.substring(findOrderLength);
                const unitSeqLength = parseInt(tmpcode.substring(0, 1));
                tmpcode = tmpcode.substring(1);
                let unitSeq = tmpcode.substring(0, unitSeqLength);
                tmpcode = tmpcode.substring(unitSeqLength);
                let productId = tmpcode;


                let goodsId = `118${systemConfigs.ROOT_CONFIGS.coreEmbedId}${productId}`;



                if (!data[0]) return false;
                $this.rootServices.apiService.getPromise<any[]>('/warehouse/goods', {

                  masterPriceTable: currenntState.voucher.Code,
                  includeCategories: true,
                  includeUnit: true,
                  includeContainer: true,
                  includeFeaturePicture: true,
                  includeHeadBookEntry: true,
                  eq_Code: goodsId,

                  // ...self.filter,
                }).then(async rs => {
                  // self.$$(page.el).find('.page-content').scrollTo(0, 0);
                  // self.$setState({ productList: products.map(t => $this.preapreProduct(t)), infiniteLoadLast: products.length === 0 });
                  // self.infiniteOffset = 0;
                  const goodsList = rs.filter(f => !!f.Container);

                  let chooseGoods: any = null
                  if (goodsList && goodsList.length > 1) {
                    // chooseGoods = await (() => {
                    //   return new Promise((resolve, reject) => {
                    //     self.$app.dialog.create({
                    //       title: 'Chọn vị trí',
                    //       text: 'Chon vị trí hàng hóa',
                    //       buttons: goodsList.map(goods => ({
                    //         text: `Số nhận thức: ${goods.Container.ContainerFindOrder}, tồn: ${goods.Inventory} ${goods.Container.UnitLabel}`,
                    //         onClick: (dialog, e) => {
                    //           resolve(goods);
                    //         }
                    //       })),
                    //       verticalButtons: true,
                    //     }).open();
                    //   });
                    // })();

                    chooseGoods = goodsList.find(f => f.Container?.ContainerFindOrder == findOrder);
                  } else {
                    chooseGoods = goodsList[0];
                  }



                  // const item = chooseGoods;
                  if (!chooseGoods) {
                    $this.commonService.showError('Hàng hóa chưa được cài đặt vị trí');
                    return
                  };

                  if (chooseGoods && chooseGoods.Container) {
                    // for (const goods of item.Goods) {
                    let existsItem: WarehouseBookEntryModel = currenntState.voucher.HeadEntries.find(f => $this.commonService.getObjectId(f.Goods as any) === chooseGoods.Code && $this.commonService.getObjectId(f.Unit as any) == $this.commonService.getObjectId(chooseGoods.WarehouseUnit));
                    if (!existsItem) {

                      // Get head entry
                      existsItem = await $this.rootServices.apiService.getPromise<any[]>('/warehouse/books/' + currenntState.voucher.Code, { includeHeadEntries: true, byGoods: chooseGoods.Code, byUnit: $this.commonService.getObjectId(chooseGoods.WarehouseUnit), byContainer: $this.commonService.getObjectId(chooseGoods.Container) }).then(rs => rs[0]['HeadEntries'][0]).then(entry => {
                        const existsItem: WarehouseBookEntryModel = {
                          Container: $this.commonService.getObjectId(chooseGoods.Container),
                          Goods: $this.commonService.getObjectId(chooseGoods.Code),
                          Description: chooseGoods.Name,
                          GoodsName: chooseGoods.Name,
                          Unit: $this.commonService.getObjectId(chooseGoods.WarehouseUnit),
                          IncreaseWrite: chooseGoods?.Inventory || 0,
                          AccessNumbers: chooseGoods.AccessNumbers || [],
                          UnitPrice: chooseGoods?.UnitPrice || 0,
                          UnitText: $this.commonService.getObjectText(chooseGoods.WarehouseUnit),
                          Image: [chooseGoods.FeaturePicture?.SmallImage],
                          Thumbnail: chooseGoods.FeaturePicture?.SmallImage || null,
                          CategoriesText: chooseGoods.Container.ContainerPath,
                          FindOrder: chooseGoods.Container.ContainerFindOrder,
                        };
                        return existsItem;
                      });
                      currenntState.voucher.HeadEntries.push(existsItem);
                    }
                    // }
                    self.$setState({
                      voucher: currenntState.voucher,
                    });
                    $this.commonService.showInfo('Đã thêm hàng hóa tại vị trí vào phiếu điều chỉnh tồn kho');
                  }

                  self.calculateTotal();
                  self.$setState({
                    voucher: currenntState.voucher,
                  });

                  $this.commonService.showInfo('Đã thêm hàng hóa/dịch vụ vào báo giá');


                  // setTimeout(() => {
                  //   self.scanContainerQrCode(e);
                  // }, 300);

                }).catch(err => {
                  console.error(err);
                  $this.commonService.showError(err);

                });
              }
            });
          },
          removeDetail(e) {
            const self: F7ComponentContextExtend = this;
            const currenntState = $this.currentState;
            const itemEle = self.$(e.target).closest('li');
            const index = itemEle.data('index');
            if (currenntState.voucher && currenntState.voucher.HeadEntries) {
              currenntState.voucher.HeadEntries.splice(index, 1);
              self.calculateTotal();
              self.$setState({
                voucher: currenntState.voucher,
              });
            }
          },
          calculateTotal() {
            const self: F7ComponentContextExtend = this;
            const currenntState = $this.currentState;
            const details = currenntState.voucher && currenntState.voucher.HeadEntries;
            let total = 0;
            if (details) {
              for (const detail of details) {
                total += detail.ToMoney;
              }
            }

            currenntState.voucher.Sum = total;
            currenntState.voucher.Discount = 0;
            currenntState.voucher.Total = total;
            currenntState.voucher.SumText = $this.rootServices.currencyPipe.transform(currenntState.voucher.Sum, 'VND');
            currenntState.voucher.TotalText = $this.rootServices.currencyPipe.transform(currenntState.voucher.Total, 'VND');
            currenntState.voucher.DiscountTex = $this.rootServices.currencyPipe.transform(currenntState.voucher.Discount, 'VND');

            return total;
          },
          async refresh(e, done) {
            const self: F7ComponentContextExtend = this;

            let doneTimeout = null;
            if (done) {
              doneTimeout = setTimeout(() => {
                done();
              }, 10000);
            };

            const currenntState = $this.currentState;

            $this.rootServices.apiService.getPromise<any[]>('/warehouse/goods', {

              masterPriceTable: currenntState.voucher.Code,
              includeCategories: true,
              includeUnit: true,
              includeContainer: true,
              includeFeaturePicture: true,
              includeHeadBookEntry: true,
              eq_Code: '[' + currenntState.voucher.HeadEntries.map(m => $this.commonService.getObjectId(m.Goods)).join(',') + ']',

              // ...self.filter,
            }).then(async rs => {
              for (const i in currenntState.voucher.HeadEntries) {
                const chooseGoods = rs.find(f => f.Code == $this.commonService.getObjectId(currenntState.voucher.HeadEntries[i].Goods));
                currenntState.voucher.HeadEntries[i] = {
                  Container: $this.commonService.getObjectId(chooseGoods.Container),
                  Goods: $this.commonService.getObjectId(chooseGoods.Code),
                  Description: chooseGoods.Name,
                  GoodsName: chooseGoods.Name,
                  Unit: $this.commonService.getObjectId(chooseGoods.WarehouseUnit),
                  IncreaseWrite: chooseGoods?.Inventory || 0,
                  AccessNumbers: chooseGoods.AccessNumbers || [],
                  UnitPrice: chooseGoods?.UnitPrice || 0,
                  UnitText: $this.commonService.getObjectText(chooseGoods.WarehouseUnit),
                  Image: [chooseGoods.FeaturePicture?.SmallImage],
                  Thumbnail: chooseGoods.FeaturePicture?.SmallImage || null,
                  CategoriesText: chooseGoods.Container.ContainerPath,
                  FindOrder: chooseGoods.Container.ContainerFindOrder,
                }
              }
              self.$setState({
                voucher: currenntState.voucher,
              });
              if (done) done();
            }).catch(err => {
              console.error(err);
              if (done) done();
            });

            return true;
          },
          async saveVoucher() {
            setTimeout(async () => {
              const self: F7ComponentContextExtend = this;
              // const id = self.$route.params['id'];
              // const chatRoom = self.$route.context.chatRoom;
              // const taskReminderData: TaskReminderModel = {};
              // const fieldEles = self.$el.find('.field');
              // fieldEles.each((index, fieldEle) => {
              //   console.log(fieldEle);
              //   console.log(fieldEle.value);
              //   if (fieldEle.name === 'MentionToMembers') {
              //     const starmtSelect = self.$app.smartSelect.get(self.$(fieldEle).closest('.smart-select')[0] as any);
              //     taskReminderData[fieldEle.name] = starmtSelect.getValue();
              //   } else if (fieldEle.name === 'RemindAtDateTime') {
              //     // const dateTimePicker = self.$app.calendar.get('input[name="RemindAtDateTime"]');
              //     const datetime: Date = self.calendarDateTime.getValue()[0];
              //     if (datetime) {
              //       taskReminderData[fieldEle.name] = datetime.toISOString();
              //     }
              //   } else {
              //     taskReminderData[fieldEle.name] = fieldEle.value;
              //   }
              // });

              // taskReminderData.Task = chatRoom.id;
              const currenntState = $this.currentState;
              // if (!currenntState.voucher.Title) {
              //   $this.commonService.showError('Tiêu đề là bắt buộc');
              //   return false;
              // }
              console.log(currenntState.voucher);
              self.$app.preloader.show();
              try {
                for (const entry of currenntState.voucher.HeadEntries) {
                  await $this.rootServices.apiService.putPromise<any[]>('/warehouse/books', {
                    id: [currenntState.voucher.Code],
                    updateHeadInventory: true,
                    goods: entry.Goods,
                    unit: entry.Unit,
                    container: entry.Container,
                    unitPrice: entry.UnitPrice,
                    inventory: entry.IncreaseWrite,
                    currency: 'VND',
                  }, [{
                    MasterPriceTable: currenntState.voucher.Code,
                    Goods: entry.Goods,
                    Unit: entry.Unit,
                    UnitPrice: entry.UnitPrice,
                    AccessNumbers: entry.AccessNumbers || []
                  }]).then(rs => {
                    // console.log(instance.rowData.Code);
                    // instance.status = 'success';
                    console.log(rs);
                  });
                }
                self.refresh();
                $this.commonService.showInfo("Đã cập nhật tồn kho đầu kỳ cho hàng hóa trong danh sách");
                self.$app.preloader.hide();
                // }
              } catch (err) {
                self.$app.preloader.hide();
              }
            }, 500);
          },
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          pageInit(e, page: F7Page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;

            // const reminder = self.$route.context.reminder || {};
            // const chatRoom = self.$route.context.chatRoom;

            // self.voucher = {
            //   Object: '',
            //   ObjectName: '',
            //   ObjectPhone: '',
            //   ObjectEmail: '',
            //   ObjectAddress: '',
            //   ObjectIdentifiedNumber: '',
            //   Contact: '',
            //   ContactName: '',
            //   ContactPhone: '',
            //   ContactEmail: '',
            //   ContactAddress: '',
            //   ContactIdentifiedNumber: '',
            //   Note: '',
            //   SubNote: '',
            //   Details: [
            //     {
            //       No: 1,
            //       CategoriesText: 'Thiết bị mạng',
            //       Thumbnail: '/assets/images/products/product-01.png',
            //       Product: 'DSFSDF',
            //       Description: 'Công Tắc Cảm Ứng Wifi SmartZ PM màu trắng, không viền, 4 nút WS1 (mã con WS1.4W)',
            //       Price: '770000',
            //       PriceText: '770.000 đ',
            //       OriginPrice: '800000',
            //       OriginPriceText: '800.000 đ',
            //       Unit: 'CAI',
            //       UnitText: 'Cái',
            //       Tax: 'VAT10',
            //       TaxText: '10% vat',
            //       ToMoney: '847000',
            //       ToMoneyText: '847.000 đ',
            //       PromotionText: 'giảm 30%'
            //     },
            //     {
            //       No: 2,
            //       CategoriesText: 'Thiết bị mạng',
            //       Thumbnail: '/assets/images/products/product-02.jpeg',
            //       Product: 'DSFSDF',
            //       Description: 'Công Tắc Cảm Ứng Wifi SmartZ PM màu trắng, viền bạc, 1 nút WS1 (mã con WS1F.1)',
            //       Price: '800000',
            //       PriceText: '800.000 đ',
            //       OriginPrice: '900000',
            //       OriginPriceText: '900.000 đ',
            //       Unit: 'CAI',
            //       UnitText: 'Cái',
            //       Tax: 'VAT10',
            //       TaxText: '10% vat',
            //       ToMoneyText: '693.000 đ',
            //       PromotionText: 'giảm 15%'
            //     },
            //     {
            //       No: 3,
            //       CategoriesText: 'Thiết bị mạng',
            //       Thumbnail: '/assets/images/products/product-03.jpg',
            //       Product: 'DSFSDF',
            //       Description: 'Cảm Biến Cửa Wifi Cho Nhà Thông Minh SmartZ PM WD1',
            //       Price: '450000',
            //       PriceText: '450.000 đ',
            //       OriginPrice: '500000',
            //       OriginPriceText: '500.000 đ',
            //       Unit: 'CAI',
            //       UnitText: 'Cái',
            //       Tax: 'VAT10',
            //       TaxText: '10% vat',
            //       ToMoneyText: '490.000 đ',
            //       PromotionText: 'giảm 20%'
            //     },
            //     {
            //       No: 4,
            //       CategoriesText: 'Thiết bị mạng',
            //       Thumbnail: '/assets/images/products/product-04.jpeg',
            //       Product: 'DSFSDF',
            //       Description: 'Bộ Báo Động Chống Trộm Qua WIFI + Sim GSM GW05 Cao Cấp',
            //       Price: '4500000',
            //       PriceText: '4.500.000 đ',
            //       OriginPrice: '5000000',
            //       OriginPriceText: '5.000.000 đ',
            //       Unit: 'CAI',
            //       UnitText: 'Cái',
            //       Tax: 'VAT10',
            //       TaxText: '10% vat',
            //       ToMoneyText: '5.950.000 đ',
            //       PromotionText: 'giảm 20%'
            //     },
            //   ],
            // };


            // self.calendarDateTime = self.$f7.calendar.create({
            //   // openIn: 'customModal',
            //   inputEl: page.$el.find('input[name="RemindAtDateTime"]'),
            //   timePicker: true,
            //   dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' },
            //   // header: true,
            //   footer: true,
            // });

            // Set back title
            // self.$setState({ backTitle: self.$route.context && self.$route.context.backTitle || 'Back', title: self.$route.context && self.$route.context.title || 'Tài khoản' });

            $this.onComponentInit({ instance: self, page: page }, self.$route.params['id']).then(curentState => {
              // const smartSelect = self.$app.smartSelect.get('.tax-smart-select');
              // if (smartSelect) {
              //   // starmtSelectRoles.setValue(memberInfo.roles);
              //   smartSelect.on('closed', (smartSelect) => {
              //     console.log(smartSelect);
              //   });
              // }

              self.detailDialog = self.$app.dialog.create({
                el: page.$el.find('.dialog-detail'),
                closeByBackdropClick: true,
              });


            });

            // setTimeout(() => {
            //   const starmtSelectTax = self.$app.smartSelect.create({
            //     el: self.$$(page.el).find('.tax-smart-select'),
            //     valueEl: self.$$(page.el).find('.vat'),
            //   } as any);
            //   if (starmtSelectTax) {
            //     starmtSelectTax.setValue('VAT10');
            //     starmtSelectTax.on('closed', (tax) => {
            //       console.log(tax);
            //     });
            //   }
            // }, 500);

            // console.log(reminder);
            $this.rootServices.authService.isAuthenticatedOrRefresh().pipe(take(1)).toPromise().then(() => {
              self.$setState({
                backTitle: self.$route.context && self.$route.context.backTitle || 'Back', title: self.$route.context && self.$route.context.title || 'Chat GUI',
                // memberList: chatRoom.memberList$.getValue().filter(member => member.type === 'USER'),
                // reminder: self.reminder,
              });

              // setTimeout(() => {
              //   self.$setState({
              //     reminder: self.reminder,
              //   });
              // }, 5000);
            }).catch(err => {
              console.error(err);
              $this.commonService.showError(err);
            });

            // setTimeout(() => {
            //   const starmtSelect = self.$app.smartSelect.get($(page.el).find('.field[name="MentionToMembers"]').closest('.smart-select')[0] as any);
            //   // const remindeAtDateTime = self.$app.calendar.get(self.$('.field[name="RemindAtDateTime"]')[0] as any);

            //   if (reminder.MentionToMembers) {
            //     starmtSelect.setValue(reminder.MentionToMembers.map(member => $this.commonService.getObjectId(member)));
            //   }
            //   if (reminder.RemindAtDateTime) {
            //     self.calendarDateTime.setValue([new Date(reminder.RemindAtDateTime)]);
            //   }

            //   starmtSelect.on('closed', (smartSelect) => {
            //     const selectedRoles: string & string[] = (smartSelect.getValue() as string[]).sort() as any;
            //     console.log('on smart select closed', selectedRoles);
            //     // if ((reminder.roles || []).toString() !== (selectedRoles || []).toString()) {
            //     //   reminder.roles = selectedRoles;
            //     //   chatRoom.updateMember(reminder).then(rs => {
            //     //     console.log('chat room member was updated', rs);
            //     //     $this.commonService.showInfo(`Đã thay đổi vai trò của ${reminder.shortName || reminder.name}`);
            //     //   }).catch(err => {
            //     //     console.error(err);
            //     //     $this.commonService.showError(err);
            //     //   });
            //     // }
            //   });
            // }, 500);
          },
          // pageBeforeIn(e, page) {
          //     console.log('page before in');
          //     const self: F7ComponentContextExtend = this;
          //     self.refresh();
          // },
          // pageAfterIn(e, page) {
          //     console.log('page after in');
          // },
          // pageBeforeOut(e, page) {
          //     console.log('page before out');
          // },
          // pageAfterOut(e, page) {
          //     console.log('page after out');
          // },
          // pageBeforeUnmount(e, page) {
          //     console.log('page before unmount');
          // },
          // pageBeforeRemove(e, page) {
          //     console.log('page before remove');
          // },
          pageBeforeRemove(e, page) {
            console.log('[page event] before remove', page.route.url);
            const self: F7ComponentContextExtend = this;
            // const starmtSelectRoles = self.$app.smartSelect.get('.smart-select');
            // starmtSelectRoles.destroy();
            const currentState = $this.state[self.$route.params.id];
            if (self.$route.params.id === 'new') {
              if (currentState && currentState.voucher && currentState.voucher.Code) {
                delete currentState.voucher;
              }
            }
          },
        },
      },
    };
  }
}
