import { VirtualList } from 'framework7/build/core/components/virtual-list/virtual-list';
import { SmartSelect } from 'framework7/build/core/components/smart-select/smart-select';
import { take, filter } from 'rxjs/operators';
import { Dialog } from "framework7/build/core/components/dialog/dialog";
import { Router } from "framework7/build/core/modules/router/router";
import { ContactModel } from "../../../../../src/app/model/contact.model";
import { CommonService } from "../../../../../src/app/services/common.service";
import { RootServices } from "../../../../../src/app/services/root.services";
import { F7ComponentContext, F7Page } from "../../../../../src/app/types/framework7-types";
import { FileModel } from '../../../../../src/app/model/file.model';
import { BaseFormComponent, FormComponentStateExtend, FormSchema } from '../../../../../src/app/lib/base-form-component';
import { ProductModel, UnitModel } from '../../../../../src/app/model/product.model';
import { ChatRoomModel } from '../../../../../src/app/model/chat-room.model';
import { WarehouseGoodsInContainerModel, WarehouseInventoryAdjustNoteDetailModel, WarehouseInventoryAdjustNoteModel, WarehouseGoodsContainerModel } from '../../../../../src/app/model/warehouse.model';
import { BehaviorSubject, Subscription } from 'rxjs';
import { KeyboardResizeMode } from '@ionic-native/keyboard/ngx';
// import * as $ from 'jquery';
declare const $: any;

// declare const $: any;
export interface ComponentStateExtend extends FormComponentStateExtend<WarehouseInventoryAdjustNoteModel> {
  [key: string]: any;
  instance: F7ComponentContextExtend,
  data?: WarehouseInventoryAdjustNoteModel;
  form?: any;
}

export class F7ComponentContextExtend extends F7ComponentContext {
  responseInProgress?: boolean;

  detailDialog?: Dialog.Dialog;
  virtualList?: VirtualList.VirtualList;

  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, [key: string]: any } };
}

/** Component group manager */
export class WarehouseInventoryAdjustNoteFormComponent extends BaseFormComponent<ComponentStateExtend, WarehouseInventoryAdjustNoteModel> {
  // states: { [key: string]: State } = {};
  currentState: ComponentStateExtend;
  title: 'Phiếu kiểm kho';
  namespace = 'inventory-adjust-note-form';
  idKey = 'Code';

  apiPath = '/warehouse/inventory-adjust-notes';

  unitList: UnitModel[] = [];
  shelfList: WarehouseGoodsContainerModel[] = [];

  contactList: ContactModel[] = [];
  loggedContact: ContactModel;

  schema: FormSchema = {
    Object: {
      type: 'autocomplete-contact',
      label: 'Khách hàng',
      validators: [
        'required'
      ]
    },
    ObjectName: {
      type: 'input',
      label: 'Tên khách hàng',
      validators: [
        'required'
      ]

    },
    Contact: {
      type: 'autocomplete-contact',
    },
    Title: {
      type: 'input',
      label: 'Tiêu đề',
      validators: [
        'required'
      ]

    },
    Shelf: {
      type: 'smart-select',
      label: 'Kệ',
      // validators: [
      //   'required'
      // ]

    },
    Note: {
      type: 'texteditor',
    },
    SubNote: {
      type: 'texteditor',
    },
    Details: {
      type: 'list',// 'virtual-list, note: xử lý riêng cho trường hợp này: khi set data thì phải replaceAllItems trong virtual list'
      makeModel: (properties: WarehouseInventoryAdjustNoteDetailModel) => {
        return new WarehouseInventoryAdjustNoteDetailModel(properties);
      },
      Unit: {
        type: 'smart-select',
        label: 'Đơn vị tính',
        validators: [
          'required'
        ]
      },
      Container: {
        type: 'smart-select',
        label: 'Vị trí',
        validators: [
          'required'
        ]
      },
      AccessNumbers: {
        type: 'smart-select',
        label: 'Số truy xuất',
        validators: [

        ]
      },
      Quantity: {
        type: 'stepper',
        label: 'Số lượng',
        validators: [
          'required'
        ]
      },
      // Price: { type: 'inputmask', format: { mask: 'decimal', option: { radixPoint: ',', groupSeparator: '.' } } },
      // ToMoney: { type: 'inputmask', format: { mask: 'decimal', option: { radixPoint: ',', groupSeparator: '.' } } },
    }
  };

  lastForceElement: HTMLElement;
  // lastForceIndex: number;

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
  ) {
    super(rootServices, commonService);
    // // console.log('Click here to open ts file');

    // this.rootServices.keyboard.setResizeMode(KeyboardResizeMode.Body);
    // this.rootServices.keyboard.disableScroll(true);
    // this.rootServices.keyboard.onKeyboardShow().subscribe(() => {
    //   setTimeout(() => {
    //     if(this.lastForceElement && $(this.lastForceElement).is('input')) {
    //       this.lastForceElement.focus();
    //     }
    //   }, 0);
    // });
  }

  makeModel(properties?: WarehouseInventoryAdjustNoteModel): WarehouseInventoryAdjustNoteModel {

    return new WarehouseInventoryAdjustNoteModel({
      ...properties,
      Object: this.loggedContact,
      ObjectName: this.loggedContact?.Name || null,
      ObjectPhone: this.loggedContact?.Phone || null,
      ObjectEmail: this.loggedContact?.Email || null,
      ObjectAddress: this.loggedContact?.Address || null,
    });
  }

  async makeNewData() {
    return new WarehouseInventoryAdjustNoteModel();
  }

  getFormData(params?: any): Promise<WarehouseInventoryAdjustNoteModel> {
    return super.getFormData({
      includeDetails: true,
      includeTax: true,
      includeUnit: true,
      includeProductPrice: true,
      includeProductUnitList: true,
      includeProductCategories: true,
      includeRelativeVouchers: true,
      ...params,
    });
  }

  prepareData(data: WarehouseInventoryAdjustNoteModel): WarehouseInventoryAdjustNoteModel {
    for (const detail of data.Details) {

      if (detail?.Unit && typeof detail?.Unit == 'string') {
        detail.Unit = this.unitList.find(f => f.id == detail.Unit as any);
      }

      if (!data.Pictures) {
        data.Pictures = [];
      }
    };
    return data;
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  async onComponentInit(state: ComponentStateExtend, index: string, asCase?: string, f7Page?: F7Page) {
    const $this = this;

    // Start loading status
    state.instance.$setState({
      loading: true,
      schema: this.schema,
    });
    this.commonService.showPreloader();

    // get goods infor in first time
    this.commonService.showInfo('Đang tải thông tin hàng hóa...');
    await this.updateGoodsInfo().then(() => {
      this.commonService.showInfo('Đã tải thông tin hàng hóa.');
    }).catch(err => {
      this.commonService.showError(err);
    });

    // Get static data
    await this.rootServices.apiService.getPromise<UnitModel[]>('/admin-product/units', { onlyIdText: true, limit: 'nolimit' }).then(rs => {
      state.instance.unitList = rs;
    });
    state.instance.shelfList = await this.rootServices.apiService.getPromise('/warehouse/goods-containers', { eq_Type: 'SHELF', limit: 'nolimit', includeIdText: true });
    // End

    // set static data
    state.instance.$setState({
      unitList: state.instance.unitList,
      contactList: state.instance.contactList,
      shelfList: state.instance.shelfList
    });

    // 
    this.loggedContact = await this.rootServices.apiService.getPromise<ContactModel[]>('/contact/contacts', { eq_User: this.rootServices.authService.getUser().id, includeIdText: true }).then(rs => rs[0]);

    const currentState: ComponentStateExtend = await super.onComponentInit(state, index, asCase, f7Page) as any;

    // Virtual list
    currentState.instance.virtualList = currentState.instance.$app.virtualList.create({
      // List Element
      el: $(f7Page.el).find('.' + this.namespace + '-virtual-list') as any,
      // Pass array with items
      items: currentState.data.Details,
      // List item Template7 template
      itemTemplate: this.itemTemplate,
      // Item height
      height: this.itemHeight,
      on: {
        itemBeforeInsert: (virtualList, element: HTMLElement, item: WarehouseInventoryAdjustNoteDetailModel) => {
          // console.log('befor insert', virtualList, element, item);
          this.itemBeforeInsert(element, item)
          // $(element).unbind('click').click(() => {
          //     // self.openChatRoom(null, $(element).attr('data-id'));
          //     this.onItemClick(currentState.instance, item);
          // });
        },
        itemsAfterInsert: (virtualList, fragment) => {
          // this.itemsAfterInsert(virtualList, fragment);
        }
      }
    });

    if (currentState.instance.$route?.context?.copyFromId) {
      currentState.instance.data.SubNote = 'Copy of ' + currentState.instance.$route?.context?.copyFromId + ': ' + (currentState.instance.data.SubNote ? ('<br>' + currentState.instance.data.SubNote) : '');
      this.setData(currentState.instance.data);
    }

    if (currentState?.instance?.$route?.context?.relativeVouchers && currentState.instance.$route.context.relativeVouchers[0]?.text) {
      if (!currentState.data.Title) {
        currentState.data.Title = currentState.instance.$route.context.relativeVouchers[0]?.text;
        currentState.instance.$setState({ data: currentState.data });
        this.validate();
      }
    }


    // Stop loading status
    currentState.instance.$setState({ loading: false });
    this.commonService.hidePreloader();

    this.rootServices.enableAssistiveTouchFeature($(currentState.instance.el).find('.assistiveTouch')[0]);

    return currentState;
  }

  async setData(data: WarehouseInventoryAdjustNoteModel, option?: { onlyList?: string[]; onlyKeys?: string[]; prepareControl?: boolean; }): Promise<boolean> {
    let priceTableDetails: { [key: string]: { Price: number } } = {};
    for (const index in data.Details) {
      const detail = data.Details[index];
      if (!detail.__unitList || detail.__unitList.length == 0) {
        if (detail.Product?.Units) {
          detail.__unitList = detail.Product.Units;
        } else {
          // detail.__unitList = this.goodsList.filter(f => f.Code == this.commonService.getObjectId(detail.Product))
          detail.__unitList = await this.rootServices.searchFromSql('product_search_index', { Code: this.commonService.getObjectId(detail.Product) }).then(rs => rs.map(m => m.Unit));
          detail.__unitList = [...new Set(detail.__unitList.map(m => this.commonService.getObjectId(m)))].map(n => detail.__unitList.find(f => this.commonService.getObjectId(f) == n))
        }
      }
      if (!detail.__containerList || detail.__containerList.length == 0) {
        // detail.__containerList = detail.Container ? [detail.Container] : [];

        // detail.__containerList = this.goodsList.filter(f => f.Code == this.commonService.getObjectId(detail.Product) && this.commonService.getObjectId(f.Unit) == this.commonService.getObjectId(detail.Unit))
        detail.__containerList = await this.rootServices.searchFromSql('product_search_index', { Code: this.commonService.getObjectId(detail.Product), UnitId: this.commonService.getObjectId(detail.Unit) }).then(rs => rs.map(m => m.Container));
        // .map(m => ({ id: m.Container.id, text: m.Container.ShelfName + '/' + m.Container.FindOrder, findOrder: m.Container.FindOrder }));
        // .map(m => m.Container);

      }
      // if (!detail.__accessNumberList || detail.__accessNumberList.length == 0) {
      detail.AccessNumbers = (detail.AccessNumbers || []).map(m => this.commonService.getObjectId(m));
      // }
      detail.ToMoney = detail.Price * detail.Quantity;
      detail.__index = index;
    }
    // if (!data?.PriceTable && !data[this.idKey]) {
    //   data.PriceTable = this.currentState.instance.priceTableList[0];
    // } else if (data?.PriceTable) {
    //   if (typeof data?.PriceTable == 'string') {
    //     data.PriceTable = this.currentState.instance.priceTableList.find(f => f.id == data?.PriceTable);
    //   }
    // }

    const total = this.calculateTotal(data);
    return super.setData(data, { ...option, onlyList: [] }).then(status => {
      this.currentState.instance.$setState({ total });
      return status;
    });
  }

  async addItemForList<WarehouseInventoryAdjustNoteDetailModel>(listName: string, item?: WarehouseInventoryAdjustNoteDetailModel): Promise<WarehouseInventoryAdjustNoteDetailModel> {
    return super.addItemForList(listName, item).then(newItem => {
      // this.commonService.showInfo('Đã thêm sản phẩm');
      return newItem;
    }).then(rs => {
      this.currentState.instance.virtualList.replaceAllItems(this.currentState.data.Details);
      // this.currentState.instance.virtualList.scrollToItem(this.currentState.data.Details.length - 1);
      this.updateAndActiveItem(this.currentState.data.Details.length - 1);
      this.rootServices.playNewPipSound();
      // this.commonService.playNewPipSound();
      return rs;
    });
  }

  updateAndActiveItem(index: number, newData?: WarehouseInventoryAdjustNoteDetailModel) {
    this.currentState.instance.virtualList.items.filter(f => f.__active).map(m => {
      this.currentState.instance.virtualList.replaceItem(m.__index, { ...m, __active: false });
    });
    const item = this.currentState.instance.virtualList.items.find(f => f.__index == index);
    if (item) {
      this.currentState.instance.virtualList.replaceItem(index, { ...(newData || item), __active: true });
      this.currentState.instance.virtualList.scrollToItem(index);
    }

  }

  async updateImages(state: (progress) => void, onAfterImageUpload?: (image: FileModel) => void) {

    let proloaderProcessing;
    try {
      const localFiles = await this.rootServices.pickFiles();
      console.debug(localFiles);

      this.commonService.showPreloader();
      proloaderProcessing = setTimeout(() => {
        this.commonService.hidePreloader();
      }, 16000);

      let images: FileModel[] = [];
      for (const i in localFiles) {
        // let image: FileModel = null;
        images[i] = await ((progress) => {
          if (localFiles[i].dataType === 'url') {
            return this.rootServices.uploadLocalFile(localFiles[i].url as string, (event) => {
              progress(event)
            });
          } else {
            const formData = new FormData();
            const imgBlob = new Blob([localFiles[i].url], {
              type: localFiles[i].type
            });
            formData.append('file', imgBlob, 'smart-bot-' + Date.now() + '.' + localFiles[i].ext);
            return this.rootServices.uploadFileData(formData, (event) => {
              progress(event)
            });
          }
        })((progress) => {
          console.log(progress);
          state(progress);
        });
        onAfterImageUpload && onAfterImageUpload(images[i]);
      }
      console.debug(images);
      clearTimeout(proloaderProcessing);
      this.commonService.hidePreloader();
      return images;
    } catch (err) {
      console.error(err);
      this.commonService.showError(err, { position: 'bottom' }); clearTimeout(proloaderProcessing);
      this.commonService.hidePreloader();
      throw new Error(err);
    }
  }

  async onFieldChange(self: F7ComponentContext, e: any, ...args: any) {
    const currentState = this.currentState;
    return super.onFieldChange(self, e, args).then(async fieldInfo => {

      if (!fieldInfo) {
        return null;
      }

      let { field, fieldName, fieldValue, index, listName } = fieldInfo;

      let requireSetData = false;

      console.log('on field change', listName, index, fieldName, fieldValue);
      if (listName == 'Details' && fieldName == 'Quantity') {

        currentState.data[listName][index]['ToMoney'] = (currentState.data[listName][index]['Quantity'] || 0) * (currentState.data[listName][index]['Price'] || 0);
        // currentState.instance.$setState({ data: currentState.data });
        this.rootServices.playIncreasePipSound();
        requireSetData = true;
      }

      if (listName == 'Details' && fieldName == 'Price') {

        currentState.data[listName][index]['ToMoney'] = (currentState.data[listName][index]['Quantity'] || 0) * (currentState.data[listName][index]['Price'] || 0);
        currentState.instance.$setState({ data: currentState.data });
        // $(currentState.instance.el).find('.list-name[name="' + listName + '"] .index-' + index + ' [name="ToMoney"]').val(currentState.data[listName][index]['ToMoney']);
        requireSetData = true;
      }
      if (listName == 'Details' && fieldName == 'ToMoney') {

        if (currentState.data[listName][index]['Quantity'] > 0) {
          currentState.data[listName][index]['Price'] = (currentState.data[listName][index]['ToMoney'] || 0) / currentState.data[listName][index]['Quantity'];
          requireSetData = true;
        }
        // currentState.instance.$setState({ data: currentState.data });
        // $(currentState.instance.el).find('.list-name[name="' + listName + '"] .index-' + index + ' [name="Price"]').val(currentState.data[listName][index]['Price']);
      }
      if (listName == 'Details' && fieldName == 'AccessNumbers') {
        currentState.data[listName][index]['Quantity'] = currentState.data[listName][index]['AccessNumbers'].length;
        this.currentState.instance.virtualList.replaceItem(index as number, currentState.data[listName][index]);
        requireSetData = true;
      }

      if (fieldName == 'Object' && fieldValue && !currentState.instance.loading) {
        await this.rootServices.apiService.getPromise<ContactModel[]>('/contact/contacts/' + this.commonService.getObjectId(fieldValue), {}).then(contacts => contacts[0]).then(contact => {

          // Auto fill object
          currentState.data.Object = fieldValue;
          currentState.data.ObjectName = contact.Name;
          currentState.data.ObjectPhone = contact.Phone;
          currentState.data.ObjectEmail = contact.Email;
          currentState.data.ObjectAddress = contact.Address;
          currentState.data.ObjectIdentified = contact.TaxCode;

          // currentState.instance.$setState({
          //   data: currentState.data,
          // });
          requireSetData = true;
          this.validate();
        });
      }

      if (fieldName == 'Contact' && fieldValue && !currentState.instance.loading) {
        await this.rootServices.apiService.getPromise<ContactModel[]>('/contact/contacts/' + this.commonService.getObjectId(fieldValue), {}).then(contacts => contacts[0]).then(contact => {

          // Auto fill object
          currentState.data.Contact = fieldValue;
          currentState.data.ContactName = contact.Name;
          currentState.data.ContactPhone = contact.Phone;
          currentState.data.ContactEmail = contact.Email;
          currentState.data.ContactAddress = contact.Address;
          currentState.data.ContactIdentified = contact.TaxCode;

          // currentState.instance.$setState({
          //   data: currentState.data,
          // });
          // requireSetData = true;
          this.validate();

        });
      }

      if (listName == 'Details' && fieldName == 'Unit') {
        // const unitPrice = currentState.data[listName][index]?.__unitList?.find(f => f.id == this.commonService.getObjectId(fieldValue));
        // if (unitPrice) {
        //   currentState.data[listName][index]['Price'] = parseFloat(unitPrice.Price || 0);
        //   // currentState.data[listName][index]['ToMoney'] = (currentState.data[listName][index]['Quantity'] || 0) * (currentState.data[listName][index]['Price'] || 0);
        // } else {
        //   currentState.data[listName][index]['Price'] = 0;
        //   this.commonService.showError('Đơn vị tính ' + currentState.instance.unitList.find(f => f.id == this.commonService.getObjectId(fieldValue))?.Name + ' không có giá bán !');
        // }
        const detail = currentState.data.Details[index];
        detail.__containerList = null;
        // detail.Container = null;

        // detail.__containerList = this.goodsList.filter(f => f.Code == this.commonService.getObjectId(detail.Product) && this.commonService.getObjectId(f.Unit) == this.commonService.getObjectId(detail.Unit))
        detail.__containerList = await this.rootServices.searchFromSql('product_search_index', { Code: this.commonService.getObjectId(detail.Product), UnitId: this.commonService.getObjectId(detail.Unit) }).then(rs => rs.map(m => ({ id: m.Container.id, text: m.Container.ShelfName + '/' + m.Container.FindOrder, findOrder: m.Container.FindOrder })));

        if (detail.__containerList && detail.__containerList.length == 1) {
          detail.Container = detail.__containerList[0];
        } else if (detail.__containerList) {
          if (detail.Container && detail.__containerList.findIndex(f => this.commonService.getObjectId(f) == detail.Container) < 0) {
            detail.Container = null;
          }
        }
        this.currentState.instance.virtualList.replaceItem(index as number, detail);

        // requireSetData = true;
      }

      if (requireSetData) {
        const total = this.calculateTotal();
        currentState.instance.$setState({
          data: currentState.data,
          total: total,
        });
      } else {
        currentState.instance.$setState({ total: this.calculateTotal() });
      }
      return fieldInfo;
    });
  }

  async removeDetailItem(self: F7ComponentContext, e: any, ...args: any): Promise<{ detail?: any, listName?: string; index?: string | number; }> {
    const currentState = this.currentState;
    return new Promise((resolve, reject) => {
      self.$app.dialog.confirm('Bạn có chắc là muốn gở chi tiết này ?', 'Gở chi tiết', () => {
        resolve(super.removeDetailItem(self, e, args).then(detailInfo => {
          let { detail, listName, index } = detailInfo;
          if (listName == 'UnitConversions') {
            self.$app.accordion.close(detail[0]);
          }
          this.calculateTotal();
          this.rootServices.playDecreasePipSound();
          currentState.instance.virtualList.replaceAllItems(currentState.data.Details);
          return detailInfo;
        }).catch(err => {
          return Promise.reject(err);
        }));
      });
    });
  }


  async chooseProduct(self: F7ComponentContextExtend) {
    // const self: F7ComponentContextExtend = this;
    const $this = this;
    const currentState = this.currentState;
    this.rootServices.navigate('/warehouse/goods-list/choose', {
      context: {
        backTitle: this.title,
        textColorClass: self.textColorClass,
        // priceTable: currentState.data.PriceTable,
        async onChoose(product: ProductModel & WarehouseGoodsInContainerModel) {

          console.log('Choosed product', product);

          // const containerList = $this.goodsList.filter(f => f.Code == $this.commonService.getObjectId(product) && f.Unit?.id == $this.commonService.getObjectId(product.Unit))
          //   .map(m => ({ id: m.Container.id, text: m.Container.ShelfName + '/' + m.Container.FindOrder, findOrder: m.Container.FindOrder }));

          // const productInfo = (await $this.rootServices.apiService.getPromise<ProductModel[]>('/admin-product/products/' + product.Code, { includeIdText: true, includeUnits: true }))[0];
          product.id = product.Code;
          product.text = product.Name;
          // const unit = product.UnitConversions && product.UnitConversions.find(f => f.IsDefaultPurchase) || product.UnitConversions[0]
          // const unit = product.UnitList.find(f => f.id == $this.commonService.getObjectId(product.Unit)) || product.UnitList[0] || product.Unit || null;
          const unit = $this.commonService.getObjectId(product.Unit);
          const container = $this.commonService.getObjectId(product.Container);

          const index = currentState.data.Details.findIndex(
            f => 
              $this.commonService.getObjectId(f.Product) == product.id 
              && $this.commonService.getObjectId(f.Unit) == $this.commonService.getObjectId(unit)
              && $this.commonService.getObjectId(f.Container) == $this.commonService.getObjectId(container)
              );
          if (index > -1) {// Exists => increase quantity
            let item = currentState.data['Details'][index];
            item.Quantity++;
            $this.updateItemForList('Details', index, item);
            $this.rootServices.playIncreasePipSound();
            $this.commonService.showInfo('Tăng số lượng lên ' + item.Quantity + ' cho sản phẩm ' + $this.commonService.getObjectText(product) + ' !', { position: 'bottom' });
            return;
          }

          $this.addItemForList('Details', {
            ...new WarehouseInventoryAdjustNoteDetailModel(),
            Product: product,
            Description: product.Name,
            Unit: unit,
            Price: parseFloat(unit?.Price || 0 as any),
            Quantity: 0,
            Container: $this.commonService.getObjectId(product.Container),
            // Tax: 'NOTAX',
            __unitList: [product.Unit],
            __containerList: [product.Container],
            // __unitPrices: product.UnitList,
            Image: [
              ...[product.GoodsThumbnail],
              ...(Array.isArray(product.GoodsPictures) && product.GoodsPictures || []).filter(f => f.Id != product?.GoodsThumbnail?.Id)
            ]
          });

          $this.commonService.showInfo('Đã thêm sản phẩm vào danh sách', { position: 'bottom' });

        },
      }
    });
  }

  status = '';
  progressStatus = 'primary';
  progressLabel = '';
  progress = 0;
  // private goodsList: ProductModel[] = [];
  // private productMapByUnit: { [key: string]: (ProductModel & { Unit?: UnitModel, Containers?: WarehouseGoodsContainerModel[] }) } = {};
  async updateGoodsInfo() {
    // if (this.goodsList && this.goodsList.length > 0) {
    //   return true;
    // }
    // this.status = 'Đang tải bảng giá...';
    // while (true) {
    // try {
    //   // const rs = await this.rootServices.productSearchIndexs$.pipe(filter(f => !!f), take(1)).toPromise();
    //   // this.productMap = this.rootServices.productMap;
    //   // this.unitMap = this.rootServices.unitSeqMap;
    //   // this.productMapByUnit = this.rootServices.productMapByUnit;
    //   this.findOrderMap = {};
    //   for (const key in this.rootServices.findOrderMap) {
    //     const m = this.rootServices.findOrderMap[key];
    //     this.findOrderMap[key] = {
    //       ...m,
    //       Unit: this.commonService.getObjectId(m.Unit),
    //       Container: this.commonService.getObjectId(m.Container)
    //     };
    //   }


    //   // Get goods list
    //   // this.goodsList = [];
    //   // for (const goodsInContainer of rs) {
    //   //   // const price = this.masterPriceTable[`${goodsInContainer.Goods}-${this.commonService.getObjectId(goodsInContainer.Unit)}`]?.Price || null;
    //   //   goodsInContainer['id'] = `${goodsInContainer.Goods}-${goodsInContainer.Unit}-${goodsInContainer.Container}`;
    //   //   goodsInContainer['text'] = goodsInContainer.GoodsName + ' (' + goodsInContainer.UnitLabel + ')';
    //   //   goodsInContainer['Keyword'] = (goodsInContainer.GoodsSku + ' ' + goodsInContainer.GoodsName + ' (' + goodsInContainer.UnitLabel + ')').toLowerCase();
    //   //   this.goodsList.push(goodsInContainer);
    //   // }

    //   return true;
    // } catch (err) {
    //   console.log(err);
    //   console.log('retry...');
    //   this.status = 'Lỗi tải bảng giá, đang thử lại...';
    //   this.commonService.showError('Bảng giá mới chưa được cập nhật, refersh trình duyệt để tải lại', { position: 'bottom' });
    //   return false;
    // }
    // }
  }

  // private productMap: { [key: string]: ProductModel } = {};
  // private unitMap: { [key: string]: UnitModel } = {};
  // private findOrderMap: { [key: string]: { Goods?: string, Unit?: any, UnitLabel?: string, Container?: any } } = {};
  masterPriceTable: { [key: string]: ProductModel } = {};

  barcodeProcessCount = -1;
  isBarcodeProcessing = new BehaviorSubject<number>(0);
  async barcodeProcess(barcode: string, option?: { type?: string }) {
    const $this = this;

    this.barcodeProcessCount++;
    const queueId = this.barcodeProcessCount;

    // Wait for previous barcode process finish
    await this.isBarcodeProcessing.pipe(filter(f => {
      console.log(`Barcode processing queue check: ${f} === ${queueId}`);
      return f === queueId;
    }), take(1)).toPromise();

    await $this.rootServices.barcodeExtract(barcode, option?.type).then(async result => {
      if (!result) {
        $this.commonService.showError('Không tìm thấy hàng hóa !', { position: 'bottom' });
        return false;
      }

      let containerList = [];
      let container;
      let accessNumber = result.accessNumber;
      let hadWarning = false;
      if (!accessNumber) {

        // if ($this.goodsList) {
        // containerList = $this.goodsList.filter(f => f.Code == result.product?.id && f.Unit?.id == result.unit?.id)
        containerList = await this.rootServices.searchFromSql('product_search_index', { Code: result.product?.id, UnitId: this.commonService.getObjectId(result.unit?.id) }).then(rs => rs.map(m => ({ id: m.Container.id, text: m.Container.ShelfName + '/' + m.Container.FindOrder, findOrder: m.Container.FindOrder })));
        // }

        container = result.container;
      } else {
        let goods = await $this.rootServices.apiService.getPromise<any[]>('/warehouse/goods', {
          includeCategories: true,
          includeFeaturePicture: true,
          includeUnit: true,
          includeContainers: true,
          includeInventory: true,
          sort_Id: 'desc',
          offset: 0,
          limit: 100,
          eq_Code: result.product.id,
          // eq_UnitSeq: result.unitSeq,
          eq_ConversionUnit: this.commonService.getObjectId(result.unit)
        }).then(rs => {
          console.log(rs);
          // const goods = rs[0];
          return rs[0]
        });
        if (goods.Containers && goods.Containers.length > 0) {
          const selectedShelf = $this.commonService.getObjectId($this.currentState.data.Shelf);
          containerList = goods.Containers.map(m => {
            m.id = m.Container;
            m.text = `${m.ContainerShelfName}/${m.ContainerFindOrder}`;
            return m;
          });
          if (selectedShelf) {
            container = goods.Containers.find(f => f.ContainerShelf == selectedShelf);
            if (container) {
              // container.selected = true;
              // $this.commonService.playNewPipSound();
            } else {
              $this.commonService.showError(`Không có vị trí nào phù hợp cho ${goods.Code} !`, { position: 'bottom' });
              $this.commonService.playErrorPipSound();
              hadWarning = true;
            }
          } else {
            if (accessNumber) {
              container = goods.Containers.find(f => Array.isArray(f.AccessNumbers) && f.AccessNumbers.some(s => s == accessNumber));
              if (!container) {
                $this.commonService.showError(`Số truy xuất ${accessNumber} không có trong kho !`);
                $this.commonService.playErrorPipSound();
                hadWarning = true;
              } else {
                // container.selected = true;
                container = $this.commonService.getObjectId(container);
                // $this.commonService.playNewPipSound();
              }
            } else {
              if (containerList.length == 1) {
                container = this.commonService.getObjectId(containerList[0]);
              }
            }
          }
        } else {
          $this.commonService.playErrorPipSound();
          $this.commonService.showError(`${goods.Name} chưa được cài đặt vị trí !`);
          hadWarning = true;
        }

      }

      console.log('Container list: ', containerList);

      const oldIndex = $this.currentState.data.Details.findIndex(f => $this.commonService.getObjectId(f.Product) == $this.commonService.getObjectId(result.product) && $this.commonService.getObjectId(f.Unit) == $this.commonService.getObjectId(result.unit) && (!container || $this.commonService.getObjectId(f.Container) == $this.commonService.getObjectId(container)))
      if (oldIndex < 0) {

        $this.addItemForList('Details', {
          ...new WarehouseInventoryAdjustNoteDetailModel(),
          Product: result.product,
          Description: result.product.Name,
          Unit: result.unit,
          Price: parseFloat(result.price || 0 as any),
          // Quantity: accessNumber ? 1 : 0,
          Quantity: 1,
          Container: container,
          AccessNumbers: (accessNumber ? [accessNumber] : []),
          __containerList: containerList,
          __unitList: [result.unit],
          Image: [
            ...(result?.product?.FeaturePicture ? [result?.product?.FeaturePicture] : []),
            ...(Array.isArray(result?.product.Pictures) && result?.product.Pictures || []).filter(f => f.Id != result?.product?.FeaturePicture?.Id)
          ]
        });

        $this.updateAndActiveItem($this.currentState.data.Details.length - 1);
        // if (!hadWarning) $this.commonService.playNewPipSound();
        if (!hadWarning) $this.commonService.showInfo('Đã thêm hàng hóa');

      } else {

        const existsDetail = $this.currentState.data.Details[oldIndex];
        if (!accessNumber) {
          // $this.commonService.playErrorPipSound();
          existsDetail.Quantity++;
          if (!hadWarning) $this.commonService.playIncreasePipSound();
          $this.updateAndActiveItem(oldIndex);
          // $this.commonService.showError('Hàng hóa đã có trong danh sách ! - ' + result.product.text + ' (' + result.unit.text + ') - ' + (containerList[0] && containerList[0].text));
        } else {
          let currentAccessNumbers: string[] = existsDetail.AccessNumbers = existsDetail.AccessNumbers || [];
          if (currentAccessNumbers.indexOf(accessNumber) < 0) {
            currentAccessNumbers.unshift(accessNumber);
            currentAccessNumbers = [...new Set(currentAccessNumbers)];
            existsDetail.Quantity = currentAccessNumbers.length;
            $this.updateAndActiveItem(oldIndex, existsDetail);
            if (!hadWarning) $this.commonService.playIncreasePipSound();
          } else {
            $this.updateAndActiveItem(existsDetail.__index);
            $this.commonService.showError(`${accessNumber} đang có trong danh sách rồi !`);
            if (!hadWarning) $this.commonService.playErrorPipSound();
          }
        }
      }

      this.isBarcodeProcessing.next(queueId + 1);
    }).catch(err => {
      this.isBarcodeProcessing.next(queueId + 1);
    });
    return true;
  }

  calculateTotal(data?: WarehouseInventoryAdjustNoteModel) {
    const currentState = this.currentState;
    let total = 0;
    const calculateData = data || currentState.data;
    for (const detail of calculateData.Details) {
      const toMoney = detail.ToMoney = (detail.Quantity || 0) * (detail.Price || 0);
      total += toMoney;
    }

    // currentState.instance.$setState({ total });
    return total;
  }

  async savePdf(self: F7ComponentContextExtend, e: any) {
    if (this.currentState.data.State !== 'APPROVED') {
      await this.save(self);
    }
    this.rootServices.iab.create(this.rootServices.apiService.buildApiUrl(this.apiPath, { id: this.currentState.data.Code, includeContact: true, includeDetails: true, includeUnit: true, renderPdf: 'download' }) as string, '_system');
  }
  async changeState(self: F7ComponentContextExtend, e: any) {
    const newState = this.currentState.data.State == 'APPROVED' ? 'UNRECORDED' : 'APPROVED';
    this.currentState.instance.$app.dialog.confirm('Bạn có muốn ' + (newState == 'APPROVED' ? 'duyệt' : 'bỏ duyệt') + ' phiếu kiểm kho `' + this.currentState.data.Title + '`', 'Thay đổi trạng thái báo giá', async () => {

      if (this.currentState.data.State !== 'APPROVED') {
        await this.save(self);
      }
      this.commonService.showPreloader(300000);
      await this.rootServices.apiService.putPromise<WarehouseInventoryAdjustNoteModel>(this.apiPath, {
        changeState: newState,
      }, [{ Code: this.currentState.data.Code }]).then(rs => {
        this.commonService.hidePreloader();
        if (newState == 'APPROVED') {
          this.commonService.showInfo('Đã duyệt phiếu kiểm kho !');
        } else {
          this.commonService.showInfo('Đã bỏ duyệt phiếu kiểm kho !');
        }
      }).catch(err => {
        this.commonService.showError(err);
        this.commonService.hidePreloader();
        return Promise.reject(err);
      });
      this.currentState.data.State = newState;
      this.setData(this.currentState.data);

    });
  }

  /** prepare for virtual list item */
  async itemBeforeInsert(element: HTMLElement, item: WarehouseInventoryAdjustNoteDetailModel) {
    const $this = this;
    const index = $(element).attr('data-index');
    item['__schema'] = this.schema;

    console.log('Before insert : ' + item.Product.text);

    // Preapre control: Price
    this.prepareControl('Price', 'Details', index);

    // Prepare control: Unit 
    const unitControl = this.currentState.instance.$app.smartSelect.create({
      el: $(element).find('[name="Unit"]')[0],
      openIn: 'popup',
      searchbar: true,
      closeOnSelect: true,
      popupSwipeToClose: true,
      scrollToSelectedItem: true,
      on: {
        close: (smartSelect: SmartSelect.SmartSelect) => {
          console.log(smartSelect);
          this.onFieldChange($this.currentState.instance, { target: { listName: 'Details', index: index, name: 'Unit', value: smartSelect.getValue() } });
        }
      }
    });
    unitControl.setValue(this.commonService.getObjectId(item.Unit));

    // Prepare control: Container
    const containerControl = this.currentState.instance.$app.smartSelect.create({
      el: $(element).find('[name="Container"]')[0],
      openIn: 'popup',
      searchbar: true,
      closeOnSelect: true,
      popupSwipeToClose: true,
      scrollToSelectedItem: true,
      on: {
        close: (smartSelect: SmartSelect.SmartSelect) => {
          console.log(smartSelect);
          this.onFieldChange($this.currentState.instance, { target: { listName: 'Details', index: index, name: 'Container', value: smartSelect.getValue() } });
        }
      }
    });
    containerControl.setValue(this.commonService.getObjectId(item.Container));

    // Prepare control: Container
    const accessNumbersControl = this.currentState.instance.$app.smartSelect.create({
      el: $(element).find('[name="AccessNumbers"]')[0],
      openIn: 'popup',
      searchbar: true,
      closeOnSelect: true,
      popupSwipeToClose: true,
      scrollToSelectedItem: true,
      on: {
        close: (smartSelect: SmartSelect.SmartSelect) => {
          console.log(smartSelect);
          this.onFieldChange($this.currentState.instance, { target: { listName: 'Details', index: index, name: 'AccessNumbers', value: smartSelect.getValue() } });
        }
      }
    });
    if (item.AccessNumbers) {
      accessNumbersControl.setValue(item.AccessNumbers.map(m => this.commonService.getObjectId(m)));
    }

    $(element).find('[name="Price"]').unbind('keyup').bind('keyup', function (e) {
      console.log(e);
      $this.onFieldChange($this.currentState.instance, { target: this });
      $(element).find('[name="ToMoney"]').val($this.currentState.data['Details'][index].Price * $this.currentState.data['Details'][index].Quantity);
      return false;
    });

    this.currentState.instance.$app.stepper.create({
      el: $(element).find('[name="Quantity"]')[0],
      min: 0,
      max: 99999999999999,
      step: 1,
      // decimalPoint: 4,
      manualInputMode: true,
      autorepeat: true,
      autorepeatDynamic: true,
      on: {
        change: (stepper, value) => {
          this.onFieldChange($this.currentState.instance, { target: { listName: 'Details', index: index, name: 'Quantity', value: value } });
          $(element).find('[name="ToMoney"]').val($this.currentState.data['Details'][index].Quantity * $this.currentState.data['Details'][index].Price);
        }
      }
    });

    // $(element).find('[name="Quantity"] input').focus(function() {
    //   $this.lastForceElement = this;
    //   // $this.lastForceIndex = item.__index;
    // })

    $(element).find('[name="ToMoney"]').unbind('keyup').bind('keyup', function (e) {
      console.log(e);
      $this.onFieldChange($this.currentState.instance, { target: this });
      $(element).find('[name="ToMoney"]').val($this.currentState.data['Details'][index].ToMoney / $this.currentState.data['Details'][index].Quantity);
      return false;
    });

    $(element).find('.detail-remove').unbind('click').bind('click', function (e) {
      $this.removeDetailItem($this.currentState.instance, e);
    });
    $(element).find('.image').unbind('click').bind('click', function (e) {
      $this.rootServices.previewPictures($this.currentState.data.Details[index].Image, 0, { addToStackButton: true });
    });

    return;
  }

  async onItemClick(self: F7ComponentContextExtend, item: WarehouseInventoryAdjustNoteDetailModel) {
    return true;
  }

  prepareItem(item: WarehouseInventoryAdjustNoteDetailModel, index?: number) {
    item.State = {
      id: item.State,
      ...this.voucherStateMap[item.State || 'NOTJUSTAPPROVED'],
    };
    // item.CreatorText = this.commonService.getObjectText(item.Creator);
    return item;
  }

  async save(self: F7ComponentContext, option?: { postParams?: any }) {
    this.currentState.data?.Details?.map(detail => {
      if (!detail.Business) {
        detail.Business = [];
      }
      if (detail.Business.findIndex(f => this.commonService.getObjectId(f) == 'GOODSINVENTORYADJUST') < 0) {
        detail.Business.push({ id: 'GOODSINVENTORYADJUST', 'text': 'Điều chỉnh hàng hóa tồn kho' });
      }
    })
    return super.save(self, option).then(rs => {
      for (const index in rs.Details) {
        const newDetail = rs.Details[index];
        newDetail.__index = index;
        this.currentState.data.Details[index].AccessNumbers = (newDetail.AccessNumbers || []).map(m => this.commonService.getObjectId(m));
        this.currentState.instance.virtualList.replaceAllItems(this.currentState.data.Details);
      }
      return rs;
    });
  }

  itemHeight = 206;
  itemTemplate = /*html*/`
  <div class="item-content block block-strong inset list-item index-{{__index}} {{js "this.__active ? 'bg-color-red text-color-white' : ''"}}" data-id="{{Uuid}}" data-index="{{__index}}" style="height: 197px; margin-top: 0; margin-bottom: 10px">
    <div class="detail-wrap">
      <div class="detail-header">
        <div class="header-label">{{js "this.Product && this.Product.Name"}}</div>
        <div class="detail-remove"><i class="icon f7-icons {{js "this.__active ? 'color-white' : 'color-red'"}}">xmark_circle_fill</i></div>
      </div>
      <div class="detail-body">
        <div class="image-wrap">
          <div class="image" data-index="0" style="{{js "this.Image && this.Image[0] && this.Image[0].Thumbnail && ('background-image: url('+this.Image[0].Thumbnail+')') || ''"}}"></div>
        </div>
        <div class="detail-content-wrap">
          <div class="list inline-labels no-hairlines">
            <ul style="background: none">
              <li class="item-content item-input">
                <div class="item-inner">
                  <div class="item-title item-label">SL</div>
                  <div class="item-input-wrap">
                    <div class="stepper stepper-fill" data-manual-input-mode="true" name="Quantity">
                      <div class="stepper-button-minus"></div>
                      <div class="stepper-input-wrap">
                        <input type="text" value="{{Quantity}}" min="0" max="999999999999999999999" step="1" class="{{js "this.__active ? 'text-color-white' : ''"}}" inputmode="decimal" name="Quantity" @keyup="onFieldChange">
                      </div>
                      <div class="stepper-button-plus"></div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="item-link smart-select" name="Unit" data-open-in="popup" data-searchbar="true" data-virtual-list="true" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-to-selected-item="true" data-list-name="UnitConversions" data-index="{{@index}}">
                <select>
                  <option value="">Chon...</option>
                  {{#js_if "this.__unitList && this.__unitList.length > 0"}}
                    {{#each __unitList}}
                    <option value="{{id}}">{{text}}</option>
                    {{/each}}
                  {{else}}
                    {{#each ../../unitList}}
                    <option value="{{id}}">{{text}}</option>
                    {{/each}}
                  {{/js_if}}
                </select>
                <div class="item-content">
                  <div class="item-inner">
                    <div class="item-title {{validate Unit __schema 'Details.Unit'}}">ĐVT (*)</div>
                    <div class="item-after {{js "this.__active ? 'text-color-white' : 'text-color-blue'"}}"></div>
                  </div>
                </div>
              </li>
              <li class="item-link smart-select" name="Container" data-open-in="popup" data-searchbar="true" data-virtual-list="true" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-to-selected-item="true" data-index="{{@index}}">
                <select>
                  <option value="">Chon...</option>
                  {{#each __containerList}}
                  <option value="{{id}}">{{text}}</option>
                  {{/each}}
                </select>
                <div class="item-content">
                  <div class="item-inner">
                    <div class="item-title {{validate Container __schema 'Details.Container'}}">Vị trí (*)</div>
                    <div class="item-after {{js "this.__active ? 'text-color-white' : 'text-color-blue'"}}"></div>
                  </div>
                </div>
              </li>
              <li class="item-link smart-select" name="AccessNumbers" data-open-in="popup" data-searchbar="true" data-virtual-list="true" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-to-selected-item="true" data-index="{{@index}}">
                <select multiple>
                  <option value="">Chon...</option>
                  {{#each AccessNumbers}}
                  <option value="{{this}}">{{this}}</option>
                  {{/each}}
                </select>
                <div class="item-content">
                  <div class="item-inner">
                    <!--<div class="item-title">Tx</div>-->
                    <div style="max-width: 100%" class="item-after {{validate AccessNumbers schema 'Details.AccessNumbers'}} {{js "this.__active ? 'text-color-white' : 'text-color-blue'"}}"></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="detail-footer">SKU: {{js "this.Product && this.Product.Sku"}}, ID: {{js "this.Product && this.Product.Code"}}</div>
    </div>
  </div>
  `;

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: 'sales-price-report-form',
      path: '/warehouse/inventory-adjust-note-form/:id',
      component: {
        template: /*html*/`
            <div class="page inventory-adjust-note-form page-form no-toolbar-x skip-keyboard-resize" data-name="inventory-adjust-note-form">
              <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a class="link back {{textColorClass}}">
                      <i class="icon icon-back"></i>
                      <span class="if-not-md {{textColorClass}}">{{backTitle}}</span>
                    </a>
                  </div>
                  <div class="title">Phiếu kiểm kho</div>
                  <div class="right">
                    <a class="link icon-only {{textColorClass}}">
                      <i class="icon f7-icons">arrowshape_turn_up_right</i>
                    </a>
                  </div>
                </div>
              </div>

              <div class="fab fab-center-bottom color-orange assistiveTouch">
                <a href="#" @click="scanProductQrCode">
                  <i class="icon f7-icons">barcode_viewfinder</i>
                </a>
              </div>

              <div class="page-content ptr-content infinite-scroll-content" @ptr:refresh="refresh" data-ptr-distance="150">
                <div class="ptr-preloader">
                  <div class="preloader"></div>
                  <div class="ptr-arrow"></div>
                </div>

                <form class="data-form">
                  <div class="list profile-form no-hairlines-md inset main-form">
                    <ul>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label">ID phiếu (*)</div>
                          <div class="item-input-wrap">
                            <input @change="onFieldChange" disabled class="field text-color-blue" name="Code" type="text" placeholder="ID phiếu, auto..."
                              value="{{data.Code}}">
                            
                          </div>
                        </div>
                      </li>
                      {{#if copyFromId}}
                      <li class="item-content">
                        <div class="item-inner">
                          <div class="item-input-wrap text-color-orange">
                            <span style="font-weight: bold; font-style: italic">Bạn đang thao tác trên phiếu copy</span>
                          </div>
                        </div>
                      </li>
                      {{/if}}
                    </ul>
                  </div>

                  <div class="block-title">Thông tin chung</div>
                  <div class="list profile-form no-hairlines-md inset main-form">
                    <ul>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label {{validate data.Title schema 'Title'}}">Tiêu đề (*)</div>
                          <div class="item-input-wrap">
                            <input @keyup="onFieldChange" class="field text-color-blue" name="Title" type="text" placeholder="Tiêu đề..."
                              value="{{data.Title}}">
                            
                          </div>
                        </div>
                      </li>

                      <li class="item-link smart-select smart-select-init smart-select-roles {{validate data.Shelf schema 'Shelf'}}" name="Shelf" data-open-in="popup" data-searchbar="true" data-virtual-list="true" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFieldChange">
                        <select>
                          <option value="">Chon...</option>
                          {{#each shelfList}}
                          <option value="{{id}}">{{text}}</option>
                          {{/each}}
                        </select>
                        <div class="item-content">
                          <div class="item-inner">
                            <div class="item-title">Kệ</div>
                            <div class="item-after text-color-blue"></div>
                          </div>
                        </div>
                      </li>

                    </ul>
                  </div>
              
                  <div class="block-title" style="margin-top: 0.5rem">Chi tiết<a @click="addDetail" data-list-name="Details" class="button button-small button-fill" style="float: right; font-size: 0.7rem; font-weight: bold">Thêm</a></div>
                  <div class="list-name thumbnail-list virtual-list inventory-adjust-note-form-virtual-list" name="Details">
                  
                  </div>
              
                  <!--<div class="block block-strong inset" style="text-align: right; font-weight: bold; font-size: 1rem;">
                    Tổng cộng: <span class="text-color-blue">{{currency total}}</span>
                  </div>-->

                  <div class="list no-hairlines-md inset" style="margin-bottom: 0.5rem">
                  <ul>
                    <li class="item-content item-input">
                      <div class="item-inner">
                        <div class="item-title item-label">Nhập số truy xuất thủ công</div>
                        <div class="item-input-wrap" style="display: flex; align-items: center;">
                          <input id="AccessNumberInput" class="field text-color-blue" name="Sku" type="text" placeholder="Số truy xuất..." @keyup="enterAccessNumber" style="text-transform: uppercase;">
                          <i class="icon f7-icons if-not-md-x link text-color-blue" @click="enterAccessNumber">arrow_up_circle_fill</i>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                  <div class="block">
                    <div class="row">
                      <a class="col-50 button  button-large button-outline link color-orange" @click="scanProductQrCode"><i class="icon f7-icons if-not-md-x">barcode_viewfinder</i> Quét mã BarCode</a>
                      <a class="col-50 button  button-large button-outline link color-orange" @click="chooseProduct"><i class="icon f7-icons if-not-md-x">cart</i> Chọn SP</a>
                    </div>
                  </div>

                  <div class="block">
                    <p class="row">
                      <a class="col-50 button button-fill button-large color-blue {{#if validates}}disabled{{/if}}"  @click="save"> Lưu lại</a>
                      <a class="col-50 button button-fill button-large color-red {{#if validates}}disabled{{/if}}" @click="savePdf">Save PDF</a>
                    </p>
                    <p class="row">
                      <a class="col-50 button button-fill button-large color-{{js "(this.data && this.data.State == 'APPROVED') ? 'orange' : 'green'"}} {{#if validates}}disabled{{/if}}" @click="changeState">{{js "(this.data && this.data.State == 'APPROVED') ? 'Bỏ duyệt' : 'Duyệt'"}}</a>
                      <a class="col-50 button button-fill button-large color-yellow {{#if validates}}disabled{{/if}}" @click="openRelativeTask">Mở task</a>
                    </p>
                  </div>

                  <div class="block-title">Người kiểm kho</div>
                  <div class="list profile-form no-hairlines-md inset main-form">
                    <ul>
                      <li class="autocomplete {{validate data.Object schema 'Object'}}" name="Object">
                        <div class="item-link item-content" href="#">
                          <div class="item-inner">
                            <div class="item-title">Người kiểm kho</div>
                            <div class="item-after text-color-blue">{{js "this.data && this.data.Object && this.data.Object.text || 'Chọn...'"}}</div>
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label {{validate data.ObjectName schema 'ObjectName'}}">Tên (*)</div>
                          <div class="item-input-wrap">
                            <input @keyup="onFieldChange" class="field text-color-blue" name="ObjectName" type="text" placeholder="Tên..."
                              value="{{data.ObjectName}}">
                            
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label">Số điện thoại (*)</div>
                          <div class="item-input-wrap">
                            <input @keyup="onFieldChange" class="field text-color-blue" name="ObjectPhone" type="text" placeholder="Số điện thoại..."
                              value="{{data.ObjectPhone}}">
                            
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label">Email (*)</div>
                          <div class="item-input-wrap">
                            <input @keyup="onFieldChange" class="field text-color-blue" name="ObjectEmail" type="text" placeholder="Email..."
                              value="{{data.ObjectEmail}}">
                            
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label">Địa chỉ (*)</div>
                          <div class="item-input-wrap">
                            <input @keyup="onFieldChange" class="field text-color-blue" name="ObjectAddress" type="text" placeholder="Địa chỉ..."
                              value="{{data.ObjectAddress}}">
                            
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div class="block-title">Ghi chú</div>
                  <div class="list profile-form no-hairlines-md main-form">
                    <div class="text-editor text-editor-init Description" name="Note" data-placeholder="Ghi chú..." @texteditor:change="onFieldChange">
                      <div class="text-editor-content" contenteditable></div>
                    </div>
                  </div>

                  <div class="block-title">Ghi chú nội bộ</div>
                  <div class="list profile-form no-hairlines-md main-form">
                    <div class="text-editor text-editor-init Description" name="SubNote" data-placeholder="Ghi chú nội bộ (không xuất hiện khi in)..." @texteditor:change="onFieldChange">
                      <div class="text-editor-content" contenteditable></div>
                    </div>
                  </div>

                  {{#if validates}}
                  <div class="block-title text-color-arange">Cảnh báo</div>
                  <div class="list inset">
                    <ul>
                      {{#each validates}}
                      <li>
                        <div class="item-content">
                          <div class="item-inner">
                            <div class="item-title">{{validates[0].label}}</div>
                            <div class="item-after text-color-orange">{{validates[0].text}}</div>
                          </div>
                        </div>
                      </li>
                      {{/each}}
                    </ul>
                  </div>
                  {{/if}}
                </form>
            
                <div class="block"><br></div>
              </div>
            </div>
        `,
        style: /*css*/`
          .page.inventory-adjust-note-form .demo-card-header-pic .card-header {
            height: calc(100vw - 2rem);
            background-size: cover;
            background-position: center;
            color: #fff;
          }

          .page.inventory-adjust-note-form .demo-card-header-pic .card-content-padding .date {
            color: #8e8e93;
          }
          .page.inventory-adjust-note-form .pictures::-webkit-scrollbar {
            display: none;
          }

          .page.inventory-adjust-note-form .card .background {
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-image: url(/assets/images/no-image-available.png);
          }

          .page.inventory-adjust-note-form .inline-labels .item-label, .inline-label .item-label, .inline-labels .item-floating-label, .inline-label .item-floating-label {
            width: auto !important;
          }

          .inventory-adjust-note-form-virtual-list ul {
            padding: 0;
          }
        `,
        data() {
          return {
            title: $this.title,
            reminder: {
              No: '234234',
            },
            data: new WarehouseInventoryAdjustNoteModel(),
            contactMemebets: [],
            // contactList: [],
            validates: null,
          };
        },
        methods: {
          async enterAccessNumber(e: any) {
            const self: F7ComponentContextExtend = this;
            const accessNumberInput = self.$el.find('#AccessNumberInput');
            const barcode: string = accessNumberInput.val().trim();

            // Sku
            if (e instanceof KeyboardEvent) {
              if (e.key != 'Enter') {
                return;
              }
            }
            $this.barcodeProcess(barcode, { type: 'ACCESS_NUMBER' });
            accessNumberInput.val('');
            // Access number case
            // if (/^127/.test(code)) {
            //   $this.barcodeProcess(code, 'ACCESS_NUMBER').then(rs => {
            //     $this.commonService.hidePreloader();
            //   }).catch(err => {
            //     $this.commonService.hidePreloader();
            //   });
            //   skuInput.val('');
            //   // $this.removeBarcodeScanner();
            //   return;
            // }
          },
          changeState(e) {
            $this.changeState(this, e);
          },
          savePdf(e) {
            $this.savePdf(this, e);
          },
          openRelativeTask(e) {
            const self: F7ComponentContextExtend = this;
            const currentState = $this.currentState;
            const voucher = currentState.data;
            if (voucher) {
              const relativeTask = voucher.RelativeVouchers && voucher.RelativeVouchers.find(f => f.type == 'CHATROOM' || f.type == 'TASK') || null;
              if (relativeTask) {
                $this.rootServices.navigate('/chat-room/' + relativeTask.id);
              } else {
                if (!voucher.Code || !voucher.Title) {
                  $this.commonService.showError('Bạn phải lưu phiếu trước và phiếu phải có tiêu đề !', { position: 'bottom' });
                  return;
                }
                self.$app.dialog.create({
                  title: 'Tạo task',
                  content: 'Bạn muốn tạo task cho phiếu này hày liên kết với task sẵn có ?',
                  verticalButtons: true,
                  buttons: [
                    {
                      text: 'Tạo mới',
                      bold: false,
                      color: 'green',
                      onClick: () => {
                        self.$f7.dialog.prompt('Mô tả cho task mới', function (description) {
                          const chatRoomData = {
                            Type: 'WORKPLACE',
                            Description: description,
                            RelativeVouchers: [
                              {
                                id: voucher.Code,
                                text: voucher.Title,
                                type: 'INVENTORYADJUST'
                              }
                            ],
                          };
                          self.$app.preloader.show();
                          $this.rootServices.apiService.postPromise<ChatRoomModel[]>('/chat/rooms', {}, [chatRoomData]).then(async rs => {
                            // Update relative vouchers for current voucher
                            currentState.data.RelativeVouchers = await $this.rootServices.apiService.getPromise<WarehouseInventoryAdjustNoteModel[]>($this.apiPath + '/' + currentState.data.Code, { includeRelativeVouchers: true }).then(rs => rs[0].RelativeVouchers);
                            currentState.instance.$setState({ data: currentState.data });

                            const contactMembers = [];
                            if (voucher.Object) {
                              contactMembers.push({
                                Type: 'CONTACT',
                                id: $this.commonService.getObjectId(voucher.Object),
                                Name: voucher.ObjectName
                              });
                            }
                            if (voucher.Contact) {
                              contactMembers.push({
                                Type: 'CONTACT',
                                id: $this.commonService.getObjectId(voucher.Contact),
                                Name: voucher.ContactName
                              });
                            }

                            if (contactMembers.length > 0) {
                              await $this.rootServices.apiService.postPromise<ChatRoomModel[]>('/chat/room-members', { chatRoom: rs[0].Code }, contactMembers).catch(err => {
                                console.log('skip for can not add cotnact', err);
                                return false;
                              });
                            }

                            console.log(rs);
                            if (rs && rs[0] && rs[0].Code) {
                              $this.commonService.navigate('/chat-room/' + rs[0].Code, {
                                context: {
                                  // backTitle: 'Workplace',
                                  title: rs[0].Description,
                                }
                              });
                              self.$app.preloader.hide();
                            }
                          }).catch(err => {
                            console.error(err);
                            self.$app.preloader.hide();
                            $this.commonService.showError(err, { position: 'bottom' });
                          });
                        }, null, voucher.Title);
                      }
                    },
                    {
                      text: 'Task sẵn có',
                      bold: false,
                      color: 'blue',
                      onClick: () => {
                        $this.commonService.navigate('/workplace/choose-parent', {
                          context: {
                            backTitle: 'Chat GUI',
                            title: 'Chọn task',
                            extendComponentId: 'choose-for-' + voucher.Code,
                            callback: async (task: ChatRoomModel) => {
                              console.log('choosed task:', task);
                              self.$app.preloader.show();
                              const taskRelativeVouchers = await $this.rootServices.apiService.getPromise<ChatRoomModel[]>('/chat/rooms/' + task.Code, { includeRelativeVouchers: true }).then(rs => rs[0].RelativeVouchers);
                              $this.rootServices.apiService.putPromise<ChatRoomModel[]>('/chat/rooms/' + task.Code, {}, [{
                                Code: task.Code, RelativeVouchers: [
                                  ...(taskRelativeVouchers || []),
                                  {
                                    id: voucher.Code,
                                    text: voucher.Title,
                                    type: 'INVENTORYADJUST',
                                  }
                                ]
                              }]).then(async rs => {

                                // Update relative vouchers for current voucher
                                currentState.data.RelativeVouchers = await $this.rootServices.apiService.getPromise<WarehouseInventoryAdjustNoteModel[]>($this.apiPath + '/' + currentState.data.Code, { includeRelativeVouchers: true }).then(rs => rs[0].RelativeVouchers);
                                currentState.instance.$setState({ data: currentState.data });

                                const contactMembers = [];
                                if (voucher.Object) {
                                  contactMembers.push({
                                    Type: 'CONTACT',
                                    id: $this.commonService.getObjectId(voucher.Object),
                                    Name: voucher.ObjectName
                                  });
                                }
                                if (voucher.Contact) {
                                  contactMembers.push({
                                    Type: 'CONTACT',
                                    id: $this.commonService.getObjectId(voucher.Contact),
                                    Name: voucher.ContactName
                                  });
                                }

                                // Add contacts to task
                                if (contactMembers.length > 0) {
                                  await $this.rootServices.apiService.postPromise<ChatRoomModel[]>('/chat/room-members', { chatRoom: task.Code }, contactMembers).catch(err => {
                                    console.log('skip for can not add cotnact', err);
                                    return false;
                                  });
                                }

                                setTimeout(() => {
                                  self.$app.preloader.hide();
                                  $this.commonService.navigate('/chat-room/' + task.Code, {
                                    context: {
                                      // backTitle: 'Workplace',
                                      title: voucher.Title,
                                    }
                                  });
                                }, 700);
                              }).catch(err => {
                                self.$app.preloader.hide();
                              });
                            },
                          }
                        });
                      }
                    },
                    {
                      text: 'Đóng',
                      bold: true,
                      color: 'red',
                      onClick: () => {

                      }
                    },
                  ]
                }).open();

              }
            }
          },
          previewPictures(e) {
            const self: F7ComponentContextExtend = this;
            const currenntState = $this.currentState;
            let index = $(e.target).closest('.list-item').data('index');
            $this.previewPictures(currenntState.data.Details[index].Image, 0);
          },
          setFeaturePicture(e) {
            const self: F7ComponentContextExtend = this;
            const currenntState = $this.currentState;
            let index = $(e.target).closest('a').data('index');
            if (typeof index != 'undefined') {
              self.$app.dialog.confirm('Bạn có muốn đặt làm hình đại diện không ?', 'Đặt hình đại diện', () => {
                currenntState.data.FeaturePicture = currenntState.data.Pictures[index];
                self.$setState({ data: currenntState.data });
              })
            }
          },
          uploadPicture(e) {
            const self: F7ComponentContextExtend = this;
            const currenntState = $this.currentState;
            $this.updateImages((progress) => {
              console.log(progress);
            }, (image) => {
              currenntState.data.Pictures.unshift(image);
              currenntState.data.FeaturePicture = image;
              self.$setState({ data: currenntState.data });

            }).then(images => {
              console.log('upload pictures success', images);
            });
          },
          addDetail(e) {
            const self: F7ComponentContextExtend = this;
            const currenntState = $this.currentState;

            $this.chooseProduct(self);

            // let listName = $(e.target).data('list-name');
            // $this.addItemForList(listName);

          },
          chooseProduct(e) {
            const self: F7ComponentContextExtend = this;
            $this.chooseProduct(self);
          },
          scanProductQrCode(e) {
            const self: F7ComponentContextExtend = this;
            const currenntState = $this.currentState;
            const systemConfigs = $this.rootServices.systemSettings$.value;

            // $this.rootServices.barcodeScanner.scan({ showTorchButton: true }).then(barcodeData => {
            //   // const data = barcodeData && barcodeData.text.split('|');
            //   const barcode = barcodeData && barcodeData.text;
            //   $this.barcodeProcess(barcode);

            // });

            $this.rootServices.scanBarcodeByMlKit().then(barcodeData => {
              let barcode = barcodeData && barcodeData.text;
              barcode = $this.rootServices.getAccessNumberFromUrl(barcode);
              $this.barcodeProcess(barcode);
            });
          },
          removeDetailItem(e) {
            return $this.removeDetailItem(this, e);
          },
          onFieldChange(e) {
            $this.onFieldChange(this, e);
          },
          async refresh(e, done) {

            done && setTimeout(() => done(), 10000);
            $this.refresh(this).then(data => {
              done && done();
            });

            return true;
          },
          save() {
            const self: F7ComponentContextExtend = this;
            const currenntState = $this.currentState;
            const relativeVouchers = self.$route.context.relativeVouchers;
            const id = currenntState.data.Code || self.$route.params['id'];

            $this.commonService.showPreloader();

            const preloaderProcess = setTimeout(() => {
              $this.commonService.hidePreloader();
            }, 15000);

            if (id === 'new') {
              if (relativeVouchers) {
                currenntState.data.RelativeVouchers = relativeVouchers;
              }
            }

            $this.save(self).then(rs => {
              $this.commonService.showInfo("Đã lưu phiếu kiểm kho");
              clearTimeout(preloaderProcess);
              $this.commonService.hidePreloader();
            }).catch(err => {
              clearTimeout(preloaderProcess);
              $this.commonService.hidePreloader();
              console.error(err);
            });
          },
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          pageInit(e, page: F7Page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;

            $this.onComponentInit({ instance: self, page: page }, self.$route.params['id'], null, page).then(curentState => {
              // self.detailDialog = self.$app.dialog.create({
              //   el: page.$el.find('.dialog-detail'),
              //   closeByBackdropClick: true,
              // });
            });

            $this.rootServices.authService.isAuthenticatedOrRefresh().pipe(take(1)).toPromise().then(() => {
              self.$setState({
                backTitle: self.$route.context && self.$route.context.backTitle || 'Back', title: self.$route.context && self.$route.context.title || 'Chat GUI',
              });
            }).catch(err => {
              console.error(err);
              $this.commonService.showError(err);
            });
          },
          // pageBeforeIn(e, page) {
          //     console.log('page before in');
          //     const self: F7ComponentContextExtend = this;
          //     self.refresh();
          // },
          pageAfterIn(e, page) {
            console.log('page after in');
            const self: F7ComponentContextExtend = this;
            self.keydownSubscription = $this.commonService.onKeydownEvent$.subscribe(event => {
              // console.log(event.key);
              $this.commonService.barcodeScanDetective(event.key, barcode => {
                console.log('barcode: ' + barcode);
                $this.barcodeProcess(barcode);
              });
            });
          },
          pageBeforeOut(e, page) {
            console.log('page before out');
            const self: F7ComponentContextExtend = this;
            // Call caback function
            if (this.$route?.context['callback']) {
              this.$route?.context['callback']($this.currentState.data, { lastAction: $this.currentState.lastAction });
            }
            (self.keydownSubscription as Subscription).unsubscribe();
          },
          // pageAfterOut(e, page) {
          //     console.log('page after out');
          // },
          // pageBeforeUnmount(e, page) {
          //     console.log('page before unmount');
          // },
          // pageBeforeRemove(e, page) {
          //     console.log('page before remove');
          // },
          pageBeforeRemove(e, page) {
            console.log('[page event] before remove', page.route.url);
            const self: F7ComponentContextExtend = this;
            // const starmtSelectRoles = self.$app.smartSelect.get('.smart-select');
            // starmtSelectRoles.destroy();
            const currentState = $this.state[self.$route.params?.id];
            if (self.$route.params?.id === 'new') {
              if (currentState && currentState.data && currentState.data.Code) {
                delete currentState.data;
              }
            }

            $this.onComponentRemove({ instance: self, page: page }, self.$route.params['id']);
          },
        },
      },
    };
  }
}
