import { CollaboratorEducationArticleModel } from 'src/app/model/collaborator.model';
import { F7ComponentContext, F7Page } from '../../../types/framework7-types';
import { Router } from 'framework7/build/core/modules/router/router';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { ComponentState, BaseComponent } from 'vendors/smart-bot-app/src/app/lib/base-component';
import { ChatRoom } from 'vendors/smart-bot-app/src/app/lib/nam-chat/chat-room';
import { ProductModel } from 'vendors/smart-bot-app/src/app/model/product.model';
import { TaskReminderModel } from 'vendors/smart-bot-app/src/app/model/task.model';
import { CommonService } from 'vendors/smart-bot-app/src/app/services/common.service';
import { RootServices } from 'vendors/smart-bot-app/src/app/services/root.services';
import * as moment from 'moment';
import { PageComponent } from '../../page.component';

export interface ComponentStateExtend extends ComponentState {
  [key: string]: any;
}

export class F7ComponentContextExtend extends F7ComponentContext {
  // messagebar?: F7Messagebar;
  // messages?: Messages.Messages;
  // images?: string[];
  responseInProgress?: boolean;
  // answers?: string[];
  // people?: { name?: string, avatar?: string }[];
  // hideToolbar?: () => void;
  // sheetToggle?: () => void;
  // deleteAttachment?: (e: MouseEvent, index: number) => void;
  // handleAttachment?: (e: MouseEvent) => void;
  // checkAttachments?: () => void;
  // sendMessage?: () => void;
  productList?: ProductModel[];
  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, reminder?: TaskReminderModel, chatRoom?: ChatRoom, reminderList$?: BehaviorSubject<TaskReminderModel[]> } };
}

/** Component group manager */
export class PageCollaboratorEducationComponent extends BaseComponent<ComponentStateExtend> {
  // states: { [key: string]: State } = {};

  title: 'Giám sát'

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
    public parentComponent: PageComponent,
  ) {
    super(rootServices);
    // // console.log('Click here to open ts file');

    this.parentComponent.eventEmitter.subscribe((e: { name: string, data: any }) => {
      switch (e.name) {
        case 'refresh':
          this.currentState?.instance?.refresh && this.currentState?.instance?.refresh();
          break;
      }
    });
  }

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      // Tab path
      path: '/collaborator/education-tab',
      // Tab id
      id: 'page-education',
      // Fill this tab content from content string
      component: {
        template: /*html*/`
          <div class="page-content infinite-scroll-content" style="padding-top: 0">
          
            <div class="block-title">Danh sách bài viết đào tạo</div>
            {{#js_if "this.eduArticleList"}}
            {{#js_if "this.eduArticleList.length > 0"}}
            <div class="list media-list workplace-list inset">
              <ul>
                {{#each eduArticleList}}
                <li data-id="{{Code}}" data-page="{{Page}}" @click="openDetails">
                  <a class="item-link item-content">
                    <div class="item-inner">
                      <div class="item-title-row">
                        <div class="item-title text-color-default description" style="font-size: 0.9rem">{{Title}}</div>
                        <!--<div class="item-after text-color-red" style="font-size: 0.8rem">
                          <span class="badge color-red unread">new</span>
                        </div>-->
                      </div>
                      <div class="item-subtitle text-color-gray">ID Đào tạo: {{Code}}</div>
                      <div class="item-subtitle text-color-gray">ID Sản phẩm: {{Product}} - {{ProductName}}</div>
                      <div class="item-subtitle text-color-gray">Ngày tạo: {{date DateOfCreated 'shortDate'}}</div>
                      <div class="item-text" style="max-height: initial"><span class="description">{{Summary}}</span></div>
                    </div>
                  </a>
                </li>
                {{/each}}
              </ul>
              {{#if infiniteLoadLast}}
              <div style="text-align: center" class="text-color-gray">end</div>
              {{else}}
              <!--<div class="block text-align-center">
                <div class="preloader"></div>
              </div>-->
              {{/if}}
            </div>
            {{else}}
            <div class="block block-strong inset bg-color-orange text-color-white">
              Chưa có phiếu chốt thưởng nào !<br>
              Nếu bạn đã chốt chiết khấu theo tuần mà vẫn chưa được chốt thưởng thì có thể KPI của bạn chưa đạt, hãy liên hệ với
              trang <a @click="gotoPage"><b>{{js "this.pageInfo && this.pageInfo.Name"}}</b></a> để được xem xét lại.<br>
              Xin cám ơn !
            </div>
            {{/js_if}}
            {{else}}
            <div class="list media-list workplace-list inset">
              <ul>
                {{#each ghostItems}}
                <li class="skeleton-text skeleton-effect-blink">
                  <a class="item-link item-content">
                    <div class="item-inner">
                      <div class="item-title-row">
                        <div class="item-title text-color-default description" style="font-size: 0.9rem"> lobortis lobortis
                          lobortis lobortis lobortis lobortis lobortis</div>
                        <div class="item-after text-color-blue" style="font-size: 0.8rem">
                          <span class="badge color-green unread">9</span>
                        </div>
                      </div>
                      <div class="item-subtitle text-color-gray">
                        <div class="chat-list-item-label"><i class="icon f7-icons">timer_fill</i>lobortis lobortis lobortis</div>
                      </div>
                      <div class="item-subtitle text-color-gray">
                        <div class="chat-list-item-label">
                          <i class="icon f7-icons">person_crop_square_fill</i>lobortis lobortis
                        </div>
                        <div class="text-color-blue lastUpdateMoment" style="float: right">lobortis&nbsp;₫
                        </div>
                      </div>
                      <div class="item-text" style="clear: both; max-height: initial;">
                        <span class="lastMessageText">
                          <div class="chip">
                            <div class="chip-label text-color-gray" style="font-weight: bold">lobortis lobortis</div>
                          </div>
                        </span>
                      </div>
                    </div>
                  </a>
                </li>
                {{/each}}
              </ul>
            </div>
            {{/js_if}}
          
          </div>
          `,
        style: /*css*/`
          #page-education .list .description {
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
          }
        `,
        data() {
          return {
            title: $this.title,
            backTitle: 'Back',
            pageInfo: {},
            ghostItems: [1, 2],
          };
        },
        methods: {
          gotoPage(e) {
            const self: F7ComponentContextExtend = this;
            $this.rootServices.navigate("/page/" + self.$route.params.pageId + "/");
          },
          openDetails(e) {
            const self: F7ComponentContextExtend = this;
            const pageId = self.$(e.target).closest('li').data('page').toLowerCase();
            const id = self.$(e.target).closest('li').data('id').split('_')[0].toLowerCase();
            $this.rootServices.navigate(`/${pageId}/ctvbanhang/edu/${id}`, {context: {
              page: self.pageInfo?.Code,
              pageInfo: self.pageInfo
            }});
          },
          subscribe(e) {
            const self: F7ComponentContextExtend = this;
            const id = self.$(e.target).closest('li').data('id');
            self.$route.context['onChoose'] && self.$route.context['onChoose'](self.productList.find(f => f.Code === id));
          },
          chooseOne(e) {
            const self: F7ComponentContextExtend = this;
            const id = self.$(e.target).closest('li').data('id');
            self.$route.context['onChoose'] && self.$route.context['onChoose'](self.productList.find(f => f.Code === id));
          },
          chooseProduct(e) {

          },
          async refresh(e, done) {
            const self: F7ComponentContextExtend = this;
            const pageId = self.$route.params?.id;
            let doneTimeout = null;
            if (done) {
              doneTimeout = setTimeout(() => {
                done();
              }, 10000);
            };

            try {
              const pageInfo = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/pages/' + pageId, {}).then(rs => rs[0]);
              const eduArticleList = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/education-articles', {
                eq_Page: pageId,
                limit: 10,
                sort_Id: 'desc',
                offset: self.infiniteOffset,
              });

              // console.log(self.searchBar);

              // const products = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/products', {
              //   includeCategories: true,
              //   includeGroups: true,
              //   filterByLevelAndBadge: true,
              //   sort_Id: true,
              //   page: pageId,
              //   search: self?.searchBar?.query && encodeURIComponent(self.searchBar.query) || '',
              //   includeFeaturePicture: true,
              //   includeUnit: true,
              //   offset: 0,
              //   limit: 40,
              //   ...self.filter,
              // }).then(products => {
              //   // self.$$(page.el).find('.page-content').scrollTo(0, 0);
              //   return products.map(t => $this.preapreProduct(t));
              // });

              self.$setState({
                eduArticleList: eduArticleList.map(item => $this.preapreProduct(item)),
                // infiniteLoadLast: products.length === 0,
                pageInfo: pageInfo,
                // lastUpdateMoment: moment(Date.now()).fromNow(),
              });
              // self.infiniteOffset = 10;

            } catch (err) {
              console.error(err);
            }

            if (done) done();
            return true;
          },
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          tabInit(e, page: F7Page) {
            console.log('tab init');
            const self: F7ComponentContextExtend = this;
            const pageId = self.$route.params?.id;
            self.$setState({ pageId: pageId, pageInfo: $this.parentComponent?.currentState?.instance?.pageInfo });
            // Loading flag
            let allowInfinite = true;
            self.infiniteOffset = 0;

            $this.parentComponent.setActiveTab('page-education');
            $this.parentComponent.currentState.instance.$setState({hideTabBar: false});

            self.refresh();
            self.searchBar = self.$app.searchbar.create({
              el: '.product-list-searchbar',
              backdrop: true,
              backdropEl: '.product-list-backdrop',
              searchContainer: '.list',
              searchIn: '.item-text',
              customSearch: true,
              on: {
                search(sb, query, previousQuery) {
                  $this.commonService.takeUntil('product-search', 600).then(() => {
                    console.log(query, previousQuery);
                    $this.rootServices.apiService.getPromise<any[]>('/collaborator/education-articles', {
                      search: encodeURIComponent(query),
                      limit: 10,
                      offset: 0,
                      page: self.pageInfo?.Code,
                      ...self.filter,
                    }).then(products => {
                      self.$$(page.el).find('.page-content').scrollTo(0, 0);
                      self.$setState({ productList: products.map(t => $this.preapreProduct(t)), infiniteLoadLast: products.length === 0 });
                      self.infiniteOffset = 0;
                    }).catch(err => {
                      console.error(err);
                      $this.commonService.showError(err);
                    });
                  });
                }
              }
            });

            // self.$$(page.el).find('.infinite-scroll-content').on('infinite', function () {
            //   // Exit, if loading in progress
            //   if (!allowInfinite) return;

            //   // Set loading flag
            //   allowInfinite = false;
            //   // Last loaded index
            //   var lastItemIndex = self.$$('.list li').length;

            //   // Emulate 1s loading
            //   $this.rootServices.apiService.getPromise<any[]>('/collaborator/education-articles', {
            //     eq_Page: pageId,
            //     limit: 10,
            //     offset: self.infiniteOffset,
            //   }).then(eduArticleList => {
            //     self.$setState({
            //       eduArticleList: self.eduArticleList.concat(eduArticleList),
            //       infiniteLoadLast: eduArticleList.length === 0,
            //     });
            //     self.infiniteOffset += 10;
            //   }).catch(err => {
            //     console.error(err);
            //     $this.commonService.showError(err);
            //   });
            // });

            $this.onComponentInit({ instance: self }, pageId + '_edutcation');
          },
          tabMounted(e, page) {
            console.log('tab before mount');
          },
          tabBeforeRemove(e, page) {
            console.log('[tab event] before remove', page?.route?.url);
            const self: F7ComponentContextExtend = this;
            const pageId = self.$route.params?.id;
            $this.onComponentRemove({ instance: self }, pageId);
            // const starmtSelectRoles = self.$app.smartSelect.get('.smart-select');
            // starmtSelectRoles.destroy();
          },
        },
      },
    };
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  preapreProduct(p: CollaboratorEducationArticleModel) {
    // if (p.Categories && p.Categories.length > 0) {
    //   p.CategoriesText = p.Categories.map(m => m.text).join(', ');
    // }
    // if (p.FeaturePicture && p.FeaturePicture.Thumbnail) {
    //   p.FeaturePictureThumbnail = p.FeaturePicture.Thumbnail;
    // }
    // const taxValue = p.Tax && p.Tax.Tax || 0;
    // p.ToMoney = parseInt(p.Price as any) + parseInt(p.Price as any) * parseInt(taxValue as any) / 100;
    // p.ToMoneyText = this.rootServices.currencyPipe.transform(p.ToMoney, 'VND');;
    // p.PriceText = this.rootServices.currencyPipe.transform(p.Price, 'VND');
    // if (p.OriginPrice) {
    //   p.OriginPriceText = this.rootServices.currencyPipe.transform(p.OriginPrice, 'VND');
    // }
    p.Summary = this.rootServices.getTextVersion(p.Summary);
    return p;
  }
}
