import { Router } from "framework7/build/core/modules/router/router";
import { BaseListComponent, F7ListComponentContext, ListComponentStateExtend } from "../../../lib/base-list-component";
import { ChatRoom } from "../../../lib/nam-chat/chat-room";
import { ProductModel } from "../../../model/product.model";
import { CommonService } from "../../../services/common.service";
import { RootServices } from "../../../services/root.services";
import { F7Page } from "../../../types/framework7-types";
import { ContactListComponent } from "../../contact/contact-list";
import { ContactGroupModel, ContactModel } from "../../../model/contact.model";
import { Template7 } from 'framework7/build/core/framework7.esm';


declare const $: any;

// declare const $: any;
export interface ComponentStateExtend extends ListComponentStateExtend<ContactModel> {
  [key: string]: any;
  instance?: F7ComponentContextExtend;
}

export interface F7ComponentContextExtend extends F7ListComponentContext<ContactModel> {
  responseInProgress?: boolean;
  listItems?: ProductModel[];
  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, chatRoom?: ChatRoom } };
}

/** Component group manager */
export class CollaboratorPublisherListComponent extends ContactListComponent {

  namespace: string = 'collaborator-publisher-list';
  path = '/collaborator/publisher-list';
  title = 'Cộng tác viên';
  apiPath = '/collaborator/publishers';
  idKey = 'Id';
  limit = 20;

  filterSchema = {
    Page: {
      type: 'smart-select',
      // dataType: 'multiple'
    },
    Groups: {
      type: 'smart-select',
      dataType: 'multiple'
    },
  };

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
  ) {
    super(rootServices, commonService);

    this.voucherStateMap['INQUEUE'] = {
      text: 'hàng đợi',
      color: 'color-orange',
      icon: 'square_stack_3d_down_right_fill'
    };
  }

  onF7pageRemove(chatRoomId: string) {
  }

  async onComponentInit(state: ComponentStateExtend, index: string, asCase?: string, f7Page?: F7Page) {

    // Load static data
    state.instance.groupList = await this.rootServices.apiService.getPromise<ContactGroupModel[]>('/contact/groups', { includeIdText: true, limit: 'nolimit' });
    state.instance.pageList = await this.rootServices.apiService.getPromise<ContactGroupModel[]>('/collaborator/pages', { onlyIdText: true, eq_State: 'ACTIVE', limit: 'nolimit' });
    state.filter = {
      Page: state.instance.pageList[0].id
    };
    state.instance.$setState({
      groupList: state.instance.groupList,
    });

    return super.onComponentInit(state, index, asCase, f7Page).then(async currentState => {

      currentState.instance.$setState({
        pageList: state.instance.pageList,
      });


      //   const control = currentState.instance.$app.smartSelect.get(currentState.instance.$el.find('.smart-select[name="Page"]')[0] as any);
      //   await this.commonService.waitFor(100, 10, async () => {
      //     const element = $(currentState.instance.el).find('.filter [name="Page"]');
      //     if (element.length < 0) return false;
      //     await this.commonService.waiting(100);
      //     const field = state.instance.$app.smartSelect.get(element[0]);
      //     if (field) {
      //         field.setValue(state.instance.pageList[0].id);
      //         return true;
      //     }
      //     return false;
      // });

      return currentState;
    });
  }

  prepareItem(item: ContactModel, index?: number) {
    const result = super.prepareItem(item, index);
    item.Title = this.commonService.getObjectText(item) || item.Name;
    item.Subtitle = 'Chăm sóc CTV: ' + this.commonService.getObjectText(item.Manager);
    if (!item.Avatar) {
      if (item.Publisher) {
        item.Avatar = 'https://appsv1.probox.vn/v3/user/users/' + item.Publisher + '/avatar';
      }
    }
    return result;
  }

  async getList(self?: F7ComponentContextExtend, extParams?: any): Promise<ContactModel[]> {
    const $this = this;
    return this.rootServices.apiService.getPromise<ContactModel[]>(this.apiPath, {
      includeCreator: true,
      includeIdText: true,
      sort_Name: 'desc',
      // sort_Id: 'desc',
      ...$this.parseFilter($this.currentState?.filter),
      ...extParams,
    }).then(list => {
      return list;
    }).catch(err => {
      console.error(err);
      this.commonService.showError(err);
      return Promise.reject(err);
    });
  }

  itemBeforeInsert(element: HTMLElement, item: ContactModel): void {
    const $this = this;
    $(element).find('.click2call').click(() => {
      // this.click2call(item.Id);
      // $this.rootServices.iab.create('tel:' + item.Phone, '_system');
      $this.rootServices.copyTextToClipboard(item.Phone);
      return false;
    });
    return super.itemBeforeInsert(element, item);
  }

  async onItemClick(self: F7ComponentContextExtend, item: ContactModel) {
    const id = item[this.idKey];

    this.rootServices.navigate('/collaborator/publisher-form/' + id, {
      context: {
        backTitle: this.title,
        textColorClass: self.textColorClass,
        callback: (data: ContactModel, state: any) => {
          console.log('Callback data: ', data);
          // this.refresh();
          if (state?.lastAction == 'UPDATE_SUCCESS') {
            if (data?.Code) {
              const index = self.virtualList.items.findIndex(f => f.Code == item.Code);
              if (index > -1) {
                self.virtualList.replaceItem(index, this.prepareItem(data));
              }
            }
          }
        }
      }
    });
  }


  click2call(id) {
    const $this = this;
    const self: F7ComponentContextExtend = this.currentState.instance;
    // const id = self.$(e.target).closest('li').data('id') || self.$(e.target).data('id');

    self.$app.dialog.create({
      title: 'Click2Call',
      text: 'Tôi sẽ gọi tới số nội bộ của bạn trước, nếu offline tôi sẽ gọi qua số cá nhân, hãy kiểm tra app GS wave đã online chưa hoặc bạn đang ở kế bên điện thoại của mình !',
      buttons: [
        {
          text: 'Đóng',
          // color: 'red',
          onClick() {
          },
        },
        {
          text: 'Gọi',
          color: 'red',
          onClick() {
            $this.commonService.showInfo('Đang gọi tới số của bạn...', { timeout: 30000 });
            $this.rootServices.apiService.putPromise('/collaborator/publishers/' + id, { click2call: true }, [{ Id: id }]).then(rs => {
              console.log(rs);
              $this.commonService.showInfo('Đang chuyển cuộc gọi...', { timeout: 30000 });
            }).catch(err => {
              console.error(err);
              $this.commonService.showError('Lỗi kết nối cuộc gọi');
            });
          },
        }
      ]
    }).open();
  }

  itemHeight = 70;
  itemTemplate = /*html*/`
    <li class="swipeout" data-id="{{Id}}" style="height: ${this.itemHeight}px; overflow: hidden">
      <a href="#" class="item-link item-content swipeout-content">
        <div class="item-media">
          <div class="avatar">
              <div style="background-image: url({{image Avatar}})"></div>
          </div>
        </div>
        <div class="item-inner">
          <div class="item-title-row">
            <div class="item-title">{{Title}}</div>
          </div>
          <div class="item-text probox-link text-color-default"><img src="assets/icon/probox.ico"> ProBox.vn/{{Publisher}}</div>
          <!--<div class="item-text">{{Subtitle}}</div>-->
        </div>
      </a>
      <div class="swipeout-actions-right">
      <a href="#" class="click2call color-red swipeout-overswipe">Copy SĐT</a>
    </div>
    </li>
  `;

  get f7Component(): Router.RouteParameters {
    const $this = this;
    const f7Component = super.f7Component;
    return {
      ...f7Component,
      component: {
        ...f7Component.component,
        template: /*html*/`
        <div class="page .${$this.namespace} no-toolbar-x ${$this.namespace}" data-name="${$this.namespace}">
          <div class="navbar">
            <div class="navbar-bg"></div>
            <div class="navbar-inner sliding">
              <div class="left">
                <a class="link back {{textColorClass}}">
                  <i class="icon icon-back"></i>
                  <span class="if-not-md {{textColorClass}}">{{js "this.backTitle || 'Back'"}}</span>
                </a>
              </div>
              <div class="title">${this.title}</div>
              <div class="right">
                <!-- Link to enable searchbar -->
                <a class="link icon-only searchbar-enable {{textColorClass}}" data-searchbar=".${$this.namespace}-searchbar">
                  <i class="icon f7-icons if-not-md">search</i>
                  <i class="icon material-icons md-only">search</i>
                </a>
              </div>
              <!-- Searchbar is a direct child of "navbar-inner" -->
              <form class="searchbar ${$this.namespace}-searchbar searchbar-expandable">
                <div class="searchbar-inner">
                  <div class="searchbar-input-wrap">
                    <input type="search" placeholder="Search" />
                    <i class="searchbar-icon"></i><span class="input-clear-button"></span>
                    
                  </div>
                  <span class="searchbar-disable-button {{textColorClass}}">Hủy</span>
                </div>
              </form>
            </div>
          </div>
          <div class="page-content ptr-content infinite-scroll-content" @ptr:refresh="refresh">
            <div class="ptr-preloader">
              <div class="preloader"></div>
              <div class="ptr-arrow"></div>
            </div>
            <div class="searchbar-backdrop ${$this.namespace}-backdrop"></div>

            <div class="block-title" style="margin-top: 2rem">Bộ lọc <a style="float: right" @click="resetFilter"
            class="text-color-red">đặt lại</a></div>
            <div class="block block-strong inset filter" style="padding: 0; overflow: hidden">
              <div class="list accordion-list inline-labels no-hairlines" style="margin: 0;">
                <ul>
                  <li class="item-link smart-select smart-select-init" name="Page" data-open-in="popup" data-searchbar="false" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFilterFieldChange">
                    <select>
                      <option value="">--</option>
                      {{#each pageList}}
                      <option value="{{id}}">{{text}}</option>
                      {{/each}}
                    </select>
                    <div class="item-content">
                      <div class="item-inner">
                        <div class="item-title">Trang</div> 
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div class="block-title">Danh sách ${this.title}
              <a class="button button-small button-fill tab-link" @click="showRefLink" style="float: right; font-size: 0.7rem; font-weight: bold; margin-right: 0.5rem">Link tuyển CTV</a>
            </div>
            <div class="virtual-list list media-list ${$this.namespace}-virtual-list inset list-complex-info">
              
            </div>
            {{#if infiniteLoadLast}}
                <div style="text-align: center" class="text-color-gray">end</div>
            {{else}}
                <div class="preloader color-blue infinite-scroll-preloader">
                    <span class="preloader-inner">
                        <span class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                            class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                            class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                            class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                            class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                            class="preloader-inner-line"></span><span class="preloader-inner-line"></span>
                    </span>
                </div>
            {{/if}}
        
            <div class="block"><br></div>
        
          </div>
        </div>
        `,
        style: /*css*/`
        .page.${this.namespace} .item-text.probox-link {
          display: flex;
          align-items: center;
        }
        .page.${this.namespace} .item-text.probox-link img {
          width: 1.1rem;
          height: 1.1rem;
          margin-right: 0.25rem;
        }
        .page.${this.namespace} .media-list .item-media, 
        .page.${this.namespace} li.media-item .item-media {
          align-self: center;
        }
        .page.${this.namespace} .list-complex-info ul li .item-title {
          height: 1.6rem;
        }
        .page.${this.namespace} .${this.namespace}-virtual-list .item-media div.avatar {
            border-radius: 50%;
            overflow: hidden;
            width: 3rem;
            height: 3rem;
            background-image: url(assets/icon/contact-icon.png);
            background-repeat: no-repeat; 
            background-size: cover;
            background-position: center;
        }
        .page.${this.namespace} .${this.namespace}-virtual-list .item-media div.avatar div {
            width: 3rem;
            height: 3rem;
            background-repeat: no-repeat; 
            background-size: cover;
            background-position: center;
        }
        `,
        methods: {
          ...f7Component.component['methods'],
          async showRefLink() {
            const self: F7ComponentContextExtend = this;
            const pageId = $this.commonService.getObjectId($this.currentState.filter['Page']);
            $this.rootServices.apiService.putPromise('/collaborator/pages/' + pageId, { genrerateRegisterRefLink: true }, [{ Code: pageId }]).then(rs => {

              let template = Template7.compile(/*html*/`
              <div style="text-align: left">
                <a class="ref-link" data-href="{{SalerRefLink}}">{{SalerRefLink}}</a><br>
              </div>
              <br>
              `);
              const dialog = $this.currentState.instance.$app.dialog.create({
                title: 'Link tuyển CTV Bán Hàng',
                text: '',
                content: template({
                  PageLink: rs['BmRefLink'],
                  SalerRefLink: rs['SalerRefLink']
                }),
                buttons: [
                  {
                    text: 'Trở về',
                    bold: true,
                    color: 'red'
                  },
                ],
                verticalButtons: true,
              });
              dialog.open();

              $(dialog.el).find('a').click(function () {
                $this.rootServices.openExtenalLink($(this).attr('data-href'));
              });

            });
          },
        },
        // on: {
        //   ...f7Component.component['on'],
        //   pageInit(e, page: F7Page) {
        //     console.log('page init');
        //     const self: F7ComponentContextExtend = this;
        //     // Loading flag
        //     $this.onComponentInit({ instance: self }, 'main', null, page);
        //   },
        //   pageBeforeOut(e, page) {
        //     const self: F7ComponentContextExtend = this;
        //     console.log('page before out');
        //     $this.onBeforeChangedState({ instance: self, page, scrollPosition: $(page.el).find('.page-content').scrollTop() }, self.componentIndex);
        //   },
        // }
      }
    };
    // return {
    //   name: this.namespace,
    //   path: this.path,
    //   component: {
    //     template: /*html*/`
    //         <div class="page no-toolbar-x ${$this.namespace}" data-name="${$this.namespace}">
    //           <div class="navbar">
    //             <div class="navbar-bg"></div>
    //             <div class="navbar-inner sliding">
    //               <div class="left">
    //                 <a class="link back {{textColorClass}}">
    //                   <i class="icon icon-back"></i>
    //                   <span class="if-not-md {{textColorClass}}">{{js "this.backTitle || 'Back'"}}</span>
    //                 </a>
    //               </div>
    //               <div class="title">${this.title}</div>
    //               <div class="right">
    //                 <!-- Link to enable searchbar -->
    //                 <a class="link icon-only searchbar-enable {{textColorClass}}" data-searchbar=".${$this.namespace}-searchbar">
    //                   <i class="icon f7-icons if-not-md">search</i>
    //                   <i class="icon material-icons md-only">search</i>
    //                 </a>
    //               </div>
    //               <!-- Searchbar is a direct child of "navbar-inner" -->
    //               <form class="searchbar ${$this.namespace}-searchbar searchbar-expandable">
    //                 <div class="searchbar-inner">
    //                   <div class="searchbar-input-wrap">
    //                     <input type="search" placeholder="Search" />
    //                     <i class="searchbar-icon"></i><span class="input-clear-button"></span>

    //                   </div>
    //                   <span class="searchbar-disable-button {{textColorClass}}">Hủy</span>
    //                 </div>
    //               </form>
    //             </div>
    //           </div>
    //           <div class="page-content ptr-content infinite-scroll-content" @ptr:refresh="refresh">
    //             <div class="ptr-preloader">
    //               <div class="preloader"></div>
    //               <div class="ptr-arrow"></div>
    //             </div>
    //             <div class="searchbar-backdrop ${$this.namespace}-backdrop"></div>

    //             <!--<div class="action-bar block block-strong inset">
    //               <div class="row">
    //                 <div class="col-25 tablet-15 action-bar-item text-color-gray link goto" @click="goto" data-url="/sales/goods-list">
    //                   <div class="demo-icon-icon"><i class="icon f7-icons">cube_box</i></div>
    //                   <div class="action-bar-item-label">Bảng giá</div>
    //                 </div>
    //                 <div class="col-25 tablet-15 action-bar-item text-color-gray link goto" @click="goto" data-url="/sales/price-report-list">
    //                   <div class="demo-icon-icon"><i class="icon f7-icons">app_fill</i></div>
    //                   <div class="action-bar-item-label">Báo giá</div>
    //                 </div>
    //                 <div class="col-25 tablet-15 action-bar-item text-color-gray link goto" @click="goto" data-url="/warehouse/inventory-adjust-note-list">
    //                   <div class="demo-icon-icon"><i class="icon f7-icons">app</i></div>
    //                   <div class="action-bar-item-label">Nhóm</div>
    //                 </div>
    //                 <div class="col-25 tablet-15 action-bar-item text-color-gray link goto" @click="goto" data-url="/warehouse/book-form/current">
    //                   <div class="demo-icon-icon"><i class="icon f7-icons">app</i></div>
    //                   <div class="action-bar-item-label">Đơn vị tính</div>
    //                 </div>
    //               </div>
    //             </div>-->

    //             <div class="block-title" style="margin-top: 2rem">Bộ lọc <a style="float: right" @click="resetFilter"
    //             class="text-color-red">đặt lại</a></div>
    //             <div class="block block-strong inset filter" style="padding: 0; overflow: hidden">
    //               <div class="list accordion-list inline-labels no-hairlines" style="margin: 0;">
    //                 <ul>
    //                   <li class="item-link smart-select smart-select-init" name="Groups" data-open-in="popup" data-searchbar="false" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFilterFieldChange">
    //                     <select multiple>
    //                       <option value="">--</option>
    //                       {{#each groupList}}
    //                       <option value="{{id}}">{{text}}</option>
    //                       {{/each}}
    //                     </select>
    //                     <div class="item-content">
    //                       <div class="item-inner">
    //                         <div class="item-title">Nhóm</div> 
    //                       </div>
    //                     </div>
    //                   </li>
    //                 </ul>
    //               </div>
    //             </div>

    //             <div class="block-title">Danh sách ${this.title}
    //               <a class="button button-small button-fill tab-link" @click="openContact" style="float: right; font-size: 0.7rem; font-weight: bold">+ Mới</a>
    //             </div>
    //             <div class="virtual-list list media-list ${$this.namespace}-virtual-list inset list-complex-info">

    //             </div>
    //             {{#if infiniteLoadLast}}
    //                 <div style="text-align: center" class="text-color-gray">end</div>
    //             {{else}}
    //                 <div class="preloader color-blue infinite-scroll-preloader">
    //                     <span class="preloader-inner">
    //                         <span class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
    //                             class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
    //                             class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
    //                             class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
    //                             class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
    //                             class="preloader-inner-line"></span><span class="preloader-inner-line"></span>
    //                     </span>
    //                 </div>
    //             {{/if}}

    //             <div class="block"><br></div>

    //           </div>
    //         </div>
    //     `,
    //     style: /*css*/`
    //       .${$this.namespace} .find-order {
    //         font-size: 16px;
    //         font-weight: bold;
    //       }
    //       .${$this.namespace} .action-bar {
    //         color: var(--f7-block-strong-text-color);
    //         padding-top: var(--f7-block-padding-vertical);
    //         padding-bottom: var(--f7-block-padding-vertical);
    //         background-color: var(--f7-block-strong-bg-color);
    //       }
    //       .${$this.namespace} .virtual-list i.f7-icons {
    //         font-size: 16px;
    //       }
    //       .${$this.namespace} .list-complex-info ul li .item-title .item-title-text {
    //         -webkit-line-clamp: 1;
    //       }
    //       .${$this.namespace} .list-complex-info ul li .item-title .item-title-text {
    //         -webkit-line-clamp: 1;
    //       }
    //       .${$this.namespace} .list-complex-info ul li .item-title {
    //         height: 18px;
    //       }
    //     `,
    //     data() {
    //       return {
    //         title: $this.title,
    //         // reminder: {
    //         //   No: '234234',
    //         // },
    //         priceReport: { Details: [] },
    //         filter: {},
    //         processMap: $this.rootServices.collaboratorService.processMap,
    //       };
    //     },
    //     methods: {
    //       reportCurrentFilterToPdf() {
    //         $this.rootServices.iab.create($this.rootServices.apiService.buildApiUrl($this.apiPath, {
    //           includeCreator: true,
    //           includeRelativeVouchers: true,
    //           sort_DateOfOrder: 'desc',
    //           ...$this.parseFilter($this.currentState?.filter),
    //           type: 'pdf'
    //         }) as string, '_system');
    //       },
    //       resetFilter(e) {
    //         $this.resetFilter(this, e);
    //       },
    //       onFilterFieldChange(e) {
    //         $this.onFilterFieldChange(this, e);
    //       },
    //       // Price report
    //       openContact(e, id?: string) {
    //         const self: F7ComponentContextExtend = this;
    //         id = id || self.$(e.target).closest('li').data('id') || 'new';

    //         // if ($this.commonService.frameSocket.isFrameMode && id && id !== 'new') {
    //         //   $this.commonService.frameSocket.emit('request-open-voucher', { id: id, type: 'PRICEREPORT' });
    //         // } else {
    //         $this.rootServices.navigate('/contact/contact-form/' + id, {
    //           context: {
    //             backTitle: $this.title,
    //             textColorClass: self.textColorClass,
    //             // memberInfo: $this.currentState.chatRoom.memberList$.getValue().find(f => f.id === id),
    //             // chatRoom: self.$route.context.chatRoom,
    //             // reminderList$: self.reminderList$,
    //             callback: (data: ContactModel, state: any) => {
    //               console.log('Callback data: ', data);
    //               if (state?.lastAction == 'CREATE_SUCCESS') {
    //                 self.refresh();
    //               }
    //             }
    //           }
    //         });
    //         // }
    //       },
    //       goto(e) {
    //         const self: F7ComponentContextExtend = this;
    //         const url = self.$(e.target).closest('.goto').data('url');
    //         $this.rootServices.navigate(url);
    //       },
    //       chooseOne(e) {
    //         const self: F7ComponentContextExtend = this;
    //         const id = self.$(e.target).closest('li').data('id');
    //         self.$route.context['onChoose'] && self.$route.context['onChoose'](self.listItems.find(f => f.Code === id));
    //       },
    //       chooseProduct(e) {

    //       },
    //       async refresh(e, done) {
    //         const self: F7ComponentContextExtend = this;

    //         $this.refresh(self).then(rs => done && done());

    //         return true;
    //       },
    //     },
    //     on: {
    //       // pageMounted(e, page) {
    //       //     console.log('page mounted');
    //       // },
    //       pageInit(e, page: F7Page) {
    //         console.log('page init');
    //         const self: F7ComponentContextExtend = this;
    //         // Loading flag
    //         let allowInfinite = true;
    //         $this.onComponentInit({ instance: self }, 'main', null, page);
    //       },
    //       // pageBeforeIn(e, page) {
    //       //     console.log('page before in');
    //       //     const self: F7ComponentContextExtend = this;
    //       //     self.refresh();
    //       // },
    //       // pageAfterIn(e, page) {
    //       //     console.log('page after in');
    //       // },
    //       // pageBeforeOut(e, page) {
    //       //     console.log('page before out');
    //       // },
    //       pageAfterOut(e, page) {
    //         console.log('page after out');
    //       },
    //       // pageBeforeUnmount(e, page) {
    //       //     console.log('page before unmount');
    //       // },
    //       // pageBeforeRemove(e, page) {
    //       //     console.log('page before remove');
    //       // },
    //       pageBeforeRemove(e, page) {
    //         console.log('[page event] before remove', page.route.url);
    //         const self: F7ComponentContextExtend = this;
    //         // const starmtSelectRoles = self.$app.smartSelect.get('.smart-select');
    //         // starmtSelectRoles.destroy();
    //       },
    //     },
    //   },
    // };
  }
}
