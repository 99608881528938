import { SalesMasterPriceTable } from './sales.model';
import { ProductModel } from './product.model';
import { Model } from './model';
export class CommercePosOrderModel extends Model {
    [key: string]: any;
    _id?: string;
    IsDebt?: boolean = false;
    Code?: string;
    Object?: string;
    ObjectName?: string;
    ObjectPhone?: string;
    ObjectEmail?: string;
    ObjectAddress?: string;
    ObjectIdentified?: string;
    Contact?: string;
    ContactName?: string;
    ContactPhone?: string;
    ContactEmail?: string;
    ContactAddress?: string;
    ContactIdentified?: string;
    DateOfReport?: string;
    DateOfCreated?: string;
    Creator?: string;
    DeliveryAddress?: string;
    Title?: string;
    Note?: string;
    SubNote?: string;
    PriceTable?: SalesMasterPriceTable;
    Details?: CommercePosOrderDetailModel[] = [];

    constructor(properties?: CommercePosOrderModel) {
        super(properties);
        this._id = 'new';
        // this.IsDebt = false;
        // this.Details = [];
    }
}

export class CommercePosOrderDetailModel extends Model {
    [key: string]: any;
    SystemUuid?: number;
    No?: number;
    Product?: ProductModel;
    Description?: string;
    Unit?: { id?: string, text?: string, [key: string]: any };
    Quantity?: number = 1;
    Price?: number;
    Tax?: { id?: string, text?: string, Tax?: number, [key: string]: any };
    ToMoney?: number;
    AccessNumbers?: string[];

    constructor(properties?: CommercePosOrderDetailModel) {
        super(properties);
        // this.Quantity = 1;
        // this.Tax = { id: 'VAT10', text: '+ 10% VAT', Tax: 10 };
    }
}
