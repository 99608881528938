import { CommerceOrderPrintComponent } from './../../../vendors/smart-bot-app/src/app/components/commerce-pos/gui/order-print';
import { Model } from 'vendors/smart-bot-app/src/app/model/model';
import { F7ListComponentContext, ListComponentStateExtend } from 'vendors/smart-bot-app/src/app/lib/base-list-component';
import { Router } from 'framework7/build/core/modules/router/router';
import { ProductModel } from 'vendors/smart-bot-app/src/app/model/product.model';
import { BaseListComponent } from 'vendors/smart-bot-app/src/app/lib/base-list-component';
import { CollaboratorOpportunityModel } from 'vendors/smart-bot-app/src/app/model/collaborator.model';
import { ContactModel } from 'vendors/smart-bot-app/src/app/model/contact.model';
import { ChatRoom } from 'vendors/smart-bot-app/src/app/lib/nam-chat/chat-room';
import { RootServices } from '../services/root.services';
import { CommonService } from 'vendors/smart-bot-app/src/app/services/common.service';
import { F7Page } from '../types/framework7-types';
// import * as $ from 'jquery';
declare const $: any;

// declare const $: any;
export interface ComponentStateExtend extends ListComponentStateExtend<CollaboratorOpportunityModel> {
  [key: string]: any;
  instance?: F7ComponentContextExtend;
}

export interface F7ComponentContextExtend extends F7ListComponentContext<CollaboratorOpportunityModel> {
  responseInProgress?: boolean;
  listItems?: ProductModel[];
  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, chatRoom?: ChatRoom } };
}

/** Component group manager */
export class BioPageSocialComponent extends BaseListComponent<ComponentStateExtend, CollaboratorOpportunityModel> {

  namespace: string = 'bio-page-social';
  // states: { [key: string]: State } = {};

  title: 'Cộng đồng Bio';
  apiPath = '/bio/pages';
  idKey = 'Code';
  limit = 20;

  // stateMap = {
  //   'NOTJUSTAPPROVED': {
  //     text: 'chưa duyệt',
  //     color: 'text-color-orange',
  //     icon: 'circle_fill'
  //   },
  //   'APPROVED': {
  //     text: 'đã duyệt',
  //     color: 'text-color-green',
  //     icon: 'person_crop_circle_fill_badge_checkmark'
  //   },
  //   'INQUEUE': {
  //     text: 'hàng đợi',
  //     color: 'text-color-orange',
  //     icon: 'square_stack_3d_down_right_fill'
  //   },
  // };

  filterSchema = {
    Publisher: {
      type: 'autocomplete',
      autocompleteOption: { minSearchInputLength: 0, autoFocus: false, limit: 20 },
      ajax: (query: any) => {
        return this.rootServices.apiService.getPromise<ContactModel[]>('/collaborator/publishers', { search: query, includeIdText: true }).then(results => {
          return results.map(m => {
            m.id = this.commonService.getObjectId(m.Contact);
            m.text = `${m.Name}`;
            return m;
          });
        });
      },
      dataType: 'multiple'
    },
    Customer: {
      type: 'autocomplete',
      autocompleteOption: { minSearchInputLength: 0, autoFocus: false, limit: 20 },
      ajax: (query: any) => {
        return this.rootServices.apiService.getPromise<ContactModel[]>('/collaborator/contacts', { search: query, includeIdText: true, includeGroups: true, sort_SearchRank: 'desc' }).then(results => {
          return results.map(m => {
            m.text = `${m.Name}${m.Groups ? ' (' + m.Groups.map(g => this.commonService.getObjectText(g as any)).join(', ') + ')' : ''}`;
            return m;
          });
        });
      },
      dataType: 'multiple'
    },
    PublisherSupporter: {
      type: 'autocomplete',
      autocompleteOption: { minSearchInputLength: 0, autoFocus: false, limit: 20 },
      ajax: (query: any) => {
        return this.rootServices.apiService.getPromise<Model[]>('/collaborator/publisher-supporters', { search: query, includeIdText: true }).then(results => {
          return results.map(m => {
            m.id = this.commonService.getObjectId(m.Contact);
            m.text = `${m.Name} (${m.Contact})`;
            return m;
          });
        });
      },
      dataType: 'multiple'
    },
    // SalesManager: {
    //   type: 'autocomplete',
    //   autocompleteOption: { minSearchInputLength: 0, autoFocus: false, limit: 20 },
    //   ajax: (query: any) => {
    //     return this.rootServices.apiService.getPromise<Model[]>('/collaborator/sales-managers', { search: query, includeIdText: true }).then(results => {
    //       return results.map(m => {
    //         m.id = this.commonService.getObjectId(m.Contact);
    //         m.text = `${m.Name} (${m.Contact})`;
    //         return m;
    //       });
    //     });
    //   },
    //   dataType: 'multiple'
    // },
    Seller: {
      type: 'autocomplete',
      autocompleteOption: { minSearchInputLength: 0, autoFocus: false, limit: 20 },
      ajax: (query: any) => {
        return this.rootServices.apiService.getPromise<Model[]>('/collaborator/sellers', { search: query, includeIdText: true }).then(results => {
          return results.map(m => {
            m.id = this.commonService.getObjectId(m.Contact);
            m.text = `${m.Name} (${m.Contact})`;
            return m;
          });
        });
      },
      dataType: 'multiple'
    },
    State: {
      type: 'smart-select',
      // dataType: 'multiple'
    },
    DateOfOpportunity: {
      type: 'datepicker',
      dataType: 'range',
      format: 'dd/mm/yyyy'
    },
  };

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
  ) {
    super(rootServices, commonService);
    // // console.log('Click here to open ts file');

    this.voucherStateMap['INQUEUE'] = {
      text: 'hàng đợi',
      color: 'color-orange',
      icon: 'square_stack_3d_down_right_fill'
    };
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  async onComponentInit(state: ComponentStateExtend, index: string, asCase?: string, f7Page?: F7Page) {
    return super.onComponentInit(state, index, asCase, f7Page).then(currentState => {
      if(currentState.instance.$route?.params?.query) {
        currentState.instance.$setState({Keyowrd: this.currentState.instance.$route.params.query});
      }
      return currentState;
    });
  }

  prepareItem(item: CollaboratorOpportunityModel, index?: number) {
    item.DateText = this.rootServices.datePipe.transform(item.Reported, 'short');
    // const state = this.commonService.getStateLabel(item.State);
    // item.StateLabel = state?.label;
    // item.StateIcon = state?.icon;
    // item.StateStatus = state?.status;
    const processMap = this.rootServices.collaboratorService.opportunityProcessMap[this.commonService.getObjectId(item.State)];
    item.State = {
      id: this.commonService.getObjectId(item.State),
      text: processMap && processMap.label || '--',
      ...processMap
    };
    item.CreatorText = this.commonService.getObjectText(item.Creator);
    if (item.StateLogs) {
      const coolStateLog = item.StateLogs.find(f => this.commonService.getObjectId(f) == 'FREEZE');
      if (coolStateLog) {
        item.Accepter = {
          id: coolStateLog.Changer,
          text: coolStateLog.ChangerName,
        };
      }
    }

    item.Keywords = [];
    if (item.Keyword1) item.Keywords.push(item.Keyword1);
    if (item.Keyword2) item.Keywords.push(item.Keyword2);
    if (item.Keyword3) item.Keywords.push(item.Keyword3);
    if (item.Keyword4) item.Keywords.push(item.Keyword4);
    item.SelfIntroduction = (item.SelfIntroduction || '').replace(/\n/i, '<br>');
    item.SkillIntroduction = (item.SkillIntroduction || '').replace(/\n/i, '<br>');
    return item;
  }

  // async getList(params: any) {
  //   // console.log(self.searchBar);
  //   return this.rootServices.apiService.getPromise<any[]>('/sales/price-reports', {
  //     includeCreator: true,
  //     includeRelativeVouchers: true,
  //     sort_Id: 'desc',
  //     limit: 10,
  //     offset: 0,
  //     ...params,
  //   });
  // }

  async getList(self?: F7ComponentContextExtend, extParams?: any): Promise<CollaboratorOpportunityModel[]> {
    const search = self.$route.params?.search;
    const query = self.$route.params?.query;
    const extendSearch = {};
    if (search && query) {
      if (search == 'Keyword') {
        extendSearch['filter_Keyword'] = query;
      }
    }
    return this.rootServices.apiService.getPromise<CollaboratorOpportunityModel[]>(this.apiPath, {
      includeCreator: true,
      includeRelativeVouchers: true,
      includeStateLogs: true,
      // includePublishers: true,
      includePublisher: true,
      // includePublisherSupporters: true,
      // includeSellers: true,
      sort_DateOfOpportunity: 'desc',
      sort_Id: 'desc',
      ...this.parseFilter(this.currentState?.filter),
      ...extParams,
      ...extendSearch,
    }).then(list => {
      return list;
    }).catch(err => {
      console.error(err);
      this.commonService.showError(err);
      return Promise.reject(err);
    });
  }
  async changeState(item: CollaboratorOpportunityModel) {
    // const newState = this.currentState.data.State == 'APPROVED' ? 'NOTJUSTAPPROVED' : 'APPROVED';
    const $this = this;
    const currentState = this.rootServices.collaboratorService.opportunityProcessMap[this.commonService.getObjectId(item.State)];
    if (currentState && currentState.nextStates) {
      const supplierDialog = this.currentState.instance.$app.dialog.create({
        cssClass: 'dialog-large',
        title: 'Chuyển trạng thái',
        text: 'Phiếu đang ở trạng thái `' + currentState.label + '`, bạn có muốn chuyển sang các trạng thái bên dưới ?',
        buttons: [
          ...currentState.nextStates.map((m, index) => ({
            text: (index + 1) + '. ' + m.confirmLabel,
            color: 'success',
            onClick: () => {
              $this.rootServices.apiService.putPromise($this.apiPath, { id: item.Code, changeState: m.state }, [{ Code: item.Code }]).then(rs => {
                // this.currentState.data.State = m.state;
                item = $this.prepareItem({
                  ...item,
                  State: {
                    id: m.state,
                    text: m.label,
                    ...m,
                  }
                });
                const vitem = $this.currentState.instance.virtualList.items.findIndex(f => f.Code == item.Code);
                $this.currentState.instance.virtualList.replaceItem(vitem, item);
                this.commonService.showInfo(m.responseText);
                // const itemEle = $($this.currentState.instance.virtualList.$el).find('[data-id="' + item.Code + '"]');
                // this.itemBeforeInsert(itemEle[0], item);
              }).catch(err => {
                this.commonService.showError(err);
              });
            }
          })),
          {
            text: 'Trở về',
            bold: true,
            color: 'red'
          },
        ],
        verticalButtons: true,
      });
      supplierDialog.open();
    }
  }

  itemBeforeInsert(element: HTMLElement, item: CollaboratorOpportunityModel): void {
    const $this = this;
    $(element).find('.approveBtn').unbind('click').click(function () {
      $this.changeState(item);
      return false;
    });
    $(element).find('.readMoreBtn').unbind('click').click(function () {
      $this.rootServices.navigate('/bio-page/' + item.Tag, {
        context: {
          backTitle: this.title,
        }
      });
      return false;
    });
    $(element).find('.copyLinkBtn').unbind('click').click(function () {
      $this.rootServices.copyTextToClipboard('https://probox.vn/' + item.Tag);
      return false;
    });
    $(element).find('.keyword').unbind('click').click(function (e) {
      const keywordUrl = $(e.currentTarget).data('href');
      $this.rootServices.navigate(keywordUrl);
    });
    return;
  }

  downloadPdf(item: CollaboratorOpportunityModel) {
    this.rootServices.iab.create(this.rootServices.apiService.buildApiUrl(this.apiPath, { id: item.Code, includeCustomer: true, includeContact: true, includeDetails: true, includeUnit: true, renderPdf: 'download' }) as string, '_system');
  }

  async onItemClick(self: F7ComponentContextExtend, item: CollaboratorOpportunityModel) {
    // const self: F7ComponentContextExtend = this;
    // id = id || self.$(e.target).closest('li').data('id') || 'new';
    const id = item[this.idKey];


  }

  itemHeight = 605;
  itemTemplate = /*html*/`
  <div class="card demo-card-header-pic bio-page">
    <div style="background-image:url({{js "this.Banner &&  this.Banner.SmallImage || ''"}})" class="card-header align-items-flex-end bio-banner">
    
      <div class="bio-logo-border">
        <div class="bio-logo bg-color-gray" @click="browsePictures" data-img-index="1"
          style="background-image: url({{js "this.Avatar && this.Avatar.SmallImage || ''"}})">
        </div>
      </div>

    </div>
    <div class="card-content card-content-padding">

      <div class="bio-title">{{Name}}</div>
      <div>@{{Tag}}</div>

      <!--<p class="date">Posted on January 21, 2015</p>-->
      <p style="display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;">{{SelfIntroduction}}</p>
      <p style="display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;">{{SkillIntroduction}}</p>
      <p style="display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;">
        {{#each Keywords}}
          <a class="keyword" data-href="/bio-page-social/Keyword/{{this}}">{{this}}</a> 
        {{/each}}
      </p>
      <div></div>
    </div>
    <div class="card-footer"><a href="#" class="link copyLinkBtn">Copy link</a><a href="#" class="link readMoreBtn">Vào xem</a></div>
  </div>
  `;

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: 'bio-page-social',
      path: '/bio-page-social',
      component: {
        template: /*html*/`
            <div class="page no-toolbar-x bio-page-social" data-name="bio-page-social">
              <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a class="link back {{textColorClass}}">
                      <i class="icon icon-back"></i>
                      <span class="if-not-md {{textColorClass}}">{{js "this.backTitle || 'Back'"}}</span>
                    </a>
                  </div>
                  <div class="title">Cộng đồng Bio page</div>
                  <div class="right">
                    <!-- Link to enable searchbar -->
                    <a class="link icon-only searchbar-enable {{textColorClass}}" data-searchbar=".bio-page-social-searchbar">
                      <i class="icon f7-icons if-not-md">search</i>
                      <i class="icon material-icons md-only">search</i>
                    </a>
                  </div>
                  <!-- Searchbar is a direct child of "navbar-inner" -->
                  <form class="searchbar bio-page-social-searchbar searchbar-expandable">
                    <div class="searchbar-inner">
                      <div class="searchbar-input-wrap">
                        <input type="search" placeholder="Search" />
                        <i class="searchbar-icon"></i><span class="input-clear-button"></span>
                        
                      </div>
                      <span class="searchbar-disable-button {{textColorClass}}">Hủy</span>
                    </div>
                  </form>
                </div>
              </div>
              <div class="page-content ptr-content infinite-scroll-content" @ptr:refresh="refresh">
                <div class="ptr-preloader">
                  <div class="preloader"></div>
                  <div class="ptr-arrow"></div>
                </div>
                <div class="searchbar-backdrop bio-page-social-backdrop"></div>
            
                {{#if Keyowrd}}<div class="block block-strong inset">Liên quan tới từ khóa: {{Keyowrd}}</div>{{/if}}
                <!--
                <div class="block-title" style="margin-top: 2rem">Bộ lọc <a style="float: right" @click="resetFilter"
                class="text-color-red">đặt lại</a></div>
                <div class="block block-strong inset filter" style="padding: 0; overflow: hidden">
                  <div class="list accordion-list inline-labels no-hairlines" style="margin: 0;">
                    <ul>
                      <li class="autocomplete" name="keyword">
                        <div class="item-link smart-select item-content" href="#">
                          <div class="item-inner" style="display: flex;">
                            <div class="item-title">Từ khóa</div>
                            <div class="item-after">{{text filter.Keyword 'Chọn'}}</div>
                          </div>
                        </div>
                      </li>
                      
                      <li class="autocomplete" name="Customer">
                        <div class="item-link smart-select item-content" href="#">
                          <div class="item-inner" style="display: flex;">
                            <div class="item-title">Khách hàng</div>
                            <div class="item-after">{{text filter.Customer 'Chọn'}}</div>
                          </div>
                        </div>
                      </li>
                      <li class="item-link smart-select smart-select-init" name="State" data-open-in="popup" data-searchbar="false" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFilterFieldChange">
                        <select>
                          <option value="">--</option>
                          {{#each processMap}}
                          <option value="{{state}}">{{label}}</option>
                          {{/each}}
                        </select>
                        <div class="item-content">
                          <div class="item-inner">
                            <div class="item-title">Trạng thái</div> 
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="item-content item-input">
                          <div class="item-inner">
                            <div class="item-title item-label">Thời gian</div>
                            <div class="item-input-wrap">
                              <input class="calendar" name="DateOfOpportunity" type="text" placeholder="Ngày đặt hàng" readonly="readonly" style="text-align: right;"/>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="accordion-item">
                        <a class="item-content item-link">
                          <div class="item-inner">
                            <div class="item-title" style="font-weight: normal" >Thêm</div>
                            <div class="item-after"><span class="badge">xổ xuống</span></div>
                          </div>
                        </a>
                        <div class="accordion-item-content">
                          <div class="list inline-labels no-hairlines" style="margin: 0;">
                            <ul>
                              <li class="autocomplete" name="PublisherSupporter">
                                <div class="item-link smart-select item-content" href="#">
                                  <div class="item-inner" style="display: flex;">
                                    <div class="item-title">NV Chăm sóc CTV</div>
                                    <div class="item-after">{{text filter.PublisherSupporter 'Chọn'}}</div>
                                  </div>
                                </div>
                              </li>
                              <li class="autocomplete" name="Seller">
                                <div class="item-link smart-select item-content" href="#">
                                  <div class="item-inner" style="display: flex;">
                                    <div class="item-title">NV Sell</div>
                                    <div class="item-after">{{text filter.Seller 'Chọn'}}</div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                      
                    </ul>
                  </div>
                </div>
                -->
                <div class="virtual-list bio-page-social-virtual-list inset">
                  
                </div>
                {{#if infiniteLoadLast}}
                    <div style="text-align: center" class="text-color-gray">end</div>
                {{else}}
                    <div class="preloader color-blue infinite-scroll-preloader">
                        <span class="preloader-inner">
                            <span class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span>
                        </span>
                    </div>
                {{/if}}
            
                <div class="block"><br></div>
            
              </div>
            </div>
        `,
        style: /*css*/`
          .bio-page-social .find-opportunity {
            font-size: 16px;
            font-weight: bold;
          }
          .bio-page-social .action-bar {
            color: var(--f7-block-strong-text-color);
            padding-top: var(--f7-block-padding-vertical);
            padding-bottom: var(--f7-block-padding-vertical);
            background-color: var(--f7-block-strong-bg-color);
          }
          .bio-page-social .virtual-list i.f7-icons {
            font-size: 16px;
          }
          .bio-page-social .list-complex-info ul li .item-title .item-title-text {
            -webkit-line-clamp: 1;
          }
          .bio-page-social .list-complex-info ul li .item-title .item-title-text {
            -webkit-line-clamp: 1;
          }
          .bio-page-social .list-complex-info ul li .item-title {
            height: 18px;
          }
          .virtual-list.bio-page-social-virtual-list ul {
            padding: 0;
          }

          .demo-card-header-pic .card-header {
            height: 14rem;
            background-size: cover;
            background-position: center;
            color: #fff;
          }
          .demo-card-header-pic .card-content-padding {
            height: 255px;
          }
          .demo-card-header-pic .card-content-padding .date{
            color: #8e8e93;
          }

          .page.bio-page-social .bio-banner {
            position: relative;
            margin-bottom: 1rem;
            height: 270px;
          }
          .page.bio-page-social .bio-logo-border {
            height: 125px;
            width: 125px;
            border-radius: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            /* margin-left: 1rem; */
            /* margin-top: -116px; */
            padding: 5px;
            background-color: var(--f7-page-bg-color);
            position: absolute;
            left: 0.5rem;
            bottom: -1.5rem;
            z-index: 16;
          }
          .page.bio-page-social .bio-logo {
            height: 116px;
            width: 116px;
            border-radius: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            margin: -1px auto;
            position: relative;
          }

          .page.bio-page-social .bio-title {
            text-align: left;
            font-weight: bold;
            font-size: 1.5rem;
          }
          .page.bio-page-social .keyword {
            text-decoration: underline;
            font-style: italic;
          }
        `,
        data() {
          return {
            title: $this.title,
            // reminder: {
            //   No: '234234',
            // },
            priceReport: { Details: [] },
            filter: {},
            processMap: $this.rootServices.collaboratorService.opportunityProcessMap,
            Keyword: null,
          };
        },
        methods: {
          reportCurrentFilterToPdf() {
            if (!$this.currentState?.filter?.Publisher) {
              $this.commonService.showError('Chưa chọn CTV Bán hàng');
              return;
            }
            const fromDate = $this.commonService.getBeginOfMonth();
            const toDate = $this.commonService.getEndOfDate();
            $this.rootServices.iab.create($this.rootServices.apiService.buildApiUrl($this.apiPath, {
              includeCreator: true,
              includeRelativeVouchers: true,
              ge_DateOfOpportunity: fromDate.toISOString(),
              le_DateOfOpportunity: toDate.toISOString(),
              sort_DateOfOpportunity: 'desc',
              ...$this.parseFilter($this.currentState?.filter),
              type: 'pdf'
            }) as string, '_system');
          },
          resetFilter(e) {
            $this.resetFilter(this, e);
          },
          onFilterFieldChange(e) {
            $this.onFilterFieldChange(this, e);
          },
          // Price report
          openVoucher(e, id?: string) {
            const self: F7ComponentContextExtend = this;
            id = id || self.$(e.target).closest('li').data('id') || 'new';

            // if ($this.commonService.frameSocket.isFrameMode && id && id !== 'new') {
            //   $this.commonService.frameSocket.emit('request-open-voucher', { id: id, type: 'PRICEREPORT' });
            // } else {
            $this.rootServices.navigate('/collaborator/opportunity/' + id, {
              context: {
                backTitle: $this.title,
                textColorClass: self.textColorClass,
                // memberInfo: $this.currentState.chatRoom.memberList$.getValue().find(f => f.id === id),
                // chatRoom: self.$route.context.chatRoom,
                // reminderList$: self.reminderList$,
                callback: (data: CollaboratorOpportunityModel, state: any) => {
                  console.log('Callback data: ', data);
                  if (state?.lastAction == 'CREATE_SUCCESS') {
                    self.refresh();
                  }
                }
              }
            });
            // }
          },
          goto(e) {
            const self: F7ComponentContextExtend = this;
            const url = self.$(e.target).closest('.goto').data('url');
            $this.rootServices.navigate(url);
          },
          chooseOne(e) {
            const self: F7ComponentContextExtend = this;
            const id = self.$(e.target).closest('li').data('id');
            self.$route.context['onChoose'] && self.$route.context['onChoose'](self.listItems.find(f => f.Code === id));
          },
          chooseProduct(e) {

          },
          async refresh(e, done) {
            const self: F7ComponentContextExtend = this;
            $this.refresh(self).then(rs => done && done());
            return true;
          },
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          pageInit(e, page: F7Page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;
            // Loading flag
            let allowInfinite = true;
            const comIndex = self.$route.params?.search+self.$route.params?.query;
            $this.onComponentInit({ instance: self}, comIndex || 'main', null, page);
          },
          // pageBeforeIn(e, page) {
          //     console.log('page before in');
          //     const self: F7ComponentContextExtend = this;
          //     self.refresh();
          // },
          // pageAfterIn(e, page) {
          //     console.log('page after in');
          // },
          // pageBeforeOut(e, page) {
          //     console.log('page before out');
          // },
          pageAfterOut(e, page) {
            console.log('page after out');
          },
          // pageBeforeUnmount(e, page) {
          //     console.log('page before unmount');
          // },
          // pageBeforeRemove(e, page) {
          //     console.log('page before remove');
          // },
          pageBeforeRemove(e, page) {
            console.log('[page event] before remove', page.route.url);
            const self: F7ComponentContextExtend = this;
            // const starmtSelectRoles = self.$app.smartSelect.get('.smart-select');
            // starmtSelectRoles.destroy();
          },
        },
      },
    };
  }
}
