import { CommonService } from 'vendors/smart-bot-app/src/app/services/common.service';
import { F7ComponentContext, F7Page } from '../types/framework7-types';
import { Router } from 'framework7/build/core/modules/router/router';
import { BaseComponent, ComponentState } from 'vendors/smart-bot-app/src/app/lib/base-component';
import { RootServices } from '../services/root.services';
import { Attachment } from 'vendors/smart-bot-app/src/app/lib/nam-chat/model/message';
import { ChatRoom } from 'vendors/smart-bot-app/src/app/lib/nam-chat/chat-room';
import { Member } from 'vendors/smart-bot-app/src/app/lib/nam-chat/model/member';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { User } from 'vendors/smart-bot-app/src/app/lib/nam-chat/model/user';
import { ChatRoomModel, ChatRoomOutsideLinkModel } from 'vendors/smart-bot-app/src/app/model/chat-room.model';
import { Dialog } from 'framework7/build/core/components/dialog/dialog';
import { TaskReminderModel } from 'vendors/smart-bot-app/src/app/model/task.model';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { any } from 'underscore';
import { ContactModel } from 'vendors/smart-bot-app/src/app/model/contact.model';

export interface ComponentStateExtend extends ComponentState {
  [key: string]: any;
  chatRoom?: ChatRoom;
  roles?: { [key: string]: { id: string, text: string } };
  referenceChatRooms?: ChatRoomModel[];
  recferenceChatRoomsLastUpdate?: number;
}

export class F7ComponentContextExtend extends F7ComponentContext {
  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, chatRoom?: ChatRoom } };

  notificationSettinsDialog?: Dialog.Dialog;
  addContactDialog?: Dialog.Dialog;
  addReminderDialog?: Dialog.Dialog;
  referenceTaskDialog?: Dialog.Dialog;

  addOutsideLinkDialog?: Dialog.Dialog;
  infoDialog?: Dialog.Dialog;
  tagsDialog?: Dialog.Dialog;
  chatRoom?: ChatRoom;
  memberList?: Member[];

  reminderList$?: BehaviorSubject<TaskReminderModel[]>;
  outsideLinks?: ChatRoomOutsideLinkModel[];
  hadUpdate?: (updateCase: string, data?: any) => void;
}

/** Component group manager */
export class ChatRoomControlComponent extends BaseComponent<ComponentStateExtend> {
  // states: { [key: string]: State } = {};

  title = 'Cài đặt nhóm';

  typeMap = {
    PRICEREPORT: {
      label: 'Báo giá',
      status: 'primary'
    },
    PURCHASEORDER: {
      label: 'Đặt hàng',
      status: 'primary'
    },
    INVENTORYADJUST: {
      label: 'Kiểm kho',
      status: 'primary'
    },
    PAYMENT: {
      label: 'Phiếu chi',
      status: 'primary'
    },
    RECEIPT: {
      label: 'Phiếu thu',
      status: 'primary'
    },
  };
  

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
    public datePipe: DatePipe,
  ) {
    super(rootServices);
    // // console.log('Click here to open ts file');
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  convertChatRoomTreeToArray(tree: ChatRoomModel[], level?: number) {
    let chatRoomList: (ChatRoomModel & { Level?: number, IsCurrent?: boolean })[] = [];
    for (const chatRoom of tree) {
      chatRoomList.push({ ...chatRoom, IsCurrent: chatRoom.Code === this.currentState.chatRoom.id, Level: level || 0 });
      if (chatRoom.Children && chatRoom.Children.length > 0) {
        chatRoomList = chatRoomList.concat(this.convertChatRoomTreeToArray(chatRoom.Children, (level || 0) + 1));
      }
    }
    return chatRoomList;
  }

  async onComponentInit(state: ComponentStateExtend, index: string, asCase?: string) {
    const currentState = await super.onComponentInit(state, index, asCase);
    if (state.chatRoom) {
      this.currentState.chatRoom = this.state[index].chatRoom = state.chatRoom;
    }
    currentState.roles = {
      CHATROOMMANAGER: {
        id: 'CHATROOMMANAGER',
        text: 'Quản lý',
      },
      HELPDESK: {
        id: 'HELPDESK',
        text: 'Hỗ trợ',
      },
      COORDINATORS: {
        id: 'COORDINATORS',
        text: 'Điều phối',
      },
      ITHELPDESK: {
        id: 'ITHELPDESK',
        text: 'Hỗ trợ IT',
      },
      SALESHELPDESK: {
        id: 'SALESHELPDESK',
        text: 'Hỗ trợ bán hàng',
      },
      ITCOORDINATORS: {
        id: 'ITCOORDINATORS',
        text: 'Điều phối IT',
      },
      SALESCOORDINATORS: {
        id: 'SALESCOORDINATORS',
        text: 'Điều phối bán hàng',
      },
      CUSTOMER: {
        id: 'CUSTOMER',
        text: 'Khách hàng',
      },
      CHATROOMMEMBER: {
        id: 'CHATROOMMEMBER',
        text: 'Thành viên',
      },
      HELPDESKCREATOR: {
        id: 'HELPDESKCREATOR',
        text: 'Người tạo yêu cầu',
      },
      CHATROOMCREATOR: {
        id: 'CHATROOMCREATOR',
        text: 'Người tạo phòng chat',
      },
      CONTACT: {
        id: 'CONTACT',
        text: 'Liên hệ',
      },
      MONITORING: {
        id: 'MONITORING',
        text: 'Giám sát',
      },
    };

    // sync attachments
    currentState?.chatRoom.syncAttachmentList().then(rs => {
      console.log('Attachments sync success');
    });

    // Load reference chat rooms
    if (!currentState.recferenceChatRoomsLastUpdate || currentState.recferenceChatRoomsLastUpdate + 60000 < Date.now()) {
      const chatRoomTree = await this.rootServices.apiService.getPromise<ChatRoomModel[]>('/chat/rooms', { referenceChatRoom: currentState.chatRoom.id });
      currentState.referenceChatRooms = this.convertChatRoomTreeToArray(chatRoomTree);
      currentState.recferenceChatRoomsLastUpdate = Date.now();
    }
    if (currentState.referenceChatRooms) {
      currentState.instance.$setState({
        referenceChatRooms: currentState.referenceChatRooms,
      });
    }

    return currentState;
  }

  // async browseAttachment(attachmentId: string) {
  //   const attachments = this.currentState.chatRoom.attachments;

  //   // Check attachments
  //   if (!attachments || attachments.length === 0) {
  //     return false;
  //   }

  //   // find index
  //   const index = attachments.findIndex(f => f.id === attachmentId);

  //   const myPhotoBrowserPopupDark = this.currentState.instance.$app.photoBrowser.create({
  //     photos: attachments.map(att => ({
  //       url: (/^image/.test(att.type) ? att.payload.url : att.payload.thumbnail) + '?token=' + this.rootServices.apiService.token.access_token,
  //       caption: att.description,
  //     })),
  //     theme: 'dark',
  //     type: 'standalone' as any,
  //   });
  //   myPhotoBrowserPopupDark.open(index);
  //   return new Promise<void>(resolve => {
  //     myPhotoBrowserPopupDark.on('closed', () => {
  //       myPhotoBrowserPopupDark.destroy();
  //       resolve();
  //     });
  //   });
  // }

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: 'chat-room-control',
      path: '/chat-room-control/:id',
      component: {
        template: /*html*/`
            <div class="page no-toolbar-x" data-name="chat-room-control">
              <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                    <div class="left">
                      <a class="link back {{textColorClass}}">
                          <i class="icon icon-back"></i>
                          <span class="if-not-md {{textColorClass}}">{{backTitle}}</span>
                      </a>
                    </div>
                    <div class="title">{{title}}</div>
                    <div class="right">
                        <!-- Link to enable searchbar -->
                        <a class="link icon-only searchbar-enable {{textColorClass}}" data-searchbar=".chat-room-control-searchbar">
                            <i class="icon f7-icons if-not-md">search</i>
                            <i class="icon material-icons md-only">search</i>
                        </a>
                    </div>
                    <!-- Searchbar is a direct child of "navbar-inner" -->
                    <form class="searchbar chat-room-control-searchbar searchbar-expandable">
                        <div class="searchbar-inner">
                        <div class="searchbar-input-wrap">
                            <input type="search" placeholder="Search"/>
                            <i class="searchbar-icon"></i><span class="input-clear-button"></span>
                        </div>
                        <span class="searchbar-disable-button {{textColorClass}}">Cancel</span>
                        </div>
                    </form>
                </div>
              </div>
              <div class="page-content ptr-content" @ptr:refresh="refresh">
                <div class="ptr-preloader">
                  <div class="preloader"></div>
                  <div class="ptr-arrow"></div>
                </div>
                <div class="searchbar-backdrop chat-room-control-searchbar-backdrop"></div>
                <br>

                {{#js_if "this.Description || (this.Tags && this.Tags.length > 0)"}}
                <div class="block block-strong action-bar inset">
                  <div>{{Description}}</div>
                </div>
                {{/if}}

                <!--<div class="block-title">Báo giá <a style="float: right" @click="openPriceReport" class="{{textColorClass}}">+ tạo báo giá</a></div>
                <div class="list media-list inset activity-list">
                  <ul>
                    {{#each priceReports}}
                    <li class="swipeout" data-id="{{Code}}">
                      <a class="item-content item-link swipeout-content" @click="openPriceReport">
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title">{{Title}}</div>
                            <div class="item-after text-color-default">{{TotalText}}</div>
                          </div>
                          <div class="item-subtitle">Ngày báo giá: {{ReportedText}}</div>
                          <div class="item-subtitle">Khách hàng: {{ObjectName}}</div>
                          <div class="item-subtitle">Liên hệ: {{ContactName}}</div>
                          <div class="item-text">{{Note}}</div>
                        </div>
                      </a>
                      <div class="swipeout-actions-right">
                        <a href="#" class="color-green swipeout-overswipe">Duyệt</a>
                      </div>
                    </li>
                    {{/each}}
                  </ul>
                </div>-->

                {{#if resources}}
                <div class="block-title">Chứng từ liên quan <a class="button button-small button-fill tab-link" @click="createRelativeVoucherDialog" style="float: right; font-size: 0.7rem; font-weight: bold">+ Mới</a></div>
                <div class="list media-list inset activity-list">
                  <ul>
                    {{#each resources}}
                    <li class="swipeout" data-id="{{id}}" data-type="{{type}}">
                      <a class="item-content item-link swipeout-content" @click="openResource">
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title">{{text}}</div>
                            <div class="item-after; text-color-blue" style="font-size: 0.8rem"><span class="badge color-{{stateStatus}} unread nowrap">{{stateLabel}}</span></div>
                          </div>
                          <div class="item-subtitle">Ngày: {{date date}}</div>
                          <div style="float: left;" class="item-text">{{typeLabel}}: {{id}}</div>
                          <div style="float: right;" class="item-text">{{currency Amount}}</div>
                          <div style="clear: both;"></div>
                        </div>
                      </a>
                      <div class="swipeout-actions-right">
                        <a href="#" class="color-green swipeout-overswipe">Duyệt</a>
                      </div>
                    </li>
                    {{/each}}
                  </ul>
                </div>
                {{/if}}

                {{#js_if "this.outsideLinks && this.outsideLinks.length > 0"}}
                <div class="block-title">Liên kết bên thứ 3</div>
                <div class="list media-list inset">
                  <ul>
                    {{#each outsideLinks}}
                    <li class="swipeout" data-name="remove-chat-room-member" data-id="{{Id}}">
                      <a class="item-content item-link swipeout-content" @click="openOutsideLink">
                        <div class="item-media">
                          <div class="list-item-img" style="width: 44px; height: 44px; background: transparent">
                            <i style="font-size: 40px !important" class="f7-icons">link_circle_fill</i>
                          </div>
                        </div>
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title">{{Description}}</div>
                          </div>
                          <div class="item-text text-color-default" style="font-size: 12px">{{Link}}</div>
                        </div>
                      </a>
                      <div class="swipeout-actions-right">
                        <a href="#" @click="removeOutsideLinkConfirm" class="swipeout-overswipe color-red"><i class="f7-icons">multiply_circle_fill</i></a>
                      </div>
                    </li>
                    {{/each}}
                  </ul>
                </div>
                {{/js_if}}

                {{#js_if "this.reminders && this.reminders.length > 0"}}
                <div class="block-title">Danh sách nhắc việc <a class="button button-small button-fill tab-link" @click="openAddReminderForm" style="float: right; font-size: 0.7rem; font-weight: bold">+ Mới</a></div>
                <div class="list media-list inset">
                  <ul>
                    {{#each reminders}}
                    <li class="swipeout" data-id="{{No}}">
                      <a class="item-content item-link swipeout-content" @click="openReminderForm">
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title">{{Title}}</div>
                            <div class="item-after"><i style="font-size: 16px" class="f7-icons">bell_fill</i></div>
                          </div>
                          <div class="item-subtitle">{{CrontabLabel}}</div>
                          {{#js_if "this.Repeat !== 'NEVER'"}}
                          <div class="item-subtitle">Bắt đầu: {{RemindAtDateTimeText}}</div>
                          {{/js_if}}
                          <div class="item-subtitle">Lần tiếp: {{NextRemind}}</div>
                          <div class="item-text">{{Note}}</div>
                        </div>
                      </a>
                      <div class="swipeout-actions-right">
                        <a href="#" @click="removeReminderConfirm" class="color-red swipeout-overswipe">Gở</a>
                      </div>
                    </li>
                    {{/each}}
                  </ul>
                </div>
                {{/js_if}}

                

                <div class="block-title">Thành viên</div>
                {{#js_if "this.memberNonGroupList && this.memberNonGroupList.length > 0"}}
                <div class="list media-list inset">
                  <ul>
                    {{#each memberNonGroupList}}
                    <li class="swipeout" data-name="remove-chat-room-member" data-id="{{id}}">
                      <a class="item-content item-link swipeout-content" @clickx="gotoProfile">
                        <div class="item-media">
                          {{#js_if "this.AvatarThumbnail"}}
                          <div class="list-item-img" style="width: 44px; height: 44px; background-image: url({{js "this.AvatarThumbnail || ''"}});"></div>
                          {{else}}
                          <div class="list-item-img" style="width: 44px; height: 44px">
                            <i style="font-size: 16px" class="f7-icons">person_fill</i>
                          </div>
                          {{/js_if}}
                        </div>
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title">{{Name}}</div>
                            <div class="item-after"><i style="font-size: 16px" class="f7-icons">{{js "this.Type === 'USER' ? 'person_fill' : this.Type === 'GROUP' ? 'person_2_fill' : ''"}}</i></div>
                          </div>
                          <div class="item-subtitle">{{Roles}}</div>
                          <div class="item-text" style="font-size: 12px">{{LastReportText}}</div>
                        </div>
                      </a>
                      <div class="swipeout-actions-right">
                        <a href="#" @click="removeMemberConfirm" class="color-red"><i class="f7-icons">multiply_circle_fill</i></a>
                        <a href="#" class="color-green" @click="click2call"><i class="f7-icons">phone_circle_fill</i></a>
                        <a href="#" class="color-orange swipeout-overswipe" @click="checkMemberLastReportLocation"><i class="f7-icons">compass_fill</i></a>
                      </div>
                    </li>
                    {{/each}}
                  </ul>
                </div>
                {{/js_if}}
                <div class="list media-list inset">
                  <ul>
                    {{#each groups}}
                    <li class="swipeout" data-name="remove-chat-room-member" data-id="{{id}}">
                      <a class="item-content item-link swipeout-content" @clickx="gotoProfile">
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title">{{Name}}</div>
                            <div class="item-after"><i style="font-size: 16px" class="f7-icons">{{js "this.Type === 'USER' ? 'person_fill' : 'person_2_fill'"}}</i></div>
                          </div>
                          <div class="item-subtitle">{{Roles}}</div>
                        </div>
                      </a>
                      <div class="swipeout-actions-right">
                        <a href="#" class="color-blur">Gọi điện</a>
                        <a href="#" @click="removeMemberConfirm" class="color-red swipeout-overswipe">Gở</a>
                      </div>
                    </li>
                    {{#each Children}}
                    <li class="swipeout" data-name="remove-chat-room-member" data-id="{{id}}">
                      <a class="item-content item-link swipeout-content" @clickx="gotoProfile">
                        <div class="item-media">
                          {{#js_if "this.AvatarThumbnail"}}
                          <div class="list-item-img" style="width: 44px; height: 44px; background-image: url({{js "this.AvatarThumbnail || ''"}});"></div>
                          {{else}}
                          <div class="list-item-img" style="width: 44px; height: 44px;">
                            <i style="font-size: 16px" class="f7-icons">person_fill</i>
                          </div>
                          {{/js_if}}
                        </div>
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title">{{Name}}</div>
                            <div class="item-after"><i style="font-size: 16px" class="f7-icons">{{js "this.Type === 'USER' ? 'person_fill' : 'person_2_fill'"}}</i></div>
                          </div>
                          <div class="item-subtitle">{{Roles}}</div>
                          <div class="item-text" style="font-size: 12px">{{LastReportText}}</div>
                        </div>
                      </a>
                      <div class="swipeout-actions-right">
                        <a href="#" @click="removeMemberConfirm" class="color-red"><i class="f7-icons">multiply_circle_fill</i></a>
                        <a href="#" class="color-green" @click="click2call"><i class="f7-icons">phone_circle_fill</i></a>
                        <a href="#" class="color-orange swipeout-overswipe" @click="checkMemberLastReportLocation"><i class="f7-icons">compass_fill</i></a>
                      </div>
                    </li>
                    {{/each}}
                    {{/each}}
                  </ul>
                </div>
                
                
              </div>

              <div class="dialog dialog-wrap dialog-notification-settings">
              <div class="dialog-padding">
              <div class="dialog dialog-inside">
                <div class="dialog-inner" style="padding-bottom: 0;">
                    <div class="dialog-title">Thông báo</div>
                    <div class="block-title" style="margin-top: 1rem;">Cài đặt cho phòng này</div>
                        <div class="list simple-list" style="margin-left: -15px; margin-right: -15px; margin-bottom: 0; margin-top: 0; text-align: left;">
                        <ul>
                            <li>
                                <span>Tin nhắn mới</span>
                                <label class="toggle toggle-init color-green">
                                    <input class="field" name="DISABLEDNEWMESSAGE" value="-1" type="checkbox" checked>
                                    <span class="toggle-icon"></span>
                                </label>
                            </li>
                            <li>
                                <span>Có người nhắc tôi</span>
                                <label class="toggle toggle-init color-red">
                                    <input class="field" name="DISABLEDMENTION" value="-1" type="checkbox" checked>
                                    <span class="toggle-icon"></span>
                                </label>
                            </li>
                            <li>
                                <span>Trả lời tin nhắn</span>
                                <label class="toggle toggle-init color-orange">
                                    <input class="field" name="DISABLEDREPLY" value="-1" type="checkbox" checked>
                                    <span class="toggle-icon"></span>
                                </label>
                            </li>
                            <li>
                                <span>Tắt tới khi quay lại</span>
                                <label class="toggle toggle-init color-gray">
                                    <input class="field" name="ENABLEDONRETURN" value="1" type="checkbox">
                                    <span class="toggle-icon"></span>
                                </label>
                            </li>
                            <li>
                                <span>Tắt tới hôm sau</span>
                                <label class="toggle toggle-init color-black">
                                    <input class="field" name="ENABLEDONTOMORROW" value="1" type="checkbox">
                                    <span class="toggle-icon"></span>
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="dialog-buttons">
                    <span class="dialog-button color-red closeBtn" @click="closeNotificationSettinsDialog">Đặt lại</span>
                    <span class="dialog-button createReferenceChatRoomBtn" @click="applyNotificationSettings">Áp dụng</span>
                </div>
              </div>
              </div>
              </div>

              <div class="dialog dialog-wrap dialog-add-contact">
                <div class="dialog-padding">
                  <div class="dialog dialog-inside">
                    <div class="dialog-inner" style="padding-bottom: 0;">
                        <div class="dialog-title">Thông tin liên hệ</div>
                        <div class="dialog-text" style="width: 100%; user-select: initial; text-align: center; margin-bottom: 0.5rem">Thêm thông tin liên hệ của đối tác/khách hàng hoặc người làm việc trực tiếp với Team</div>

                        <div class="list media-list" style="margin-left: -15px; margin-right: -15px; margin-bottom: 0; margin-top: 0; text-align: left;">
                            <ul>
                                <li class="item-content item-input">
                                    <div class="item-media"><i class="icon f7-icons text-color-default">at_circle_fill</i></div>
                                    <div class="item-inner">
                                        <div class="item-title item-label">Tên liên hệ (*)</div>
                                        <div class="item-input-wrap">
                                        <input class="field" name="Name" type="text" placeholder="Tên đầy đủ">
                                        </div>
                                    </div>
                                </li>
                                <li class="item-content item-input">
                                    <div class="item-media"><i class="icon f7-icons text-color-default">phone_circle_fill</i></div>
                                    <div class="item-inner">
                                        <div class="item-title item-label">Số điện thoại (*)</div>
                                        <div class="item-input-wrap">
                                        <input class="field" name="Phone" type="tel" placeholder="Số điện thoại liên hệ">
                                        </div>
                                    </div>
                                </li>
                                <li class="item-content item-input">
                                    <div class="item-media"><i class="icon f7-icons text-color-default">envelope_fill</i></div>
                                    <div class="item-inner">
                                        <div class="item-title item-label">Email</div>
                                        <div class="item-input-wrap">
                                        <input class="field" type="email" name="Email" placeholder="Địa chỉ email liên hệ">
                                        </div>
                                    </div>
                                </li>
                                <li class="item-content item-input">
                                    <div class="item-media"><i class="icon f7-icons text-color-default">location_fill</i></div>
                                    <div class="item-inner">
                                        <div class="item-title item-label">Địa chỉ</div>
                                        <div class="item-input-wrap">
                                        <input class="field" type="text" name="Address" placeholder="Địa chỉ liên hệ">
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="dialog-buttons">
                        <span class="dialog-button color-red closeBtn" @click="closeAddContactDialog">Đóng</span>
                        <span class="dialog-button createReferenceChatRoomBtn" @click="addContact">Thêm</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="dialog dialog-wrap dialog-add-reminder">
                <div class="dialog-padding">
                  <div class="dialog dialog-inside">
                    <div class="dialog-inner" style="padding-bottom: 0;">
                        <div class="dialog-title">Nhắc việc</div>
                        <div class="dialog-text" style="width: 100%; user-select: initial; text-align: center; margin-bottom: 0.5rem">Hệ thống sẽ tự động nhắc việc khi đến hạn</div>

                        <div class="list media-list" style="margin-left: -15px; margin-right: -15px; margin-bottom: 0; margin-top: 0; text-align: left;">
                            <ul>
                                <li class="item-content item-input">
                                    <div class="item-media"><i class="icon f7-icons text-color-default">at_circle_fill</i></div>
                                    <div class="item-inner">
                                        <div class="item-title item-label">Tiêu đề (*)</div>
                                        <div class="item-input-wrap">
                                        <input class="field" name="Title" type="text" placeholder="Tên đầy đủ">
                                        </div>
                                    </div>
                                </li>
                                <li class="item-content item-input">
                                    <div class="item-media"><i class="icon f7-icons text-color-default">phone_circle_fill</i></div>
                                    <div class="item-inner">
                                        <div class="item-title item-label">Thời gian (*)</div>
                                        <div class="item-input-wrap">
                                        <input class="field" name="RemindAtDateTime" type="text" placeholder="Số điện thoại liên hệ">
                                        </div>
                                    </div>
                                </li>
                                <li class="item-content item-input">
                                    <div class="item-media"><i class="icon f7-icons text-color-default">envelope_fill</i></div>
                                    <div class="item-inner">
                                        <div class="item-title item-label">Lặp lại</div>
                                        <div class="item-input-wrap">
                                        <select class="field" type="email" name="Repeat" placeholder="Địa chỉ email liên hệ">
                                          <option value="NEVER">Không bao giờ</option>
                                          <option value="DAILY">Hàng ngày</option>
                                          <option value="WEEKLY">Hàng tuần</option>
                                          <option value="MONTHLY">Hàng tháng</option>
                                          <option value="YEARLY">Hàng năm</option>
                                        </select>
                                        </div>
                                    </div>
                                </li>
                                <li class="item-content item-input">
                                    <div class="item-media"><i class="icon f7-icons text-color-default">location_fill</i></div>
                                    <div class="item-inner">
                                        <div class="item-title item-label">Lời nhắc</div>
                                        <div class="item-input-wrap">
                                        <input class="field" type="text" name="Note" placeholder="Địa chỉ liên hệ">
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="dialog-buttons">
                        <span class="dialog-button color-red closeBtn" @click="closeAddReminderDialog">Đóng</span>
                        <span class="dialog-button createReferenceChatRoomBtn" @click="addReminder">Thêm</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="dialog dialog-wrap dialog-reference-task">
                <div class="dialog-padding">
                    <div class="dialog dialog-inside dialog-buttons-vertical">
                        <div class="dialog-inner" style="padding-bottom: 0;">
                            <div class="dialog-title">Tạo task con</div>
                            <div class="dialog-text" style="width: 100%; user-select: initial; text-align: center;">Viết mô tả và chọn thành viên và liên hệ sẽ được thêm vào task con mới</div>
                            <br>
                            <div class="list media-list" style="margin-left: -15px; margin-right: -15px; margin-bottom: 0; margin-top: 0; text-align: left;">
                              <ul>
                                <li class="item-content item-input">
                                    <div class="item-media"><i class="icon f7-icons text-color-default">doc_text_fill</i></div>
                                    <div class="item-inner">
                                        <div class="item-title item-label">Mô tả (*)</div>
                                        <div class="item-input-wrap">
                                        <textarea class="field" name="Description" type="text" placeholder="Mô tả"></textarea>
                                        </div>
                                    </div>
                                </li>
                              </ul>
                            </div>

                            {{#js_if "this.memberListForchoosed && this.memberListForchoosed.contacts && this.memberListForchoosed.contacts.length > 0"}}
                            <div class="block-title" style="text-align: left; margin-left: 0px; margin-top: 10px;">Liên hệ</div>
                            <div class="list media-list" style="margin-left: -15px; margin-right: -15px; margin-bottom: 0; margin-top: 0; text-align: left">
                                <ul style="background: inherit">
                                {{#each memberListForchoosed.contacts}}
                                <li data-id="{{id}}">
                                    <label class="item-content item-checkbox">
                                        <input class="member-item" type="checkbox" name="demo-media-checkbox" value="1" data-id="{{id}}"/>
                                        <i class="icon icon-checkbox"></i>
                                        <div class="item-media">
                                            {{#js_if "this.AvatarThumbnail"}}
                                            <div class="list-item-img" style="width: 44px; height: 44px; background-image: url({{js "this.AvatarThumbnail || ''"}});"></div>
                                            {{else}}
                                            <div class="list-item-img" style="width: 44px; height: 44px">
                                            <i style="font-size: 16px" class="f7-icons">person_fill</i>
                                            </div>
                                            {{/js_if}}
                                        </div>
                                        <div class="item-inner">
                                            <div class="item-title-row">
                                                <div class="item-title">{{Name}}</div>
                                            </div>
                                            <div class="item-subtitle">{{Roles}}</div>
                                        </div>
                                    </label>
                                </li>
                                {{/each}}
                                </ul>
                            </div>
                            {{/js_if}}
                            <div class="block-title" style="text-align: left; margin-left: 0px; margin-top: 10px;">Thành viên</div>
                            {{#js_if "this.memberListForchoosed && this.memberListForchoosed.memberNonGroupList && this.memberListForchoosed.memberNonGroupList.length > 0"}}
                            <div class="list media-list" style="margin-left: -15px; margin-right: -15px; margin-bottom: 0; margin-top: 0; text-align: left">
                                <ul style="background: inherit">
                                {{#each memberListForchoosed.memberNonGroupList}}
                                <li data-id="{{id}}">
                                    <label class="item-content item-checkbox">
                                        <input class="member-item" type="checkbox" name="demo-media-checkbox" value="1" data-id="{{id}}"/>
                                        <i class="icon icon-checkbox"></i>
                                        <div class="item-media">
                                            {{#js_if "this.AvatarThumbnail"}}
                                            <div class="list-item-img" style="width: 44px; height: 44px; background-image: url({{js "this.AvatarThumbnail || ''"}});"></div>
                                            {{else}}
                                            <div class="list-item-img" style="width: 44px; height: 44px">
                                            <i style="font-size: 16px" class="f7-icons">person_fill</i>
                                            </div>
                                            {{/js_if}}
                                        </div>
                                        <div class="item-inner">
                                            <div class="item-title-row">
                                                <div class="item-title">{{Name}}</div>
                                            </div>
                                            <div class="item-subtitle">{{Roles}}</div>
                                        </div>
                                    </label>
                                </li>
                                {{/each}}
                                </ul>
                            </div>
                            {{/js_if}}
                            {{#js_if "this.memberListForchoosed && this.memberListForchoosed.groups && this.memberListForchoosed.groups.length > 0"}}
                            <div class="block-title" style="text-align: left; margin-left: 0px; margin-top: 10px;">Thành viên theo nhóm</div>
                            <div class="list media-list" style="margin-left: -15px; margin-right: -15px; margin-bottom: 0; margin-top: 0; text-align: left;">
                                <ul style="background: inherit">
                                    {{#each memberListForchoosed.groups}}
                                    <li data-id="{{id}}">
                                        <label class="item-content item-checkbox">
                                            <input class="member-item" type="checkbox" name="demo-media-checkbox" value="1" data-id="{{id}}"/>
                                            <i class="icon icon-checkbox"></i>
                                            <div class="item-inner">
                                                <div class="item-title-row">
                                                    <div class="item-title">{{Name}}</div>
                                                </div>
                                                <div class="item-subtitle">{{Roles}}</div>
                                            </div>
                                        </label>
                                    </li>
                                    {{#each children}}
                                    <li data-id="{{id}}">
                                        <label class="item-content item-checkbox">
                                            <input class="member-item" type="checkbox" name="demo-media-checkbox" value="1" data-id="{{id}}"/>
                                            <i class="icon icon-checkbox"></i>
                                            <div class="item-media">
                                            {{#js_if "this.AvatarThumbnail"}}
                                            <div class="list-item-img" style="width: 44px; height: 44px; background-image: url({{js "this.AvatarThumbnail || ''"}});"></div>
                                            {{else}}
                                            <div class="list-item-img" style="width: 44px; height: 44px">
                                                <i style="font-size: 16px" class="f7-icons">person_fill</i>
                                            </div>
                                            {{/js_if}}
                                            </div>
                                            <div class="item-inner">
                                                <div class="item-title-row">
                                                    <div class="item-title">{{Name}}</div>
                                                </div>
                                                <div class="item-subtitle">{{Roles}}</div>
                                            </div>
                                        </label>
                                    </li>
                                    {{/each}}
                                    {{/each}}
                                </ul>
                            </div>
                            {{/js_if}}
                        </div>
                        <div class="dialog-buttons has-icon">
                          <span class="dialog-button replyBtn" @click="createReferenceTask">Tạo task con<i class="icon f7-icons">plus_app_fill</i></span>
                          <span class="dialog-button color-red closeBtn" @click="closeReferenceTaskDialog">Đóng<i class="icon f7-icons">multiply_circle_fill</i></span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="dialog dialog-wrap dialog-tags">
              <div class="dialog-padding">
                <div class="dialog dialog-inside">
                  <div class="dialog-inner" style="padding-bottom: 0;">
                      <div class="dialog-title">Gán thẻ</div>
                      <div class="dialog-text" style="width: 100%; user-select: initial; text-align: center; margin-bottom: 0.5rem">Gán thẻ cho task để tìm kiếm dễ hơn</div>

                      <div class="list media-list" style="margin-left: -15px; margin-right: -15px; margin-bottom: 0; margin-top: 0; text-align: left;">
                          <ul>
                              <li class="item-content item-input">
                                  <div class="item-media"><i class="icon f7-icons text-color-default">tag_fill</i></div>
                                  <div class="item-inner">
                                      <div class="item-title item-label">Thẻ (*)</div>
                                      <div class="item-input-wrap">
                                      <input class="field" name="tags" type="text" placeholder="phân cách bằng dấu phẩy ','">
                                      </div>
                                  </div>
                              </li>
                          </ul>
                      </div>
                  </div>
                  <div class="dialog-buttons">
                      <span class="dialog-button color-red closeBtn" @click="closeTagsDialog">Đóng</span>
                      <span class="dialog-button createReferenceChatRoomBtn" @click="changeTags">Cập nhật</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="dialog dialog-wrap dialog-info">
              <div class="dialog-padding">
                <div class="dialog dialog-inside">
                  <div class="dialog-inner" style="padding-bottom: 0;">
                      <div class="dialog-title">Thông tin task</div>
                      <div class="dialog-text" style="width: 100%; user-select: initial; text-align: center; margin-bottom: 0.5rem">Cập nhật thông tin cho task</div>

                      <div class="list media-list" style="margin-left: -15px; margin-right: -15px; margin-bottom: 0; margin-top: 0; text-align: left;">
                          <ul>
                              <li class="item-content item-input">
                                  <div class="item-media"><i class="icon f7-icons text-color-default">doc_text_fill</i></div>
                                  <div class="item-inner">
                                      <div class="item-title item-label">Mô tả (*)</div>
                                      <div class="item-input-wrap">
                                      <textarea class="field" name="description" placeholder="Mô tả cho task"></textarea>
                                      </div>
                                  </div>
                              </li>
                          </ul>
                      </div>
                  </div>
                  <div class="dialog-buttons">
                      <span class="dialog-button color-red closeBtn" @click="closeInfoDialog">Đóng</span>
                      <span class="dialog-button" @click="changeInfo">Cập nhật</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="dialog dialog-wrap dialog-outside-link">
              <div class="dialog-padding">
                <div class="dialog dialog-inside">
                  <div class="dialog-inner" style="padding-bottom: 0;">
                      <div class="dialog-title">Thông tin task</div>
                      <div class="dialog-text" style="width: 100%; user-select: initial; text-align: center; margin-bottom: 0.5rem">Cập nhật thông tin cho task</div>

                      <div class="list media-list" style="margin-left: -15px; margin-right: -15px; margin-bottom: 0; margin-top: 0; text-align: left;">
                          <ul>
                              <li class="item-content item-input">
                                  <div class="item-media"><i class="icon f7-icons text-color-default">link_circle_fill</i></div>
                                  <div class="item-inner">
                                      <div class="item-title item-label">Link (*)</div>
                                      <div class="item-input-wrap">
                                      <input type="text" class="field" name="Link" placeholder="Liên kết" />
                                      </div>
                                  </div>
                              </li>
                              <li class="item-content item-input">
                                  <div class="item-media"><i class="icon f7-icons text-color-default">doc_text_fill</i></div>
                                  <div class="item-inner">
                                      <div class="item-title item-label">Mô tả (*)</div>
                                      <div class="item-input-wrap">
                                      <textarea class="field" name="Description" placeholder="Mô tả cho task"></textarea>
                                      </div>
                                  </div>
                              </li>
                          </ul>
                      </div>
                  </div>
                  <div class="dialog-buttons">
                      <span class="dialog-button color-red closeBtn" @click="closeOutsideLinkDialog">Đóng</span>
                      <span class="dialog-button" @click="addOutsideLink">Thêm</span>
                  </div>
                </div>
              </div>
            </div>

          </div>
        `,
        style: `
        `,
        data(): { title?: string, attachments?: Attachment[], memberList?: Member[], referenceChatRooms?: ChatRoomModel[], reminders: TaskReminderModel[], memberListForchoosed: any, outsideLinks?: ChatRoomOutsideLinkModel[] } {
          return {
            // title: $this.title,
            attachments: [],
            memberList: [],
            referenceChatRooms: [],
            reminders: [],
            outsideLinks: [],
            memberListForchoosed: any,
          };
        },
        methods: {
          // Price report
          createRelativeVoucherDialog(e, id?: string) {

            const self: F7ComponentContextExtend = this;
            self.$f7.dialog.create({
              title: 'Chứng từ liên quan',
              text: 'Thêm mới chứng từ liên quan',
              buttons: [
                {
                  text: 'Báo giá',
                  onClick: (e) => {
                    $this.rootServices.navigate('/sales-price-report-form/new', {
                      context: {
                        backTitle: $this.title,
                        textColorClass: self.textColorClass,
                        chatRoom: self.$route.context.chatRoom,
                        relativeVouchers: [
                          {
                            id: self?.$route?.context?.chatRoom?.id,
                            text: self?.$route?.context?.chatRoom?.rooInfo$?.value?.Description,
                            type: 'CHATROOM'
                          }
                        ],
                      }
                    });
                  }
                },
                {
                  text: 'Phiếu đặt mua hàng',
                  onClick: (e) => {
                    $this.rootServices.navigate('/purchase/order/new', {
                      context: {
                        backTitle: $this.title,
                        textColorClass: self.textColorClass,
                        chatRoom: self.$route.context.chatRoom,
                        relativeVouchers: [
                          {
                            id: self?.$route?.context?.chatRoom?.id,
                            text: self?.$route?.context?.chatRoom?.rooInfo$?.value?.Description,
                            type: 'CHATROOM'
                          }
                        ],
                        callback: (newVoucher: any) => {
                          self.refresh();
                        }
                      }
                    });
                  }
                },
                {
                  text: 'Phiếu kiểm kho',
                  onClick: (e) => {
                    $this.rootServices.navigate('/warehouse/inventory-adjust-note-form/new', {
                      context: {
                        backTitle: $this.title,
                        textColorClass: self.textColorClass,
                        chatRoom: self.$route.context.chatRoom,
                        relativeVouchers: [
                          {
                            id: self?.$route?.context?.chatRoom?.id,
                            text: self?.$route?.context?.chatRoom?.rooInfo$?.value?.Description,
                            type: 'CHATROOM'
                          }
                        ],
                        callback: (newVoucher: any) => {
                          self.refresh();
                        }
                      }
                    });
                  }
                },
                {
                  text: 'Phiếu thu',
                  onClick: (e) => {
                    $this.rootServices.navigate('/accounting/receipt-voucher-form/new', {
                      context: {
                        backTitle: $this.title,
                        textColorClass: self.textColorClass,
                        chatRoom: self.$route.context.chatRoom,
                        relativeVouchers: [
                          {
                            id: self?.$route?.context?.chatRoom?.id,
                            text: self?.$route?.context?.chatRoom?.rooInfo$?.value?.Description,
                            type: 'CHATROOM'
                          }
                        ],
                        callback: (newVoucher: any) => {
                          self.refresh();
                        }
                      }
                    });
                  }
                },
                {
                  text: 'Phiếu chi',
                  onClick: (e) => {
                    $this.rootServices.navigate('/accounting/payment-voucher-form/new', {
                      context: {
                        backTitle: $this.title,
                        textColorClass: self.textColorClass,
                        chatRoom: self.$route.context.chatRoom,
                        relativeVouchers: [
                          {
                            id: self?.$route?.context?.chatRoom?.id,
                            text: self?.$route?.context?.chatRoom?.rooInfo$?.value?.Description,
                            type: 'CHATROOM'
                          }
                        ],
                        callback: (newVoucher: any) => {
                          self.refresh();
                        }
                      }
                    });
                  }
                },
                {
                  bold: true,
                  color: 'red',
                  text: 'Trở về',
                },
              ],
              verticalButtons: true,
            }).open();

            //

            // id = id || self.$(e.target).closest('li').data('id') || 'new';

            // if ($this.commonService.frameSocket.isFrameMode && id && id !== 'new') {
            //   $this.commonService.frameSocket.emit('request-open-voucher', { id: id, type: 'PRICEREPORT' });
            // } else {
            //   $this.rootServices.navigate('/sales-price-report-form/' + id, {
            //     context: {
            //       backTitle: $this.title,
            //       textColorClass: self.textColorClass,
            //       // memberInfo: $this.currentState.chatRoom.memberList$.getValue().find(f => f.id === id),
            //       chatRoom: self.$route.context.chatRoom,
            //       // reminderList$: self.reminderList$,
            //     }
            //   });
            // }
          },
          openPriceReport(e, id?: string) {
            const self: F7ComponentContextExtend = this;
            id = id || self.$(e.target).closest('li').data('id') || 'new';

            if ($this.commonService.frameSocket.isFrameMode && id && id !== 'new') {
              $this.commonService.frameSocket.emit('request-open-voucher', { id: id, type: 'PRICEREPORT' });
            } else {
              $this.rootServices.navigate('/sales-price-report-form/' + id, {
                context: {
                  backTitle: $this.title,
                  textColorClass: self.textColorClass,
                  // memberInfo: $this.currentState.chatRoom.memberList$.getValue().find(f => f.id === id),
                  chatRoom: self.$route.context.chatRoom,
                  // reminderList$: self.reminderList$,
                }
              });
            }
          },
          // End Price Report
          // Resources
          openResource(e, id?: string) {
            const self: F7ComponentContextExtend = this;
            id = id || self.$(e.target).closest('li').data('id') || 'new';
            const type = self.$(e.target).closest('li').data('type');
            const resource = self.resources?.find(f => f.id == id);
            if (false && $this.commonService.isFrameMode) { // Do not emit to main frame
              if (resource) {
                $this.commonService.frameSocket.emit('request-open-voucher', resource);
              }
            } else {
              if (type == 'PRICEREPORT') {
                self.openPriceReport(null, id);
              }
              if (type == 'PURCHASEORDER') {
                $this.rootServices.navigate('/purchase/order/' + id, {
                  context: {
                    backTitle: $this.title,
                    textColorClass: self.textColorClass,
                    chatRoom: self.$route.context.chatRoom,
                  }
                });
              }
              if (type == 'INVENTORYADJUST') {
                $this.rootServices.navigate('/warehouse/inventory-adjust-note-form/' + id, {
                  context: {
                    backTitle: $this.title,
                    textColorClass: self.textColorClass,
                    chatRoom: self.$route.context.chatRoom,
                  }
                });
              }
              if (type == 'PAYMENT') {
                $this.rootServices.navigate('/accounting/payment-voucher-form/' + id, {
                  context: {
                    backTitle: $this.title,
                    textColorClass: self.textColorClass,
                    chatRoom: self.$route.context.chatRoom,
                  }
                });
              }
              if (type == 'RECEIPT') {
                $this.rootServices.navigate('/accounting/receipt-voucher-form/' + id, {
                  context: {
                    backTitle: $this.title,
                    textColorClass: self.textColorClass,
                    chatRoom: self.$route.context.chatRoom,
                  }
                });
              }
            }
            // $this.rootServices.navigate('/sales-price-report-form/' + id, {
            //   context: {
            //     backTitle: $this.title,
            //     textColorClass: self.textColorClass,
            //     // memberInfo: $this.currentState.chatRoom.memberList$.getValue().find(f => f.id === id),
            //     chatRoom: self.$route.context.chatRoom,
            //     // reminderList$: self.reminderList$,
            //   }
            // });
          },
          // End Resource
          copyRoomCode() {
            const self: F7ComponentContextExtend = this;
            $this.rootServices.clipboard.copy(self.chatRoom.id);
            $this.commonService.showInfo('Copy: ' + self.chatRoom.id);
          },
          reportLastMembersLocation(e) {
            const self: F7ComponentContextExtend = this;
            if ($this.rootServices.authService.getUser().core.name.indexOf('core.probox.vn') > -1) {
              $this.commonService.navigate('/google-maps', {
                context: {
                  markers: self.$route.context.chatRoom.memberList$.getValue().filter(f => !!f.Latitude).map(member => ({
                    title: `<div class="text-color-default">${member.Name}` + (member.ShortName ? ` (${member.ShortName})` : '') + '<br> Hoạt động lần cuối vào ' + moment(member.LastReport).fromNow() + '<br>' + $this.rootServices.datePipe.transform(new Date(member.LastReport), 'short') + '</div>',
                    position: {
                      lat: member.Latitude,
                      lng: member.Longitude,
                    },
                    label: moment(member.LastReport).fromNow(),
                    image: member.Avatar?.payload?.thumbnail,
                  })),
                }
              });
            } else {
              self.$app.dialog.alert('Comming soon');
            }
          },
          openTaskActivities(e) {
            const self: F7ComponentContextExtend = this;
            // const id = self.$(e.target).closest('li').data('id');
            $this.rootServices.navigate('/task-activities', {
              context: {
                backTitle: $this.title,
                textColorClass: self.textColorClass,
                chatRoom: self.$route.context.chatRoom,
              }
            });
          },
          openAddReminderForm(e: MouseEvent) {
            const self: F7ComponentContextExtend = this;
            // const id = self.$(e.target).closest('li').data('id');
            $this.rootServices.navigate('/task-reminder/new', {
              context: {
                backTitle: $this.title,
                textColorClass: self.textColorClass,
                // memberInfo: $this.currentState.chatRoom.memberList$.getValue().find(f => f.id === id),
                chatRoom: self.$route.context.chatRoom,
                reminderList$: self.reminderList$,
              }
            });
          },
          openReminderForm(e) {
            const self: F7ComponentContextExtend = this;
            const no = self.$(e.target).closest('li').data('id');
            $this.rootServices.navigate('/task-reminder/' + no, {
              context: {
                backTitle: $this.title,
                textColorClass: self.textColorClass,
                chatRoom: self.$route.context.chatRoom,
                reminder: self.reminders && self.reminders.find(f => f.No == no),
                reminderList$: self.reminderList$,
                // memberList: self.$route.context.chatRoom.memberList$.getValue().filter(f => f.type == 'USER'),
              }
            });
          },
          // Add reminder dialog
          addReminder(e: MouseEvent) {
            const self: F7ComponentContextExtend = this;
            const contact: Member = {};
            const fieldEles = self.addReminderDialog.$el.find('.field');
            fieldEles.each((index, fieldEle) => {
              console.log(fieldEle);
              console.log(fieldEle.value);
              contact[fieldEle.name] = fieldEle.value;
            });
            if (!contact.Phone) {
              console.error('Bạn chưa điền số điện thoại');
              $this.commonService.showError('Bạn chưa điền số điện thoại');
              return;
            }
            if (!contact.Name) {
              console.error('Bạn chưa điền tên');
              $this.commonService.showError('Bạn chưa điền tên');
              return;
            }
            console.log(contact);
            self.$app.preloader.show();
            try {
              $this.currentState.chatRoom.addMember({
                Type: 'CONTACT',
                id: contact.Phone,
                Phone: contact.Phone,
                Name: contact.Name,
                Address: contact.Address,
                Email: contact.Email,
              }).then(rs => {
                self.$app.preloader.hide();
                console.debug('Complete add member to chat rooom', rs);
                $this.commonService.showInfo('Đã thêm 1 thông tin liên hệ');
                self.closeAddContactDialog();
              }).catch(err => {
                self.$app.preloader.hide();
                console.error(err);
                $this.commonService.showError(err);
              });
            } catch (err) {
              console.error(err);
              self.$app.preloader.hide();
              $this.commonService.showError(err);
            }
          },
          removeReminderConfirm(e) {
            const self: F7ComponentContextExtend = this;
            const no = self.$(e.target).closest('li').data('id') || self.$(e.target).data('id');
            const reminder = self.reminderList$.getValue().find(f => f.No == no);
            const currentSwipeout: any = self.$(e.target).closest('li');
            const confirmRemove = self.$app.actions.create({
              buttons: [
                [
                  {
                    text: 'Gở ' + reminder.Title + ' khỏi task ?',
                    label: true,
                  },
                  {
                    text: 'Gở ' + reminder.Title,
                    color: 'red',
                    icon: '<i class="icon f7-icons">text_badge_minus</i>',
                    bold: true,
                    onClick() {
                      // self.skipUpdateMemberList = true;

                      $this.rootServices.apiService.deletePromise('/task/reminders/' + self.$route.context.chatRoom.id + '-' + no, { chatRoom: self.$route.context.chatRoom.id }).then(rs => {
                        self.$app.swipeout.close(currentSwipeout[0]);
                        setTimeout(() => {
                          const reminders = self.reminderList$.getValue().filter(f => f.No != no);
                          self.reminderList$.next(reminders);
                        }, 500);
                      }).catch(err => {
                        $this.commonService.showError(err);
                        self.$app.swipeout.close(currentSwipeout[0]);
                      });

                    }
                  }
                ],
                [{
                  text: 'Trở về',
                  icon: '<i class="icon f7-icons">arrow_down_circle_fill</i>',
                  bold: true,
                  onClick() {
                    self.$app.swipeout.close(currentSwipeout[0]);
                  }
                }],
              ] as any
            });
            confirmRemove.open();
            return true;
          },
          closeAddReminderDialog() {
            const self: F7ComponentContextExtend = this;
            self.addReminderDialog && self.addReminderDialog.close();
          },
          // Add contact dialog
          openAddContactDialog(e: MouseEvent) {
            const self: F7ComponentContextExtend = this;
            // $this.openCreateTicketDialog();
            if (self.addContactDialog) {

              self.$app.dialog.create({
                title: $this.rootServices.env.name || 'Smart-BOT',
                // text: 'Liên hệ này chưa bấm quan tâm, bạn có muốn gửi lời mời (zns) không ?',
                text: 'Bạn muốn thêm liên hệ mới hay liên hệ sẵn có ?',
                verticalButtons: true,
                buttons: [
                  {
                    text: 'Tạo liên hệ mới',
                    color: 'green',
                    onClick() {
                      self.addContactDialog.open();
                      self.addContactDialog.$el.find('.field[name="name"]').focus();
                    },
                  },
                  {
                    text: 'Liên hệ có sẵn',
                    color: 'blue',
                    onClick() {
                      $this.commonService.navigate('/contacts', {
                        context: {
                          callback: (contacts: ContactModel[]) => {
                            console.log(contacts);
                            if (contacts && contacts.length > 0) {
                              for (const contact of contacts) {
                                $this.commonService.showPreloader();

                                const member: Member = {
                                  Type: 'CONTACT',
                                  id: contact.Code,
                                  Name: contact.Name,
                                };

                                if (contact?.Platform) {
                                  if (contact.Platform == 'ZALO') {
                                    member.References = {
                                      ZaloAppId: contact['AppUid'],
                                      ZaloUser: contact['UserUid'],
                                    };
                                  } else {
                                    member.RefPlatform = contact.Platform;
                                    member.RefUserUuid = contact.UserUid;
                                    member.Page = contact.Page;
                                    member.RefType = contact.RefType;
                                  }
                                }

                                $this.currentState.chatRoom.addMember(member).then(rs => {
                                  $this.commonService.hidePreloader();
                                  console.debug('Complete add member to chat rooom', rs);
                                  $this.commonService.showInfo('Đã thêm thông tin liên hệ: ' + contact.Name);
                                }).catch(err => {
                                  $this.commonService.hidePreloader();
                                  console.error(err);
                                  $this.commonService.showError(err);
                                });
                              }
                            }
                          }
                        }
                      })
                    },
                  },
                  {
                    text: 'Liên hệ ngoài hệ thống',
                    color: 'blue',
                    onClick() {
                      self.$app.dialog.prompt('Thêm liên hệ từ core khác, vd: hunganh99@core.domain.com hoặc id: 99902496', 'Thêm liên hệ ngoài hệ thống', uri => {

                        const member: Member = {
                          Type: 'CONTACT',
                          RefPlatform: 'PROBOXONE',
                          RefUserUuid: uri,
                          id: uri,
                          RefConnectState: 'CONNECTED'
                        };

                        $this.currentState.chatRoom.addMember(member).then(rs => {
                          $this.commonService.hidePreloader();
                          console.debug('Complete add member to chat rooom', rs);
                          $this.commonService.showInfo('Đã thêm thông tin liên hệ ngoài hệ thống');
                        }).catch(err => {
                          $this.commonService.hidePreloader();
                          console.error(err);
                          $this.commonService.showError(err);
                        });

                      });
                    },
                  },
                  {
                    text: 'Trở về',
                    color: 'red',
                    bold: true,
                    onClick() {
                    },
                  },
                ]
              }).open();
            }
          },
          addContact(e: MouseEvent) {
            const self: F7ComponentContextExtend = this;
            const contact: Member = {};
            const fieldEles = self.addContactDialog.$el.find('.field');
            fieldEles.each((index, fieldEle) => {
              console.log(fieldEle);
              console.log(fieldEle.value);
              contact[fieldEle.name] = fieldEle.value;
            });
            if (!contact.Phone) {
              console.error('Bạn chưa điền số điện thoại');
              $this.commonService.showError('Bạn chưa điền số điện thoại');
              return;
            }
            if (!contact.Name) {
              console.error('Bạn chưa điền tên');
              $this.commonService.showError('Bạn chưa điền tên');
              return;
            }
            console.log(contact);
            self.$app.preloader.show();
            try {
              $this.currentState.chatRoom.addMember({
                Type: 'CONTACT',
                id: contact.Phone,
                Phone: contact.Phone,
                Name: contact.Name,
                Address: contact.Address,
                Email: contact.Email,
              }).then(rs => {
                self.$app.preloader.hide();
                console.debug('Complete add member to chat rooom', rs);
                $this.commonService.showInfo('Đã thêm 1 thông tin liên hệ');
                self.closeAddContactDialog();
              }).catch(err => {
                self.$app.preloader.hide();
                console.error(err);
                $this.commonService.showError(err);
              });
            } catch (err) {
              console.error(err);
              self.$app.preloader.hide();
              $this.commonService.showError(err);
            }
          },
          closeAddContactDialog() {
            const self: F7ComponentContextExtend = this;
            self.addContactDialog && self.addContactDialog.close();
          },
          // Change tags dialog
          openTagsDialog(e: MouseEvent) {
            const self: F7ComponentContextExtend = this;
            // $this.openCreateTicketDialog();
            if (self.tagsDialog) {
              self.tagsDialog.open();
              const tags = $this.currentState.chatRoom.rooInfo$.getValue().Tags;
              self.tagsDialog.$el.find('[name="tags"]').val(tags);
              self.tagsDialog.$el.find('.field[name="tags"]').focus();
            }
          },
          changeTags(e) {
            const self: F7ComponentContextExtend = this;
            const currentState = $this.state[self.$route.params.id];
            // const tags = currentState.chatRoom.rooInfo$.getValue().tags;
            const newTags = self.tagsDialog.$el.find('[name="tags"]').val();
            currentState.chatRoom.updateRoomInfo({ tags: newTags.split(',').map(tag => tag.trim()) });
            self.closeTagsDialog();
            // self.$app.dialog.prompt('Mỗi thẻ phân cách bằng dấu `,`', 'Cập nhật thẻ', (newTags) => {
            //   console.log(newTags);
            //   if (newTags) {
            //     currentState.chatRoom.updateRoomInfo({ tags: newTags.split(',').map(tag => tag.trim()) });
            //   }
            // }, () => {
            //   console.log('cancel');
            // }, tags && typeof tags === 'object' && tags.join(', ') || '');
          },
          removeTag(e) {
            const self: F7ComponentContextExtend = this;
            const currentState = $this.state[self.$route.params.id];
            const tag = self.$(e.target).closest('a').data('tag') || self.$(e.target).data('tag');
            const tags = (currentState.chatRoom.rooInfo$.getValue().Tags || []).filter(f => f !== tag);
            currentState.chatRoom.updateRoomInfo({ tags: tags });
          },
          closeTagsDialog() {
            const self: F7ComponentContextExtend = this;
            self.tagsDialog && self.tagsDialog.close();
          },
          // Change task info dialog
          openInfoDialog(e: MouseEvent) {
            const self: F7ComponentContextExtend = this;
            // $this.openCreateTicketDialog();
            if (self.infoDialog) {
              self.infoDialog.open();
              const description = $this.currentState.chatRoom.rooInfo$.getValue().Description;
              self.infoDialog.$el.find('[name="description"]').val(description);
              self.infoDialog.$el.find('.field[name="description"]').focus();
            }
          },
          changeInfo(e) {
            const self: F7ComponentContextExtend = this;
            const currentState = $this.state[self.$route.params.id];
            // const tags = currentState.chatRoom.rooInfo$.getValue().tags;
            const newDescription = self.infoDialog.$el.find('[name="description"]').val();
            currentState.chatRoom.updateRoomInfo({ description: newDescription });
            self.closeInfoDialog();
            // self.$app.dialog.prompt('Mỗi thẻ phân cách bằng dấu `,`', 'Cập nhật thẻ', (newTags) => {
            //   console.log(newTags);
            //   if (newTags) {
            //     currentState.chatRoom.updateRoomInfo({ tags: newTags.split(',').map(tag => tag.trim()) });
            //   }
            // }, () => {
            //   console.log('cancel');
            // }, tags && typeof tags === 'object' && tags.join(', ') || '');
          },
          closeInfoDialog() {
            const self: F7ComponentContextExtend = this;
            self.infoDialog && self.infoDialog.close();
          },
          openOutsideLinkDialog(e: MouseEvent) {
            const self: F7ComponentContextExtend = this;
            // $this.openCreateTicketDialog();
            if (self.addOutsideLinkDialog) {
              self.addOutsideLinkDialog.open();
              const description = $this.currentState.chatRoom.rooInfo$.getValue().Description;
              // self.addOutsideLinkDialog.$el.find('[name="description"]').val(description);
              // self.addOutsideLinkDialog.$el.find('.field[name="description"]').focus();
            }
          },
          addOutsideLink(e) {
            const self: F7ComponentContextExtend = this;
            const currentState = $this.state[self.$route.params.id];
            // const tags = currentState.chatRoom.rooInfo$.getValue().tags;
            const description = self.addOutsideLinkDialog.$el.find('[name="Description"]').val();
            const link = self.addOutsideLinkDialog.$el.find('[name="Link"]').val();
            $this.commonService.showPreloader();
            $this.rootServices.apiService.postPromise('/chat/room-outside-links', { chatRoom: self.$route.context.chatRoom.id }, [{ Description: description, Link: link }]).then(rs => {
              $this.commonService.hidePreloader();
            }).catch(err => {
              $this.commonService.showError(err);
              $this.commonService.hidePreloader();
            });
            self.closeOutsideLinkDialog();
            // self.$app.dialog.prompt('Mỗi thẻ phân cách bằng dấu `,`', 'Cập nhật thẻ', (newTags) => {
            //   console.log(newTags);
            //   if (newTags) {
            //     currentState.chatRoom.updateRoomInfo({ tags: newTags.split(',').map(tag => tag.trim()) });
            //   }
            // }, () => {
            //   console.log('cancel');
            // }, tags && typeof tags === 'object' && tags.join(', ') || '');
          },
          openOutsideLink(e) {
            const self: F7ComponentContextExtend = this;
            const id = self.$(e.target).closest('li').data('id') || self.$(e.target).data('id');
            const outsideLink = (self.outsideLinks || []).find(f => f.Id === id);
            if (outsideLink) {
              $this.rootServices.iab.create(outsideLink.Link, '_system');
            }
          },
          removeOutsideLinkConfirm(e) {
            const self: F7ComponentContextExtend = this;
            const id = self.$(e.target).closest('li').data('id') || self.$(e.target).data('id');
            const outsideLink = (self.outsideLinks || []).find(f => f.Id === id);
            const currentSwipeout: any = self.$(e.target).closest('li');
            const confirmRemove = self.$app.actions.create({
              buttons: [
                [
                  {
                    text: 'Gở Link ' + outsideLink.Description + ' khỏi phòng chat ?',
                    label: true,
                  },
                  {
                    text: 'Gở link',
                    color: 'red',
                    icon: '<i class="icon f7-icons">text_badge_minus</i>',
                    bold: true,
                    onClick() {
                      self.skipUpdateMemberList = true;
                      $this.rootServices.apiService.deletePromise('/chat/room-outside-links/' + id, { chatRoom: self.$route.context.chatRoom.id }).then(rs => {
                        self.$app.swipeout.close(currentSwipeout[0], () => {
                          self.refresh();
                        });
                        console.log(rs);
                      }).catch(err => {
                        self.$app.swipeout.close(currentSwipeout[0]);
                        $this.commonService.showError(err);
                      });
                    }
                  }
                ],
                [{
                  text: 'Trở về',
                  icon: '<i class="icon f7-icons">arrow_down_circle_fill</i>',
                  bold: true,
                  onClick() {
                    self.$app.swipeout.close(currentSwipeout[0]);
                  }
                }],
              ] as any
            });
            confirmRemove.open();
            return true;
          },
          closeOutsideLinkDialog() {
            const self: F7ComponentContextExtend = this;
            self.addOutsideLinkDialog && self.addOutsideLinkDialog.close();
          },
          // Reference task dialog control
          openReferenceTaskDialog(e: MouseEvent) {
            const self: F7ComponentContextExtend = this;

            self.$app.dialog.create({
              title: 'Tạo liên kết',
              text: 'Bạn muốn liên kết với task cha, task con hay tự tạo task con mới ?',
              verticalButtons: true,
              buttons: [
                {
                  text: 'Liên bên thứ 3',
                  color: 'blue',
                  onClick: () => {
                    self.openOutsideLinkDialog();
                  },
                },
                {
                  text: 'Liên kết task cha',
                  // color: '',
                  onClick: () => {
                    $this.commonService.navigate('/workplace/choose-parent', {
                      context: {
                        backTitle: 'Chat GUI',
                        title: 'Chọn task cha',
                        extendComponentId: 'choose-for-' + self.$route.context.chatRoom.id,
                        callback: (task: ChatRoomModel) => {
                          console.log('choosed task:', task);
                          $this.rootServices.apiService.putPromise('/chat/rooms', { id: [self.$route.context.chatRoom.id], addReferenceChatRoom: true, parentChatRoom: task.Code, parentDescription: task.Description }, [{ Code: self.$route.context.chatRoom.id }]).then(rs => {
                            $this.commonService.showInfo('Đã thêm vào task chat');
                            self.refresh().then(rs => {
                              if (self.hadUpdate) {
                                self.hadUpdate('update-chat-room-references');
                              }
                            });
                          }).catch(err => {
                            $this.commonService.showError(err);
                          });
                        },
                      }
                    });
                  },
                },
                {
                  text: 'Liên kết task con',
                  // color: '',
                  onClick: () => {
                    $this.commonService.navigate('/workplace/choose-child', {
                      context: {
                        backTitle: 'Chat GUI',
                        title: 'Chọn task con',
                        extendComponentId: 'choose-for-' + self.$route.context.chatRoom.id,
                        callback: (task: ChatRoomModel) => {
                          console.log('choosed task:', task);
                          $this.rootServices.apiService.putPromise('/chat/rooms', { id: [self.$route.context.chatRoom.id], addReferenceChatRoom: true, childChatRoom: task.Code, childDescription: task.Description }, [{ Code: self.$route.context.chatRoom.id }]).then(rs => {
                            $this.commonService.showInfo('Đã thêm vào task chat');
                            self.refresh().then(rs => {
                              if (self.hadUpdate) {
                                self.hadUpdate('update-chat-room-references');
                              }
                            });
                          }).catch(err => {
                            $this.commonService.showError(err);
                          });
                        },
                      }
                    });
                  },
                },
                {
                  text: 'Liên kết tương tác cha',
                  color: 'helpdesk-text-red',
                  onClick: () => {
                    $this.commonService.navigate('/helpdesk/choose-parent', {
                      context: {
                        backTitle: 'Chat GUI',
                        title: 'Chọn task cha',
                        extendComponentId: 'choose-for-' + self.$route.context.chatRoom.id,
                        callback: (task: ChatRoomModel) => {
                          console.log('choosed task:', task);
                          $this.rootServices.apiService.putPromise('/chat/rooms', { id: [self.$route.context.chatRoom.id], addReferenceChatRoom: true, parentChatRoom: task.Code, parentDescription: task.Description }, [{ Code: self.$route.context.chatRoom.id }]).then(rs => {
                            $this.commonService.showInfo('Đã thêm vào task chat');
                            self.refresh().then(rs => {
                              if (self.hadUpdate) {
                                self.hadUpdate('update-chat-room-references');
                              }
                            });
                          }).catch(err => {
                            $this.commonService.showError(err);
                          });
                        },
                      }
                    });
                  },
                },
                {
                  text: 'Liên kết tương tác con',
                  color: 'helpdesk-text-red',
                  onClick: () => {
                    $this.commonService.navigate('/helpdesk/choose-child', {
                      context: {
                        backTitle: 'Chat GUI',
                        title: 'Chọn task con',
                        extendComponentId: 'choose-for-' + self.$route.context.chatRoom.id,
                        callback: (task: ChatRoomModel) => {
                          console.log('choosed task:', task);
                          $this.rootServices.apiService.putPromise('/chat/rooms', { id: [self.$route.context.chatRoom.id], addReferenceChatRoom: true, childChatRoom: task.Code, childDescription: task.Description }, [{ Code: self.$route.context.chatRoom.id }]).then(rs => {
                            $this.commonService.showInfo('Đã thêm vào task chat');
                            self.refresh().then(rs => {
                              if (self.hadUpdate) {
                                self.hadUpdate('update-chat-room-references');
                              }
                            });
                          }).catch(err => {
                            $this.commonService.showError(err);
                          });
                        },
                      }
                    });
                  },
                },
                {
                  text: 'Tạo task con mới',
                  color: 'green',
                  onClick: () => {
                    self.openCreateChildTaskDialog();
                  },
                },
                {
                  text: 'Đóng',
                  color: 'red',
                  bold: true,
                  onClick: () => { },
                },
              ],
            }).open();

            // $this.openCreateTicketDialog();

          },
          openCreateChildTaskDialog() {
            const self: F7ComponentContextExtend = this;
            if (self.referenceTaskDialog) {

              const memberList = self.memberList.filter(f => f.id !== $this.rootServices.authService.getUser().id);
              const contactList = memberList.filter(member => member.Type === 'CONTACT');

              const nonGroupMembers = memberList.filter(member => member.Type === 'USER' && !member.Group);
              const groupMembers = memberList.filter(member => member.Group);
              const contacts = memberList.filter(member => member.Type === 'CONTACT');
              let groups = memberList.filter(member => member.Group && !member.User);
              groups = groups.map(group => {
                return {
                  ...group,
                  roles: group.Roles && group.Roles.map(role => ($this.currentState.roles[role] && $this.currentState.roles[role].text) || role) as any,
                  children: groupMembers.filter(member => member.Type === 'USER' && (member.Group && member.Group.id || member.Group) === group.id),
                };
                // group.roles = group.roles && group.roles.map(role => ($this.currentState.roles[role] && $this.currentState.roles[role].text) || role) as any;
                // group.children = groupMembers.filter(member => member.type === 'USER' && (member.group && member.group.id || member.group) === group.id);
              });

              self.$setState({
                memberListForchoosed: {
                  contactList: contactList.map(contact => {
                    return {
                      ...contact,
                      Roles: contact.Roles && contact.Roles.map(role => ($this.currentState.roles[role] && $this.currentState.roles[role].text) || role),
                    };
                  }),
                  memberNonGroupList: nonGroupMembers.map(member => {
                    return {
                      ...member,
                      Roles: member.Roles && member.Roles.map(role => ($this.currentState.roles[role] && $this.currentState.roles[role].text) || role),
                    };
                  }),
                  groups: groups.map(group => {
                    group.Children = group.Children.map(member => {
                      return {
                        ...member,
                        Roles: (member.Roles) && member.Roles.map(role => ($this.currentState.roles[role] && $this.currentState.roles[role].text) || role) as any,
                      };
                    });
                    return group;
                  }),
                  contacts: contacts.map(contact => {
                    return {
                      ...contact,
                      Roles: contact.Roles && contact.Roles.map(role => $this.currentState.roles && $this.currentState.roles[role] && $this.currentState.roles[role].text || role) as any,
                    };
                  }),
                }
              });

              self.referenceTaskDialog.open();
              // self.referenceTaskDialog.$el.find('.field[name="name"]').focus();
            }
          },
          removeTaskReference(e) {
            const self: F7ComponentContextExtend = this;
            const currentSwipeout: any = self.$(e.target).closest('li');
            const id = currentSwipeout.data('id') || self.$(e.target).data('id');
            if (id) {

              const currentState = $this.state[self.$route.params.id];
              const referenceRoom = currentState.referenceChatRooms.find(f => f.Code == id);

              const currentSwipeout: any = self.$(e.target).closest('li');
              const confirmRemove = self.$app.actions.create({
                buttons: [
                  [
                    {
                      text: `Ban có chắc là muốn gở ${referenceRoom.Description} ra khỏi task`,
                      label: true,
                    },
                    {
                      text: `Gở ra khỏi task`,
                      color: 'red',
                      icon: '<i class="icon f7-icons">text_badge_minus</i>',
                      bold: true,
                      onClick() {
                        $this.rootServices.apiService.putPromise('/chat/rooms/' + self.$route.context.chatRoom.id, { removeReferenceChatRoom: true, referenceChatRoom: id }, [{ Code: self.$route.context.chatRoom.id }]).then(rs => {
                          self.$app.swipeout.close(currentSwipeout[0], () => {
                            self.refresh().then(rs => {
                              if (self.hadUpdate) {
                                self.hadUpdate('update-chat-room-references');
                              }
                            });
                          });
                        });
                      }
                    }
                  ],
                  [{
                    text: 'Trở về',
                    icon: '<i class="icon f7-icons">arrow_down_circle_fill</i>',
                    bold: true,
                    onClick() {
                      self.$app.swipeout.close(currentSwipeout[0]);
                    }
                  }],
                ] as any
              });
              confirmRemove.open();

            }
          },
          closeReferenceTaskDialog() {
            const self: F7ComponentContextExtend = this;
            self.referenceTaskDialog && self.referenceTaskDialog.close();
          },
          async createReferenceTask(e: MouseEvent) {
            const self: F7ComponentContextExtend = this;
            const currentState = $this.state[self.$route.params.id];
            const dialog = self.referenceTaskDialog.$el;
            // const msgIndex = dialog.data('msgindex');
            // const message = currentState.chatRoom.messageList.find(f => f.index === msgIndex);

            const description = dialog.find('[name="Description"]').val();

            const members: string[] = [];
            dialog.find('input.member-item:checked').each((i, item) => {
              members.push(item.attributes['data-id'].value);
            });


            $this.commonService.showPreloader();
            try {
              const newChatRoom = await currentState.chatRoom.createReferenceChatRoom(description, { members: members });
              if (newChatRoom) {
                // console.log('message index: ' + msgIndex);
                self.closeReferenceTaskDialog();
                $this.rootServices.navigate('/chat-room/' + newChatRoom.id);
              }

              $this.commonService.hidePreloader();
              self.refresh().then(rs => {
                if (self.hadUpdate) {
                  self.hadUpdate('update-chat-room-references');
                }
              });
            } catch (err) {
              console.log(err);
              $this.commonService.hidePreloader();
              $this.commonService.showError(err);
            }
          },
          //Notification settings dialog
          openNotificationSettinsDialog(e) {
            const self: F7ComponentContextExtend = this;

            self.notificationSettinsDialog && self.notificationSettinsDialog.open();
            // console.log('Current filter: ', self.filter);
          },
          closeNotificationSettinsDialog(e) {
            const self: F7ComponentContextExtend = this;

            (async (callback) => {
              // const userSettings = $this.rootServices.socketService.info$.getValue().settings;
              const userSettings = await $this.rootServices.settings$.pipe(takeUntil($this.currentState.destroy$), filter(f => f !== null), take(1)).toPromise();
              if (userSettings && userSettings.NOTIFICATION_SETTINGS && (userSettings.NOTIFICATION_SETTINGS.DISABLEDNEWMESSAGE || userSettings.NOTIFICATION_SETTINGS.DISABLEDMENTION || userSettings.NOTIFICATION_SETTINGS.DISABLEDREPLY)) {
                self.$app.actions.create({
                  buttons: [
                    [
                      {
                        text: 'Cài đặt thông báo của task này sẽ không phụ thuộc vào cài đặt chung !',
                        label: true,
                      },
                      {
                        text: 'Đồng ý',
                        color: 'red',
                        icon: '<i class="icon f7-icons">checkmark_alt_circle_fill</i>',
                        bold: true,
                        onClick() {
                          callback();
                        }
                      }
                    ],
                    [{
                      text: 'Trở về',
                      icon: '<i class="icon f7-icons">arrow_down_circle_fill</i>',
                      // color: 'red',
                      bold: true,
                      onClick() {
                        self.notificationSettinsDialog.close();
                      }
                    }],
                  ] as any
                }).open();
              } else {
                callback();
              }
            })(() => {
              if (self.notificationSettinsDialog) {
                const fieldEles = self.notificationSettinsDialog.$el.find('.field');
                fieldEles.each((index, el) => {
                  self.$(el).prop('checked', parseInt(el.value) < 0 ? true : false);
                });
                const notificationSettings: { [key: string]: any } = {};
                fieldEles.each((index, el) => {
                  console.log(index, el.checked);
                  notificationSettings[el.name] = (el.checked ? 1 : -1) * parseInt(el.value) > 0 ? true : false;
                });
                if (self.$route.context && self.$route.context.chatRoom) {
                  self.$route.context.chatRoom.updateMemberNotificationSettings(notificationSettings);
                }
                self.$setState({ notificationSettings: null });
                self.notificationSettinsDialog.close();
              }
            });

          },
          async applyNotificationSettings(e) {
            const self: F7ComponentContextExtend = this;

            (async (callback) => {
              // const userSettings = $this.rootServices.socketService.info$.getValue().settings;
              const userSettings = await $this.rootServices.settings$.pipe(takeUntil($this.currentState.destroy$), filter(f => f !== null), take(1)).toPromise();
              if (userSettings && userSettings.NOTIFICATION_SETTINGS && (userSettings.NOTIFICATION_SETTINGS.DISABLEDNEWMESSAGE || userSettings.NOTIFICATION_SETTINGS.DISABLEDMENTION || userSettings.NOTIFICATION_SETTINGS.DISABLEDREPLY)) {
                self.$app.actions.create({
                  buttons: [
                    [
                      {
                        text: 'Cài đặt thông báo của task này sẽ không phụ thuộc vào cài đặt chung !',
                        label: true,
                      },
                      {
                        text: 'Đồng ý',
                        color: 'red',
                        icon: '<i class="icon f7-icons">checkmark_alt_circle_fill</i>',
                        bold: true,
                        onClick() {
                          callback();
                        }
                      }
                    ],
                    [{
                      text: 'Trở về',
                      icon: '<i class="icon f7-icons">arrow_down_circle_fill</i>',
                      // color: 'red',
                      bold: true,
                      onClick() {
                        self.notificationSettinsDialog.close();
                      }
                    }],
                  ] as any
                }).open();
              } else {
                callback();
              }
            })(() => {
              self.notificationSettinsDialog && self.notificationSettinsDialog.close();
              const fieldEles = self.notificationSettinsDialog.$el.find('.field');
              console.log(fieldEles);
              const notificationSettings: { [key: string]: any } = {};
              fieldEles.each((index, el) => {
                console.log(index, el.checked);
                notificationSettings[el.name] = (el.checked ? 1 : -1) * parseInt(el.value) > 0 ? true : false;
              });
              console.log(notificationSettings);
              if (self.$route.context && self.$route.context.chatRoom) {
                self.$route.context.chatRoom.updateMemberNotificationSettings(notificationSettings);
              }
              self.$setState({ notificationSettings: Object.keys(notificationSettings).length > 0 ? notificationSettings : null });
              if (notificationSettings['ENABLEDONRETURN']) {
                self.$router.navigate(self.$router.history[0], { force: true });
              }
            });

          },
          // Click2call
          click2call(e) {
            const self: F7ComponentContextExtend = this;
            const memberId = self.$(e.target).closest('li').data('id') || self.$(e.target).data('id');
            const chatRoom = self.$route.context.chatRoom;
            if (!chatRoom) {
              $this.commonService.showError('Không có liên kết với phòng chat nào !');
              return false;
            }
            const member = self.$route.context.chatRoom.memberList$.getValue().find(f => f.id === memberId);
            if (!member) {
              $this.commonService.showError('Thành viên không có trong phòng này !');
              return false;
            }

            self.$app.dialog.create({
              title: 'Click2Call',
              text: 'Tôi sẽ gọi tới số nội bộ của bạn trước, nếu offline tôi sẽ gọi qua số cá nhân, hãy kiểm tra app GS wave đã online chưa hoặc bạn đang ở kế bên điện thoại của mình !',
              buttons: [
                {
                  text: 'Đóng',
                  // color: 'red',
                  onClick() {
                  },
                },
                {
                  text: 'Gọi',
                  color: 'red',
                  onClick() {
                    $this.commonService.showInfo('Đang gọi tới số của bạn...', { timeout: 30000 });
                    $this.rootServices.apiService.putPromise('/chat/room-members/' + memberId, { click2call: true, chatRoom: chatRoom.id }, [{ Type: member.Type, User: member.id, Contact: member.id }]).then(rs => {
                      console.log(rs);
                      $this.commonService.showInfo('Đang chuyển cuộc gọi...', { timeout: 30000 });
                    }).catch(err => {
                      console.error(err);
                      $this.commonService.showError('Lỗi kết nối cuộc gọi');
                    });
                  },
                }
              ]
            }).open();
          },
          // promptChangeDescription(e) {
          //   const self: F7ComponentContextExtend = this;
          //   const currentState = $this.state[self.$route.params.id];
          //   self.$app.dialog.prompt('', 'Cập nhật mô tả', (newDescription) => {
          //     console.log(newDescription);
          //     if (newDescription) {
          //       currentState.chatRoom.updateRoomInfo({ description: newDescription });
          //     }
          //   }, () => {
          //     console.log('cancel');
          //   }, currentState.chatRoom.rooInfo$.getValue().description);
          // },
          openChatRoom(e) {
            const self: F7ComponentContextExtend = this;
            const roomId = self.$(e.target).closest('li').data('id');
            $this.rootServices.navigate('/chat-room/' + roomId, { context: { backTitle: self.title } });
          },
          async refresh(e, done) {
            const self: F7ComponentContextExtend = this;
            const currentState = $this.state[self.$route.params.id];
            const chatRoom = self.$route.context.chatRoom;

            let doneTimeout = null;
            if (done) {
              doneTimeout = setTimeout(() => {
                done();
              }, 10000);
            };

            // let outsideLinks = null;
            // $this.rootServices.authService.isAuthenticatedOrRefresh().pipe(take(1)).toPromise().then(async () => {
            // Load task reminder list

            const awaitAll = [];
            // Load reference chat rooms
            awaitAll.push(self.chatRoom.syncMemberList({ force: true }));
            awaitAll.push(self.chatRoom.syncReferenceList());

            // const chatRoomTree = await $this.rootServices.apiService.getPromise<ChatRoomModel[]>('/chat/rooms', { referenceChatRoom: self.$route.context.chatRoom.id });
            // awaitAll.push(self.chatRoom.references$.pipe(takeUntil(currentState.destroy$), filter(f => f && f.length > 0), take(1)).toPromise().then(references => {
            //   currentState.referenceChatRooms = $this.convertChatRoomTreeToArray(references);
            //   currentState.recferenceChatRoomsLastUpdate = Date.now();
            //   currentState.instance.$setState({
            //     referenceChatRooms: currentState.referenceChatRooms,
            //   });
            // }));


            awaitAll.push($this.rootServices.apiService.getPromise<TaskReminderModel[]>('/task/reminders', { chatRoom: chatRoom.id, sort_NextRemind: 'asc' }).then(taskReminders => {
              self.reminderList$.next(taskReminders);
              return self.reminderList$.value;
            }));
            $this.rootServices.apiService.getPromise<ChatRoomOutsideLinkModel[]>('/chat/room-outside-links', { chatRoom: chatRoom.id }).then(outsideLinks => {
              currentState.instance.$setState({
                outsideLinks: outsideLinks,
              });
              return outsideLinks;
            });
            // awaitAll.push($this.rootServices.apiService.getPromise<PriceReportVoucherModel[]>('/sales/price-reports', { task: chatRoom.id, sort_Id: 'desc' }).then(priceReports => {
            //   priceReports.map(priceReport => {
            //     priceReport.ReportedText = $this.rootServices.datePipe.transform(priceReport.Reported, 'short');
            //     return priceReport;
            //   });
            //   currentState.instance.$setState({
            //     priceReports: priceReports,
            //   });
            //   return priceReports;
            // }));
            awaitAll.push($this.rootServices.apiService.getPromise<any[]>('/chat/rooms/' + chatRoom.id, { includeRelativeVouchers: true }).then(async resources => {
              if (resources[0]?.RelativeVouchers) {
                const purchaseOrders = resources[0]?.RelativeVouchers.filter(f => f.type == 'PURCHASEORDER');
                const purchaseOrdersInfo = await $this.rootServices.apiService.getPromise<any[]>('/purchase/order-vouchers/', { id: purchaseOrders.map(m => m.id) });
                for (const resource of resources[0]?.RelativeVouchers) {
                  resource.typeLabel = $this.typeMap[resource.type]?.label;
                  resource.typeStatus = $this.typeMap[resource.type]?.status;
                  const refPO = purchaseOrdersInfo.find(f => f.Code == resource.id);
                  if(refPO) {
                    resource.date = $this.rootServices.datePipe.transform(refPO.DateOfOrder, 'short');
                  } else {
                    resource.date = $this.rootServices.datePipe.transform(resource.date, 'short');
                  }
                  if(refPO){
                    resource.state = $this.commonService.stateMap[refPO.State];
                    resource.stateLabel = resource.state.label;
                    resource.stateStatus = resource.state.statusColor;
                    resource.Amount = refPO.Amount;
                  }
                }
              }
              currentState.instance.$setState({
                resources: resources[0]?.RelativeVouchers,
              });
              return resources[0]?.Resources;
            }));

            // Waiting for all async complete
            await Promise.all(awaitAll).catch(err => {
              console.error(err);
              if (done) done();
            });
            // console.log('resources : ' + resources);

            // if (currentState.referenceChatRooms) {
            //   currentState.instance.$setState({
            //     referenceChatRooms: currentState.referenceChatRooms,
            //     outsideLinks: outsideLinks,
            //     priceReports: priceReports,
            //     resources,
            //   });
            // }
            if (done) done();
            // }).catch(err => {
            //   if (done) done();
            // });

            // self.$setState({
            //   // attachments: $this.currentState,
            // });
            return true;
          },
          async browseAttachments(e) {
            const self: F7ComponentContextExtend = this;
            const attachmentId = self.$(e.target).closest('li').data('id') || self.$(e.target).data('id');
            $this.browseAttachment($this.currentState.chatRoom.attachments$.getValue(), attachmentId, (attachment => {
              console.debug('download index', attachment);
              if (/image/.test(attachment.Type)) {
                // $this.currentState.instance.$app.dialog.confirm([attachment.name, attachment.description].filter(f => !!f).join('<br>') || 'Đính kèm', 'Xác nhận tải về', async () => {
                //   $this.rootServices.saveImageToPhone(attachment.payload.url as string);
                // });
                $this.currentState.instance.$app.dialog.create({
                  title: 'Xác nhận tải về',
                  content: 'Đính kèm',
                  buttons: [
                    {
                      text: 'Đóng',
                      color: 'red',
                      close: true,
                    },
                    {
                      text: 'Mở link',
                      onClick: () => {
                        $this.rootServices.saveImageToPhone(attachment.Url as string);
                      }
                    }
                  ],
                }).open();
              } else if (/video/.test(attachment.Type)) {
                $this.playVideo((attachment.OriginUrl || attachment.Url) as string);
              } else {
                $this.rootServices.iab.create((attachment.OriginUrl || attachment.Url) as string, '_system');
              }
            })).then(() => {
              console.log('Photo browser was closed');
            });
          },
          addMember() {
            const self: F7ComponentContextExtend = this;
            console.debug('add memeber');
            const currentState = $this.state[self.$route.params.id];
            self.$router.navigate('/users/choose-chatroom-member', {
              context: {
                chatRoom: currentState.chatRoom,
                textColorClass: self.textColorClass,
                onChooseContact(user: User) {
                  console.log(user);
                  $this.currentState.chatRoom.addMember({
                    Type: user.type,
                    id: user.Code,
                    Name: user.Name,
                  }).then(rs => {
                    console.debug('Complete add member to chat rooom', rs);
                  }).catch(err => {
                    console.error(err);
                    $this.commonService.showError(err);
                  });
                },
              },
            });
          },
          removeMemberConfirm(e) {
            const self: F7ComponentContextExtend = this;
            const memberId = self.$(e.target).closest('li').data('id') || self.$(e.target).data('id');
            const member = self.memberList.find(f => f.id == memberId);
            const currentSwipeout: any = self.$(e.target).closest('li');
            const confirmRemove = self.$app.actions.create({
              buttons: [
                [
                  {
                    text: 'Gở ' + (member.Type === 'GROUP' ? 'nhóm thành viên' : 'thành viên') + ' ' + member.Name + ' khỏi phòng chat ?',
                    label: true,
                  },
                  {
                    text: 'Gở ' + member.Name,
                    color: 'red',
                    icon: '<i class="icon f7-icons">text_badge_minus</i>',
                    bold: true,
                    onClick() {
                      self.skipUpdateMemberList = true;
                      $this.currentState.chatRoom.removeMember($this.currentState.chatRoom.memberList$.getValue().find(f => f.id === memberId)).then(rs => {
                        // self.$app.swipeout.delete(currentSwipeout[0]); // neu comment dong nay thi dong tiep theo cung tu dong delete
                        self.skipUpdateMemberList = false;
                        self.$app.swipeout.close(currentSwipeout[0], () => {
                          $this.currentState.chatRoom.syncMemberList();
                        });
                        console.log(rs);
                      }).catch(err => {
                        // self.$app.swipeout.delete(currentSwipeout[0]);
                        self.$app.swipeout.close(currentSwipeout[0]);
                        console.error(err);
                        $this.commonService.showError(err);
                      });
                    }
                  }
                ],
                [{
                  text: 'Trở về',
                  icon: '<i class="icon f7-icons">arrow_down_circle_fill</i>',
                  bold: true,
                  onClick() {
                    self.$app.swipeout.close(currentSwipeout[0]);
                  }
                }],
              ] as any
            });
            confirmRemove.open();
            return true;
          },
          checkMemberLastReportLocation(e) {
            const self: F7ComponentContextExtend = this;
            const memberId = self.$(e.target).closest('li').data('id') || self.$(e.target).data('id');
            const member = self.memberList.find(f => f.id == memberId);
            const currentSwipeout: any = self.$(e.target).closest('li');
            $this.rootServices.iab.create(`https://www.google.com/maps/place/${member.Latitude},${member.Longitude}`, '_system');
            self.$app.swipeout.close(currentSwipeout[0]);
            return true;
          },
          gotoProfile(e) {
            // const self: F7ComponentContextExtend = this;
            // const id = self.$(e.target).closest('li').data('id');
            // const memberInfo = $this.currentState.chatRoom.memberList$.getValue().find(f => f.id === id);
            // $this.rootServices.navigate('/profile/' + id, {
            //   context: {
            //     type: memberInfo.Type,
            //     backTitle: $this.title,
            //     textColorClass: self.textColorClass,
            //     memberInfo: memberInfo,
            //     chatRoom: self.$route.context.chatRoom,
            //   }
            // });
          },
        },
        on: {
          async pageInit(e, page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;
            const chatRoomId = self.$route.params.id;
            const chatRoom = self.$route.context.chatRoom;
            self.memberList = [];
            self.reminderList$ = new BehaviorSubject<TaskReminderModel[]>([]);

            if (!self.$route.context || !self.$route.context.title) {
              self.$setState({
                title: $this.title,
              });
            }

            // price report list
            // self.priceReportList = [
            //   {
            //     Code: 'DSFSDFGDG',
            //     Title: 'Báo giá thiết bị mạng văn phòng',
            //     Note: 'Các hạng mục liên quan tới thiết bị mạng vằn phòng bao gòm router, switch, wifi,...',
            //     ObjectName: 'Cty TNHH MTV Trường Quốc',
            //     ContactName: 'anh Nguyễn Văn Thành',
            //     DateOfReportText: '10:30, 22/12/2021',
            //     TotalText: '12.500.000 đ'
            //   },
            //   {
            //     Code: 'DSFSDFGDG',
            //     Title: 'Báo giá thiết bị mạng văn phòng',
            //     Note: 'Các hạng mục liên quan tới thiết bị mạng vằn phòng bao gòm router, switch, wifi,...',
            //     ObjectName: 'Cty cổ phần dịch dụ vận chuyển',
            //     ContactName: 'anh Lê Văn Hùng',
            //     DateOfReportText: '10:30, 23/12/2021',
            //     TotalText: '12.500.000 đ'
            //   },
            // ];

            await chatRoom.syncMemberList();

            $this.onComponentInit({ instance: self, chatRoom: self.$route.context.chatRoom, page, destroy$: new Subject() }, chatRoomId, 'init').then(async currentState => {

              self.notificationSettinsDialog = self.$app.dialog.create({
                el: page.$el.find('.dialog-notification-settings'),
                closeByBackdropClick: true,
              });

              self.addContactDialog = self.$app.dialog.create({
                el: page.$el.find('.dialog-add-contact'),
                closeByBackdropClick: true,
              });

              self.addReminderDialog = self.$app.dialog.create({
                el: page.$el.find('.dialog-add-reminder'),
                closeByBackdropClick: true,
              });
              self.calendarDateTime = self.$f7.calendar.create({
                inputEl: page.$el.find('.dialog-add-reminder input[name="RemindAtDateTime"]'),
                timePicker: true,
                dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' },
              });
              self.referenceTaskDialog = self.$app.dialog.create({
                el: page.$el.find('.dialog-reference-task'),
                closeByBackdropClick: true,
              });
              self.tagsDialog = self.$app.dialog.create({
                el: page.$el.find('.dialog-tags'),
                closeByBackdropClick: true,
              });
              self.infoDialog = self.$app.dialog.create({
                el: page.$el.find('.dialog-info'),
                closeByBackdropClick: true,
              });
              self.addOutsideLinkDialog = self.$app.dialog.create({
                el: page.$el.find('.dialog-outside-link'),
                closeByBackdropClick: true,
              });

              const fieldEles = self.notificationSettinsDialog.$el.find('.field');
              currentState.chatRoom.memberList$.pipe(filter(f => f && f.length > 0), take(1)).toPromise().then(memberList => {
                const selfMember = memberList.find(f => f.id === $this.rootServices.authService.user$.getValue().id);
                const currentNotificationSettings = selfMember.DisabledNotification || {};
                fieldEles.each((index, el) => {
                  console.log(index, el.checked);
                  // if (el.checked) {
                  self.$(el).prop('checked', parseInt(el.value) * (currentNotificationSettings[el.name] ? 1 : -1) > 0 ? true : false);
                  // notificationSettings[el.name] = (el.checked ? 1 : -1) * parseInt(el.value) > 0 ? true : false;
                  // }
                });
              });

              chatRoom.rooInfo$ && chatRoom.rooInfo$.pipe(takeUntil(currentState.destroy$), filter(f => !!f)).subscribe(roomInfo => {
                self.$setState({
                  Description: roomInfo.Description,
                  Tags: roomInfo.Tags,
                });
              });

              $this.currentState.chatRoom.attachments$.pipe(takeUntil($this.currentState.destroy$)).subscribe(attachments => {
                self.$setState({
                  attachments: attachments.map(att => ({
                    ...att,
                    // Id: `${att.ChatRoom}/${att.MessageNo}/${att.Id}`,
                    DateOfAttach: new Date(att.DateOfAttach).toLocaleString(),
                    // thumbnail: att.Thumbnail ? att.Thumbnail : '',
                    // url: att.Url ? att.Url : '',
                    // payload: {
                    // },
                  })),
                });
              });

              $this.currentState.chatRoom.memberList$.pipe(takeUntil($this.currentState.destroy$)).subscribe(memberList => {
                self.memberList = memberList;
                if (!self.skipUpdateMemberList) {
                  // memberList = memberList.filter(f => f.id !== $this.rootServices.authService.getUser().id);
                  const contactList = memberList.filter(member => member.Type === 'CONTACT');

                  const nonGroupMembers = memberList.filter(member => member.Type === 'USER' && !member.Group);
                  const groupMembers = memberList.filter(member => member.Group);
                  const contacts = memberList.filter(member => member.Type === 'CONTACT');
                  let groups = memberList.filter(member => member.Group && !member.User);
                  groups = groups.map(group => {
                    return {
                      ...group,
                      Roles: group.Roles && group.Roles.map(role => ($this.currentState.roles[role] && $this.currentState.roles[role].text) || role) as any,
                      Children: groupMembers.filter(member => member.Type === 'USER' && (member.Group && member.Group.id || member.Group) === group.id),
                    };
                    // group.roles = group.roles && group.roles.map(role => ($this.currentState.roles[role] && $this.currentState.roles[role].text) || role) as any;
                    // group.children = groupMembers.filter(member => member.type === 'USER' && (member.group && member.group.id || member.group) === group.id);
                  });

                  self.$setState({
                    contactList: contactList.map(contact => {
                      return {
                        ...contact,
                        RefIcon: contact.RefPlatform == 'PROBOXONE' ? (contact.RefConnectState == 'CONNECTED' ? 'assets/icon/probox.ico' : '') : (contact.ZaloUserStatus ? 'assets/icon/zalo-seeklogo.com.svg' : ''),
                        Roles: contact.Roles && contact.Roles.map(role => ($this.currentState.roles[role] && $this.currentState.roles[role].text) || role),
                      };
                    }),
                    memberNonGroupList: nonGroupMembers.map(member => {
                      return {
                        ...member,
                        ...{ AvatarThumbnail: $this.rootServices.apiService.getBaseApiUrl() + '/user/users/' + member.id + '/avatar' },
                        LastReportText: $this.rootServices.datePipe.transform(new Date(member.LastReport), 'short') + ' (' + moment(member.LastReport).fromNow() + ')',
                        Roles: member.Roles && member.Roles.map(role => ($this.currentState.roles[role] && $this.currentState.roles[role].text) || role),
                      };
                    }),
                    groups: groups.map(group => {
                      group.Children = group.Children.map(member => {
                        return {
                          ...member,
                          ...{ AvatarThumbnail: $this.rootServices.apiService.getBaseApiUrl() + '/user/users/' + member.id + '/avatar' },
                          LastReportText: $this.rootServices.datePipe.transform(new Date(member.LastReport), 'short') + ' (' + moment(member.LastReport).fromNow() + ')',
                          Roles: (member.Roles) && member.Roles.map(role => ($this.currentState.roles[role] && $this.currentState.roles[role].text) || role) as any,
                        };
                      });
                      return group;
                    }),
                    contacts: contacts.map(contact => {
                      return {
                        ...contact,
                        // Avatar: contact.AvatarThumbnail,
                        RefIcon: contact.RefPlatform == 'PROBOXONE' ? (contact.RefConnectState == 'CONNECTED' ? 'assets/icon/probox.ico' : '') : (contact.ZaloUserStatus ? 'assets/icon/zalo-seeklogo.com.svg' : ''),
                        Roles: contact.Roles && contact.Roles.map(role => currentState.roles && currentState.roles[role] && currentState.roles[role].text || role) as any,
                      };
                    }),
                  });

                  // self.$setState({
                  // });
                  // self.$setState({
                  //   memberList: groupMembers.map(member => {
                  //     // const member = memberList[userCode];
                  //     // member.user.avatar = member.user.avatar + '?token=' + $this.rootServices.apiService.token.access_token;
                  //     return {
                  //       ...member,
                  //       roles: member.roles && member.roles.map(role => $this.currentState.roles[role].text),
                  //       user: {
                  //         ...(member.user as any),
                  //         role: member.user && member.user.role || 'undefined',
                  //         avatar: member.user && member.user.avatar && {
                  //           payload: {
                  //             thumbnail: member.user.avatar.payload.thumbnail + '?token=' + $this.rootServices.apiService.token.access_token
                  //           }
                  //         }
                  //       },
                  //     };
                  //   }),
                  // });
                }
              });

              // page.$el.find('.tree-view-item-selectable').on('click', function (e) {
              //   const $targetEl = $(e.target);
              //   if ($targetEl.closest('.treeview-item-selected').length) return;
              //   if ($targetEl.is('.treeview-toggle')) return;
              //   $targetEl.parents('.treeview').find('.treeview-item-selected').removeClass('treeview-item-selected')
              //   $targetEl.closest('.treeview-item-selectable').addClass('treeview-item-selected')
              // });

              // self.searchBar = self.$app.searchbar.create({
              //   el: '.chat-room-control-searchbar',
              //   backdrop: true,
              //   backdropEl: '.chat-room-control-searchbar-backdrop',
              //   searchContainer: '.list',
              //   searchIn: '.item-text',
              //   customSearch: true,
              //   on: {
              //     search(sb, query, previousQuery) {
              //       // $this.commonService.takeUntil('chat-room-control-search', 600).then(() => {
              //       //     console.log(query, previousQuery);
              //       //     $this.rootServices.apiService.getPromise<any[]>('/chat/rooms', {
              //       //         search: query,
              //       //         sort_LastUpdate: 'desc',
              //       //         limit: 10,
              //       //         offset: 0,
              //       //         filter_Type: '[LOCALGROUP,LOCAL,WORKPLACE]',
              //       //         ...self.filter,
              //       //     }).then(chatRooms => {
              //       //         self.$$(page.el).find('.page-content').scrollTo(0, 0);
              //       //         self.$setState({ chatRooms, infiniteLoadLast: chatRooms.length === 0 });
              //       //         self.infiniteOffset = 0;
              //       //     }).catch(err => {
              //       //         console.error(err);
              //       //         $this.commonService.showError(err);
              //       //     });
              //       // });
              //     }
              //   }
              // });

              self.reminderList$.pipe(takeUntil($this.currentState.destroy$)).subscribe(reminderList => {
                self.$setState({
                  reminders: reminderList.map(reminder => {
                    reminder['RemindAtDateTimeText'] = new Date(reminder.RemindAtDateTime).toLocaleString();
                    reminder.NextRemind = moment(reminder.NextRemind).fromNow();
                    return reminder;
                  }),
                });
              });
              // $this.rootServices.authService.isAuthenticatedOrRefresh().pipe(take(1)).toPromise().then(() => {
              //   // Load task reminder list
              //   $this.rootServices.apiService.getPromise<TaskReminderModel[]>('/task/reminders', { chatRoom: chatRoom.id, sort_NextRemind: 'asc' }).then(taskReminders => {
              //     self.reminderList$.next(taskReminders);
              //   });
              // });

            });

            // self.$app.on('swipeoutDelete', function(el) {
            //   console.log(el);
            //   const swipeoutName = self.$(el).data('name');
            //   const userId = self.$(el).data('id');
            //   if (swipeoutName === 'remove-chat-room-member') {
            //     $this.currentState.chatRoom.removeMember(userId).then(rs => {
            //       console.log(rs);
            //     }).catch(err => {
            //       console.error(err);
            //     });
            //   }
            // });

          },
          pageBeforeIn(e, page) {
            const self: F7ComponentContextExtend = this;
            const chatRoomId = self.$route.params.id;

            // Fire event: On Changed State
            $this.onChangedState({ instance: self, page }, chatRoomId, 'before-in');
          },
          pageBeforeOut(e, page) {
            const self: F7ComponentContextExtend = this;
            const chatRoomId = self.$route.params.id;

            // Fire event: On Before Changed State
            $this.onBeforeChangedState({ instance: self, page, scrollPosition: page.$el[0].children[0].scrollTop || 1 }, chatRoomId);
          },
          pageAfterIn(e, page: F7Page) {
            console.log('page after in');
            // const self: F7ComponentContextExtend = this;
            // self.$$('.deleted-callback').on('swipeout:deleted', (e2: CustomEvent) => {
            //   // self.$app.dialog.alert('Thanks, item removed!');
            //   console.debug(e2);

            // });
            // page.$el.find('.remove-member-confirm').on('click', function (e2: MouseEvent) {
            //   self.$app.dialog.confirm('Thành viên sẽ bị gở khỏi phòng chat !', () => {
            //     const currentSwipeout: any = self.$(e2.target).closest('li');
            //     const userId = currentSwipeout.data('id') || self.$(e2.target).data('id');
            //     $this.currentState.chatRoom.removeMember(userId).then(rs => {
            //       console.log(rs);
            //       self.$app.swipeout.delete(currentSwipeout[0]);
            //     }).catch(err => {
            //       console.error(err);
            //     });
            //   });
            // });
          },
          pageBeforeRemove(e, page) {
            console.log('[page event] before remove', page.route.url);
            const self: F7ComponentContextExtend = this;
            const chatRoomId = self.$route.params.id;
            $this.currentState.destroy$.next();
            $this.currentState.destroy$.complete();
            $this.currentState = null;
          },
        },
      },
    };
  }
}
