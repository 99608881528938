export class Model {
    id?: string;
    text?: string;
    [key: string]: any;
    RelativeVouchers?: { [key: string]: any, id?: string, text?: string, type?: any }[];
    constructor(properties?: Model) {
        if (properties) {
            for (const name of Object.keys(properties)) {
                this[name] = properties[name];
            }
        }
    }

    static getInstance() {
        return new Model();
    }
}

// export interface RootConfigModel {
//     coreName: string;
//     coreEmbedId: string;
//     domain: string,
//     sslEnabled: boolean,
//     chatService: {
//         port: number,
//         protocol: string,
//         host: string
//     }
// }

// export interface SystemSettingsModel {
//     [key: string]: any;
//     NOTIFICATION_ALLOW_TIME_RANGE: {
//         from: string,
//         to: string,
//     };
//     ROOT_CONFIGS: RootConfigModel;
//     LICENSE_INFO: {
//         register: {
//             companyName: string,
//             companyTaxCode: string,
//             email: string,
//             tel: string,
//             address: string,
//             website: string,
//             voucherInfo: string,
//             voucherLogo: string,
//             voucherLogoHeight: number,
//             backgroundLogoColor: string,
//         }
//     },
// }


export interface ProcessMap {
    id?: string;
    text?: string;
    state: string;
    label: string;
    confirmLabel?: string;
    status: string;
    color: string;
    outline?: boolean;
    iconPack?: string;
    icon?: string;
    nextState?: string;
    nextStateLabel?: string;
    confirmTitle?: string;
    confirmText?: string;
    responseTitle?: string;
    responseText?: string;
    nextStates?: ProcessMap[],
}