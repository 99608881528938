import { filter, take } from 'rxjs/operators';
import { F7ComponentContext, F7Page } from '../../../types/framework7-types';
import { Router } from 'framework7/build/core/modules/router/router';
import { BehaviorSubject } from 'rxjs';
import { ComponentState, BaseComponent } from 'vendors/smart-bot-app/src/app/lib/base-component';
import { ChatRoom } from 'vendors/smart-bot-app/src/app/lib/nam-chat/chat-room';
import { ProductModel } from 'vendors/smart-bot-app/src/app/model/product.model';
import { TaskReminderModel } from 'vendors/smart-bot-app/src/app/model/task.model';
import { CommonService } from 'vendors/smart-bot-app/src/app/services/common.service';
import { RootServices } from 'vendors/smart-bot-app/src/app/services/root.services';
import { PhotoBrowser } from 'framework7/build/core/components/photo-browser/photo-browser';
import { CollaboratorOrderModel } from 'src/app/model/collaborator.model';
import * as $ from 'jquery';
import { BaseFormComponent, FormComponentStateExtend, FormSchema } from 'vendors/smart-bot-app/src/app/lib/base-form-component';

// declare const $: any;

// export interface ComponentStateExtend extends ComponentState {
//   [key: string]: any;
//   productList?: any[];
// }

export class F7ComponentContextExtend extends F7ComponentContext {
  responseInProgress?: boolean;
  product?: ProductModel;
  $root: any;
  photoBrowser?: PhotoBrowser.PhotoBrowser;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, reminder?: TaskReminderModel, chatRoom?: ChatRoom, reminderList$?: BehaviorSubject<TaskReminderModel[]> } };
}
export interface ComponentStateExtend extends FormComponentStateExtend<CollaboratorOrderModel> {
  [key: string]: any;
  instance: F7ComponentContextExtend,
  data?: CollaboratorOrderModel;
  form?: any;
}
/** Component group manager */
export class ProductComponent extends BaseFormComponent<ComponentStateExtend, CollaboratorOrderModel> {

  // states: { [key: string]: State } = {};

  title = 'Sản phẩm'

  schema: FormSchema = {
    CustomerName: {
      type: 'input',
      label: 'Tên',
      validators: [
        'required'
      ]
    },
    CustomerPhone: {
      type: 'input',
      label: 'Số điện thoại',
      validators: [
        'required'
      ]
    },
    DeliveryAddress: {
      type: 'input',
      label: 'Số nhà, tên đường',
      validators: [
        'required'
      ]
    },
    Province: {
      label: 'Tỉnh/TP',
      type: 'autocomplete',
      ajax: (query: any) => {
        return this.rootServices.apiService.getPromise('/general/locations', { select: 'id=>Code,text=>CONCAT(TypeLabel;\' \';FullName)', limit: 100, 'search': query, eq_Type: '[PROVINCE,CITY]' });
      },
      validators: [
        'required'
      ]
    },
    District: {
      label: 'Quận/Huyện',
      type: 'autocomplete',
      ajax: (query: any) => {
        const data = this.currentState.data;
        return this.rootServices.apiService.getPromise('/general/locations', { select: 'id=>Code,text=>CONCAT(TypeLabel;\' \';FullName)', limit: 100, 'search': query, eq_Type: '[CDISTRICT,PDISTRICT,BURG,CITYDISTRICT]', eq_Parent: this.commonService.getObjectId(data.Province) });
      },
      validators: [
        'required'
      ]
    },
    Ward: {
      label: 'Phường/Xã',
      type: 'autocomplete',
      ajax: (query: any) => {
        const data = this.currentState.data;
        return this.rootServices.apiService.getPromise('/general/locations', { select: 'id=>Code,text=>CONCAT(TypeLabel;\' \';FullName)', limit: 100, 'search': query, eq_Type: '[VILLAGE,WARD,TOWNS]', eq_Parent: this.commonService.getObjectId(data.District) });
      },
      validators: [
        'required'
      ]
    },
    Quantity: {
      type: 'input',
      label: 'Số lượng',
      validators: [
        'required'
      ]
    },
  };

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
  ) {
    super(rootServices, commonService);
    console.log('// Click here to open ts file');

  }

  makeModel(properties?: CollaboratorOrderModel): CollaboratorOrderModel {
    return new CollaboratorOrderModel({ ...properties, Page: this.currentState.instance.$route.params['pageId'] });
  }

  async getFormData(params?: any): Promise<CollaboratorOrderModel> {
    return {};
  }

  get isPublicComponent() {
    return true;
  }

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      // Tab path
      name: 'product',
      path: '/:pageId/ctvbanhang/product/:id',
      // Tab id
      id: 'product',
      // Fill this tab content from content string
      component: {
        template: /*html*/`
        <div class="page product" data-name="prouct">
          <div class="navbar">
            <div class="navbar-bg"></div>
            <div class="navbar-inner sliding">
              <div class="left">
                <a class="link back {{textColorClass}}">
                  <i class="icon icon-back"></i>
                  <span class="if-not-md {{textColorClass}}">{{js "this.backTitle || 'Back'"}}</span>
                </a>
              </div>
              <div class="title">{{title}}</div>
              <div class="right">
                <a class="link icon-only">
                  <i class="icon f7-icons">ellipsis</i>
                </a>
              </div>
            </div>
          </div>
          <div class="page-content">
            
            <!--<div class="block inset block-strong page-banner bg-color-gray{{js "!this.pageInfo ? ' skeleton-text skeleton-effect-blink' : ''"}}" style="background-image: url({{js "this.pageInfo && this.pageInfo.Banner && this.pageInfo.Banner.OriginImage"}});"></div>-->
            <div class="block inset block-strong page-bannerx {{js "!this.pageInfo ? ' skeleton-text skeleton-effect-blink' : ''"}}" style="padding: 0; overflow: hidden">
              <img src="{{js "this.pageInfo && this.pageInfo.Banner && this.pageInfo.Banner.OriginImage"}}" style="width: 100%; margin-bottom: -6px">
            </div>
            <div class="block block-strong inset{{js "!this.pageInfo ? ' skeleton-text skeleton-effect-blink' : ''"}}">
                <div class="page-header">
                    <div class="logo bg-color-gray" style="background-image: url({{js "this.pageInfo && this.pageInfo.Logo && this.pageInfo.Logo.OriginImage"}});"></div>
                    <div class="info">
                        <div class="name">{{js "this.pageInfo && this.pageInfo.Name"}}</div>
                        <div class="summary">{{js "this.pageInfo && this.pageInfo.Summary"}}</div>
                        <div class="tag">@{{js "this.pageInfo && this.pageInfo.TagName"}}</div>
                    </div>
                </div>
            </div>
            
            <div style="display: {{js "this.product ? 'block'  : 'none'"}}">
              <div class="block-title" style="margin-top: 0.5rem;"><a href="#" @click="downloadPictures">Tải tất cả hình ảnh</a></div>
              <div @click="browsePictures" class="block block-strong inset feature-image bg-color-gray">
                <img src="{{js "this.product && this.product.FeaturePicture && this.product.FeaturePicture.SmallImage"}}">
              </div>
              <div class="block block-strong inset" style="margin-top: 0; width: inherit">
                <div>{{js "this.product && this.product.Name || ''"}}</div>
                <div>Giá: <span class="text-color-red" style="font-weight: bold">{{js "this.product && this.product.PriceText || ''"}}</span>/<span
                    style="font-weight: bold">{{js "this.product && this.product.UnitLabel || ''"}}<span></div>
              </div>
            
              {{#if isLoggedIn}}
              <div class="block">
                <p class="row">
                  {{#js_if "this.onProductChoose"}}
                    <button class="col button button-large button-fill color-green" @click="onProductChoose">Chọn</button>
                  {{else}}
                      {{#js_if "this.product && this.product.IsSubscribed"}}
                        <!--<button class="col-100 button button-large button-fill color-blue" @click="copyLink">Share link</button>-->
                      {{else}}
                        <!--<button class="col button button-large button-fill color-red" @click="subscribe">Đăng ký</button>-->
                      {{/js_if}}
                  {{/js_if}}
                </p>
              </div>
              {{else}}
              <div class="block">
                <p class="row">
                  <button class="col button button-large button-fill color-red" @click="subscribe">Đăng ký</button>
                </p>
              </div>
              {{/if}}
              {{#js_if "!this.registerRequired && !this.orderDisabled"}}
              <!--<div class="block-title" style="margin-top: 0.5rem;">Đặt hàng</div>
              
              <div class="list profile-form no-hairlines-md inset main-form">
                <ul>                      
                  <li class="item-content item-input">
                    <div class="item-inner">
                      <div class="item-title item-label {{validate data.CustomerName schema 'CustomerName'}}">Tên (*)</div>
                      <div class="item-input-wrap">
                        <input @keyup="onFieldChange" class="field text-color-blue {{validate data.CustomerName schema 'CustomerName'}}" name="CustomerName" type="text" placeholder="Tên...">
                        
                      </div>
                    </div>
                  </li>
                  <li class="item-content item-input">
                    <div class="item-inner">
                      <div class="item-title item-label">Số điện thoại (*)</div>
                      <div class="item-input-wrap">
                        <input @keyup="onFieldChange" class="field text-color-blue {{validate data.CustomerPhone schema 'CustomerPhone'}}" name="CustomerPhone" type="text" placeholder="{{js "this.data.ObjectPhone && this.data.ObjectPhone.placeholder || 'Số điện thoại'"}}">
                        
                      </div>
                    </div>
                  </li>
                  <li class="item-content item-input">
                    <div class="item-inner">
                      <div class="item-title item-label">Địa chỉ (*)</div>
                      <div class="item-input-wrap">
                        <input @keyup="onFieldChange" class="field text-color-blue {{validate data.DeliveryAddress schema 'DeliveryAddress'}}" name="DeliveryAddress" type="text" placeholder="Số nhà, tên đường">
                        
                      </div>
                    </div>
                  </li>

                  <li class="autocomplete {{validate data.Province schema 'Province'}}" name="Province">
                    <div class="item-link item-content" href="#">
                      <div class="item-inner">
                        <div class="item-title">Tỉnh/TP (*)</div>
                        <div class="item-after text-color-blue">{{js "this.data && this.data.Province && this.data.Province.text || ''"}}</div>
                      </div>
                    </div>
                  </li>
                  <li class="autocomplete {{validate data.District schema 'District'}}" name="District">
                    <div class="item-link item-content" href="#">
                      <div class="item-inner">
                        <div class="item-title">Quận/Huyện (*)</div>
                        <div class="item-after text-color-blue">{{js "this.data && this.data.District && this.data.District.text || ''"}}</div>
                      </div>
                    </div>
                  </li>
                  <li class="autocomplete {{validate data.Ward schema 'Ward'}}" name="Ward">
                    <div class="item-link item-content" href="#">
                      <div class="item-inner">
                        <div class="item-title">Phường/Xã (*)</div>
                        <div class="item-after text-color-blue">{{js "this.data && this.data.Ward && this.data.Ward.text || ''"}}</div>
                      </div>
                    </div>
                  </li>
                  <li class="item-content item-input">
                    <div class="item-inner">
                      <div class="item-title item-label">Số lượng (*)</div>
                      <div class="item-input-wrap">
                        <input @keyup="onFieldChange" class="field text-color-blue {{validate data.Quantity schema 'Quantity'}}" name="Quantity" type="text" placeholder="Số lượng">
                      </div>
                    </div>
                  </li>
                </ul>
              </div>-->
              <!--<div class="block" style="margin-top: 1rem; margin-bottom: 1rem"> 
                <button class="button button-large button-fill" data-product="{{Code}}" data-list="subscription" @click="orderProduct">Thêm vào đơn hàng</button>
              </div>-->
              {{/js_if}}
              
              <div class="block block-strong description inset">{{js "this.product && this.product.Description"}}</div>
              <div class="block block-strong technical inset">{{js "this.product && this.product.Technical"}}</div>
              
              {{#js_if "this.product && this.product.ExtendTerm"}}
                <div class="block-header">Điều khoản tăng cường</div>
                <div class="block block-strong technical inset">{{product.ExtendTerm.Title}}</div>
                {{#js_if "this.product && this.product.ExtendTerm.ContentBlock1"}}<div class="block block-strong technical inset">{{js "this.product && this.product.ExtendTerm && this.product.ExtendTerm.ContentBlock1"}}</div>{{/js_if}}
                {{#js_if "this.product && this.product.ExtendTerm.ContentBlock2"}}<div class="block block-strong technical inset">{{js "this.product && this.product.ExtendTerm && this.product.ExtendTerm.ContentBlock2"}}</div>{{/js_if}}
                {{#js_if "this.product && this.product.ExtendTerm.ContentBlock3"}}<div class="block block-strong technical inset">{{js "this.product && this.product.ExtendTerm && this.product.ExtendTerm.ContentBlock3"}}</div>{{/js_if}}
              {{/js_if}}
            </div>
            {{else}}
            <div class="skeleton-text skeleton-effect-blink" style="display: {{js "!this.product ? 'block'  : 'none'"}}">
              <div class="block block-strong inset feature-image bg-color-gray" style="background-image: url(assets/images/no-image-available.png)"></div>
              <div class="block block-strong" style="margin-top: 0">
                <div>sdfgsd gsdf sdf sd fdsfadfasdfasdf sadf</div>
                <div>asd <span class="text-color-red" style="font-weight: bold">123123132</span>/<span
                    style="font-weight: bold">asdf asdf asdf asdf<span></div>
              </div>
              <div class="block">
                <p class="row">
                  <button class="col button button-large button-fill color-blue" @click="order">asdas asdasd</button>
                  <button class="col button button-large button-fill color-green">asdas asdasd</button>
                </p>
              </div>
              <div class="block-header">asdas asfd</div>
              <div class="block block-strong description">asdf asdf asdfsadfsa dfasdf asdf asdf asdf sadf adsf asdf asdf sadf sadf sdf sd fasdf sdf asdf sadf asdf sadf asdf sdaf sdaf asdf asdf asdfasdf asdf</div>
              <div class="block-header">asdas asdsd</div>
              <div class="block block-strong technical">asd fasd fasdf sadf sdaf sadf sdf sadf sadfgsdfs sfg adifugasd gfksgf kdgs kagsdfdasgasgdfsjdgfkagsdfkjgadfkjadskfi jgadwkf kasd fgaksdj </div>
            </div>
            {{/js_if}}
          </div>
        </div>
        `,
        style: /*css*/`
          .page.product .feature-image {
            border-radius: 0.3rem;
            // height: 320px;
            // background-repeat: no-repeat;
            // background-size: cover;
            padding: 0;
            overflow: hidden;
          }
          .page.product .feature-image img {
            width: 100%;
            margin-bottom: -5px;
          }
          .ios .page.product .feature-image {
            box-shadow: var(--f7-dialog-box-shadow);
          }
          .page.product .description img, .page.product .description img {

          }
          .page.product .description *,
          .page.product .technical * {
            max-width: 100%;
          }
          .page.product .description figure,
          .page.product .technical figure {
            width: initial !important;
          }
          .page.product .description figure img,
          .page.product .technical figure img {
            border-radius: 0.3rem;
          }
        `,
        data() {
          return {
            title: $this.title,
            isLoggedIn: false,
            // backTitle: 'Back',
            // pageInfo: {},
            // product: { FeaturePicture: { SmallImage: '' } },
            validates: null,
            data: {},
          };
        },
        methods: {
          onFieldChange(e) {
            $this.onFieldChange(this, e);
          },
          copyDescription(e) {
            const self: F7ComponentContextExtend = this;
            // $this.rootServices.copyHtmlToClipboard(self.product.Description);
            // const el = $(e.target).closest('.page').find('.description');
            // console.log(el);
            $this.rootServices.copyHtmlToClipboard(self.product.Description);
          },
          copyTechnical(e) {
            const self: F7ComponentContextExtend = this;
            $this.rootServices.copyHtmlToClipboard(self.product.Technical);
          },
          copyBaseTerm(e) {
            const self: F7ComponentContextExtend = this;
            const baseTerm = `Chiết khấu cơ bản: ${self.product.Level1CommissionRatio}%\n
            Thưởng theo tuần\n
            + KPI yêu cầu: ${self.product.Level1WeeklyKpi} ${self.product.UnitLabel}\n
            + Tỷ lệ thưởng trên doanh thu:  ${self.product.Level1WeeklyAwardRatio}%\n
            Thưởng theo tháng\n
            + KPI yêu cầu: ${self.product.Level1MonthlyKpi} ${self.product.UnitLabel}\n
            + Tỷ lệ thưởng trên doanh thu:  ${self.product.Level1MonthlyAwardRatio}%\n
            Thưởng theo quý\n
            + KPI yêu cầu: ${self.product.Level1QuarterlyKpi} ${self.product.UnitLabel}\n
            + Tỷ lệ thưởng trên doanh thu:  ${self.product.Level1WeeklyAwardRatio}%\n
            Thưởng theo năm\n
            + KPI yêu cầu: ${self.product.Level1YearlyKpi} ${self.product.UnitLabel}\n
            + Tỷ lệ thưởng trên doanh thu:  ${self.product.Level1YearlyAwardRatio}%\n`;
            $this.rootServices.copyHtmlToClipboard(baseTerm);
          },
          downloadPictures(e) {
            const self: F7ComponentContextExtend = this;
            $this.currentState.instance.$app.dialog.confirm('Xác nhận tải về', 'ProBox', async () => {
              for (const picture of self.product.PicturesPreview) {
                $this.rootServices.saveImageToPhone(picture.payload.url as string);
              }
            });
          },
          copyLink(e) {
            const self: F7ComponentContextExtend = this;
            const link = `${$this.rootServices.env.universal.link}/${self.pageId.toLowerCase()}/ctvbanhang/product/${self.$route.params?.id?.replace(/_\w+/, '')?.toLowerCase()}?publisher=${$this.rootServices.authService.getUser()?.id}&productToken=${self.product.Token}`;
            $this.rootServices.copyTextToClipboard(link);
          },
          orderProduct(e) {
            const self: F7ComponentContextExtend = this;
            const currentState = $this.currentState;
            const data = currentState.data;
            // const productListName = $(e.target).data('list');
            const cardEle = $(e.target).closest('.order-form');
            // const nameEle = cardEle.find('input[name="CustomerName"]');
            // const phoneEle = cardEle.find('input[name="CustomerPhone"]');
            // const addressEle = cardEle.find('input[name="CustomerAddress"]');

            // const productCode = $(e.target).data('product');
            let product = self.product;
            // if (productListName === 'subscription') {
            //   product = self.profile?.subscriptionProducts?.find(f => f.Code === productCode);
            // } else {
            //   product = self.profile?.Blocks?.find(f => f.Type === 'BUSINESSPRODUCT')?.Data?.find(f => f.Code === productCode);
            // }

            if (!product) {
              $this.commonService.showError('Sản phẩm không tồn tại');
              return;
            }
            if (!data.CustomerName) {
              $this.commonService.showError('Quý khách chưa điền tên !');
              return;
            }
            if (!data.CustomerPhone) {
              $this.commonService.showError('Quý khách chưa điền số điện thoại !');
              return;
            }
            if (!data.DeliveryAddress) {
              $this.commonService.showError('Quý khách chưa điền địa chỉ nhận hàng !');
              return;
            }

            $this.commonService.showPreloader();
            $this.rootServices.apiService.postPromise<CollaboratorOrderModel[]>('/collaborator/orders', { 
              skipAuthx: true, 
              publisher: self.$route.context && self.$route.context['params'] && self.$route.context['params'].publisher,
              page: self.$route.params['pageId'],
            }, [
              {
                Page: product.Page,
                ObjectName: data.CustomerName as any,
                ObjectPhone: data.CustomerPhone as any,
                DeliveryAddress: data.DeliveryAddress as any,
                Province: data.Province,
                District: data.District,
                Ward: data.Ward,
                Details: [
                  {
                    Product: product.Code,
                    Token: (self.$route.context['params'] && self.$route.context['params']['productToken']) || product.Token,
                    Description: product.Name,
                    Unit: product.Unit,
                    Price: product.Price,
                    Quantity: data.Quantity || 1,
                    Image: product?.FeaturePicture ? [product?.FeaturePicture] : [],
                  }
                ]
              }
            ]).then(rs => rs[0]).then(newOrder => {
              $this.commonService.hidePreloader();
              $this.commonService.showInfo(`Cám ơn bạn đã ủng hộ sản phẩm: ${product.Name}<br>Mã đặt hàng của bạn là: ${newOrder.Code}<br>Chúc bạn một ngày may mắn và vui vẻ ❤️`, { timeout: 120000 });
              data.CustomerName = '';
              data.CustomerPhone = '';
              data.DeliveryAddress = '';
              data.Province = null;
              data.District = null;
              data.Ward = null;

              $this.setData(data);

            }).catch(err => {
              $this.commonService.showError(err);
              $this.commonService.hidePreloader();
            });
          },
          order(e) {
            const self: F7ComponentContextExtend = this;
            $this.commonService.navigate(`/page-collaborator-order/${self.$route.params?.pageId}/new`, {
              context: {
                product: self.product,
                pageId: self.pageId,
                pageInfo: self.pageInfo,
              }
            });
          },
          browsePictures(e) {
            const self: F7ComponentContextExtend = this;
            // self.photoBrowser && self.photoBrowser.open();
            const product = self.product;
            const featurePictureIndex = product.FeaturePicture ? product.Pictures.findIndex(f => f.Id == product.FeaturePicture.Id) : 0;
            $this.rootServices.previewPictures(product.Pictures, featurePictureIndex, { addToStackButton: true });

            // $this.browseAttachment(product.Pictures as any, `${product.FeaturePicture.Store}/${product.FeaturePicture.Id}`, (attachment => {
            //   console.debug('download attachment', attachment);
            //   if (/image/.test(attachment.Type)) {
            //     $this.currentState.instance.$app.dialog.confirm([attachment.Name, attachment.Description].filter(f => !!f).join('<br>') || 'Đính kèm', 'Xác nhận tải về', async () => {
            //       $this.rootServices.saveImageToPhone(attachment.Url as string);
            //     });
            //   } else if (/video/.test(attachment.Type)) {
            //     // $this.playVideo((attachment.payload.origin || attachment.payload.url) as string);
            //     $this.currentState.instance.$app.dialog.confirm([attachment.Name, attachment.Description].filter(f => !!f).join('<br>') || 'Đính kèm', 'Xác nhận tải về', async () => {
            //       $this.rootServices.saveImageToPhone(attachment.Url as string);
            //     });
            //   } else {
            //     $this.rootServices.iab.create((attachment.OriginUrl || attachment.Url) as string, '_system');
            //   }
            // }));
          },
          subscribe(e) {
            const self: F7ComponentContextExtend = this;
            if (!self.isLoggedIn) {
              self.$app.dialog.confirm(`Mời bạn đăng ký tài khoản MXH ProBox để trở thành CTV Bán Hàng của ${self.pageInfo?.Name}`, () => {
                $this.rootServices.navigate('/register', {
                  context: {
                    reason: `Mời bạn đăng ký tài khoản MXH ProBox để trở thành CTV Bán Hàng của <b>${self.pageInfo?.Name}</b>`,
                    term: `Bằng với việc đăng ký thành công, bạn trở thành thành viên của MXH ProBox và đã nhận thức MXH ProBox là công cụ làm việc trực tiếp với doanh nghiệp sở hữu Module Page <b>${self.pageInfo?.Name}</b>, mọi quyền lợi và lợi ích liên quan đến từng công việc sẽ do doanh nghiệp sở hữu Module Page <b>${self.pageInfo?.Name}</b> chịu trách nhiệm. MXH ProBox chỉ có trách nhiệm cung cấp thông tin và nhận thức sự đúng đắng của doanh nghiệp thông qua các quy trình chứng thực đặt biệt.`,
                    navigateOnSuccess: 'goback',
                    afterRegister: () => {
                      $this.rootServices.apiService.putPromise('/collaborator/products', { id: [self.product.Code], subscribe: true, page: self.product.Page }, [{ Code: self.product.Code, WarehouseUnit: self.product.WarehouseUnit, Token: self.product.Token }]).then(rs => {
                        $this.commonService.showInfo('Đăng ký thành công');
                        self.refresh();
                      }).catch(err => {
                        $this.commonService.showError(err);
                      });
                    },
                  }
                });
              });
            } else {
              self.$app.dialog.confirm('Bạn có muốn đăng ký sản phẩm này không ? !', () => {
                // $this.rootServices.apiService.putPromise('/collaborator/products', { id: [self.product.Code], subscribe: true, page: self.product.Page }, [{ Code: self.product.Code, WarehouseUnit: self.product.WarehouseUnit, Token: self.product.Token }]).then(rs => {
                $this.rootServices.apiService.postPromise('/collaborator/product-subscriptions', { subscribe: true, page: self.product.Page }, [{
                  Product: self.product.Code,
                  WarehouseUnit: self.product.WarehouseUnit,
                  Unit: self.product.WarehouseUnit,
                  Token: self.product.Token
                }]).then(rs => {
                  $this.commonService.showInfo('Đăng ký thành công');
                  self.refresh();
                }).catch(err => {
                  $this.commonService.showError(err);
                });
              });
            }
          },
          chooseOne(e) {
            const self: F7ComponentContextExtend = this;
            const id = self.$(e.target).closest('li').data('id');
            self.$route.context['onChoose'] && self.$route.context['onChoose'](self.productList.find(f => f.Code === id));
          },
          chooseProduct(e) {

          },
          async refresh(e, done) {
            const self: F7ComponentContextExtend = this;
            const productId = self.$route.params?.id;
            const pageId = self.$route.params?.pageId || self.pageId;
            let doneTimeout = null;
            if (done) {
              doneTimeout = setTimeout(() => {
                done();
              }, 10000);
            };

            // return;
            // await $this.commonService.waiting(1500);
            // return;
            const params = self.$route?.context && self.$route?.context['params'] || {};
            const product = await $this.rootServices.apiService.getPromise<any[]>(`/collaborator/products`, { ...params, skipAuth: true, eq_Code: productId, includePictures: true, includeSubscribed: true, includePrice: true, includeExtendTerm: true, page: pageId, includeIdText: true }).then(rs => {
              if (!rs || rs.length === 0) {
                return null;
              }
              return $this.preapreProduct({ ...rs[0], FeaturePicture: { ...rs[0].FeaturePicture, SmallImage: rs[0].FeaturePicture?.SmallImage } });
            });
            if (!product) {
              $this.commonService.showError('Sản phẩm chưa được đưa lên sàn');
              return;
            }
            if (product?.Units) {
              product.Unit = product.Unit || (product.Units && product.Units[0] && product.Units[0]['Unit'] || '') as any;
              const unit = product.Units.find(f => f.Unit == product.Unit);
              if (unit) {
                product.Price = unit.Price;
                product.PriceText = $this.rootServices.currencyPipe.transform(product.Price, 'VND');
              }
            }
            const pageInfo = self.pageInfo || await $this.rootServices.apiService.getPromise<any[]>('/collaborator/pages/' + product.Page.toUpperCase(), { skipAutxh: true }).then(rs => rs[0]);
            self.$setState({ product: { ...product, Token: product.Token || (params.productToken) }, pageInfo: pageInfo, data: {} });

            // if (self.photoBrowser && self.photoBrowser.destroy) {
            //   self.photoBrowser.destroy();
            // }
            // self.photoBrowser = self.$app.photoBrowser.create({
            //   photos: product.Pictures as any,
            // })

            if (done) done();
            return product;
          },
        },
        on: {
          pageBeforeIn(e, page) {
            console.log('[page event] before in', page.route.url);
            const self: F7ComponentContextExtend = this;
            const productId = self.$route?.params?.id;
            const pageId = self.$route?.params?.pageId;

            const currentState = $this.state[self.$route.params.id];

            // Fire event: On Changed State
            // Show preloader
            $this.onChangedState({ instance: self, page }, `${productId}`, 'before-in').then(() => {

            });
          },
          pageInit(e, page: F7Page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;
            const productId = self.$route?.params?.id;
            const pageId = self.$route?.params?.pageId;
            let orderDisabled = false;

            const prePath = self.$router.history[self.$router.history.length - 2];
            if (prePath) {
              if (/^\/\w+\/ctvbanhang\/edu\/\w+/.test(prePath)) {
                orderDisabled = true;
              }
            }

            self.$setState({ orderDisabled: orderDisabled });

            const toolbarEl = self.$('.toolbar.main');
            toolbarEl.hide();
            $this.rootServices.authService?.loginState$.pipe(filter(f => !!f)).subscribe(status => {
              self.$setState({ isLoggedIn: status || false });
              if (status === true) {
                // self.refresh();
                toolbarEl.show();
              } else {
                toolbarEl.hide();
              }
            });

            if ((self.$route?.context as any)?.onProductChoose) {
              self.$setState({
                pageId: pageId || self.pageId,
                onProductChoose: (e) => {
                  // const product = '';
                  (self.$route?.context as any)?.onProductChoose(self.product);
                }
              })
            }

            $this.onComponentInit({ instance: self }, `${productId}`, 'init').then(async currentState => {
              setTimeout(() => {
                if (self.registerRequired && !self.isLoggedIn) {
                  $this.rootServices.navigate('/login', {
                    context: {
                      reason: `Mời bạn đăng nhập/đăng ký để tiếp tục đăng ký kinh doanh sản phẩm: ${self?.product?.Name}.`,
                      navigateOnSuccess: 'goback',
                    }
                  });
                }
              }, 5000);
            });

          },
          pageBeforeRemove(e, page) {
            console.log('[tab event] before remove', page?.route?.url);
            const self: F7ComponentContextExtend = this;
            const productId = self.$route.params?.id;
            $this.onComponentRemove({ instance: self }, productId);
          },
        },
      },
    };
  }

  onF7pageRemove(chatRoomId: string) {
  }

  preapreProduct(p: ProductModel) {
    if (p.Categories && p.Categories.length > 0) {
      p.CategoriesText = p.Categories.map(m => m.text).join(', ');
    }
    if (p.FeaturePicture && p.FeaturePicture.Thumbnail) {
      p.FeaturePictureThumbnail = p.FeaturePicture.Thumbnail;
    }
    const taxValue = p.Tax && p.Tax.Tax || 0;
    p.ToMoney = parseInt(p.Price as any) + parseInt(p.Price as any) * parseInt(taxValue as any) / 100;
    p.ToMoneyText = this.rootServices.currencyPipe.transform(p.ToMoney, 'VND');;
    p.PriceText = this.rootServices.currencyPipe.transform(p.Price, 'VND');
    if (p.OriginPrice) {
      p.OriginPriceText = this.rootServices.currencyPipe.transform(p.OriginPrice, 'VND');
    }

    if (p.Pictures) {
      try {
        p.PicturesPreview = p.Pictures.map(picture => ({
          id: `${picture.Store}/${picture.Id}`,
          type: picture.Type && picture.Type.split('/')[0] || 'image',
          dataType: picture.Type,
          ext: picture.Extension,
          name: '',
          description: '',
          payload: {
            thumbnail: picture.Thumbnail,
            url: picture.DownloadLink,
            small: picture.SmallImage,
            large: picture.LargeImage,
            origin: picture.OriginImage,
          }
        })) as any;
      } catch (err) {
        console.warn(err);
        p.PicturesPreview = [];
      }
    }

    return p;
  }

  async onComponentInit(state: ComponentStateExtend, index: string, asCase?: string) {
    state.instance.$setState({
      schema: this.schema,
    });
    return super.onComponentInit(state, index, asCase).then(async currentState => {

      // await currentState.instance.refresh();
      currentState.data = {};
      return currentState;

    });
  }
}
