import { F7ListComponentContext } from 'vendors/smart-bot-app/src/app/lib/base-list-component';
import { Router } from 'framework7/build/core/modules/router/router';
import { Messages } from 'framework7/build/core/components/messages/messages';
import { F7ComponentContext, F7Messagebar, F7Page } from '../types/framework7-types';
import { RootServices } from '../services/root.services';
import * as moment from 'moment';
import { CommonService } from 'vendors/smart-bot-app/src/app/services/common.service';
import { ChatRoomModel } from 'vendors/smart-bot-app/src/app/model/chat-room.model';
import { isPlatform } from '@ionic/angular';
import { Dialog } from 'framework7/build/core/components/dialog/dialog';
import { DatePipe } from '@angular/common';
import { BaseListComponent, ListComponentStateExtend } from 'vendors/smart-bot-app/src/app/lib/base-list-component';
import { filter, take } from 'rxjs/operators';
// import * as $ from 'jquery';
declare const $: any;

// declare const $: any;
export interface ComponentStateExtend extends ListComponentStateExtend<ChatRoomModel> {
    [key: string]: any;
    instance?: F7ComponentContextExtend,
}
export interface F7ComponentContextExtend extends F7ListComponentContext<ChatRoomModel> {
    items?: ChatRoomModel[];
    createChatRoomDialog?: Dialog.Dialog;
    filterDialog?: Dialog.Dialog;
    createWorkplaceDialog?: Dialog.Dialog;
    mode?: string;

    callback?: (task: ChatRoomModel) => void;
    refresh?: (e?: Event, done?: () => void) => Promise<boolean>;

}

/** Component group manager */
export class WorkplaceComponent extends BaseListComponent<ComponentStateExtend, ChatRoomModel> {

    title = 'Thông tin';
    idKey = 'Code';

    namespace = 'workplace';

    /** Vouchers stap map */
    voucherStateMap = {
        'OPEN': {
            text: 'mới',
            color: 'color-red',
            icon: 'circle_fill'
        },
        'ACCEPT': {
            text: 'đang xử lý',
            color: 'color-orange',
            icon: 'graph_circle_fill'
        },
        'COMPLETE': {
            text: 'hoàn tất',
            color: 'color-green',
            icon: 'checkmark_alt_circle_fill'
        },
        'CLOSE': {
            text: 'đóng',
            color: 'color-gray',
            icon: 'multiply_circle_fill'
        },
        'CANCEL': {
            text: 'hủy',
            color: 'color-gray',
            icon: 'multiply_circle_fill'
        },
    };

    constructor(
        public rootServices: RootServices,
        public commonService: CommonService,
        public datePipe: DatePipe,
    ) {
        super(rootServices, commonService);
        // // console.log('Click here to open ts file');
    }

    onF7pageRemove(chatRoomId: string) {
        // if (this.chatRoomCacheList[chatRoomId]) {
        //   this.chatRoomCacheList[chatRoomId].disconnect();
        // }
    }

    async onComponentInit(state: ComponentStateExtend, index: string, asCase?: string, f7Page?: F7Page) {
        return super.onComponentInit(state, index, asCase, f7Page).then(currentState => {

            return currentState;
        });
    }

    async getList(self?: F7ComponentContextExtend, extParams?: any): Promise<ChatRoomModel[]> {
        if (!this.rootServices.authService.loginState$?.value) {
            return [];
        }
        return this.rootServices.apiService.getPromise<ChatRoomModel[]>('/chat/rooms', {
            // search: this.currentState.instance.searchBar.query,
            sort_LastUpdate: 'desc',
            limit: self.limit,
            offset: 0,
            ne_Type: 'HELPDESK',
            ...self.filter,
            ...extParams
        }).then(list => {
            return list;
        }).catch(err => {
            console.error(err);
            this.commonService.showError(err);
            return Promise.reject(err);
        });
    }

    prepareItem(item: ChatRoomModel, index: number) {
        const unread = item.NumOfMessage - item.LoggedNumOfMessage - item.LoggedNumOfReadMessage;
        // item.LastUpdate_Moment = moment(item.LastUpdate).fromNow();
        // item.DateOfCreate = this.datePipe.transform(item.DateOfCreate, 'short');
        item.StateLabel = { 'OPEN': 'Mở', 'NEWSESSION': 'Mới', 'ACCEPT': 'Tiếp nhận', 'CLOSE': 'Đóng', 'COMPLETE': 'Hoàn tất', 'CANCEL': 'Hủy' }[item.State];
        if (item.State in ['OPEN', 'NEWSESSION']) {
            item.ActionMemberName = item.OpenByMemberName;
        }
        if (item.State === 'ACCEPT') {
            item.ActionMemberName = item.AcceptByMemberName;
        }
        if (item.State === 'CLOSE') {
            item.ActionMemberName = item.CloseByMemberName;
        }
        if (item.State === 'COMPLETE') {
            item.ActionMemberName = item.CompleteByMemberName;
        }
        if (item.State === 'CANCEL') {
            item.ActionMemberName = item.CancelByMemberName;
        }
        item.State = this.voucherStateMap[item.State] || {};
        item.NumOfUnreadMessage = unread > 0 ? unread : '';

        if (item.MembersIndex && Array.isArray(item.MembersIndex)) {

            const smartBoot = item.MembersIndex.find(f => f == 'SMARTBOT');
            item.MembersIndex = item.MembersIndex.filter(f => f != 'SMARTBOT' && f != this.commonService.getObjectId(this.rootServices.authService.user$.value));
            item.MembersIndex.push(smartBoot);
            item.MembersIndex = item.MembersIndex.splice(0, 4);

            // item.Members = item.MembersIndex.filter(f => f != 'SMARTBOT').map(m => ({ id: m, text: m, avatar: this.rootServices.apiService.getBaseApiUrl() + '/user/users/' + m + '/avatar' }));
            // const smartBotIndex = item.MembersIndex.findIndex(f => f == 'SMARTBOT');
            // if (smartBotIndex > -1) {
            //     const smartBot = item.MembersIndex.splice(smartBotIndex, 1)[0];
            //     item.MembersIndex.push(smartBot);
            // }
            item.Members = item.MembersIndex.map(m => ({ id: m, text: m, avatar: this.rootServices.apiService.getBaseApiUrl() + '/user/users/' + m + '/avatar' }));
        } else {
            item.Members = [];
        }

        item.__index = index;
        return item;
    }

    async refresh(self?: F7ComponentContext): Promise<ChatRoomModel> {
        return super.refresh(self).then(rs => {
            this.currentState.instance.updateBadge();
            return rs;
        });
    }

    itemBeforeInsert(element: HTMLElement, item: ChatRoomModel): void {
        const $this = this;
        $(element).find('.approveBtn').unbind('click').click(function () {
            $this.currentState.instance.$app.dialog.confirm('Bạn có muốn đánh dấu đã đọc cho tất cả tin nhắn ?', 'Đánh dấu đã đọc', () => {
                console.log('ok');
            });
            return false;
        })
        return;
    }

    async onItemClick(self: F7ComponentContextExtend, item: ChatRoomModel) {
        const $this = this;
        const roomId = item[this.idKey];
        if (self.mode && self.callback) {
            self.callback(item);
            self.$router.back();
            return;
        }

        try {
            if ($this.commonService.getObjectId(item.Creator) !== $this.rootServices.authService.user$.getValue().id && ['OPEN', 'NEWSESSION'].indexOf(item.State && item.State['Code'] || item.State) > -1) {
                await new Promise((resolve, reject) => {
                    self.$app.dialog.confirm(item.Description, 'Tiếp nhận yêu cầu', () => {
                        self.$app.preloader.show();
                        $this.rootServices.apiService.putPromise('/chat/rooms/' + item.Code, { changeStateTo: 'ACCEPT' }, [{ Code: item.Code }]).then(rs => {
                            $this.commonService.showInfo('Đã tiếp nhận công việc');
                            self.$app.preloader.hide();
                            resolve(true);
                        }).catch(err => {
                            console.error(err);
                            self.$app.preloader.hide();
                            $this.commonService.showError(err);
                        });
                    }, () => {
                        reject('Không tiếp nhận công việc');

                    });
                });
            }
            self.$router.navigate('/chat-room/' + roomId, { context: { backTitle: self.title, title: item && item.Description } });
        } catch (err) {
            console.error(err);
            $this.commonService.showError(err);
        }

    }

    itemHeightx = 135;
    itemTemplatex = /*html*/`
    <li data-id="{{Code}}" data-chat-room="{{Code}}" style="height: ${this.itemHeight}px; overflow: hidden" class="swipeout">
        <a class="item-link item-content swipeout-content">
            <div class="item-inner" style="height: ${this.itemHeight}px">
                <div class="item-title-row">
                    <div class="item-title text-color-default description">
                        <div class="item-title-text">{{Description}}</div>
                        </div>
                    <div class="item-after; text-color-blue" style="font-size: 0.8rem">
                        <span class="badge color-red unread">{{NumOfUnreadMessage}}</span>
                    </div>
                </div>
                
                {{#if DateOfCreate}}
                <div class="item-subtitle text-color-gray" style="display: flex; align-items: center; justify-content: space-between;">
                    <div style="display: flex; align-items: center">
                        <i class="icon f7-icons" style="margin-right: 4px;">timer_fill</i>
                        <div class="description">{{date DateOfCreate format="short"}}</div>
                    </div>
                    <div style="display: flex; align-items: center">
                        {{moment LastUpdate coalesce="--"}}
                    </div>
                </div>
                {{/if}}
                
                {{#if LastMessageText}}
                <div class="item-subtitle text-color-gray" style="display: flex; align-items: center">
                    <i class="icon f7-icons" style="margin-right: 4px;">captions_bubble_fill</i>
                    <div class="description" style="overflow: hidden; text-overflow: ellipsis;">{{js "this.LastMessageUserName && (this.LastMessageUserName + ': ') || ''"}}{{LastMessageText}}</div>
                </div>
                {{/if}}
                <div class="item-text" style="clear: both; max-height: initial;">
                    <span class="lastMessageText">
                        <span class="tag">#{{Code}}</span>
                        {{#each Tags}}
                        <span class="tag">#{{this}}</span>
                        {{/each}}
                    </span>
                </div>
                <div class="members" style="height: 32px">
                    {{#each Members}}
                    <div class="member" style="width: 16px; height: 16px; float:left; margin-right: 3px; overflow: hidden; border-radius: 50%; background-repeat: no-repeat; background-color: gray; background-size: cover; background-image: url('{{avatar}}')"></div>
                    {{/each}}
                </div>
            </div>
        </a>

        <div class="swipeout-actions-right">
            <a href="#" class="approveBtn open-more-actions swipeout-overswipe">More</a>
        </div>
    </li>
    `;

    itemHeight = 73;
    itemTemplate = /*html*/`
    <li data-id="{{Code}}" data-chat-room="{{Code}}" style="height: ${this.itemHeight}px; overflow: hidden" class="swipeout">
        <a href="#" class="item-content link swipeout-content">
            <div class="item-media">
                {{#each Members}}
                <div class="avatar">
                    <div style="background-image: url({{avatar}})"></div>
                </div>
                {{/each}}
            </div>
            <div class="item-inner">
                <div class="item-title-row">
                    <div class="item-title" style="color: var(--f7-text-color)">{{Description}}</div>
                    <div class="item-after">{{moment LastUpdate coalesce="--"}}</div>
                </div>
                <div class="item-title-row">
                    <div class="item-subtitle" style="color: var(--f7-text-color); opacity: .8">{{objecttext this 'LastMessageUserName' '--'}}: {{LastMessageText}}</div>
                    <div class="item-after"><span class="badge color-red unread">{{NumOfUnreadMessage}}</span></div>
                </div>
                <!--
                <div class="item-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sagittis tellus ut turpis condimentum, ut dignissim lacus tincidunt. Cras dolor metus, ultrices condimentum sodales sit amet, pharetra sodales eros. Phasellus vel felis tellus. Mauris rutrum ligula nec dapibus feugiat. In vel dui laoreet, commodo augue id, pulvinar lacus.</div>
                -->
            </div>
        </a>

        <div class="swipeout-actions-right">
            <a href="#" class="approveBtn open-more-actions swipeout-overswipe">More</a>
        </div>
    </li>
    `;

    get f7Component(): Router.RouteParameters {
        const $this = this;
        return {
            name: 'workplace',
            path: '/workplace',
            component: {
                template: /*html*/`
                <div class="page ${this.namespace}">
                    <div class="navbar {{extendComponentId}}">
                        <div class="navbar-bg"></div>
                        <div class="navbar-inner sliding">
                            <div class="left"><!-- Link to enable searchbar -->
                                <a class="link icon-only searchbar-enable text-color-default" data-searchbar="{{js "this.extendComponentId && ('.' + this.extendComponentId+' ') || ''"}}.${this.namespace}-searchbar">
                                    <i class="icon f7-icons if-not-md">search</i>
                                    <i class="icon material-icons md-only">search</i>
                                </a>
                            </div>
                            <div class="title text-color-default">{{title}}</div>
                            <div class="right">
                                <a class="link icon-only text-color-default">
                                    <i class="icon f7-icons">qrcode_viewfinder</i>
                                </a>
                            </div>
                            <!-- Searchbar is a direct child of "navbar-inner" -->
                            <form class="searchbar ${this.namespace}-searchbar searchbar-expandable" data-search-container=".${this.namespace}-virtual-list" data-search-item="li" data-search-in=".item-title" >
                                <div class="searchbar-inner">
                                    <div class="searchbar-input-wrap">
                                        <input type="search" placeholder="#thẻ/@thành viên/+tin nhắn/tiêu đề" />
                                        <i class="searchbar-icon"></i><span class="input-clear-button"></span>
                                    </div>
                                    <span class="searchbar-disable-button text-color-default">Cancel</span>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="page-content ptr-content infinite-scroll-content" data-ptr-mousewheel="false" @ptr:refresh="refresh">
                        <div class="ptr-preloader">
                            <div class="preloader"></div>
                            <div class="ptr-arrow"></div>
                        </div>
                        <div class="searchbar-backdrop ${this.namespace}-searchbar-backdrop"></div>


                        <div class="block-header">{{lastUpdateMoment}}, kéo xuống để cập nhật</div>
                        
                
                        <div class="list media-list ${this.namespace}-list insetx virtual-list ${this.namespace}-virtual-list ${this.namespace}-virtual-list-vdom list-complex-infox">
                            
                        </div>
                        {{#if infiniteLoadLast}}
                            <div style="text-align: center" class="text-color-gray">end</div>
                        {{else}}
                            <div class="preloader color-blue infinite-scroll-preloader">
                                <span class="preloader-inner">
                                    <span class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                        class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                        class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                        class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                        class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                        class="preloader-inner-line"></span><span class="preloader-inner-line"></span>
                                </span>
                            </div>
                        {{/if}}
                    </div>
                
                    <div class="dialog dialog-wrap dialog-create-chat-room">
                        <div class="dialog-padding">
                            <div class="dialog dialog-inside">
                                <div class="dialog-inner" style="padding-bottom: 0;">
                                    <div class="dialog-title">Workplace</div>
                                    <div class="dialog-text" style="width: 100%; user-select: initial; text-align: center;">Tạo phòng
                                        tương tác nội bộ</div>
                                    <br>
                                    <div class="list media-list"
                                        style="margin-left: -15px; margin-right: -15px; margin-bottom: 0; margin-top: 0; text-align: left;">
                                        <ul>
                                            <li class="item-content item-input">
                                                <div class="item-media"><i
                                                        class="icon f7-icons text-color-default">chat_bubble_text_fill</i></div>
                                                <div class="item-inner">
                                                    <div class="item-title item-label">Mô tả (*)</div>
                                                    <div class="item-input-wrap">
                                                        <textarea class="field" name="Description" type="text"
                                                            placeholder="Mô tả/tin nhắn đầu tiên"></textarea>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="dialog-buttons">
                                    <span class="dialog-button color-red closeBtn" @click="closeCreateChatRoomDialog">Đóng</span>
                                    <span class="dialog-button createReferenceChatRoomBtn" @click="createChatRoom">Tạo phòng</span>
                                </div>
                            </div>
                        </div>
                    </div>
                
                    <div class="dialog dialog-wrap dialog dialog-filter">
                        <div class="dialog-padding">
                            <div class="dialog dialog-inside">
                                <div class="dialog-inner" style="padding-bottom: 0;">
                                    <div class="dialog-title">Helpdesk</div>
                                    <div class="block-title" style="margin-top: 1rem;">Bộ lọc yêu cầu khách hàng</div>
                                    <div class="list simple-list"
                                        style="margin-left: -15px; margin-right: -15px; margin-bottom: 0; margin-top: 0; text-align: left;">
                                        <ul>
                                            <li>
                                                <span>Mới</span>
                                                <label class="toggle toggle-init color-red">
                                                    <input class="field" name="filter_State" value="[OPEN,NEWSESSION]" type="checkbox">
                                                    <span class="toggle-icon"></span>
                                                </label>
                                            </li>
                                            <li>
                                                <span>Đang xử lý</span>
                                                <label class="toggle toggle-init color-orange">
                                                    <input class="field" name="filter_State" value="ACCEPT" type="checkbox">
                                                    <span class="toggle-icon"></span>
                                                </label>
                                            </li>
                                            <li>
                                                <span>Hoàn tất</span>
                                                <label class="toggle toggle-init color-green">
                                                    <input class="field" name="filter_State" value="COMPLETE" type="checkbox">
                                                    <span class="toggle-icon"></span>
                                                </label>
                                            </li>
                                            <li>
                                                <span>Đã hủy</span>
                                                <label class="toggle toggle-init color-gray">
                                                    <input class="field" name="filter_State" value="CANCEL" type="checkbox">
                                                    <span class="toggle-icon"></span>
                                                </label>
                                            </li>
                                            <li>
                                                <span>Chưa đọc</span>
                                                <label class="toggle toggle-init color-green">
                                                    <input class="field" name="filter_UnreadState" value="true" type="checkbox">
                                                    <span class="toggle-icon"></span>
                                                </label>
                                            </li>
                                            <li>
                                                <span>Quan trọng</span>
                                                <label class="toggle toggle-init color-gray">
                                                    <input class="field" name="filter_ImportantFlag" value="true" type="checkbox">
                                                    <span class="toggle-icon"></span>
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="dialog-buttons">
                                    <span class="dialog-button color-red closeBtn" @click="closeFilterDialog">Đặt lại</span>
                                    <span class="dialog-button createReferenceChatRoomBtn" @click="applyFilter">Áp dụng</span>
                                </div>
                            </div>
                        </div>
                    </div>
                
                    <div class="dialog dialog-wrap dialog-create-${this.namespace}-dialog">
                        <div class="dialog-padding">
                            <div class="dialog dialog-inside">
                                <div class="dialog-inner" style="padding-bottom: 0;">
                                    <div class="dialog-title">Workplace</div>
                                    <div class="dialog-text" style="width: 100%; user-select: initial; text-align: center;">Tạo
                                        workplace</div>
                                    <br>
                                    <div class="list media-list"
                                        style="margin-left: -15px; margin-right: -15px; margin-bottom: 0; margin-top: 0; text-align: left;">
                                        <ul>
                                            <li class="item-content item-input">
                                                <div class="item-media"><i
                                                        class="icon f7-icons text-color-default">chat_bubble_text_fill</i></div>
                                                <div class="item-inner">
                                                    <div class="item-title item-label">Mô tả (*)</div>
                                                    <div class="item-input-wrap">
                                                        <textarea class="field" name="Description" type="text"
                                                            placeholder="Mô tả/tin nhắn đầu tiên"></textarea>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="dialog-buttons">
                                    <span class="dialog-button color-red closeBtn" @click="closeCreateWorkplaceDialog">Đóng</span>
                                    <span class="dialog-button createReferenceChatRoomBtn" @click="createWorkplace">Tạo workplace</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              `,
                style: /*css*/`
                .page.${this.namespace} .tag {
                    font-size: 12px;
                    font-style: italic;
                }

                .page.${this.namespace} .${this.namespace}-virtual-list li {
                    padding-top: 0.5rem;
                    padding-bottom: 0.5rem;
                }
                .page.${this.namespace} .${this.namespace}-virtual-list .item-title {
                    font-size: 1rem;
                }
                .page.${this.namespace} .${this.namespace}-virtual-list .item-subtitle {
                    font-size: 0.8rem;
                }
                .page.${this.namespace} .${this.namespace}-virtual-list .item-media {
                    overflow: hidden;
                    border-radius: 50%;
                    width: 4rem;
                    height: 4rem;
                    align-self: center;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;    
                    background-color: var(--f7-page-bg-color);
                    padding: 0.5rem;
                }
                .page.${this.namespace} .${this.namespace}-virtual-list .item-inner {
                    margin-left: 0.5rem;
                }
                .page.${this.namespace} .${this.namespace}-virtual-list .item-media div.avatar {
                    border-radius: 50%;
                    overflow: hidden;
                    width: 1.5rem;
                    height: 1.5rem;
                    background-image: url(assets/icon/user-icon.png);
                    background-repeat: no-repeat; 
                    background-size: cover;
                    background-position: center;
                }
                .page.${this.namespace} .${this.namespace}-virtual-list .item-media div.avatar div {
                    width: 1.5rem;
                    height: 1.5rem;
                    background-repeat: no-repeat; 
                    background-size: cover;
                    background-position: center;
                }
                .page.${this.namespace} .${this.namespace}-virtual-list .item-media:has(> :last-child:nth-child(1)) {
                    padding: 0;
                }
                .page.${this.namespace} .${this.namespace}-virtual-list .item-media:has(> :last-child:nth-child(1)) div.avatar {
                    width: 4rem;
                    height: 4rem;
                }
                .page.${this.namespace} .${this.namespace}-virtual-list .item-media:has(> :last-child:nth-child(1)) div.avatar div {
                    width: 4rem;
                    height: 4rem;
                }
                `,
                data() {
                    const self: F7ComponentContextExtend = this;
                    return {
                        title: self.title || $this.title,
                        chatRooms: [],
                        // infiniteLoadLast: true,
                        disabledLeftPannel: false && isPlatform('desktop'),
                        lastUpdateMoment: moment(Date.now()).fromNow(),
                        filter: null,
                        mode: '',
                        // limit: 100,
                    };
                },
                methods: {
                    gotoAccount() {
                        const self: F7ComponentContextExtend = this;
                        $this.rootServices.navigate('/bio-page/default', { context: { backTitle: 'Home' } });
                    },
                    openFilterDialog(e) {
                        const self: F7ComponentContextExtend = this;
                        self.filterDialog && self.filterDialog.open();
                        console.log('Current filter: ', self.filter);
                    },
                    closeFilterDialog(e) {
                        const self: F7ComponentContextExtend = this;
                        if (self.filterDialog) {
                            const fieldEles = self.filterDialog.$el.find('.field');
                            fieldEles.each((index, el) => {
                                self.$(el).prop('checked', false);
                            });
                            self.$setState({ filter: null });
                            self.filterDialog.close();
                            self.refresh(null, () => { });
                        }
                    },
                    applyFilter(e) {
                        const self: F7ComponentContextExtend = this;
                        self.filterDialog && self.filterDialog.close();
                        const fieldEles = self.filterDialog.$el.find('.field');
                        console.log(fieldEles);
                        const filter: { [key: string]: any } = {};
                        fieldEles.each((index, el) => {
                            console.log(index, el.checked);
                            if (el.checked) {
                                filter[el.name] = el.value;
                            }
                        });
                        console.log(filter);
                        self.$setState({ filter: Object.keys(filter).length > 0 ? filter : null });
                        self.refresh(null, () => { });
                    },
                    closeCreateChatRoomDialog() {
                        const self: F7ComponentContextExtend = this;
                        self.createChatRoomDialog && self.createChatRoomDialog.close();
                    },
                    createChatRoom(e: MouseEvent) {
                        const self: F7ComponentContextExtend = this;
                        const chatRoomData: ChatRoomModel = {};
                        const fieldEles = self.createChatRoomDialog.$el.find('.field');
                        fieldEles.each((index, fieldEle) => {
                            console.log(fieldEle);
                            console.log(fieldEle.value);
                            chatRoomData[fieldEle.name] = fieldEle.value;
                        });
                        if (!chatRoomData.Description) {
                            console.error('Bạn chưa điền mô tả');
                            $this.commonService.showError('Bạn chưa điền mô tả');
                            return;
                        }
                        console.log(chatRoomData);
                        self.$app.preloader.show();
                        try {
                            $this.rootServices.apiService.postPromise<ChatRoomModel[]>('/chat/rooms', {}, [chatRoomData]).then(rs => {
                                console.log(rs);
                                if (rs && rs[0] && rs[0].Code) {
                                    $this.commonService.navigate('/chat-room/' + rs[0].Code, {
                                        context: {
                                            backTitle: 'Workplace',
                                            title: rs[0].Description,
                                        }
                                    });
                                    self.createChatRoomDialog.close();
                                    fieldEles.each((index, fieldEle) => {
                                        fieldEle.value = null;
                                        self.$(fieldEle).change();
                                    });
                                    self.$app.preloader.hide();
                                }
                            }).catch(err => {
                                console.error(err);
                                self.$app.preloader.hide();
                                $this.commonService.showError(err);
                            });
                        } catch (err) {
                            console.error(err);
                            self.$app.preloader.hide();
                            $this.commonService.showError(err);
                        }
                    },
                    openCreateTicketDialog(e: MouseEvent) {
                        const self: F7ComponentContextExtend = this;
                        if (self.createChatRoomDialog) {
                            self.createChatRoomDialog.open();
                            self.createChatRoomDialog.$el.find('.field[name="Description"]').focus();
                        }
                    },
                    openCreateWorkplaceDialog(e: MouseEvent) {
                        const self: F7ComponentContextExtend = this;
                        if (self.createWorkplaceDialog) {
                            self.createWorkplaceDialog.open();
                            self.createWorkplaceDialog.$el.find('.field[name="Description"]').focus();
                        }
                    },
                    closeCreateWorkplaceDialog() {
                        const self: F7ComponentContextExtend = this;
                        self.createWorkplaceDialog && self.createWorkplaceDialog.close();
                    },
                    createWorkplace(e: MouseEvent) {
                        const self: F7ComponentContextExtend = this;
                        const chatRoomData: ChatRoomModel = {};
                        const fieldEles = self.createWorkplaceDialog.$el.find('.field');
                        fieldEles.each((index, fieldEle) => {
                            console.log(fieldEle);
                            console.log(fieldEle.value);
                            chatRoomData[fieldEle.name] = fieldEle.value;
                        });
                        if (!chatRoomData.Description) {
                            console.error('Bạn chưa điền mô tả');
                            $this.commonService.showError('Bạn chưa điền mô tả');
                            return;
                        }
                        chatRoomData.Type = 'WORKPLACE';
                        chatRoomData.State = 'OPEN';
                        console.log(chatRoomData);
                        self.$app.preloader.show();
                        try {
                            $this.rootServices.apiService.postPromise<ChatRoomModel[]>('/chat/rooms', {}, [chatRoomData]).then(rs => {
                                console.log(rs);
                                if (rs && rs[0] && rs[0].Code) {
                                    $this.commonService.navigate('/chat-room/' + rs[0].Code, {
                                        context: {
                                            backTitle: 'Workplace',
                                            title: rs[0].Description,
                                        }
                                    });
                                    self.createWorkplaceDialog.close();
                                    fieldEles.each((index, fieldEle) => {
                                        fieldEle.value = null;
                                        self.$(fieldEle).change();
                                    });
                                    self.$app.preloader.hide();
                                }
                            }).catch(err => {
                                console.error(err);
                                self.$app.preloader.hide();
                                $this.commonService.showError(err);
                            });
                        } catch (err) {
                            console.error(err);
                            self.$app.preloader.hide();
                            $this.commonService.showError(err);
                        }
                    },
                    async refresh(e, done) {
                        $this.refresh(this).then(rs => {
                            done && done();
                        });
                        return true;
                    },
                    async openChatRoom(e, id?: string) {

                    },
                    updateBadge(e) {
                        const self: F7ComponentContextExtend = this;
                        let unreadCount = 0;
                        if (self.chatRooms) {
                            for (const cahtRoom of self.chatRooms) {
                                if (cahtRoom.NumOfUnreadMessage) {
                                    unreadCount += parseInt(cahtRoom.NumOfUnreadMessage);
                                }
                            }
                            $this.rootServices.workplaceBadge = unreadCount;
                        }
                    },
                },
                on: {
                    async pageInit(e, page: F7Page) {
                        console.log('page init');
                        const self: F7ComponentContextExtend = this;
                        if (self.$route.params['id']) {
                            self.mode = self.$route.params['id'];
                        }
                        const currentState: ComponentStateExtend = await $this.onComponentInit({ instance: self }, 'main', null, page).then(currentState => {
                            // Init create chat room dialog
                            self.createChatRoomDialog = self.$app.dialog.create({
                                el: page.$el.find('.dialog-create-chat-room'),
                                closeByBackdropClick: true,
                            });
                            self.createWorkplaceDialog = self.$app.dialog.create({
                                el: page.$el.find('.dialog-create-workplace-dialog'),
                                closeByBackdropClick: true,
                            });
                            self.filterDialog = self.$app.dialog.create({
                                el: page.$el.find('.dialog-filter'),
                                closeByBackdropClick: true,
                            });
                            self.filterDialog.$el.find('.field').each((index, field) => {
                                self.$(field).change((e: Event) => {
                                    console.log(e);
                                    if (e.target['name'] === 'filter_State' && e.target['checked']) {
                                        self.filterDialog.$el.find('.field[name="filter_State"]').filter((index, el) => el.value !== e.target['value']).prop('checked', false);
                                    }
                                });
                            });


                            return currentState;
                        });

                        // const extendComponentId = self.$route?.context?.extendComponentId;





                        // if (page.router.history && page.router.history[page.router.history.length - 1] === page.route.url) {
                        self.refresh();
                        // }
                    },
                    pageAfterIn(e, page) {
                        console.log('p[page event]age after in', page.route.url);
                        const self: F7ComponentContextExtend = this;
                        $this.onChangedState({ instance: self, page }, 'main', 'before-in', { doNotRefresh: true }).then(currentState => {
                            self.$setState({
                                lastUpdateMoment: moment(self.lastUpdate).fromNow(),
                            });
                        });
                    },
                    pageBeforeIn(e, page: F7Page) {
                        console.log('[page event] before in', page.route.url);
                        const self: F7ComponentContextExtend = this;

                        if ($this.rootServices.changedChatRooms.workplace.length > 0) {
                            const updatedRooms = [... new Set($this.rootServices.changedChatRooms.workplace)];
                            setTimeout(() => {
                                $this.rootServices.apiService.getPromise<ChatRoomModel[]>('/chat/rooms', { id: updatedRooms }).then(chatRoomInfos => {
                                    // const newChatRooms = self.virtualList.items.map(oldChatRoom => {
                                    //     const newChatRoom = chatRoomInfos.find(f => f.Code === oldChatRoom.Code);
                                    //     if (newChatRoom) {
                                    //         // newChatRoom.LastUpdate_Moment = moment(newChatRoom.LastUpdate).fromNow();
                                    //         // newChatRoom.DateOfCreate = new Date(newChatRoom.DateOfCreate).toLocaleString();
                                    //         // const unread = newChatRoom.NumOfMessage - newChatRoom.LoggedNumOfMessage - newChatRoom.LoggedNumOfReadMessage;
                                    //         // newChatRoom.NumOfUnreadMessage = unread > 0 ? unread : '';

                                    //         $this.preapreTaskState(newChatRoom);
                                    //         return newChatRoom;
                                    //     }
                                    //     return oldChatRoom;
                                    // });
                                    // self.$setState({
                                    //     items: newChatRooms,
                                    // });
                                    for (const chatRoomInfo of chatRoomInfos) {
                                        const index = self.virtualList.items.findIndex(f => f.Code == chatRoomInfo.Code);
                                        const updatedItem = $this.prepareItem(chatRoomInfo, index);
                                        if (index > -1) {
                                            self.virtualList.replaceItem(index, updatedItem);
                                        }
                                    }

                                    // self.virtualList.items = newChatRooms;
                                    // self.virtualList.update();
                                    $this.rootServices.changedChatRooms.workplace = [];

                                    self.updateBadge();
                                }).catch(err => {
                                    console.error(err);
                                    $this.commonService.showError(err);
                                });
                            }, 1500);
                        }
                    },
                }
            },
        };
    }
}
