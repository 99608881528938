// import { environment } from './../../../../../src/environments/environment';
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpResponse, HttpErrorResponse,
  HttpInterceptor, HttpRequest, HttpHandler, HttpEvent,
} from '@angular/common/http';
// import { NbAuthService, NbAuthToken } from '@nebular/auth';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { retry, catchError, switchMap, take, filter } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService, AuthToken, JwtToken } from './auth.service';
import { CommonService } from './common.service';
import { environment } from '../../../src/environments/environment';

export class ApiToken {
  // tslint:disable-next-line: variable-name
  access_token?: string;
  // tslint:disable-next-line: variable-name
  refresh_token?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  public baseApiUrl = '/';
  protected session = '';
  // public get token(): ApiToken {
  //   const tokenString = localStorage.getItem('auth_app_token');
  //   if (tokenString) {
  //     const tokenParse: { createdAt: string, name: string, ownerStrategyName: string, value: string } = JSON.parse(tokenString);
  //     return JSON.parse(tokenParse.value);
  //   }
  //   return null;
  // }
  public token: JwtToken;
  // public apiUrl: string;

  private unauthoriziedSubject: BehaviorSubject<{ previousUrl: string }>
    = new BehaviorSubject<{ previousUrl: string }>(null);
  public unauthorizied$: Observable<{ previousUrl: string }> = this.unauthoriziedSubject.asObservable();
  env = environment;

  constructor(
    public http: HttpClient,
    public authService: AuthService,
    public router: Router,
    // public dialogService: NbDialogService,
    // public translate: TranslateService,
    // private commonService: CommonService,
    // private activatedRoute: ActivatedRouteSnapshot,
  ) {

    this.authService.onTokenChange()
      .subscribe((token: AuthToken) => {
        if (token && token.isValid()) {
          this.setToken(token.getPayload());
        }
      });

    this.authService.getToken().then((token: AuthToken) => {
      if (token.isValid()) {
        this.setToken(token.getPayload());
      }
    });
  }

  get apiUrl() {
    return this.token.api_url + '?token=' + this.token.access_token;
  }

  async prepareToken() {
    if (!this.token) {
      this.token = (await this.authService.getToken()).getPayload();
    }
  }

  getBaseApiUrl() {
    return this.token.api_url + '/' + this.env.api.version;
  }

  setToken(token: JwtToken) {
    if (token) {
      this.token = token;
      // this.token = JSON.parse(token.toString());
      // if (this.token) {
      //   this.setAccessToken(this.token['access_token']);
      //   this.setRefreshToken(this.token['refresh_token']);
      // }
    }
  }

  storeSession(session: string) {
    localStorage.setItem('api_session', session);
  }

  getSession(): string {
    return localStorage.getItem('api_session');
  }

  setAccessToken(token: string) {
    localStorage.setItem('api_access_token', token);
  }

  setRefreshToken(token: string) {
    localStorage.setItem('api_refresh_token', token);
  }

  getAccessToken(): string {
    return localStorage.getItem('api_access_token');
  }

  getRefreshToken(): string {
    return localStorage.getItem('api_refresh_token');
  }

  clearToken() {
    this.setAccessToken(null);
  }

  encodeId(id: string) {
    return id.replace(/-/g, '~!');
  }

  buildApiUrl(path: string, params?: { [key: string]: any, id?: string, id0?: string }) {
    if (!this.token) {
      this.token = { access_token: '', refresh_token: '' };
    }
    if (!params) params = {};
    params['token'] = (params && params['token']) || this.authService.token$?.value?.getPayload()?.access_token;
    if (!params['token']) delete (params['token']);

    // const token = this.getAccessToken();
    let paramsStr = '';

    if (typeof params === 'undefined') { params = {}; }
    if (Array.isArray(params.id)) {
      params.id.forEach((id, index) => {
        params['id' + index] = encodeURIComponent(this.encodeId(id));
      });
      delete params.id;
    } else if (params.id) {
      params.id0 = this.encodeId(params.id);
      delete params.id;
    }

    if (params) {
      paramsStr += this.buildParams(params);
    }
    // if (this.token) {
    //   paramsStr += (paramsStr ? '&' : '') + 'token=' + token;
    // }
    if (/^http/i.test(path)) {
      return `${path}?${paramsStr}`;
    }
    if (/\/v\d+/.test(this.token.api_url)) {
      this.token.api_url = this.token.api_url.replace(/\/v\d+/, '');
    }
    return `${this.token.api_url}/${this.env.api.version}${path}?${paramsStr}`;
  }

  buildParams(params: any): string {
    let httpParams = '';
    let first = true;
    Object.entries(params).forEach(([key, value]) => {
      httpParams += `${!first ? '&' : ''}${key}=${typeof value !== 'undefined' ? encodeURIComponent(value as any) : ''}`;
      first = false;
    });
    return httpParams;
  }

  refreshToken(success: () => void, error?: () => void) {
    this.authService.isAuthenticatedOrRefresh().subscribe(result => {
      console.debug(result);
      success();
    });
  }

  /** Restful api getting request */
  // get<T>(enpoint: string, params: { [key: string]: any, silent?: boolean },
  //   success: (resources: T) => void, error?: (e: HttpErrorResponse) => void,
  //   complete?: (resp: T | HttpErrorResponse) => void) {
  //   const obs = this.http.get<T>(this.buildApiUrl(enpoint, params))
  //     .pipe(retry(0), catchError(e => {
  //       if (error) { error(e); }
  //       if (complete) { complete(e); }
  //       return this.handleError(e, params.silent);
  //     }))
  //     .subscribe((resources: T) => {
  //       success(resources);
  //       if (complete) { complete(resources); }
  //       obs.unsubscribe();
  //     });
  // }

  /** Restful api getting request - promise */
  async getPromise<T>(enpoint: string, params?: { silent?: boolean, [key: string]: any }): Promise<T> {
    // return new Promise<T>(async (resolve, reject) => {

    // Wait for token prepared
    if (!params?.skipAuth) {
      await this.prepareToken();
      // if (!this.token || !this.token.access_token) {
      //   reject('token was not set');
      // }
      if (!this.token || !this.token.api_url) {
        // this.token = { ...this.token, api_url: this.env.api.defaultUrl };
        return Promise.reject('Token not set');
      }
    } else {
      this.token = { ...this.token, api_url: this.env.api.defaultUrl };
    }

    return this.http.get<T>(this.buildApiUrl(enpoint, params))
      .pipe(retry(0), take(1), catchError(e => {
        // reject(e);
        // Promise.reject(e);
        return this.handleError(e, params.silent);
      })).toPromise();
    // .subscribe((resources: T) => {
    //   resolve(resources);
    // });
    // });

  }

  /** Restful api getting request - promise */
  getObservable<T>(enpoint: string, params?: { silent?: boolean, [key: string]: any }): Observable<HttpResponse<T>> {

    return this.http.get<T>(this.buildApiUrl(enpoint, params), { observe: 'response' }).pipe(
      retry(0),
      take(1),
      catchError(e => {
        return this.handleError(e, params.silent);
      }));
  }

  /** Restful api post request */
  // post<T>(enpoint: string, params: any, resource: T, success: (newResource: T) => void, error?: (e: HttpErrorResponse) => void,
  //   complete?: (resp: T | HttpErrorResponse) => void) {
  //   const obs = this.http.post(this.buildApiUrl(enpoint, params), resource)
  //     .pipe(retry(0), catchError(e => {
  //       if (error) { error(e); }
  //       if (complete) { complete(e); }
  //       return this.handleError(e, params.silent);
  //     }))
  //     .subscribe((newResource: T) => {
  //       success(newResource);
  //       if (complete) { complete(newResource); }
  //       obs.unsubscribe();
  //     });
  // }

  /** Restful api post request */
  postPromise<T>(enpoint: string, params: any, resource: T): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.http.post(this.buildApiUrl(enpoint, params), resource)
        .pipe(retry(0), take(1), catchError(e => {
          reject(e);
          return this.handleError(e, params.silent);
        }))
        .subscribe((newResource: T) => {
          resolve(newResource);
          // obs.unsubscribe();
        });
    });
  }

  uploadPromise(enpoint: string, params: any, resource: FormData): Observable<HttpEvent<any>> {

    const req = new HttpRequest('POST', this.buildApiUrl(enpoint, params), resource, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req).pipe(catchError(e => {
      return this.handleError(e, params.silent);
    }));

    // return new Promise<T>((resolve, reject) => {
    //   this.http.post(this.buildApiUrl(enpoint, params), resource)
    //     .pipe(retry(0), take(1), catchError(e => {
    //       reject(e);
    //       return this.handleError(e, params.silent);
    //     }))
    //     .subscribe((newResource: T) => {
    //       resolve(newResource);
    //       // obs.unsubscribe();
    //     });
    // });
  }

  /** Restful api put request */
  // put<T>(enpoint: string, params: any, resource: T, success: (newResource: T) => void,
  //   error?: (e: HttpErrorResponse) => void,
  //   complete?: (resp: T | HttpErrorResponse) => void) {
  //   const obs = this.http.put(this.buildApiUrl(enpoint, params), resource)
  //     .pipe(retry(0), catchError(e => {
  //       if (error) { error(e); }
  //       if (complete) { complete(e); }
  //       return this.handleError(e, params.silent);
  //     }))
  //     .subscribe((newResource: T) => {
  //       success(newResource);
  //       if (complete) { complete(newResource); }
  //       obs.unsubscribe();
  //     });
  // }

  /** Restful api put request */
  putPromise<T>(enpoint: string, params: any, resource: T): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const obs = this.http.put(this.buildApiUrl(enpoint, params), resource)
        .pipe(retry(0), catchError(e => {
          reject(e);
          return this.handleError(e, params.silent);
        }))
        .subscribe((newResource: T) => {
          resolve(newResource);
          obs.unsubscribe();
        });
    });
  }

  // postPut<T>(method: string, enpoint: string, params: any, resource: T,
  //   success: (newResource: T) => void, error?: (e: HttpErrorResponse) => void,
  //   complete?: (resp: T | HttpErrorResponse) => void) {
  //   if (method === 'POST') {
  //     this.post<T>(enpoint, params, resource, success, error, complete);
  //   } else if (method === 'PUT') {
  //     this.put<T>(enpoint, params, resource, success, error, complete);
  //   }
  // }

  /** Restful api delete request */
  deletePromise<T>(enpoint: string, params: any) {

    return new Promise<T>((resolve, reject) => {
      this.http.delete(this.buildApiUrl(enpoint, params))
        .pipe(take(1), retry(0), catchError(e => {
          reject(e);
          return this.handleError(e, params.silent);
        }))
        .subscribe((newResource: T) => {
          resolve(newResource);
        });
    });

    // let apiUrl = '';
    // if (Array.isArray(id)) {
    //   const params = {};
    //   id.forEach((item, index) => {
    //     params['id' + index] = encodeURIComponent(item);
    //   });
    //   apiUrl = this.buildApiUrl(`${enpoint}`, params);
    // } else if (typeof id === 'object') {
    //   apiUrl = this.buildApiUrl(enpoint, id);
    // }
    // const obs = this.http.delete(apiUrl)
    //   .pipe(retry(0), catchError(e => {
    //     if (error) { error(e); }
    //     if (complete) { complete(e); }
    //     return this.handleError(e, id.silent);
    //   }))
    //   .subscribe((resp) => {
    //     success(resp);
    //     if (complete) { complete(resp); }
    //     obs.unsubscribe();
    //   });
  }

  logout(sucess, error) {
    this.http.get<HttpResponse<any>>(this.buildApiUrl('/user/logout'), { observe: 'response' })
      .subscribe((resp: HttpResponse<any>) => {
        sucess(resp.body);
      }, (e: HttpErrorResponse) => {
        if (error) { error(e); }
      });
  }

  // tslint:disable-next-line: member-ordering
  private takeUltilCount = {};
  // tslint:disable-next-line: member-ordering
  private takeUltilPastCount = {};
  async takeUntil(context: string, delay: number, callback?: () => void): Promise<boolean> {
    const result = new Promise<boolean>(resolve => {
      if (delay === 0) {
        resolve(true);
        return;
      }
      if (!this.takeUltilCount[context]) { this.takeUltilCount[context] = 0; }
      this.takeUltilCount[context]++;
      ((takeCount) => {
        setTimeout(() => {
          this.takeUltilPastCount[context] = takeCount;
        }, delay);
      })(this.takeUltilCount[context]);
      setTimeout(() => {
        if (this.takeUltilPastCount[context] === this.takeUltilCount[context]) {
          resolve(true);
        }
      }, delay);
    });
    if (callback) {
      callback();
    }
    return result;
  }

  onUnauthorizied() {
    this.takeUntil('ultil_unauthorize', 5000).then(() => {
      // Fix stress requests
      this.authService.isAuthenticated().subscribe(isAuth => {
        if (!isAuth) {
          this.unauthoriziedSubject.next({
            previousUrl: this.router.url,
          });
        }
      });
    });
  }

  handleError(e: HttpErrorResponse, silent?: boolean) {
    if (e.status === 0) {
      console.warn('Network down !');
      return throwError(e);
    }
    if (e.status === 401) {
      console.warn('You were not logged in');
      this.onUnauthorizied();
    }
    if (e.status === 405) {
      // if (!silent) this.dialogService.open(ShowcaseDialogComponent, {
      //   context: {
      //     title: 'Yêu cầu quyền truy cập',
      //     content: this.joinLogs(e),
      //     actions: [
      //       {
      //         label: 'Trở về',
      //         icon: 'back',
      //         status: 'info',
      //         action: () => { },
      //       },
      //     ],
      //   },
      // });
    }
    if (e.status === 400) {
      // if (!silent) this.dialogService.open(ShowcaseDialogComponent, {
      //   context: {
      //     title: 'Yêu cầu không thể thực thi',
      //     content: this.joinLogs(e),
      //     actions: [
      //       {
      //         label: 'Trở về',
      //         icon: 'back',
      //         status: 'info',
      //         action: () => { },
      //       },
      //     ],
      //   },
      // });
    }
    if (e.status === 403) {
      // if (!silent) this.dialogService.open(ShowcaseDialogComponent, {
      //   context: {
      //     title: 'Yêu cầu không có quyền',
      //     content: this.joinLogs(e),
      //     actions: [
      //       {
      //         label: 'Trở về',
      //         icon: 'back',
      //         status: 'info',
      //         action: () => { },
      //       },
      //     ],
      //   },
      // });
    }
    let errorMessage = '';
    if (e.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${e.error.message}`;
    } else {
      if (e.error && e.error.logs) {
        const errorLogs = e.error.logs[0];
        errorMessage = `Error Code: ${e.status}\nMessage: ${errorLogs}`;
      } else {
        // server-side error
        errorMessage = `Error Code: ${e.status}\nMessage: ${e.message}`;
      }
    }
    console.debug(errorMessage);
    return throwError(errorMessage);
  }

  joinLogs(e: HttpErrorResponse & { error: { logs: string[] } }): string {
    if (e.error.logs) {
      if (e.error.logs.length > 1) {
        return '<ul><li>' + e.error.logs.map((log: string) => this.textTransform(log, 'head-title')).join('</li><li>') + '</li></ul>';
      }
      if (e.error.logs.length === 1) {
        return e.error.logs.map((log: string) => this.textTransform(log, 'head-title'))[0];
      }
      return e.message;
    }
    return '';
  }

  textTitleCase(text: string) {
    const sentence = text.toLowerCase().split(' ');
    for (let i = 0; i < sentence.length; i++) {
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
    document.write(sentence.join(' '));
    return sentence.join(' ');
  }

  textTransform(text: string, transform: 'title' | 'upper' | 'lower' | 'head-title') {
    switch (transform) {
      case 'title':
        return this.textTitleCase(text);
      case 'upper':
        return text.toUpperCase();
      case 'lower':
        return text.toLowerCase();
      case 'head-title':
        return text.replace(/^./, text.charAt(0).toUpperCase());
      default: return text;
    }
  }
}

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  refreshTokenInProgress = false;
  private refreshTokenSubject = new BehaviorSubject<AuthToken>(null);
  constructor(
    protected authService: AuthService,
    protected apiService: ApiService,
    protected commonService: CommonService,
  ) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!/\/v\d+\//i.test(req.url) || /\/v\d+\/user\/login|register/i.test(req.url)) {
      return next.handle(req);
    }

    try {
      const urlParsed = new URL(req.url);
      // skip authen check if not had token or had skippAuth was set 'true'
      if (req.url && urlParsed.searchParams.get('skipAuth') === 'true' || !urlParsed.searchParams.get('token')) {
        return next.handle(req).pipe(catchError(error2 => {
          if (/\/v\d+\/user\/login|register/i.test(req.url)) {
            this.apiService.onUnauthorizied();
            return next.handle(error2);
          }
          if (error2.status !== 401) {
            return throwError(error2);
          }
          return this.refreshToken(req, next);
        }));
      }
    } catch (err) {
      // skip err
    }

    return this.authService.isAuthenticated().pipe(switchMap(isAuth => {
      if (!isAuth) {
        return this.refreshToken(req, next);
      } else {

        return next.handle(req).pipe(catchError(error2 => {
          if (req.url.includes('v1/user/login') || req.url.includes('v1/user/register')) {
            this.apiService.onUnauthorizied();
            return next.handle(error2);
          }
          if (error2.status !== 401) {
            return throwError(error2);
          }
          return this.refreshToken(req, next);
        }));

      }
    }));
  }

  refreshToken(req: HttpRequest<any>, next: HttpHandler) {
    if (this.refreshTokenInProgress) {
      return this.refreshTokenSubject
        .pipe(filter(result => result && result.isValid()),
          take(1), switchMap((newAuthToken) => {
            return this.continueRequest(req, next, newAuthToken.getPayload().access_token);
          }));
    }

    this.refreshTokenInProgress = true;
    const autoUnlock = setTimeout(() => {
      this.refreshTokenInProgress = false;
    }, 5000);
    // if(!this.apiService.token || !this.apiService.token.refresh_token) {
    //   return throwError('AutRefresh token fail');
    // }   
    return this.authService.refreshToken('email').pipe(switchMap(authResult => {

      this.refreshTokenInProgress = false;
      clearTimeout(autoUnlock);
      if (authResult.isValid()) {
        this.apiService.setToken(authResult.getPayload());
        // this.apiService.token = authResult.getPayload();
        this.refreshTokenSubject.next(authResult);
        return this.continueRequest(req, next, authResult.getPayload().access_token);
      }
      this.apiService.onUnauthorizied();
      return throwError('AutRefresh token fail - token not valid');

    }), catchError((error2: HttpErrorResponse) => {
      clearTimeout(autoUnlock);
      this.refreshTokenInProgress = false;
      console.log(error2);
      return throwError('AutRefresh token fail');
    }));
  }

  continueRequest(req: HttpRequest<any>, next: HttpHandler, accessToken: string) {
    return next.handle(req.clone({
      url: req.url.replace(/token=([^\/=\?&]+)/, `token=${accessToken}`),
    }));
  }
}
