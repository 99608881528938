import { Injectable } from "@angular/core";
import { RootServices as SbRootServices } from 'vendors/smart-bot-app/src/app/services/root.services';

@Injectable({
    providedIn: 'root',
})
export class RootServices extends SbRootServices {

    async cacheUpdate(option?: { silent?: boolean }) {
        return true;
    }

}
