import { CollaboratorOrderModel } from './../../../model/collaborator.model';
import { Router } from 'framework7/build/core/modules/router/router';
import { Messages } from 'framework7/build/core/components/messages/messages';
import * as moment from 'moment';
import { Searchbar } from 'framework7/build/core/components/searchbar/searchbar';
import { isPlatform } from '@ionic/angular';
import { Dialog } from 'framework7/build/core/components/dialog/dialog';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { BaseComponent, ComponentState } from 'vendors/smart-bot-app/src/app/lib/base-component';
import { ChatRoomModel } from 'vendors/smart-bot-app/src/app/model/chat-room.model';
import { RootServices } from 'vendors/smart-bot-app/src/app/services/root.services';
import { CommonService } from 'vendors/smart-bot-app/src/app/services/common.service';
import { F7ComponentContext, F7Messagebar, F7Page } from 'src/app/types/framework7-types';

export interface ComponentStateExtend extends ComponentState {
    [key: string]: any;
}

export class F7ComponentContextExtend extends F7ComponentContext {
    messagebar?: F7Messagebar;
    messages?: Messages.Messages;
    images?: string[];
    responseInProgress?: boolean;
    answers?: string[];
    people?: { name?: string, avatar?: string }[];
    hideToolbar?: () => void;
    sheetToggle?: () => void;
    deleteAttachment?: (e: MouseEvent, index: number) => void;
    handleAttachment?: (e: MouseEvent) => void;
    checkAttachments?: () => void;
    sendMessage?: () => void;
    $root: any;
    searchBar?: Searchbar.Searchbar;
    commissionDetailOrders?: ChatRoomModel[];

    createChatRoomDialog?: Dialog.Dialog;
    filterDialog?: Dialog.Dialog;
    createWorkplaceDialog?: Dialog.Dialog;
    mode?: string;
    callback?: (task: ChatRoomModel) => void;

    refresh?: (e?: Event, done?: () => void) => Promise<boolean>;
}

/** Component group manager */
export class PageCollaboratorCommissionDetailOrdersComponent extends BaseComponent<ComponentStateExtend> {
    // states: { [key: string]: State } = {};

    title = 'Danh sách đơn hàng được tính chiết khấu';

    stateIndex = {
        APPROVED: 'đã duyệt',
        NOTJUSTAPPROVED: 'chưa duyệt',
        COMPLETE: 'hoàn tất',
        CONFIRMATIONREQUESTED: 'yêu cầu xác nhận',
        CONFIRMED: 'đã xác nhận',
    };

    stateColorIndex = {
        APPROVED: 'green',
        NOTJUSTAPPROVED: 'red',
        COMPLETE: 'green',
        CONFIRMATIONREQUESTED: 'orange',
        CONFIRMED: 'blue',
    };

    constructor(
        public rootServices: RootServices,
        public commonService: CommonService,
        public datePipe: DatePipe,
        public currencyPipe: CurrencyPipe,
    ) {
        super(rootServices);
        // // console.log('Click here to open ts file');
    }

    onF7pageRemove(chatRoomId: string) {
        // if (this.chatRoomCacheList[chatRoomId]) {
        //   this.chatRoomCacheList[chatRoomId].disconnect();
        // }
    }

    preapreListItem(item: any, voucherDetail: any) {
        // const unread = t.NumOfMessage - t.LoggedNumOfMessage - t.LoggedNumOfReadMessage;
        // item.LastUpdate_Moment = moment(item.DateOfOrderText).fromNow();
        item.VoucherDateText = this.datePipe.transform(item.VoucherDate, 'short');
        // item.StateColor = this.stateColorIndex[item.State];
        item.PriceText = this.currencyPipe.transform(item.Price, 'VND');
        const netRevenue = item.Price * item.QuantityConversion;
        item.NetRevenueText = this.currencyPipe.transform(netRevenue, 'VND');
        item.CommissionAmountText = this.currencyPipe.transform(netRevenue * voucherDetail.CommissionRatio / 100, 'VND');
        // item.StateText = this.stateIndex[item.State];
        return item;
    }

    get f7Component(): Router.RouteParameters {
        const $this = this;
        return {
            name: 'page-collaborator-commission-detail-orders',
            path: '/page-collaborator-commission-detail-orders/:commissionVoucher/:no',
            component: {
                template: /*html*/`
                <div class="page">
                    <div class="navbar">
                        <div class="navbar-bg"></div>
                        <div class="navbar-inner sliding">
                            <div class="left">
                                <a class="link back {{textColorClass}}">
                                    <i class="icon icon-back"></i>
                                    <span class="if-not-md {{textColorClass}}">{{backTitle}}</span>
                                </a>
                            </div>
                            <div class="title text-color-default">{{title}}</div>
                            <div class="right">
                                <!-- Link to enable searchbar -->
                                <a class="link icon-only searchbar-enable text-color-default" data-searchbar=".workplace-searchbar">
                                    <i class="icon f7-icons if-not-md">search</i>
                                    <i class="icon material-icons md-only">search</i>
                                </a>
                            </div>
                            <!-- Searchbar is a direct child of "navbar-inner" -->
                            <form class="searchbar workplace-searchbar searchbar-expandable">
                                <div class="searchbar-inner">
                                    <div class="searchbar-input-wrap">
                                        <input type="search" placeholder="#thẻ/@thành viên/+tin nhắn/tiêu đề" />
                                        <i class="searchbar-icon"></i>
                                        <span class="input-clear-button"></span>
                                    </div>
                                    <span class="searchbar-disable-button text-color-default">Cancel</span>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="page-content ptr-content infinite-scroll-content" data-ptr-mousewheel="false" @ptr:refresh="refresh">
                        <div class="ptr-preloader">
                            <div class="preloader"></div>
                            <div class="ptr-arrow"></div>
                        </div>
                        <div class="searchbar-backdrop workplace-searchbar-backdrop"></div>
                
                        
                        <!--<div class="block inset block-strong page-banner bg-color-gray{{js "!this.pageInfo ? ' skeleton-text skeleton-effect-blink' : ''"}}" style="background-image: url({{js "this.pageInfo && this.pageInfo.Banner && this.pageInfo.Banner.OriginImage"}});"></div>-->
                        <div class="block inset block-strong page-bannerx {{js "!this.pageInfo ? ' skeleton-text skeleton-effect-blink' : ''"}}" style="padding: 0; overflow: hidden">
                            <img src="{{js "this.pageInfo && this.pageInfo.Banner && this.pageInfo.Banner.OriginImage"}}" style="width: 100%; margin-bottom: -6px">
                        </div>
                        <div class="block block-strong inset{{js "!this.pageInfo ? ' skeleton-text skeleton-effect-blink' : ''"}}">
                            <div class="page-header">
                                <div class="logo bg-color-gray" style="background-image: url({{js "this.pageInfo && this.pageInfo.Logo && this.pageInfo.Logo.OriginImage"}});"></div>
                                <div class="info">
                                    <div class="name">{{js "this.pageInfo && this.pageInfo.Name"}}</div>
                                    <div class="summary">{{js "this.pageInfo && this.pageInfo.Summary"}}</div>
                                    <div class="tag">@{{js "this.pageInfo && this.pageInfo.TagName"}}</div>
                                </div>
                            </div>
                        </div>
                
                        <div class="block block-strong inset{{js "!this.commissionDetail || !this.commissionDetail.CommissionVoucher ? ' skeleton-text skeleton-effect-blink' : ''"}}">
                            <div>{{commissionDetail.ProductName}}</div>
                            <div class="text-color-orange">KPI đạt được: {{commissionDetail.SumOfQuantity}}</div>
                            <div class="text-color-blue">Doanh số: {{commissionDetail.SumOfNetRevenueText}}</div>
                            <div class="text-color-green">Tổng chiết khấu: <span style="font-weight: bold">{{commissionDetail.CommissionAmountText}}</span></div>
                        </div>
                
                        <div class="block-title">Đơn hàng được tính chiết khấu</div>
                        {{#js_if "this.commissionDetailOrders && this.commissionDetailOrders.length > 0"}}
                        <div class="list media-list workplace-list inset">
                            <ul>
                                {{#each commissionDetailOrders}}
                                <li data-id="{{Voucher}}" @click="openOrder">
                                    <a class="item-link item-content">
                                        <div class="item-inner">
                                            <div class="item-title-row">
                                                <div class="item-title text-color-default description" style="font-size: 0.9rem">
                                                    {{Voucher}}</div>
                                                <div class="item-after text-color-blue" style="font-size: 0.8rem"><span
                                                        class="badge color-{{StateColor}} unread">{{StateText}}</span></div>
                                            </div>
                                            <div class="item-subtitle text-color-gray">
                                                <div class="chat-list-item-label"><i
                                                        class="icon f7-icons">timer_fill</i>{{Description}}</div>
                                            </div>
                                            <div class="item-subtitle text-color-gray">
                                                <div class="chat-list-item-label">
                                                    <i class="icon f7-icons">person_crop_square_fill</i>Ngày
                                                </div>
                                                <div class="text-color-orange lastUpdateMoment" style="float: right">{{VoucherDateText}}
                                                </div>
                                            </div>
                                            <div class="item-subtitle text-color-gray">
                                                <div class="chat-list-item-label">
                                                    <i class="icon f7-icons">person_crop_square_fill</i>Số lượng
                                                </div>
                                                <div class="text-color-orange lastUpdateMoment" style="float: right">{{Quantity}} {{UnitLabel}}
                                                </div>
                                            </div>
                                            <div class="item-subtitle text-color-gray">
                                                <div class="chat-list-item-label">
                                                    <i class="icon f7-icons">person_crop_square_fill</i>Đơn giá
                                                </div>
                                                <div class="lastUpdateMoment" style="float: right">{{PriceText}}
                                                </div>
                                            </div>
                                            <div class="item-subtitle text-color-gray">
                                                <div class="chat-list-item-label">
                                                    <i class="icon f7-icons">person_crop_square_fill</i>Doanh số
                                                </div>
                                                <div class="text-color-blue lastUpdateMoment" style="float: right">{{NetRevenueText}}
                                                </div>
                                            </div>
                                            <div class="item-subtitle text-color-gray">
                                                <div class="chat-list-item-label">
                                                    <i class="icon f7-icons">person_crop_square_fill</i>Chiết khấu
                                                </div>
                                                <div class="text-color-green lastUpdateMoment" style="float: right">{{CommissionAmountText}}
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                {{/each}}
                            </ul>
                            {{#if infiniteLoadLast}}<div style="text-align: center" class="text-color-gray">end</div>{{else}}<div
                                class="preloader infinite-scroll-preloader"></div>{{/if}}
                        </div>
                        {{else}}
                        <div class="list media-list workplace-list inset">
                            <ul>
                                <li class="skeleton-text skeleton-effect-blink">
                                    <a class="item-link item-content">
                                        <div class="item-inner">
                                            <div class="item-title-row">
                                                <div class="item-title text-color-default description" style="font-size: 0.9rem"> lobortis lobortis lobortis lobortis lobortis lobortis lobortis</div>
                                                <div class="item-after text-color-blue" style="font-size: 0.8rem">
                                                    <span class="badge color-green unread">9</span>
                                                </div>
                                            </div>
                                            <div class="item-subtitle text-color-gray">
                                                <div class="chat-list-item-label"><i class="icon f7-icons">timer_fill</i>lobortis lobortis lobortis</div>
                                            </div>
                                            <div class="item-subtitle text-color-gray">
                                                <div class="chat-list-item-label">
                                                    <i class="icon f7-icons">person_crop_square_fill</i>lobortis lobortis
                                                </div>
                                                <div class="text-color-blue lastUpdateMoment" style="float: right">lobortis&nbsp;₫
                                                </div>
                                            </div>
                                            <div class="item-text" style="clear: both; max-height: initial;">
                                                <span class="lastMessageText"><div class="chip"><div class="chip-label text-color-gray" style="font-weight: bold">lobortis lobortis</div></div></span>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li class="skeleton-text skeleton-effect-blink">
                                    <a class="item-link item-content">
                                        <div class="item-inner">
                                            <div class="item-title-row">
                                                <div class="item-title text-color-default description" style="font-size: 0.9rem"> lobortis lobortis lobortis lobortis lobortis lobortis lobortis</div>
                                                <div class="item-after text-color-blue" style="font-size: 0.8rem">
                                                    <span class="badge color-green unread">9</span>
                                                </div>
                                            </div>
                                            <div class="item-subtitle text-color-gray">
                                                <div class="chat-list-item-label"><i class="icon f7-icons">timer_fill</i>lobortis lobortis lobortis</div>
                                            </div>
                                            <div class="item-subtitle text-color-gray">
                                                <div class="chat-list-item-label">
                                                    <i class="icon f7-icons">person_crop_square_fill</i>lobortis lobortis
                                                </div>
                                                <div class="text-color-blue lastUpdateMoment" style="float: right">lobortis&nbsp;₫
                                                </div>
                                            </div>
                                            <div class="item-text" style="clear: both; max-height: initial;">
                                                <span class="lastMessageText"><div class="chip"><div class="chip-label text-color-gray" style="font-weight: bold">lobortis lobortis</div></div></span>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li class="skeleton-text skeleton-effect-blink">
                                    <a class="item-link item-content">
                                        <div class="item-inner">
                                            <div class="item-title-row">
                                                <div class="item-title text-color-default description" style="font-size: 0.9rem"> lobortis lobortis lobortis lobortis lobortis lobortis lobortis</div>
                                                <div class="item-after text-color-blue" style="font-size: 0.8rem">
                                                    <span class="badge color-green unread">9</span>
                                                </div>
                                            </div>
                                            <div class="item-subtitle text-color-gray">
                                                <div class="chat-list-item-label"><i class="icon f7-icons">timer_fill</i>lobortis lobortis lobortis</div>
                                            </div>
                                            <div class="item-subtitle text-color-gray">
                                                <div class="chat-list-item-label">
                                                    <i class="icon f7-icons">person_crop_square_fill</i>lobortis lobortis
                                                </div>
                                                <div class="text-color-blue lastUpdateMoment" style="float: right">lobortis&nbsp;₫
                                                </div>
                                            </div>
                                            <div class="item-text" style="clear: both; max-height: initial;">
                                                <span class="lastMessageText"><div class="chip"><div class="chip-label text-color-gray" style="font-weight: bold">lobortis lobortis</div></div></span>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li class="skeleton-text skeleton-effect-blink">
                                    <a class="item-link item-content">
                                        <div class="item-inner">
                                            <div class="item-title-row">
                                                <div class="item-title text-color-default description" style="font-size: 0.9rem"> lobortis lobortis lobortis lobortis lobortis lobortis lobortis</div>
                                                <div class="item-after text-color-blue" style="font-size: 0.8rem">
                                                    <span class="badge color-green unread">9</span>
                                                </div>
                                            </div>
                                            <div class="item-subtitle text-color-gray">
                                                <div class="chat-list-item-label"><i class="icon f7-icons">timer_fill</i>lobortis lobortis lobortis</div>
                                            </div>
                                            <div class="item-subtitle text-color-gray">
                                                <div class="chat-list-item-label">
                                                    <i class="icon f7-icons">person_crop_square_fill</i>lobortis lobortis
                                                </div>
                                                <div class="text-color-blue lastUpdateMoment" style="float: right">lobortis&nbsp;₫
                                                </div>
                                            </div>
                                            <div class="item-text" style="clear: both; max-height: initial;">
                                                <span class="lastMessageText"><div class="chip"><div class="chip-label text-color-gray" style="font-weight: bold">lobortis lobortis</div></div></span>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li class="skeleton-text skeleton-effect-blink">
                                    <a class="item-link item-content">
                                        <div class="item-inner">
                                            <div class="item-title-row">
                                                <div class="item-title text-color-default description" style="font-size: 0.9rem"> lobortis lobortis lobortis lobortis lobortis lobortis lobortis</div>
                                                <div class="item-after text-color-blue" style="font-size: 0.8rem">
                                                    <span class="badge color-green unread">9</span>
                                                </div>
                                            </div>
                                            <div class="item-subtitle text-color-gray">
                                                <div class="chat-list-item-label"><i class="icon f7-icons">timer_fill</i>lobortis lobortis lobortis</div>
                                            </div>
                                            <div class="item-subtitle text-color-gray">
                                                <div class="chat-list-item-label">
                                                    <i class="icon f7-icons">person_crop_square_fill</i>lobortis lobortis
                                                </div>
                                                <div class="text-color-blue lastUpdateMoment" style="float: right">lobortis&nbsp;₫
                                                </div>
                                            </div>
                                            <div class="item-text" style="clear: both; max-height: initial;">
                                                <span class="lastMessageText"><div class="chip"><div class="chip-label text-color-gray" style="font-weight: bold">lobortis lobortis</div></div></span>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li class="skeleton-text skeleton-effect-blink">
                                    <a class="item-link item-content">
                                        <div class="item-inner">
                                            <div class="item-title-row">
                                                <div class="item-title text-color-default description" style="font-size: 0.9rem"> lobortis lobortis lobortis lobortis lobortis lobortis lobortis</div>
                                                <div class="item-after text-color-blue" style="font-size: 0.8rem">
                                                    <span class="badge color-green unread">9</span>
                                                </div>
                                            </div>
                                            <div class="item-subtitle text-color-gray">
                                                <div class="chat-list-item-label"><i class="icon f7-icons">timer_fill</i>lobortis lobortis lobortis</div>
                                            </div>
                                            <div class="item-subtitle text-color-gray">
                                                <div class="chat-list-item-label">
                                                    <i class="icon f7-icons">person_crop_square_fill</i>lobortis lobortis
                                                </div>
                                                <div class="text-color-blue lastUpdateMoment" style="float: right">lobortis&nbsp;₫
                                                </div>
                                            </div>
                                            <div class="item-text" style="clear: both; max-height: initial;">
                                                <span class="lastMessageText"><div class="chip"><div class="chip-label text-color-gray" style="font-weight: bold">lobortis lobortis</div></div></span>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        {{/js_if}}
                    </div>
                </div>
              `,
                style: ``,
                data() {
                    const self: F7ComponentContextExtend = this;
                    return {
                        title: self.title || $this.title,
                        backTitle: 'Back',
                        commissionDetailOrders: [],
                        infiniteLoadLast: true,
                        disabledLeftPannel: false && isPlatform('desktop'),
                        lastUpdateMoment: moment(Date.now()).fromNow(),
                        filter: null,
                        mode: '',
                        unreadFilterStatus: false,
                        // pageInfo: { Banner: {} },
                        commissionDetail: {},
                    };
                },
                methods: {
                    openExtOrders(e) {
                        const self: F7ComponentContextExtend = this;
                        $this.commonService.navigate('/page-collaborator-award-detail-reforders/' + self.$route.params?.id, { context: { commission: self.commissions, pageInfo: self.pageInfo } });
                    },
                    confirm(e) {
                        const self: F7ComponentContextExtend = this;
                        self.$app.dialog.confirm('Bạn có muốn xác nhận số tiền chiết khấu này không ? !', () => {
                            $this.rootServices.apiService.putPromise('/collaborator/commission-vouchers/' + self.commission.Code, { changeState: 'CONFIRMED' }, [{ Code: self.commission.Code }]).then(rs => {
                                $this.commonService.showInfo('Xác nhận thành công');
                                self.refresh();
                            }).catch(err => {
                                $this.commonService.showError(err);
                            });
                        });
                    },
                    // toggleUnreadFilter(e) {
                    //     const self: F7ComponentContextExtend = this;
                    //     const unreadCheckbox = self.filterDialog.$el.find('.field[name="filter_UnreadState"]');
                    //     unreadCheckbox.click();
                    //     self.applyFilter(e);
                    //     self.$setState({ unreadFilterStatus: unreadCheckbox[0] && unreadCheckbox[0]['checked'] });
                    // },
                    // openFilterDialog(e) {
                    //     const self: F7ComponentContextExtend = this;
                    //     self.filterDialog && self.filterDialog.open();
                    //     console.log('Current filter: ', self.filter);
                    // },
                    // closeFilterDialog(e) {
                    //     const self: F7ComponentContextExtend = this;
                    //     if (self.filterDialog) {
                    //         const fieldEles = self.filterDialog.$el.find('.field');
                    //         fieldEles.each((index, el) => {
                    //             self.$(el).prop('checked', false);
                    //         });
                    //         self.$setState({ filter: null });
                    //         self.filterDialog.close();
                    //         self.refresh(null, () => { });
                    //     }
                    // },
                    // applyFilter(e) {
                    //     const self: F7ComponentContextExtend = this;
                    //     self.filterDialog && self.filterDialog.close();
                    //     const fieldEles = self.filterDialog.$el.find('.field');
                    //     console.log(fieldEles);
                    //     const filter: { [key: string]: any } = {};
                    //     fieldEles.each((index, el) => {
                    //         console.log(index, el.checked);
                    //         if (el.checked) {
                    //             filter[el.name] = el.value;
                    //         }
                    //     });
                    //     console.log(filter);
                    //     self.$setState({ filter: Object.keys(filter).length > 0 ? filter : null });
                    //     self.refresh(null, () => { });
                    // },
                    closeCreateChatRoomDialog() {
                        const self: F7ComponentContextExtend = this;
                        self.createChatRoomDialog && self.createChatRoomDialog.close();
                    },
                    createChatRoom(e: MouseEvent) {
                        const self: F7ComponentContextExtend = this;
                        const chatRoomData: ChatRoomModel = {};
                        const fieldEles = self.createChatRoomDialog.$el.find('.field');
                        fieldEles.each((index, fieldEle) => {
                            console.log(fieldEle);
                            console.log(fieldEle.value);
                            chatRoomData[fieldEle.name] = fieldEle.value;
                        });
                        if (!chatRoomData.Description) {
                            console.error('Bạn chưa điền mô tả');
                            $this.commonService.showError('Bạn chưa điền mô tả');
                            return;
                        }
                        console.log(chatRoomData);
                        self.$app.preloader.show();
                        try {
                            $this.rootServices.apiService.postPromise<ChatRoomModel[]>('/chat/rooms', {}, [chatRoomData]).then(rs => {
                                console.log(rs);
                                if (rs && rs[0] && rs[0].Code) {
                                    $this.commonService.navigate('/chat-room/' + rs[0].Code, {
                                        context: {
                                            backTitle: 'Workplace',
                                            title: rs[0].Description,
                                        }
                                    });
                                    self.createChatRoomDialog.close();
                                    fieldEles.each((index, fieldEle) => {
                                        fieldEle.value = null;
                                        self.$(fieldEle).change();
                                    });
                                    self.$app.preloader.hide();
                                }
                            }).catch(err => {
                                console.error(err);
                                self.$app.preloader.hide();
                                $this.commonService.showError(err);
                            });
                        } catch (err) {
                            console.error(err);
                            self.$app.preloader.hide();
                            $this.commonService.showError(err);
                        }
                    },
                    openCreateTicketDialog(e: MouseEvent) {
                        const self: F7ComponentContextExtend = this;
                        if (self.createChatRoomDialog) {
                            self.createChatRoomDialog.open();
                            self.createChatRoomDialog.$el.find('.field[name="Description"]').focus();
                        }
                    },
                    openCreateWorkplaceDialog(e: MouseEvent) {
                        const self: F7ComponentContextExtend = this;
                        if (self.createWorkplaceDialog) {
                            self.createWorkplaceDialog.open();
                            self.createWorkplaceDialog.$el.find('.field[name="Description"]').focus();
                        }
                    },
                    closeCreateWorkplaceDialog() {
                        const self: F7ComponentContextExtend = this;
                        self.createWorkplaceDialog && self.createWorkplaceDialog.close();
                    },
                    createWorkplace(e: MouseEvent) {
                        const self: F7ComponentContextExtend = this;
                        const chatRoomData: ChatRoomModel = {};
                        const fieldEles = self.createWorkplaceDialog.$el.find('.field');
                        fieldEles.each((index, fieldEle) => {
                            console.log(fieldEle);
                            console.log(fieldEle.value);
                            chatRoomData[fieldEle.name] = fieldEle.value;
                        });
                        if (!chatRoomData.Description) {
                            console.error('Bạn chưa điền mô tả');
                            $this.commonService.showError('Bạn chưa điền mô tả');
                            return;
                        }
                        chatRoomData.Type = 'WORKPLACE';
                        chatRoomData.State = 'OPEN';
                        console.log(chatRoomData);
                        self.$app.preloader.show();
                        try {
                            $this.rootServices.apiService.postPromise<ChatRoomModel[]>('/chat/rooms', {}, [chatRoomData]).then(rs => {
                                console.log(rs);
                                if (rs && rs[0] && rs[0].Code) {
                                    $this.commonService.navigate('/chat-room/' + rs[0].Code, {
                                        context: {
                                            backTitle: 'Workplace',
                                            title: rs[0].Description,
                                        }
                                    });
                                    self.createWorkplaceDialog.close();
                                    fieldEles.each((index, fieldEle) => {
                                        fieldEle.value = null;
                                        self.$(fieldEle).change();
                                    });
                                    self.$app.preloader.hide();
                                }
                            }).catch(err => {
                                console.error(err);
                                self.$app.preloader.hide();
                                $this.commonService.showError(err);
                            });
                        } catch (err) {
                            console.error(err);
                            self.$app.preloader.hide();
                            $this.commonService.showError(err);
                        }
                    },
                    async refresh(e, done) {
                        const self: F7ComponentContextExtend = this;
                        // Refresh whene overtime 5 minutes
                        let doneTimeout = null;
                        if (done) {
                            doneTimeout = setTimeout(() => {
                                done();
                            }, 10000);
                        };
                        if (done || ($this.rootServices.authService.loginState$.getValue() && (self.commissionDetailOrders.length === 0 || !self.lastUpdate || self.lastUpdate + 300000 < Date.now()))) {
                            console.log('refresh chat room list');

                            self.commissionDetail = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/commission-voucher-details/' + self.$route?.params?.commissionVoucher + '-' + self.$route?.params?.no, {
                                // eq_CommissionVoucher: self.$route?.params?.commissionVoucher,
                                // eq_DetailNo: self.$route?.params?.no,
                                page: $this.commonService.getObjectId(self.commission?.Page),
                                search: encodeURIComponent(self.searchBar.query),
                                // includeDirectOrders: true, includeRefOrders: true,
                                limit: 1,
                                offset: 0,
                                ...self.filter,
                            }).catch(err => {
                                if (done) done();
                                if (doneTimeout) clearTimeout(doneTimeout);
                                return Promise.reject(err);
                            }).then(rs => {
                                const commissionDetail = rs[0];
                                // commissionDetail.AmountText = $this.currencyPipe.transform(commissionDetail.Amount, 'VND');
                                // commissionDetail.CommissionFromText = $this.datePipe.transform(commissionDetail.CommissionFrom, 'shortDate');
                                // commissionDetail.CommissionToText = $this.datePipe.transform(commissionDetail.CommissionTo, 'shortDate');
                                // commissionDetail.StateText = $this.stateIndex[commissionDetail.State];
                                // commissionDetail.StateColor = $this.stateColorIndex[commissionDetail.State];
                                commissionDetail.CommissionAmountText = $this.currencyPipe.transform(commissionDetail.CommissionAmount, 'VND');
                                commissionDetail.SumOfNetRevenueText = $this.currencyPipe.transform(commissionDetail.SumOfNetRevenue, 'VND');
                                return commissionDetail;
                            });
                            // self.directOrders = self.commission?.DirectOrders;
                            // self.refOrders = self.commission?.RefOrders;

                            self.commissionDetailOrders = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/commission-voucher-detail-orders', {
                                page: $this.commonService.getObjectId(self.commission?.Page),
                                eq_CommissionVoucher: self.$route?.params?.commissionVoucher,
                                eq_DetailNo: self.$route?.params?.no,
                                search: encodeURIComponent(self.searchBar.query),
                                sort_No: 'asc',
                                limit: 10,
                                offset: 0,
                            }).catch(err => {
                                if (done) done();
                                if (doneTimeout) clearTimeout(doneTimeout);
                                return Promise.reject(err);
                            });
                            if (doneTimeout) clearTimeout(doneTimeout);
                            const pageInfo = self.pageInfo || await $this.rootServices.apiService.getPromise<any[]>('/collaborator/pages/' + self.commission?.Page, {}).then(rs => rs[0]);
                            self.$setState({
                                commissionDetail: self.commissionDetail,
                                commissionDetailOrders: self.commissionDetailOrders.map(t => {
                                    $this.preapreListItem(t, self.commissionDetail);
                                    return t;
                                }), lastUpdate: Date.now(),
                                pageInfo: pageInfo,
                            });
                            self.infiniteOffset = 10;

                            self.$setState({ infiniteLoadLast: self.commissionDetailOrders.length === 0 });
                            if (done) done();

                            self.updateBadge();
                        } else {
                            self.$setState({
                                lastUpdateMoment: moment(self.lastUpdate).fromNow(),
                            });
                        }
                        return true;
                    },
                    async openOrder(e) {
                        const self: F7ComponentContextExtend = this;
                        const orderId = self.$(e.currentTarget).data('id');
                        $this.commonService.navigate('/page-collaborator-order/' + self.commission?.Page + '/' + orderId);

                    },
                    updateBadge(e) {
                        const self: F7ComponentContextExtend = this;
                        let unreadCount = 0;
                        if (self.commissionDetailOrders) {
                            for (const cahtRoom of self.commissionDetailOrders) {
                                if (cahtRoom.NumOfUnreadMessage) {
                                    unreadCount += parseInt(cahtRoom.NumOfUnreadMessage);
                                }
                            }
                            $this.rootServices.workplaceBadge = unreadCount;
                        }
                    },
                },
                on: {
                    pageInit(e, page: F7Page) {
                        console.log('page init');
                        const self: F7ComponentContextExtend = this;
                        if (self.$route.params['id']) {
                            self.mode = self.$route.params['id'];
                        }
                        const pageId = self.$route.params?.pageId;
                        $this.onComponentInit({ instance: self }, 'main').then(async currentState => {
                            // Init create chat room dialog
                            // self.createChatRoomDialog = self.$app.dialog.create({
                            //     el: page.$el.find('.dialog-create-chat-room'),
                            //     closeByBackdropClick: true,
                            // });
                            // self.createWorkplaceDialog = self.$app.dialog.create({
                            //     el: page.$el.find('.dialog-create-workplace-dialog'),
                            //     closeByBackdropClick: true,
                            // });
                            // self.filterDialog = self.$app.dialog.create({
                            //     el: page.$el.find('.dialog-filter'),
                            //     closeByBackdropClick: true,
                            // });
                            // self.filterDialog.$el.find('.field').each((index, field) => {
                            //     self.$(field).change((e: Event) => {
                            //         console.log(e);
                            //         if (e.target['name'] === 'filter_State' && e.target['checked']) {
                            //             self.filterDialog.$el.find('.field[name="filter_State"]').filter((index, el) => el.value !== e.target['value']).prop('checked', false);
                            //         }
                            //     });
                            // });

                            // self.$setState({ pageInfo: pageInfo })
                        });

                        // Loading flag
                        let allowInfinite = true;
                        // Last loaded index
                        var lastItemIndex = self.$$('.list li').length;

                        // Max items to load
                        var maxItems = 200;

                        // Append items per load
                        var itemsPerLoad = 20;

                        self.infiniteOffset = 0;

                        self.searchBar = self.$app.searchbar.create({
                            el: '.workplace-searchbar',
                            backdrop: true,
                            backdropEl: '.workplace-searchbar-backdrop',
                            searchContainer: '.list',
                            searchIn: '.item-text',
                            customSearch: true,
                            on: {
                                search(sb, query, previousQuery) {
                                    $this.commonService.takeUntil('heldpesk-ticket-search', 600).then(() => {
                                        console.log(query, previousQuery);
                                        $this.rootServices.apiService.getPromise<any[]>('/collaborator/commission-voucher-detail-orders', {
                                            eq_CommissionVoucher: self.$route?.params?.commissionVoucher,
                                            eq_DetailNo: self.$route?.params?.no,
                                            search: encodeURIComponent(query),
                                            sort_No: 'desc',
                                            // eq_CommissionVoucher: self.$route.params?.id,
                                            limit: 10,
                                            offset: 0,
                                            ...self.filter,
                                        }).then(commissionDetailOrders => {
                                            self.$$(page.el).find('.page-content').scrollTo(0, 0);
                                            self.$setState({ commissionDetailOrders: commissionDetailOrders.map(t => $this.preapreListItem(t, self.commissionDetail)), infiniteLoadLast: commissionDetailOrders.length === 0 });
                                            self.infiniteOffset = 0;
                                        }).catch(err => {
                                            console.error(err);
                                            $this.commonService.showError(err);
                                        });
                                    });
                                }
                            }
                        });

                        // Attach 'infinite' event handler
                        self.$$(page.el).find('.infinite-scroll-content').on('infinite', function () {
                            // Exit, if loading in progress
                            if (!allowInfinite) return;

                            // Set loading flag
                            allowInfinite = false;

                            // Emulate 1s loading
                            $this.rootServices.apiService.getPromise<any[]>('/collaborator/commission-voucher-detail-orders', {
                                eq_CommissionVoucher: self.$route?.params?.commissionVoucher,
                                eq_DetailNo: self.$route?.params?.no,
                                search: self.searchBar.query,
                                sort_No: 'asc',
                                // eq_CommissionVoucher: self.$route.params?.id,
                                limit: 10,
                                offset: self.infiniteOffset,
                                ...self.filter,
                            }).then(commissionDetailOrders => {
                                commissionDetailOrders.map(t => {
                                    // Moment.now()
                                    // const unread = t.NumOfMessage - t.LoggedNumOfMessage - t.LoggedNumOfReadMessage;
                                    // t.LastUpdate_Moment = moment(t.LastUpdate).fromNow();
                                    // t.DateOfCreate = new Date(t.DateOfCreate).toLocaleString();
                                    // // t.Infos.Request_Date_Time = new Date(t.Infos.Request_Date_Time).toLocaleTimeString();
                                    // t.NumOfUnreadMessage = unread > 0 ? unread : '';
                                    $this.preapreListItem(t, self.commissionDetail);
                                    return t;
                                });
                                self.$setState({ commissionDetailOrders: self.commissionDetailOrders.concat(commissionDetailOrders), infiniteLoadLast: commissionDetailOrders.length === 0 });
                                self.infiniteOffset += 10;
                                lastItemIndex = self.$$('.list li').length;
                                allowInfinite = true;

                                self.updateBadge();
                            }).catch(err => {
                                console.error(err);
                                $this.commonService.showError(err);
                            });
                        });

                        // if (page.router.history && page.router.history[page.router.history.length - 1] === page.route.url) {
                        //     self.refresh();
                        // }
                    },
                    pageAfterIn(e, page) {
                        console.log('p[page event]age after in', page.route.url);
                        const self: F7ComponentContextExtend = this;
                        $this.onChangedState({ instance: self, page }, 'main', 'before-in').then(currentState => {
                            self.$setState({
                                lastUpdateMoment: moment(self.lastUpdate).fromNow(),
                            });
                        });
                    },
                    pageBeforeIn(e, page: F7Page) {
                        console.log('[page event] before in', page.route.url);
                        const self: F7ComponentContextExtend = this;

                        if ($this.rootServices.changedChatRooms.workplace.length > 0) {
                            const updatedRooms = [... new Set($this.rootServices.changedChatRooms.workplace)];
                            $this.rootServices.apiService.getPromise<ChatRoomModel[]>('/chat/rooms', { id: updatedRooms }).then(chatRoomInfos => {
                                const newChatRooms = self.commissionDetailOrders.map(oldChatRoom => {
                                    const newChatRoom = chatRoomInfos.find(f => f.Code === oldChatRoom.Code);
                                    if (newChatRoom) {
                                        // newChatRoom.LastUpdate_Moment = moment(newChatRoom.LastUpdate).fromNow();
                                        // newChatRoom.DateOfCreate = new Date(newChatRoom.DateOfCreate).toLocaleString();
                                        // const unread = newChatRoom.NumOfMessage - newChatRoom.LoggedNumOfMessage - newChatRoom.LoggedNumOfReadMessage;
                                        // newChatRoom.NumOfUnreadMessage = unread > 0 ? unread : '';

                                        $this.preapreListItem(newChatRoom, self.commissionDetail);
                                        return newChatRoom;
                                    }
                                    return oldChatRoom;
                                });
                                self.$setState({
                                    commissionDetailOrders: newChatRooms,
                                });
                                $this.rootServices.changedChatRooms.workplace = [];

                                self.updateBadge();
                            }).catch(err => {
                                console.error(err);
                                $this.commonService.showError(err);
                            });
                        }
                    },
                }
            },
        };
    }
}
