import { F7ListComponentContext, ListComponentStateExtend } from '../../../../../src/app/lib/base-list-component';
import { F7Page } from '../../../types/framework7-types';
import { Router } from 'framework7/build/core/modules/router/router';
import { RootServices } from '../../../services/root.services';
import { ChatRoom } from '../../../lib/nam-chat/chat-room';
import { CommonService } from '../../../services/common.service';
import { ProductModel } from '../../../model/product.model';
import { BaseListComponent } from '../../../../../src/app/lib/base-list-component';
import { CashVoucherModel } from '../../../../../src/app/model/accounting.model';
// import * as $ from 'jquery';
declare const $: any;

// declare const $: any;
export interface ComponentStateExtend extends ListComponentStateExtend<CashVoucherModel> {
  [key: string]: any;
  instance?: F7ComponentContextExtend;
}

export interface F7ComponentContextExtend extends F7ListComponentContext<CashVoucherModel> {
  responseInProgress?: boolean;
  listItems?: ProductModel[];
  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, chatRoom?: ChatRoom } };
}

/** Component group manager */
export class AccPaymentVoucherListComponent extends BaseListComponent<ComponentStateExtend, CashVoucherModel> {

  namespace: string = 'acc-payment-voucher-list';
  // states: { [key: string]: State } = {};

  title: 'Phiếu chi';
  apiPath = '/accounting/cash-vouchers';
  idKey = 'Code';
  limit = 100;

  // stateMap = {
  //   'NOTJUSTAPPROVED': {
  //     text: 'chưa duyệt',
  //     color: 'text-color-orange',
  //     icon: 'circle_fill'
  //   },
  //   'UNRECORDED': {
  //     text: 'bỏ ghi',
  //     color: 'text-color-orange',
  //     icon: 'circle_fill'
  //   },
  //   'APPROVED': {
  //     text: 'đã duyệt',
  //     color: 'text-color-green',
  //     icon: 'person_crop_circle_fill_badge_checkmark'
  //   },
  // };

  filterSchema = {
    Object: {
      type: 'autocomplete-contact',
      dataType: 'multiple'
    },
    State: {
      type: 'smart-select',
      // dataType: 'multiple'
    },
    DateOfVoucher: {
      type: 'datepicker',
      dataType: 'range',
      format: 'dd/mm/yyyy'
    },
  };

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
  ) {
    super(rootServices, commonService);
    // // console.log('Click here to open ts file');
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  async onComponentInit(state: ComponentStateExtend, index: string, asCase?: string, f7Page?: F7Page) {
    return super.onComponentInit(state, index, asCase, f7Page).then(currentState => {

      return currentState;
    });
  }

  prepareItem(item: CashVoucherModel, index?: number) {
    // item.DateText = this.rootServices.datePipe.transform(item.DateOfVoucher, 'short');
    // const state = this.commonService.getStateLabel(item.State);
    // item.StateLabel = state?.label;
    // item.StateIcon = state?.icon;
    // item.StateStatus = state?.status;
    item.State = {
      id: item.State,
      ...this.voucherStateMap[item.State || 'NOTJUSTAPPROVED'],
    };
    // item.CreatorText = this.commonService.getObjectText(item.Creator);
    return item;
  }

  // async getList(params: any) {
  //   // console.log(self.searchBar);
  //   return this.rootServices.apiService.getPromise<any[]>('/sales/price-reports', {
  //     includeCreator: true,
  //     includeRelativeVouchers: true,
  //     sort_Id: 'desc',
  //     limit: 10,
  //     offset: 0,
  //     ...params,
  //   });
  // }

  async getList(self?: F7ComponentContextExtend, extParams?: any): Promise<CashVoucherModel[]> {
    if (!this.rootServices.authService.loginState$?.value) {
      return [];
    }
    return this.rootServices.apiService.getPromise<CashVoucherModel[]>(this.apiPath, {
      includeCreator: true,
      includeRelativeVouchers: true,
      sort_Id: 'desc',
      eq_Type: 'PAYMENT',
      ...this.parseFilter(this.currentState?.filter),
      ...extParams
    }).then(list => {
      return list;
    }).catch(err => {
      console.error(err);
      this.commonService.showError(err);
      return Promise.reject(err);
    });
  }

  itemBeforeInsert(element: HTMLElement, item: CashVoucherModel): void {
    const $this = this;
    $(element).find('.approveBtn').unbind('click').click(function () {
      if ($this.commonService.getObjectId(item.State) != 'APPROVED') {
        $this.currentState.instance.$app.dialog.confirm('Bạn có muốn duyệt phiếu chi `' + item.Description + '` ?', 'Duyệt phiếu chi', () => {

          $this.rootServices.apiService.putPromise<CashVoucherModel[]>($this.apiPath + '/' + item.Code, { changeState: 'APPROVED' }, [{ Code: item.Code }]).then(rs => {
            $this.commonService.showInfo('Đã duyệt phiếu chi `' + item.Description + '`');
            item = $this.prepareItem({
              ...item,
              State: 'APPROVED'
            });
            $this.currentState.instance.virtualList.replaceItem($this.currentState.instance.virtualList.items.findIndex(f => f.Code == item.Code), item);
          }).catch(err => $this.commonService.showError(err));
        });
      } else {
        $this.currentState.instance.$app.dialog.confirm('Bạn bỏ duyệt phiếu chi `' + item.Description + '` ?', 'Bỏ duyệt phiếu chi', () => {
          $this.rootServices.apiService.putPromise<CashVoucherModel[]>($this.apiPath + '/' + item.Code, { changeState: 'UNRECORDED' }, [{ Code: item.Code }]).then(rs => {
            $this.commonService.showInfo('Đã bỏ duyệt phiếu chi `' + item.Description + '`');
            item = $this.prepareItem({
              ...item,
              State: 'UNRECORDED'
            });
            $this.currentState.instance.virtualList.replaceItem($this.currentState.instance.virtualList.items.findIndex(f => f.Code == item.Code), item);
          }).catch(err => $this.commonService.showError(err));
        });
      }
      return false;
    });
    $(element).find('.moreBtn').unbind('click').click(function () {
      $this.currentState.instance.$app.dialog.create({
        title: 'Phiếu chi',
        text: item.Description,
        buttons: [
          {
            text: 'Copy phiếu chi',
            color: 'orange',
            onClick: (dialog, e) => {

              $this.rootServices.navigate('/accounting/payment-voucher-form/new', {
                context: {
                  copyFromId: item.Code,
                  backTitle: $this.title,
                  callback: (data: CashVoucherModel, state: any) => {
                    console.log('Callback data: ', data);
                    if (state?.lastAction == 'CREATE_SUCCESS') {
                      $this.refresh();
                    }
                  }
                }
              });
            }
          },
          {
            text: 'Download PDF',
            color: 'green',
            onClick: (dialog, e) => {
              $this.downloadPdf(item);
            }
          },
          {
            text: 'Trở về',
            bold: true,
            color: 'red'
          },
        ],
        verticalButtons: true,
      }).open();
      return false;
    });
    return;
  }

  downloadPdf(item: CashVoucherModel) {
    this.rootServices.iab.create(this.rootServices.apiService.buildApiUrl(this.apiPath, { id: item.Code, includeObject: true, includeContact: true, includeDetails: true, includeUnit: true, renderPdf: 'download' }) as string, '_system');
  }

  async onItemClick(self: F7ComponentContextExtend, item: CashVoucherModel) {
    // const self: F7ComponentContextExtend = this;
    // id = id || self.$(e.target).closest('li').data('id') || 'new';
    const id = item[this.idKey];

    this.rootServices.navigate('/accounting/payment-voucher-form/' + id, {
      context: {
        backTitle: this.title,
        textColorClass: self.textColorClass,
        callback: (data: CashVoucherModel, state: any) => {
          console.log('Callback data: ', data);
          // this.refresh();
          if (state?.lastAction == 'UPDATE_SUCCESS') {
            if (data?.Code) {
              const index = self.virtualList.items.findIndex(f => f.Code == item.Code);
              if (index > -1) {
                self.virtualList.replaceItem(index, this.prepareItem(data));
              }
            }
          }
        }
      }
    });
  }

  itemHeight = 143;
  itemTemplate = /*html*/`
  <li data-id="{{Code}}" data-chat-room="{{Code}}" style="height: 143px; overflow: hidden" class="swipeout">
    <a class="item-link item-content swipeout-content">
        <div class="item-inner" style="height: 143px">
            <div class="item-title-row">
                <div class="item-title text-color-default description">
                    <div class="item-title-text">{{Description}}</div>
                </div>
                <div class="item-after text-color-blue" style="font-size: 0.8rem">
                    <span class="text-{{State.color}} nowrap"><i class="icon f7-icons" style="text-align: right;">{{State.icon}}</i></span>
                </div>
            </div>

            <div class="item-subtitle text-color-gray" style="display: flex; align-items: center; justify-content: space-between;">
                <div style="display: flex; align-items: center">
                    <i class="icon f7-icons" style="margin-right: 4px;">compass_fill</i>
                    <div class="description">Trạng thái</div>
                </div>
                <div style="display: flex; align-items: center" class="text-{{State.color}}">
                {{text State}}</i>
                </div>
            </div>

            <div class="item-subtitle text-color-gray" style="display: flex; align-items: center; justify-content: space-between;">
                <div style="display: flex; align-items: center">
                    <i class="icon f7-icons" style="margin-right: 4px;">money_dollar_circle_fill</i>
                    <div class="description">Số tiền</div>
                </div>
                <div style="display: flex; align-items: center">
                    {{currency Amount}}</i>
                </div>
            </div>
            
            {{#if DateOfVoucher}}
            <div class="item-subtitle text-color-gray" style="display: flex; align-items: center; justify-content: space-between;">
                <div style="display: flex; align-items: center">
                    <i class="icon f7-icons" style="margin-right: 4px;">timer_fill</i>
                    <div class="description">TG: {{date DateOfVoucher format="short"}}</div>
                </div>
                <div style="display: flex; align-items: center">
                    {{moment DateOfVoucher coalesce="--"}}
                </div>
            </div>
            {{/if}}
            
            {{#if ObjectName}}
            <div class="item-subtitle text-color-gray" style="display: flex; align-items: center">
                <i class="icon f7-icons" style="margin-right: 4px;">person_crop_circle</i>
                <div class="description" style="overflow: hidden; text-overflow: ellipsis;">LH: {{ObjectName}}</div>
            </div>
            {{/if}}
            <div class="item-text" style="clear: both; max-height: initial;">
                <span class="lastMessageText">
                    <span class="tag">#{{Code}}</span>
                    {{#each Tags}}
                    <span class="tag">#{{this}}</span>
                    {{/each}}
                </span>
            </div>
        </div>
    </a>

    <div class="swipeout-actions-right">
    <a href="#" class="approveBtn open-more-actions {{js "(this.State && this.State.id != 'APPROVED') ? 'color-green' : 'color-orange'"}} swipeout-close">{{js "(this.State && this.State.id == 'APPROVED') ? 'Bỏ ghi' : 'Duyệt'"}}</a>
        <a href="#" class="moreBtn open-more-actions color-gray swipeout-overswipe swipeout-close">Thêm</a>
    </div>
  </li>
  `;

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: 'acc-payment-voucher-list',
      path: '/accounting/payment-voucher-list',
      component: {
        template: /*html*/`
            <div class="page no-toolbar-x page-acc-payment-voucher-list" data-name="acc-payment-voucher-list">
              <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a class="link back {{textColorClass}}">
                      <i class="icon icon-back"></i>
                      <span class="if-not-md {{textColorClass}}">{{js "this.backTitle || 'Back'"}}</span>
                    </a>
                  </div>
                  <div class="title">Phiếu chi</div>
                  <div class="right">
                    <!-- Link to enable searchbar -->
                    <a class="link icon-only searchbar-enable {{textColorClass}}" data-searchbar=".acc-payment-voucher-list-searchbar">
                      <i class="icon f7-icons if-not-md">search</i>
                      <i class="icon material-icons md-only">search</i>
                    </a>
                  </div>
                  <!-- Searchbar is a direct child of "navbar-inner" -->
                  <form class="searchbar acc-payment-voucher-list-searchbar searchbar-expandable">
                    <div class="searchbar-inner">
                      <div class="searchbar-input-wrap">
                        <input type="search" placeholder="Search" />
                        <i class="searchbar-icon"></i><span class="input-clear-button"></span>
                      </div>
                      <span class="searchbar-disable-button {{textColorClass}}">Hủy</span>
                    </div>
                  </form>
                </div>
              </div>
              <div class="page-content ptr-content infinite-scroll-content" @ptr:refresh="refresh">
                <div class="ptr-preloader">
                  <div class="preloader"></div>
                  <div class="ptr-arrow"></div>
                </div>
                <div class="searchbar-backdrop acc-payment-voucher-list-backdrop"></div>
            
                <!--<div class="action-bar block block-strong inset">
                  <div class="row">
                    <div class="col-25 tablet-15 action-bar-item text-color-gray link goto" @click="goto" data-url="/sales/goods-list">
                      <div class="demo-icon-icon"><i class="icon f7-icons">cube_box</i></div>
                      <div class="action-bar-item-label">Bảng giá</div>
                    </div>
                    <div class="col-25 tablet-15 action-bar-item text-color-gray link goto" @click="goto" data-url="/sales/price-report-list">
                      <div class="demo-icon-icon"><i class="icon f7-icons">app_fill</i></div>
                      <div class="action-bar-item-label">Báo giá</div>
                    </div>
                    <div class="col-25 tablet-15 action-bar-item text-color-gray link goto" @click="goto" data-url="/warehouse/inventory-adjust-note-list">
                      <div class="demo-icon-icon"><i class="icon f7-icons">app</i></div>
                      <div class="action-bar-item-label">Nhóm</div>
                    </div>
                    <div class="col-25 tablet-15 action-bar-item text-color-gray link goto" @click="goto" data-url="/warehouse/book-form/current">
                      <div class="demo-icon-icon"><i class="icon f7-icons">app</i></div>
                      <div class="action-bar-item-label">Đơn vị tính</div>
                    </div>
                  </div>
                </div>-->

                <div class="block-title" style="margin-top: 2rem">Bộ lọc <a style="float: right" @click="resetFilter"
                class="text-color-red">đặt lại</a></div>
                <div class="block block-strong inset filter" style="padding: 0; overflow: hidden">
                  <div class="list accordion-list inline-labels no-hairlines" style="margin: 0;">
                    <ul>
                      <li class="autocomplete" name="Object">
                        <div class="item-link smart-select item-content" href="#">
                          <div class="item-inner" style="display: flex;">
                            <div class="item-title">Liên hệ</div>
                            <div class="item-after">{{text filter.Object 'Chọn'}}</div>
                          </div>
                        </div>
                      </li>
                      <li class="item-link smart-select smart-select-init" name="State" data-open-in="popup" data-searchbar="false" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFilterFieldChange">
                        <select>
                          <option value="">Chọn</option>
                          <option value="APPROVED">Đã duyệt</option>
                          <option value="NOTJUSTAPPROVED">Chưa duyệt</option>
                          <option value="UNRECORDED">Bỏ ghi</option>
                        </select>
                        <div class="item-content">
                          <div class="item-inner">
                            <div class="item-title">Trạng thái</div> 
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="item-content item-input">
                          <div class="item-inner">
                            <div class="item-title item-label">Thời gian</div>
                            <div class="item-input-wrap">
                              <input class="calendar" name="DateOfVoucher" type="text" placeholder="Ngày chi tiền" readonly="readonly" style="text-align: right;"/>
                            </div>
                          </div>
                        </div>
                      </li>

                    </ul>
                  </div>
                </div>

                <div class="block-title">Phiếu chi <a class="button button-small button-fill tab-link" @click="openVoucher" style="float: right; font-size: 0.7rem; font-weight: bold">+ Mới</a></div>
                <div class="virtual-list list media-list acc-payment-voucher-list-virtual-list inset list-complex-info">
                  
                </div>
                {{#if infiniteLoadLast}}
                    <div style="text-align: center" class="text-color-gray">end</div>
                {{else}}
                    <div class="preloader color-blue infinite-scroll-preloader">
                        <span class="preloader-inner">
                            <span class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span>
                        </span>
                    </div>
                {{/if}}
            
                <div class="block"><br></div>
            
              </div>
            </div>
        `,
        style: /*css*/`
          .acc-payment-voucher-list .find-order {
            font-size: 16px;
            font-weight: bold;
          }
          .acc-payment-voucher-list .action-bar {
            color: var(--f7-block-strong-text-color);
            padding-top: var(--f7-block-padding-vertical);
            padding-bottom: var(--f7-block-padding-vertical);
            background-color: var(--f7-block-strong-bg-color);
          }
          .page-acc-payment-voucher-list .virtual-list i.f7-icons {
            font-size: 16px;
          }
          .page-acc-payment-voucher-list .list-complex-info ul li .item-title .item-title-text {
            -webkit-line-clamp: 1;
          }
          .page-acc-payment-voucher-list .list-complex-info ul li .item-title .item-title-text {
            -webkit-line-clamp: 1;
          }
          .page-acc-payment-voucher-list .list-complex-info ul li .item-title {
            height: 18px;
          }
        `,
        data() {
          return {
            title: $this.title,
            // reminder: {
            //   No: '234234',
            // },
            priceReport: { Details: [] },
            filter: {}
          };
        },
        methods: {
          resetFilter(e) {
            $this.resetFilter(this, e);
          },
          onFilterFieldChange(e) {
            $this.onFilterFieldChange(this, e);
          },
          // Price report
          openVoucher(e, id?: string) {
            const self: F7ComponentContextExtend = this;
            id = id || self.$(e.target).closest('li').data('id') || 'new';

            // if ($this.commonService.frameSocket.isFrameMode && id && id !== 'new') {
            //   $this.commonService.frameSocket.emit('request-open-voucher', { id: id, type: 'PRICEREPORT' });
            // } else {
            $this.rootServices.navigate('/accounting/payment-voucher-form/' + id, {
              context: {
                backTitle: $this.title,
                textColorClass: self.textColorClass,
                // memberInfo: $this.currentState.chatRoom.memberList$.getValue().find(f => f.id === id),
                // chatRoom: self.$route.context.chatRoom,
                // reminderList$: self.reminderList$,
                callback: (data: CashVoucherModel, state: any) => {
                  console.log('Callback data: ', data);
                  if (state?.lastAction == 'CREATE_SUCCESS') {
                    self.refresh();
                  }
                }
              }
            });
            // }
          },
          goto(e) {
            const self: F7ComponentContextExtend = this;
            const url = self.$(e.target).closest('.goto').data('url');
            $this.rootServices.navigate(url);
          },
          chooseOne(e) {
            const self: F7ComponentContextExtend = this;
            const id = self.$(e.target).closest('li').data('id');
            self.$route.context['onChoose'] && self.$route.context['onChoose'](self.listItems.find(f => f.Code === id));
          },
          chooseProduct(e) {

          },
          async refresh(e, done) {
            const self: F7ComponentContextExtend = this;

            $this.refresh(self).then(rs => done && done());

            // let doneTimeout = null;
            // if (done) {
            //   doneTimeout = setTimeout(() => {
            //     done();
            //   }, 10000);
            // };

            // // console.log(self.searchBar);
            // // $this.rootServices.apiService.getPromise<any[]>('/warehouse/inventory-adjust-notes', {
            // //   search: self?.searchBar?.query && self.searchBar.query || '',
            // //   includeParent: true,
            // //   includePath: true,
            // //   includeWarehouse: true,
            // //   includeGoods: true,
            // //   sort_Id: 'desc',
            // //   limit: 10,
            // //   offset: 0,
            // //   ...self.filter,
            // // }).then(products => {
            // $this.getList({
            //   search: self?.searchBar?.query && self.searchBar.query || '',
            //   limit: 10,
            //   offset: 0,
            //   ...self.filter,
            // }).then(products => {
            //   self.$setState({ listItems: products.map(t => $this.prepareItem(t)), infiniteLoadLast: products.length === 0 });
            //   self.infiniteOffset = 10;
            //   if (done) done();
            // }).catch(err => {
            //   console.error(err);
            //   $this.commonService.showError(err);
            //   if (done) done();
            // });

            return true;
          },
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          pageInit(e, page: F7Page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;
            // Loading flag
            let allowInfinite = true;

            // self.refresh();
            // self.searchBar = self.$app.searchbar.create({
            //   el: '.inventory-adjust-note-list-searchbar',
            //   backdrop: true,
            //   backdropEl: '.product-list-backdrop',
            //   searchContainer: '.list',
            //   searchIn: '.item-text',
            //   customSearch: true,
            //   on: {
            //     search(sb, query, previousQuery) {
            //       $this.commonService.takeUntil('product-search', 600).then(() => {
            //         console.log(query, previousQuery);
            //         $this.getList({
            //           search: self?.searchBar?.query && self.searchBar.query || '',
            //           limit: 10,
            //           offset: 0,
            //           ...self.filter,
            //         }).then(products => {
            //           self.$$(page.el).find('.page-content').scrollTo(0, 0);
            //           self.$setState({ listItems: products.map(t => $this.prepareItem(t)), infiniteLoadLast: products.length === 0 });
            //           self.infiniteOffset = 0;
            //         }).catch(err => {
            //           console.error(err);
            //           $this.commonService.showError(err);
            //         });
            //       });
            //     }
            //   }
            // });

            // self.$$(page.el).find('.infinite-scroll-content').on('infinite', function () {
            //   // Exit, if loading in progress
            //   if (!allowInfinite) return;

            //   // Set loading flag
            //   allowInfinite = false;
            //   // Last loaded index
            //   var lastItemIndex = self.$$('.list li').length;

            //   // Emulate 1s loading
            //   $this.getList({
            //     search: self?.searchBar?.query && self.searchBar.query || '',
            //     limit: 10,
            //     offset: self.infiniteOffset,
            //     ...self.filter,
            //   }).then(products => {
            //     products.map(t => {
            //       $this.prepareItem(t);
            //       return t;
            //     });
            //     self.$setState({ listItems: self.listItems.concat(products), infiniteLoadLast: products.length === 0 });
            //     self.infiniteOffset += 10;
            //     lastItemIndex = self.$$('.list li').length;
            //     allowInfinite = true;

            //     // self.updateBadge();
            //   }).catch(err => {
            //     console.error(err);
            //     $this.commonService.showError(err);
            //   });
            // });

            // // const reminder = self.$route.context.reminder || {};
            // // const chatRoom = self.$route.context.chatRoom;


            // self.calendarDateTime = self.$f7.calendar.create({
            //   // openIn: 'customModal',
            //   inputEl: page.$el.find('input[name="RemindAtDateTime"]'),
            //   timePicker: true,
            //   dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' },
            //   // header: true,
            //   footer: true,
            // });

            // Set back title
            // self.$setState({ backTitle: self.$route.context && self.$route.context.backTitle || 'Back', title: self.$route.context && self.$route.context.title || 'Tài khoản' });

            $this.onComponentInit({ instance: self }, 'main', null, page);

            // console.log(reminder);
            // $this.rootServices.authService.isAuthenticatedOrRefresh().pipe(take(1)).toPromise().then(() => {
            //   self.$setState({
            //     backTitle: self.$route.context && self.$route.context.backTitle || 'Back', title: self.$route.context && self.$route.context.title || 'Profile',
            //     memberList: chatRoom.memberList$.getValue().filter(member => member.Type === 'USER'),
            //     // reminder: self.reminder,
            //   });

            //   // setTimeout(() => {
            //   //   self.$setState({
            //   //     reminder: self.reminder,
            //   //   });
            //   // }, 5000);
            // }).catch(err => {
            //   console.error(err);
            //   $this.commonService.showError(err);
            // });

          },
          // pageBeforeIn(e, page) {
          //     console.log('page before in');
          //     const self: F7ComponentContextExtend = this;
          //     self.refresh();
          // },
          // pageAfterIn(e, page) {
          //     console.log('page after in');
          // },
          // pageBeforeOut(e, page) {
          //     console.log('page before out');
          // },
          pageAfterOut(e, page) {
            console.log('page after out');
          },
          // pageBeforeUnmount(e, page) {
          //     console.log('page before unmount');
          // },
          // pageBeforeRemove(e, page) {
          //     console.log('page before remove');
          // },
          pageBeforeRemove(e, page) {
            console.log('[page event] before remove', page.route.url);
            const self: F7ComponentContextExtend = this;
            // const starmtSelectRoles = self.$app.smartSelect.get('.smart-select');
            // starmtSelectRoles.destroy();
          },
        },
      },
    };
  }
}
