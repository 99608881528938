import { F7ComponentContext, F7Page } from '../types/framework7-types';
import { Router } from 'framework7/build/core/modules/router/router';
import { BaseComponent, ComponentState } from '../lib/base-component';
import { RootServices } from '../services/root.services';
import { take } from 'rxjs/operators';
import { Member } from '../lib/nam-chat/model/member';
import { ChatRoom } from '../lib/nam-chat/chat-room';
import { CommonService } from '../services/common.service';
import { TaskReminderModel } from '../model/task.model';
import { BehaviorSubject } from 'rxjs';
// import * as $ from 'jquery';
declare const $: any;
export interface ComponentStateExtend extends ComponentState {
  [key: string]: any;
}

export class F7ComponentContextExtend extends F7ComponentContext {
  // messagebar?: F7Messagebar;
  // messages?: Messages.Messages;
  // images?: string[];
  responseInProgress?: boolean;
  // answers?: string[];
  // people?: { name?: string, avatar?: string }[];
  // hideToolbar?: () => void;
  // sheetToggle?: () => void;
  // deleteAttachment?: (e: MouseEvent, index: number) => void;
  // handleAttachment?: (e: MouseEvent) => void;
  // checkAttachments?: () => void;
  // sendMessage?: () => void;
  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, reminder?: TaskReminderModel, chatRoom?: ChatRoom, reminderList$?: BehaviorSubject<TaskReminderModel[]> } };
}

// declare const $: JQuery;

/** Component group manager */
export class TaskReminderComponent extends BaseComponent<ComponentStateExtend> {
  // states: { [key: string]: State } = {};

  title: 'Task Reminder'

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
  ) {
    super(rootServices);
    // // console.log('Click here to open ts file');
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: 'task-reminder',
      path: '/task-reminder/:id',
      component: {
        template: /*html*/`
            <div class="page no-toolbar-x" data-name="task-reminder">
              <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                      <a class="link back {{textColorClass}}">
                          <i class="icon icon-back"></i>
                          <span class="if-not-md {{textColorClass}}">{{backTitle}}</span>
                      </a>
                  </div>
                  <div class="title">Task Reminder</div>
                  <div class="right">
                      <a class="link icon-only {{textColorClass}}">
                          <i class="icon f7-icons">ellipsis</i>
                      </a>
                  </div>
                </div>
              </div>
              <div class="page-content">
                <div class="block-title">Nhắc việc</div>
                <div class="list media-list inset profile-form no-hairlines-md">
                    <input type="hidden" name="No" value="{{js "this.reminder && this.reminder.No || ''"}}">
                    <ul>
                        <li class="item-content item-input">
                            <div class="item-media"><i class="icon f7-icons text-color-default">creditcard_fill</i></div>
                            <div class="item-inner">
                                <div class="item-title item-label">Tiêu đề (*)</div>
                                <div class="item-input-wrap">
                                <input class="field" name="Title" type="text" placeholder="Tiêu đề lời nhắc" value="{{reminder.Title}}">
                                </div>
                            </div>
                        </li>
                        <li class="item-content item-input">
                            <div class="item-media"><i class="icon f7-icons text-color-default">calendar_circle_fill</i></div>
                            <div class="item-inner">
                                <div class="item-title item-label">Thời gian (*)</div>
                                <div class="item-input-wrap">
                                <input class="field" name="RemindAtDateTime" type="text" placeholder="Thời gian nhắc việc">
                                </div>
                            </div>
                        </li>
                        <li class="item-content item-input">
                            <div class="item-media"><i class="icon f7-icons text-color-default">goforward</i></div>
                            <div class="item-inner">
                                <div class="item-title item-label">Lặp lại</div>
                                <div class="item-input-wrap">
                                <select class="field" name="Repeat" value="{{reminder.Repeat}}">
                                  <option value="NEVER" {{js "this.reminder && this.reminder.Repeat == 'NEVER' && 'selected'"}}>Không bao giờ</option>
                                  <option value="DAILY" {{js "this.reminder && this.reminder.Repeat == 'DAILY' && 'selected'"}}>Hàng ngày</option>
                                  <option value="WEEKLY" {{js "this.reminder && this.reminder.Repeat == 'WEEKLY' && 'selected'"}}>Hàng tuần</option>
                                  <option value="MONTHLY" {{js "this.reminder && this.reminder.Repeat == 'MONTHLY' && 'selected'"}}>Hàng tháng</option>
                                  <option value="YEARLY" {{js "this.reminder && this.reminder.Repeat == 'YEARLY' && 'selected'"}}>Hàng năm</option>
                                  <option value="HOURLY" {{js "this.reminder && this.reminder.Repeat == 'HOURLY' && 'selected'"}}>Mỗi giờ</option>
                                  <option value="EVERYMINUTE" {{js "this.reminder && this.reminder.Repeat == 'EVERYMINUTE' && 'selected'"}}>Mỗi phút</option>
                                </select>
                                </div>
                            </div>
                        </li>
                        <li class="item-content item-input">
                            <div class="item-media"><i class="icon f7-icons text-color-default">chat_bubble_text_fill</i></div>
                            <div class="item-inner">
                                <div class="item-title item-label">Lời nhắc</div>
                                <div class="item-input-wrap">
                                <textarea class="field" name="Note" placeholder="Nội dung tin nhắn">{{reminder.Note}}</textarea>
                                </div>
                            </div>
                        </li>
                        <li class="item-link smart-select smart-select-init" data-open-in="sheet" data-value-el=".MentionToMembers_value">
                          <select class="field" name="MentionToMembers" multiple="true">
                            {{#if memberList}}
                            {{#each memberList}}
                            <option value="{{id}}">{{name}}</option>
                            {{/each}}
                            {{/if}}
                          </select>
                          <div class="item-content">
                            <div class="item-media"><i class="icon f7-icons text-color-default {{textColorClass}}">person_circle_fill</i></div>
                            <div class="item-inner">
                              <div class="item-title item-label">Thành viên được nhắc</div>
                              <div class="item-text MentionToMembers_value"></div>
                            </div>
                          </div>
                        </li>
                        <li class="item-content item-input" style="padding-left: 0">
                          <a class="col button button-fill button-large link" style="width: 100%" @click="saveTaskReminder">Lưu lại</a>
                        </li>
                    </ul>
                </div>
                <div class="block-footer" style="width: 100%; user-select: initial; text-align: center; margin-bottom: 0.5rem">Hệ thống sẽ tự động nhắc việc khi đến hạn</div>
                
                <div class="block"><br></div>
                
              </div>
            </div>
        `,
        style: `
        `,
        data() {
          return {
            title: $this.title,
            reminder: {
              No: '234234',
            },
          };
        },
        methods: {
          async refresh() {
            const self: F7ComponentContextExtend = this;
            return true;
          },
          saveTaskReminder() {
            const self: F7ComponentContextExtend = this;
            const no = self.$route.params['id'];
            const chatRoom = self.$route.context.chatRoom;
            const taskReminderData: TaskReminderModel = {};
            const fieldEles = self.$el.find('.field');
            fieldEles.each((index, fieldEle) => {
              console.log(fieldEle);
              console.log(fieldEle.value);
              if (fieldEle.name === 'MentionToMembers') {
                const starmtSelect = self.$app.smartSelect.get(self.$(fieldEle).closest('.smart-select')[0] as any);
                taskReminderData[fieldEle.name] = starmtSelect.getValue();
              } else if (fieldEle.name === 'RemindAtDateTime') {
                // const dateTimePicker = self.$app.calendar.get('input[name="RemindAtDateTime"]');
                const datetime: Date = self.calendarDateTime.getValue()[0];
                if (datetime) {
                  taskReminderData[fieldEle.name] = datetime.toISOString();
                }
              } else {
                taskReminderData[fieldEle.name] = fieldEle.value;
              }
            });

            taskReminderData.Task = chatRoom.id;
            console.log(taskReminderData);
            self.$app.preloader.show();
            try {
              if (no === 'new') {
                $this.rootServices.apiService.postPromise<TaskReminderModel[]>('/task/reminders', { chatRoom: chatRoom.id }, [taskReminderData]).then(rs => {
                  console.log(rs);
                  self.$app.preloader.hide();
                  $this.commonService.showInfo("Đã lưu lời nhắc");

                  // let reminders: TaskReminderModel[] = self.$route.context.reminderList$.getValue();
                  // reminders.push(rs[0]);
                  // reminders = reminders.sort((a, b) => a.NextRemind > b.NextRemind ? 1 : -1);
                  // self.$route.context.reminderList$.next(reminders);

                  self.$router.back();
                }).catch(err => {
                  console.error(err);
                  $this.commonService.showError(err);
                  self.$app.preloader.hide();
                });
              } else {
                taskReminderData.No = no;
                $this.rootServices.apiService.putPromise<TaskReminderModel[]>('/task/reminders/' + chatRoom.id + '-' + no, { chatRoom: chatRoom.id }, [taskReminderData]).then(rs => {
                  console.log(rs);
                  self.$app.preloader.hide();
                  $this.commonService.showInfo("Đã lưu lời nhắc");
                  // let reminders: TaskReminderModel[] = self.$route.context.reminderList$.getValue();
                  // let reminderIndex: number = reminders.findIndex(f => f.No == no);
                  // reminders[reminderIndex] = { ...reminders[reminderIndex], ...rs[0] };
                  // reminders = reminders.sort((a, b) => a.NextRemind > b.NextRemind ? 1 : -1);
                  // self.$route.context.reminderList$.next(reminders);
                  self.$router.back();
                }).catch(err => {
                  console.error(err);
                  $this.commonService.showError(err);
                  self.$app.preloader.hide();
                });
              }
            } catch (err) {
              self.$app.preloader.hide();
            }
          },
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          pageInit(e, page: F7Page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;

            const reminder = self.$route.context.reminder || {};
            const chatRoom = self.$route.context.chatRoom;


            self.calendarDateTime = self.$f7.calendar.create({
              // openIn: 'customModal',
              inputEl: page.$el.find('input[name="RemindAtDateTime"]'),
              timePicker: true,
              dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' },
              // header: true,
              footer: true,
            });

            // Set back title
            // self.$setState({ backTitle: self.$route.context && self.$route.context.backTitle || 'Back', title: self.$route.context && self.$route.context.title || 'Tài khoản' });

            $this.onComponentInit({ instance: self }, 'main');

            console.log(reminder);
            $this.rootServices.authService.isAuthenticatedOrRefresh().pipe(take(1)).toPromise().then(() => {
              self.$setState({
                backTitle: self.$route.context && self.$route.context.backTitle || 'Back', title: self.$route.context && self.$route.context.title || 'Profile',
                memberList: chatRoom.memberList$.getValue().filter(member => member.Type === 'USER'),
                // reminder: self.reminder,
              });

              // setTimeout(() => {
              //   self.$setState({
              //     reminder: self.reminder,
              //   });
              // }, 5000);
            }).catch(err => {
              console.error(err);
              $this.commonService.showError(err);
            });

            setTimeout(() => {
              const starmtSelect = self.$app.smartSelect.get(self.$(page.el).find('.field[name="MentionToMembers"]').closest('.smart-select')[0] as any);
              // const remindeAtDateTime = self.$app.calendar.get(self.$('.field[name="RemindAtDateTime"]')[0] as any);

              if (reminder.MentionToMembers) {
                starmtSelect.setValue(reminder.MentionToMembers.map(member => $this.commonService.getObjectId(member)));
              }
              if (reminder.RemindAtDateTime) {
                self.calendarDateTime.setValue([new Date(reminder.RemindAtDateTime)]);
              }

              starmtSelect.on('closed', (smartSelect) => {
                const selectedRoles: string & string[] = (smartSelect.getValue() as string[]).sort() as any;
                console.log('on smart select closed', selectedRoles);
                // if ((reminder.roles || []).toString() !== (selectedRoles || []).toString()) {
                //   reminder.roles = selectedRoles;
                //   chatRoom.updateMember(reminder).then(rs => {
                //     console.log('chat room member was updated', rs);
                //     $this.commonService.showInfo(`Đã thay đổi vai trò của ${reminder.shortName || reminder.name}`);
                //   }).catch(err => {
                //     console.error(err);
                //     $this.commonService.showError(err);
                //   });
                // }
              });
            }, 500);
          },
          // pageBeforeIn(e, page) {
          //     console.log('page before in');
          //     const self: F7ComponentContextExtend = this;
          //     self.refresh();
          // },
          // pageAfterIn(e, page) {
          //     console.log('page after in');
          // },
          // pageBeforeOut(e, page) {
          //     console.log('page before out');
          // },
          // pageAfterOut(e, page) {
          //     console.log('page after out');
          // },
          // pageBeforeUnmount(e, page) {
          //     console.log('page before unmount');
          // },
          // pageBeforeRemove(e, page) {
          //     console.log('page before remove');
          // },
          pageBeforeRemove(e, page) {
            console.log('[page event] before remove', page.route.url);
            const self: F7ComponentContextExtend = this;
            const starmtSelectRoles = self.$app.smartSelect.get('.smart-select');
            starmtSelectRoles.destroy();
          },
        },
      },
    };
  }
}
