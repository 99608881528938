import { ProcessMap } from '../../../../src/app/model/model';
import { CommonService } from './../../services/common.service';
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class CollaboratorService {

  constructor(
    public commonService: CommonService
  ) {

    }

    processMap: { [key: string]: ProcessMap } = {
        "PROCESSING": {
            ...this.commonService.processingState,
            nextState: 'APPROVED',
            nextStates: [
                { ...this.commonService.approvedState, confirmLabel: 'Chốt đơn', confirmText: 'Bạn có muốn chốt đơn', status: 'success' },
                this.commonService.unrecordedState,
            ],
        },
        "APPROVED": {
            ...this.commonService.approvedState,
            text: 'Chốt đơn',
            label: 'Chốt đơn',
            nextState: 'COMPLETED',
            nextStates: [
                // this.commonService.depploymentState,
                // this.commonService.transportState,
                this.commonService.completeState,
                this.commonService.unrecordedState,
            ],
        },
        // "TRANSPORT": {
        //     ...this.commonService.transportState,
        //     nextState: 'DELIVERED',
        //     nextStates: [
        //         { ...this.commonService.deliveredState, status: 'success' },
        //         this.commonService.unrecordedState,
        //     ],
        // },
        // "DEPLOYMENT": {
        //     ...this.commonService.depploymentState,
        //     nextState: 'DEPLOYED',
        //     nextStates: [
        //         this.commonService.deployedState,
        //         this.commonService.unrecordedState,
        //     ],
        // },
        "DEPLOYED": {
            ...this.commonService.deployedState,
            nextState: 'COMPLETED',
            nextStates: [
                this.commonService.completeState,
                this.commonService.unrecordedState,
            ],
        },
        // "DELIVERED": {
        //     ...this.commonService.deliveredState,
        //     nextState: 'COMPLETED',
        //     nextStates: [
        //         this.commonService.completeState,
        //         this.commonService.returnState,
        //         this.commonService.unrecordedState,
        //     ],
        // },
        "COMPLETED": {
            ...this.commonService.completeState,
            nextState: 'UNRECORDED',
            nextStates: [
                this.commonService.unrecordedState,
            ],
        },
        "RETURN": {
            ...this.commonService.returnState,
            nextState: 'COMPLETED',
            nextStates: [
                // { ...this.commonService.completeState, status: 'success' },
                this.commonService.completeState,
                this.commonService.unrecordedState,
            ],
        },
        "UNRECORDED": {
            ...this.commonService.unrecordedState,
            nextState: 'PROCESSING',
            nextStates: [
                this.commonService.processingState,
            ],
        },
        "NOTJUSTAPPROVED": {
            ...this.commonService.notJustApprodedState,
            nextState: 'PROCESSING',
            nextStates: [
                this.commonService.processingState,
            ],
        },
    };



  freezeState: ProcessMap = {
    state: 'FREEZE',
    label: 'Lạnh',
    confirmLabel: 'Chuyển sang Lạnh',
    status: 'info',
    color: 'blue',
    outline: true,
    confirmTitle: 'Chuyển sang Lạnh',
    confirmText: 'Bạn có muốn chuyển sang trạng thái Lạnh',
    responseTitle: 'Chuyển sang trạng thái Lạnh',
    responseText: 'Đã chuyễn sang trạng thái Lạnh',
  };
  coolState: ProcessMap = {
    state: 'COOL',
    label: 'Mát',
    confirmLabel: 'Chuyển sang Mát',
    status: 'info',
    color: 'blue-tint',
    outline: true,
    confirmTitle: 'Chuyển sang Mát',
    confirmText: 'Bạn có muốn chuyển sang trạng thái Mát',
    responseTitle: 'Chuyển sang trạng thái Mát',
    responseText: 'Đã chuyễn sang trạng thái Mát',
  };
  warmState: ProcessMap = {
    state: 'WARM',
    label: 'Ấm',
    confirmLabel: 'Chuyển sang Ấm',
    status: 'warning',
    color: 'orange',
    outline: true,
    confirmTitle: 'Chuyển sang Ấm',
    confirmText: 'Bạn có muốn chuyển sang trạng thái Ấm',
    responseTitle: 'Chuyển sang trạng thái Ấm',
    responseText: 'Đã chuyễn sang trạng thái Ấm',
  };
  hotState: ProcessMap = {
    state: 'HOT',
    label: 'Nóng',
    confirmLabel: 'Chuyển sang Nóng',
    status: 'danger',
    color: 'red',
    outline: true,
    confirmTitle: 'Chuyển sang Nóng',
    confirmText: 'Bạn có muốn chuyển sang trạng thái Nóng',
    responseTitle: 'Chuyển sang trạng thái Nóng',
    responseText: 'Đã chuyễn sang trạng thái Nóng',
    nextState: 'FREEZE',
  };
  convertedState: ProcessMap = {
    state: 'CONVERTED',
    label: 'Đã chuyển đổi',
    confirmLabel: 'Chuyển đổi',
    status: 'success',
    color: 'green',
    outline: true,
    confirmTitle: 'Chuyển đổi cơ hội',
    confirmText: 'Bạn có muốn chuyển đổi',
    responseTitle: 'Chuyển đổi cơ hội',
    responseText: 'Đã chuyển đổi',
    nextState: 'FREEZE',
  };
  unrecordedState: ProcessMap = {
    state: 'UNRECORDED',
    label: 'Đã hủy',
    confirmLabel: 'Hủy',
    status: 'warning',
    color: 'yellow',
    outline: true,
    confirmTitle: 'Hủy cơ hội',
    confirmText: 'Bạn có muốn hủy cơ hội',
    responseTitle: 'Hủy cơ hội',
    responseText: 'Đã hủy cơ hội',
    nextState: 'FREEZE',
  };

  notJustApprodedState: ProcessMap = {
    state: 'NOTJUSTAPPROVED',
    label: 'Mới',
    confirmLabel: 'Chuyển sang Lạnh',
    status: 'danger',
    color: 'red',
    outline: false,
    confirmTitle: 'Chuyển sang Lạnh',
    confirmText: 'Bạn có muốn chuyển sang trạng thái Lạnh',
    responseTitle: 'Chuyển sang trạng thái Lạnh',
    responseText: 'Đã chuyễn sang trạng thái Lạnh',
    nextState: 'FREEZE',
  };

  opportunityProcessMap: { [key: string]: ProcessMap } = {
    "FREEZE": {
      ...this.freezeState,
      nextState: 'COOL',
      nextStates: [
        this.coolState,
      ],
    },
    "COOL": {
      ...this.coolState,
      nextState: 'WARM',
      nextStates: [
        this.warmState,
      ],
    },
    "WARM": {
      ...this.warmState,
      nextState: 'HOT',
      nextStates: [
        this.hotState,
      ],
    },
    "HOT": {
      ...this.hotState,
      nextState: 'CONVERTED',
      nextStates: [
        this.convertedState,
      ],
    },
    "CONVERTED": {
      ...this.convertedState,
      nextState: 'UNRECORDED',
      nextStates: [
        this.unrecordedState,
      ],
    },
    "UNRECORDED": {
      ...this.unrecordedState,
      nextState: 'FREEZE',
      nextStates: [
        this.freezeState,
      ],
    },
    "NOTJUSTAPPROVED": {
      ...this.notJustApprodedState,
      nextState: '',
      nextStates: [
        this.freezeState,
      ],
    },
    "": {
      ...this.notJustApprodedState,
      nextState: '',
      nextStates: [
        this.freezeState,
      ],
    },
  };
}