import { ProductModel } from 'vendors/smart-bot-app/src/app/model/product.model';
import { F7ComponentContext } from '../types/framework7-types';
import { Router } from 'framework7/build/core/modules/router/router';
import { take } from 'rxjs/operators';
import { BaseComponent, ComponentState } from 'vendors/smart-bot-app/src/app/lib/base-component';
import { ChatRoom } from 'vendors/smart-bot-app/src/app/lib/nam-chat/chat-room';
import { Member } from 'vendors/smart-bot-app/src/app/lib/nam-chat/model/member';
import { CommonService } from 'vendors/smart-bot-app/src/app/services/common.service';
import { RootServices } from 'vendors/smart-bot-app/src/app/services/root.services';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { AccountModel } from 'vendors/smart-bot-app/src/app/model/account.model';
import { CollaboratorOrderModel } from '../model/collaborator.model';
import * as $ from 'jquery';
import { TextEditor } from 'framework7/build/core/components/text-editor/text-editor';
import { FileModel } from 'vendors/smart-bot-app/src/app/model/file.model';

// declare const $: any;
export interface ComponentStateExtend extends ComponentState {
  [key: string]: any;
}

export class F7ComponentContextExtend extends F7ComponentContext {
  // messagebar?: F7Messagebar;
  // messages?: Messages.Messages;
  // images?: string[];
  responseInProgress?: boolean;
  // answers?: string[];
  // people?: { name?: string, avatar?: string }[];
  // hideToolbar?: () => void;
  // sheetToggle?: () => void;
  // deleteAttachment?: (e: MouseEvent, index: number) => void;
  // handleAttachment?: (e: MouseEvent) => void;
  // checkAttachments?: () => void;
  // sendMessage?: () => void;
  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, memberInfo?: Member, chatRoom?: ChatRoom, type?: string } };
}

/** Component group manager */
export class BioPageSettingComponent extends BaseComponent<ComponentStateExtend> {
  // states: { [key: string]: State } = {};

  title: 'BIO Setting'

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
    public datePipe: DatePipe,
    public currencyPipe: CurrencyPipe,
  ) {
    super(rootServices);
    // console.log('Click here to open ts file');
  }

  get isPublicComponent() {
    return true;
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: 'bio-page-setting',
      path: '/bio-page-setting/:id',
      component: {
        template: /*html*/`
            <div class="page user-bio" data-name="bio">
              <div class="navbar user-bio navbar-transparent">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a class="link back {{textColorClass}}">
                      <i class="icon icon-back"></i>
                      <span class="if-not-md {{textColorClass}}">{{backTitle}}</span>
                    </a>
                  </div>
                  <div class="title">{{title}}</div>
                  <div class="right">
                    <a class="link icon-only">
                      <i class="icon f7-icons">search</i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="page-content ptr-content infinite-scroll-content" @ptr:refresh="refresh">

                <div class="ptr-preloader">
                  <div class="preloader"></div>
                  <div class="ptr-arrow"></div>
                </div>
            
                <div class="bio-info">
                  <div class="bio-banner bg-color-gray"
                    style="background-image: url({{js "this.bio && this.bio.Banner && this.bio.Banner.SmallImage"}})">
                    {{#if itself}}
                    <div @click="uploadBanner" class="upload-banner-btn" style="right: 20px; bottom: 20px;">
                      <i class="icon f7-icons upload-banner-icon">camera_fill</i>
                    </div>
                    {{/if}}
                  </div>
                  <div class="bio-logo-border">
                    <div class="bio-logo bg-color-gray"
                      style="background-image: url({{js "this.bio && this.bio.Avatar && this.bio.Avatar.SmallImage"}})">
                    </div>
                    {{#if itself}}
                    <div @click="uploadAvatar" class="upload-banner-btn" style="right: 3vw; bottom: 6vw;">
                      <i class="icon f7-icons upload-banner-icon">camera_fill</i>
                    </div>
                    {{/if}}
                  </div>
                  <div class="bio-title text-color-default {{textColorClass}}">
                    {{js "this.bio && this.bio.Name || ''"}}</div>
                  <div class="bio-subtitle text-color-gray" style="font-size: 12px;">{{js "this.bio && this.bio.Tag && ('@' + this.bio.Tag) || ''"}}</div>
                  <!--<div style="text-align: center; margin-top: 10px;">
                    <a @click="copyText" data-text="https://probox.vn/bio/{{js "this.bio && this.bio.Code || ''"}}"><i style="font-size: 14px" class="icon f7-icons">square_on_square</i> https://probox.vn/bio/{{js "this.bio && this.bio.Code || ''"}}</a>
                  </div>-->
                  <div style="text-align: center; margin-top: 10px;">
                    <a @click="copyText" data-text="https://probox.vn/{{js "this.bio && this.bio.Tag || 'new'"}}"><i style="font-size: 14px" class="icon f7-icons">square_on_square</i> https://probox.vn/{{js "this.bio && this.bio.Tag || 'new'"}}</a>
                  </div>
                </div>
            
                {{#js_if "this.bio && this.bio.Phone"}}
                <div class="block inset" style="padding: 0; display: flex">
                  <button class="button button-large button-fill color-blue" style="flex: 1; margin-right: 8px; font-size: 16px" data-href="tel:{{js "this.bio && this.bio.Phone"}}" @click="openLink">
                    <i style="font-size: 20px" class="icon f7-icons">phone_fill</i> Gọi ngay {{js "this.bio && this.bio.Phone"}}
                  </button>
                  <button @click="copyText" data-text="{{bio.Phone}}" class="button button-large button-fill color-gray" style="width: 50px">
                    <i class="icon f7-icons">square_fill_on_square_fill</i>
                  </button>
                </div>
                {{/js_if}}

                <div class="block-header">Thông liên hệ</div>
                <div class="list inset bio-form no-hairlines-md">
                  <form autocomplete="off">
                    <ul>
                      <li class="item-content item-input">
                        <div class="item-media"><i class="icon f7-icons text-color-default">person_circle_fill</i></div>
                        <div class="item-inner">
                          <div class="item-title item-label">Tên đầy đủ</div>
                          <div class="item-input-wrap">
                            <input class="bio-field" name="Name" type="text" placeholder="Tên đầy đủ của bạn là..."
                              value="{{js "this.bio && this.bio.Name || ''"}}">
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-media"><i class="icon f7-icons text-color-default">person_circle_fill</i></div>
                        <div class="item-inner">
                          <div class="item-title item-label">Bí danh</div>
                          <div class="item-input-wrap">
                            <input class="bio-field" name="Aliases" type="text" placeholder="Bí danh của bạn là..."
                              value="{{js "this.bio && this.bio.Aliases || ''"}}">
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-media"><i class="icon f7-icons text-color-default">phone_circle_fill</i></div>
                        <div class="item-inner">
                          <div class="item-title item-label">Số điện thoại</div>
                          <div class="item-input-wrap">
                            <input class="bio-field" name="Phone" type="text" placeholder="Số điện thoại của bạn là..."
                              value="{{js "this.bio && this.bio.Phone || ''"}}">
                          </div>
                        </div>
                      </li>
                      <!--<li class="item-content item-input">
                        <div class="item-media"><i class="icon f7-icons text-color-default">location_fill</i></div>
                        <div class="item-inner">
                          <div class="item-title item-label">Địa chỉ</div>
                          <div class="item-input-wrap">
                            <input class="bio-field" name="Address" type="text" placeholder="Địa chỉ của bạn là..."
                              value="{{js "this.bio && this.bio.Address || ''"}}">
                          </div>
                        </div>
                      </li>-->
                      <li class="item-content item-input">
                        <div class="item-media"><i class="icon f7-icons text-color-default">at_circle_fill</i></div>
                        <div class="item-inner">
                          <div class="item-title item-label">Url</div>
                          <div class="item-input-wrap">
                            <input class="bio-field" name="Tag" type="text" placeholder="Tên hiển thị trong link bio"
                              value="{{js "this.bio && this.bio.Tag || ''"}}" autocorrect="off">
                            
                          </div>
                        </div>
                      </li>
                    </ul>
                  </form>
                </div>

                <div class="block-header">Liên kết</div>
                <div class="list inset bio-form no-hairlines-md">
                  <form autocomplete="off">
                    <ul>
                      <li class="item-content item-input">
                        <div class="item-media">
                          <img src="assets/icon/facebook.png" width="48">  
                        </div>
                        <div class="item-inner">
                          <div class="item-title item-label" style="color: #1877f2">Facebook</div>
                          <div class="item-input-wrap">
                            <input class="bio-field" name="FbLink" type="text" placeholder="Link Facebook của bạn là..." style="color: #4395ff;"
                              value="{{js "this.bio && this.bio.FbLink || ''"}}">
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-media">
                          <img src="assets/icon/tiktok.png" width="48">
                        </div>
                        <div class="item-inner">
                          <div class="item-title item-label" style="color: #700d27">Tiktok</div>
                          <div class="item-input-wrap">
                            <input class="bio-field" name="TiktokLink" type="text" placeholder="Link Tiktok của bạn là..."  style="color: #a63150;"
                              value="{{js "this.bio && this.bio.TiktokLink || ''"}}">
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-media">
                          <img src="assets/icon/www.png" width="48">
                        </div>
                        <div class="item-inner">
                          <div class="item-title item-label" style="color: var(--f7-block-strong-text-color)">Blog</div>
                          <div class="item-input-wrap">
                            <input class="bio-field" name="WebLink" type="text" placeholder="Link Blog của bạn là..." style="color: var(--f7-label-text-color);"
                              value="{{js "this.bio && this.bio.WebLink || ''"}}">
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-media">
                          <img src="assets/icon/youtube.png" width="48">
                        </div>
                        <div class="item-inner">
                          <div class="item-title item-label" style="color: #fe0a00">Youtube</div>
                          <div class="item-input-wrap">
                            <input class="bio-field" name="YoutubeLink" type="text" placeholder="Link Youtube của bạn là..." style="color: #f93e38;"
                              value="{{js "this.bio && this.bio.YoutubeLink || ''"}}">
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-media">
                          <img src="assets/icon/x.png" width="48">
                        </div>
                        <div class="item-inner">
                          <div class="item-title item-label" style="color: var(--f7-block-strong-text-color);">X</div>
                          <div class="item-input-wrap">
                            <input class="bio-field" name="XLink" type="text" placeholder="Link X của bạn là..." style="color: var(--f7-label-text-color);"
                              value="{{js "this.bio && this.bio.XLink || ''"}}">
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-media">
                          <img src="assets/icon/zalo.png" width="48">
                        </div>
                        <div class="item-inner">
                          <div class="item-title item-label" style="color: #0768ff;">Zalo</div>
                          <div class="item-input-wrap">
                            <input class="bio-field" name="ZaloLink" type="text" placeholder="Số Zalo của bạn là..." style="color: #3f88fa;"
                              value="{{js "this.bio && this.bio.ZaloLink || ''"}}">
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-media">
                          <img src="assets/icon/telegram.png" width="48">
                        </div>
                        <div class="item-inner">
                          <div class="item-title item-label" style="color: #23a1de;">Telegram</div>
                          <div class="item-input-wrap">
                            <input class="bio-field" name="TelegramLink" type="text" placeholder="Link Telegram của bạn là..." style="color: #07aeff;"
                              value="{{js "this.bio && this.bio.TelegramLink || ''"}}">
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-media">
                          <img src="assets/icon/email-icon.png" width="48">
                        </div>
                        <div class="item-inner">
                          <div class="item-title item-label" style="color: #f44335;">Email</div>
                          <div class="item-input-wrap">
                            <input class="bio-field" name="Email" type="text" placeholder="Email của bạn là..." style="color: #ff6f63;"
                              value="{{js "this.bio && this.bio.Email || ''"}}">
                          </div>
                        </div>
                      </li>
                    </ul>
                  </form>
                </div>

                <div class="block-header">Thông tin chung</div>
                <div class="list inset bio-form no-hairlines-md">
                  <form autocomplete="off">
                    <ul>
                      <li class="item-content item-input">
                        <div class="item-media"><i class="icon f7-icons text-color-default">tag_fill</i></div>
                        <div class="item-inner">
                          <div class="item-title item-label">Keyword 1</div>
                          <div class="item-input-wrap">
                            <input class="bio-field" name="Keyword1" type="text" placeholder="Keyword 1..."
                              value="{{js "this.bio && this.bio.Keyword1 || ''"}}">
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-media"><i class="icon f7-icons text-color-default">tag_fill</i></div>
                        <div class="item-inner">
                          <div class="item-title item-label">Keyword 2</div>
                          <div class="item-input-wrap">
                            <input class="bio-field" name="Keyword2" type="text" placeholder="Keyword 2..."
                              value="{{js "this.bio && this.bio.Keyword2 || ''"}}">
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-media"><i class="icon f7-icons text-color-default">chat_bubble_fill</i></div>
                        <div class="item-inner">
                          <div class="item-title item-label">Tự giới thiệu</div>
                          <div class="item-input-wrap">
                            <textarea class="bio-field" name="SelfIntroduction" type="text" placeholder="Tự giới thiệu...">{{js "this.bio && this.bio.SelfIntroduction || ''"}}</textarea>
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-media"><i class="icon f7-icons text-color-default">cube_box_fill</i></div>
                        <div class="item-inner">
                          <div class="item-title item-label">Kỹ năng</div>
                          <div class="item-input-wrap">
                            <textarea class="bio-field" name="SkillIntroduction" type="text" placeholder="Kỹ năng...">{{js "this.bio && this.bio.SkillIntroduction || ''"}}</textarea>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </form>
                </div>

                <div class="block inset" style="display: flex; flex-wrap: wrap;">
                  <div style="flex: 50%;">
                    <div class="image" style="background-image: url({{js "this.bio && this.bio.Image1 && this.bio.Image1.SmallImage"}})">
                      <div @click="uploadImage1" class="upload-banner-btn" style="right: 3vw; bottom: 6vw; right: 5px; top: 5px; left: initial; bottom: initial">
                        <i class="icon f7-icons upload-banner-icon">camera_fill</i>
                      </div>
                    </div>
                  </div>
                  <div style="flex: 50%;">
                    <div class="image" style="background-image: url({{js "this.bio && this.bio.Image2 && this.bio.Image2.SmallImage"}})">
                      <div @click="uploadImage2" class="upload-banner-btn" style="right: 3vw; bottom: 6vw; right: 5px; top: 5px; left: initial; bottom: initial">
                        <i class="icon f7-icons upload-banner-icon">camera_fill</i>
                      </div>
                    </div>
                  </div>
                  <div style="flex: 50%;">
                    <div class="image" style="background-image: url({{js "this.bio && this.bio.Image3 && this.bio.Image3.SmallImage"}})">
                      <div @click="uploadImage3" class="upload-banner-btn" style="right: 3vw; bottom: 6vw; right: 5px; top: 5px; left: initial; bottom: initial">
                        <i class="icon f7-icons upload-banner-icon">camera_fill</i>
                      </div>
                    </div>
                  </div>
                  <div style="flex: 50%;">
                    <div class="image" style="background-image: url({{js "this.bio && this.bio.Image4 && this.bio.Image4.SmallImage"}})">
                      <div @click="uploadImage4" class="upload-banner-btn" style="right: 3vw; bottom: 6vw; right: 5px; top: 5px; left: initial; bottom: initial">
                        <i class="icon f7-icons upload-banner-icon">camera_fill</i>
                      </div>
                    </div>
                  </div>
                </div>

                
                <div class="block-header">Liên kết dự phòng</div>
                <div class="list inset bio-form no-hairlines-md">
                  <form autocomplete="off">
                    <ul>
                      <li class="item-content item-input">
                        <div class="item-media">
                          <img src="assets/icon/facebook.png" width="48">  
                        </div>
                        <div class="item-inner">
                          <div class="item-title item-label" style="color: #1877f2">Facebook</div>
                          <div class="item-input-wrap">
                            <input class="bio-field" name="FbLink1" type="text" placeholder="Link Facebook của bạn là..." style="color: #4395ff;"
                              value="{{js "this.bio && this.bio.FbLink1 || ''"}}">
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-media">
                          <img src="assets/icon/tiktok.png" width="48">
                        </div>
                        <div class="item-inner">
                          <div class="item-title item-label" style="color: #700d27">Tiktok</div>
                          <div class="item-input-wrap">
                            <input class="bio-field" name="TiktokLink1" type="text" placeholder="Link Tiktok của bạn là..."  style="color: #a63150;"
                              value="{{js "this.bio && this.bio.TiktokLink1 || ''"}}">
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-media">
                          <img src="assets/icon/www.png" width="48">
                        </div>
                        <div class="item-inner">
                          <div class="item-title item-label" style="color: var(--f7-block-strong-text-color)">Blog</div>
                          <div class="item-input-wrap">
                            <input class="bio-field" name="WebLink1" type="text" placeholder="Link Blog của bạn là..." style="color: var(--f7-label-text-color);"
                              value="{{js "this.bio && this.bio.WebLink1 || ''"}}">
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-media">
                          <img src="assets/icon/youtube.png" width="48">
                        </div>
                        <div class="item-inner">
                          <div class="item-title item-label" style="color: #fe0a00">Youtube</div>
                          <div class="item-input-wrap">
                            <input class="bio-field" name="YoutubeLink1" type="text" placeholder="Link Youtube của bạn là..." style="color: #f93e38;"
                              value="{{js "this.bio && this.bio.YoutubeLink1 || ''"}}">
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-media">
                          <img src="assets/icon/x.png" width="48">
                        </div>
                        <div class="item-inner">
                          <div class="item-title item-label" style="color: var(--f7-block-strong-text-color);">X</div>
                          <div class="item-input-wrap">
                            <input class="bio-field" name="XLink1" type="text" placeholder="Link X của bạn là..." style="color: var(--f7-label-text-color);"
                              value="{{js "this.bio && this.bio.XLink1 || ''"}}">
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-media">
                          <img src="assets/icon/zalo.png" width="48">
                        </div>
                        <div class="item-inner">
                          <div class="item-title item-label" style="color: #0768ff;">Zalo</div>
                          <div class="item-input-wrap">
                            <input class="bio-field" name="ZaloLink1" type="text" placeholder="Số Zalo của bạn là..." style="color: #3f88fa;"
                              value="{{js "this.bio && this.bio.ZaloLink1 || ''"}}">
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-media">
                          <img src="assets/icon/telegram.png" width="48">
                        </div>
                        <div class="item-inner">
                          <div class="item-title item-label" style="color: #23a1de;">Telegram</div>
                          <div class="item-input-wrap">
                            <input class="bio-field" name="TelegramLink1" type="text" placeholder="Link Telegram của bạn là..." style="color: #07aeff;"
                              value="{{js "this.bio && this.bio.TelegramLink1 || ''"}}">
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-media">
                          <img src="assets/icon/email-icon.png" width="48">
                        </div>
                        <div class="item-inner">
                          <div class="item-title item-label" style="color: #f44335">Email</div>
                          <div class="item-input-wrap">
                            <input class="bio-field" name="Email1" type="text" placeholder="Email Telegram của bạn là..." style="color: #ff6f63"
                              value="{{js "this.bio && this.bio.Email1 || ''"}}">
                          </div>
                        </div>
                      </li>
                    </ul>
                  </form>
                </div>



                <div class="list inset bio-form no-hairlines-md">
                  <form autocomplete="off">
                    <ul>
                      <li class="item-content item-input">
                        <div class="item-media"><i class="icon f7-icons text-color-default">creditcard</i></div>
                        <div class="item-inner">
                          <div class="item-title item-label">Serial number eCard</div>
                          <div class="item-input-wrap">
                            <input class="bio-field" name="EcardSerialNumber" type="text" placeholder="ID eCard..."
                              value="{{js "this.bio && this.bio.EcardSerialNumber || ''"}}" disabled>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </form>
                </div>
                <div class="block-footer">
                Chú ý ! thông tin lưu tại đây được công khai trên trang Bio của bạn, thông tin này để mọi người truy cập vào có thể liên lạc với bạn. Với số điện thoại, bạn nên chọn số điện thoại không liên quan tài khoản ProBox.vn , tài khoản ngân hàng để tránh bị làm phiền hoặc tấn công các vấn đề khác.
                </div>
                

                <div class="list inset bio-form no-hairlines-md">
                  <form autocomplete="off">
                    <ul>
                      <li class="item-content item-input" style="padding-left: 0">
                        <a class="col button button-fill button-large link" @click="updateBio" style="width: 100%">Lưu</a>
                      </li>
                    </ul>
                  </form>
                </div>

            
              </div>
            </div>
        `,
        style: /*css*/`
          .post-content {
            padding: 1rem;
          }
          .post-content img {
            border-radius: 5px;
            margin-top: 5px;
            margin-bottom: 5px;
          }
          .page.user-bio .page-content {
            
          }
          /*.navbar.user-bio::after {
            position: absolute;
            content: "";
            left: 0px;
            top: 0px;
            height: 100%;
            width: 100%;
            background: linear-gradient(var(--f7-page-bg-color), transparent);
            pointer-events: none;
          }*/
          .page.user-bio .bio-info {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            margin-top: 0.5rem;
          }
          .page.user-bio .bio-logo-border {
            height: 160px;
            width: 160px;
            border-radius: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            margin-left: 1rem;
            margin-top: -100px;
            padding: 5px;
            background-color: var(--f7-page-bg-color);
            position: relative;
          }
          .page.user-bio .bio-logo {
            height: 150px;
            width: 150px;
            border-radius: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            margin: -1px auto;
            position: relative;
          }
          .page.user-bio .bio-banner {
            height: 200px;
            width: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            margin: 0 auto;
            position: relative;
            border-top-left-radius: 0.8rem;
            border-top-right-radius: 0.8rem;
          }
          .page.user-bio .upload-banner-btn {
            position: absolute;
            border-radius: 50%;
            background: #fff;
            border: 1.5px solid #000;
            padding: 2px;
            width: 28px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
          .page.user-bio .upload-banner-btn .upload-banner-icon {
            font-size: 14px;
            color: black;
          }
          .page.user-bio .bio-title {
            text-align: center;
            font-weight: bold;
          }
          .page.user-bio .bio-subtitle {
            text-align: center;
          }

          .page.user-bio .image {
            border-radius: var(--f7-list-inset-border-radius);
            margin: 5px; 
            height: 150px; 
            position: relative; 
            background-repeat: no-repeat; 
            overflow: hidden; 
            background-size: cover; 
            background-position: center;
            background-color: var(--f7-block-strong-bg-color);
          }

          .page.user-bio .bio-form li .item-media i {
            // font-size: 45px;
          }

          .demo-card-header-pic .card-header {
            height: 40vw;
            background-size: cover;
            background-position: center;
            color: #fff;
          }
          .demo-card-header-pic .card-title {
            color: var(--f7-block-title-text-color);
            font-weight: bold;
            font-size: 14px;
            padding: 0.5rem;
            padding-left: 1rem;
            padding-right: 1rem;
            border-radius: var(--f7-card-border-radius) var(--f7-card-border-radius) 0 0;
          }
        
          .demo-card-header-pic .card-content-padding .date {
            color: #8e8e93;
          }
        
          .demo-facebook-card .card-header {
            display: block;
            padding: 10px;
          }
        
          .demo-facebook-card .demo-facebook-avatar {
            float: left;
          }
          .demo-facebook-card .demo-facebook-avatar img {
            border-radius: 50%
          }
        
          .demo-facebook-card .demo-facebook-name {
            margin-left: 44px;
            font-size: 14px;
            font-weight: 500;
          }
        
          .demo-facebook-card .demo-facebook-date {
            margin-left: 44px;
            font-size: 13px;
            color: #8e8e93;
          }
        
          .demo-facebook-card .card-footer {
            background: #fafafa;
          }
        
          .demo-facebook-card .card-footer a {
            color: #81848b;
            font-weight: 500;
          }
        
          .demo-facebook-card .card-content img {
            display: block;
          }
        
          .demo-facebook-card .card-content-padding {
            padding: 15px 10px;
          }
        
          .demo-facebook-card .card-content-padding .likes {
            color: #8e8e93;
          }

          .page.user-bio .card.product .card-content * {
            max-width: 100%;
          }
          .page.user-bio .card.product .card-content figure {
              width: initial !important;
          }
          .page.user-bio .list.inset {
            
            color: var(--f7-color-gray-shade);
          }
        `,
        data() {
          return {
            title: $this.title,
            editable: false,
            bio: {},
            isAppPlatform: $this.rootServices.isAppPlatform,
            isLoggedIn: $this.rootServices.authService.getUser()?.id && true || false,
            itself: false,
          };
        },
        methods: {
          editLinksBlock(e) {
            const self: F7ComponentContextExtend = this;
            self.$setState({ linksBlockEditMode: true });
          },
          saveLinksBlock(e) {
            const self: F7ComponentContextExtend = this;
            self.$setState({ linksBlockEditMode: false });
            const linksBlockForm = $(e.target).closest('.link-block-form');
            const fieldEles = linksBlockForm.find('input[type="text"]');
            const links = {};
            fieldEles.each((index, fieldEle) => {
              console.log(fieldEle);
              console.log(fieldEle['value']);
              links[fieldEle['name']] = fieldEle['value'];
            });

            if ($this.rootServices.authService.getUser()?.id == self.bio.Code) {
              $this.rootServices.apiService.putPromise(`/user/bio-blocks/${self.bio.Code}-4`, {}, [{
                User: self.bio.Code,
                No: 4,
                Type: 'LINKS',
                Data: links,
              }]).then(rs => {
                $this.commonService.showInfo('Cập nhật links block thành công');
                self.refresh();
              });
            } else {
              console.error('không thể cập nhật links block cho user khác');
            }
          },
          gotoProducct(e) {
            const self: F7ComponentContextExtend = this;
            const productCode = $(e.target).data('id')?.replace(/_\w+/, '')?.toLowerCase();
            const pageCode = $(e.target).data('page')?.toLowerCase();
            // const product = self.bio?.products?.find(f => f.Code == productCode);
            $this.rootServices.navigate(`/${pageCode}/ctvbanhang/product/${productCode}`, { context: { backTitle: 'Profile', publisher: self.$route.params?.id } });
          },
          gotoAccount() {
            const self: F7ComponentContextExtend = this;
            if (self.itself) {
              $this.rootServices.navigate('/account', { context: { backTitle: 'Profile' } });
            }
          },
          uploadBanner(e) {
            const self: F7ComponentContextExtend = this;
            if (!self.bio.Code || $this.rootServices.authService.getUser()?.id == self.bio.User) {
              $this.rootServices.pickAndUploadFile().then(file => {
                console.log(file);
                self.bio.Banner = file;
                self.$setState({
                  bio: self.bio,
                });
                // $this.rootServices.apiService[self.bio.Code ? 'put' : 'post'+'Promise']('/bio/pages/' + (self.bio.Code|| ''), {}, [{
                //   Code: self.bio.Code,
                //   Banner: file,
                // }]).then(rs => {
                //   $this.commonService.showInfo('Cập nhật banner thành công');
                //   self.refresh();
                // });
              });
            } else {
              console.error('không thể cập nhật banner cho user khác');
            }
          },
          uploadAvatar(e) {
            const self: F7ComponentContextExtend = this;
            if (!self.bio.Code || $this.rootServices.authService.getUser()?.id == self.bio.User) {
              $this.rootServices.pickAndUploadFile().then(file => {
                console.log(file);
                self.bio.Avatar = file;
                self.$setState({
                  bio: self.bio,
                });
              });
            } else {
              console.error('không thể cập nhật banner cho user khác');
            }
          },
          uploadImage1(e) {
            const self: F7ComponentContextExtend = this;
            if (!self.bio.Code || $this.rootServices.authService.getUser()?.id == self.bio.User) {
              $this.rootServices.pickAndUploadFile().then(file => {
                console.log(file);
                self.bio.Image1 = file;
                self.$setState({
                  bio: self.bio,
                });
              });
            } else {
              console.error('không thể cập nhật banner cho user khác');
            }
          },
          uploadImage2(e) {
            const self: F7ComponentContextExtend = this;
            if (!self.bio.Code || $this.rootServices.authService.getUser()?.id == self.bio.User) {
              $this.rootServices.pickAndUploadFile().then(file => {
                console.log(file);
                self.bio.Image2 = file;
                self.$setState({
                  bio: self.bio,
                });
              });
            } else {
              console.error('không thể cập nhật banner cho user khác');
            }
          },
          uploadImage3(e) {
            const self: F7ComponentContextExtend = this;
            if (!self.bio.Code || $this.rootServices.authService.getUser()?.id == self.bio.User) {
              $this.rootServices.pickAndUploadFile().then(file => {
                console.log(file);
                self.bio.Image3 = file;
                self.$setState({
                  bio: self.bio,
                });
              });
            } else {
              console.error('không thể cập nhật banner cho user khác');
            }
          },
          uploadImage4(e) {
            const self: F7ComponentContextExtend = this;
            if (!self.bio.Code || $this.rootServices.authService.getUser()?.id == self.bio.User) {
              $this.rootServices.pickAndUploadFile().then(file => {
                console.log(file);
                self.bio.Image4 = file;
                self.$setState({
                  bio: self.bio,
                });
              });
            } else {
              console.error('không thể cập nhật banner cho user khác');
            }
          },
          copyText(e) {
            const self: F7ComponentContextExtend = this;
            const text = $(e.target).data('text');
            console.log(text);
            $this.rootServices.copyTextToClipboard(text);
          },
          orderProduct(e) {
            const self: F7ComponentContextExtend = this;
            const productListName = $(e.target).data('list');
            const cardEle = $(e.target).closest('.card');
            const nameEle = cardEle.find('input[name="CustomerName"]');
            const phoneEle = cardEle.find('input[name="CustomerPhone"]');
            const addressEle = cardEle.find('input[name="CustomerAddress"]');

            const productCode = $(e.target).data('product');
            let product = null;
            if (productListName === 'subscription') {
              product = self.bio?.subscriptionProducts?.find(f => f.Code === productCode);
            } else {
              product = self.bio?.Blocks?.find(f => f.Type === 'BUSINESSPRODUCT')?.Data?.find(f => f.Code === productCode);
            }

            if (!product) {
              $this.commonService.showError('Sản phẩm không tồn tại');
              return;
            }
            if (!nameEle.val()) {
              $this.commonService.showError('Quý khách chưa điền tên !');
              return;
            }
            if (!phoneEle.val()) {
              $this.commonService.showError('Quý khách chưa điền số điện thoại !');
              return;
            }
            if (!addressEle.val()) {
              $this.commonService.showError('Quý khách chưa điền địa chỉ nhận hàng !');
              return;
            }

            $this.commonService.showPreloader();
            $this.rootServices.apiService.postPromise<CollaboratorOrderModel[]>('/collaborator/orders', { skipAuth: true, publisher: self.$route.params?.id }, [
              {
                Page: product.Page,
                ObjectName: nameEle.val() as string,
                ObjectPhone: phoneEle.val() as string,
                ObjectAddress: addressEle.val() as string,
                Details: [
                  {
                    Product: product.Code,
                    Description: product.Name,
                    Unit: product.Unit,
                    Price: product.Price,
                    Quantity: 1,
                    Image: product?.FeaturePicture ? [product?.FeaturePicture] : [],
                    Token: product.Token,
                  }
                ]
              }
            ]).then(rs => rs[0]).then(newOrder => {
              $this.commonService.hidePreloader();
              $this.commonService.showInfo(`Cám ơn bạn đã ủng hộ sản phẩm: ${product.Name}<br>Mã đặt hàng của bạn là: ${newOrder.Code}<br>Chúc bạn một ngày may mắn và vui vẻ ❤️`, { timeout: 120000 });
              nameEle.val('');
              phoneEle.val('');
              addressEle.val('');
            }).catch(err => {
              $this.commonService.showError(err);
              $this.commonService.hidePreloader();
            });
          },
          openLink(e) {
            const self: F7ComponentContextExtend = this;
            const link = $(e.target).closest('a').data('href');
            $this.rootServices.iab.create(link, '_system');
          },
          editProductBlock(e) {
            const self: F7ComponentContextExtend = this;
            const blockNo = $(e.target).data('no');
            $this.rootServices.navigate(`/product-block/${self.$route.params?.id}`, {
              context: {
                productList: self.bio?.products,
                onSave: (productList: any[]) => {
                  $this.rootServices.apiService.putPromise(`/user/bio-blocks/${self.$route.params.id}-${blockNo}`, {}, [{
                    User: self.$route.params.id,
                    No: blockNo,
                    Type: 'BUSINESSPRODUCT',
                    Data: productList.map(p => p.Code),
                  }]).then(rs => self.refresh());
                }
              }
            });
          },
          editContent(e) {
            const self: F7ComponentContextExtend = this;
            const blockNo = $(e.target).data('no');
            const blockType = $(e.target).data('type');
            const postContent = $(e.target).closest('.card').find('.post-content');
            $this.rootServices.navigate('/editor/' + $this.rootServices.authService?.getUser()?.id, {
              context: {
                postContent: postContent.html(),
                onSave: (content: any) => {
                  console.log(content);
                  postContent.html(content);
                  $this.rootServices.apiService.putPromise(`/user/bio-blocks/${self.$route.params.id}-${blockNo}`, {}, [{
                    User: self.$route.params.id,
                    No: blockNo,
                    Type: blockType,
                    Content: content,
                  }]).then(rs => self.refresh());
                }
              }
            });
          },
          async refresh(e, done) {
            const self: F7ComponentContextExtend = this;
            const currentState = $this.state[self.$route.params?.id];

            let doneTimeout = null;
            if (done) {
              doneTimeout = setTimeout(() => {
                done();
              }, 10000);
            };

            await $this.rootServices.apiService.getPromise<AccountModel>('/bio/pages', { eq_User: $this.rootServices?.authService?.getUser()?.id, skipAuth: true, includeBlocks: true, sort_Id: 'asc' }).then(rs => rs[0]).then(async bioPage => {
              console.log(bioPage);
              if (!bioPage) {
                bioPage = {};
              }
              // self.textEditorCustomButtons.setValue(bioPage.SelfIntroduction);
              let coreDomain = $this.rootServices.apiService.token && $this.rootServices.apiService.token.api_url;
              if (coreDomain) {
                const url = new URL(coreDomain);
                coreDomain = url.host;
              }
              // const bioInfo = bioPage.Blocks?.find(f => f.Type == 'PROFILEINFO');
              // const csrInfo = bioPage.Blocks?.find(f => f.Type == 'CSR');
              // const bioProducts = bioPage?.Blocks?.find(f => f.Type == 'BUSINESSPRODUCT')?.Data?.map(product => {
              //   if (product?.Units) {
              //     product.Unit = product.Unit || (product.Units && product.Units[0] && product.Units[0]['Unit'] || '');
              //     const unit = product.Units.find(f => f.id == product.Unit);
              //     if (unit) {
              //       product.Price = unit.Price;
              //       product.Unit = unit.id;
              //       product.UnitName = unit.text;
              //       product.PriceText = $this.rootServices.currencyPipe.transform(product.Price, 'VND');
              //     }
              //   }
              //   return {
              //     ...product,
              //     // PriceText: $this.currencyPipe.transform(product.Price || 0, 'VND'),
              //     FeaturePicture: product.FeaturePicture && product.FeaturePicture.OriginImage ? product.FeaturePicture : {
              //       ThumbnalImage: 'assets/images/no-image-available.png',
              //       SmallImage: 'assets/images/no-image-available.png',
              //       OriginImage: 'assets/images/no-image-available.png',
              //     }
              //   };
              // });
              // const bioLinks = bioPage?.Blocks?.find(f => f.Type == 'LINKS')?.Data;

              // const subscriptionProducts = await $this.rootServices.apiService.getPromise<ProductModel[]>('/collaborator/product-subscriptions', {
              //   publisher: self.$route.params?.id,
              //   includeCategories: true,
              //   includeGroups: true,
              //   includeUnitPrices: true,
              //   sort_Id: 'desc',
              //   limit: 'nolimit',
              //   skipAuth: true,
              // }).then(rs => {
              //   return rs.map(product => {

              //     if (product?.Units) {
              //       product.Unit = product.Unit || (product.Units && product.Units[0] && product.Units[0]['Unit'] || '') as any;
              //       const unit = product.Units.find(f => f.id == product.Unit);
              //       if (unit) {
              //         product.Price = unit.Price;
              //         product.Unit = unit.id;
              //         product.UnitName = unit.text;
              //         product.PriceText = $this.rootServices.currencyPipe.transform(product.Price, 'VND');
              //       }
              //     }

              //     product.PriceText = $this.currencyPipe.transform(product.Price || 0, 'VND');
              //     product.FeaturePicture = product.FeaturePicture && product.FeaturePicture.OriginImage ? product.FeaturePicture : {
              //       ThumbnalImage: 'assets/images/no-image-available.png',
              //       SmallImage: 'assets/images/no-image-available.png',
              //       OriginImage: 'assets/images/no-image-available.png',
              //     } as any;
              //     return product;
              //   });
              // });

              self.$setState({
                bio: {
                  ...bioPage,
                  Core: coreDomain,
                  // info: bioInfo,
                  // products: bioProducts,
                  // subscriptionProducts: subscriptionProducts,
                  // links: bioLinks,
                  // csrInfo: csrInfo,
                  tageName: bioPage.Tag,
                },
                itself: !bioPage.Code || bioPage.User == $this.rootServices?.authService?.getUser()?.id,
                title: bioPage.Name,
                backTitle: self.backTitle || 'Back'
                // backTitle: userInfo.Name,
              });
              currentState.loaded$ && currentState.loaded$.next(true);
            });
            done && done();
            return true;
          },
          updateBio(e) {
            const self: F7ComponentContextExtend = this;
            $this.commonService.showPreloader();

            const fieldEles = self.$el.find('form .bio-field') as any;
            for (let i = 0; i < fieldEles.length; i++) {
              const fieldEle = fieldEles[i];
              self.bio[fieldEle.name] = fieldEle.value;
            }

            // self.bio.SelfIntroduction = self.textEditorCustomButtons.getValue();

            $this.rootServices.apiService[(self.bio.Code ? 'put' : 'post') + 'Promise']('/bio/pages/' + (self.bio.Code || ''), {}, [
              self.bio
            ]).then(rs => {
              $this.commonService.showInfo('Cập nhật bio thành công');
              self.refresh();
              $this.commonService.hidePreloader();
            }).catch(err => {
              $this.commonService.hidePreloader();
              return Promise.reject(err);
            });
          }
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          pageInit(e, page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;

            $this.onComponentInit({ instance: self }, self.$route.params?.id).then(currentState => {


              // self.textEditorCustomButtons = self.$app.textEditor.create({
              //   el: '.text-editor-custom-buttons.SelfIntroduction',
              //   // define custom "hr" button
              //   value: self.postContent,
              //   customButtons: {
              //     upload: {
              //       content: '<i class="f7-icons">photo</i>',
              //       onClick(editor: TextEditor.TextEditor, buttonEl) {
              //         // document.execCommand('insertHorizontalRule', false);
              //         // editor.insertImage();

              //         $this.rootServices.pickFiles().then(async localFiles => {
              //           try {
              //             $this.commonService.showPreloader();
              //             console.debug(localFiles);
              //             let image: FileModel = null;
              //             if (localFiles[0].dataType === 'url') {
              //               image = await $this.rootServices.uploadLocalFile(localFiles[0].url as string);
              //             } else {
              //               const formData = new FormData();
              //               const imgBlob = new Blob([localFiles[0].url], {
              //                 type: localFiles[0].type
              //               });
              //               formData.append('file', imgBlob, 'probox-' + Date.now() + '.' + localFiles[0].ext);
              //               image = await $this.rootServices.uploadFileData(formData, null, { weight: localFiles[0].size });
              //             }
              //             console.debug(image);
              //             document.execCommand('insertImage', false, image.OriginImage);
              //             $this.commonService.hidePreloader();
              //           } catch (err) {
              //             $this.commonService.hidePreloader();
              //           }
              //         });
              //       },
              //     },
              //   },
              //   buttons: [
              //     ['bold', 'italic', 'underline', 'strikeThrough'],
              //     ['orderedList', 'unorderedList'],
              //     ['link'],
              //     ['paragraph', 'h1', 'h2', 'h3'],
              //     ['alignLeft', 'alignCenter', 'alignRight', 'alignJustify'],
              //     ['subscript', 'superscript'],
              //     ['indent', 'outdent'],
              //     'upload'
              //   ] as any,
              // });

              // console.log(memberInfo);
              $this.rootServices.authService.isAuthenticatedOrRefresh().pipe(take(1)).toPromise().then(() => {
                self.refresh();
              }).catch(err => {
                console.error(err);
                $this.commonService.showError(err);
              });
            });

          },
          // pageBeforeIn(e, page) {
          //     console.log('page before in');
          // },
          // pageAfterIn(e, page) {
          //     console.log('page after in');
          // },
          // pageBeforeOut(e, page) {
          //     console.log('page before out');
          // },
          // pageAfterOut(e, page) {
          //     console.log('page after out');
          // },
          // pageBeforeUnmount(e, page) {
          //     console.log('page before unmount');
          // },
          // pageBeforeRemove(e, page) {
          //     console.log('page before remove');
          // },
          pageBeforeRemove(e, page) {
            console.log('[page event] before remove', page.route.url);
            const self: F7ComponentContextExtend = this;
            const smartSelectRoles = self.$app.smartSelect.get('.smart-select-roles');
            smartSelectRoles && smartSelectRoles.destroy();
            self.textEditorCustomButtons && self.textEditorCustomButtons.destroy()
          },
        },
      },
    };
  }
}
