import { take } from 'rxjs/operators';
import { Dialog } from "framework7/build/core/components/dialog/dialog";
import { Router } from "framework7/build/core/modules/router/router";
import { ContactModel } from "../../../model/contact.model";
import { SalesMasterPriceTable } from "../../../model/sales.model";
import { CommonService } from "../../../services/common.service";
import { RootServices } from "../../../services/root.services";
import { F7ComponentContext, F7Page } from "../../../types/framework7-types";
import { FileModel } from '../../../model/file.model';
import { BaseFormComponent, FormComponentStateExtend, FormSchema } from '../../../lib/base-form-component';
import { ProductModel, UnitModel } from '../../../model/product.model';
import { ChatRoomMemberModel, ChatRoomModel } from '../../../model/chat-room.model';
import { CollaboratorOpportunityCommentModel, CollaboratorOpportunityDetailModel, CollaboratorOpportunityModel, CollaboratorOrderModel } from '../../../model/collaborator.model';
import { Messages } from 'framework7/build/core/components/messages/messages';
import { CollaboratorStateMap } from '../collaborator.state.map';
// import * as $ from 'jquery';
declare const $: any;

// declare const $: any;
export interface ComponentStateExtend extends FormComponentStateExtend<CollaboratorOpportunityModel> {
  [key: string]: any;
  instance: F7ComponentContextExtend,
  data?: CollaboratorOpportunityModel;
  form?: any;
}

export class F7ComponentContextExtend extends F7ComponentContext {
  responseInProgress?: boolean;

  detailDialog?: Dialog.Dialog;

  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, [key: string]: any } };
}

/** Component group manager */
export class CollaboratorOpportunityFormComponent extends BaseFormComponent<ComponentStateExtend, CollaboratorOpportunityModel> {
  // states: { [key: string]: State } = {};
  currentState: ComponentStateExtend;
  title: 'Cơ hội';

  idKey = 'Code';

  apiPath = '/collaborator/opportunities';

  taxList = [
    {
      id: 'VAT10',
      text: '+ 10% VAT',
      Tax: 10,
      Type: 'NONE',
      Name: 'Chưa bao gồm VAT 10%',
      selected: false,
    },
    {
      id: 'FULLVAT10',
      text: 'Đã bao gồm VAT 10%',
      Tax: 0,
      Type: 'NONE',
      Name: 'Đã Full VAT 10%',
      selected: false,
    },
    {
      id: 'NOTAX',
      text: 'n/a',
      Tax: 0,
      Type: 'NONE',
      Name: 'Không thuế',
      selected: false,
    },
  ];

  unitList: UnitModel[] = [];
  pageList: UnitModel[] = [];
  priceTableList: SalesMasterPriceTable[] = [];

  contactList: ContactModel[] = [];

  schema: FormSchema = {
    // Customer: {
    //   type: 'smart-select',
    //   label: 'Khách hàng',
    //   validators: [
    //     'required'
    //   ]
    // },
    CustomerName: {
      type: 'input',
      label: 'Tên nhà khách hàng',
      validators: [
        'required'
      ]
    },
    Customer: {
      type: 'autocomplete',
      autocompleteOption: { minSearchInputLength: 0, autoFocus: false, limit: 20 },
      ajax: (query: any) => {
        return this.rootServices.apiService.getPromise<ContactModel[]>('/collaborator/contacts', { search: query, includeIdText: true, includeGroups: true, sort_SearchRank: 'desc' }).then(results => {
          return results.map(m => {
            m.text = `${m.Name}${m.Groups ? ' (' + m.Groups.map(g => this.commonService.getObjectText(g as any)).join(', ') + ')' : ''}`;
            return m;
          });
        });
      },
      label: 'Khách hàng',
      validators: [
        'required'
      ]
    },
    DirectReceiver: {
      type: 'autocomplete',
      autocompleteOption: { minSearchInputLength: 0, autoFocus: false, limit: 20 },
      ajax: (query: any) => {
        return this.rootServices.apiService.getPromise<ContactModel[]>('/collaborator/contacts', { search: query, includeIdText: true, includeGroups: true, sort_SearchRank: 'desc' }).then(results => {
          return results.map(m => {
            m.text = `${m.Name}${m.Groups ? ' (' + m.Groups.map(g => this.commonService.getObjectText(g as any)).join(', ') + ')' : ''}`;
            return m;
          });
        });
      },
      label: 'Người nhận hàng trực tiếp',
      validators: [
      ]
    },
    Publisher: {
      type: 'autocomplete',
      autocompleteOption: { minSearchInputLength: 0, autoFocus: false, limit: 20 },
      ajax: (query: any) => {
        return this.rootServices.apiService.getPromise<ContactModel[]>('/collaborator/publishers', { search: query, includeIdText: true }).then(results => {
          return results.map(m => {
            m.id = this.commonService.getObjectId(m.Contact);
            m.text = `${m.Name}`;
            return m;
          });
        });
      },
      label: 'CTV Bán Hàng',
      validators: [
      ]
    },
    Page: {
      type: 'smart-select',
      label: 'Trang',
      validators: [
        'required'
      ]
    },
    Target: {
      type: 'smart-select',
      label: 'Mục tiêu',
      validators: [
        'required'
      ]
    },
    Title: {
      type: 'input',
      label: 'Tiêu đề',
      validators: [
        'required'
      ]
    },
    Note: {
      type: 'texteditor',
    },
    SubNote: {
      type: 'texteditor',
    },
    DeliveryProvince: {
      label: 'Tỉnh/TP',
      type: 'autocomplete',
      autocompleteOption: { minSearchInputLength: 0, autoFocus: false, limit: 20 },
      ajax: (query: any) => {
        return this.rootServices.apiService.getPromise('/general/locations', { select: 'id=>Code,text=>CONCAT(TypeLabel;\' \';FullName)', limit: 100, 'search': query, eq_Type: '[PROVINCE,CITY]' });
      },
      validators: [
      ]
    },
    DeliveryDistrict: {
      label: 'Quận/Huyện',
      type: 'autocomplete',
      autocompleteOption: { minSearchInputLength: 0, autoFocus: false, limit: 20 },
      ajax: (query: any) => {
        const data = this.currentState.data;
        return this.rootServices.apiService.getPromise('/general/locations', { select: 'id=>Code,text=>CONCAT(TypeLabel;\' \';FullName)', limit: 100, 'search': query, eq_Type: '[CDISTRICT,PDISTRICT,BURG,CITYDISTRICT]', eq_Parent: this.commonService.getObjectId(data?.DeliveryProvince) });
      },
      validators: [
      ]
    },
    DeliveryWard: {
      label: 'Phường/Xã',
      type: 'autocomplete',
      autocompleteOption: { minSearchInputLength: 0, autoFocus: false, limit: 20 },
      ajax: (query: any) => {
        const data = this.currentState.data;
        return this.rootServices.apiService.getPromise('/general/locations', { select: 'id=>Code,text=>CONCAT(TypeLabel;\' \';FullName)', limit: 100, 'search': query, eq_Type: '[VILLAGE,WARD,TOWNS]', eq_Parent: this.commonService.getObjectId(data?.DeliveryDistrict) });
      },
      validators: [
      ]
    },
    Details: {
      type: 'list',
      makeModel: (properties: CollaboratorOpportunityDetailModel) => {
        return new CollaboratorOpportunityDetailModel(properties);
      },
      Unit: {
        type: 'smart-select',
        label: 'Đơn vị tính',
        validators: [
          'required'
        ]
      },
      Quantity: {
        type: 'stepper',
        label: 'Số lượng',
        validators: [
          'required'
        ]
      },
      IsRelativeToPublisher: {
        type: 'checkbox',
        label: 'Liên quan CTV',
        validators: [
        ]
      },
      Price: { type: 'inputmask', format: { mask: 'decimal', option: { radixPoint: ',', groupSeparator: '.' } } },
      ToMoney: { type: 'inputmask', format: { mask: 'decimal', option: { radixPoint: ',', groupSeparator: '.' } } },
    }
  };

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
  ) {
    super(rootServices, commonService);
    // // console.log('Click here to open ts file');

    this.rootServices.platform.pause.subscribe(status => {
      // Auto save
      // this.currentState?.data?.Code && this.currentState?.data?.State != 'APPROVED' && this.currentState.instance.save();
    });
  }

  makeModel(properties?: CollaboratorOpportunityModel): CollaboratorOpportunityModel {

    // if (!properties) {
    //   properties = {};
    // }
    // if (!properties.Page) {
    //   properties.Page = this.pageList[0];
    // }
    return new CollaboratorOpportunityModel(properties);
  }

  async makeNewData() {
    return new CollaboratorOpportunityModel();
  }

  getFormData(params?: any): Promise<CollaboratorOpportunityModel> {
    return super.getFormData({
      includeCustomer: true,
      includeContact: true,
      includeDetails: true,
      includeRelativeVouchers: true,
      includePublisher: true,
      includeComments: true,
      ...params,
    }).then(rs => {
      if (rs.RelativeVouchers) {
        rs.RelativeVouchers.map(relativeVoucher => {
          relativeVoucher.type = this.commonService.voucherTypeMap[relativeVoucher.type] || { id: relativeVoucher.type };
        });
      }
      return rs;
    });
  }

  prepareData(data: CollaboratorOpportunityModel): CollaboratorOpportunityModel {
    for (const detail of data.Details) {

      if (detail?.Unit && typeof detail?.Unit == 'string') {
        detail.Unit = this.unitList.find(f => f.id == detail.Unit as any);
      }

      if (!data.Pictures) {
        data.Pictures = [];
      }
    };
    this.loadComment(data.Comments);
    return data;
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  prepareComment(comment: CollaboratorOpportunityCommentModel): Messages.Message {
    const loggedUser = this.rootServices.authService?.user$?.value;
    const type = loggedUser.id == this.commonService.getObjectId(comment.Sender) ? 'sent' : 'received';

    let imageHtml = null;
    let imgSrc = comment.Attachments && comment.Attachments[0] ? comment.Attachments[0].LargeImage : null;
    if (comment.Attachments && comment.Attachments.length > 1) {
      imgSrc = null;
      imageHtml = this.createThumbnailWrap(comment.Attachments);
    }
    return {
      /** Message text. */
      text: comment.Content,
      /** Single message header. */
      header: null,
      /** Single message footer. */
      footer: comment.DateOfPost && new Date(comment.DateOfPost).toUTCString() || null,
      /** Sender name. */
      name: comment.SenderName,
      /** Sender avatar URL string. */
      avatar: type != 'sent' && comment.SenderAvatar?.Thumbnail || null,
      /** Message type - sent or received. (default sent) */
      type: type,
      /** Message text header. */
      textHeader: null,
      /** Message text footer. */
      textFooter: null,
      /** Message image HTML string, e.g. <img src="path/to/image">. Can be used instead of imageSrc parameter. */
      image: imageHtml,
      /** Message image URL string. Can be used instead of image parameter. */
      imageSrc: imgSrc,
      /** Defines whether it should be rendered as a message or as a messages title. */
      isTitle: false,
    };
  }

  async postComment(comment: CollaboratorOpportunityCommentModel) {
    const $this = this;
    $this.commonService.showPreloader();
    const loggedUser = this.rootServices.authService?.user$?.value;
    // return if empty message
    if (!comment.Content.length && (!comment.Attachments || comment.Attachments.length == 0)) return;

    // Clear area
    this.currentState.instance.messagebar.clear();

    // Return focus to area
    this.currentState.instance.messagebar.focus();

    comment.Opportunity = this.currentState.data.Code;
    comment.Sender = {
      id: loggedUser.id,
      text: loggedUser.name,
    };
    comment.SenderName = loggedUser.name;
    comment.SenderAvatar = loggedUser.avatar;
    comment.DateOfPost = new Date();

    try {
      const newComments = await this.rootServices.apiService.postPromise<CollaboratorOpportunityCommentModel[]>('/collaborator/opportunity-comments', {
        page: this.commonService.getObjectId(this.currentState.data.Page),
      }, [comment]);
      // Add message to messages
      this.currentState.instance.messages.addMessage(this.prepareComment(newComments[0]), 'append');
      $this.commonService.hidePreloader();
      return newComments[0];
    } catch (err) {
      console.error(err);
      this.commonService.showError(err);
      $this.commonService.hidePreloader();
      return false;
    }


    // if (responseInProgress) return;
    // // Receive dummy message
    // receiveMessage();
  }

  createThumbnailWrap(attachments: FileModel[]) {
    return attachments.map(m => `<img src="${m.LargeImage}">`).join('');
  }

  initComment(state: ComponentStateExtend) {
    const $this = this;
    // if (this.currentState.instance.messages) {
    //   // this.currentState.instance.messages.destroy();
    //   this.currentState.instance.messages.clear();
    //   this.currentState.instance.messages.messages = messages;
    //   this.currentState.instance.messages.renderMessages();
    //   return;
    // }
    state.instance.messages = state.instance.$app.messages.create({
      el: state.instance.$('.messages'),
      firstMessageRule(message: Messages.Message, previousMessage: Messages.Message, nextMessage: Messages.Message) {
        if (message.isTitle) { return false; }
        if (!previousMessage || previousMessage.type !== message.type || previousMessage.name !== message.name) { return true; }
        return false;
      },
      lastMessageRule(message: Messages.Message, previousMessage: Messages.Message, nextMessage: Messages.Message) {
        if (message.isTitle) { return false; }
        if (!nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name) { return true; }
        return false;
      },
      tailMessageRule(message: Messages.Message, previousMessage: Messages.Message, nextMessage: Messages.Message) {
        if (message.isTitle) { return false; }
        if (!nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name) { return true; }
        return false;
      },
      sameFooterMessageRule(message: Messages.Message, previousMessage: Messages.Message, nextMessage: Messages.Message) {
        if (message.isTitle) { return false; }
        if (!nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name || nextMessage.footer !== message.footer) { return false; }
        return true;
      },
    } as Messages.Parameters);


    if (!state.instance.messagebar) {
      state.instance.messagebar = state.instance.$app.messagebar.create({
        el: state.instance.$el.find('.messagebar'),
      } as any);
      state.instance.messagebar.$el.find('.link').on('click', () => {
        var text = state.instance.messagebar.getValue().replace(/\n/g, '<br>').trim();
        this.postComment({
          Content: text,
          Attachments: state.instance.messagebar.attachments as any,
        }).then(newComment => {
          state.instance.messagebar.attachments = [];
          state.instance.messagebar.renderAttachments();
          state.instance.messagebar.attachmentsHide();
        });
      });
      state.instance.messagebar.$el.find('.choose-file').on('click', () => {
        // this.rootServices.pickAndUploadFile().then(file => {
        //   state.instance.messagebar.attachments.unshift(new FileModel(file) as any);
        //   state.instance.messagebar.renderAttachments();
        //   state.instance.messagebar.attachmentsShow();
        // });
        $this.rootServices.pickAndUploadFiles().subscribe(st => {
          if (st.state == 'STARTUPLOAD') {
            $this.commonService.showPreloader();
          }
          if (st.state == 'UPLOADING') {
            const progress = st.progress;
            console.log('Upload progress: ', progress);
          }
          if (st.state == 'COMPLETE') {
            const images = st.results;
            console.log('upload pictures success', images);
            for (const image of images) {
              state.instance.messagebar.attachments.unshift(new FileModel(image) as any);
            }
            state.instance.messagebar.renderAttachments();
            state.instance.messagebar.attachmentsShow();
            $this.commonService.hidePreloader();
          }
          if (st.state == 'ERROR') {
            console.error(st);
            $this.commonService.hidePreloader();
          }
        });
      });
    }

    state.instance.$('.page-content').scrollTop(0);
  }

  loadComment(comments: CollaboratorOpportunityCommentModel[]) {
    this.currentState.instance.messages.clear();
    // this.currentState.instance.messages.messages = comments.map(comment => this.prepareComment(comment));
    // this.currentState.instance.messages.renderMessages();
    this.currentState.instance.messages.addMessages(comments.map(comment => this.prepareComment(comment)), 'append');
  }

  async onComponentInit(state: ComponentStateExtend, index: string, asCase?: string, f7Page?: F7Page) {
    const $this = this;

    // Start loading status
    state.instance.$setState({
      loading: true,
      schema: this.schema,
    });
    this.commonService.showPreloader();

    // Load Contact Member list
    if (state?.instance?.$route?.context?.chatRoom?.memberList$) {
      await state?.instance?.$route?.context?.chatRoom?.memberList$?.pipe(take(1)).toPromise().then(memberList => {
        state.instance.contactList = memberList.filter(f => f.Type == 'CONTACT').map(m => { m['text'] = m.Name; return m; }) as any;
        if (state.instance.contactList && state.instance.contactList.length > 0) {
          this.schema['Customer'].validators = [];
        } else {
          // this.schema['Customer'].validators = [];
        }
      });

    }

    // Init Messages for comment feature
    this.initComment(state);

    // Get static data
    await this.rootServices.apiService.getPromise<UnitModel[]>('/admin-product/units', { onlyIdText: true, limit: 'nolimit' }).then(rs => {
      state.instance.unitList = rs;
    });
    await this.rootServices.apiService.getPromise<any[]>('/collaborator/pages', { onlyIdText: true, limit: 'nolimit' }).then(rs => {
      state.instance.pageList = rs;
    });
    await this.rootServices.apiService.getPromise<any[]>('/collaborator/opportunities/targets', {}).then(rs => {
      state.instance.targetList = rs;
    });
    // End
    state.instance.$setState({
      unitList: state.instance.unitList,
      contactList: state.instance.contactList,
      processMap: CollaboratorStateMap.opportunityStateMap,
    });

    const currentState: ComponentStateExtend = await super.onComponentInit(state, index, asCase) as any;


    if (currentState.instance.$route?.context?.copyFromId) {
      currentState.instance.data.SubNote = 'Copy of ' + currentState.instance.$route?.context?.copyFromId + ': ' + (currentState.instance.data.SubNote ? ('<br>' + currentState.instance.data.SubNote) : '');
      this.setData(currentState.instance.data);
    }

    if (currentState?.instance?.$route?.context?.relativeVouchers && currentState.instance.$route.context.relativeVouchers[0]?.text) {
      if (!currentState.data.Title) {
        currentState.data.Title = currentState.instance.$route.context.relativeVouchers[0]?.text;
        currentState.instance.$setState({ data: currentState.data });
        this.validate();
      }
    }

    // set static data
    if (!currentState.data?.Page) {
      currentState.data.Page = currentState.instance.pageList[0];
      // currentState.data.Title = 'Debug';
      this.setData(currentState.instance.data);
    }

    // Prepare data
    // if (currentState.data) {
    //   // for (const detail of currentState.data?.Details) {
    //   //   detail.__unitList = detail.Product?.Units || [];
    //   //   detail.ToMoney = detail.Price * detail.Quantity;
    //   // }
    //   // currentState.data.Customer2 = currentState.data.Customer;
    //   // currentState.data.Contact2 = currentState.data.Contact;
    //   await this.setData(currentState.data, { prepareControl: true });
    //   // this.calculateTotal();
    // }
    // End Prepare data

    // Stop loading status
    currentState.instance.$setState({ loading: false });
    this.commonService.hidePreloader();
    return currentState;
  }

  async setData(data: CollaboratorOpportunityModel, option?: { onlyList?: string[]; onlyKeys?: string[]; prepareControl?: boolean; }): Promise<boolean> {
    if (data.State) {
      data.State = this.commonService.getObjectId(data.State);
    }
    if (data?.Details) {
      const productIds = data.Details.filter(f => this.commonService.getObjectId(f.Type) != 'CATEGORY').map(m => `${this.commonService.getObjectId(this.currentState.data['Page'])}-${this.commonService.getObjectId(m.Product)}`);
      const productInfoMap: { [key: string]: ProductModel } = await this.rootServices.apiService.getPromise<ProductModel[]>('/collaborator/page-products', {
        id: productIds,
        includeIdText: true,
        includeProduct: true,
        includeUnit: true,
        includeUnitPrices: true,
        productOfPage: true,
      }).then(rs => rs.reduce((prev, next, i) => {
        prev[this.commonService.getObjectId(next)] = next;
        return prev;
      }, {}));

      // const unitPriceMap = await this.rootServices.apiService.getPromise<SalesMasterPriceTableDetailModel[]>('/sales/master-price-table-details', {
      //   priceTable: 'default',
      //   eq_Code: '[' + productIds.join(',') + ']',
      // }).then(rs => rs.reduce((result, current, index) => { result[current.Code + '-' + this.commonService.getObjectId(current.Unit)] = current.Price; return result; }, {}));

      for (const detail of data.Details) {
        if (!detail.__unitList || detail.__unitList.length == 0) {
          detail.__unitList = productInfoMap[this.commonService.getObjectId(detail.Product)]?.Units.map(m => {
            // m.Price = unitPriceMap[this.commonService.getObjectId(detail.Product) + '-' + this.commonService.getObjectId(m)];
            return m;
          });
          // detail.__unitList = detail.Product?.Units || [];
        }
        // for (const unit of detail.__unitList) {
        //   unit.Price = unitPriceMap[this.commonService.getObjectId(detail.Product) + '-' + this.commonService.getObjectId(unit)];
        // }
        detail.ToMoney = detail.Price * detail.Quantity;
      }
    }

    // data.Customer = data.Customer;
    // data.Publisher = data.Publisher;

    const total = this.calculateTotal(data);
    return super.setData(data, option).then(status => {
      this.currentState.instance.$setState({ total });

      // Update tabs

      return status;
    });
  }

  async addItemForList<CollaboratorOpportunityDetailModel>(listName: string, item?: CollaboratorOpportunityDetailModel): Promise<CollaboratorOpportunityDetailModel> {
    return super.addItemForList(listName, item).then(newItem => {
      this.commonService.showInfo('Đã thêm sản phẩm', { position: 'bottom' });
      return newItem;
    }).then(rs => {
      this.activeItem(this.currentState.data.Details.length - 1);
      this.rootServices.playNewPipSound();
      return rs;
    });
  }

  activeItem(index: number, newData?: CollaboratorOpportunityDetailModel) {

    this.currentState.data.Details.map(detail => {
      detail.__active = false;
    });
    this.currentState.data.Details[index].__active = true;

    const total = this.calculateTotal();
    this.currentState.instance.$setState({ data: this.currentState.data, total });
    // if (newData?.AccessNumbers) {
    //   setTimeout(() => {
    //     this.setFieldValue('smart-select', 'AccessNumbers', newData.AccessNumbers, 'Details', index);
    //   }, 300);
    // }
    setTimeout(() => {
      const itemEl = $(this.currentState.instance.el).find('.list-item.index-' + index);
      // itemEl[0] && itemEl[0].scrollIntoView();
      if (itemEl.length > 0) {
        const pageContentEl = $(this.currentState.instance.el).find('.page-content');
        pageContentEl.animate({ scrollTop: pageContentEl.scrollTop() + itemEl.offset().top - itemEl.height() }, 300);
      }
    }, 300);


  }

  async updateImages(state: (progress) => void, onAfterImageUpload?: (image: FileModel) => void) {

    let proloaderProcessing;
    try {
      const localFiles = await this.rootServices.pickFiles();
      console.debug(localFiles);

      this.commonService.showPreloader();
      proloaderProcessing = setTimeout(() => {
        this.commonService.hidePreloader();
      }, 16000);

      let images: FileModel[] = [];
      for (const i in localFiles) {
        // let image: FileModel = null;
        images[i] = await ((progress) => {
          if (localFiles[i].dataType === 'url') {
            return this.rootServices.uploadLocalFile(localFiles[i].url as string, (event) => {
              progress(event)
            });
          } else {
            const formData = new FormData();
            const imgBlob = new Blob([localFiles[i].url], {
              type: localFiles[i].type
            });
            formData.append('file', imgBlob, 'smart-bot-' + Date.now() + '.' + localFiles[i].ext);
            return this.rootServices.uploadFileData(formData, (event) => {
              progress(event)
            });
          }
        })((progress) => {
          console.log(progress);
          state(progress);
        });
        onAfterImageUpload && onAfterImageUpload(images[i]);
      }
      console.debug(images);
      clearTimeout(proloaderProcessing);
      this.commonService.hidePreloader();
      return images;
    } catch (err) {
      console.error(err);
      this.commonService.showError(err, { position: 'bottom' }); clearTimeout(proloaderProcessing);
      this.commonService.hidePreloader();
      throw new Error(err);
    }
  }

  async onFieldChange(self: F7ComponentContext, e: any, ...args: any) {
    const currentState = this.currentState;
    return super.onFieldChange(self, e, args).then(async fieldInfo => {

      if (!fieldInfo) {
        return null;
      }

      let { field, fieldName, fieldValue, index, listName, previousValue: previousUnit } = fieldInfo;
      let requireSetData = false;

      console.log('on field change', listName, index, fieldName, fieldValue);
      if (listName == 'Details' && fieldName == 'Quantity') {

        currentState.data[listName][index]['ToMoney'] = (currentState.data[listName][index]['Quantity'] || 0) * (currentState.data[listName][index]['Price'] || 0);
        // currentState.instance.$setState({ data: currentState.data });
        this.rootServices.playDecreasePipSound();
        requireSetData = true;
      }

      if (listName == 'Details' && fieldName == 'Price') {

        currentState.data[listName][index]['ToMoney'] = (currentState.data[listName][index]['Quantity'] || 0) * (currentState.data[listName][index]['Price'] || 0);
        currentState.instance.$setState({ data: currentState.data });
        // $(currentState.instance.el).find('.list-name[name="' + listName + '"] .index-' + index + ' [name="ToMoney"]').val(currentState.data[listName][index]['ToMoney']);
      }
      if (listName == 'Details' && fieldName == 'ToMoney') {

        if (currentState.data[listName][index]['Quantity'] > 0) {
          currentState.data[listName][index]['Price'] = (currentState.data[listName][index]['ToMoney'] || 0) / currentState.data[listName][index]['Quantity'];
        }
        // currentState.instance.$setState({ data: currentState.data });
        requireSetData = true;
        // $(currentState.instance.el).find('.list-name[name="' + listName + '"] .index-' + index + ' [name="Price"]').val(currentState.data[listName][index]['Price']);
      }

      if ((fieldName == 'Customer') && fieldValue && !currentState.instance.loading) {
        await this.rootServices.apiService.getPromise<ContactModel[]>('/contact/contacts/' + this.commonService.getObjectId(fieldValue), {}).then(contacts => contacts[0]).then(contact => {

          // Auto fill object
          currentState.data.Customer = fieldValue;
          currentState.data.CustomerName = contact.Name;
          currentState.data.CustomerPhone = contact.Phone;
          currentState.data.CustomerEmail = contact.Email;
          currentState.data.CustomerAddress = contact.Address;

          // currentState.data.DeliveryProvince = contact.Province;
          // currentState.data.DeliveryDistrict = contact.District;
          // currentState.data.DeliveryWard = contact.Ward;
          // currentState.data.DeliveryAddress = contact.Address;

          // currentState.instance.$setState({
          //   data: currentState.data,
          // });
          requireSetData = true;
          this.validate();
        });
      }
      if ((fieldName == 'DirectReceiver') && fieldValue && !currentState.instance.loading) {
        await this.rootServices.apiService.getPromise<ContactModel[]>('/contact/contacts/' + this.commonService.getObjectId(fieldValue), {}).then(contacts => contacts[0]).then(contact => {

          // Auto fill object
          currentState.data.DirectReceiver = fieldValue;
          currentState.data.DirectReceiverName = contact.Name;
          currentState.data.DirectReceiverPhone = contact.Phone;
          currentState.data.DirectReceiverEmail = contact.Email;

          currentState.data.DeliveryProvince = contact.Province;
          currentState.data.DeliveryDistrict = contact.District;
          currentState.data.DeliveryWard = contact.Ward;
          currentState.data.DeliveryAddress = contact.Address;

          // currentState.instance.$setState({
          //   data: currentState.data,
          // });
          requireSetData = true;
          this.validate();
        });
      }
      if ((fieldName == 'Publisher') && fieldValue && !currentState.instance.loading) {
        await this.rootServices.apiService.getPromise<ContactModel[]>('/contact/contacts/' + this.commonService.getObjectId(fieldValue), {}).then(contacts => contacts[0]).then(contact => {

          // Auto fill object
          currentState.data.Publisher = fieldValue;
          currentState.data.PublisherName = contact.Name;
          currentState.data.PublisherPhone = contact.Phone;
          currentState.data.PublisherEmail = contact.Email;
          currentState.data.PublisherAddress = contact.Address;

          // currentState.instance.$setState({
          //   data: currentState.data,
          // });

          this.validate();
          requireSetData = true;
        });
      }

      if (listName == 'Details' && fieldName == 'Unit') {
        // let price = currentState.data[listName][index]?.Price;
        const detail = currentState.data[listName][index];
        previousUnit = detail.__unitList.find(f => this.commonService.getObjectId(f) == this.commonService.getObjectId(previousUnit));
        if (previousUnit && this.commonService.getObjectId(previousUnit) != this.commonService.getObjectId(detail.Unit)) {
          const nextUnit = detail.__unitList.find(f => this.commonService.getObjectId(f) == this.commonService.getObjectId(fieldValue));
          if (previousUnit.ConversionRatio && nextUnit.ConversionRatio) {
            detail['Quantity'] = detail['Quantity'] * previousUnit.ConversionRatio;
            // detail['Price'] = detail['Price'] / previousUnit.ConversionRatio;
            detail['Quantity'] = detail['Quantity'] / nextUnit.ConversionRatio;
          }
          detail['Price'] = nextUnit.Price;
          detail['ToMoney'] = detail['Price'] * detail['Quantity'];

          let business = [];
          const product = detail.Product;
          // if (product) {
          //   if (this.commonService.getObjectId(detail.Type) == 'PRODUCT') {
          //     const unit = detail.__unitList.find(f => this.commonService.getObjectId(f) == this.commonService.getObjectId(detail.Unit)) || null;
          //     if (unit) {
          //       if (unit.IsAutoAdjustInventory === false) {
          //         business = [{
          //           id: 'PURCHASESKIPWAREHOUSE',
          //           text: 'Mua hàng nhưng không nhập kho (công nợ)',
          //         }];
          //       }
          //       if (unit.IsAutoAdjustInventory === true) {
          //         business = [{
          //           id: 'PURCHASEWAREHOUSE',
          //           text: 'Mua hàng sau đó nhập kho (công nợ)',
          //         }];
          //       }
          //     }
          //   }
          //   if (this.commonService.getObjectId(detail.Type) == 'SERVICE') {
          //     business = [{
          //       id: 'PURCHASECOST',
          //       text: 'Chi phí phát sinh khi mua hàng: vận chuyển, xăng dầu,... (công nợ)',
          //     }];
          //   }
          //   detail['Business'] = business;
          // }
        }
      }

      if (requireSetData) {
        const total = this.calculateTotal();
        console.log('collaborator form set data: ', currentState.data);
        currentState.instance.$setState({
          data: currentState.data,
          total: total,
        });
      } else {
        currentState.instance.$setState({ total: this.calculateTotal() });
      }

      return fieldInfo;
    });
  }

  async removeDetailItem(self: F7ComponentContext, e: any, ...args: any): Promise<{ detail?: any, listName?: string; index?: string | number; }> {
    const currentState = this.currentState;
    return new Promise((resolve, reject) => {
      self.$app.dialog.confirm('Bạn có chắc là muốn gở chi tiết này ?', 'Gở chi tiết', () => {
        resolve(super.removeDetailItem(self, e, args).then(detailInfo => {
          let { detail, listName, index } = detailInfo;
          if (listName == 'UnitConversions') {
            self.$app.accordion.close(detail[0]);
          }
          this.calculateTotal();
          this.rootServices.playDecreasePipSound();
          return detailInfo;
        }).catch(err => {
          return Promise.reject(err);
        }));
      });
    });
  }


  async chooseProduct(self: F7ComponentContextExtend) {
    // const self: F7ComponentContextExtend = this;
    const $this = this;
    const currentState = this.currentState;
    this.rootServices.navigate('/collaborator/product-list/choose', {
      context: {
        index: 'choosecollaboratorproduct',
        query: {
          onlyBusinessProducts: false,
          includeUnits: true,
          includePrice: true,
        },
        page: $this.commonService.getObjectId($this.currentState.data['Page']),
        backTitle: this.title,
        textColorClass: self.textColorClass,
        async onChoose(product: ProductModel, unitId?: string) {
          currentState.instance.$router.back();
          console.log('Choosed product', product);

          // const productInfo = (await $this.rootServices.apiService.getPromise<ProductModel[]>('/admin-product/products/' + product.Code, { includeIdText: true, includeUnits: true }))[0];
          product.id = product.Code;
          product.text = product.Name;
          // const unit = product.UnitConversions && product.UnitConversions.find(f => f.IsDefaultPurchase) || product.UnitConversions[0]
          // const unit = product.UnitConversions && product.UnitConversions.length == 1 && product.UnitConversions[0] || null;
          // const unit = product.UnitList.find(f => f.id == $this.commonService.getObjectId(product.Unit)) || product.UnitList[0] || product.Unit || null;

          // Load price
          // const unitPriceMap = await $this.rootServices.apiService.getPromise<SalesMasterPriceTableDetailModel[]>('/sales/master-price-table-details', {
          //   priceTable: 'default',
          //   eq_Code: product.Code,
          // }).then(rs => rs.reduce((result, current, index) => {
          //   result[current.Code + '-' + $this.commonService.getObjectId(current.Unit)] = current.Price;
          //   return result;
          // }, {}));

          // for (const unit of product.Units) {
          //   unit.Price = unitPriceMap[product.Code + '-' + $this.commonService.getObjectId(unit)];
          // }


          let unit = null;
          if (unitId) {
            unit = product.Units.find(f => $this.commonService.getObjectId(f) == unitId);
          } else {
            unit = product.Units.find(f => f.IsDefaultSales) || product.Units[0] || null;
          }

          const index = currentState.data.Details.findIndex(f => $this.commonService.getObjectId(f.Product) == product.id && $this.commonService.getObjectId(f.Unit) == $this.commonService.getObjectId(unit));
          if (index > -1) {
            let item = currentState.data['Details'][index];
            item.Quantity++;
            $this.updateItemForList('Details', index, item);
            $this.commonService.showInfo('Tăng số lượng lên ' + item.Quantity + ' cho sản phẩm ' + $this.commonService.getObjectText(product) + ' !', { position: 'bottom' });
            return;
          }

          let business = [];
          if ($this.commonService.getObjectId(product.Type) == 'PRODUCT') {
            if (unit) {
              if (unit.IsAutoAdjustInventory === false) {
                business = [{
                  id: 'PURCHASESKIPWAREHOUSE',
                  text: 'Mua hàng nhưng không nhập kho (công nợ)',
                }];
              }
              if (unit.IsAutoAdjustInventory === true) {
                business = [{
                  id: 'PURCHASEWAREHOUSE',
                  text: 'Mua hàng sau đó nhập kho (công nợ)',
                }];
              }
            }
          }
          if ($this.commonService.getObjectId(product.Type) == 'SERVICE') {
            business = [{
              id: 'PURCHASECOST',
              text: 'Chi phí phát sinh khi mua hàng: vận chuyển, xăng dầu,... (công nợ)',
            }];
          }

          $this.addItemForList('Details', {
            ...new CollaboratorOpportunityDetailModel(),
            Type: product.Type || 'PRODUCT',
            // Business: business,
            Product: {
              id: product.id,
              text: product.text,
              Name: product.ProductName,
              Units: product.Units,
            },
            Description: product.Name,
            Unit: unit,
            Price: unit.Price || 0,
            Quantity: 1,
            // Tax: 'NOTAX',
            __unitList: product.Units,
            Image: [
              ...[product.FeaturePicture],
              ...(Array.isArray(product.Pictures) && product.Pictures || []).filter(f => f.Id != product?.FeaturePicture?.Id)
            ]
          });

        },
      }
    });
  }

  calculateTotal(data?: CollaboratorOpportunityModel) {
    const currentState = this.currentState;
    let total = 0;
    const calculateData = data || currentState.data;
    for (const detail of calculateData.Details) {
      const toMoney = detail.ToMoney = (detail.Quantity || 0) * (detail.Price || 0);
      total += toMoney;
    }

    // currentState.instance.$setState({ total });
    return total;
  }

  async save(self: F7ComponentContext, option?: { postParams?: any, updateProperties?: string[] }) {
    const $this = this;
    if (!option) option = {};
    if (!option.postParams) option.postParams = {};
    option.postParams.page = $this.commonService.getObjectId($this.currentState.data.Page);

    // Do not update relative vouchers
    // delete this.currentState.data.RelativeVouchers;
    if (!/^new\-?/.test(this.currentState.instance?.$route?.params?.id)) {
      delete this.currentState.data.RelativeVouchers;
    }

    return super.save(self, option).then(rs => {
      this.currentState.data.State = rs.State;
      self.$setState({ data: this.currentState.data });
      return rs;
    });
  }

  async savePdf(self: F7ComponentContextExtend, e: any) {
    if (this.currentState.data.State !== 'APPROVED') {
      await this.save(self);
    }
    this.rootServices.iab.create(this.rootServices.apiService.buildApiUrl(this.apiPath, { id: this.currentState.data.Code, includeContact: true, includeDetails: true, includeUnit: true, renderPdf: 'download' }) as string, '_system');
  }

  async createPdfQuatation(self: F7ComponentContextExtend, e: any) {
    if (this.currentState.data.State !== 'APPROVED') {
      await this.save(self);
    }
    this.rootServices.iab.create(this.rootServices.apiService.buildApiUrl(this.apiPath, { id: this.currentState.data.Code, includeContact: true, includeDetails: true, includeUnit: true, renderPdfQuotation: 'download' }) as string, '_system');
  }

  async changeState(self: F7ComponentContextExtend, e: any) {
    // const newState = this.currentState.data.State == 'APPROVED' ? 'NOTJUSTAPPROVED' : 'APPROVED';
    const $this = this
    const currentState = CollaboratorStateMap.opportunityStateMap[this.currentState.data.State];
    if (currentState && currentState.nextStates) {
      const supplierDialog = this.currentState.instance.$app.dialog.create({
        cssClass: 'dialog-large',
        title: 'Chuyển trạng thái',
        text: 'Phiếu đang ở trạng thái `' + currentState.label + '`, bạn có muốn chuyển sang các trạng thái bên dưới ?',
        buttons: [
          ...currentState.nextStates.map((m, index) => ({
            text: (index + 1) + '. ' + m.confirmLabel,
            color: 'success',
            onClick: () => {
              this.rootServices.apiService.putPromise(this.apiPath + '/' + this.currentState.data.Code, { changeState: m.state }, [{ Code: this.currentState.data.Code }]).then(async rs => {
                this.currentState.data.State = m.state;
                this.setData(this.currentState.data);

                this.currentState.lastAction = 'UPDATE_SUCCESS';

                if (m.state == 'CONVERTED') {
                  const newData = await $this.refresh();
                  if (newData.RelativeVouchers && newData.RelativeVouchers.length > 0) {
                    const relativeOrders = newData.RelativeVouchers.filter(f => $this.commonService.getObjectId(f.type) == 'CLBRTORDER');
                    if (relativeOrders.length > 0) {
                      $this.rootServices.navigate('/collaborator/order/' + this.commonService.getObjectId(relativeOrders[0]), {
                        context: {
                          // copyFromId: item.Code,
                          backTitle: $this.title,
                        }
                      });
                    } else {
                      const relativeContracts = newData.RelativeVouchers.filter(f => $this.commonService.getObjectId(f.type) == 'CONTRACT');
                      if (relativeContracts.length > 0) {
                        $this.commonService.showInfo('Đã chuyển đổi thành hợp đồng có mã ' + this.commonService.getObjectId(relativeContracts[0]), { position: 'center' });
                      }
                    }
                  }
                }
              }).catch(err => {
                this.commonService.showError(err);
              });
            }
          })),
          {
            text: 'Trở về',
            bold: true,
            color: 'red'
          },
        ],
        verticalButtons: true,
      });
      supplierDialog.open();
    }

    // this.currentState.instance.$app.dialog.confirm('Bạn có muốn ' + (newState == 'APPROVED' ? 'duyệt' : 'bỏ duyệt') + ' phiếu báo giá `' + this.currentState.data.Title + '`', 'Thay đổi trạng thái báo giá', async () => {

    //   if (this.currentState.data.State !== 'APPROVED') {
    //     await this.save(self);
    //   }
    //   await this.rootServices.apiService.putPromise<CollaboratorOpportunityModel>(this.apiPath, {
    //     changeState: newState,
    //   }, [{ Code: this.currentState.data.Code }]).then(rs => {
    //     if (newState == 'APPROVED') {
    //       this.commonService.showInfo('Đã duyệt phiếu đặt mua hàng !', { position: 'bottom' });
    //     } else {
    //       this.commonService.showInfo('Đã bỏ duyệt phiếu đặt mua hàng !', { position: 'bottom' });
    //     }
    //   });
    //   this.currentState.data.State = newState;
    //   this.setData(this.currentState.data);

    // });
  }
  async convertToOrder(self: F7ComponentContextExtend, e: any) {
    // const newState = this.currentState.data.State == 'APPROVED' ? 'NOTJUSTAPPROVED' : 'APPROVED';
    const $this = this;
    const currentVoucherState = CollaboratorStateMap.opportunityStateMap[this.currentState.data.State];
    if (currentVoucherState && currentVoucherState.nextStates) {
      const supplierDialog = this.currentState.instance.$app.dialog.create({
        cssClass: 'dialog-large',
        title: 'Chuyển đổi cơ hội',
        text: 'Bạn có muốn chuyển đổi cơ hội `' + this.currentState.data.Title + '`, sang đơn hàng ?',
        buttons: [
          ...currentVoucherState.nextStates.map((m, index) => ({
            text: 'Chuyển đổi',
            color: 'success',
            onClick: () => {

              $this.rootServices.navigate('/collaborator/order/new', {
                context: {
                  // copyFromId: item.Code,
                  backTitle: this.title,
                  data: {
                    ...$this.currentState.data,
                    Code: null,
                    SystemUuid: null,
                    Object: $this.currentState.data.Customer,
                    ObjectName: $this.currentState.data.CustomerName,
                    ObjectPhone: $this.currentState.data.CustomerPhone,
                    ObjectEmail: $this.currentState.data.CustomerEmail,
                    ObjectAddress: $this.currentState.data.CustomerAddress,
                    Details: $this.currentState.data.Details.map(detail => ({
                      ...detail,
                      Id: null,
                      SystemUuid: null,
                      RelateDetail: `CLBRTOPPORTUNITY/${$this.currentState.data.Code}/${detail.SystemUuid}`,
                    })),
                    RelativeVouchers: [{
                      id: $this.currentState.data.Code,
                      text: $this.currentState.data.Title,
                      type: 'CLBRTOPPORTUNITY',
                    }],
                  },
                  callback: (data: CollaboratorOrderModel, state: any) => {
                    console.log('Callback data: ', data);
                    if (state?.lastAction == 'CREATE_SUCCESS') {
                      this.refresh();
                    }
                  }
                }
              });
            }
          })),
          {
            text: 'Trở về',
            bold: true,
            color: 'red'
          },
        ],
        verticalButtons: true,
      });
      supplierDialog.open();
    }
  }



  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: 'collaborator-opportunity-form',
      path: '/collaborator/opportunity/:id',
      component: {
        template: /*html*/`
            <div class="page page-form collaborator-opportunity-form no-toolbar-x" data-name="collaborator-opportunity-form">
              <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a class="link back {{textColorClass}}">
                      <i class="icon icon-back"></i>
                      <span class="if-not-md {{textColorClass}}">{{backTitle}}</span>
                    </a>
                  </div>
                  <div class="title">Cơ hội</div>
                  <div class="right">
                    <a class="link icon-only {{textColorClass}}">
                      <i class="icon f7-icons">arrowshape_turn_up_right</i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="page-content ptr-content infinite-scroll-content" @ptr:refresh="refresh" data-ptr-distance="150">
                <div class="ptr-preloader">
                  <div class="preloader"></div>
                  <div class="ptr-arrow"></div>
                </div>

                <form class="data-form">
                  <div class="list profile-form no-hairlines-md inset main-form">
                    <ul>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label">ID phiếu (*)</div>
                          <div class="item-input-wrap">
                            <input @change="onFieldChange" disabled class="field text-color-blue" name="Code" type="text" placeholder="ID phiếu, auto..."
                              value="{{data.Code}}">
                          </div>
                        </div>
                      </li>
                      {{#if copyFromId}}
                      <li class="item-content">
                        <div class="item-inner">
                          <div class="item-input-wrap text-color-orange">
                            <span style="font-weight: bold; font-style: italic">Bạn đang thao tác trên phiếu copy</span>
                          </div>
                        </div>
                      </li>
                      {{/if}}
                      <li class="item-link smart-select smart-select-init smart-select-roles {{validate data.Page schema 'Page'}}" name="Page" data-open-in="popup" data-searchbar="true" data-virtual-list="true" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFieldChange">
                        <select>
                          <option value="">Chon...</option>
                          {{#each pageList}}
                          <option value="{{id}}">{{text}}</option>
                          {{/each}}
                        </select>
                        <div class="item-content">
                          <div class="item-inner">
                            <div class="item-title">Trang</div>
                            <div class="item-after text-color-blue"></div>
                          </div>
                        </div>
                      </li>
                      <li class="item-link smart-select smart-select-init smart-select-roles {{validate data.Target schema 'Target'}}" name="Target" data-open-in="popup" data-searchbar="true" data-virtual-list="true" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFieldChange">
                        <select>
                          <option value="">Chon...</option>
                          {{#each targetList}}
                          <option value="{{id}}">{{text}}</option>
                          {{/each}}
                        </select>
                        <div class="item-content">
                          <div class="item-inner">
                            <div class="item-title">Mục tiêu</div>
                            <div class="item-after text-color-blue"></div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div class="block-title">Thông tin khách hàng <a style="float: right" @click="createNewCustomer" class="{{textColorClass}}">+ mới</a></div>
                  <div class="list profile-form no-hairlines-md inset main-form">
                    <ul>
                      <li class="autocomplete" name="Customer">
                        <div class="item-link item-content" href="#">
                          <div class="item-inner">
                            <div class="item-title">Khách hàng</div>
                            <div class="item-after text-color-blue {{validate data.Customer schema 'Customer'}}">{{objecttext this 'data.Customer' 'Khách hàng (*)...'}}</div>
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label {{validate data.CustomerName schema 'CustomerName'}}">Tên (*)</div>
                          <div class="item-input-wrap">
                            <input disabled @keyup="onFieldChange" class="field text-color-bluex" name="CustomerName" type="text" placeholder="Tên khách hàng..."
                              value="{{data.CustomerName}}">
                            
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label">Số điện thoại (*)</div>
                          <div class="item-input-wrap">
                            <input disabled @keyup="onFieldChange" class="field text-color-bluex" name="CustomerPhone" type="text" placeholder="SĐT khách hàng..."
                              value="{{data.CustomerPhone}}">
                            
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label">Email (*)</div>
                          <div class="item-input-wrap">
                            <input disabled @keyup="onFieldChange" class="field text-color-bluex" name="CustomerEmail" type="text" placeholder="Email khách hàng..."
                              value="{{data.CustomerEmail}}">
                            
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label">Địa chỉ (*)</div>
                          <div class="item-input-wrap">
                            <input disabled @keyup="onFieldChange" class="field text-color-bluex" name="CustomerAddress" type="text" placeholder="ĐC khách hàng..."
                              value="{{data.CustomerAddress}}">
                            
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div class="block-title">Thông tin CTV</div>
                  <div class="list profile-form no-hairlines-md inset main-form">
                    <ul>
                      <li class="autocomplete" name="Publisher">
                        <div class="item-link item-content" href="#">
                          <div class="item-inner">
                            <div class="item-title">CTV</div>
                            <div class="item-after text-color-blue">{{objecttext this 'data.Publisher' 'CTV...'}}</div>
                          </div>
                        </div>
                      </li>

                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label">Tên</div>
                          <div class="item-input-wrap">
                            <input disabled @change="onFieldChange" class="field text-color-bluex" name="PublisherName" type="text" placeholder="Tên CTV..."
                              value="{{data.PublisherName}}">
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label">Số điện thoại</div>
                          <div class="item-input-wrap">
                            <input disabled @change="onFieldChange" class="field text-color-bluex" name="PublisherPhone" type="text" placeholder="SĐT CTV..."
                              value="{{data.PublisherPhone}}">
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label">Email</div>
                          <div class="item-input-wrap">
                            <input disabled @change="onFieldChange" class="field text-color-bluex" name="PublisherEmail" type="text" placeholder="Email CTV..."
                              value="{{data.PublisherEmail}}">
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label">Địa chỉ</div>
                          <div class="item-input-wrap">
                            <input disabled @change="onFieldChange" class="field text-color-bluex" name="PublisherAddress" type="text" placeholder="Địa chỉ CTV..."
                              value="{{data.PublisherAddress}}">
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  
                  <div class="block-title">Thông tin nhận hàng</div>
                  <div class="list profile-form no-hairlines-md inset main-form">
                    <ul>
                    <li class="autocomplete" name="DirectReceiver">
                      <div class="item-link item-content" href="#">
                        <div class="item-inner">
                          <div class="item-title">Người nhận hàng trực tiếp</div>
                          <div class="item-after text-color-blue {{validate data.DirectReceiver schema 'DirectReceiver'}}">{{text data.DirectReceiver coalesce="Chọn..."}}</div>
                        </div>
                      </div>
                    </li>
                    <li class="item-content item-input">
                      <div class="item-inner">
                        <div class="item-title item-label {{validate data.DirectReceiverName schema 'DirectReceiverName'}}">Tên</div>
                        <div class="item-input-wrap">
                          <input disabled @keyup="onFieldChange" class="field text-color-bluex" name="DirectReceiverName" type="text" placeholder="Tên..."
                            value="{{data.DirectReceiverName}}">
                          
                        </div>
                      </div>
                    </li>
                    <li class="item-content item-input">
                      <div class="item-inner">
                        <div class="item-title item-label">Số điện thoại</div>
                        <div class="item-input-wrap">
                          <input disabled @keyup="onFieldChange" class="field text-color-bluex" name="DirectReceiverPhone" type="text" placeholder="Số điện thoại..."
                            value="{{data.DirectReceiverPhone}}">
                          
                        </div>
                      </div>
                    </li>
                    <li class="item-content item-input">
                      <div class="item-inner">
                        <div class="item-title item-label">Email</div>
                        <div class="item-input-wrap">
                          <input disabled @keyup="onFieldChange" class="field text-color-bluex" name="DirectReceiverEmail" type="text" placeholder="Email..."
                            value="{{data.DirectReceiverEmail}}">
                          
                        </div>
                      </div>
                    </li>
                      <li class="autocomplete" name="DeliveryProvince">
                        <div class="item-link item-content" href="#">
                          <div class="item-inner">
                            <div class="item-title">Tỉnh/TP (*)</div>
                            <div class="item-after text-color-blue {{validate data.DeliveryProvince schema 'DeliveryProvince'}}">{{objecttext this "data.DeliveryProvince" "Tỉnh/TP..."}}</div>
                          </div>
                        </div>
                      </li>
                      <li class="autocomplete" name="DeliveryDistrict">
                        <div class="item-link item-content" href="#">
                          <div class="item-inner">
                            <div class="item-title">Quận/Huyện</div>
                            <div class="item-after text-color-blue {{validate data.DeliveryDistrict schema 'DeliveryDistrict'}}">{{objecttext this "data.DeliveryDistrict" "Quận/Huyện..."}}</div>
                          </div>
                        </div>
                      </li>
                      <li class="autocomplete" name="DeliveryWard">
                        <div class="item-link item-content" href="#">
                          <div class="item-inner">
                            <div class="item-title">Phường/Xã</div>
                            <div class="item-after text-color-blue {{validate data.DeliveryWard schema 'DeliveryWard'}}">{{objecttext this "data.DeliveryWard" "Phường/Xã..."}}</div>
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label">Địa chỉ</div>
                          <div class="item-input-wrap">
                            <input @keyup="onFieldChange" class="field text-color-blue" name="DeliveryAddress" type="text" placeholder="Số nhà tên đường..."
                              value="{{data.DeliveryAddress}}">
                            
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div class="block-title">Thông tin chung</div>
                  <div class="list profile-form no-hairlines-md inset main-form">
                    <ul>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label {{validate data.Title schema 'Title'}}">Tiêu đề (*)</div>
                          <div class="item-input-wrap">
                            <input @keyup="onFieldChange" class="field text-color-blue" name="Title" type="text" placeholder="Tiêu đề..."
                              value="{{data.Title}}">
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div class="block-title" style="margin-top: 0.5rem">Chi tiết</div>
                  <div class="list-name thumbnail-list" name="Details">
                    {{#each data.Details}}
                      <div class="block block-strong inset list-item index-{{@index}} {{js "this.__hasWarning ? 'bg-color-orange' : (this.__active ? 'bg-color-red text-color-white' : '')"}}" data-id="{{Uuid}}" data-index="{{@index}}">
                        <div class="item-content">
                          <div class="detail-wrap">
                            <div class="detail-header" >
                              <div class="header-label">{{js "this.Description || this.Product && this.Product.Name"}}</div>
                              <div class="detail-remove" @click="removeDetailItem"><i class="icon f7-icons {{js "this.__active ? 'color-white' : 'color-red'"}}">xmark_circle_fill</i></div>
                            </div>
                            <div class="detail-body">
                              <div class="image-wrap">
                                <div class="image"  @click="previewDetailAcctachment" data-index="0" style="{{js "this.Image && this.Image[0] && this.Image[0].Thumbnail && ('background-image: url('+this.Image[0].Thumbnail+')') || ''"}}"></div>
                              </div>
                              <div class="detail-content-wrap">
                                <div class="list inline-labels no-hairlines">
                                  <ul style="background: none">
                                    <li class="item-content item-input">
                                      <div class="item-inner" dtyle="min-height: 2rem; padding-top: 0; padding-bottom: 0">
                                        <div class="item-title item-label">SL</div>
                                        <div class="item-input-wrap">
                                          <div class="stepper stepper-fill stepper-init" data-manual-input-mode="true" data-autorepeat="true" data-autorepeat-dynamic="true" name="Quantity" @stepper:change="onFieldChange">
                                            <div class="stepper-button-minus"></div>
                                            <div class="stepper-input-wrap">
                                              <input type="text" value="{{Quantity}}" min="0" max="999999999999999999999" step="1" inputmode="decimal" class="{{js "this.__active ? 'text-color-white' : ''"}}" name="Quantity" @keyup="onFieldChange">
                                            </div>
                                            <div class="stepper-button-plus"></div>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li class="item-link smart-select smart-select-init" name="Unit" data-open-in="popup" data-searchbar="true" data-virtual-list="true" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFieldChange" data-list-name="UnitConversions" data-index="{{@index}}">
                                      <select>
                                        <option value="">Chon...</option>
                                        {{#js_if "this.__unitList && this.__unitList.length > 0"}}
                                          {{#each __unitList}}
                                          <option value="{{id}}">{{text}}</option>
                                          {{/each}}
                                        {{else}}
                                          {{#each ../../unitList}}
                                          <option value="{{id}}">{{text}}</option>
                                          {{/each}}
                                        {{/js_if}}
                                      </select>
                                      <div class="item-content">
                                        <div class="item-inner">
                                          <div class="item-title {{validate Unit ../schema 'Details.Unit'}}">ĐVT (*)</div>
                                          <div class="item-after {{js "this.__active ? 'text-color-white' : 'text-color-blue'"}}"></div>
                                        </div>
                                      </div>
                                    </li>
                                    <li class="item-content item-input">
                                      <div class="item-inner">
                                        <div class="item-title item-label">Giá</div>
                                        <div class="item-input-wrap">
                                          <input disabled type="text" class="inputmask field {{js "this.__active ? 'text-color-white' : 'text-color-blue'"}}" name="Price" value="{{decimal Price}}" placeholder="Đơn giá" @keyup="onFieldChange">
                                          <div class="currency-symbol {{js "this.__active ? 'text-color-white' : 'text-color-blue'"}}">₫</div>
                                        </div>
                                      </div>
                                    </li>
                                    <li class="item-content item-input">
                                      <div class="item-inner">
                                        <div class="item-title item-label">T.Tiền</div>
                                        <div class="item-input-wrap">
                                          <input disabled type="text" class="inputmask field {{js "this.__active ? 'text-color-white' : 'text-color-blue'"}}" name="ToMoney" style="font-weight: bold;" value="{{decimal ToMoney}}" placeholder="Thành tiền" @keyup="onFieldChange">
                                          <div style="font-weight: bold" class="currency-symbol {{js "this.__active ? 'text-color-white' : 'text-color-blue'"}}">₫</div>
                                        </div>
                                      </div>
                                    </li>
                                    <li class="item-content item-input">
                                      <div class="item-inner">
                                        <div class="item-title item-label">Liên quan CTV</div>
                                        <div class="item-input-wrap" style="flex-direction: row-reverse;">
                                          <input type="checkbox" class="inputmask field {{js "this.__active ? 'text-color-white' : 'text-color-blue'"}}" name="IsRelativeToPublisher" style="font-weight: bold; width: 1rem;" value="1"  @change="onFieldChange">
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div class="detail-footer">SKU: {{js "this.Product && this.Product.Sku"}}, ID: {{js "this.Product && this.Product.Code"}}</div>
                          </div>
                        </div>
                      </div>
                      {{/each}}
                  </div>
              
                  <div class="block block-strong inset" style="text-align: right; font-weight: bold; font-size: 1rem;">
                    Tổng cộng: <span class="text-color-blue">{{currency total}}</span>
                  </div>

                  <div class="block">
                    <a class="col button  button-large button-outline link color-orange" style="width: 100%" @click="chooseProduct">
                    <i class="icon f7-icons if-not-md-x">cart</i> Chọn hàng hóa/dịch vụ</a>
                  </div>

                  <div class="block">
                    <p class="row">
                      <a class="col-50 button button-fill button-large color-blue {{#if validates}}disabled{{/if}}"  @click="save"> Lưu lại</a>
                      <a class="col-50 button button-fill button-large color-red  {{#if validates}}disabled{{/if}}" @click="savePdf">Save PDF</a>
                    </p>
                    <p class="row">
                      <a class="col-50 button button-fill button-large color-{{js "this.data.State && this.processMap && this.processMap[this.data.State] && this.processMap[this.data.State].color"}}  {{#if validates}}disabled{{/if}}" @click="changeState">{{js "this.data.State && this.processMap && this.processMap[this.data.State] && this.processMap[this.data.State].label"}}</a>
                      <a class="col-50 button button-fill button-large color-yellow  {{#if validates}}disabled{{/if}}" @click="openRelativeTask">Mở task</a>
                    </p>
                    <p class="row">
                      <a class="col-100 button button-fill button-large color-green  {{#if validates}}disabled{{/if}}" @click="createPdfQuatation">Tạo báo giá PDF</a>
                    </p>
                    <!--<p class="row">
                      <a class="col-100 button button-fill button-large color-red  {{#if validates}}disabled{{/if}}" @click="convertToOrder">Chuyển đổi</a>
                    </p>-->
                  </div>

                  <div class="block-title">Ghi chú</div>
                  <div class="list profile-form no-hairlines-md main-form">
                    <div class="text-editor text-editor-init Description" name="Note" data-placeholder="Ghi chú..." @texteditor:change="onFieldChange">
                      <div class="text-editor-content" contenteditable></div>
                    </div>
                  </div>

                  <div class="block-title">Ghi chú nội bộ</div>
                  <div class="list profile-form no-hairlines-md main-form">
                    <div class="text-editor text-editor-init Description" name="SubNote" data-placeholder="Ghi chú nội bộ (không xuất hiện khi in)..." @texteditor:change="onFieldChange">
                      <div class="text-editor-content" contenteditable></div>
                    </div>
                  </div>

                  <div class="block-title">Chứng từ liên quan</div>
                  <div class="list media-list inset activity-list">
                    <ul>
                      {{#each data.RelativeVouchers}}
                      <li class="swipeout" data-id="{{id}}" data-type="{{type}}">
                        <a class="item-content item-link swipeout-content">
                          <div class="item-inner">
                            <div class="item-title-row">
                              <div class="item-title">{{type.symbol}}: {{text}}</div>
                              <div class="item-after; text-color-blue" style="font-size: 0.8rem"><span class="badge color-blue unread nowrap">{{typeLabel}}</span></div>
                            </div>
                            <div class="item-subtitle">Ngày: {{date date}}</div>
                            <div class="item-text">ID: {{id}}</div>
                          </div>
                        </a>
                        <div class="swipeout-actions-right">
                          <a href="#" class="color-green swipeout-overswipe">Duyệt</a>
                        </div>
                      </li>
                      {{/each}}
                    </ul>
                  </div>

                  {{#if validates}}
                  <div class="block-title text-color-arange">Cảnh báo</div>
                  <div class="list inset">
                    <ul>
                      {{#each validates}}
                      <li>
                        <div class="item-content">
                          <div class="item-inner">
                            <div class="item-title">{{validates[0].label}}</div>
                            <div class="item-after text-color-orange">{{validates[0].text}}</div>
                          </div>
                        </div>
                      </li>
                      {{/each}}
                    </ul>
                  </div>
                  {{/if}}
                  
                  <div class="block-title">Bình luận</div>
                  <div class="block block-strong inset">
                    <div class="messages"></div>
                    <div class="messagebar toolbar">
                      <div class="toolbar-inner">
                        <a class="link toggle-sheet choose-file" href="#">
                          <i class="icon f7-icons if-not-md">camera_fill</i>
                          <i class="icon material-icons md-only">camera_alt</i>
                        </a>
                        <div class="messagebar-area">
                          <textarea placeholder="Message"></textarea>
                        </div>
                        <a class="link" href="#">Post</a>
                      </div>
                      <div class="messagebar-sheet"></div>
                    </div>
                  </div>
                </form>
            
                <div class="block"><br></div>
              </div>
            </div>
        `,
        style: /*css*/`
          .page.collaborator-opportunity-form .demo-card-header-pic .card-header {
            height: calc(100vw - 2rem);
            background-size: cover;
            background-position: center;
            color: #fff;
          }

          .page.collaborator-opportunity-form .demo-card-header-pic .card-content-padding .date {
            color: #8e8e93;
          }
          .page.collaborator-opportunity-form .pictures::-webkit-scrollbar {
            display: none;
          }

          .page.collaborator-opportunity-form .card .background {
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-image: url(/assets/images/no-image-available.png);
          }

          .page.collaborator-opportunity-form .inline-labels .item-label, .inline-label .item-label, .inline-labels .item-floating-label, .inline-label .item-floating-label {
            width: auto !important;
          }
        `,
        data() {
          return {
            title: $this.title,
            reminder: {
              No: '234234',
            },
            data: new CollaboratorOpportunityModel(),
            taxList: $this.taxList,
            contactMemebets: [],
            // contactList: [],
            validates: null,
          };
        },
        methods: {
          createNewCustomer(e) {
            const self = this;
            $this.rootServices.createNewContactForm('Khách hàng mới', { Groups: [{ id: 'CUSTOMER', text: 'Khách hàng' }] }).then(newContact => {
              $this.currentState.data.Customer = { id: newContact.Code, text: newContact.Name } as any;
              $this.currentState.data.CustomerName = newContact.Name;
              $this.currentState.data.CustomerPhone = newContact.Phone;
              $this.currentState.data.CustomerEmail = newContact.Email;
              $this.currentState.data.CustomerAddress = newContact.Address;
              $this.setData($this.currentState.data);
            }).catch(err => {
              $this.commonService.showError(err);
            });
          },
          createNewPublisher(e) {
            const self = this;
            $this.rootServices.createNewContactForm('CTV Bán hàng mới', { Groups: [{ id: 'PUBLISHER', text: 'CTV' }, { id: 'SALESCOLLABORATOR', text: 'CTV Bán Hàng ™' }] }).then(newContact => {
              $this.currentState.data.Publisher = { id: newContact.Code, text: newContact.Name } as any;
              $this.currentState.data.PublisherName = newContact.Name;
              $this.currentState.data.PublisherPhone = newContact.Phone;
              $this.currentState.data.PublisherEmail = newContact.Email;
              $this.currentState.data.PublisherAddress = newContact.Address;
              $this.setData($this.currentState.data);
            }).catch(err => {
              $this.commonService.showError(err);
            });
          },
          previewPictures(e) {
            const self: F7ComponentContextExtend = this;
            const currentState = $this.currentState;

            let index = $(e.target).data('index');
            if (typeof index == 'undefined') {
              const id = $(e.target).attr('data-id');
              index = currentState.data.Attachments.findIndex(f => f.Id == id);
            }
            $this.rootServices.previewPictures(currentState.data.Attachments, index || 0, { addToStackButton: true });
          },
          addAcctachment() {
            const self: F7ComponentContextExtend = this;
            const currentState = $this.currentState;
            $this.rootServices.pickAndUploadFiles().subscribe(state => {
              if (state.state == 'UPLOADING') {
                const progress = state.progress;
                console.log('Upload progress: ', progress);
              }
              if (state.state == 'COMPLETE') {
                const images = state.results;
                // currentState.data.Pictures.unshift(images[0]);
                // currentState.data.FeaturePicture = images[0];
                // self.$setState({ data: currentState.data });
                console.log('upload pictures success', images);
                if (!$this.currentState.data.Attachments) {
                  $this.currentState.data.Attachments = [];
                }
                for (const image of images) {
                  $this.currentState.data.Attachments.push(image);
                }
                $this.currentState.instance.$setState({ data: $this.currentState.data });
              }
              if (state.state == 'ERROR') {
                console.error(state);
              }
            });
          },
          changeState(e) {
            $this.changeState(this, e);
          },
          convertToOrder(e) {
            $this.convertToOrder(this, e);
          },
          savePdf(e) {
            $this.savePdf(this, e);
          },
          createPdfQuatation(e) {
            $this.createPdfQuatation(this, e);
          },
          async openRelativeTask(e) {
            const self: F7ComponentContextExtend = this;
            const currentState = $this.currentState;
            const voucher = currentState.data;
            if (voucher) {
              const relativeTask = voucher.RelativeVouchers && voucher.RelativeVouchers.find(f => $this.commonService.getObjectId(f.type) == 'CHATROOM' || f.type == 'TASK') || null;
              if (relativeTask) {
                $this.rootServices.navigate('/chat-room/' + relativeTask.id);
              } else {
                // Assign resource to chat room
                $this.commonService.showPreloader();
                const task = await $this.rootServices.apiService.putPromise<ChatRoomModel[]>('/chat/rooms', { assignResource: true }, [{
                  Code: null,
                  Resources: [
                    {
                      ResourceType: 'CLBRTOPPORTUNITY',
                      Resource: voucher.Code,
                      Title: voucher.Title,
                      Date: voucher.DateOfOpportunity,
                    }
                  ]
                }]).then(rs => {
                  if (rs && rs.length > 0) {
                    // Add publisher to chat room
                    if ($this.commonService.getObjectId(voucher.Publisher)) {
                      $this.rootServices.apiService.putPromise<ChatRoomMemberModel[]>('/chat/room-members', { chatRoom: rs[0].Code }, [{
                        ChatRoom: rs[0].Code as any,
                        Type: 'CONTACT',
                        RefUserUuid: voucher.PublisherRefId,
                        Name: $this.commonService.getObjectText(voucher.Publisher) || voucher.PublisherName,
                        Page: voucher.Page,
                        RefPlatform: 'PROBOXONE',
                        RefType: 'PUBLISHER',
                        id: $this.commonService.getObjectId(voucher.Publisher),
                        text: $this.commonService.getObjectText(voucher.Publisher) || voucher.PublisherName,
                      }]).then(rs2 => {

                        // Connect publisher
                        $this.rootServices.apiService.putPromise<ChatRoomMemberModel[]>('/chat/room-members', { chatRoom: rs[0].Code, connectRefContactMember: true }, [{
                          Type: 'CONTACT',
                          Contact: rs2[0].Contact,
                        }]).then(rs3 => {
                          $this.rootServices.navigate('/chat-room/' + rs[0].Code);
                          $this.commonService.hidePreloader();
                        });

                      }).catch(err => {
                        $this.commonService.hidePreloader();
                        return Promise.reject(err);
                      });
                    } else {
                      $this.rootServices.navigate('/chat-room/' + rs[0].Code);
                      $this.commonService.hidePreloader();
                    }

                    return { id: rs[0].Code, text: voucher.Title, type: 'TASK' };
                  }
                }).catch(err => {
                  $this.commonService.hidePreloader();
                  return Promise.reject(err);
                });

              }
            }
          },
          previewDetailAcctachment(e) {
            const self: F7ComponentContextExtend = this;
            const currentState = $this.currentState;
            let index = $(e.target).closest('.list-item').data('index');
            let realIndex = 0;
            let pictures = [];
            for (const detailIndex in currentState.data.Details) {
              const detail = currentState.data.Details[detailIndex];
              const detailImages = detail.Image;
              if (Array.isArray(detailImages)) {
                if (detailIndex == index) {
                  realIndex = pictures.length;
                }
                pictures = pictures.concat(detailImages.filter(f => !!f).map(m => { m.Description = detail.Description; return m; }));
              }
            }
            $this.rootServices.previewPictures(pictures, realIndex, { addToStackButton: true });
          },
          setFeaturePicture(e) {
            const self: F7ComponentContextExtend = this;
            const currentState = $this.currentState;
            let index = $(e.target).closest('a').data('index');
            if (typeof index != 'undefined') {
              self.$app.dialog.confirm('Bạn có muốn đặt làm hình đại diện không ?', 'Đặt hình đại diện', () => {
                currentState.data.FeaturePicture = currentState.data.Pictures[index];
                self.$setState({ data: currentState.data });
              })
            }
          },
          uploadPicture(e) {
            const self: F7ComponentContextExtend = this;
            const currentState = $this.currentState;
            $this.updateImages((progress) => {
              console.log(progress);
            }, (image) => {
              currentState.data.Pictures.unshift(image);
              currentState.data.FeaturePicture = image;
              self.$setState({ data: currentState.data });

            }).then(images => {
              console.log('upload pictures success', images);
            });
          },
          addDetail(e) {
            const self: F7ComponentContextExtend = this;
            const currentState = $this.currentState;

            $this.chooseProduct(self);

            // let listName = $(e.target).data('list-name');
            // $this.addItemForList(listName);

          },
          chooseProduct(e) {
            const self: F7ComponentContextExtend = this;
            $this.chooseProduct(self);
          },
          scanProductQrCode(e) {
            const self: F7ComponentContextExtend = this;
            const currentState = $this.currentState;
            const systemConfigs = $this.rootServices.systemSettings$.value;

            $this.rootServices.barcodeScanner.scan({ showTorchButton: true }).then(barcodeData => {
              // const data = barcodeData && barcodeData.text.split('|');
              const data = barcodeData && barcodeData.text;

              let tmpcode = data.substring(1);
              const findOpportunityLength = parseInt(tmpcode.substring(0, 1));
              tmpcode = tmpcode.substring(1);
              const findOpportunity = tmpcode.substring(0, findOpportunityLength);
              tmpcode = tmpcode.substring(findOpportunityLength);
              const unitSeqLength = parseInt(tmpcode.substring(0, 1));
              tmpcode = tmpcode.substring(1);
              let unitSeq = tmpcode.substring(0, unitSeqLength);
              tmpcode = tmpcode.substring(unitSeqLength);
              let productId = tmpcode;


              let goodsId = `118${systemConfigs.ROOT_CONFIGS.coreEmbedId}${productId}`;
              if (!data[0]) return false;
              if (productId) {

                // const [productId, unitId] = data[0].split('-');
                if (!data[0]) return false;
                $this.rootServices.apiService.getPromise<any[]>('/admin-product/products/' + goodsId, {
                  select: 'id=>Code,text=>Name,Code=>Code,Name,OriginName=>Name,Sku,FeaturePicture,Pictures',
                  includeSearchResultLabel: true,
                  includeUnits: true,
                  ...self.filter,
                }).then(products => {
                  // self.$$(page.el).find('.page-content').scrollTo(0, 0);
                  // self.$setState({ productList: products.map(t => $this.preapreProduct(t)), infiniteLoadLast: products.length === 0 });
                  // self.infiniteOffset = 0;

                  const product = products[0];
                  if (!product) return;

                  product.Unit = product.Units?.find(f => f.Sequence == unitSeq);
                  product.UnitList = product.Units;

                  let existsProduct: ProductModel = currentState.voucher.Details.find(f => $this.commonService.getObjectId(f.Product as any) === product.Code && $this.commonService.getObjectId(f.Unit as any) === $this.commonService.getObjectId(product.Unit));
                  if (!existsProduct) {
                    existsProduct = {
                      Product: product.Code,
                      Description: product.Name,
                      Quantity: 1,
                      // Price: product.Price,
                      Unit: product.Unit,
                      // PriceText: $this.rootServices.currencyPipe.transform(product.Price, 'VND'),
                      OriginPrice: product.OriginPrice,
                      OriginPriceText: $this.rootServices.currencyPipe.transform(product.OriginPrice, 'VND'),
                      // Tax: { id: 'NOTAX', text: 'n/a' } as any,
                      // TaxText: product.TaxText,
                      // ToMoney: (existsProduct.Quantity * existsProduct.Price) + (existsProduct.Quantity * existsProduct.Price * (existsProduct.Tax && existsProduct.Tax.Tax || 0)),
                      // ToMoneyText: $this.rootServices.currencyPipe.transform(product.Price, 'VND'),
                      Image: product.Pictures,
                      Thumbnail: product.FeaturePicture && product.FeaturePicture?.Thumbnail || null,
                      Promotion: product.Promotion,
                      PromotionText: product.PromotionText,
                      Categories: product.Categories,
                      CategoriesText: product.Categories?.map(m => m.text).join(', '),
                      // TaxList: product.UnitList,
                      // UnitList: [],
                    };

                    // if (product.Tax && !product.Tax.id) {
                    //   existsProduct.Tax = $this.taxList.find(f => f.id === (product.Tax as any));
                    // } else {
                    //   existsProduct.Tax = $this.taxList.find(f => f.id === 'NOTAX');
                    // }
                    if (product.Unit && !product.Unit.id) {
                      existsProduct.Unit = { id: product.Unit, text: product.Unit } as any;
                    } else {
                      existsProduct.Unit = product.Unit
                    }

                    // existsProduct.TaxList = [];
                    // for (const tax of $this.taxList) {
                    //   existsProduct.TaxList.push({ ...tax, selected: tax.id == ($this.commonService.getObjectId(existsProduct.Tax as any) || 'NOTAX') })
                    // }

                    existsProduct.UnitList = [];
                    if (product.UnitList && product.UnitList.length > 0) {
                      for (const unit of product.UnitList) {
                        const isSelected = unit.id === $this.commonService.getObjectId(product.Unit);
                        if (isSelected && unit.Price) {
                          existsProduct.Price = unit.Price;
                          existsProduct.PriceText = $this.rootServices.currencyPipe.transform(unit.Price, 'VND');
                        }
                        existsProduct.UnitList.push({ ...unit, selected: isSelected });
                      }
                    } else {
                      for (const unit of $this.unitList) {
                        existsProduct.UnitList.push({ ...unit, selected: product.Unit && unit.id === $this.commonService.getObjectId(existsProduct.Unit) });
                      }
                    }

                    currentState.voucher.Details.push(existsProduct);
                  } else {
                    existsProduct.Quantity++;
                  }
                  // existsProduct.ToMoney = (existsProduct.Quantity * existsProduct.Price) + (existsProduct.Quantity * existsProduct.Price * (existsProduct.Tax && existsProduct.Tax.Tax || 0) / 100);
                  existsProduct.ToMoney = existsProduct.Quantity * existsProduct.Price;
                  existsProduct.ToMoneyText = $this.rootServices.currencyPipe.transform(existsProduct.ToMoney, 'VND');

                  self.calculateTotal();
                  self.$setState({
                    voucher: currentState.voucher,
                  });

                  $this.commonService.showInfo('Đã thêm hàng hóa/dịch vụ vào báo giá', { position: 'bottom' });


                  // setTimeout(() => {
                  //   self.scanProductQrCode(e);
                  // }, 300);

                }).catch(err => {
                  console.error(err);
                  $this.commonService.showError(err, { position: 'bottom' });

                });
              }
            });
          },
          removeDetailItem(e) {
            return $this.removeDetailItem(this, e);
          },
          onFieldChange(e) {
            $this.onFieldChange(this, e);
          },
          async refresh(e, done) {

            done && setTimeout(() => done(), 10000);
            $this.refresh(this).then(data => {
              done && done();
            });

            return true;
          },
          save() {
            const self: F7ComponentContextExtend = this;
            const currentState = $this.currentState;
            const relativeVouchers = self.$route.context.relativeVouchers;
            const id = currentState.data.Code || self.$route.params['id'];

            $this.commonService.showPreloader();

            const preloaderProcess = setTimeout(() => {
              $this.commonService.hidePreloader();
            }, 15000);

            if (id === 'new') {
              if (relativeVouchers) {
                currentState.data.RelativeVouchers = relativeVouchers;
              }
            }

            $this.save(self).then(rs => {
              $this.commonService.showInfo("Đã lưu đon đặt mua hàng", { position: 'bottom' });
              clearTimeout(preloaderProcess);
              $this.commonService.hidePreloader();
            }).catch(err => {
              clearTimeout(preloaderProcess);
              $this.commonService.hidePreloader();
              console.error(err);
            });
          },
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          pageInit(e, page: F7Page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;

            $this.onComponentInit({ instance: self, page: page }, self.$route.params['id'], null, page).then(curentState => {
              // self.detailDialog = self.$app.dialog.create({
              //   el: page.$el.find('.dialog-detail'),
              //   closeByBackdropClick: true,
              // });
            });

            $this.rootServices.authService.isAuthenticatedOrRefresh().pipe(take(1)).toPromise().then(() => {
              self.$setState({
                backTitle: self.$route.context && self.$route.context.backTitle || 'Back', title: self.$route.context && self.$route.context.title || 'Chat GUI',
              });
            }).catch(err => {
              console.error(err);
              $this.commonService.showError(err, { position: 'bottom' });
            });
          },
          // pageBeforeIn(e, page) {
          //     console.log('page before in');
          //     const self: F7ComponentContextExtend = this;
          //     self.refresh();
          // },
          // pageAfterIn(e, page) {
          //     console.log('page after in');
          // },
          pageBeforeOut(e, page) {
            console.log('page before out');
            const self: F7ComponentContextExtend = this;
            // Call caback function
            if (this.$route?.context['callback']) {
              this.$route?.context['callback']($this.currentState.data, { lastAction: $this.currentState.lastAction });
            }

            // Auto save
            // $this.currentState?.data?.Code && $this.currentState?.data?.State != 'APPROVED' && self.save();
          },
          // pageAfterOut(e, page) {
          //     console.log('page after out');
          // },
          // pageBeforeUnmount(e, page) {
          //     console.log('page before unmount');
          // },
          // pageBeforeRemove(e, page) {
          //     console.log('page before remove');
          // },
          pageBeforeRemove(e, page) {
            console.log('[page event] before remove', page.route.url);
            const self: F7ComponentContextExtend = this;
            // const starmtSelectRoles = self.$app.smartSelect.get('.smart-select');
            // starmtSelectRoles.destroy();
            const currentState = $this.state[self.$route.params.id];
            if (self.$route.params.id === 'new') {
              if (currentState && currentState.data && currentState.data.Code) {
                delete currentState.data;
              }
            }
          },
        },
      },
    };
  }
}
