import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable()
export class NotificationService {

    constructor(
        private platform: Platform,
    ) { }
}
