import { ProductModel } from 'vendors/smart-bot-app/src/app/model/product.model';
import { F7ComponentContext } from '../types/framework7-types';
import { Router } from 'framework7/build/core/modules/router/router';
import { take } from 'rxjs/operators';
import { BaseComponent, ComponentState } from 'vendors/smart-bot-app/src/app/lib/base-component';
import { ChatRoom } from 'vendors/smart-bot-app/src/app/lib/nam-chat/chat-room';
import { Member } from 'vendors/smart-bot-app/src/app/lib/nam-chat/model/member';
import { CommonService } from 'vendors/smart-bot-app/src/app/services/common.service';
import { RootServices } from 'vendors/smart-bot-app/src/app/services/root.services';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { AccountModel } from 'vendors/smart-bot-app/src/app/model/account.model';
import { CollaboratorOrderModel } from '../model/collaborator.model';
import * as $ from 'jquery';

// declare const $: any;
export interface ComponentStateExtend extends ComponentState {
  [key: string]: any;
}

export class F7ComponentContextExtend extends F7ComponentContext {
  // messagebar?: F7Messagebar;
  // messages?: Messages.Messages;
  // images?: string[];
  responseInProgress?: boolean;
  // answers?: string[];
  // people?: { name?: string, avatar?: string }[];
  // hideToolbar?: () => void;
  // sheetToggle?: () => void;
  // deleteAttachment?: (e: MouseEvent, index: number) => void;
  // handleAttachment?: (e: MouseEvent) => void;
  // checkAttachments?: () => void;
  // sendMessage?: () => void;
  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, memberInfo?: Member, chatRoom?: ChatRoom, type?: string } };
}

/** Component group manager */
export class UserProfileComponent extends BaseComponent<ComponentStateExtend> {
  // states: { [key: string]: State } = {};

  title: 'Profile'

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
    public datePipe: DatePipe,
    public currencyPipe: CurrencyPipe,
  ) {
    super(rootServices);
    // console.log('Click here to open ts file');
  }

  get isPublicComponent() {
    return true;
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: 'user-profile',
      path: '/user-profile/:id',
      component: {
        template: /*html*/`
            <div class="page user-profile" data-name="profile">
              <div class="navbar user-profile navbar-transparent">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a class="link back {{textColorClass}}">
                      <i class="icon icon-back"></i>
                      <span class="if-not-md {{textColorClass}}">{{backTitle}}</span>
                    </a>
                  </div>
                  <div class="title">{{title}}</div>
                  <div class="right">
                    <a class="link icon-only">
                      <i class="icon f7-icons">search</i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="page-content ptr-content infinite-scroll-content" @ptr:refresh="refresh">

                <div class="ptr-preloader">
                  <div class="preloader"></div>
                  <div class="ptr-arrow"></div>
                </div>
            
                <div class="profile-info">
                  <div class="profile-banner bg-color-gray"
                    style="background-image: url({{js "this.profile && this.profile.Banner && this.profile.Banner.SmallImage"}})">
                    {{#if itself}}
                    <div @click="uploadBanner" class="upload-banner-btn" style="right: 20px; bottom: 20px;">
                      <i class="icon f7-icons upload-banner-icon">camera_fill</i>
                    </div>
                    {{/if}}
                  </div>
                  <div class="profile-logo-border">
                    <div class="profile-logo bg-color-gray"
                      style="background-image: url({{js "this.profile && this.profile.Avatar && this.profile.Avatar.SmallImage"}})">
                    </div>
                    {{#if itself}}
                    <div @click="uploadAvatar" class="upload-banner-btn" style="right: 3vw; bottom: 6vw;">
                      <i class="icon f7-icons upload-banner-icon">camera_fill</i>
                    </div>
                    {{/if}}
                  </div>
                  <div class="profile-title text-color-default {{textColorClass}}">
                    {{js "this.profile && this.profile.Name || ''"}}</div>
                  <div class="profile-subtitle text-color-gray" style="font-size: 12px;">{{js "this.profile && this.profile.TagName && ('@' + this.profile.TagName) || ''"}}</div>
                  <!--<div style="text-align: center; margin-top: 10px;">
                    <a @click="copyText" data-text="https://probox.vn/profile/{{js "this.profile && this.profile.Code || ''"}}"><i style="font-size: 14px" class="icon f7-icons">square_on_square</i> https://probox.vn/profile/{{js "this.profile && this.profile.Code || ''"}}</a>
                  </div>-->
                  <div style="text-align: center; margin-top: 10px;">
                    <a @click="copyText" data-text="https://probox.vn/{{js "this.profile && this.profile.TagName || ''"}}"><i style="font-size: 14px" class="icon f7-icons">square_on_square</i> https://probox.vn/{{js "this.profile && this.profile.TagName || ''"}}</a>
                  </div>
                </div>
            
                <div class="block" style="display: flex">
                  <button class="button button-large button-fill color-blue" style="flex: 1; margin-right: 8px; font-size: 16px" data-text="{{js "this.profile && this.profile.Code"}}" @click="copyText">
                  <i style="font-size: 20px" class="icon f7-icons">link_circle_fill</i> Mã ref user {{js "this.profile && this.profile.Code"}}
                  </button>
                  <button @click="gotoAccount" class="button button-large button-fill color-gray" style="width: 50px"><i class="icon f7-icons">ellipsis</i></button>
                </div>

                <div class="block-title">Ủng hộ mình nhé, cám ơn bạn ! {{#if itself}}<a @click="editProductBlock" data-no="3" style="float: right">chỉnh block</a>{{/if}}</div>
                {{#js_if "this.profile && this.profile.products"}}
                {{#each profile.products}}
                <div class="card demo-card-header-pic product" data-id="{{Code}}">
                  <div class="card-title">{{Name}}</div>
                  <div style="background-image:url({{js "this.FeaturePicture && this.FeaturePicture.OriginImage"}}); border-radius: 0"
                    class="card-header align-items-flex-end"></div>
                  <div class="card-content card-content-padding">
                    <p><span style="font-weight: bold;" class="text-color-red">{{PriceText}}</span>/<span>{{UnitName}}</span></p>
                    <p>Sản phẩm phân phối bởi trang doanh nghiệp: <br><b>{{PageName}}</b></p>
                  </div>

                  <div class="block-title" style="margin-top: 0.5rem;">Đặt hàng</div>
                  <div class="list inline-labels no-hairlines-md">
                    <ul>
                      <li class="item-content item-input">
                        <div class="item-media">
                          <i class="icon f7-icons" style="color: var(--f7-color-gray-shade)">person_alt_circle</i>
                        </div>
                        <div class="item-inner">
                          <div class="item-title item-label" style="color: var(--f7-color-gray-shade)">Tên</div>
                          <div class="item-input-wrap">
                            <input name="CustomerName" type="text" placeholder="Tên của bạn" />
                            <span class="input-clear-button"></span>
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-media">
                          <i class="icon f7-icons" style="color: var(--f7-color-gray-shade)">phone_circle</i>
                        </div>
                        <div class="item-inner">
                          <div class="item-title item-label" style="color: var(--f7-color-gray-shade)">Điện thoại</div>
                          <div class="item-input-wrap">
                            <input name="CustomerPhone" type="text" placeholder="Số điện thoại" />
                            <span class="input-clear-button"></span>
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-media">
                          <i class="icon f7-icons" style="color: var(--f7-color-gray-shade)">placemark</i>
                        </div>
                        <div class="item-inner">
                          <div class="item-title item-label" style="color: var(--f7-color-gray-shade)">Địa chỉ</div>
                          <div class="item-input-wrap">
                            <input name="CustomerAddress" type="text" placeholder="Địa chỉ nhận hàng" />
                            <span class="input-clear-button"></span>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div class="block" style="margin-top: 1rem; margin-bottom: 1rem"> 
                      <button class="button button-large button-fill" data-product="{{Code}}" @click="orderProduct">Đặt hàng</button>
                      {{#if "isAppPlatform"}}
                      <!--<button class="button button-large button-fill color-red" style="margin-top: 0.5rem">Giảm 20% trên app ProBox</button>-->
                      {{/if}}
                    </div>
                  </div>

                  <div class="card-footer"><a href="#" @click="gotoProducct" data-id="{{Code}}" class="link">Xem thêm</a></div>
                </div>
                {{/each}}
                {{/js_if}}

                <div class="block-title">Tất cả sản phẩm đang bán</div>
                {{#js_if "this.profile && this.profile.subscriptionProducts"}}
                {{#each profile.subscriptionProducts}}
                <div class="card demo-card-header-pic product" data-id="{{Code}}">
                  <div class="card-title">{{Name}}</div>
                  <div style="background-image:url({{js "this.FeaturePicture && this.FeaturePicture.OriginImage"}}); border-radius: 0"
                    class="card-header align-items-flex-end"></div>
                  <div class="card-content card-content-padding">
                    <p><span style="font-weight: bold;" class="text-color-red">{{PriceText}}</span>/<span>{{UnitName}}</span></p>
                    <p>Sản phẩm phân phối bởi trang doanh nghiệp: <br><b>{{PageName}}</b></p>
                  </div>

                  <div class="block-title" style="margin-top: 0.5rem;">Đặt hàng</div>
                  <div class="list inline-labels no-hairlines-md">
                    <ul>
                      <li class="item-content item-input">
                        <div class="item-media">
                          <i class="icon f7-icons" style="color: var(--f7-color-gray-shade)">person_alt_circle</i>
                        </div>
                        <div class="item-inner">
                          <div class="item-title item-label" style="color: var(--f7-color-gray-shade)">Tên</div>
                          <div class="item-input-wrap">
                            <input name="CustomerName" type="text" placeholder="Tên của bạn" />
                            <span class="input-clear-button"></span>
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-media">
                          <i class="icon f7-icons" style="color: var(--f7-color-gray-shade)">phone_circle</i>
                        </div>
                        <div class="item-inner">
                          <div class="item-title item-label" style="color: var(--f7-color-gray-shade)">Điện thoại</div>
                          <div class="item-input-wrap">
                            <input name="CustomerPhone" type="text" placeholder="Số điện thoại" />
                            <span class="input-clear-button"></span>
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-media">
                          <i class="icon f7-icons" style="color: var(--f7-color-gray-shade)">placemark</i>
                        </div>
                        <div class="item-inner">
                          <div class="item-title item-label" style="color: var(--f7-color-gray-shade)">Địa chỉ</div>
                          <div class="item-input-wrap">
                            <input name="CustomerAddress" type="text" placeholder="Địa chỉ nhận hàng" />
                            <span class="input-clear-button"></span>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div class="block" style="margin-top: 1rem; margin-bottom: 1rem"> 
                      <button class="button button-large button-fill" data-product="{{Code}}" data-list="subscription" @click="orderProduct">Đặt hàng</button>
                    </div>
                  </div>

                  <div class="card-footer"><a href="#" @click="gotoProducct" data-id="{{Code}}" data-page="{{Page}}" class="link">Xem thêm</a></div>
                </div>
                {{/each}}
                {{/js_if}}

                <div class="block-title">Hoạt động CSR</div>
                <div class="card demo-facebook-card" data-no="5" data-type="CSR">
                  <div class="card-header">
                    <div class="demo-facebook-avatar"><img src="{{js "this.profile && this.profile.Avatar && this.profile.Avatar.SmallImage"}}" width="34"
                        height="34" /></div>
                    <div class="demo-facebook-name">{{profile.Name}}</div>
                    <div class="demo-facebook-date">Monday at 3:47 PM</div>
                  </div>
                  <div class="card-content">
                    <div class="post-content">{{js "this.profile && this.profile.csrInfo && this.profile.csrInfo.Content"}}</div>
                  </div>
                  <div class="card-footer">
                    <a href="#" class="link">Xem thêm</a>
                    {{#if itself}}<a href="#" class="link" @click="editContent" data-no="5" data-type="CSR">Chỉnh</a>{{/if}}
                  </div>
                </div>
            
                {{#if linksBlockEditMode}}
                <div class="link-block-form">
                  <div class="block-title">Liên kết {{#if itself}}<a @click="saveLinksBlock" data-no="4" style="float: right">lưu</a>{{/if}}</div>
                  <div class="list inset no-hairlines-md">
                    <ul>
                      <li class="item-content item-input">
                        <div class="item-media">
                          <i class="icon f7-icons upload-banner-icon">logo_facebook</i>
                        </div>
                        <div class="item-inner">
                          <div class="item-title item-label">Facebook</div>
                          <div class="item-input-wrap">
                            <input type="text" name="Facebook" value="{{js "this.profile && this.profile.links && this.profile.links.Facebook"}}" placeholder="Facebook"/>
                            <span class="input-clear-button"></span>
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-media">
                          <i class="icon f7-icons upload-banner-icon">music_note</i>
                        </div>
                        <div class="item-inner">
                          <div class="item-title item-label">Tiktok</div>
                          <div class="item-input-wrap">
                            <input type="text" name="Tiktok" value="{{js "this.profile && this.profile.links && this.profile.links.Tiktok"}}" placeholder="Tiktok"/>
                            <span class="input-clear-button"></span>
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-media">
                          <i class="icon f7-icons upload-banner-icon">chat_bubble_2_fill</i>
                        </div>
                        <div class="item-inner">
                          <div class="item-title item-label">Zalo Group</div>
                          <div class="item-input-wrap">
                            <input type="text" name="ZaloGroup" value="{{js "this.profile && this.profile.links && this.profile.links.ZaloGroup"}}" placeholder="Zalo Group"/>
                            <span class="input-clear-button"></span>
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-media">
                          <i class="icon f7-icons upload-banner-icon">paperplane_fill</i>
                        </div>
                        <div class="item-inner">
                          <div class="item-title item-label">Telegram</div>
                          <div class="item-input-wrap">
                            <input type="text" name="Telegram" value="{{js "this.profile && this.profile.links && this.profile.links.Telegram"}}" placeholder="Telegram"/>
                            <span class="input-clear-button"></span>
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-media">
                          <i class="icon f7-icons upload-banner-icon">logo_google</i>
                        </div>
                        <div class="item-inner">
                          <div class="item-title item-label">Google</div>
                          <div class="item-input-wrap">
                            <input type="text" name="Google" value="{{js "this.profile && this.profile.links && this.profile.links.Google"}}" placeholder="Google"/>
                            <span class="input-clear-button"></span>
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-media">
                          <i class="icon f7-icons upload-banner-icon">link_circle_fill</i>
                        </div>
                        <div class="item-inner">
                          <div class="item-title item-label">Khác</div>
                          <div class="item-input-wrap">
                            <input type="text" name="Other" value="{{js "this.profile && this.profile.links && this.profile.links.Other"}}" placeholder="Khác"/>
                            <span class="input-clear-button"></span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                {{else}}
                <div class="block-title">Liên kết {{#if itself}}<a @click="editLinksBlock" data-no="4" style="float: right">chỉnh block</a>{{/if}}</div>
                <div class="list inset" data-no="4">
                  <ul>
                    {{#js_if "this.profile && this.profile.links && this.profile.links.Facebook"}}
                    <li>
                      <a data-href="{{js "this.profile && this.profile.links && this.profile.links.Facebook"}}" class="item-link item-content" @click="openLink">
                        <div class="item-media"><i class="icon f7-icons upload-banner-icon">logo_facebook</i></div>
                        <div class="item-inner">
                          <div class="item-title">Facebook</div>
                        </div>
                      </a>
                    </li>
                    {{/js_if}}
                    {{#js_if "this.profile && this.profile.links && this.profile.links.Tiktok"}}
                    <li>
                      <a data-href="{{js "this.profile && this.profile.links && this.profile.links.Tiktok"}}" class="item-link item-content" @click="openLink">
                        <div class="item-media"><i class="icon f7-icons upload-banner-icon">music_note</i></div>
                        <div class="item-inner">
                          <div class="item-title">Tiktok</div>
                        </div>
                      </a>
                    </li>
                    {{/js_if}}
                    {{#js_if "this.profile && this.profile.links && this.profile.links.ZaloGroup"}}
                    <li>
                      <a data-href="{{js "this.profile && this.profile.links && this.profile.links.ZaloGroup"}}" class="item-link item-content" @click="openLink">
                        <div class="item-media"><i class="icon f7-icons upload-banner-icon">chat_bubble_2_fill</i></div>
                        <div class="item-inner">
                          <div class="item-title">Zalo Group</div>
                        </div>
                      </a>
                    </li>
                    {{/js_if}}
                    {{#js_if "this.profile && this.profile.links && this.profile.links.Telegram"}}
                    <li>
                      <a data-href="{{js "this.profile && this.profile.links && this.profile.links.Telegram"}}" class="item-link item-content" @click="openLink">
                        <div class="item-media"><i class="icon f7-icons upload-banner-icon">paperplane_fill</i></div>
                        <div class="item-inner">
                          <div class="item-title">Telegram</div>
                        </div>
                      </a>
                    </li>
                    {{/js_if}}
                    {{#js_if "this.profile && this.profile.links && this.profile.links.Google"}}
                    <li>
                      <a data-href="{{js "this.profile && this.profile.links && this.profile.links.Google"}}" class="item-link item-content" @click="openLink">
                        <div class="item-media"><i class="icon f7-icons upload-banner-icon">logo_google</i></div>
                        <div class="item-inner">
                          <div class="item-title">Google</div>
                        </div>
                      </a>
                    </li>
                    {{/js_if}}
                    {{#js_if "this.profile && this.profile.links && this.profile.links.Other"}}
                    <li>
                      <a data-href="{{js "this.profile && this.profile.links && this.profile.links.Other"}}" class="item-link item-content" @click="openLink">
                        <div class="item-media"><i class="icon f7-icons upload-banner-icon">link_circle_fill</i></div>
                        <div class="item-inner">
                          <div class="item-title">Khác</div>
                        </div>
                      </a>
                    </li>
                    {{/js_if}}
                  </ul>
                </div>
                {{/if}}
            

                <div class="block-title">Tiểu sử</div>
                <div class="card demo-facebook-card" data-no="2" data-type="PROFILEINFO">
                  <div class="card-header">
                    <div class="demo-facebook-avatar"><img src="{{js "this.profile && this.profile.Avatar && this.profile.Avatar.SmallImage"}}" width="34"
                        height="34" /></div>
                    <div class="demo-facebook-name">{{profile.Name}}</div>
                    <div class="demo-facebook-date">Monday at 3:47 PM</div>
                  </div>
                  <div class="card-content">
                    <div class="post-content">{{js "this.profile && this.profile.info && this.profile.info.Content"}}</div>
                  </div>
                  <div class="card-footer">
                    <a href="#" class="link">Xem thêm</a>
                    {{#if itself}}<a href="#" class="link" @click="editContent" data-no="2" data-type="PROFILEINFO">Chỉnh</a>{{/if}}
                  </div>
                </div>
            
            
              </div>
            </div>
        `,
        style: /*css*/`
          .post-content {
            padding: 1rem;
          }
          .post-content img {
            border-radius: 5px;
            margin-top: 5px;
            margin-bottom: 5px;
          }
          .page.user-profile .page-content {
            
          }
          /*.navbar.user-profile::after {
            position: absolute;
            content: "";
            left: 0px;
            top: 0px;
            height: 100%;
            width: 100%;
            background: linear-gradient(var(--f7-page-bg-color), transparent);
            pointer-events: none;
          }*/
          .page.user-profile .profile-info {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            margin-top: 0.5rem;
          }
          .page.user-profile .profile-logo-border {
            height: 209px;
            width: 209px;
            border-radius: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            margin: 0 auto;
            margin-top: -100px;
            padding: 5px;
            background-color: var(--f7-page-bg-color);
            position: relative;
          }
          .page.user-profile .profile-logo {
            height: 200px;
            width: 200px;
            border-radius: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            margin: -1px auto;
            position: relative;
          }
          .page.user-profile .profile-banner {
            height: 200px;
            width: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            margin: 0 auto;
            position: relative;
            border-top-left-radius: 0.8rem;
            border-top-right-radius: 0.8rem;
          }
          .page.user-profile .upload-banner-btn {
            position: absolute;
            border-radius: 50%;
            background: #fff;
            border: 1.5px solid #000;
            padding: 2px;
            width: 28px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
          .page.user-profile .upload-banner-btn .upload-banner-icon {
            font-size: 14px;
            color: black;
          }
          .page.user-profile .profile-title {
            text-align: center;
            font-weight: bold;
          }
          .page.user-profile .profile-subtitle {
            text-align: center;
          }
          .page.user-profile .profile-form li .item-media i {
            /* font-size: 44px;*/
          }

          .demo-card-header-pic .card-header {
            height: 40vw;
            background-size: cover;
            background-position: center;
            color: #fff;
          }
          .demo-card-header-pic .card-title {
            color: var(--f7-block-title-text-color);
            font-weight: bold;
            font-size: 14px;
            padding: 0.5rem;
            padding-left: 1rem;
            padding-right: 1rem;
            border-radius: var(--f7-card-border-radius) var(--f7-card-border-radius) 0 0;
          }
        
          .demo-card-header-pic .card-content-padding .date {
            color: #8e8e93;
          }
        
          .demo-facebook-card .card-header {
            display: block;
            padding: 10px;
          }
        
          .demo-facebook-card .demo-facebook-avatar {
            float: left;
          }
          .demo-facebook-card .demo-facebook-avatar img {
            border-radius: 50%
          }
        
          .demo-facebook-card .demo-facebook-name {
            margin-left: 44px;
            font-size: 14px;
            font-weight: 500;
          }
        
          .demo-facebook-card .demo-facebook-date {
            margin-left: 44px;
            font-size: 13px;
            color: #8e8e93;
          }
        
          .demo-facebook-card .card-footer {
            background: #fafafa;
          }
        
          .demo-facebook-card .card-footer a {
            color: #81848b;
            font-weight: 500;
          }
        
          .demo-facebook-card .card-content img {
            display: block;
          }
        
          .demo-facebook-card .card-content-padding {
            padding: 15px 10px;
          }
        
          .demo-facebook-card .card-content-padding .likes {
            color: #8e8e93;
          }

          .page.user-profile .card.product .card-content * {
            max-width: 100%;
          }
          .page.user-profile .card.product .card-content figure {
              width: initial !important;
          }
          .page.user-profile .list.inset {
            margin-left: calc(var(--f7-card-margin-horizontal) + var(--f7-safe-area-left)); 
            margin-right: calc(var(--f7-card-margin-horizontal) + var(--f7-safe-area-right));
            color: var(--f7-color-gray-shade);
          }
        `,
        data() {
          return {
            title: $this.title,
            editable: false,
            profile: {},
            isAppPlatform: $this.rootServices.isAppPlatform,
            isLoggedIn: $this.rootServices.authService.getUser()?.id && true || false,
            itself: false,
          };
        },
        methods: {
          editLinksBlock(e) {
            const self: F7ComponentContextExtend = this;
            self.$setState({ linksBlockEditMode: true });
          },
          saveLinksBlock(e) {
            const self: F7ComponentContextExtend = this;
            self.$setState({ linksBlockEditMode: false });
            const linksBlockForm = $(e.target).closest('.link-block-form');
            const fieldEles = linksBlockForm.find('input[type="text"]');
            const links = {};
            fieldEles.each((index, fieldEle) => {
              console.log(fieldEle);
              console.log(fieldEle['value']);
              links[fieldEle['name']] = fieldEle['value'];
            });

            if ($this.rootServices.authService.getUser()?.id == self.profile.Code) {
              $this.rootServices.apiService.putPromise(`/user/profile-blocks/${self.profile.Code}-4`, {}, [{
                User: self.profile.Code,
                No: 4,
                Type: 'LINKS',
                Data: links,
              }]).then(rs => {
                $this.commonService.showInfo('Cập nhật links block thành công');
                self.refresh();
              });
            } else {
              console.error('không thể cập nhật links block cho user khác');
            }
          },
          gotoProducct(e) {
            const self: F7ComponentContextExtend = this;
            const productCode = $(e.target).data('id')?.replace(/_\w+/,'')?.toLowerCase();
            const pageCode = $(e.target).data('page')?.toLowerCase();
            // const product = self.profile?.products?.find(f => f.Code == productCode);
            $this.rootServices.navigate(`/${pageCode}/ctvbanhang/product/${productCode}`, { context: { backTitle: 'Profile', publisher: self.$route.params?.id } });
          },
          gotoAccount() {
            const self: F7ComponentContextExtend = this;
            if (self.itself) {
              $this.rootServices.navigate('/account', { context: { backTitle: 'Profile' } });
            }
          },
          uploadBanner(e) {
            const self: F7ComponentContextExtend = this;
            if ($this.rootServices.authService.getUser()?.id == self.profile.Code) {
              $this.rootServices.pickAndUploadFile().then(file => {
                console.log(file);
                $this.rootServices.apiService.putPromise('/user/profiles/' + self.profile.Code, {}, [{
                  Code: self.profile.Code,
                  Banner: file,
                }]).then(rs => {
                  $this.commonService.showInfo('Cập nhật banner thành công');
                  self.refresh();
                });
              });
            } else {
              console.error('không thể cập nhật banner cho user khác');
            }
          },
          uploadAvatar(e) {
            const self: F7ComponentContextExtend = this;
            if ($this.rootServices.authService.getUser()?.id == self.profile.Code) {
              $this.rootServices.pickAndUploadFile().then(file => {
                console.log(file);
                $this.rootServices.apiService.putPromise('/user/profiles/' + self.profile.Code, {}, [{
                  Code: self.profile.Code,
                  Avatar: file,
                }]).then(rs => {
                  $this.commonService.showInfo('Cập nhật banner thành công');
                  self.refresh();
                  const oldUser = $this.rootServices.authService.user$.getValue();
                  oldUser.avatar = oldUser.avatar || { id: '', payload: {} };
                  oldUser.avatar = { ...oldUser.avatar, payload: { ...oldUser.avatar.payload, thumbnail: file.Thumbnail, url: file.SmallImage } }
                  $this.rootServices.authService.user$.next(oldUser);
                });
              });
            } else {
              console.error('không thể cập nhật banner cho user khác');
            }
          },
          copyText(e) {
            const self: F7ComponentContextExtend = this;
            const text = $(e.target).data('text');
            console.log(text);
            $this.rootServices.copyTextToClipboard(text);
          },
          orderProduct(e) {
            const self: F7ComponentContextExtend = this;
            const productListName = $(e.target).data('list');
            const cardEle = $(e.target).closest('.card');
            const nameEle = cardEle.find('input[name="CustomerName"]');
            const phoneEle = cardEle.find('input[name="CustomerPhone"]');
            const addressEle = cardEle.find('input[name="CustomerAddress"]');

            const productCode = $(e.target).data('product');
            let product = null;
            if (productListName === 'subscription') {
              product = self.profile?.subscriptionProducts?.find(f => f.Code === productCode);
            } else {
              product = self.profile?.Blocks?.find(f => f.Type === 'BUSINESSPRODUCT')?.Data?.find(f => f.Code === productCode);
            }

            if (!product) {
              $this.commonService.showError('Sản phẩm không tồn tại');
              return;
            }
            if (!nameEle.val()) {
              $this.commonService.showError('Quý khách chưa điền tên !');
              return;
            }
            if (!phoneEle.val()) {
              $this.commonService.showError('Quý khách chưa điền số điện thoại !');
              return;
            }
            if (!addressEle.val()) {
              $this.commonService.showError('Quý khách chưa điền địa chỉ nhận hàng !');
              return;
            }

            $this.commonService.showPreloader();
            $this.rootServices.apiService.postPromise<CollaboratorOrderModel[]>('/collaborator/orders', { skipAuth: true, publisher: self.$route.params?.id }, [
              {
                Page: product.Page,
                ObjectName: nameEle.val() as string,
                ObjectPhone: phoneEle.val() as string,
                ObjectAddress: addressEle.val() as string,
                Details: [
                  {
                    Product: product.Code,
                    Description: product.Name,
                    Unit: product.Unit,
                    Price: product.Price,
                    Quantity: 1,
                    Image: product?.FeaturePicture ? [product?.FeaturePicture] : [],
                    Token: product.Token,
                  }
                ]
              }
            ]).then(rs => rs[0]).then(newOrder => {
              $this.commonService.hidePreloader();
              $this.commonService.showInfo(`Cám ơn bạn đã ủng hộ sản phẩm: ${product.Name}<br>Mã đặt hàng của bạn là: ${newOrder.Code}<br>Chúc bạn một ngày may mắn và vui vẻ ❤️`, { timeout: 120000 });
              nameEle.val('');
              phoneEle.val('');
              addressEle.val('');
            }).catch(err => {
              $this.commonService.showError(err);
              $this.commonService.hidePreloader();
            });
          },
          openLink(e) {
            const self: F7ComponentContextExtend = this;
            const link = $(e.target).closest('a').data('href');
            $this.rootServices.iab.create(link, '_system');
          },
          editProductBlock(e) {
            const self: F7ComponentContextExtend = this;
            const blockNo = $(e.target).data('no');
            $this.rootServices.navigate(`/product-block/${self.$route.params?.id}`, {
              context: {
                productList: self.profile?.products,
                onSave: (productList: any[]) => {
                  $this.rootServices.apiService.putPromise(`/user/profile-blocks/${self.$route.params.id}-${blockNo}`, {}, [{
                    User: self.$route.params.id,
                    No: blockNo,
                    Type: 'BUSINESSPRODUCT',
                    Data: productList.map(p => p.Code),
                  }]).then(rs => self.refresh());
                }
              }
            });
          },
          editContent(e) {
            const self: F7ComponentContextExtend = this;
            const blockNo = $(e.target).data('no');
            const blockType = $(e.target).data('type');
            const postContent = $(e.target).closest('.card').find('.post-content');
            $this.rootServices.navigate('/editor/' + $this.rootServices.authService?.getUser()?.id, {
              context: {
                postContent: postContent.html(),
                onSave: (content: any) => {
                  console.log(content);
                  postContent.html(content);
                  $this.rootServices.apiService.putPromise(`/user/profile-blocks/${self.$route.params.id}-${blockNo}`, {}, [{
                    User: self.$route.params.id,
                    No: blockNo,
                    Type: blockType,
                    Content: content,
                  }]).then(rs => self.refresh());
                }
              }
            });
          },
          async refresh(e, done) {
            const self: F7ComponentContextExtend = this;
            const currentState = $this.state[self.$route.params?.id];

            let doneTimeout = null;
            if (done) {
              doneTimeout = setTimeout(() => {
                done();
              }, 10000);
            };

            await $this.rootServices.apiService.getPromise<AccountModel>('/user/profiles/' + self.$route.params?.id, { skipAuth: true, includeBlocks: true }).then(rs => rs[0]).then(async userInfo => {
              console.log(userInfo);
              let coreDomain = $this.rootServices.apiService.token && $this.rootServices.apiService.token.api_url;
              if (coreDomain) {
                const url = new URL(coreDomain);
                coreDomain = url.host;
              }
              const profileInfo = userInfo.Blocks?.find(f => f.Type == 'PROFILEINFO');
              const csrInfo = userInfo.Blocks?.find(f => f.Type == 'CSR');
              const profileProducts = userInfo?.Blocks?.find(f => f.Type == 'BUSINESSPRODUCT')?.Data?.map(product => {
                if (product?.Units) {
                  product.Unit = product.Unit || (product.Units && product.Units[0] && product.Units[0]['Unit'] || '');
                  const unit = product.Units.find(f => f.id == product.Unit);
                  if (unit) {
                    product.Price = unit.Price;
                    product.Unit = unit.id;
                    product.UnitName = unit.text;
                    product.PriceText = $this.rootServices.currencyPipe.transform(product.Price, 'VND');
                  }
                } 
                return {
                  ...product,
                  // PriceText: $this.currencyPipe.transform(product.Price || 0, 'VND'),
                  FeaturePicture: product.FeaturePicture && product.FeaturePicture.OriginImage ? product.FeaturePicture : {
                    ThumbnalImage: 'assets/images/no-image-available.png',
                    SmallImage: 'assets/images/no-image-available.png',
                    OriginImage: 'assets/images/no-image-available.png',
                  }
                };
              });
              const profileLinks = userInfo?.Blocks?.find(f => f.Type == 'LINKS')?.Data;

              const subscriptionProducts = await $this.rootServices.apiService.getPromise<ProductModel[]>('/collaborator/product-subscriptions', {
                publisher: self.$route.params?.id,
                includeCategories: true,
                includeGroups: true,
                includeUnitPrices: true,
                sort_Id: 'desc',
                limit: 'nolimit',
                skipAuth: true,
              }).then(rs => {
                return rs.map(product => {

                  if (product?.Units) {
                    product.Unit = product.Unit || (product.Units && product.Units[0] && product.Units[0]['Unit'] || '') as any;
                    const unit = product.Units.find(f => f.id == product.Unit);
                    if (unit) {
                      product.Price = unit.Price;
                      product.Unit = unit.id;
                      product.UnitName = unit.text;
                      product.PriceText = $this.rootServices.currencyPipe.transform(product.Price, 'VND');
                    }
                  } 

                  product.PriceText = $this.currencyPipe.transform(product.Price || 0, 'VND');
                  product.FeaturePicture = product.FeaturePicture && product.FeaturePicture.OriginImage ? product.FeaturePicture : {
                    ThumbnalImage: 'assets/images/no-image-available.png',
                    SmallImage: 'assets/images/no-image-available.png',
                    OriginImage: 'assets/images/no-image-available.png',
                  } as any;
                  return product;
                });
              });

              self.$setState({
                profile: {
                  ...userInfo,
                  Core: coreDomain,
                  info: profileInfo,
                  products: profileProducts,
                  subscriptionProducts: subscriptionProducts,
                  links: profileLinks,
                  csrInfo: csrInfo,
                  tageName: userInfo.TagName,
                },
                itself: userInfo.Code == $this.rootServices?.authService?.getUser()?.id,
                title: userInfo.Name,
                backTitle: self.backTitle || 'Back'
                // backTitle: userInfo.Name,
              });
              currentState.loaded$ && currentState.loaded$.next(true);
            });
            done && done();
            return true;
          },
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          pageInit(e, page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;

            $this.onComponentInit({ instance: self }, self.$route.params?.id).then(currentState => {

              // console.log(memberInfo);
              $this.rootServices.authService.isAuthenticatedOrRefresh().pipe(take(1)).toPromise().then(() => {
                self.refresh();
              }).catch(err => {
                console.error(err);
                $this.commonService.showError(err);
              });
            });

          },
          // pageBeforeIn(e, page) {
          //     console.log('page before in');
          // },
          // pageAfterIn(e, page) {
          //     console.log('page after in');
          // },
          // pageBeforeOut(e, page) {
          //     console.log('page before out');
          // },
          // pageAfterOut(e, page) {
          //     console.log('page after out');
          // },
          // pageBeforeUnmount(e, page) {
          //     console.log('page before unmount');
          // },
          // pageBeforeRemove(e, page) {
          //     console.log('page before remove');
          // },
          pageBeforeRemove(e, page) {
            console.log('[page event] before remove', page.route.url);
            const self: F7ComponentContextExtend = this;
            const smartSelectRoles = self.$app.smartSelect.get('.smart-select-roles');
            smartSelectRoles && smartSelectRoles.destroy();
          },
        },
      },
    };
  }
}
