import { ChatRoom } from 'vendors/smart-bot-app/src/app/lib/nam-chat/chat-room';
import { AppComponentProcessor } from './../app.component.processor';
import { ComponentState } from 'vendors/smart-bot-app/src/app/lib/base-component';
import { Router } from 'framework7/build/core/modules/router/router';
import { Messages } from 'framework7/build/core/components/messages/messages';
import { F7Messagebar, F7ComponentContext, F7Page } from '../types/framework7-types';
import { BaseComponent } from 'vendors/smart-bot-app/src/app/lib/base-component';
import { Message, Attachment } from 'vendors/smart-bot-app/src/app/lib/nam-chat/model/message';
import { IChatRoomContext } from 'vendors/smart-bot-app/src/app/lib/nam-chat/chat-room';
import { RootServices } from '../services/root.services';
import { DatePipe } from '@angular/common';
import { ImagePicker } from '@ionic-native/image-picker/ngx';
import { ImageResizerOptions } from '@ionic-native/image-resizer/ngx';
import { CameraOptions } from '@ionic-native/camera/ngx';
import { FileModel } from 'vendors/smart-bot-app/src/app/model/file.model';
import { isPlatform } from '@ionic/angular';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { IMySocketContext, JWTToken } from 'vendors/smart-bot-app/src/app/lib/nam-socket/my-socket';
import { HelpdeskTicketModel } from 'vendors/smart-bot-app/src/app/model/helpdesk.model';
import { ChatRoomModel } from 'vendors/smart-bot-app/src/app/model/chat-room.model';
import { RoomInfoModel } from 'vendors/smart-bot-app/src/app/lib/nam-chat/model/room-info';
import { CommonService } from 'vendors/smart-bot-app/src/app/services/common.service';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import * as moment from 'moment';
import { Dialog } from 'framework7/build/core/components/dialog/dialog';
import { Member } from 'vendors/smart-bot-app/src/app/lib/nam-chat/model/member';
import * as _ from 'underscore';
import Autolinker from 'vendors/smart-bot-app/vendors/AutolinkerJS/src/index';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { FileTransfer, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { MediaFile, CaptureError, CaptureVideoOptions } from '@ionic-native/media-capture/ngx';
import { Coordinates } from '@ionic-native/geolocation/ngx';
import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { DocumentViewerOptions } from '@awesome-cordova-plugins/document-viewer/ngx';
import { RecentLoginModel } from 'vendors/smart-bot-app/src/app/model/recent-login.model';
import { environment } from 'src/environments/environment';
// import * as $ from 'jquery';
declare const $: any;

// declare const $: any;
declare const textFit: any;

export interface ComponentStateExtend extends ComponentState {
    chatRoom?: ChatRoom;
    instance?: F7ComponentContextExtend;
    attachments?: string[];
    selectedAttachments?: Attachment[];
    messageText?: string;
    messageTags?: any[];
    lastMessage?: { [key: string]: Message };
    lastOffset?: number;
    messageList?: Message[];
    isFullLoad?: boolean;
    htmlCache?: string;
    newMessagesCounter?: number;
    readMessagesQueue?: Element[];
    referenceChatRooms?: ChatRoomModel[];
    recferenceChatRoomsLastUpdate?: number;
    quoteMessage?: Message,
    activate?: boolean,
    ready$?: BehaviorSubject<boolean>;
    activeMessage?: Message,
}

export interface PickerFile {
    url: string | ArrayBuffer;
    name?: string;
    ext: string;
    type: string;
    size?: number;
    dataType: 'url' | 'base64' | 'array-buffer';
    thumbnail?: string;
}

export class GuiMessage {
    type?: 'sent' | 'received';
    avatar?: string;
    name?: string;
    header?: string;
    textHeader?: string;
    text?: string;
    textFooter?: string;
    footer?: string;
    isTitle?: boolean;
    image?: string;
    imageSrc?: string;
}

export class F7ComponentContextExtend extends F7ComponentContext {

    messagebar?: F7Messagebar & { selectedAttachments: Attachment[] };
    messageInput?: any;
    messages?: Messages.Messages;
    images?: string[];
    responseInProgress?: boolean;
    answers?: string[];
    people?: { name?: string, avatar?: string }[];
    $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, description?: string } };

    textColorClass?: string;

    messageInfoDialog?: Dialog.Dialog;
    membersChoosedDialog?: Dialog.Dialog;
    createTicketDialog?: Dialog.Dialog;

    // data
    chatRoomId?: string;
    socket?: ChatRoom;

    roomInfo?: ChatRoomModel;
    ticket?: HelpdeskTicketModel;
    isFullLoad?: boolean;
    $setState: (mergeState?: {
        [key: string]: any,
        roomInfo?: ChatRoomModel,
        isFullLoad?: boolean,
        lastUpdate?: number,
        title?: string,
        backTitle?: string;
        ticket?: HelpdeskTicketModel,
        textColorClass?: string,
        readMessageMembers?: Member[],
        memberNonGroupList?: Member[],
        groups?: Member[],
        contacts?: Member[],
    }) => void;

    memberNonGroupList?: Member[];
    groups?: Member[];
    contacts?: Member[];

    // methods
    hideToolbar?: () => void;
    sheetToggle?: () => void;
    deleteAttachment?: (e: MouseEvent, index: number) => void;
    handleAttachment?: (e: MouseEvent) => void;
    checkAttachments?: () => void;
    sendMessage?: () => void;
    replyMessage?: (e: any, option?: { defaultContent?: string }) => void;
}

/** Component group manager */
export class ChatRoomComponent extends BaseComponent<ComponentStateExtend> implements IChatRoomContext, IMySocketContext {

    title = 'Tin Nhắn';
    protected autolinker: Autolinker;

    env = environment;

    constructor(
        public rootServices: RootServices,
        public commonService: CommonService,
        public datePipe: DatePipe,
        public imagePicker: ImagePicker,
        public iab: InAppBrowser,
        public fileTransfer: FileTransfer,
        public file: File,
        public appComponent: AppComponentProcessor,
    ) {
        super(rootServices);
        // // console.log('Click here to open ts file');

        this.autolinker = new Autolinker({
            urls: {
                schemeMatches: true,
                wwwMatches: true,
                tldMatches: true
            },
            email: true,
            phone: true,
            mention: false,
            hashtag: false,

            stripPrefix: false,
            stripTrailingSlash: true,
            newWindow: true,
            truncate: {
                length: 0,
                location: 'end'
            },

            className: 'autolink link'
        });

        this.rootServices.platform.pause.subscribe(() => {
            for (const id of Object.keys(this.state)) {
                if (this.state[id].chatRoom) {
                    this.state[id].chatRoom.deactive();
                    this.state[id].ready$ && this.state[id].ready$.next(false);
                }
            }
            this.currentState && this.currentState.instance && this.currentState.instance.messagebar && this.currentState.instance.messagebar.$el.find('textarea').blur();
        });
        this.rootServices.platform.resume.subscribe(() => {
            // for (const id of Object.keys(this.state)) {
            //     if (this.state[id].chatRoom) {
            //         this.state[id].chatRoom.active();
            //     }
            // }
            if (this.currentState && this.currentState.instance && this.currentState.instance.$route && this.currentState.chatRoom) {
                const instance = this.currentState.instance;
                const chatRoomView = instance.$router.view;
                const currentView = this.rootServices.f7app.views.current;

                // Same view with current view
                if ((currentView as any).selector === (chatRoomView as any).selector) {

                    // On top of view
                    if (instance.$router.view.history[instance.$router.view.history.length - 1] === instance.$route.url) {
                        this.currentState.ready$.next(false);
                        this.currentState.chatRoom.active().then(() => {
                            console.log('active ontop chat room');
                            // this.loadLastMessage(this.currentState, this.currentState.page.$el.find('.messages-ghost'));
                        }).catch(err => {
                            console.error('Erron when active ontop chat room');
                        });
                    }
                }

            }
        });

        // if (isPlatform('android')) {
        this.rootServices.keyboard.onKeyboardWillShow().subscribe(info => {
            if ($('.page-current').is('.page-chat-room')) {
                console.log(info);
                $(this.currentState.instance.el).find('.messagebar')
                    .css({ bottom: '0px' });
                this.currentState.instance.$app.toolbar.hide('.toolbar.toolbar-bottom', false);
            }
        });
        this.rootServices.keyboard.onKeyboardWillHide().subscribe(info => {
            if ($('.page-current').is('.page-chat-room')) {
                console.log(info);
                $(this.currentState.instance.el).find('.messagebar')
                    .css({ bottom: isPlatform('ios') ? '50px' : '56px' });
                this.currentState.instance.$app.toolbar.show('.toolbar.toolbar-bottom', false);
            }
        });
        // }
    }
    getLoginInfo(): { [key: string]: any; token: string; user: { [key: string]: any; id: string; name: string; }; } {
        return {
            user: this.rootServices.authService.user$.getValue(),
            token: this.rootServices.apiService.token.access_token,
        };
    }
    refreshToken(): Promise<string> {
        return this.rootServices.authService.refreshToken().pipe(take(1), takeUntil(this.currentState.destroy$), map(t => t.getPayload().access_token)).toPromise();
    }

    showInsideNotification(title: string, subtitle: string, content: string, namespace: string, option?: { core?: string, from?: { id: string, name: string }, [key: string]: any }) {
        if (this.rootServices.backgroundMode.isActive()) {
            if (!this.currentState || namespace !== this.currentState.id) {
                return false;
            }
        } else {
            if (!this.currentState || namespace !== this.currentState.id) {
                // Push virtual notification
                this.rootServices.f7app.notification.create({
                    icon: '<i class="icon demo-icon">7</i>',
                    title: title || (option && option.from.name) || undefined,
                    titleRightText: 'now',
                    subtitle: subtitle,
                    text: content,
                    closeTimeout: 10000,
                    closeOnClick: true,
                    on: {
                        click: () => {
                            const token = this.rootServices.authService.token$.getValue().getPayload();
                            if (option && option.core && token.core !== option.core) {

                                this.rootServices.storage.get('recent_logins').then((recentLogins: RecentLoginModel[]) => {
                                    recentLogins = recentLogins.filter(f => f.id != this.rootServices.authService.token$.value?.getPayload().api_url + this.rootServices.authService.user$.value?.id).map(item => {
                                        item.coreName = new URL(item.url).host;
                                        item.avatar = item.url + '/v3/user/users/' + item.user + '/avatar';
                                        item.banner = item.url + '/v3/system/settings/banner';
                                        return item;
                                    });

                                    const relativeLogins = recentLogins.filter(f => f.core == option.core);
                                    if (relativeLogins.length > 0) {
                                        this.rootServices.switchAccount(relativeLogins.map(m => m.id), { message: 'Thông báo từ máy chủ khác, bạn có muốn chuyển máy chủ không ?' }).then(rs => {
                                            this.navigate('/chat-room/' + namespace, { view: this.rootServices.f7app.views.current });
                                        });
                                    } else {
                                        this.commonService.showError('Bạn chưa đăng nhập trên máy chủ này !');
                                    }
                                }).catch(err => {
                                    console.error(err);
                                    this.commonService.showError(err);
                                    return [];
                                });
                                // this.rootServices.f7app.dialog.confirm('Thông báo từ máy chủ khác, bạn có muốn chuyển máy chủ không ?', 'Thông báo', () => {
                                //     // this.appComponent.settingsComponent.currentState &&
                                //     //     this.appComponent.settingsComponent.currentState.instance &&
                                //     //     this.appComponent.settingsComponent.currentState.instance.switchAccount();



                                // });
                            } else {
                                this.navigate('/chat-room/' + namespace, { view: this.rootServices.f7app.views.current });
                            }
                        },
                    },
                }).open();

                return true;
            } else {
                // check view
                const currentView = this.rootServices.f7app.views.current;
                const currentRoute = this.currentState.page.route;
                const thisView = this.currentState.page.view;
                if ((thisView as any).selector !== (currentView as any).selector
                    || currentView.router.history.lastIndexOf(currentRoute.url) < currentView.router.history.length - 1) {
                    this.rootServices.f7app.notification.create({
                        icon: '<i class="icon demo-icon">7</i>',
                        title: title || (option && option.from.name) || undefined,
                        titleRightText: 'now',
                        subtitle: subtitle || undefined,
                        text: content || undefined,
                        closeTimeout: 10000,
                        closeOnClick: true,
                        on: {
                            click: () => {
                                this.navigate('/chat-room/' + namespace, { view: this.rootServices.f7app.views.current });
                            },
                        },
                    }).open();
                    return true;
                }
            }
        }
        return false;
    }

    get user() {
        return this.rootServices.authService.getUser();
    }

    async getAuthenticateToken(): Promise<JWTToken> {
        // console.debug('getAuthenticateToken');
        const token = (await this.rootServices.authService.getToken()).getPayload();
        return token;
    }
    getLastMesssage(): Message {
        console.debug('on getLastMesssage');
        return null;
    }
    getTopMessageNo(chatRoom: ChatRoom) {
        const currentState = this.state[chatRoom.id];
        if (!currentState?.instance?.messages?.el) {
            throw Error('chat room instance was destroy !!');
        }
        const topMessage = $(this.currentState.instance.messages.el).find('.message:not(.message-typing):not(.message-sending):first');
        let topIndex = topMessage.attr('globalid');
        if (topIndex) {
            topIndex = topIndex.split('/').pop();
        }
        return topIndex;
    }
    getEndMessageNo(chatRoom: ChatRoom) {
        const currentState = this.state[chatRoom.id];
        if (!currentState?.instance?.messages?.el) {
            throw Error('chat room instance was destroy !!');
        }
        const topMessage = $(this.currentState.instance.messages.el).find('.message:not(.message-typing):not(.message-sending):last');
        let endIndex = topMessage.attr('globalid');
        if (endIndex) {
            endIndex = endIndex.split('/').pop();
        }
        return endIndex;
    }
    isLive(chatRoom: ChatRoom) {
        const currentState = this.state[chatRoom.id];
        if (!currentState?.instance?.messages?.el) {
            throw Error('chat room instance was destroy !!');
        }
        return true;
    }
    async onChatRoomInit(): Promise<boolean> {
        console.debug('on onChatRoomInit');
        return true;
    }
    async onChatRoomConnect(): Promise<boolean> {
        console.debug('onChatRoomConnect');
        return true;
    }
    async onChatRoomReconnect(): Promise<boolean> {
        console.debug('onChatRoomReconnect');
        return true;
    }
    async onNewMessage(newMessage: Message): Promise<boolean> {
        const currentState = this.state[newMessage.ChatRoom];
        if (!currentState || !currentState.instance || !currentState.instance.messages) {
            return false;
        }
        // Wait for chat room load complete
        await currentState.ready$.pipe(filter(state => state === true), take(1)).toPromise();
        try {
            // Check token and refresh before add message (attachment)
            if (newMessage.Attachments && newMessage.Attachments.length > 0) {
                await this.rootServices.authService.isAuthenticatedOrRefresh().pipe(take(1), takeUntil(currentState.destroy$)).toPromise();
            }
            currentState.instance.messages.hideTyping();
            currentState.previousMessageTyping = null;
            currentState.newMessagesCounter++;
            const messageGlobalId = `${newMessage.ChatRoom}/${newMessage.No}`;
            this.addMessage(newMessage, 'append', true);
            const pageContent = currentState.page.$el[0].children[1];
            if (pageContent) {
                if (pageContent.scrollTop + pageContent.clientHeight + 500 > pageContent.scrollHeight) {
                    const newMessageEle = currentState.instance.messages.$el.find('[globalId="' + messageGlobalId + '"]');
                    await this.rootServices.waiting(500);
                    await this.scrollToMessage(newMessageEle, 100, { namespace: newMessage.ChatRoom });
                } else {
                    // Notify had new message at bottom
                }
            }
            currentState.lastMessage[currentState.page.view.id] = newMessage;
            this.updateMessageReadState();
        } catch (err) {
            console.error(err);
            this.commonService.showError(err);
        }
        return true;
    }

    async restoreMessages(namespace: string, messages: Message[]): Promise<boolean> {
        const currentState = this.state[namespace];
        console.debug(`[${currentState.id}] restoreMessages...`, messages);
        if (!currentState) {
            return false;
        }
        for (const messageToAppend of messages) {
            if (!this.addMessage(messageToAppend, 'append', true)) {
                return false;
            }
        }
        this.updateMessageReadState();
        return true;
    }

    renderMessageText(content: string) {
        return content.replace(/([\@\#])\[([^\[\]]+)\]\((\w+)\:(\w+)\)/g, '<a class="mention link" data-tagtype="$1" data-type="$3" data-id="$4">$2</a>').replace(/\n/g, '<br>');
    }

    renderMessageQuoteText(content: string) {
        return content.replace(/([\@\#])\[([^\[\]]+)\]\((\w+)\:(\w+)\)/g, '<a class="mention" data-tagtype="$1" data-type="$3" data-id="$4">$2</a>');
    }

    async addMessage(message: Message, method: 'append' | 'prepend', animate: boolean) {
        const $this = this;
        const currentState = this.state[message.ChatRoom];

        try {
            if (!currentState || !currentState.instance || !currentState.instance?.messages?.messages) {
                return false;
            }
            const currentUser = this.rootServices.authService.getUser();
            const images = this.createThumbnailWrap(message.Attachments, message.ChatRoom, message.No);
            const messageGlobalId = `${message.ChatRoom}/${message.No}`;
            const isSentMsg = message.From.id === currentUser.id;
            const avatar = !isSentMsg && message.From && message.From.Avatar;

            // Quote message
            let text = this.renderMessageText(message.Text);

            text = this.autolinker.link(text);

            let quoteFromName = '';
            let quoteMessageDate = '';
            let quoteContent = '';
            let quoteAttachment = '';
            if (message.ReplyQuote) {
                quoteFromName = message.ReplyQuote.From && message.ReplyQuote.From.name;
                quoteMessageDate = message.ReplyQuote.DateOfPost && $this.formatMomentDateTime(message.ReplyQuote.DateOfPost);
                quoteContent = this.renderMessageText(message.ReplyQuote.Text);
                quoteContent = this.autolinker.link(quoteContent.replace(/\n/g, '<br>'));
                quoteAttachment = message.ReplyQuote.Attachments && this.createThumbnailWrap(message.ReplyQuote.Attachments, message.ReplyQuote.ChatRoom, message.ReplyQuote.No, { prefixId: message.No.toString() });
                text = `<div class="message-quote" namespace="${message.ReplyQuote.ChatRoom}" index="${message.ReplyQuote.No}">${quoteFromName}: ${quoteMessageDate}<br>${quoteContent}<br><div class="message-quote-image">${quoteAttachment}<div class="message-quote-image-clearfix"></div></div></div><div class="message-main-text">` + text + '</div>';
            }

            //Todo : Next update: only check for append mode
            let msgEle = currentState.instance.messages.$el.find(`.message[globalId="${messageGlobalId}"]`);
            if (msgEle && msgEle.length > 0) {
                console.error('dupplicate message detected !!!');
                return false;
            }

            currentState.instance.messages.addMessage({
                type: isSentMsg ? 'sent' : 'received',
                // avatar: avatar || undefined,
                avatar: (message?.From?.Type || message?.From?.type) == 'CONTACT' ? (avatar || undefined) : (!isSentMsg && this.rootServices.apiService.getBaseApiUrl() + '/user/users/' + message?.From?.id + '/avatar' || undefined),
                name: message.From.Name,
                header: null,
                textHeader: null,
                text: text,
                textFooter: null,
                // footer: this.datePipe.transform(message.date, 'short'),
                footer: (message.State && `${message.State}<br>` || '') + $this.formatMomentDateTime(message.DateOfPost),
                isTitle: false,
                image: images,
                imageSrc: '',
                cssClass: this.calculateImagesWrap(message.Attachments && message.Attachments.length || 0),
                attrs: {
                    globalId: messageGlobalId,
                    read: message.ReadState && message.ReadState[this.rootServices.authService.user$.getValue().id] ? 1 : '',
                    date: message.DateOfPost,
                },
            } as any, method || 'append', animate);
            msgEle = currentState.instance.messages.$el.find(`.message[globalId="${messageGlobalId}"]`);
            msgEle.append('<div class="message-more-btn"><i class="f7-icons link">ellipsis_circle_fill</i></div>');
            if (quoteAttachment) {
                msgEle.addClass('had-contain-even-image');
            }
            if (!method || method === 'append') {
                currentState.lastMessage[currentState.page.view.id] = message;
            }
            msgEle.find('.message-content').append('<span class="readstate" msgtype="' + (isSentMsg ? 'sent' : 'received') + '" globalId="' + messageGlobalId + '" read="' + (message.ReadState && message.ReadState[this.rootServices.authService.user$.getValue().id] ? 1 : '') + '"></span>');
            this.applyMessageBehavior(msgEle);
            return msgEle;
        } catch (err) {
            console.error(err);
            $this.commonService.showError(err);
            return null;
        }
    }

    async openMessageInfoDialog(msgEl: any) {
        const $this = this;
        const $ = this.currentState.instance.$;
        const msgIndex = parseInt(msgEl.attr('globalid').split('/')[1]);
        const message = this.currentState.chatRoom.messageList.find(msg => msg.No === msgIndex);
        this.currentState.activeMessage = message;
        const dialog = this.currentState.instance.messageInfoDialog;
        const readMessageMembers = (await this.currentState.chatRoom.getMessageReadState(msgIndex)).filter(f => !!f);
        if (!message.PlanText) {
            message.PlanText = $this.currentState.chatRoom.convertToPlanText(message.Text);
        }
        this.currentState.instance.$setState({
            messageText: message && message.PlanText.replace(/\n/g, '<br>'),
            mesageSender: [message.From].map(member => {
                // const member = memberList[userCode];
                return {
                    ...member,
                    // avatar: member.type == 'CONTACT' ? (member?.Avatar?.payload?.thumbnail || member.Avatar) : ($this.rootServices.apiService.getBaseApiUrl() + '/user/users/' + member.id + '/avatar'),
                    AvatarThumbnail: member.Avatar,
                    Time: moment(message && message.DateOfPost || Date.now()).fromNow(),
                    Fulldate: this.datePipe.transform(message && message.DateOfPost || Date.now(), 'short'),
                };
            })[0],
            readMessageMembers: readMessageMembers && readMessageMembers instanceof Array && readMessageMembers.sort((a, b) => b.Time - a.Time).map(readStateMembers => {
                // const member = memberList[userCode];
                return {
                    ...readStateMembers,
                    // avatar: member.Type == 'CONTACT' ? (member?.Avatar?.payload?.thumbnail || member.Avatar) : ($this.rootServices.apiService.getBaseApiUrl() + '/user/users/' + member.id + '/avatar'),
                    Time: moment(message && message.DateOfPost || Date.now()).fromNow(),
                    Fulldate: this.datePipe.transform(message && message.DateOfPost || Date.now(), 'short'),
                };
            }) as any,
        });
        setTimeout(() => {
            dialog.$el.data('msgindex', message.No);
            dialog.open();
            this.currentState.instance.$(dialog.el).find('a.mention').click((e: MouseEvent) => {
                const memberId = e.target['attributes']['data-id'].value;
                $this.rootServices.navigate('/profile/' + memberId, {
                    context: {
                        backTitle: $this.title,
                        memberInfo: $this.currentState.chatRoom.memberList$.getValue().find(f => f.id === memberId),
                        chatRoom: $this.currentState.chatRoom,
                    }
                });
                dialog.close();
            });
        }, 300);
        return dialog;
    }

    async openMembersChoosedDialog(message: Message) {
        const $this = this;
        const $ = this.currentState.instance.$;
        let memberList = await this.currentState.chatRoom.memberList$.pipe(takeUntil(this.currentState.destroy$), filter(f => !!f), take(1)).toPromise();
        memberList = memberList.filter(f => f.id !== this.rootServices.authService.user$.getValue().id);
        const nonGroupMembers = memberList.filter(member => member.Type !== 'CONTACT' && !member.Group);
        const groupMembers = memberList.filter(member => member.Type !== 'CONTACT' && member.Group);
        const groups = memberList.filter(member => member.Type !== 'CONTACT' && member.Group && !member.User);
        const contacts = memberList.filter(member => member.Type === 'CONTACT');
        groups.map(group => {
            group.Children = groupMembers.filter(member => member.Type === 'USER' && (member.Group && member.Group.id || member.Group) === group.id);
        });

        this.currentState.instance.$setState({
            contacts: contacts.map(member => {
                return {
                    ...member,
                    // AvatarThumbnail: $this.rootServices.apiService.getBaseApiUrl() + '/user/users/' + member.id + '/avatar',
                    Roles: member.Roles && member.Roles.map(role => $this.currentState.roles && $this.currentState.roles[role] && $this.currentState.roles[role].text || role) as any,
                };
            }),
            memberNonGroupList: nonGroupMembers.map(member => {
                return {
                    ...member,
                    AvatarThumbnail: $this.rootServices.apiService.getBaseApiUrl() + '/user/users/' + member.id + '/avatar',
                    Roles: member.Roles && member.Roles.map(role => $this.currentState.roles && $this.currentState.roles[role] && $this.currentState.roles[role].text || role) as any,
                };
            }),
            groups: groups.map(group => {
                group.Children = group.Children.map(member => {
                    return {
                        ...member,
                        AvatarThumbnail: $this.rootServices.apiService.getBaseApiUrl() + '/user/users/' + member.id + '/avatar',
                        Roles: (member.Roles) && member.Roles.map(role => $this.currentState.roles[role].text) as any,
                    };
                });
                return group;
            }),
        });

        const dialog = this.currentState.instance.membersChoosedDialog;
        dialog.$el.find('.dialog-text').html(message && message.Text && this.renderMessageText(message.Text));

        setTimeout(() => {
            dialog.$el.data('msgindex', message.No);
            dialog.open();
            this.currentState.instance.$(dialog.el).find('a.mention').click((e: MouseEvent) => {
                const memberId = e.target['attributes']['data-id'].value;
                $this.rootServices.navigate('/profile/' + memberId, {
                    context: {
                        backTitle: $this.title,
                        memberInfo: $this.currentState.chatRoom.memberList$.getValue().find(f => f.id === memberId),
                        chatRoom: $this.currentState.chatRoom,
                    }
                });
                dialog.close();
            });
        }, 300);
        return dialog;
    }

    async goToMessage(msgIndex: number) {
        let originMsgEle = this.currentState.instance.messages.$el.find('.message[globalid="' + this.currentState.id + '/' + msgIndex + '"]');
        if (originMsgEle.length > 0) {
            this.scrollToMessage(originMsgEle, null, { alignTop: true });
            originMsgEle.addClass('dim');
            setTimeout(() => {
                originMsgEle.removeClass('dim');
            }, 2000);
        } else {
            const pageContentEl = this.currentState.instance.$$(this.currentState.page.el).find('.page-content');

            while (!this.currentState.instance.isFullLoad) {
                pageContentEl.scrollTo(0, 0, 300);
                await this.commonService.waiting(1000);
                originMsgEle = this.currentState.instance.messages.$el.find('.message[globalid="' + this.currentState.id + '/' + msgIndex + '"]');
                if (originMsgEle.length > 0) {
                    break;
                }
            }
            this.scrollToMessage(originMsgEle, null, { alignTop: true });
            originMsgEle.addClass('dim');
            originMsgEle.removeClass('dim');
        }
    }

    applyMessageBehavior(msgEle: any) {
        const $this = this;
        const msgImageEles = msgEle.find('.message-bubble-img-wrap');
        const msgFooterEl = msgEle.find('.message-footer');
        const msgDate = msgFooterEl.attr('date');
        if (msgDate) {
            msgFooterEl.html($this.formatMomentDateTime(msgDate));
        } else {
            msgFooterEl.attr('date', msgEle.attr('date'));
        }

        // Goto mention member profile
        msgEle.find('a.mention').click((e) => {
            const tagId = e.target.attributes['data-id'].value;
            const tagType = e.target.attributes['data-tagtype'].value;
            if (tagType === '@') {
                const member = $this.currentState.chatRoom.memberList$.getValue().find(f => f.id === tagId);
                if (!member) {
                    $this.commonService.showError('Thành viên/liên hệ không có thong task này !');
                } else {
                    $this.rootServices.navigate('/profile/' + tagId, {
                        context: {
                            backTitle: $this.title,
                            memberInfo: member,
                            chatRoom: $this.currentState.chatRoom,
                        }
                    });
                }
            } else if (tagType === '#') {
                $this.rootServices.navigate('/chat-room/' + tagId, {
                    context: {
                        backTitle: $this.title,
                    }
                });
            }
        });

        if (false && this.rootServices.isMobile) { // Tmp disabled
            let checkdown = null;
            msgEle.on('mousedown', (e) => {
                checkdown = setTimeout(async () => {
                    const dialog = $this.openMessageInfoDialog(msgEle);
                }, 300)
            }).on('mouseup', () => {
                clearTimeout(checkdown);
            }).on('mouseout', () => {
                clearTimeout(checkdown);
            }).on('mousemove', () => {
                clearTimeout(checkdown);
            });

            msgEle.on('touchstart', (e) => {
                checkdown = setTimeout(async () => {
                    const dialog = $this.openMessageInfoDialog(msgEle);
                }, 300)
            }).on('touchend', () => {
                clearTimeout(checkdown);
            }).on('touchmove', () => {
                clearTimeout(checkdown);
            });
        }

        // Apply attachments browser event
        msgImageEles.each((index: number, msgImageEle: HTMLElement) => {
            $this.currentState.instance.$$(msgImageEle).click(async function () {
                console.debug('image click');
                let attId = null;
                for (let i = 0; i < 5; i++) {
                    attId = this.attributes?.attachmentglobalid?.value;
                    if (attId) {
                        break;
                    }
                    console.log('Waiting for attachment global id available');
                    await $this.rootServices.waiting(300);
                }
                if (!attId) {
                    console.error('attachment global id was not available');
                    return false;
                }
                let attachments = $this.currentState.chatRoom.attachments$.getValue();
                let attachment = attachments.find(f => f.Id === attId);
                if (!attachment) {
                    await $this.currentState?.chatRoom.syncAttachmentList();
                    attachments = $this.currentState.chatRoom.attachments$.getValue();
                    attachment = attachments.find(f => f.Id === attId);
                }
                console.log(`attachment global id ${attId}`);
                if (attachment) {

                    const pdfFileName = attachment.File.split('/').pop();
                    const extension = pdfFileName.split('.').pop();

                    if (/video/.test(attachment.Type)) {
                        $this.playVideo((attachment.OriginUrl || attachment.Url) as string);
                    } else if (/image/.test(attachment.Type)) {
                        $this.browseAttachment(attachments, attId, (attachment => {
                            console.debug('download attachment', attachment);
                            if (/image/.test(attachment.Type)) {
                                $this.currentState.instance.$app.dialog.confirm([attachment.Name, attachment.Description].filter(f => !!f).join('<br>') || 'Đính kèm', 'Xác nhận tải về', async () => {
                                    $this.rootServices.saveImageToPhone(attachment.Url as string);
                                });
                            } else if (/video/.test(attachment.Type)) {
                                $this.playVideo((attachment.OriginUrl || attachment.Url) as string);
                            } else {
                                $this.iab.create((attachment.OriginUrl || attachment.Url) as string, '_system');
                            }
                        })).then(() => {
                            console.debug('Photo browser was closed');
                        });
                    } else if (/pdf/.test(extension)) {
                        $this.commonService.showPreloader();
                        console.log($this.rootServices.file);
                        const fileTransfer: FileTransferObject = $this.rootServices.transfer.create();
                        fileTransfer.download(attachment.OriginUrl as string, $this.rootServices.file.cacheDirectory + pdfFileName)
                            .then(entry => {
                                console.log(entry);
                                $this.commonService.hidePreloader();
                                // $this.rootServices.document.viewDocument($this.rootServices.file.cacheDirectory + pdfFileName, 'application/pdf', {
                                //     // title: ''
                                // })
                                $this.rootServices.fileOpener.open($this.rootServices.file.cacheDirectory + pdfFileName, attachment.Type).catch(err => {
                                    $this.iab.create(attachment.Url as string, '_system');
                                });

                            }).catch(err => {
                                $this.commonService.showError(err);
                                $this.commonService.hidePreloader();
                            });
                    } else {
                        $this.currentState.instance.$app.dialog.confirm([attachment.Name, attachment.Description].filter(f => !!f).join('<br>') || 'Đính kèm', 'Xác nhận tải về', async () => {
                            $this.iab.create(attachment.Url as string, '_system');
                            // $this.iab.create(attachment.Url as string);
                        });
                    }
                }
            });
        });

        msgEle.find('.message-quote > a.autolink').each((i, el) => {
            const $el = this.currentState.instance.$(el);
            $el.attr('href', '');
        });

        msgEle.find('.message-text > a.autolink, .message-main-text > a.autolink').each((i, el) => {
            const $el = this.currentState.instance.$(el);
            let link = $el.attr('href');
            if (link) {
                $el.attr('data-href', link);
                $el.attr('href', '');
            } else {
                link = $el.attr('data-href');
            }
            if ($el.is('.link-phone')) {
                link = link.replace(/^tel:/, '');
            }
            if ($el.is('.link-email')) {
                link = link.replace(/^mailto:/, '');
            }
            $el.click((e: MouseEvent) => {
                if ($el.is('.link-phone') || $el.is('.link-email')) {
                    this.rootServices.clipboard.copy(link);
                    this.commonService.showInfo('đã copy: ' + link);
                } else {
                    console.log(link);
                    this.currentState.instance.$app.dialog.create({
                        title: 'Xác nhận mở link',
                        content: 'link',
                        buttons: [
                            {
                                text: 'Copy',
                                onClick: () => {
                                    this.rootServices.clipboard.copy(link);
                                    this.commonService.showInfo('đã copy: ' + link);
                                }
                            },
                            {
                                text: 'Mở link',
                                color: 'red',
                                onClick: () => {
                                    const browser = this.iab.create(link, '_system');
                                }
                            }
                        ],
                    }).open();
                }

            });
        });

        // Message quote
        msgEle.find('.message-quote').click(async e => {
            if (e.target.className.indexOf('message-quote') < 0) {
                return;
            }
            const msgQuoteEl = this.currentState.instance.$(e.target).closest('.message-quote');
            const namespace = msgQuoteEl.attr('namespace');
            const index = parseInt(msgQuoteEl.attr('index'));
            if (namespace === this.currentState.id) {
                $this.goToMessage(index);
            } else {
                this.commonService.navigate('/chat-room/' + namespace, {
                    context: {
                        goToMessage: index,
                    }
                })
            }
        });

        msgEle.find('.message-more-btn').click(() => {
            $this.openMessageInfoDialog(msgEle);
        });

        var observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting === true) {
                const messageEl = entries[0].target;
                const readState = messageEl.attributes['read'].value;
                // if (!readState && messageEl.className.indexOf('message-received') > -1) {
                if (!readState && messageEl.attributes['msgtype'].value === 'received') {
                    console.log('readstate off ' + messageEl.attributes['globalid'].value + ': ' + readState);
                    this.currentState.readMessagesQueue.push(messageEl);
                }
            }
        }, { threshold: [1] });

        const trackingEl = msgEle.find('.readstate');
        if (trackingEl.length > 0) {
            observer.observe(trackingEl[0]);
        }
    }

    updateMessageReadState() {
        const currentState = this.currentState;
        this.commonService.takeUntil('update-message-read-state-' + currentState.id, 1000).then(() => {
            if (currentState.readMessagesQueue && currentState.readMessagesQueue.length > 0) {
                const readMessages = currentState.readMessagesQueue;
                currentState.readMessagesQueue = [];
                console.log('update messages read state');
                if (readMessages && readMessages.length > 0) {
                    currentState.chatRoom.updateMessagesReadState([...new Set(readMessages.map(messageEl => {
                        const globalId = messageEl.attributes['globalid'].value;
                        return parseInt(globalId.split('/')[1]);
                    }))]).then(rs => {
                        if (currentState.instance) {
                            for (const messageEl of readMessages) {
                                currentState.instance?.$(messageEl).attr('read', '1');
                            }
                        }
                    });
                }
            }
        });
    }

    restoreMessagesEvent() {
        const $this = this;
        const pageContentEl = $this.currentState.instance.$$($this.currentState.page.el).find('.page-content');
        const messageEls = pageContentEl.find('.message');
        messageEls.each(i => {
            this.applyMessageBehavior(this.currentState.instance.$(messageEls[i]).closest('.message'));
        });
    }

    calculateImagesWrap(listSize: number) {
        return (listSize === 0) ? '' : (listSize === 1 ? 'had-contain-1-image' : (listSize === 2 ? 'had-contain-2-image' : ('had-contain-' + (listSize % 2 === 0 ? 'even' : 'odd') + '-image')));
    }

    /** Before change state event */
    async onBeforeChangedState(state: ComponentStateExtend, index: string) {
        console.debug('onBeforeChangedState');
        const currentState = await super.onBeforeChangedState(state, index);
        try {
            currentState.attachments = currentState.instance.messagebar.attachments;
            currentState.selectedAttachments = currentState.instance.messagebar.selectedAttachments;
            currentState.messageText = currentState.instance.messagebar.getValue();
            currentState.isFullLoad = currentState.instance.isFullLoad;
        } catch (err) {
            console.debug(err);
            this.commonService.showError(err);
        }
        return currentState;
    }

    convertChatRoomTreeToArray(tree: ChatRoomModel[], level?: number) {
        let chatRoomList: (ChatRoomModel & { Level?: number, IsCurrent?: boolean })[] = [];
        for (const chatRoom of tree) {
            chatRoomList.push({ ...chatRoom, IsCurrent: chatRoom.Code === this.currentState.id, Level: level || 0 });
            if (chatRoom.Children && chatRoom.Children.length > 0) {
                chatRoomList = chatRoomList.concat(this.convertChatRoomTreeToArray(chatRoom.Children, (level || 0) + 1));
            }
        }
        return chatRoomList;
    }

    prepareMessages(self: F7ComponentContextExtend, page: F7Page) {
        return self.$app.messages.create({
            el: page.$el.find('.messages-visable'),
            firstMessageRule(message: Messages.Message, previousMessage: Messages.Message, nextMessage: Messages.Message) {
                if (message.isTitle) { return false; }
                if (!previousMessage || previousMessage.type !== message.type || previousMessage.name !== message.name) { return true; }
                return false;
            },
            lastMessageRule(message: Messages.Message, previousMessage: Messages.Message, nextMessage: Messages.Message) {
                if (message.isTitle) { return false; }
                if (!nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name) { return true; }
                return false;
            },
            tailMessageRule(message: Messages.Message, previousMessage: Messages.Message, nextMessage: Messages.Message) {
                if (message.isTitle) { return false; }
                if (!nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name) { return true; }
                return false;
            },
            sameFooterMessageRule(message: Messages.Message, previousMessage: Messages.Message, nextMessage: Messages.Message) {
                if (message.isTitle) { return false; }
                if (!nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name || nextMessage.footer !== message.footer) { return false; }
                return true;
            },
        } as Messages.Parameters);
    }

    async loadLastMessage(currentState: ComponentState, messagesGhostEl: any) {
        // return false;
        const lastMEssageEl = $(currentState.instance.messages.el).find('.message:not(.message-typing):not(.message-sending):last');
        let gloabelId: string = null;
        let newMessages = null;
        let visableMessages = null;
        if (lastMEssageEl && lastMEssageEl.length > 0 && (gloabelId = lastMEssageEl.attr('globalid'))) {
            const lastMessageIndex = parseInt(gloabelId.split('/')[1]);
            newMessages = await currentState.chatRoom.getMessagesFromIndex(lastMessageIndex);
            await this.restoreMessages(currentState.chatRoom.id, newMessages);
        } else {
            currentState.lastOffset = 0;
            currentState.isFullLoad = false;
            currentState.instance.$setState({ isFullLoad: false });
            messagesGhostEl.removeClass('hidden');
            currentState.loaded = false;

            // currentState.lastOffset = 0;
            visableMessages = await currentState.chatRoom.getMessages(currentState.lastOffset + 30, 0);
            currentState.lastOffset += 30;
            if (await this.restoreMessages(currentState.chatRoom.id, visableMessages)) {
                if (visableMessages && visableMessages.length < 30) {
                    currentState.isFullLoad = true;
                    setTimeout(() => {
                        messagesGhostEl.addClass('hidden');
                    }, 100);
                    currentState.instance.$setState({ isFullLoad: true });
                }
                currentState.loaded = true;
            }
        }
        return newMessages || visableMessages;
    }

    async onComponentInit(state: ComponentStateExtend, index: string, asCase?: string) {
        const currentState = await super.onComponentInit(state, index, asCase);
        currentState.readMessagesQueue = [];
        currentState.activate = true;
        currentState.roles = {
            CHATROOMMANAGER: {
                id: 'CHATROOMMANAGER',
                text: 'Quản lý',
            },
            COORDINATORS: {
                id: 'COORDINATORS',
                text: 'Điều phối',
            },
            HELPDESK: {
                id: 'HELPDESK',
                text: 'Hỗ trợ',
            },
            ITHELPDESK: {
                id: 'ITHELPDESK',
                text: 'Hỗ trợ IT',
            },
            SALESHELPDESK: {
                id: 'SALESHELPDESK',
                text: 'Hỗ trợ bán hàng',
            },
            ITCOORDINATORS: {
                id: 'ITCOORDINATORS',
                text: 'Điều phối IT',
            },
            SALESCOORDINATORS: {
                id: 'SALESCOORDINATORS',
                text: 'Điều phối bán hàng',
            },
            CUSTOMER: {
                id: 'CUSTOMER',
                text: 'Khách hàng',
            },
            CHATROOMMEMBER: {
                id: 'CHATROOMMEMBER',
                text: 'Thành viên',
            },
            HELPDESKCREATOR: {
                id: 'HELPDESKCREATOR',
                text: 'Người tạo yêu cầu',
            },
            CHATROOMCREATOR: {
                id: 'HELPDESKCREATOR',
                text: 'Người tạo yêu cầu',
            },
            CONTACT: {
                id: 'CONTACT',
                text: 'Liên hệ',
            },
            MONITORING: {
                id: 'MONITORING',
                text: 'Giám sát',
            },
        };
        // Variables
        const pageContentEl = currentState.instance.$$(currentState.page.el).find('.page-content');
        const messagesWrapEl = pageContentEl.find('.messages-wrap');
        let messagesGhostEl = pageContentEl.find('.messages-ghost');
        let messagesEl = pageContentEl.find('.messages-visable');

        if (!currentState.lastMessage) {
            currentState.lastMessage = {};
        }
        if (currentState.newMessagesCounter === undefined) {
            currentState.newMessagesCounter = 0;
        }
        if (!currentState.ready$) {
            currentState.ready$ = new BehaviorSubject<boolean>(false);
        } else {
            currentState.ready$.next(false);
        }

        if (currentState.isFullLoad) {
            messagesGhostEl.addClass('hidden');
            currentState.instance.$setState({ isFullLoad: true });
        }

        // Load reference chat rooms
        currentState.instance.refresh();

        const chatRoomId = currentState.id;
        if (!currentState.chatRoom) {
            if (chatRoomId) {
                // if (this.rootServices.socketService.socketServerUri === null) {
                //     throw new Error('Socket info was not ready');
                // }
                currentState.chatRoom = new ChatRoom(
                    chatRoomId,
                    this.rootServices.authService.getUser(),
                    this,
                    // this.rootServices.socketService,
                    this.rootServices,
                );
                await currentState.chatRoom.initNamespaceSocket();
            } else {
                console.warn('Chat room id was not provided !!!');
                return null;
            }
        }

        // Listen chat room event
        currentState.chatRoom.event$.pipe(takeUntil(currentState.destroy$), filter(f => !!f)).subscribe(event => {
            console.log(event);
            if (event.name === 'forbidden') {
                this.commonService.navigate(currentState.instance.$router.history[0]);
                currentState.chatRoom.destroy();
                delete this.state[currentState.id];
            }
        });

        // Wait for chat room ready
        // currentState.chatRoom.connect();
        try {
            currentState.chatRoom.rooInfo$.pipe(filter(info => !!info), takeUntil(currentState.destroy$)).subscribe(info => {
                currentState.instance.$setState({
                    title: info.Description,
                    members: info.Members,
                });

            });
        } catch (err) {
            console.log(err);
            this.commonService.showError(err);
        }
        let isFirstReady = true;
        if (currentState.loaded === undefined) {
            currentState.loaded = false;
        }

        if (currentState.loaded && currentState.htmlCache) {
            messagesEl = pageContentEl.find('.messages-visable');
            messagesGhostEl = pageContentEl.find('.messages-ghost');
            messagesEl.html(currentState.htmlCache);

            if (currentState.loaded && currentState.lastUpdate && currentState.lastUpdate + 10 * 60 * 1000 < Date.now()) {
                // Clean messsage list
                const messageEls = currentState.instance.messages.$el.find('.message:not(.message-typing):not(.message-sending)');
                const messageListSize = messageEls.length;
                let hadMessagesRemoved = false;
                messageEls.each((index, msgEl) => {
                    if (index < messageListSize - 30) {
                        currentState.instance.$(msgEl).remove();
                        hadMessagesRemoved = true;
                    }
                });
                if (hadMessagesRemoved) {
                    currentState.lastOffset = 30;
                    currentState.isFullLoad = false;
                    currentState.instance.$setState({ isFullLoad: false });
                    messagesGhostEl.removeClass('hidden');
                }

            }

            messagesWrapEl.find('.message-typing').remove();
            messagesWrapEl.find('.message-sending').remove();

            if (currentState.instance.messages) {
                currentState.instance.messages.destroy();
            }
            currentState.instance.messages = this.prepareMessages(currentState.instance, currentState.page);
            this.restoreMessagesEvent();
            if (currentState.scrollPosition) {
                currentState.instance.messages.scroll(0, currentState.scrollPosition);
            } else {
                await this.scrollToBottom(300);
            }
        }

        currentState.chatRoom.state$.pipe(filter(f => f === 'ready'), takeUntil(currentState.destroy$)).subscribe(async status => {
            try {
                currentState.ready$.next(false);
                // Check notification settings
                currentState.chatRoom.memberList$.pipe(filter(f => f && f.length > 0), take(1)).toPromise().then(memberList => {
                    const selfMember = memberList.find(f => f.id === this.rootServices.authService.user$.getValue().id);
                    if (selfMember) {
                        if (selfMember.DisabledNotification && selfMember.DisabledNotification.ENABLEDONRETURN) {
                            currentState.chatRoom.updateMemberNotificationSettings({ DISABLEDNEWMESSAGE: false, DISABLEDMENTION: false, DISABLEDREPLY: false });
                        }

                        // Reset notification settings after ENABLEDONTOMORROW timeout
                        if (selfMember.DisabledNotification && selfMember.DisabledNotification.ENABLEDONTOMORROW) {
                            const checkPoint = selfMember.DisabledNotification.ENABLEDONTOMORROW;
                            if (checkPoint && (checkPoint + 24 * 60 * 60) * 1000 < Date.now()) {
                                currentState.chatRoom.updateMemberNotificationSettings({ DISABLEDNEWMESSAGE: false, DISABLEDMENTION: false, DISABLEDREPLY: false });
                            }
                        }
                    }
                });

                if (isFirstReady) {
                    isFirstReady = false;
                    if (!currentState.lastOffset) {
                        currentState.lastOffset = 0;
                    }

                    // Restore messages
                    let newMessages = null;
                    let visableMessages = null;
                    if (currentState.loaded && currentState.htmlCache) {
                        newMessages = await this.loadLastMessage(currentState, messagesGhostEl);

                    } else {
                        currentState.lastOffset = 0;
                        // const topMessage = $(currentState.instance.messages.el).find('.message:not(.message-typing):not(.message-sending):first');
                        // let topIndex = topMessage.attr('globalid');
                        //     if(topIndex) {
                        //         topIndex = topIndex.split('/').pop();
                        //     }
                        visableMessages = await currentState.chatRoom.getMessages(currentState.lastOffset + 30, 0, this.getTopMessageNo(currentState.chatRoom));
                        currentState.lastOffset += 30;
                        if (await this.restoreMessages(currentState.chatRoom.id, visableMessages)) {
                            if (visableMessages && visableMessages.length < 30) {
                                currentState.isFullLoad = true;
                                setTimeout(() => {
                                    messagesGhostEl.addClass('hidden');
                                }, 100);
                                currentState.instance.$setState({ isFullLoad: true });
                            }
                            currentState.loaded = true;
                        }
                    }
                    currentState.chatRoom.subscribeUpdate();
                    if (((!newMessages || newMessages.length === 0) && (!visableMessages || visableMessages.length === 0)) && currentState.scrollPosition) {
                        currentState.instance.messages.scroll(0, currentState.scrollPosition);
                    } else {
                        await this.scrollToBottom(300);
                    }
                } else {
                    const newMessages = await this.loadLastMessage(currentState, messagesGhostEl);
                    if (newMessages && newMessages.length > 0) {
                        await this.scrollToBottom(300);
                    }

                }

                // Go to message 
                if (currentState?.instance?.$route?.context && currentState?.instance?.$route?.context['goToMessage']) {
                    this.goToMessage(currentState.instance.$route.context['goToMessage']);
                }

                if (currentState?.isFullLoad) {
                    currentState?.instance?.$setState({ isFullLoad: true });
                    messagesGhostEl.hide();
                    messagesGhostEl.addClass('hidden');
                }
            } catch (err) {
                console.error(err);
                currentState.chatRoom.destroy();
                currentState.chatRoom = null;
                // delete this.currentState[index], currentState.chatRoom, currentState;
            }
            currentState.ready$.next(true);
        });

        // Load heldpesk ticket
        const params: any = {};
        this.currentState.chatRoom.rooInfo$.pipe(filter(f => f !== null), take(1), takeUntil(currentState.destroy$)).toPromise().then(async roomInfo => {

            if (roomInfo && roomInfo.Type == 'HELPDESK') {
                params.filter_ChatRoom = currentState.chatRoom.id;
            } else {
                params.filter_LocalChatRoom = currentState.chatRoom.id;
                this.rootServices.changedChatRooms.workplace.push(currentState.id);
            }
            try {
                const helpdeskTickets = await this.rootServices.apiService.getPromise<HelpdeskTicketModel[]>('/helpdesk/tickets/', params).catch(err => null);
                if (helpdeskTickets && helpdeskTickets[0]) {
                    const helpdeskTicket = helpdeskTickets[0];

                    // Push ticket to change list
                    this.rootServices.changedChatRooms.helpdesk.push(helpdeskTicket.Code);

                    if (this.commonService.getObjectId(helpdeskTicket.Creator) !== this.rootServices.authService.user$?.getValue()?.id && (helpdeskTicket.State.Code || helpdeskTicket.State) === 'OPEN') {
                        currentState.instance.$app.dialog.confirm(helpdeskTicket.Description, 'Tiếp nhận yêu cầu', () => {
                            currentState.instance.$app.preloader.show();
                            this.rootServices.apiService.putPromise('/helpdesk/tickets/' + helpdeskTicket.Code, { changeStateTo: 'ACCEPT' }, [{ Code: helpdeskTicket.Code }]).then(rs => {
                                this.commonService.showInfo('Đã tiếp nhận yêu cầu');
                                currentState.instance.$app.preloader.hide();
                                currentState.instance.$setState({
                                    ticket: { ...currentState.instance.ticket, State: 'ACCEPT' }
                                });
                            }).catch(err => {
                                console.error(err);
                                currentState.instance.$app.preloader.hide();
                                this.commonService.showError(err);
                            });
                        }, () => {
                            currentState.instance.$router.back();
                            this.commonService.showInfo('Không tiếp nhận yêu cầu');
                        });
                    }
                    currentState.instance.$setState({
                        roomInfo: roomInfo,
                        ticket: helpdeskTickets[0],
                        textColorClass: roomInfo.Code === helpdeskTickets[0].ChatRoom ? 'helpdesk-text-red' : '',
                    });
                } else {

                    const roomInfo2 = await this.rootServices.apiService.getPromise<ChatRoomModel[]>('/chat/rooms/' + currentState.id, { select: 'Code=>Code,State=>State,Description=>Description' }).then(rs => rs[0]).catch(err => null);
                    // const members = roomInfo2.MembersIndex && Array.isArray(roomInfo2.MembersIndex) ? roomInfo2.MembersIndex.filter(f => f != 'SMARTBOT').map(m => ({ id: m, text: m, avatar: this.rootServices.apiService.getBaseApiUrl() + '/user/users/' + m + '/avatar' })) : [];
                    currentState.instance.$setState({
                        roomInfo: roomInfo,
                        // members: members,
                    });
                    this.currentState.chatRoom.rooInfo$.next({
                        ...this.currentState.chatRoom.rooInfo$.value,
                        ...roomInfo2,
                    })

                    if (this.commonService.getObjectId(roomInfo2.Creator) !== this.rootServices.authService.user$.getValue().id && ['OPEN', 'NEWSESSION'].indexOf(roomInfo2.State && roomInfo2.State['Code'] || roomInfo2.State) > -1) {
                        currentState.instance.$app.dialog.confirm(roomInfo2.Description, 'Tiếp nhận công việc', () => {
                            if (!currentState?.instance?.$app) return;
                            currentState.instance.$app.preloader.show();
                            this.rootServices.apiService.putPromise('/chat/rooms/' + roomInfo2.Code, { changeStateTo: 'ACCEPT' }, [{ Code: roomInfo2.Code }]).then(rs => {
                                this.commonService.showInfo('Đã tiếp nhận công việc');
                                currentState.instance.$app.preloader.hide();
                                currentState.chatRoom.rooInfo$.next({
                                    ...currentState.chatRoom.rooInfo$.value,
                                    State: 'ACCEPT'
                                });
                            }).catch(err => {
                                console.error(err);
                                currentState.instance.$app.preloader.hide();
                                this.commonService.showError(err);
                            });
                        }, () => {
                            currentState.instance.$router.back();
                            this.commonService.showInfo('Không tiếp nhận công việc');
                        });
                    }
                }

                // pageContentEl.css({height: '99%'});

            } catch (err) {
                console.log(err);
                this.commonService.showError(err);
            }
        });

        // Listen page content scroll
        let allowLoadMore = true;
        console.debug('sroll event listen');
        setTimeout(() => {
            pageContentEl.scroll(event => {
                let scrollTop = pageContentEl.scrollTop();

                if (!currentState.isFullLoad && allowLoadMore && scrollTop < messagesGhostEl.height()) {
                    this.commonService.takeUntil('chat-room-load-more', 300).then(async () => {
                        console.debug(`load more offset ${currentState.lastOffset}, limit 30`);
                        allowLoadMore = false;


                        let scrollTop = pageContentEl.scrollTop();
                        let scrollBottom = (messagesWrapEl[0] as HTMLElement).clientHeight - scrollTop;
                        try {
                            // await currentState.chatRoom.isFullLoadMessageList$.pipe(filter(status => status), take(1)).toPromise();
                            // const topMessage = $(currentState.instance.messages.el).find('.message:not(.message-typing):not(.message-sending):first');
                            // let topIndex = topMessage.attr('globalid');
                            // if (topIndex) {
                            //     topIndex = topIndex.split('/').pop();
                            // }
                            const topMessages = await currentState.chatRoom.getMessages(30, currentState.lastOffset + currentState.newMessagesCounter, this.getTopMessageNo(currentState.chatRoom));
                            currentState.lastOffset += 30;

                            for (let i = topMessages.length - 1; i > -1; i--) {
                                const topMessage = topMessages[i];
                                if (!(await this.addMessage(topMessage, 'prepend', false))) {
                                }
                            }

                            if (topMessages.length === 0) {
                                currentState.isFullLoad = true;
                                console.debug('is full load');
                                console.debug('now is full loaded');
                                // pageContentEl.scrollTo(0, messagesGhostEl.height() - 30, 300);
                                // messagesGhostEl.transition(300);
                                messagesGhostEl.addClass('hidden');
                                currentState.instance.$setState({
                                    isFullLoad: true,
                                });
                            }
                            // Calculate messages wrap height
                            pageContentEl.scrollTo(0, (messagesWrapEl[0] as HTMLElement).clientHeight - scrollBottom, 0);
                            // allowLoadMore = true;
                        } catch (err) {
                            console.error(err);
                            this.commonService.showError(err);
                        }
                        allowLoadMore = true;
                    });
                } else {
                    if (!allowLoadMore) {
                        console.debug('another load more in progress...');
                    }
                }

                this.updateMessageReadState();
            });

        }, 500);

        // Calculate navbar height
        const navbarHeight = $('.navbar-chat-room').height();
        currentState.instance.$setState({
            navbarHeight: navbarHeight,
        });

        return currentState;
    }

    /** Change state event */
    async onChangedState(state: ComponentStateExtend, index: string, asCase?: string) {
        console.debug('onChangedState');
        const currentState = await super.onChangedState(state, index, asCase);
        currentState.messageList = currentState.messageList || [];

        if (asCase === 'init') {
            currentState.lastMessage = {};
        }
        if (!currentState.lastMessage) {
            currentState.lastMessage = {};
        }

        try {
            currentState.instance.messagebar.attachments = currentState.attachments || [];
            currentState.instance.messagebar.attachments = currentState.attachments || [];
            currentState.instance.messagebar.selectedAttachments = currentState.selectedAttachments || [];
            currentState.instance.messagebar.setValue(currentState.messageText || '');
            currentState.instance.$setState({
                isFullLoad: currentState.isFullLoad,
            });
        } catch (err) {
            console.debug(err);
            this.commonService.showError(err);
        }

        return currentState;
    }

    onF7pageRemove(chatRoomId: string) {
        // if (this.chatRoomCacheList[chatRoomId]) {
        //   this.chatRoomCacheList[chatRoomId].disconnect();
        // }
    }

    async scrollToBottom(duration?: number) {
        try {
            // const pageContent = this.currentState.page.$el[0].children[1];
            const pageContentEl = this.currentState.page.$el;
            const messagesWrapEl = pageContentEl.find('.messages-wrap');
            duration = duration === 0 ? 0 : (duration || 300);

            // this.currentState.instance.messages.scroll(duration, messagesWrapEl.height());
            setTimeout(() => {
                this.currentState?.instance?.messages?.scroll(duration, 999999999999);
            }, 300);
            if (duration > 0) {
                return new Promise(resolve => setTimeout(() => resolve(true), duration + 350));
            }
        } catch (err) {
            console.error(err);
            this.commonService.showError(err);
        }
        return;
    }

    async scrollToMessage(messageEle: any, duration?: number, option?: { [key: string]: any, alignTop?: boolean, namespace?: string }) {
        // return false;
        let currentState: ComponentState = null;
        if (option && option.namespace) {
            currentState = this.state[option.namespace];
        } else {
            currentState = this.currentState;
        }
        const pageContentEl = currentState.page.$el;
        const messagesWrapEl = pageContentEl.find('.messages-wrap');
        messageEle = messageEle[0] || messageEle;
        duration = duration === 0 ? 0 : (duration || 300);
        const pageContent = currentState.page.$el[0].children[1];
        let scrollToPosition = 0;
        if (option && option.alignTop) {
            scrollToPosition = messageEle.offsetTop - 200;
        } else {
            if (pageContent && messageEle && messagesWrapEl) {
                scrollToPosition = messageEle.offsetTop + messageEle.clientHeight + messagesWrapEl.height() - pageContent.clientHeight + 123;
            }
        }
        currentState.instance && currentState.instance.messages && currentState.instance.messages.scroll(duration, scrollToPosition < 0 ? 0 : scrollToPosition);
        // pageContentEl.scrollTo(0, scrollToPosition, duration);

        // if (messageEle && messageEle[0]) {
        //     messageEle[0].scrollIntoView();
        return this.rootServices.waiting(duration);
        // }

        // return true;

    }

    createThumbnailWrap(attachments: Attachment[], namespace: string, messageIndex: number, option?: { prefixId?: string }) {
        let images = '';
        let totalImage = 0;
        if (attachments) {
            totalImage = attachments.length;
            for (let i = 0; i < totalImage; i++) {
                const attachment = attachments[i];
                let globalId = '';
                if (/^\w+\/\d+\/\w+\/\d+/.test(attachment.Id)) {
                    globalId = attachment.Id;
                } else {
                    globalId = `${namespace}/${messageIndex}/${attachment.Id}`;
                }
                // if (attachment.dataType !== 'url') {
                if (attachment && attachment) {
                    images += `<div attachmentglobalid="${globalId}" class="message-bubble-img-wrap" style="text-align: left; background-image: url('${attachment.LargeUrl || attachment.SmallUrl || attachment.Thumbnail || attachment.OriginUrl || attachment.Url}"></div>`;
                }
                // } else {
                //     images += `<div attachmentglobalid="${globalId}" class="message-bubble-img-wrap" style="background-image: url('${attachment.payload.thumbnail + '?token=' + this.rootServices.apiService.token.access_token}'); ${totalImage % 2 !== 0 && i === 0 ? '' : ''}"></div>`;
                // }
            }
        }
        return images;
    }

    async getAttachmentThumbnail(atta: Attachment, dimension: number) {
        let thumbnail: string;
        if (['jpeg', 'jpg', 'png'].indexOf(atta.Ext) > -1) {
            // Make image thumbnail
            const options = {
                uri: atta.Thumbnail,
                folderName: 'Protonet',
                fileName: 'thumbnail.' + atta.Ext,
                // quality: 90,
                width: 1024,
                height: 1280,
                fit: true,
            } as ImageResizerOptions;
            options.width = options.height = dimension;
            try {
                thumbnail = await this.rootServices.imageResizer.resize(options);
            } catch (err) {
                thumbnail = atta.Thumbnail;
                console.error(err);
            }
            if (atta.Thumbnail) {
                thumbnail = atta.Thumbnail;
            } else {
                const trustImage: { changingThisBreaksApplicationSecurity: string } = this.rootServices.sanitizer.bypassSecurityTrustResourceUrl(this.rootServices.webview.convertFileSrc(thumbnail)) as any;
                thumbnail = trustImage.changingThisBreaksApplicationSecurity;
            }
        } else {
            // Make file thumbnail
            thumbnail = 'assets/icons/file-extensions/' + atta.Ext + '.png';
            thumbnail = this.rootServices.makeLocalResourceUrl(thumbnail);
        }
        return thumbnail;
    }

    async uploadAttachments(attachments: (Attachment & { progress?: (event: HttpEvent<any>) => void })[]): Promise<Attachment[]> {
        this.rootServices.backgroundMode.enable();
        const task = [];
        if (!attachments || attachments.length === 0) {
            return attachments;
        }
        for (const attachment of attachments) {
            task.push(new Promise<Attachment>((resolve, reject) => {
                if (attachment.DataType === 'base64') {
                    const formData = new FormData();
                    const imgBlob = new Blob([this.rootServices.convertBase64ToByteArray((attachment.Url as string).replace(/^data:\w+\/[^,]+;\w+\,/, ''))], {
                        type: attachment.Type
                    });
                    formData.append('file', imgBlob, (attachment.Name || 'smart-bot-') + Date.now() + '.' + attachment.Ext);
                    this.rootServices.uploadFileData(formData, attachment.progress).then(async (fileInfo) => {
                        const fileModel = new FileModel(fileInfo);
                        fileModel.MimeType = attachment.Type;
                        attachment.Type = attachment.Type;
                        attachment.File = fileModel.GlobalId;
                        // attachment.name = fileModel.Name;
                        attachment.Thumbnail = fileModel.Thumbnail;
                        attachment.Url = fileModel.DownloadLink;
                        attachment.SmallUrl = fileModel.SmallImage;
                        attachment.LargeUrl = fileModel.LargeImage;
                        attachment.OriginUrl = fileModel.OriginImage;
                        resolve(attachment);
                    }, err => {
                        reject(err);
                    });
                } else if (attachment.DataType === 'array-buffer') {
                    const formData = new FormData();
                    const imgBlob = new Blob([attachment.Url], {
                        type: attachment.Type
                    });
                    formData.append('file', imgBlob, (attachment.Name || 'smart-bot-') + Date.now() + '.' + attachment.Ext);
                    this.rootServices.uploadFileData(formData, attachment.progress).then((fileInfo) => {
                        const fileModel = new FileModel(fileInfo);
                        fileModel.MimeType = attachment.Type;
                        attachment.Type = attachment.Type;
                        attachment.File = fileModel.GlobalId;
                        attachment.Thumbnail = fileModel.Thumbnail;
                        attachment.Url = fileModel.DownloadLink;
                        attachment.SmallUrl = fileModel.SmallImage;
                        attachment.LargeUrl = fileModel.LargeImage;
                        attachment.OriginUrl = fileModel.OriginImage;
                        resolve(attachment);
                    }, err => {
                        reject(err);
                    });
                } else {
                    const fileExt = attachment.Ext;
                    this.rootServices.uploadLocalFile((attachment.Url as string), attachment.progress).then(fileInfo => {
                        const fileModel = new FileModel(fileInfo);
                        attachment.Type = attachment.Type;
                        attachment.File = fileModel.GlobalId;
                        attachment.Thumbnail = fileModel.Thumbnail;
                        attachment.OriginUrl = fileModel.OriginImage;
                        attachment.Url = fileModel.DownloadLink;
                        attachment.SmallUrl = fileModel.SmallImage;
                        attachment.LargeUrl = fileModel.LargeImage;
                        resolve(attachment);
                    }, (err) => {
                        reject(err);
                    });
                }
            }));
        }

        return Promise.all(task).then(rs => {
            this.rootServices.backgroundMode.disable();
            return rs;
        }).catch(err => {
            this.rootServices.backgroundMode.disable();
            return Promise.reject(err);
        });
    }

    onMessageBoxUpdate() {
        this.commonService.takeUntil('message-box-update', 500).then(() => {
            this.scrollToBottom(300);
        });
    }

    formatMomentDateTime(date: string | number | Date) {
        return this.datePipe.transform(date instanceof Date ? date : new Date(date), 'short') + " (" + moment(date).fromNow() + ')';
    }

    get f7Component(): Router.RouteParameters {
        const $this = this;
        return {
            name: 'chat-room',
            path: '/chat-room/:id',
            // Component Object
            component: {
                template: /*html*/`
                <div class="page no-toolbarx page-chat-room" data-name="chat-room">
                    <div class="navbar navbar-chat-room">

                        <div class="navbar-bg"></div>
                        <div class="navbar-inner">
                        <div class="left">
                            <a class="link back {{textColorClass}}">
                                <i class="icon icon-back"></i>
                                <span class="if-not-md {{textColorClass}}">{{backTitle}}</span>
                            </a>
                        </div>
                        <div class="title sliding">{{title}}</div>
                        <div class="right">
                            <a @click="openControl" class="link icon-only {{textColorClass}}">
                                <i class="icon f7-icons">bars</i>
                            </a>
                        </div>
                        <form data-search-container=".components-list" data-search-in="a"
                            class="searchbar searchbar-expandable searchbar-components searchbar-init">
                            <div class="searchbar-inner">
                            <div class="searchbar-input-wrap">
                                <input type="search" placeholder="Search components" />
                                <i class="searchbar-icon"></i><span class="input-clear-button"></span>
                            </div>
                            <span class="searchbar-disable-button if-not-aurora">Cancel</span>
                            </div>
                        </form>
                        </div>
                    </div>
                    <div class="toolbar messagebar" @messagebar:attachmentdelete="deleteAttachment" style="bottom: {{js "this.isAndroid ? 56 : 50"}}px;">
                        <div class="toolbar-inner">
                            <a class="link icon-only" @click="sheetToggle">
                                <i class="icon f7-icons if-not-md {{textColorClass}}">circle_grid_3x3_fill</i>
                                <i class="icon material-icons md-only">apps</i>
                            </a>
                            <div class="messagebar-area">
                                <textarea {{js "this.isAndroid ? 'autocomplete=new-password' : ''"}} autocorrect="off" class="resizable" placeholder="Tin nhắn" disabled></textarea>
                            </div>
                            <a class="link icon-only demo-send-message-link" @click="sendMessage">
                                <i class="icon f7-icons if-not-md {{textColorClass}}">arrow_up_circle_fill</i>
                                <i class="icon material-icons md-only">send</i>
                            </a>
                        </div>
                        <div class="messagebar-sheet">
                            {{#each images}}
                            <label class="checkbox messagebar-sheet-image" style="background-image:url({{this}})" @change="handleAttachment">
                            <input type="checkbox">
                            <i class="icon icon-checkbox"></i>
                            </label>
                            {{/each}}
                        </div>
                    </div>



                    <!--<div style="top: {{js "this.navbarHeight + 5"}}px" class="chat-room-member-wrap {{js "this.members && this.members.length  && 'show' || ''"}}">
                        {{#js_if "this.members && this.members.length > 0"}}
                        <div class="members">
                            {{#each members}}
                            <div class="member tooltip-init" data-tooltip="{{text}}" style="background-image: url('{{avatar}}');"></div>
                            {{/each}}
                        </div>
                        {{/js_if}}
                    </div>-->

                    <div class="page-content messages-content" style="position: relative">


                        <div class="messages-wrap">
                        <div class="messages messages-ghost {{textColorClass}}">
                            <div class="message message-sent message-tail" style="width: 70%">
                                <div class="message-content" style="width: 100%">
                                    <div class="message-bubble" style="width: 100%;">
                                        <div class="message-text skeleton-text skeleton-effect-blink">____ ____ ____ _____ ___ __ ____ __ ________ __ __</div>
                                    </div>
                                    <div class="message-footer skeleton-text skeleton-effect-blink">__ __ __</div>
                                </div>
                            </div>
                            <div class="message message-received message-tail" style="width: 70%">
                                <div class="message-content" style="width: 100%">
                                    <div class="message-bubble" style="width: 100%;">
                                        <div class="message-text skeleton-text skeleton-effect-blink">____ ____ ____ _____ ___ __ ____ __ </div>
                                    </div>
                                    <div class="message-footer skeleton-text skeleton-effect-blink">__ __ __</div>
                                </div>
                            </div>
                        </div>
                        {{#if isFullLoad}}
                        <div class="messages-title"><b>đã tải tất cả tin nhắn</b></div>
                        {{else}}
                        <div class="messages-title text-color-default {{textColorClass}}"><b>đang tải tin nhắn...</b></div>
                        {{/if}}
                        <div class="messages messages-visable {{textColorClass}}"></div>
                        </div>
                    </div>
                    
                    <div class="dialog dialog-wrap dialog-message-info">
                    <div class="dialog-padding">
                    <div class="dialog dialog-inside dialog-buttons-vertical">
                        <div class="dialog-inner" style="padding-bottom: 0;">
                            <div class="dialog-title">Chi tiết tin nhắn</div>
                            <!--<div style="
                                padding: 0.5rem;
                                background-color: var(--ion-color-warning);
                                margin-left: -2rem;
                                margin-right: -2rem;
                                font-style: italic;
                                font-weight: normal;
                                color: var(--ion-color-step-500);
                                font-size: 12px;
                                padding-left: 1rem;
                                padding-right: 1rem;
                                text-align: center;
                            ">chạm 2 lần để chọn văn bản</div>-->
                            <div class="dialog-text" style="
                                width: 100%; 
                                padding: 1rem;
                                text-align: left; 
                                background: #82828238;
                                border-radius: 1rem;
                            ">{{messageText}}</div>

                            <div class="block-title" style="text-align: left; margin-left: 0px; margin-top: 10px;">Đã xem tin nhắn</div>
                            <div class="list media-list" style="margin-left: -15px; margin-right: -15px; margin-bottom: 0; margin-top: 0; text-align: left">
                                <ul style="background: inherit">
                                    <li class="swipeout" data-name="remove-chat-room-member" data-id="{{mesageSender.id}}">
                                        <a class="item-content item-link swipeout-content" @clickx="gotoProfile">
                                        <div class="item-media">
                                            {{#js_if "this.mesageSender && this.mesageSender.AvatarThumbnail || ''"}}
                                            <div class="list-item-img" style="width: 44px; height: 44px; background-image: url({{mesageSender.AvatarThumbnail}});"></div>
                                            {{else}}
                                            <div class="list-item-img" style="width: 44px; height: 44px;">
                                                <i style="font-size: 16px" class="f7-icons">person_fill</i>
                                            </div>
                                            {{/js_if}}
                                        </div>
                                        <div class="item-inner">
                                            <div class="item-title-row">
                                            <div class="item-title">{{js "this.mesageSender && this.mesageSender.Name || ''"}}</div>
                                            <div class="item-after"><i style="font-size: 16px" class="f7-icons">paperplane_fill</i></div>
                                            </div>
                                            <div class="item-subtitle">Gửi: {{js "this.mesageSender && this.mesageSender.Time || ''"}}</div>
                                            <div class="item-text">{{js "this.mesageSender && this.mesageSender.Fulldate || ''"}}</div>
                                        </div>
                                        </a>
                                        <div class="swipeout-actions-right">
                                        <a href="#" class="color-blur swipeout-overswipe" @click="click2call">Gọi điện</a>
                                        </div>
                                    </li>
                                    {{#each readMessageMembers}}
                                    <li class="swipeout" data-name="remove-chat-room-member" data-id="{{id}}">
                                        <a class="item-content item-link swipeout-content" @clickx="gotoProfile">
                                        <div class="item-media">
                                            {{#js_if "this.AvatarThumbnail"}}
                                            <div class="list-item-img" style="width: 44px; height: 44px; background-image: url({{this.AvatarThumbnail}});"></div>
                                            {{else}}
                                            <div class="list-item-img" style="width: 44px; height: 44px">
                                                <i style="font-size: 16px" class="f7-icons">person_fill</i>
                                            </div>
                                            {{/js_if}}
                                        </div>
                                        <div class="item-inner">
                                            <div class="item-title-row">
                                                <div class="item-title">{{Name}}</div>
                                            <div class="item-after"><i style="font-size: 16px" class="f7-icons">eye_fill</i></div>
                                            </div>
                                            <div class="item-subtitle">Xem: {{Time}}</div>
                                            <div class="item-text">{{Fulldate}}</div>
                                        </div>
                                        </a>
                                        <div class="swipeout-actions-right">
                                        <a href="#" class="color-blur swipeout-overswipe" @click="click2call">Gọi điện</a>
                                        </div>
                                    </li>
                                    {{/each}}
                                </ul>
                            </div>
                        </div>
                        <div class="dialog-buttons has-icon">
                            <span class="dialog-button copyBtn" @click="copyMessage">Copy<i class="icon f7-icons">doc_on_clipboard_fill</i></span>
                            <span class="dialog-button replyBtn" @click="replyMessage">Trả lời<i class="icon f7-icons">arrowshape_turn_up_left_fill</i></span>
                            <span class="dialog-button color-red closeBtn" @click="closeMessageDialog">Đóng<i class="icon f7-icons">multiply_circle_fill</i></span>
                        </div>
                    </div>
                    </div>
                    </div>

                    <div class="dialog dialog-wrap dialog-members-choosed">
                        <div class="dialog-padding">
                            <div class="dialog dialog-inside">
                                <div class="dialog-inner" style="padding-bottom: 0;">
                                    <div class="dialog-title">Tạo phòng con</div>
                                    <div class="dialog-text" style="width: 100%; user-select: initial; text-align: center;">{{messageText}}</div>


                                    <div class="block-title" style="text-align: left; margin-left: 0px; margin-top: 10px;">Liên hệ</div>
                                    {{#js_if "this.contacts && this.contacts.length > 0"}}
                                    <div class="list media-list" style="margin-left: -15px; margin-right: -15px; margin-bottom: 0; margin-top: 0; text-align: left">
                                        <ul style="background: inherit">
                                        {{#each contacts}}
                                        <li data-id="{{id}}">
                                            <label class="item-content item-checkbox">
                                                <input class="member-item" type="checkbox" name="demo-media-checkbox" value="1" data-id="{{id}}"/>
                                                <i class="icon icon-checkbox"></i>
                                                <div class="item-media">
                                                    {{#js_if "this.AvatarThumbnail"}}
                                                    <div class="list-item-img" style="width: 44px; height: 44px; background-image: url({{js "this.AvatarThumbnail || ''"}});"></div>
                                                    {{else}}
                                                    <div class="list-item-img" style="width: 44px; height: 44px">
                                                    <i style="font-size: 16px" class="f7-icons">person_fill</i>
                                                    </div>
                                                    {{/js_if}}
                                                </div>
                                                <div class="item-inner">
                                                    <div class="item-title-row">
                                                        <div class="item-title">{{Name}}</div>
                                                    </div>
                                                    <div class="item-subtitle">{{Roles}}</div>
                                                </div>
                                            </label>
                                        </li>
                                        {{/each}}
                                        </ul>
                                    </div>
                                    {{/js_if}}

                                    {{#js_if "this.memberNonGroupList && this.memberNonGroupList.length > 0"}}
                                    <div class="block-title" style="text-align: left; margin-left: 0px; margin-top: 10px;">Thành viên</div>
                                    <div class="list media-list" style="margin-left: -15px; margin-right: -15px; margin-bottom: 0; margin-top: 0; text-align: left">
                                        <ul style="background: inherit">
                                        {{#each memberNonGroupList}}
                                        <li data-id="{{id}}">
                                            <label class="item-content item-checkbox">
                                                <input class="member-item" type="checkbox" name="demo-media-checkbox" value="1" data-id="{{id}}"/>
                                                <i class="icon icon-checkbox"></i>
                                                <div class="item-media">
                                                    {{#js_if "this.AvatarThumbnail"}}
                                                    <div class="list-item-img" style="width: 44px; height: 44px; background-image: url({{js "this.AvatarThumbnail || ''"}});"></div>
                                                    {{else}}
                                                    <div class="list-item-img" style="width: 44px; height: 44px">
                                                    <i style="font-size: 16px" class="f7-icons">person_fill</i>
                                                    </div>
                                                    {{/js_if}}
                                                </div>
                                                <div class="item-inner">
                                                    <div class="item-title-row">
                                                        <div class="item-title">{{Name}}</div>
                                                    </div>
                                                    <div class="item-subtitle">{{Roles}}</div>
                                                </div>
                                            </label>
                                        </li>
                                        {{/each}}
                                        </ul>
                                    </div>
                                    {{/js_if}}
                                    {{#js_if "this.groups && this.groups.length > 0"}}
                                    <div class="block-title" style="text-align: left; margin-left: 0px; margin-top: 10px;">Thành viên theo nhóm</div>
                                    <div class="list media-list" style="margin-left: -15px; margin-right: -15px; margin-bottom: 0; margin-top: 0; text-align: left;">
                                        <ul style="background: inherit">
                                            {{#each groups}}
                                            <li data-id="{{id}}">
                                                <label class="item-content item-checkbox">
                                                    <input class="member-item" type="checkbox" name="demo-media-checkbox" value="1" data-id="{{id}}"/>
                                                    <i class="icon icon-checkbox"></i>
                                                    <div class="item-inner">
                                                        <div class="item-title-row">
                                                            <div class="item-title">{{Name}}</div>
                                                        </div>
                                                        <div class="item-subtitle">{{Roles}}</div>
                                                    </div>
                                                </label>
                                            </li>
                                            {{#each children}}
                                            <li data-id="{{id}}">
                                                <label class="item-content item-checkbox">
                                                    <input class="member-item" type="checkbox" name="demo-media-checkbox" value="1" data-id="{{id}}"/>
                                                    <i class="icon icon-checkbox"></i>
                                                    <div class="item-media">
                                                    {{#js_if "this.AvatarThumbnail"}}
                                                    <div class="list-item-img" style="width: 44px; height: 44px; background-image: url({{js "this.AvatarThumbnail || ''"}});"></div>
                                                    {{else}}
                                                    <div class="list-item-img" style="width: 44px; height: 44px">
                                                        <i style="font-size: 16px" class="f7-icons">person_fill</i>
                                                    </div>
                                                    {{/js_if}}
                                                    </div>
                                                    <div class="item-inner">
                                                        <div class="item-title-row">
                                                            <div class="item-title">{{Name}}</div>
                                                        </div>
                                                        <div class="item-subtitle">{{Roles}}</div>
                                                    </div>
                                                </label>
                                            </li>
                                            {{/each}}
                                            {{/each}}
                                        </ul>
                                    </div>
                                    {{/js_if}}
                                </div>
                                <div class="dialog-buttons">
                                    <span class="dialog-button color-red closeBtn" @click="closeMembersChoosedDialog">Trở về</span>
                                    <span class="dialog-button replyBtn" @click="createReferenceChatRoomBtn">Tạo phòng</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="dialog dialog-wrap dialog-create-ticket">
                    <div class="dialog-padding">
                    <div class="dialog dialog-inside">
                        <div class="dialog-inner" style="padding-bottom: 0;">
                            <div class="dialog-title">Tạo yêu cầu</div>
                            <div class="dialog-text" style="width: 100%; user-select: initial; text-align: center;">Các liên hệ và thành viên sẽ có mặt trong yêu cầu mới</div>

                            <div class="block-title" style="text-align: left; margin-left: 0px; margin-top: 10px;">Liên hệ</div>
                            {{#js_if "this.contacts && this.contacts.length > 0"}}
                            <div class="list media-list" style="margin-left: -15px; margin-right: -15px; margin-bottom: 0; margin-top: 0; text-align: left">
                                <ul style="background: inherit">
                                {{#each contacts}}
                                <li data-id="{{id}}">
                                    <label class="item-content item-checkbox">
                                        <input class="member-item" type="checkbox" name="demo-media-checkbox" value="1" data-id="{{id}}"/>
                                        <i class="icon icon-checkbox"></i>
                                        <div class="item-media">
                                            {{#js_if "this.AvatarThumbnail"}}
                                            <div class="list-item-img" style="width: 44px; height: 44px; background-image: url({{js "this.AvatarThumbnail || ''"}});"></div>
                                            {{else}}
                                            <div class="list-item-img" style="width: 44px; height: 44px">
                                            <i style="font-size: 16px" class="f7-icons">person_fill</i>
                                            </div>
                                            {{/js_if}}
                                        </div>
                                        <div class="item-inner">
                                            <div class="item-title-row">
                                                <div class="item-title">{{Name}}</div>
                                            </div>
                                            <div class="item-subtitle">{{Roles}}</div>
                                        </div>
                                    </label>
                                </li>
                                {{/each}}
                                </ul>
                            </div>
                            {{/js_if}}
                            <div class="block-title" style="text-align: left; margin-left: 0px; margin-top: 10px;">Thành viên</div>
                            {{#js_if "this.memberNonGroupList && this.memberNonGroupList.length > 0"}}
                            <div class="list media-list" style="margin-left: -15px; margin-right: -15px; margin-bottom: 0; margin-top: 0; text-align: left">
                                <ul style="background: inherit">
                                {{#each memberNonGroupList}}
                                <li data-id="{{id}}">
                                    <label class="item-content item-checkbox">
                                        <input class="member-item" type="checkbox" name="demo-media-checkbox" value="1" data-id="{{id}}"/>
                                        <i class="icon icon-checkbox"></i>
                                        <div class="item-media">
                                            {{#js_if "this.AvatarThumbnail"}}
                                            <div class="list-item-img" style="width: 44px; height: 44px; background-image: url({{js "this.AvatarThumbnail || ''"}});"></div>
                                            {{else}}
                                            <div class="list-item-img" style="width: 44px; height: 44px">
                                            <i style="font-size: 16px" class="f7-icons">person_fill</i>
                                            </div>
                                            {{/js_if}}
                                        </div>
                                        <div class="item-inner">
                                            <div class="item-title-row">
                                                <div class="item-title">{{Name}}</div>
                                            </div>
                                            <div class="item-subtitle">{{Roles}}</div>
                                        </div>
                                    </label>
                                </li>
                                {{/each}}
                                </ul>
                            </div>
                            {{/js_if}}
                            {{#js_if "this.groups && this.groups.length > 0"}}
                            <div class="block-title" style="text-align: left; margin-left: 0px; margin-top: 10px;">Thành viên theo nhóm</div>
                            <div class="list media-list" style="margin-left: -15px; margin-right: -15px; margin-bottom: 0; margin-top: 0; text-align: left;">
                                <ul style="background: inherit">
                                    {{#each groups}}
                                    <li data-id="{{id}}">
                                        <label class="item-content item-checkbox">
                                            <input class="member-item" type="checkbox" name="demo-media-checkbox" value="1" data-id="{{id}}"/>
                                            <i class="icon icon-checkbox"></i>
                                            <div class="item-inner">
                                                <div class="item-title-row">
                                                    <div class="item-title">{{Name}}</div>
                                                </div>
                                                <div class="item-subtitle">{{Roles}}</div>
                                            </div>
                                        </label>
                                    </li>
                                    {{#each children}}
                                    <li data-id="{{id}}">
                                        <label class="item-content item-checkbox">
                                            <input class="member-item" type="checkbox" name="demo-media-checkbox" value="1" data-id="{{id}}"/>
                                            <i class="icon icon-checkbox"></i>
                                            <div class="item-media">
                                            {{#js_if "this.avatar && this.AvatarThumbnail"}}
                                            <div class="list-item-img" style="width: 44px; height: 44px; background-image: url({{js "this.AvatarThumbnail || ''"}});"></div>
                                            {{else}}
                                            <div class="list-item-img" style="width: 44px; height: 44px">
                                                <i style="font-size: 16px" class="f7-icons">person_fill</i>
                                            </div>
                                            {{/js_if}}
                                            </div>
                                            <div class="item-inner">
                                                <div class="item-title-row">
                                                    <div class="item-title">{{Name}}</div>
                                                </div>
                                                <div class="item-subtitle">{{Roles}}</div>
                                            </div>
                                        </label>
                                    </li>
                                    {{/each}}
                                    {{/each}}
                                </ul>
                            </div>
                            {{/js_if}}
                        </div>
                        <div class="dialog-buttons">
                        <span class="dialog-button color-red closeBtn" @click="closeCreateTicketDialog">Trở về</span>
                            <span class="dialog-button replyBtn" @click="createTicket">Tạo yêu cầu</span>
                        </div>
                    </div>
                    </div>
                    </div>
                </div>
              `,
                style: `
                    .dialog-message-info .list .item-text {
                        font-size: 0.6rem !important;
                        font-weight: bold !important;
                        line-height: 0.6rem !important;
                    }
                `,
                data() {
                    return {
                        // backTitle: 'Back',
                        isFullLoad: false,
                        responseInProgress: false,
                        // title: $this.title,
                        // largeTile: $this.title,
                        readMessageMembers: [],
                        mesageSender: {},
                        isAndroid: isPlatform('android'),
                        isDesktop: isPlatform('desktop'),
                        members: null,
                    };
                },
                methods: {
                    click2call(e) {
                        const self: F7ComponentContextExtend = this;
                        const currentState = $this.state[self.$route.params.id];
                        self.messageInfoDialog && self.messageInfoDialog.close();
                        const memberId = self.$(e.target).closest('li').data('id') || self.$(e.target).data('id');
                        self.$app.dialog.create({
                            title: 'Click2Call',
                            text: 'Tôi sẽ gọi tới số nội bộ của bạn trước, nếu offline tôi sẽ gọi qua số cá nhân, hãy kiểm tra app GS wave đã online chưa hoặc bạn đang ở kế bên điện thoại của mình !',
                            buttons: [
                                {
                                    text: 'Đóng',
                                    // color: 'red',
                                    onClick() {
                                    },
                                },
                                {
                                    text: 'Gọi',
                                    color: 'red',
                                    onClick() {
                                        $this.commonService.showInfo('Đang gọi tới số của bạn...', { timeout: 30000 });
                                        $this.rootServices.apiService.putPromise('/chat/room-members/' + memberId, { click2call: true, chatRoom: currentState.id }, [{ Code: memberId }]).then(rs => {
                                            console.log(rs);
                                            $this.commonService.showInfo('Đang chuyển cuộc gọi...', { timeout: 30000 });
                                        }).catch(err => {
                                            console.error(err);
                                            $this.commonService.showError('Lỗi kết nối cuộc gọi');
                                        });
                                    },
                                }
                            ]
                        }).open();
                    },
                    openCreateTicketDialog(e) {
                        const self: F7ComponentContextExtend = this;
                        const currentState = $this.state[self.$route.params.id];
                        const dialog = self.$(e.target).closest('.dialog-wrap');
                        const msgIndex = dialog.data('msgindex');
                        const message = currentState.chatRoom.messageList.find(f => f.No === msgIndex);

                        $this.openMembersChoosedDialog(message);
                        self.messageInfoDialog.close();
                    },
                    closeCreateTicketDialog(e) {
                        const self: F7ComponentContextExtend = this;
                        const currentState = $this.state[self.$route.params.id];
                        const msgIndex = self.$(e.target).closest('.dialog-wrap').data('msgindex');
                        console.log('message index: ' + msgIndex);
                        self.createTicketDialog.close();
                    },
                    async createTicket(e) {
                        const self: F7ComponentContextExtend = this;
                        const currentState = $this.state[self.$route.params.id];
                        const dialog = self.$(e.target).closest('.dialog');

                        const members: string[] = [];
                        dialog.find('input.member-item:checked').each((i, item) => {
                            members.push(item.attributes['data-id'].value);
                        });

                        const choosedMembers = currentState.chatRoom.memberList$.getValue().filter(f => members.some(s => s === f.id));
                        const chooosedContacts = choosedMembers.filter(f => f.Type === 'CONTACT');

                        if (chooosedContacts.length === 0) {
                            $this.commonService.showError('Bạn phải chọn ít nhất 1 liên hệ');
                            return;
                        }

                        const firestContact = chooosedContacts[0];
                        self.$app.preloader.show();
                        try {
                            const newTicket = (await $this.rootServices.apiService.postPromise<HelpdeskTicketModel[]>('/helpdesk/tickets', { autoCreateChatRoom: true }, [{
                                Description: currentState.chatRoom.rooInfo$.getValue().Description,
                                Object: firestContact.id,
                                ObjectName: firestContact.Name,
                                LocalChatRoom: currentState.chatRoom.id,
                                Members: choosedMembers,
                            }]))[0];
                            if (newTicket) {
                                self.createTicketDialog.close();
                                $this.rootServices.navigate('/chat-room/' + (newTicket.ChatRoom.Code || newTicket.ChatRoom));
                            }

                            self.$app.preloader.hide();
                        } catch (err) {
                            console.log(err);
                            self.$app.preloader.hide();
                            $this.commonService.showError(err);
                        }
                    },
                    copyMessage(e: MouseEvent) {
                        const self: F7ComponentContextExtend = this;
                        $this.rootServices.clipboard.copy($this.currentState.activeMessage && $this.currentState.activeMessage.PlanText);
                        $this.commonService.showInfo('Tin nhắn đã được copy');
                        self.messageInfoDialog.close();
                    },
                    replyMessage(e: MouseEvent & Message, option?: { defaultContent?: string }) {
                        const self: F7ComponentContextExtend = this;
                        const currentState = $this.state[self.$route.params.id];
                        let msgIndex = null;
                        let message = null;
                        if (e && e.target) {
                            msgIndex = self.$(e.target).closest('.dialog-wrap').data('msgindex');
                            console.log('message index: ' + msgIndex);
                            message = currentState.chatRoom.messageList.find(f => f.No === msgIndex);
                        } else {
                            message = e;
                        }
                        if (message) {
                            let attachmenthtml = '';

                            if (message.Attachments && message.Attachments.length > 0) {
                                attachmenthtml = '<img src="' + message.Attachments.filter(att => att.Thumbnail).map(att => (att.Thumbnail || '')).join('"><img src="') + '">';
                                attachmenthtml = '<div class="message-quote-attachments">' + attachmenthtml + '</div>';
                            }

                            const quoteEl = self.$('\
                                <div class="message-quote">\
                                    <div>\
                                        <span class="message-quote-from-name">@' + message.From.Name + '</span>\
                                        <i class="icon f7-icons helpdesk-text-red message-quote-close">multiply_circle_fill</i>\
                                        <span class="message-quote-time">' + (moment(message.DateOfPost).fromNow()) + '</span>\
                                    </div>\
                                    <hr>\
                                    <span class="message-quote-content">\
                                        <span class="message-quote-icon material-icons">format_quote</span>' + $this.renderMessageQuoteText(message.Text) + '\
                                    </span>\
                                    '+ attachmenthtml + '\
                                </div>');
                            const messageArea = currentState.instance.messagebar.$el.find('.messagebar-area');
                            if (option && option.defaultContent) {
                                currentState.instance.messagebar.$textareaEl.val(option.defaultContent).change();
                            }
                            messageArea.find('.message-quote').remove();
                            const closeBtn = quoteEl.find('.message-quote-close');
                            closeBtn.click(() => {
                                const messageArea = self.messagebar.$el.find('.messagebar-area');
                                messageArea.find('.message-quote').remove();
                                currentState.quoteMessage = null;
                            });
                            messageArea.prepend(quoteEl);
                            currentState.quoteMessage = message;
                        }
                        self.messageInfoDialog.close();
                        $this.onMessageBoxUpdate();
                    },
                    openMembersChoosedDialog(e) {
                        const self: F7ComponentContextExtend = this;
                        const currentState = $this.state[self.$route.params.id];
                        const dialog = self.$(e.target).closest('.dialog-wrap');
                        const msgIndex = dialog.data('msgindex');
                        const message = currentState.chatRoom.messageList.find(f => f.No === msgIndex);

                        $this.openMembersChoosedDialog(message);
                        self.messageInfoDialog.close();
                    },
                    async createReferenceChatRoomBtn(e) {
                        const self: F7ComponentContextExtend = this;
                        const currentState = $this.state[self.$route.params.id];
                        const dialog = self.$(e.target).closest('.dialog-wrap');
                        const msgIndex = dialog.data('msgindex');
                        const message = currentState.chatRoom.messageList.find(f => f.No === msgIndex);

                        const members: string[] = [];
                        dialog.find('input.member-item:checked').each((i, item) => {
                            members.push(item.attributes['data-id'].value);
                        });


                        self.$app.preloader.show();
                        try {
                            const newChatRoom = await currentState.chatRoom.createReferenceChatRoom(message.PlanText, { referenceMessage: message, members: members });
                            if (newChatRoom) {
                                console.log('message index: ' + msgIndex);
                                self.membersChoosedDialog.close();
                                $this.rootServices.navigate('/chat-room/' + newChatRoom.id);
                            }

                            self.$app.preloader.hide();
                        } catch (err) {
                            console.log(err);
                            self.$app.preloader.hide();
                            $this.commonService.showError(err);
                        }
                    },
                    forwardMessage(e) {
                        const self: F7ComponentContextExtend = this;
                        const currentState = $this.state[self.$route.params.id];
                        const msgIndex = self.$(e.target).closest('.dialog-wrap').data('msgindex');
                        console.log('message index: ' + msgIndex);
                        const message = currentState.chatRoom.messageList.find(f => f.No === msgIndex);
                        if (message) {
                            let attachmenthtml = '';

                            if (message.Attachments && message.Attachments.length > 0) {
                                attachmenthtml = '<img src="' + message.Attachments.filter(att => att && att.Thumbnail).map(att => (att && att.Thumbnail || '')).join('"><img src="') + '">';
                            }
                            attachmenthtml = '<div class="message-quote-attachments">' + attachmenthtml + '<i class="actionButton cancelBtn icon f7-icons" style="color: var(--ion-color-danger)">multiply_circle_fill</i><i class="actionButton forwardBtn icon f7-icons" style="color: var(--ion-color-warning)">arrowshape_turn_up_left_fill</i></div>';

                            const msgBuffer = self.$('\
                            <li class="swipeout message-buffer">\
                                <div class="item-content swipeout-content message-quote">\
                                    <div class="item-media">\
                                        <i class="icon icon-f7"></i>\
                                    </div>\
                                    <div class="item-inner">\
                                        <div class="item-title-row">\
                                            <div class="item-title">@' + message.From.name + '</div>\
                                            <div class="item-after">' + (moment(message.DateOfPost).fromNow()) + '</div>\
                                        </div>\
                                        <div class="item-subtitle">' + $this.renderMessageQuoteText(message.Text) + '</div>\
                                    </div>\
                                </div>\
                                <div class="swipeout-actions-right">\
                                    <a href="#" class="open-more-actions forward-action">Forward</a>\
                                    <a href="#" class="swipeout-delete">Delete</a>\
                                </div>\
                            </li>');
                            $this.rootServices.bufferArea.find('ul').prepend(msgBuffer);
                            $this.rootServices.bufferArea.addClass('visabled');
                            const forwardBtn = msgBuffer.find('.forward-action');
                            const cancelBtn = msgBuffer.find('.swipeout-delete');
                            forwardBtn.click(() => {
                                const currentView = $this.rootServices.f7app.views.current;
                                const history = currentView.history;
                                const lastHistory = history[history.length - 1];
                                if (lastHistory && lastHistory.indexOf('chat-room') > -1) {
                                    $this.currentState.instance.replyMessage(message, { defaultContent: 'Tin nhắn chuyển tiếp' });
                                    msgBuffer.remove();

                                    if ($this.rootServices.bufferArea.find('.message-quote').length === 0) {
                                        $this.rootServices.bufferArea.removeClass('visabled');
                                    }
                                }
                            });
                            cancelBtn.click(() => {
                                // if ($this.rootServices.f7app.swipeout) {
                                $this.rootServices.f7app.swipeout.delete(msgBuffer[0] as HTMLElement, () => {
                                    msgBuffer.remove();
                                    setTimeout(() => {
                                        if ($this.rootServices.bufferArea.find('.message-buffer').length === 0) {
                                            $this.rootServices.bufferArea.removeClass('visabled');
                                        }
                                    }, 100);
                                });
                                // } else {
                                //     if ($this.rootServices.bufferArea.find('.message-buffer').length === 0) {
                                //         $this.rootServices.bufferArea.removeClass('visabled');
                                //     }
                                // }
                            });

                        }
                        self.messageInfoDialog.close();
                    },
                    closeMessageDialog(e) {
                        const self: F7ComponentContextExtend = this;
                        const currentState = $this.state[self.$route.params.id];
                        const msgIndex = self.$(e.target).closest('.dialog-wrap').data('msgindex');
                        console.log('message index: ' + msgIndex);
                        self.messageInfoDialog.close();
                    },
                    closeMembersChoosedDialog(e) {
                        const self: F7ComponentContextExtend = this;
                        const currentState = $this.state[self.$route.params.id];
                        const msgIndex = self.$(e.target).closest('.dialog-wrap').data('msgindex');
                        console.log('message index: ' + msgIndex);
                        self.membersChoosedDialog.close();
                    },
                    gotoProfile(e) {
                        const self: F7ComponentContextExtend = this;
                        const id = self.$(e.target).closest('li').data('id');
                        $this.rootServices.navigate('/profile/' + id, {
                            context: {
                                backTitle: $this.title,
                                memberInfo: $this.currentState.chatRoom.memberList$.getValue().find(f => f.id === id),
                                chatRoom: $this.currentState.chatRoom,
                            }
                        });
                        $this.currentState.instance.messageInfoDialog.close();
                    },
                    openControl() {
                        const self: F7ComponentContextExtend = this;
                        const currentState = $this.state[self.$route.params.id];
                        self.$router.navigate('/chat-room-control/' + self.$route.params.id, {
                            context: {
                                backTitle: 'Phòng Chat',
                                chatRoom: currentState.chatRoom,
                                textColorClass: self.textColorClass,
                                hadUpdate: (updateCase: string, data?: any) => {
                                    switch (updateCase) {
                                        case 'update-chat-room-references':
                                            self.refresh();
                                            break;
                                    }
                                }
                            }
                        });
                    },
                    async refresh() {
                        const self: F7ComponentContextExtend = this;
                        const currentState = $this.state[self.$route.params.id];
                        const chatRoomTree = await $this.rootServices.apiService.getPromise<ChatRoomModel[]>('/chat/rooms', { referenceChatRoom: currentState.id });
                        currentState.referenceChatRooms = $this.convertChatRoomTreeToArray(chatRoomTree);
                        if (currentState.referenceChatRooms) {
                            currentState.referenceChatRooms = currentState.referenceChatRooms.filter(function (item, pos) {
                                return currentState.referenceChatRooms.findIndex(i => i.Code === item.Code) == pos;
                            });
                            currentState.referenceChatRooms = currentState.referenceChatRooms.filter(f => f.Code !== currentState.id)
                                .map(chatRoom => ({
                                    id: chatRoom.Code, name: chatRoom.Description, 'avatar': null, 'type': chatRoom.Type, group: ''
                                }));
                        }
                        currentState.recferenceChatRoomsLastUpdate = Date.now();
                        return true;
                    },
                    hideToolbar() {
                        const self: F7ComponentContextExtend = this;
                        console.log('hide tool bar', this);
                        const app = self.$app;

                        app.toolbar.hide('.toolbar');
                    },
                    async sheetToggle() {
                        const self: F7ComponentContextExtend = this;
                        const currentState = $this.state[self.$route.params.id];
                        try {
                            let localFiles: PickerFile[] = null;

                            localFiles = await new Promise<PickerFile[]>(async (resolveLocalFile, reject3) => {
                                let actionBtns = [
                                    []
                                ];
                                if (!isPlatform('desktop')) {
                                    // actionBtns[0].push({
                                    //     text: 'Chụp hình',
                                    //     color: self.textColorClass,
                                    //     disabled: isPlatform('desktop'),
                                    //     // bold: true,
                                    //     icon: '<i class="icon f7-icons">camera_on_rectangle_fill</i>',
                                    //     onClick: (actions: any, e: unknown) => {
                                    //         const options: CameraOptions = {
                                    //             quality: 100,
                                    //             saveToPhotoAlbum: true,
                                    //             correctOrientation: true,
                                    //             // allowEdit: true,
                                    //             destinationType: $this.rootServices.camera.DestinationType.FILE_URI,
                                    //             encodingType: $this.rootServices.camera.EncodingType.JPEG,
                                    //             // mediaType: $this.rootServices.camera.MediaType.ALLMEDIA,
                                    //         };

                                    //         $this.rootServices.camera.getPicture(options).then((imagePath: string) => {
                                    //             resolveLocalFile([{ url: imagePath, ext: ((imagePath.split('.').pop() || '').toLocaleLowerCase()) || 'jpeg', dataType: 'url', type: 'image/jpeg', name: 'photo-shoot.jpeg' }]);
                                    //         }, (err) => {
                                    //             // Handle error
                                    //             resolveLocalFile([]);
                                    //         });
                                    //     },
                                    // });
                                    // if (isPlatform('ios') || isPlatform('android')) {
                                    //     actionBtns[0].push(
                                    //         {
                                    //             text: 'Quay video',
                                    //             color: self.textColorClass,
                                    //             disabled: isPlatform('desktop'),
                                    //             // bold: true,
                                    //             icon: '<i class="icon f7-icons">videocam_fill</i>',
                                    //             onClick: (actions: any, e: unknown) => {
                                    //                 let options: CaptureVideoOptions = {};
                                    //                 $this.rootServices.mediaCapture.captureVideo(options)
                                    //                     .then(
                                    //                         (mediafile: MediaFile[]) => {
                                    //                             console.log(mediafile);
                                    //                             let videoPath = mediafile[0].fullPath;
                                    //                             const ext = ((videoPath.split('.').pop() || '').toLocaleLowerCase()) || 'mp4';
                                    //                             videoPath = (videoPath.indexOf('file://') == 0) ? videoPath : 'file://' + videoPath;
                                    //                             resolveLocalFile([{ url: videoPath, ext: ext, dataType: 'url', type: 'video/mp4', name: 'video-shoot.mp4' }]);
                                    //                         },
                                    //                         (err: CaptureError) => {
                                    //                             console.error(err);
                                    //                         }
                                    //                     );
                                    //             },
                                    //         });
                                    // }
                                    actionBtns[0].push({
                                        text: 'Đính kèm hình ảnh',
                                        color: self.textColorClass,
                                        // bold: true,
                                        disabled: false,
                                        icon: '<i class="icon f7-icons">photo_fill_on_rectangle_fill</i>',
                                        onClick: async (actions: any, e: unknown) => {
                                            if ($this.rootServices.platform.is('desktop')) {
                                                // fallback to browser APIs
                                                const results = await new Promise<PickerFile[]>(resovle => {
                                                    const input = document.createElement('input');
                                                    input.type = 'file';
                                                    input.multiple = true;
                                                    input.onchange = (event: Event & { target: { files: FileList } }) => {

                                                        const progress = [];
                                                        // tslint:disable-next-line: prefer-for-of
                                                        for (let i = 0; i < event.target.files.length; i++) {
                                                            const file = event.target.files[i];
                                                            const ext: string = file.type.split('/').pop() || file.name.split('.').pop() || 'jpeg';
                                                            progress.push(new Promise<PickerFile>(resolve2 => {
                                                                // const file = e.target.files[0];
                                                                const reader = new FileReader();
                                                                reader.readAsArrayBuffer(file);

                                                                // here we tell the reader what to do when it's done reading...
                                                                reader.onload = readerEvent => {
                                                                    const localImage = readerEvent.target.result; // this is the content!
                                                                    resolve2({ url: readerEvent.target.result, thumbnail: 'data:image/jpeg;base64,' + btoa(new Uint8Array(localImage as any).reduce((data, byte) => data + String.fromCharCode(byte), '')), ext: ext, dataType: 'array-buffer', type: file.type || 'image/jpeg', name: file.name });
                                                                };
                                                            }));
                                                        }
                                                        resovle(Promise.all(progress));
                                                    };
                                                    input.click();
                                                });
                                                resolveLocalFile(results);
                                            } else {// make your native API calls
                                                const results = await $this.imagePicker.getPictures({
                                                    maximumImagesCount: 10,
                                                    width: 1024,
                                                    // outputType: 1,
                                                }).catch(err => {
                                                    // self.$app.preloader.hide();
                                                    console.error(err);
                                                    $this.commonService.showError(err);
                                                });
                                                resolveLocalFile(results.map((result: string) => {
                                                    const ext = ((result.split('.').pop() || '').toLocaleLowerCase()) || 'jpeg';
                                                    return { url: result, ext: 'jpeg', type: (['jpeg', 'jpg', 'png', 'webp'].indexOf(ext) > -1 ? 'image/' : ['mp4', 'mov', 'webm'].indexOf(ext) > -1 ? 'video/' : 'file/') + ext, dataType: 'url', name: 'video-choose.' + ext };
                                                }));

                                            }
                                        },
                                    });
                                    actionBtns[0].push({
                                        text: 'Đính kèm video',
                                        color: self.textColorClass,
                                        disabled: false,
                                        // bold: true,
                                        icon: '<i class="icon f7-icons">videocam_circle_fill</i>',
                                        onClick: async (actions: any, e: unknown) => {
                                            if ($this.rootServices.platform.is('desktop')) {
                                                // fallback to browser APIs
                                                const results = await new Promise<PickerFile[]>(resovle => {
                                                    const input = document.createElement('input');
                                                    input.type = 'file';
                                                    input.multiple = true;
                                                    input.onchange = (event: Event & { target: { files: FileList } }) => {

                                                        const progress = [];
                                                        // tslint:disable-next-line: prefer-for-of
                                                        for (let i = 0; i < event.target.files.length; i++) {
                                                            const file = event.target.files[i];
                                                            const ext: string = file.type.split('/').pop() || file.name.split('.').pop() || 'jpeg';
                                                            progress.push(new Promise<PickerFile>(resolve2 => {
                                                                // const file = e.target.files[0];
                                                                const reader = new FileReader();
                                                                reader.readAsArrayBuffer(file);

                                                                // here we tell the reader what to do when it's done reading...
                                                                reader.onload = readerEvent => {
                                                                    const localImage = readerEvent.target.result; // this is the content!
                                                                    resolve2({ url: readerEvent.target.result, thumbnail: 'data:image/jpeg;base64,' + btoa(new Uint8Array(localImage as any).reduce((data, byte) => data + String.fromCharCode(byte), '')), ext: ext, dataType: 'array-buffer', type: file.type || 'image/jpeg', name: file.name });
                                                                };
                                                            }));
                                                        }
                                                        resovle(Promise.all(progress));
                                                    };
                                                    input.click();
                                                });
                                                resolveLocalFile(results);
                                            } else {// make your native API calls
                                                const options: CameraOptions = {
                                                    quality: 100,
                                                    // saveToPhotoAlbum: true,
                                                    correctOrientation: true,
                                                    allowEdit: true,
                                                    // destinationType: $this.rootServices.camera.DestinationType.NATIVE_URI,
                                                    // encodingType: $this.rootServices.camera.EncodingType.JPEG,
                                                    mediaType: $this.rootServices.camera.MediaType.VIDEO,
                                                    sourceType: $this.rootServices.camera.PictureSourceType.PHOTOLIBRARY,
                                                };

                                                $this.rootServices.camera.getPicture(options).then((imagePath) => {
                                                    imagePath = (parseInt(imagePath.indexOf('file://')) == 0) ? imagePath : 'file://' + imagePath;
                                                    const ext = ((imagePath.split('.').pop() || '').toLocaleLowerCase()) || 'jpeg';
                                                    resolveLocalFile([{ url: imagePath, ext: ext, dataType: 'url', type: (['jpeg', 'jpg', 'png', 'webp'].indexOf(ext) > -1 ? 'image/' : 'video/') + ext, name: 'video-shoot.' + ext }]);
                                                }, (err) => {
                                                    // Handle error
                                                    resolveLocalFile([]);
                                                });
                                            }
                                        },
                                    });

                                    actionBtns[0].push({
                                        text: 'Đính kèm tài liệu',
                                        color: self.textColorClass,
                                        // disabled: isPlatform('desktop'),
                                        // bold: true,
                                        icon: '<i class="icon f7-icons">folder_fill</i>',
                                        onClick: (actions: any, e: unknown) => {
                                            $this.rootServices.chooser.getFile()
                                                .then(file => {
                                                    console.log('Choose file: ', file);
                                                    console.log(file ? file.name : 'canceled');
                                                    let ext = null;
                                                    if (file.name) {
                                                        ext = file.name.substr(file.name.lastIndexOf('.') + 1);
                                                    } else {
                                                        ext = file.mediaType.split('/')[1];
                                                    }
                                                    if (/^content:\/\//.test(file.uri)) {
                                                        resolveLocalFile([file ? { url: file.dataURI, ext: ext, dataType: 'base64', type: file.mediaType, name: file.name } : null]);
                                                    } else {
                                                        resolveLocalFile([file ? { url: file.uri, ext: ext, dataType: 'url', type: file.mediaType, name: file.name } : null]);
                                                    }
                                                }).catch((err: any) => {
                                                    console.error(err);
                                                    $this.commonService.showError(err);
                                                });
                                        },
                                    });
                                } else {
                                    actionBtns[0].push({
                                        text: 'Đính kèm',
                                        color: self.textColorClass,
                                        // bold: true,
                                        disabled: false,
                                        icon: '<i class="icon f7-icons">photo_fill_on_rectangle_fill</i>',
                                        onClick: async (actions: any, e: unknown) => {
                                            // fallback to browser APIs
                                            const results = await new Promise<PickerFile[]>(resovle => {
                                                const input = document.createElement('input');
                                                input.type = 'file';
                                                input.multiple = true;
                                                input.onchange = (event: Event & { target: { files: FileList } }) => {

                                                    const progress = [];
                                                    // tslint:disable-next-line: prefer-for-of
                                                    for (let i = 0; i < event.target.files.length; i++) {
                                                        const file = event.target.files[i];
                                                        const ext: string = (file.name.split('.').pop() || file.type.split('/').pop() || 'jpeg').toLowerCase();
                                                        progress.push(new Promise<PickerFile>(resolve2 => {
                                                            // const file = e.target.files[0];
                                                            const reader = new FileReader();
                                                            reader.readAsArrayBuffer(file);

                                                            // here we tell the reader what to do when it's done reading...
                                                            reader.onload = readerEvent => {
                                                                const localImage = readerEvent.target.result; // this is the content!
                                                                resolve2({ url: readerEvent.target.result, thumbnail: ['mov', 'mp4'].indexOf(ext) ? `assets/icons/file-extensions/${ext}.png` : 'data:image/jpeg;base64,' + btoa(new Uint8Array(localImage as any).reduce((data, byte) => data + String.fromCharCode(byte), '')), ext: ext, dataType: 'array-buffer', type: file.type || 'image/jpeg', name: file.name });
                                                            };
                                                        }));
                                                    }
                                                    resovle(Promise.all(progress));
                                                };
                                                input.click();
                                            });
                                            resolveLocalFile(results);
                                        },
                                    });
                                }
                                actionBtns.push([
                                    {
                                        text: 'Trở về',
                                        bold: true,
                                        disabled: false,
                                        icon: '<i class="icon f7-icons">arrow_down_circle_fill</i>',
                                        color: 'blue'
                                    },
                                ] as any);
                                // ];
                                if (self.roomInfo && self.roomInfo.Type == 'HELPDESK') {
                                    const group1 = [
                                        {
                                            text: 'Hành động',
                                            label: true,
                                        },
                                        {
                                            text: 'Chat nội bộ',
                                            // color: self.textColorClass,
                                            icon: '<i class="icon f7-icons">briefcase_fill</i>',
                                            // bold: true,
                                            onClick: async (actions: any, e: unknown) => {
                                                if (self.ticket && self.ticket.LocalChatRoom) {
                                                    $this.rootServices.navigate('/chat-room/' + self.ticket.LocalChatRoom, {
                                                        context: {
                                                            backTitle: 'Heldpesk',
                                                        }
                                                    });
                                                } else {
                                                    self.$app.preloader.show();
                                                    $this.rootServices.apiService.postPromise<ChatRoomModel>('/helpdesk/tickets/' + self.ticket.Code, { requestLocalChatRoom: true }, {}).then<RoomInfoModel>(chatRoom => {
                                                        return {
                                                            id: chatRoom.Code,
                                                            Description: chatRoom.Description,
                                                            Type: chatRoom.Type,
                                                            State: chatRoom.State,
                                                        };
                                                    }).then(roomInfo => {
                                                        self.$app.preloader.hide();
                                                        self.ticket.LocalChatRoom = roomInfo.id,
                                                            self.$setState({
                                                                ticket: self.ticket,
                                                                roomInfo: roomInfo,
                                                            });
                                                        $this.rootServices.navigate('/chat-room/' + self.ticket.LocalChatRoom, {
                                                            context: {
                                                                backTitle: 'Heldpesk',
                                                            }
                                                        });
                                                    }).catch(err => {
                                                        self.$app.preloader.hide();
                                                        console.error(err);
                                                        $this.commonService.showError(err);
                                                    });
                                                }
                                            },
                                        },
                                        {
                                            text: 'Lập báo cáo',
                                            color: self.textColorClass,
                                            icon: '<i class="icon f7-icons">doc_richtext</i>',
                                            // bold: true,
                                            onClick: (actions: any, e: unknown) => {

                                            },
                                        },
                                    ];
                                    if (self.ticket && self.ticket.State === 'ACCEPT') {
                                        group1.push({
                                            text: 'Hủy yêu cầu',
                                            color: self.textColorClass,
                                            icon: '<i class="icon f7-icons">multiply_circle_fill</i>',
                                            // bold: true,
                                            onClick: (actions: any, e: unknown) => {
                                                if (self.ticket && self.ticket.Code) {
                                                    self.$app.preloader.show();
                                                    self.$app.dialog.confirm(self.ticket.Description, 'Hủy yêu cầu', () => {
                                                        $this.rootServices.apiService.putPromise('/helpdesk/tickets/' + self.ticket.Code, { changeStateTo: 'CANCEL' }, [{ Code: self.ticket.Code }]).then(rs => {
                                                            console.log('Hủy yêu cầu');
                                                            self.$app.preloader.hide();
                                                            $this.commonService.showInfo('Hủy yêu cầu thành công');
                                                            self.$setState({
                                                                ticket: { ...self.ticket, State: 'CANCEL' }
                                                            });
                                                        }).catch(err => {
                                                            console.error(err);
                                                            $this.commonService.showError(err);
                                                            self.$app.preloader.hide();
                                                        });
                                                    }, () => {
                                                        $this.commonService.showInfo('Không hủy yêu cầu');
                                                    });
                                                }
                                            },
                                        } as any);
                                        group1.push({
                                            text: 'Hoàn tất yêu cầu',
                                            color: self.textColorClass,
                                            icon: '<i class="icon f7-icons">checkmark_alt_circle_fill</i>',
                                            // bold: true,
                                            onClick: (actions: any, e: unknown) => {
                                                if (self.ticket && self.ticket.Code) {
                                                    self.$app.dialog.confirm(self.ticket.Description, 'Hoàn tất yêu cầu', () => {
                                                        self.$app.preloader.show();
                                                        $this.rootServices.apiService.putPromise('/helpdesk/tickets/' + self.ticket.Code, { changeStateTo: 'COMPLETE' }, [{ Code: self.ticket.Code }]).then(rs => {
                                                            console.log('Hoàn tất yêu cầu');
                                                            self.$app.preloader.hide();
                                                            $this.commonService.showInfo('Hoàn tất yêu cầu thành công');
                                                            self.$setState({
                                                                ticket: { ...self.ticket, State: 'COMPLETE' }
                                                            });
                                                        }).catch(err => {
                                                            console.error(err);
                                                            self.$app.preloader.hide();
                                                            $this.commonService.showError(err);
                                                        });
                                                    }, () => {
                                                        $this.commonService.showInfo('Không hoàn tất yêu cầu');
                                                    });
                                                }
                                            },
                                        } as any);
                                    }
                                    actionBtns.unshift(group1 as any);

                                } else {
                                    if (!self.roomInfo?.Type) {
                                        self.roomInfo = await currentState.chatRoom.syncInfo();
                                        currentState?.instance.$setState({ roomInfo: self.roomInfo });
                                    }
                                    if (self.roomInfo?.Type && self.roomInfo?.Type != 'HELPDESK') {
                                        const updateRoomStateActions = [
                                            {
                                                text: 'Hành động',
                                                label: true,
                                            },
                                        ] as any;
                                        if (currentState.chatRoom.rooInfo$?.getValue()?.State === 'ACCEPT') {
                                            updateRoomStateActions.push({
                                                text: 'Hoàn tất',
                                                // color: 'gray',
                                                icon: '<i class="icon f7-icons">text_badge_checkmark</i>',
                                                // bold: true,
                                                onClick: (actions: any, e: unknown) => {
                                                    self.$app.dialog.confirm(currentState.chatRoom.rooInfo$.getValue().Description, 'Hoàn tất công việc', () => {
                                                        self.$app.preloader.show();
                                                        $this.rootServices.apiService.putPromise('/chat/rooms/' + currentState.id, { changeStateTo: 'COMPLETE' }, [{ Code: currentState.id }]).then(rs => {
                                                            console.log('Hoàn tất công việc');
                                                            self.$app.preloader.hide();
                                                            $this.commonService.showInfo('Hoàn tất công việc thành công');
                                                            $this.currentState.chatRoom.rooInfo$.next({
                                                                ...$this.currentState.chatRoom.rooInfo$.value,
                                                                State: 'COMPLETE'
                                                            });
                                                        }).catch(err => {
                                                            console.error(err);
                                                            self.$app.preloader.hide();
                                                            $this.commonService.showError(err);
                                                        });
                                                    }, () => {
                                                        $this.commonService.showInfo('Không hoàn tất công việc');
                                                    });
                                                },
                                            });
                                            updateRoomStateActions.push(
                                                {
                                                    text: 'Hủy',
                                                    // color: 'gray',
                                                    icon: '<i class="icon f7-icons">multiply_circle_fill</i>',
                                                    // bold: true,
                                                    onClick: (actions: any, e: unknown) => {
                                                        self.$app.dialog.confirm(currentState.chatRoom.rooInfo$.getValue().Description, 'Hủy công việc', () => {
                                                            self.$app.preloader.show();
                                                            $this.rootServices.apiService.putPromise('/chat/rooms/' + currentState.id, { changeStateTo: 'CANCEL' }, [{ Code: currentState.id }]).then(rs => {
                                                                console.log('Hủy công việc');
                                                                self.$app.preloader.hide();
                                                                $this.commonService.showInfo('Hủy công việc thành công');
                                                                $this.currentState.chatRoom.rooInfo$.next({
                                                                    ...$this.currentState.chatRoom.rooInfo$.value,
                                                                    State: 'CANCEL'
                                                                });
                                                            }).catch(err => {
                                                                console.error(err);
                                                                self.$app.preloader.hide();
                                                                $this.commonService.showError(err);
                                                            });
                                                        }, () => {
                                                            $this.commonService.showInfo('Không hủy công việc');
                                                        });
                                                    },
                                                });
                                        }
                                        // actionBtns.unshift(updateRoomStateActions);
                                        if (!currentState.chatRoom.rooInfo$?.getValue()?.State || currentState.chatRoom.rooInfo$.getValue().State === 'OPEN') {
                                        }
                                        actionBtns.unshift(updateRoomStateActions);
                                        // }
                                        // });
                                    }
                                }
                                const chooseCameraOrLibrary = self.$app.actions.create({
                                    buttons: actionBtns as any
                                });
                                chooseCameraOrLibrary.open();
                            });

                            if (localFiles) {
                                for (const localFile of localFiles) {
                                    if (!localFile) {
                                        continue;
                                    }
                                    let thumbnail = null;
                                    if (localFile.dataType === 'url') {
                                        let extension: string = localFile.ext;
                                        extension = extension.toLowerCase();
                                        if (['jpeg', 'jpg', 'png'].indexOf(extension) > -1) {
                                            // Make image thumbnail
                                            const options = {
                                                uri: localFile.url,
                                                folderName: 'Protonet',
                                                fileName: 'thumbnail.' + extension,
                                                // quality: 90,
                                                width: 1024,
                                                height: 1280,
                                                fit: true,
                                            } as ImageResizerOptions;
                                            if (localFiles.length === 1) {
                                                options.width = options.height = 1024;
                                            } else {
                                                options.width = options.height = 256;
                                            }
                                            try {
                                                thumbnail = await $this.rootServices.imageResizer.resize(options);
                                            } catch (err) {
                                                thumbnail = localFile.url;
                                                console.error(err);
                                            }
                                            if (localFile.thumbnail) {
                                                thumbnail = localFile.thumbnail;
                                            } else {
                                                const trustImage: { changingThisBreaksApplicationSecurity: string } = $this.rootServices.sanitizer.bypassSecurityTrustResourceUrl($this.rootServices.webview.convertFileSrc(thumbnail)) as any;
                                                thumbnail = trustImage.changingThisBreaksApplicationSecurity;
                                            }
                                        } else {
                                            // Make file thumbnail
                                            thumbnail = 'assets/icons/file-extensions/' + extension + '.png';
                                            thumbnail = $this.rootServices.makeLocalResourceUrl(thumbnail);
                                        }
                                    } else {// is base64
                                        if (localFile.type.indexOf('image') < 0) {
                                            thumbnail = 'assets/icons/file-extensions/' + localFile.ext + '.png';
                                        } else {
                                            thumbnail = localFile.thumbnail || localFile.url;
                                        }
                                    }
                                    try {
                                        self.messagebar.attachments.push(thumbnail);
                                        self.messagebar.selectedAttachments.push({
                                            Id: `${Date.now()}`,
                                            Type: localFile.type,
                                            DataType: localFile.dataType,
                                            Ext: localFile.ext,
                                            Name: localFile.name,
                                            Thumbnail: thumbnail,
                                            Url: localFile.url,
                                        });
                                    } catch (err) {
                                        console.error(err);
                                        $this.commonService.showError(err);
                                    }
                                }
                                self.messagebar.renderAttachments();
                                self.checkAttachments();
                                $this.onMessageBoxUpdate();
                            }
                        } catch (err) {
                            console.error(err);
                            $this.commonService.showError(err);
                        }
                    },
                    deleteAttachment(e, index) {
                        const self: F7ComponentContextExtend = this;
                        const image = self.messagebar.attachments.splice(index, 1)[0];
                        self.messagebar.selectedAttachments.splice(index, 1)[0];
                        self.messagebar.renderAttachments();
                        self.checkAttachments();
                        // Uncheck in sheet
                    },
                    handleAttachment(e) {
                        const self = this;
                        const $$ = self.$$;
                    },
                    checkAttachments() {
                        const self: F7ComponentContextExtend = this;
                        if (self.messagebar.attachments.length > 0) {
                            self.messagebar.attachmentsShow();
                            self.messagebar.setPlaceholder('Add comment or Send');
                        } else {
                            self.messagebar.attachmentsHide();
                            self.messagebar.setPlaceholder('Tin nhắn');
                        }
                    },
                    async sendMessage(e, MouseEvent, message?: Message) {
                        const hadInputMessage = !!message;
                        let text = '';
                        let tags = null;
                        message = message || {} as any;
                        const self: F7ComponentContextExtend = this;
                        // const text: string = message && message.content ? message.content : self.messagebar.getValue().replace(/\n/g, '<br>').trim();
                        if (message && message.Text) {
                            text = message.Text;
                        } else {
                            text = await new Promise(resolve => {
                                self.messageInput.mentionsInput('val', function (text: string) {
                                    resolve(text);
                                });
                            });
                            tags = await new Promise(resolve => {
                                self.messageInput.mentionsInput('getMentions', function (mentions: { id: string, type: string, value: string }[]) {
                                    resolve(mentions.map(m => ({
                                        type: 'MEMBER',
                                        name: m.value,
                                        member: {
                                            id: m.id,
                                            type: 'USER',
                                            user: m.id,
                                            name: m.value,
                                        },
                                    })));
                                });
                            });
                            self.messageInput.mentionsInput('reset');
                        }
                        const messagesToSend = [];
                        const currentState = $this.state[self.$route.params.id];

                        // msg.footer = 'đang gửi...';
                        if (self.messagebar.selectedAttachments) {
                            message.Attachments = self.messagebar.selectedAttachments;
                        }

                        // Check input
                        if (!text && (!message.Attachments || message.Attachments.length === 0)) {
                            self.messagebar.setPlaceholder('Chưa có nội dung!');
                            setTimeout(() => {
                                self.messagebar.setPlaceholder('Tin nhắn');
                            }, 2000);
                            if (!hadInputMessage) { self.messagebar.focus(); }
                            return false;
                        }

                        // messagesToSend.push(msg);
                        // // Reset attachments
                        self.messagebar.attachments = [];
                        self.messagebar.selectedAttachments = [];
                        self.checkAttachments();
                        // // Hide sheet
                        self.messagebar.sheetHide();
                        // Uncheck selected images in sheet
                        self.messagebar.$sheetEl.find('input').prop('checked', false);
                        // Clear area
                        self.messagebar.clear();

                        const messageArea = self.messagebar.$el.find('.messagebar-area');
                        messageArea.find('.message-quote').remove();

                        // Focus area
                        if (text.length) { self.messagebar.focus(); }
                        // Send message

                        const user = $this.rootServices.authService.getUser();
                        const msgData: Message = {
                            No: Date.now(),
                            ChatRoom: currentState.id,
                            From: {
                                id: user.id,
                                text: user.name,
                                name: user.name,
                                Code: user.id,
                                Name: user.name,
                                Avatar: user.avatar?.payload?.thumbnail,
                            },
                            Text: text,
                            PlanText: currentState.chatRoom.convertToPlanText(text),
                            Attachments: message.Attachments,
                            DateOfPost: new Date().toISOString(),
                            Tags: tags,
                            ReplyQuote: currentState.quoteMessage,
                        };

                        $this.scrollToBottom();
                        const newMessageEle = await $this.addMessage(msgData, 'append', true);
                        if (!newMessageEle) {
                            throw new Error('add message failed');
                        }
                        (newMessageEle as any).addClass('message-sending');
                        currentState.newMessagesCounter++;
                        if (!newMessageEle) {
                            console.log('could not create new message element');
                            return false;
                        }
                        newMessageEle.find('.message-footer').html('<i class="icon f7-icons if-not-md">photo_fill_on_rectangle_fill</i><i class="icon material-icons if-md">attachment</i> <span>...</span>');
                        await $this.scrollToMessage(newMessageEle, null, { namespace: currentState.id });
                        newMessageEle.find('.message-footer span').html('đang xử lý...');

                        try {
                            if (message.Attachments && message.Attachments.length > 0) {
                                newMessageEle.find('.message-footer').attr('class', 'message-footer text-color-orange');
                                newMessageEle.find('.message-footer i.f7-icons').html('rectangle_paperclip');
                                newMessageEle.find('.message-footer i.material-icons').html('attachment');
                                newMessageEle.find('.message-footer span').html('đang đính kèm...');

                                const messageImages = newMessageEle.find('.message-image') as any;
                                const imagesEle = messageImages.find('.message-bubble-img-wrap') as any[];
                                for (let pIndex = 0; pIndex < imagesEle.length; pIndex++) {
                                    const imageEle = self.$$(imagesEle[pIndex]);
                                    const percentEle = self.$(`<div class="upload-progress">chuẩn bị</div>`);
                                    imageEle.append(percentEle);
                                    message.Attachments[pIndex]['progress'] = (event: HttpEvent<any>) => {
                                        if (event.type === HttpEventType.UploadProgress) {
                                            const percent = Math.round(100 * event['loaded'] / event['total']);
                                            if (event['loaded'] === event['total']) {
                                                percentEle.html(`đang xử lý...`);
                                            } else {
                                                percentEle.html(`${percent}%`);
                                            }
                                        } else if (event instanceof HttpResponse) {
                                            percentEle.html('hoàn tất');
                                            setTimeout(() => {
                                                percentEle.html('');
                                            }, 1000);
                                        }
                                    };
                                }

                                message.Attachments = await $this.uploadAttachments(message.Attachments);
                                message.Attachments.forEach(att => {
                                    att.Description = text;
                                });
                                $this.scrollToMessage(newMessageEle, 0, { namespace: currentState.id });
                            }

                            if ($this.env.chat.requireLocation) {
                                newMessageEle.find('.message-footer').attr('class', 'message-footer text-color-teal');
                                newMessageEle.find('.message-footer i.f7-icons').html('location_fill');
                                newMessageEle.find('.message-footer i.material-icons').html('location_on');
                                newMessageEle.find('.message-footer span').html('đang xác định tòa độ...');
                                // Socket send message


                                /// Request location
                                if (false) {
                                    console.log('wait for get geo location...');
                                    const coords = await $this.rootServices.getLastGeoLocation({ retryAfterMilisecond: isPlatform('android') ? 60000 : isPlatform('desktop') ? 15 * 60000 : 0 }).then<Coordinates>((coords) => {
                                        console.log(coords);
                                        return coords && {
                                            latitude: coords.latitude,
                                            longitude: coords.longitude,
                                            accuracy: coords.accuracy,
                                            altitude: coords.altitude,
                                            altitudeAccuracy: coords.altitudeAccuracy,
                                            heading: coords.heading,
                                            speed: coords.speed,
                                        };
                                    }).catch((err) => {
                                        console.log('Error getting location', err);
                                        return null;
                                    });
                                    msgData.Coordinates = coords;
                                }
                            }

                            newMessageEle.find('.message-footer').attr('class', 'message-footer text-color-teal');
                            newMessageEle.find('.message-footer i.f7-icons').html('paperplane_fill');
                            newMessageEle.find('.message-footer i.material-icons').html('send');
                            newMessageEle.find('.message-footer span').html('đang gửi tin nhắn...');

                            currentState.quoteMessage = null;
                            currentState.chatRoom.sending$.next(true);
                            try {
                                const rspMessage = await currentState.chatRoom.sendMessage(msgData, $this.user);
                                const msgFooter = newMessageEle.find('.message-footer');
                                if (rspMessage.State) {
                                    msgFooter.find('span').html(rspMessage.State);
                                    newMessageEle.find('.message-footer i.f7-icons').html('info_circle_fill');
                                    newMessageEle.find('.message-footer i.material-icons').html('error');
                                    msgFooter.attr('class', 'message-footer text-color-red');
                                } else {
                                    msgFooter.find('i.f7-icons').html('checkmark_circle_fill');
                                    msgFooter.find('i.material-icons').html('check_circle');
                                    msgFooter.attr('class', 'message-footer text-color-blue');
                                    msgFooter.find('span').html($this.formatMomentDateTime(rspMessage.DateOfPost) + (rspMessage.Status ? `<br>${rspMessage.Status}` : ''));
                                }
                                newMessageEle.attr('globalid', rspMessage.ChatRoom + '/' + rspMessage.No);
                                $this.scrollToMessage(newMessageEle, 0, { namespace: currentState.id });

                                if (rspMessage.Attachments && rspMessage.Attachments.length > 0) {
                                    const messageImages = newMessageEle.find('.message-image') as any;
                                    const imagesEle = messageImages.find('.message-bubble-img-wrap') as any[];
                                    for (let pIndex = 0; pIndex < imagesEle.length; pIndex++) {
                                        const imageEle = self.$$(imagesEle[pIndex]);
                                        const attachment = rspMessage.Attachments[pIndex];
                                        let globalId = '';
                                        if (/^\w+\/\w+\/\w+/.test(attachment.Id)) {
                                            globalId = attachment.Id;
                                        } else {
                                            globalId = `${rspMessage.ChatRoom}/${rspMessage.No}/${attachment.Id}`;
                                        }
                                        imageEle.attr('attachmentglobalid', globalId);
                                        imageEle.css({ backgroundImage: 'url(' + (attachment && attachment && (attachment.LargeUrl || attachment.Thumbnail)) + ')' });
                                    }
                                }

                                console.debug(rspMessage);
                                currentState.lastMessage[currentState.page.view.id] = rspMessage;
                                (newMessageEle as any).removeClass('message-sending');
                            } catch (err) {
                                const msgFooter = newMessageEle.find('.message-footer');
                                msgFooter.attr('class', 'message-footer text-color-red');
                                msgFooter.find('i.f7-icons').html('info_circle_fill');
                                msgFooter.find('i.material-icons').html('error');
                                msgFooter.find('span').html('lỗi gửi tin nhắn...');
                                (newMessageEle as any).addClass('message-sent-error');
                                console.error(err);
                                $this.commonService.showError(err);
                            }
                            currentState.chatRoom.sending$.next(false);

                            // Mock response
                            if (self.responseInProgress) { return; }
                            self.responseInProgress = true;

                        } catch (err) {
                            console.error(err);
                            newMessageEle.find('.message-footer').attr('class', 'message-footer text-color-red');
                            newMessageEle.find('.message-footer i.f7-icons').html('info_circle_fill');
                            newMessageEle.find('.message-footer i.material-icons').html('error');
                            newMessageEle.find('.message-footer span').html('lỗi đính kèm...');
                        }

                        $this.scrollToMessage(newMessageEle, 0, { namespace: currentState.id });
                    },
                },
                on: {
                    pageInit(e, page: F7Page) {
                        console.log('[page event] init', page.route.url);
                        const self: F7ComponentContextExtend = this;
                        const chatRoomId = self.$route.params.id;

                        // Set back title
                        self.$setState({ backTitle: self.$route.context && self.$route.context.backTitle || 'Back' });
                        if (!chatRoomId) {
                            return;
                        }

                        // Init messages info button dialog
                        self.messageInfoDialog = self.$app.dialog.create({
                            el: page.$el.find('.dialog-message-info'),
                            closeByBackdropClick: true,
                        });
                        self.membersChoosedDialog = self.$app.dialog.create({
                            el: page.$el.find('.dialog-members-choosed'),
                            closeByBackdropClick: true,
                        });
                        self.createTicketDialog = self.$app.dialog.create({
                            el: page.$el.find('.dialog-create-ticket'),
                            closeByBackdropClick: true,
                        });
                        // End Init messages info button dialog

                        const app = self.$app;
                        self.messagebar = app.messagebar.create({
                            el: page.$el.find('.messagebar'),
                            attachments: [],
                        }) as any;

                        self.messages = $this.prepareMessages(self, page);

                        // Fire event: On Changed State
                        $this.onComponentInit({ instance: self, page, destroy$: new Subject() }, chatRoomId, 'init').then(currentState => {
                            currentState.lastUpdate = Date.now();
                            console.debug('Chat room f7component init success');
                            self.messagebar.$el.keyup(function (e: KeyboardEvent) {
                                if (!e.shiftKey && e.code === 'Enter') {
                                    const mentiosAutoconpleteListEl = page.$el.find('.mentions-autocomplete-list');
                                    if (mentiosAutoconpleteListEl[0].clientHeight === 0) {
                                        self.sendMessage();
                                        return false;
                                    }
                                }
                                $this.commonService.takeOnce('message-typing', 600).then(() => {
                                    currentState.chatRoom.sendTypingStatus({ status: 'typing' });
                                });
                                $this.commonService.takeUntil('message-stop-typing', 3000).then(() => {
                                    if (self.$route) {
                                        const currentState = $this.state[self.$route.params.id];
                                        currentState.chatRoom.sendTypingStatus({ status: 'clear' });
                                    }
                                });
                                $this.onMessageBoxUpdate();
                            });

                            // Mentions init
                            self.messageInput = ($(self.messagebar.el).find('textarea') as any);
                            self.messageInput.focus(() => {
                                console.log('Message box focus');
                                $this.onMessageBoxUpdate();
                            });
                            setTimeout(async () => {
                                const baseUrl = $this.rootServices.apiService.getBaseApiUrl();
                                const currentUser = $this.rootServices.authService.user$.getValue();
                                let mentionMembers = currentState.chatRoom.memberList$.getValue()
                                    .filter(f => f.id !== currentUser.id)
                                    .map(member => ({
                                        id: member.id, name: member.ShortName || member.Name, 'avatar': (baseUrl + '/user/users/' + member.id + '/avatar') || null, 'type': member.Type, group: member.Group && member.Group.id || member.Group
                                    }));
                                currentState.chatRoom.memberList$.pipe(takeUntil(currentState.destroy$)).subscribe(ml => {
                                    mentionMembers = ml
                                        .filter(f => f.id !== currentUser.id)
                                        .map(member => ({
                                            id: member.id, name: member.ShortName || member.Name, 'avatar': (baseUrl + '/user/users/' + member.id + '/avatar') || null, 'type': member.Type, group: member.Group && member.Group.id || member.Group
                                        }));
                                });
                                self.messageInput.mentionsInput({
                                    minChars: 0,
                                    triggerChar: '@',
                                    hashtagTriggerChar: '#',
                                    templates: {
                                        wrapper: _.template('<div class="mentions-input-box"></div>'),
                                        autocompleteList: _.template('<div class="mentions-autocomplete-list"></div>'),
                                        autocompleteListItem: _.template('<li belongto="<%= group %>" data-ref-id="<%= id %>" data-ref-type="<%= type %>" data-display="<%= display %>"><%= content %></li>'),
                                        autocompleteListItemAvatar: _.template('<img src="<%= avatar %>" />'),
                                        autocompleteListItemIcon: _.template('<div class="icon <%= icon %>"></div>'),
                                        mentionsOverlay: _.template('<div class="mentions"><div></div></div>'),
                                        mentionItemSyntax: _.template('@[<%= value %>](<%= type %>:<%= id %>)'),
                                        hashtagItemSyntax: _.template('#[<%= value %>](<%= type %>:<%= id %>)'),
                                        mentionItemHighlight: _.template('<strong><span><%= value %></span></strong>')
                                    },
                                    onDataRequest: function (mode, query, callback) {
                                        const data = _.filter(mentionMembers, (item) => { return $this.commonService.smartFilter(item.name, query) });
                                        console.log('mention search results: ', data);
                                        callback.call(this, data);
                                    },
                                    onHashtagDataRequest: function (mode, query, callback) {
                                        const data = _.filter(currentState.referenceChatRooms, (item) => { return $this.commonService.smartFilter(item.name, query) });
                                        console.log('hastag search results: ', data);
                                        callback.call(this, data);
                                    }
                                });
                                self.messageInput.prop('disabled', false);
                            }, 0);
                            // End Mentions init

                            currentState.previousMessageTyping = null;
                            currentState.chatRoom.messageTyping$.pipe(takeUntil(currentState.destroy$), filter(f => !!f)).subscribe(msg => {
                                console.log('message typing', msg);
                                if (!currentState.previousMessageTyping || (currentState.previousMessageTyping.from && currentState.previousMessageTyping.from.id !== (msg.from && msg.from.id))) {
                                    if (msg.status === 'typing') {
                                        $this.commonService.takeOnce('message-typing-status', 1000).then(() => {
                                            self.messages.showTyping({
                                                name: msg.from.Name,
                                                avatar: msg.from.Avatar.payload.thumbnail,
                                                type: 'received',
                                                text: null,
                                                footer: null,
                                                header: null,
                                                textFooter: null,
                                                textHeader: null,
                                                image: null,
                                                imageSrc: null,
                                                isTitle: false,
                                            });
                                        });
                                    }
                                    // else {
                                    $this.commonService.takeUntil('clear_receive_typing_message', 10000).then(() => {
                                        self.messages && self.messages.hideTyping && self.messages.hideTyping();
                                        currentState.previousMessageTyping = null;
                                    });
                                }

                                currentState.previousMessageTyping = msg;
                            });
                        });
                    },
                    pageBeforeIn(e, page) {
                        console.log('[page event] before in', page.route.url);
                        const self: F7ComponentContextExtend = this;
                        const chatRoomId = self.$route.params.id;
                        const currentState = $this.state[self.$route.params.id];

                        // Fire event: On Changed State
                        // Show preloader
                        $this.onChangedState({ instance: self, page }, chatRoomId, 'before-in').then(() => {
                            if (currentState && currentState.chatRoom) {
                                currentState.chatRoom.active();
                            }

                        });
                    },
                    pageBeforeOut(e, page) {
                        console.log('[page event] before out', page.route.url);
                        const self: F7ComponentContextExtend = this;
                        const chatRoomId = self.$route.params.id;
                        const currentState = $this.state[chatRoomId];
                        // Fire event: On Before Changed State
                        $this.onBeforeChangedState({ instance: self, page, scrollPosition: page.$el.find('.messages-content')[0].scrollTop || 1 }, chatRoomId).then(() => {
                        });

                        currentState.activate = false;

                    },
                    pageMounted(e, page) {
                        const self: F7ComponentContextExtend = this;
                        console.log('[page event] mounted', page.route.url);
                    },
                    pageAfterIn(e, page) {
                        const self: F7ComponentContextExtend = this;
                        console.log('[page event] after in', page.route.url);
                        const currentState = $this.state[self.$route.params.id];
                        currentState.lastUpdate = Date.now();

                        // if ($this.rootServices.isAndroid) {
                        //     self.onKeyboardDidShowSubsciption = $this.rootServices.keyboard.onKeyboardDidShow().subscribe(info => {
                        //         console.log(info);
                        //         $(currentState.instance.el).css({ height: 'calc(100% - ' + (info.keyboardHeight + 29) + 'px)' })
                        //     });
                        //     self.onKeyboardDidHideSubsciption = $this.rootServices.keyboard.onKeyboardDidHide().subscribe(info => {
                        //         console.log(info);
                        //         $(currentState.instance.el).css({ height: '100%' });
                        //     });
                        // }
                    },
                    pageAfterOut(e, page) {
                        const self: F7ComponentContextExtend = this;
                        const currentState = $this.state[self.$route.params.id];
                        console.log('[page event] after out', page.route.url);
                        if (currentState) {
                            // currentState.chatRoom && currentState.chatRoom.deactive();
                            currentState.ready$.next(false);
                        }

                        // if (self.onKeyboardDidShowSubsciption) {
                        //     (self.onKeyboardDidShowSubsciption as Subscription).unsubscribe();
                        // }
                        // if (self.onKeyboardDidHideSubsciption) {
                        //     (self.onKeyboardDidHideSubsciption as Subscription).unsubscribe();
                        // }
                    },
                    pageBeforeUnmount(e, page) {
                        console.log('[page event] before unmount', page.route.url);
                    },
                    pageBeforeRemove(e, page) {
                        console.log('[page event] before remove', page.route.url);
                        const self: F7ComponentContextExtend = this;
                        const chatRoomId = self.$route.params.id;
                        const currentState = $this.state[self.$route.params.id];
                        if (self.messagebar) { self.messagebar.destroy(); }
                        const pageContentEl = currentState.instance.$$(currentState.page.el).find('.page-content');
                        const messagesEl = pageContentEl.find('.messages-wrap .messages-visable');
                        if (currentState.chatRoom && currentState.chatRoom.currentState === 'ready') {
                            currentState.htmlCache = messagesEl.html();
                        } else {
                            currentState.htmlCache = '';
                        }

                        currentState.lastUpdate = Date.now();
                        if (currentState && currentState.destroy$) {
                            currentState.destroy$.next();
                            currentState.destroy$.complete();
                            currentState.chatRoom && currentState.chatRoom.deactive();
                        }
                        // if (self.onKeyboardDidShowSubsciption) {
                        //     (self.onKeyboardDidShowSubsciption as Subscription).unsubscribe();
                        // }
                        // if (self.onKeyboardDidHideSubsciption) {
                        //     (self.onKeyboardDidHideSubsciption as Subscription).unsubscribe();
                        // }
                    },
                }
            },
        };
    }
}
