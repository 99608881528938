import Inputmask from "inputmask";
import { TextEditor } from 'framework7/build/core/components/text-editor/text-editor';
import { SmartSelect } from 'framework7/build/core/components/smart-select/smart-select';
import { Toggle } from 'framework7/build/core/components/toggle/toggle';
import { BaseComponent, ComponentState } from './base-component';
import { CommonService } from '../services/common.service';
import { RootServices } from '../services/root.services';
import { F7ComponentContext, F7Page } from '../types/framework7-types';
import { ContactModel } from '../model/contact.model';
import { Model } from "../model/model";
import { Autocomplete } from "framework7/build/core/components/autocomplete/autocomplete";
// import * as $ from 'jquery';
declare const $: any;

// declare const $: any;
export interface PrintControlSchema {
    [key: string]: any;
    type?: string;
    autocompleteOption?: Partial<Autocomplete.Parameters> & {
        [key: string]: any,
        minSearchInputLength?: number,
    }
    dataType?: string;
    validators?: any[];
    makeModel?: (properties?: any) => any;
}
export interface PrintSchema {
    [key: string]: PrintControlSchema;
}

export interface PrintComponentStateExtend<M extends Model> extends ComponentState {
    [key: string]: any;
    instance: F7ComponentContext,
    data?: M;
    form?: any;
    lastAction?: string;
}


export abstract class BasePrintComponent<S extends PrintComponentStateExtend<M>, M> extends BaseComponent<ComponentState> {

    apiPath: string;
    currentState: S;

    idKey: string;
    schema: PrintSchema;
    specialTypes = ['smart-select', 'autocomplete', 'datepicker', 'toggle', 'calandar', 'list'];

    constructor(
        public rootServices: RootServices,
        public commonService: CommonService,
    ) {
        super(rootServices);
    }

    abstract makeModel(properties?: M): M;

    async onComponentInit(state: ComponentState, index: string, asCase?: string, page?: F7Page): Promise<ComponentState> {
        return super.onComponentInit(state, index, asCase, page).then(async (currentState: PrintComponentStateExtend<M>) => {

            // Wait for controls init;
            await this.commonService.waiting(500);
            // await this.setData(currentState.data);
            // currentState.data = await this.getPrintData();
            this.setData(await this.getPrintData());

            return currentState;
        });
    }

    async getPrintData(params?: any): Promise<M> {
        return this.rootServices.apiService.getPromise<M[]>(this.apiPath + '/' + this.currentState.instance.$route.params['id'], params || {}).then(rs => this.prepareData(rs[0]));
    }


    prepareData(data: M) {
        return data;
    }

    async setData(data: M) {
        this.currentState.data = data;
        this.currentState.instance.$setState({ data: data });
        return true;
    }

    async refresh(self?: ComponentState): Promise<M> {
        let doneTimeout = null;
        try {
            return this.getPrintData().then(data => {
                this.setData(data);
                return data;
            });
        } catch (err) {
            console.error(err);
            return Promise.reject(err);
        }
        return null;
    }
}