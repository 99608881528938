import { F7ComponentContext, F7Page } from '../../../types/framework7-types';
import { Router } from 'framework7/build/core/modules/router/router';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { ComponentState, BaseComponent } from 'vendors/smart-bot-app/src/app/lib/base-component';
import { ChatRoom } from 'vendors/smart-bot-app/src/app/lib/nam-chat/chat-room';
import { ProductModel } from 'vendors/smart-bot-app/src/app/model/product.model';
import { TaskReminderModel } from 'vendors/smart-bot-app/src/app/model/task.model';
import { CommonService } from 'vendors/smart-bot-app/src/app/services/common.service';
import { RootServices } from 'vendors/smart-bot-app/src/app/services/root.services';
import * as moment from 'moment';
import { PageComponent } from '../../page.component';

export interface ComponentStateExtend extends ComponentState {
  [key: string]: any;
}

export class F7ComponentContextExtend extends F7ComponentContext {
  // messagebar?: F7Messagebar;
  // messages?: Messages.Messages;
  // images?: string[];
  responseInProgress?: boolean;
  // answers?: string[];
  // people?: { name?: string, avatar?: string }[];
  // hideToolbar?: () => void;
  // sheetToggle?: () => void;
  // deleteAttachment?: (e: MouseEvent, index: number) => void;
  // handleAttachment?: (e: MouseEvent) => void;
  // checkAttachments?: () => void;
  // sendMessage?: () => void;
  productList?: ProductModel[];
  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, reminder?: TaskReminderModel, chatRoom?: ChatRoom, reminderList$?: BehaviorSubject<TaskReminderModel[]> } };
}

/** Component group manager */
export class PageCollaboratorOrderTabComponent extends BaseComponent<ComponentStateExtend> {
  // states: { [key: string]: State } = {};

  title: 'Đơn hàng'

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
    public parentComponent: PageComponent,
  ) {
    super(rootServices);
    // // console.log('Click here to open ts file');

    this.parentComponent.eventEmitter.subscribe((e: { name: string, data: any }) => {
      switch (e.name) {
        case 'refresh':
          this.currentState?.instance?.refresh && this.currentState?.instance?.refresh();
          break;
      }
    });
  }

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      // Tab path
      path: '/collaborator/order-tab',
      // Tab id
      id: 'page-order-tab',
      // Fill this tab content from content string
      component: {
        template: /*html*/`
          <div class="page-content" style="padding-top: 0">

            {{#js_if "this.pageInfo && !this.pageInfo.IsSubscribed"}}
            <div class="block" style="margin-top: 0">
              <button class="button button-fill button-large color-orange" @click="registerPublisher">Đăng ký CTV bán hàng</button>
            </div>
            {{/js_if}}
            
            <div class="list media-list inset">
              <ul>
                <li class="item-link">
                  <a class="item-content item-link text-color-gray" data-href="/page-collaborator-orders/{{js "this.pageInfo && this.pageInfo.Code"}}" @click="navigate">
                    <div class="item-media"><i class="icon f7-icons">square_list</i></div>
                    <div class="item-inner">
                      <div class="item-title-row">
                        <div class="item-title">Đơn hàng CTV</div>
                      </div>
                      <div class="item-subtitle">Theo dõi tình trạng đơn hàng</div>
                    </div>
                  </a>
                </li>
                <li class="item-link">
                  <a class="item-content item-link text-color-gray" data-href="/page-collaborator-pos-orders/{{js "this.pageInfo && this.pageInfo.Code"}}" @click="navigate">
                    <div class="item-media"><i class="icon f7-icons">square_list</i></div>
                    <div class="item-inner">
                      <div class="item-title-row">
                        <div class="item-title">Đơn hàng POS</div>
                      </div>
                      <div class="item-subtitle">Theo dõi tình trạng đơn hàng POS</div>
                    </div>
                  </a>
                </li>
                <li class="item-link">
                  <a class="item-content item-link text-color-gray" data-href="/page-collaborator-orders/{{js "this.pageInfo && this.pageInfo.Code"}}" @click="navigate">
                    <div class="item-media"><i class="icon f7-icons">square_list</i></div>
                    <div class="item-inner">
                      <div class="item-title-row">
                        <div class="item-title">Đơn hàng Web (coming soon)</div>
                      </div>
                      <div class="item-subtitle">Theo dõi tình trạng đơn hàng Web</div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>

          </div>
          `,
        style: /*css*/`

        `,
        data() {
          return {
            title: $this.title,
            backTitle: 'Back',
            // pageInfo: {},
          };
        },
        methods: {
          registerPublisher() {
            const self: F7ComponentContextExtend = this;
            self.$app.dialog.confirm(`Bạn có muốn trở thành CTV bán hàng của ${self.pageInfo?.Name}`, 'Đăng ký CTV bán hàng', () => {
              $this.rootServices.apiService.putPromise('/collaborator/pages/' + self.$route?.params?.id, { registerPublisher: true }, [{
                Code: self.$route?.params?.id
              }]).then(rs => {
                $this.commonService.showInfo('Chúc mừng bạn đã trở thành CTV bán hàng của ' + self.pageInfo?.Name + ' !');
                $this.parentComponent && $this.parentComponent.currentState && $this.parentComponent.currentState.instance && $this.parentComponent.currentState.instance.$setState({
                  pageInfo: {
                    ...$this.parentComponent.currentState.instance.pageInfo,
                    IsSubscribed: true,
                  }
                });
                $this.parentComponent && $this.parentComponent.currentState && $this.parentComponent.currentState.instance.refresh(null, null, true);
                self.$setState({
                  pageInfo: {
                    ...$this.parentComponent.currentState.instance.pageInfo,
                    IsSubscribed: true,
                  }
                });
              }).catch(err => {
                $this.commonService.showError(err);
              });
            });
          },
          navigate(e) {
            const self: F7ComponentContextExtend = this;
            const href = self.$(e.target).closest('a').data('href');
            $this.commonService.navigate(href, { context: { pageInfo: self.pageInfo } });
          },
          subscribe(e) {
            const self: F7ComponentContextExtend = this;
            const id = self.$(e.target).closest('li').data('id');
            self.$route.context['onChoose'] && self.$route.context['onChoose'](self.productList.find(f => f.Code === id));
          },
          chooseOne(e) {
            const self: F7ComponentContextExtend = this;
            const id = self.$(e.target).closest('li').data('id');
            self.$route.context['onChoose'] && self.$route.context['onChoose'](self.productList.find(f => f.Code === id));
          },
          chooseProduct(e) {

          },
          async refresh(e, done?: any, force?: boolean) {
            const self: F7ComponentContextExtend = this;
            const pageId = self.$route.params?.id;
            let doneTimeout = null;
            if (done) {
              doneTimeout = setTimeout(() => {
                done();
              }, 10000);
            };

            try {

              if (!self.pageInfo || force) {
                self.pageInfo = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/pages/' + pageId, {}).then(rs => rs[0]);
              }

              // console.log(self.searchBar);

              // const products = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/products', {
              //   includeCategories: true,
              //   includeGroups: true,
              //   filterByLevelAndBadge: true,
              //   sort_Id: true,
              //   page: pageId,
              //   search: self?.searchBar?.query && encodeURIComponent(self.searchBar.query) || '',
              //   includeFeaturePicture: true,
              //   includeUnit: true,
              //   offset: 0,
              //   limit: 40,
              //   ...self.filter,
              // }).then(products => {
              //   // self.$$(page.el).find('.page-content').scrollTo(0, 0);
              //   return products.map(t => $this.preapreProduct(t));
              // });

              self.$setState({
                // productList: products,
                // infiniteLoadLast: products.length === 0,
                pageInfo: self.pageInfo,
                // lastUpdateMoment: moment(Date.now()).fromNow(),
              });
              self.infiniteOffset = 0;

            } catch (err) {
              console.error(err);
            }

            if (done) done();
            return true;
          },
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          tabInit(e, page: F7Page) {
            console.log('tab init');
            const self: F7ComponentContextExtend = this;
            const pageId = self.$route.params?.id;
            self.$setState({
              pageId: pageId,
              // pageInfo: $this.parentComponent?.currentState?.instance?.pageInfo,
            });
            // Loading flag
            let allowInfinite = true;

            $this.parentComponent.setActiveTab('page-order-tab');
            $this.parentComponent.currentState.instance.$setState({hideTabBar: false});

            self.refresh();
            self.searchBar = self.$app.searchbar.create({
              el: '.product-list-searchbar',
              backdrop: true,
              backdropEl: '.product-list-backdrop',
              searchContainer: '.list',
              searchIn: '.item-text',
              customSearch: true,
              on: {
                search(sb, query, previousQuery) {
                  $this.commonService.takeUntil('product-search', 600).then(() => {
                    console.log(query, previousQuery);
                    $this.rootServices.apiService.getPromise<any[]>('/sales/master-price-tables/products', {
                      search: encodeURIComponent(query),
                      priceTable: $this.commonService.getObjectId(self.$route.context['priceTable']) || null,
                      includeFeaturePicture: true,
                      limit: 10,
                      offset: 0,
                      includeCategories: true,
                      ...self.filter,
                    }).then(products => {
                      self.$$(page.el).find('.page-content').scrollTo(0, 0);
                      self.$setState({ productList: products.map(t => $this.preapreProduct(t)), infiniteLoadLast: products.length === 0 });
                      self.infiniteOffset = 0;
                    }).catch(err => {
                      console.error(err);
                      $this.commonService.showError(err);
                    });
                  });
                }
              }
            });

            self.$$(page.el).find('.infinite-scroll-content').on('infinite', function () {
              // Exit, if loading in progress
              if (!allowInfinite) return;

              // Set loading flag
              allowInfinite = false;
              // Last loaded index
              var lastItemIndex = self.$$('.list li').length;

              // Emulate 1s loading
              $this.rootServices.apiService.getPromise<any[]>('/sales/master-price-tables/products', {
                search: self.searchBar.query,
                sort_LastUpdate: 'desc',
                limit: 10,
                offset: self.infiniteOffset,
                // sort_LastUpdate: 'desc',
                priceTable: $this.commonService.getObjectId(self.$route.context['priceTable']) || null,
                includeFeaturePicture: true,
                includeCategories: true,
                ...self.filter,
              }).then(products => {
                products.map(t => {
                  $this.preapreProduct(t);
                  return t;
                });
                self.$setState({ productList: self.productList.concat(products), infiniteLoadLast: products.length === 0 });
                self.infiniteOffset += 10;
                lastItemIndex = self.$$('.list li').length;
                allowInfinite = true;

                // self.updateBadge();
              }).catch(err => {
                console.error(err);
                $this.commonService.showError(err);
              });
            });


            // self.calendarDateTime = self.$f7.calendar.create({
            //   // openIn: 'customModal',
            //   inputEl: page.$el.find('input[name="RemindAtDateTime"]'),
            //   timePicker: true,
            //   dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' },
            //   // header: true,
            //   footer: true,
            // });

            // Set back title
            // self.$setState({ backTitle: self.$route.context && self.$route.context.backTitle || 'Back', title: self.$route.context && self.$route.context.title || 'Tài khoản' });

            $this.onComponentInit({ instance: self }, pageId);


          },
          tabMounted(e, page) {
            console.log('tab before mount');
          },
          tabBeforeRemove(e, page) {
            console.log('[tab event] before remove', page?.route?.url);
            const self: F7ComponentContextExtend = this;
            const pageId = self.$route.params?.id;
            $this.onComponentRemove({ instance: self }, pageId);
            // const starmtSelectRoles = self.$app.smartSelect.get('.smart-select');
            // starmtSelectRoles.destroy();
          },
        },
      },
    };
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  preapreProduct(p: ProductModel) {
    if (p.Categories && p.Categories.length > 0) {
      p.CategoriesText = p.Categories.map(m => m.text).join(', ');
    }
    if (p.FeaturePicture && p.FeaturePicture.Thumbnail) {
      p.FeaturePictureThumbnail = p.FeaturePicture.Thumbnail;
    }
    const taxValue = p.Tax && p.Tax.Tax || 0;
    p.ToMoney = parseInt(p.Price as any) + parseInt(p.Price as any) * parseInt(taxValue as any) / 100;
    p.ToMoneyText = this.rootServices.currencyPipe.transform(p.ToMoney, 'VND');;
    p.PriceText = this.rootServices.currencyPipe.transform(p.Price, 'VND');
    if (p.OriginPrice) {
      p.OriginPriceText = this.rootServices.currencyPipe.transform(p.OriginPrice, 'VND');
    }
    return p;
  }
}
