import { take } from 'rxjs/operators';
import { Dialog } from "framework7/build/core/components/dialog/dialog";
import { Router } from "framework7/build/core/modules/router/router";
import { ContactModel } from "vendors/smart-bot-app/src/app/model/contact.model";
import { SalesMasterPriceTable } from "vendors/smart-bot-app/src/app/model/sales.model";
import { CommonService } from "vendors/smart-bot-app/src/app/services/common.service";
import { RootServices } from "vendors/smart-bot-app/src/app/services/root.services";
import { F7ComponentContext, F7Page } from "vendors/smart-bot-app/src/app/types/framework7-types";
import { FileModel } from 'vendors/smart-bot-app/src/app/model/file.model';
import { BaseFormComponent, FormComponentStateExtend, FormSchema } from 'vendors/smart-bot-app/src/app/lib/base-form-component';
import { ProductModel, UnitModel } from 'vendors/smart-bot-app/src/app/model/product.model';
import { ChatRoomMemberModel, ChatRoomModel } from 'vendors/smart-bot-app/src/app/model/chat-room.model';
import { CollaboratorOpportunityCommentModel, CollaboratorOpportunityDetailModel, ECardModel } from 'vendors/smart-bot-app/src/app/model/collaborator.model';
import { Messages } from 'framework7/build/core/components/messages/messages';
// import * as $ from 'jquery';
declare const $: any;

// declare const $: any;
export interface ComponentStateExtend extends FormComponentStateExtend<ECardModel> {
  [key: string]: any;
  instance: F7ComponentContextExtend,
  data?: ECardModel;
  form?: any;
}

export class F7ComponentContextExtend extends F7ComponentContext {
  responseInProgress?: boolean;

  detailDialog?: Dialog.Dialog;

  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, [key: string]: any } };
}

/** Component group manager */
export class ECardSettingComponent extends BaseFormComponent<ComponentStateExtend, ECardModel> {
  // states: { [key: string]: State } = {};
  currentState: ComponentStateExtend;
  title: 'eCard';

  idKey = 'Code';
  apiPath = '/ecard/ecards';

  schema: FormSchema = {
    // Customer: {
    //   type: 'smart-select',
    //   label: 'Khách hàng',
    //   validators: [
    //     'required'
    //   ]
    // },
    Name: {
      type: 'input',
      label: 'Tên (bí danh)',
      validators: [
        'required'
      ]
    },
    Phone: {
      type: 'input',
      label: 'Số điện thoại',
      validators: [
      ]
    },
    Email: {
      type: 'input',
    },
    Website: {
      type: 'input',
    },
  };

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
  ) {
    super(rootServices, commonService);
    // // console.log('Click here to open ts file');

    this.rootServices.platform.pause.subscribe(status => {
      // Auto save
      // this.currentState?.data?.Code && this.currentState?.data?.State != 'APPROVED' && this.currentState.instance.save();
    });
  }

  makeModel(properties?: ECardModel): ECardModel {

    // if (!properties) {
    //   properties = {};
    // }
    // if (!properties.Page) {
    //   properties.Page = this.pageList[0];
    // }
    return new ECardModel(properties);
  }

  async makeNewData() {
    return new ECardModel();
  }

  getFormData(params?: any): Promise<ECardModel> {
    return super.getFormData({
      eq_User: this.rootServices?.authService?.getUser()?.id,
      sort_Id: 'asc',
      ...params,
    }).then(rs => {
      return rs;
    });
  }

  prepareData(data: ECardModel): ECardModel {
    return data;
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  async onComponentInit(state: ComponentStateExtend, index: string, asCase?: string, f7Page?: F7Page) {
    const $this = this;

    // Start loading status
    state.instance.$setState({
      loading: true,
      schema: this.schema,
    });
    this.commonService.showPreloader();

    // Load Contact Member list
    if (state?.instance?.$route?.context?.chatRoom?.memberList$) {
      await state?.instance?.$route?.context?.chatRoom?.memberList$?.pipe(take(1)).toPromise().then(memberList => {
        state.instance.contactList = memberList.filter(f => f.Type == 'CONTACT').map(m => { m['text'] = m.Name; return m; }) as any;
        if (state.instance.contactList && state.instance.contactList.length > 0) {
          this.schema['Customer'].validators = [];
        } else {
          // this.schema['Customer'].validators = [];
        }
      });

    }

    // Get static data
    await this.rootServices.apiService.getPromise<UnitModel[]>('/admin-product/units', { onlyIdText: true, limit: 'nolimit' }).then(rs => {
      state.instance.unitList = rs;
    });
    await this.rootServices.apiService.getPromise<any[]>('/collaborator/pages', { onlyIdText: true, limit: 'nolimit' }).then(rs => {
      state.instance.pageList = rs;
    });
    // End
    state.instance.$setState({
      unitList: state.instance.unitList,
      contactList: state.instance.contactList,
      processMap: this.rootServices.collaboratorService.opportunityProcessMap,
    });

    const currentState: ComponentStateExtend = await super.onComponentInit(state, index, asCase) as any;


    if (currentState.instance.$route?.context?.copyFromId) {
      currentState.instance.data.SubNote = 'Copy of ' + currentState.instance.$route?.context?.copyFromId + ': ' + (currentState.instance.data.SubNote ? ('<br>' + currentState.instance.data.SubNote) : '');
      this.setData(currentState.instance.data);
    }

    // if (currentState?.instance?.$route?.context?.relativeVouchers && currentState.instance.$route.context.relativeVouchers[0]?.text) {
    //   if (!currentState.data.Title) {
    //     currentState.data.Title = currentState.instance.$route.context.relativeVouchers[0]?.text;
    //     currentState.instance.$setState({ data: currentState.data });
    //     this.validate();
    //   }
    // }
    // End Prepare data

    // Stop loading status
    currentState.instance.$setState({ loading: false });
    this.commonService.hidePreloader();
    return currentState;
  }

  async setData(data: ECardModel, option?: { onlyList?: string[]; onlyKeys?: string[]; prepareControl?: boolean; }): Promise<boolean> {
    if (data.State) {
      data.State = this.commonService.getObjectId(data.State);
    }

    // const total = this.calculateTotal(data);
    return super.setData(data, option).then(status => {
      this.currentState.instance.$setState({ numOfChars: this.countChars() });

      // Update tabs

      return status;
    });
  }

  async addItemForList<CollaboratorOpportunityDetailModel>(listName: string, item?: CollaboratorOpportunityDetailModel): Promise<CollaboratorOpportunityDetailModel> {
    return super.addItemForList(listName, item).then(newItem => {
      this.commonService.showInfo('Đã thêm sản phẩm', { position: 'bottom' });
      return newItem;
    }).then(rs => {
      this.activeItem(this.currentState.data.Details.length - 1);
      this.rootServices.playNewPipSound();
      return rs;
    });
  }

  activeItem(index: number, newData?: CollaboratorOpportunityDetailModel) {

    this.currentState.data.Details.map(detail => {
      detail.__active = false;
    });
    this.currentState.data.Details[index].__active = true;

    setTimeout(() => {
      const itemEl = $(this.currentState.instance.el).find('.list-item.index-' + index);
      // itemEl[0] && itemEl[0].scrollIntoView();
      if (itemEl.length > 0) {
        const pageContentEl = $(this.currentState.instance.el).find('.page-content');
        pageContentEl.animate({ scrollTop: pageContentEl.scrollTop() + itemEl.offset().top - itemEl.height() }, 300);
      }
    }, 300);
  }

  countChars(data?: ECardModel) {
    const currentState = this.currentState;
    let numOfChars = 0;
    const calculateData = data || currentState.data;

    if (calculateData.Name) {
      numOfChars += calculateData.Name?.length + 3;
    }
    if (calculateData.Phone) {
      numOfChars += calculateData.Phone?.length + 4;
    }
    if (calculateData.Email) {
      numOfChars += calculateData.Email?.length + 6;
    }
    if (calculateData.Website) {
      numOfChars += calculateData.Website?.length + 4;
    }

    return numOfChars + 8;
  }

  async onFieldChange(self: F7ComponentContext, e: any, ...args: any) {
    const currentState = this.currentState;
    return super.onFieldChange(self, e, args).then(async fieldInfo => {

      if (!fieldInfo) {
        return null;
      }

      currentState.instance.$setState({ numOfChars: this.countChars() });

      return fieldInfo;
    });
  }

  async removeDetailItem(self: F7ComponentContext, e: any, ...args: any): Promise<{ detail?: any, listName?: string; index?: string | number; }> {
    const currentState = this.currentState;
    return new Promise((resolve, reject) => {
      self.$app.dialog.confirm('Bạn có chắc là muốn gở chi tiết này ?', 'Gở chi tiết', () => {
        resolve(super.removeDetailItem(self, e, args).then(detailInfo => {
          let { detail, listName, index } = detailInfo;
          if (listName == 'UnitConversions') {
            self.$app.accordion.close(detail[0]);
          }
          // this.calculateTotal();
          this.rootServices.playDecreasePipSound();
          return detailInfo;
        }).catch(err => {
          return Promise.reject(err);
        }));
      });
    });
  }


  async save(self: F7ComponentContext, option?: { postParams?: any, updateProperties?: string[] }) {
    const $this = this;
    if (!option) option = {};
    if (!option.postParams) option.postParams = {};
    option.postParams.page = $this.commonService.getObjectId($this.currentState.data.Page);

    // Do not update relative vouchers
    // delete this.currentState.data.RelativeVouchers;
    if (!/^new\-?/.test(this.currentState.instance?.$route?.params?.id)) {
      delete this.currentState.data.RelativeVouchers;
    }

    return super.save(self, option).then(rs => {
      this.currentState.data.State = rs.State;
      self.$setState({ data: this.currentState.data });
      return rs;
    });
  }

  async changeState(self: F7ComponentContextExtend, e: any) {
    // const newState = this.currentState.data.State == 'APPROVED' ? 'NOTJUSTAPPROVED' : 'APPROVED';
    const $this = this
    const currentState = this.rootServices.collaboratorService.opportunityProcessMap[this.currentState.data.State];
    if (currentState && currentState.nextStates) {
      const supplierDialog = this.currentState.instance.$app.dialog.create({
        cssClass: 'dialog-large',
        title: 'Chuyển trạng thái',
        text: 'Phiếu đang ở trạng thái `' + currentState.label + '`, bạn có muốn chuyển sang các trạng thái bên dưới ?',
        buttons: [
          ...currentState.nextStates.map((m, index) => ({
            text: (index + 1) + '. ' + m.confirmLabel,
            color: 'success',
            onClick: () => {
              this.rootServices.apiService.putPromise(this.apiPath + '/' + this.currentState.data.Code, { changeState: m.state }, [{ Code: this.currentState.data.Code }]).then(async rs => {
                this.currentState.data.State = m.state;
                this.setData(this.currentState.data);

                this.currentState.lastAction = 'UPDATE_SUCCESS';

                if (m.state == 'CONVERTED') {
                  const newData = await $this.refresh();
                  if (newData.RelativeVouchers && newData.RelativeVouchers.length > 0) {
                    const relativeOrders = newData.RelativeVouchers.filter(f => $this.commonService.getObjectId(f.type) == 'CLBRTORDER');
                    if (relativeOrders.length > 0) {
                      $this.rootServices.navigate('/collaborator/order/' + this.commonService.getObjectId(relativeOrders[0]), {
                        context: {
                          // copyFromId: item.Code,
                          backTitle: $this.title,
                        }
                      });
                    }
                  }
                }
              }).catch(err => {
                this.commonService.showError(err);
              });
            }
          })),
          {
            text: 'Trở về',
            bold: true,
            color: 'red'
          },
        ],
        verticalButtons: true,
      });
      supplierDialog.open();
    }

    // this.currentState.instance.$app.dialog.confirm('Bạn có muốn ' + (newState == 'APPROVED' ? 'duyệt' : 'bỏ duyệt') + ' phiếu báo giá `' + this.currentState.data.Title + '`', 'Thay đổi trạng thái báo giá', async () => {

    //   if (this.currentState.data.State !== 'APPROVED') {
    //     await this.save(self);
    //   }
    //   await this.rootServices.apiService.putPromise<ECardModel>(this.apiPath, {
    //     changeState: newState,
    //   }, [{ Code: this.currentState.data.Code }]).then(rs => {
    //     if (newState == 'APPROVED') {
    //       this.commonService.showInfo('Đã duyệt phiếu đặt mua hàng !', { position: 'bottom' });
    //     } else {
    //       this.commonService.showInfo('Đã bỏ duyệt phiếu đặt mua hàng !', { position: 'bottom' });
    //     }
    //   });
    //   this.currentState.data.State = newState;
    //   this.setData(this.currentState.data);

    // });
  }

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: 'e-card-setting',
      path: '/e-card-setting/:id',
      component: {
        template: /*html*/`
            <div class="page page-form e-card-setting-form no-toolbar-x" data-name="e-card-setting-form">
              <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a class="link back {{textColorClass}}">
                      <i class="icon icon-back"></i>
                      <span class="if-not-md {{textColorClass}}">{{backTitle}}</span>
                    </a>
                  </div>
                  <div class="title">Cơ hội</div>
                  <div class="right">
                    <a class="link icon-only {{textColorClass}}">
                      <i class="icon f7-icons">arrowshape_turn_up_right</i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="page-content ptr-content infinite-scroll-content" @ptr:refresh="refresh" data-ptr-distance="150">
                <div class="ptr-preloader">
                  <div class="preloader"></div>
                  <div class="ptr-arrow"></div>
                </div>

                <form class="data-form">
                  <div class="list profile-form no-hairlines-md inset main-form">
                    <ul>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label">Serial number eCard</div>
                          <div class="item-input-wrap">
                            <input @change="onFieldChange" disabled class="field text-color-blue" name="SerialNumber" type="text" placeholder="Serial number eCard..." value="{{data.SerialNumber}}" disabled>
                          </div>
                        </div>
                      </li>
                      {{#if copyFromId}}
                      <li class="item-content">
                        <div class="item-inner">
                          <div class="item-input-wrap text-color-orange">
                            <span style="font-weight: bold; font-style: italic">Bạn đang thao tác trên phiếu copy</span>
                          </div>
                        </div>
                      </li>
                      {{/if}}
                    </ul>
                  </div>

                  <div class="block-title">Thông tin chung</div>
                  <div class="list profile-form no-hairlines-md inset main-form">
                    <ul>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label {{validate data.Name schema 'Name'}}">Tên (bí danh) {{validatesymbol schema 'Name'}}...</div>
                          <div class="item-input-wrap">
                            <input @keyup="onFieldChange" class="field text-color-blue" name="Name" type="text" placeholder="Tên (bí danh)..."
                              value="{{data.Name}}">
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label {{validate data.Phone schema 'Phone'}}">Số điện thoại {{validatesymbol schema 'Phone'}}...</div>
                          <div class="item-input-wrap">
                            <input @keyup="onFieldChange" class="field text-color-blue" name="Phone" type="text" placeholder="Số điện thoại..."
                              value="{{data.Phone}}">
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label {{validate data.Email schema 'Email'}}">Email {{validatesymbol schema 'Email'}}...</div>
                          <div class="item-input-wrap">
                            <input @keyup="onFieldChange" class="field text-color-blue" name="Email" type="text" placeholder="Email..."
                              value="{{data.Email}}">
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label {{validate data.Email schema 'c'}}">Link BIO {{validatesymbol schema 'Website'}}...</div>
                          <div class="item-input-wrap">
                            <input @keyup="onFieldChange" class="field text-color-blue" name="Website" type="text" placeholder="Link BIO..."
                              value="{{data.Website}}" disabled>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div class="block-footer {{#js_if 'this.numOfChars > 492'}}text-color-red{{/js_if}}">{{objecttext this 'numOfChars' '0'}}/492 ký tự</div>
                  <div class="block-footer">Thông tin sẽ được ghi vào eCard</div>

                  <div class="block">
                    <p class="row">
                      <a class="col-100 button button-fill button-large color-blue {{#if validates}}disabled{{/if}} {{#js_if 'this.numOfChars > 492'}}disabled{{/js_if}}"  @click="save"> Lưu lại</a>
                    </p>
                  </div>
                  {{#if validates}}
                  <div class="block-title text-color-arange">Cảnh báo</div>
                  <div class="list inset">
                    <ul>
                      {{#each validates}}
                      <li>
                        <div class="item-content">
                          <div class="item-inner">
                            <div class="item-title">{{validates[0].label}}</div>
                            <div class="item-after text-color-orange">{{validates[0].text}}</div>
                          </div>
                        </div>
                      </li>
                      {{/each}}
                    </ul>
                  </div>
                  {{/if}}
                </form>
            
                <div class="block"><br></div>
              </div>
            </div>
        `,
        style: /*css*/`
          .page.e-card-setting-form .demo-card-header-pic .card-header {
            height: calc(100vw - 2rem);
            background-size: cover;
            background-position: center;
            color: #fff;
          }

          .page.e-card-setting-form .demo-card-header-pic .card-content-padding .date {
            color: #8e8e93;
          }
          .page.e-card-setting-form .pictures::-webkit-scrollbar {
            display: none;
          }

          .page.e-card-setting-form .card .background {
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-image: url(/assets/images/no-image-available.png);
          }

          .page.e-card-setting-form .inline-labels .item-label, .inline-label .item-label, .inline-labels .item-floating-label, .inline-label .item-floating-label {
            width: auto !important;
          }
        `,
        data() {
          return {
            title: $this.title,
            reminder: {
              No: '234234',
            },
            data: new ECardModel(),
            contactMemebets: [],
            // contactList: [],
            validates: null,
            numOfChars: 0,
          };
        },
        methods: {
          createNewCustomer(e) {
            const self = this;
            $this.rootServices.createNewContactForm('Khách hàng mới', { Groups: [{ id: 'CUSTOMER', text: 'Khách hàng' }] }).then(newContact => {
              $this.currentState.data.Customer = { id: newContact.Code, text: newContact.Name } as any;
              $this.currentState.data.CustomerName = newContact.Name;
              $this.currentState.data.CustomerPhone = newContact.Phone;
              $this.currentState.data.CustomerEmail = newContact.Email;
              $this.currentState.data.CustomerAddress = newContact.Address;
              $this.setData($this.currentState.data);
            }).catch(err => {
              $this.commonService.showError(err);
            });
          },
          createNewPublisher(e) {
            const self = this;
            $this.rootServices.createNewContactForm('CTV Bán hàng mới', { Groups: [{ id: 'PUBLISHER', text: 'CTV' }, { id: 'SALESCOLLABORATOR', text: 'CTV Bán Hàng ™' }] }).then(newContact => {
              $this.currentState.data.Publisher = { id: newContact.Code, text: newContact.Name } as any;
              $this.currentState.data.PublisherName = newContact.Name;
              $this.currentState.data.PublisherPhone = newContact.Phone;
              $this.currentState.data.PublisherEmail = newContact.Email;
              $this.currentState.data.PublisherAddress = newContact.Address;
              $this.setData($this.currentState.data);
            }).catch(err => {
              $this.commonService.showError(err);
            });
          },
          previewPictures(e) {
            const self: F7ComponentContextExtend = this;
            const currentState = $this.currentState;

            let index = $(e.target).data('index');
            if (typeof index == 'undefined') {
              const id = $(e.target).attr('data-id');
              index = currentState.data.Attachments.findIndex(f => f.Id == id);
            }
            $this.rootServices.previewPictures(currentState.data.Attachments, index || 0, { addToStackButton: true });
          },
          addAcctachment() {
            const self: F7ComponentContextExtend = this;
            const currentState = $this.currentState;
            $this.rootServices.pickAndUploadFiles().subscribe(state => {
              if (state.state == 'UPLOADING') {
                const progress = state.progress;
                console.log('Upload progress: ', progress);
              }
              if (state.state == 'COMPLETE') {
                const images = state.results;
                // currentState.data.Pictures.unshift(images[0]);
                // currentState.data.FeaturePicture = images[0];
                // self.$setState({ data: currentState.data });
                console.log('upload pictures success', images);
                if (!$this.currentState.data.Attachments) {
                  $this.currentState.data.Attachments = [];
                }
                for (const image of images) {
                  $this.currentState.data.Attachments.push(image);
                }
                $this.currentState.instance.$setState({ data: $this.currentState.data });
              }
              if (state.state == 'ERROR') {
                console.error(state);
              }
            });
          },
          changeState(e) {
            $this.changeState(this, e);
          },
          onFieldChange(e) {
            $this.onFieldChange(this, e);
          },
          async refresh(e, done) {

            done && setTimeout(() => done(), 10000);
            $this.refresh(this).then(data => {
              done && done();
            });

            return true;
          },
          save() {
            const self: F7ComponentContextExtend = this;
            const currentState = $this.currentState;
            // const relativeVouchers = self.$route.context.relativeVouchers;
            const id = currentState.data.Code || self.$route.params['id'];

            $this.commonService.showPreloader();

            const preloaderProcess = setTimeout(() => {
              $this.commonService.hidePreloader();
            }, 15000);

            if (id === 'new') {
              // if (relativeVouchers) {
              //   currentState.data.RelativeVouchers = relativeVouchers;
              // }
            }

            $this.save(self).then(rs => {
              $this.commonService.showInfo("Đã lưu đon đặt mua hàng", { position: 'bottom' });
              clearTimeout(preloaderProcess);
              $this.commonService.hidePreloader();
            }).catch(err => {
              clearTimeout(preloaderProcess);
              $this.commonService.hidePreloader();
              console.error(err);
            });
          },
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          async pageInit(e, page: F7Page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;
            if (self.$route.params['id'] == 'default') {
              const eCard = await $this.rootServices.apiService.getPromise<any[]>($this.apiPath, { eq_User: $this.rootServices?.authService?.getUser()?.id, sort_Id: 'desc' }).then(rs => rs[0]);
              if (eCard) {
                self.$route.params['id'] = eCard.Code;
              }
            }
            $this.onComponentInit({ instance: self, page: page }, self.$route.params['id'], null, page).then(async currentState => {
              // self.detailDialog = self.$app.dialog.create({
              //   el: page.$el.find('.dialog-detail'),
              //   closeByBackdropClick: true,
              // });

              await $this.rootServices.apiService.getPromise<any[]>('/bio/pages', { eq_User: $this.rootServices.authService.user$?.value.id }).then(rs => rs[0]).then(async bio => {
                console.log(bio);
                const ecard = self.data;

                if (!ecard.Name) {
                  ecard.Name = bio.Aliases;
                }
                if (!ecard.Phone) {
                  ecard.Phone = bio.Phone;
                }
                if (!ecard.Email) {
                  ecard.Email = bio.Email;
                }
                if (!ecard.Website) {
                  ecard.Website = `https://probox.vn/${bio.Tag}`;
                }
                if (!ecard.SerialNumber) {
                  ecard.SerialNumber = bio.EcardSerialNumber;
                }

                $this.setData(ecard);

              });

              return currentState;
            });

            $this.rootServices.authService.isAuthenticatedOrRefresh().pipe(take(1)).toPromise().then(() => {
              self.$setState({
                backTitle: self.$route.context && self.$route.context.backTitle || 'Back', title: self.$route.context && self.$route.context.title || 'Chat GUI',
              });
            }).catch(err => {
              console.error(err);
              $this.commonService.showError(err, { position: 'bottom' });
            });
          },
          // pageBeforeIn(e, page) {
          //     console.log('page before in');
          //     const self: F7ComponentContextExtend = this;
          //     self.refresh();
          // },
          // pageAfterIn(e, page) {
          //     console.log('page after in');
          // },
          pageBeforeOut(e, page) {
            console.log('page before out');
            const self: F7ComponentContextExtend = this;
            // Call caback function
            if (this.$route?.context['callback']) {
              this.$route?.context['callback']($this.currentState.data, { lastAction: $this.currentState.lastAction });
            }

            // Auto save
            // $this.currentState?.data?.Code && $this.currentState?.data?.State != 'APPROVED' && self.save();
          },
          // pageAfterOut(e, page) {
          //     console.log('page after out');
          // },
          // pageBeforeUnmount(e, page) {
          //     console.log('page before unmount');
          // },
          // pageBeforeRemove(e, page) {
          //     console.log('page before remove');
          // },
          pageBeforeRemove(e, page) {
            console.log('[page event] before remove', page.route.url);
            const self: F7ComponentContextExtend = this;
            // const starmtSelectRoles = self.$app.smartSelect.get('.smart-select');
            // starmtSelectRoles.destroy();
            const currentState = $this.state[self.$route.params.id];
            if (self.$route.params.id === 'new') {
              if (currentState && currentState.data && currentState.data.Code) {
                delete currentState.data;
              }
            }
          },
        },
      },
    };
  }
}
