import { PhotoBrowser } from 'framework7/build/core/components/photo-browser/photo-browser';
import { FileModel } from '../../src/app/model/file.model';
import { Platform, isPlatform, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import Framework7, { Dom7, Template7 } from 'framework7/build/core/framework7.esm';
import { Router as F7Router, Router } from 'framework7/build/core/modules/router/router';
import { ChatRoomComponent } from './components/chat-room.component';
import { WorkplaceComponent } from './components/workplace.component';
import { HelpdeskComponent } from './components/helpdesk.component';
import { NotificationComponent } from './components/notification.component';
import { LoginComponent } from './components/login.component';
import { F7Framework7, F7TabContext } from './types/framework7-types';
import { View as F7View, View } from 'framework7/build/core/components/view/view';
import { AuthService } from './services/auth.service';
import { Storage } from '@ionic/storage';
import { ApiService } from './services/api.service';
import { SettingsComponent } from './components/settings.component';
// import { SocketService } from './services/socket.service';
import { RootServices } from './services/root.services';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { ImagePicker } from '@ionic-native/image-picker/ngx';
import { ChatRoomControlComponent } from './components/chat-room-control.component';
import { UsersComponent } from './components/users.component';
import { filter, take, takeUntil } from 'rxjs/operators';
import { CommonService } from './services/common.service';
import { HomeComponent } from './components/home.component';
import { BehaviorSubject, Subject } from 'rxjs';
import { AccountComponent } from './components/account.component';
import { GoogleMapsComponent } from './components/goolge-maps.component';
import { BaseComponent, ComponentState } from './lib/base-component';
import { RecentLoginsComponent } from './components/recent-logins.component';
import { RecentLoginModel } from './model/recent-login.model';
import { DownloadProgress, SyncStatus } from '@ionic-native/code-push/ngx';
import { UpdateComponent } from './components/update.component';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { TaskReminderComponent } from './components/task-reminder';
import { TaskActivitiesComponent } from './components/task-activities.component';
import { ProfileComponent } from './components/profile.component';
import { ContactsComponent } from './components/contacts.component';
import { ProductListComponent } from './components/product-list';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
// import { WarehouseGoodsListComponent } from './components/warehouse/goods-list.ts';
import { WarehouseContainerListComponent } from './components/warehouse/container-list';
import { WarehouseBookFormComponent } from './components/warehouse/book-form';
import { AdminProductProductListComponent } from './components/admin-product/product-list';
import { PurchaseOrderListComponent } from './components/purchase/order/purchase-order-list';
import { PurchaseOrderFormComponent } from './components/purchase/order/purchase-order-form';
import { PriceReportListComponent } from './components/sales/price-report/sales-price-report-list';
import { CommercePosGuiComponent } from './components/commerce-pos/gui/pos-gui';
import { CommercePosGoodsListComponent } from './components/commerce-pos/gui/goods-list';
import { CommercePosDashboardComponent } from './components/commerce-pos/dashboard/pos-dashboard';
import { SalesDashboardComponent } from './components/sales/dashboard/sales-dashboard';
import { AccountingDashboardComponent } from './components/accounting/dashboard/accounting-dashboard';
import { PurchaseDashboardComponent } from './components/purchase/dashboard/purchase-dashboard';
import { WarehouseDashboardComponent } from './components/warehouse/dashboard/warehouse-dashboard';
import { AdminProductProductFormComponent } from './components/admin-product/product-form';
import { SalesPriceReportFormComponent } from './components/sales/price-report/sales-price-report-form';
import * as moment from 'moment';
import { SalesGoodsListComponent } from './components/sales/goods-list';
import { AccPaymentVoucherListComponent } from './components/accounting/payment-voucher/payment-voucher-list';
import { AccPaymentVoucherFormComponent } from './components/accounting/payment-voucher/payment-voucher-form';
import { AccReceiptVoucherListComponent } from './components/accounting/receipt-voucher/receipt-voucher-list';
import { AccReceiptVoucherFormComponent } from './components/accounting/receipt-voucher/receipt-voucher-form';
import { WarehouseInventoryAdjustNoteListComponent } from './components/warehouse/inventory-adjust-note/inventory-adjust-note-list';
import { WarehouseInventoryAdjustNoteFormComponent } from './components/warehouse/inventory-adjust-note/inventory-adjust-note-form';
import { WarehouseGoodsListComponent } from './components/warehouse/goods-list';
import { WarehouseGoodsQueueComponent } from './components/warehouse/goods-queue';
import { CommercePosOrderListComponent } from './components/commerce-pos/gui/order-list';
// import { Printer } from '@awesome-cordova-plugins/printer/ngx';
import { CommerceOrderPrintComponent } from './components/commerce-pos/gui/order-print';
import { PurchasePriceStatisticsComponent } from './components/purchase/dashboard/purchase-price-statistics';
import { environment } from '../../src/environments/environment';
import { DocumentScannerOptions } from '@awesome-cordova-plugins/document-scanner/ngx';
import { ZBarOptions } from '@awesome-cordova-plugins/zbar/ngx';
import { ContactModel } from './model/contact.model';
import { WarehouseGoodsQueueModel } from './model/warehouse.model';
import { PurchaseProductListComponent } from './components/purchase/product-list';
import { CollaboratorOrderListComponent } from './components/collaborator/order/order-list';
import { CollaboratorOrderFormComponent } from './components/collaborator/order/order-form';
import { CollaboratorProductListComponent } from './components/collaborator/product-list';
import { ContactListComponent } from './components/contact/contact-list';
import { ContactFormComponent } from './components/contact/contact-form';
import { CollaboratorCustomerListComponent } from './components/collaborator/customer/customer-list';
import { CollaboratorCustomerFormComponent } from './components/collaborator/customer/customer-form';
import { CollaboratorPublisherListComponent } from './components/collaborator/publisher/publisher-list';
import { CollaboratorPublisherFormComponent } from './components/collaborator/publisher/publisher-form';
import { CollaboratorDashboardComponent } from './components/collaborator/dashboard/dashboard';
import { SalesMasterPriceTableUpdateNoteListComponent } from './components/sales/master-price-table-update-note/master-price-table-update-note-list';
import { DeplpoymentVoucherListComponent } from './components/deployment/deloyment-voucher/deloyment-voucher-list';
import { DeplpoymentVoucherFormComponent } from './components/deployment/deloyment-voucher/deloyment-voucher-form';
import { CollaboratorOpportunityListComponent } from './components/collaborator/opportunity/opportunity-list';
import { CollaboratorOpportunityFormComponent } from './components/collaborator/opportunity/opportunity-form';
import { AboutComponent } from './components/about.component';
import { PbxExtensionListComponent } from './components/pbx/extension/extension-list';
import { PbxCallLogListComponent } from './components/pbx/call-log/call-log-list';
import { MailClientInboxComponent } from './components/mail-client/email-list/inbox';
import { ContractListComponent } from './components/contract/contract/contract-list';
import { ContractFormComponent } from './components/contract/contract/contract-form';
// import * as $ from 'jquery';
declare const $: any;

// declare const $: any;

declare let globalRootServices: RootServices;
declare let globalCommonService: CommonService;
// @Component({
//   selector: 'app-root',
//   templateUrl: 'app.component.html',
//   styleUrls: ['app.component.scss'],
//   // providers: [DatePipe],
// })
export class AppComponentProcessor {

  /** Views */
  notificationView: F7View.View;
  mainView: F7View.View;
  homeView: F7View.View;
  notificationsView: F7View.View;
  helpdeskView: F7View.View;
  workplaceView: F7View.View;
  settingsView: F7View.View;
  dialpadView: F7View.View;

  /** Components */
  homeComponent: HomeComponent;
  chatRoomComponent: ChatRoomComponent;
  chatRoomControlComponent: ChatRoomControlComponent;
  workplaceComponent: WorkplaceComponent;
  helpdeskComponent: HelpdeskComponent;
  notificationComponent: NotificationComponent;
  loginComponent: LoginComponent;
  settingsComponent: SettingsComponent;
  usersComponent: UsersComponent;
  accountComponent: AccountComponent;
  profileComponent: ProfileComponent;
  recentLoginsComponent: RecentLoginsComponent;
  updateComponent: UpdateComponent;
  // dialpadComponent: DialpadComponent;
  taskReminderComponent: TaskReminderComponent;
  taskActivitiesComponent: TaskActivitiesComponent;
  googleMapsComponent: GoogleMapsComponent;
  contactsComponent: ContactsComponent;
  salesPriceReportFormComponent: SalesPriceReportFormComponent;
  salesMasterPriceTableUpdateNoteListComponent: SalesMasterPriceTableUpdateNoteListComponent;
  productListComponent: ProductListComponent;
  warehouseGoodsListComponent: WarehouseGoodsListComponent;
  warehouseContainerListComponent: WarehouseContainerListComponent;
  warehouseInventoryAdjustNoteListComponent: WarehouseInventoryAdjustNoteListComponent;
  warehouseInventoryAdjustNoteFormComponent: WarehouseInventoryAdjustNoteFormComponent;
  warehouseBookFormComponent: WarehouseBookFormComponent;
  adminProductProductListComponent: AdminProductProductListComponent;
  salesGoodsListComponent: SalesGoodsListComponent;
  purchaseOrderListComponent: PurchaseOrderListComponent;
  purchaseOrderFormComponent: PurchaseOrderFormComponent;
  priceReportListComponent: PriceReportListComponent;
  commercePosGuiComponent: CommercePosGuiComponent;
  commercePosGoodsListComponent: CommercePosGoodsListComponent;
  commercePosDashboardComponent: CommercePosDashboardComponent;
  salesDashboardComponent: SalesDashboardComponent;
  accountingDashboardComponent: AccountingDashboardComponent;
  purchaseDashboardComponent: PurchaseDashboardComponent;
  warehouseDashboardComponent: WarehouseDashboardComponent;
  adminProductProductFormComponent: AdminProductProductFormComponent;
  accPaymentVoucherListComponent: AccPaymentVoucherListComponent;
  accPaymentVoucherFormComponent: AccPaymentVoucherFormComponent;
  accReceiptVoucherListComponent: AccReceiptVoucherListComponent;
  accReceiptVoucherFormComponent: AccReceiptVoucherFormComponent;
  warehouseGoodsQueueComponent: WarehouseGoodsQueueComponent;
  commercePosOrderListComponent: CommercePosOrderListComponent;
  commercePosOrderPrintComponent: CommerceOrderPrintComponent;
  purchasePriceStatisticsComponent: PurchasePriceStatisticsComponent;
  purchaseProductListComponent: PurchaseProductListComponent;
  collaboratorDashboardComponent: CollaboratorDashboardComponent;
  collaboratorOrderListComponent: CollaboratorOrderListComponent;
  collaboratorOrderFormComponent: CollaboratorOrderFormComponent;
  collaboratorProductListComponent: CollaboratorProductListComponent;
  collaboratorCustomerListComponent: CollaboratorCustomerListComponent;
  collaboratorCustomerFormComponent: CollaboratorCustomerFormComponent;
  collaboratorPublisherListComponent: CollaboratorPublisherListComponent;
  collaboratorPublisherFormComponent: CollaboratorPublisherFormComponent;
  collaboratorOpportunityListComponent: CollaboratorOpportunityListComponent;
  collaboratorOpportunityFormComponent: CollaboratorOpportunityFormComponent;
  cotactListComponent: ContactListComponent;
  cotactFormComponent: ContactFormComponent;
  deplpoymentVoucherListComponent: DeplpoymentVoucherListComponent;
  deplpoymentVoucherFormComponent: DeplpoymentVoucherFormComponent;
  aboutComponent: AboutComponent;
  // collaboratorProductListComponent: CollaboratorProductListComponent;

  components: { [key: string]: BaseComponent<ComponentState> };

  routes: Router.RouteParameters[];

  isDarkMode$ = new BehaviorSubject(false);
  app: Framework7;

  // frameSocket: FrameSocket;
  isDesktop = isPlatform('desktop');

  previewStack: FileModel[] = [];
  previewStackIndex = 0;

  constructor(
    public platform: Platform,
    public splashScreen: SplashScreen,
    public statusBar: StatusBar,
    public authService: AuthService,
    public storage: Storage,
    public apiService: ApiService,
    // public socketService: SocketService,
    public rootServices: RootServices,
    public datePipe: DatePipe,
    public currencyPipe: CurrencyPipe,
    public imagePicker: ImagePicker,
    public commonService: CommonService,
    public iab: InAppBrowser,
    public fileTransfer: FileTransfer,
    public file: File,
    public deeplinks: Deeplinks,
    public navController: NavController,
    // public printer?: Printer,
  ) {
    window['globalRootServices'] = rootServices;
    window['globalCommonService'] = commonService;
    this.initializeApp();
    this.prepareForFrame();
    this.rootServices.camera.cleanup();


    if (this.rootServices.isAndroid) {
      this.rootServices.keyboard.onKeyboardDidShow().subscribe(info => {
        console.log(info);
        $('body').find('.page').css({ height: '100%' });
        $('body').find('.page.page-current:not(.skip-keyboard-resize)')
          .css({ height: 'calc(100% - ' + (info.keyboardHeight + 29) + 'px)' });
      });
      this.rootServices.keyboard.onKeyboardDidHide().subscribe(info => {
        console.log(info);
        $('body').find('.page').css({ height: '100%' });
      });
    }
    if (this.rootServices.isIos) {
      this.rootServices.keyboard.onKeyboardDidShow().subscribe(info => {
        console.log(info);
        $('body').find('.page').css({ height: '100%' });
        $('body').find('.page.page-current:not(.skip-keyboard-resize)')
          .css({ height: 'calc(100% - ' + (info.keyboardHeight) + 'px)' });
      });
      this.rootServices.keyboard.onKeyboardWillHide().subscribe(info => {
        console.log(info);
        $('body').find('.page').css({ height: '100%' });
      });
    }

    // Assistive touch
    document.addEventListener("deviceready", () => {
      const assistiveTouch = $('#assistive-touch')[0];
      //   var offset = [50, 500];
      //   // var assistiveTouch = document.getElementById("overlay");
      //   var isDown = false;
      //   assistiveTouch.addEventListener(isPlatform('ios') || isPlatform('android') ? 'touchstart' : 'mousedown', function (e) {
      //     isDown = true;
      //     if (isPlatform('android')) {
      //       offset = [
      //         assistiveTouch.offsetLeft - e.changedTouches[0].pageX,
      //         assistiveTouch.offsetTop - e.changedTouches[0].pageY
      //       ];
      //     } else {
      //       offset = [
      //         assistiveTouch.offsetLeft - e.pageX,
      //         assistiveTouch.offsetTop - e.pageY
      //       ];
      //     }
      //   }, true);
      //   document.addEventListener(isPlatform('ios') || isPlatform('android') ? 'touchend' : 'mouseup', function () {
      //     isDown = false;
      //   }, true);

      //   document.addEventListener(isPlatform('ios') || isPlatform('android') ? 'touchmove' : 'mousemove', function (e) {
      //     event.preventDefault();
      //     if (isDown) {
      //       if (isPlatform('android')) {
      //         assistiveTouch.style.left = (e['changedTouches'][0]['pageX'] + offset[0]) + 'px';
      //         assistiveTouch.style.top = (e['changedTouches'][0]['pageY'] + offset[1]) + 'px';
      //       } else {
      //         assistiveTouch.style.left = (e['pageX'] + offset[0]) + 'px';
      //         assistiveTouch.style.top = (e['pageY'] + offset[1]) + 'px';
      //       }
      //     }
      //   }, true);
      this.rootServices.enableAssistiveTouchFeature(assistiveTouch);
    });
    // // End assistive touch

    this.restorePreviewStack();

  }

  get appName() {
    return environment.name;
  }

  prepareForFrame() {
    // this.frameSocket = new FrameSocket(window.top);

    // this.frameSocket.on<boolean>('ready').subscribe(async request => {
    //   console.debug('parent ready', request);
    //   // Set mode to frame
    // });
    this.initForFrame();
  }

  async initForFrame() {

    const url = new URL(window.location.href);
    const initTheme = url.searchParams.get('theme');
    if (initTheme) {
      this.isDarkMode$.next(initTheme === 'dark' || initTheme === 'cosmic' ? true : false);
    }

    this.commonService.frameSocket.on<{ chatRoom: string; type: string }>('open-chat-room').subscribe(request => {
      const chatRoom = request.data.chatRoom;
      const type = request.data?.type;
      console.error(request);
      this.rootServices.navigate('/chat-room/' + chatRoom, {
        context: { textColorClass: type === 'HELPDESK' ? 'helpdesk-txt-color-red' : '' }
      });
      request.callback('success', true);
    });

    this.commonService.frameSocket.on<{ theme: string }>('change-theme').subscribe(request => {
      console.log('Receive theme change: ', request);
      this.isDarkMode$.next(request.data.theme === 'dark' || request.data.theme === 'cosmic' ? true : false);
      request.callback('success', true);
    });

    // this.commonService.frameSocket.on<{ phonenumber: string; name: string }>('phone-call').subscribe(async request => {
    //   const phonenumber = request.data.phonenumber;
    //   const name = request.data.name;
    //   console.debug(request);
    //   if (phonenumber) {
    //     const dialpadComponent: DialpadComponent = this.components['dialpad'] as DialpadComponent;
    //     if (dialpadComponent) {
    //       const session = await dialpadComponent.call(phonenumber, name || phonenumber);
    //       // const subcription = session.stateChanged$.pipe(filter(state => state === 'progress'), take(1)).subscribe(state => {
    //       request.callback('success', session);
    //       // });
    //       // setTimeout(() => {
    //       //   subcription.unsubscribe();
    //       // }, 10000);
    //       return true;
    //     }
    //     request.callback('error', 'dialpad was not init');
    //     return false;
    //   }
    //   request.callback('error', 'phonenumber was not provided');
    // });
    return true;
  }

  /** Prepare router */
  async prepareRouter() {
    this.homeComponent = new HomeComponent(this.rootServices, this.commonService);
    this.chatRoomComponent = new ChatRoomComponent(this.rootServices, this.commonService, this.datePipe, this.imagePicker,
      this.iab,
      this.fileTransfer,
      this.file,
      this,
    );
    this.chatRoomControlComponent = new ChatRoomControlComponent(this.rootServices, this.commonService, this.datePipe);
    this.workplaceComponent = new WorkplaceComponent(this.rootServices, this.commonService, this.datePipe);
    this.helpdeskComponent = new HelpdeskComponent(this.rootServices, this.commonService, this.datePipe);
    this.notificationComponent = new NotificationComponent(this.rootServices, this.commonService);
    this.loginComponent = new LoginComponent(this.rootServices, this.commonService);
    this.settingsComponent = new SettingsComponent(this, this.rootServices, this.commonService);
    this.usersComponent = new UsersComponent(this.rootServices, this.commonService);
    this.accountComponent = new AccountComponent(this.rootServices, this.commonService, this);
    this.profileComponent = new ProfileComponent(this.rootServices, this.commonService);
    this.recentLoginsComponent = new RecentLoginsComponent(this.rootServices, this.commonService);
    this.updateComponent = new UpdateComponent(this.rootServices, this.commonService);
    this.taskReminderComponent = new TaskReminderComponent(this.rootServices, this.commonService);
    this.taskActivitiesComponent = new TaskActivitiesComponent(this.rootServices, this.commonService, this.datePipe);
    this.googleMapsComponent = new GoogleMapsComponent(this.rootServices, this.commonService);
    this.contactsComponent = new ContactsComponent(this.rootServices, this.commonService);
    this.salesDashboardComponent = new SalesDashboardComponent(this.rootServices, this.commonService);
    this.salesGoodsListComponent = new SalesGoodsListComponent(this.rootServices, this.commonService);
    this.salesPriceReportFormComponent = new SalesPriceReportFormComponent(this.rootServices, this.commonService);
    this.salesMasterPriceTableUpdateNoteListComponent = new SalesMasterPriceTableUpdateNoteListComponent(this.rootServices, this.commonService);
    this.productListComponent = new ProductListComponent(this.rootServices, this.commonService);
    this.warehouseGoodsListComponent = new WarehouseGoodsListComponent(this.rootServices, this.commonService);
    this.warehouseContainerListComponent = new WarehouseContainerListComponent(this.rootServices, this.commonService);
    this.warehouseInventoryAdjustNoteListComponent = new WarehouseInventoryAdjustNoteListComponent(this.rootServices, this.commonService);
    this.warehouseInventoryAdjustNoteFormComponent = new WarehouseInventoryAdjustNoteFormComponent(this.rootServices, this.commonService);
    this.warehouseBookFormComponent = new WarehouseBookFormComponent(this.rootServices, this.commonService);
    this.adminProductProductListComponent = new AdminProductProductListComponent(this.rootServices, this.commonService);
    this.purchaseOrderListComponent = new PurchaseOrderListComponent(this.rootServices, this.commonService);
    this.purchaseOrderFormComponent = new PurchaseOrderFormComponent(this.rootServices, this.commonService);
    this.priceReportListComponent = new PriceReportListComponent(this.rootServices, this.commonService);
    this.commercePosGuiComponent = new CommercePosGuiComponent(this.rootServices, this.commonService);
    this.commercePosGoodsListComponent = new CommercePosGoodsListComponent(this.rootServices, this.commonService);
    this.commercePosDashboardComponent = new CommercePosDashboardComponent(this.rootServices, this.commonService);
    this.accountingDashboardComponent = new AccountingDashboardComponent(this.rootServices, this.commonService);
    this.purchaseDashboardComponent = new PurchaseDashboardComponent(this.rootServices, this.commonService);
    this.warehouseDashboardComponent = new WarehouseDashboardComponent(this.rootServices, this.commonService);
    this.adminProductProductFormComponent = new AdminProductProductFormComponent(this.rootServices, this.commonService);
    this.accPaymentVoucherListComponent = new AccPaymentVoucherListComponent(this.rootServices, this.commonService);
    this.accPaymentVoucherFormComponent = new AccPaymentVoucherFormComponent(this.rootServices, this.commonService);
    this.accReceiptVoucherListComponent = new AccReceiptVoucherListComponent(this.rootServices, this.commonService);
    this.accReceiptVoucherFormComponent = new AccReceiptVoucherFormComponent(this.rootServices, this.commonService);
    this.warehouseGoodsQueueComponent = new WarehouseGoodsQueueComponent(this.rootServices, this.commonService);
    this.commercePosOrderListComponent = new CommercePosOrderListComponent(this.rootServices, this.commonService);
    this.commercePosOrderPrintComponent = new CommerceOrderPrintComponent(this.rootServices, this.commonService);
    this.purchasePriceStatisticsComponent = new PurchasePriceStatisticsComponent(this.rootServices, this.commonService);
    this.purchaseProductListComponent = new PurchaseProductListComponent(this.rootServices, this.commonService);
    this.collaboratorDashboardComponent = new CollaboratorDashboardComponent(this.rootServices, this.commonService);
    this.collaboratorOrderListComponent = new CollaboratorOrderListComponent(this.rootServices, this.commonService);
    this.collaboratorOrderFormComponent = new CollaboratorOrderFormComponent(this.rootServices, this.commonService);
    this.collaboratorProductListComponent = new CollaboratorProductListComponent(this.rootServices, this.commonService);
    this.collaboratorCustomerListComponent = new CollaboratorCustomerListComponent(this.rootServices, this.commonService);
    this.collaboratorCustomerFormComponent = new CollaboratorCustomerFormComponent(this.rootServices, this.commonService);
    this.collaboratorPublisherListComponent = new CollaboratorPublisherListComponent(this.rootServices, this.commonService);
    this.collaboratorPublisherFormComponent = new CollaboratorPublisherFormComponent(this.rootServices, this.commonService);
    this.collaboratorOpportunityListComponent = new CollaboratorOpportunityListComponent(this.rootServices, this.commonService);
    this.collaboratorOpportunityFormComponent = new CollaboratorOpportunityFormComponent(this.rootServices, this.commonService);
    this.cotactListComponent = new ContactListComponent(this.rootServices, this.commonService);
    this.cotactFormComponent = new ContactFormComponent(this.rootServices, this.commonService);
    this.deplpoymentVoucherListComponent = new DeplpoymentVoucherListComponent(this.rootServices, this.commonService);
    this.deplpoymentVoucherFormComponent = new DeplpoymentVoucherFormComponent(this.rootServices, this.commonService);
    this.aboutComponent = new AboutComponent(this.rootServices, this.commonService);

    this.components = {
      'home': this.homeComponent,
      'chat-room': this.chatRoomComponent,
      'chat-room-control': this.chatRoomControlComponent,
      'workplace': this.workplaceComponent,
      'helpdesk': this.helpdeskComponent,
      'notification': this.notificationComponent,
      'login': this.loginComponent,
      'settings': this.settingsComponent,
      'users': this.usersComponent,
      'account': this.accountComponent,
      'profile': this.profileComponent,
      'recent-logins': this.recentLoginsComponent,
      'update': this.updateComponent,
      'taskReminder': this.taskReminderComponent,
      'task-log': this.taskActivitiesComponent,
      'contacts': this.contactsComponent,
      'sales-price-report-form': this.salesPriceReportFormComponent,
      'sales-master-price-table-update-note-list': this.salesMasterPriceTableUpdateNoteListComponent,
      'product-list': this.productListComponent,
      'warehouse-goods-list': this.productListComponent,
      'warehouse-container-list': this.warehouseContainerListComponent,
      'warehouse-inventory-adjust-note-list': this.warehouseInventoryAdjustNoteListComponent,
      'warehouse-inventory-adjust-note-form': this.warehouseInventoryAdjustNoteFormComponent,
      'warehouse-book-form': this.warehouseBookFormComponent,
      'admin-product-product-list': this.adminProductProductListComponent,
      'admin-product-product-form': this.adminProductProductFormComponent,
      'purchase-order-list': this.purchaseOrderListComponent,
      'purchase-order-form': this.purchaseOrderFormComponent,
      'purchase-product-list': this.purchaseProductListComponent,
      'purchase-price-statistics': this.purchasePriceStatisticsComponent,
      'sales-price-report-list': this.priceReportListComponent,
      'commerce-pos-gui': this.commercePosGuiComponent,
      'commerce-pos-dashboard': this.commercePosDashboardComponent,
      'commerce-pos-goods-list': this.commercePosGoodsListComponent,
      'sales-dashboard': this.salesDashboardComponent,
      'accounting-dashboard': this.accountingDashboardComponent,
      'purchase-dashboard': this.purchaseDashboardComponent,
      'warehouse-dashboard': this.warehouseDashboardComponent,
      'acc-payment-voucher-list': this.accPaymentVoucherListComponent,
      'acc-payment-voucher-form': this.accPaymentVoucherFormComponent,
      'acc-receipt-voucher-list': this.accReceiptVoucherListComponent,
      'acc-receipt-voucher-form': this.accReceiptVoucherFormComponent,
      'warehouse-goods-queue': this.warehouseGoodsQueueComponent,
      'commmerce-pos-order-list': this.commercePosOrderListComponent,
      'commmerce-pos-order-print': this.commercePosOrderPrintComponent,
      'collaborator-dashboard': this.collaboratorDashboardComponent,
      'collaborator-order-list': this.collaboratorOrderListComponent,
      'collaborator-order-form': this.collaboratorOrderFormComponent,
      'collaborator-product-list': this.collaboratorProductListComponent,
      'collaborator-customer-list': this.collaboratorCustomerListComponent,
      'collaborator-customer-form': this.collaboratorCustomerFormComponent,
      'collaborator-publisher-list': this.collaboratorPublisherListComponent,
      'collaborator-publisher-form': this.collaboratorPublisherFormComponent,
      'collaborator-opportunity-list': this.collaboratorOpportunityListComponent,
      'collaborator-opportunity-form': this.collaboratorOpportunityFormComponent,
      'contact-list': this.cotactListComponent,
      'contact-form': this.cotactFormComponent,
      'deployment-voucher-list': this.deplpoymentVoucherListComponent,
      'deployment-voucher-form': this.deplpoymentVoucherFormComponent,
      'about': this.aboutComponent,
      // 'dialpad': this.dialpadComponent,
    };

    this.routes = [
      // {
      //   name: 'home',
      //   path: 'home',
      //   component: {
      //     template: ``,
      //     data() {
      //       return {
      //         notifications: this.$root['notifications'],
      //       };
      //     },
      //     methods: {
      //       onNotificationClick() {
      //         const router: F7Router.Router = this.$f7router;
      //         router.navigate('/chat-room/123');
      //       }
      //     },
      //   },
      // },
      // // Home route, override home component path
      // // {...this.homeComponent.f7Component, path: ''},
      // this.homeComponent.f7Component,
      // Home route, override home component path
      this.homeComponent.f7Component,
      // Login rotue
      this.loginComponent.f7Component,
      // Notification route, override home component path
      this.notificationComponent.f7Component,
      // Settings route
      this.settingsComponent.f7Component,
      // Helpdesk route
      this.helpdeskComponent.f7Component,
      { ...this.helpdeskComponent.f7Component, path: '/helpdesk/:id' },
      // Workplace route
      this.workplaceComponent.f7Component,
      { ...this.workplaceComponent.f7Component, path: '/workplace/:id' },
      // Chat room route
      this.chatRoomComponent.f7Component,
      // Chat room control route
      this.chatRoomControlComponent.f7Component,
      // Contacts route
      this.usersComponent.f7Component,
      // Contacts route
      this.accountComponent.f7Component,
      // Profile route
      this.profileComponent.f7Component,
      // Recent logins route
      this.recentLoginsComponent.f7Component,
      // Update route
      this.updateComponent.f7Component,
      // Task Reminder
      this.taskReminderComponent.f7Component,
      // Chat room control route
      this.taskActivitiesComponent.f7Component,
      // Chat room control route
      this.googleMapsComponent.f7Component,
      // Contacts control route
      this.contactsComponent.f7Component,
      // Contacts control route
      this.salesPriceReportFormComponent.f7Component,
      // Product List control route
      this.productListComponent.f7Component,
      // Warehouse
      // Goods List control route
      this.warehouseGoodsListComponent.f7Component,
      { ...this.warehouseGoodsListComponent.f7Component, path: '/warehouse/goods-list/:action' },
      // Container List control route
      this.warehouseContainerListComponent.f7Component,
      { ...this.warehouseContainerListComponent.f7Component, path: '/warehouse/container-list/:action' },
      // Inventory Adjust Note control route
      this.warehouseInventoryAdjustNoteListComponent.f7Component,
      this.warehouseInventoryAdjustNoteFormComponent.f7Component,
      // Commerce POS control route
      this.salesGoodsListComponent.f7Component,
      { ...this.salesGoodsListComponent.f7Component, path: '/sales/goods-list/:action' },
      // Book form control route
      this.warehouseBookFormComponent.f7Component,
      { ...this.warehouseBookFormComponent.f7Component, path: '/warehouse/book-form/:id' },
      // Admin product
      // Product list
      this.adminProductProductListComponent.f7Component,
      { ...this.adminProductProductListComponent.f7Component, path: '/admin-product/product-list/:case' },
      this.adminProductProductFormComponent.f7Component,
      // Purchase Order
      this.purchaseOrderListComponent.f7Component,
      this.purchaseOrderFormComponent.f7Component,
      this.purchasePriceStatisticsComponent.f7Component,
      this.purchaseProductListComponent.f7Component,
      // Price report
      this.priceReportListComponent.f7Component,
      // Commerce POS
      this.commercePosGuiComponent.f7Component,
      this.commercePosDashboardComponent.f7Component,
      this.commercePosOrderListComponent.f7Component,
      this.commercePosOrderPrintComponent.f7Component,
      //
      this.salesDashboardComponent.f7Component,
      this.salesMasterPriceTableUpdateNoteListComponent.f7Component,
      this.purchaseDashboardComponent.f7Component,
      this.commercePosGoodsListComponent.f7Component,
      { ...this.commercePosGoodsListComponent.f7Component, path: '/commerce-pos/goods-list/:action' },
      this.accountingDashboardComponent.f7Component,
      this.warehouseDashboardComponent.f7Component,
      // Accouting
      this.accPaymentVoucherListComponent.f7Component,
      this.accPaymentVoucherFormComponent.f7Component,
      this.accReceiptVoucherListComponent.f7Component,
      this.accReceiptVoucherFormComponent.f7Component,
      // Warehouse
      this.warehouseGoodsQueueComponent.f7Component,
      // Collaborator
      this.collaboratorDashboardComponent.f7Component,
      this.collaboratorOrderListComponent.f7Component,
      this.collaboratorOrderFormComponent.f7Component,
      this.collaboratorProductListComponent.f7Component,
      this.collaboratorCustomerListComponent.f7Component,
      this.collaboratorCustomerFormComponent.f7Component,
      this.collaboratorPublisherListComponent.f7Component,
      this.collaboratorPublisherFormComponent.f7Component,
      this.collaboratorOpportunityListComponent.f7Component,
      this.collaboratorOpportunityFormComponent.f7Component,
      this.cotactListComponent.f7Component,
      this.cotactFormComponent.f7Component,
      this.deplpoymentVoucherListComponent.f7Component,
      this.deplpoymentVoucherFormComponent.f7Component,

      // About component
      this.aboutComponent.f7Component,

      // Pbx
      new PbxExtensionListComponent(this.rootServices, this.commonService).f7Component,
      new PbxCallLogListComponent(this.rootServices, this.commonService).f7Component,

      // Mail Client
      new MailClientInboxComponent(this.rootServices, this.commonService).f7Component,

      // Contract
      new ContractListComponent(this.rootServices, this.commonService).f7Component,
      new ContractFormComponent(this.rootServices, this.commonService).f7Component,

      // Dialpad route
      // this.dialpadComponent.f7Component,
      // Default route (404 page). MUST BE THE LAST
      // ,
      // Chat room controzlComponent.f7Component,
    ];
    if (this.isDesktop) {
      // this.dialpadComponent = new DialpadComponent(this.rootServices, this.commonService);
      // this.routes.push(this.dialpadComponent.f7Component);
      // this.components['dialpad'] = this.dialpadComponent;
    }

    return true;
  }

  async initF7View(app: F7Framework7) {
    // this.mainView = app.views.create('#view-index', { stackPages: true, name: 'index', url: '/home' });
    await this.rootServices.waitFor(50, 1000, () => $('#view-index').length > 0);
    this.mainView = await new Promise<any>(resolve => app.views.create('#view-index', { stackPages: true, name: 'index', url: '/home', on: { pageInit: (e) => resolve(e.view) } }));
    await this.rootServices.waitFor(50, 1000, () => $('#view-notifications').length > 0);
    this.notificationView = await new Promise<any>(resolve => app.views.create('#view-notifications', { stackPages: true, name: 'notifications', url: '/notifications', on: { pageInit: (e) => resolve(e.view) } }));
    await this.rootServices.waitFor(50, 1000, () => $('#view-home').length > 0);
    this.homeView = await new Promise<any>(resolve => app.views.create('#view-home', { stackPages: true, name: 'home', url: '/home', on: { pageInit: (e) => resolve(e.view) } }));
    await this.rootServices.waitFor(50, 1000, () => $('#view-helpdesk').length > 0);
    this.helpdeskView = await new Promise<any>(resolve => app.views.create('#view-helpdesk', { stackPages: true, name: 'helpdesk', url: '/helpdesk', on: { pageInit: (e) => resolve(e.view) } }));
    await this.rootServices.waitFor(50, 1000, () => $('#view-workplace').length > 0);
    this.workplaceView = await new Promise<any>(resolve => app.views.create('#view-workplace', { stackPages: true, name: 'workplace', url: '/workplace', on: { pageInit: (e) => resolve(e.view) } }));
  }

  async onF7AppInit(app: F7Framework7) {
    const $$ = Dom7;
    this.rootServices.pagesCache = $('#pages-cache');
    // try {
    //   this.mainView = app.views.create('#view-index', { stackPages: true, name: 'index', url: '/home' });
    //   this.homeView = app.views.create('#view-home', { stackPages: true, name: 'home', url: '/home' });
    //   // $this.notificationsView = self.views.create('#view-notifications');
    //   // this.helpdeskView = app.views.create('#view-helpdesk', { stackPages: true, name: 'helpdesk', url: '/helpdesk' });
    //   this.workplaceView = app.views.create('#view-workplace', { stackPages: true, name: 'workplace', url: '/workplace' });
    //   this.settingsView = app.views.create('#view-settings', { stackPages: true, name: 'settings', url: '/settings' });
    //   this.dialpadView = app.views.create('#view-dialpad', { stackPages: true, name: 'dialpad', url: '/dialpad' });
    // } catch (e) {
    //   console.log(e);
    // }
    await this.initF7View(app);

    if (this.rootServices.isMobile) {
      $$('html').addClass('disable-text-select');
    }

    // Init buffer area
    this.rootServices.bufferArea = app.$('#buffer-area');

    app.on('tabShow', async (tabEle: F7TabContext) => {
      let view: View.View;
      this.rootServices.closeEmbedBarcodeScanner();
      switch (tabEle.id) {
        case 'view-helpdesk':
          console.log('view-helpdesk tab show');
          view = tabEle.f7View;
          if (!(await this.helpdeskComponent.refresh())) {
            console.log('refresh error');
          }
          break;
        case 'view-home':
          console.log('view-home tab show');
          view = tabEle.f7View;
          if (!(await this.homeComponent.refresh())) {
            console.log('refresh error');
          }
          break;
        case 'view-workplace':
          console.log('view-workplace tab show');
          view = tabEle.f7View;
          if (!(await this.workplaceComponent.refresh())) {
            console.log('refresh error');
          }
          break;
        // case 'view-dialpad':
        //   app.toolbar.hide('.toolbar');
        //   break;
      }
    });
    app.on('tabHide', (tabEle) => {
      // console.log('On tab show', tabEle);
    });

    app.pinCodeConfirmDialog = app.dialog.create({
      el: $$('.dialog-pin-code-confirm') as any,
      closeByBackdropClick: false,
    });
    this.commonService.customListLinkDialog = app.dialog.create({
      el: $$('#custom-list-link-dialog') as any,
      closeByBackdropClick: false,
    });


    // Check login
    // if (false) { // Move check authenticated to base component init
    this.authService.onLoginStateChange().subscribe(state => {
      if (state === false) {
        // Go to recent login page
        // this.commonService.navigate('/recent-logins');
        this.rootServices.navigate(this.rootServices.env.authentication.notLoggedInUrl, { animate: false });
      } else {
        // Login success
      }
    });

    // if (false) this.authService.isAuthenticatedOrRefresh().subscribe(isAuth => {
    //   if (!isAuth) {
    //     this.commonService.navigate(this.rootServices.env.authentication.notLoggedInUrl, { animate: false });
    //   }
    // });
    // }
    this.rootServices.allReady$.next(true);
    return true;
  }

  async deepLinkProcess(path: string) {
    if (false) {

    } else {
      if (path !== '/') {
        const params = this.commonService.getUrlParams(location.search);
        await this.rootServices.waitingForAllReady();
        setTimeout(() => {
          this.commonService.navigate(path, { context: { params: params }, animate: false });
        }, 0);// fix routes register delay
      }
    }
  }

  async registerDeeplinks() {
    // console.log(getPlatforms());
    await this.rootServices.waitingForAllReady();
    console.log('[deeplink] ' + this.rootServices?.device?.platform);

    let path = '';
    if (!this.rootServices.device.platform || this.rootServices.device.platform == 'browser') {
      console.log('[deeplink] Register deeplink for BROWSER');
      path = location.pathname;
      console.log('[deeplink] path:' + path);
      this.deepLinkProcess(path);
    } else {
      console.log('[deeplink] Register deeplink for NATIVE');
      this.deeplinks.route({
      }).subscribe(match => {
        console.log('[deeplink] match:', match);
      }, matchall => {
        path = matchall.$link.path;
        console.log('[deeplink] matchall:', matchall);
        console.log('[deeplink] matchall path:' + path);
        this.deepLinkProcess(path);
      });
    }
    return true;
  }

  appUpdate() {
    const updateDownloadProgress = (progress: DownloadProgress) => {
      console.log(`Downloaded ${progress.receivedBytes} of ${progress.totalBytes}`);
      this.rootServices.updateProgress$.next(progress);
    };
    if (this.rootServices.env.mode === 'production') {

      // Only update for logged in user
      // this.authService.loginState$.pipe(filter(f => f === true), take(1)).toPromise().then(state => {
      this.rootServices.codePush.sync({ deploymentKey: this.rootServices.getDeploymentKey() }, updateDownloadProgress).subscribe((syncStatus) => {
        console.log('Check for update', syncStatus);
        this.rootServices.updateStatus$.next(syncStatus);
        if (syncStatus === SyncStatus.DOWNLOADING_PACKAGE) {
          // this.rootServices.navigate('/update', { context: { backTitle: 'Home' } });
        }
        if (syncStatus === SyncStatus.DOWNLOADING_PACKAGE) {
          const notification = this.rootServices.f7app.notification.create({
            title: 'Cập nhật',
            icon: '<div class="notification-icon" style="background-image: url(/assets/icon/favicon.png)"></div>',
            subtitle: 'Đang tải bản cập nhật ứng dụng',
            text: 'Hệ thống đang tải bản cập nhật ứng dụng',
            closeOnClick: true,
            on: {
              click: () => {
                this.rootServices.navigate('/update', { view: this.rootServices.f7app.views.current });
              },
            }
          }).open();
          setTimeout(() => {
            notification.close();
          }, 5000);
        }
        if (syncStatus === SyncStatus.INSTALLING_UPDATE) {
          const notification = this.rootServices.f7app.notification.create({
            title: 'Cập nhật',
            icon: '<div class="notification-icon" style="background-image: url(/assets/icon/favicon.png)"></div>',
            subtitle: 'Đang cập nhật ứng dụng',
            text: 'Hệ thống đang cập nhật ứng dụng',
            closeOnClick: true,
            on: {
              click: () => {
                this.rootServices.navigate('/update', { view: this.rootServices.f7app.views.current });
              },
            }
          }).open();
          setTimeout(() => {
            notification.close();
          }, 5000);
        }
        if (syncStatus === SyncStatus.UPDATE_INSTALLED) {
          const notification = this.rootServices.f7app.notification.create({
            title: 'Cập nhật',
            icon: '<div class="notification-icon" style="background-image: url(/assets/icon/favicon.png)"></div>',
            subtitle: 'Ứng dụng đã được cập nhật',
            text: 'Phiên bản mới sẽ được áp dụng khi mở lại ứng dụng',
            closeOnClick: true,
            on: {
              click: () => {
                this.rootServices.navigate('/update', { view: this.rootServices.f7app.views.current });
              },
            }
          }).open();
          setTimeout(() => {
            notification.close();
          }, 5000);
        }
        if (syncStatus === SyncStatus.ERROR) {
          const notification = this.rootServices.f7app.notification.create({
            title: 'Cập nhật',
            icon: '<div class="notification-icon" style="background-image: url(/assets/icon/favicon.png)"></div>',
            subtitle: 'Cập nhật không thành công',
            text: 'Ứng dụng sẽ cập nhật lại trong lần khởi động tiếp theo',
            closeOnClick: true,
            on: {
              click: () => {
                this.rootServices.navigate('/update', { view: this.rootServices.f7app.views.current });
              },
            }
          }).open();
          setTimeout(() => {
            notification.close();
          }, 5000);

          // Update version name
          this.rootServices.getVersion().then(version => {
            this.rootServices.version$.next(version);
          });
        }
      });
      // });

    } else {
      this.rootServices.updateStatus$.next(SyncStatus.UP_TO_DATE);
    }
  }

  async initializeApp() {
    const $this = this;

    if (!this.rootServices.isMobile) {// Register after routes register
      this.registerDeeplinks();
    }

    return this.platform.ready().then(async () => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      // Load version
      this.rootServices.getVersion().then(version => {
        this.rootServices.version$.next(version);
      });

      // Code push
      // $this.rootServices.codePush.getCurrentPackage().then(packageInfo => {
      //   if (packageInfo) {
      //     const buildNumer = parseInt(packageInfo.label.split('v')[1]);
      //     const baseVersion = packageInfo.appVersion.replace(/\.\d+$/, '');
      //     console.log(packageInfo);
      //     this.rootServices.version$.next({ ...this.rootServices.version$.getValue(), appVersion: 'v' + baseVersion + '.' + (buildNumer - $this.rootServices.env.baseBuild) });
      //   }
      // });

      // const updateDownloadProgress = (progress: DownloadProgress) => {
      //   console.log(`Downloaded ${progress.receivedBytes} of ${progress.totalBytes}`);
      //   this.rootServices.updateProgress$.next(progress);
      // };
      // this.rootServices.codePush.checkForUpdate().then(info => {
      //   this.rootServices.navigate('/update', { context: { backTitle: 'Home' } });
      // });
      // if (this.rootServices.env.mode === 'production') {

      //   // Only update for logged in user
      //   // this.authService.loginState$.pipe(filter(f => f === true), take(1)).toPromise().then(state => {
      //   this.rootServices.codePush.sync({ deploymentKey: this.rootServices.getDeploymentKey() }, updateDownloadProgress).subscribe((syncStatus) => {
      //     console.log('Check for update', syncStatus);
      //     this.rootServices.updateStatus$.next(syncStatus);
      //     if (syncStatus === SyncStatus.DOWNLOADING_PACKAGE) {
      //       // this.rootServices.navigate('/update', { context: { backTitle: 'Home' } });
      //     }
      //     if (syncStatus === SyncStatus.INSTALLING_UPDATE) {
      //       const notification = this.rootServices.f7app.notification.create({
      //         title: 'Smart-BOT',
      //         icon: '<div class="notification-icon" style="background-image: url(/assets/icon/favicon.png)"></div>',
      //         subtitle: 'Đang cập nhật ứng dụng',
      //         text: 'Hệ thống đang cập nhật ứng dụng',
      //         closeOnClick: true,
      //       }).open();
      //       setTimeout(() => {
      //         notification.close();
      //       }, 5000);
      //     }
      //     if (syncStatus === SyncStatus.UPDATE_INSTALLED) {
      //       const notification = this.rootServices.f7app.notification.create({
      //         title: 'Smart-BOT',
      //         icon: '<div class="notification-icon" style="background-image: url(/assets/icon/favicon.png)"></div>',
      //         subtitle: 'Ứng dụng đã được cập nhật',
      //         text: 'Phiên bản mới sẽ được áp dụng khi mở lại ứng dụng',
      //         closeOnClick: true,
      //       }).open();
      //       setTimeout(() => {
      //         notification.close();
      //       }, 5000);
      //     }
      //     if (syncStatus === SyncStatus.ERROR) {
      //       const notification = this.rootServices.f7app.notification.create({
      //         title: 'Smart-BOT',
      //         icon: '<div class="notification-icon" style="background-image: url(/assets/icon/favicon.png)"></div>',
      //         subtitle: 'Cập nhật không thành công',
      //         text: 'Ứng dụng sẽ cập nhật lại trong lần khởi động tiếp theo',
      //         closeOnClick: true,
      //       }).open();
      //       setTimeout(() => {
      //         notification.close();
      //       }, 5000);
      //     }
      //   });
      //   // });

      // } else {
      //   this.rootServices.updateStatus$.next(SyncStatus.UP_TO_DATE);
      // }
      this.appUpdate();
      // this.rootServices.codePush.sync({}, updateDownloadProgress).subscribe((syncStatus) => {
      //   console.log(syncStatus);
      //   this.updateStatus$.next(syncStatus);
      //   if (syncStatus === SyncStatus.UPDATE_INSTALLED || syncStatus === SyncStatus.ERROR || syncStatus === SyncStatus.UPDATE_IGNORED || syncStatus === SyncStatus.UP_TO_DATE) {
      //     // this.rootServices.f7app.views.current.router.back();
      //   }
      // });

      // Fix status bar for android
      if (isPlatform('android')) {
        this.statusBar.styleLightContent();
        this.statusBar.backgroundColorByHexString('#f7f7f8');
      }

      // Subscrible login changed
      this.authService.user$.pipe(filter(f => !!f)).subscribe(async user => {
        // On login success
        if (user) {
          const recentLogins: RecentLoginModel[] = await this.storage.get('recent_logins');
          if (recentLogins) {
            const recentLogin = recentLogins.find(f => f.id == this.apiService.token.api_url + user.id);
            if (recentLogin) {
              recentLogin.avatar = user.avatar && user.avatar.payload && user.avatar.payload.thumbnail;
            }
          }
          this.storage.set('recent_logins', recentLogins);
        }
      });

      // Subscrible token refresh
      this.authService.tokenRefresh$.pipe(filter(f => !!f)).subscribe(async authToken => {
        // On login success
        const user = await this.authService.user$.pipe(filter(f => !!f), take(1)).toPromise();
        const token = authToken.getPayload();
        const recentLogins: RecentLoginModel[] = await this.storage.get('recent_logins');
        if (recentLogins) {
          const recentLogin = recentLogins.find(f => f.id == token.api_url + user.id);
          if (recentLogin) {
            if (recentLogin.token?.user == token?.user) {
              recentLogin.token = token;
            }
          }
        }
        this.storage.set('recent_logins', recentLogins);
      });

      this.platform.backButton.subscribeWithPriority(0, () => {
        // this.commonService.navigate('/home');
        const dialog = this.rootServices.f7app.dialog.get('.dialog.modal-in');
        const sheetModal = this.rootServices.f7app.actions.get('.sheet-modal.modal-in');
        const photoBrowser = this.rootServices.f7app.photoBrowser.get('.photo-browser-popup');
        if (photoBrowser) {
          photoBrowser.close();
        } else if (dialog) {
          dialog.close();
        } else if (sheetModal) {
          sheetModal.close();
        } else {
          this.rootServices.f7app.view.current.router.back();
        }
      });

      // Background mode
      // this.rootServices.backgroundMode.enable();

      // let sleepAction = null;
      this.rootServices.backgroundMode.on('activate').subscribe(() => {
        console.log('background mode now activate');
        //   console.log('disable background mode after 3 minutes...');
        //   sleepAction = setTimeout(() => {
        //     console.log('disable background mode');

        //     // sleep all chat room sockets sadfsadf
        //     for (const chatRoomId of Object.keys(this.chatRoomComponent.state)) {
        //       const chatRoomState = this.chatRoomComponent.state[chatRoomId];
        //       if (chatRoomState.chatRoom) {
        //         chatRoomState.chatRoom.roomSocket.destroySocket();
        //       }
        //     }
        //     this.rootServices.backgroundMode.disable();
        //   // }, 180000); // 3 minutes
        //   }, 20000); // 3 minutes
      });

      this.rootServices.backgroundMode.on('deactivate').subscribe(() => {
        console.log('background mode now deactivate');
        //   this.rootServices.backgroundMode.enable();
        //   if (sleepAction) {
        //     console.log('clear timeout to disable background mode');
        //     clearTimeout(sleepAction);
        //   }
      });

      /** Waiting for prepare router */
      await this.prepareRouter();

      // Default route
      this.routes.push({
        path: '(.*)',
        component: {
          template: `
          <div class="page">
            <div class="navbar">
              <div class="navbar-bg"></div>
              <div class="navbar-inner sliding">
                <div class="left">
                  <a href="#" class="link back">
                    <i class="icon icon-back"></i>
                    <span class="if-not-md">Back</span>
                  </a>
                </div>
                <div class="title">Not found</div>
              </div>
            </div>
            <div class="page-content">
              <div class="block block-strong inset">
                <p>Sorry</p>
                <p>Requested content not found.</p>
              </div>
            </div>
          </div>
          `,
          on: {
            pageInit(e, page) {
              console.debug(e, page);
            }
          }
        },
      });

      /** Log device id */
      console.log('Device uuid: ', await this.rootServices.getDeviceUuid());

      // Event
      this.rootServices.authService.onLoginStateChange().subscribe(async state => {
        if (state) {// Login
          console.log('User login');
          // Update push notification token
          if (this.rootServices.isMobile) {
            // this.rootServices.firebaseX.getToken()
            //   .then((token: string) => {
            //     $this.rootServices.registerDevice({ pushRegId: token });
            //   }) // save the token server-side and use it to push notifications to this device
            //   .catch(err => {
            //     console.error('Error getting token', err);
            //     $this.commonService.showError(err)
            //       ;
            //   });

            this.rootServices.firebaseMessaging.getToken().then((token: string) => {
              console.log(`Got a new token on login: `, token);
              this.rootServices.registerDevice({ pushRegId: token });
            }).catch(err => {
              console.error(err);
            });
          }

        } else if (state === false) { // Logout
          console.log('User logout');
          // Unregister device
          $this.rootServices.unregisterDevice();
        }
      });

      // Platform`
      // this.platform.pause.subscribe(status => {
      //   console.log('enable background mode on pause');
      //   this.rootServices.backgroundMode.enable();
      //   // this.rootServices.backgroundMode.moveToBackground();
      // });
      // if (isPlatform('desktop')) {
      //   // this.isDarkMode$.next(true);
      // } else {
      let deviceUpdateLoop = null;
      this.platform.resume.subscribe(status => {
        console.log('app resume.');
        //   console.log('disable background mode on resume');
        //   this.rootServices.backgroundMode.disable();

        // Detective dark mode
        this.detectiveDarkMode();

        // Update device last reports loop

        this.rootServices.deviceUpdate().catch(err => {
          if (err.status == 400) {
            console.error(err);
            console.log('RE-Register device...');
            // Re-register device

            this.rootServices.firebaseMessaging.getToken().then((token: string) => {
              console.log(`Got a new token: `, token);
              this.rootServices.registerDevice({ pushRegId: token });
            }).catch(err => {
              console.error(err);
            });


          }
        });

        if (!this.commonService.isFrameMode) {
          deviceUpdateLoop = setInterval(() => {
            this.rootServices.deviceUpdate();
          }, 60000);
        }

        // Check for udpate
        this.appUpdate();
        // if (false && this.rootServices.env.mode === 'production') {
        //   this.rootServices.codePush.sync({ deploymentKey: this.rootServices.getDeploymentKey() }, updateDownloadProgress).subscribe((syncStatus) => {
        //     console.log(syncStatus);
        //     if (syncStatus === SyncStatus.UPDATE_INSTALLED) {
        //       this.rootServices.f7app.notification.create({
        //         icon: '<i class="icon demo-icon">7</i>',
        //         title: 'Robot update',
        //         titleRightText: 'now',
        //         subtitle: 'Robot đã được cập nhật',
        //         text: new Date().toLocaleString(),
        //         closeTimeout: 0,
        //         closeOnClick: true,
        //         on: {
        //           click: () => {
        //             this.rootServices.navigate('/update/');
        //           },
        //         },
        //       }).open();
        //       this.homeComponent.newNotification$.next({
        //         Id: Date.now().toString(),
        //         Type: 'UPDATE',
        //         Title: 'Robot update',
        //         Content: 'Robot đã được cập nhật',
        //         DateOfCreate: new Date().toLocaleString(),
        //         Data: {

        //         }
        //       });
        //     }
        //   });
        // }
      });
      this.platform.pause.subscribe(status => {
        console.log('app pause.');
        if (!this.commonService.isFrameMode) {
          if (deviceUpdateLoop) {
            clearInterval(deviceUpdateLoop);
          }
        }
        this.homeComponent && this.homeComponent.currentState && this.homeComponent.currentState.instance && this.homeComponent.currentState.instance.refresh();
      });
      // }

      // Socket service
      // this.socketService.ready$.pipe(filter(status => status), take(1)).subscribe(state => {
      //   if (state) {
      //     this.socketService.onNotify().subscribe((notification: any) => {
      //       switch (notification.type) {
      //         case '1': // Message
      //           if (notification.room) {
      //             this.rootServices.f7app.notification.create({
      //               icon: '<i class="icon demo-icon">7</i>',
      //               title: notification.from.name,
      //               titleRightText: 'now',
      //               subtitle: notification.body,
      //               text: 'Chạm để đi tới phòng chat',
      //               closeOnClick: true,
      //               on: {
      //                 close: () => {
      //                   this.rootServices.navigate('/chat-room/' + notification.room, { view: this.rootServices.f7app.views.current });
      //                 },
      //               },
      //             }).open();
      //           }
      //           // }
      //           break;
      //       }
      //     });
      //   }
      // });

      // Firebasex
      if (this.rootServices.isMobile) {

        // Request receive notification permission
        console.log('firebase messaging: ', this.rootServices.firebaseMessaging);
        // console.log('requestPermission: ', this.rootServices.firebaseMessaging.requestPermission());
        // if (isPlatform('ios')) {
        // const rsPmsStatus = await 
        this.rootServices.firebaseMessaging.requestPermission().then(status => {
          console.debug('Firebase message requestPermission: ', status);
          return true;
        }).catch(err => {
          console.error(err);
          console.error('Notification permission not granted');
          return false;
        });
        // if (!rsPmsStatus) {
        //   console.error('Notification permission not granted');
        //   return;
        // }
        // }

        this.rootServices.updateStatus$.pipe(filter(f => [SyncStatus.UPDATE_INSTALLED, SyncStatus.UP_TO_DATE].indexOf(f) > -1), take(1)).subscribe(async status => {
          // this.rootServices.firebaseX.hasPermission().then(async status => {
          //   console.log('Has notify permission', status);
          // if (!status) {
          // }
          // this.rootServices.firebaseX.getToken()
          //   .then(token => console.log(`The firebase token is : ${token}`)) // save the token server-side and use it to push notifications to this device
          //   .catch(error => console.error('Error getting token', error));

          // Wait for login success and register device
          this.rootServices.authService.loginState$.pipe(filter(f => f), take(1)).toPromise().then(() => {
            this.rootServices.firebaseMessaging.getToken().then((token: string) => {
              console.log(`Got a new token on first time: `, token);
              this.rootServices.registerDevice({ pushRegId: token });
            }).catch(err => {
              console.error(err);
            });
          });

          // Handler google/apple notification
          // this.rootServices.firebaseMessaging.subscribe('');
          console.log('Register forground notification receiver');
          this.rootServices.firebaseMessaging.onMessage()
            .subscribe((data: { id?: string, type: string, tap?: 'background' | 'foreground', d: string, room?: string, title?: string, body?: string, aps?: { alert?: { title?: string, body?: string } } }) => {
              console.log(`Forground User opened a notification: `, data);

              // Wait for socket ready
              // this.socketService.ready$.pipe(filter(state => state), take(1)).subscribe(async status => {
              //   if (status) {
              // await this.rootServices.waitFor(300, 30, () => !!this.apiService.token && !!this.apiService.token.access_token);
              switch (data.type) {
                case 'CHATROOM': // Message

                  try {
                    const token = this.authService.token$.getValue().getPayload();

                    if (data.room) {
                      // if (data.tap === 'background' || data.tap === 'foreground') {
                      //   // this.app.view.current.router.navigate('/chat-room/' + data.room);
                      //   if (data.d && token.core !== data.d) {

                      //     $this.rootServices.storage.get('recent_logins').then((recentLogins: RecentLoginModel[]) => {
                      //       recentLogins = recentLogins.filter(f => f.id != $this.rootServices.authService.token$.value?.getPayload().api_url + $this.rootServices.authService.user$.value?.id).map(item => {
                      //         item.coreName = new URL(item.url).host;
                      //         item.avatar = item.url + '/v3/user/users/' + item.user + '/avatar';
                      //         item.banner = item.url + '/v3/system/settings/banner';
                      //         return item;
                      //       });

                      //       const relativeLogins = recentLogins.filter(f => f.core == data.d);
                      //       if (relativeLogins.length > 0) {
                      //         this.rootServices.switchAccount(relativeLogins.map(m => m.id), { message: 'Thông báo từ máy chủ khác, bạn có muốn chuyển máy chủ không ?' }).then(rs => {
                      //           this.rootServices.navigate('/chat-room/' + data.room);
                      //         });
                      //       } else {
                      //         this.commonService.showError('Bạn chưa đăng nhập trên máy chủ này !');
                      //       }

                      //     }).catch(err => {
                      //       console.error(err);
                      //       $this.commonService.showError(err);
                      //       return [];
                      //     });

                      //     // this.rootServices.f7app.dialog.confirm('Thông báo từ máy chủ khác, bạn có muốn chuyển máy chủ không ?', 'Thông báo', () => {
                      //     //   // this.settingsComponent.currentState &&
                      //     //   //   this.settingsComponent.currentState.instance &&
                      //     //   //   this.settingsComponent.currentState.instance.switchAccount();
                      //     // });
                      //   } else {
                      //     this.rootServices.navigate('/chat-room/' + data.room);
                      //   }

                      // } else {
                      if (this.chatRoomComponent.showInsideNotification(data.title || data.aps && data.aps.alert && data.aps.alert.title, null, data.body || data.aps && data.aps.alert && data.aps.alert.body, data.room, { core: data.d })) {
                        this.homeComponent.newNotification$.next({
                          Id: data.id,
                          Type: 'CHATROOM',
                          Title: data.title || data.aps && data.aps.alert && data.aps.alert.title,
                          Content: data.body || data.aps && data.aps.alert && data.aps.alert.body,
                          DateOfCreate: new Date().toLocaleString(),
                          Data: {
                            room: data.room,
                            core: data.d
                          }
                        });
                      }
                      // }
                    }
                  } catch (err) {
                    console.log('switch account');
                  }


                  break;
              }
              //   }

              // });
            });

          console.log('Register background notification receiver');
          this.rootServices.firebaseMessaging.onBackgroundMessage()
            .subscribe((data: { [key: string]: any, id?: string, type: string, tap?: 'background' | 'foreground', d: string, room?: string, title?: string, body?: string, aps?: { alert?: { title?: string, body?: string } } }) => {
              console.log(`Background User opened a notification: `, data);

              // Wait for socket ready
              // this.socketService.ready$.pipe(filter(state => state), take(1)).subscribe(async status => {
              //   if (status) {
              // await this.rootServices.waitFor(300, 30, () => !!this.apiService.token && !!this.apiService.token.access_token);
              switch (data.type) {
                case 'CHATROOM': // Message

                  try {
                    const token = this.authService.token$.getValue().getPayload();

                    if (data.room) {
                      // if (data.tap === 'background' || data.tap === 'foreground') {
                      // this.app.view.current.router.navigate('/chat-room/' + data.room);
                      if (data.d && token.core !== data.d) {

                        $this.rootServices.storage.get('recent_logins').then((recentLogins: RecentLoginModel[]) => {
                          recentLogins = recentLogins.filter(f => f.id != $this.rootServices.authService.token$.value?.getPayload().api_url + $this.rootServices.authService.user$.value?.id).map(item => {
                            item.coreName = new URL(item.url).host;
                            item.avatar = item.url + '/v3/user/users/' + item.user + '/avatar';
                            item.banner = item.url + '/v3/system/settings/banner';
                            return item;
                          });

                          const relativeLogins = recentLogins.filter(f => f.core == data.d);
                          if (relativeLogins.length > 0) {
                            this.rootServices.switchAccount(relativeLogins.map(m => m.id), { message: 'Thông báo từ máy chủ khác, bạn có muốn chuyển máy chủ không ?' }).then(rs => {
                              this.rootServices.navigate('/chat-room/' + data.room);
                            });
                          } else {
                            this.commonService.showError('Bạn chưa đăng nhập trên máy chủ này !');
                          }

                        }).catch(err => {
                          console.error(err);
                          $this.commonService.showError(err);
                          return [];
                        });

                        // this.rootServices.f7app.dialog.confirm('Thông báo từ máy chủ khác, bạn có muốn chuyển máy chủ không ?', 'Thông báo', () => {
                        //   // this.settingsComponent.currentState &&
                        //   //   this.settingsComponent.currentState.instance &&
                        //   //   this.settingsComponent.currentState.instance.switchAccount();
                        // });
                      } else {
                        this.rootServices.navigate('/chat-room/' + data.room);
                      }

                      // } else {
                      //   if (this.chatRoomComponent.showInsideNotification(data.title || data.aps && data.aps.alert && data.aps.alert.title, null, data.body || data.aps && data.aps.alert && data.aps.alert.body, data.room, { core: data.d })) {
                      //     this.homeComponent.newNotification$.next({
                      //       Id: data.id,
                      //       Type: 'CHATROOM',
                      //       Title: data.title || data.aps && data.aps.alert && data.aps.alert.title,
                      //       Content: data.body || data.aps && data.aps.alert && data.aps.alert.body,
                      //       DateOfCreate: new Date().toLocaleString(),
                      //       Data: {
                      //         room: data.room,
                      //         core: data.d
                      //       }
                      //     });
                      //   }
                      // }
                    }
                  } catch (err) {
                    console.log('switch account');
                  }


                  break;
                case 'ACTIVITY':
                  console.log(data);
                  if (data.Action == 'NAVIGATE') {
                    $this.rootServices.navigate(data.Path);
                  }
                  $this.rootServices.apiService.putPromise('/notification/notifications/updateReceiverState', { state: 'ACTIVE' }, [{ Id: data.id }]).then(rs => {
                    console.debug(rs);
                    // self.updateBadge();
                  }).catch(err => {
                    console.error(err);
                    $this.commonService.showError(err);
                  });
                  break;
              }
              //   }

              // });
            });

          this.rootServices.firebaseMessaging.onTokenRefresh()
            .subscribe(() => {
              this.rootServices.firebaseMessaging.getToken().then((token: string) => {
                console.log(`Got a new token: `, token);
                this.rootServices.registerDevice({ pushRegId: token });
              });
            });
          // });
          // this.rootServices.firebaseX.hasPermission().then(async status => {
          //   console.log('Has notify permission', status);
          //   if (!status) {
          //     await this.rootServices.firebaseX.grantPermission().then(status => {
          //       console.debug(status);
          //       return status;
          //     });
          //   }
          //   this.rootServices.firebaseX.getToken()
          //     .then(token => console.log(`The firebase token is : ${token}`)) // save the token server-side and use it to push notifications to this device
          //     .catch(error => console.error('Error getting token', error));

          //   // Handler google/apple notification
          //   this.rootServices.firebaseX.onMessageReceived()
          //     .subscribe((data: { id?: string, type: string, tap?: 'background' | 'foreground', d: string, room?: string, title?: string, body?: string, aps?: { alert?: { title?: string, body?: string } } }) => {
          //       console.log(`User opened a notification: `, data);

          //       // Wait for socket ready
          //       // this.socketService.ready$.pipe(filter(state => state), take(1)).subscribe(async status => {
          //       //   if (status) {
          //       // await this.rootServices.waitFor(300, 30, () => !!this.apiService.token && !!this.apiService.token.access_token);
          //       switch (data.type) {
          //         case 'CHATROOM': // Message

          //           try {
          //             const token = this.authService.token$.getValue().getPayload();

          //             if (data.room) {
          //               if (data.tap === 'background' || data.tap === 'foreground') {
          //                 // this.app.view.current.router.navigate('/chat-room/' + data.room);
          //                 if (data.d && token.core !== data.d) {

          //                   $this.rootServices.storage.get('recent_logins').then((recentLogins: RecentLoginModel[]) => {
          //                     recentLogins = recentLogins.filter(f => f.id != $this.rootServices.authService.token$.value?.getPayload().api_url + $this.rootServices.authService.user$.value?.id).map(item => {
          //                       item.coreName = new URL(item.url).host;
          //                       item.avatar = item.url + '/v3/user/users/' + item.user + '/avatar';
          //                       item.banner = item.url + '/v3/system/settings/banner';
          //                       return item;
          //                     });

          //                     const relativeLogins = recentLogins.filter(f => f.core == data.d);
          //                     if (relativeLogins.length > 0) {
          //                       this.rootServices.switchAccount(relativeLogins.map(m => m.id), { message: 'Thông báo từ máy chủ khác, bạn có muốn chuyển máy chủ không ?' }).then(rs => {
          //                         this.rootServices.navigate('/chat-room/' + data.room);
          //                       });
          //                     } else {
          //                       this.commonService.showError('Bạn chưa đăng nhập trên máy chủ này !');
          //                     }

          //                   }).catch(err => {
          //                     console.error(err);
          //                     $this.commonService.showError(err);
          //                     return [];
          //                   });

          //                   // this.rootServices.f7app.dialog.confirm('Thông báo từ máy chủ khác, bạn có muốn chuyển máy chủ không ?', 'Thông báo', () => {
          //                   //   // this.settingsComponent.currentState &&
          //                   //   //   this.settingsComponent.currentState.instance &&
          //                   //   //   this.settingsComponent.currentState.instance.switchAccount();
          //                   // });
          //                 } else {
          //                   this.rootServices.navigate('/chat-room/' + data.room);
          //                 }

          //               } else {
          //                 if (this.chatRoomComponent.showInsideNotification(data.title || data.aps && data.aps.alert && data.aps.alert.title, null, data.body || data.aps && data.aps.alert && data.aps.alert.body, data.room, { core: data.d })) {
          //                   this.homeComponent.newNotification$.next({
          //                     Id: data.id,
          //                     Type: 'CHATROOM',
          //                     Title: data.title || data.aps && data.aps.alert && data.aps.alert.title,
          //                     Content: data.body || data.aps && data.aps.alert && data.aps.alert.body,
          //                     DateOfCreate: new Date().toLocaleString(),
          //                     Data: {
          //                       room: data.room,
          //                       core: data.d
          //                     }
          //                   });
          //                 }
          //               }
          //             }
          //           } catch (err) {
          //             console.log('switch account');
          //           }


          //           break;
          //       }
          //       //   }

          //       // });
          //     });

          //   this.rootServices.firebaseX.onTokenRefresh()
          //     .subscribe((token: string) => {
          //       console.log(`Got a new token: `, token);
          //       this.rootServices.registerDevice({ pushRegId: token });
          //     });
          // });

          // this.rootServices.androidPermissions.checkPermission(this.rootServices.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE).then(
          //   result => {
          //     console.log('Has READ_EXTERNAL_STORAGE permission?', result.hasPermission);
          //   },
          //   err => {
          //     this.rootServices.androidPermissions.requestPermission(this.rootServices.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE).then(result => {
          //       console.log(result);
          //     }).catch(err => {
          //       console.error(err);
          //     });
          //   }
          // );
        });

      }
      // End firebasex

      // Detective dark mode
      this.detectiveDarkMode();

      /** Framework7 init */
      const $$ = Dom7;

      // Customer Template7 helper
      // Cast objet as text
      const $this = this;
      Template7.registerHelper('objecttext', function (object: any, path: string, coalesce: string) {
        const pathParts = path.split('.');
        let resutl = object;
        for (const pathPart of pathParts) {
          if (!resutl || typeof resutl[pathPart] == 'undefined') {
            return coalesce || '';
          }
          resutl = resutl[pathPart];
        }
        return resutl && $this.commonService.getObjectText(resutl) || coalesce;
      });
      Template7.registerHelper('objectid', function (object: any, path: string, coalesce: string) {
        const pathParts = path.split('.');
        let resutl = object;
        for (const pathPart of pathParts) {
          if (!resutl || typeof resutl[pathPart] == 'undefined') {
            return coalesce || '';
          }
          resutl = resutl[pathPart];
        }
        return resutl && $this.commonService.getObjectId(resutl) || coalesce;
      });
      Template7.registerHelper('text', function (value: any, options) {
        if (Array.isArray(value)) {
          return value.map(m => $this.commonService.getObjectText(m)).join(', ');
        }
        return value && $this.commonService.getObjectText(value) || options?.hash?.coalesce || '';
      });
      // Cast object as id
      Template7.registerHelper('id', function (value: any, coalesce: string, options) {
        if (Array.isArray(value)) {
          return value.map(m => $this.commonService.getObjectId(m)).join(', ');
        }
        return value && $this.commonService.getObjectId(value) || options?.hash?.coalesce || '';
      });
      Template7.registerHelper('currency', function (value: any, options: any) {
        return value && $this.rootServices.currencyPipe.transform(value, 'VND') || options?.hash?.coalesce || '';
      });
      Template7.registerHelper('objectcurrency', function (object: any, path: string, coalesce: any) {
        const pathParts = path.split('.');
        let resutl = object;
        for (const pathPart of pathParts) {
          if (!resutl || typeof resutl[pathPart] == 'undefined') {
            return coalesce || '';
          }
          resutl = resutl[pathPart];
        }
        return resutl && $this.rootServices.currencyPipe.transform(resutl, 'VND') || coalesce || '';
      });
      Template7.registerHelper('decimal', function (value: any, options: any) {
        return (value || value === 0) && $this.rootServices.decimalPipe.transform(value, '1.0-2') || options?.hash?.coalesce || '';
      });
      Template7.registerHelper('date', function (value: any, options: { hash: { format: string, coalesce: string } }) {
        try {
          return value && $this.rootServices.datePipe.transform(value, options.hash?.format || 'shortDate') || options.hash?.coalesce || '';
        } catch (err) {
          return value;
        }
      });
      Template7.registerHelper('image', function (value: any, options: { hash: { size: string, coalesce: string } }) {
        try {
          let size = 'Thumbnail';
          if (options?.hash?.size) {
            size = options.hash.size;
          }
          return (value && value[size]) || (value || options.hash?.coalesce || '');
        } catch (err) {
          return value;
        }
      });
      Template7.registerHelper('moment', function (value: any, options: { hash: { format: string, coalesce: string } }) {
        return value && moment(value).fromNow() || options?.hash?.coalesce || '';
      });
      Template7.registerHelper('validate', function (value: any, schema: any, name: string, options) {

        if (!schema) return '';
        let listName = null;
        if (/\./.test(name)) {
          [listName, name] = name.split('.');
        }
        const fieldSchema = listName ? schema[listName][name] : schema[name];
        value = $this.commonService.getObjectId(value);
        if (fieldSchema) {
          const validateClass = [];
          if (fieldSchema.validators && fieldSchema.validators.length > 0) {
            for (const validator of fieldSchema.validators) {
              const validatorName = typeof validator == 'string' ? validator : validator.name;
              switch (validatorName) {
                case 'required':
                  if (value !== 0 && !value) {
                    validateClass.push('validate-required');
                  }
                  break;
              }
            }
          }
          return validateClass.join(' ');
        }
        return '';
      });
      Template7.registerHelper('validatesymbol', function (schema: any, name: string, options) {

        if (!schema) return '';
        let listName = null;
        if (/\./.test(name)) {
          [listName, name] = name.split('.');
        }
        const fieldSchema = listName ? schema[listName][name] : schema[name];
        if (fieldSchema) {
          const validateSymbol = [];
          if (fieldSchema.validators && fieldSchema.validators.length > 0) {
            for (const validator of fieldSchema.validators) {
              const validatorName = typeof validator == 'string' ? validator : validator.name;
              switch (validatorName) {
                case 'required':
                  validateSymbol.push('(*)');
                  break;
              }
            }
          }
          return validateSymbol.join(' ');
        }
        return '';
      });

      (window as any).f7app = $this.app = new Framework7({
        root: '#app', // App root element

        id: 'com.namsoftware.minierpapp', // App bundle ID
        name: this.appName, // App name
        theme: isPlatform('android') ? 'md' : 'ios', // Automatic theme detection
        // theme: 'md', // Tmp set
        swipeout: {
          // noFollow: true,
          removeElements: true,
        },
        panel: {
          // swipe: true,
          // swipeNoFollow: true,`
          // visibleBreakpoint: 1024,
        },
        // App root data
        data() {
          return {
            activeComponent: null,
            components: {
              loginComponent: $this.loginComponent,
              notificationComponent: $this.notificationComponent,
              workplaceComponent: $this.workplaceComponent,
              helpdeskComponent: $this.helpdeskComponent,
              chatRoomComponent: $this.chatRoomComponent,
              chatRoomControlComponent: $this.chatRoomControlComponent,
              taskActivitiesComponent: $this.taskActivitiesComponent,
              usersComponent: $this.usersComponent,
            },
          };
        },
        // App root methods
        methods: {
          helloWorld() {
            $this.app.dialog.alert('Hello World!');
          },
        },
        // App routes
        routes: this.routes,

        // Input settings
        input: {
          scrollIntoViewOnFocus: Framework7.device.cordova && !Framework7.device.electron,
          scrollIntoViewCentered: Framework7.device.cordova && !Framework7.device.electron,
        },
        // Cordova Statusbar settings
        statusbar: {
          iosOverlaysWebView: true,
          androidOverlaysWebView: false,
        },
        on: {
          init() {
            const app: F7Framework7 = this;
            $this.onF7AppInit(app);
          },
        },
      });
      // Login Screen Demo
      // tslint:disable-next-line: only-arrow-functions
      $$('#my-login-screen .login-button').on('click', () => {
        const username = $$('#my-login-screen [name="username"]').val();
        const password = $$('#my-login-screen [name="password"]').val();

        // Close login screen
        $this.app.loginScreen.close('#my-login-screen');

        // Alert username and password
        $this.app.dialog.alert('Username: ' + username + '<br>Password: ' + password);
      });
      return true;
    });

    (window as any).openLink = (link) => {
      $this.rootServices.iab.create(link, '_system');
    };
  }

  detectiveDarkMode() {
    try {
      // tslint:disable-next-line: no-string-literal
      (window as any)?.cordova?.plugins['ThemeDetection']?.isAvailable((isAvilable: { value: boolean, message: string }) => {
        if (isAvilable.value) {
          console.debug('dark mode is availabled');
          // tslint:disable-next-line: no-string-literal
          (window as any).cordova.plugins['ThemeDetection'].isDarkModeEnabled((isDarkMode: { value: boolean, message: string }) => {
            this.isDarkMode$.next(isDarkMode.value);
            console.debug('dark mode status: ' + isDarkMode.value);
          });
        } else {
          console.debug('dark mode is not availabled');
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  notificationSetup() {
  }

  async presentToast(message) {
    const toast = await this.rootServices.toastController.create({
      message,
      duration: 3000
    });
    toast.present();
  }

  // isEmbedBarcodeScan = false;
  scanQRCode(e?: MouseEvent) {
    // if (!this.rootServices.isEmbedBarcodeScan) {
    //   this.rootServices.isEmbedBarcodeScan = true;
    //   this.rootServices.openEmbedBarcodeScanner(result => {
    //     const toast = this.rootServices.f7app.toast.create({
    //       text: result,
    //       closeTimeout: 10000,
    //       cssClass: 'text-color-default',
    //       closeButton: true,
    //       position: 'top',
    //       closeButtonText: 'Copy',
    //     }).open();
    //     toast.$el.find('.toast-button').click(() => {
    //       this.rootServices.clipboard.copy(result);
    //       this.commonService.showInfo(`Dữ liệu trong ${result} đã được copy`);
    //     });
    //     this.rootServices.closeEmbedBarcodeScanner();
    //   });
    // } else {
    //   this.rootServices.isEmbedBarcodeScan = false;
    //   this.rootServices.closeEmbedBarcodeScanner();
    // }
    // return;
    this.rootServices.scanBarcodeByMlKit().then(barcodeData => {
      const data = barcodeData && barcodeData.text.split('|');
      if (data.length > 1) {
        switch (data[0]) {
          case 'SCAN2LOGIN':
            // this.rootServices.dialogs.prompt('Nhập mã PIN', 'Xác nhận đăng nhập trên trình duyệt', ['Xác nhận']).then(dialogCallback => {
            this.rootServices.f7app.pinCodeConfirmDialog.open();
            this.rootServices.f7app.pinCodeConfirmDialog.$el.find('.field[name="PinCode"]').focus();

            const subcription = this.pinCodeConfirmSubject.subscribe(pinCode => {
              if (pinCode === null) {
                return;
              }
              if (pinCode === '') {
                this.commonService.showError('Bạn chưa mã pin');
                return;
              }
              this.authService.refreshToken().pipe(take(1)).toPromise().then(rs => {
                this.apiService.putPromise<{ SecondLoginToken: string }>('/user/login/sendSecondLoginToRequestDevice', { pinCode: pinCode, requestToken: data[1] }, null).then(rs => {
                  console.log(rs);
                  // this.socketService.mainSocket.emit('send-token-to-pending-login-device', { socketId: data[1], secondLoginToken: rs.SecondLoginToken }).then(rs => {
                  //   if (rs) {
                  this.commonService.showInfo('Đăng nhập thành công');
                  this.rootServices.f7app.panel.close();
                  subcription.unsubscribe();
                  //   }
                  // }).catch(err => {
                  //   this.commonService.showError(err);
                  //   subcription.unsubscribe();
                  // });
                }).catch(err => {
                  this.commonService.showError(err);
                  subcription.unsubscribe();
                });
              });
            });

            // });            
            return;
        }
      }
      console.log('Barcode data', barcodeData);
      this.rootServices.clipboard.copy(barcodeData.text);
      this.commonService.showInfo(`Dữ liệu trong ${barcodeData.format} đã được copy`);
    }).catch(err => {
      console.log('Error', err);
    });
  }

  pinCodeConfirmSubject = new Subject<string>();
  // pinCodeConfirm$ = this.pinCodeConfirmSubject.asObservable();
  onPinCodeConfirm(e: MouseEvent) {
    const pinCodeField = this.rootServices.f7app.pinCodeConfirmDialog.$el.find('.field[name="PinCode"]');
    this.pinCodeConfirmSubject.next(pinCodeField.val());
    pinCodeField.val('');
    this.rootServices.f7app.pinCodeConfirmDialog.close();
  }

  closePinCodeConfig(e: MouseEvent) {
    this.rootServices.f7app.pinCodeConfirmDialog.close();
  }

  checkUpdate(e: MouseEvent) {
    if (isPlatform('ios')) {
      this.rootServices.market.open('1531539465');
    }
    if (isPlatform('android')) {
      this.rootServices.market.open(this.rootServices.env.bundleId);
    }
  }

  docScan() {
    let opts: DocumentScannerOptions = {};
    this.rootServices.documentScanner.scanDoc(opts)
      .then((res: string) => console.log(res))
      .catch((error: any) => console.error(error));
  }

  gmvBarcodeScan() {
    const defaultOptions = {
      barcodeFormats: {
        Code128: true,
        Code39: true,
        Code93: true,
        CodaBar: true,
        DataMatrix: true,
        EAN13: true,
        EAN8: true,
        ITF: true,
        QRCode: true,
        UPCA: true,
        UPCE: true,
        PDF417: true,
        Aztec: true,
      },
      beepOnSuccess: true,
      vibrateOnSuccess: true,
      detectorSize: 0.7,
      rotateCamera: false,
    };
    window['cordova']['plugins']['mlkit'].barcodeScanner.scan(
      defaultOptions,
      (result) => {
        // Do something with the data
        console.log(result);
        this.commonService.showInfo(JSON.stringify(result));
      },
      (error) => {
        // Error handling
        this.commonService.showError(error);
      },
    );
  }

  zbarScan() {
    let options: ZBarOptions = {
      flash: 'on',
      drawSight: false
    }

    this.rootServices.zbar.scan(options)
      .then(result => {
        console.log(result); // Scanned code
        this.commonService.showInfo(result);
      })
      .catch(error => {
        this.commonService.showError(error);
        console.log(error); // Error message
      });
  }

  openPreviewStack() {
    const $this = this;
    const currentPreviewStack = this.rootServices.previewPictures(this.previewStack, this.previewStackIndex || 0, {
      on: {
        slideChange: (photoBrowser: PhotoBrowser.PhotoBrowser) => {
          this.previewStackIndex = photoBrowser.activeIndex;
          this.savePreviewStackIndex();
        },
      },
      addToStackButton: false, id: 'preview-stack', buttons: [
        {
          name: 'removeStack',
          icon: 'multiply_circle_fill',
          click: async (index: number, photoBrowser: PhotoBrowser.PhotoBrowser) => {
            $this.previewStack.splice(index, 1);
            this.rootServices.appComponent.ref.detectChanges();
            $this.previewStackIndex = $this.previewStack.length == 0 ? 0 : ((index > $this.previewStack.length - 1) ? ($this.previewStack.length - 1) : index);
            $this.savePreviewStack();
            photoBrowser.close();
            if ($this.previewStack.length > 0) {
              await currentPreviewStack;
              this.openPreviewStack();
            }
            $this.commonService.showInfo('Đã gở hình ảnh khỏi ngăn xếp', { position: 'bottom' });
          }
        }
      ]
    });
  }

  savePreviewStack() {
    const loginId = this.authService.getRecentLoginId();
    this.rootServices.storage.set(loginId + '_preview_stack', JSON.stringify(this.previewStack));
    this.rootServices.storage.set(loginId + '_preview_stack_index', this.previewStackIndex || 0);
  }
  savePreviewStackIndex() {
    const loginId = this.authService.getRecentLoginId();
    this.rootServices.storage.set(loginId + '_preview_stack_index', this.previewStackIndex || 0);
  }

  async restorePreviewStack() {
    await this.authService.loginState$.pipe(filter(f => !!f), take(1)).toPromise();
    const loginId = this.authService.getRecentLoginId();
    const previewStack = await this.rootServices.storage.get(loginId + '_preview_stack');
    const previewStackIndex = await this.rootServices.storage.get(loginId + '_preview_stack_index');
    if (previewStack) {
      this.previewStack = JSON.parse(previewStack);
    }
    if (previewStackIndex) {
      this.previewStackIndex = previewStackIndex;
    }
  }

  scan2PO() {
    const $this = this;
    this.rootServices.scanBarcodeByMlKit().then(async barcodeData => {
      let barcode = barcodeData?.text;
      try {
        if (barcode) {
          barcode = this.rootServices.getAccessNumberFromUrl(barcode);
          this.commonService.showPreloader();
          const { product, unit, container, findOrder, price, accessNumber, unitSeq } = await this.rootServices.barcodeExtract(barcode);
          console.log(product, unit, container, findOrder, price, accessNumber, unitSeq);
          // this.commonService.showInfo(this.commonService.getObjectId(product) + ', ' + this.commonService.getObjectId(unit) + ', ' + this.commonService.getObjectId(container) + ', ' + findOrder + ', ' + accessNumber + ', ' + unitSeq);

          if (!product || !unit) {
            this.commonService.showError('Không tìm thấy thông tin hàng hóa !');
            return false;
          }

          let suppliers: { id: string, text: string, LastPurchasePrice: number, LastPurchaseDate: string }[] = await this.rootServices.apiService.getPromise('/purchase/goods', { eq_Code: this.commonService.getObjectId(product), eq_ConversionUnit: this.commonService.getObjectId(unit), includeUnit: true, includeSuppliers: true }).then(rs => rs[0]?.Suppliers);
          if (Array.isArray(suppliers)) {
            suppliers = suppliers.sort((a, b) => a.LastPurchasePrice - b.LastPurchasePrice);
          }
          console.log(suppliers);

          this.commonService.hidePreloader();
          let supplier: ContactModel = null;
          let quantity: number = 0;
          let description: string = '';

          const supplierListTemplete = Template7.compile(/*html*/`
          <div class="list media-list" style="margin-left: -1rem; margin-right: -1rem; margin-bottom: 0.5rem; margin-top: 0.5rem; text-align: left; font-size: 1.5rem; max-height: 328px; overflow: auto;">
            <ul>
              {{#each suppliers}}
              <li data-id="{{id}}">
                <a href="#" class="item-link item-content">
                  <div class="item-media">
                    {{#if avatar}}
                    <div class="bg-color-gray" style="border-radius: 50%; overflow: hidden; width: 50px; height: 50px; background-repeat: no-repeat; background-size: cover; background-image: url({{js "this.avatar || ''"}})"></div>
                    {{else}}
                    <div class="bg-color-gray" style="border-radius: 50%; overflow: hidden; width: 50px; height: 50px; display: flex; align-items: center; justify-content: center;">
                      <i class="icon f7-icons">person_fill</i>
                    </div>
                    {{/if}}
                  </div>
                  <div class="item-inner">
                    <div class="item-title" style="white-space: normal;">{{text this}}</div>
                    <div class="item-subtitle text-color-gray" style="font-size: 1rem; font-weight: bold;">{{#if id}}Lần nhập trước: {{date LastPurchaseDate}}{{else}}Chọn nhà cung cấp khác{{/if}}</div>
                    {{#if id}}
                    <div class="item-subtitle text-color-gray" style="font-size: 1rem; font-weight: bold;">Giá nhập: {{currency LastPurchasePrice}}</div>
                    {{/if}}
                  </div>
                </a>
              </li>
              {{/each}}
            </ul>
          </div>
        `);

          const supplierDialog = this.rootServices.f7app.dialog.create({
            cssClass: 'dialog-large',
            title: product.Name + ' (' + this.commonService.getObjectText(unit) + ')',
            text: 'Hãy chọn 1 nhà cung cấp để đặt hàng',
            content: supplierListTemplete({ suppliers }),
            buttons: [
              // ...buttons,
              {
                text: 'Trở về',
                bold: true,
                color: 'red'
              },
            ],
            verticalButtons: true,
          });
          supplierDialog.open();

          const listItem = supplierDialog.$el.find('.list li');
          listItem.click(async function () {
            const id = $(this).attr('data-id');
            console.log(id);

            supplierDialog.close();

            let listItemTemplate = Template7.compile(/*html*/`
              <div class="block-title" style="text-align: left">Thông tin đặt hàng</div>
              <div class="list inline-labels profile-form no-hairlines-md main-form" style="text-align: left; margin-left: -1rem; margin-right: -1rem; margin-bottom: 0; font-size: 1.5rem;">
                <ul>
                  <li class="autocomplete" name="Object">
                    <div class="item-link item-content" href="#">
                      <div class="item-inner">
                        <div class="item-title" style="font-size: 1.5rem;">Nhà cung cấp *</div>
                        <div class="item-after {{js "this.data && this.data.Object && this.data.Object.id && 'text-color-blue' || 'text-color-red'"}}">{{js "this.data && this.data.Object && this.data.Object.id && this.data.Object.text || 'Chọn'"}}</div>
                      </div>
                    </div>
                  </li>
                  <li class="item-content item-input">
                    <div class="item-inner">
                      <div class="item-title item-label" style="font-size: 1.5rem;">Số lượng</div>
                      <div class="item-input-wrap">
                        <input class="field text-color-blue" name="Quantity" type="text" inputmode="decimal" placeholder="Số lượng..." style="font-size: 1.5rem;">
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            `);

            let supplier = suppliers.find(f => f.id == id);
            const orderSupplierDialog = $this.rootServices.f7app.dialog.create({
              cssClass: 'dialog-large',
              title: 'Chọn nhà cung cấp khác',
              content: listItemTemplate({ data: { Object: supplier } }),
              buttons: [
                {
                  text: 'Đặt hàng',
                  bold: true,
                  color: 'blue',
                  onClick: async () => {
                    if ($this.commonService.getObjectId(supplier)) {

                      quantity = $(orderSupplierDialog.el).find('[name="Quantity"]').val() as number;
                      // description = $(orderSupplierDialog.el).find('[name="Description"]').val();

                      await $this.warehouseGoodsQueueComponent.pushGoodsToPurchaseOrderQueue(supplier, { ...product, id: product.Code, text: product.Name }, unit, quantity).then(queuePo => {
                      }).catch(err => {
                        console.error(err);
                      });
                    }
                  }
                },
                {
                  text: 'Trở về',
                  bold: true,
                  color: 'red'
                },
              ],
              verticalButtons: true,
            });
            orderSupplierDialog.open();
            const objectEle = $(orderSupplierDialog.el).find('[name="Object"]');
            $this.warehouseGoodsQueueComponent.createAutocompleteField(objectEle[0], null, (query => {
              return $this.rootServices.apiService.getPromise<ContactModel[]>('/contact/contacts', { search: query, includeIdText: true });
            }), value => {
              objectEle.find('.item-after').html($this.commonService.getObjectText(value[0]));
              supplier = value[0];
            }, value => {
              // orderSupplierDialog.open();
            });
            return true;


          });
          // }); 

        }
      } catch (err) {
        $this.commonService.showError(err);
        $this.commonService.hidePreloader();
      }
    });
  }

  scanToCheckAccessNumber() {
    const $this = this;
    this.rootServices.scanBarcodeByMlKit().then(async barcodeData => {
      let barcode = barcodeData?.text;
      try {
        if (barcode) {
          barcode = this.rootServices.getAccessNumberFromUrl(barcode);
          this.commonService.showPreloader();
          const { product, unit, container, findOrder, price, accessNumber, unitSeq } = await this.rootServices.barcodeExtract(barcode);
          console.log(product, unit, container, findOrder, price, accessNumber, unitSeq);
          // this.commonService.showInfo(this.commonService.getObjectId(product) + ', ' + this.commonService.getObjectId(unit) + ', ' + this.commonService.getObjectId(container) + ', ' + findOrder + ', ' + accessNumber + ', ' + unitSeq);

          if (!product || !unit) {
            this.commonService.showError('Không tìm thấy thông tin hàng hóa !');
            return false;
          }

          let warehouseBookEntries: any[] = await this.rootServices.apiService.getPromise('/warehouse/access-numbers', { eq_AccessNumber: accessNumber, gt_IncreaseWrite: 0 });
          if (Array.isArray(warehouseBookEntries)) {
            warehouseBookEntries = warehouseBookEntries.sort((a, b) => a.LastPurchasePrice - b.LastPurchasePrice);
          }
          console.log(warehouseBookEntries);

          this.commonService.hidePreloader();
          let supplier: ContactModel = null;
          let quantity: number = 0;
          let description: string = '';

          const supplierListTemplete = Template7.compile(/*html*/`
          <img src="{{product.FeaturePicture.Thumbnail}}" style="border-radius: 1rem; width: 100%;">
          <div class="list media-list" style="margin-left: -1rem; margin-right: -1rem; margin-bottom: 0.5rem; margin-top: 0.5rem; text-align: left; font-size: 1.5rem;">
            <ul>
              {{#each warehouseBookEntries}}
              <li data-id="{{id}}" data-relative-voucher="{{Voucher}}">
                <a href="#" class="item-link item-content">
                  <div class="item-media">
                    {{#if avatar}}
                    <div class="bg-color-gray" style="border-radius: 50%; overflow: hidden; width: 50px; height: 50px; background-repeat: no-repeat; background-size: cover; background-image: url({{js "this.avatar || ''"}})"></div>
                    {{else}}
                    <div class="bg-color-gray" style="border-radius: 50%; overflow: hidden; width: 50px; height: 50px; display: flex; align-items: center; justify-content: center;">
                      <i class="icon f7-icons">person_fill</i>
                    </div>
                    {{/if}}
                  </div>
                  <div class="item-inner">
                    <div class="item-title" style="white-space: normal;">{{ObjectName}}</div>
                    <div class="item-subtitle text-color-gray" style="font-size: 1rem; font-weight: bold;">Ngày nhập: {{date VoucherDate}}</div>
                    <div class="item-subtitle text-color-gray" style="font-size: 1rem; font-weight: bold;">Giá nhập: {{currency UnitPrice}}</div>
                  </div>
                </a>
              </li>
              {{/each}}
            </ul>
          </div>
        `);

          const supplierDialog = this.rootServices.f7app.dialog.create({
            cssClass: 'dialog-large',
            title: product.Name + ' (' + this.commonService.getObjectText(unit) + ')',
            text: 'Nhà cung cấp',
            content: supplierListTemplete({ warehouseBookEntries, product }),
            buttons: [
              // ...buttons,
              {
                text: 'Trở về',
                bold: true,
                color: 'red'
              },
            ],
            verticalButtons: true,
          });
          supplierDialog.open();

          const listItem = supplierDialog.$el.find('.list li');
          const prodImgEle = supplierDialog.$el.find('img');
          listItem.click(async function () {
            const id = $(this).attr('data-id');
            const relativeVoucherId = $(this).attr('data-relative-voucher');
            console.log(relativeVoucherId);

            if (/^110/.test(relativeVoucherId)) {
              // This is goods receipt note
              const relativeVouchers = await $this.rootServices.apiService.getPromise('/warehouse/goods-receipt-notes/' + relativeVoucherId, { includeRelativeVouchers: true });
              console.log(relativeVouchers);
              if (relativeVouchers && relativeVouchers[0] && relativeVouchers[0].RelativeVouchers && relativeVouchers[0].RelativeVouchers.length > 0) {
                const purchaseOrderId = relativeVouchers[0].RelativeVouchers.find(f => f.type == 'PURCHASEORDER');
                console.log(purchaseOrderId);
                if (purchaseOrderId) {
                  $this.rootServices.navigate('/purchase/order/' + $this.commonService.getObjectId(purchaseOrderId), {
                    context: {
                      backTitle: 'Truy xuất',
                      // textColorClass: self.textColorClass,
                      // chatRoom: self.$route.context.chatRoom,
                    }
                  });
                  supplierDialog.close();
                }
              }
            }

            return true;
          });
          prodImgEle.click(() => {
            $this.rootServices.previewPictures(product.Pictures, product.Pictures.findIndex(f => f.SysGlobalId == product.FeaturePicture.SysGlobalId), { addToStackButton: true });
          });
          // }); 

        }
      } catch (err) {
        $this.commonService.showError(err);
        $this.commonService.hidePreloader();
      }
    });
  }


}

// Global static functions
export class __ {

  static objecttext(obj) {
    return globalCommonService.getObjectText(obj);
  }
  static objectstext(objs) {
    return Array.isArray(objs) && objs.map(obj => globalCommonService.getObjectText(obj)).join(', ') || '';
  }
  static objectid(obj) {
    return globalCommonService.getObjectId(obj);
  }
  static objectsid(objs) {
    return Array.isArray(objs) && objs.map(obj => globalCommonService.getObjectId(obj)).join(', ') || '';
  }
  static dateformat(date: Date, format?: string) {
    return globalRootServices.datePipe.transform(date, format || 'short');
  }
  static decimal(value: number, option?: { format?: string }) {
    return (value || value === 0) && globalRootServices.decimalPipe.transform(value, option?.format || '1.0-2') || '';
  }
  static currency(value: number, option?: { code?: string }) {
    return value && globalRootServices.currencyPipe.transform(value, option?.code || 'VND') || '';
  }
  static moment(value: number) {
    return value && moment(value).fromNow() || '';
  }
}
