import { F7ComponentContext } from '../types/framework7-types';
import { Router } from 'framework7/build/core/modules/router/router';
import { BaseComponent, ComponentState } from '../lib/base-component';
import { RootServices } from '../services/root.services';
import { ContactModel } from '../model/contact.model';
import { CommonService } from '../services/common.service';
import { ChatRoom } from '../lib/nam-chat/chat-room';


export interface ComponentStateExtend extends ComponentState {
  [key: string]: any;
  instance: F7ComponentContextExtend;
}

export class F7ComponentContextExtend extends F7ComponentContext {
  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, onChooseContact?: (contact: ContactModel) => void } };
  // [key: string]: any;

  callback?: (contacts: ContactModel[]) => void;

  chatRoom?: ChatRoom;
}

/** Component group manager */
export class ContactsComponent extends BaseComponent<ComponentStateExtend> {
  // states: { [key: string]: State } = {};

  title = 'Liên hệ';

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
    public hasToolBar?: boolean,
  ) {
    super(rootServices);
    // // console.log('Click here to open ts file');
  }

  onF7pageRemove(id: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  async onComponentInit(state: ComponentStateExtend, index: string, asCase?: string) {
    const currentState = await super.onComponentInit(state, index, asCase);
    // if (state.chatRoom) {
    //   this.currentState.chatRoom = this.state[index].chatRoom = state.chatRoom;
    // }
    if (state.chatRoom) {
      this.currentState.chatRoom = this.state[index].chatRoom = state.chatRoom;
    }
    return currentState;
  }

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: 'contacts',
      path: '/contacts',
      component: {
        template: /*html*/`
        <div class="page{{js "this.hasToolBar ? '' : ' no-toolbar'"}}">
          <div class="navbar">
            <div class="navbar-bg"></div>
            <div class="navbar-inner sliding">
              <div class="left">
                {{#if hasToolBar}}
                <a href="#" class="link panel-open icon-only {{textColorClass}}">
                  <i class="icon f7-icons">bars</i>
                </a>
                {{else}}
                <a href="#" class="link back {{textColorClass}}">
                  <i class="icon icon-back"></i>
                  <span class="if-not-md {{textColorClass}}">Back</span>
                </a>
                {{/if}}
              </div>
              <div class="title">{{title}}</div>
              <div class="right">
                <a class="link icon-only">
                  <i class="icon f7-icons">ellipsis</i>
                </a>
              </div>
              <div class="subnavbar">
                <form data-search-container=".virtual-list-vdom" data-search-item="li" data-search-in=".item-title"
                  class="searchbar searchbar-contacts searchbar-init">
                  <div class="searchbar-inner">
                    <div class="searchbar-input-wrap">
                      <input type="search" placeholder="Tên hoặc số điện thoại đầy đủ" />
                      <i class="searchbar-icon"></i><span class="input-clear-button"></span>
                    </div>
                    <span class="searchbar-disable-button if-not-aurora {{textColorClass}}">Cancel</span>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="searchbar-backdrop"></div>
          <div class="page-content">
            <div class="block block-strong inset">
              <p>Danh sách liên hệ sẽ hiển thị khi bạn tìm kiếm</p>
            </div>
            <div class="list simple-list searchbar-not-found inset">
              <ul>
                <li>Không tim thấy kết quả nào</li>
              </ul>
            </div>
            <div class="list virtual-list virtual-list-vdom media-list searchbar-found inset">
              <ul>
                {{#each vlData.items}}
                <li key="{{id}}" _id="{{id}}" _appuid="{{AppUid}}" _useruid="{{UserUid}}"
                  style="top: {{../../vlData.topPosition}}px" @click="itemClick">
                  <a href="#" class="item-link item-content">
                    <div class="item-media"><i class="icon list-item-img"
                        style="background-image: url({{AvatarThumbnail}}); width: 44px; height: 44px"></i></div>
                    <div class="item-inner">
                      <div class="item-title-row">
                        <div class="item-title">{{title}}</div>
                      </div>
                      <div class="item-subtitle">{{subtitle}}</div>
                    </div>
                  </a>
                </li>
                {{/each}}
              </ul>
            </div>
          </div>
        </div>
        `,
        style: ``,
        data: async () => {
          return {
            title: this.title,
            hasToolBar: $this.hasToolBar || false,
            items: [],
            vlData: {
              items: [],
            },
          };
        },
        methods: {
          async refresh() {
            const self: F7ComponentContextExtend = this;
            self.$setState({
              // attachments: $this.currentState,
            });
            return true;
          },
          itemClick(event: MouseEvent) {
            const self: F7ComponentContextExtend = this;
            // const id = $(item).attr('_id');
            // const select
            console.log(event);
            const id = self.$(event.currentTarget).attr('_id');
            const appUid = self.$(event.currentTarget).attr('_appuid');
            const userUid = self.$(event.currentTarget).attr('_useruid');
            const contact = $this.currentState.instance.items.find(c => (c.id === id && (!appUid || appUid == c['AppUid']) && (!userUid || userUid == c['UserUid'])));
            if (self.callback) {
              self.callback([contact]);
              $this.currentState.instance.$router.back();
            }
          },
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          async pageInit(e, page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;
            const id = self.$route.params.id;


            self.searchBar = self.$app.searchbar.create({
              el: '.searchbar-contacts',
              // searchContainer: '.virtual-list-vdom',
              // searchin: '.item-title',
            });
            self.$(self.searchBar.$inputEl[0]).keyup(e => {
              // console.log(e);
              $this.commonService.takeUntil('contact_search', 300).then(async () => {
                // console.log(self.searchBar.$inputEl[0].value);
                let contacts = [];
                let groupContacts = [];
                try {
                  const rs = await $this.rootServices.apiService.getPromise<ContactModel[]>('/contact/contacts', { search: self.searchBar.$inputEl[0].value, joinOutsideReferences: true, sort_SearchRank: 'desc' });
                  // .then(rs => {
                  contacts = rs.map(contact => ({
                    ...contact,
                    type: 'CONTACT',
                    id: contact.Code,
                    title: contact.Name,
                    subtitle: contact['Platform'] + ': ' + contact['Page'],
                    AvatarThumbnail: contact['AvatarUrl']
                  }));


                  // const groups = await $this.rootServices.apiService.getPromise<ContactModel[]>('/chat/user-groups', { search: self.searchBar.$inputEl[0].value, chatRoom: self.chatRoom && self.chatRoom.id });
                  // groupContacts = groups.map(group => ({
                  //   ...group,
                  //   type: 'GROUP',
                  //   id: group.Code,
                  //   title: group.Name,
                  //   subtitle: group.Name,
                  // }));

                } catch (err) {
                  console.log(err);
                }
                self.items = self.virtualList.items = contacts;
                self.virtualList.update();
                // });
              });
            });

            self.virtualList = self.$app.virtualList.create({
              // List Element
              el: self.$el.find('.virtual-list') as any,
              // Pass array with items
              items: self.items,
              // Custom search function for searchbar
              searchByItem: (query: string, item: any, index: number) => true,
              // Item height
              height: self.$theme.ios ? 63 : (self.$theme.md ? 73 : 46),
              // Render external function
              renderExternal: (vl: any, vlData?: any) => {
                self.$setState({
                  vlData: vlData,
                });
                return;
              },
            });


            $this.onComponentInit({ instance: self, page }, id, 'init').then(async () => {
              // self.$setState({
              //   contactList: await $this.rootServices.apiService.getPromise<ContactModel[]>('/', { limit: 50 }),
              // });
            });
          },
          pageBeforeIn(e, page) {
            const self: F7ComponentContextExtend = this;
            const chatRoomId = self.$route.params.id;

            // Fire event: On Changed State
            $this.onChangedState({ instance: self, page }, chatRoomId, 'before-in');
          },
          pageBeforeOut(e, page) {
            const self: F7ComponentContextExtend = this;
            const id = self.$route.params.id;

            // Fire event: On Before Changed State
            $this.onBeforeChangedState({ instance: self, page, scrollPosition: page.$el[0].children[0].scrollTop || 1 }, id);
          }
        },
      },
    };
  }
}
