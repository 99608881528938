import Inputmask from "inputmask";
import { TextEditor } from 'framework7/build/core/components/text-editor/text-editor';
import { SmartSelect } from 'framework7/build/core/components/smart-select/smart-select';
import { Toggle } from 'framework7/build/core/components/toggle/toggle';
import { BaseComponent, ComponentState } from '../../../src/app/lib/base-component';
import { CommonService } from '../services/common.service';
import { RootServices } from '../services/root.services';
import { F7ComponentContext, F7Page } from '../types/framework7-types';
import { ContactModel } from '../model/contact.model';
import { Model } from "../model/model";
import { Autocomplete } from "framework7/build/core/components/autocomplete/autocomplete";
// import * as $ from 'jquery';
declare const $: any;

// declare const $: any;
export interface FormControlSchema {
    [key: string]: any;
    type?: string;
    autocompleteOption?: Partial<Autocomplete.Parameters> & {
        [key: string]: any,
        minSearchInputLength?: number,
    }
    dataType?: string;
    validators?: any[];
    makeModel?: (properties?: any) => any;
}
export interface FormSchema {
    [key: string]: FormControlSchema;
}

export interface FormComponentStateExtend<M extends Model> extends ComponentState {
    [key: string]: any;
    instance: F7ComponentContext,
    data?: M;
    form?: any;
    lastAction?: string;
}


export abstract class BaseFormComponent<S extends FormComponentStateExtend<M>, M> extends BaseComponent<ComponentState> {

    apiPath: string;
    currentState: S;

    idKey: string;
    schema: FormSchema;
    specialTypes = ['smart-select', 'autocomplete', 'datepicker', 'toggle', 'calandar', 'list'];

    constructor(
        public rootServices: RootServices,
        public commonService: CommonService,
    ) {
        super(rootServices);
    }

    abstract makeModel(properties?: M): M;

    async onComponentInit(state: ComponentState, index: string, asCase?: string, page?: F7Page): Promise<ComponentState> {
        return super.onComponentInit(state, index, asCase, page).then(async (currentState: FormComponentStateExtend<M>) => {
            // Prepare for autocomplete
            for (const fieldName in this.schema) {
                const prop = this.schema[fieldName];
                if (prop.type == 'autocomplete') {
                    const autocomplete = this.createAutocompleteField($(currentState.instance.el).find('.autocomplete[name="' + fieldName + '"]')[0], currentState, (query) => {
                        return prop.ajax(query);
                    }, (value) => {
                        currentState.data[fieldName] = value[0];
                        currentState.instance.$setState({
                            data: currentState.data,
                            validates: this.formValidate()
                        });
                        currentState.instance.onFieldChange({ target: fieldName, value: value[0] });
                    }, (value) => {
                        console.log(value);
                    }, prop.autocompleteOption);
                }
                if (prop.type == 'autocomplete-contact') {
                    const autocomplete = this.createAutocompleteField($(currentState.instance.el).find('.autocomplete[name="' + fieldName + '"]')[0], currentState, (query) => {
                        return this.rootServices.apiService.getPromise<ContactModel[]>('/contact/contacts', { search: query, includeIdText: true, includeGroups: true, sort_SearchRank: 'desc' }).then(results => {
                            return results.map(m => {
                                m.text = `${m.Name}${m.Groups ? ' (' + m.Groups.map(g => this.commonService.getObjectText(g as any)).join(', ') + ')' : ''}`;
                                return m;
                            });
                        });
                    }, (value) => {
                        currentState.data[fieldName] = value[0];
                        currentState.instance.$setState({
                            data: currentState.data,
                            validates: this.formValidate()
                        });
                        currentState.instance.onFieldChange({ target: fieldName, value: value[0] });
                    }, (value) => {
                        console.log(value);
                    }, prop.autocompleteOption);
                }
                if (prop.type == 'autocomplete-product') {
                    const autocomplete = this.createAutocompleteField($(currentState.instance.el).find('.autocomplete[name="' + fieldName + '"]')[0], currentState, (query) => {
                        return this.rootServices.apiService.getPromise<ContactModel[]>('/admin-product/products', { search: query, includeIdText: true }).then(results => {
                            return results;
                        });
                    }, (value) => {
                        currentState.data[fieldName] = value[0];
                        currentState.instance.$setState({
                            data: currentState.data,
                            validates: this.formValidate()
                        });
                        currentState.instance.onFieldChange({ target: fieldName, value: value[0] });
                    }, (value) => {
                        console.log(value);
                    }, prop.autocompleteOption);
                }
                if (prop.type == 'datepicker') {
                    const field = $(currentState.instance.el).find('.calendar[name="' + fieldName + '"]')[0];
                    const datepicker = field.datepicker = this.createDatePickerField(field, currentState, (value) => {
                        if (currentState.data) {
                            currentState.data[fieldName] = value[0];
                            currentState.instance.$setState({
                                data: currentState.data,
                                validates: this.formValidate()
                            });
                            currentState.instance.onFieldChange({ target: fieldName, value: value[0] });
                        }
                    }, (value) => {
                        console.log('calandar value: ' + value);
                    }, {
                        dateFormat: 'HH::mm, dd/mm/yyyy',
                    });
                    // datepicker.setValue([new Date()]);
                }
            }

            // Load data or create new data
            if (/^new\-?/.test(currentState.instance?.$route?.params?.id)) {
                if (!currentState.data) {

                    if (currentState.instance.$route?.context?.copyFromId) {
                        // Case: compy from id
                        currentState.data = await this.getFormData();
                        // Wait for controls init;
                        // await this.commonService.waiting(500);
                        currentState.data[this.idKey] = null;
                    } else {
                        // Wait for controls init;
                        // await this.commonService.waiting(500);

                        currentState.data = this.makeModel();
                    }
                }
                if (state.instance?.$route?.context && state.instance?.$route?.context['data']) {
                    currentState.data = state.instance.$route.context['data'];
                }
            } else {
                if (!currentState.data || !currentState.instance?.$route?.context || !currentState.instance?.$route?.context['doNotReload']) {
                    currentState.data = await this.getFormData();
                }
            }

            // Wait for controls init;
            await this.commonService.waiting(500);
            await this.setData(currentState.data, { prepareControl: true });
            return currentState;
        });
    }

    async prepareControl(name: string, listName?: string, index?: number | string, option?: { force?: boolean, params?: { [key: string]: any, on: any } }) {
        const currentState = this.currentState;
        let cssPath = '', preValue = null, $field;

        if (listName && typeof index != 'undefined' && index !== null) {
            cssPath += `.list-name[name=${listName}] .index-${index} `;
        } else {
            cssPath += '.main-form ';
        }
        const schema = listName ? this.schema[listName][name] : this.schema[name];
        if (schema) {
            switch (schema.type) {
                case 'inputmask':
                    cssPath += `.inputmask[name="${name}"]`;
                    // console.log(cssPath);
                    const format = listName ? this.schema[listName][name].format : this.schema[name].format;
                    Inputmask(format.mask, format.option).mask($(currentState.instance.el).find(cssPath));

                    $(currentState.instance.el).find(cssPath).focus(function () {
                        this['select']();
                    });
                    break;
                case 'smart-select':
                    if (option?.force) {
                        cssPath += `.smart-select[name="${name}"]`;
                        // console.log(cssPath);
                        return currentState.instance.$app.smartSelect.create({
                            el: $(currentState.instance.el).find(cssPath) as any,
                            openIn: 'popup',
                            searchbar: true,
                            closeOnSelect: true,
                            popupSwipeToClose: true,
                            scrollToSelectedItem: true,
                            ...option?.params
                        });
                    }
                    break;
                case 'toggle':
                    cssPath += `.smart-select[name="${name}"]`;
                    // currentState.instance.$app.toggle.get()
                    break;
            }
        }
        return null;
    }

    async setFieldValue(type: string, name: string, value: any, listName?: string, index?: number | string) {
        const currentState = this.currentState;
        let cssPath = '', preValue = null, $field;

        if (listName && typeof index != 'undefined' && index !== null) {
            cssPath += `.list-name[name=${listName}] .index-${index} `;
        } else {
            cssPath += '.main-form ';
        }
        switch (type) {
            case 'smart-select':
                cssPath += `.smart-select[name="${name}"]`;
                // console.log(cssPath);
                await this.commonService.waitFor(100, 50, async () => {
                    // console.log('wait for field ' + cssPath + ' visabled');
                    $field = $(currentState.instance.el).find(cssPath);
                    return $field.length > 0;
                });
                if ($field.length > 0) {
                    // console.log('set value ' + cssPath + ': ', value);
                    preValue = Array.isArray(value) ? value.map(m => this.commonService.getObjectId(m)) as any : this.commonService.getObjectId(value) as any;
                    // setTimeout(() => {
                    currentState.instance.$app.smartSelect.get($field[0]).setValue(preValue);
                    // }, 300);
                } else {
                    console.warn('field not found');
                }
                break;
            case 'stepper':
                cssPath += `.stepper[name="${name}"]`;
                // console.log(cssPath);
                await this.commonService.waitFor(100, 10, async () => {
                    // console.log('wait for field ' + cssPath + ' visabled');
                    $field = $(currentState.instance.el).find(cssPath);
                    return $field.length > 0;
                });
                if ($field.length > 0) {
                    preValue = Array.isArray(value) ? value.map(m => this.commonService.getObjectId(m)) as any : this.commonService.getObjectId(value) as any;
                    currentState.instance.$app.stepper.get($field[0]).setValue(preValue || 0);
                } else {
                    console.warn('field not found');
                }
                break;
            case 'texteditor':
                cssPath += `.text-editor[name="${name}"]`;
                // console.log(cssPath);
                await this.commonService.waitFor(100, 10, async () => {
                    // console.log('wait for field ' + cssPath + ' visabled');
                    $field = $(currentState.instance.el).find(cssPath);
                    return $field.length > 0;
                });
                if ($field.length > 0) {
                    currentState.instance.$app.textEditor.get($field[0]).setValue(value);
                } else {
                    console.warn('field not found');
                }
                break;
            case 'toggle':
                cssPath += `.toggle[name="${name}"]`;
                // console.log(cssPath);
                await this.commonService.waitFor(100, 10, async () => {
                    // console.log('wait for field ' + cssPath + ' visabled');
                    $field = $(currentState.instance.el).find(cssPath);
                    return $field.length > 0;
                });
                if ($field.length > 0) {
                    // console.log('wait for field ' + cssPath + ' visabled');
                    const toggle = currentState.instance.$app.toggle.get($field[0]);
                    if (toggle.checked != !!value) toggle.toggle();
                } else {
                    console.warn('field not found');
                }
                break;
            case 'datepicker':
                cssPath += `.calendar[name="${name}"]`;
                await this.commonService.waitFor(100, 10, async () => {
                    // console.log('wait for field ' + cssPath + ' visabled');
                    $field = $(currentState.instance.el).find(cssPath);
                    return $field.length > 0;
                });
                if ($field.length > 0) {
                    // console.log('wait for field ' + cssPath + ' visabled');
                    // const datePicker = currentState.instance.$app.calendar.get($field[0]);
                    const datePicker = $field[0].datepicker;
                    datePicker.setValue([new Date(value)]);
                } else {
                    console.warn('field not found');
                }
                break;
            // case 'mask':
            //     cssPath += `[name="${name}"]`;
            //     if (listName && typeof index != 'undefined') {
            //         if (currentState?.data[listName][index]['__controls']) {
            //             const mask = currentState?.data[listName][index]['__controls'][name];
            //             if (mask) {
            //                 setTimeout(() => {
            //                     mask.el.value = mask.masked.resolve((value || '').toString());
            //                 }, 300);
            //             }
            //         }
            //     }
            //     break;
            case 'inputmask':// skip
                // cssPath += `[name="${name}"]`;
                // $(currentState.instance.el).find(cssPath).val(value);
                break;
            case 'checkbox':

                cssPath += `input[name="${name}"]`;
                await this.commonService.waitFor(100, 10, async () => {
                    // console.log('wait for field ' + cssPath + ' visabled');
                    $field = $(currentState.instance.el).find(cssPath);
                    return $field.length > 0;
                });
                
                $(currentState.instance.el).find(cssPath).prop('checked', value);
                break;
            default:
                cssPath += `[name="${name}"]`;
                $(currentState.instance.el).find(cssPath).val(value);
                break;
        }
        return true;
    }

    getFieldValue(type: string, name: string, listName?: string, index?: number) {
        const currentState = this.currentState;
        let cssPath = '';

        if (listName && typeof index != 'undefined' && index !== null) {
            cssPath += `.${listName} .index-${index} `;
        } else {
            cssPath += '.main-form ';
        }

        switch (type) {
            case 'smart-select':
                cssPath += `.smart-select[name="${name}"]`;
                return currentState.instance.$app.smartSelect.get($(currentState.instance.el).find(cssPath)[0]).getValue();
            case 'texteditor':
                cssPath += `.text-editor[name="${name}"]`;
                return currentState.instance.$app.textEditor.get($(currentState.instance.el).find(cssPath)[0]).getValue();
            case 'toggle':
                cssPath += `.toggle[name="${name}"]`;
                return currentState.instance.$app.toggle.get($(currentState.instance.el).find(cssPath)[0]).checked;
            default:
                cssPath += `[name="${name}"]`;
                return $(currentState.instance.el).find(cssPath).val();
        }
    }

    async getFormData(params?: any): Promise<M> {
        const currentState = this.currentState;
        if (currentState.instance.$route.context?.copyFromId || (currentState.instance.$route.params['id'] && !/^new\-?/.test(currentState.instance.$route.params['id']))) {
            return this.rootServices.apiService.getPromise<M[]>(this.apiPath + '/' + (currentState.instance.$route?.context?.copyFromId || currentState.instance.$route.params['id']), {
                ...params,
            }).then(rs => {
                delete rs[0]['Id'];
                if (currentState.instance.$route.context?.copyFromId) {
                    delete rs[0][this.idKey];
                }
                return this.prepareData(rs[0]);
            });
        }
        return this.makeNewData();
    }

    prepareData(data: M) {
        return data;
    }

    async setData(data: M, option?: { onlyList?: string[], onlyKeys?: string[], prepareControl?: boolean }) {
        console.log('setData', data);
        this.currentState.data = data;
        this.currentState.instance.$setState({ data, validates: this.formValidate() });
        for (const key in this.schema) {
            const fieldSchema = this.schema[key];
            if (fieldSchema.type == 'list') {
                if (!option?.onlyList || option?.onlyList?.indexOf(key) > -1) {
                    for (const index in (data[key] as any)) {
                        for (const detailKey in this.schema[key]) {
                            if (!option?.onlyKeys || option?.onlyKeys.indexOf(detailKey) > -1) {
                                if (detailKey !== 'type' && detailKey != 'makeModel') {
                                    const detalFieldSchema = this.schema[key][detailKey];
                                    this.setFieldValue(detalFieldSchema.type, detailKey, data[key][index][detailKey], key, index);
                                    if (option?.prepareControl) {
                                        setTimeout(() => {
                                            this.prepareControl(detailKey, key, index);
                                        }, 300);
                                    }
                                }

                            }
                        }
                    }
                }
            } else {
                if (!option?.onlyKeys || option?.onlyKeys.indexOf(key) > -1) {
                    this.setFieldValue(fieldSchema.type, key, Array.isArray(data[key]) ? data[key].map(m => this.commonService.getObjectId(m)) : this.commonService.getObjectId(data[key]) as any);

                    if (option?.prepareControl) {
                        setTimeout(() => {
                            this.prepareControl(key);
                        }, 300);
                    }
                }
            }
        }

        return true;
    }

    async makeNewData(): Promise<M> {
        return null;
    }

    async onFieldChange(self: F7ComponentContext, e: any, ...args: any): Promise<{ field?: HTMLElement, fieldName?: string, fieldValue?: any, index: number | string, listName?: string, previousValue?: any }> {
        // const self: F7ComponentContext = this;
        const field = e.target;
        // console.log(e.key);

        let fieldName, fieldValue, index, listName;
        index = e.target?.index || $(field).closest('.list-item').data('index');
        listName = e.target?.listName || $(field).closest('.list-name').attr('name');

        if (['keyup'].indexOf(e?.type) > -1) {
            if (['ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown', 'Control', 'Control', 'Shift', 'CapsLock', 'Tab', 'Escape', 'F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 'F9', 'F10', 'F11', 'F12'].indexOf(e.key) > -1) {
                return null;
            }
        }

        if (['keyup'].indexOf(e?.type) > -1) {
            await this.commonService.takeUntil('on-form-field-change-' + listName + listName + fieldName, 300);
        }

        if ($(field).is('.toggle')) {
            const toggle: Toggle.Toggle = self.$app.toggle.get(field);
            fieldName = $(field).attr('name');
            fieldValue = toggle.checked;
        } else if ($(field).is('input[type=checkbox]')) {
            fieldName = $(field).attr('name');
            fieldValue = $(field).is(':checked');
        } else if ($(field).is('.smart-select')) {
            const smartSelect: SmartSelect.SmartSelect = self.$app.smartSelect.get(field);
            fieldName = $(field).attr('name');
            fieldValue = smartSelect.getValue();
        } else if ($(field).is('.text-editor')) {
            const textEditor: TextEditor.TextEditor = self.$app.textEditor.get(field);
            fieldName = $(field).attr('name');
            fieldValue = textEditor.getValue();
        } else if ($(field).is('.stepper')) {
            const stepper: any = self.$app.stepper.get(field);
            fieldName = $(field).attr('name');
            fieldValue = stepper.getValue();
        } else if ($(field).is('.inputmask')) {
            fieldName = field.name;
            fieldValue = field.value;
            if (field?.inputmask?.opts?.inputmode == 'decimal') {
                fieldValue = this.rootServices.parseLocaleDecimal(fieldValue);
            }
        } else if ($(field).is('.calendar')) {
            fieldName = field;
            // fieldValue = ;

        } else {
            if (typeof field == 'string') {
                fieldName = field;
                fieldValue = e.value;
            } else {
                fieldName = field.name;
                fieldValue = field.value;
            }
        }

        const currentState = this.currentState;
        let previousValue = null;
        if (currentState.data) {
            if (listName && typeof index != 'undefined') {
                previousValue = currentState.data[listName][index][fieldName];
                currentState.data[listName][index][fieldName] = fieldValue;
            } else {
                previousValue = currentState.data[fieldName];
                currentState.data[fieldName] = fieldValue;
            }
        }
        // fieldValue = this.commonService.getObjectId(fieldValue);

        console.log(currentState.data);
        if (['keyup'].indexOf(e?.type) > -1) {
            this.commonService.takeUntil('form_field_keyup_validate', 300).then(status => {
                this.validate();
            });
        } else {
            this.validate();
        }
        return { field, fieldName, fieldValue, index, listName, previousValue };
    }

    async addItemForList<D>(listName: string, item?: D): Promise<D> {
        const currentState = this.currentState;
        if (!item && this.schema[listName] && this.schema[listName].makeModel) {
            item = this.schema[listName].makeModel();
        }
        if (!currentState.data[listName]) {
            currentState.data[listName] = [];
        }
        item['__index'] = currentState.data[listName].length;
        currentState.data[listName].push(item);

        this.setData(currentState.data, { prepareControl: true });
        return item;
    }

    async updateItemForList<D>(listName: string, index: number, item: D): Promise<D> {
        const currentState = this.currentState;
        currentState.data[listName][index] = item;

        this.setData(currentState.data);
        return item;
    }

    async removeDetailItem(self: F7ComponentContext, e: any, ...args: any): Promise<{ detail?: any, listName?: string, index?: number | string }> {
        const currenntState = this.currentState;


        const listName = self.$(e.target).closest('.list-name').attr('name');
        const detail = self.$(e.target).closest('.list-item');
        const index = self.$(e.target).closest('.list-item').data('index');

        // self.$app.accordion.close($(e.target).closest('.accordion-item')[0]);

        currenntState.data[listName].splice(index, 1);
        currenntState.data[listName].map((item, index) => {
            item['__index'] = index;
        })
        // self.$setState({
        //     data: currenntState.data,
        // });
        this.setData(currenntState.data);

        return { detail, listName, index };
    }

    /** 
     * Save form
     * @param self
     * @parma option */
    async save(self: F7ComponentContext, option?: { postParams?: any, updateProperties?: string[] }) {
        this.commonService.showPreloader(10000);
        let currenntState = this.currentState;
        if (this.currentState.id != this.state[self.$route.params['id']].id) {
            return Promise.reject('Lỗi ghi đề phiếu phiếu khác !!!');
        }
        if (this.currentState.data && this.currentState.data[this.idKey] && this.currentState.data[this.idKey] != self.$route.params['id']) {
            return Promise.reject('Lỗi ghi đề phiếu phiếu khác !!!');
        }
        const id = currenntState.data[this.idKey] || self.$route.params['id'];
        console.log(currenntState.data);

        const data = {
            ...currenntState.data,
            __controls: null
        };

        for (const key in this.schema) {
            const fieldSchema = this.schema[key];
            if (fieldSchema.type == 'list' && Array.isArray(currenntState.data[key])) {
                data[key] = [
                    ...currenntState.data[key].map(item => ({ ...item, __controls: null, __schema: null })),
                ];
            }
        }

        try {
            if (/^new\/?/.test(id)) {
                return this.rootServices.apiService.postPromise<M[]>(this.apiPath, {
                    ...option?.postParams
                }, [data]).then(rs => {
                    this.commonService.hidePreloader();
                    this.state[rs[0][this.idKey]] = currenntState;
                    this.state[id] = null;
                    console.log(rs);
                    currenntState.data[this.idKey] = rs[0][this.idKey];

                    if (option?.updateProperties) {
                        for (const prop of option?.updateProperties) {
                            currenntState.data[prop] = rs[0][prop];
                        }
                    }

                    self.$route.params['id'] = rs[0][this.idKey];
                    currenntState.id = self.$route.params['id'];

                    for (const key in this.schema) {
                        const fieldSchema = this.schema[key];
                        if (fieldSchema.type == 'list') {
                            if (Array.isArray(rs[0][key])) {
                                for (const index in rs[0][key]) {
                                    if (rs[0][key][index]['SystemUuid']) {
                                        currenntState.data[key][index]['SystemUuid'] = rs[0][key][index]['SystemUuid'];
                                    }
                                }
                            }
                        }
                    }

                    currenntState.instance.$setState({ data: currenntState.data, validates: this.formValidate() });
                    currenntState.lastAction = 'CREATE_SUCCESS';
                    return rs[0];
                    // self.$route.context['callback'] && self.$route.context['callback'](rs[0]);
                }).catch(err => {
                    console.error(err);
                    this.commonService.showError(err);
                    this.commonService.hidePreloader();
                    currenntState.lastAction = 'CREATE_ERROR';
                    return Promise.reject(err);
                });
            } else {
                return this.rootServices.apiService.putPromise<M[]>(this.apiPath + '/' + id, {
                    ...option?.postParams
                }, [data]).then(rs => {
                    console.log(rs);
                    this.commonService.hidePreloader();
                    for (const key in this.schema) {
                        const fieldSchema = this.schema[key];
                        if (fieldSchema.type == 'list') {
                            if (Array.isArray(rs[0][key])) {
                                for (const index in rs[0][key]) {
                                    if (rs[0][key][index]['SystemUuid']) {
                                        currenntState.data[key][index]['SystemUuid'] = rs[0][key][index]['SystemUuid'];
                                    }
                                }
                            }
                        }
                    }
                    currenntState.lastAction = 'UPDATE_SUCCESS';
                    return rs[0];

                    // self.$route.context['callback'] && self.$route.context['callback'](rs[0]);
                }).catch(err => {
                    console.error(err);
                    this.commonService.showError(err);
                    this.commonService.hidePreloader();
                    currenntState.lastAction = 'UPDATE_ERROR';
                    return Promise.reject(err);
                });
            }
        } catch (err) {
            this.commonService.hidePreloader();
        }
    }


    formValidate() {
        console.log('Form validate...');
        const validates = [];
        const data = this.currentState.data;
        if (data) {
            for (const key in this.schema) {
                const fieldSchema = this.schema[key];
                if (fieldSchema.type == 'list' && Array.isArray(data[key])) {
                    for (const index in (data[key] as any)) {
                        for (const detailKey in this.schema[key]) {
                            if (detailKey !== 'type' && detailKey != 'makeModel') {
                                const detalFieldSchema = this.schema[key][detailKey];
                                let fieldValidates = this.fieldValidate(data[key][index][detailKey], `${key}.${detailKey}`, detalFieldSchema);
                                if (fieldValidates) {
                                    // if(!validates[key]) {
                                    //     validates[key] = [];
                                    // }
                                    validates.push({ list: detailKey, index, name: `${key}.${detailKey}`, validates: fieldValidates });
                                }

                            }
                        }
                    }
                } else {
                    let fieldValidates = this.fieldValidate(data[key], key, fieldSchema);
                    if (fieldValidates) {
                        validates.push({ name: key, validates: fieldValidates });
                    }
                }
            }
        }
        return validates.length > 0 ? validates : null;
    }

    validate(state?: FormComponentStateExtend<M>) {
        console.log('validate and update state...');
        const validates = this.formValidate();
        (state || this.currentState).instance.$setState({
            validates: validates
        });
        return validates;
    }

    fieldValidate(value: any, name: string, fieldSchema?: any) {
        let listName = null;
        if (/\./.test(name)) {
            [listName, name] = name.split('.');
        }
        if (!fieldSchema) {
            fieldSchema = listName ? this.schema[listName][name] : this.schema[name];
        }
        const v = this.commonService.getObjectId(value);
        if (fieldSchema) {
            const validates = [];
            if (fieldSchema.validators && fieldSchema.validators.length > 0) {
                for (const validator of fieldSchema.validators) {
                    const validatorName = typeof validator == 'string' ? validator : validator.name;
                    switch (validatorName) {
                        case 'required':
                            if (v !== 0 && !v) {
                                validates.push({ name: name, label: fieldSchema?.label || name, id: `${name}.${validatorName}`, text: 'Trường bắt buộc', status: 'warning' });
                            }
                            break;
                    }
                }
            }
            return validates.length == 0 ? null : validates;
        }
        return null;
    }

    async refresh(self?: ComponentState): Promise<M> {
        // const self: F7ComponentContextExtend = this;
        let doneTimeout = null;
        // if (done) {
        //     doneTimeout = setTimeout(() => {
        //         done();
        //     }, 10000);
        // };

        try {
            return this.getFormData().then(data => {
                this.setData(data, { prepareControl: true });
                // done && done();
                return data;
            });
        } catch (err) {
            console.error(err);
            // done && done();
            return Promise.reject(err);
        }
        return null;
    }
}