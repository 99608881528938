import { Model } from "vendors/smart-bot-app/src/app/model/model";
import { IdTextModel } from "./common.model";
import { FileModel } from "vendors/smart-bot-app/src/app/model/file.model";

export interface CollaboratorPublisherModel {
    [key: string]: any;
    Id?: string;
    Page?: string;
    Publisher?: string;
    Name?: string;
    Phone?: string;
    Email?: string;
    Address?: string;
    IdentifiedNumber?: string;
    Assigned?: string;
    State?: string;
    Level?: string;
    LevelLabel?: string;
}

export class CollaboratorOrderModel extends Model {
    [key: string]: any;
    Id?: string;
    Page?: string;
    Publisher?: any;
    Code?: string;
    Object?: any;
    ObjectName?: string;
    ObjectAddress?: string;
    ObjectPhone?: string;
    ObjectEmail?: string;
    ObjectTaxCode?: string;
    ObjectBank?: string;
    ObjectBankName?: string;
    ObjectBankAccount?: string;
    ObjectIdentifiedNumber?: string;
    DateOfOrder?: string;
    DateOfDelivery?: string;
    DeliveryAddress?: string;
    FullDeliveryAddress?: string;
    DirectReceiverName?: string;
    IsExportVatInvoice?: string;
    Title?: string;
    Note?: string;
    SubNote?: string;
    State?: any;
    Permission?: string;
    PriceTable?: string;
    Details?: CollaboratorOrderDetailModel[];

    constructor(properties?: CollaboratorOrderModel) {
        super(properties);
        this.Details = [];
    }
}

export class CollaboratorOrderDetailModel {
    [key: string]: any;
    Id?: string;
    Voucher?: string;
    Type?: string;
    No?: number;
    Image?: any;
    Product?: any;
    Description?: string;
    Quantity?: number;
    Price?: number;
    Tax?: any;
    Currency?: string;
    Unit?: any;

    constructor(properties?: CollaboratorOrderDetailModel) {
        this.Quantity = 1;
        this.Tax = { id: 'VAT10', text: '+ 10% VAT', Tax: 10 };
    }
}

export class CollaboratorCommissionVoucherModel {
    [key: string]: any;
    Id?: string;
    Code?: string;
    Page?: string;
    Publisher?: string;
    PublisherName?: string;
    PublisherPhone?: string;
    PublisherEmail?: string;
    PublisherAddress?: string;
    PublisherIdentifiedNumber?: string;
    Bank?: string;
    BankName?: string;
    BankAccount?: string;
    Amount?: number;
    Created?: string;
    CommissionFrom?: string;
    CommissionTo?: string;
    Description?: string;
    State?: string;

}

export interface CollaboratorEducationArticleModel {
    // [key: string]: any;
    id?: string; text?: string;
    Id?: string;
    Code?: string;
    Title?: string;
    Summary?: string;
    ContentBlock1?: string;
    ContentBlock2?: string;
    ContentBlock3?: string;
    Creator?: string;
    DateOfCreated?: string;
    Product?: string & IdTextModel;
    ProductName?: string;
    Page?: string & IdTextModel;
    PageName?: string;
    State?: string;
}

export class CollaboratorOpportunityModel extends Model {
    Id?: string;
    Code?: string;
    Page?: string;
    Originator?: any;
    OriginatorName?: string;
    Title?: string;
    Note?: string;
    Customer?: any;
    CustomerName?: string;
    CustomerPhone?: string;
    CustomerEmail?: string;
    CustomerAddress?: string;
    Creator?: string;
    CreatorName?: string;
    Created?: string;
    DateOfOpportunity?: string;
    State?: any;
    Permission?: string;
    Business?: any;
    Amount?: string;

    Details?: CollaboratorOpportunityDetailModel[];
    Comments?: CollaboratorOpportunityCommentModel[];

    constructor(perperties?: CollaboratorOpportunityModel) {
        super(perperties);
    }
}

export class CollaboratorOpportunityDetailModel extends Model {
    Id?: string;
    Voucher?: string;
    No?: string;
    Type?: any;
    Product?: any;
    ProductName?: string;
    Description?: string;
    Unit?: any;
    UnitLabel?: string;
    Quantity?: number;
    Price?: number;
    Business?: string;
    Image?: any;
    SystemUuid?: string;

    constructor(perperties?: CollaboratorOpportunityDetailModel) {
        super(perperties);
    }
}
export class CollaboratorOpportunityCommentModel extends Model {
    Id?: string;
    Opportunity?: any;
    Content?: string;
    Attachments?: FileModel[];
    DateOfPost?: Date;
    Sender?: any;
    SenderName?: string;
    SenderAvatar?: any;
    SystemUuid?: string;

    constructor(perperties?: CollaboratorOpportunityCommentModel) {
        super(perperties);
    }
}