import { Dialog } from "framework7/build/core/components/dialog/dialog";
import { Router } from "framework7/build/core/modules/router/router";
import { take } from "rxjs/operators";
import { CollaboratorOrderModel, CollaboratorOrderDetailModel } from "src/app/model/collaborator.model";
import { RootServices } from "src/app/services/root.services";
import { F7ComponentContext, F7Page } from "src/app/types/framework7-types";
import { FormComponentStateExtend, BaseFormComponent, FormSchema } from "vendors/smart-bot-app/src/app/lib/base-form-component";
import { BasePrintComponent, PrintComponentStateExtend } from "vendors/smart-bot-app/src/app/lib/base-print-component";
import { ContactModel } from "vendors/smart-bot-app/src/app/model/contact.model";
import { FileModel } from "vendors/smart-bot-app/src/app/model/file.model";
import { CommonService } from "vendors/smart-bot-app/src/app/services/common.service";



// import * as $ from 'jquery';
declare const $: any;

// declare const $: any;
export interface ComponentStateExtend extends PrintComponentStateExtend<CollaboratorOrderModel> {
  [key: string]: any;
  instance: F7ComponentContextExtend,
  data?: CollaboratorOrderModel;
  form?: any;
}

export class F7ComponentContextExtend extends F7ComponentContext {
  responseInProgress?: boolean;

  detailDialog?: Dialog.Dialog;

  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, [key: string]: any } };
}

/** Component group manager */
export class PageCollaboratorPosOrderPrintComponent extends BasePrintComponent<ComponentStateExtend, CollaboratorOrderModel> {
  // states: { [key: string]: State } = {};
  currentState: ComponentStateExtend;
  namespace = 'page-collaborator-pos-order-print';
  title = 'Đơn Hàng POS';
  path = '/page-collaborator-pos-order-print/:pageId/:id';
  idKey = 'Code';
  apiPath = '/collaborator/pos-orders';

  // unitList: UnitModel[] = [];
  // priceTableList: SalesMasterPriceTable[] = [];

  contactList: ContactModel[] = [];


  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
  ) {
    super(rootServices, commonService);
    // // console.log('Click here to open ts file');

    this.rootServices.platform.pause.subscribe(status => {
      // Auto save
      // this.currentState?.data?.Code && this.currentState?.data?.State != 'APPROVED' && this.currentState.instance.save();
    });
  }

  makeModel(properties?: CollaboratorOrderModel): CollaboratorOrderModel {
    return new CollaboratorOrderModel(properties);
  }

  async makeNewData() {
    return new CollaboratorOrderModel();
  }

  getPrintData(params?: any): Promise<CollaboratorOrderModel> {
    if(!params) params = {};
    params['page'] = this.currentState.instance.$route.params.pageId;
    params['includeDetails'] = true;
    return super.getPrintData({
      ...params,
    });
  }

  prepareData(data: CollaboratorOrderModel): CollaboratorOrderModel {
    if(data.Details) {
      for(const d in data.Details) {
        data.Details[d].ToMoney = data.Details[d].Quantity * data.Details[d].Price;
      }
    }
    return data;
  }

  onF7pageRemove(chatRoomId: string) {
    
  }

  async onComponentInit(state: ComponentStateExtend, index: string, asCase?: string, f7Page?: F7Page) {
    const $this = this;

    // Start loading status
    state.instance.$setState({
      loading: true,
      schema: this.schema,
    });
    this.commonService.showPreloader();

    // Load Contact Member list
    if (state?.instance?.$route?.context?.chatRoom?.memberList$) {
      await state?.instance?.$route?.context?.chatRoom?.memberList$?.pipe(take(1)).toPromise().then(memberList => {
        state.instance.contactList = memberList.filter(f => f.Type == 'CONTACT').map(m => { m['text'] = m.Name; return m; }) as any;
        if (state.instance.contactList && state.instance.contactList.length > 0) {
          this.schema['Object'].validators = [];
        } else {
          // this.schema['Object'].validators = [];
        }
      });

    }
    // End


    const currentState: ComponentStateExtend = await super.onComponentInit(state, index, asCase) as any;

    if (currentState.instance.$route?.context?.copyFromId) {
      currentState.instance.data.SubNote = 'Copy of ' + currentState.instance.$route?.context?.copyFromId + ': ' + (currentState.instance.data.SubNote ? ('<br>' + currentState.instance.data.SubNote) : '');
      this.setData(currentState.instance.data);
    }

    if (currentState?.instance?.$route?.context?.relativeVouchers && currentState.instance.$route.context.relativeVouchers[0]?.text) {
      if (!currentState.data.Title) {
        currentState.data.Title = currentState.instance.$route.context.relativeVouchers[0]?.text;
        currentState.instance.$setState({ data: currentState.data });
        // this.validate();
      }
    }
    // End Prepare data

    // Stop loading status
    currentState.instance.$setState({ loading: false });
    this.commonService.hidePreloader();
    return currentState;
  }

  async setData(data: CollaboratorOrderModel, option?: { onlyList?: string[]; onlyKeys?: string[]; prepareControl?: boolean; }): Promise<boolean> {
    if (data.State) {
      data.State = this.commonService.getObjectId(data.State);
    }

    return super.setData(data).then(status => {
      // this.currentState.instance.$setState({ total });

      // Update tabs

      return status;
    });
  }

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: this.namespace,
      path: this.path,
      component: {
        template: /*html*/`
            <div class="page page-form ${this.namespace} no-toolbar-x" data-name="${this.namespace}">
              <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a class="link back {{textColorClass}}">
                      <i class="icon icon-back"></i>
                      <span class="if-not-md {{textColorClass}}">{{backTitle}}</span>
                    </a>
                  </div>
                  <div class="title">${this.title}</div>
                  <div class="right">
                    <a class="link icon-only {{textColorClass}}">
                      <i class="icon f7-icons">arrowshape_turn_up_right</i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="page-content ptr-content infinite-scroll-content" @ptr:refresh="refresh" data-ptr-distance="150">
                <div class="ptr-preloader">
                  <div class="preloader"></div>
                  <div class="ptr-arrow"></div>
                </div>

                <div class="block block-strong inset">
                  ID: {{data.Code}}<br>
                  Title: {{data.Title}}<br>
                  Ngày bán: {{date data.DateOfSale 'short'}}<br>
                  Khách hàng: {{text data.Object}}<br>
                </div>

                <div class="block-title" style="margin-top: 0.5rem">Chi tiết</div>
                <div class="list-name thumbnail-list" name="Details">
                  {{#each data.Details}}
                    <div class="block block-strong inset list-item index-{{@index}} {{js "this.__hasWarning ? 'bg-color-orange' : (this.__active ? 'bg-color-red text-color-white' : '')"}}" data-id="{{Uuid}}" data-index="{{@index}}">
                      <div class="item-content">
                        <div class="detail-wrap">
                          <div class="detail-header" >
                            <div class="header-label">{{js "this.Description || this.Product && this.Product.Name"}}</div>
                            <div class="detail-remove"><i class="icon f7-icons {{js "this.__active ? 'color-white' : 'color-red'"}}">xmark_circle_fill</i></div>
                          </div>
                          <div class="detail-body">
                            <div class="image-wrap">
                              <div class="image" data-index="0" style="{{js "this.Image && this.Image.Thumbnail && ('background-image: url('+this.Image.Thumbnail+')') || ''"}}"></div>
                            </div>
                            <div class="detail-content-wrap">
                              <div class="list inline-labels no-hairlines">
                                <ul style="background: none">
                                  <li class="item-content item-input">
                                    <div class="item-inner" dtyle="min-height: 2rem; padding-top: 0; padding-bottom: 0">
                                      <div class="item-title item-label">SL</div>
                                      <div class="item-after">{{Quantity}}</div>
                                  </li>
                                  <li name="Unit">
                                    <div class="item-content">
                                      <div class="item-inner">
                                        <div class="item-title">ĐVT</div>
                                        <div class="item-after">{{text Unit}}</div>
                                      </div>
                                    </div>
                                  </li>
                                  <li class="item-content item-input">
                                    <div class="item-inner" dtyle="min-height: 2rem; padding-top: 0; padding-bottom: 0">
                                      <div class="item-title item-label">Giá</div>
                                      <div class="item-after">{{currency Price}}</div>
                                  </li>
                                  <li class="item-content item-input">
                                    <div class="item-inner" dtyle="min-height: 2rem; padding-top: 0; padding-bottom: 0">
                                      <div class="item-title item-label">T.Tiền</div>
                                      <div class="item-after">{{currency ToMoney}}</div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div class="detail-footer">SKU: {{js "this.Product && this.Product.Sku"}}, ID: {{js "this.Product && this.Product.Code"}}</div>
                        </div>
                      </div>
                    </div>
                    {{/each}}
                </div>

            
                <div class="block"><br></div>
              </div>
            </div>
        `,
        style: /*css*/`
          .${this.namespace} .demo-card-header-pic .card-header {
            height: calc(100vw - 2rem);
            background-size: cover;
            background-position: center;
            color: #fff;
          }

          .${this.namespace} .demo-card-header-pic .card-content-padding .date {
            color: #8e8e93;
          }
          .${this.namespace} .pictures::-webkit-scrollbar {
            display: none;
          }

          .${this.namespace} .card .background {
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-image: url(/assets/images/no-image-available.png);
          }

          .${this.namespace} .inline-labels .item-label, .inline-label .item-label, .inline-labels .item-floating-label, .inline-label .item-floating-label {
            width: auto !important;
          }
        `,
        data() {
          return {
            title: $this.title,
            data: new ContactModel(),
            validates: null,
          };
        },
        methods: {
          async refresh(e, done) {
            done && setTimeout(() => done(), 10000);
            $this.refresh(this).then(data => {
              done && done();
            });
            return true;
          },
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          pageInit(e, page: F7Page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;

            $this.onComponentInit({ instance: self, page: page }, self.$route.params['id'], null, page).then(curentState => {
              // self.detailDialog = self.$app.dialog.create({
              //   el: page.$el.find('.dialog-detail'),
              //   closeByBackdropClick: true,
              // });
            });

            $this.rootServices.authService.isAuthenticatedOrRefresh().pipe(take(1)).toPromise().then(() => {
              self.$setState({
                backTitle: self.$route.context && self.$route.context.backTitle || 'Back', title: self.$route.context && self.$route.context.title || 'Chat GUI',
              });
            }).catch(err => {
              console.error(err);
              $this.commonService.showError(err, { position: 'bottom' });
            });
          },
          // pageBeforeIn(e, page) {
          //     console.log('page before in');
          //     const self: F7ComponentContextExtend = this;
          //     self.refresh();
          // },
          // pageAfterIn(e, page) {
          //     console.log('page after in');
          // },
          pageBeforeOut(e, page) {
            console.log('page before out');
            const self: F7ComponentContextExtend = this;
            // Call caback function
            if (this.$route?.context['callback']) {
              this.$route?.context['callback']($this.currentState.data, { lastAction: $this.currentState.lastAction });
            }

            // Auto save
            // $this.currentState?.data?.Code && $this.currentState?.data?.State != 'APPROVED' && self.save();
          },
          // pageAfterOut(e, page) {
          //     console.log('page after out');
          // },
          // pageBeforeUnmount(e, page) {
          //     console.log('page before unmount');
          // },
          // pageBeforeRemove(e, page) {
          //     console.log('page before remove');
          // },
          pageBeforeRemove(e, page) {
            console.log('[page event] before remove', page.route.url);
            const self: F7ComponentContextExtend = this;
            // const starmtSelectRoles = self.$app.smartSelect.get('.smart-select');
            // starmtSelectRoles.destroy();
            const currentState = $this.state[self.$route.params.id];
            if (self.$route.params.id === 'new') {
              if (currentState && currentState.data && currentState.data.Code) {
                delete currentState.data;
              }
            }
          },
        },
      },
    };
  }
}
