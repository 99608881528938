import { Router } from 'framework7/build/core/modules/router/router';
import { Messages } from 'framework7/build/core/components/messages/messages';
import * as moment from 'moment';
import { Searchbar } from 'framework7/build/core/components/searchbar/searchbar';
import { isPlatform } from '@ionic/angular';
import { Dialog } from 'framework7/build/core/components/dialog/dialog';
import { DatePipe } from '@angular/common';
import { BaseComponent, ComponentState } from 'vendors/smart-bot-app/src/app/lib/base-component';
import { ChatRoomModel } from 'vendors/smart-bot-app/src/app/model/chat-room.model';
import { RootServices } from 'vendors/smart-bot-app/src/app/services/root.services';
import { CommonService } from 'vendors/smart-bot-app/src/app/services/common.service';
import { F7ComponentContext, F7Messagebar, F7Page } from 'src/app/types/framework7-types';
import { CollaboratorOrderModel } from 'src/app/model/collaborator.model';
import { CollaboratorStateMap } from 'vendors/smart-bot-app/src/app/components/collaborator/collaborator.state.map';

export interface ComponentStateExtend extends ComponentState {
    [key: string]: any;
}

export class F7ComponentContextExtend extends F7ComponentContext {
    messagebar?: F7Messagebar;
    messages?: Messages.Messages;
    images?: string[];
    responseInProgress?: boolean;
    answers?: string[];
    people?: { name?: string, avatar?: string }[];
    hideToolbar?: () => void;
    sheetToggle?: () => void;
    deleteAttachment?: (e: MouseEvent, index: number) => void;
    handleAttachment?: (e: MouseEvent) => void;
    checkAttachments?: () => void;
    sendMessage?: () => void;
    $root: any;
    searchBar?: Searchbar.Searchbar;
    orderList?: ChatRoomModel[];

    createChatRoomDialog?: Dialog.Dialog;
    filterDialog?: Dialog.Dialog;
    createWorkplaceDialog?: Dialog.Dialog;
    mode?: string;
    callback?: (task: ChatRoomModel) => void;

    refresh?: (e?: Event, done?: () => void) => Promise<boolean>;
}

/** Component group manager */
export class PageCollaboratorPosOrdersComponent extends BaseComponent<ComponentStateExtend> {
    // states: { [key: string]: State } = {};

    title = 'Danh sách đơn POS';

    constructor(
        public rootServices: RootServices,
        public commonService: CommonService,
        public datePipe: DatePipe,
    ) {
        super(rootServices);
        // // console.log('Click here to open ts file');
    }

    onF7pageRemove(chatRoomId: string) {
        // if (this.chatRoomCacheList[chatRoomId]) {
        //   this.chatRoomCacheList[chatRoomId].disconnect();
        // }
    }

    preapreTaskState(t: CollaboratorOrderModel) {
        // const unread = t.NumOfMessage - t.LoggedNumOfMessage - t.LoggedNumOfReadMessage;
        t.LastUpdate_Moment = moment(t.DateOfOrderText).fromNow();
        t.DateOfOrderText = this.datePipe.transform(t.DateOfOrder, 'short');
        // t.StateColor = t.State === 'APPROVED' ? 'green' : (t.State === 'COMPLETE' ? 'gray' : 'red');
        t.State = CollaboratorStateMap.orderStateMap[this.commonService.getObjectId(t.State)];
        // t.StateLabel = { 'OPEN': 'Mở', 'NEWSESSION': 'Mới', 'ACCEPT': 'Tiếp nhận', 'CLOSE': 'Đóng', 'COMPLETE': 'Hoàn tất', 'CANCEL': 'Hủy' }[t.State];
        // if (t.State in ['OPEN', 'NEWSESSION']) {
        //     t.ActionMemberName = t.OpenByMemberName;
        // }
        // if (t.State === 'ACCEPT') {
        //     t.ActionMemberName = t.AcceptByMemberName;
        // }
        // if (t.State === 'CLOSE') {
        //     t.ActionMemberName = t.CloseByMemberName;
        // }
        // if (t.State === 'COMPLETE') {
        //     t.ActionMemberName = t.CompleteByMemberName;
        // }
        // if (t.State === 'CANCEL') {
        //     t.ActionMemberName = t.CancelByMemberName;
        // }
        // t.NumOfUnreadMessage = unread > 0 ? unread : '';
        // t.StateText = CollaboratorStateMap.orderStateMap[this.commonService.getObjectId(t.State)];
        return t;
    }

    get f7Component(): Router.RouteParameters {
        const $this = this;
        return {
            name: 'page-collaborator-pos-orders',
            path: '/page-collaborator-pos-orders/:pageId',
            component: {
                template: /*html*/`
                <div class="page">
                    <div class="navbar">
                        <div class="navbar-bg"></div>
                        <div class="navbar-inner sliding">
                            <div class="left">
                                <a class="link back {{textColorClass}}">
                                    <i class="icon icon-back"></i>
                                    <span class="if-not-md {{textColorClass}}">{{backTitle}}</span>
                                </a>
                            </div>
                            <div class="title text-color-default">{{title}}</div>
                            <div class="right">
                                <!-- Link to enable searchbar -->
                                <a class="link icon-only searchbar-enable text-color-default" data-searchbar=".workplace-searchbar">
                                    <i class="icon f7-icons if-not-md">search</i>
                                    <i class="icon material-icons md-only">search</i>
                                </a>
                            </div>
                            <!-- Searchbar is a direct child of "navbar-inner" -->
                            <form class="searchbar workplace-searchbar searchbar-expandable">
                                <div class="searchbar-inner">
                                    <div class="searchbar-input-wrap">
                                        <input type="search" placeholder="#thẻ/@thành viên/+tin nhắn/tiêu đề" />
                                        <i class="searchbar-icon"></i>
                                        <span class="input-clear-button"></span>
                                    </div>
                                    <span class="searchbar-disable-button text-color-default">Cancel</span>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="page-content ptr-content infinite-scroll-content" data-ptr-mousewheel="false" @ptr:refresh="refresh">
                        <div class="ptr-preloader">
                            <div class="preloader"></div>
                            <div class="ptr-arrow"></div>
                        </div>
                        <div class="searchbar-backdrop workplace-searchbar-backdrop"></div>
                
                        
                        <!--<div class="block inset block-strong page-banner bg-color-gray{{js "!this.pageInfo ? ' skeleton-text skeleton-effect-blink' : ''"}}" style="background-image: url({{js "this.pageInfo && this.pageInfo.Banner && this.pageInfo.Banner.OriginImage"}});"></div>-->
                        <div class="block inset block-strong page-bannerx {{js "!this.pageInfo ? ' skeleton-text skeleton-effect-blink' : ''"}}" style="padding: 0; overflow: hidden">
                            <img src="{{js "this.pageInfo && this.pageInfo.Banner && this.pageInfo.Banner.OriginImage"}}" style="width: 100%; margin-bottom: -6px">
                        </div>
                        <div class="block block-strong inset{{js "!this.pageInfo ? ' skeleton-text skeleton-effect-blink' : ''"}}">
                            <div class="page-header">
                                <div class="logo bg-color-gray" style="background-image: url({{js "this.pageInfo && this.pageInfo.Logo && this.pageInfo.Logo.OriginImage"}});"></div>
                                <div class="info">
                                    <div class="name">{{js "this.pageInfo && this.pageInfo.Name"}}</div>
                                    <div class="summary">{{js "this.pageInfo && this.pageInfo.Summary"}}</div>
                                    <div class="tag">@{{js "this.pageInfo && this.pageInfo.TagName"}}</div>
                                </div>
                            </div>
                        </div>
                
                        <div class="block-title">Đơn hàng POS</div>
                        {{#js_if "this.orderList"}}
                        {{#js_if "this.orderList.length > 0"}}
                        <div class="list media-list workplace-list inset">
                            <ul>
                                {{#each orderList}}
                                <li data-id="{{Code}}" data-chat-room="{{Code}}" @click="openVoucher">
                                    <a class="item-link item-content">
                                        <div class="item-inner">
                                            <div class="item-title-row">
                                                <div class="item-title text-color-default description" style="font-size: 0.9rem">
                                                    {{Title}}</div>
                                                
                                            </div>
                                            <div class="item-subtitle text-color-gray">
                                                <div class="chat-list-item-label">
                                                    <i class="icon f7-icons">person_crop_square_fill</i>
                                                    Số phiếu: {{Code}}
                                                </div>
                                                <div class="text-color-blue lastUpdateMoment" style="float: right"><span
                                                class="badge color-{{State.color}} unread">{{text State}}</span>
                                                </div>
                                            </div>
                                            <div class="item-title-row">
                                                <div class="item-subtitle text-color-gray">
                                                    <div class="chat-list-item-label"><i
                                                            class="icon f7-icons">timer_fill</i>{{date DateOfSale 'short'}}
                                                    </div>
                                                </div>
                                                <div class="item-after text-color-blue" style="font-size: 0.8rem">{{moment DateOfSale}}</div>
                                            </div>
                                            {{#if Title}}<div class="item-text"><i class="icon f7-icons">tickets_fill</i><span
                                                    class="description">{{Title}}</span></div>{{/if}}
                                            {{#if LastMessageText}}<div class="item-text chat-list-item-label"><i
                                                    class="icon f7-icons">captions_bubble_fill</i><span
                                                    class="lastMessageUserName">{{ObjectName}}</span></div>{{/if}}
                                            {{#if ObjectName}}<div class="item-text chat-list-item-label"><i
                                                class="icon f7-icons">person_crop_circle</i><span
                                                class="lastMessageUserName">KH: {{ObjectName}}</span></div>{{/if}}
                                            <div class="item-text" style="clear: both; max-height: initial;"><span
                                                    class="lastMessageText">
                                                    {{#each Tags}}
                                                    <div class="chip">
                                                        <div class="chip-label text-color-gray" style="font-weight: bold">#{{this}}
                                                        </div>
                                                    </div>
                                                    {{/each}}
                                                </span></div>
                                        </div>
                                    </a>
                                </li>
                                {{/each}}
                            </ul>
                            {{#if infiniteLoadLast}}    
                            <div style="text-align: center" class="text-color-gray">end</div>
                            {{else}}
                            <div class="block text-align-center"><div class="preloader"></div></div>
                            {{/if}}
                        </div>
                        {{else}}
                        <div class="block block-strong inset bg-color-orange text-color-white">
                            Chưa có đơn hàng nào !<br>
                            <a href="https://block.probox.vn/don-hang-dau-tien">Link</a> hướng dẫn bán đơn hàng đầu tiên<br>
                        </div>
                        {{/js_if}}
                        {{else}}
                        <div class="block-title">Đơn hàng</div>
                        <div class="list media-list workplace-list inset">
                            <ul>
                                {{#each ghostItems}}
                                <li class="skeleton-text skeleton-effect-blink">
                                    <a class="item-link item-content">
                                        <div class="item-inner">
                                            <div class="item-title-row">
                                                <div class="item-title text-color-default description" style="font-size: 0.9rem"> lobortis lobortis lobortis lobortis lobortis lobortis lobortis</div>
                                                <div class="item-after text-color-blue" style="font-size: 0.8rem">
                                                    <span class="badge color-green unread">9</span>
                                                </div>
                                            </div>
                                            <div class="item-subtitle text-color-gray">
                                                <div class="chat-list-item-label"><i class="icon f7-icons">timer_fill</i>lobortis lobortis lobortis</div>
                                            </div>
                                            <div class="item-subtitle text-color-gray">
                                                <div class="chat-list-item-label">
                                                    <i class="icon f7-icons">person_crop_square_fill</i>lobortis lobortis
                                                </div>
                                                <div class="text-color-blue lastUpdateMoment" style="float: right">lobortis&nbsp;₫
                                                </div>
                                            </div>
                                            <div class="item-text" style="clear: both; max-height: initial;">
                                                <span class="lastMessageText"><div class="chip"><div class="chip-label text-color-gray" style="font-weight: bold">lobortis lobortis</div></div></span>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                {{/each}}
                            </ul>
                        </div>
                        {{/js_if}}
                    </div>
                
                    <div class="dialog dialog-wrap dialog-create-chat-room">
                        <div class="dialog-padding">
                            <div class="dialog dialog-inside">
                                <div class="dialog-inner" style="padding-bottom: 0;">
                                    <div class="dialog-title">Workplace</div>
                                    <div class="dialog-text" style="width: 100%; user-select: initial; text-align: center;">Tạo phòng
                                        tương tác nội bộ</div>
                                    <br>
                                    <div class="list media-list"
                                        style="margin-left: -15px; margin-right: -15px; margin-bottom: 0; margin-top: 0; text-align: left;">
                                        <ul>
                                            <li class="item-content item-input">
                                                <div class="item-media"><i
                                                        class="icon f7-icons text-color-default">chat_bubble_text_fill</i></div>
                                                <div class="item-inner">
                                                    <div class="item-title item-label">Mô tả (*)</div>
                                                    <div class="item-input-wrap">
                                                        <textarea class="field" name="Description" type="text"
                                                            placeholder="Mô tả/tin nhắn đầu tiên"></textarea>
                                                        <span class="input-clear-button"></span>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="dialog-buttons">
                                    <span class="dialog-button color-red closeBtn" @click="closeCreateChatRoomDialog">Đóng</span>
                                    <span class="dialog-button createReferenceChatRoomBtn" @click="createChatRoom">Tạo phòng</span>
                                </div>
                            </div>
                        </div>
                    </div>
                
                    <div class="dialog dialog-wrap dialog dialog-filter">
                        <div class="dialog-padding">
                            <div class="dialog dialog-inside">
                                <div class="dialog-inner" style="padding-bottom: 0;">
                                    <div class="dialog-title">Helpdesk</div>
                                    <div class="block-title" style="margin-top: 1rem;">Bộ lọc yêu cầu khách hàng</div>
                                    <div class="list simple-list"
                                        style="margin-left: -15px; margin-right: -15px; margin-bottom: 0; margin-top: 0; text-align: left;">
                                        <ul>
                                            <li>
                                                <span>Chưa tiếp nhận</span>
                                                <label class="toggle toggle-init color-black">
                                                    <input class="field" name="filter_State" value="[OPEN,NEWSESSION]" type="checkbox">
                                                    <span class="toggle-icon"></span>
                                                </label>
                                            </li>
                                            <li>
                                                <span>Chưa hoàn thành</span>
                                                <label class="toggle toggle-init color-blue">
                                                    <input class="field" name="filter_State" value="ACCEPT" type="checkbox">
                                                    <span class="toggle-icon"></span>
                                                </label>
                                            </li>
                                            <li>
                                                <span>Đã hủy</span>
                                                <label class="toggle toggle-init color-red">
                                                    <input class="field" name="filter_State" value="CANCEL" type="checkbox">
                                                    <span class="toggle-icon"></span>
                                                </label>
                                            </li>
                                            <li>
                                                <span>Chưa đọc</span>
                                                <label class="toggle toggle-init color-green">
                                                    <input class="field" name="filter_UnreadState" value="true" type="checkbox">
                                                    <span class="toggle-icon"></span>
                                                </label>
                                            </li>
                                            <li>
                                                <span>Quan trọng</span>
                                                <label class="toggle toggle-init color-orange">
                                                    <input class="field" name="filter_ImportantFlag" value="true" type="checkbox">
                                                    <span class="toggle-icon"></span>
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="dialog-buttons">
                                    <span class="dialog-button color-red closeBtn" @click="closeFilterDialog">Đặt lại</span>
                                    <span class="dialog-button createReferenceChatRoomBtn" @click="applyFilter">Áp dụng</span>
                                </div>
                            </div>
                        </div>
                    </div>
                
                    <div class="dialog dialog-wrap dialog-create-workplace-dialog">
                        <div class="dialog-padding">
                            <div class="dialog dialog-inside">
                                <div class="dialog-inner" style="padding-bottom: 0;">
                                    <div class="dialog-title">Workplace</div>
                                    <div class="dialog-text" style="width: 100%; user-select: initial; text-align: center;">Tạo task
                                    </div>
                                    <br>
                                    <div class="list media-list"
                                        style="margin-left: -15px; margin-right: -15px; margin-bottom: 0; margin-top: 0; text-align: left;">
                                        <ul>
                                            <li class="item-content item-input">
                                                <div class="item-media"><i
                                                        class="icon f7-icons text-color-default">chat_bubble_text_fill</i></div>
                                                <div class="item-inner">
                                                    <div class="item-title item-label">Mô tả (*)</div>
                                                    <div class="item-input-wrap">
                                                        <textarea class="field" name="Description" type="text"
                                                            placeholder="Mô tả/tin nhắn đầu tiên"></textarea>
                                                        <span class="input-clear-button"></span>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="dialog-buttons">
                                    <span class="dialog-button color-red closeBtn" @click="closeCreateWorkplaceDialog">Đóng</span>
                                    <span class="dialog-button createReferenceChatRoomBtn" @click="createWorkplace">Tạo task</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              `,
                style: ``,
                data() {
                    const self: F7ComponentContextExtend = this;
                    return {
                        title: self.title || $this.title,
                        backTitle: 'Back',
                        orderList: null,
                        infiniteLoadLast: true,
                        disabledLeftPannel: false && isPlatform('desktop'),
                        lastUpdateMoment: moment(Date.now()).fromNow(),
                        filter: null,
                        mode: '',
                        unreadFilterStatus: false,
                        // pageInfo: {},
                        ghostItems: [1, 2],
                        pageId: null,
                    };
                },
                methods: {
                    createNewOrder() {
                        const self: F7ComponentContextExtend = this;
                        const pageId = self.$route?.params?.pageId;
                        $this.rootServices.navigate(`/page-collaborator-order/${pageId}/new`, {
                            context: {
                                pageId: pageId,
                                callback: (data: CollaboratorOrderModel, state: any) => {
                                    console.log('Callback data: ', data);
                                    if (state?.lastAction == 'CREATE_SUCCESS') {
                                        self.refresh(null, () => { });
                                    }
                                    if (state?.lastAction == 'UPDATE_SUCCESS') {
                                        self.refresh(null, () => { });
                                    }
                                }
                            }
                        });
                    },
                    toggleUnreadFilter(e) {
                        const self: F7ComponentContextExtend = this;
                        const unreadCheckbox = self.filterDialog.$el.find('.field[name="filter_UnreadState"]');
                        unreadCheckbox.click();
                        self.applyFilter(e);
                        self.$setState({ unreadFilterStatus: unreadCheckbox[0] && unreadCheckbox[0]['checked'] });
                    },
                    openFilterDialog(e) {
                        const self: F7ComponentContextExtend = this;
                        self.filterDialog && self.filterDialog.open();
                        console.log('Current filter: ', self.filter);
                    },
                    closeFilterDialog(e) {
                        const self: F7ComponentContextExtend = this;
                        if (self.filterDialog) {
                            const fieldEles = self.filterDialog.$el.find('.field');
                            fieldEles.each((index, el) => {
                                self.$(el).prop('checked', false);
                            });
                            self.$setState({ filter: null });
                            self.filterDialog.close();
                            self.refresh(null, () => { });
                        }
                    },
                    applyFilter(e) {
                        const self: F7ComponentContextExtend = this;
                        self.filterDialog && self.filterDialog.close();
                        const fieldEles = self.filterDialog.$el.find('.field');
                        console.log(fieldEles);
                        const filter: { [key: string]: any } = {};
                        fieldEles.each((index, el) => {
                            console.log(index, el.checked);
                            if (el.checked) {
                                filter[el.name] = el.value;
                            }
                        });
                        console.log(filter);
                        self.$setState({ filter: Object.keys(filter).length > 0 ? filter : null });
                        self.refresh(null, () => { });
                    },
                    closeCreateChatRoomDialog() {
                        const self: F7ComponentContextExtend = this;
                        self.createChatRoomDialog && self.createChatRoomDialog.close();
                    },
                    createChatRoom(e: MouseEvent) {
                        const self: F7ComponentContextExtend = this;
                        const chatRoomData: ChatRoomModel = {};
                        const fieldEles = self.createChatRoomDialog.$el.find('.field');
                        fieldEles.each((index, fieldEle) => {
                            console.log(fieldEle);
                            console.log(fieldEle.value);
                            chatRoomData[fieldEle.name] = fieldEle.value;
                        });
                        if (!chatRoomData.Description) {
                            console.error('Bạn chưa điền mô tả');
                            $this.commonService.showError('Bạn chưa điền mô tả');
                            return;
                        }
                        console.log(chatRoomData);
                        self.$app.preloader.show();
                        try {
                            $this.rootServices.apiService.postPromise<ChatRoomModel[]>('/chat/rooms', {}, [chatRoomData]).then(rs => {
                                console.log(rs);
                                if (rs && rs[0] && rs[0].Code) {
                                    $this.commonService.navigate('/chat-room/' + rs[0].Code, {
                                        context: {
                                            backTitle: 'Workplace',
                                            title: rs[0].Description,
                                        }
                                    });
                                    self.createChatRoomDialog.close();
                                    fieldEles.each((index, fieldEle) => {
                                        fieldEle.value = null;
                                        self.$(fieldEle).change();
                                    });
                                    self.$app.preloader.hide();
                                }
                            }).catch(err => {
                                console.error(err);
                                self.$app.preloader.hide();
                                $this.commonService.showError(err);
                            });
                        } catch (err) {
                            console.error(err);
                            self.$app.preloader.hide();
                            $this.commonService.showError(err);
                        }
                    },
                    openCreateTicketDialog(e: MouseEvent) {
                        const self: F7ComponentContextExtend = this;
                        if (self.createChatRoomDialog) {
                            self.createChatRoomDialog.open();
                            self.createChatRoomDialog.$el.find('.field[name="Description"]').focus();
                        }
                    },
                    openCreateWorkplaceDialog(e: MouseEvent) {
                        const self: F7ComponentContextExtend = this;
                        if (self.createWorkplaceDialog) {
                            self.createWorkplaceDialog.open();
                            self.createWorkplaceDialog.$el.find('.field[name="Description"]').focus();
                        }
                    },
                    closeCreateWorkplaceDialog() {
                        const self: F7ComponentContextExtend = this;
                        self.createWorkplaceDialog && self.createWorkplaceDialog.close();
                    },
                    createWorkplace(e: MouseEvent) {
                        const self: F7ComponentContextExtend = this;
                        const chatRoomData: ChatRoomModel = {};
                        const fieldEles = self.createWorkplaceDialog.$el.find('.field');
                        fieldEles.each((index, fieldEle) => {
                            console.log(fieldEle);
                            console.log(fieldEle.value);
                            chatRoomData[fieldEle.name] = fieldEle.value;
                        });
                        if (!chatRoomData.Description) {
                            console.error('Bạn chưa điền mô tả');
                            $this.commonService.showError('Bạn chưa điền mô tả');
                            return;
                        }
                        chatRoomData.Type = 'WORKPLACE';
                        chatRoomData.State = 'OPEN';
                        console.log(chatRoomData);
                        self.$app.preloader.show();
                        try {
                            $this.rootServices.apiService.postPromise<ChatRoomModel[]>('/chat/rooms', {}, [chatRoomData]).then(rs => {
                                console.log(rs);
                                if (rs && rs[0] && rs[0].Code) {
                                    $this.commonService.navigate('/chat-room/' + rs[0].Code, {
                                        context: {
                                            backTitle: 'Workplace',
                                            title: rs[0].Description,
                                        }
                                    });
                                    self.createWorkplaceDialog.close();
                                    fieldEles.each((index, fieldEle) => {
                                        fieldEle.value = null;
                                        self.$(fieldEle).change();
                                    });
                                    self.$app.preloader.hide();
                                }
                            }).catch(err => {
                                console.error(err);
                                self.$app.preloader.hide();
                                $this.commonService.showError(err);
                            });
                        } catch (err) {
                            console.error(err);
                            self.$app.preloader.hide();
                            $this.commonService.showError(err);
                        }
                    },
                    async refresh(e, done) {
                        const self: F7ComponentContextExtend = this;
                        // Refresh whene overtime 5 minutes
                        try {
                            let doneTimeout = null;
                            if (done) {
                                doneTimeout = setTimeout(() => {
                                    done();
                                }, 10000);
                            };
                            if (done || ($this.rootServices.authService.loginState$.getValue() && (!self.orderList || self.orderList.length === 0 || !self.lastUpdate || self.lastUpdate + 300000 < Date.now()))) {
                                console.log('refresh chat room list');
                                // return;
                                self.orderList = await $this.rootServices.apiService.getPromise<any[]>('/collaborator/pos-orders', {
                                    search: encodeURIComponent(self.searchBar.query),
                                    sort_Id: 'desc',
                                    page: self.pageInfo?.Code,
                                    limit: 10,
                                    offset: 0,
                                    ...self.filter,
                                }).catch(err => {
                                    if (done) done();
                                    if (doneTimeout) clearTimeout(doneTimeout);
                                    return Promise.reject(err);
                                });
                                if (doneTimeout) clearTimeout(doneTimeout);
                                self.$setState({
                                    orderList: self.orderList.map(t => {
                                        // Moment.now()
                                        // const unread = t.NumOfMessage - t.LoggedNumOfMessage - t.LoggedNumOfReadMessage;
                                        // t.LastUpdate_Moment = moment(t.LastUpdate).fromNow();
                                        // t.DateOfCreate = new Date(t.DateOfCreate).toLocaleString();
                                        // t.Infos.Request_Date_Time = new Date(t.Infos.Request_Date_Time).toLocaleTimeString();
                                        $this.preapreTaskState(t);
                                        return t;
                                    }), lastUpdate: Date.now()
                                });
                                self.infiniteOffset = 10;

                                self.$setState({ infiniteLoadLast: self.orderList.length === 0 });
                                if (done) done();

                                self.updateBadge();
                            } else {
                                self.$setState({
                                    lastUpdateMoment: moment(self.lastUpdate).fromNow(),
                                });
                            }
                            return true;
                        } catch (err) {
                            $this.commonService.showError(err);
                        }
                    },
                    async openVoucher(e) {
                        const self: F7ComponentContextExtend = this;
                        const orderId = self.$(e.target).closest('li').data('id');
                        $this.commonService.navigate(`/page-collaborator-pos-order-print/${self.$route.params?.pageId}/${orderId}`, {
                            context: {
                                pageInfo: self.pageInfo,
                                callback: (data: CollaboratorOrderModel, state: any) => {
                                    console.log('Callback data: ', data);
                                    if (state?.lastAction == 'CREATE_SUCCESS') {
                                        self.refresh(null, () => { });
                                    }
                                    if (state?.lastAction == 'UPDATE_SUCCESS') {
                                        self.refresh(null, () => { });
                                    }
                                }
                            }
                        });

                    },
                    updateBadge(e) {
                        const self: F7ComponentContextExtend = this;
                        let unreadCount = 0;
                        if (self.orderList) {
                            for (const cahtRoom of self.orderList) {
                                if (cahtRoom.NumOfUnreadMessage) {
                                    unreadCount += parseInt(cahtRoom.NumOfUnreadMessage);
                                }
                            }
                            $this.rootServices.workplaceBadge = unreadCount;
                        }
                    },
                },
                on: {
                    pageInit(e, page: F7Page) {
                        console.log('page init');
                        const self: F7ComponentContextExtend = this;
                        if (self.$route.params['id']) {
                            self.mode = self.$route.params['id'];
                        }
                        const pageId = self.$route.params?.pageId;
                        $this.onComponentInit({ instance: self }, 'main').then(async currentState => {
                            // Init create chat room dialog
                            self.createChatRoomDialog = self.$app.dialog.create({
                                el: page.$el.find('.dialog-create-chat-room'),
                                closeByBackdropClick: true,
                            });
                            self.createWorkplaceDialog = self.$app.dialog.create({
                                el: page.$el.find('.dialog-create-workplace-dialog'),
                                closeByBackdropClick: true,
                            });
                            self.filterDialog = self.$app.dialog.create({
                                el: page.$el.find('.dialog-filter'),
                                closeByBackdropClick: true,
                            });
                            self.filterDialog.$el.find('.field').each((index, field) => {
                                self.$(field).change((e: Event) => {
                                    console.log(e);
                                    if (e.target['name'] === 'filter_State' && e.target['checked']) {
                                        self.filterDialog.$el.find('.field[name="filter_State"]').filter((index, el) => el.value !== e.target['value']).prop('checked', false);
                                    }
                                });
                            });
                            const pageInfo = self.pageInfo || await $this.rootServices.apiService.getPromise<any[]>('/collaborator/pages/' + pageId, {}).then(rs => rs[0]);
                            self.$setState({ pageInfo: pageInfo, pageId: pageId });
                        });

                        // Loading flag
                        let allowInfinite = true;
                        // Last loaded index
                        var lastItemIndex = self.$$('.list li').length;

                        // Max items to load
                        var maxItems = 200;

                        // Append items per load
                        var itemsPerLoad = 20;

                        self.infiniteOffset = 0;

                        self.searchBar = self.$app.searchbar.create({
                            el: '.workplace-searchbar',
                            backdrop: true,
                            backdropEl: '.workplace-searchbar-backdrop',
                            searchContainer: '.list',
                            searchIn: '.item-text',
                            customSearch: true,
                            on: {
                                search(sb, query, previousQuery) {
                                    $this.commonService.takeUntil('heldpesk-ticket-search', 600).then(() => {
                                        console.log(query, previousQuery);
                                        $this.rootServices.apiService.getPromise<any[]>('/collaborator/pos-orders', {
                                            search: encodeURIComponent(query),
                                            sort_Id: 'desc',
                                            page: self.pageInfo?.Code,
                                            limit: 10,
                                            offset: 0,
                                            ...self.filter,
                                        }).then(orderList => {
                                            self.$$(page.el).find('.page-content').scrollTo(0, 0);
                                            self.$setState({ orderList: orderList.map(t => $this.preapreTaskState(t)), infiniteLoadLast: orderList.length === 0 });
                                            self.infiniteOffset = 0;
                                        }).catch(err => {
                                            console.error(err);
                                            $this.commonService.showError(err);
                                        });
                                    });
                                }
                            }
                        });

                        // Attach 'infinite' event handler
                        self.$$(page.el).find('.infinite-scroll-content').on('infinite', function () {
                            // Exit, if loading in progress
                            if (!allowInfinite) return;

                            // Set loading flag
                            allowInfinite = false;

                            // Emulate 1s loading
                            $this.rootServices.apiService.getPromise<any[]>('/collaborator/pos-orders', {
                                search: self.searchBar.query,
                                sort_Id: 'desc',
                                page: self.pageInfo?.Code,
                                limit: 10,
                                offset: self.infiniteOffset,
                                ...self.filter,
                            }).then(orderList => {
                                orderList.map(t => {
                                    $this.preapreTaskState(t);
                                    return t;
                                });
                                self.$setState({ orderList: self.orderList.concat(orderList), infiniteLoadLast: orderList.length === 0 });
                                self.infiniteOffset += 10;
                                lastItemIndex = self.$$('.list li').length;
                                allowInfinite = true;

                                self.updateBadge();
                            }).catch(err => {
                                console.error(err);
                                $this.commonService.showError(err);
                            });
                        });

                        // if (page.router.history && page.router.history[page.router.history.length - 1] === page.route.url) {
                        //     self.refresh();
                        // }
                    },
                    pageAfterIn(e, page) {
                        console.log('p[page event]age after in', page.route.url);
                        const self: F7ComponentContextExtend = this;
                        $this.onChangedState({ instance: self, page }, 'main', 'before-in').then(currentState => {
                            self.$setState({
                                lastUpdateMoment: moment(self.lastUpdate).fromNow(),
                            });
                        });
                    },
                    pageBeforeIn(e, page: F7Page) {
                        console.log('[page event] before in', page.route.url);
                        const self: F7ComponentContextExtend = this;

                        if ($this.rootServices.changedChatRooms.workplace.length > 0) {
                            const updatedRooms = [... new Set($this.rootServices.changedChatRooms.workplace)];
                            $this.rootServices.apiService.getPromise<ChatRoomModel[]>('/chat/rooms', { id: updatedRooms }).then(chatRoomInfos => {
                                const newChatRooms = self.orderList.map(oldChatRoom => {
                                    const newChatRoom = chatRoomInfos.find(f => f.Code === oldChatRoom.Code);
                                    if (newChatRoom) {
                                        // newChatRoom.LastUpdate_Moment = moment(newChatRoom.LastUpdate).fromNow();
                                        // newChatRoom.DateOfCreate = new Date(newChatRoom.DateOfCreate).toLocaleString();
                                        // const unread = newChatRoom.NumOfMessage - newChatRoom.LoggedNumOfMessage - newChatRoom.LoggedNumOfReadMessage;
                                        // newChatRoom.NumOfUnreadMessage = unread > 0 ? unread : '';

                                        $this.preapreTaskState(newChatRoom);
                                        return newChatRoom;
                                    }
                                    return oldChatRoom;
                                });
                                self.$setState({
                                    orderList: newChatRooms,
                                });
                                $this.rootServices.changedChatRooms.workplace = [];

                                self.updateBadge();
                            }).catch(err => {
                                console.error(err);
                                $this.commonService.showError(err);
                            });
                        }
                    },
                }
            },
        };
    }
}
