import { F7ComponentContext, F7Page } from '../types/framework7-types';
import { Router } from 'framework7/build/core/modules/router/router';
import { BaseComponent, ComponentState } from '../lib/base-component';
import { RootServices } from '../services/root.services';
import { Attachment } from '../lib/nam-chat/model/message';
import { ChatRoom } from '../lib/nam-chat/chat-room';
import { Member } from '../lib/nam-chat/model/member';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { User } from '../lib/nam-chat/model/user';
import { ChatRoomModel } from '../model/chat-room.model';
import { CommonService } from '../services/common.service';
import { Dialog } from 'framework7/build/core/components/dialog/dialog';
import { TaskActivityModel, TaskReminderModel } from '../model/task.model';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { isPlatform } from '@ionic/angular';
import { Coordinates } from '@ionic-native/geolocation/ngx';
import { AuthUser } from '../services/auth.service';
// import * as AutoNumeric from 'autonumeric';
// import { Options } from 'autonumeric';

declare const AutoNumeric;

export interface ComponentStateExtend extends ComponentState {
  [key: string]: any;
  chatRoom?: ChatRoom;
  roles?: { [key: string]: { id: string, text: string } };
  referenceChatRooms?: ChatRoomModel[];
  recferenceChatRoomsLastUpdate?: number;
}

export class F7ComponentContextExtend extends F7ComponentContext {
  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, chatRoom?: ChatRoom } };

  notificationSettinsDialog?: Dialog.Dialog;
  addPaymentRequestDialog?: Dialog.Dialog;
  checkInCheckoutDialog?: Dialog.Dialog;
  addReminderDialog?: Dialog.Dialog;
  filterDialog?: Dialog.Dialog;
  quickReportDialog?: Dialog.Dialog;
  activitiesList$?: BehaviorSubject<TaskActivityModel[]>;

  refresh?: (e?: Event, done?: () => void) => Promise<boolean>;
}

/** Component group manager */
export class TaskActivitiesComponent extends BaseComponent<ComponentStateExtend> {
  // states: { [key: string]: State } = {};

  title = 'Task Activities';
  types = {
    PAYMENTREQUEST: 'Yêu cầu chi',
    INFO: 'Thông tin',
    FINALSETTLEMENTREQUEST: 'Y/C Tất toán',
    CHECKIN: 'Check-In',
    CHECKOUT: 'Check-Out',
    TIMEATTENDANCE: 'Chấm công',
  };

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
    public datePipe: DatePipe,
  ) {
    super(rootServices);
    // // console.log('Click here to open ts file');
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  convertChatRoomTreeToArray(tree: ChatRoomModel[], level?: number) {
    let chatRoomList: (ChatRoomModel & { Level?: number, IsCurrent?: boolean })[] = [];
    for (const chatRoom of tree) {
      chatRoomList.push({ ...chatRoom, IsCurrent: chatRoom.Code === this.currentState.chatRoom.id, Level: level || 0 });
      if (chatRoom.Children && chatRoom.Children.length > 0) {
        chatRoomList = chatRoomList.concat(this.convertChatRoomTreeToArray(chatRoom.Children, (level || 0) + 1));
      }
    }
    return chatRoomList;
  }

  async onComponentInit(state: ComponentStateExtend, index: string, asCase?: string) {
    const currentState = await super.onComponentInit(state, index, asCase);
    if (state.chatRoom) {
      this.currentState.chatRoom = this.state[index].chatRoom = state.chatRoom;
    }
    currentState.roles = {
      CHATROOMMANAGER: {
        id: 'CHATROOMMANAGER',
        text: 'Quản lý',
      },
      HELPDESK: {
        id: 'HELPDESK',
        text: 'Hỗ trợ',
      },
      COORDINATORS: {
        id: 'COORDINATORS',
        text: 'Điều phối',
      },
      ITHELPDESK: {
        id: 'ITHELPDESK',
        text: 'Hỗ trợ IT',
      },
      SALESHELPDESK: {
        id: 'SALESHELPDESK',
        text: 'Hỗ trợ bán hàng',
      },
      ITCOORDINATORS: {
        id: 'ITCOORDINATORS',
        text: 'Điều phối IT',
      },
      SALESCOORDINATORS: {
        id: 'SALESCOORDINATORS',
        text: 'Điều phối bán hàng',
      },
      CUSTOMER: {
        id: 'CUSTOMER',
        text: 'Khách hàng',
      },
      CHATROOMMEMBER: {
        id: 'CHATROOMMEMBER',
        text: 'Thành viên',
      },
      HELPDESKCREATOR: {
        id: 'HELPDESKCREATOR',
        text: 'Người tạo yêu cầu',
      },
      CHATROOMCREATOR: {
        id: 'CHATROOMCREATOR',
        text: 'Người tạo phòng chat',
      },
      CONTACT: {
        id: 'CONTACT',
        text: 'Liên hệ',
      },
      MONITORING: {
        id: 'MONITORING',
        text: 'Giám sát',
      },
    };

    // Load reference chat rooms
    if (!currentState.recferenceChatRoomsLastUpdate || currentState.recferenceChatRoomsLastUpdate + 60000 < Date.now()) {
      const chatRoomTree = await this.rootServices.apiService.getPromise<ChatRoomModel[]>('/chat/rooms', { referenceChatRoom: currentState.chatRoom.id });
      currentState.referenceChatRooms = this.convertChatRoomTreeToArray(chatRoomTree);
      currentState.recferenceChatRoomsLastUpdate = Date.now();
    }
    if (currentState.referenceChatRooms) {
      currentState.instance.$setState({
        referenceChatRooms: currentState.referenceChatRooms,
      });
    }

    return currentState;
  }

  // async browseAttachment(attachmentId: string) {
  //   const attachments = this.currentState.chatRoom.attachments;

  //   // Check attachments
  //   if (!attachments || attachments.length === 0) {
  //     return false;
  //   }

  //   // find index
  //   const index = attachments.findIndex(f => f.id === attachmentId);

  //   const myPhotoBrowserPopupDark = this.currentState.instance.$app.photoBrowser.create({
  //     photos: attachments.map(att => ({
  //       url: (/^image/.test(att.type) ? att.payload.url : att.payload.thumbnail) + '?token=' + this.rootServices.apiService.token.access_token,
  //       caption: att.description,
  //     })),
  //     theme: 'dark',
  //     type: 'standalone' as any,
  //   });
  //   myPhotoBrowserPopupDark.open(index);
  //   return new Promise<void>(resolve => {
  //     myPhotoBrowserPopupDark.on('closed', () => {
  //       myPhotoBrowserPopupDark.destroy();
  //       resolve();
  //     });
  //   });
  // }

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: 'task-activities',
      path: '/task-activities',
      component: {
        template: /*html*/`
            <div class="page no-toolbar-x" data-name="task-activities">
              <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a class="link back {{textColorClass}}">
                      <i class="icon icon-back"></i>
                      <span class="if-not-md {{textColorClass}}">{{backTitle}}</span>
                    </a>
                  </div>
                  <div class="title">{{title}}</div>
                  <div class="right">
                    <!-- Link to enable searchbar -->
                    <a class="link icon-only searchbar-enable {{textColorClass}}" data-searchbar=".activities-searchbar">
                      <i class="icon f7-icons if-not-md">search</i>
                      <i class="icon material-icons md-only">search</i>
                    </a>
                  </div>
                  <!-- Searchbar is a direct child of "navbar-inner" -->
                  <form class="searchbar searchbar-expandable searchbar-init activities-searchbar"
                    data-search-container=".activity-list" data-search-in=".item-title,.item-text">
                    <div class="searchbar-inner">
                      <div class="searchbar-input-wrap">
                        <input type="search" placeholder="Tìm hoạt động" />
                        <i class="searchbar-icon"></i><span class="input-clear-button"></span>
                      </div>
                      <span class="searchbar-disable-button {{textColorClass}}">Hủy</span>
                    </div>
                  </form>
                </div>
              </div>
              <div class="page-content ptr-content" @ptr:refresh="refresh">
                <div class="ptr-preloader">
                  <div class="preloader"></div>
                  <div class="ptr-arrow"></div>
                </div>
                <div class="searchbar-backdrop chat-room-control-searchbar-backdrop"></div>
            
                <div class="block block-strong action-bar inset">
                  <div class="row">
                    <div class="col-33 medium-15 action-bar-item text-color-gray link" @click="openAddPaymentRequestDialog">
                      <div class="demo-icon-icon"><i class="icon f7-icons">money_dollar_circle_fill</i></div>
                      <div class="action-bar-item-label">y/c chi</div>
                    </div>
                    <div class="col-33 medium-15 action-bar-item text-color-gray link{" @click="openFinalSettlementRequestDialog">
                      <div class="demo-icon-icon"><i class="icon f7-icons">rectangle_fill_badge_checkmark</i></div>
                      <div class="action-bar-item-label">tất toán</div>
                    </div>
                    <div class="col-33 medium-15 action-bar-item text-color-gray link" @click="openCheckInCheckoutDialog">
                      <div class="demo-icon-icon"><i class="icon f7-icons">timer_fill</i></div>
                      <div class="action-bar-item-label">chấm công</div>
                    </div>
                    <div
                      class="col-33 medium-15 action-bar-item text-color-gray link{{js "this.reports ? ' text-color-default' : ''"}}"
                      @click="openQuickReportDialog">
                      <div class="demo-icon-icon"><i class="icon f7-icons">chart_pie_fill</i></div>
                      <div class="action-bar-item-label">báo cáo</div>
                    </div>
                    <!--<div class="col-33 medium-15 action-bar-item text-color-gray link" @click="reportLastMembersLocation">
                      <div class="demo-icon-icon"><i class="icon f7-icons">compass_fill</i></div>
                      <div class="action-bar-item-label">tòa độ</div>
                    </div>-->
                    <div
                      class="col-33 medium-15 action-bar-item text-color-gray link{{js "this.filter ? ' text-color-default' : ''"}}"
                      @click="openFilterDialog">
                      <div class="demo-icon-icon"><i class="icon f7-icons">line_horizontal_3_decrease_circle_fill</i></div>
                      <div class="action-bar-item-label">bộ lọc</div>
                    </div>
                  </div>
                </div>
            
            
                {{#js_if "this.memberList && this.memberList.length > 0"}}
                <div class="block-title">Tương tác cuối</div>
                <div class="list media-list inset">
                  <ul>
                    {{#each memberList}}
                    <li data-name="remove-chat-room-member" data-id="{{id}}">
                      <a class="item-content item-link swipeout-content" @click="checkMemberLastReportLocation">
                        <div class="item-media">
                          {{#js_if "this.AvatarThumbnail"}}
                          <div class="list-item-img"
                            style="width: 44px; height: 44px; background-image: url({{js "this.AvatarThumbnail || ''"}});"></div>
                          {{else}}
                          <div class="list-item-img" style="width: 44px; height: 44px">
                            <i style="font-size: 16px" class="f7-icons">person_fill</i>
                          </div>
                          {{/js_if}}
                        </div>
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title">{{Name}}</div>
                            <div class="item-after"><i style="font-size: 16px"
                                class="f7-icons">{{js "this.type === 'USER' ? 'person_fill' : this.type === 'GROUP' ? 'person_2_fill' : ''"}}</i>
                            </div>
                          </div>
                          <div class="item-subtitle">{{lastMessageText}}</div>
                          <div class="item-text" style="font-size: 12px">{{lastReportText}}</div>
                          {{#js_if "this.linkGoogleMaps"}}
                          <div class="item-text text-color-default" style="font-size: 12px">{{linkGoogleMaps}}</div>
                          {{/js_if}}
                        </div>
                      </a>
                    </li>
                    {{/each}}
                  </ul>
                </div>
                {{/js_if}}
                <div class="block-footer">Chúng tôi hiểu dữ liệu định vị là riêng tư, chúng tôi chỉ đưa ra dữ liệu tương đối để phục
                  vụ đội nhóm tương tác nhanh, phục vụ cho công tác xử lý công việc nhanh nhất, chủ động tình huống nhanh nhất.
                </div>
            
                {{#js_if "this.reports"}}
                <div class="block-title">Báo cáo</div>
                <div class="list media-list inset">
                  <ul>
                    {{#each reports}}
                    <li class="swipeout" data-type="{{Type}}">
                      <a class="item-content item-link swipeout-content">
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title">{{Description}}</div>
                            <div class="item-after text-color-default">{{AmountText}}</div>
                          </div>
                          <div class="item-text">{{DateOfWriteText}}</div>
                        </div>
                      </a>
                      <div class="swipeout-actions-right">
                        <a href="#" class="color-blue swipeout-overswipe" @click="reportMoreAction">{{ActionName}}</a>
                      </div>
                    </li>
                    {{/each}}
                  </ul>
                </div>
                {{/js_if}}
            
                {{#js_if "this.activities && this.activities.length > 0"}}
                <div class="block-title">Nhật ký hoạt động</div>
                <div class="list media-list inset activity-list">
                  <ul>
                    {{#each activities}}
                    <li class="swipeout" data-id="{{Id}}">
                      <a class="item-content item-link swipeout-content">
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title">{{UserText}}</div>
                            <div
                              class="item-after {{js "['APPROVED', 'FINALSETTLEMENT'].indexOf(this.State) > -1 ? 'text-color-default' : (this.State === 'CANCEL' ? 'text-color-red' : '')"}}">
                              {{js "['FINALSETTLEMENTREQUEST', 'PAYMENTREQUEST'].indexOf(this.Type) > -1 ? this.AmountText : this.Type"}}
                            </div>
                          </div>
                          <div class="item-subtitle">{{DateOfWriteText}}</div>
                          <div class="item-text">{{TypeText}}: {{Description}}</div>
                        </div>
                      </a>
                      {{#js_if "this.Type === 'PAYMENTREQUEST' &&  this.State === 'REQUEST'"}}
                      <div class="swipeout-actions-right">
                        <a href="#" class="color-green swipeout-overswipe" @click="cashPaymentApprovedConfirm">Duyệt</a>
                      </div>
                      {{/js_if}}
                    </li>
                    {{/each}}
                  </ul>
                </div>
                {{/js_if}}
            
              </div>
            
              <div class="dialog dialog-wrap dialog dialog-filter">
                <div class="dialog-padding">
                  <div class="dialog dialog-inside">
                    <div class="dialog-inner" style="padding-bottom: 0;">
                      <div class="dialog-title">Hoạt động</div>
                      <div class="block-title" style="margin-top: 1rem;">Bộ lọc các hoạt động</div>
                      <div class="list simple-list"
                        style="margin-left: -15px; margin-right: -15px; margin-bottom: 0; margin-top: 0; text-align: left;">
                        <ul>
                          <li>
                            <span>Y/C Tất toán</span>
                            <label class="toggle toggle-init color-blue">
                              <input class="field" name="eq_Type" value="FINALSETTLEMENTREQUEST" type="checkbox">
                              <span class="toggle-icon"></span>
                            </label>
                          </li>
                          <li>
                            <span>Yêu cầu chi</span>
                            <label class="toggle toggle-init color-orange">
                              <input class="field" name="eq_Type" value="PAYMENTREQUEST" type="checkbox">
                              <span class="toggle-icon"></span>
                            </label>
                          </li>
                          <li>
                            <span>Check-In</span>
                            <label class="toggle toggle-init color-blue">
                              <input class="field" name="eq_Type" value="CHECKIN" type="checkbox">
                              <span class="toggle-icon"></span>
                            </label>
                          </li>
                          <li>
                            <span>Check-Out</span>
                            <label class="toggle toggle-init color-green">
                              <input class="field" name="eq_Type" value="CHECKOUT" type="checkbox">
                              <span class="toggle-icon"></span>
                            </label>
                          </li>
                          <li>
                            <span>Thông tin</span>
                            <label class="toggle toggle-init color-yellow">
                              <input class="field" name="eq_Type" value="INFO" type="checkbox">
                              <span class="toggle-icon"></span>
                            </label>
                          </li>
                          <li>
                            <span>Hoạt động của tôi</span>
                            <label class="toggle toggle-init color-black">
                              <input class="field" name="eq_User" value="{{authUser.id}}" type="checkbox">
                              <span class="toggle-icon"></span>
                            </label>
                          </li>
                          <li>
                            <span>Hoạt động liên quan</span>
                            <label class="toggle toggle-init color-black">
                              <input class="field" name="eq_RelateUser" value="{{authUser.id}}" type="checkbox">
                              <span class="toggle-icon"></span>
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="dialog-buttons">
                      <span class="dialog-button color-red closeBtn" @click="closeFilterDialog">Đặt lại</span>
                      <span class="dialog-button createReferenceChatRoomBtn" @click="applyFilter">Áp dụng</span>
                    </div>
                  </div>
                </div>
              </div>
            
              <div class="dialog dialog-wrap dialog dialog-quick-report">
                <div class="dialog-padding">
                  <div class="dialog dialog-inside">
                    <div class="dialog-inner" style="padding-bottom: 0;">
                      <div class="dialog-title">Báo cáo</div>
                      <div class="block-title" style="margin-top: 1rem;">Báo cáo nhanh các hoạt động trong task</div>
                      <div class="list simple-list"
                        style="margin-left: -15px; margin-right: -15px; margin-bottom: 0; margin-top: 0; text-align: left;">
                        <ul>
                          <li>
                            <span>Y/C Tất toán</span>
                            <label class="toggle toggle-init color-blue">
                              <input class="field" name="eq_Type" value="FINALSETTLEMENTREQUEST" type="checkbox">
                              <span class="toggle-icon"></span>
                            </label>
                          </li>
                          <li>
                            <span>Yêu cầu chi</span>
                            <label class="toggle toggle-init color-orange">
                              <input class="field" name="eq_Type" value="PAYMENTREQUEST" type="checkbox">
                              <span class="toggle-icon"></span>
                            </label>
                          </li>
                          <li>
                            <span>Chấm công</span>
                            <label class="toggle toggle-init color-blue">
                              <input class="field" name="eq_Type" value="TIMEATTENDANCE" type="checkbox">
                              <span class="toggle-icon"></span>
                            </label>
                          </li>
                          <li>
                            <span>Thông tin</span>
                            <label class="toggle toggle-init color-yellow">
                              <input class="field" name="eq_Type" value="INFO" type="checkbox">
                              <span class="toggle-icon"></span>
                            </label>
                          </li>
                          <li>
                            <span>Hoạt động của tôi</span>
                            <label class="toggle toggle-init color-black">
                              <input class="field" name="eq_User" value="{{authUser.id}}" type="checkbox">
                              <span class="toggle-icon"></span>
                            </label>
                          </li>
                          <li>
                            <span>Hoạt động liên quan</span>
                            <label class="toggle toggle-init color-black">
                              <input class="field" name="eq_RelateUser" value="{{authUser.id}}" type="checkbox">
                              <span class="toggle-icon"></span>
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="dialog-buttons">
                      <span class="dialog-button color-red closeBtn" @click="closeQuickReportDialog">Đặt lại</span>
                      <span class="dialog-button createReferenceChatRoomBtn" @click="applyQuickReport">Áp dụng</span>
                    </div>
                  </div>
                </div>
              </div>
            
              <div class="dialog dialog-wrap dialog-add-payment-request">
                <div class="dialog-padding">
                  <div class="dialog dialog-inside">
                    <div class="dialog-inner" style="padding-bottom: 0;">
                      <div class="dialog-title">Yêu cầu chi tiền</div>
                      <div class="dialog-text" style="width: 100%; user-select: initial; text-align: center; margin-bottom: 0.5rem">
                        Gửi yêu cầu chi tiền để ghi nhận các phát sinh trong nhiệm vụ này</div>
            
                      <div class="list media-list"
                        style="margin-left: -15px; margin-right: -15px; margin-bottom: 0; margin-top: 0; text-align: left;">
                        <ul>
                          <li class="item-content item-input">
                            <div class="item-media"><i class="icon f7-icons text-color-default">money_dollar_circle_fill</i></div>
                            <div class="item-inner">
                              <div class="item-title item-label">Số tiền (*)</div>
                              <div class="item-input-wrap">
                                <input class="field" name="Amount" type="number" placeholder="Số tiền">
                              </div>
                            </div>
                          </li>
                          <li class="item-content item-input">
                            <div class="item-media"><i class="icon f7-icons text-color-default">chat_bubble_text_fill</i></div>
                            <div class="item-inner">
                              <div class="item-title item-label">Mô tả</div>
                              <div class="item-input-wrap">
                                <textarea class="field" type="text" name="Description" placeholder="Mô tả"></textarea>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="dialog-buttons">
                      <span class="dialog-button color-red closeBtn" @click="closeAddPaymentRequestDialog">Đóng</span>
                      <span class="dialog-button createReferenceChatRoomBtn" @click="addPaymentRequest">Thêm</span>
                    </div>
                  </div>
                </div>
              </div>
            
              <div class="dialog dialog-wrap dialog-final-settlement-request">
                <div class="dialog-padding">
                  <div class="dialog dialog-inside">
                    <div class="dialog-inner" style="padding-bottom: 0;">
                      <div class="dialog-title">Yêu cầu tất toán</div>
                      <div class="dialog-text" style="width: 100%; user-select: initial; text-align: center; margin-bottom: 0.5rem">
                        Gửi yêu cầu tất toán để thanh toán các phát sinh trong nhiệm vụ tính tới hiện tại</div>
            
                      <div class="list media-list"
                        style="margin-left: -15px; margin-right: -15px; margin-bottom: 0; margin-top: 0; text-align: left;">
                        <ul>
                          <li class="item-content item-input">
                            <div class="item-media"><i class="icon f7-icons text-color-default">money_dollar_circle_fill</i></div>
                            <div class="item-inner">
                              <div class="item-title item-label">Tổng tiền (*)</div>
                              <div class="item-input-wrap">
                                <input class="field" name="Amount" type="number" placeholder="Số tiền">
                              </div>
                            </div>
                          </li>
                          <li class="item-content item-input">
                            <div class="item-media"><i class="icon f7-icons text-color-default">chat_bubble_text_fill</i></div>
                            <div class="item-inner">
                              <div class="item-title item-label">Mô tả</div>
                              <div class="item-input-wrap">
                                <textarea class="field" type="text" name="Description" placeholder="Mô tả"></textarea>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="dialog-buttons">
                      <span class="dialog-button color-red closeBtn" @click="closeFinalSettlementRequestDialog">Đóng</span>
                      <span class="dialog-button createReferenceChatRoomBtn" @click="addFinalSettlementRequest">Thêm</span>
                    </div>
                  </div>
                </div>
              </div>
            
              <div class="dialog dialog-wrap dialog-checkin-checkout">
                <div class="dialog-padding">
                  <div class="dialog dialog-inside dialog-buttons-vertical">
                    <div class="dialog-inner" style="padding-bottom: 0;">
                      <div class="dialog-title">Chấm công</div>
                      <div class="dialog-text" style="width: 100%; user-select: initial; text-align: center; margin-bottom: 0.5rem">
                        Hãy kiểm tra thời gian và địa điểm trước khi check-in hoặc check-out</div>
            
                      <div class="list media-list"
                        style="margin-left: -15px; margin-right: -15px; margin-bottom: 0; margin-top: 0; text-align: left">
                        <ul style="background: inherit">
                          <li>
                            <a class="item-content item-link">
                              <div class="item-media">
                                <i style="font-size: 47px !important" class="f7-icons text-color-default">clock_fill</i>
                              </div>
                              <div class="item-inner">
                                <div class="item-title-row">
                                  <div class="item-title">Thời gian</div>
                                </div>
                                <div class="item-subtitle">Thời gian check-in/out</div>
                                <div class="item-text">{{currentTime}}</div>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a class="item-content item-link" @click="openCurrentLocationInMaps">
                              <div class="item-media">
                                <i style="font-size: 47px !important" class="f7-icons text-color-default">compass_fill</i>
                              </div>
                              <div class="item-inner">
                                <div class="item-title-row">
                                  <div class="item-title">Địa điểm</div>
                                </div>
                                <div class="item-subtitle">Địa điểm check-in/out</div>
                                <div class="item-text">Kiểm tra địa điểm</div>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="dialog-buttons has-icon">
                      <span class="dialog-button copyBtn" @click="checkIn">Check-IN<i
                          class="icon f7-icons">square_arrow_right_fill</i></span>
                      <span class="dialog-button replyBtn" @click="checkOut">Check-OUT<i
                          class="icon f7-icons">square_arrow_left_fill</i></span>
                      <span class="dialog-button color-red closeBtn" @click="closeCheckInCheckoutDialog">Đóng<i
                          class="icon f7-icons">multiply_circle_fill</i></span>
                    </div>
                  </div>
                </div>
              </div>
            
            </div>
        `,
        style: `
        `,
        data(): { title?: string, attachments?: Attachment[], memberList?: Member[], referenceChatRooms?: ChatRoomModel[], activities: TaskReminderModel[], report: {}, authUser: AuthUser } {
          return {
            // title: $this.title,
            attachments: [],
            memberList: [],
            referenceChatRooms: [],
            activities: [],
            report: null,
            authUser: $this.rootServices.authService.getUser(),
          };
        },
        methods: {
          checkMemberLastReportLocation(e) {
            const self: F7ComponentContextExtend = this;
            const memberId = self.$(e.target).closest('li').data('id') || self.$(e.target).data('id');
            const member = self.memberList.find(f => f.id == memberId);
            if (!member || !member.latitude) {
              $this.commonService.showError(`Thành viên ${member.name} không có ghi nhận tòa độ mới nhất !`);
            } else {
              const currentSwipeout: any = self.$(e.target).closest('li');
              $this.rootServices.iab.create(`https://www.google.com/maps/place/${member.latitude},${member.longitude}`, '_system');
              self.$app.swipeout.close(currentSwipeout[0]);
            }
            return true;
          },
          reportLastMembersLocation(e) {
            const self: F7ComponentContextExtend = this;
            if ($this.rootServices.authService.getUser().core.name.indexOf('core.probox.vn') > -1) {
              $this.commonService.navigate('/google-maps', {
                context: {
                  markers: self.$route.context.chatRoom.memberList$.getValue().filter(f => !!f.Latitude).map(member => ({
                    title: `<div class="text-color-default">${member.Name}` + (member.ShortName ? ` (${member.ShortName})` : '') + '<br> Hoạt động lần cuối vào ' + moment(member.LastReport).fromNow() + '<br>' + $this.rootServices.datePipe.transform(new Date(member.LastReport), 'short') + '</div>',
                    position: {
                      lat: member.Latitude,
                      lng: member.Longitude,
                    },
                    label: moment(member.LastReport).fromNow(),
                    image: member.Avatar?.payload?.thumbnail,
                  })),
                }
              });
            } else {
              self.$app.dialog.alert('Coming soon');
            }
          },
          openCurrentLocationInMaps(e) {
            const self: F7ComponentContextExtend = this;
            self.$app.preloader.show();
            const preploaderAutoClose = setTimeout(() => {
              self.$app.preloader.hide();
            }, 15000);
            $this.rootServices.getLastGeoLocation({ retryAfterMilisecond: isPlatform('android') ? 60000 : isPlatform('desktop') ? 15 * 60000 : 0 }).then<Coordinates>((coords) => {
              // resp.coords.latitude
              // resp.coords.longitude
              console.log(coords);

              const link = `https://www.google.com/maps/place/${coords.latitude},${coords.longitude}`;
              $this.rootServices.iab.create(link, '_system');

              self.$app.preloader.hide();
              clearTimeout(preploaderAutoClose);

              return null;
            }).catch((err) => {
              console.log('Error getting location', err);
              // $this.commonService.showError(err);
              return null;
            });
          },
          openFilterDialog(e) {
            const self: F7ComponentContextExtend = this;
            self.filterDialog && self.filterDialog.open();
            console.log('Current filter: ', self.filter);
          },
          closeFilterDialog(e) {
            const self: F7ComponentContextExtend = this;
            if (self.filterDialog) {
              const fieldEles = self.filterDialog.$el.find('.field');
              fieldEles.each((index, el) => {
                self.$(el).prop('checked', false);
              });
              self.$setState({ filter: null, reports: null });
              self.filterDialog.close();
              self.refresh(null, () => { });
            }
          },
          applyFilter(e) {
            const self: F7ComponentContextExtend = this;
            self.filterDialog && self.filterDialog.close();
            const fieldEles = self.filterDialog.$el.find('.field');
            console.log(fieldEles);
            const filter: { [key: string]: string[] } = {};
            fieldEles.each((index, el) => {
              console.log(index, el.checked);
              if (!filter[el.name]) {
                filter[el.name] = [];
              }
              if (el.checked) {
                filter[el.name].push(el.value);
              }
            });
            for (const key in filter) {
              if (filter[key] && filter[key].length === 0) {
                delete filter[key];
              }
            }
            console.log(filter);
            self.$setState({ filter: Object.keys(filter).length > 0 ? filter : null });
            self.refresh(null, () => { });
          },
          openQuickReportDialog(e) {
            const self: F7ComponentContextExtend = this;
            self.quickReportDialog && self.quickReportDialog.open();
            console.log('Current filter: ', self.filter);
          },
          closeQuickReportDialog(e) {
            const self: F7ComponentContextExtend = this;
            if (self.quickReportDialog) {
              const fieldEles = self.quickReportDialog.$el.find('.field');
              fieldEles.each((index, el) => {
                self.$(el).prop('checked', false);
              });
              self.$setState({ filter: null, reports: null });
              self.quickReportDialog.close();
              self.refresh(null, () => { });
            }
          },
          async applyQuickReport(e) {
            const self: F7ComponentContextExtend = this;
            self.quickReportDialog && self.quickReportDialog.close();
            const fieldEles = self.quickReportDialog.$el.find('.field');
            console.log(fieldEles);
            const filter: { [key: string]: string[] } = {};
            fieldEles.each((index, el) => {
              console.log(index, el.checked);
              if (!filter[el.name]) {
                filter[el.name] = [];
              }
              if (el.checked) {
                filter[el.name].push(el.value);
              }
            });
            for (const key in filter) {
              if (filter[key] && filter[key].length === 0) {
                delete filter[key];
              }
            }
            console.log(filter);

            // self.refresh();

            const reports = [];
            let report: { list: TaskActivityModel[], total: number } = null;
            const cond = { task: self.$route.context.chatRoom.id };

            if (filter['eq_User']) {
              cond['eq_User'] = filter['eq_User'];
            }
            if (filter['eq_RelateUser']) {
              cond['eq_RelateUser'] = filter['eq_RelateUser'];
            }

            if (filter['eq_Type'].indexOf('FINALSETTLEMENTREQUEST') > -1) {
              cond['report'] = 'FINAL_PAYMENT_SETTLEMENT';
              report = await $this.rootServices.apiService.getPromise<{ list: TaskActivityModel[], total: number }>('/task/activities', cond).then(report => {
                const reportItem = {
                  Type: 'FINALSETTLEMENTREQUEST',
                  TypeText: $this.types['FINALSETTLEMENTREQUEST'],
                  UserText: 'Báo cáo ' + $this.types['FINALSETTLEMENTREQUEST'],
                  Description: 'Tổng ' + $this.types['FINALSETTLEMENTREQUEST'],
                  DateOfWriteText: new Date().toLocaleString(),
                  AmountText: $this.rootServices.currencyPipe.transform(report.total, 'VND'),
                  ActionName: 'Thêm',
                };
                reports.push(reportItem);
                return report;
              });
            }
            if (filter['eq_Type'].indexOf('PAYMENTREQUEST') > -1) {
              cond['report'] = 'APPROVED_PAYMENT';
              report = await $this.rootServices.apiService.getPromise<{ list: TaskActivityModel[], total: number }>('/task/activities', cond).then(report => {
                const reportItem = {
                  Type: 'PAYMENTREQUEST',
                  TypeText: $this.types['PAYMENTREQUEST'],
                  UserText: 'Báo cáo ' + $this.types['PAYMENTREQUEST'],
                  Description: 'Tổng ' + $this.types['PAYMENTREQUEST'],
                  DateOfWriteText: new Date().toLocaleString(),
                  AmountText: $this.rootServices.currencyPipe.transform(report.total, 'VND'),
                  ActionName: 'Thêm',
                };
                reports.push(reportItem);
                return report;
              });
            }
            if (filter['eq_Type'].indexOf('INFO') > -1) {
              cond['report'] = 'INFO';
              report = await $this.rootServices.apiService.getPromise<{ list: TaskActivityModel[], total: number }>('/task/activities', cond).then(report => {
                const reportItem = {
                  Type: 'INFO',
                  TypeText: $this.types['INFO'],
                  UserText: 'Báo cáo ' + $this.types['INFO'],
                  Description: 'Tổng ' + $this.types['INFO'],
                  DateOfWriteText: new Date().toLocaleString(),
                  AmountText: report.total + ' hoạt động',
                };
                reports.push(reportItem);
                return report;
              });
            }
            if (filter['eq_Type'].indexOf('TIMEATTENDANCE') > -1) {
              cond['report'] = 'TIME_ATTENDANCE';
              report = await $this.rootServices.apiService.getPromise<{ list: TaskActivityModel[], total: number }>('/task/activities', cond).then(report => {
                const reportItem = {
                  Type: 'TIMEATTENDANCE',
                  TypeText: $this.types['TIMEATTENDANCE'],
                  UserText: 'Báo cáo ' + $this.types['TIMEATTENDANCE'],
                  Description: 'Tổng ' + $this.types['TIMEATTENDANCE'],
                  DateOfWriteText: new Date().toLocaleString(),
                  AmountText: report.total + ' hoạt động',
                  ActionName: 'Xuất',
                };
                reports.push(reportItem);
                return report;
              });
            }
            report.list.map(taskActivity => {
              if (['PAYMENTREQUEST', 'FINALSETTLEMENTREQUEST'].indexOf(taskActivity.Type) > -1) {
                taskActivity['AmountText'] = $this.rootServices.currencyPipe.transform(taskActivity['Amount'], 'VND');
              }
              // const type = { PAYMENTREQUEST: 'Yêu cầu chi', INFO: 'Thông tin', 'FINALSETTLEMENTREQUEST': 'Y/C Tất toán' };
              taskActivity['TypeText'] = $this.types[taskActivity['Type']];
              taskActivity['State'] = taskActivity.State;
              taskActivity['UserText'] = (self.$route.context.chatRoom.memberList$.getValue().find(f => (f.User && f.User.id || f.User) == taskActivity.User) || {}).Name;
              taskActivity['DateOfWriteText'] = new Date(taskActivity.DateOfWrite).toLocaleString() + ' (' + moment(taskActivity.DateOfWrite).fromNow() + ')';
            });
            // self.activitiesList$.next(report.list);
            delete filter['report'];
            self.$setState({ reports: reports, activities: report.list, filter: Object.keys(filter).length > 0 ? filter : null });
            // self.$setState({ reports: reports });
          },
          reportMoreAction(e) {
            const self: F7ComponentContextExtend = this;
            const type = self.$(e.target).closest('li').data('type') || self.$(e.target).data('type');
            switch (type) {
              case 'TIMEATTENDANCE':
                self.$app.dialog.confirm('Xuất dữ liệu chấp công, sau khi xử lý xong hệ thống sẽ gửi link tải vào task này.', 'Chấp công', () => {
                  const filter = self.filter;
                  $this.rootServices.apiService.putPromise('/task/activities/attendance', { task: self.$route.context.chatRoom.id, export: 'txt' }, []).then(rs => {
                    $this.commonService.showInfo('Đã xuất dữ liệu chấm công');
                  });
                }, () => { })
                break;
            }
          },
          openAddPaymentRequestDialog(e: MouseEvent) {
            const self: F7ComponentContextExtend = this;
            // $this.openCreateTicketDialog();
            if (self.addPaymentRequestDialog) {
              self.addPaymentRequestDialog.open();
              self.addPaymentRequestDialog.$el.find('.field[name="name"]').focus();
            }
          },
          addPaymentRequest(e: MouseEvent) {
            const self: F7ComponentContextExtend = this;
            const activity: TaskActivityModel = {};
            const fieldEles = self.addPaymentRequestDialog.$el.find('.field');
            fieldEles.each((index, fieldEle) => {
              console.log(fieldEle);
              console.log(fieldEle.value);
              activity[fieldEle.name] = fieldEle.value;
            });
            if (!activity.Amount) {
              console.error('Bạn chưa điền số số tiền');
              $this.commonService.showError('Bạn chưa điền số số tiền');
              return;
            }
            if (!activity.Description) {
              console.error('Bạn chưa điền mô tả');
              $this.commonService.showError('Bạn chưa điền mô tả');
              return;
            }
            // activity.Amount = self.paymentRequestAmount && self.paymentRequestAmount.rawValue || 0;
            activity.Type = 'PAYMENTREQUEST';
            activity.State = 'REQUEST';
            console.log(activity);
            self.$app.preloader.show();
            try {
              $this.rootServices.apiService.postPromise<TaskActivityModel[]>('/task/activities', { task: self.$route.context.chatRoom.id }, [activity]).then(rs => {
                self.$app.preloader.hide();
                console.debug('Complete add task activity', rs);
                $this.commonService.showInfo('Đã gửi yêu cầu chi tiền');
                self.closeAddPaymentRequestDialog();
                self.refresh();
              }).catch(err => {
                self.$app.preloader.hide();
                console.error(err);
                $this.commonService.showError(err);
              });
            } catch (err) {
              console.error(err);
              self.$app.preloader.hide();
              $this.commonService.showError(err);
            }
          },
          closeAddPaymentRequestDialog() {
            const self: F7ComponentContextExtend = this;
            self.addPaymentRequestDialog && self.addPaymentRequestDialog.close();
          },
          openFinalSettlementRequestDialog(e: MouseEvent) {
            const self: F7ComponentContextExtend = this;
            $this.rootServices.apiService.getPromise<{ list: TaskActivityModel[], total: number }>('/task/activities', { task: self.$route.context.chatRoom.id, report: 'APPROVED_PAYMENT', eq_RelateUser: $this.rootServices.authService.getUser().id }).then(report => {
              if (report && report.total) {
                if (self.finalSettlementDialog) {
                  self.finalSettlementDialog.$el.find('.field[name="Amount"]').val(report.total);
                  let description = 'Yêu cầu tất toán số tiền đã chi ' + $this.rootServices.currencyPipe.transform(report.total, 'VND');

                  if (report.list) for (const item of report.list) {
                    description += `\n- ${item.Description} số tiền ${$this.rootServices.currencyPipe.transform(item.Amount, item.AmountUnit)}`;
                  }

                  self.finalSettlementDialog.$el.find('.field[name="Description"]').val(description);
                  self.finalSettlementDialog.open();
                  self.finalSettlementDialog.$el.find('.field[name="Description"]').focus();
                }
              } else {
                self.$app.dialog.alert('Hiện tại không có yêu cầu chi nào đã duyệt !', 'Hoạt động');
              }
            });
            // $this.openCreateTicketDialog();

          },
          addFinalSettlementRequest(e: MouseEvent) {
            const self: F7ComponentContextExtend = this;
            const activity: TaskActivityModel = {};
            const fieldEles = self.finalSettlementDialog.$el.find('.field');
            fieldEles.each((index, fieldEle) => {
              console.log(fieldEle);
              console.log(fieldEle.value);
              activity[fieldEle.name] = fieldEle.value;
            });
            if (!activity.Amount) {
              console.error('Bạn chưa điền số số tiền');
              $this.commonService.showError('Bạn chưa điền số số tiền');
              return;
            }
            if (!activity.Description) {
              console.error('Bạn chưa điền mô tả');
              $this.commonService.showError('Bạn chưa điền mô tả');
              return;
            }
            // activity.Amount = self.paymentRequestAmount && self.paymentRequestAmount.rawValue || 0;
            activity.Type = 'FINALSETTLEMENTREQUEST';
            activity.State = 'REQUEST';
            console.log(activity);
            self.$app.preloader.show();
            try {
              $this.rootServices.apiService.postPromise<TaskActivityModel[]>('/task/activities', { task: self.$route.context.chatRoom.id }, [activity]).then(rs => {
                self.$app.preloader.hide();
                console.debug('Complete add task activity', rs);
                $this.commonService.showInfo('Đã gửi yêu cầu chi tiền');
                self.closeFinalSettlementRequestDialog();
                self.refresh();
              }).catch(err => {
                self.$app.preloader.hide();
                console.error(err);
                $this.commonService.showError(err);
              });
            } catch (err) {
              console.error(err);
              self.$app.preloader.hide();
              $this.commonService.showError(err);
            }
          },
          closeFinalSettlementRequestDialog() {
            const self: F7ComponentContextExtend = this;
            self.finalSettlementDialog && self.finalSettlementDialog.close();
          },
          openCheckInCheckoutDialog(e: MouseEvent) {
            const self: F7ComponentContextExtend = this;

            // $this.rootServices.getLastGeoLocation({ retryAfterMilisecond: isPlatform('android') ? 60000 : isPlatform('desktop') ? 15 * 60000 : 0 }).then<Coordinates>((coords) => {
            // resp.coords.latitude
            // resp.coords.longitude
            // console.log(coords);

            self.$setState({ currentTime: $this.datePipe.transform(Date.now(), 'short') });
            self.checkInCheckoutDialog && self.checkInCheckoutDialog.open();

            //   return coords && {
            //     latitude: coords.latitude,
            //     longitude: coords.longitude,
            //     accuracy: coords.accuracy,
            //     altitude: coords.altitude,
            //     altitudeAccuracy: coords.altitudeAccuracy,
            //     heading: coords.heading,
            //     speed: coords.speed,
            //   };
            // }).catch((err) => {
            //   console.log('Error getting location', err);
            //   // $this.commonService.showError(err);
            //   return null;
            // });

          },
          checkIn(e: MouseEvent) {
            const self: F7ComponentContextExtend = this;
            $this.commonService.showPreloader();
            $this.rootServices.getLastGeoLocation({ retryAfterMilisecond: isPlatform('android') ? 60000 : isPlatform('desktop') ? 15 * 60000 : 0 }).then<Coordinates>((coords) => {
              // resp.coords.longitude
              console.log(coords);

              $this.rootServices.apiService.postPromise<TaskActivityModel[]>('/task/activities', { task: self.$route.context.chatRoom.id }, [{ Type: 'CHECKIN', Data: { coordinates: coords } }]).then(rs => {
                self.$app.preloader.hide();
                $this.commonService.hidePreloader();
                self.closeCheckInCheckoutDialog();
                $this.refresh();
              }).catch(err => {
                $this.commonService.showError(err);
              });


              return null;
            }).catch((err) => {
              console.log('Error getting location', err);
              // $this.commonService.showError(err);
              return null;
            });
          },
          checkOut(e: MouseEvent) {
            const self: F7ComponentContextExtend = this;
            $this.commonService.showPreloader();
            $this.rootServices.getLastGeoLocation({ retryAfterMilisecond: isPlatform('android') ? 60000 : isPlatform('desktop') ? 15 * 60000 : 0 }).then<Coordinates>((coords) => {
              // resp.coords.longitude
              console.log(coords);

              $this.rootServices.apiService.postPromise<TaskActivityModel[]>('/task/activities', { task: self.$route.context.chatRoom.id }, [{ Type: 'CHECKOUT', Data: { coordinates: coords } }]).then(rs => {
                self.$app.preloader.hide();
                $this.commonService.hidePreloader();
                self.closeCheckInCheckoutDialog();
                $this.refresh();
              }).catch(err => {
                $this.commonService.showError(err);
              });


              return null;
            }).catch((err) => {
              console.log('Error getting location', err);
              // $this.commonService.showError(err);
              return null;
            });
          },
          closeCheckInCheckoutDialog() {
            const self: F7ComponentContextExtend = this;
            self.checkInCheckoutDialog && self.checkInCheckoutDialog.close();
          },
          async refresh(e, done) {
            const self: F7ComponentContextExtend = this;
            const chatRoom = self.$route.context.chatRoom;

            let doneTimeout = null;
            if (done) {
              doneTimeout = setTimeout(() => {
                done();
              }, 10000);
            };

            $this.rootServices.authService.isAuthenticatedOrRefresh().pipe(take(1)).toPromise().then(async () => {
              // Load task reminder list
              let filter = {};
              for (const key in self.filter) {
                filter[key] = '[' + self.filter[key].join(',') + ']'
              }
              await $this.rootServices.apiService.getPromise<TaskActivityModel[]>('/task/activities', {
                chatRoom: chatRoom.id,
                sort_Id: 'desc',
                // search: self.searchBar.query,
                ...filter,
              }).then(taskActivities => {
                if (done) done();
                taskActivities.map(taskActivity => {
                  if (['PAYMENTREQUEST', 'FINALSETTLEMENTREQUEST'].indexOf(taskActivity.Type) > -1) {
                    taskActivity['AmountText'] = $this.rootServices.currencyPipe.transform(taskActivity['Amount'], 'VND');
                  }
                  // const type = { PAYMENTREQUEST: 'Yêu cầu chi', INFO: 'Thông tin', 'FINALSETTLEMENTREQUEST': 'Y/C Tất toán' };
                  taskActivity['TypeText'] = $this.types[taskActivity['Type']];
                  taskActivity['State'] = taskActivity.State;
                  taskActivity['UserText'] = (self.$route.context.chatRoom.memberList$.getValue().find(f => (f.User && f.User.id || f.User) == taskActivity.User) || {}).Name;
                  taskActivity['DateOfWriteText'] = new Date(taskActivity.DateOfWrite).toLocaleString() + ' (' + moment(taskActivity.DateOfWrite).fromNow() + ')';
                });
                self.activitiesList$.next(taskActivities);
              }).catch(err => {
                if (done) done();
              });

              await self.$route.context.chatRoom.syncMemberList({ force: true });
              return true;
            });

            // self.$setState({
            //   // attachments: $this.currentState,
            // });
            return true;
          },
          cashPaymentApprovedConfirm(e) {
            const self: F7ComponentContextExtend = this;
            const id = self.$(e.target).closest('li').data('id');
            const dialog = self.$app.dialog.confirm('Bạn có phải là người chi tiền cho hoạt động này ?', 'Xác nhận chi tiền', () => {
              $this.rootServices.apiService.putPromise('/task/activities', { task: self.$route.context.chatRoom.id, id: id }, [{ Id: id, State: 'APPROVED' }]).then(rs => {
                $this.commonService.showInfo('Bạn đã xác nhận chi tiền cho hoạt động này !');
                dialog.close();
                self.refresh();
              });
            }, () => {
              $this.commonService.showInfo('Bạn chưa xác nhận chi tiền cho hoạt động này !');
            })
          },
        },
        on: {
          async pageInit(e, page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;
            const chatRoomId = self.$route.params.id;
            const chatRoom = self.$route.context.chatRoom;
            self.memberList = [];
            self.activitiesList$ = new BehaviorSubject<TaskReminderModel[]>([]);

            if (!self.$route.context || !self.$route.context.title) {
              self.$setState({
                title: 'Task Activities',
              });
            }

            await chatRoom.syncMemberList();

            $this.onComponentInit({ instance: self, chatRoom: self.$route.context.chatRoom, page, destroy$: new Subject() }, chatRoomId, 'init').then(currentState => {

              // self.notificationSettinsDialog = self.$app.dialog.create({
              //   el: page.$el.find('.dialog-notification-settings'),
              //   closeByBackdropClick: true,
              // });

              self.addPaymentRequestDialog = self.$app.dialog.create({
                el: page.$el.find('.dialog-add-payment-request'),
                closeByBackdropClick: true,
              });

              self.finalSettlementDialog = self.$app.dialog.create({
                el: page.$el.find('.dialog-final-settlement-request'),
                closeByBackdropClick: true,
              });

              self.checkInCheckoutDialog = self.$app.dialog.create({
                el: page.$el.find('.dialog-checkin-checkout'),
                closeByBackdropClick: true,
              });

              self.filterDialog = self.$app.dialog.create({
                el: page.$el.find('.dialog-filter'),
                closeByBackdropClick: true,
              });

              self.quickReportDialog = self.$app.dialog.create({
                el: page.$el.find('.dialog-quick-report'),
                closeByBackdropClick: true,
              });

              // setTimeout(() => {
              //   // self.paymentRequestAmount = new AutoNumeric(page.$el.find('.dialog-add-payment-request input[name="Amount"]')[0], '', {
              //   //   currencySymbol: 'đ',
              //   //   currencySymbolPlacement: "s",
              //   //   decimalPlaces: 0,
              //   // });
              //   // const paymentRequestAmount = new AutoNumeric();
              // }, 1000);


              // self.addReminderDialog = self.$app.dialog.create({
              //   el: page.$el.find('.dialog-add-reminder'),
              //   closeByBackdropClick: true,
              // });
              // self.calendarDateTime = self.$f7.calendar.create({
              //   inputEl: page.$el.find('.dialog-add-reminder input[name="RemindAtDateTime"]'),
              //   timePicker: true,
              //   dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' },
              // });

              // const fieldEles = self.notificationSettinsDialog.$el.find('.field');
              // const selfMember = currentState.chatRoom.memberList$.getValue().find(f => f.id === $this.rootServices.authService.user$.getValue().id);
              // const currentNotificationSettings = selfMember.disabledNotification || {};
              // fieldEles.each((index, el) => {
              //   console.log(index, el.checked);
              //   // if (el.checked) {
              //   self.$(el).prop('checked', parseInt(el.value) * (currentNotificationSettings[el.name] ? 1 : -1) > 0 ? true : false);
              //   // notificationSettings[el.name] = (el.checked ? 1 : -1) * parseInt(el.value) > 0 ? true : false;
              //   // }
              // });

              // chatRoom.rooInfo$ && chatRoom.rooInfo$.pipe(takeUntil(currentState.destroy$), filter(f => !!f)).subscribe(roomInfo => {
              //   self.$setState({
              //     description: roomInfo.description,
              //     tags: roomInfo.tags,
              //   });
              // });

              // $this.currentState.chatRoom.attachments$.pipe(takeUntil($this.currentState.destroy$)).subscribe(attachments => {
              //   self.$setState({
              //     attachments: attachments.map(att => ({
              //       ...att,
              //       dateOfAttach: new Date(att.dateOfAttach).toLocaleString(),
              //       payload: {
              //         thumbnail: att.payload.thumbnail ? att.payload.thumbnail : '',
              //         url: att.payload.url ? att.payload.url : '',
              //       },
              //     })),
              //   });
              // });

              $this.currentState.chatRoom.memberList$.pipe(takeUntil($this.currentState.destroy$)).subscribe(memberList => {
                self.memberList = memberList;
                if (!self.skipUpdateMemberList) {

                  // const contactList = memberList.filter(member => member.type === 'CONTACT');

                  // const nonGroupMembers = memberList.filter(member => member.type === 'USER' && !member.group);
                  const groupMembers = memberList.filter(member => member.Group);
                  let groups = memberList.filter(member => member.Group && !member.User);
                  groups = groups.map(group => {
                    return {
                      ...group,
                      roles: group.Roles && group.Roles.map(role => ($this.currentState.roles[role] && $this.currentState.roles[role].text) || role) as any,
                      children: groupMembers.filter(member => member.Type === 'USER' && (member.Group && member.Group.id || member.Group) === group.id),
                    };
                    // group.roles = group.roles && group.roles.map(role => ($this.currentState.roles[role] && $this.currentState.roles[role].text) || role) as any;
                    // group.children = groupMembers.filter(member => member.type === 'USER' && (member.group && member.group.id || member.group) === group.id);
                  });

                  self.$setState({
                    // contactList: contactList.map(member => {
                    //   return {
                    //     ...member,
                    //     roles: member.roles && member.roles.map(role => ($this.currentState.roles[role] && $this.currentState.roles[role].text) || role),
                    //   };
                    // }),
                    memberList: memberList.filter(member => member.Type === 'USER').sort((a, b) => new Date(b.LastReport).getTime() - new Date(a.LastReport).getTime()).map(member => {
                      return {
                        ...member,
                        lastReportText: $this.rootServices.datePipe.transform(new Date(member.LastReport), 'short') + ' (' + moment(member.LastReport).fromNow() + ')',
                        linkGoogleMaps: member.Latitude && member.Longitude ? `https://www.google.com/maps/place/${member.Latitude},${member.Longitude}` : '',
                        roles: member.Roles && member.Roles.map(role => ($this.currentState.roles[role] && $this.currentState.roles[role].text) || role),
                      };
                    }),
                  });

                  // self.$setState({
                  // });
                  // self.$setState({
                  //   memberList: groupMembers.map(member => {
                  //     // const member = memberList[userCode];
                  //     // member.user.avatar = member.user.avatar + '?token=' + $this.rootServices.apiService.token.access_token;
                  //     return {
                  //       ...member,
                  //       roles: member.roles && member.roles.map(role => $this.currentState.roles[role].text),
                  //       user: {
                  //         ...(member.user as any),
                  //         role: member.user && member.user.role || 'undefined',
                  //         avatar: member.user && member.user.avatar && {
                  //           payload: {
                  //             thumbnail: member.user.avatar.payload.thumbnail + '?token=' + $this.rootServices.apiService.token.access_token
                  //           }
                  //         }
                  //       },
                  //     };
                  //   }),
                  // });
                }
              });

              // page.$el.find('.tree-view-item-selectable').on('click', function (e) {
              //   const $targetEl = $(e.target);
              //   if ($targetEl.closest('.treeview-item-selected').length) return;
              //   if ($targetEl.is('.treeview-toggle')) return;
              //   $targetEl.parents('.treeview').find('.treeview-item-selected').removeClass('treeview-item-selected')
              //   $targetEl.closest('.treeview-item-selectable').addClass('treeview-item-selected')
              // });

              // self.searchBar = self.$app.searchbar.create({
              //   el: '.chat-room-control-searchbar',
              //   backdrop: true,
              //   backdropEl: '.chat-room-control-searchbar-backdrop',
              //   searchContainer: '.list',
              //   searchIn: '.item-text',
              //   customSearch: true,
              //   on: {
              //     search(sb, query, previousQuery) {
              //       // $this.commonService.takeUntil('chat-room-control-search', 600).then(() => {
              //       //     console.log(query, previousQuery);
              //       //     $this.rootServices.apiService.getPromise<any[]>('/chat/rooms', {
              //       //         search: query,
              //       //         sort_LastUpdate: 'desc',
              //       //         limit: 10,
              //       //         offset: 0,
              //       //         filter_Type: '[LOCALGROUP,LOCAL,WORKPLACE]',
              //       //         ...self.filter,
              //       //     }).then(chatRooms => {
              //       //         self.$$(page.el).find('.page-content').scrollTo(0, 0);
              //       //         self.$setState({ chatRooms, infiniteLoadLast: chatRooms.length === 0 });
              //       //         self.infiniteOffset = 0;
              //       //     }).catch(err => {
              //       //         console.error(err);
              //       //         $this.commonService.showError(err);
              //       //     });
              //       // });
              //     }
              //   }
              // });

              self.activitiesList$.pipe(takeUntil($this.currentState.destroy$)).subscribe(activitiyList => {
                self.$setState({
                  activities: activitiyList.map(reminder => {
                    // reminder['RemindAtDateTimeText'] = new Date(reminder.RemindAtDateTime).toLocaleString();
                    // reminder.NextRemind = moment(reminder.NextRemind).fromNow();
                    return reminder;
                  }),
                });
              });
              // $this.rootServices.authService.isAuthenticatedOrRefresh().pipe(take(1)).toPromise().then(() => {
              //   // Load task reminder list
              //   $this.rootServices.apiService.getPromise<TaskReminderModel[]>('/task/reminders', { chatRoom: chatRoom.id, sort_NextRemind: 'asc' }).then(taskReminders => {
              //     self.reminderList$.next(taskReminders);
              //   });
              // });

            });

            // self.$app.on('swipeoutDelete', function(el) {
            //   console.log(el);
            //   const swipeoutName = self.$(el).data('name');
            //   const userId = self.$(el).data('id');
            //   if (swipeoutName === 'remove-chat-room-member') {
            //     $this.currentState.chatRoom.removeMember(userId).then(rs => {
            //       console.log(rs);
            //     }).catch(err => {
            //       console.error(err);
            //     });
            //   }
            // });

          },
          pageBeforeIn(e, page) {
            const self: F7ComponentContextExtend = this;
            const chatRoomId = self.$route.params.id;

            // Fire event: On Changed State
            $this.onChangedState({ instance: self, page }, chatRoomId, 'before-in');
          },
          pageBeforeOut(e, page) {
            const self: F7ComponentContextExtend = this;
            const chatRoomId = self.$route.params.id;

            // Fire event: On Before Changed State
            $this.onBeforeChangedState({ instance: self, page, scrollPosition: page.$el[0].children[0].scrollTop || 1 }, chatRoomId);
          },
          pageAfterIn(e, page: F7Page) {
            console.log('page after in');
            // const self: F7ComponentContextExtend = this;
            // self.$$('.deleted-callback').on('swipeout:deleted', (e2: CustomEvent) => {
            //   // self.$app.dialog.alert('Thanks, item removed!');
            //   console.debug(e2);

            // });
            // page.$el.find('.remove-member-confirm').on('click', function (e2: MouseEvent) {
            //   self.$app.dialog.confirm('Thành viên sẽ bị gở khỏi phòng chat !', () => {
            //     const currentSwipeout: any = self.$(e2.target).closest('li');
            //     const userId = currentSwipeout.data('id') || self.$(e2.target).data('id');
            //     $this.currentState.chatRoom.removeMember(userId).then(rs => {
            //       console.log(rs);
            //       self.$app.swipeout.delete(currentSwipeout[0]);
            //     }).catch(err => {
            //       console.error(err);
            //     });
            //   });
            // });
          },
          pageBeforeRemove(e, page) {
            console.log('[page event] before remove', page.route.url);
            const self: F7ComponentContextExtend = this;
            const chatRoomId = self.$route.params.id;
            $this.currentState.destroy$.next();
            $this.currentState.destroy$.complete();
            $this.currentState = null;
          },
        },
      },
    };
  }
}
