import { Router } from "framework7/build/core/modules/router/router";
import { BehaviorSubject } from "rxjs";
import { AdminProductProductListComponent } from "vendors/smart-bot-app/src/app/components/admin-product/product-list";
import { ComponentState } from "vendors/smart-bot-app/src/app/lib/base-component";
import { F7ListComponentContext } from "vendors/smart-bot-app/src/app/lib/base-list-component";
import { ChatRoom } from "vendors/smart-bot-app/src/app/lib/nam-chat/chat-room";
import { ProductModel, ProductCategoryModel, ProductGroupModel } from "vendors/smart-bot-app/src/app/model/product.model";
import { TaskReminderModel } from "vendors/smart-bot-app/src/app/model/task.model";
import { CommonService } from "vendors/smart-bot-app/src/app/services/common.service";
import { RootServices } from "../../services/root.services";
import { F7Page } from "../../types/framework7-types";

declare const $: any;

// declare const $: any;
export interface ComponentStateExtend extends ComponentState {
  [key: string]: any;
}

export interface F7ComponentContextExtend extends F7ListComponentContext<ProductModel> {
  responseInProgress?: boolean;
  productList?: ProductModel[];
  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, reminder?: TaskReminderModel, chatRoom?: ChatRoom, reminderList$?: BehaviorSubject<TaskReminderModel[]> } };

  categoryList?: ProductCategoryModel[],
  groupList?: ProductGroupModel[],

}

/** Component group manager */
export class CollaboratorPageProductListComponent extends AdminProductProductListComponent {
  namespace: string = 'collaborator-page-product';
  itemHeight = 208;

  apiPath = '/collaborator/products';
  componentName = 'collaborator-product-list';
  route = '/collaborator/page-product-list/:case';

  limit = 20;
  title: 'Sản phẩm';
  idKey = 'Code';

  filterSchema = {
    Categories: {
      type: 'smart-select',
      dataType: 'multiple'
    },
    Groups: {
      type: 'smart-select',
      dataType: 'multiple'
    },
  };

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
  ) {
    super(rootServices, commonService);
    // // console.log('Click here to open ts file');
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  async onComponentInit(state: ComponentStateExtend, index: string, asCase?: string, f7Page?: F7Page) {
    return super.onComponentInit(state, index, asCase, f7Page).then(currentState => {
      this.refresh();
      return currentState;
    });
  }

  // onItemClick(self: F7ListComponentContext<ProductModel>, item: ProductModel): void {
  //   return;
  // }

  // prepareItem(item: ProductModel, index?: number): ProductModel {
  //   item.UnitList = item.Units;
  //   return super.prepareItem(item, index);
  // }

  async getList(self?: F7ComponentContextExtend, extParams?: any): Promise<ProductModel[]> {
    const $this = this;
    return this.rootServices.apiService.getPromise<any[]>(this.apiPath, {
      includeIdText: true,
      includeCategories: true,
      includeGroups: true,
      includeProduct: true,
      includeUnit: true,
      includeUnitPrices: true,
      productOfPage: true,
      page: $this.currentState?.instance?.$route?.context['pageId'],
      ...this.parseFilter(this.currentState?.filter),
      ...extParams
    }).then(list => {
      for (const item of list) {
        item.id = item.Code;
        // item.Name = item.ProductName;
      }
      self.$setState({ productList: list.map((t, i) => this.prepareItem(t, i)), infiniteLoadLast: list.length === 0 });
      self.infiniteOffset = 10;
      return list;
    }).catch(err => {
      console.error(err);
      this.commonService.showError(err);
      return Promise.reject(err);
    });
  }

  prepareItem(item: ProductModel, index?: number): ProductModel {
    // if (item.Units && item.Units.length > 0) {
    //   let defaultSalesUnit = item.Units.find(f => f.IsDefaultSales);
    //   if (defaultSalesUnit) {
    //     item.Unit = defaultSalesUnit;
    //     const price = item.UnitList && item.UnitList.find(f => f.id === item.Unit.id);
    //     if (price) {
    //       item.Price = price.Price;
    //     }
    //   } else {
    //     defaultSalesUnit = item.Units[0];
    //     item.Unit = defaultSalesUnit;
    //   }
    // } else {
    //   item.Unit = item.WarehouseUnit && item.WarehouseUnit.id ? item.WarehouseUnit : (item.WarehouseUnit ? { id: item.WarehouseUnit, text: item.WarehouseUnit } as any : { id: '', text: '--' });
    // }
    item.Units = [{
      ...item.Unit,
      Price: item.Price
    }];
    return item;
  }

  itemTemplate = /*html*/`
  <div class="block block-strong inset list-item index-{{__index}}" data-id="{{Code}}" data-index="{{__index}}" style="height: 197px; overflow: hidden; margin-top: 0; margin-bottom: 10px">
    <div class="item-content">
      <div class="detail-wrap">
        <div class="detail-header">
          <div class="header-label">{{Name}}</div>
          <div class="detail-choose chooseOne link"><i class="icon f7-icons color-orange" style="font-size: 2rem;">cart_fill_badge_plus</i></div>
        </div>
        <div class="detail-body" style="padding-bottom: 0.5rem;">
          <div class="image-wrap">
            <div class="image previewPictures" data-index="0" style="height: 112px; {{js "this.FeaturePicture && this.FeaturePicture.Thumbnail && ('background-image: url('+this.FeaturePicture.Thumbnail+')') || ''"}}"></div>
          </div>
          <div class="detail-content-wrap">
            <div class="list inline-labels no-hairlines">
              <ul>
                <li class="item-content item-input item-link showBarcode" data-unit="{{id}}"  style="padding-left: 0; min-height: 2rem;">
                  <div class="item-inner">
                    <div class="item-title item-label">ĐVT</div>
                    <div class="unit" style="text-align: right">Giá</div>
                  </div>
                </li>
                {{#each Units}}
                <li class="item-content item-input item-link showBarcode chooseOne" data-unit="{{id}}"  style="padding-left: 0; min-height: 2rem;">
                  <div class="item-inner">
                    <div class="item-title item-label">{{text}}</div>
                    <div class="unit text-color-blue" style="text-align: right">
                      {{currency Price}}
                    </div>
                  </div>
                </li>
                {{/each}}
                <li class="item-content item-input" style="padding-left: 0; min-height: 2rem;">
                  <div class="item-inner" style="padding-top: var(--f7-list-item-padding-vertical);
                  padding-bottom: var(--f7-list-item-padding-vertical);">
                    <div>
                      <i class="openProduct f7-icons text-color-gray link" style="font-size: 21px; float: left; line-height: 26px; margin-right: 0.5rem;">square_pencil</i>
                      <i class="copyProduct f7-icons text-color-gray link" style="font-size: 20px; float: left; line-height: 26px; margin-right: 0.5rem; margin-top: 2px;">square_on_square</i>
                    </div>
                    <!--<i class="chooseOne f7-icons text-color-orange link" style="font-size: 32px; float: left; line-height: 26px; margin-left: 1rem">cart_fill_badge_plus</i>-->
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="detail-footer">SKU: {{Sku}}, ID: {{Code}}{{#js_if "this.Categories && this.Categories.length > 0"}}, DM: {{text Categories}}{{/js_if}}</div>
      </div>
    </div>
  </div>
  `;

  // itemBeforeInsert(element: HTMLElement, item: ProductModel): void {
  //   const $this = this;
  //   const result = super.itemBeforeInsert(element, item);
  //   $(element).find('.unitBtn').unbind('click').click(async function () {
  //     const unitId = $(this).attr('data-unit');
  //     if (item.Units && item.Units.length > 0) {
  //       const unit = item.Units.find(u => u.id == unitId);
  //       if (unit) {
  //         let listItemTemplate = Template7.compile(/*html*/`
  //           <div class="list media-list" style="margin-left: -1rem; margin-right: -1rem; margin-bottom: 0">
  //             <ul>
  //               {{#each RelativeObjects}}
  //               <li data-container="{{id this}}">
  //                 <a href="#" class="item-link item-content">
  //                   <!--<div class="item-media"><i class="icon icon-f7"></i></div>-->
  //                   <div class="item-inner">
  //                     <div class="item-title-row">
  //                       <div class="item-title">{{text this}}</div>
  //                       <div class="item-after">{{currency price}}</div>
  //                     </div>
  //                     <div class="item-text" style="text-align: left">Lần cuối: {{date date}}</div>
  //                   </div>
  //                 </a>
  //               </li>
  //               {{/each}}
  //             </ul>
  //           </div>
  //           `);
  //         const dialog = $this.currentState.instance.$app.dialog.create({
  //           title: 'Nhà cung cấp liên quan',
  //           text: item.Name + ' (' + $this.commonService.getObjectText(unit) + ') được nhập bởi các nhà cung cấp bên dưới',
  //           content: listItemTemplate({ RelativeObjects: unit.RelativeObjects }),
  //           buttons: [
  //             {
  //               text: 'Trở về',
  //               bold: true,
  //               color: 'red'
  //             },
  //           ],
  //           verticalButtons: true,
  //         });
  //         dialog.open();
  //       }
  //     }
  //   });
  //   return result;
  // }

  // itemTemplate = /*html*/`
  // <div class="block block-strong inset list-item index-{{@index}}" data-id="{{Code}}" data-index="{{@index}}" style="height: 197px; overflow: hidden; margin-top: 0; margin-bottom: 10px">
  //   <div class="item-content">
  //     <div class="detail-wrap">
  //       <div class="detail-header">
  //         <div class="header-label">{{Sku}} - {{Name}} ({{Code}})</div>
  //         <div class="detail-remove chooseOne link"><i class="icon f7-icons color-orange" style="font-size: 2rem;">cart_fill_badge_plus</i></div>
  //       </div>
  //       <div class="detail-body" style="padding-bottom: 0.5rem;">
  //         <div class="image-wrap">
  //           <div class="image previewPictures" data-index="0" style="height: 112px; {{js "this.FeaturePicture && this.FeaturePicture.Thumbnail && ('background-image: url('+this.FeaturePicture.Thumbnail+')') || ''"}}"></div>
  //         </div>
  //         <div class="detail-content-wrap">
  //           <div class="list inline-labels no-hairlines thin-scrollbar" style="overflow: auto; max-height: 128px;">
  //             <ul>
  //               {{#each UnitList}}
  //               <li class="item-content item-input item-link unitBtn" data-unit="{{id}}" style="padding-left: 0; min-height: 2rem;">
  //                 <div class="item-inner">
  //                   <div class="item-title item-label" style="width: 100%;">{{text}} {{js "this.ConversionRatio > 1 ? ('('+this.ConversionRatio+')') : ''"}} - {{LastPurchaseObjectName}}</div>
  //                 </div>
  //               </li>
  //               {{else}}
  //               {{#each ../UnitConversions}}
  //               <li class="item-content item-input" style="padding-left: 0; min-height: 2rem;">
  //                 <div class="item-inner">
  //                   <div class="item-title item-label">ĐVT</div>
  //                   <div class="unit text-color-blue" style="text-align: right">
  //                     {{text}}
  //                   </div>
  //                 </div>
  //               </li>
  //               {{else}}
  //               <li class="item-content item-input" style="padding-left: 0; min-height: 2rem;">
  //                 <div class="item-inner">
  //                   <div class="item-title item-label">ĐVT</div>
  //                   <div class="unit text-color-blue" style="text-align: right">
  //                     {{text ../Unit '--'}}
  //                   </div>
  //                 </div>
  //               </li>
  //               {{/each}}
  //               {{/each}}
  //               {{#if OriginPrice}}
  //               <li class="item-content item-input" style="padding-left: 0; min-height: 2rem;">
  //                 <div class="item-inner">
  //                   <div class="item-title item-label">Giá cũ</div>
  //                   <div class="price text-color-red" style="text-align: right; font-weight: bold">
  //                     {{currency OriginPrice coalesce="--"}}
  //                   </div>
  //                 </div>
  //               </li>
  //               {{/if}}
  //               <li class="item-content item-input" style="padding-left: 0; min-height: 2rem;">
  //                 <div class="item-inner" style="padding-top: var(--f7-list-item-padding-vertical);
  //                 padding-bottom: var(--f7-list-item-padding-vertical);">
  //                   <div>
  //                     <i class="openProduct f7-icons text-color-gray link" style="font-size: 21px; float: left; line-height: 26px; margin-right: 0.5rem;">square_pencil</i>
  //                     <i class="copyProduct f7-icons text-color-gray link" style="font-size: 20px; float: left; line-height: 26px; margin-right: 0.5rem; margin-top: 2px;">square_on_square</i>
  //                   </div>
  //                   <!--<i class="chooseOne f7-icons text-color-orange link" style="font-size: 32px; float: left; line-height: 26px; margin-left: 1rem">cart_fill_badge_plus</i>-->
  //                 </div>
  //               </li>
  //             </ul>
  //           </div>
  //         </div>
  //       </div>
  //       <div class="detail-footer">{{this.CategoriesText}}</div>
  //     </div>
  //   </div>
  // </div>
  // `;

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: this.componentName,
      path: this.route,
      ...super.f7Component,
    };
  }
}
