import { ProcessMap } from '../../../../src/app/model/model';
import { CommonService } from './../../services/common.service';
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class SalesService {

    constructor(
        public commonService: CommonService
    ) {

    }

    masterPriceTableUpdateNoteprocessMap: { [key: string]: ProcessMap } = {
        // "PROCESSING": {
        //     ...this.commonService.proccessingState,
        //     nextState: 'APPROVED',
        //     nextStates: [
        //         { ...this.commonService.approvedState, confirmLabel: 'Chốt đơn', confirmText: 'Bạn có muốn chốt đơn', status: 'success' },
        //         this.commonService.unrecordedState,
        //     ],
        // },
        "APPROVED": {
            ...this.commonService.approvedState,
            label: 'Đã duyệt',
            nextState: 'NOTJUSTAPPROVED',
            nextStates: [
                this.commonService.notJustApprodedState,
            ],
        },
        // "TRANSPORT": {
        //     ...this.commonService.transportState,
        //     nextState: 'DELIVERED',
        //     nextStates: [
        //         { ...this.commonService.deliveredState, status: 'success' },
        //         this.commonService.unrecordedState,
        //     ],
        // },
        // "DEPLOYMENT": {
        //     ...this.commonService.depploymentState,
        //     nextState: 'DEPLOYED',
        //     nextStates: [
        //         this.commonService.deployedState,
        //         this.commonService.unrecordedState,
        //     ],
        // },
        // "DEPLOYED": {
        //     ...this.commonService.deployedState,
        //     nextState: 'COMPLETED',
        //     nextStates: [
        //         this.commonService.completeState,
        //         this.commonService.unrecordedState,
        //     ],
        // },
        // "DELIVERED": {
        //     ...this.commonService.deliveredState,
        //     nextState: 'COMPLETED',
        //     nextStates: [
        //         this.commonService.completeState,
        //         this.commonService.returnState,
        //         this.commonService.unrecordedState,
        //     ],
        // },
        // "COMPLETED": {
        //     ...this.commonService.completeState,
        //     nextState: 'UNRECORDED',
        //     nextStates: [
        //         this.commonService.unrecordedState,
        //     ],
        // },
        // "RETURN": {
        //     ...this.commonService.returnState,
        //     nextState: 'COMPLETED',
        //     nextStates: [
        //         // { ...this.commonService.completeState, status: 'success' },
        //         this.commonService.completeState,
        //         this.commonService.unrecordedState,
        //     ],
        // },
        // "UNRECORDED": {
        //     ...this.commonService.unrecordedState,
        //     nextState: 'PROCESSING',
        //     nextStates: [
        //         this.commonService.proccessingState,
        //     ],
        // },
        "NOTJUSTAPPROVED": {
            ...this.commonService.notJustApprodedState,
            nextState: 'APPROVED',
            nextStates: [
                this.commonService.approvedState,
            ],
        },
    };
}