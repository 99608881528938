import { F7ComponentContext } from '../types/framework7-types';
import { Messages } from 'framework7/build/core/components/messages/messages';
import { F7Messagebar } from '../types/framework7-types';
import { Router } from 'framework7/build/core/modules/router/router';
import { BaseComponent, ComponentState } from '../lib/base-component';
import { RootServices } from '../services/root.services';
import { AccountModel as AccountModel } from '../model/account.model';
import { FileModel } from '../model/file.model';
import { CommonService } from '../services/common.service';
import { Dialog } from 'framework7/build/core/components/dialog/dialog';
import { isPlatform } from '@ionic/angular';
import { AppComponentProcessor } from '../app.component.processor';
import { Template7 } from 'framework7/build/core/framework7.esm.bundle';
import { RecentLoginModel } from '../model/recent-login.model';
import { filter, take } from 'rxjs/operators';
// import * as $ from 'jquery';
declare const $: any;

// declare const $: any;
export interface ComponentStateExtend extends ComponentState {
  [key: string]: any;
}

export class F7ComponentContextExtend extends F7ComponentContext {
  messagebar?: F7Messagebar;
  messages?: Messages.Messages;
  images?: string[];
  responseInProgress?: boolean;
  answers?: string[];
  people?: { name?: string, avatar?: string }[];
  hideToolbar?: () => void;
  sheetToggle?: () => void;
  deleteAttachment?: (e: MouseEvent, index: number) => void;
  handleAttachment?: (e: MouseEvent) => void;
  checkAttachments?: () => void;
  sendMessage?: () => void;
  $root: any;
  // [key: string]: any;

  updatePinCodeDialog?: Dialog.Dialog;
  changePasswordDialog?: Dialog.Dialog;
}

/** Component group manager */
export class AccountComponent extends BaseComponent<ComponentStateExtend> {
  // states: { [key: string]: State } = {};

  title: 'Tài khoản'

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
    public appComponent: AppComponentProcessor,
  ) {
    super(rootServices);
    // // console.log('Click here to open ts file');
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  async refresh(self?: F7ComponentContext) {
    this.rootServices.apiService.getPromise<AccountModel>('/user/users/getInfo').then(userInfo => {
      console.log(userInfo);
      let coreDomain = this.rootServices.apiService.token && this.rootServices.apiService.token.api_url;
      if (coreDomain) {
        const url = new URL(coreDomain);
        coreDomain = url.host;
      }
      // if (userInfo.Avatar) {
      userInfo.Avatar.payload.thumbnail = this.rootServices.apiService.getBaseApiUrl() + '/user/users/' + userInfo.Code + '/avatar';
      // }
      self.$setState({
        account: { ...userInfo, Core: coreDomain }
      });
    });
  }

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: 'account',
      path: '/account',
      component: {
        template: /*html*/`
            <div class="page no-toolbar-x" data-name="account">
              <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a class="link back">
                      <i class="icon icon-back"></i>
                      <span class="if-not-md">{{backTitle}}</span>
                    </a>
                  </div>
                  <div class="title">Tài khoản</div>
                  <div class="right">
                    <a class="link" @click="switchAccount">
                      <span>Chuyển máy chủ</span>
                      <i class="icon icon-forward"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="page-content ptr-content" data-ptr-mousewheel="false" @ptr:refresh="refresh">

                <div class="ptr-preloader">
                    <div class="preloader"></div>
                    <div class="ptr-arrow"></div>
                </div>
            
                <div class="profile-info">
                  <div @click="changeAvatar" class="profile-logo bg-color-gray"
                    style="background-image: url({{js "this.account && this.account.Avatar && this.account.Avatar.payload && this.account.Avatar.payload.thumbnail || ''"}})">
                  </div>
                  <div class="profile-title text-color-default">{{js "this.account && this.account.Name || ''"}}</div>
                  <div class="profile-subtitle text-color-gray">{{js "this.account && this.account.Core"}}</div>
                  <!--<div class="profile-subtitle text-color-gray">{{js "this.account && (this.account.Role == 'Main session' && 'Đăng nhập bằng mật khẩu' || 'Đăng nhập bằng QRCode') || ''"}}</div>-->
                </div>
            
                <!--
                <div class="block-header">Cài đặt tài khoản</div>
                <div class="list simple-list inset">
                  <ul>
                    <li>
                      <span>Online</span>
                      <label class="toggle toggle-init color-blue">
                        <input type="checkbox" checked>
                        <span class="toggle-icon"></span>
                      </label>
                    </li>
                    <li>
                      <span>Chia sẻ vị trí</span>
                      <label class="toggle toggle-init color-black">
                        <input type="checkbox" checked>
                        <span class="toggle-icon"></span>
                      </label>
                    </li>
                    <li>
                      <span>Nhận job</span>
                      <label class="toggle toggle-init color-orange">
                        <input type="checkbox" checked>
                        <span class="toggle-icon"></span>
                      </label>
                    </li>
                  </ul>
                </div>
                -->
            
                <div class="block-header">Thông tin tài khoản</div>
                <div class="list inset profile-form no-hairlines-md">
                  <form autocomplete="off">
                    <ul>
                      <li class="item-content item-input">
                        <div class="item-media"><i class="icon f7-icons text-color-default">person_circle_fill</i></div>
                        <div class="item-inner">
                          <div class="item-title item-label">Tên đầy đủ</div>
                          <div class="item-input-wrap">
                            <input class="profile-field" name="Name" type="text" placeholder="Họ và tên"
                              value="{{js "this.account && this.account.Name"}}">
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-media"><i class="icon f7-icons text-color-default">at_circle_fill</i></div>
                        <div class="item-inner">
                          <div class="item-title item-label">Tên đại diện</div>
                          <div class="item-input-wrap">
                            <input class="profile-field" name="ShortName" type="text" placeholder="Dùng trong xưng hô "
                              value="{{js "this.account && this.account.ShortName || ''"}}" autocomplete="new-password"
                              autocorrect="off">
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-media"><i class="icon f7-icons text-color-default">person_crop_circle</i></div>
                        <div class="item-inner">
                          <div class="item-title item-label">Danh xưng</div>
                          <div class="item-input-wrap">
                            <input class="profile-field" name="Title" type="text" placeholder="Mr/Mis/anh/chị/ông/bà/sếp..."
                              value="{{js "this.account && this.account.Title || ''"}}" autocomplete="new-password"
                              autocorrect="off">
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-media"><i class="icon f7-icons text-color-default">phone_circle_fill</i></div>
                        <div class="item-inner">
                          <div class="item-title item-label">Số điện thoại</div>
                          <div class="item-input-wrap">
                            <input class="profile-field" name="Phone" type="tel" placeholder="Số điện thoại chính"
                              value="{{js "this.account && this.account.Phone || ''"}}" autocomplete="new-password"
                              autocorrect="off">
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-media"><i class="icon f7-icons text-color-default">envelope_fill</i></div>
                        <div class="item-inner">
                          <div class="item-title item-label">Email</div>
                          <div class="item-input-wrap">
                            <input class="profile-field" type="text" name="Email" placeholder="Địa chỉ email"
                              value="{{js "this.account && this.account.Email || ''"}}" autocomplete="new-password"
                              autocorrect="off">
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-media"><i class="icon f7-icons text-color-default">location_fill</i></div>
                        <div class="item-inner">
                          <div class="item-title item-label">Địa chỉ</div>
                          <div class="item-input-wrap">
                            <input class="profile-field" type="text" name="Address" placeholder="Địa chỉ"
                              value="{{js "this.account && this.account.Address || ''"}}" autocomplete="new-password"
                              autocorrect="off">
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-media"><i class="icon f7-icons text-color-default">smiley_fill</i></div>
                        <div class="item-inner clear-after">
                          <div class="item-title item-label">Giới tính</div>
                          <div class="item-input-wrap input-dropdown-wrap">
                            <select class="profile-field" name="Gender" value="{{js "this.account && this.account.Gender || ''"}}">
                              <option value="MALE">Nam</option>
                              <option value="FEMALE">Nữ</option>
                              <option value="OTHER">Khác</option>
                            </select>
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input" style="padding-left: 0">
                        <a class="col button button-fill button-large link" @click="updateAccount" style="width: 100%">Lưu thông tin
                          tài khoản</a>
                      </li>
                    </ul>
                  </form>
                </div>
            
                {{#js_if "!this.isDesktop"}}
                <div class="block-header">Bảo mật</div>
                <div class="list media-list inset">
                  <ul>
                    <li class="item-link" @click="openChangePasswordDialog">
                      <div class="item-content text-color-gray">
                        <div class="item-media"><i style="font-size: 44px" class="icon f7-icons">lock_fill</i></div>
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title">Mật khẩu</div>
                          </div>
                          <div class="item-subtitle">Thay đổi mật khẩu</div>
                        </div>
                      </div>
                    </li>
                    <li class="item-link" @click="openUpdatePinCodeDialog">
                      <div class="item-content text-color-gray">
                        <div class="item-media"><i style="font-size: 44px" class="icon f7-icons">pencil_ellipsis_rectangle</i></div>
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title">Mã PIN</div>
                          </div>
                          <div class="item-subtitle">Thay đổi mã PIN</div>
                        </div>
                      </div>
                    </li>
                    <li class="item-link" @click="scan2login">
                      <div class="item-content text-color-gray">
                        <div class="item-media"><i style="font-size: 44px" class="icon f7-icons">qrcode_viewfinder</i></div>
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title">Scan2Login</div>
                          </div>
                          <div class="item-subtitle">Quét mã để đăng nhập</div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="block-footer">Thay đổi mật khẩu, mã PIN và quản lý các phiên đăng nhập của bạn</div>
                {{/js_if}}
                <div class="block"><br></div>
            
              </div>
            
              <div class="dialog dialog-wrap dialog-update-pin-code">
                <div class="dialog-padding">
                  <div class="dialog dialog-inside">
                    <div class="dialog-inner" style="padding-bottom: 0;">
                      <div class="dialog-title">Bảo mật</div>
                      <div class="dialog-text" style="width: 100%; user-select: initial; text-align: center;">Cập nhật mã PIN</div>
                      <br>
                      <div class="list media-list"
                        style="margin-left: -15px; margin-right: -15px; margin-bottom: 0; margin-top: 0; text-align: left;">
                        <ul>
                          <li class="item-content item-input">
                            <div class="item-media"><i class="icon f7-icons text-color-default">pencil_ellipsis_rectangle</i></div>
                            <div class="item-inner">
                              <div class="item-title item-label">Mã PIN (*)</div>
                              <div class="item-input-wrap">
                                <input class="field" name="PinCode" type="password" pattern="[0-9]*" inputmode="numeric"
                                  maxlength="4" placeholder="nhập mã PIN">
                              </div>
                            </div>
                          </li>
                          <li class="item-content item-input">
                            <div class="item-media"><i class="icon f7-icons text-color-default">lock_open_fill</i></div>
                            <div class="item-inner">
                              <div class="item-title item-label">Mật khẩu hiện tại (*)</div>
                              <div class="item-input-wrap">
                                <input class="field" name="Password" type="password" placeholder="nhập mật khẩu hiện tại">
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="dialog-buttons">
                      <span class="dialog-button color-red closeBtn" @click="closeUpdatePinCodeDialog">Đóng</span>
                      <span class="dialog-button createReferenceChatRoomBtn" @click="updatePinCode">Cập nhật</span>
                    </div>
                  </div>
                </div>
              </div>
            
              <div class="dialog dialog-wrap dialog-change-password">
                <div class="dialog-padding">
                  <div class="dialog dialog-inside">
                    <div class="dialog-inner" style="padding-bottom: 0;">
                      <div class="dialog-title">Bảo mật</div>
                      <div class="dialog-text" style="width: 100%; user-select: initial; text-align: center;">Đổi mật khẩu</div>
                      <br>
                      <div class="list media-list"
                        style="margin-left: -15px; margin-right: -15px; margin-bottom: 0; margin-top: 0; text-align: left;">
                        <ul>
                          <li class="item-content item-input">
                            <div class="item-media"><i class="icon f7-icons text-color-default">lock_open_fill</i></div>
                            <div class="item-inner">
                              <div class="item-title item-label">Mật khẩu hiện tại (*)</div>
                              <div class="item-input-wrap">
                                <input class="field" name="Password" type="password" placeholder="nhập mật khẩu hiện tại">
                              </div>
                            </div>
                          </li>
                          <li class="item-content item-input">
                            <div class="item-media"><i class="icon f7-icons text-color-default">lock_fill</i></div>
                            <div class="item-inner">
                              <div class="item-title item-label">Mật khẩu mới (*)</div>
                              <div class="item-input-wrap">
                                <input class="field" name="NewPassword" type="password" placeholder="nhập mật khẩu mới">
                              </div>
                            </div>
                          </li>
                          <li class="item-content item-input">
                            <div class="item-media"><i class="icon f7-icons text-color-default">lock_fill</i></div>
                            <div class="item-inner">
                              <div class="item-title item-label">Mật khẩu mới (*)</div>
                              <div class="item-input-wrap">
                                <input class="field" name="ReNewPassword" type="password" placeholder="nhập lại mật khẩu mới">
                              </div>
                            </div>
                          </li>
                          <li class="item-content item-input">
                            <div class="item-media"><i class="icon f7-icons text-color-default">pencil_ellipsis_rectangle</i></div>
                            <div class="item-inner">
                              <div class="item-title item-label">Mã PIN (*)</div>
                              <div class="item-input-wrap">
                                <input class="field" name="PinCode" type="password" pattern="[0-9]*" inputmode="numeric"
                                  maxlength="4" placeholder="nhập mã pin">
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="dialog-buttons">
                      <span class="dialog-button color-red closeBtn" @click="closeChangePasswordDialog">Đóng</span>
                      <span class="dialog-button createReferenceChatRoomBtn" @click="changePassword">Đổi mật khẩu</span>
                    </div>
                  </div>
                </div>
              </div>
            
            
            
            </div>
        `,
        style: `
          
        `,
        data() {
          return {
            title: $this.title,
            isDesktop: isPlatform('desktop'),
          };
        },
        methods: {
          async switchAccount() {
            const self: F7ComponentContextExtend = this;

            const recentLogins: RecentLoginModel[] = await $this.rootServices.storage.get('recent_logins').then((recentLogins: RecentLoginModel[]) => {
              return recentLogins.filter(f => f.id != $this.rootServices.authService.token$.value?.getPayload().api_url + $this.rootServices.authService.user$.value?.id).map(item => {
                item.coreName = new URL(item.url).host;
                item.avatar = item.url + '/v3/user/users/' + item.user + '/avatar';
                item.banner = item.url + '/v3/system/settings/banner';
                return item;
              });
            }).catch(err => {
              console.error(err);
              $this.commonService.showError(err);
              return [];
            });

            let listItemTemplate = Template7.compile(/*html*/`
              <div class="list media-list" style="margin-left: -1rem; margin-right: -1rem; margin-bottom: 0.5rem; margin-top: 0.5rem; text-align: left;">
                <ul>
                  {{#each recentLogins}}
                  <li data-id="{{id}}">
                    <a href="#" class="item-link item-content">
                      <div class="item-media"><div class="bg-color-gray" style="border-radius: 50%; overflow: hidden; width: 50px; height: 50px; background-repeat: no-repeat; background-size: cover; background-image: url({{js "this.avatar || ''"}})"></div></div>
                      <div class="item-inner">
                        <div class="item-title" style="white-space: normal;">{{name}}</div>
                        <div class="item-subtitle text-color-gray" style="font-size: 0.7rem; font-weight: bold;">{{coreName}}</div>
                      </div>
                    </a>
                  </li>
                  {{/each}}
                </ul>
              </div>
            `);

            const dialog = $this.currentState.instance.$app.dialog.create({
              title: 'Chuyển máy chủ',
              text: '',
              content: listItemTemplate({ recentLogins }),
              buttons: [
                {
                  text: 'Trở về',
                  bold: true,
                  color: 'red',
                  onClick: () => {

                  }
                },
              ],
              verticalButtons: true,
            });
            dialog.open();

            const listItem = dialog.$el.find('.list li');
            listItem.click(async function () {
              const id = $(this).attr('data-id');

              const recentLogin = recentLogins.find(f => f.id == id);
              if (recentLogin) {

                // Update current token to recent login
                await $this.rootServices.storage.get('recent_logins')
                  .then((recentLogins: RecentLoginModel[]) => {
                    recentLogins = recentLogins || [];
                    const token = $this.rootServices.authService.token$.getValue().getPayload();
                    let currentLogin = recentLogins.find(f => f.core === token.core && f.user == token.user);
                    recentLogins = recentLogins.filter(f => f.core !== currentLogin.core || f.user !== currentLogin.user);
                    const loginUser = $this.rootServices.authService.getUser();
                    currentLogin.name = loginUser.name;
                    currentLogin.avatar = loginUser.avatar && loginUser.avatar.payload && loginUser.avatar.payload.thumbnail;
                    currentLogin.coreName = loginUser.core && loginUser.core.name;
                    currentLogin.coreBanner = loginUser.core && loginUser.core.banner;
                    if (currentLogin.token.user != token.user) {
                      return Promise.reject('token not matched');
                    }
                    currentLogin.token = token;
                    recentLogins.unshift(currentLogin);
                    return $this.rootServices.storage.set('recent_logins', recentLogins);
                  }).catch(err => {
                    console.error(err);
                    $this.commonService.showError(err);
                  });


                await $this.rootServices.storage.remove($this.rootServices.env.tokenStorageKey).then(() => {
                }).catch(err => {
                  console.error(err);
                  $this.commonService.showError(err);
                });

                // Restore login
                await $this.rootServices.authService.restoreLogin(id).then(async authToken => {
                  if (authToken) {
                    const token = authToken.getPayload();
                    return $this.rootServices.storage.get('recent_logins')
                      .then(async (recentLogins: RecentLoginModel[]) => {
                        recentLogins = recentLogins || [];
                        let currentLogin = recentLogins.find(f => f.core === token.core && f.user === token.user);
                        recentLogins = recentLogins.filter(f => f.core !== currentLogin.core || f.user !== currentLogin.user);

                        const loginUser = await $this.rootServices.authService.user$.value;
                        currentLogin.name = loginUser.name;
                        currentLogin.avatar = loginUser.avatar && loginUser.avatar.payload && loginUser.avatar.payload.thumbnail;
                        currentLogin.coreName = loginUser.core && loginUser.core.name;
                        currentLogin.coreBanner = loginUser.core && loginUser.core.banner || '/assets/images/no-image-available.png';
                        currentLogin.banner = loginUser.core && loginUser.core.banner;
                        currentLogin.backgroundBannerColor = loginUser.core && loginUser.core.backgroundBannerColor;
                        if (currentLogin.token.user != token.user) {
                          return Promise.reject('token not matched');
                        }
                        currentLogin.token = token;

                        if (/\/v\d+/.test(currentLogin.url)) {
                          currentLogin.url = currentLogin.url.replace(/\/v\d+/, '');
                        }

                        recentLogins.unshift(currentLogin);
                        $this.rootServices.storage.set('recent_logins', recentLogins);

                        const router: Router.Router = this.$f7router;
                      }).catch(err => {
                        console.error(err);
                        $this.commonService.showError(err);
                      });


                  } else {
                    self.$router.navigate('/login', {
                      context: {
                        recentLogin: recentLogin,
                      }
                    });
                  }
                }).catch(err => {
                  self.$router.navigate('/login', {
                    context: {
                      recentLogin: recentLogin,
                    }
                  });
                  console.error(err);
                  $this.commonService.showError(err);
                });
              }

              console.log(recentLogin);
              dialog.close();
            });
          },
          scan2login() {
            const self: F7ComponentContextExtend = this;
            $this.appComponent.scanQRCode();
          },
          openChangePasswordDialog(e: MouseEvent) {
            const self: F7ComponentContextExtend = this;
            if (self.changePasswordDialog) {
              self.changePasswordDialog.open();
              self.changePasswordDialog.$el.find('.field[name="Password"]').focus();
            }
          },
          closeChangePasswordDialog() {
            const self: F7ComponentContextExtend = this;
            self.changePasswordDialog && self.changePasswordDialog.close();
          },
          changePassword(e: MouseEvent) {
            const self: F7ComponentContextExtend = this;
            const formData: any = {};
            const fieldEles = self.changePasswordDialog.$el.find('.field');
            fieldEles.each((index, fieldEle) => {
              console.log(fieldEle);
              console.log(fieldEle.value);
              formData[fieldEle.name] = fieldEle.value;
            });
            if (!formData.Password) {
              $this.commonService.showError('Bạn chưa nhập mật khẩu hiện tại');
              return;
            }
            if (!formData.NewPassword) {
              $this.commonService.showError('Bạn chưa nhập mật khẩu mới');
              return;
            }
            if (!formData.PinCode) {
              $this.commonService.showError('Bạn chưa nhập mã PIN');
              return;
            }
            if (formData.ReNewPassword !== formData.NewPassword) {
              $this.commonService.showError('Nhập lại mật khẩu chưa khớp');
              return;
            }
            console.log(formData);
            self.$app.preloader.show();
            try {
              $this.rootServices.apiService.putPromise('/user/users', { changePassword: true }, formData).then(rs => {
                // console.log(rs);
                self.changePasswordDialog.close();
                self.$app.preloader.hide();
                fieldEles.each((index, fieldEle) => {
                  fieldEle.value = '';
                });
                $this.commonService.showInfo('Đổi mật khẩu thành công');
              }).catch(err => {
                console.error(err);
                self.$app.preloader.hide();
                $this.commonService.showError(err);
              });
            } catch (err) {
              console.error(err);
              self.$app.preloader.hide();
              $this.commonService.showError(err);
            }
          },
          openUpdatePinCodeDialog(e: MouseEvent) {
            const self: F7ComponentContextExtend = this;
            if (self.updatePinCodeDialog) {
              self.updatePinCodeDialog.open();
              self.updatePinCodeDialog.$el.find('.field[name="PinCode"]').focus();
            }
          },
          closeUpdatePinCodeDialog() {
            const self: F7ComponentContextExtend = this;
            self.updatePinCodeDialog && self.updatePinCodeDialog.close();
          },
          updatePinCode(e: MouseEvent) {
            const self: F7ComponentContextExtend = this;
            const formData: any = {};
            const fieldEles = self.updatePinCodeDialog.$el.find('.field');
            fieldEles.each((index, fieldEle) => {
              console.log(fieldEle);
              console.log(fieldEle.value);
              formData[fieldEle.name] = fieldEle.value;
            });
            if (!formData.PinCode) {
              console.error('Bạn chưa nhập mã pin');
              $this.commonService.showError('Bạn chưa nhập mã pin');
              return;
            }
            if (!formData.Password) {
              console.error('Bạn chưa nhập mật khẩu hiện tại');
              $this.commonService.showError('Bạn chưa nhập mật khẩu hiện tại');
              return;
            }
            console.log(formData);
            self.$app.preloader.show();
            try {
              $this.rootServices.apiService.putPromise('/user/users', { updatePinCode: true }, formData).then(rs => {
                // console.log(rs);
                self.updatePinCodeDialog.close();
                self.$app.preloader.hide();
                fieldEles.each((index, fieldEle) => {
                  fieldEle.value = '';
                });
                $this.commonService.showInfo('Đã cập nhật mã PIN mới');
              }).catch(err => {
                console.error(err);
                self.$app.preloader.hide();
                $this.commonService.showError(err);
              });
            } catch (err) {
              console.error(err);
              self.$app.preloader.hide();
              $this.commonService.showError(err);
            }
          },
          async refresh(e, done) {
            $this.refresh(this).then(rs => {
              done && done();
            });
            return true;
          },
          logout(e: MouseEvent) {
            $this.rootServices.authService.logout();
          },
          // switchAccount(e: MouseEvent) {
          //   $this.rootServices.authService.logout();
          // },
          updateAccount() {
            const self: F7ComponentContextExtend = this;
            const profileData: AccountModel = {};
            const fieldEles = self.$el.find('.profile-field');
            fieldEles.each((index, fieldEle) => {
              console.log(fieldEle);
              console.log(fieldEle.value);
              profileData[fieldEle.name] = fieldEle.value;
            });
            console.log(profileData);
            self.$app.preloader.show();
            $this.rootServices.apiService.putPromise<AccountModel>('/user/users/updateAccount', {}, profileData).then(rs => {
              console.log(rs);
              self.$app.preloader.hide();
              $this.commonService.showInfo("Đã cập nhật thông tin tài khoản");
            }).catch(err => {
              console.error(err);
              $this.commonService.showError(err);
              self.$app.preloader.hide();
            });
          },
          async changeAvatar(e) {
            const self: F7ComponentContextExtend = this;
            console.debug(e);

            try {
              const localFiles = await $this.rootServices.pickFiles();
              console.debug(localFiles);

              let image: FileModel = null;
              if (localFiles[0].dataType === 'url') {
                image = await $this.rootServices.uploadLocalFile(localFiles[0].url as string);
              } else {
                const formData = new FormData();
                const imgBlob = new Blob([localFiles[0].url], {
                  type: localFiles[0].type
                });
                formData.append('file', imgBlob, 'smart-bot-' + Date.now() + '.' + localFiles[0].ext);
                image = await $this.rootServices.uploadFileData(formData);
              }
              console.debug(image);
              e.target.style.backgroundImage = 'url(' + image.Thumbnail + ')';

              // const updateProfileResult = await $this.rootServices.apiService.putPromise('/user/users/updateAccount', {}, { Avatar: image.GlobalId, AvatarThumbnail: image.Thumbnail })
              const updateProfileResult = await $this.rootServices.apiService.putPromise('/user/users/updateAccount', {}, { Avatar: image });
              console.debug('Update profile avatar: ', updateProfileResult);

              // Update auth user
              const oldUser = $this.rootServices.authService.user$.getValue();
              oldUser.avatar = oldUser.avatar || { id: '', payload: {} };
              oldUser.avatar = { ...oldUser.avatar, payload: { ...oldUser.avatar.payload, thumbnail: image.Thumbnail, url: image.DownloadLink } }
              $this.rootServices.authService.user$.next(oldUser);

            } catch (err) {
              console.error(err);
              $this.commonService.showError(err);
            }

          },
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          pageInit(e, page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;

            // Set back title
            self.$setState({ backTitle: self.$route.context && self.$route.context.backTitle || 'Back', title: self.$route.context && self.$route.context.title || 'Tài khoản' });

            self.updatePinCodeDialog = self.$app.dialog.create({
              el: page.$el.find('.dialog-update-pin-code'),
              closeByBackdropClick: true,
            });
            self.changePasswordDialog = self.$app.dialog.create({
              el: page.$el.find('.dialog-change-password'),
              closeByBackdropClick: true,
            });

            $this.onChangedState({ instance: self }, 'main');

            $this.rootServices.apiService.getPromise<AccountModel>('/user/users/getInfo').then(userInfo => {
              console.log(userInfo);
              let coreDomain = $this.rootServices.apiService.token && $this.rootServices.apiService.token.api_url;
              if (coreDomain) {
                const url = new URL(coreDomain);
                coreDomain = url.host;
              }
              // if (userInfo.Avatar) {
              if (!userInfo.Avatar) userInfo.Avatar = {} as any;
              if (!userInfo.Avatar.payload) userInfo.Avatar.payload = {} as any;
              userInfo.Avatar.payload.thumbnail = $this.rootServices.apiService.getBaseApiUrl() + '/user/users/' + userInfo.Code + '/avatar';
              // }
              self.$setState({
                account: { ...userInfo, Core: coreDomain }
              });
            });
          },
          // pageBeforeIn(e, page) {
          //     console.log('page before in');
          // },
          // pageAfterIn(e, page) {
          //     console.log('page after in');
          // },
          // pageBeforeOut(e, page) {
          //     console.log('page before out');
          // },
          // pageAfterOut(e, page) {
          //     console.log('page after out');
          // },
          // pageBeforeUnmount(e, page) {
          //     console.log('page before unmount');
          // },
          // pageBeforeRemove(e, page) {
          //     console.log('page before remove');
          // },
        },
      },
    };
  }
}
