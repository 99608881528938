<ion-app>
    <div id="app" [ngClass]="{'theme-dark': processor.isDarkMode$ | async}">
        <!-- Left panel with cover effect-->
        <div class="panel panel-left panel-cover panel-init theme-dark">
            <div class="view">
                <div class="page">
                    <div class="navbar">
                        <div class="navbar-bg"></div>
                        <div class="navbar-inner">
                            <div class="left">
                                <a class="link panel-close">
                                    <i class="icon icon-back"></i>
                                    <span class="if-not-md">Close</span>
                                </a>
                            </div>
                            <div class="title">ProBox.vn</div>
                        </div>
                    </div>
                    <div class="page-content">
                        
                        <div class="list media-list user-menu-item">
                            <ul>
                                <li class="item-link" (click)="gotoProfile()">
                                    <div class="item-content">
                                        <div class="item-media">
                                            <div style="width: 44px; height: 44px; border-radius: 50%; background-image: url({{account?.Avatar?.SmallImage}}); background-repeat: no-repeat; background-position: center; background-size: cover;"></div>
                                        </div>
                                        <div class="item-inner">
                                            <div class="item-title-row">
                                                <div class="item-title">{{account.Name}}</div>
                                            </div>
                                            <div class="item-subtitle">@{{account.TagName}}</div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <!-- Menu -->
                        <div class="block-title">Danh bạ</div>
                        <div class="list">
                            <ul>
                                <li>
                                    <a  href="#" (click)="goto('/contact-list')" class="item-link item-content">
                                        <div class="item-media"><i class="icon f7-icons">person_alt_circle</i></div>
                                        <div class="item-inner">
                                            <div class="item-title">
                                                Liên hệ
                                                <div class="item-footer">Khách hàng, đối tác,...</div>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div class="block-title">Bio</div>
                        <div class="list">
                            <ul>
                                <li>
                                    <a href="javascript:rootServices.navigate('/bio-page-setting/default') && f7app.panel.close()" class="item-link item-content">
                                        <div class="item-media"><i class="icon f7-icons">gear_alt</i></div>
                                        <div class="item-inner">
                                            <div class="item-title">
                                                Cấu hình
                                                <div class="item-footer">Cài đăt trang BIO</div>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:rootServices.navigate('/bio-page-social') && f7app.panel.close()" class="item-link item-content">
                                        <div class="item-media"><i class="icon f7-icons">person_3</i></div>
                                        <div class="item-inner">
                                            <div class="item-title">
                                                Cộng đồng Bio
                                                <div class="item-footer">Cài đăt trang BIO</div>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:rootServices.navigate('/e-card-setting/default') && f7app.panel.close()" class="item-link item-content">
                                        <div class="item-media"><i class="icon f7-icons">creditcard</i></div>
                                        <div class="item-inner">
                                            <div class="item-title">
                                                eCard
                                                <div class="item-footer">Thông tin eCard</div>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="block-title">Công việc của tôi</div>
                        <div class="list">
                            <ul>
                                <li>
                                    <a href="javascript:f7app.tab.show(f7app.$('#view-pages')) && f7app.panel.close()" class="item-link item-content">
                                        <div class="item-media"><i class="icon f7-icons">bag</i></div>
                                        <div class="item-inner">
                                            <div class="item-title">
                                                CTV Bán Hàng ™
                                                <div class="item-footer">CTV Bán Hàng ™</div>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <!-- End Menu -->

                        <!-- Account -->
                        <div class="block-title">Tài khoản</div>
                        <div class="list">
                            <ul>
                                <li>
                                    <a (click)="gotoToAccount()" class="item-link item-content panel-close">
                                        <div class="item-media"><i class="icon f7-icons">person_alt</i></div>
                                        <div class="item-inner">
                                            <div class="item-title">
                                                Cài đặt cơ bản
                                                <div class="item-footer">Account Settings</div>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:rootServices.navigate('/identity-card-verification') && f7app.panel.close()" class="item-link item-content">
                                        <div class="item-media"><i class="icon f7-icons">creditcard</i></div>
                                        <div class="item-inner">
                                            <div class="item-title">
                                                Định danh
                                                <div class="item-footer">CCCD, Passport,...</div>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <!-- System -->
                        <div class="block-title">HỆ THỐNG/SYSTEM</div>
                        <div class="list">
                            <ul>
                                <li>
                                    <a href="javascript:f7app.tab.show(f7app.$('#view-settings')) && f7app.panel.close()" class="item-link item-content panel-close">
                                        <div class="item-media"><i class="icon f7-icons">gear</i></div>
                                        <div class="item-inner">
                                            <div class="item-title">
                                                Cài đặt
                                                <div class="item-footer">Settings</div>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                <li>
                                    <a (click)="gotoAbout()" class="item-link item-content" class="item-link item-content">
                                        <div class="item-media"><i class="icon f7-icons">info_circle</i></div>
                                        <div class="item-inner">
                                            <div class="item-title">
                                                Giới thiệu
                                                <div class="item-footer">About</div>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a (click)="processor.checkUpdate($event)" class="item-link item-content">
                                        <div class="item-media"><i class="icon f7-icons">tag</i></div>
                                        <div class="item-inner">
                                            <div class="item-title">
                                                Phiên bản
                                                <div class="item-footer">Version</div>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <!-- End System  -->
                        <div class="block">Trợ lý an sinh của bạn</div>
                    </div>


                    <div class="dialog dialog-wrap dialog-pin-code-confirm">
                        <div class="dialog-padding">
                            <div class="dialog dialog-inside">
                                <div class="dialog-inner" style="padding-bottom: 0;">
                                    <div class="dialog-title">Bảo mật</div>
                                    <div class="dialog-text" style="width: 100%; user-select: initial; text-align: center;">Cập nhật mã PIN</div>
                                    <br>
                                    <div class="list media-list" style="margin-left: -15px; margin-right: -15px; margin-bottom: 0; margin-top: 0; text-align: left;">
                                        <ul>
                                            <li class="item-content item-input">
                                                <div class="item-media"><i class="icon f7-icons text-color-default">pencil_ellipsis_rectangle</i></div>
                                                <div class="item-inner">
                                                    <div class="item-title item-label">Mã pin (*)</div>
                                                    <div class="item-input-wrap">
                                                        <input class="field" name="PinCode" type="password" pattern="[0-9]*" inputmode="numeric" maxlength="4" placeholder="nhập mã PIN">
                                                        <span class="input-clear-button"></span>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="dialog-buttons">
                                    <span class="dialog-button color-red closeBtn" (click)="processor.closePinCodeConfig($event)">Đóng</span>
                                    <span class="dialog-button createReferenceChatRoomBtn" (click)="processor.onPinCodeConfirm($event)">Xác nhận</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Right panel with reveal effect-->
        <div class="panel panel-right panel-reveal theme-dark">
            <div class="view">
                <div class="page">
                    <div class="navbar">
                        <div class="navbar-bg"></div>
                        <div class="navbar-inner">
                            <div class="title">Right Panel</div>
                        </div>
                    </div>
                    <div class="page-content">
                        <div class="block">Right panel content goes here</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- buffer area -->
        <div class="list media-list" id="buffer-area">
            <ul>

            </ul>
        </div>

        <!-- Views/Tabs container -->
        <div class="views tabs safe-areas">
            <!-- Tabbar for switching views-tabs -->
            <div class="toolbar toolbar-bottom tabbar-labels tabbar-scrollable-x main">
                <div class="toolbar-inner">
                    <a class="tab-link panel-open">
                        <i class="icon f7-icons if-active">bars_fill</i>
                        <i class="icon f7-icons if-not-active">bars</i>
                        <span class="tabbar-label">Menu</span>
                    </a>
                    <a href="#view-home" class="tab-link tab-link-active">
                        <i class="icon f7-icons if-active">bell_fill<span *ngIf="rootServices.homeBadge > 0" class="badge color-red">{{rootServices.homeBadge}}</span></i>
                        <i class="icon f7-icons if-not-active">bell<span *ngIf="rootServices.homeBadge > 0" class="badge color-red">{{rootServices.homeBadge}}</span></i>
                        <span class="tabbar-label">Thông báo</span>
                    </a>
                    <a href="#view-workplace" class="tab-link">
                        <i class="icon f7-icons if-active">briefcase_fill<span *ngIf="rootServices.workplaceBadge > 0" class="badge color-red">{{rootServices.workplaceBadge}}</span></i>
                        <i class="icon f7-icons if-not-active">briefcase<span *ngIf="rootServices.workplaceBadge > 0" class="badge color-red">{{rootServices.workplaceBadge}}</span></i>
                        <span class="tabbar-label">Thông tin</span>
                    </a>
                    <!-- <a href="#view-e-commerce-platform" class="tab-link">
                        <i class="icon f7-icons if-active">bag_fill</i>
                        <i class="icon f7-icons if-not-active">bag</i>
                        <span class="tabbar-label">Center</span>
                    </a> -->
                    <a href="#view-pages" class="tab-link">
                        <i class="icon f7-icons if-active">flag_fill<span *ngIf="false" class="badge color-red">{{rootServices.workplaceBadge}}</span></i>
                        <i class="icon f7-icons if-not-active">flag<span *ngIf="false" class="badge color-red">{{rootServices.workplaceBadge}}</span></i>
                        <span class="tabbar-label">Trang</span>
                    </a>
                    <!-- <a href="#view-newsfeed" class="tab-link">
                        <i class="icon f7-icons if-active">house_fill</i>
                        <i class="icon f7-icons if-not-active">house</i>
                        <span class="tabbar-label">Bảng tin</span>
                    </a> -->
                    <!-- <a href="#view-csr" class="tab-link">
                        <i class="icon f7-icons if-active">heart_fill</i>
                        <i class="icon f7-icons if-not-active">heart</i>
                        <span class="tabbar-label">Niềm tin</span>
                    </a> -->
                </div>
            </div>

            <!-- Your main view/tab, should have "view-main" class. It also has "tab-active" class -->
            <div id="view-index" class="view view-main view-init-x tab">

            </div>
            <div id="view-home" class="view view-main view-init-x tab" data-url="/home">

            </div>

            <!-- Notificaitons View -->
            <div id="view-notifications" class="view view-init tab tab-active" data-name="notifications" data-url="/home">
                <!-- Catalog page will be loaded here dynamically from /catalog/ route -->
            </div>

            <!-- Helpdesk View -->
            <!-- <div id="view-helpdesk" class="view view-init-x tab" data-name="helpdesk" data-url="/helpdesk"> -->
            <!-- Settings page will be loaded here dynamically from /settings/ route -->
            <!-- </div> -->

            <!-- Workplace View -->
            <div id="view-workplace" class="view tab" data-name="workplace" data-url="/workplace">
                <!-- Settings page will be loaded here dynamically from /settings/ route -->
            </div>

            <!-- Pages View -->
            <div id="view-pages" class="view tab" data-name="pages" data-url="/pages"></div>

            <!-- E-Commerce Platform View -->
            <div id="view-e-commerce-platform" class="view tab" data-name="e-commerce-platform" data-url="/e-commerce-platform"></div>

            <!-- Newsfeed View -->
            <div id="view-newsfeed" class="view tab" data-name="newsfeed" data-url="/newsfeed"></div>

            <!-- CSR View -->
            <div id="view-csr" class="view tab" data-name="csr" data-url="/csr"></div>

            <!-- Contacts View -->
            <div id="view-contacts" class="view tab" data-name="contacts-view" data-url="/contacts-view">
                <!-- Settings page will be loaded here dynamically from /settings/ route -->
            </div>

            <!-- Company View -->
            <div id="view-company-list" class="view tab" data-name="company-list" data-url="/company-list">
                <!-- Settings page will be loaded here dynamically from /settings/ route -->
            </div>

            <!-- Settings View -->
            <div id="view-settings" class="view tab" data-name="settings" data-url="/settings">
                <!-- Settings page will be loaded here dynamically from /settings/ route -->
            </div>

            <!-- Settings View -->
            <div id="view-dialpad" class="view tab" data-name="dialpad" data-url="/dialpad">
                <!-- Settings page will be loaded here dynamically from /settings/ route -->
            </div>

        </div>

        <!-- Popup -->
        <div class="popup" id="my-popup">
            <div class="view">
                <div class="page">
                    <div class="navbar">
                        <div class="navbar-bg"></div>
                        <div class="navbar-inner">
                            <div class="title">Popup</div>
                            <div class="right">
                                <a href="#" class="link popup-close">Close</a>
                            </div>
                        </div>
                    </div>
                    <div class="page-content">
                        <div class="block">
                            <p>Popup content goes here.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Login Screen -->
        <div class="login-screen" id="my-login-screen">
            <div class="view">
                <div class="page">
                    <div class="page-content login-screen-content">
                        <div class="login-screen-title">Login</div>
                        <div class="list">
                            <ul>
                                <li class="item-content item-input">
                                    <div class="item-inner">
                                        <div class="item-title item-label">Username</div>
                                        <div class="item-input-wrap">

                                            <input type="text" name="username" placeholder="Your username">
                                        </div>
                                    </div>
                                </li>
                                <li class="item-content item-input">
                                    <div class="item-inner">
                                        <div class="item-title item-label">Password</div>
                                        <div class="item-input-wrap">

                                            <input type="password" name="password" placeholder="Your password">
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="list">
                            <ul>
                                <li>

                                    <a href="#" class="item-link list-button login-button">Sign In</a>
                                </li>
                            </ul>
                            <div class="block-footer">Some text about login information.<br>Click "Sign In" to close Login Screen
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ion-app>