import { Router } from "framework7/build/core/modules/router/router";
import { CollaboratorOrderModel } from "src/app/model/collaborator.model";
import { RootServices } from "src/app/services/root.services";
import { F7Page } from "src/app/types/framework7-types";
import { CollaboratorStateMap } from "vendors/smart-bot-app/src/app/components/collaborator/collaborator.state.map";
import { ListComponentStateExtend, F7ListComponentContext, BaseListComponent } from "vendors/smart-bot-app/src/app/lib/base-list-component";
import { ChatRoom } from "vendors/smart-bot-app/src/app/lib/nam-chat/chat-room";
import { ContactModel } from "vendors/smart-bot-app/src/app/model/contact.model";
import { Model } from "vendors/smart-bot-app/src/app/model/model";
import { ProductModel } from "vendors/smart-bot-app/src/app/model/product.model";
import { CommonService } from "vendors/smart-bot-app/src/app/services/common.service";

// import * as $ from 'jquery';
declare const $: any;

// declare const $: any;
export interface ComponentStateExtend extends ListComponentStateExtend<CollaboratorOrderModel> {
    [key: string]: any;
    instance?: F7ComponentContextExtend;
}

export interface F7ComponentContextExtend extends F7ListComponentContext<CollaboratorOrderModel> {
    responseInProgress?: boolean;
    listItems?: ProductModel[];
    $root: any;
    $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, chatRoom?: ChatRoom } };
}

/** Component group manager */
export class PageCollaboratorReportPublisherCommissionListComponent extends BaseListComponent<ComponentStateExtend, CollaboratorOrderModel> {

    namespace: string = 'collaborator-order-list';
    // states: { [key: string]: State } = {};

    title = 'DS Báo cáo CK CTV';
    apiPath = '/collaborator/report/publisher/commissions';
    idKey = 'Code';
    limit = 20;


    filterSchema = {
        Publisher: {
            type: 'autocomplete',
            autocompleteOption: { minSearchInputLength: 0, autoFocus: false, limit: 20 },
            ajax: (query: any) => {
                return this.rootServices.apiService.getPromise<ContactModel[]>('/collaborator/publishers', { search: query, includeIdText: true }).then(results => {
                    return results.map(m => {
                        m.id = this.commonService.getObjectId(m.Contact);
                        m.text = `${m.Name}`;
                        return m;
                    });
                });
            },
            dataType: 'multiple'
        },
        Object: {
            type: 'autocomplete',
            autocompleteOption: { minSearchInputLength: 0, autoFocus: false, limit: 20 },
            ajax: (query: any) => {
                return this.rootServices.apiService.getPromise<ContactModel[]>('/collaborator/contacts', { search: query, includeIdText: true, includeGroups: true, sort_SearchRank: 'desc' }).then(results => {
                    return results.map(m => {
                        m.text = `${m.Name}${m.Groups ? ' (' + m.Groups.map(g => this.commonService.getObjectText(g as any)).join(', ') + ')' : ''}`;
                        return m;
                    });
                });
            },
            dataType: 'multiple'
        },
        PublisherSupporter: {
            type: 'autocomplete',
            autocompleteOption: { minSearchInputLength: 0, autoFocus: false, limit: 20 },
            ajax: (query: any) => {
                return this.rootServices.apiService.getPromise<Model[]>('/collaborator/publisher-supporters', { search: query, includeIdText: true }).then(results => {
                    return results.map(m => {
                        m.id = this.commonService.getObjectId(m.Contact);
                        m.text = `${m.Name} (${m.Contact})`;
                        return m;
                    });
                });
            },
            dataType: 'multiple'
        },
        SalesManager: {
            type: 'autocomplete',
            autocompleteOption: { minSearchInputLength: 0, autoFocus: false, limit: 20 },
            ajax: (query: any) => {
                return this.rootServices.apiService.getPromise<Model[]>('/collaborator/sales-managers', { search: query, includeIdText: true }).then(results => {
                    return results.map(m => {
                        m.id = this.commonService.getObjectId(m.Contact);
                        m.text = `${m.Name} (${m.Contact})`;
                        return m;
                    });
                });
            },
            dataType: 'multiple'
        },
        Seller: {
            type: 'autocomplete',
            autocompleteOption: { minSearchInputLength: 0, autoFocus: false, limit: 20 },
            ajax: (query: any) => {
                return this.rootServices.apiService.getPromise<Model[]>('/collaborator/sellers', { search: query, includeIdText: true }).then(results => {
                    return results.map(m => {
                        m.id = this.commonService.getObjectId(m.Contact);
                        m.text = `${m.Name} (${m.Contact})`;
                        return m;
                    });
                });
            },
            dataType: 'multiple'
        },
        State: {
            type: 'smart-select',
            // dataType: 'multiple'
        },
        DateOfOrder: {
            type: 'datepicker',
            dataType: 'range',
            format: 'dd/mm/yyyy'
        },
    };

    constructor(
        public rootServices: RootServices,
        public commonService: CommonService,
    ) {
        super(rootServices, commonService);
        // // console.log('Click here to open ts file');

        this.voucherStateMap['INQUEUE'] = {
            text: 'hàng đợi',
            color: 'color-orange',
            icon: 'square_stack_3d_down_right_fill'
        };
    }

    onF7pageRemove(chatRoomId: string) {
        // if (this.chatRoomCacheList[chatRoomId]) {
        //   this.chatRoomCacheList[chatRoomId].disconnect();
        // }
    }

    async onComponentInit(state: ComponentStateExtend, index: string, asCase?: string, f7Page?: F7Page) {
        return super.onComponentInit(state, index, asCase, f7Page).then(currentState => {
            this.rootServices.apiService.getPromise<any[]>('/collaborator/pages/' + state.instance.$route.params?.pageId, {}).then(rs => rs[0]).then(pageInfo => {
                state.instance.$setState({
                    pageInfo: pageInfo,
                });
            });
            return currentState;
        });
    }

    prepareItem(item: CollaboratorOrderModel, index?: number) {
        item.DateText = this.rootServices.datePipe.transform(item.Reported, 'short');
        const processMap = CollaboratorStateMap.publisherCommissionStateMap[this.commonService.getObjectId(item.State)];
        // item.CreatorText = this.commonService.getObjectText(item.Creator);
        // if (!item.RelativeVouchers) {
        //     item.RelativeVouchers = [];
        // }
        // item.RelativeVouchers.map(m => {
        //     if (typeof m.state == 'string') {
        //         m.state = m.state.trim();
        //     }
        //     m.state = this.commonService.stateMap[m.state] || m.state;
        //     m.type = this.commonService.voucherTypeMap[m.type] || m.type;
        //     return m;
        // });

        // if (!item.Publishers) {
        //     item.Publishers = [];
        // }
        // if (item.Publisher) {
        //     if (item.Publishers.findIndex(f => this.commonService.getObjectId(f) == this.commonService.getObjectId(item.Publisher)) < 0) {
        //         item.Publishers.push(item.Publisher);
        //     }
        // }
        // item = {
        //     ...item,
        //     ...item.ReportData,
        // };
        for (const key in item.ReportData) {
            if (['ReportData', 'Id', 'Code', 'SystemUuid', 'State'].indexOf(key) < 0) {
                item[key] = item.ReportData[key];
            }
        }
        item.State = {
            id: this.commonService.getObjectId(item.State),
            text: processMap && processMap.text || '--',
            ...processMap
        };
        item.MainSummary = item.Summary['REPORTBYVOUCHER'][item.MainReportPart];
        item.HeadSummary = {
            GenerateCredit: 0,
            GeneratePaidAmount: 0,
            BasicCommissionAmount: 0,
            AdvanceCommissionAmount: 0,
            AddonCommissionAmount: 0,
            TotalCommissionAmount: 0,
            RealTotalCommissionAmount: 0,
        };
        for (const part in item.Summary['REPORTBYVOUCHER']) {
            if (part != item.MainReportPart) {
                const summary = item.Summary['REPORTBYVOUCHER'][part];
                item.HeadSummary.GenerateCredit += parseFloat(summary.GenerateCredit || 0);
                item.HeadSummary.GeneratePaidAmount += parseFloat(summary.GeneratePaidAmount || 0);
                item.HeadSummary.BasicCommissionAmount += parseFloat(summary.BasicCommissionAmount || 0);
                item.HeadSummary.AdvanceCommissionAmount += parseFloat(summary.AdvanceCommissionAmount || 0);
                item.HeadSummary.AddonCommissionAmount += parseFloat(summary.AddonCommissionAmount || 0);
                item.HeadSummary.RealTotalCommissionAmount += parseFloat(summary.RealTotalCommissionAmount || 0);
            }
        }
        return item;
    }

    async getList(self?: F7ComponentContextExtend, extParams?: any): Promise<CollaboratorOrderModel[]> {
        return this.rootServices.apiService.getPromise<CollaboratorOrderModel[]>(this.apiPath, {
            page: self.$route.params?.pageId,
            includePublisher: true,
            ...this.parseFilter(this.currentState?.filter),
            ...extParams
        }).then(list => {
            return list;
        }).catch(err => {
            console.error(err);
            this.commonService.showError(err);
            return Promise.reject(err);
        });
    }
    async changeState(item: CollaboratorOrderModel) {
        // const newState = this.currentState.data.State == 'APPROVED' ? 'NOTJUSTAPPROVED' : 'APPROVED';
        const $this = this;
        const currentState = CollaboratorStateMap.orderStateMap[this.commonService.getObjectId(item.State)];
        if (currentState && currentState.nextStates) {
            const supplierDialog = this.currentState.instance.$app.dialog.create({
                cssClass: 'dialog-large',
                title: 'Chuyển trạng thái',
                text: 'Phiếu đang ở trạng thái `' + currentState.label + '`, bạn có muốn chuyển sang các trạng thái bên dưới ?',
                buttons: [
                    ...currentState.nextStates.map((m, index) => ({
                        text: (index + 1) + '. ' + m.confirmLabel,
                        color: 'success',
                        onClick: () => {
                            $this.rootServices.apiService.putPromise($this.apiPath, { id: item.Code, changeState: m.state }, [{ Code: item.Code }]).then(rs => {
                                // this.currentState.data.State = m.state;
                                item = $this.prepareItem({
                                    ...item,
                                    State: {
                                        id: m.state,
                                        text: m.label,
                                        ...m,
                                    }
                                });
                                const vitem = $this.currentState.instance.virtualList.items.findIndex(f => f.Code == item.Code);
                                $this.currentState.instance.virtualList.replaceItem(vitem, item);
                                this.commonService.showInfo(m.responseText);
                            }).catch(err => {
                                this.commonService.showError(err);
                            });
                        }
                    })),
                    {
                        text: 'Trở về',
                        bold: true,
                        color: 'red'
                    },
                ],
                verticalButtons: true,
            });
            supplierDialog.open();
        }
    }

    itemBeforeInsert(element: HTMLElement, item: CollaboratorOrderModel): void {
        const $this = this;
        $(element).find('.approveBtn').unbind('click').click(function () {
            $this.changeState(item);
            return false;
        });
        $(element).find('.moreBtn').unbind('click').click(function () {
            $this.currentState.instance.$app.dialog.create({
                title: 'Phiếu báo giá',
                text: item.Title,
                buttons: [
                    {
                        text: 'Download PDF',
                        color: 'green',
                        onClick: (dialog, e) => {
                            $this.downloadPdf(item);
                        }
                    },
                    {
                        text: 'Trở về',
                        bold: true,
                        color: 'red'
                    },
                ],
                verticalButtons: true,
            }).open();
            return false;
        });
        return;
    }

    downloadPdf(item: CollaboratorOrderModel) {
        this.rootServices.iab.create(this.rootServices.apiService.buildApiUrl(this.apiPath, { id: item.Code, includeObject: true, includeContact: true, includeDetails: true, includeUnit: true, renderPdf: 'download' }) as string, '_system');
    }

    async onItemClick(self: F7ComponentContextExtend, item: CollaboratorOrderModel) {
        const id = item[this.idKey];
        this.rootServices.navigate('/page/collaborator/report/publisher/commission/form/' + id, {
            context: {
                backTitle: this.title,
                pageInfo: self.pageInfo,
                textColorClass: self.textColorClass,
                callback: (data: CollaboratorOrderModel, state: any) => {
                    console.log('Callback data: ', data);
                    // this.refresh();
                    if (state?.lastAction == 'UPDATE_SUCCESS') {
                        if (data?.Code) {
                            const index = self.virtualList.items.findIndex(f => f.Code == item.Code);
                            if (index > -1) {
                                self.virtualList.replaceItem(index, this.prepareItem(data));
                            }
                        }
                    }
                }
            }
        });
    }

    itemHeight = 275;
    itemTemplate = /*html*/`
    <li data-id="{{Code}}" data-chat-room="{{Code}}" style="height: ${this.itemHeight}px; overflow: hidden" class="swipeout">
        <a class="item-link item-content swipeout-content">
            <div class="item-inner" style="height: ${this.itemHeight}px">
                <div class="item-title-row">
                    <div class="item-title text-color-default description">
                        <div class="item-title-text">{{Title}}</div>
                    </div>
                    <div class="item-after text-color-blue" style="font-size: 0.8rem">
                        <span class="text-{{State.color}} nowrap"><i class="icon f7-icons" style="text-align: right;">{{State.icon}}</i></span>
                    </div>
                </div>

                <div class="item-subtitle text-color-gray" style="display: flex; align-items: center; justify-content: space-between;">
                    <div style="display: flex; align-items: center">
                        <i class="icon f7-icons" style="margin-right: 4px;">compass_fill</i>
                        <div class="description">Số phiếu: {{Code}}</div>
                    </div>
                    {{#if State}}
                    <div class="item-subtitle" style="float: right;">
                        <div class="chat-list-item-label text-color-{{js "this.State.color"}}">
                            {{text State}}
                        </div>
                    </div>
                    {{/if}}
                </div>
                
                {{#if DateOfReport}}
                <div class="item-subtitle text-color-gray" style="display: flex; align-items: center; justify-content: space-between;">
                    <div style="display: flex; align-items: center">
                        <i class="icon f7-icons" style="margin-right: 4px;">timer_fill</i>
                        <div class="description">Ngày báo cáo</div>
                    </div>
                    <div style="display: flex; align-items: center">{{date DateOfReport format="short"}}</div>
                </div>
                {{/if}}
                
                {{#if ObjectName}}
                <div class="item-subtitle text-color-gray" style="display: flex; align-items: center">
                    <i class="icon f7-icons" style="margin-right: 4px;">person_crop_circle</i>
                    <div class="description" style="overflow: hidden; text-overflow: ellipsis;">CTV: {{ObjectName}}</div>
                </div>
                {{/if}}
                <div class="item-subtitle text-color-gray">
                    <div class="chat-list-item-label"><i class="icon f7-icons">bag_fill</i>Doanh số</div>
                    <div class="text-color-blue" style="float: right">{{objectcurrency this 'MainSummary.GenerateCredit' '--'}}</div>
                </div>
                <div class="item-subtitle text-color-gray">
                    <div class="chat-list-item-label"><i class="icon f7-icons">doc_checkmark_fill</i>Đã Thu được {{GeneratePaidRatio}}%</div>
                    <div class="text-color-blue" style="float: right">{{objectcurrency this 'MainSummary.GeneratePaidAmount' '--'}}</div>
                </div>
                <div class="item-subtitle text-color-gray">
                    <div class="chat-list-item-label"><i class="icon f7-icons">gift_alt</i>CK Cơ bản</div>
                    <div class="text-color-green" style="float: right">{{objectcurrency this 'MainSummary.BasicCommissionAmount' '--'}}</div>
                </div>
                <div class="item-subtitle text-color-gray">
                    <div class="chat-list-item-label"><i class="icon f7-icons">gift_alt</i>CK Nâng cao</div>
                    <div class="text-color-green" style="float: right">{{objectcurrency this 'MainSummary.AdvanceCommissionAmount' '--'}}</div>
                </div>
                <div class="item-subtitle text-color-gray">
                    <div class="chat-list-item-label"><i class="icon f7-icons">gift_alt</i>CK Add-on</div>
                    <div class="text-color-green" style="float: right">{{objectcurrency this 'MainSummary.AddonCommissionAmount' '--'}}
                    </div>
                </div>
                <div class="item-subtitle text-color-gray">
                    <div class="chat-list-item-label"><i class="icon f7-icons">gift_alt_fill</i>Tổng chiết khấu</div>
                    <div class="text-color-lightblue" style="float: right">{{objectcurrency this 'MainSummary.TotalCommissionAmount' '--'}}</div>
                </div>
                <div class="item-subtitle text-color-gray">
                    <div class="chat-list-item-label"><i class="icon f7-icons">gift_fill</i>CK thực nhận kỳ trước</div>
                    <div class="text-color-blue" style="float: right">{{objectcurrency this 'HeadSummary.RealTotalCommissionAmount' '--'}}</div>
                </div>
                <div class="item-subtitle text-color-gray">
                    <div class="chat-list-item-label"><i class="icon f7-icons">gift_fill</i>CK thực nhận</div>
                    <div class="text-color-blue" style="float: right">{{objectcurrency this 'MainSummary.RealTotalCommissionAmount' '--'}}</div>
                </div>
            </div>
        </a>

        <div class="swipeout-actions-right">
            <a href="#" class="approveBtn open-more-actions color-orange swipeout-close"> {{js "this.State && this.State.label"}}</a>
            <a href="#" class="moreBtn open-more-actions color-gray swipeout-overswipe swipeout-close">Thêm</a>
        </div>
    </li>
  `;

    get f7Component(): Router.RouteParameters {
        const $this = this;
        return {
            name: this.namespace,
            path: '/page/collaborator/report/publisher/commission/list/:pageId',
            component: {
                template: /*html*/`
            <div class="page no-toolbar-x ${this.namespace}" data-name="${this.namespace}">
              <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a class="link back {{textColorClass}}">
                      <i class="icon icon-back"></i>
                      <span class="if-not-md {{textColorClass}}">{{js "this.backTitle || 'Back'"}}</span>
                    </a>
                  </div>
                  <div class="title">${this.title}</div>
                  <div class="right">
                    <!-- Link to enable searchbar -->
                    <a class="link icon-only searchbar-enable {{textColorClass}}" data-searchbar=".${this.namespace}-searchbar">
                      <i class="icon f7-icons if-not-md">search</i>
                      <i class="icon material-icons md-only">search</i>
                    </a>
                  </div>
                  <!-- Searchbar is a direct child of "navbar-inner" -->
                  <form class="searchbar ${this.namespace}-searchbar searchbar-expandable">
                    <div class="searchbar-inner">
                      <div class="searchbar-input-wrap">
                        <input type="search" placeholder="Search" />
                        <i class="searchbar-icon"></i><span class="input-clear-button"></span>
                        
                      </div>
                      <span class="searchbar-disable-button {{textColorClass}}">Hủy</span>
                    </div>
                  </form>
                </div>
              </div>
              <div class="page-content ptr-content infinite-scroll-content" @ptr:refresh="refresh">
                <div class="ptr-preloader">
                  <div class="preloader"></div>
                  <div class="ptr-arrow"></div>
                </div>
                <div class="searchbar-backdrop ${this.namespace}-backdrop"></div>
                <!--
                <div class="block-title" style="margin-top: 2rem">Bộ lọc <a style="float: right" @click="resetFilter"
                class="text-color-red">đặt lại</a></div>
                <div class="block block-strong inset filter" style="padding: 0; overflow: hidden">
                  <div class="list accordion-list inline-labels no-hairlines" style="margin: 0;">
                  <ul>
                    <li class="autocomplete" name="Publisher">
                      <div class="item-link smart-select item-content" href="#">
                        <div class="item-inner" style="display: flex;">
                          <div class="item-title">CTV Bán Hàng</div>
                          <div class="item-after">{{text filter.Publisher 'Chọn...'}}</div>
                        </div>
                      </div>
                    </li>
                    <li class="autocomplete" name="Object">
                      <div class="item-link smart-select item-content" href="#">
                        <div class="item-inner" style="display: flex;">
                          <div class="item-title">Khách hàng</div>
                          <div class="item-after">{{text filter.Object 'Chọn...'}}</div>
                        </div>
                      </div>
                    </li>
                    <li class="item-link smart-select smart-select-init" name="State" data-open-in="popup" data-searchbar="false" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFilterFieldChange">
                      <select>
                        <option value="">--</option>
                        {{#each processMap}}
                        <option value="{{state}}">{{label}}</option>
                        {{/each}}
                      </select>
                      <div class="item-content">
                        <div class="item-inner">
                          <div class="item-title">Trạng thái</div> 
                        </div>
                      </div>
                    </li>
                    <li class="accordion-item">
                      <a class="item-content item-link">
                        <div class="item-inner">
                          <div class="item-title" style="font-weight: normal" >Thêm</div>
                          <div class="item-after"><span class="badge">xổ xuống</span></div>
                        </div>
                      </a>
                      <div class="accordion-item-content">
                        <div class="list inline-labels no-hairlines" style="margin: 0;">
                          <ul>
                            <li class="autocomplete" name="PublisherSupporter">
                              <div class="item-link smart-select item-content" href="#">
                                <div class="item-inner" style="display: flex;">
                                  <div class="item-title">NV Chăm sóc CTV</div>
                                  <div class="item-after">{{text filter.PublisherSupporter 'Chọn...'}}</div>
                                </div>
                              </div>
                            </li>
                            <li class="autocomplete" name="Seller">
                              <div class="item-link smart-select item-content" href="#">
                                <div class="item-inner" style="display: flex;">
                                  <div class="item-title">NV Sale</div>
                                  <div class="item-after">{{text filter.Seller 'Chọn...'}}</div>
                                </div>
                              </div>
                            </li>
                            <li class="autocomplete" name="SalesManager">
                              <div class="item-link smart-select item-content" href="#">
                                <div class="item-inner" style="display: flex;">
                                  <div class="item-title">QL Sales</div>
                                  <div class="item-after">{{text filter.SalesManager 'Chọn...'}}</div>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div class="item-content item-input">
                                <div class="item-inner">
                                  <div class="item-title item-label">Thời gian</div>
                                  <div class="item-input-wrap">
                                    <input class="calendar" name="DateOfOrder" type="text" placeholder="Ngày tạo" readonly="readonly" style="text-align: right;"/>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>

                  </ul>
                  </div>
                </div>
                -->

                <div class="block-title">
                  <div>${this.title}</div>
                </div>
                <div class="virtual-list list media-list ${this.namespace}-virtual-list inset list-complex-info">
                  
                </div>
                {{#if infiniteLoadLast}}
                    <div style="text-align: center" class="text-color-gray">end</div>
                {{else}}
                    <div class="preloader color-blue infinite-scroll-preloader">
                        <span class="preloader-inner">
                            <span class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span>
                        </span>
                    </div>
                {{/if}}
            
                <div class="block"><br></div>
            
              </div>
            </div>
        `,
                style: /*css*/`
          .page.${this.namespace} .find-order {
            font-size: 16px;
            font-weight: bold;
          }
          .page.${this.namespace} .action-bar {
            color: var(--f7-block-strong-text-color);
            padding-top: var(--f7-block-padding-vertical);
            padding-bottom: var(--f7-block-padding-vertical);
            background-color: var(--f7-block-strong-bg-color);
          }
          .page.${this.namespace} .virtual-list i.f7-icons {
            font-size: 16px;
          }
          .page.${this.namespace} .list-complex-info ul li .item-title .item-title-text {
            -webkit-line-clamp: 1;
          }
          .page.${this.namespace} .list-complex-info ul li .item-title .item-title-text {
            -webkit-line-clamp: 1;
          }
          .page.${this.namespace} .list-complex-info ul li .item-title {
            height: 18px;
          }
          .page.${this.namespace} .probox-link {
            display: flex;
            align-items: center;
          }
          .page.${this.namespace} .probox-link img {
            width: 1.1rem;
            height: 1.1rem;
            margin-right: 0.25rem;
          }
        `,
                data() {
                    return {
                        title: $this.title,
                        // reminder: {
                        //   No: '234234',
                        // },
                        priceReport: { Details: [] },
                        filter: {},
                        processMap: CollaboratorStateMap.orderStateMap,
                    };
                },
                methods: {
                    reportCurrentFilterToPdf() {
                        if (!$this.currentState?.filter?.Publisher) {
                            $this.commonService.showError('Chưa chọn CTV Bán hàng');
                            return;
                        }
                        const fromDate = $this.commonService.getBeginOfMonth();
                        const toDate = $this.commonService.getEndOfDate();
                        $this.rootServices.iab.create($this.rootServices.apiService.buildApiUrl($this.apiPath, {
                            includeCreator: true,
                            includeRelativeVouchers: true,
                            ge_DateOfOrder: fromDate.toISOString(),
                            le_DateOfOrder: toDate.toISOString(),
                            sort_DateOfOrder: 'desc',
                            ...$this.parseFilter($this.currentState?.filter),
                            type: 'pdf'
                        }) as string, '_system');
                    },
                    resetFilter(e) {
                        $this.resetFilter(this, e);
                    },
                    onFilterFieldChange(e) {
                        $this.onFilterFieldChange(this, e);
                    },
                    // Price report
                    openVoucher(e, id?: string) {
                        const self: F7ComponentContextExtend = this;
                        id = id || self.$(e.target).closest('li').data('id') || 'new';

                        $this.rootServices.navigate('/collaborator/order/' + id, {
                            context: {
                                backTitle: $this.title,
                                textColorClass: self.textColorClass,
                                callback: (data: CollaboratorOrderModel, state: any) => {
                                    console.log('Callback data: ', data);
                                    if (state?.lastAction == 'CREATE_SUCCESS') {
                                        self.refresh();
                                    }
                                }
                            }
                        });
                        // }
                    },
                    goto(e) {
                        const self: F7ComponentContextExtend = this;
                        const url = self.$(e.target).closest('.goto').data('url');
                        $this.rootServices.navigate(url);
                    },
                    chooseOne(e) {
                        const self: F7ComponentContextExtend = this;
                        const id = self.$(e.target).closest('li').data('id');
                        self.$route.context['onChoose'] && self.$route.context['onChoose'](self.listItems.find(f => f.Code === id));
                    },
                    chooseProduct(e) {

                    },
                    async refresh(e, done) {
                        const self: F7ComponentContextExtend = this;
                        $this.refresh(self).then(rs => done && done());
                        return true;
                    },
                },
                on: {
                    // pageMounted(e, page) {
                    //     console.log('page mounted');
                    // },
                    pageInit(e, page: F7Page) {
                        console.log('page init');
                        const self: F7ComponentContextExtend = this;
                        // Loading flag
                        let allowInfinite = true;
                        $this.onComponentInit({ instance: self }, 'main', null, page);
                    },
                    // pageBeforeIn(e, page) {
                    //     console.log('page before in');
                    //     const self: F7ComponentContextExtend = this;
                    //     self.refresh();
                    // },
                    // pageAfterIn(e, page) {
                    //     console.log('page after in');
                    // },
                    // pageBeforeOut(e, page) {
                    //     console.log('page before out');
                    // },
                    pageAfterOut(e, page) {
                        console.log('page after out');
                    },
                    // pageBeforeUnmount(e, page) {
                    //     console.log('page before unmount');
                    // },
                    // pageBeforeRemove(e, page) {
                    //     console.log('page before remove');
                    // },
                    pageBeforeRemove(e, page) {
                        console.log('[page event] before remove', page.route.url);
                        const self: F7ComponentContextExtend = this;
                        // const starmtSelectRoles = self.$app.smartSelect.get('.smart-select');
                        // starmtSelectRoles.destroy();
                    },
                },
            },
        };
    }
}
