import { Model } from '../../../model/model';
import { F7ListComponentContext, ListComponentStateExtend } from '../../../lib/base-list-component';
import { F7Page } from '../../../types/framework7-types';
import { Router } from 'framework7/build/core/modules/router/router';
import { RootServices } from '../../../services/root.services';
import { ChatRoom } from '../../../lib/nam-chat/chat-room';
import { CommonService } from '../../../services/common.service';
import { ProductModel } from '../../../model/product.model';
import { BaseListComponent } from '../../../lib/base-list-component';
import { CollaboratorOpportunityModel } from '../../../model/collaborator.model';
import { ContactModel } from '../../../model/contact.model';
import { ContractStateMap } from '../contract.state.map';
// import * as $ from 'jquery';
declare const $: any;

// declare const $: any;
export interface ComponentStateExtend extends ListComponentStateExtend<CollaboratorOpportunityModel> {
  [key: string]: any;
  instance?: F7ComponentContextExtend;
}

export interface F7ComponentContextExtend extends F7ListComponentContext<CollaboratorOpportunityModel> {
  responseInProgress?: boolean;
  listItems?: ProductModel[];
  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, chatRoom?: ChatRoom } };
}

/** Component group manager */
export class ContractListComponent extends BaseListComponent<ComponentStateExtend, CollaboratorOpportunityModel> {

  namespace: string = 'contract-list';
  // states: { [key: string]: State } = {};

  title: 'Hợp đồng';
  apiPath = '/contract/contracts';
  path = '/contract/contract-list';
  idKey = 'Code';
  limit = 20;

  // stateMap = {
  //   'NOTJUSTAPPROVED': {
  //     text: 'chưa duyệt',
  //     color: 'text-color-orange',
  //     icon: 'circle_fill'
  //   },
  //   'APPROVED': {
  //     text: 'đã duyệt',
  //     color: 'text-color-green',
  //     icon: 'person_crop_circle_fill_badge_checkmark'
  //   },
  //   'INQUEUE': {
  //     text: 'hàng đợi',
  //     color: 'text-color-orange',
  //     icon: 'square_stack_3d_down_right_fill'
  //   },
  // };

  filterSchema = {
    // Publisher: {
    //   type: 'autocomplete',
    //   autocompleteOption: { minSearchInputLength: 0, autoFocus: false, limit: 20 },
    //   ajax: (query: any) => {
    //     return this.rootServices.apiService.getPromise<ContactModel[]>('/collaborator/publishers', { search: query, includeIdText: true }).then(results => {
    //       return results.map(m => {
    //         m.id = this.commonService.getObjectId(m.Contact);
    //         m.text = `${m.Name}`;
    //         return m;
    //       });
    //     });
    //   },
    //   dataType: 'multiple'
    // },
    // Customer: {
    //   type: 'autocomplete',
    //   autocompleteOption: { minSearchInputLength: 0, autoFocus: false, limit: 20 },
    //   ajax: (query: any) => {
    //     return this.rootServices.apiService.getPromise<ContactModel[]>('/collaborator/contacts', { search: query, includeIdText: true, includeGroups: true, sort_SearchRank: 'desc' }).then(results => {
    //       return results.map(m => {
    //         m.text = `${m.Name}${m.Groups ? ' (' + m.Groups.map(g => this.commonService.getObjectText(g as any)).join(', ') + ')' : ''}`;
    //         return m;
    //       });
    //     });
    //   },
    //   dataType: 'multiple'
    // },
    // PublisherSupporter: {
    //   type: 'autocomplete',
    //   autocompleteOption: { minSearchInputLength: 0, autoFocus: false, limit: 20 },
    //   ajax: (query: any) => {
    //     return this.rootServices.apiService.getPromise<Model[]>('/collaborator/publisher-supporters', { search: query, includeIdText: true }).then(results => {
    //       return results.map(m => {
    //         m.id = this.commonService.getObjectId(m.Contact);
    //         m.text = `${m.Name} (${m.Contact})`;
    //         return m;
    //       });
    //     });
    //   },
    //   dataType: 'multiple'
    // },
    // SalesManager: {
    //   type: 'autocomplete',
    //   autocompleteOption: { minSearchInputLength: 0, autoFocus: false, limit: 20 },
    //   ajax: (query: any) => {
    //     return this.rootServices.apiService.getPromise<Model[]>('/collaborator/sales-managers', { search: query, includeIdText: true }).then(results => {
    //       return results.map(m => {
    //         m.id = this.commonService.getObjectId(m.Contact);
    //         m.text = `${m.Name} (${m.Contact})`;
    //         return m;
    //       });
    //     });
    //   },
    //   dataType: 'multiple'
    // },
    // Seller: {
    //   type: 'autocomplete',
    //   autocompleteOption: { minSearchInputLength: 0, autoFocus: false, limit: 20 },
    //   ajax: (query: any) => {
    //     return this.rootServices.apiService.getPromise<Model[]>('/collaborator/sellers', { search: query, includeIdText: true }).then(results => {
    //       return results.map(m => {
    //         m.id = this.commonService.getObjectId(m.Contact);
    //         m.text = `${m.Name} (${m.Contact})`;
    //         return m;
    //       });
    //     });
    //   },
    //   dataType: 'multiple'
    // },
    State: {
      type: 'smart-select',
      // dataType: 'multiple'
    },
    Type: {
      type: 'smart-select',
      // dataType: 'multiple'
    },
    // DateOfOpportunity: {
    //   type: 'datepicker',
    //   dataType: 'range',
    //   format: 'dd/mm/yyyy'
    // },
  };

  stateMap: any;

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
  ) {
    super(rootServices, commonService);
    // // console.log('Click here to open ts file');

    this.voucherStateMap['INQUEUE'] = {
      text: 'hàng đợi',
      color: 'color-orange',
      icon: 'square_stack_3d_down_right_fill'
    };

    this.stateMap = {
      ...this.commonService.stateMap,
      ...ContractStateMap.contractStateMap,
    };

  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }
  typeMap: any;
  typeList: any;
  async onComponentInit(state: ComponentStateExtend, index: string, asCase?: string, f7Page?: F7Page) {
    this.typeMap = await this.rootServices.apiService.getPromise<any[]>('/contract/contracts/types', {}).then(rs => {
      this.typeList = rs;
      return rs.reduce((result, current, index) => { result[current.id] = current; return result; }, {});
    });
    return super.onComponentInit(state, index, asCase, f7Page).then(currentState => {

      return currentState;
    });
  }

  prepareItem(item: CollaboratorOpportunityModel, index?: number) {
    item.DateText = this.rootServices.datePipe.transform(item.Reported, 'short');
    const processMap = ContractStateMap.contractStateMap[this.commonService.getObjectId(item.State)];
    item.State = {
      id: this.commonService.getObjectId(item.State),
      text: processMap && processMap.label || '--',
      ...processMap
    };

    item.RelativeVouchers?.map(m => {
      if (typeof m.state == 'string') {
        m.state = m.state.trim();
      }
      if (!m.state) {
        m.state = 'NOTJUSTAPPROVED';
      }
      m.state = this.stateMap[m.state] || m.state;
      m.type = this.commonService.voucherTypeMap[m.type] || m.type;
      return m;
    });

    if (['PRINCIPLE'].indexOf(this.commonService.getObjectId(item.Target)) > -1) {
      item.FollowRelativeVoucher = item.RelativeVouchers?.find(f => this.commonService.getObjectId(f.type) == 'CONTRACT');
      if (item.FollowRelativeVoucher) {
        item.FollowRelativeVoucher.label = 'Mã hợp đồng nguyên tắc';
      }
    }
    if (['SALECONTRACT'].indexOf(this.commonService.getObjectId(item.Target)) > -1) {
      item.FollowRelativeVoucher = item.RelativeVouchers?.find(f => this.commonService.getObjectId(f.type) == 'CONTRACT');
      if (item.FollowRelativeVoucher) {
        item.FollowRelativeVoucher.label = 'Mã hợp đồng mua bán';
      }
    }
    if (['QUICKORDER'].indexOf(this.commonService.getObjectId(item.Target)) > -1) {
      item.FollowRelativeVoucher = item.RelativeVouchers?.find(f => this.commonService.getObjectId(f.type) == 'CLBRTORDER');
      if (item.FollowRelativeVoucher) {
        item.FollowRelativeVoucher.label = 'Mã đơn hàng nhanh';
      }
    }

    if (item.Type) {
      item.Type = this.typeMap[this.commonService.getObjectId(item.Type)] || item.Type;
    }

    return item;
  }

  // async getList(params: any) {
  //   // console.log(self.searchBar);
  //   return this.rootServices.apiService.getPromise<any[]>('/sales/price-reports', {
  //     includeCreator: true,
  //     includeRelativeVouchers: true,
  //     sort_Id: 'desc',
  //     limit: 10,
  //     offset: 0,
  //     ...params,
  //   });
  // }

  async getList(self?: F7ComponentContextExtend, extParams?: any): Promise<CollaboratorOpportunityModel[]> {
    return this.rootServices.apiService.getPromise<CollaboratorOpportunityModel[]>(this.apiPath, {
      // includeCreator: true,
      includeRelativeVouchers: true,
      // includeStateLogs: true,
      // // includePublishers: true,
      // includePublisher: true,
      // includePublisherSupporter: true,
      // includeSeller: true,
      // includeSalesManager: true,
      // sort_DateOfOpportunity: 'desc',
      sort_Id: 'desc',
      ...this.parseFilter(this.currentState?.filter),
      ...extParams
    }).then(list => {
      return list;
    }).catch(err => {
      console.error(err);
      this.commonService.showError(err);
      return Promise.reject(err);
    });
  }
  async changeState(item: CollaboratorOpportunityModel) {
    // const newState = this.currentState.data.State == 'APPROVED' ? 'NOTJUSTAPPROVED' : 'APPROVED';
    const $this = this;
    const currentState = ContractStateMap.contractStateMap[this.commonService.getObjectId(item.State)];
    if (currentState && currentState.nextStates) {
      const supplierDialog = this.currentState.instance.$app.dialog.create({
        cssClass: 'dialog-large',
        title: 'Chuyển trạng thái',
        text: 'Phiếu đang ở trạng thái `' + currentState.label + '`, bạn có muốn chuyển sang các trạng thái bên dưới ?',
        buttons: [
          ...currentState.nextStates.map((m, index) => ({
            text: (index + 1) + '. ' + m.confirmLabel,
            color: 'success',
            onClick: () => {
              $this.rootServices.apiService.putPromise($this.apiPath, { id: item.Code, changeState: m.state }, [{ Code: item.Code }]).then(rs => {
                // this.currentState.data.State = m.state;
                item = $this.prepareItem({
                  ...item,
                  State: {
                    id: m.state,
                    text: m.label,
                    ...m,
                  }
                });
                const vitem = $this.currentState.instance.virtualList.items.findIndex(f => f.Code == item.Code);
                $this.currentState.instance.virtualList.replaceItem(vitem, item);
                this.commonService.showInfo(m.responseText);
                // const itemEle = $($this.currentState.instance.virtualList.$el).find('[data-id="' + item.Code + '"]');
                // this.itemBeforeInsert(itemEle[0], item);
              }).catch(err => {
                this.commonService.showError(err);
              });
            }
          })),
          {
            text: 'Trở về',
            bold: true,
            color: 'red'
          },
        ],
        verticalButtons: true,
      });
      supplierDialog.open();
    }
  }

  itemBeforeInsert(element: HTMLElement, item: CollaboratorOpportunityModel): void {
    const $this = this;
    $(element).find('.approveBtn').unbind('click').click(function () {
      // if ($this.commonService.getObjectId(item.State) != 'APPROVED') {
      // $this.currentState.instance.$app.dialog.confirm('Bạn có muốn duyệt phiếu đặt mua hàng `' + item.Title + '` ?', 'Duyệt phiếu đặt mua hàng', () => {

      //   $this.rootServices.apiService.putPromise<CollaboratorOpportunityModel[]>($this.apiPath + '/' + item.Code, { changeState: 'APPROVED' }, [{ Code: item.Code }]).then(rs => {
      //     $this.commonService.showInfo('Đã duyệt phiếu đặt mua hàng `' + item.Title + '`');
      //     item = $this.prepareItem({
      //       ...item,
      //       State: 'APPROVED'
      //     });
      //     $this.currentState.instance.virtualList.replaceItem($this.currentState.instance.virtualList.items.findIndex(f => f.Code == item.Code), item);
      //   }).catch(err => $this.commonService.showError(err));
      // });
      $this.changeState(item);
      // } else {
      //   $this.currentState.instance.$app.dialog.confirm('Bạn bỏ duyệt phiếu đặt mua hàng `' + item.Title + '` ?', 'Bỏ duyệt phiếu đặt mua hàng', () => {
      //     $this.rootServices.apiService.putPromise<CollaboratorOpportunityModel[]>($this.apiPath + '/' + item.Code, { changeState: 'NOTJUSTAPPROVED' }, [{ Code: item.Code }]).then(rs => {
      //       $this.commonService.showInfo('Đã bỏ duyệt phiếu đặt mua hàng `' + item.Title + '`');
      //       item = $this.prepareItem({
      //         ...item,
      //         State: 'NOTJUSTAPPROVED'
      //       });
      //       $this.currentState.instance.virtualList.replaceItem($this.currentState.instance.virtualList.items.findIndex(f => f.Code == item.Code), item);
      //     }).catch(err => $this.commonService.showError(err));
      //   });
      // }
      return false;
    });
    $(element).find('.moreBtn').unbind('click').click(function () {
      $this.currentState.instance.$app.dialog.create({
        title: 'Phiếu báo giá',
        text: item.Title,
        buttons: [
          {
            text: 'Copy Hợp đồng',
            color: 'orange',
            onClick: (dialog, e) => {

              $this.rootServices.navigate('/contract/contract/new', {
                context: {
                  copyFromId: item.Code,
                  backTitle: $this.title,
                  callback: (data: CollaboratorOpportunityModel, state: any) => {
                    console.log('Callback data: ', data);
                    if (state?.lastAction == 'CREATE_SUCCESS') {
                      $this.refresh();
                    }
                  }
                }
              });
            }
          },
          {
            text: 'Download PDF',
            color: 'green',
            onClick: (dialog, e) => {
              $this.downloadPdf(item);
            }
          },
          {
            text: 'Trở về',
            bold: true,
            color: 'red'
          },
        ],
        verticalButtons: true,
      }).open();
      return false;
    });
    return;
  }

  downloadPdf(item: CollaboratorOpportunityModel) {
    this.rootServices.iab.create(this.rootServices.apiService.buildApiUrl(this.apiPath, { id: item.Code, includeCustomer: true, includeContact: true, includeDetails: true, includeUnit: true, renderPdf: 'download' }) as string, '_system');
  }

  async onItemClick(self: F7ComponentContextExtend, item: CollaboratorOpportunityModel) {
    // const self: F7ComponentContextExtend = this;
    // id = id || self.$(e.target).closest('li').data('id') || 'new';
    const id = item[this.idKey];

    this.rootServices.navigate('/contract/contract/' + id, {
      context: {
        backTitle: this.title,
        textColorClass: self.textColorClass,
        callback: (data: CollaboratorOpportunityModel, state: any) => {
          console.log('Callback data: ', data);
          // this.refresh();
          if (state?.lastAction == 'UPDATE_SUCCESS') {
            if (data?.Code) {
              const index = self.virtualList.items.findIndex(f => f.Code == item.Code);
              if (index > -1) {
                self.virtualList.replaceItem(index, this.prepareItem(data));
              }
            }
          }
        }
      }
    });
  }

  itemHeight = 164;
  itemTemplate = /*html*/`
  <li data-id="{{Code}}" data-chat-room="{{Code}}" style="height: ${this.itemHeight}px; overflow: hidden" class="swipeout">
    <a class="item-link item-content swipeout-content">
        <div class="item-inner">
            <div class="item-title-row">
                <div class="item-title text-color-default description">
                    <div class="item-title-text">{{Title}}</div>
                </div>
                <div class="item-after text-color-blue" style="font-size: 0.8rem">
                    <span class="text-{{State.color}} nowrap"><i class="icon f7-icons" style="text-align: right;">{{State.icon}}</i></span>
                </div>
            </div>

            <div class="item-subtitle text-color-gray" style="display: flex; align-items: center; justify-content: space-between;">
                <div style="display: flex; align-items: center">
                    <i class="icon f7-icons" style="margin-right: 4px;">compass_fill</i>
                    <div class="description">Trạng thái</div>
                </div>
                {{#if State}}
                <div style="display: flex; align-items: center" class="text-color-{{State.color}}">
                    {{text State}}
                </div>
                {{/if}}
            </div>
            
            {{#if Created}}
            <div class="item-subtitle text-color-gray" style="display: flex; align-items: center; justify-content: space-between;">
                <div style="display: flex; align-items: center">
                    <i class="icon f7-icons" style="margin-right: 4px;">timer_fill</i>
                    <div class="description">{{date Created format="short"}}</div>
                </div>
                <div style="display: flex; align-items: center">
                    {{moment Created coalesce="--"}}
                </div>
            </div>
            {{/if}}
            

            <div class="item-subtitle text-color-gray" style="display: flex; align-items: center">
                <i class="icon f7-icons" style="margin-right: 4px;">person_crop_circle</i>
                <div class="description" style="overflow: hidden; text-overflow: ellipsis;">Bên A: {{objecttext this 'AObjectName' '--'}}</div>
            </div>

            <div class="item-subtitle text-color-gray" style="display: flex; align-items: center">
                <i class="icon f7-icons" style="margin-right: 4px;">person_crop_circle_fill</i>
                <div class="description" style="overflow: hidden; text-overflow: ellipsis;">Bên B: {{objecttext this 'BObjectName' '--'}}</div>
            </div>
            {{#if Type}}
            <div class="item-subtitle text-color-gray" style="display: flex; align-items: center">
                <i class="icon f7-icons" style="margin-right: 4px;">doc_text_fill</i>
                <div class="description" style="overflow: hidden; text-overflow: ellipsis;">
                {{objecttext this 'Type' '--'}}
                </div>
            </div>
            {{/if}}
            <div class="item-text" style="clear: both; max-height: initial;">
                <span class="lastMessageText">
                    <span class="tag">#{{Code}}</span>
                    {{#each RelativeVouchers}}
                    <span class="tag text-color-{{objecttext this 'state.color'}}">#{{js_if "this.type && this.type.symbol"}}{{type.symbol}}:{{/js_if}}{{id}}{{js_if "this.state"}}.{{text state}}{{/js_if}}</span>
                    {{/each}}
                </span>
            </div>
        </div>
    </a>

    <div class="swipeout-actions-right">
        <a href="#" class="approveBtn open-more-actions color-orange swipeout-close"> {{js "this.State && this.State.label"}}</a>
        <a href="#" class="moreBtn open-more-actions color-gray swipeout-overswipe swipeout-close">Thêm</a>
    </div>
  </li>
  `;

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: 'contract-list',
      path: this.path,
      component: {
        template: /*html*/`
            <div class="page no-toolbar-x ${this.namespace}" data-name="${this.namespace}">
              <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a class="link back {{textColorClass}}">
                      <i class="icon icon-back"></i>
                      <span class="if-not-md {{textColorClass}}">{{js "this.backTitle || 'Back'"}}</span>
                    </a>
                  </div>
                  <div class="title">Hợp đồng</div>
                  <div class="right">
                    <!-- Link to enable searchbar -->
                    <a class="link icon-only searchbar-enable {{textColorClass}}" data-searchbar=".${this.namespace}-searchbar">
                      <i class="icon f7-icons if-not-md">search</i>
                      <i class="icon material-icons md-only">search</i>
                    </a>
                  </div>
                  <!-- Searchbar is a direct child of "navbar-inner" -->
                  <form class="searchbar ${this.namespace}-searchbar searchbar-expandable">
                    <div class="searchbar-inner">
                      <div class="searchbar-input-wrap">
                        <input type="search" placeholder="Search" />
                        <i class="searchbar-icon"></i><span class="input-clear-button"></span>
                        
                      </div>
                      <span class="searchbar-disable-button {{textColorClass}}">Hủy</span>
                    </div>
                  </form>
                </div>
              </div>
              <div class="page-content ptr-content infinite-scroll-content" @ptr:refresh="refresh">
                <div class="ptr-preloader">
                  <div class="preloader"></div>
                  <div class="ptr-arrow"></div>
                </div>
                <div class="searchbar-backdrop ${this.namespace}-backdrop"></div>
            
                <!--<div class="action-bar block block-strong inset">
                  <div class="row">
                    <div class="col-25 tablet-15 action-bar-item text-color-gray link goto" @click="goto" data-url="/sales/goods-list">
                      <div class="demo-icon-icon"><i class="icon f7-icons">cube_box</i></div>
                      <div class="action-bar-item-label">Bảng giá</div>
                    </div>
                    <div class="col-25 tablet-15 action-bar-item text-color-gray link goto" @click="goto" data-url="/sales/price-report-list">
                      <div class="demo-icon-icon"><i class="icon f7-icons">app_fill</i></div>
                      <div class="action-bar-item-label">Báo giá</div>
                    </div>
                    <div class="col-25 tablet-15 action-bar-item text-color-gray link goto" @click="goto" data-url="/warehouse/inventory-adjust-note-list">
                      <div class="demo-icon-icon"><i class="icon f7-icons">app</i></div>
                      <div class="action-bar-item-label">Nhóm</div>
                    </div>
                    <div class="col-25 tablet-15 action-bar-item text-color-gray link goto" @click="goto" data-url="/warehouse/book-form/current">
                      <div class="demo-icon-icon"><i class="icon f7-icons">app</i></div>
                      <div class="action-bar-item-label">Đơn vị tính</div>
                    </div>
                  </div>
                </div>-->

                <div class="block-title" style="margin-top: 2rem">Bộ lọc <a style="float: right" @click="resetFilter"
                class="text-color-red">đặt lại</a></div>
                <div class="block block-strong inset filter" style="padding: 0; overflow: hidden">
                  <div class="list accordion-list inline-labels no-hairlines" style="margin: 0;">
                    <ul>
                      <li class="item-link smart-select smart-select-init" name="Type" data-open-in="popup" data-searchbar="false" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFilterFieldChange">
                        <select>
                          <option value="">--</option>
                          {{#each typeList}}
                          <option value="{{id this}}">{{text this}}</option>
                          {{/each}}
                        </select>
                        <div class="item-content">
                          <div class="item-inner">
                            <div class="item-title">Loại</div> 
                          </div>
                        </div>
                      </li>
                      <li class="item-link smart-select smart-select-init" name="State" data-open-in="popup" data-searchbar="false" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFilterFieldChange">
                        <select>
                          <option value="">--</option>
                          {{#each processMap}}
                          <option value="{{state}}">{{label}}</option>
                          {{/each}}
                        </select>
                        <div class="item-content">
                          <div class="item-inner">
                            <div class="item-title">Trạng thái</div> 
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="block-title">Danh sách Hợp đồng 
                  <a class="button button-small button-fill tab-link" @click="openVoucher" style="float: right; font-size: 0.7rem; font-weight: bold">+ Mới</a>
                  <!--<a class="button button-small button-fill tab-link" @click="reportCurrentFilterToPdf" style="float: right; font-size: 0.7rem; font-weight: bold; margin-right: 3px">Xuất lọc và báo cáo (PDF)</a>-->
                </div>
                <div class="virtual-list list media-list ${this.namespace}-virtual-list inset list-complex-info">
                  
                </div>
                {{#if infiniteLoadLast}}
                    <div style="text-align: center" class="text-color-gray">end</div>
                {{else}}
                    <div class="preloader color-blue infinite-scroll-preloader">
                        <span class="preloader-inner">
                            <span class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span>
                        </span>
                    </div>
                {{/if}}
            
                <div class="block"><br></div>
            
              </div>
            </div>
        `,
        style: /*css*/`
          .page.${this.namespace} .find-opportunity {
            font-size: 16px;
            font-weight: bold;
          }
          .page.${this.namespace} .action-bar {
            color: var(--f7-block-strong-text-color);
            padding-top: var(--f7-block-padding-vertical);
            padding-bottom: var(--f7-block-padding-vertical);
            background-color: var(--f7-block-strong-bg-color);
          }
          .page.${this.namespace} .virtual-list i.f7-icons {
            font-size: 16px;
          }
          .page.${this.namespace} .list-complex-info ul li .item-title .item-title-text {
            -webkit-line-clamp: 1;
          }
          .page.${this.namespace} .list-complex-info ul li .item-title .item-title-text {
            -webkit-line-clamp: 1;
          }
          .page.${this.namespace} .list-complex-info ul li .item-title {
            height: 18px;
          }
          .page.${this.namespace} .probox-link {
            display: flex;
            align-items: center;
          }
          .page.${this.namespace} .probox-link img {
            width: 1.1rem;
            height: 1.1rem;
            margin-right: 0.25rem;
          }
        `,
        data() {
          return {
            title: $this.title,
            // reminder: {
            //   No: '234234',
            // },
            // priceReport: { Details: [] },
            filter: {},
            processMap: ContractStateMap.contractStateMap,
            typeList: $this.typeList,
          };
        },
        methods: {
          reportCurrentFilterToPdf() {
            if (!$this.currentState?.filter?.Publisher) {
              $this.commonService.showError('Chưa chọn CTV Bán hàng');
              return;
            }
            const fromDate = $this.commonService.getBeginOfMonth();
            const toDate = $this.commonService.getEndOfDate();
            $this.rootServices.iab.create($this.rootServices.apiService.buildApiUrl($this.apiPath, {
              includeCreator: true,
              includeRelativeVouchers: true,
              ge_DateOfOpportunity: fromDate.toISOString(),
              le_DateOfOpportunity: toDate.toISOString(),
              sort_DateOfOpportunity: 'desc',
              ...$this.parseFilter($this.currentState?.filter),
              type: 'pdf'
            }) as string, '_system');
          },
          resetFilter(e) {
            $this.resetFilter(this, e);
          },
          onFilterFieldChange(e) {
            $this.onFilterFieldChange(this, e);
          },
          // Price report
          openVoucher(e, id?: string) {
            const self: F7ComponentContextExtend = this;
            id = id || self.$(e.target).closest('li').data('id') || 'new';

            // if ($this.commonService.frameSocket.isFrameMode && id && id !== 'new') {
            //   $this.commonService.frameSocket.emit('request-open-voucher', { id: id, type: 'PRICEREPORT' });
            // } else {
            $this.rootServices.navigate('/contract/contract/' + id, {
              context: {
                backTitle: $this.title,
                textColorClass: self.textColorClass,
                // memberInfo: $this.currentState.chatRoom.memberList$.getValue().find(f => f.id === id),
                // chatRoom: self.$route.context.chatRoom,
                // reminderList$: self.reminderList$,
                callback: (data: CollaboratorOpportunityModel, state: any) => {
                  console.log('Callback data: ', data);
                  if (state?.lastAction == 'CREATE_SUCCESS') {
                    self.refresh();
                  }
                }
              }
            });
            // }
          },
          goto(e) {
            const self: F7ComponentContextExtend = this;
            const url = self.$(e.target).closest('.goto').data('url');
            $this.rootServices.navigate(url);
          },
          chooseOne(e) {
            const self: F7ComponentContextExtend = this;
            const id = self.$(e.target).closest('li').data('id');
            self.$route.context['onChoose'] && self.$route.context['onChoose'](self.listItems.find(f => f.Code === id));
          },
          chooseProduct(e) {

          },
          async refresh(e, done) {
            const self: F7ComponentContextExtend = this;

            $this.refresh(self).then(rs => done && done());

            // let doneTimeout = null;
            // if (done) {
            //   doneTimeout = setTimeout(() => {
            //     done();
            //   }, 10000);
            // };

            // // console.log(self.searchBar);
            // // $this.rootServices.apiService.getPromise<any[]>('/warehouse/inventory-adjust-notes', {
            // //   search: self?.searchBar?.query && self.searchBar.query || '',
            // //   includeParent: true,
            // //   includePath: true,
            // //   includeWarehouse: true,
            // //   includeGoods: true,
            // //   sort_Id: 'desc',
            // //   limit: 10,
            // //   offset: 0,
            // //   ...self.filter,
            // // }).then(products => {
            // $this.getList({
            //   search: self?.searchBar?.query && self.searchBar.query || '',
            //   limit: 10,
            //   offset: 0,
            //   ...self.filter,
            // }).then(products => {
            //   self.$setState({ listItems: products.map(t => $this.prepareItem(t)), infiniteLoadLast: products.length === 0 });
            //   self.infiniteOffset = 10;
            //   if (done) done();
            // }).catch(err => {
            //   console.error(err);
            //   $this.commonService.showError(err);
            //   if (done) done();
            // });

            return true;
          },
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          pageInit(e, page: F7Page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;
            // Loading flag
            let allowInfinite = true;

            // self.refresh();
            // self.searchBar = self.$app.searchbar.create({
            //   el: '.inventory-adjust-note-list-searchbar',
            //   backdrop: true,
            //   backdropEl: '.product-list-backdrop',
            //   searchContainer: '.list',
            //   searchIn: '.item-text',
            //   customSearch: true,
            //   on: {
            //     search(sb, query, previousQuery) {
            //       $this.commonService.takeUntil('product-search', 600).then(() => {
            //         console.log(query, previousQuery);
            //         $this.getList({
            //           search: self?.searchBar?.query && self.searchBar.query || '',
            //           limit: 10,
            //           offset: 0,
            //           ...self.filter,
            //         }).then(products => {
            //           self.$$(page.el).find('.page-content').scrollTo(0, 0);
            //           self.$setState({ listItems: products.map(t => $this.prepareItem(t)), infiniteLoadLast: products.length === 0 });
            //           self.infiniteOffset = 0;
            //         }).catch(err => {
            //           console.error(err);
            //           $this.commonService.showError(err);
            //         });
            //       });
            //     }
            //   }
            // });

            // self.$$(page.el).find('.infinite-scroll-content').on('infinite', function () {
            //   // Exit, if loading in progress
            //   if (!allowInfinite) return;

            //   // Set loading flag
            //   allowInfinite = false;
            //   // Last loaded index
            //   var lastItemIndex = self.$$('.list li').length;

            //   // Emulate 1s loading
            //   $this.getList({
            //     search: self?.searchBar?.query && self.searchBar.query || '',
            //     limit: 10,
            //     offset: self.infiniteOffset,
            //     ...self.filter,
            //   }).then(products => {
            //     products.map(t => {
            //       $this.prepareItem(t);
            //       return t;
            //     });
            //     self.$setState({ listItems: self.listItems.concat(products), infiniteLoadLast: products.length === 0 });
            //     self.infiniteOffset += 10;
            //     lastItemIndex = self.$$('.list li').length;
            //     allowInfinite = true;

            //     // self.updateBadge();
            //   }).catch(err => {
            //     console.error(err);
            //     $this.commonService.showError(err);
            //   });
            // });

            // // const reminder = self.$route.context.reminder || {};
            // // const chatRoom = self.$route.context.chatRoom;


            // self.calendarDateTime = self.$f7.calendar.create({
            //   // openIn: 'customModal',
            //   inputEl: page.$el.find('input[name="RemindAtDateTime"]'),
            //   timePicker: true,
            //   dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' },
            //   // header: true,
            //   footer: true,
            // });

            // Set back title
            // self.$setState({ backTitle: self.$route.context && self.$route.context.backTitle || 'Back', title: self.$route.context && self.$route.context.title || 'Tài khoản' });

            $this.onComponentInit({ instance: self }, 'main', null, page);

            // console.log(reminder);
            // $this.rootServices.authService.isAuthenticatedOrRefresh().pipe(take(1)).toPromise().then(() => {
            //   self.$setState({
            //     backTitle: self.$route.context && self.$route.context.backTitle || 'Back', title: self.$route.context && self.$route.context.title || 'Profile',
            //     memberList: chatRoom.memberList$.getValue().filter(member => member.Type === 'USER'),
            //     // reminder: self.reminder,
            //   });

            //   // setTimeout(() => {
            //   //   self.$setState({
            //   //     reminder: self.reminder,
            //   //   });
            //   // }, 5000);
            // }).catch(err => {
            //   console.error(err);
            //   $this.commonService.showError(err);
            // });

          },
          // pageBeforeIn(e, page) {
          //     console.log('page before in');
          //     const self: F7ComponentContextExtend = this;
          //     self.refresh();
          // },
          // pageAfterIn(e, page) {
          //     console.log('page after in');
          // },
          // pageBeforeOut(e, page) {
          //     console.log('page before out');
          // },
          pageAfterOut(e, page) {
            console.log('page after out');
          },
          // pageBeforeUnmount(e, page) {
          //     console.log('page before unmount');
          // },
          // pageBeforeRemove(e, page) {
          //     console.log('page before remove');
          // },
          pageBeforeRemove(e, page) {
            console.log('[page event] before remove', page.route.url);
            const self: F7ComponentContextExtend = this;
            // const starmtSelectRoles = self.$app.smartSelect.get('.smart-select');
            // starmtSelectRoles.destroy();
          },
        },
      },
    };
  }
}
