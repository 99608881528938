import { F7ComponentContext } from '../types/framework7-types';
import { Messages } from 'framework7/build/core/components/messages/messages';
import { F7Messagebar } from '../types/framework7-types';
import { Router } from 'framework7/build/core/modules/router/router';
import { Dialog } from 'framework7/build/core/components/dialog/dialog';
import { isPlatform } from '@ionic/angular';
import { AppComponentProcessor } from '../app.component.processor';
import { BaseComponent, ComponentState } from 'vendors/smart-bot-app/src/app/lib/base-component';
import { RootServices } from 'vendors/smart-bot-app/src/app/services/root.services';
import { CommonService } from 'vendors/smart-bot-app/src/app/services/common.service';
import { AccountModel } from 'vendors/smart-bot-app/src/app/model/account.model';
import { FileModel } from 'vendors/smart-bot-app/src/app/model/file.model';

export interface ComponentStateExtend extends ComponentState {
  [key: string]: any;
}

export class F7ComponentContextExtend extends F7ComponentContext {
  messagebar?: F7Messagebar;
  messages?: Messages.Messages;
  images?: string[];
  responseInProgress?: boolean;
  answers?: string[];
  people?: { name?: string, avatar?: string }[];
  hideToolbar?: () => void;
  sheetToggle?: () => void;
  deleteAttachment?: (e: MouseEvent, index: number) => void;
  handleAttachment?: (e: MouseEvent) => void;
  checkAttachments?: () => void;
  sendMessage?: () => void;
  $root: any;
  // [key: string]: any;

  updatePinCodeDialog?: Dialog.Dialog;
  changePasswordDialog?: Dialog.Dialog;
}

/** Component group manager */
export class AccountComponent extends BaseComponent<ComponentStateExtend> {
  // states: { [key: string]: State } = {};

  title: 'Tài khoản'

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
    public appComponent: AppComponentProcessor,
  ) {
    super(rootServices);
    // console.log('Click here to open ts file');
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: 'account',
      path: '/account',
      component: {
        template: /*html*/`
            <div class="page account no-toolbar" data-name="account">
              <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a class="link back">
                      <i class="icon icon-back"></i>
                      <span class="if-not-md">{{backTitle}}</span>
                    </a>
                  </div>
                  <div class="title">Tài khoản</div>
                  <div class="right">
                    <a class="link icon-only">
                      <i class="icon f7-icons">ellipsis</i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="page-content">
            
                <div class="profile-info">
                  <div @click="changeAvatar" class="profile-logo bg-color-gray"
                    style="background-image: url({{js "this.account && this.account.Avatar && this.account.Avatar.payload && this.account.Avatar.payload.thumbnail || ''"}})">
                  </div>
                  <div class="profile-title text-color-default">{{js "this.account && this.account.Name || ''"}}</div>
                  <div class="profile-subtitle text-color-gray">ID: {{js "this.account && this.account.Code || ''"}}</div>
                </div>
            
                <!--<div class="block-header">Cài đặt tài khoản</div>
                            <div class="list simple-list inset">
                              <ul>
                                <li>
                                  <span>Online</span>
                                  <label class="toggle toggle-init color-blue">
                                    <input type="checkbox" checked>
                                    <span class="toggle-icon"></span>
                                  </label>
                                </li>
                                <li>
                                  <span>Chia sẻ vị trí</span>
                                  <label class="toggle toggle-init color-black">
                                    <input type="checkbox" checked>
                                    <span class="toggle-icon"></span>
                                  </label>
                                </li>
                                <li>
                                  <span>Nhận job</span>
                                  <label class="toggle toggle-init color-orange">
                                    <input type="checkbox" checked>
                                    <span class="toggle-icon"></span>
                                  </label>
                                </li>
                              </ul>
                            </div>-->
            
                <div class="block-header">Thông tin tài khoản</div>
                <div class="list inset profile-form no-hairlines-md">
                  <form autocomplete="off">
                    <ul>
                      <li class="item-content item-input">
                        <div class="item-media"><i class="icon f7-icons text-color-default">person_circle_fill</i></div>
                        <div class="item-inner">
                          <div class="item-title item-label">Tên đầy đủ</div>
                          <div class="item-input-wrap">
                            <input class="profile-field" name="Name" type="text" placeholder="Họ và tên"
                              value="{{js "this.account && this.account.Name"}}">
                            
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-media"><i class="icon f7-icons text-color-default">at_circle_fill</i></div>
                        <div class="item-inner">
                          <div class="item-title item-label">Tên đại diện</div>
                          <div class="item-input-wrap">
                            <input class="profile-field" name="ShortName" type="text" placeholder="Dùng trong xưng hô"
                              value="{{js "this.account && this.account.ShortName || ''"}}" autocomplete="new-password"
                              autocorrect="off">
                            
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-media"><i class="icon f7-icons text-color-default">at_circle_fill</i></div>
                        <div class="item-inner">
                          <div class="item-title item-label">Url</div>
                          <div class="item-input-wrap">
                            <input class="profile-field" name="TagName" type="text" placeholder="Tên hiển thị trong link profile"
                              value="{{js "this.account && this.account.TagName || ''"}}" autocorrect="off">
                            
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-media"><i class="icon f7-icons text-color-default">person_crop_circle</i></div>
                        <div class="item-inner">
                          <div class="item-title item-label">Danh xưng</div>
                          <div class="item-input-wrap">
                            <input class="profile-field" name="Title" type="text" placeholder="Mr/Mis/anh/chị/ông/bà/sếp..."
                              value="{{js "this.account && this.account.Title || ''"}}" autocomplete="new-password"
                              autocorrect="off">
                            
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-media"><i class="icon f7-icons text-color-default">phone_circle_fill</i></div>
                        <div class="item-inner">
                          <div class="item-title item-label">Số điện thoại</div>
                          <div class="item-input-wrap">
                            <input class="profile-field" name="Phone" type="tel" placeholder="Số điện thoại chính"
                              value="{{js "this.account && this.account.Phone || ''"}}" autocomplete="new-password"
                              autocorrect="off">
                            
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-media"><i class="icon f7-icons text-color-default">envelope_fill</i></div>
                        <div class="item-inner">
                          <div class="item-title item-label">Email</div>
                          <div class="item-input-wrap">
                            <input class="profile-field" type="text" name="Email" placeholder="Địa chỉ email"
                              value="{{js "this.account && this.account.Email || ''"}}" autocomplete="new-password"
                              autocorrect="off">
                            
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-media"><i class="icon f7-icons text-color-default">location_fill</i></div>
                        <div class="item-inner">
                          <div class="item-title item-label">Địa chỉ</div>
                          <div class="item-input-wrap">
                            <input class="profile-field" type="text" name="Address" placeholder="Địa chỉ"
                              value="{{js "this.account && this.account.Address || ''"}}" autocomplete="new-password"
                              autocorrect="off">
                            
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-media"><i class="icon f7-icons text-color-default">smiley_fill</i></div>
                        <div class="item-inner clear-after">
                          <div class="item-title item-label">Giới tính</div>
                          <div class="item-input-wrap input-dropdown-wrap">
                            <select class="profile-field" name="Gender" value="{{js "this.account && this.account.Gender || ''"}}">
                              <option value="MALE">Nam</option>
                              <option value="FEMALE">Nữ</option>
                              <option value="OTHER">Khác</option>
                            </select>
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input" style="padding-left: 0">
                        <a class="col button button-fill button-large link" @click="updateAccount" style="width: 100%">Lưu thông tin
                          tài khoản</a>
                      </li>
                    </ul>
                  </form>
                </div>

                <div class="block-header">Xác thực</div>
                <div class="list media-list inset">
                  <ul>
                    <li class="item-link" @click="verifyPhone">
                      <div class="item-content text-color-{{js "this.account && this.account.IsPhoneVerfied ? 'green' : 'gray'"}}">
                        <div class="item-media"><i style="font-size: 44px" class="icon f7-icons">phone_circle_fill</i></div>
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title">Xác thực số điện thoại</div>
                          </div>
                          <div class="item-subtitle">Nhận mã xác thực OTP qua zalo</div>
                        </div>
                      </div>
                    </li>
                    <li class="item-link" @click="verifyEmail">
                      <div class="item-content text-color-{{js "this.account && this.account.IsEmailVerfied ? 'green' : 'gray'"}}">
                        <div class="item-media"><i style="font-size: 44px" class="icon f7-icons">envelope_fill</i></div>
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title">Xác thực email</div>
                          </div>
                          <div class="item-subtitle">Nhận mã xác thực OTP qua email</div>
                        </div>
                      </div>
                    </li>
                    <!--
                    <li class="item-link" @click="verifyIdentifiedNumber">
                      <div class="item-content text-color-{{js "this.account && this.account.IsIdentifiedNumberVerfied ? 'green' : 'gray'"}}">
                        <div class="item-media"><i style="font-size: 44px" class="icon f7-icons">creditcard_fill</i></div>
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title">Xác thực CCCD</div>
                          </div>
                          <div class="item-subtitle">{{js "!this.IdentityCardImage1 ? 'Upload mặt trước CCCD' : 'Upload mặt sau CCCD'"}}</div>
                        </div>
                      </div>
                    </li>-->
                  </ul>
                </div>

                <!--<div class="block inset" style="display: flex; flex-wrap: wrap;">
                <div style="flex: 50%;">
                  {{#js_if "this.IdentityCardImage1"}}
                    <div class="image" style="background-image: url({{js "this.IdentityCardImage1 && this.IdentityCardImage1.SmallImage"}})">
                      
                    </div>
                    {{/js_if}}
                  </div>
                  <div style="flex: 50%;">
                  {{#js_if "this.IdentityCardImage2"}}
                    <div class="image" style="background-image: url({{js "this.IdentityCardImage2 && this.IdentityCardImage2.SmallImage"}})">
                      
                    </div>
                  {{/js_if}}
                  </div>
                </div>-->
            
                {{#js_if "true || !this.isDesktop"}}
                <div class="block-header">Bảo mật</div>
                <div class="list media-list inset">
                  <ul>
                    <li class="item-link" @click="openChangePasswordDialog">
                      <div class="item-content text-color-gray">
                        <div class="item-media"><i style="font-size: 44px" class="icon f7-icons">lock_fill</i></div>
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title">Mật khẩu</div>
                          </div>
                          <div class="item-subtitle">Thay đổi mật khẩu</div>
                        </div>
                      </div>
                    </li>
                    <li class="item-link" @click="openUpdatePinCodeDialog">
                      <div class="item-content text-color-gray">
                        <div class="item-media"><i style="font-size: 44px" class="icon f7-icons">pencil_ellipsis_rectangle</i></div>
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title">Mã PIN</div>
                          </div>
                          <div class="item-subtitle">Thay đổi mã PIN</div>
                        </div>
                      </div>
                    </li>
                    <li class="item-link" @click="scan2login">
                      <div class="item-content text-color-gray">
                        <div class="item-media"><i style="font-size: 44px" class="icon f7-icons">qrcode_viewfinder</i></div>
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title">Scan2Login</div>
                          </div>
                          <div class="item-subtitle">Quét mã để đăng nhập</div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="block-footer">Thay đổi mật khẩu, mã PIN và quản lý các phiên đăng nhập của bạn</div>
                {{/js_if}}
                <div class="block"><br></div>
                <div class="block">
                  <a class="col button button-large link color-gray" @click="deleteAccount" style="width: 100%">Xóa tài khoản</a>
                </div>
            
              </div>
            
              <div class="dialog dialog-wrap dialog-update-pin-code">
                <div class="dialog-padding">
                  <div class="dialog dialog-inside">
                    <div class="dialog-inner" style="padding-bottom: 0;">
                      <div class="dialog-title">Bảo mật</div>
                      <div class="dialog-text" style="width: 100%; user-select: initial; text-align: center;">Cập nhật mã PIN</div>
                      <br>
                      <div class="list media-list"
                        style="margin-left: -15px; margin-right: -15px; margin-bottom: 0; margin-top: 0; text-align: left;">
                        <ul>
                          <li class="item-content item-input">
                            <div class="item-media"><i class="icon f7-icons text-color-default">pencil_ellipsis_rectangle</i></div>
                            <div class="item-inner">
                              <div class="item-title item-label">Mã PIN (*)</div>
                              <div class="item-input-wrap">
                                <input class="field" name="PinCode" type="password" pattern="[0-9]*" inputmode="numeric"
                                  maxlength="4" placeholder="nhập mã PIN">
                                
                              </div>
                            </div>
                          </li>
                          <li class="item-content item-input">
                            <div class="item-media"><i class="icon f7-icons text-color-default">lock_open_fill</i></div>
                            <div class="item-inner">
                              <div class="item-title item-label">Mật khẩu hiện tại (*)</div>
                              <div class="item-input-wrap">
                                <input class="field" name="Password" type="password" placeholder="nhập mật khẩu hiện tại">
                                
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="dialog-buttons">
                      <span class="dialog-button color-red closeBtn" @click="closeUpdatePinCodeDialog">Đóng</span>
                      <span class="dialog-button createReferenceChatRoomBtn" @click="updatePinCode">Cập nhật</span>
                    </div>
                  </div>
                </div>
              </div>
            
              <div class="dialog dialog-wrap dialog-change-password">
                <div class="dialog-padding">
                  <div class="dialog dialog-inside">
                    <div class="dialog-inner" style="padding-bottom: 0;">
                      <div class="dialog-title">Bảo mật</div>
                      <div class="dialog-text" style="width: 100%; user-select: initial; text-align: center;">Đổi mật khẩu</div>
                      <br>
                      <div class="list media-list"
                        style="margin-left: -15px; margin-right: -15px; margin-bottom: 0; margin-top: 0; text-align: left;">
                        <ul>
                          <li class="item-content item-input">
                            <div class="item-media"><i class="icon f7-icons text-color-default">lock_open_fill</i></div>
                            <div class="item-inner">
                              <div class="item-title item-label">Mật khẩu hiện tại (*)</div>
                              <div class="item-input-wrap">
                                <input class="field" name="Password" type="password" placeholder="nhập mật khẩu hiện tại">
                                
                              </div>
                            </div>
                          </li>
                          <li class="item-content item-input">
                            <div class="item-media"><i class="icon f7-icons text-color-default">lock_fill</i></div>
                            <div class="item-inner">
                              <div class="item-title item-label">Mật khẩu mới (*)</div>
                              <div class="item-input-wrap">
                                <input class="field" name="NewPassword" type="password" placeholder="nhập mật khẩu mới">
                                
                              </div>
                            </div>
                          </li>
                          <li class="item-content item-input">
                            <div class="item-media"><i class="icon f7-icons text-color-default">lock_fill</i></div>
                            <div class="item-inner">
                              <div class="item-title item-label">Mật khẩu mới (*)</div>
                              <div class="item-input-wrap">
                                <input class="field" name="ReNewPassword" type="password" placeholder="nhập lại mật khẩu mới">
                                
                              </div>
                            </div>
                          </li>
                          <li class="item-content item-input">
                            <div class="item-media"><i class="icon f7-icons text-color-default">pencil_ellipsis_rectangle</i></div>
                            <div class="item-inner">
                              <div class="item-title item-label">Mã PIN (*)</div>
                              <div class="item-input-wrap">
                                <input class="field" name="PinCode" type="password" pattern="[0-9]*" inputmode="numeric"
                                  maxlength="4" placeholder="nhập mã pin">
                                
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="dialog-buttons">
                      <span class="dialog-button color-red closeBtn" @click="closeChangePasswordDialog">Đóng</span>
                      <span class="dialog-button createReferenceChatRoomBtn" @click="changePassword">Đổi mật khẩu</span>
                    </div>
                  </div>
                </div>
              </div>
            
            
            
            </div>
        `,
        style: /*css*/`
          .page.account .profile-info {
            margin-top: 20px;
          }
          
          .page.account .profile-logo {
            height: 100px;
            width: 100px;
            border-radius: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            margin: 0 auto;
          }
          
          .page.account .profile-title {
            text-align: center;
            font-weight: bold;
          }
          
          .page.account .profile-subtitle {
            text-align: center;
          }

          .page.account .image {
            border-radius: var(--f7-list-inset-border-radius);
            margin: 5px; 
            height: 150px; 
            position: relative; 
            background-repeat: no-repeat; 
            overflow: hidden; 
            background-size: cover; 
            background-position: center;
            background-color: var(--f7-block-strong-bg-color);
          }
        `,
        data() {
          return {
            title: $this.title,
            isDesktop: isPlatform('desktop'),
          };
        },
        methods: {
          async verifyIdentifiedNumber(e) {
            const self: F7ComponentContextExtend = this;
            try {
              if (!self.IdentityCardImage1) {
                self.IdentityCardImage1 = await $this.rootServices.pickAndUploadFile();
                self.$setState({ IdentityCardImage1: self.IdentityCardImage1 });
                $this.commonService.showInfo('Bấm lần nữa để upload mặt sau CCCD', { position: 'center' });
                return;
              }
              if (!self.IdentityCardImage2) {
                self.IdentityCardImage2 = await $this.rootServices.pickAndUploadFile();
                self.$setState({ IdentityCardImage2: self.IdentityCardImage2 });
                $this.rootServices.apiService.putPromise('/user/users/updateAccount', {}, {
                  IdentityCardImage1: self.IdentityCardImage1,
                  IdentityCardImage2: self.IdentityCardImage2,
                }).then(rs => {
                  $this.commonService.showInfo('Đã gửi yêu cầu xác thực CCCD', { position: 'center' });
                  self.IdentityCardImage1 = self.IdentityCardImage2 = null;
                  // $this.refresh();
                }).catch(err => {
                  console.error(err);
                  self.$app.preloader.hide();
                  $this.commonService.showError(err);
                });
              }

            } catch (err) {
              console.error(err);
            }
          },
          verifyEmail() {
            const self: F7ComponentContextExtend = this;
            self.$app.preloader.show();
            $this.rootServices.apiService.putPromise<any>('/user/users/requestVerifyEmail', {}, []).then(async rs => {
              self.$app.preloader.hide();
              let tryTime = 1;
              return await $this.rootServices.enterPasscodePopup(
                'Điền mã xác thực OTP',
                  /*html*/ `
                      <div class="block block-strong inset">Bạn hãy vào hộp thư cá nhân của bạn để lấy OPT, nếu không có trong Inbox vui lòng kiểm tra trong spam!</div>
                      <div class="block block-strong inset" style="padding: 0; overflow: hidden; line-height: 0">
                          
                      </div>
                  `, 4, {
                swipeToClose: false,
                backLabel: 'Skip',
                onFullFill: (otp_1) => {
                  self.$app.preloader.show();
                  return $this.rootServices.apiService.putPromise('/user/users/verifyEmail', {}, [{
                    Otp: otp_1,
                  }]).then(async (rs_1) => {
                    self.$app.preloader.hide();
                    $this.commonService.showInfo('Xác thực thành công !', { position: 'center' });

                    $this.refresh();
                    // $this.commonService.showInfo('');
                    // self.register.Code = rs[0].Code;
                    // self.$setState({
                    //   register: self.register,
                    // });

                    return true;
                  }).catch(err_3 => {
                    self.$app.preloader.hide();
                    $this.commonService.showError(err_3, { position: 'bottom' });
                    tryTime++;
                    if (tryTime <= 3) {
                      // self.tryVerifiedOtp(phone, 'Mã OTP không hợp lệ, hãy thử lại (' + tryTime + '/3)', tryTime);
                      return Promise.reject({ error: 422, message: 'Mã OTP không hợp lệ, hãy thử lại (' + tryTime + '/3)' });
                    }
                    tryTime = 1;
                    return Promise.reject({ error: 400, message: 'Mã OTP đã hết hiệu lực !' });
                  });
                }
              }).catch(errOrSkip => {
                self.$app.preloader.hide();
                // if (errOrSkip == 'CLOSE') {

                // } else {
                //   $this.commonService.showError(errOrSkip);
                //   self.$setState({ notvalidated: false, regnotvalidated: false });
                // }
              });
            }).catch(err => {
              self.$app.preloader.hide();
              return Promise.reject(err);
            });
          },
          verifyPhone() {
            const self: F7ComponentContextExtend = this;
            self.$app.preloader.show();
            $this.rootServices.apiService.putPromise<any>('/user/users/requestVerifyPhoneByZaloOtp', {}, []).then(async rs => {
              self.$app.preloader.hide();
              let tryTime = 1;
              return await $this.rootServices.enterPasscodePopup(
                'Điền mã xác thực OTP',
                  /*html*/ `
                      <div class="block-title">Bạn hãy vào Zalo để kiểm tra tin nhắn OTP</div>
                      <div class="block block-strong inset" style="padding: 0; overflow: hidden; line-height: 0">
                          <img src="assets/images/zalo-otp.png" style="width: 100%">
                      </div>
                  `, 4, {
                swipeToClose: false,
                backLabel: 'Skip',
                onFullFill: (otp_1) => {
                  self.$app.preloader.show();
                  return $this.rootServices.apiService.putPromise('/user/users/verifyPhoneByZaloOtp', {}, [{
                    Otp: otp_1,
                  }]).then(async (rs_1) => {
                    self.$app.preloader.hide();
                    $this.commonService.showInfo('Xác thực thành công !', { position: 'center' });

                    $this.refresh();
                    // $this.commonService.showInfo('');
                    // self.register.Code = rs[0].Code;
                    // self.$setState({
                    //   register: self.register,
                    // });

                    return true;
                  }).catch(err_3 => {
                    self.$app.preloader.hide();
                    $this.commonService.showError(err_3, { position: 'bottom' });
                    tryTime++;
                    if (tryTime <= 3) {
                      // self.tryVerifiedOtp(phone, 'Mã OTP không hợp lệ, hãy thử lại (' + tryTime + '/3)', tryTime);
                      return Promise.reject({ error: 422, message: 'Mã OTP không hợp lệ, hãy thử lại (' + tryTime + '/3)' });
                    }
                    tryTime = 1;
                    return Promise.reject({ error: 400, message: 'Mã OTP đã hết hiệu lực !' });
                  });
                }
              }).catch(errOrSkip => {
                self.$app.preloader.hide();
                // if (errOrSkip == 'CLOSE') {

                // } else {
                //   $this.commonService.showError(errOrSkip);
                //   self.$setState({ notvalidated: false, regnotvalidated: false });
                // }
              });
            }).catch(err => {
              self.$app.preloader.hide();
              return Promise.reject(err);
            });
          },
          scan2login() {
            const self: F7ComponentContextExtend = this;
            $this.appComponent.scanQRCode();
          },
          openChangePasswordDialog(e: MouseEvent) {
            const self: F7ComponentContextExtend = this;
            if (self.changePasswordDialog) {
              self.changePasswordDialog.open();
              self.changePasswordDialog.$el.find('.field[name="Password"]').focus();
            }
          },
          closeChangePasswordDialog() {
            const self: F7ComponentContextExtend = this;
            self.changePasswordDialog && self.changePasswordDialog.close();
          },
          changePassword(e: MouseEvent) {
            const self: F7ComponentContextExtend = this;
            const formData: any = {};
            const fieldEles = self.changePasswordDialog.$el.find('.field');
            fieldEles.each((index, fieldEle) => {
              console.log(fieldEle);
              console.log(fieldEle.value);
              formData[fieldEle.name] = fieldEle.value;
            });
            if (!formData.Password) {
              $this.commonService.showError('Bạn chưa nhập mật khẩu hiện tại');
              return;
            }
            if (!formData.NewPassword) {
              $this.commonService.showError('Bạn chưa nhập mật khẩu mới');
              return;
            }
            if (!formData.PinCode) {
              $this.commonService.showError('Bạn chưa nhập mã PIN');
              return;
            }
            if (formData.ReNewPassword !== formData.NewPassword) {
              $this.commonService.showError('Nhập lại mật khẩu chưa khớp');
              return;
            }
            console.log(formData);
            self.$app.preloader.show();
            try {
              $this.rootServices.apiService.putPromise('/user/users', { changePassword: true }, formData).then(rs => {
                // console.log(rs);
                self.changePasswordDialog.close();
                self.$app.preloader.hide();
                fieldEles.each((index, fieldEle) => {
                  fieldEle.value = '';
                });
                $this.commonService.showInfo('Đổi mật khẩu thành công');
              }).catch(err => {
                console.error(err);
                self.$app.preloader.hide();
                $this.commonService.showError(err);
              });
            } catch (err) {
              console.error(err);
              self.$app.preloader.hide();
              $this.commonService.showError(err);
            }
          },
          openUpdatePinCodeDialog(e: MouseEvent) {
            const self: F7ComponentContextExtend = this;
            if (self.updatePinCodeDialog) {
              self.updatePinCodeDialog.open();
              self.updatePinCodeDialog.$el.find('.field[name="PinCode"]').focus();
            }
          },
          closeUpdatePinCodeDialog() {
            const self: F7ComponentContextExtend = this;
            self.updatePinCodeDialog && self.updatePinCodeDialog.close();
          },
          updatePinCode(e: MouseEvent) {
            const self: F7ComponentContextExtend = this;
            const formData: any = {};
            const fieldEles = self.updatePinCodeDialog.$el.find('.field');
            fieldEles.each((index, fieldEle) => {
              console.log(fieldEle);
              console.log(fieldEle.value);
              formData[fieldEle.name] = fieldEle.value;
            });
            if (!formData.PinCode) {
              console.error('Bạn chưa nhập mã pin');
              $this.commonService.showError('Bạn chưa nhập mã pin');
              return;
            }
            if (!formData.Password) {
              console.error('Bạn chưa nhập mật khẩu hiện tại');
              $this.commonService.showError('Bạn chưa nhập mật khẩu hiện tại');
              return;
            }
            console.log(formData);
            self.$app.preloader.show();
            try {
              $this.rootServices.apiService.putPromise('/user/users', { updatePinCode: true }, formData).then(rs => {
                // console.log(rs);
                self.updatePinCodeDialog.close();
                self.$app.preloader.hide();
                fieldEles.each((index, fieldEle) => {
                  fieldEle.value = '';
                });
                $this.commonService.showInfo('Đã cập nhật mã PIN mới');
              }).catch(err => {
                console.error(err);
                self.$app.preloader.hide();
                $this.commonService.showError(err);
              });
            } catch (err) {
              console.error(err);
              self.$app.preloader.hide();
              $this.commonService.showError(err);
            }
          },
          async refresh(e, done) {
            const self: F7ComponentContextExtend = this;

            $this.rootServices.apiService.getPromise<AccountModel>('/user/users/getInfo', {
              includePhoneVerification: true,
              includeEmailVerification: true,
            }).then(userInfo => {
              console.log(userInfo);
              let coreDomain = $this.rootServices.apiService.token && $this.rootServices.apiService.token.api_url;
              if (coreDomain) {
                const url = new URL(coreDomain);
                coreDomain = url.host;
              }
              // if (userInfo.Avatar) {
              if (!userInfo.Avatar) {
                userInfo.Avatar = {
                  payload: {

                  }
                } as any;
              }
              userInfo.Avatar.payload.thumbnail = $this.rootServices.apiService.getBaseApiUrl() + '/user/users/' + userInfo.Code + '/avatar';
              // }
              self.$setState({
                account: { ...userInfo, Core: coreDomain }
              });

              if (done) {
                done();
              }
            }).catch(err => {
              console.error(err);
              if (done) {
                done();
              }
            });

            return true;
          },
          logout(e: MouseEvent) {
            $this.rootServices.authService.logout();
          },
          switchAccount(e: MouseEvent) {
            $this.rootServices.authService.logout();
          },
          deleteAccount(e: MouseEvent) {
            const self: F7ComponentContextExtend = this;
            self.$app.dialog.confirm('Bạn có chắc là muốn xóa tài khoản của mình ?', 'Xóa tài khoản', () => {
              self.$app.dialog.prompt('Điền mật khẩu để xác nhận lại lần nữa, sau khi xóa tài khoản bạn không thể đăng ký lại bằng số điện thoại này trong vòng 30 ngày tiếp theo !', 'Xóa tài khoản', (password) => {
                $this.rootServices.apiService.deletePromise('/user/users', { deleteMyAccount: true, password: password }).then(rs => {
                  if (rs['message']) {
                    $this.commonService.showInfo(rs['message']);
                    $this.rootServices.authService.logout().then(status => {
                      location.reload();
                    });
                  }
                }).catch(err => {
                  $this.commonService.showError(err);
                });
              });
            });
          },
          updateAccount() {
            const self: F7ComponentContextExtend = this;
            const profileData: AccountModel = {};
            const fieldEles = self.$el.find('.profile-field');
            fieldEles.each((index, fieldEle) => {
              console.log(fieldEle);
              console.log(fieldEle.value);
              profileData[fieldEle.name] = fieldEle.value;
            });
            console.log(profileData);
            self.$app.preloader.show();
            $this.rootServices.apiService.putPromise<AccountModel>('/user/users/updateAccount', {}, profileData).then(rs => {
              console.log(rs);
              self.$app.preloader.hide();
              $this.commonService.showInfo("Đã cập nhật thông tin tài khoản");
              $this.refresh();
            }).catch(err => {
              console.error(err);
              $this.commonService.showError(err);
              self.$app.preloader.hide();
            });
          },
          async changeAvatar(e) {
            const self: F7ComponentContextExtend = this;
            console.debug(e);

            try {
              const localFiles = await $this.rootServices.pickFiles();
              console.debug(localFiles);

              let image: FileModel = null;
              if (localFiles[0].dataType === 'url') {
                image = await $this.rootServices.uploadLocalFile(localFiles[0].url as string);
              } else {
                const formData = new FormData();
                const imgBlob = new Blob([localFiles[0].url], {
                  type: localFiles[0].type
                });
                formData.append('file', imgBlob, 'smart-bot-' + Date.now() + '.' + localFiles[0].ext);
                image = await $this.rootServices.uploadFileData(formData, null, { weight: localFiles[0].size });
              }
              console.debug(image);
              e.target.style.backgroundImage = 'url(' + image.Thumbnail + ')';

              // const updateProfileResult = await $this.rootServices.apiService.putPromise('/user/users/updateAccount', {}, { Avatar: image.GlobalId, AvatarThumbnail: image.Thumbnail })
              const updateProfileResult = await $this.rootServices.apiService.putPromise('/user/users/updateAccount', {}, { Avatar: image });
              console.debug('Update profile avatar: ', updateProfileResult);

              // Update auth user
              const oldUser = $this.rootServices.authService.user$.getValue();
              oldUser.avatar = oldUser.avatar || { id: '', payload: {} };
              oldUser.avatar = { ...oldUser.avatar, payload: { ...oldUser.avatar.payload, thumbnail: image.Thumbnail, url: image.DownloadLink } }
              $this.rootServices.authService.user$.next(oldUser);

            } catch (err) {
              console.error(err);
              $this.commonService.showError(err);
            }

          },
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          pageInit(e, page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;

            // Set back title
            self.$setState({ backTitle: self.$route.context && self.$route.context.backTitle || 'Back', title: self.$route.context && self.$route.context.title || 'Tài khoản' });

            self.updatePinCodeDialog = self.$app.dialog.create({
              el: page.$el.find('.dialog-update-pin-code'),
              closeByBackdropClick: true,
            });
            self.changePasswordDialog = self.$app.dialog.create({
              el: page.$el.find('.dialog-change-password'),
              closeByBackdropClick: true,
            });

            $this.onChangedState({ instance: self }, 'main');

            $this.refresh();
          },
          // pageBeforeIn(e, page) {
          //     console.log('page before in');
          // },
          // pageAfterIn(e, page) {
          //     console.log('page after in');
          // },
          // pageBeforeOut(e, page) {
          //     console.log('page before out');
          // },
          // pageAfterOut(e, page) {
          //     console.log('page after out');
          // },
          // pageBeforeUnmount(e, page) {
          //     console.log('page before unmount');
          // },
          // pageBeforeRemove(e, page) {
          //     console.log('page before remove');
          // },
        },
      },
    };
  }
}
