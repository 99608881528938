import { Dialog } from "framework7/build/core/components/dialog/dialog";
import { Router } from "framework7/build/core/modules/router/router";
import { take } from "rxjs/operators";
import { BaseFormComponent, FormComponentStateExtend, FormSchema } from "../../../lib/base-form-component";
import { ChatRoomModel } from "../../..//model/chat-room.model";
import { CollaboratorOrderModel, CollaboratorOrderDetailModel } from "../../../model/collaborator.model";
import { ContactModel } from "../../..//model/contact.model";
import { FileModel } from "../../..//model/file.model";
import { ProductModel } from "../../..//model/product.model";
import { CommonService } from "../../..//services/common.service";
import { RootServices } from "../../..//services/root.services";
import { F7ComponentContext, F7Page } from "../../..//types/framework7-types";


// import * as $ from 'jquery';
declare const $: any;

// declare const $: any;
export interface ComponentStateExtend extends FormComponentStateExtend<ContactModel> {
  [key: string]: any;
  instance: F7ComponentContextExtend,
  data?: CollaboratorOrderModel;
  form?: any;
}

export class F7ComponentContextExtend extends F7ComponentContext {
  responseInProgress?: boolean;

  detailDialog?: Dialog.Dialog;

  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, [key: string]: any } };
}

/** Component group manager */
export class CollaboratorPublisherFormComponent extends BaseFormComponent<ComponentStateExtend, CollaboratorOrderModel> {
  // states: { [key: string]: State } = {};
  currentState: ComponentStateExtend;
  namespace = 'collaborator-publisher-form';
  title = 'Cộng tác viên';
  path = '/collaborator/publisher-form/:id';
  idKey = 'Id';
  apiPath = '/collaborator/publishers';

  // unitList: UnitModel[] = [];
  // priceTableList: SalesMasterPriceTable[] = [];

  contactList: ContactModel[] = [];

  schema: FormSchema = {
    Contact: {
      type: 'autocomplete',
      autocompleteOption: { minSearchInputLength: 0, autoFocus: false, limit: 20 },
      ajax: (query: any) => {
        return this.rootServices.apiService.getPromise<ContactModel[]>('/contact/contacts', { search: query, includeIdText: true, includeGroups: true, sort_SearchRank: 'desc' }).then(results => {
          return results.map(m => {
            m.text = `${m.Name}${m.Groups ? ' (' + m.Groups.map(g => this.commonService.getObjectText(g as any)).join(', ') + ')' : ''}`;
            return m;
          });
        });
      },
      label: 'Liên hệ',
      validators: [
        'required'
      ]
    },
    Name: {
      type: 'input',
      label: 'Tên liên hệ',
      validators: [
        'required'
      ]
    },
  };

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
  ) {
    super(rootServices, commonService);
    // // console.log('Click here to open ts file');

    this.rootServices.platform.pause.subscribe(status => {
      // Auto save
      this.currentState?.data?.Code && this.currentState?.data?.State != 'APPROVED' && this.currentState.instance.save();
    });
  }

  makeModel(properties?: CollaboratorOrderModel): CollaboratorOrderModel {
    return new CollaboratorOrderModel(properties);
  }

  async makeNewData() {
    return new CollaboratorOrderModel();
  }

  getFormData(params?: any): Promise<CollaboratorOrderModel> {
    return super.getFormData({
      ...params,
    });
  }

  prepareData(data: CollaboratorOrderModel): CollaboratorOrderModel {
    // for (const detail of data.Details) {

    //   // if (detail?.Unit && typeof detail?.Unit == 'string') {
    //   //   detail.Unit = this.unitList.find(f => f.id == detail.Unit as any);
    //   // }

    //   // if (!data.Pictures) {
    //   //   data.Pictures = [];
    //   // }
    // };
    return data;
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  async onComponentInit(state: ComponentStateExtend, index: string, asCase?: string, f7Page?: F7Page) {
    const $this = this;

    // Start loading status
    state.instance.$setState({
      loading: true,
      schema: this.schema,
    });
    this.commonService.showPreloader();

    // Load Contact Member list
    if (state?.instance?.$route?.context?.chatRoom?.memberList$) {
      await state?.instance?.$route?.context?.chatRoom?.memberList$?.pipe(take(1)).toPromise().then(memberList => {
        state.instance.contactList = memberList.filter(f => f.Type == 'CONTACT').map(m => { m['text'] = m.Name; return m; }) as any;
        if (state.instance.contactList && state.instance.contactList.length > 0) {
          this.schema['Object'].validators = [];
        } else {
          // this.schema['Object'].validators = [];
        }
      });

    }
    // End

    // set static data
    // state.instance.$setState({
    // });

    const currentState: ComponentStateExtend = await super.onComponentInit(state, index, asCase) as any;

    if (currentState.instance.$route?.context?.copyFromId) {
      currentState.instance.data.SubNote = 'Copy of ' + currentState.instance.$route?.context?.copyFromId + ': ' + (currentState.instance.data.SubNote ? ('<br>' + currentState.instance.data.SubNote) : '');
      this.setData(currentState.instance.data);
    }

    if (currentState?.instance?.$route?.context?.relativeVouchers && currentState.instance.$route.context.relativeVouchers[0]?.text) {
      if (!currentState.data.Title) {
        currentState.data.Title = currentState.instance.$route.context.relativeVouchers[0]?.text;
        currentState.instance.$setState({ data: currentState.data });
        this.validate();
      }
    }

    // Prepare data
    // if (currentState.data) {
    //   // for (const detail of currentState.data?.Details) {
    //   //   detail.__unitList = detail.Product?.Units || [];
    //   //   detail.ToMoney = detail.Price * detail.Quantity;
    //   // }
    //   // currentState.data.Object2 = currentState.data.Object;
    //   // currentState.data.Contact2 = currentState.data.Contact;
    //   await this.setData(currentState.data, { prepareControl: true });
    //   // this.calculateTotal();
    // }
    // End Prepare data

    // Stop loading status
    currentState.instance.$setState({ loading: false });
    this.commonService.hidePreloader();
    return currentState;
  }

  async setData(data: CollaboratorOrderModel, option?: { onlyList?: string[]; onlyKeys?: string[]; prepareControl?: boolean; }): Promise<boolean> {
    if (data.State) {
      data.State = this.commonService.getObjectId(data.State);
    }
    // if (data.Contact && data.ContactName) {
    //   data.Contact = {
    //     id: this.commonService.getObjectId(data.Congtact),
    //     text: data.ContactName,
    //   };
    // }
    if (data?.Details) {
      const productIds = data.Details.filter(f => this.commonService.getObjectId(f.Type) != 'CATEGORY').map(m => `${this.commonService.getObjectId(this.currentState.data['Page'])}-${this.commonService.getObjectId(m.Product)}`);
      const productInfoMap: { [key: string]: ProductModel } = await this.rootServices.apiService.getPromise<ProductModel[]>('/collaborator/page-products', {
        id: productIds,
        includeIdText: true,
        includeProduct: true,
        includeUnit: true,
        includeUnitPrices: true,
        productOfPage: true,
      }).then(rs => rs.reduce((prev, next, i) => {
        prev[this.commonService.getObjectId(next)] = next;
        return prev;
      }, {}));

      // const unitPriceMap = await this.rootServices.apiService.getPromise<SalesMasterPriceTableDetailModel[]>('/sales/master-price-table-details', {
      //   priceTable: 'default',
      //   eq_Code: '[' + productIds.join(',') + ']',
      // }).then(rs => rs.reduce((result, current, index) => { result[current.Code + '-' + this.commonService.getObjectId(current.Unit)] = current.Price; return result; }, {}));

      // for (const detail of data.Details) {
      //   if (!detail.__unitList || detail.__unitList.length == 0) {
      //     detail.__unitList = productInfoMap[this.commonService.getObjectId(detail.Product)]?.Units.map(m => {
      //       // m.Price = unitPriceMap[this.commonService.getObjectId(detail.Product) + '-' + this.commonService.getObjectId(m)];
      //       return m;
      //     });
      //     // detail.__unitList = detail.Product?.Units || [];
      //   }
      //   // for (const unit of detail.__unitList) {
      //   //   unit.Price = unitPriceMap[this.commonService.getObjectId(detail.Product) + '-' + this.commonService.getObjectId(unit)];
      //   // }
      //   detail.ToMoney = detail.Price * detail.Quantity;
      // }
    }

    // data.Object = data.Object;
    // data.Publisher = data.Publisher;

    // const total = this.calculateTotal(data);
    return super.setData(data, option).then(status => {
      // this.currentState.instance.$setState({ total });

      // Update tabs

      return status;
    });
  }

  async addItemForList<CollaboratorOrderDetailModel>(listName: string, item?: CollaboratorOrderDetailModel): Promise<CollaboratorOrderDetailModel> {
    return super.addItemForList(listName, item).then(newItem => {
      this.commonService.showInfo('Đã thêm sản phẩm', { position: 'bottom' });
      return newItem;
    }).then(rs => {
      this.activeItem(this.currentState.data.Details.length - 1);
      this.rootServices.playNewPipSound();
      return rs;
    });
  }

  activeItem(index: number, newData?: CollaboratorOrderDetailModel) {

    this.currentState.data.Details.map(detail => {
      detail.__active = false;
    });
    this.currentState.data.Details[index].__active = true;

    // const total = this.calculateTotal();
    this.currentState.instance.$setState({
      data: this.currentState.data,
      // total 
    });
    // if (newData?.AccessNumbers) {
    //   setTimeout(() => {
    //     this.setFieldValue('smart-select', 'AccessNumbers', newData.AccessNumbers, 'Details', index);
    //   }, 300);
    // }
    setTimeout(() => {
      const itemEl = $(this.currentState.instance.el).find('.list-item.index-' + index);
      // itemEl[0] && itemEl[0].scrollIntoView();
      if (itemEl.length > 0) {
        const pageContentEl = $(this.currentState.instance.el).find('.page-content');
        pageContentEl.animate({ scrollTop: pageContentEl.scrollTop() + itemEl.offset().top - itemEl.height() }, 300);
      }
    }, 300);


  }

  async updateImages(state: (progress) => void, onAfterImageUpload?: (image: FileModel) => void) {

    let proloaderProcessing;
    try {
      const localFiles = await this.rootServices.pickFiles();
      console.debug(localFiles);

      this.commonService.showPreloader();
      proloaderProcessing = setTimeout(() => {
        this.commonService.hidePreloader();
      }, 16000);

      let images: FileModel[] = [];
      for (const i in localFiles) {
        // let image: FileModel = null;
        images[i] = await ((progress) => {
          if (localFiles[i].dataType === 'url') {
            return this.rootServices.uploadLocalFile(localFiles[i].url as string, (event) => {
              progress(event)
            });
          } else {
            const formData = new FormData();
            const imgBlob = new Blob([localFiles[i].url], {
              type: localFiles[i].type
            });
            formData.append('file', imgBlob, 'smart-bot-' + Date.now() + '.' + localFiles[i].ext);
            return this.rootServices.uploadFileData(formData, (event) => {
              progress(event)
            });
          }
        })((progress) => {
          console.log(progress);
          state(progress);
        });
        onAfterImageUpload && onAfterImageUpload(images[i]);
      }
      console.debug(images);
      clearTimeout(proloaderProcessing);
      this.commonService.hidePreloader();
      return images;
    } catch (err) {
      console.error(err);
      this.commonService.showError(err, { position: 'bottom' }); clearTimeout(proloaderProcessing);
      this.commonService.hidePreloader();
      throw new Error(err);
    }
  }

  async onFieldChange(self: F7ComponentContext, e: any, ...args: any) {
    const currentState = this.currentState;
    return super.onFieldChange(self, e, args).then(async fieldInfo => {

      if (!fieldInfo) {
        return null;
      }

      let { field, fieldName, fieldValue, index, listName, previousValue: previousUnit } = fieldInfo;
      let requireSetData = false;

      console.log('on field change', listName, index, fieldName, fieldValue);
      // if (listName == 'Details' && fieldName == 'Quantity') {

      //   currentState.data[listName][index]['ToMoney'] = (currentState.data[listName][index]['Quantity'] || 0) * (currentState.data[listName][index]['Price'] || 0);
      //   // currentState.instance.$setState({ data: currentState.data });
      //   this.rootServices.playDecreasePipSound();
      //   requireSetData = true;
      // }

      // if (listName == 'Details' && fieldName == 'Price') {

      //   currentState.data[listName][index]['ToMoney'] = (currentState.data[listName][index]['Quantity'] || 0) * (currentState.data[listName][index]['Price'] || 0);
      //   currentState.instance.$setState({ data: currentState.data });
      //   // $(currentState.instance.el).find('.list-name[name="' + listName + '"] .index-' + index + ' [name="ToMoney"]').val(currentState.data[listName][index]['ToMoney']);
      // }
      // if (listName == 'Details' && fieldName == 'ToMoney') {

      //   if (currentState.data[listName][index]['Quantity'] > 0) {
      //     currentState.data[listName][index]['Price'] = (currentState.data[listName][index]['ToMoney'] || 0) / currentState.data[listName][index]['Quantity'];
      //   }
      //   // currentState.instance.$setState({ data: currentState.data });
      //   requireSetData = true;
      //   // $(currentState.instance.el).find('.list-name[name="' + listName + '"] .index-' + index + ' [name="Price"]').val(currentState.data[listName][index]['Price']);
      // }

      // if ((fieldName == 'Object') && fieldValue && !currentState.instance.loading) {
      //   await this.rootServices.apiService.getPromise<ContactModel[]>('/contact/contacts/' + this.commonService.getObjectId(fieldValue), {}).then(contacts => contacts[0]).then(contact => {

      //     // Auto fill object
      //     currentState.data.Object = fieldValue;
      //     currentState.data.ObjectName = contact.Name;
      //     currentState.data.ObjectPhone = contact.Phone;
      //     currentState.data.ObjectEmail = contact.Email;
      //     currentState.data.ObjectAddress = contact.Address;
      //     currentState.data.ObjectIdentified = contact.TaxCode;

      //     // currentState.instance.$setState({
      //     //   data: currentState.data,
      //     // });
      //     requireSetData = true;
      //     this.validate();
      //   });
      // }
      // if ((fieldName == 'Publisher') && fieldValue && !currentState.instance.loading) {
      //   await this.rootServices.apiService.getPromise<ContactModel[]>('/contact/contacts/' + this.commonService.getObjectId(fieldValue), {}).then(contacts => contacts[0]).then(contact => {

      //     // Auto fill object
      //     currentState.data.Contact = fieldValue;
      //     currentState.data.ContactName = contact.Name;
      //     currentState.data.ContactPhone = contact.Phone;
      //     currentState.data.ContactEmail = contact.Email;
      //     currentState.data.ContactAddress = contact.Address;
      //     currentState.data.ContactIdentified = contact.TaxCode;

      //     // currentState.instance.$setState({
      //     //   data: currentState.data,
      //     // });

      //     this.validate();
      //     requireSetData = true;
      //   });
      // }

      // if (listName == 'Details' && fieldName == 'Unit') {
      //   // let price = currentState.data[listName][index]?.Price;
      //   const detail = currentState.data[listName][index];
      //   previousUnit = detail.__unitList.find(f => this.commonService.getObjectId(f) == this.commonService.getObjectId(previousUnit));
      //   if (previousUnit && this.commonService.getObjectId(previousUnit) != this.commonService.getObjectId(detail.Unit)) {
      //     const nextUnit = detail.__unitList.find(f => this.commonService.getObjectId(f) == this.commonService.getObjectId(fieldValue));
      //     if (previousUnit.ConversionRatio && nextUnit.ConversionRatio) {
      //       detail['Quantity'] = detail['Quantity'] * previousUnit.ConversionRatio;
      //       // detail['Price'] = detail['Price'] / previousUnit.ConversionRatio;
      //       detail['Quantity'] = detail['Quantity'] / nextUnit.ConversionRatio;
      //     }
      //     detail['Price'] = nextUnit.Price;
      //     detail['ToMoney'] = detail['Price'] * detail['Quantity'];

      //     let business = [];
      //     const product = detail.Product;
      //     // if (product) {
      //     //   if (this.commonService.getObjectId(detail.Type) == 'PRODUCT') {
      //     //     const unit = detail.__unitList.find(f => this.commonService.getObjectId(f) == this.commonService.getObjectId(detail.Unit)) || null;
      //     //     if (unit) {
      //     //       if (unit.IsAutoAdjustInventory === false) {
      //     //         business = [{
      //     //           id: 'PURCHASESKIPWAREHOUSE',
      //     //           text: 'Mua hàng nhưng không nhập kho (công nợ)',
      //     //         }];
      //     //       }
      //     //       if (unit.IsAutoAdjustInventory === true) {
      //     //         business = [{
      //     //           id: 'PURCHASEWAREHOUSE',
      //     //           text: 'Mua hàng sau đó nhập kho (công nợ)',
      //     //         }];
      //     //       }
      //     //     }
      //     //   }
      //     //   if (this.commonService.getObjectId(detail.Type) == 'SERVICE') {
      //     //     business = [{
      //     //       id: 'PURCHASECOST',
      //     //       text: 'Chi phí phát sinh khi mua hàng: vận chuyển, xăng dầu,... (công nợ)',
      //     //     }];
      //     //   }
      //     //   detail['Business'] = business;
      //     // }
      //   }
      // }

      if (requireSetData) {
        // const total = this.calculateTotal();
        console.log('collaborator form set data: ', currentState.data);
        currentState.instance.$setState({
          data: currentState.data,
          // total: total,
        });
      } else {
        // currentState.instance.$setState({ total: this.calculateTotal() });
      }

      return fieldInfo;
    });
  }

  // async removeDetailItem(self: F7ComponentContext, e: any, ...args: any): Promise<{ detail?: any, listName?: string; index?: string | number; }> {
  //   const currentState = this.currentState;
  //   return new Promise((resolve, reject) => {
  //     self.$app.dialog.confirm('Bạn có chắc là muốn gở chi tiết này ?', 'Gở chi tiết', () => {
  //       resolve(super.removeDetailItem(self, e, args).then(detailInfo => {
  //         let { detail, listName, index } = detailInfo;
  //         if (listName == 'UnitConversions') {
  //           self.$app.accordion.close(detail[0]);
  //         }
  //         // this.calculateTotal();
  //         this.rootServices.playDecreasePipSound();
  //         return detailInfo;
  //       }).catch(err => {
  //         return Promise.reject(err);
  //       }));
  //     });
  //   });
  // }


  // async chooseProduct(self: F7ComponentContextExtend) {
  //   // const self: F7ComponentContextExtend = this;
  //   const $this = this;
  //   const currentState = this.currentState;
  //   this.rootServices.navigate('/collaborator/product-list/choose', {
  //     context: {
  //       page: $this.commonService.getObjectId($this.currentState.data['Page']),
  //       backTitle: this.title,
  //       textColorClass: self.textColorClass,
  //       async onChoose(product: ProductModel, unitId?: string) {
  //         currentState.instance.$router.back();
  //         console.log('Choosed product', product);

  //         // const productInfo = (await $this.rootServices.apiService.getPromise<ProductModel[]>('/admin-product/products/' + product.Code, { includeIdText: true, includeUnits: true }))[0];
  //         product.id = product.Product;
  //         product.text = product.Name;
  //         // const unit = product.UnitConversions && product.UnitConversions.find(f => f.IsDefaultPurchase) || product.UnitConversions[0]
  //         // const unit = product.UnitConversions && product.UnitConversions.length == 1 && product.UnitConversions[0] || null;
  //         // const unit = product.UnitList.find(f => f.id == $this.commonService.getObjectId(product.Unit)) || product.UnitList[0] || product.Unit || null;

  //         // Load price
  //         // const unitPriceMap = await $this.rootServices.apiService.getPromise<SalesMasterPriceTableDetailModel[]>('/sales/master-price-table-details', {
  //         //   priceTable: 'default',
  //         //   eq_Code: product.Code,
  //         // }).then(rs => rs.reduce((result, current, index) => {
  //         //   result[current.Code + '-' + $this.commonService.getObjectId(current.Unit)] = current.Price;
  //         //   return result;
  //         // }, {}));

  //         // for (const unit of product.Units) {
  //         //   unit.Price = unitPriceMap[product.Code + '-' + $this.commonService.getObjectId(unit)];
  //         // }


  //         let unit = null;
  //         if (unitId) {
  //           unit = product.Units.find(f => $this.commonService.getObjectId(f) == unitId);
  //         } else {
  //           unit = product.Units.find(f => f.IsDefaultSales) || product.Units[0] || null;
  //         }

  //         const index = currentState.data.Details.findIndex(f => $this.commonService.getObjectId(f.Product) == product.id && $this.commonService.getObjectId(f.Unit) == $this.commonService.getObjectId(unit));
  //         if (index > -1) {
  //           let item = currentState.data['Details'][index];
  //           item.Quantity++;
  //           $this.updateItemForList('Details', index, item);
  //           $this.commonService.showInfo('Tăng số lượng lên ' + item.Quantity + ' cho sản phẩm ' + $this.commonService.getObjectText(product) + ' !', { position: 'bottom' });
  //           return;
  //         }

  //         let business = [];
  //         if ($this.commonService.getObjectId(product.Type) == 'PRODUCT') {
  //           if (unit) {
  //             if (unit.IsAutoAdjustInventory === false) {
  //               business = [{
  //                 id: 'PURCHASESKIPWAREHOUSE',
  //                 text: 'Mua hàng nhưng không nhập kho (công nợ)',
  //               }];
  //             }
  //             if (unit.IsAutoAdjustInventory === true) {
  //               business = [{
  //                 id: 'PURCHASEWAREHOUSE',
  //                 text: 'Mua hàng sau đó nhập kho (công nợ)',
  //               }];
  //             }
  //           }
  //         }
  //         if ($this.commonService.getObjectId(product.Type) == 'SERVICE') {
  //           business = [{
  //             id: 'PURCHASECOST',
  //             text: 'Chi phí phát sinh khi mua hàng: vận chuyển, xăng dầu,... (công nợ)',
  //           }];
  //         }

  //         $this.addItemForList('Details', {
  //           ...new CollaboratorOrderDetailModel(),
  //           Type: product.Type || 'PRODUCT',
  //           // Business: business,
  //           Product: {
  //             id: product.id,
  //             text: product.text,
  //             Name: product.ProductName,
  //             Units: product.Units,
  //           },
  //           Description: product.Name,
  //           Unit: unit,
  //           Price: unit.Price || 0,
  //           Quantity: 1,
  //           // Tax: 'NOTAX',
  //           __unitList: product.Units,
  //           Image: [
  //             ...[product.FeaturePicture],
  //             ...(Array.isArray(product.Pictures) && product.Pictures || []).filter(f => f.Id != product?.FeaturePicture?.Id)
  //           ]
  //         });

  //       },
  //     }
  //   });
  // }

  // calculateTotal(data?: CollaboratorOrderModel) {
  //   const currentState = this.currentState;
  //   let total = 0;
  //   const calculateData = data || currentState.data;
  //   for (const detail of calculateData.Details) {
  //     const toMoney = detail.ToMoney = (detail.Quantity || 0) * (detail.Price || 0);
  //     total += toMoney;
  //   }

  //   // currentState.instance.$setState({ total });
  //   return total;
  // }

  async save(self: F7ComponentContext, option?: { postParams?: any, updateProperties?: string[] }) {
    const $this = this;
    if (!option) option = {};
    if (!option.postParams) option.postParams = {};
    option.postParams.page = $this.commonService.getObjectId($this.currentState.data.Page);
    return super.save(self, option).then(rs => {
      this.currentState.data.State = rs.State;
      self.$setState({ data: this.currentState.data });
      return rs;
    });
  }

  // async savePdf(self: F7ComponentContextExtend, e: any) {
  //   if (this.currentState.data.State !== 'APPROVED') {
  //     await this.save(self);
  //   }
  //   this.rootServices.iab.create(this.rootServices.apiService.buildApiUrl(this.apiPath, { id: this.currentState.data.Code, includeContact: true, includeDetails: true, includeUnit: true, renderPdf: 'download' }) as string, '_system');
  // }
  // async changeState(self: F7ComponentContextExtend, e: any) {
  //   // const newState = this.currentState.data.State == 'APPROVED' ? 'NOTJUSTAPPROVED' : 'APPROVED';
  //   const currentState = this.rootServices.collaboratorService.processMap[this.currentState.data.State];
  //   if (currentState && currentState.nextStates) {
  //     const supplierDialog = this.currentState.instance.$app.dialog.create({
  //       cssClass: 'dialog-large',
  //       title: 'Chuyển trạng thái',
  //       text: 'Phiếu đang ở trạng thái `' + currentState.label + '`, bạn có muốn chuyển sang các trạng thái bên dưới ?',
  //       buttons: [
  //         ...currentState.nextStates.map((m, index) => ({
  //           text: (index + 1) + '. ' + m.confirmLabel,
  //           color: 'success',
  //           onClick: () => {
  //             this.rootServices.apiService.putPromise(this.apiPath + '/' + this.currentState.data.Code, { changeState: m.state }, [{ Code: this.currentState.data.Code }]).then(rs => {
  //               this.currentState.data.State = m.state;
  //               this.setData(this.currentState.data);
  //             }).catch(err => {
  //               this.commonService.showError(err);
  //             });
  //           }
  //         })),
  //         {
  //           text: 'Trở về',
  //           bold: true,
  //           color: 'red'
  //         },
  //       ],
  //       verticalButtons: true,
  //     });
  //     supplierDialog.open();
  //   }

  //   // this.currentState.instance.$app.dialog.confirm('Bạn có muốn ' + (newState == 'APPROVED' ? 'duyệt' : 'bỏ duyệt') + ' phiếu báo giá `' + this.currentState.data.Title + '`', 'Thay đổi trạng thái báo giá', async () => {

  //   //   if (this.currentState.data.State !== 'APPROVED') {
  //   //     await this.save(self);
  //   //   }
  //   //   await this.rootServices.apiService.putPromise<CollaboratorOrderModel>(this.apiPath, {
  //   //     changeState: newState,
  //   //   }, [{ Code: this.currentState.data.Code }]).then(rs => {
  //   //     if (newState == 'APPROVED') {
  //   //       this.commonService.showInfo('Đã duyệt phiếu đặt mua hàng !', { position: 'bottom' });
  //   //     } else {
  //   //       this.commonService.showInfo('Đã bỏ duyệt phiếu đặt mua hàng !', { position: 'bottom' });
  //   //     }
  //   //   });
  //   //   this.currentState.data.State = newState;
  //   //   this.setData(this.currentState.data);

  //   // });
  // }



  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: this.namespace,
      path: this.path,
      component: {
        template: /*html*/`
            <div class="page page-form ${this.namespace} no-toolbar-x" data-name="${this.namespace}">
              <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a class="link back {{textColorClass}}">
                      <i class="icon icon-back"></i>
                      <span class="if-not-md {{textColorClass}}">{{backTitle}}</span>
                    </a>
                  </div>
                  <div class="title">${this.title}</div>
                  <div class="right">
                    <a class="link icon-only {{textColorClass}}">
                      <i class="icon f7-icons">arrowshape_turn_up_right</i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="page-content ptr-content infinite-scroll-content" @ptr:refresh="refresh" data-ptr-distance="150">
                <div class="ptr-preloader">
                  <div class="preloader"></div>
                  <div class="ptr-arrow"></div>
                </div>

                <form class="data-form">
                  <div class="list profile-form no-hairlines-md inset main-form">
                    <ul>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label">ID (*)</div>
                          <div class="item-input-wrap">
                            <input @change="onFieldChange" disabled class="field text-color-blue" name="Code" type="text" placeholder="ID phiếu, auto..."
                              value="{{data.Code}}">
                          </div>
                        </div>
                      </li>
                      {{#if copyFromId}}
                      <li class="item-content">
                        <div class="item-inner">
                          <div class="item-input-wrap text-color-orange">
                            <span style="font-weight: bold; font-style: italic">Bạn đang thao tác trên phiếu copy</span>
                          </div>
                        </div>
                      </li>
                      {{/if}}
                      <li class="autocomplete {{validate data.Contact schema 'Contact'}}" name="Contact">
                        <div class="item-link item-content" href="#">
                          <div class="item-inner">
                            <div class="item-title">ID Liên hệ</div>
                            <div class="item-after text-color-blue">{{text data.Contact coalesce="Chọn..."}}</div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div class="block-title">Thông tin liên hệ</div>
                  <div class="list profile-form no-hairlines-md inset main-form">
                    <ul>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label {{validate data.Name schema 'Name'}}">Tên (*)</div>
                          <div class="item-input-wrap">
                            <input @keyup="onFieldChange" class="field text-color-bluex" name="Name" type="text" placeholder="Tên..."
                              value="{{data.Name}}">
                            
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label">Số điện thoại (*)</div>
                          <div class="item-input-wrap">
                            <input @keyup="onFieldChange" class="field text-color-bluex" name="Phone" type="text" placeholder="Số điện thoại..."
                              value="{{data.Phone}}">
                            
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label">Email (*)</div>
                          <div class="item-input-wrap">
                            <input @keyup="onFieldChange" class="field text-color-bluex" name="Email" type="text" placeholder="Email..."
                              value="{{data.Email}}">
                            
                          </div>
                        </div>
                      </li>
                      <li class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label">Địa chỉ (*)</div>
                          <div class="item-input-wrap">
                            <input @keyup="onFieldChange" class="field text-color-bluex" name="Address" type="text" placeholder="Địa chỉ..."
                              value="{{data.Address}}">
                            
                          </div>
                        </div>
                      </li>
                      <!--
                      <li class="autocomplete {{validate data.Province schema 'Province'}}" name="Province">
                        <div class="item-link item-content" href="#">
                          <div class="item-inner">
                            <div class="item-title">Tỉnh/TP (*)</div>
                            <div class="item-after text-color-blue">{{text data.Province}}</div>
                          </div>
                        </div>
                      </li>
                      <li class="autocomplete {{validate data.District schema 'District'}}" name="District">
                        <div class="item-link item-content" href="#">
                          <div class="item-inner">
                            <div class="item-title">Quận/Huyện (*)</div>
                            <div class="item-after text-color-blue">{{text data.District}}</div>
                          </div>
                        </div>
                      </li>
                      <li class="autocomplete {{validate data.Ward schema 'Ward'}}" name="Ward">
                        <div class="item-link item-content" href="#">
                          <div class="item-inner">
                            <div class="item-title">Phường/Xã (*)</div>
                            <div class="item-after text-color-blue">{{text data.Ward}}</div>
                          </div>
                        </div>
                      </li>
                      -->
                    </ul>
                  </div>

                  <div class="block">
                    <p class="row">
                      <a class="col-100 button button-fill button-large color-blue {{#if validates}}disabled{{/if}}"  @click="save"> Lưu lại</a>
                    </p>
                  </div>

                  {{#if validates}}
                  <div class="block-title text-color-arange">Cảnh báo</div>
                  <div class="list inset">
                    <ul>
                      {{#each validates}}
                      <li>
                        <div class="item-content">
                          <div class="item-inner">
                            <div class="item-title">{{validates[0].label}}</div>
                            <div class="item-after text-color-orange">{{validates[0].text}}</div>
                          </div>
                        </div>
                      </li>
                      {{/each}}
                    </ul>
                  </div>
                  {{/if}}
                </form>
            
                <div class="block"><br></div>
              </div>
            </div>
        `,
        style: /*css*/`
          .${this.namespace} .demo-card-header-pic .card-header {
            height: calc(100vw - 2rem);
            background-size: cover;
            background-position: center;
            color: #fff;
          }

          .${this.namespace} .demo-card-header-pic .card-content-padding .date {
            color: #8e8e93;
          }
          .${this.namespace} .pictures::-webkit-scrollbar {
            display: none;
          }

          .${this.namespace} .card .background {
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-image: url(/assets/images/no-image-available.png);
          }

          .${this.namespace} .inline-labels .item-label, .inline-label .item-label, .inline-labels .item-floating-label, .inline-label .item-floating-label {
            width: auto !important;
          }
        `,
        data() {
          return {
            title: $this.title,
            data: new ContactModel(),
            validates: null,
          };
        },
        methods: {
          onFieldChange(e) {
            $this.onFieldChange(this, e);
          },
          async refresh(e, done) {
            done && setTimeout(() => done(), 10000);
            $this.refresh(this).then(data => {
              done && done();
            });
            return true;
          },
          save() {
            const self: F7ComponentContextExtend = this;
            const currentState = $this.currentState;
            const relativeVouchers = self.$route.context.relativeVouchers;
            const id = currentState.data.Code || self.$route.params['id'];

            $this.commonService.showPreloader();

            const preloaderProcess = setTimeout(() => {
              $this.commonService.hidePreloader();
            }, 15000);

            if (id === 'new') {
              if (relativeVouchers) {
                currentState.data.RelativeVouchers = relativeVouchers;
              }
            }

            $this.save(self).then(rs => {
              $this.commonService.showInfo("Đã lưu thông tin", { position: 'bottom' });
              clearTimeout(preloaderProcess);
              $this.commonService.hidePreloader();
            }).catch(err => {
              clearTimeout(preloaderProcess);
              $this.commonService.hidePreloader();
              console.error(err);
            });
          },
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          pageInit(e, page: F7Page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;

            $this.onComponentInit({ instance: self, page: page }, self.$route.params['id'], null, page).then(curentState => {
              // self.detailDialog = self.$app.dialog.create({
              //   el: page.$el.find('.dialog-detail'),
              //   closeByBackdropClick: true,
              // });
            });

            $this.rootServices.authService.isAuthenticatedOrRefresh().pipe(take(1)).toPromise().then(() => {
              self.$setState({
                backTitle: self.$route.context && self.$route.context.backTitle || 'Back', title: self.$route.context && self.$route.context.title || 'Chat GUI',
              });
            }).catch(err => {
              console.error(err);
              $this.commonService.showError(err, { position: 'bottom' });
            });
          },
          // pageBeforeIn(e, page) {
          //     console.log('page before in');
          //     const self: F7ComponentContextExtend = this;
          //     self.refresh();
          // },
          // pageAfterIn(e, page) {
          //     console.log('page after in');
          // },
          pageBeforeOut(e, page) {
            console.log('page before out');
            const self: F7ComponentContextExtend = this;
            // Call caback function
            if (this.$route?.context['callback']) {
              this.$route?.context['callback']($this.currentState.data, { lastAction: $this.currentState.lastAction });
            }

            // Auto save
            $this.currentState?.data?.Code && $this.currentState?.data?.State != 'APPROVED' && self.save();
          },
          // pageAfterOut(e, page) {
          //     console.log('page after out');
          // },
          // pageBeforeUnmount(e, page) {
          //     console.log('page before unmount');
          // },
          // pageBeforeRemove(e, page) {
          //     console.log('page before remove');
          // },
          pageBeforeRemove(e, page) {
            console.log('[page event] before remove', page.route.url);
            const self: F7ComponentContextExtend = this;
            // const starmtSelectRoles = self.$app.smartSelect.get('.smart-select');
            // starmtSelectRoles.destroy();
            const currentState = $this.state[self.$route.params.id];
            if (self.$route.params.id === 'new') {
              if (currentState && currentState.data && currentState.data.Code) {
                delete currentState.data;
              }
            }
          },
        },
      },
    };
  }
}
