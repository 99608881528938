import { ProductGroupModel } from '../../model/product.model';
import { F7ListComponentContext } from '../../lib/base-list-component';
import { F7Page } from '../../types/framework7-types';
import { Router } from 'framework7/build/core/modules/router/router';
import { ComponentState } from '../../lib/base-component';
import { RootServices } from '../../services/root.services';
import { ChatRoom } from '../../lib/nam-chat/chat-room';
import { CommonService } from '../../services/common.service';
import { TaskReminderModel } from '../../model/task.model';
import { BehaviorSubject } from 'rxjs';
import { ProductCategoryModel, ProductModel } from '../../model/product.model';
import { ProductListComponent } from '../product-list';
import { Template7 } from 'framework7/build/core/framework7.esm';
import { AdminProductProductListComponent } from '../admin-product/product-list';
import { Model } from '../../model/model';
declare const $: any;

// declare const $: any;
export interface ComponentStateExtend extends ComponentState {
  [key: string]: any;
}

export interface F7ComponentContextExtend extends F7ListComponentContext<ProductModel> {
  responseInProgress?: boolean;
  productList?: ProductModel[];
  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, reminder?: TaskReminderModel, chatRoom?: ChatRoom, reminderList$?: BehaviorSubject<TaskReminderModel[]> } };

  categoryList?: ProductCategoryModel[],
  pageList?: Model[],
  groupList?: ProductGroupModel[],

}

/** Component group manager */
export class CollaboratorProductListComponent extends AdminProductProductListComponent {
  namespace: string = 'collaborator-product';
  itemHeight = 208;

  apiPath = '/collaborator/products';
  componentName = 'collaborator-product-list';
  route = '/collaborator/product-list/:case';

  limit = 20;
  title: 'Sản phẩm';
  idKey = 'Code';

  filterSchema = {
    Categories: {
      type: 'smart-select',
      dataType: 'multiple'
    },
    Groups: {
      type: 'smart-select',
      dataType: 'multiple'
    },
    Page: {
      type: 'smart-select',
      // dataType: 'multiple'
    },
  };

  defaultPage: any;

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
  ) {
    super(rootServices, commonService);
    // // console.log('Click here to open ts file');
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  async onComponentInit(state: ComponentStateExtend, index: string, asCase?: string, f7Page?: F7Page) {


    await this.rootServices.apiService.getPromise<any[]>('/collaborator/pages', { onlyIdText: true, limit: 'nolimit' }).then(rs => {
      state.instance.pageList = rs;
      if (rs.length == 1) {
        this.defaultPage = rs[0];
      }
    });



    state.filter = {
      Page: state.instance.$route?.context['page'],
    };

    return super.onComponentInit(state, index, asCase, f7Page).then(state => {
      state.instance.$setState({
        multichoose: state?.instance?.$route?.params['case'] == 'multichoose',
        hideprice: state?.instance?.$route?.params['case'] == 'multichoose',
        chooseList: [],
      });
      return state;
    });
  }

  // onItemClick(self: F7ListComponentContext<ProductModel>, item: ProductModel): void {
  //   return;
  // }

  // prepareItem(item: ProductModel, index?: number): ProductModel {
  //   item.UnitList = item.Units;
  //   return super.prepareItem(item, index);
  // }

  async getList(self?: F7ComponentContextExtend, extParams?: any): Promise<ProductModel[]> {
    const $this = this;
    const filter = this.parseFilter(this.currentState?.filter);
    return this.rootServices.apiService.getPromise<any[]>(this.apiPath, {
      includeIdText: true,
      includeCategories: true,
      includeGroups: true,
      includeProduct: true,
      includeUnit: true,
      includeUnitPrices: true,
      productOfPage: true,
      onlyBusinessProducts: true,
      ...(self.$route.context as any)?.query,
      // page: $this.currentState?.instance?.$route?.context && $this.currentState?.instance?.$route?.context['page'] || this.commonService.getObjectId($this.defaultPage),
      ...filter,
      ...extParams,
      page: filter['eq_Page'],
    }).then(list => {
      for (const item of list) {
        // item.id = item.Product;
        // item.Name = item.ProductName;
        if (!item.Units && item.Unit) {
          item.Units = [{ ...item.Unit, Price: item.Price }];
        }
      }
      self.$setState({ productList: list.map((t, i) => this.prepareItem(t, i)), infiniteLoadLast: list.length === 0 });
      self.infiniteOffset = 10;
      return list;
    }).catch(err => {
      console.error(err);
      this.commonService.showError(err);
      return Promise.reject(err);
    });
  }

  prepareItem(item: ProductModel, index?: number): ProductModel {
    item.multichoose = this.currentState.instance.$route.params.case == 'multichoose';
    item.hideprice = item.multichoose;
    return super.prepareItem(item, index);
  }

  itemBeforeInsert(element: HTMLElement, item: ProductModel): void {
    super.itemBeforeInsert(element, item);
    const miltichooseCheckBox = $(element).find('[name="multichoose"]');
    miltichooseCheckBox.change(() => {
      if (miltichooseCheckBox.is(':checked')) {
        this.currentState.instance.chooseList[item[this.idKey]] = item[this.idKey];
      } else {
        delete this.currentState.instance.chooseList[item[this.idKey]];
      }
      this.currentState.instance.$setState({ chooseList: this.currentState.instance.chooseList });
      console.log(this.currentState.instance.chooseList);
    });
  }
  // itemBeforeInsert(element: HTMLElement, item: ProductModel): void {
  //   const $this = this;
  //   const result = super.itemBeforeInsert(element, item);
  //   $(element).find('.unitBtn').unbind('click').click(async function () {
  //     const unitId = $(this).attr('data-unit');
  //     if (item.Units && item.Units.length > 0) {
  //       const unit = item.Units.find(u => u.id == unitId);
  //       if (unit) {
  //         let listItemTemplate = Template7.compile(/*html*/`
  //           <div class="list media-list" style="margin-left: -1rem; margin-right: -1rem; margin-bottom: 0">
  //             <ul>
  //               {{#each RelativeObjects}}
  //               <li data-container="{{id this}}">
  //                 <a href="#" class="item-link item-content">
  //                   <!--<div class="item-media"><i class="icon icon-f7"></i></div>-->
  //                   <div class="item-inner">
  //                     <div class="item-title-row">
  //                       <div class="item-title">{{text this}}</div>
  //                       <div class="item-after">{{currency price}}</div>
  //                     </div>
  //                     <div class="item-text" style="text-align: left">Lần cuối: {{date date}}</div>
  //                   </div>
  //                 </a>
  //               </li>
  //               {{/each}}
  //             </ul>
  //           </div>
  //           `);
  //         const dialog = $this.currentState.instance.$app.dialog.create({
  //           title: 'Nhà cung cấp liên quan',
  //           text: item.Name + ' (' + $this.commonService.getObjectText(unit) + ') được nhập bởi các nhà cung cấp bên dưới',
  //           content: listItemTemplate({ RelativeObjects: unit.RelativeObjects }),
  //           buttons: [
  //             {
  //               text: 'Trở về',
  //               bold: true,
  //               color: 'red'
  //             },
  //           ],
  //           verticalButtons: true,
  //         });
  //         dialog.open();
  //       }
  //     }
  //   });
  //   return result;
  // }

  itemTemplate = /*html*/`
  <div class="block block-strong inset list-item index-{{__index}}" data-id="{{Code}}" data-index="{{__index}}" style="height: 197px; overflow: hidden; margin-top: 0; margin-bottom: 10px">
    <div class="item-content">
      <div class="detail-wrap">
        <div class="detail-header">
          <div class="header-label">{{Name}}</div>
          {{#if multichoose}}
          <div class="detail-choose chooseOne link"><input type="checkbox" name="multichoose" style="width: 32px; height: 32px"></div>
          {{else}}
          <div class="detail-choose chooseOne link"><i class="icon f7-icons color-orange" style="font-size: 2rem;">cart_fill_badge_plus</i></div>
          {{/if}}
        </div>
        <div class="detail-body" style="padding-bottom: 0.5rem;">
          <div class="image-wrap">
            <div class="image previewPictures" data-index="0" style="height: 112px; {{js "this.FeaturePicture && this.FeaturePicture.Thumbnail && ('background-image: url('+this.FeaturePicture.Thumbnail+')') || ''"}}"></div>
          </div>
          <div class="detail-content-wrap">
            <div class="list inline-labels no-hairlines">
              <ul>
                <li class="item-content item-input item-link showBarcode" data-unit="{{id}}"  style="padding-left: 0; min-height: 2rem;">
                  <div class="item-inner">
                    <div class="item-title item-label">ĐVT</div>
                    <div class="unit" style="text-align: right">Giá</div>
                  </div>
                </li>
                {{#each Units}}
                <li class="item-content item-input item-link showBarcode chooseOne" data-unit="{{id}}"  style="padding-left: 0; min-height: 2rem;">
                  <div class="item-inner">
                    <div class="item-title item-label">{{text}}</div>
                    <div class="unit text-color-blue" style="text-align: right">
                      {{#if ../hideprice}}
                      --
                      {{else}}
                      {{currency Price}}
                      {{/if}}
                    </div>
                  </div>
                </li>
                {{/each}}
                <li class="item-content item-input" style="padding-left: 0; min-height: 2rem;">
                  <div class="item-inner" style="padding-top: var(--f7-list-item-padding-vertical);
                  padding-bottom: var(--f7-list-item-padding-vertical);">
                    <div>
                      <i class="openProduct f7-icons text-color-gray link" style="font-size: 21px; float: left; line-height: 26px; margin-right: 0.5rem;">square_pencil</i>
                      <i class="copyProduct f7-icons text-color-gray link" style="font-size: 20px; float: left; line-height: 26px; margin-right: 0.5rem; margin-top: 2px;">square_on_square</i>
                    </div>
                    <!--<i class="chooseOne f7-icons text-color-orange link" style="font-size: 32px; float: left; line-height: 26px; margin-left: 1rem">cart_fill_badge_plus</i>-->
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="detail-footer">SKU: {{Sku}}, ID: {{Code}}{{#js_if "this.Categories && this.Categories.length > 0"}}, DM: {{text Categories}}{{/js_if}}</div>
      </div>
    </div>
  </div>
  `;

  get f7Component(): Router.RouteParameters {
    const $this = this;
    const superComponent = super.f7Component;
    return {
      name: this.componentName,
      path: this.route,
      ...superComponent,
      component: {
        ...superComponent.component,
        template: /*html*/`
            <div class="page page-${$this.namespace} no-toolbar-x" data-name="page-${$this.namespace}">
              <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a class="link back {{textColorClass}}">
                      <i class="icon icon-back"></i>
                      <span class="if-not-md {{textColorClass}}">{{backTitle}}</span>
                    </a>
                  </div>
                  <div class="title">Hàng hóa/Dịch vụ CTV</div>
                  <div class="right">
                    <!-- Link to enable searchbar -->
                    <a class="link icon-only searchbar-enable {{textColorClass}}" data-searchbar=".${$this.namespace}-searchbar">
                      <i class="icon f7-icons if-not-md">search</i>
                      <i class="icon material-icons md-only">search</i>
                    </a>
                  </div>
                  <!-- Searchbar is a direct child of "navbar-inner" -->
                  <form class="searchbar ${$this.namespace}-searchbar searchbar-expandable">
                    <div class="searchbar-inner">
                      <div class="searchbar-input-wrap">
                        <input type="search" placeholder="Search" />
                        <i class="searchbar-icon"></i><span class="input-clear-button"></span>
                      </div>
                      <span class="searchbar-disable-button {{textColorClass}}">Hủy</span>
                    </div>
                  </form>
                </div>
              </div>
              <div class="page-content ptr-content infinite-scroll-content" @ptr:refresh="refresh">
                <div class="ptr-preloader">
                  <div class="preloader"></div>
                  <div class="ptr-arrow"></div>
                </div>
                <div class="searchbar-backdrop ${$this.namespace}-backdrop"></div>

                <div class="block-title" style="margin-top: 2rem">Bộ lọc <a style="float: right" @click="resetFilter"
                class="text-color-red">đặt lại</a></div>
                <div class="block block-strong inset filter" style="padding: 0; overflow: hidden">
                  <div class="list accordion-list inline-labels no-hairlines" style="margin: 0;">
                    <ul>
                      <li>
                        <a class="item-link smart-select smart-select-init" name="Page" data-open-in="popup" data-searchbar="true" data-virtual-list="true" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFilterFieldChange">
                            <select>
                              <option value="">Chọn</option>
                              {{#each pageList}}
                              <option value="{{id}}">{{text}}</option>
                              {{/each}}
                            </select>
                            <div class="item-content">
                              <div class="item-inner">
                                <div class="item-title">Trang</div>
                              </div>
                            </div>
                          </a>
                      </li>
                      <li>
                        <a class="item-link smart-select smart-select-init" name="Categories" data-open-in="popup" data-searchbar="true" data-virtual-list="true" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFilterFieldChange">
                            <select multiple>
                              <option value="">Chọn</option>
                              {{#each categoryList}}
                              <option value="{{id}}">{{text}}</option>
                              {{/each}}
                            </select>
                            <div class="item-content">
                              <div class="item-inner">
                                <div class="item-title">Danh mục</div>
                              </div>
                            </div>
                          </a>
                      </li>
                      <li>
                        <a class="item-link smart-select smart-select-init" name="Groups" data-open-in="popup" data-searchbar="true" data-virtual-list="true" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFilterFieldChange">
                            <select multiple>
                              <option value="">Chọn</option>
                              {{#each groupList}}
                              <option value="{{id}}">{{text}}</option>
                              {{/each}}
                            </select>
                            <div class="item-content">
                              <div class="item-inner">
                                <div class="item-title">Nhóm</div>
                              </div>
                            </div>
                          </a>
                      </li>
                    </ul>
                  </div>
                </div>
            
                <div class="block-title">Hàng hóa/dịch vụ CTV
                  <a class="button button-small button-fill tab-link" @click="exportCommisstionReport" style="float: right; font-size: 0.7rem; font-weight: bold">+ Xuất chiết khấu</a>
                </div>
                <div class="virtual-list ${$this.namespace}-virtual-list thumbnail-list"></div>


                {{#if infiniteLoadLast}}
                <div style="text-align: center" class="text-color-gray">end</div>
                {{else}}
                <div class="preloader color-blue infinite-scroll-preloader">
                    <span class="preloader-inner">
                        <span class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                            class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                            class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                            class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                            class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                            class="preloader-inner-line"></span><span class="preloader-inner-line"></span>
                    </span>
                </div>
                {{/if}}
            
                <div class="block"><br></div>
            
              </div>


              <!-- Dialog area -->
              <div class="dialog dialog-wrap ${$this.namespace}-price-dialog">
                <div class="dialog-padding">
                  <div class="dialog dialog-inside">
                    <div class="dialog-inner" style="padding-bottom: 0;">
                        <div class="dialog-title">{{priceDialogData.title}}</div>
                        <div class="dialog-text" style="width: 100%; user-select: initial; text-align: center; margin-bottom: 0.5rem">{{priceDialogData.content}}</div>

                        <div class="list inline-labels" style="margin-left: -15px; margin-right: -15px; margin-bottom: 0; margin-top: 0; text-align: left;">
                            <ul>
                                {{#js_if "this.priceDialogData && this.priceDialogData.product && this.priceDialogData.product.UnitList"}}
                                {{#each priceDialogData.product.UnitList}}
                                <li class="item-content item-input">
                                    <div class="item-inner">
                                        <div class="item-title item-label" style="overflow: initial;">{{text}}</div>
                                        <div class="item-input-wrap">
                                          <input class="field price text-color-red" name="{{id}}" type="text" placeholder="Giá bán" value="{{Price}}" style="text-align: right">
                                        </div>
                                    </div>
                                </li>
                                {{/each}}
                                {{/js_if}}
                            </ul>
                        </div>
                    </div>
                    <div class="dialog-buttons">
                        <span class="dialog-button color-red closeBtn">Đóng</span>
                        <span class="dialog-button updateBtn">Xác nhận</span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End Dialog area -->
            </div>
        `,
        methods: {
          ...superComponent.component['methods'],
          async refresh(e, done) {
            const self: F7ComponentContextExtend = this;
            self.$setState({
              chooseList: [],
            });
            setTimeout(() => {
              $this.refresh(this).then(rs => {
                done && done();
              });
            }, 0);

            return true;
          },
          exportCommisstionReport(e) {
            const self: F7ComponentContextExtend = this;
            if (Object.keys(self.chooseList).length == 0) {
              $this.commonService.showError('Bạn chưa chọn sản phẩm !');
              return;
            }
            $this.rootServices.iab.create($this.rootServices.apiService.buildApiUrl('/collaborator/page-products', {
              type: 'pdf',
              report: 'ExportProductCommission',
              eq_Product: '[' + Object.keys(self.chooseList).join(',') + ']',
              includeCategories: true,
              includeGroups: true,
              includeProduct: true,
              includeUnit: true,
              includeUnitPrices: true,
              includeCommissionRatio: true,
              productOfPage: true,
              sort_Id: 'desc',
              page: $this.currentState?.instance?.$route?.context && $this.currentState?.instance?.$route?.context['page'] || $this.commonService.getObjectId($this.defaultPage),
              ...$this.parseFilter($this.currentState?.filter),
            }) as string, '_system');
          }
        }
      }
    };
  }
}
