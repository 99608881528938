import { ProductModel } from 'vendors/smart-bot-app/src/app/model/product.model';
import { F7ComponentContext } from '../types/framework7-types';
import { Router } from 'framework7/build/core/modules/router/router';
import { take } from 'rxjs/operators';
import { BaseComponent, ComponentState } from 'vendors/smart-bot-app/src/app/lib/base-component';
import { ChatRoom } from 'vendors/smart-bot-app/src/app/lib/nam-chat/chat-room';
import { Member } from 'vendors/smart-bot-app/src/app/lib/nam-chat/model/member';
import { CommonService } from 'vendors/smart-bot-app/src/app/services/common.service';
import { RootServices } from 'vendors/smart-bot-app/src/app/services/root.services';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { AccountModel } from 'vendors/smart-bot-app/src/app/model/account.model';
import { CollaboratorOrderModel } from '../model/collaborator.model';
import * as $ from 'jquery';
import { environment } from 'src/environments/environment';

declare var QRCode;

// declare const $: any;
export interface ComponentStateExtend extends ComponentState {
  [key: string]: any;
}

export class F7ComponentContextExtend extends F7ComponentContext {
  // messagebar?: F7Messagebar;
  // messages?: Messages.Messages;
  // images?: string[];
  responseInProgress?: boolean;
  // answers?: string[];
  // people?: { name?: string, avatar?: string }[];
  // hideToolbar?: () => void;
  // sheetToggle?: () => void;
  // deleteAttachment?: (e: MouseEvent, index: number) => void;
  // handleAttachment?: (e: MouseEvent) => void;
  // checkAttachments?: () => void;
  // sendMessage?: () => void;
  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, memberInfo?: Member, chatRoom?: ChatRoom, type?: string } };
}

/** Component group manager */
export class BioPageComponent extends BaseComponent<ComponentStateExtend> {
  // states: { [key: string]: State } = {};

  title: 'BIO'

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
    public datePipe: DatePipe,
    public currencyPipe: CurrencyPipe,
  ) {
    super(rootServices);
    // console.log('Click here to open ts file');
  }

  get isPublicComponent() {
    return true;
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: 'bio-page',
      path: '/bio-page/:id',
      component: {
        template: /*html*/`
            <div class="page bio-page no-toolbar no-navbar" data-name="bio-page">
              <div class="navbar bio-page navbar-transparent">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a class="link back {{textColorClass}}">
                      <i class="icon icon-back"></i>
                      <span class="if-not-md {{textColorClass}}">{{backTitle}}</span>
                    </a>
                  </div>
                  <div class="title">{{title}}</div>
                  <div class="right">
                    <a class="link icon-only">
                      <i class="icon f7-icons">search</i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="page-content ptr-content infinite-scroll-content" @ptr:refresh="refresh">

                <div class="ptr-preloader">
                  <div class="preloader"></div>
                  <div class="ptr-arrow"></div>
                </div>

                {{#js_if "!this.bio || !this.bio.Code"}}
                <div class="block block-strong inset" style="padding: 0; overflow: hidden">
                  <img src="assets/images/bulldozer.png" style="width: 100%;">
                </div>
                <div class="block block-strong inset">
                  Trang BIO đang cập nhật thông tin !
                </div>
                {{else}}
            
                <div class="bio-info">
                  <div class="bio-banner bg-color-gray" @click="browsePictures" data-img-index="0"
                    style="background-image: url({{js "this.bio && this.bio.Banner && this.bio.Banner.SmallImage"}})">
                    {{#if itself}}
                    <div @click="uploadBanner" class="upload-banner-btn" style="right: 20px; bottom: 20px;">
                      <i class="icon f7-icons upload-banner-icon">camera_fill</i>
                    </div>
                    {{/if}}
                  </div>
                  <div class="bio-logo-border">
                    <div class="bio-logo bg-color-gray" @click="browsePictures" data-img-index="1"
                      style="background-image: url({{js "this.bio && this.bio.Avatar && this.bio.Avatar.SmallImage"}})">
                    </div>
                    {{#if itself}}
                    <div @click="uploadAvatar" class="upload-banner-btn" style="right: 3vw; bottom: 6vw;">
                      <i class="icon f7-icons upload-banner-icon">camera_fill</i>
                    </div>
                    {{/if}}
                  </div>
                </div>

                <div class="block inset" style="padding: 0; margin-top: 0; margin-bottom: 0;">
                  
                  <div class="bio-title text-color-defaultx">
                    {{js "this.bio && this.bio.Name || ''"}}</div>
                  <div class="bio-subtitle text-color-gray" style="font-size: 12px;">{{js "this.bio && this.bio.Tag && ('@' + this.bio.Tag) || ''"}}</div>
                  <div class="bio-link">
                    <a @click="copyText" data-text="https://probox.vn/{{js "this.bio && this.bio.Tag || ''"}}"><i style="font-size: 14px" class="icon f7-icons">square_on_square</i> https://probox.vn/{{js "this.bio && this.bio.Tag || ''"}}</a>
                  </div>

                </div>
                
                {{#js_if "this.bio && this.bio.Phone"}}
                <div class="block inset" style="padding: 0; display: flex">
                  <button class="button button-large button-fill color-blue" style="flex: 1; margin-right: 8px; font-size: 16px" data-href="tel:{{js "this.bio && this.bio.Phone"}}" @click="openLink">
                    <i style="font-size: 20px" class="icon f7-icons">phone_fill</i> Gọi ngay {{js "this.bio && this.bio.Phone"}}
                  </button>
                  <button @click="copyText" data-text="{{bio.Phone}}" class="button button-large button-fill color-gray" style="width: 50px">
                    <i class="icon f7-icons">square_fill_on_square_fill</i>
                  </button>
                </div>
                {{/js_if}}
                
                <div class="block-header">Liên Kết Chính Thức</div>
                <div class="list media-list inset">
                  <ul>
                    {{#js_if "this.bio.FbLink"}}
                    <li>
                      <a @click="openLink" data-href="https://fb.com/{{bio.FbLink}}" class="item-content item-link">
                        <div class="item-media">
                          <img src="assets/icon/facebook.png" width="48">  
                        </div>
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title" style="color: #1877f2">Facebook</div>
                          </div>
                          <div class="item-subtitle" style="color: #4395ff;">fb.com/{{bio.FbLink}}</div>
                        </div>
                      </a>
                    </li>
                    {{/js_if}}
                    {{#js_if "this.bio.TiktokLink"}}
                    <li>
                      <a @click="openLink" data-href="https://tiktok.com/@{{bio.TiktokLink}}" class="item-content item-link">
                        <div class="item-media">
                          <img src="assets/icon/tiktok.png" width="48">
                        </div>
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title" style="color: #700d27">Tiktok</div>
                          </div>
                          <div class="item-subtitle" style="color: #a63150;">tiktok.com/@{{bio.TiktokLink}}</div>
                        </div>
                      </a>
                    </li>
                    {{/js_if}}
                    {{#js_if "this.bio.WebLink"}}
                    <li>
                      <a @click="openLink" data-href="{{bio.WebLink}}" class="item-content item-link">
                        <div class="item-media">
                          <img src="assets/icon/www.png" width="48">
                        </div>
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title" style="color: var(--f7-block-strong-text-color)">Blog</div>
                          </div>
                          <div class="item-subtitle" style="color: var(--f7-label-text-color);">{{bio.WebLink}}</div>
                        </div>
                      </a>
                    </li>
                    {{/js_if}}
                    {{#js_if "this.bio.YoutubeLink"}}
                    <li>
                      <a @click="openLink" data-href="https://youtube.com/@{{bio.YoutubeLink}}" class="item-content item-link">
                        <div class="item-media">
                          <img src="assets/icon/youtube.png" width="48">
                        </div>
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title" style="color: #fe0a00">Youtube</div>
                          </div>
                          <div class="item-subtitle" style="color: #f93e38;">youtube.com/@{{bio.YoutubeLink}}</div>
                        </div>
                      </a>
                    </li>
                    {{/js_if}}
                    {{#js_if "this.bio.XLink"}}
                    <li>
                      <a @click="openLink" data-href="https://x.com/{{bio.XLink}}" class="item-content item-link">
                        <div class="item-media">
                          <img src="assets/icon/x.png" width="48">
                        </div>
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title" style="color: var(--f7-block-strong-text-color)">X</div>
                          </div>
                          <div class="item-subtitle" style="color: var(--f7-label-text-color)">x.com/{{bio.XLink}}</div>
                        </div>
                      </a>
                    </li>
                    {{/js_if}}
                    {{#js_if "this.bio.ZaloLink"}}
                    <li>
                      <a @click="openLink" data-href="https://zalo.me/{{bio.ZaloLink}}" class="item-content item-link">
                        <div class="item-media">
                          <img src="assets/icon/zalo.png" width="48">
                        </div>
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title" style="color: #0768ff;">Zalo</div>
                          </div>
                          <div class="item-subtitle" style="color: #3f88fa;">zalo.me/{{bio.ZaloLink}}</div>
                        </div>
                      </a>
                    </li>
                    {{/js_if}}
                    {{#js_if "this.bio.TelegramLink"}}
                    <li>
                      <a @click="openLink" data-href="https://t.me/{{bio.TelegramLink}}" class="item-content item-link">
                        <div class="item-media">
                          <img src="assets/icon/telegram.png" width="48">
                        </div>
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title" style="color: #23a1de;">Telegram</div>
                          </div>
                          <div class="item-subtitle" style="color: #07aeff;">t.me/{{bio.TelegramLink}}</div>
                        </div>
                      </a>
                    </li>
                    {{/js_if}}
                    {{#js_if "this.bio.Email"}}
                    <li>
                      <a @click="openLink" data-href="mailto:{{bio.Email}}" class="item-content item-link">
                        <div class="item-media">
                          <img src="assets/icon/email-icon.png" width="48">
                        </div>
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title" style="color: #f44335">Email</div>
                          </div>
                          <div class="item-subtitle" style="color: #ff6f63;">{{bio.Email}}</div>
                        </div>
                      </a>
                    </li>
                    {{/js_if}}
                  </ul>
                </div>

                <div class="block block-strong inset" style="background-color: #fff !important;">
                  <div class="bio-qr-code"></div>
                </div>


                <div class="list inset bio-form no-hairlines-md">
                  <form autocomplete="off">
                    <ul>
                      <li class="item-content item-input" style="padding-left: 0">
                        <a class="col button button-fill button-large link" @click="downloadContact" style="width: 100%">Tải contact</a>
                      </li>
                    </ul>
                  </form>
                </div>


                <div class="block-header">Hình ảnh</div>
                <div class="block inset" style="display: flex; flex-wrap: wrap; padding: 0">
                  {{#js_if "this.bio && this.bio.Image1"}}
                  <div style="flex: 50%;">
                    <div class="image" @click="browsePictures" data-img-index="2" style="background-image: url({{js "this.bio && this.bio.Image1 && this.bio.Image1.SmallImage"}})"></div>
                  </div>
                  {{/js_if}}
                  {{#js_if "this.bio && this.bio.Image2"}}
                  <div style="flex: 50%;">
                    <div class="image" @click="browsePictures" data-img-index="3" style="background-image: url({{js "this.bio && this.bio.Image2 && this.bio.Image2.SmallImage"}})"></div>
                  </div>
                  {{/js_if}}
                  {{#js_if "this.bio && this.bio.Image3"}}
                  <div style="flex: 50%;">
                    <div class="image" @click="browsePictures" data-img-index="4" style="background-image: url({{js "this.bio && this.bio.Image3 && this.bio.Image3.SmallImage"}})"></div>
                  </div>
                  {{/js_if}}
                  {{#js_if "this.bio && this.bio.Image4"}}
                  <div style="flex: 50%;">
                    <div class="image" @click="browsePictures" data-img-index="5" style="background-image: url({{js "this.bio && this.bio.Image4 && this.bio.Image4.SmallImage"}})"></div>
                  </div>
                  {{/js_if}}
                </div>

                <div class="block-header">Nhớ về tôi</div>
                <div class="block block-strong inset">
                  {{#js_if "this.bio.Keyword1"}}<span style="font-size: 1rem; font-weight: bold"><i class="icon f7-icons text-color-default" style="font-size: 1rem;">tag_fill</i> {{bio.Keyword1}}</span>{{/js_if}}
                  {{#js_if "this.bio.Keyword1"}}<span style="font-size: 1rem; font-weight: bold"><i class="icon f7-icons text-color-default" style="font-size: 1rem;">tag_fill</i> {{bio.Keyword2}}</span>{{/js_if}}
                </div>

                {{#js_if "this.bio.SelfIntroduction"}}
                <div class="block-header">Tự giới thiệu</div>
                <div class="block block-strong inset">
                  {{js "this.bio.SelfIntroduction && this.bio.SelfIntroduction || ''"}}
                </div>
                {{/js_if}}

                {{#js_if "this.bio.SkillIntroduction"}}
                <div class="block-header">Kỹ năng</div>
                <div class="block block-strong inset">
                  {{bio.SkillIntroduction}}
                </div>
                {{/js_if}}

                <div class="block inset" style="padding: 0">
                  <button class="button button-large button-fill color-blue" style="flex: 1; margin-right: 8px; font-size: 16px" data-text="{{js "this.bio && this.bio.User"}}" @click="copyText">
                  <i style="font-size: 20px" class="icon f7-icons">link_circle_fill</i> Mã ref user {{js "this.bio && this.bio.User"}}
                  </button>
                </div>
                
                {{#js_if "this.bio.FbLink1 || this.bio.TiktokLink1 || this.bio.WebLink1 || this.bio.YoutubeLink1 || this.bio.XLink1 || this.bio.ZaloLink1 || this.bio.TelegramLink1"}}
                <div class="block-header">Liên kết dự phòng</div>
                <div class="list media-list inset">
                  <ul>
                    {{#js_if "this.bio.FbLink1"}}
                    <li>
                      <a @click="openLink" data-href="https://fb.com/{{bio.FbLink1}}" class="item-content item-link">
                        <div class="item-media">
                          <img src="assets/icon/facebook.png" width="48">  
                        </div>
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title" style="color: #1877f2">Facebook</div>
                          </div>
                          <div class="item-subtitle" style="color: #4395ff;">fb.com/{{bio.FbLink1}}</div>
                        </div>
                      </a>
                    </li>
                    {{/js_if}}
                    {{#js_if "this.bio.TiktokLink1"}}
                    <li>
                      <a @click="openLink" data-href="https://tiktok.com/@{{bio.TiktokLink1}}" class="item-content item-link">
                        <div class="item-media">
                          <img src="assets/icon/tiktok.png" width="48">
                        </div>
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title" style="color: #700d27">Tiktok</div>
                          </div>
                          <div class="item-subtitle" style="color: #a63150;">tiktok.com/@{{bio.TiktokLink1}}</div>
                        </div>
                      </a>
                    </li>
                    {{/js_if}}
                    {{#js_if "this.bio.WebLink1"}}
                    <li>
                      <a @click="openLink" data-href="{{bio.WebLink1}}" class="item-content item-link">
                        <div class="item-media">
                          <img src="assets/icon/www.png" width="48">
                        </div>
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title" style="color: var(--f7-block-strong-text-color)">Blog</div>
                          </div>
                          <div class="item-subtitle" style="color: var(--f7-label-text-color);">{{bio.WebLink1}}</div>
                        </div>
                      </a>
                    </li>
                    {{/js_if}}
                    {{#js_if "this.bio.YoutubeLink1"}}
                    <li>
                      <a @click="openLink" data-href="https://youtube.com/@{{bio.YoutubeLink1}}" class="item-content item-link">
                        <div class="item-media">
                          <img src="assets/icon/youtube.png" width="48">
                        </div>
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title" style="color: #fe0a00">Youtube</div>
                          </div>
                          <div class="item-subtitle" style="color: #f93e38;">youtube.com/@{{bio.YoutubeLink1}}</div>
                        </div>
                      </a>
                    </li>
                    {{/js_if}}
                    {{#js_if "this.bio.XLink1"}}
                    <li>
                      <a @click="openLink" data-href="https://x.com/{{bio.XLink1}}" class="item-content item-link">
                        <div class="item-media">
                          <img src="assets/icon/x.png" width="48">
                        </div>
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title" style="color: var(--f7-block-strong-text-color)">X</div>
                          </div>
                          <div class="item-subtitle" style="color: var(--f7-label-text-color)">x.com/{{bio.XLink1}}</div>
                        </div>
                      </a>
                    </li>
                    {{/js_if}}
                    {{#js_if "this.bio.ZaloLink1"}}
                    <li>
                      <a @click="openLink" data-href="https://zalo.me/{{bio.ZaloLink1}}" class="item-content item-link">
                        <div class="item-media">
                          <img src="assets/icon/zalo.png" width="48">
                        </div>
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title" style="color: #0768ff;">Zalo</div>
                          </div>
                          <div class="item-subtitle" style="color: #3f88fa;">zalo.me/{{bio.ZaloLink1}}</div>
                        </div>
                      </a>
                    </li>
                    {{/js_if}}
                    {{#js_if "this.bio.TelegramLink1"}}
                    <li>
                      <a @click="openLink" data-href="https://t.me/{{bio.TelegramLink1}}" class="item-content item-link">
                        <div class="item-media">
                          <img src="assets/icon/telegram.png" width="48">
                        </div>
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title" style="color: #23a1de;">Telegram</div>
                          </div>
                          <div class="item-subtitle" style="color: #07aeff;">t.me/{{bio.TelegramLink1}}</div>
                        </div>
                      </a>
                    </li>
                    {{/js_if}}
                    {{#js_if "this.bio.TelegramLink1"}}
                    <li>
                      <a @click="openLink" data-href="https://t.me/{{bio.Email1}}" class="item-content item-link">
                        <div class="item-media">
                          <img src="assets/icon/telegram.png" width="48">
                        </div>
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title" style="color: #f44335;">Email</div>
                          </div>
                          <div class="item-subtitle" style="color: #ff6f63;">t.me/{{bio.Email1}}</div>
                        </div>
                      </a>
                    </li>
                    {{/js_if}}
                  </ul>
                </div>
                {{/js_if}}
            
              </div>
              {{/js_if}}
            </div>
        `,
        style: /*css*/`
          .post-content {
            padding: 1rem;
          }
          .post-content img {
            border-radius: 5px;
            margin-top: 5px;
            margin-bottom: 5px;
          }
          .page.bio-page .page-content {
            
          }
          /*.navbar.bio-page::after {
            position: absolute;
            content: "";
            left: 0px;
            top: 0px;
            height: 100%;
            width: 100%;
            background: linear-gradient(var(--f7-page-bg-color), transparent);
            pointer-events: none;
          }*/
          .page.bio-page .bio-info {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            margin-top: 0.5rem;
          }
          .page.bio-page .bio-logo-border {
            height: 160px;
            width: 160px;
            border-radius: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            margin-left: 1rem;
            margin-top: -116px;
            padding: 5px;
            background-color: var(--f7-page-bg-color);
            position: relative;
          }
          .page.bio-page .bio-logo {
            height: 150px;
            width: 150px;
            border-radius: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            margin: -1px auto;
            position: relative;
          }
          .page.bio-page .bio-banner {
            height: 200px;
            width: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            margin: 0 auto;
            position: relative;
            border-top-left-radius: 0.8rem;
            border-top-right-radius: 0.8rem;
          }
          .page.bio-page .upload-banner-btn {
            position: absolute;
            border-radius: 50%;
            background: #fff;
            border: 1.5px solid #000;
            padding: 2px;
            width: 28px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
          .page.bio-page .upload-banner-btn .upload-banner-icon {
            font-size: 14px;
            color: black;
          }
          .page.bio-page .bio-title {
            text-align: left;
            font-weight: bold;
            // margin-left: 1.5rem;
            font-size: 1.5rem;
          }
          .page.bio-page .bio-subtitle {
            text-align: left;
            // margin-left: 1.5rem;
            font-size: 1rem;
          }
          .page.bio-page .bio-link {
            text-align: left; 
            // margin-left: 1.5rem;
            font-size: 1rem;
          }
          .page.bio-page .bio-form li .item-media i {
            /* font-size: 44px;*/
          }

          .page.bio-page .image {
            border-radius: var(--f7-list-inset-border-radius);
            margin: 5px; 
            height: 150px; 
            position: relative; 
            background-repeat: no-repeat; 
            overflow: hidden; 
            background-size: cover; 
            background-position: center;
            background-color: var(--f7-block-strong-bg-color);
          }

          .page.bio-page .block,
          .page.bio-page .list {
            margin-left: 1.5rem;
            margin-right: 1.5rem;
          }
            
          .page.bio-page .block-header {
            margin-left: 0.5rem;
          }

          .bio-qr-code img {
            width: 100%;
          }

          .page.bio-page .card.product .card-content * {
            max-width: 100%;
          }
          .page.bio-page .card.product .card-content figure {
              width: initial !important;
          }
          .page.bio-page .list.inset {
            margin-left: calc(var(--f7-card-margin-horizontal) + var(--f7-safe-area-left)); 
            margin-right: calc(var(--f7-card-margin-horizontal) + var(--f7-safe-area-right));
            margin-left: 1.5rem; 
            margin-right: 1.5rem;
            color: var(--f7-color-gray-shade);
          }
        `,
        data() {
          return {
            title: $this.title,
            editable: false,
            bio: {},
            isAppPlatform: $this.rootServices.isAppPlatform,
            isLoggedIn: $this.rootServices.authService.getUser()?.id && true || false,
            itself: false
          };
        },
        methods: {
          downloadContact(e) {
            const self: F7ComponentContextExtend = this;
            $this.rootServices.openExtenalLink($this.rootServices.apiService.buildApiUrl('/bio/pages/' + self.bio.Code, { downloadVCard: true }));
          },
          browsePictures(e) {
            const self: F7ComponentContextExtend = this;
            const index = $(e.target).data('img-index');
            $this.rootServices.previewPictures(self.bio.Images, index, { addToStackButton: true, imageSize: 'LargeImage' });
          },
          callnow(e) {
            const self: F7ComponentContextExtend = this;
            const phone = $(e.target).data('text');
            $this.rootServices.iab
          },
          editLinksBlock(e) {
            const self: F7ComponentContextExtend = this;
            self.$setState({ linksBlockEditMode: true });
          },
          saveLinksBlock(e) {
            const self: F7ComponentContextExtend = this;
            self.$setState({ linksBlockEditMode: false });
            const linksBlockForm = $(e.target).closest('.link-block-form');
            const fieldEles = linksBlockForm.find('input[type="text"]');
            const links = {};
            fieldEles.each((index, fieldEle) => {
              console.log(fieldEle);
              console.log(fieldEle['value']);
              links[fieldEle['name']] = fieldEle['value'];
            });

            if ($this.rootServices.authService.getUser()?.id == self.bio.Code) {
              $this.rootServices.apiService.putPromise(`/user/bio-blocks/${self.bio.Code}-4`, {}, [{
                User: self.bio.Code,
                No: 4,
                Type: 'LINKS',
                Data: links,
              }]).then(rs => {
                $this.commonService.showInfo('Cập nhật links block thành công');
                self.refresh();
              });
            } else {
              console.error('không thể cập nhật links block cho user khác');
            }
          },
          gotoProducct(e) {
            const self: F7ComponentContextExtend = this;
            const productCode = $(e.target).data('id')?.replace(/_\w+/, '')?.toLowerCase();
            const pageCode = $(e.target).data('page')?.toLowerCase();
            // const product = self.bio?.products?.find(f => f.Code == productCode);
            $this.rootServices.navigate(`/${pageCode}/ctvbanhang/product/${productCode}`, { context: { backTitle: 'Profile', publisher: self.$route.params?.id } });
          },
          gotoAccount() {
            const self: F7ComponentContextExtend = this;
            if (self.itself) {
              $this.rootServices.navigate('/account', { context: { backTitle: 'Profile' } });
            }
          },
          uploadBanner(e) {
            const self: F7ComponentContextExtend = this;
            if ($this.rootServices.authService.getUser()?.id == self.bio.Code) {
              $this.rootServices.pickAndUploadFile().then(file => {
                console.log(file);
                $this.rootServices.apiService.putPromise('/user/bios/' + self.bio.Code, {}, [{
                  Code: self.bio.Code,
                  Banner: file,
                }]).then(rs => {
                  $this.commonService.showInfo('Cập nhật banner thành công');
                  self.refresh();
                });
              });
            } else {
              console.error('không thể cập nhật banner cho user khác');
            }
          },
          uploadAvatar(e) {
            const self: F7ComponentContextExtend = this;
            if ($this.rootServices.authService.getUser()?.id == self.bio.Code) {
              $this.rootServices.pickAndUploadFile().then(file => {
                console.log(file);
                $this.rootServices.apiService.putPromise('/user/bios/' + self.bio.Code, {}, [{
                  Code: self.bio.Code,
                  Avatar: file,
                }]).then(rs => {
                  $this.commonService.showInfo('Cập nhật banner thành công');
                  self.refresh();
                  const oldUser = $this.rootServices.authService.user$.getValue();
                  oldUser.avatar = oldUser.avatar || { id: '', payload: {} };
                  oldUser.avatar = { ...oldUser.avatar, payload: { ...oldUser.avatar.payload, thumbnail: file.Thumbnail, url: file.SmallImage } }
                  $this.rootServices.authService.user$.next(oldUser);
                });
              });
            } else {
              console.error('không thể cập nhật banner cho user khác');
            }
          },
          copyText(e) {
            const self: F7ComponentContextExtend = this;
            const text = $(e.currentTarget).data('text');
            console.log(text);
            $this.rootServices.copyTextToClipboard(text);
          },
          orderProduct(e) {
            const self: F7ComponentContextExtend = this;
            const productListName = $(e.target).data('list');
            const cardEle = $(e.target).closest('.card');
            const nameEle = cardEle.find('input[name="CustomerName"]');
            const phoneEle = cardEle.find('input[name="CustomerPhone"]');
            const addressEle = cardEle.find('input[name="CustomerAddress"]');

            const productCode = $(e.target).data('product');
            let product = null;
            if (productListName === 'subscription') {
              product = self.bio?.subscriptionProducts?.find(f => f.Code === productCode);
            } else {
              product = self.bio?.Blocks?.find(f => f.Type === 'BUSINESSPRODUCT')?.Data?.find(f => f.Code === productCode);
            }

            if (!product) {
              $this.commonService.showError('Sản phẩm không tồn tại');
              return;
            }
            if (!nameEle.val()) {
              $this.commonService.showError('Quý khách chưa điền tên !');
              return;
            }
            if (!phoneEle.val()) {
              $this.commonService.showError('Quý khách chưa điền số điện thoại !');
              return;
            }
            if (!addressEle.val()) {
              $this.commonService.showError('Quý khách chưa điền địa chỉ nhận hàng !');
              return;
            }

            $this.commonService.showPreloader();
            $this.rootServices.apiService.postPromise<CollaboratorOrderModel[]>('/collaborator/orders', { skipAuth: true, publisher: self.$route.params?.id }, [
              {
                Page: product.Page,
                ObjectName: nameEle.val() as string,
                ObjectPhone: phoneEle.val() as string,
                ObjectAddress: addressEle.val() as string,
                Details: [
                  {
                    Product: product.Code,
                    Description: product.Name,
                    Unit: product.Unit,
                    Price: product.Price,
                    Quantity: 1,
                    Image: product?.FeaturePicture ? [product?.FeaturePicture] : [],
                    Token: product.Token,
                  }
                ]
              }
            ]).then(rs => rs[0]).then(newOrder => {
              $this.commonService.hidePreloader();
              $this.commonService.showInfo(`Cám ơn bạn đã ủng hộ sản phẩm: ${product.Name}<br>Mã đặt hàng của bạn là: ${newOrder.Code}<br>Chúc bạn một ngày may mắn và vui vẻ ❤️`, { timeout: 120000 });
              nameEle.val('');
              phoneEle.val('');
              addressEle.val('');
            }).catch(err => {
              $this.commonService.showError(err);
              $this.commonService.hidePreloader();
            });
          },
          openLink(e) {
            const self: F7ComponentContextExtend = this;
            const link = $(e.target).closest('a,button').data('href');
            $this.rootServices.iab.create(link, '_system');
          },
          editProductBlock(e) {
            const self: F7ComponentContextExtend = this;
            const blockNo = $(e.target).data('no');
            $this.rootServices.navigate(`/product-block/${self.$route.params?.id}`, {
              context: {
                productList: self.bio?.products,
                onSave: (productList: any[]) => {
                  $this.rootServices.apiService.putPromise(`/user/bio-blocks/${self.$route.params.id}-${blockNo}`, {}, [{
                    User: self.$route.params.id,
                    No: blockNo,
                    Type: 'BUSINESSPRODUCT',
                    Data: productList.map(p => p.Code),
                  }]).then(rs => self.refresh());
                }
              }
            });
          },
          editContent(e) {
            const self: F7ComponentContextExtend = this;
            const blockNo = $(e.target).data('no');
            const blockType = $(e.target).data('type');
            const postContent = $(e.target).closest('.card').find('.post-content');
            $this.rootServices.navigate('/editor/' + $this.rootServices.authService?.getUser()?.id, {
              context: {
                postContent: postContent.html(),
                onSave: (content: any) => {
                  console.log(content);
                  postContent.html(content);
                  $this.rootServices.apiService.putPromise(`/user/bio-blocks/${self.$route.params.id}-${blockNo}`, {}, [{
                    User: self.$route.params.id,
                    No: blockNo,
                    Type: blockType,
                    Content: content,
                  }]).then(rs => self.refresh());
                }
              }
            });
          },
          async refresh(e, done) {
            const self: F7ComponentContextExtend = this;
            const currentState = $this.state[self.$route.params?.id];

            let doneTimeout = null;
            if (done) {
              doneTimeout = setTimeout(() => {
                done();
              }, 10000);
            };
            const params = { skipAuth: true, includeBlocks: true };
            if (self.$route.params['id'] && self.$route.params['id'] != 'default') {
              params['eq_Tag'] = self.$route.params['id'];
            } else {
              if ($this.rootServices.authService.getUser()?.id) {
                params['eq_User'] = $this.rootServices.authService.getUser()?.id;
              }
            }

            await $this.rootServices.apiService.getPromise<AccountModel>('/bio/pages', params).then(rs => rs[0]).then(async bio => {
              console.log(bio);

              // Set title
              $this.rootServices.titleService.setTitle(bio.Name + (bio.Aliases ? (' - ' + bio.Aliases) : ''));

              if (bio) {
                if (bio.WebLink) {
                  if (!/^http/.test(bio.WebLink)) {
                    bio.WebLink = `https://${bio.WebLink}`;
                  }
                }
                if (bio.WebLink1) {
                  if (!/^http/.test(bio.WebLink1)) {
                    bio.WebLink = `https://${bio.WebLink1}`;
                  }
                }
              }

              bio.SelfIntroduction = (bio.SelfIntroduction || '').replace(/\n/g, '<br>');
              bio.SkillIntroduction = (bio.SkillIntroduction || '').replace(/\n/g, '<br>');
              bio.Images = [
                bio.Banner,
                bio.Avatar,
                bio.Image1,
                bio.Image2,
                bio.Image3,
                bio.Image4,
              ];


              let coreDomain = $this.rootServices.apiService.token && $this.rootServices.apiService.token.api_url;
              if (coreDomain) {
                const url = new URL(coreDomain);
                coreDomain = url.host;
              }
              const bioInfo = bio.Blocks?.find(f => f.Type == 'PROFILEINFO');
              const csrInfo = bio.Blocks?.find(f => f.Type == 'CSR');
              const bioProducts = bio?.Blocks?.find(f => f.Type == 'BUSINESSPRODUCT')?.Data?.map(product => {
                if (product?.Units) {
                  product.Unit = product.Unit || (product.Units && product.Units[0] && product.Units[0]['Unit'] || '');
                  const unit = product.Units.find(f => f.id == product.Unit);
                  if (unit) {
                    product.Price = unit.Price;
                    product.Unit = unit.id;
                    product.UnitName = unit.text;
                    product.PriceText = $this.rootServices.currencyPipe.transform(product.Price, 'VND');
                  }
                }
                return {
                  ...product,
                  // PriceText: $this.currencyPipe.transform(product.Price || 0, 'VND'),
                  FeaturePicture: product.FeaturePicture && product.FeaturePicture.OriginImage ? product.FeaturePicture : {
                    ThumbnalImage: 'assets/images/no-image-available.png',
                    SmallImage: 'assets/images/no-image-available.png',
                    OriginImage: 'assets/images/no-image-available.png',
                  }
                };
              });
              const bioLinks = bio?.Blocks?.find(f => f.Type == 'LINKS')?.Data;

              // const subscriptionProducts = await $this.rootServices.apiService.getPromise<ProductModel[]>('/collaborator/product-subscriptions', {
              //   publisher: self.$route.params?.id,
              //   includeCategories: true,
              //   includeGroups: true,
              //   includeUnitPrices: true,
              //   sort_Id: 'desc',
              //   limit: 'nolimit',
              //   skipAuth: true,
              // }).then(rs => {
              //   return rs.map(product => {

              //     if (product?.Units) {
              //       product.Unit = product.Unit || (product.Units && product.Units[0] && product.Units[0]['Unit'] || '') as any;
              //       const unit = product.Units.find(f => f.id == product.Unit);
              //       if (unit) {
              //         product.Price = unit.Price;
              //         product.Unit = unit.id;
              //         product.UnitName = unit.text;
              //         product.PriceText = $this.rootServices.currencyPipe.transform(product.Price, 'VND');
              //       }
              //     }

              //     product.PriceText = $this.currencyPipe.transform(product.Price || 0, 'VND');
              //     product.FeaturePicture = product.FeaturePicture && product.FeaturePicture.OriginImage ? product.FeaturePicture : {
              //       ThumbnalImage: 'assets/images/no-image-available.png',
              //       SmallImage: 'assets/images/no-image-available.png',
              //       OriginImage: 'assets/images/no-image-available.png',
              //     } as any;
              //     return product;
              //   });
              // });

              self.$setState({
                bio: {
                  ...bio,
                  Core: coreDomain,
                  info: bioInfo,
                  products: bioProducts,
                  // subscriptionProducts: subscriptionProducts,
                  links: bioLinks,
                  csrInfo: csrInfo,
                  tageName: bio.TagName,
                },
                itself: bio.Code == $this.rootServices?.authService?.getUser()?.id,
                title: bio.Name,
                backTitle: self.backTitle || 'Back'
                // backTitle: userInfo.Name,
              });

              // Load qr code
              const baseLink = environment.universal.link;
              setTimeout(() => {
                self.$('.bio-qr-code').html('');
                new QRCode(self.$('.bio-qr-code')[0], {
                  text: baseLink + '/' + bio.Tag,
                });
              }, 300);

              currentState.loaded$ && currentState.loaded$.next(true);
            });
            done && done();
            return true;
          },
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          pageInit(e, page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;

            $this.onComponentInit({ instance: self }, self.$route.params?.id).then(currentState => {

              // console.log(memberInfo);
              $this.rootServices.authService.isAuthenticatedOrRefresh().pipe(take(1)).toPromise().then(() => {
                self.refresh();
              }).catch(err => {
                console.error(err);
                $this.commonService.showError(err);
              });
            });

          },
          // pageBeforeIn(e, page) {
          //     console.log('page before in');
          // },
          // pageAfterIn(e, page) {
          //     console.log('page after in');
          // },
          // pageBeforeOut(e, page) {
          //     console.log('page before out');
          // },
          // pageAfterOut(e, page) {
          //     console.log('page after out');
          // },
          // pageBeforeUnmount(e, page) {
          //     console.log('page before unmount');
          // },
          // pageBeforeRemove(e, page) {
          //     console.log('page before remove');
          // },
          pageBeforeRemove(e, page) {
            console.log('[page event] before remove', page.route.url);
            const self: F7ComponentContextExtend = this;
            const smartSelectRoles = self.$app.smartSelect.get('.smart-select-roles');
            smartSelectRoles && smartSelectRoles.destroy();
          },
        },
      },
    };
  }
}
