import { F7ComponentContext } from '../types/framework7-types';
import { Router } from 'framework7/build/core/modules/router/router';
import { TextEditor } from 'framework7/build/core/components/text-editor/text-editor';
import { take } from 'rxjs/operators';
import { BaseComponent, ComponentState } from 'vendors/smart-bot-app/src/app/lib/base-component';
import { ChatRoom } from 'vendors/smart-bot-app/src/app/lib/nam-chat/chat-room';
import { Member } from 'vendors/smart-bot-app/src/app/lib/nam-chat/model/member';
import { CommonService } from 'vendors/smart-bot-app/src/app/services/common.service';
import { RootServices } from 'vendors/smart-bot-app/src/app/services/root.services';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { AccountModel } from 'vendors/smart-bot-app/src/app/model/account.model';
import { FileModel } from 'vendors/smart-bot-app/src/app/model/file.model';
import * as $ from 'jquery';

// declare const $: any;
export interface ComponentStateExtend extends ComponentState {
  [key: string]: any;
}

export class F7ComponentContextExtend extends F7ComponentContext {
  // messagebar?: F7Messagebar;
  // messages?: Messages.Messages;
  // images?: string[];
  responseInProgress?: boolean;
  // answers?: string[];
  // people?: { name?: string, avatar?: string }[];
  // hideToolbar?: () => void;
  // sheetToggle?: () => void;
  // deleteAttachment?: (e: MouseEvent, index: number) => void;
  // handleAttachment?: (e: MouseEvent) => void;
  // checkAttachments?: () => void;
  // sendMessage?: () => void;
  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string, memberInfo?: Member, chatRoom?: ChatRoom, type?: string } };
}

/** Component group manager */
export class EditorComponent extends BaseComponent<ComponentStateExtend> {
  // states: { [key: string]: State } = {};

  title: 'Soạn thảo'

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
    public datePipe: DatePipe,
    public currencyPipe: CurrencyPipe,
  ) {
    super(rootServices);
    // console.log('Click here to open ts file');
  }

  get isPublicComponent() {
    return true;
  }

  onF7pageRemove(chatRoomId: string) {
    // if (this.chatRoomCacheList[chatRoomId]) {
    //   this.chatRoomCacheList[chatRoomId].disconnect();
    // }
  }

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: 'editor',
      path: '/editor/:id',
      component: {
        template: /*html*/`
            <div class="page editor no-toolbar" data-name="editor">
              <div class="navbar editor">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a class="link back {{textColorClass}}">
                      <i class="icon icon-back"></i>
                      <span class="if-not-md {{textColorClass}}">{{backTitle}}</span>
                    </a>
                  </div>
                  <div class="title">{{title}}</div>
                  <div class="right">
                    <a class="link icon-only {{js "this.editable ? 'text-color-editable' : this.textColorClass"}}"
                      @click="publish">
                      Lưu
                    </a>
                  </div>
                </div>
              </div>
              <div class="page-content">
            
                <div class="text-editor text-editor-custom-buttons" style="margin: 0; height: initial;">
                  <div class="text-editor-content" contenteditable style="min-height: calc(100vh - 300px);"></div>
                </div>
            
              </div>
            </div>
        `,
        style: /*css*/`
        `,
        data() {
          return {
            title: $this.title,
            editable: false,
            backTitle: 'Back'
          };
        },
        methods: {
          async refresh() {
            const self: F7ComponentContextExtend = this;
            return true;
          },
          publish(e) {
            const self: F7ComponentContextExtend = this;
            if (self.onSave && self.textEditorCustomButtons) {
              self.onSave(self.textEditorCustomButtons.getValue());
            }
            self.$router.back();
          }
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          pageInit(e, page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;

            $this.onComponentInit({ instance: self }, 'main').then(currentState => {

              // console.log(memberInfo);
              // $this.rootServices.authService.isAuthenticatedOrRefresh().pipe(take(1)).toPromise().then(() => {
              //   $this.rootServices.apiService.getPromise<AccountModel>('/user/profiles/' + self.$route.params?.id, { skipAuth: true }).then(rs => rs[0]).then(userInfo => {
              //     console.log(userInfo);
              //     let coreDomain = $this.rootServices.apiService.token && $this.rootServices.apiService.token.api_url;
              //     if (coreDomain) {
              //       const url = new URL(coreDomain);
              //       coreDomain = url.host;
              //     }
              //     self.$setState({
              //       profile: { ...userInfo, Core: coreDomain },
              //       title: userInfo.Name,
              //       // backTitle: userInfo.Name,
              //     });
              //     currentState.loaded$ && currentState.loaded$.next(true);
              //   });
              // }).catch(err => {
              //   console.error(err);
              //   $this.commonService.showError(err);
              // });

              self.textEditorCustomButtons = self.$app.textEditor.create({
                el: '.text-editor-custom-buttons',
                // define custom "hr" button
                value: self.postContent,
                customButtons: {
                  upload: {
                    content: '<i class="f7-icons">photo</i>',
                    onClick(editor: TextEditor.TextEditor, buttonEl) {
                      // document.execCommand('insertHorizontalRule', false);
                      // editor.insertImage();

                      $this.rootServices.pickFiles().then(async localFiles => {
                        try {
                          $this.commonService.showPreloader();
                          console.debug(localFiles);
                          let image: FileModel = null;
                          if (localFiles[0].dataType === 'url') {
                            image = await $this.rootServices.uploadLocalFile(localFiles[0].url as string);
                          } else {
                            const formData = new FormData();
                            const imgBlob = new Blob([localFiles[0].url], {
                              type: localFiles[0].type
                            });
                            formData.append('file', imgBlob, 'probox-' + Date.now() + '.' + localFiles[0].ext);
                            image = await $this.rootServices.uploadFileData(formData, null, { weight: localFiles[0].size });
                          }
                          console.debug(image);
                          document.execCommand('insertImage', false, image.OriginImage);
                          $this.commonService.hidePreloader();
                        } catch (err) {
                          $this.commonService.hidePreloader();
                        }
                      });
                    },
                  },
                },
                buttons: [
                  ['bold', 'italic', 'underline', 'strikeThrough'],
                  ['orderedList', 'unorderedList'],
                  ['link'],
                  ['paragraph', 'h1', 'h2', 'h3'],
                  ['alignLeft', 'alignCenter', 'alignRight', 'alignJustify'],
                  ['subscript', 'superscript'],
                  ['indent', 'outdent'],
                  'upload'
                ] as any,
              });

            });

          },
          // pageBeforeIn(e, page) {
          //     console.log('page before in');
          // },
          // pageAfterIn(e, page) {
          //     console.log('page after in');
          // },
          // pageBeforeOut(e, page) {
          //     console.log('page before out');
          // },
          // pageAfterOut(e, page) {
          //     console.log('page after out');
          // },
          // pageBeforeUnmount(e, page) {
          //     console.log('page before unmount');
          // },
          // pageBeforeRemove(e, page) {
          //     console.log('page before remove');
          // },
          pageBeforeRemove(e, page) {
            console.log('[page event] before remove', page.route.url);
            const self: F7ComponentContextExtend = this;
            // const smartSelectRoles = self.$app.smartSelect.get('.smart-select-roles');
            // smartSelectRoles && smartSelectRoles.destroy();
            self.textEditorCustomButtons && self.textEditorCustomButtons.destroy()
          },
        },
      },
    };
  }
}
