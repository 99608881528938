import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-universal-links',
  templateUrl: './universal-links.component.html',
  styleUrls: ['./universal-links.component.scss'],
})
export class UniversalLinksComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    alert('universal links component init');
  }

}
