import { Router } from "framework7/build/core/modules/router/router";
import { BaseListComponent, F7ListComponentContext, ListComponentStateExtend } from "../../../lib/base-list-component";
import { ProductModel } from "../../../model/product.model";
import { CommonService } from "../../../services/common.service";
import { RootServices } from "../../../services/root.services";
import { F7Page } from "../../../types/framework7-types";
import { ContactGroupModel } from "../../../model/contact.model";
import { Model } from "../../../model/model";
import { Template7 } from "framework7/build/core/framework7.esm.bundle";


declare const $: any;

// declare const $: any;
export interface ComponentStateExtend extends ListComponentStateExtend<Model> {
  [key: string]: any;
  instance?: F7ComponentContextExtend;
}

export interface F7ComponentContextExtend extends F7ListComponentContext<Model> {
  responseInProgress?: boolean;
  listItems?: ProductModel[];
  $root: any;
  $route: Router.Route & { context?: { backTitle?: string, title?: string, largeTitle?: string } };
}

/** Component group manager */
export class PbxExtensionListComponent extends BaseListComponent<ComponentStateExtend, Model> {

  namespace: string = 'pbx-extension-list';
  path = '/pbx/extension-list';
  title = 'Số mở rộng';
  apiPath = '/ivoip/extensions';
  idKey = 'extension_uuid';
  limit = 20;

  filterSchema = {
    domainId: {
      type: 'smart-select',
      dataType: 'single'
    },
  };

  constructor(
    public rootServices: RootServices,
    public commonService: CommonService,
  ) {
    super(rootServices, commonService);

    this.voucherStateMap['INQUEUE'] = {
      text: 'hàng đợi',
      color: 'color-orange',
      icon: 'square_stack_3d_down_right_fill'
    };
  }

  onF7pageRemove(chatRoomId: string) {
  }

  async onComponentInit(state: ComponentStateExtend, index: string, asCase?: string, f7Page?: F7Page) {

    // Load static data
    state.instance.pbxDomainList = await this.rootServices.apiService.getPromise<ContactGroupModel[]>('/ivoip/pbxs', { silent: true, includeDomains: true, limit: 'nolimit' }).then(rs => {
      console.log(rs);
      const domains = [];
      for (const pbx of rs) {
        for (const domain of pbx.Domains) {
          domain.id = domain.DomainUuid;
          domain.text = domain.Description;
          domains.push(domain);
        }
      }
      return domains;
    });
    state.filter = {
      domainId: state.instance.pbxDomainList[0].id
    };
    state.instance.$setState({
      groupList: state.instance.groupList,
    });

    return super.onComponentInit(state, index, asCase, f7Page).then(async currentState => {

      currentState.instance.$setState({
        pbxDomainList: state.instance.pbxDomainList,
      });


      //   const control = currentState.instance.$app.smartSelect.get(currentState.instance.$el.find('.smart-select[name="Page"]')[0] as any);
      //   await this.commonService.waitFor(100, 10, async () => {
      //     const element = $(currentState.instance.el).find('.filter [name="Page"]');
      //     if (element.length < 0) return false;
      //     await this.commonService.waiting(100);
      //     const field = state.instance.$app.smartSelect.get(element[0]);
      //     if (field) {
      //         field.setValue(state.instance.pbxDomainList[0].id);
      //         return true;
      //     }
      //     return false;
      // });

      return currentState;
    });
  }

  prepareItem(item: Model, index?: number) {
    // item.Title = this.commonService.getObjectText(item) || item.Name;
    // item.Subtitle = 'Chăm sóc CTV: ' + this.commonService.getObjectText(item.Manager);
    // if (!item.Avatar) {
    //   if (item.Publisher) {
    //     item.Avatar = 'https://appsv1.probox.vn/v3/user/users/' + item.Publisher + '/avatar';
    //   }
    // }
    return item;
  }

  async getList(self?: F7ComponentContextExtend, extParams?: any): Promise<Model[]> {
    const $this = this;
    const filter = $this.parseFilter($this.currentState?.filter);
    extParams.domainId = filter['eq_domainId'];
    return this.rootServices.apiService.getPromise<Model[]>(this.apiPath, {
      includeSipInfo: true,
      ...filter,
      ...extParams,
    }).then(list => {
      return list;
    }).catch(err => {
      console.error(err);
      this.commonService.showError(err);
      return Promise.reject(err);
    });
  }

  itemBeforeInsert(element: HTMLElement, item: Model): void {
    const $this = this;
    $(element).find('.click2call').click(() => {
      // this.click2call(item.Id);
      // $this.rootServices.iab.create('tel:' + item.Phone, '_system');
      $this.rootServices.copyTextToClipboard(item.Phone);
      return false;
    });
  }

  async onItemClick(self: F7ComponentContextExtend, item: Model) {
    const id = item[this.idKey];
    const $this = this;

    if (item.registrations.length > 0) {
      let template = Template7.compile(/*html*/`
      <div class="list media-list" style="margin-left: -1rem; margin-right: -1rem; margin-bottom: 0.5rem; margin-top: 0.5rem; text-align: left;">
        <ul>
          {{#each registrations}}
          <li data-id="{{id this}}">
            <a href="#" class="item-link item-content">
              <div class="item-media">
                <i class="icon f7-icons">headphones</i>
              </div>
              <div class="item-inner">
                <div class="item-title" style="white-space: normal;">{{agent}}</div>
                <div class="item-subtitle">Public IP: {{networkIp}}</div>
                <div class="item-subtitle">Local IP: {{lanIp}}</div>
              </div>
            </a>
          </li>
          {{/each}}
        </ul>
      </div>
      `);

      const dialog = $this.currentState.instance.$app.dialog.create({
        title: 'Đang online',
        text: '',
        content: template({
          registrations: item.registrations.map(m => ({
            agent: m['agent'],
            networkIp: m['network-ip'],
            lanIp: m['lan-ip'],
            pingTime: m['ping-time'],
          }))
        }),
        buttons: [
          {
            text: 'Trở về',
            bold: true,
            color: 'red'
          },
        ],
        verticalButtons: true,
      });
      dialog.open();
    } else {
      this.commonService.showInfo('Không có thiết bị nào online');
    }
  }


  click2call(id) {
    const $this = this;
    const self: F7ComponentContextExtend = this.currentState.instance;
    // const id = self.$(e.target).closest('li').data('id') || self.$(e.target).data('id');

    self.$app.dialog.create({
      title: 'Click2Call',
      text: 'Tôi sẽ gọi tới số nội bộ của bạn trước, nếu offline tôi sẽ gọi qua số cá nhân, hãy kiểm tra app GS wave đã online chưa hoặc bạn đang ở kế bên điện thoại của mình !',
      buttons: [
        {
          text: 'Đóng',
          // color: 'red',
          onClick() {
          },
        },
        {
          text: 'Gọi',
          color: 'red',
          onClick() {
            $this.commonService.showInfo('Đang gọi tới số của bạn...', { timeout: 30000 });
            $this.rootServices.apiService.putPromise('/collaborator/publishers/' + id, { click2call: true }, [{ Id: id }]).then(rs => {
              console.log(rs);
              $this.commonService.showInfo('Đang chuyển cuộc gọi...', { timeout: 30000 });
            }).catch(err => {
              console.error(err);
              $this.commonService.showError('Lỗi kết nối cuộc gọi');
            });
          },
        }
      ]
    }).open();
  }

  itemHeight = 70;
  itemTemplate = /*html*/`
    <li class="swipeout" data-id="{{Id}}" style="height: ${this.itemHeight}px; overflow: hidden">
      <a href="#" class="item-link item-content swipeout-content">
        <div class="item-media">
          <i class="icon f7-icons">headphones</i>
        </div>
        <div class="item-inner">
          <div class="item-title-row">
            <div class="item-title">{{description}}</div>
            {{#js_if "this.registrations.length > 0"}}
            <div class="item-after"><span class="badge color-green">{{js "this.registrations.length"}}</span></div>
            {{/js_if}}
          </div>
          <div class="item-text">{{extension}}</div>
          <!--<div class="item-text">{{Subtitle}}</div>-->
        </div>
      </a>
      <div class="swipeout-actions-right">
      <a href="#" class="click2call color-red swipeout-overswipe">Copy SĐT</a>
    </div>
    </li>
  `;

  get f7Component(): Router.RouteParameters {
    const $this = this;
    return {
      name: this.namespace,
      path: this.path,
      component: {
        template: /*html*/`
            <div class="page no-toolbar-x ${$this.namespace}" data-name="${$this.namespace}">
              <div class="navbar">
                <div class="navbar-bg"></div>
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a class="link back {{textColorClass}}">
                      <i class="icon icon-back"></i>
                      <span class="if-not-md {{textColorClass}}">{{js "this.backTitle || 'Back'"}}</span>
                    </a>
                  </div>
                  <div class="title">${this.title}</div>
                  <div class="right">
                    <!-- Link to enable searchbar -->
                    <a class="link icon-only searchbar-enable {{textColorClass}}" data-searchbar=".${$this.namespace}-searchbar">
                      <i class="icon f7-icons if-not-md">search</i>
                      <i class="icon material-icons md-only">search</i>
                    </a>
                  </div>
                  <!-- Searchbar is a direct child of "navbar-inner" -->
                  <form class="searchbar ${$this.namespace}-searchbar searchbar-expandable">
                    <div class="searchbar-inner">
                      <div class="searchbar-input-wrap">
                        <input type="search" placeholder="Search" />
                        <i class="searchbar-icon"></i><span class="input-clear-button"></span>
                        
                      </div>
                      <span class="searchbar-disable-button {{textColorClass}}">Hủy</span>
                    </div>
                  </form>
                </div>
              </div>
              <div class="page-content ptr-content infinite-scroll-content" @ptr:refresh="refresh">
                <div class="ptr-preloader">
                  <div class="preloader"></div>
                  <div class="ptr-arrow"></div>
                </div>
                <div class="searchbar-backdrop ${$this.namespace}-backdrop"></div>
            
                <div class="block-title" style="margin-top: 2rem">Bộ lọc <a style="float: right" @click="resetFilter"
                class="text-color-red">đặt lại</a></div>
                <div class="block block-strong inset filter" style="padding: 0; overflow: hidden">
                  <div class="list accordion-list inline-labels no-hairlines" style="margin: 0;">
                    <ul>
                      <li class="item-link smart-select smart-select-init" name="domainId" data-open-in="popup" data-searchbar="false" data-close-on-select="true" data-popup-swipe-to-close="true" data-scroll-yo-selected-item="true" @smartselect:close="onFilterFieldChange">
                        <select>
                          <option value="">--</option>
                          {{#each pbxDomainList}}
                          <option value="{{id}}">{{text}}</option>
                          {{/each}}
                        </select>
                        <div class="item-content">
                          <div class="item-inner">
                            <div class="item-title">Domain</div> 
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="block-title">Danh sách ${this.title}
                  <!--<a class="button button-small button-fill tab-link" @click="openContact" style="float: right; font-size: 0.7rem; font-weight: bold">+ Mới</a>-->
                </div>
                <div class="virtual-list list media-list ${$this.namespace}-virtual-list inset list-complex-info">
                  
                </div>
                {{#if infiniteLoadLast}}
                    <div style="text-align: center" class="text-color-gray">end</div>
                {{else}}
                    <div class="preloader color-blue infinite-scroll-preloader">
                        <span class="preloader-inner">
                            <span class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span><span
                                class="preloader-inner-line"></span><span class="preloader-inner-line"></span>
                        </span>
                    </div>
                {{/if}}
            
                <div class="block"><br></div>
            
              </div>
            </div>
        `,
        style: /*css*/`
          .${$this.namespace} .find-order {
            font-size: 16px;
            font-weight: bold;
          }
          .${$this.namespace} .action-bar {
            color: var(--f7-block-strong-text-color);
            padding-top: var(--f7-block-padding-vertical);
            padding-bottom: var(--f7-block-padding-vertical);
            background-color: var(--f7-block-strong-bg-color);
          }
          .${$this.namespace} .virtual-list i.f7-icons {
            font-size: 16px;
          }
          .${$this.namespace} .list-complex-info ul li .item-title .item-title-text {
            -webkit-line-clamp: 1;
          }
          .${$this.namespace} .list-complex-info ul li .item-title .item-title-text {
            -webkit-line-clamp: 1;
          }
          .${$this.namespace} .list-complex-info ul li .item-title {
            height: 18px;
          }


          .page.${this.namespace} .item-text.probox-link {
            display: flex;
            align-items: center;
          }
          .page.${this.namespace} .item-text.probox-link img {
            width: 1.1rem;
            height: 1.1rem;
            margin-right: 0.25rem;
          }
          .page.${this.namespace} .media-list .item-media i.icon {
            width: 44px; height: 44px; 
            font-size: 28px;
            padding: 6px;
            color: gray;
          }
          .page.${this.namespace} .media-list .item-media, 
          .page.${this.namespace} li.media-item .item-media {
            align-self: center;
          }
          .page.${this.namespace} .list-complex-info ul li .item-title {
            height: 1.6rem;
          }
          .page.${this.namespace} .${this.namespace}-virtual-list .item-media div.avatar {
              border-radius: 50%;
              overflow: hidden;
              width: 3rem;
              height: 3rem;
              background-image: url(assets/icon/contact-icon.png);
              background-repeat: no-repeat; 
              background-size: cover;
              background-position: center;
          }
          .page.${this.namespace} .${this.namespace}-virtual-list .item-media div.avatar div {
              width: 3rem;
              height: 3rem;
              background-repeat: no-repeat; 
              background-size: cover;
              background-position: center;
          }
        `,
        data() {
          return {
            title: $this.title,
            // reminder: {
            //   No: '234234',
            // },
            priceReport: { Details: [] },
            filter: {},
            processMap: $this.rootServices.collaboratorService.processMap,
          };
        },
        methods: {
          reportCurrentFilterToPdf() {
            $this.rootServices.iab.create($this.rootServices.apiService.buildApiUrl($this.apiPath, {
              includeCreator: true,
              includeRelativeVouchers: true,
              sort_DateOfOrder: 'desc',
              ...$this.parseFilter($this.currentState?.filter),
              type: 'pdf'
            }) as string, '_system');
          },
          resetFilter(e) {
            $this.resetFilter(this, e);
          },
          onFilterFieldChange(e) {
            $this.onFilterFieldChange(this, e);
          },
          // Price report
          openContact(e, id?: string) {
            const self: F7ComponentContextExtend = this;
            id = id || self.$(e.target).closest('li').data('id') || 'new';

            // if ($this.commonService.frameSocket.isFrameMode && id && id !== 'new') {
            //   $this.commonService.frameSocket.emit('request-open-voucher', { id: id, type: 'PRICEREPORT' });
            // } else {
            $this.rootServices.navigate('/contact/contact-form/' + id, {
              context: {
                backTitle: $this.title,
                textColorClass: self.textColorClass,
                // memberInfo: $this.currentState.chatRoom.memberList$.getValue().find(f => f.id === id),
                // chatRoom: self.$route.context.chatRoom,
                // reminderList$: self.reminderList$,
                callback: (data: Model, state: any) => {
                  console.log('Callback data: ', data);
                  if (state?.lastAction == 'CREATE_SUCCESS') {
                    self.refresh();
                  }
                }
              }
            });
            // }
          },
          goto(e) {
            const self: F7ComponentContextExtend = this;
            const url = self.$(e.target).closest('.goto').data('url');
            $this.rootServices.navigate(url);
          },
          chooseOne(e) {
            const self: F7ComponentContextExtend = this;
            const id = self.$(e.target).closest('li').data('id');
            self.$route.context['onChoose'] && self.$route.context['onChoose'](self.listItems.find(f => f.Code === id));
          },
          chooseProduct(e) {

          },
          async refresh(e, done) {
            const self: F7ComponentContextExtend = this;
            $this.refresh(self).then(rs => done && done());
            return true;
          },
        },
        on: {
          // pageMounted(e, page) {
          //     console.log('page mounted');
          // },
          pageInit(e, page: F7Page) {
            console.log('page init');
            const self: F7ComponentContextExtend = this;
            // Loading flag
            let allowInfinite = true;

            $this.onComponentInit({ instance: self }, 'main', null, page);
          },
          // pageBeforeIn(e, page) {
          //     console.log('page before in');
          //     const self: F7ComponentContextExtend = this;
          //     self.refresh();
          // },
          // pageAfterIn(e, page) {
          //     console.log('page after in');
          // },
          // pageBeforeOut(e, page) {
          //     console.log('page before out');
          // },
          pageAfterOut(e, page) {
            console.log('page after out');
          },
          // pageBeforeUnmount(e, page) {
          //     console.log('page before unmount');
          // },
          // pageBeforeRemove(e, page) {
          //     console.log('page before remove');
          // },
          pageBeforeRemove(e, page) {
            console.log('[page event] before remove', page.route.url);
            const self: F7ComponentContextExtend = this;
            // const starmtSelectRoles = self.$app.smartSelect.get('.smart-select');
            // starmtSelectRoles.destroy();
          },
        },
      },
    };
  }
}
